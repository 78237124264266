import React from 'react';
import { PageWithAppcues } from '~/helpers/RouteWithAppcues';
import Designs from '../designs/Designs';

const SharedWithMeContainer = () => (
    <PageWithAppcues>
        <Designs isSharedWithMe />
    </PageWithAppcues>
);
export default SharedWithMeContainer;
