import React, { useEffect, useState } from 'react';
import { Button, Dropdown } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import useAccessRights from 'packages/hooks/useAccessRights';
import useQueryParams from 'packages/hooks/useQueryParams';
import roles from '~/configs/roles';
import features from '~/configs/features';
import { OrderingFilterProps, OwnershipFilterType } from './types';

const Ownership = ({ sortBy, onSort, isDisabled, isDesigns, ...props }: OrderingFilterProps) => {
    const [ownership, setOwnership] = useState(sortBy);
    const { translate } = useTranslate();
    const { isAccountRoleAssigned, isPlatformAllowed } = useAccessRights();
    const { getQueryParam } = useQueryParams();

    const isTrialAccount =
        isPlatformAllowed([features.TRIAL_ENABLED]) && isAccountRoleAssigned(roles.video_brand_studio.video.manage);

    const onSortBySelect = (newSortBy: OwnershipFilterType) => {
        setOwnership(newSortBy);
        onSort(newSortBy);
    };

    let ownershipTitles;
    if (isDesigns) {
        ownershipTitles = {
            [OwnershipFilterType.OWNED_BY_ANYONE]: translate('filters.ownership.anyone'),
            [OwnershipFilterType.OWNED_BY_ME]: translate('filters.ownership.me'),
            [OwnershipFilterType.NOT_SHARED_WITH_ME]: translate('filters.ownership.not-me'),
        };
    } else {
        ownershipTitles = {
            [OwnershipFilterType.OWNED_BY_ANYONE]: translate('filters.ownership.anyone'),
            [OwnershipFilterType.OWNED_BY_ME]: translate('filters.ownership.me'),
            [OwnershipFilterType.OWNED_BY_OTHERS]: translate('filters.ownership.others'),
            [OwnershipFilterType.NOT_SHARED_WITH_ME]: translate('filters.ownership.not-me'),
        };
    }

    useEffect(() => {
        const queriedOwnership = getQueryParam('ownership')?.toUpperCase();

        if (queriedOwnership && Object.keys(OwnershipFilterType).includes(queriedOwnership)) {
            setOwnership(OwnershipFilterType[queriedOwnership]);
        }
    }, []);

    useEffect(() => {
        if (sortBy) {
            setOwnership(sortBy);
        }
    }, [sortBy]);

    return (
        <>
            <Dropdown
                position="bottom-left"
                trigger={({ isOpen, ...triggerProps }) => (
                    <Button
                        data-testid="overview ownership filter"
                        variant="clean"
                        isPressed={isOpen}
                        {...triggerProps}
                        aria-label={translate('filters.order.sortBy.label')}
                        isDisabled={isDisabled}
                        rightIcon={<Dropdown.Arrow />}
                    >
                        {ownershipTitles[ownership]}
                    </Button>
                )}
                {...props}
            >
                <Dropdown.Item
                    onClick={() => onSortBySelect(OwnershipFilterType.OWNED_BY_ME)}
                    isChecked={ownership === OwnershipFilterType.OWNED_BY_ME}
                >
                    {translate('filters.ownership.me')}
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => onSortBySelect(OwnershipFilterType.OWNED_BY_ANYONE)}
                    isChecked={ownership === OwnershipFilterType.OWNED_BY_ANYONE}
                    isDisabled={isTrialAccount}
                >
                    {translate('filters.ownership.anyone')}
                </Dropdown.Item>
                {!isDesigns && (
                    <Dropdown.Item
                        onClick={() => onSortBySelect(OwnershipFilterType.OWNED_BY_OTHERS)}
                        isChecked={ownership === OwnershipFilterType.OWNED_BY_OTHERS}
                        isDisabled={isTrialAccount}
                    >
                        {translate('filters.ownership.others')}
                    </Dropdown.Item>
                )}
                {isAccountRoleAssigned(roles.video_brand_studio.handler.admin) && (
                    <>
                        <Dropdown.Divider />
                        <Dropdown.Item
                            onClick={() => onSortBySelect(OwnershipFilterType.NOT_SHARED_WITH_ME)}
                            isChecked={ownership === OwnershipFilterType.NOT_SHARED_WITH_ME}
                            isDisabled={isTrialAccount}
                        >
                            {translate('filters.ownership.not-me')}
                        </Dropdown.Item>
                    </>
                )}
            </Dropdown>
        </>
    );
};

export default Ownership;
