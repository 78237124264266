import { NAMES_MAP } from './maps';

type Options = {
    sort: [string, string];
    page?: number;
    size?: number;
    filter: string;
    search?: string;
};

const createQuery = (opt: Options): string => {
    const { sort, page, size, filter, search } = opt;

    return `${sort ? `sortingField=${NAMES_MAP.get(sort[0]) || sort[0]}&sortingOrder=${NAMES_MAP.get(sort[1])}&` : ''}${
        filter && filter !== 'all' ? `status=${filter}&` : ''
    }page=${page || 0}&size=${size || 1000}${search ? `&query=${encodeURIComponent(search)}` : ''}`;
};

export { createQuery };
