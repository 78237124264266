import { createNewTreeFromDraft, findElementById, flattenTree } from './helpers/elementtree';
import { copy, IMG_VIDEO_VALUE, millisecondToFrames, rangesOverlaping } from './editorHelper';
import { elementIsImage, elementIsVideo, isImageOrVideo } from './helpers';

/**
 * BLOC - business logic component
 */
export const prepareDataForSave = (
    validate = false,
    elementsToParse,
    invalidElements = {},
    depth = 0,
    creativeType = '',
) => {
    const elements = Object.values(elementsToParse).map((el) => {
        const element = copy(el);

        if (element.type === 'GROUP') {
            element.children = prepareDataForSave(
                validate,
                element.children,
                invalidElements,
                depth + 1,
                creativeType,
            ).elements;
        }

        if (el.invalidProperties && el.invalidProperties.size) {
            invalidElements[element.id] = element;
        }

        if (isImageOrVideo(element.type)) {
            if (element.properties.contentTransform.type === 'FREE') {
                element.properties.contentTransform.verticalAlignment = undefined;
                element.properties.contentTransform.horizontalAlignment = undefined;
            } else {
                element.properties.contentTransform.scale = undefined;
                element.properties.contentTransform.verticalOffset = undefined;
                element.properties.contentTransform.horizontalOffset = undefined;
                element.properties.contentTransform.rotation = undefined;
            }

            element.properties.naturalWidth = undefined;
            element.properties.naturalHeight = undefined;

            if (element.properties.value === null && validate) {
                element.invalidProperties = new Set();
                element.invalidProperties.add(IMG_VIDEO_VALUE);
                invalidElements[element.id] = element;

                return;
            }
        }

        if (creativeType === 'IMAGE') {
            delete element.properties.duration;
            delete element.properties.startFrame;
            delete element.properties.animationIn;
            delete element.properties.animationOut;
            delete element.properties.animations;
            delete element.properties.timelineBehavior;
            delete element.properties.useDynamicLength;
        }

        if (element.properties.value && typeof element.properties.value === 'object') {
            delete element.properties.value.thumbnail;
            delete element.properties.value.ref;
        }

        if (elementIsVideo(element.type)) {
            if (typeof element.properties.useAudio !== 'boolean') {
                element.properties.useAudio = false;
            }
        }

        // Seems like this logic should be in the dropShadow class
        // todo: Delete after release
        if (element.properties.dropShadow) {
            element.properties.dropShadow =
                element.properties.dropShadow.state === 'DISABLE' ? null : element.properties.dropShadow;

            if (element.properties.dropShadow) {
                delete element.properties.dropShadow.state;
            }
        }

        delete element.properties.lockUniScaling;
        const value = element.properties.value;

        const data = {
            id: element.id,
            type: element.type.toUpperCase(),
            name: element.name,
            properties: { ...element.properties, value },
            contentPropertyUuid: element.contentPropertyUuid,
        };

        if (element.parentId) {
            data.parentId = element.parentId;
        }

        if (element.presetId) {
            data.presetId = element.presetId;
        }

        if (element.children) {
            data.children = element.children;
        }

        return data;
    });

    if (!Object.keys(invalidElements).length || !validate || depth) {
        return { elements };
    }

    return { invalidElements };
};

const addGroupElementsToShot = (shotElements, elements) => {
    const elementsToBeAdded = [];
    shotElements.forEach((shotElement) => {
        const element = findElementById(elements, shotElement.id);

        if (!element) {
            return;
        }

        const { parentId } = element;

        if (
            parentId &&
            !elementsToBeAdded.find((el) => el.id === parentId) &&
            !shotElements.find((el) => el.id === parentId)
        ) {
            elementsToBeAdded.push({ id: parentId });
        }
    });

    if (!elementsToBeAdded.length) {
        return shotElements;
    }

    return addGroupElementsToShot([...elementsToBeAdded, ...shotElements], elements);
};

export const prepareShotsForSave = (shots, elements) =>
    shots.map((shot) => {
        const { name, displayOrder, startFrame, duration, thumbnailFrame } = shot;
        const shotElements = shot.elements.map((element) => ({ id: element.id, disabled: element.disabled }));
        const newElements = addGroupElementsToShot(shotElements, elements);

        return {
            name,
            displayOrder,
            startFrame,
            duration,
            thumbnailFrame,
            elements: newElements,
        };
    });

const filterElementsByFrameRange = (elements, { fromFrame, toFrame }) => {
    const elementsById = {};
    Object.values(flattenTree(elements))
        .filter(({ properties: { startFrame, duration } }) => {
            return rangesOverlaping({ from: startFrame, to: startFrame + duration }, { from: fromFrame, to: toFrame });
        })
        .forEach((element) => {
            elementsById[element.id] = element;
        });

    return elementsById;
};

const filterElementsByFrameRangeForConfigurator = (elements, { fromFrame, toFrame }, shots) => {
    const elementsById = {};

    Object.values(elements)
        .filter(({ properties: { startFrame } }) => startFrame >= fromFrame && startFrame < toFrame)
        .forEach((element) => {
            elementsById[element.id] = element;
        });

    return elementsById;
};

const filterElementsByFrame = (elements, frame) =>
    filterElementsByFrameRange(elements, { fromFrame: frame, toFrame: frame });

const patchElementThumbnailsByFrame = (elements, frame, frameRate) => {
    const elementIds = Object.keys(flattenTree(elements));

    return createNewTreeFromDraft(elements, (draftTree) => {
        elementIds.forEach((elementId) => {
            const element = findElementById(draftTree, elementId);

            const properties = element.properties;

            if (elementIsVideo(element.type)) {
                if (properties.value) {
                    const { duration, startFrame } = properties;
                    const activeFrame = Math.min(frame, startFrame + duration);
                    const thumbnailFrame =
                        activeFrame - startFrame + millisecondToFrames(properties.value.offsetTime, frameRate);
                    const params = {
                        frame: thumbnailFrame,
                        assetId: properties.value.value,
                        width: properties.width,
                        height: properties.height,
                    };

                    if (element.type === 'VIDEO' && properties.contentTransform.type === 'COVER') {
                        params.width = properties.naturalWidth;
                        params.height = properties.naturalHeight;
                    }
                }
            }
        });
    });
};

export const getElementsToShowForShot = (elements, shot, useShotThumbnailFrame) => {
    if (useShotThumbnailFrame) {
        const frame = shot.thumbnailFrame + shot.startFrame;

        return filterElementsByFrame(elements, frame);
    }

    return filterElementsByFrameRangeForConfigurator(elements, {
        fromFrame: shot.startFrame,
        toFrame: shot.startFrame + shot.duration - 1,
    });
};

export const getElementsForShots = (elements, shot, frameRate, globalElements = [], useShotThumbnailFrame) => {
    const frame = shot.thumbnailFrame + shot.startFrame;
    const parsedElements = patchElementThumbnailsByFrame(elements, frame, frameRate);
    const elementsToShow = getElementsToShowForShot(parsedElements, shot, useShotThumbnailFrame);

    return Object.values(elementsToShow);
};

const findNextName = (list, baseName, idx = 1) => {
    const name = `${baseName} (${idx})`;

    if (!list.includes(name)) {
        return name;
    }

    return findNextName(list, baseName, idx + 1);
};

export const getVideosNames = (creativeName, videos) => {
    const names = videos.map((video) => video.name);

    for (let i = 0; i < names.length; i++) {
        if (!names[i]) {
            names[i] = findNextName(names, creativeName);
        }
    }

    return names;
};
