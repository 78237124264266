import React, { useCallback, useEffect, useState } from 'react';
import { IconStarredFolder } from '@bynder/icons';
import usePresets from '~/common/editor/presets/usePresets';
import generateTestId from '~/helpers/testIdHelpers';
import useEditor from '../../../../../hooks/useEditor';
import { collectSingleTopLevelGroup } from './utils';
import { TimelineActionsIcon } from '../TimelineActions.styled';

export default function PresetAction() {
    const { createPreset } = usePresets();
    const { manipulationRenderer } = useEditor();
    const [selectedGroup, setSelectedGroup] = useState(() =>
        collectSingleTopLevelGroup(manipulationRenderer?.getSelectedElements() || []),
    );
    const isActive = !!selectedGroup;
    const classNames = ['timeline-actions__icon', isActive && 'active'];

    const handleCreatePreset = useCallback(() => {
        if (isActive) {
            createPreset(selectedGroup);
        }
    }, [isActive, createPreset, selectedGroup]);

    useEffect(() => {
        if (!manipulationRenderer) {
            return;
        }

        const listener = () => {
            setSelectedGroup(collectSingleTopLevelGroup(manipulationRenderer.getSelectedElements()));
        };

        manipulationRenderer.eventEmitter.on('elementSelected', listener);

        return () => manipulationRenderer.eventEmitter.off('elementSelected', listener);
    }, [manipulationRenderer]);

    return (
        <div title="Save as Preset">
            <TimelineActionsIcon
                onClick={handleCreatePreset}
                active={isActive}
                {...generateTestId('timeline_actions_preset')}
            >
                <IconStarredFolder />
            </TimelineActionsIcon>
        </div>
    );
}
