import React, { FC, useCallback } from 'react';
import type { ColorParams } from '@bynder-studio/render-core';
import { Dropdown, Thumbnail } from '@bynder/design-system';
import { colorToCSS } from '~/common/editor/editorHelper';
import { hexToRgb, rgbToHex } from '../../../ColorPicker/utils';
import type { BrandColorOnElement } from 'packages/pages/editor/RightSideMenu/FormComponents/EditorColor';
import type { BrandColor } from 'packages/store/brandColors/types';

type ColorItemProps = {
    item: BrandColor | BrandColorOnElement | ColorParams;
    selected?: boolean;
    onClick: (item: any) => void;
};

export const ColorItem: FC<ColorItemProps> = ({ item, selected, onClick }) => {
    const handleClick = useCallback(() => {
        onClick(item);
    }, [item]);

    return (
        <Dropdown.Item
            isChecked={selected}
            icon={
                <Thumbnail
                    shape="circle"
                    backgroundColor={
                        (item as BrandColorOnElement).hexCode
                            ? hexToRgb((item as BrandColorOnElement).hexCode, (item as BrandColorOnElement).alpha)
                            : colorToCSS(item)
                    }
                    size="xs"
                    variant="content"
                />
            }
            onClick={handleClick}
            checkedVariant="checkmark"
        >
            {(item as BrandColorOnElement).name || rgbToHex(colorToCSS(item)).toUpperCase()}
        </Dropdown.Item>
    );
};
