import { v4 as uuidv4 } from 'uuid';
import {
    BaseVisualElement,
    getAllElementsRecursively,
    TextElement,
    TextStyle,
    Template,
} from '@bynder-studio/render-core';
import { collectStyleIds } from 'packages/pages/editor/RightSideMenu/Shots/Text/TextStyles/utils';

export type MatchList = { [key: TextStyle['uuid']]: TextStyle['uuid'] };

const collectTextStyles = (selectedGroup: BaseVisualElement[], currentStyles: TextStyle[]) => {
    const textElements = getAllElementsRecursively(selectedGroup).filter((element) => element instanceof TextElement);

    return [
        ...new Set(
            (textElements as TextElement[]).flatMap((element) =>
                [...collectStyleIds(element as TextElement)].filter((style) => !!style),
            ),
        ),
    ]
        .map((styleId) => currentStyles.find(({ uuid }) => uuid === styleId))
        .filter((style) => !!style) as TextStyle[];
};

export const convertTextStyles = (selectedGroup, currentStyles) => {
    const idsMatchList = {};

    const textStyles = collectTextStyles(selectedGroup, currentStyles).map((style) => {
        const { uuid, ...styleProps } = style;
        const newUuid = uuidv4();
        idsMatchList[uuid] = newUuid;

        return { ...styleProps, uuid: newUuid };
    });

    return [idsMatchList, textStyles];
};

export const replaceStyleIds = (
    formattedText: { runs: TextElement['formattedText']['runs'] },
    matchList: MatchList,
) => {
    return formattedText.runs.map((run) => {
        if (run.styleId && matchList[run.styleId]) {
            run.styleId = matchList[run.styleId];
        }

        return run;
    });
};

export const replaceDuplicatedIds = (elements: Template['elements'], matchList: MatchList) => {
    Object.values(elements).forEach((element) => {
        if (element?.children) {
            return replaceDuplicatedIds(element.children, matchList);
        }

        if (element?.type === 'TEXT') {
            const styleId = matchList[element.properties.styleId];

            if (styleId) {
                element.properties.styleId = styleId;
            }

            element.properties.value.runs = replaceStyleIds(element.properties.value, matchList);
        }
    });

    return elements;
};

export type Node<T> = T & { children?: Record<string, Node<T>> };

export const getAllNodesRecursively = <T extends object>(node: Node<T>) => {
    const result: Node<T>[] = [node];

    if (!node?.children) {
        return result;
    }

    Object.values(node.children).forEach((child) => {
        result.push(...getAllNodesRecursively(child));
    });

    return result;
};
