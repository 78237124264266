import React, { FC } from 'react';
import { Form, Slider } from '@bynder/design-system';
import { CropSettingProps } from './types';

const CropSetting: FC<CropSettingProps> = ({
    label,
    name,
    value,
    format,
    min,
    max,
    isFromCenter = true,
    setSettingValue,
    disabled,
    ...props
}) => {
    const factor = format === 'percent' ? 100 : 1;

    const onChange = (val: number) => {
        if (disabled) return;
        setSettingValue(Number((val / factor).toFixed(2)), name);
    };

    const sliderValue = Math.round(value * factor);

    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Slider
                value={sliderValue}
                min={sliderValue < min * factor ? sliderValue : min * factor}
                max={sliderValue > max * factor ? sliderValue : max * factor}
                isFromCenter={isFromCenter}
                inputPosition="right"
                onChange={onChange}
                isInactive={disabled}
                isTooltipHidden={disabled}
                {...props}
            />
        </Form.Group>
    );
};

export default CropSetting;
