import { useSelector } from 'react-redux';
import { isBynderScopeAllowedSelector, isPlatformAllowedSelector } from 'packages/store/user/user.selectors';
import useAccountPermissions from './accountPermissions';
import useEntityPermissions from './entityPermissions';

const useAccessRights = () => {
    const isPlatformAllowedSelectorFn = useSelector(isPlatformAllowedSelector);
    const isBynderScopeAllowedSelectorFn = useSelector(isBynderScopeAllowedSelector);
    const { isAccountRoleAssigned, isAccountActionAllowed, getAccountAssignedRole } = useAccountPermissions();
    const { isEntityActionAllowed, getIdentityRole, getIdentityRoleAllUsers } = useEntityPermissions(
        getAccountAssignedRole,
        isPlatformAllowedSelectorFn,
    );

    return {
        isPlatformAllowed: isPlatformAllowedSelectorFn,
        isBynderScopeAllowed: isBynderScopeAllowedSelectorFn,
        isAccountRoleAssigned,
        isAccountActionAllowed,
        getAccountAssignedRole,
        isEntityActionAllowed,
        getIdentityRole,
        getIdentityRoleAllUsers,
    };
};

export default useAccessRights;
