import { FC } from 'react';
import {
    IconAlignBottom,
    IconAlignHorizontalCenter,
    IconAlignTop,
    IconTextCenter,
    IconTextJustify,
    IconTextLeft,
    IconTextRight,
} from '@bynder/icons';

export type AlignmentNumber = -1 | 0 | 1 | 2;

export const HORIZNTAL_ALIGNMENTS = [
    { name: 'Left', value: -1, icon: IconTextLeft, testIdName: 'left' },
    { name: 'Center', value: 0, icon: IconTextCenter, testIdName: 'center' },
    { name: 'Right', value: 1, icon: IconTextRight, testIdName: 'right' },
    { name: 'Justified', value: 2, icon: IconTextJustify, testIdName: 'justified' },
] as { name: string; value: AlignmentNumber; icon: FC; testIdName: string }[];

export const VERTICAL_ALIGNMENTS = [
    { name: 'Align top', value: -1, icon: IconAlignTop, testIdName: 'align-top' },
    { name: 'Align middle', value: 0, icon: IconAlignHorizontalCenter, testIdName: 'align-middle' },
    { name: 'Align bottom', value: 1, icon: IconAlignBottom, testIdName: 'align-bottom' },
] as { name: string; value: AlignmentNumber; icon: FC; testIdName: string }[];
