import { ACCEPT, AssetType, Task, UploaderError } from './types';
import { isFileSizeValid, isMediaResolutionValid } from '~/common/editor/contentSelection/utils';

export const createStream = <T>() => {
    let resolve = (_: IteratorResult<T>) => {};

    const queue: Promise<IteratorResult<T>>[] = [
        new Promise((r) => {
            resolve = r;
        }),
    ];

    const enqueue = (value: T, done = false) => {
        const resolvePrev = resolve;

        queue.push(
            new Promise((r) => {
                resolve = r;
            }),
        );

        resolvePrev({ value, done });
    };

    return [
        {
            async *[Symbol.asyncIterator]() {
                while (queue.length) {
                    const { value, done } = await queue.shift()!;
                    yield value as T;

                    if (done) {
                        return;
                    }
                }
            },
        },
        enqueue,
    ] as const;
};

export const XHRUpload = ({
    url,
    file,
    onProgress: handleProgress,
    onSuccess,
    onError,
    onAbort,
    abortSignal,
}: {
    url: string;
    file: File;
    onProgress: (progress: ProgressEvent) => void;
    onSuccess: (responceUrl: string) => void;
    onError: () => void;
    onAbort: () => void;
    abortSignal: AbortSignal;
}) => {
    if (abortSignal.aborted) {
        onAbort();

        return;
    }

    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url, true);
    xhr.setRequestHeader('Content-Type', 'application/octet-stream');

    const handleAbort = () => {
        abortSignal.removeEventListener('abort', onAbort);
        xhr.abort();
        onAbort();
    };

    const handleLoad = () => {
        abortSignal.removeEventListener('abort', onAbort);

        if (xhr.status === 200) {
            onSuccess(xhr.responseURL);
        } else {
            onError();
        }
    };

    const handleError = () => {
        abortSignal.removeEventListener('abort', onAbort);
        onError();
    };

    xhr.upload.addEventListener('progress', handleProgress);
    xhr.addEventListener('load', handleLoad);
    xhr.addEventListener('error', handleError);
    abortSignal.addEventListener('abort', handleAbort);

    xhr.send(file);
};

export const isMimeTypeValid = (assetTypes: AssetType[], fileType: string) => {
    return assetTypes.some((category) => ACCEPT[category].includes(fileType));
};

export const determineMimeType = (file: File) => {
    if (file.type) {
        return file.type;
    }

    const ext = file.name.split('.').pop() || '';
    const extensions = {
        sketch: 'application/sketch',
        psd: 'image/vnd.adobe.photoshop',
    };

    return extensions[ext] || '';
};

export const validateFile = async (file: File, assetTypes: AssetType[]): Promise<Task<null>> => {
    const error: UploaderError = {
        status: 'error',
        stage: 'validating',
        type: 'generic',
    };

    const fileType = determineMimeType(file);

    if (!isMimeTypeValid(assetTypes, fileType)) {
        error.type = 'mimeType';

        return error;
    }

    if (!isFileSizeValid(file.size)) {
        error.type = 'size';

        return error;
    }

    if (!(await isMediaResolutionValid(file))) {
        error.type = 'resolution';

        return error;
    }

    return { status: 'success', data: null };
};
