import { useEffect, useState } from 'react';
import useDesign from 'packages/pages/design/hooks/useDesign';
import { renditionToUrl } from 'packages/variation-export/utils';
import { VariationItemBySizeIdType } from 'packages/variation-export/types';

type useVariationThumbnailType = (sizes: VariationItemBySizeIdType) => {
    isUpdating: boolean;
    thumbnail: string;
    containsTransparency: boolean;
};

const getPageMeta = (creativeModel: any, pageIndex?: number) => {
    const idx = pageIndex ?? creativeModel.getCurrentPageIndex();

    return creativeModel.getModelsMetaData()[idx];
};

export const usePageIndex = () => {
    const { creativeModel } = useDesign();
    const [pageIndex, setPageIndex] = useState(() => creativeModel.getCurrentPageIndex());

    useEffect(() => {
        const handleChangePage = (pageIndex) => {
            setPageIndex(pageIndex);
        };

        const emitter = creativeModel.getEventEmitter();

        emitter.on('currentPageChange', handleChangePage);

        return () => {
            emitter.off('currentPageChange', handleChangePage);
        };
    }, [creativeModel]);

    return { pageIndex };
};

const useCurrentSizeMeta = () => {
    const { creativeModel } = useDesign();
    const [page, setPage] = useState(() => getPageMeta(creativeModel));

    useEffect(() => {
        const handleChangePage = (newIdx) => {
            setPage(getPageMeta(creativeModel, newIdx));
        };

        const emitter = creativeModel.getEventEmitter();

        emitter.on('currentPageChange', handleChangePage);

        return () => {
            emitter.off('currentPageChange', handleChangePage);
        };
    }, [creativeModel]);

    return page;
};

export const useVariationThumbnail: useVariationThumbnailType = (sizes) => {
    const sizeMeta = useCurrentSizeMeta();
    const { previewRendition, containsTransparency, creativeThumbnailUrl } = sizes[sizeMeta.id];
    const sizeThumbnail = renditionToUrl(previewRendition);

    return {
        containsTransparency,
        thumbnail: sizeThumbnail ?? creativeThumbnailUrl,
        isUpdating: !sizeThumbnail,
    };
};
