import React from 'react';
import { Button, ModalConfirmation, Thumbnail, token } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconDelete } from '@bynder/icons';
import modalContainer from 'packages/common/modalContainer';

const DeleteModal = ({ isOpen, onClose, onConfirm, variationName = '' }) => {
    const { translate } = useTranslate();

    return (
        <ModalConfirmation
            container={modalContainer}
            title={translate('design.sidebar.variations.modal.delete.title', { variationName })}
            isOpen={isOpen}
            onClose={onClose}
            thumbnail={
                <Thumbnail
                    backgroundColor={token.red500}
                    icon={<IconDelete />}
                    iconColor={token.gray10}
                    shape="circle"
                    variant="clean"
                />
            }
            actionPrimary={
                <Button variant="danger" onClick={onConfirm}>
                    {translate('design.sidebar.variations.modal.delete.action')}
                </Button>
            }
            actionSecondary={
                <Button onClick={onClose}>{translate('design.sidebar.variations.modal.delete.cancel')}</Button>
            }
        >
            {translate('design.sidebar.variations.modal.delete.text')}
        </ModalConfirmation>
    );
};

export default DeleteModal;
