import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import {
    toggleFigmaFrameId,
    selectFigmaPageId,
    closeFigmaSelectedLimitReachedModal,
} from 'packages/store/figma/figma.actions';
import { getFigmaState } from 'packages/store/figma/figma.selectors';
import { SELECTED_FRAMES_LIMIT } from 'packages/store/figma/figma.reducer';
import { type FigmaFile } from 'packages/store/figma/types';
import modalContainer from 'packages/common/modalContainer';

export default function useFigmaFramesSelection(file?: FigmaFile) {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const {
        selectedFrameIds: selectedFramesIds,
        selectedPage: selectedPageId,
        isLimitReached,
    } = useSelector(getFigmaState);

    useEffect(() => {
        dispatch(selectFigmaPageId(file?.pages[0]?.id ?? undefined));
    }, [file, dispatch]);

    const selectPage = useCallback(
        (pageId: string) => {
            dispatch(selectFigmaPageId(pageId));
        },
        [dispatch],
    );

    const toggleFrame = useCallback(
        (frameId: string) => {
            dispatch(toggleFigmaFrameId(frameId));
        },
        [dispatch],
    );

    const closeLimitReachedModal = useCallback(() => {
        dispatch(closeFigmaSelectedLimitReachedModal());
    }, [dispatch]);

    const renderLimitReachedModal = useCallback(() => {
        if (!isLimitReached) {
            return null;
        }

        return (
            <Modal
                container={modalContainer}
                title={translate('modal.design.create.design.figma.frames.limit-reached.title', {
                    count: SELECTED_FRAMES_LIMIT,
                })}
                isOpen={isLimitReached}
                onClose={closeLimitReachedModal}
                actionPrimary={
                    <Button variant="primary" onClick={closeLimitReachedModal}>
                        {translate('modal.design.create.design.figma.frames.limit-reached.confirm')}
                    </Button>
                }
            >
                {translate('modal.design.create.design.figma.frames.limit-reached.text', {
                    count: SELECTED_FRAMES_LIMIT,
                })}
            </Modal>
        );
    }, [closeLimitReachedModal, isLimitReached, translate]);

    return { selectedPageId, selectPage, selectedFramesIds, toggleFrame, renderLimitReachedModal };
}
