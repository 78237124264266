import React from 'react';
import { Column, ColumnSelect, ListContainer, RowContainer } from './List.styled';
import PageItem from './PageItem';
import { SelectItemCheckbox } from './SelectItemCheckbox';
import useExport from '../hooks/useExport';

const PageList = ({ items }) => {
    const { orderBy, pageIds } = useExport();

    let filteredItems = items.filter((item) => pageIds.includes(item.pageId.toString()));

    if (filteredItems.length === 0) {
        filteredItems = items;
    }

    if (orderBy === 'name') {
        filteredItems.sort((a, b) => a.name.localeCompare(b.name));
    }

    if (orderBy === 'updated') {
        filteredItems.sort((a, b) => a.displayOrder - b.displayOrder);
    }

    return (
        <ListContainer>
            {filteredItems.map((item, index) => (
                <RowContainer key={index} gap="4" alignItems="center" bordered>
                    <ColumnSelect>
                        <SelectItemCheckbox item={item} />
                    </ColumnSelect>
                    <Column>
                        <PageItem {...item} />
                    </Column>
                </RowContainer>
            ))}
        </ListContainer>
    );
};

export default PageList;
