export const setKeyByPath = (data: any, path: string[], value: any, index = 0): void => {
    const key = path[index];

    if (path.length > index + 1) {
        data[key] = data[key] || {};
        setKeyByPath(data[key], path, value, index + 1);
    } else {
        data[key] = value;
    }
};

export const fromNumToPercent = (totalNum: number, number: number) => (100 / totalNum) * number;

export const fromPercentToNum = (totalNum: number, percent: number) => (totalNum / 100) * percent;

export const equals = (a: any, b: any): boolean => {
    if (a === b) {
        return true;
    }

    if (a instanceof Date && b instanceof Date) {
        return a.getTime() === b.getTime();
    }

    if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) {
        return a === b;
    }

    if (a.prototype !== b.prototype) {
        return false;
    }

    const keys = Object.keys(a);

    if (keys.length !== Object.keys(b).length) {
        return false;
    }

    return keys.every((k) => equals(a[k], b[k]));
};

export const deepClone = <T extends any>(obj: T) => {
    if (obj === null) {
        return null;
    }

    const clone = Object.assign({}, obj);
    Object.keys(clone).forEach((key) => (clone[key] = typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key]));

    if (Array.isArray(obj)) {
        (clone as any).length = obj.length;

        return Array.from(clone as any) as T;
    }

    return clone as T;
};

export const isEmpty = (obj: any) => {
    if (obj === null || obj === undefined) {
        return true;
    }

    if (typeof obj === 'string') {
        return obj.length === 0;
    }

    if (Array.isArray(obj)) {
        return obj.length === 0;
    }

    if (typeof obj === 'object') {
        return Object.keys(obj).length === 0;
    }

    return false;
};

export const mergeData = (oldVal: any, newVal: any): any => {
    const isObject = (val: any) => typeof val === 'object' && !Array.isArray(val) && val !== null;

    const data = isObject(oldVal) && isObject(newVal) ? { ...oldVal, ...newVal } : newVal;

    if (isObject(data)) {
        Object.keys(data)
            .filter((key) => isObject(oldVal[key]) && isObject(newVal[key]))
            .forEach((key) => {
                data[key] = mergeData(oldVal[key], newVal[key]);
            });
    }

    return data;
};

export const safeDeepCloneForElement = (element: any) => {
    if (typeof element !== 'object') {
        return element;
    }

    const { children, parent, ...rest } = element;
    const clone = deepClone(rest);

    if ('children' in element) {
        clone.children = children;
    }

    if ('parent' in element) {
        clone.parent = parent;
    }

    return clone;
};

export const cleanupNullValues = <T extends object>(obj: T) => {
    Object.keys(obj).forEach((key) => obj[key] === null && delete obj[key]);

    return obj as { [Key in keyof T]: Exclude<T[Key], null> };
};

export const isDefined = (val: any) => val !== null && val !== undefined;

export const isDefinedNumber = (val: any) => isDefined(val) && Number.isFinite(val);

export const updateListOrderByIdxs = (originalList: any[], order: number[]) =>
    originalList.reduce((acc, item, index) => {
        acc[order[index]] = item;

        return acc;
    }, []);
