import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from '@bynder/localization';
import { Divider, token, Tooltip } from '@bynder/design-system';
import { IconCancel, IconCheck, IconError } from '@bynder/icons';
import {
    getImportedTemplate,
    getImportTemplateErrors,
    getImportTemplateValidationErrors,
} from 'packages/store/importTemplate/importTemplate.selectors';
import { getFormattedTime } from 'packages/pages/components/card/utils';
import { errorKeys } from 'packages/store/importTemplate/types';
import {
    ImportContainer,
    ImportInner,
    IconContainer,
    Title,
    FileImportColumnTitle,
    FileImportListItem,
    FileImportListItemTitle,
    StyledTableTitle,
    StyledTableCell,
    StyledIcon,
} from './Import.styled';

const ImportStatusXML = () => {
    const { translate } = useTranslate();
    const template = useSelector(getImportedTemplate);
    const errors = useSelector(getImportTemplateErrors);
    const validationErrors = useSelector(getImportTemplateValidationErrors);

    const creativeType = () => (template.videoElementCount ? 'VIDEO' : 'IMAGE');
    const getDesignFormat = () =>
        creativeType() === 'VIDEO'
            ? translate('modal.design.create.design.import.file.type.video')
            : translate('modal.design.create.design.import.file.type.image');
    const getFormattedDimensions = () => template.pages.map((size) => `${size.width} × ${size.height} px`).join(', ');

    const getErrorIcon = (error: string) => (
        <Tooltip content={error}>
            <IconError style={{ color: token.red500 }} size="20" />
        </Tooltip>
    );

    const templateName = {
        value: (template && template.name) || (errors && errors.name),
        error: () => {
            if (validationErrors.includes(errorKeys.EMPTY_NAME)) {
                return translate('modal.design.create.design.import.file.empty_name');
            }
            if (validationErrors?.[0]?.includes(errorKeys.TOO_LONG_NAME)) {
                return translate('modal.design.create.design.import.file.too_long_name');
            }
            return '';
        },
    };

    const templateAssets = {
        value: translate('modal.design.create.design.import.file.assets'),
        error: () => {
            if (validationErrors.includes(errorKeys.MAX_ASSETS)) {
                return translate('modal.design.create.design.import.file.max_assets');
            }
            return '';
        },
    };

    const generalDetails = [
        {
            title: translate('modal.design.create.design.import.file.type'),
            value: template && getDesignFormat(),
            error: () => {},
        },
        {
            title: translate('modal.design.create.design.import.file.duration'),
            value: (template && getFormattedTime(template)) || '00:00',
            error: () => {
                if (validationErrors.includes(errorKeys.MIN_DURATION)) {
                    return translate('modal.design.create.design.import.file.min_duration');
                }
                if (validationErrors.includes(errorKeys.MAX_DURATION)) {
                    return translate('modal.design.create.design.import.file.max_duration');
                }
            },
        },
        {
            title: translate('modal.design.create.design.import.file.dimensions'),
            value: template && template.pages && template.pages.length && getFormattedDimensions(),
            error: () => {
                if (typeof validationErrors[0] === 'string' && validationErrors[0] === errorKeys.TOO_LONG_NAME) {
                    return '';
                }
                if (validationErrors.includes(errorKeys.MIN_SIZE)) {
                    return translate('modal.design.create.design.import.file.min_size');
                }
                if (validationErrors.includes(errorKeys.MAX_RESOLUTION)) {
                    return translate('modal.design.create.design.import.file.max_resolution');
                }
                if (validationErrors.includes(errorKeys.ODD_RESOLUTION)) {
                    return translate('modal.design.create.design.import.file.odd_resolution');
                }
                return '';
            },
        },
    ];

    const mediaDetails = [
        {
            title: translate('modal.design.create.design.import.file.images'),
            value: template && template.imageElementCount,
            error: () => {},
        },
        {
            title: translate('modal.design.create.design.import.file.videos'),
            value: template && template.videoElementCount,
            error: () => {},
        },
        {
            title: translate('modal.design.create.design.import.file.texts'),
            value: template && template.textElementCount,
            error: () => {},
        },
    ];

    const generateDetailsRow = (data) =>
        data
            .map(({ title, value, error }) =>
                value || error() ? (
                    <tr key={title}>
                        <StyledTableTitle>
                            <FileImportListItemTitle>{title}</FileImportListItemTitle>
                        </StyledTableTitle>
                        <StyledTableCell>
                            {error() ? (
                                <FileImportListItem thumbnail={getErrorIcon(error())}>{value}</FileImportListItem>
                            ) : (
                                <FileImportListItem>{value}</FileImportListItem>
                            )}
                        </StyledTableCell>
                    </tr>
                ) : null,
            )
            .filter((item) => item);

    const generalRows = generateDetailsRow(generalDetails);
    const mediaRows = generateDetailsRow(mediaDetails);

    return (
        <ImportContainer id="new_design_modal_import_status_xml" data-testid="Import status container">
            <ImportInner>
                <IconContainer data-testid="Import status icon" importStatus={!errors && !validationErrors.length}>
                    {!errors && !validationErrors.length ? <IconCheck /> : <IconCancel />}
                </IconContainer>
                <Title id="new_design_modal_import_status_xml_title" data-testid="Import status title">
                    {templateName.value}
                    {templateName.error() && <StyledIcon>{getErrorIcon(templateName.error())}</StyledIcon>}
                </Title>
                {generalRows.length ? (
                    <>
                        <Divider />
                        <table>
                            <tbody>
                                <th colSpan={2}>
                                    <FileImportColumnTitle>
                                        {translate('modal.design.create.design.import.file.details')}
                                    </FileImportColumnTitle>
                                </th>
                                {generalRows}
                            </tbody>
                        </table>
                    </>
                ) : null}
                {mediaRows.length ? (
                    <>
                        <Divider />
                        <table>
                            <tbody>
                                <th colSpan={2}>
                                    <FileImportColumnTitle>
                                        {templateAssets.value}
                                        {templateAssets.error() && (
                                            <StyledIcon>{getErrorIcon(templateAssets.error())}</StyledIcon>
                                        )}
                                    </FileImportColumnTitle>
                                </th>
                                {mediaRows}
                            </tbody>
                        </table>
                    </>
                ) : null}
            </ImportInner>
        </ImportContainer>
    );
};

export default ImportStatusXML;
