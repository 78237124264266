import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const EditorIcon = styled.div`
    width: 18px;
    height: 18px;
`;

export const EditorIconLarge = styled.div`
    width: 22px;
    height: 22px;
`;

export const LayersListItem = styled.div`
    height: 50px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${token.colorShadow};
    border-left: 5px solid transparent;
    cursor: pointer;

    &.layers__list-item--active {
        background-color: ${token.brandPrimary500};
        border-left-color: ${token.brandPrimary700};
        color: white;
    }

    &.layers__list-item--invalid {
        border-left-color: ${token.red500};
    }

    &.layers__list-item--disabled {
        color: ${token.colorTextDisabled};
        cursor: not-allowed;
    }

    .layers__expand-container {
        width: 12px;
    }

    .layers__icon-expand {
        width: 16px;
        height: 16px;
        transition: 0.2s ease-out;
        margin-top: 6px;
    }

    .layers__icon-expand > svg {
        display: block;
        margin: -3px;
    }

    .layers__expanded {
        transform: rotate(90deg);
    }

    .layers__type-icon {
        margin: 0 12px 0 8px;
    }

    .layers__element-title {
        flex: 1;
    }

    .layers__visibility-toggle {
        margin-right: 8px;
    }
`;
