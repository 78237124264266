import { AnimationOutTypes } from '../../Enums/AnimationOutTypes';
import { Directions } from '../../Enums/Directions';
import { RegionTypes } from '../../Enums/RegionTypes';
import KeyFrameHelper, { KeyFrame } from '../../Helpers/KeyFrames';
import { createCirclePath, createRectanglePath } from '../../Helpers/pathFunctions';
import { Position } from '../Shared/Position';
import { BaseAnimationConfig, BaseAnimationOut } from './BaseAnimation';
import { BaseVisualElement } from '../Elements/BaseVisualElement';
import { BaseCompElement } from '../CompModels/Elements/BaseCompElement';
import { calculateWipe } from './wipeCalculation';

class FadeOutAnimation extends BaseAnimationOut {
    updateCompEl(frameIndex: number, compEl: BaseCompElement): BaseCompElement {
        if (!this.containsFrame(frameIndex) || this.duration <= 1) {
            return compEl;
        }

        const startOpacity = compEl.opacity;
        const finalOpacity = this.config.opacity || 0;
        const newOpacity = this.timingFn(
            frameIndex - this.startFrame,
            startOpacity,
            finalOpacity - startOpacity,
            this.duration - 1,
        );
        compEl.opacity = newOpacity;

        return compEl;
    }
}

class MoveOutAnimation extends BaseAnimationOut {
    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        if (!this.containsFrame(frameIndex) || this.duration <= 1) {
            return compEl;
        }

        const direction = this.config.direction;
        const distance = this.config.distance;
        const mask = this.config.mask;
        const startPosition = compEl.getAbsContentPosition();
        const currentDimension = compEl.contentBox.dimension;
        const canvasDimension = this.el.canvasDimension;
        const finalPosition = new Position(startPosition.getX(), startPosition.getY());

        switch (direction) {
            case Directions.DOWN: {
                finalPosition.setY(
                    startPosition.getY() + (canvasDimension.getHeight() - startPosition.getY()) * distance,
                );
                break;
            }

            case Directions.UP: {
                finalPosition.setY(
                    startPosition.getY() - (currentDimension.getHeight() + startPosition.getY()) * distance,
                );
                break;
            }

            case Directions.LEFT: {
                finalPosition.setX(
                    startPosition.getX() - (currentDimension.getWidth() + startPosition.getX()) * distance,
                );
                break;
            }

            case Directions.RIGHT: {
                finalPosition.setX(
                    startPosition.getX() + (canvasDimension.getWidth() - startPosition.getX()) * distance,
                );
                break;
            }
        }

        const newX = this.timingFn(
            frameIndex - this.startFrame,
            startPosition.getX(),
            finalPosition.getX() - startPosition.getX(),
            this.duration - 1,
        );
        const newY = this.timingFn(
            frameIndex - this.startFrame,
            startPosition.getY(),
            finalPosition.getY() - startPosition.getY(),
            this.duration - 1,
        );

        const translateX = startPosition.getX() - newX;
        const translateY = startPosition.getY() - newY;

        if (mask && mask !== 'false') {
            const contentOffsetX = compEl.contentBox.position.getX();
            const contentOffsetY = compEl.contentBox.position.getY();
            const BBWidth = compEl.boundingBox.dimension.width;
            const BBHeight = compEl.boundingBox.dimension.height;

            switch (direction) {
                case Directions.DOWN:
                    compEl.clipPath = createRectanglePath(
                        0,
                        translateY,
                        BBWidth - contentOffsetX,
                        BBHeight - contentOffsetY,
                    );
                    break;
                case Directions.UP:
                    compEl.clipPath = createRectanglePath(
                        0 - contentOffsetX,
                        translateY - contentOffsetY,
                        BBWidth + contentOffsetX,
                        BBHeight + contentOffsetY,
                    );
                    break;
                case Directions.RIGHT:
                    compEl.clipPath = createRectanglePath(
                        translateX,
                        0,
                        BBWidth - contentOffsetX,
                        BBHeight - contentOffsetY,
                    );
                    break;
                case Directions.LEFT:
                    compEl.clipPath = createRectanglePath(
                        translateX - contentOffsetX,
                        0 - contentOffsetY,
                        BBWidth + contentOffsetX,
                        BBHeight + contentOffsetY,
                    );
                    break;
            }
        }

        // translate to relative box position
        compEl.boundingBox.position.setX(compEl.boundingBox.position.getX() - translateX);
        compEl.boundingBox.position.setY(compEl.boundingBox.position.getY() - translateY);
        compEl.translatedX = translateX;
        compEl.translatedY = translateY;
        compEl.motionBlur = compEl.motionBlur || (this.config.motionBlur ?? false);

        return compEl;
    }
}

class ScaleOutAnimation extends BaseAnimationOut {
    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        if (!this.containsFrame(frameIndex) || this.duration <= 1) {
            return compEl;
        }

        const scaleFrom = compEl.scale;
        const scaleTo = this.config.scale;
        const newScale = this.timingFn(frameIndex - this.startFrame, scaleFrom, scaleTo - scaleFrom, this.duration - 1);
        compEl.scale = newScale;
        // set motion blur if enabled
        compEl.motionBlur = compEl.motionBlur || (this.config.motionBlur ?? false);

        return compEl;
    }
}

class BlurOutAnimation extends BaseAnimationOut {
    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        if (!this.containsFrame(frameIndex) || this.duration <= 1) {
            return compEl;
        }

        const blurFrom = compEl.blur;
        const blurTo = this.config.blur;
        const newBlur = this.timingFn(frameIndex - this.startFrame, blurFrom, blurTo - blurFrom, this.duration - 1);
        compEl.blur = newBlur;

        return compEl;
    }
}

class IrisOutAnimation extends BaseAnimationOut {
    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        if (!this.containsFrame(frameIndex) || this.duration <= 1) {
            return compEl;
        }

        // The transformed region is used to center the effect, while the content is used to determine the radius
        // TODO: this might fail when combined with scaling
        const contentRegion = compEl.getRegion(RegionTypes.TRANSFORMED);
        const regionWidth = contentRegion.getWidth();
        const regionHeight = contentRegion.getHeight();
        // const contentWidth = compEl.contentBox.dimension.getWidth();
        // const contentHeight = compEl.contentBox.dimension.getHeight();
        const ox = contentRegion.left;
        const oy = contentRegion.top;

        const distance = Math.sqrt((regionWidth / 2) ** 2 + (regionHeight / 2) ** 2);
        const newRadius = this.timingFn(frameIndex - this.startFrame, distance, 0 - distance, this.duration - 1);
        compEl.clipPath = createCirclePath(ox + regionWidth / 2, oy + regionHeight / 2, newRadius);
        // set motion blur if enabled
        compEl.motionBlur = compEl.motionBlur || (this.config.motionBlur ?? false);

        return compEl;
    }
}

class WipeOutAnimation extends BaseAnimationOut {
    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        if (!this.containsFrame(frameIndex) || this.duration <= 1) {
            return compEl;
        }

        const direction = this.config.direction;
        const softness = this.config.softness;

        const contentRegion = compEl.getRegion(RegionTypes.TRANSFORMED);
        const w = contentRegion.getWidth();
        const h = contentRegion.getHeight();
        const ox = contentRegion.left;
        const oy = contentRegion.top;

        // Note that we negate this as opposed to WipeIn

        const directionMap = {
            [Directions.UP]: Directions.DOWN,
            [Directions.RIGHT]: Directions.LEFT,
            [Directions.DOWN]: Directions.UP,
            [Directions.LEFT]: Directions.RIGHT,
            [Directions.BOTTOM_LEFT]: Directions.TOP_RIGHT,
            [Directions.BOTTOM_RIGHT]: Directions.TOP_LEFT,
            [Directions.TOP_LEFT]: Directions.BOTTOM_RIGHT,
            [Directions.TOP_RIGHT]: Directions.BOTTOM_LEFT,
        };

        const p = this.timingFn(frameIndex - this.startFrame, 1, -1, this.duration - 1); // get distance percentage
        const params = { ox, oy, w, h, p };
        const { clipPath, clipSoftness } = calculateWipe(directionMap[direction as Directions], softness, params);

        compEl.clipPath = clipPath;
        compEl.clipSoftness = clipSoftness;
        // set motion blur if enabled
        compEl.motionBlur = compEl.motionBlur || (this.config.motionBlur ?? false);

        return compEl;
    }
}

class PopOutAnimation extends BaseAnimationOut {
    // scale to 110% in 20% of time then to 0% in the remaining 80%;
    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        if (!this.containsFrame(frameIndex) || this.duration <= 1) {
            return compEl;
        }

        const scaleFrom = compEl.scale;
        const keyFrameHelper = new KeyFrameHelper([
            new KeyFrame(0.0, Number(scaleFrom)), // scale is 1.0 at 0%
            new KeyFrame(0.2, scaleFrom * 1.1), // scale to 1.1 at 20%
            new KeyFrame(1.0, scaleFrom * 0.0), // scale to 0.0 at 100%
        ]);
        // get percentage of the animation finished
        const p = this.timingFn(frameIndex - this.startFrame, 0, 1, this.duration - 1); // get distance percentage

        // apply percentage to relative keyframes
        const newScale = keyFrameHelper.getValueLinear(p);
        compEl.scale = newScale;
        // set motion blur if enabled
        compEl.motionBlur = compEl.motionBlur || (this.config.motionBlur ?? false);

        return compEl;
    }
}

class FadeMoveOutAnimation extends BaseAnimationOut {
    fadeOutAnimation!: FadeOutAnimation;

    moveOutAnimation!: MoveOutAnimation;

    constructor(config: BaseAnimationConfig, el: BaseVisualElement) {
        super(config, el);
        this.fadeOutAnimation = new FadeOutAnimation(config, el);
        this.moveOutAnimation = new MoveOutAnimation(config, el);
    }

    update(config: BaseAnimationConfig): void {
        super.update(config);
        this.fadeOutAnimation.update(config);
        this.moveOutAnimation.update(config);
    }

    updateCompEl(frameIndex: number, compEl: BaseCompElement): BaseCompElement {
        if (!this.containsFrame(frameIndex) || this.duration <= 1) {
            return compEl;
        }

        compEl = this.fadeOutAnimation.updateCompEl(frameIndex, compEl);
        compEl = this.moveOutAnimation.updateCompEl(frameIndex, compEl);
        // set motion blur if enabled
        compEl.motionBlur = compEl.motionBlur || (this.config.motionBlur ?? false);

        return compEl;
    }
}

class FadeScaleOutAnimation extends BaseAnimationOut {
    fadeOutAnimation!: FadeOutAnimation;

    scaleOutAnimation!: ScaleOutAnimation;

    constructor(config: BaseAnimationConfig, el: BaseVisualElement) {
        super(config, el);
        this.fadeOutAnimation = new FadeOutAnimation(config, el);
        this.scaleOutAnimation = new ScaleOutAnimation(config, el);
    }

    update(config: BaseAnimationConfig): void {
        super.update(config);
        this.fadeOutAnimation.update(config);
        this.scaleOutAnimation.update(config);
    }

    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        if (!this.containsFrame(frameIndex) || this.duration <= 1) {
            return compEl;
        }

        compEl = this.fadeOutAnimation.updateCompEl(frameIndex, compEl);
        compEl = this.scaleOutAnimation.updateCompEl(frameIndex, compEl);
        // set motion blur if enabled
        compEl.motionBlur = compEl.motionBlur || (this.config.motionBlur ?? false);

        return compEl;
    }
}

class FadeBlurOutAnimation extends BaseAnimationOut {
    fadeOutAnimation!: FadeOutAnimation;

    blurOutAnimation!: BlurOutAnimation;

    constructor(config: BaseAnimationConfig, el: BaseVisualElement) {
        super(config, el);
        this.fadeOutAnimation = new FadeOutAnimation(config, el);
        this.blurOutAnimation = new BlurOutAnimation(config, el);
    }

    update(config: BaseAnimationConfig): void {
        super.update(config);
        this.fadeOutAnimation.update(config);
        this.blurOutAnimation.update(config);
    }

    updateCompEl(frameIndex: number, compEl: BaseCompElement): BaseCompElement {
        if (!this.containsFrame(frameIndex) || this.duration <= 1) {
            return compEl;
        }

        compEl = this.fadeOutAnimation.updateCompEl(frameIndex, compEl);
        compEl = this.blurOutAnimation.updateCompEl(frameIndex, compEl);

        return compEl;
    }
}

class EmptyAnimationOut extends BaseAnimationOut {
    updateCompEl(frameIndex: number, compEl: BaseCompElement): BaseCompElement {
        return compEl;
    }
} // ------------------------------------------------------------------------------

export const createAnimationOut = (config: BaseAnimationConfig, el: BaseVisualElement) => {
    if (!el.animationOut) {
        const elementDuration = el.duration;
        const animationInDuration = el.animationIn?.duration ?? 0;
        const freeDuration = elementDuration - animationInDuration;
        config.duration = freeDuration > 0 ? Math.min(freeDuration, config.duration) : config.duration;
    }

    if (config) {
        switch (config.type) {
            case AnimationOutTypes.FADE_OUT:
                return new FadeOutAnimation(config, el);

            case AnimationOutTypes.MOVE_OUT:
                return new MoveOutAnimation(config, el);

            case AnimationOutTypes.FADE_MOVE_OUT:
                return new FadeMoveOutAnimation(config, el);

            case AnimationOutTypes.SCALE_OUT:
                return new ScaleOutAnimation(config, el);

            case AnimationOutTypes.FADE_SCALE_OUT:
                return new FadeScaleOutAnimation(config, el);

            case AnimationOutTypes.BLUR_OUT:
                return new BlurOutAnimation(config, el);

            case AnimationOutTypes.FADE_BLUR_OUT:
                return new FadeBlurOutAnimation(config, el);

            case AnimationOutTypes.IRIS_OUT:
                return new IrisOutAnimation(config, el);

            case AnimationOutTypes.WIPE_OUT:
                return new WipeOutAnimation(config, el);

            case AnimationOutTypes.POP_OUT:
                return new PopOutAnimation(config, el);

            default:
                return new EmptyAnimationOut(config, el);
        }
    }

    return null;
};
