import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from '~/helpers/debounce';
import { fetchTemplates, fetchTemplatesBySearchQuery } from '~/store/templates/templates.actions';
import useQueryParams from 'packages/hooks/useQueryParams';
import { getTemplates } from 'packages/store/templates/templates.selectors';
import { MediaFilterType } from 'packages/pages/components/filters/components/MediaType/types';
import { OrderFilterType, SortingFilterType } from 'packages/pages/components/filters/components/Ordering/types';
import { OwnershipFilterType } from 'packages/pages/components/filters/components/Ownership/types';

const useFilters = () => {
    // if you want to predefine filters value on page load
    // create the filters state here and pass a value as a prop directly to filters
    const dispatch = useDispatch();
    const { setQueryParam } = useQueryParams();

    const { fetchOptions } = useSelector(getTemplates);

    const onSearch = useMemo(
        () =>
            debounce(
                (newSearchValue: string) => {
                    dispatch(fetchTemplatesBySearchQuery(newSearchValue));
                    setQueryParam('search', newSearchValue);
                },
                1000,
                false,
            ),
        [dispatch],
    );

    const onMediaTypeSelect = (type: MediaFilterType) => {
        const newOptions = { ...fetchOptions };

        if (type === 'ANY') {
            newOptions.creativeType = undefined;
        } else {
            newOptions.creativeType = type;
        }

        dispatch(fetchTemplates({ newOptions, setSelected: false, fetchFromStart: true }));

        setQueryParam('typeDesign', type.toLowerCase());
    };

    const onSort = (sortBy: SortingFilterType, sortOrder: OrderFilterType) => {
        const newOptions = { ...fetchOptions };
        newOptions.sort = [sortBy, sortOrder];

        dispatch(fetchTemplates({ newOptions, setSelected: false, fetchFromStart: true }));

        setQueryParam('sortedBy', sortBy);
        setQueryParam('sortOrder', sortOrder);
    };

    const onOwnershipSelect = (ownershipSource: OwnershipFilterType) => {
        const newOptions = { ...fetchOptions };
        newOptions.ownershipSource = ownershipSource;

        dispatch(fetchTemplates({ newOptions, setSelected: false, fetchFromStart: true }));

        setQueryParam('ownership', ownershipSource.toLowerCase());
    };

    return { onSearch, onMediaTypeSelect, onSort, onOwnershipSelect };
};

export default useFilters;
