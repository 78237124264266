import { useCallback } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export const useShortcutsBlocker = (cancelCb = noop) => {
    const handleKeyDown = useCallback(
        (event) => {
            event.preventDefault();
            event.stopPropagation();

            if (event.code === 'Escape') {
                cancelCb();
                document.removeEventListener('keydown', handleKeyDown, true);
            }
        },
        [cancelCb],
    );

    const toggleShortcutsBlocker = (mode: boolean) => {
        if (mode) {
            document.addEventListener('keydown', handleKeyDown, true);
        } else {
            document.removeEventListener('keydown', handleKeyDown, true);
        }
    };

    return { toggleShortcutsBlocker };
};
