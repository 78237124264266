import { createSelector } from 'reselect';

const getCategories = (state) => state.categories.categories;
export const templatesSelector = (state) => state.templates.templates;
export const singleTemplateSelector = (state) => state.templates.template;
export const selectedTemplateIdSelector = (state) => state.templates.selectedTemplateId;
export const categoriesTemplatesSelector = createSelector(getCategories, (categories) =>
    categories.reduce((acc, category) => {
        if (category.templates) {
            acc.push(...category.templates);
        }
        return acc;
    }, []),
);

export const selectedTemplateSelector = createSelector(
    templatesSelector,
    categoriesTemplatesSelector,
    selectedTemplateIdSelector,
    singleTemplateSelector,
    (templates, categoriesTemplates, selectedTemplateId, queriedTemplate) => {
        const listTemplates =
            templates.find((template) => template.id === selectedTemplateId) ||
            categoriesTemplates.find((template) => template.id === selectedTemplateId);

        if (!listTemplates) {
            return queriedTemplate;
        }

        return listTemplates;
    },
);
