import React, { useEffect, useRef } from 'react';
import { ElementUpdateTypes } from '@bynder-studio/render-core';
import { Form, Input } from '@bynder/design-system';
import { IconAngle } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import editorAutocorrect from 'packages/pages/editor/EditorAutocorrectRules';
import useForceUpdate from '~/hooks/useForceUpdate';

const Rotation = ({ creativeModel, selectedElement, disabled }) => {
    const { translate } = useTranslate();
    const { id, locked, rotation } = selectedElement;
    const inputRef = useRef<HTMLInputElement>(null);
    const value = useRef(rotation);
    const forceUpdate = useForceUpdate();

    const setValue = (val) => {
        value.current = val;
        if (inputRef.current) {
            inputRef.current.value = val;
        }
    };

    const applyChanges = (val: number) => {
        const correctValue = editorAutocorrect('element_rotation', val);
        setValue(correctValue);

        if (selectedElement.rotation !== correctValue) creativeModel.updateElement(id, { rotation: correctValue });
    };

    const onBlur = () => {
        applyChanges(value.current);
    };

    const onChange = (newValue) => {
        setValue(newValue);
        forceUpdate();
    };

    const onKeyDown = (event) => {
        switch (event.key) {
            case 'Enter':
                onBlur();
                break;
            case 'ArrowUp':
            case 'ArrowDown':
                event.preventDefault();
                const numberToAdd = event.key === 'ArrowDown' ? -1 : 1;
                const factor = event.shiftKey ? 10 : 1;
                applyChanges(Number(value.current) + numberToAdd * factor);
                break;
        }
    };

    // because value can be changed on the canvas
    useEffect(() => {
        const correctValue = editorAutocorrect('element_rotation', rotation);
        setValue(correctValue);
    }, [id, rotation]);

    useEffect(() => {
        return creativeModel.onElementPartialUpdate(id, ElementUpdateTypes.ROTATION, ({ element }) => {
            setValue(editorAutocorrect('element_rotation', element.rotation));
        });
    }, [creativeModel, id]);

    return (
        <Form.Group>
            <Input
                inputRef={inputRef}
                name={translate('editor.sidebar.shots.rotation.label')}
                aria-label={translate('editor.sidebar.shots.rotation.label')}
                type="number"
                value={value.current}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onChange={onChange}
                isDisabled={locked || disabled}
                icon={<IconAngle />}
                suffix="°"
                data-testid="shots_properties_rotation"
            />
        </Form.Group>
    );
};

export default Rotation;
