import React from 'react';
import { getUnixTime } from 'date-fns';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { notify, Thumbnail, Button } from '@bynder/design-system';
import { IconCheck, IconOpenInNew } from '@bynder/icons';
import { Translate } from '@bynder/localization';
import { setPage } from 'packages/store/newDesign/newDesign.actions';
import { DesignSubPages } from 'packages/pages/designCreateModal/components/Sidebar/types';
import { isAccountRoleAssignedSelector } from 'packages/store/user/user.selectors';
import { moveToCategory } from 'packages/store/categories/categories.actions';
import { fetchCreative } from '~/store/creatives/common.actions';
import DesignsService from 'packages/services/Designs';
import CreativeService from 'packages/services/CreativeService';
import { catchResponseError } from 'packages/helpers/helpers';
import { AMPLITUDE_TYPES } from '~/store/amplitude/constants';
import { sendAmplitudeDesignEvent, sendAmplitudeNewDesignEvent } from '~/store/amplitude/actions';
import CollectionsService from '../../services/CollectionsService';
import roles from '~/configs/roles';
import { sendAppcuesEvent } from '~/helpers/RouteWithAppcues';
import * as types from '../types';
import CreativesService from '../../services/CreativesService';
import { Template } from 'packages/store/templates/types';

export default {
    fetchCreatives,
};

export function selectCreative(creative) {
    return {
        type: types.SELECT_CREATIVE,
        creative,
    };
}

export function updateThumbnails(creativeThumbnails) {
    return {
        type: types.CREATIVES_CHANGE_SOME,
        creativeThumbnails,
    };
}

export function createCreative(
    // todo: fix this type
    template: Template & { fromXML?: boolean; corruptionReason?: any },
    collectionId: string,
) {
    return async (dispatch, getState) => {
        // makes tests pass, but it's not the best solution
        if (!template) {
            return;
        }

        const { fromXML, ...templateData } = template;

        dispatch({ type: types.NEW_CREATIVE_REQUEST });
        dispatch(showLoading());

        const state = getState();
        const customerId = state.user.currentCompany;

        const handleCreate = ({ status, json: creative }) => {
            if (status === 409) {
                // silently ignore conflict
                return;
            }

            if (status >= 400) {
                dispatch({ type: types.CREATE_CREATIVE_FAIL });

                if (creative?.status === 'MISSING_ASSET') {
                    notify({
                        title: <Translate id="modal.design.create.failure.assets" />,
                        variant: 'error',
                    });
                } else {
                    notify({
                        title: <Translate id="modal.design.create.failure" />,
                        variant: 'error',
                    });
                }

                return;
            }

            const origin = fromXML ? 'XML Import' : 'Template';
            const pagePreset = '';

            dispatch({
                type: types.CREATE_CREATIVE_SUCCESS,
                newDesign: creative,
                origin,
                sizePreset: pagePreset,
            });

            dispatch(
                sendAmplitudeNewDesignEvent({
                    eventType: AMPLITUDE_TYPES.CREATE_DESIGN,
                    design: creative,
                    additionalProps: {
                        origin,
                        pagePreset,
                    },
                }),
            );

            notify({
                title: <Translate id="modal.design.create.success" />,
                variant: 'success',
            });

            sendAppcuesEvent('Design created', { id: creative.id, name: creative.name });

            return `/designs/${creative.id}/`;
        };

        const res = await (fromXML
            ? DesignsService.createFromXML(
                  {
                      customerId: Number(customerId),
                      undefined,
                      name: templateData.name,
                      templateId: templateData.id,
                      templateType: templateData.type,
                      creativeType: templateData.creativeType,
                      data: templateData,
                      status: fromXML || !!templateData.corruptionReason ? 'DRAFT' : 'PUBLISHED',
                  },
                  collectionId,
              )
            : CreativeService.create(templateData.id, collectionId)
        ).catch(catchResponseError);

        if (!res) {
            return;
        }

        const newDesignPath = handleCreate(res);

        dispatch(hideLoading());

        if (!newDesignPath) {
            return;
        }

        if (
            isAccountRoleAssignedSelector(state)(roles.video_brand_studio.handler.admin) ||
            isAccountRoleAssignedSelector(state)(roles.video_brand_studio.template.manage)
        ) {
            dispatch(setPage(DesignSubPages.ALL));
        } else {
            dispatch(setPage(DesignSubPages.CATEGORIES));
        }

        return newDesignPath;
    };
}

export function handleToggleAll() {
    sendAppcuesEvent('All designs selected', {});

    return {
        type: types.TOGGLE_CREATIVES_ALL,
    };
}

export function handleDeselectAll() {
    sendAppcuesEvent('All designs deselected', {});

    return {
        type: types.DESELECT_CREATIVES_ALL,
    };
}

export function handleToggleItem(id, allChecked) {
    sendAppcuesEvent('Design selected/deselected/toggled', { id });

    return {
        type: types.TOGGLE_CREATIVES_ITEM,
        id,
        allChecked,
    };
}

export function eraseCreativeData() {
    return {
        type: types.SET_CREATIVE,
        creative: null,
        dynamicFields: null,
    };
}

export function fetchCreatives(showLoadingBar = true) {
    return (dispatch, getState) => {
        const state = getState();
        const customerId = state.user.currentCompany;
        const { sort, size, filter, ownershipSource } = state.creatives.options;
        const page = 0;
        const options = { sort, page, size, filter, ownershipSource };
        dispatch({ type: types.GET_CREATIVES_REQUEST, reloadCreatives: true });
        dispatch(showLoading());
        CreativesService.fetchCreatives(customerId, options)
            .then(({ status, json }: { status: number; json: any }) => {
                dispatch(hideLoading());

                if (status === 200) {
                    const { items: creatives, totalItems: totalCount } = json;
                    dispatch({ type: types.GET_CREATIVES_SUCCESS, creatives, totalCount, options });
                } else {
                    notify({
                        title: 'Oops! Something went wrong. Please refresh your page.',
                        variant: 'error',
                    });
                }
            })
            .catch(catchResponseError);
    };
}

export function fetchCreativesByOptions(options, clearData = true) {
    return (dispatch, getState) => {
        const searchTime = getUnixTime(new Date(Date.now()));

        if (options && getState().creatives.options.search !== options.search) {
            dispatch({
                type: types.GET_CREATIVES_REQUEST,
                searching: true,
                searchTime,
            });
        } else {
            dispatch({ type: types.GET_CREATIVES_REQUEST, searching: false });
        }

        const customerId = getState().user.currentCompany;

        if (clearData) {
            options.page = 0;
        }

        return CreativesService.fetchCreatives(customerId, options)
            .then(({ status, json }: { status: number; json: any }) => {
                if (status === 200) {
                    const { items: creatives, totalItems: totalCount } = json;

                    if (
                        getState().creatives.searching &&
                        getState().creatives.options.searchTime &&
                        getState().creatives.options.searchTime > searchTime
                    ) {
                        return;
                    }

                    dispatch({ type: types.GET_CREATIVES_SUCCESS, creatives, options, clearData, totalCount });
                }
            })
            .catch(catchResponseError);
    };
}

// because we get options within this action creator,
// we can memoize and debounce callbacks which use it
export function fetchCreativesBySearchQuery(searchQuery: string) {
    return (dispatch, getState) => {
        const options = { ...getState().creatives.options, search: searchQuery };

        dispatch(fetchCreativesByOptions(options));
    };
}

export function generateVideos(rows) {
    return (dispatch, getState) => {
        const { id } = getState().creatives.creative;
        CreativesService.generateVideos({ id, rows })
            .then(({ status }) => {
                if (status === 200) {
                    dispatch({ type: types.GENERATE_VIDEOS_SUCCESS });
                    sendAppcuesEvent('Designs video generated', { id });
                } else {
                    notify({
                        title: 'Failed to generate',
                        variant: 'error',
                    });
                }
            })
            .catch(catchResponseError);
    };
}

export function generateUnmount() {
    return { type: types.GENERATE_UNMOUNT };
}

export function pendingDesignSavingRequest(isPending) {
    return { type: types.PENDING_REQUESTS, pendingDesignSavingRequest: isPending };
}

export function handleDeleteModal(show) {
    return {
        type: types.CREATIVE_DELETE_MODAL_TOGGLE,
        show,
    };
}

export function deleteCreatives(ids) {
    return (dispatch, getState) => {
        const state = getState();
        const { creatives } = state.creatives;

        const filteredItems = creatives.filter((element) => ids.includes(element.id));
        const videoTypeArray = filteredItems.filter((element) => element.creativeType === 'VIDEO');
        dispatch({ type: types.DELETE_CREATIVES_IN_PROGRESS, ids });
        CreativesService.deleteCreatives(ids)
            .then(({ status }) => {
                if (status === 200) {
                    dispatch({ type: types.DELETE_CREATIVES_SUCCESS, ids });
                    dispatch(fetchCreativesByOptions(getState().creatives.options, true));
                    notify({
                        title: <Translate id="modal.design.delete.success" count={ids.length} />,
                        variant: 'success',
                    });
                    sendAppcuesEvent('Designs removed', { ids });
                } else {
                    dispatch({ type: types.DELETE_CREATIVES_FAIL, ids });
                    notify({
                        title: <Translate id="modal.design.delete.failure" count={videoTypeArray.length} />,
                        variant: 'error',
                    });
                }
            })
            .catch(catchResponseError);
    };
}

export function duplicateCreatives(ids, collectionId, creativesCopyInfo = []) {
    return (dispatch, getState) => {
        dispatch({ type: types.DUPLICATE_CREATIVES_IN_PROGRESS, ids, creativesCopyInfo });
        DesignsService.duplicateCreatives(ids, collectionId)
            .then(({ status, json }) => {
                if (status === 200) {
                    const { items: creatives } = json;
                    dispatch({ type: types.DUPLICATE_CREATIVES_SUCCESS, ids });
                    dispatch(
                        sendAmplitudeDesignEvent({
                            eventType: AMPLITUDE_TYPES.DUPLICATE_DESIGN,
                            design: creatives[0],
                        }),
                    );
                    dispatch(fetchCreativesByOptions(getState().creatives.options, true));

                    if (collectionId) {
                        CollectionsService.getCollection(collectionId)
                            .then(({ status, collection }) => {
                                if (status === 200) {
                                    dispatch({
                                        type: types.COLLECTION_SUCCESS_FETCH,
                                        collection,
                                    });
                                } else {
                                    dispatch({ type: types.COLLECTION_FAILURE_FETCH });
                                }
                            })
                            .catch(catchResponseError);
                    }

                    notify({
                        title: <Translate id="modal.design.duplicate.success" />,
                        variant: 'success',
                    });
                    sendAppcuesEvent('Designs duplicated', { ids });
                } else {
                    dispatch({ type: types.DUPLICATE_CREATIVES_FAIL, ids });
                    notify({
                        title: <Translate id="modal.design.duplicate.failure" />,
                        variant: 'error',
                    });
                }
            })
            .catch(catchResponseError);
    };
}

export function init(params = {}) {
    return (dispatch, getState) => {
        dispatch({ type: types.SET_CREATIVES_DEFAULTS });

        const options = { ...getState().creatives.options, ...params };
        dispatch(fetchCreativesByOptions(options, true));
    };
}

export function designsGhostCards(placeholderDesigns) {
    return (dispatch, getState) => {
        dispatch({ type: types.IMPORT_CREATIVES_GHOST_CARDS, placeholderDesigns });
    };
}

export function saveAsTemplate({
    name,
    description,
    categoryId = null,
}: {
    name: string;
    description: string;
    categoryId?: number | null;
}) {
    return async (dispatch, getState) => {
        const state = getState();
        const { id: creativeId } = state.creatives.creative;

        const res = await CreativeService.saveAsTemplate({ id: creativeId, name, description }).catch(
            catchResponseError,
        );

        if (!res) {
            return;
        }

        if (res.status >= 400) {
            notify({
                title: 'Fail to save',
                variant: 'error',
            });

            return;
        }

        const { id: templateId } = res.json as { id: number };

        dispatch(sendAmplitudeDesignEvent({ eventType: AMPLITUDE_TYPES.SAVE_AS_TEMPLATE }));

        if (categoryId) {
            dispatch(
                moveToCategory({
                    categoryId,
                    templates: [templateId],
                    type: 'CREATIVE',
                    isOverview: false,
                    isEditor: true,
                    silent: true,
                }),
            );
        }

        notify({
            title: 'Design saved as template.',
            variant: 'success',
        });

        sendAppcuesEvent('Designs saved as template', { id: creativeId, name });
    };
}

export function saveAsVideo(creativeName, navigateToDesign: (designId) => void, onError) {
    return (dispatch, getState) => {
        const store = getState();
        const { id } = store.creatives.creative;

        DesignsService.createVideoCreative({ creativeName, creativeId: id })
            .then(({ json, status }) => {
                if (status === 200) {
                    notify({
                        title: 'Successfully created new video design',
                        thumbnail: (
                            <Thumbnail
                                variant="clean"
                                shape="circle"
                                backgroundColor="green500"
                                icon={<IconCheck />}
                                iconColor="white"
                            />
                        ),
                        button: (
                            <Button
                                variant="clean"
                                icon={<IconOpenInNew />}
                                onClick={() => navigateToDesign(json.id)}
                                title="Open"
                            >
                                Open
                            </Button>
                        ),
                    });
                    sendAppcuesEvent('Designs saved as video', { id, name: creativeName });
                } else {
                    onError(json);
                }
            })
            .catch(catchResponseError);
    };
}

export function editCreativeName(id, name, inEditor = false) {
    return (dispatch, getState) => {
        dispatch({ type: types.RENAME_CREATIVE_IN_PROGRESS, id });
        CreativesService.renameCreativeOrTemplate(id, { name })
            .then(({ status }) => {
                if (status === 200) {
                    dispatch({ type: types.RENAME_CREATIVE_SUCCESS, id });

                    if (inEditor) {
                        dispatch(fetchCreative(id, inEditor));
                    } else {
                        dispatch(fetchCreativesByOptions(getState().creatives.options, true));
                    }

                    notify({
                        title: <Translate id="modal.design.rename.success" />,
                        variant: 'success',
                    });
                    sendAppcuesEvent('Designs renamed', { id, name });
                } else {
                    dispatch({ type: types.RENAME_CREATIVE_FAILURE, id });
                    notify({
                        title: <Translate id="modal.design.rename.failure" />,
                        variant: 'error',
                    });
                }
            })
            .catch(catchResponseError);
    };
}
