export enum ElementTypes {
    NAME = 'NAME',
    TEXT = 'TEXT',
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
    SHAPE = 'SHAPE',
    GROUP = 'GROUP',
    BACKGROUND_COLOR = 'BACKGROUND_COLOR',
    GLOBAL_AUDIO = 'GLOBAL_AUDIO',
    POSTER_FRAME = 'POSTER_FRAME',
}
