import React from 'react';
import { useResizeStats, useResizing } from '../editor/timeline/tracks/dragging/useResizing';
import { ResizeStats } from '../editor/timeline/tracks/resize-stats/ResizeStats';
import { TransitionControlStyled } from './TransitionControl.styled';

type Props = {
    label: string;
    onChange: (params: any) => void;
    onChangeEnd: (params: any) => void;
    elementRef: any;
    containerRef: any;
    startControl: boolean;
    endControl: boolean;
    disabled: boolean;
};

const className = 'TransitionControl';

const TransitionControl = ({
    label,
    onChange,
    onChangeEnd,
    elementRef,
    containerRef,
    style,
    elementDuration,
    duration,
    startControl,
    endControl,
    disabled,
    color,
    backgroundColor,
}: Props) => {
    const [resizeStatsRef, onStatsResize, onStatsResizeEnd] = useResizeStats(elementDuration, duration);

    const onResize = (
        fromRight,
        elementLeft,
        elementWidth,
        leftPercentage,
        widthPercentage,
        containerBoundingBox,
        boundingBox,
    ) => {
        onChange && onChange({ fromRight, elementLeft, elementWidth, leftPercentage, widthPercentage });
        onStatsResize(
            null,
            fromRight,
            elementLeft,
            elementWidth,
            leftPercentage,
            widthPercentage,
            containerBoundingBox,
            boundingBox,
        );
    };

    const onResizeEnd = (leftPercentage, widthPercentage) => {
        onChangeEnd && onChangeEnd({ leftPercentage, widthPercentage });
        onStatsResizeEnd();
    };

    const [onResizeMouseDown] = useResizing(elementRef, containerRef, onResize, onResizeEnd, undefined, {
        minLeft: 0,
        minRight: 0,
    });

    const classNames = [];
    startControl && classNames.push('TransitionControl--withStartControl');
    endControl && classNames.push('TransitionControl--withEndControl');
    disabled && classNames.push('TransitionControl--disabled');

    const onStartMouseDown = (event) => !disabled && startControl && onResizeMouseDown(event, 'left');
    const onEndMouseDown = (event) => !disabled && endControl && onResizeMouseDown(event, 'right');

    return (
        <TransitionControlStyled
            className={classNames.join(' ')}
            ref={elementRef}
            style={{ ...style, color, backgroundColor }}
        >
            <div className={`${className}-controls`}>
                <div
                    className={`${className}-resizer-left ${className}-resizer-${startControl ? 'kube' : 'circle'}`}
                    onMouseDown={onStartMouseDown}
                />
                <div className={`${className}-line`} />
                <div
                    className={`${className}-resizer-right ${className}-resizer-${endControl ? 'kube' : 'circle'}`}
                    onMouseDown={onEndMouseDown}
                />
            </div>
            <div className={`${className}-label`}>{label}</div>
            <ResizeStats resizeStatsRef={resizeStatsRef} />
        </TransitionControlStyled>
    );
};

TransitionControl.defaultProps = {
    startControl: true,
    endControl: true,
};

export default TransitionControl;
