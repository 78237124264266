import type { ColorParams } from '@bynder-studio/misc';
import { ElementUpdateTypes } from '../../Enums/ElementUpdateTypes';
import type { IAsset } from '../Assets/IAsset';
import { Box } from '../Shared/Box';
import type { BaseVisualElementParams } from './BaseVisualElement';
import { BaseVisualElement } from './BaseVisualElement';
import { ElementUpdateOptions, IElement } from './IElement';
import { TimelineBehavior } from '../../Enums/TimelineBehavior';
import { LineCapTypes } from '../../Enums/LineCapTypes';
import { ShapeTypes } from '../../Enums/ShapeTypes';
import { createEllipsePath, createRoundedRectanglePath } from '../../Helpers/pathFunctions';
import { BorderAlignmentTypes } from '../../Enums/BorderAlignmentTypes';
import { Position } from '../Shared/Position';
import { Dimension } from '../Shared/Dimension';
import { ShapeCompElement } from '../CompModels/Elements/ShapeCompElement';
import pathstuff from '../../Helpers/pathstuff';
import type { CreativeTypes } from '../../Enums/CreativeTypes';
import { PreviewTypes } from '../../Enums/PreviewTypes';

export type ShapeElementParams = BaseVisualElementParams & {
    shapeType: ShapeTypes;
    path?: string;
    fillColor?: ColorParams | null;
    borderColor?: ColorParams | null;
    borderWidth?: number | null;
    borderAlignment?: BorderAlignmentTypes;
    borderLineCap?: LineCapTypes | null;
    borderRadius?: number;
    timelineBehavior?: TimelineBehavior;
    fillBrandColors?: number[];
    borderBrandColors?: number[];
};

export class ShapeElement extends BaseVisualElement implements IElement {
    path = '';

    shapeType: ShapeTypes = ShapeTypes.RECTANGLE;

    fillColor?: ColorParams | null = null;

    borderColor?: ColorParams | null = null;

    borderWidth?: number | null = null;

    borderLineCap?: LineCapTypes | null = null;

    borderAlignment: BorderAlignmentTypes = BorderAlignmentTypes.MIDDLE;

    borderRadius = 0;

    timelineBehavior: TimelineBehavior = TimelineBehavior.AUTO;

    fillBrandColors: number[] = [];

    borderBrandColors: number[] = [];

    // Keep track of original width and height in case of custom path
    naturalPathWidth = 0;

    naturalPathHeight = 0;

    constructor(params: ShapeElementParams) {
        super();
        this.setProperties(params);
    }

    setProperties(params: ShapeElementParams): Set<ElementUpdateTypes> {
        const updateTypes: Set<ElementUpdateTypes> = super.setProperties(params);

        if (params.fillColor !== undefined) {
            this.fillColor = params.fillColor;
            updateTypes.add(ElementUpdateTypes.FILL_COLOR);
        }

        if (params.borderColor !== undefined) {
            this.borderColor = params.borderColor;
            updateTypes.add(ElementUpdateTypes.BORDER_COLOR);
        }

        if (params.borderWidth !== undefined) {
            this.borderWidth = params.borderWidth;
            updateTypes.add(ElementUpdateTypes.BORDER_WIDTH);
        }

        if (params.borderAlignment !== undefined) {
            this.borderAlignment = params.borderAlignment;
            updateTypes.add(ElementUpdateTypes.BORDER_ALIGNMENT);
        }

        if (params.borderLineCap !== undefined) {
            this.borderLineCap = params.borderLineCap;
            updateTypes.add(ElementUpdateTypes.BORDER_LINE_CAP);
        }

        if (params.borderRadius !== undefined) {
            this.borderRadius = Math.max(Number(params.borderRadius) || 0, 0);
            updateTypes.add(ElementUpdateTypes.BORDER_RADIUS);
        }

        if (params.shapeType !== undefined) {
            this.shapeType = params.shapeType;
            updateTypes.add(ElementUpdateTypes.SHAPE);
        }

        if (
            params.timelineBehavior !== undefined &&
            this.timelineBehavior !== params.timelineBehavior &&
            Object.values(TimelineBehavior).includes(params.timelineBehavior)
        ) {
            this.timelineBehavior = params.timelineBehavior || TimelineBehavior.AUTO;
            updateTypes.add(ElementUpdateTypes.TIMELINE_BEHAVIOR);
        }

        if (
            this.shapeType !== ShapeTypes.CUSTOM &&
            (updateTypes.has(ElementUpdateTypes.SHAPE) ||
                updateTypes.has(ElementUpdateTypes.DIMENSION) ||
                updateTypes.has(ElementUpdateTypes.BORDER_RADIUS) ||
                updateTypes.has(ElementUpdateTypes.BORDER_ALIGNMENT))
        ) {
            this.path = this.createPath();
            updateTypes.add(ElementUpdateTypes.SOURCE);
        }

        if (this.shapeType === ShapeTypes.CUSTOM && params.path !== undefined) {
            this.path = params.path;

            const bbox = pathstuff.getBBox(this.path);
            this.naturalPathWidth = bbox.width;
            this.naturalPathHeight = bbox.height;

            updateTypes.add(ElementUpdateTypes.SOURCE);
        }

        this.fillBrandColors = params.fillBrandColors ?? this.fillBrandColors;
        this.borderBrandColors = params.borderBrandColors ?? this.borderBrandColors;

        return updateTypes;
    }

    update(params: ShapeElementParams, options?: ElementUpdateOptions): Set<ElementUpdateTypes> {
        return super.update(params, options);
    }

    createPath(): string {
        const w = this.dimension.getWidth();
        const h = this.dimension.getHeight();
        const bw = Math.min(this.borderRadius || 0, w / 2, h / 2);

        // Path position is relative to content box, make sure we correctly offset the path based on border type
        let borderOverflow = 0;

        if (this.borderWidth && this.borderAlignment == BorderAlignmentTypes.MIDDLE) {
            borderOverflow = Math.ceil(this.borderWidth / 2);
        } else if (this.borderWidth && this.borderAlignment == BorderAlignmentTypes.OUTSIDE) {
            borderOverflow = this.borderWidth;
        }

        if (this.shapeType === ShapeTypes.ELLIPSE) {
            return createEllipsePath(borderOverflow, borderOverflow, w / 2, h / 2, w / 2, h / 2);
        } else if (this.shapeType === ShapeTypes.RECTANGLE) {
            return createRoundedRectanglePath(borderOverflow, borderOverflow, w, h, bw);
        }

        if (!this.naturalPathWidth || !this.naturalPathHeight) {
            return '';
        }

        const path = pathstuff.scale(
            this.dimension.width / this.naturalPathWidth,
            this.dimension.height / this.naturalPathHeight,
        )(this.path);

        if (borderOverflow) {
            return pathstuff.translate(borderOverflow, borderOverflow)(path);
        }

        return path;
    }

    getCompElement(frameIndex: number) {
        const compEl = new ShapeCompElement();

        compEl.originalElement = this;
        compEl.id = this.id;
        compEl.hidden = this.hidden;
        compEl.renderOrder = this.renderOrder;
        compEl.opacity = this.opacity;
        compEl.rotation = this.rotation;
        compEl.scale = this.scale;
        compEl.dropShadow = this.dropShadow;
        compEl.mask = this.mask;
        compEl.blendMode = this.blendMode;
        compEl.isAssetLoading = false;
        compEl.path = this.createPath(); // TODO: this temporarily fixes the issue when increasing border width size
        compEl.color = this.fillColor;
        compEl.borderColor = this.borderColor;
        compEl.borderWidth = this.borderWidth;
        compEl.borderAlignment = this.borderAlignment;
        compEl.borderLineCap = this.borderLineCap;
        compEl.boundingBox = new Box(this.position.getCopy(), this.dimension.getCopy());

        const borderWidth = this.borderWidth || 0;

        if (borderWidth && this.borderAlignment == BorderAlignmentTypes.MIDDLE) {
            const borderOverflow = Math.ceil(borderWidth / 2);
            compEl.contentBox = new Box(
                new Position(-borderOverflow, -borderOverflow),
                new Dimension(
                    this.dimension.getWidth() + borderOverflow * 2,
                    this.dimension.getHeight() + borderOverflow * 2,
                ),
            );
        } else if (borderWidth && this.borderAlignment == BorderAlignmentTypes.OUTSIDE) {
            const borderOverflow = borderWidth;
            compEl.contentBox = new Box(
                new Position(-borderOverflow, -borderOverflow),
                new Dimension(
                    this.dimension.getWidth() + borderOverflow * 2,
                    this.dimension.getHeight() + borderOverflow * 2,
                ),
            );
        } else {
            compEl.contentBox = new Box(new Position(0, 0), this.dimension.getCopy());
        }

        compEl.pathPosition = new Position(0, 0);

        return this.applyAnimations(frameIndex, compEl);
    }

    isContainsAsset(asset: IAsset): boolean {
        return false;
    }

    getValidationRules(creativeType: CreativeTypes, previewType: PreviewTypes) {
        const rules = super.getValidationRules(creativeType, previewType);

        if (previewType === PreviewTypes.CONTENT) {
            return rules;
        }

        return {
            ...rules,
            shape: {
                REQUIRED: true,
            },
            // borderWidth: {
            // LESS_THAN: Math.min(this.dimension.getWidth() / 2, this.dimension.getHeight() / 2) + 1,
            // },
        };
    }

    cleanupEmittingValues(values: Record<string, any>) {
        delete values.fillBrandColors;
        delete values.borderBrandColors;
        super.cleanupEmittingValues(values);
    }

    toObject() {
        return {
            ...super.toObject(),
            shapeType: this.shapeType,
            path: this.path,
            fillColor: this.fillColor,
            borderColor: this.borderColor,
            borderWidth: this.borderWidth,
            borderAlignment: this.borderAlignment,
            borderLineCap: this.borderLineCap,
            borderRadius: this.borderRadius,
            timelineBehavior: this.timelineBehavior,
            fillBrandColors: this.fillBrandColors,
            borderBrandColors: this.borderBrandColors,
        };
    }
}
