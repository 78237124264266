import React, { useCallback, useMemo, Suspense } from 'react';
import { lazyPrefetching } from 'packages/helpers/helpers';
import Duration from 'packages/common/Duration';
import { DurationModes } from 'packages/common/Duration/useDuration';
import { millisecondToFrames } from '../editorHelper';
import Loader from '../../loader/Loader';
import {
    PortionSelectorPlayer,
    PortionSelectorContainer,
    PortionSelectorPlayerWrapper,
} from './PortionSelector.styled';

export const SimplePlayer = lazyPrefetching(() =>
    import(
        /* webpackChunkName: "videojs" */
        /* webpackPrefetch: true */
        '../../player/Simple'
    ),
);

// TODO: Get rid of classNames and their files
const PortionSelector = ({
    elementId,
    elementDuration,
    duration,
    offsetTime,
    resizableRange,
    frameRate,
    handleOnChange,
    url,
    type,
    isEditorPage,
}) => {
    const changeRange = useCallback(
        (leftPercentage, widthPercentage) => {
            const durationInSeconds = duration / 1000;
            const newOffsetTime = Math.round((frameRate * durationInSeconds * leftPercentage) / 100);
            let newElementDuration = null;

            if (resizableRange && widthPercentage) {
                const maxDuration = durationInSeconds * frameRate - newOffsetTime;
                const calculatedDuration = Math.round((frameRate * durationInSeconds * widthPercentage) / 100);

                newElementDuration = Math.min(calculatedDuration, maxDuration);
            }

            handleOnChange(newOffsetTime, newElementDuration);
        },
        [duration, frameRate, handleOnChange, resizableRange],
    );

    const elementDurationInFrames = useMemo(
        () => millisecondToFrames(elementDuration, frameRate),
        [elementDuration, frameRate],
    );
    const offsetInFrames = useMemo(() => millisecondToFrames(offsetTime, frameRate), [offsetTime, frameRate]);
    const durationInFrames = useMemo(() => millisecondToFrames(duration, frameRate), [duration, frameRate]);

    if (!url) return null;

    const isFileLongerThanAsset = elementDuration > duration;
    const actionsDisabled = isFileLongerThanAsset && !isEditorPage;

    // NOTE: This calculation needs to be done in FRAMES!
    // it's necessary because of the floor in the convertation
    const calculatedWidth = (elementDurationInFrames / durationInFrames) * 100;
    const calculatedOffset = (offsetInFrames / durationInFrames) * 100;
    const leftPercentage = calculatedOffset >= 100 ? 0 : calculatedOffset;
    const widthPercentage = isFileLongerThanAsset
        ? 100 - leftPercentage
        : calculatedWidth <= 100
        ? calculatedWidth
        : 100; // this consitions refactor later

    const start = offsetTime;
    const end = isFileLongerThanAsset ? start + duration : start + elementDuration;

    if (!duration) {
        return (
            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                <Loader size={40} />
            </div>
        );
    }

    return (
        <>
            <PortionSelectorPlayer className={`text-center ${!duration ? `d-none` : ``}`}>
                <Suspense fallback={<div>Loading...</div>}>
                    <SimplePlayer
                        src={url}
                        type={type}
                        value={{ min: start, max: end }}
                        frameRate={frameRate}
                        controls
                    />
                </Suspense>
            </PortionSelectorPlayer>
            <PortionSelectorContainer>
                <PortionSelectorPlayerWrapper />
                {duration > 0 && (
                    <Duration
                        elementId={elementId}
                        actionsDisabled={actionsDisabled}
                        isResizeDisabled={actionsDisabled}
                        start={offsetInFrames}
                        end={millisecondToFrames(end, frameRate)}
                        duration={isFileLongerThanAsset ? durationInFrames - offsetInFrames : elementDurationInFrames}
                        mode={resizableRange ? DurationModes.TRIM : DurationModes.SLIP}
                        onChange={changeRange}
                        fullDuration={duration}
                        elementDuration={elementDuration}
                        widthPercentageDefault={widthPercentage}
                        leftPercentageDefault={leftPercentage}
                        frameRate={frameRate}
                        isEditorPage={isEditorPage}
                    />
                )}
            </PortionSelectorContainer>
        </>
    );
};

export default PortionSelector;
