import React from 'react';
import useEditorSelectedElement from '~/hooks/useEditorSelectedElement';
import Shots from './Shots';
import Globals from './Globals';
import generateTestId from '~/helpers/testIdHelpers';

const testId = generateTestId('editor_sidebar');

const RightSideMenu = () => {
    const { selectedElement, selectedElements, topLevelSelectedElements } = useEditorSelectedElement();
    const isMultiSelection = topLevelSelectedElements?.length > 1;

    return (
        <div {...testId}>
            {selectedElement || isMultiSelection ? (
                <Shots
                    isMultiSelection={isMultiSelection}
                    selectedElement={selectedElement}
                    selectedElements={selectedElements}
                />
            ) : (
                <Globals />
            )}
        </div>
    );
};

export default RightSideMenu;
