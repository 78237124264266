import React, { useState } from 'react';
import CreateModal from './components/modals/CreateModal';

export function useContentPropertyCreateModal({ onCreate, elementName }) {
    const [isOpen, setIsModalOpen] = useState(false);

    const renderContentPropertyCreateModal = () => {
        return (
            <CreateModal
                elementName={elementName}
                isOpen={isOpen}
                onClose={() => setIsModalOpen(false)}
                onCreate={onCreate}
            />
        );
    };

    return {
        renderContentPropertyCreateModal,
        setIsContentPropertyCreateModalOpen: setIsModalOpen,
    };
}
