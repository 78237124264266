import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getUser as getUserSelector } from 'packages/store/user/user.selectors';
import AuthenticationHelper from '~/helpers/AuthenticationHelper';

export const usePageLoader = () => {
    const { user } = useSelector(getUserSelector);
    const hasToken = AuthenticationHelper.isLoggedIn();
    const location = useLocation();
    let shouldShowLoader = true;

    const isRestrictedPath =
        location.pathname !== '/' &&
        (location.pathname !== '/designs' || (location.pathname === '/designs' && location.search !== '')) &&
        location.pathname !== '/shared';

    if (!user && !hasToken && isRestrictedPath) {
        shouldShowLoader = true;
    } else if (!user && hasToken && isRestrictedPath) {
        shouldShowLoader = true;
    } else {
        shouldShowLoader = false;
    }

    return { shouldShowLoader };
};
