import { flattenTree } from '../../../helpers/elementtree';

export const collectTopLevelGroups = (elements) =>
    elements
        .filter((element) => !!element.children)
        .filter((group) => !group.parent || !elements.includes(group.parent));

export const collectSingleTopLevelGroup = (elements) => {
    const elementOutOfGroups = elements
        .filter((element) => !element.parent || !elements.includes(element.parent))
        .filter((element) => !element.children);
    const groups = collectTopLevelGroups(elements);

    if (elementOutOfGroups.length || groups.length !== 1) {
        return null;
    }

    return groups[0];
};

export const collectTopLevelElements = (elements) =>
    elements.filter((group) => !group.parent || !elements.includes(group.parent));

export const isElementLocked = (element, recursive = true) => {
    if (!!element.children && recursive) {
        const isChildrenLocked = Object.values(flattenTree(element.children, true)).map(isElementLocked).every(Boolean);

        return isChildrenLocked && isElementLocked(element, false);
    } else {
        return !!element.locked;
    }
};
