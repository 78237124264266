import React, { useCallback } from 'react';
import { Tooltip, Button } from '@bynder/design-system';
import { IconDelete } from '@bynder/icons';
import { REMOVE_FROM_ACTIVITY_LOG } from '~/helpers/textConstants';

import ExportItemSubtitleBar from './ExportItemSubtitleBar';
import { ExportItemActionsWrapper, ExportItemRow, ExportItemRowWrapper } from './AllExports.styled';

const FailedJobRow = ({ job, onDelete }) => {
    const count = job.jobVariations?.length || 0;

    const handleDelete = useCallback(() => {
        onDelete(job.id);
    }, [job, onDelete]);

    return (
        <ExportItemRowWrapper className="w-100">
            <ExportItemRow justifyContent="space-between" hoverDisabled>
                <ExportItemSubtitleBar
                    jobType={job.type.toLowerCase()}
                    statusText="Something went wrong"
                    count={count}
                    name={job.name}
                    isFailed
                />
                <ExportItemActionsWrapper>
                    <Tooltip content={REMOVE_FROM_ACTIVITY_LOG} position="top-left">
                        <Button
                            title={REMOVE_FROM_ACTIVITY_LOG}
                            variant="clean"
                            onClick={handleDelete}
                            icon={<IconDelete />}
                        />
                    </Tooltip>
                </ExportItemActionsWrapper>
            </ExportItemRow>
        </ExportItemRowWrapper>
    );
};

export default FailedJobRow;
