import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@bynder/design-system';
import useFigmaConnection from './useFigmaConnection';
import FigmaConnection from './FigmaConnection';
import useRevokeAccessModal from './useRevokeAccessModal';
import useFigmaFiles from './useFigmaFiles';
import FigmaLinkInput from './FigmaLinkInput';
import FigmaFile from './FigmaFile';
import useFigmaFramesSelection from './useFrameSelection';
import { dropImportedTemplate } from 'packages/store/importTemplate/importTemplate.actions';
import { initializeTemplates } from '~/store/templates/templates.actions';
import { initializeGallery } from '~/store/gallery/gallery.actions';
import { deselectBlankType } from 'packages/store/newDesign/newDesign.actions';

export default function FigmaImportPage() {
    const {
        connection,
        fetchConnectionStatus,
        login,
        revokeAccess,
        switchAccount,
        isLoading: isConnectionLoading,
    } = useFigmaConnection();
    const { setRevokeAccessModalOpen, renderRevokeAccessModal } = useRevokeAccessModal(revokeAccess);
    const {
        value: file,
        fetchFile,
        links,
        clearLinks,
        cancelRequest,
    } = useFigmaFiles(connection?.connected ? connection.email : '');
    const { selectedPageId, selectPage, selectedFramesIds, toggleFrame, renderLimitReachedModal } =
        useFigmaFramesSelection(file.status === 'success' ? file.value : undefined);

    const dispatch = useDispatch();

    useLayoutEffect(() => {
        dispatch(dropImportedTemplate());
        dispatch(initializeTemplates());
        dispatch(initializeGallery());
        dispatch(deselectBlankType());
    }, [dispatch]);

    useEffect(() => {
        if (file.status === 'error' && file.cause === 'not authorized') {
            fetchConnectionStatus();
        }
    }, [file, fetchConnectionStatus]);

    return (
        <>
            <Box paddingBlock="4">
                <FigmaConnection
                    connection={connection}
                    login={login}
                    isLoading={isConnectionLoading}
                    switchAccount={switchAccount}
                    setRevokeAccessModalOpen={setRevokeAccessModalOpen}
                />
            </Box>
            {connection?.connected && (
                <>
                    <Box paddingBlock="4">
                        <FigmaLinkInput
                            links={links}
                            fetchFile={fetchFile}
                            isLoading={file.status === 'loading'}
                            removeHistory={clearLinks}
                            cancelRequest={cancelRequest}
                        />
                    </Box>
                    <Box paddingBlock="4">
                        <FigmaFile
                            file={file}
                            selectedPageId={selectedPageId}
                            selectPage={selectPage}
                            selectedFramesIds={selectedFramesIds ?? []}
                            toggleFrame={toggleFrame}
                            switchAccount={switchAccount}
                        />
                    </Box>
                </>
            )}

            {renderRevokeAccessModal()}
            {renderLimitReachedModal()}
        </>
    );
}
