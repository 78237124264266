import styled, { css } from 'styled-components';
import { Flex, ListItem, textStyles, token } from '@bynder/design-system';
import { IconClear } from '@bynder/icons';

export const ExportsItemContainer = styled.div`
    padding: 4px 0;
    & > div:last-child {
        border-bottom: 0;
        margin-block-end: 0;
    }
    &.export--filters {
        margin-block-end: 14px;
    }
`;

export const ExportItemArrow = styled('div')`
    svg {
        transform: rotate(180deg);
        path {
            fill: #ccd3d6;
        }
    }
`;

export const ExportItemRowWrapper = styled(Flex)`
    height: 64px;
    width: 100%;
    padding-bottom: ${token.spacing3};
    margin-bottom: ${token.spacing3};
    border-bottom: 1px solid rgba(0, 34, 51, 0.05);
    &:last-child {
        border-bottom: none;
    }
`;

export const ExportItemRow = styled(Flex)`
    width: 100%;
    padding: ${token.spacing3} ${token.spacing4};
    ${(props) =>
        !props.hoverdisabled &&
        css`
            &:hover {
                background-color: ${token.gray25};
                cursor: pointer;

                ${ExportItemArrow} {
                    svg {
                        path {
                            fill: ${token.gray600};
                        }
                    }
                }
            }
        `}
`;

export const ExportSectionTitle = styled.span`
    display: block;
    padding: 12px;
    font-size: 16px;
    line-height: 20px;
    color: #334e5c;
    flex-grow: 1;
`;

export const ExportItemThumbnail = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 5px;
    background: ${token.gray50a};
    margin-inline-end: 12px;

    &.failed {
        position: relative;
        border-bottom-right-radius: 22px;
        .failed-icon {
            position: absolute;
            bottom: -4px;
            right: -4px;
            width: 16px;
            height: 16px;
            background-color: #f45225;
            color: #fff;
            border-radius: 100%;
            svg {
                vertical-align: top !important;
                margin-top: 0.0312em;
                path {
                    fill: #ffffff !important;
                }
            }
        }
    }
`;

export const ExportItemThumbnailProgress = styled(ExportItemThumbnail)`
    background: #ccd3d6;
    &:before {
        position: absolute;
        content: '';
        border: 5px solid #ccd3d6;
        border-radius: 50%;
        border-top: 5px solid #fff;
        width: 30px;
        height: 30px;
        -webkit-animation: spin 1.5s linear infinite; /* Safari */
        animation: spin 1.5s linear infinite;
    }
    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export const ExportItemTitle = styled.span`
    font-size: 16px;
    line-height: 20px;
    color: #334e5c;
`;

export const ExportItemSubtitle = styled.span`
    ${textStyles.uiTextS};
    color: ${token.gray600};
`;

export const ExportItemActionsWrapper = styled(Flex)`
    width: fit-content;
`;

export const ExportItemTabHeader = styled(Flex)`
    padding: 0 44px 24px 24px;
    .job--details-header {
        flex-grow: 1;
    }
    .job--details-back-btn {
        margin-inline-end: 12px;
    }
`;

export const ExportItemSubtitleSeparator = styled.span`
    display: inline-block;
    padding-left: 6px;
    padding-right: 6px;
`;

export const ExportJobsContainer = styled.div`
    height: calc(100% - 106px);
    overflow-y: auto;
    overflow-x: hidden;
`;

export const ExportXIcon = styled(IconClear)`
    position: absolute;
    right: -6px;
    bottom: -6px;
  
    width: 20px;
    height: 20px;
  
    background-color: ${token.gray10};
    border: 2px solid ${token.gray10};
    border-radius: 50%;
  
    color: ${token.red400};
  }
`;

export const InlineBannerWr = styled.div`
    margin-bottom: 14px;
`;

export const SyledList = styled(ListItem)`
    width: 100%;
    &:hover {
        background-color: transparent;
    }
`;
