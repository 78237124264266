import { ColorParams, defaultColor, toRGBA } from '@bynder-studio/misc';

const BLINKS_PER_SECOND = 2;

export class Cursor {
    private ctx: CanvasRenderingContext2D;

    private lineWidth = 2; // cursor width

    private lineHeight = 40; // cursor height

    private lastTimestamp = null;

    private visible = false;

    private color: ColorParams = defaultColor;

    // coordinates of the cursor of text baseline relative to the canvas
    private x = 0;

    private y = 0;

    constructor(ctx: CanvasRenderingContext2D) {
        this.ctx = ctx;
        this.visible = true;
    }

    setColor = (color: ColorParams) => {
        this.color = color;
    };

    updatePosition(x: number, y: number, height: number, resetTimer = true) {
        this.x = x;
        this.y = y;
        this.lineHeight = height;

        if (resetTimer) {
            this.visible = true;
            this.lastTimestamp = Date.now();
        }
    }

    draw(scale: number, dpr: number, alpha: number, forceDrawing: boolean) {
        const scaleWithDpr = scale * dpr;
        const currentTimestamp = Date.now();
        const timeDiff = currentTimestamp - this.lastTimestamp;

        if (timeDiff > 1000 / BLINKS_PER_SECOND) {
            this.lastTimestamp = Date.now();
            this.visible = !this.visible;
        }

        if (forceDrawing || this.visible) {
            this.ctx.save();
            // reset the transform to make sure the x and y offset are from the absolute left top of the canvas
            this.ctx.resetTransform();
            this.ctx.beginPath();
            this.ctx.strokeStyle = toRGBA(this.color);
            this.ctx.lineWidth = this.lineWidth;
            this.ctx.globalAlpha = alpha;
            // round position of cursor to display it sharply
            this.ctx.moveTo(Math.round(this.x * scaleWithDpr), this.y * scaleWithDpr);
            this.ctx.lineTo(Math.round(this.x * scaleWithDpr), (this.y + this.lineHeight) * scaleWithDpr);
            this.ctx.stroke();
            this.ctx.restore();
        }
    }
}
