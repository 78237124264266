import React, { useCallback } from 'react';
import { GroupElement, ImageElement, TextElement, VideoElement, ShapeElement } from '@bynder-studio/render-core';
import { IconBuildShape, IconFolder, IconImage, IconSync, IconTextField, IconVideo } from '@bynder/icons';
import { Thumbnail, token } from '@bynder/design-system';
import generateTestId from '~/helpers/testIdHelpers';
import { GenerateIconProps } from '../types';

export function useGenerateIcon({ isInModal = false }: GenerateIconProps) {
    const generateIcon = useCallback(
        (layerElement, isSelectedElement) => {
            const { type: layerElementType, contentPropertyId, uuid } = layerElement ?? {};
            const isContentProperty = contentPropertyId || uuid;

            if (isSelectedElement && isContentProperty) {
                return (
                    <Thumbnail
                        variant="clean"
                        shape="circle"
                        backgroundColor={token.teal500}
                        icon={<IconSync style={{ color: token.white }} />}
                        {...generateTestId('shorts_content_property_selected_indicator')}
                    />
                );
            }

            if (layerElementType === 'TEXT' || layerElement instanceof TextElement) {
                return (
                    <Thumbnail
                        variant="clean"
                        icon={<IconTextField />}
                        size={!isSelectedElement && !isInModal ? 's' : 'm'}
                    >
                        {!isSelectedElement && isContentProperty && (
                            <Thumbnail.Indicator
                                backgroundColor="teal500"
                                icon={<IconSync />}
                                {...generateTestId('shorts_content_property_indicator')}
                            />
                        )}
                    </Thumbnail>
                );
            }

            if (layerElementType === 'IMAGE' || layerElement instanceof ImageElement) {
                const imageSource =
                    layerElement instanceof ImageElement ? layerElement.src : layerElement.properties.src;

                if (imageSource) {
                    return (
                        <Thumbnail
                            variant="content"
                            imageUrl={imageSource ? imageSource : undefined}
                            size={!isSelectedElement && !isInModal ? 's' : 'm'}
                        >
                            {!isSelectedElement && isContentProperty && (
                                <Thumbnail.Indicator
                                    backgroundColor="teal500"
                                    icon={<IconSync />}
                                    {...generateTestId('shorts_content_property_indicator')}
                                />
                            )}
                        </Thumbnail>
                    );
                }

                return (
                    <Thumbnail variant="clean" icon={<IconImage />} size={!isSelectedElement && !isInModal ? 's' : 'm'}>
                        {!isSelectedElement && isContentProperty && (
                            <Thumbnail.Indicator
                                backgroundColor="teal500"
                                icon={<IconSync />}
                                {...generateTestId('shorts_content_property_indicator')}
                            />
                        )}
                    </Thumbnail>
                );
            }

            if (layerElementType === 'VIDEO' || layerElement instanceof VideoElement) {
                return (
                    <Thumbnail variant="clean" icon={<IconVideo />} size={!isSelectedElement && !isInModal ? 's' : 'm'}>
                        {!isSelectedElement && isContentProperty && (
                            <Thumbnail.Indicator
                                backgroundColor="teal500"
                                icon={<IconSync />}
                                {...generateTestId('shorts_content_property_indicator')}
                            />
                        )}
                    </Thumbnail>
                );
            }

            if (layerElementType === 'GROUP' || layerElement instanceof GroupElement) {
                return (
                    <Thumbnail
                        variant="clean"
                        icon={<IconFolder />}
                        size={!isSelectedElement && !isInModal ? 's' : 'm'}
                    >
                        {!isSelectedElement && isContentProperty && (
                            <Thumbnail.Indicator
                                backgroundColor="teal500"
                                icon={<IconSync />}
                                {...generateTestId('shorts_content_property_indicator')}
                            />
                        )}
                    </Thumbnail>
                );
            }

            if (layerElementType === 'SHAPE' || layerElement instanceof ShapeElement) {
                return (
                    <Thumbnail
                        variant="clean"
                        icon={<IconBuildShape />}
                        size={!isSelectedElement && !isInModal ? 's' : 'm'}
                    >
                        {!isSelectedElement && isContentProperty && (
                            <Thumbnail.Indicator
                                backgroundColor="teal500"
                                icon={<IconSync />}
                                {...generateTestId('shorts_content_property_indicator')}
                            />
                        )}
                    </Thumbnail>
                );
            }

            return undefined;
        },
        [isInModal],
    );

    return {
        generateIcon,
    };
}
