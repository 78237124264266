import { Flex, Input, List, token } from '@bynder/design-system';
import { styled } from 'styled-components';

export const PresetsContainer = styled(Flex)`
    width: 100%;
    overflow: hidden;
`;

export const PresetsListContainer = styled(Flex)`
    width: 60%;
    max-height: 60vh;
    overflow: hidden;
    padding: ${token.spacing4};
    margin-left: calc(-1 * ${token.spacing5});
`;

export const PresetDetails = styled(Flex)`
    width: 40%;
    padding: ${token.spacing4};
`;

export const PresetDefaultInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    padding: ${token.spacing4};
    text-transform: uppercase;
    width: 40%;
    text-align: center;
`;

export const PresetItemsContainer = styled.div`
    width: 100%;
`;

export const StyledInput = styled(Input)`
    width: 300px;
`;

export const PresetsList = styled(List)`
    padding: 0 ${token.spacing5};
`;
