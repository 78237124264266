import React, { useCallback } from 'react';
import { notify } from '@bynder/design-system';
import { IconHideSmall, IconSyncOff, IconLockSmall, IconAnimate } from '@bynder/icons';
import Editable from '../../../../../editable/Editable';

const Inline = ({
    name,
    displayName,
    editable,
    setEditable,
    icon,
    notEditable,
    onAnimationExpand,
    hasAnimations,
    isHidden,
    elementNameNodeRef,
    isLocked,
    hasUnlinkedProperty,
    onNameChange,
    scrollContainerRef,
    onMountCb = () => {},
}) => {
    const handleConfirm = useCallback(
        (value) => {
            setEditable(false);
            onNameChange(value);
        },
        [onNameChange],
    );
    const stopPropagateHandler = useCallback((e) => e.stopPropagation(), []);

    const showIndicators = (!notEditable && isHidden) || isLocked || hasAnimations;

    const handleValidationFail = useCallback((text) => {
        notify({
            id: 'elementNameEditingError',
            title: text,
            variant: 'error',
        });
    }, []);

    return (
        <div className="timeline__element__content h-100">
            <div className="timeline__element__icon">{icon}</div>
            {hasUnlinkedProperty && <IconSyncOff />}
            <div className="timeline__element__name">
                <div
                    onKeyDown={(e) => editable && e.nativeEvent.stopImmediatePropagation()}
                    onClick={(e) => editable && e.stopPropagation()}
                >
                    {notEditable ? (
                        name
                    ) : (
                        <Editable
                            valueNodeRef={elementNameNodeRef}
                            key={name}
                            value={name}
                            displayValue={displayName}
                            editable={editable}
                            onToggle={(editable) => setEditable(editable)}
                            onConfirm={(value) => handleConfirm(value)}
                            className="text-truncate timeline__element__name-editable"
                            confirmBeforeUnmount
                            hideIcon
                            useCapture
                            withDoubleClick
                            validations={{ maxLength: 128 }}
                            ignoreChanges={() => true}
                            handleValidationFail={handleValidationFail}
                            scrollContainerRef={scrollContainerRef}
                            onMountCb={onMountCb}
                        />
                    )}
                </div>
            </div>
            {showIndicators && (
                <div className="timeline__element__indicators">
                    <div className="timeline__element__indicators__line" onClick={stopPropagateHandler}>
                        {hasAnimations && (
                            <div
                                className="timeline__element__animation"
                                onMouseDown={stopPropagateHandler}
                                onMouseUp={onAnimationExpand}
                            >
                                <IconAnimate size="16" />
                            </div>
                        )}
                    </div>
                    <div className="timeline__element__indicators__line">
                        {!notEditable && isHidden && (
                            <div className="timeline__element__visibility">
                                <IconHideSmall size="16" />
                            </div>
                        )}
                        {isLocked && (
                            <div className="timeline__element__lock">
                                <IconLockSmall size="16" />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Inline;
