import { useEffect, useMemo, useState } from 'react';

/**
 * Compares provided array with an array in internal state.
 * If the internal array has all the items from the provided array, returns the internal array.
 * Otherwise, updates the internal array with the provided one and returns it.
 */
export default function useSuperSet<T>(arr: T[]): T[] {
    const [memoized, setMemoized] = useState(arr);
    const memoizedSet = useMemo(() => new Set(memoized), [memoized]);

    useEffect(() => {
        if (arr === memoized || arr.every((id) => memoizedSet.has(id))) {
            return;
        }

        setMemoized(arr);
    }, [arr, memoized, memoizedSet]);

    return memoized;
}
