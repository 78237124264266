import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import useVariations from 'packages/pages/design/hooks/useVariations';
import { useRedLine } from 'packages/pages/design/timeline/TimelineShotContainer/useRedLine';
import { framesToTime } from '~/helpers/helpers';
import { useResizing } from '~/common/editor/timeline/tracks/dragging/useResizing';
import { useHorizontalDragging } from '~/common/editor/timeline/tracks/dragging/useHorizontalDragging';
import { millisecondToFrames, framesToMilliseconds } from '~/common/editor/editorHelper';
import { pureElementsSelector } from 'packages/store/creativeEditor/creativeEditor.selectors';
import { flattenTree } from '~/common/editor/helpers/elementtree';
import { type Brand } from '~/common/types';

export enum DurationModes {
    SLIP = 'SLIP',
    TRIM = 'TRIM',
}

type FrameNumberType = Brand<number, 'frames'>;
type MillisecondNumberType = Brand<number, 'millisecond'>;
type PercentNumberType = Brand<number, 'percent'>;

const MAX_VIDEO_DURATION = 5 * 60 * 25;

export type Props = {
    elementId: number;
    actionsDisabled: boolean;
    widthPercentageDefault: PercentNumberType;
    leftPercentageDefault: PercentNumberType;
    start: FrameNumberType;
    end: FrameNumberType;
    onChange: (...args) => void;
    frameRate: FrameNumberType;
    fullDuration: MillisecondNumberType;
    elementDuration: FrameNumberType;
    mode: DurationModes;
    duration: FrameNumberType;
    isEditorPage: boolean;
    isResizeDisabled: boolean;
};

const getPercentages = (startFrame, endFrame, fullDuration) => {
    const leftPercentage = (100 / fullDuration) * startFrame;
    const widthPercentage = (100 / fullDuration) * (endFrame - startFrame);

    return [leftPercentage, widthPercentage];
};

const MIN_WIDTH_FOR_BADGE_PX = 60;

export const useDuration = ({
    elementId,
    actionsDisabled,
    onChange,
    fullDuration,
    duration,
    elementDuration,
    leftPercentageDefault,
    widthPercentageDefault,
    mode,
    frameRate,
    start,
    end,
    isEditorPage,
    isResizeDisabled,
}: Props) => {
    // TODO: Add types to store
    const elements: any = useSelector(pureElementsSelector);
    const { variations, currentVariation } = useVariations();
    const [isBadgeVisible, setIsBadgeVisible] = useState<boolean>(false);
    const controlRef = useRef<HTMLDivElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const redLineRef = useRef<HTMLDivElement | null>(null);
    const timeTooltipRef = useRef<HTMLDivElement | null>(null);
    const isResizing = useRef(false);
    const isDragging = useRef(false);
    const redLine = useRedLine(redLineRef, containerRef, 5, timeTooltipRef);
    const maxValueForSlipMode = useMemo((): number => fullDuration - elementDuration, [fullDuration, elementDuration]);
    const fullDurationInFrames = useMemo(
        (): number => millisecondToFrames(fullDuration, frameRate),
        [fullDuration, frameRate],
    );
    const isSlipMode = mode === DurationModes.SLIP;

    const originalVideoLength: number = useMemo(
        () => (!elements || isEditorPage ? 0 : flattenTree(elements)[elementId]?.properties.duration),
        [elements, elementId],
    );

    const isDynamicLengthEnabled = useMemo(() => {
        if (!elements || isEditorPage) {
            return false;
        }

        return flattenTree(elements)[elementId]?.properties.useDynamicLength;
    }, [elements, elementId]);

    const maxDynamicLength = useMemo(() => {
        if (!currentVariation || !variations) {
            return MAX_VIDEO_DURATION;
        }

        const currentVariationData = variations.find((v) => v.id === currentVariation);
        const { elements: updatedElements } = Object.values(currentVariationData.sizes)[0];
        const totalOtherElsLength = Object.keys(updatedElements).reduce((accum, currentElId) => {
            if (Number(currentElId) === elementId) {
                return accum;
            }

            const currentEl = updatedElements[currentElId];

            return accum + (currentEl.duration || 0);
        }, 0);

        return MAX_VIDEO_DURATION - totalOtherElsLength;
    }, [elementId, variations, currentVariation]);

    const handleMouseMoveX = (e: React.MouseEvent<HTMLDivElement>): void => {
        if (isResizing.current) {
            redLineRef.current?.removeAttribute('style');

            return;
        }

        if (e.target !== e.currentTarget) {
            handleMouseLeave();

            return;
        }

        if (
            isDragging.current ||
            redLineRef.current === null ||
            containerRef.current === null ||
            timeTooltipRef.current === null
        ) {
            return;
        }

        redLine.handleMouseMove(e);

        const bounds = containerRef.current.getBoundingClientRect();
        const newPosX = e.clientX - bounds.left + containerRef.current.scrollLeft;
        const posInPercent = (100 / bounds.width) * newPosX;
        const newOffsetTime = framesToTime(Math.round((fullDurationInFrames / 100) * posInPercent));
        timeTooltipRef.current.textContent = `${newOffsetTime}`;
    };

    const handleMouseLeave = (): void => {
        redLineRef.current?.removeAttribute('style');
        timeTooltipRef.current?.removeAttribute('style');
    };

    const updateSliderStyles = useCallback((left, width) => {
        if (!controlRef.current) {
            return;
        }

        controlRef.current.style.left = left + '%';
        controlRef.current.style.width = width + '%';
    }, []);

    const onResize = (
        fromRight: number,
        elementLeft: number,
        elementWidth: number,
        leftPercentage: number,
        widthPercentage: number,
    ) => {
        const elementTargetWidth = (containerRef.current.getBoundingClientRect().width * widthPercentage) / 100;

        if (isResizeDisabled || (isDynamicLengthEnabled && elementTargetWidth < getOriginalVideoLengthInPx())) {
            return;
        }

        isResizing.current = true;
        onChange(leftPercentage, widthPercentage);
    };

    const onResizeEnd = (leftPercentage: number, widthPercentage: number) => {
        if (isResizeDisabled) {
            return;
        }

        isResizing.current = false;

        const elementTargetWidth = (containerRef.current.getBoundingClientRect().width * widthPercentage) / 100;

        if (isDynamicLengthEnabled && !isEditorPage && elementTargetWidth < getOriginalVideoLengthInPx()) {
            return;
        }

        onChange(leftPercentage, widthPercentage);
        // we duplicate it here because sometimes useEffect has no effect
        // the reason - the same value as earlier
        updateSliderStyles(leftPercentage, widthPercentage);
    };

    useEffect(() => {
        if (!isResizing.current && !isDragging.current) {
            updateSliderStyles(leftPercentageDefault, widthPercentageDefault);
        }

        if (controlRef.current) {
            const { width } = controlRef?.current.getBoundingClientRect();
            setIsBadgeVisible(width >= MIN_WIDTH_FOR_BADGE_PX);
        }
    }, [leftPercentageDefault, widthPercentageDefault]);

    const getOriginalVideoLengthInPx = useCallback(() => {
        if (!containerRef.current) {
            return 0;
        }

        const { width } = containerRef.current.getBoundingClientRect();

        return (width / fullDurationInFrames) * originalVideoLength;
    }, [fullDurationInFrames, originalVideoLength]);

    const getMaxDynamicLengthInPx = useCallback(() => {
        if (!containerRef.current) {
            return null;
        }

        const { width } = containerRef.current.getBoundingClientRect();

        return (width / fullDurationInFrames) * maxDynamicLength;
    }, [fullDurationInFrames, maxDynamicLength]);

    const [onHandleMouseDown] = useResizing(controlRef, containerRef, onResize, onResizeEnd, controlRef, {
        minLeft: 20,
        minRight: 20,
        minWidth: getOriginalVideoLengthInPx(),
        maxWidth: getMaxDynamicLengthInPx(),
        setMaxIfOnEdge: true,
    });

    const onDrag = (leftPercentage: number) => {
        isDragging.current = true;
        redLineRef.current?.removeAttribute('style');

        onChange(leftPercentage);
    };

    const onDrop = (): void => {
        setTimeout(() => {
            isDragging.current = false;
        }, 0);
    };

    const handleDrag = useHorizontalDragging({
        controlRef,
        containerRef,
        onDrag,
        onDrop,
        useWidthForMaxLeft: true,
    });

    const onStartFrameCorrect = (frame: number) => {
        const chosenFrameInTime: number = framesToMilliseconds(frame, frameRate);
        let desiredStartFrame =
            isSlipMode && chosenFrameInTime > maxValueForSlipMode
                ? millisecondToFrames(maxValueForSlipMode, frameRate)
                : frame;
        const desiredEndFrame = isSlipMode ? desiredStartFrame + millisecondToFrames(elementDuration, frameRate) : end;

        // start-value can't be more than end-value - so just leave it to zero;
        if (desiredStartFrame >= desiredEndFrame) {
            desiredStartFrame = 0;
        }

        const expectedVideoLength = desiredEndFrame - desiredStartFrame;

        if (expectedVideoLength < originalVideoLength || expectedVideoLength > maxDynamicLength) {
            desiredStartFrame = desiredEndFrame - originalVideoLength;
        }

        return desiredStartFrame;
    };

    const onEndFrameCorrect = (frame: number) => {
        let desiredEndFrame;

        if (!isSlipMode) {
            desiredEndFrame = frame <= start || frame > fullDurationInFrames ? fullDurationInFrames : frame;

            const expectedVideoLength = desiredEndFrame - start;

            if (expectedVideoLength < originalVideoLength || expectedVideoLength > maxDynamicLength) {
                desiredEndFrame = start + originalVideoLength;
            }
        } else {
            const minValueForEndFrame = millisecondToFrames(elementDuration, frameRate);
            desiredEndFrame = Math.max(minValueForEndFrame, Math.min(frame, fullDurationInFrames));
        }

        return desiredEndFrame;
    };

    const onDurationFrameCorrect = (frame: number) => {
        let desiredDurationFrame = frame < 1 ? 1 : frame;

        if (desiredDurationFrame < originalVideoLength || desiredDurationFrame > maxDynamicLength) {
            desiredDurationFrame = originalVideoLength;
        }

        const maxDuration = fullDurationInFrames - start;

        if (desiredDurationFrame > maxDuration) {
            desiredDurationFrame = maxDuration;
        }

        return desiredDurationFrame;
    };

    const onInputCorrection = (inputType: 'start' | 'end' | 'duration') => (frame: number) => {
        if (inputType === 'start') {
            return onStartFrameCorrect(frame);
        } else if (inputType === 'end') {
            return onEndFrameCorrect(frame);
        } else if (inputType === 'duration') {
            return onDurationFrameCorrect(frame);
        }
    };

    const handleStartFrameChange = (desiredStartFrame: number) => {
        const desiredEndValue = isSlipMode ? desiredStartFrame + millisecondToFrames(elementDuration, frameRate) : end;
        onChange(...getPercentages(desiredStartFrame, desiredEndValue, fullDurationInFrames));
    };

    const handleEndFrameChange = (frame: number) => {
        const minValueForEndFrame = millisecondToFrames(elementDuration, frameRate);
        const desiredEndFrame = isSlipMode ? end : frame;
        let desiredStartFrame: number = start;

        if (isSlipMode) {
            desiredStartFrame =
                // eslint-disable-next-line no-nested-ternary
                frame < minValueForEndFrame
                    ? 0
                    : frame > fullDurationInFrames
                    ? fullDurationInFrames - minValueForEndFrame
                    : frame - minValueForEndFrame;
        }

        const [leftPercentage, widthPercentage] = getPercentages(
            desiredStartFrame,
            desiredEndFrame,
            fullDurationInFrames,
        );
        const values = isSlipMode ? [leftPercentage] : [leftPercentage, widthPercentage];

        onChange(...values);
    };

    const handleDurationFrameChange = (durationFrame: number) => {
        onChange(...getPercentages(start, start + durationFrame, fullDurationInFrames));
    };

    const onInputChange = (inputType: 'start' | 'end' | 'duration') => (frame: number) => {
        if (inputType === 'start') {
            return handleStartFrameChange(frame);
        } else if (inputType === 'end') {
            return handleEndFrameChange(frame);
        } else if (inputType === 'duration') {
            return handleDurationFrameChange(frame);
        }
    };

    const handleDragByClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target !== event.currentTarget || !containerRef.current || isDragging.current) {
            return;
        }

        const bounds = containerRef.current.getBoundingClientRect();
        const newPosX = event.clientX - bounds.left + containerRef.current.scrollLeft;
        const posInPercent = (100 / bounds.width) * newPosX;

        let desiredStartFrame = (fullDurationInFrames / 100) * posInPercent;

        if (desiredStartFrame + duration > fullDurationInFrames) {
            desiredStartFrame = fullDurationInFrames - duration;
        }

        const desiredEndFrame = desiredStartFrame + duration;

        const [leftPercentage, widthPercentage] = getPercentages(
            desiredStartFrame,
            desiredEndFrame,
            fullDurationInFrames,
        );

        onChange(leftPercentage, widthPercentage);
        handleMouseLeave();
    };

    const handleZoomControlChange = useCallback(({ left, width, zoom }): void => {
        if (!containerRef.current) {
            return;
        }

        const maxLeftPercent = 100 - width;
        const l = ((zoom - 100) / maxLeftPercent) * left || 0;

        containerRef.current.style.width = `${zoom}%`;
        containerRef.current.style.left = `-${l}%`;
    }, []);

    return {
        isSlipMode,
        duration,
        frameRate,
        start,
        end,
        containerRef,
        redLineRef,
        timeTooltipRef,
        controlRef,
        handleDrag,
        isBadgeVisible,
        handleZoomControlChange,
        fullDurationInFrames,
        actionsDisabled,
        isDragging: isDragging.current,
        onInputCorrection,
        onInputChange,
        handleDragByClick,
        handleMouseMoveX,
        handleMouseLeave,
        onHandleMouseDown,
    };
};
