import React, { useEffect, useRef, useState } from 'react';
import { BaseMultiPageModel, ContentProperty } from '@bynder-studio/render-core';
import { useDispatch } from 'react-redux';
import { Button, List, Modal } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import generateTestId from '~/helpers/testIdHelpers';
import ContentPropertyItem from './ContentPropertyItem';
import { markAsUnsaved } from 'packages/store/creativeEditor/creativeEditor.actions';
import modalContainer from 'packages/common/modalContainer';

type ModalPropsType = {
    isOpen: boolean;
    properties: ContentProperty[];
    onClose: () => void;
    onSave: (updatedProperties: ContentProperty[]) => void;
    creativeModel: BaseMultiPageModel;
};

const ContentPropertyListModal = ({ creativeModel, properties, isOpen, onClose, onSave }: ModalPropsType) => {
    const [propertyToRenameId, setPropertyToRenameId] = useState<string | null>(null);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [updatedProperties, setUpdatedProperties] = useState(properties);
    const dispatch = useDispatch();

    const MAX_INPUT_SIZE = 120;

    const { translate } = useTranslate();

    const propertiesRef = useRef(properties);

    const TitlesMap = {
        IMAGE: translate('editor.sidebar.shots.contentProperties.modal.image'),
        TEXT: translate('editor.sidebar.shots.contentProperties.modal.text'),
        SHAPE: translate('editor.sidebar.shots.contentProperties.modal.shape'),
        GROUP: translate('editor.sidebar.shots.contentProperties.modal.group'),
        VIDEO: translate('editor.sidebar.shots.contentProperties.modal.video'),
        MASK: translate('editor.sidebar.shots.contentProperties.modal.mask'),
    };

    const handleNameChange = (id, name) => {
        const modifiedProperty = updatedProperties.filter((item) => item.uuid === id);
        const modifiedPropertyIndex = updatedProperties.indexOf(modifiedProperty[0]);

        if (name.length && name.length <= MAX_INPUT_SIZE && modifiedProperty[0].name !== name) {
            const newProperty = { ...modifiedProperty[0], name };
            const newUpdatedProperties = [...updatedProperties];

            newUpdatedProperties.splice(modifiedPropertyIndex, 1, newProperty);
            setUpdatedProperties(newUpdatedProperties);
        }

        setPropertyToRenameId(null);
    };

    const handleOnDelete = (id) => {
        setUpdatedProperties(updatedProperties.filter((item) => item.uuid !== id));
    };

    const handleOnSave = () => {
        onSave(updatedProperties);
        dispatch(markAsUnsaved());
        onClose();
    };

    const isEmpty = updatedProperties.length === 0;
    const sortedProperties = updatedProperties.reduce(
        (acc, property) => {
            const element = properties.find((item) => item.uuid === property.uuid);

            if (element) {
                const { type } = element;
                acc[type].push(property);
            }

            return acc;
        },
        {
            IMAGE: [] as ContentProperty[],
            TEXT: [] as ContentProperty[],
            SHAPE: [] as ContentProperty[],
            GROUP: [] as ContentProperty[],
            VIDEO: [] as ContentProperty[],
        },
    );

    const getDiffProperties = () => {
        const propertiesToSave = updatedProperties.reduce((acc, item) => {
            const originalProperty = propertiesRef.current.find((property) => property.uuid === item.uuid);

            if (originalProperty?.name !== item.name) {
                return [...acc, item];
            }

            return acc;
        }, [] as ContentProperty[]);

        return propertiesToSave;
    };

    useEffect(() => {
        if (!isOpen) {
            setPropertyToRenameId(null);
            setIsSaveDisabled(true);
            setUpdatedProperties(properties);
        }
    }, [isOpen, properties]);

    useEffect(() => {
        if (propertiesRef?.current?.length !== updatedProperties.length || getDiffProperties().length > 0) {
            setIsSaveDisabled(false);
        } else {
            setIsSaveDisabled(true);
        }
    }, [updatedProperties]);

    useEffect(() => {
        setUpdatedProperties(properties);
        propertiesRef.current = properties;
    }, [properties]);

    return (
        <Modal
            container={modalContainer}
            title={translate('editor.sidebar.shots.contentProperties.modal.title')}
            isOpen={isOpen}
            onClose={onClose}
            isClosedOnOverlayClick
            actionPrimary={
                <Button
                    variant="primary"
                    onClick={handleOnSave}
                    isDisabled={isSaveDisabled}
                    {...generateTestId('editor : modal : content properties list save button')}
                >
                    {translate('editor.sidebar.shots.contentProperties.modal.save')}
                </Button>
            }
            actionSecondary={
                <Button
                    variant="secondary"
                    onClick={onClose}
                    {...generateTestId('editor : modal : content properties list cancel button')}
                >
                    {translate('editor.sidebar.shots.contentProperties.modal.cancel')}
                </Button>
            }
        >
            {!isEmpty &&
                Object.entries(sortedProperties).map(([key, values]: [string, any]) => {
                    return (
                        values.length > 0 && (
                            <List key={key}>
                                <List.Item key={`list-title-${key}`}>{TitlesMap[key]}</List.Item>
                                {values.map((value) => (
                                    <ContentPropertyItem
                                        key={value.uuid}
                                        property={value}
                                        propertyToRenameId={propertyToRenameId}
                                        actions={{
                                            onRename: setPropertyToRenameId,
                                            onDelete: handleOnDelete,
                                            onChange: handleNameChange,
                                        }}
                                        creativeModel={creativeModel}
                                    />
                                ))}
                            </List>
                        )
                    );
                })}
            {isEmpty && translate('editor.sidebar.shots.contentProperties.modal.empty')}
        </Modal>
    );
};

export default ContentPropertyListModal;
