import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const EditableLayerItemStyled = styled.div`
    padding: 2px 0;

    .layers--item-input-container {
        position: relative;
        border: 1px solid ${token.gray300};
        box-shadow: ${token.elevationStatic};
        border-radius: 5px;
        margin: 0;
    }

    .layers--item-content-input {
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
        outline: 0;
        border: none;
        width: 100%;
        height: 100%;
        color: ${token.colorText};
        font-size: 16px;
        line-height: 20px;
        background-color: transparent;
    }

    .layers--item-input-clear-btn {
        position: absolute;
        top: 8px;
        right: 14px;
        width: 24px;
        height: 24px;
        color: ${token.colorTextDisabled};
        transition: color 0.5s ease;

        svg {
            width: 100%;
            height: 100%;
        }

        &:hover {
            color: ${token.colorText};
        }
    }
`;
