import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { InteractiveIcon, Thumbnail, ColorPicker } from '@bynder/design-system';
import generateTestId from '~/helpers/testIdHelpers';
import { hexToRgb, rgbToHex } from 'packages/pages/editor/RightSideMenu/FormComponents/ColorPicker/utils';
import { StyledInput } from './ColorInput.styled';

const ColorInput = ({ isMixed, isAlphaMixed, id, state, value, labels, actions, testId, inputRef, disabled }) => {
    const [showMixedPlacehodler, setShowMixedPlaceholder] = useState(isMixed);
    const [pickerColor, setPickerColor] = useState(value);
    const [isFocused, setIsFocused] = useState(false);
    const { inputColor } = state;
    const { inputOnBlur, inputOnKeyDown, pickerOnChange, onChange } = actions;
    const { label, htmlFor, describedby } = labels;
    const inputTestId = generateTestId(testId);
    const hexInputRef = document.querySelector('[aria-label="Hex"]');

    const interactiveIconColor = isMixed ? null : isAlphaMixed ? hexToRgb(rgbToHex(value), 1) : value;

    // NOTE: Temp solution because of the design.
    // Hope we will delete it soon
    useEffect(() => {
        if (isMixed && isFocused) {
            setShowMixedPlaceholder(false);
            onChange('#_____');
        } else if (isMixed && !isFocused) {
            setShowMixedPlaceholder(true);
        }
    }, [isMixed]);

    const onFocus = () => {
        setIsFocused(true);

        if (isMixed) {
            setShowMixedPlaceholder(false);
            onChange('#_____');
        }
    };

    const handleOnBlur = (...args) => {
        setIsFocused(false);
        inputOnBlur(...args);

        if (isMixed) {
            setShowMixedPlaceholder(true);
        }
    };

    const rgbaToColorObj = (rgba) => {
        const colorCode = rgba.replace('rgba(', '').replace(')', '');
        const color = rgbToHex(rgba);
        const opacity = Number((Number(colorCode.split(',')[3]) * 100).toFixed(0));

        return {
            color,
            opacity,
        };
    };

    const colorObjToRgba = (pickerValue) => {
        const { color, opacity } = pickerValue;

        return hexToRgb(color, opacity / 100);
    };

    const handlePickerColorChange = (colorObj) => {
        setPickerColor(colorObj);

        // 6 digits of a hex code plus hash sign
        if (colorObj.color.length === 7) {
            pickerOnChange(colorObjToRgba(colorObj));
        }
    };

    useEffect(() => {
        if (!hexInputRef) {
            return;
        }

        const listener = (event) => {
            if (hexInputRef.contains(event.target)) {
                return;
            }

            if (pickerColor?.color?.length === 4) {
                pickerOnChange(colorObjToRgba(pickerColor));
            }
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [hexInputRef, pickerColor]);

    return (
        <ColorPicker
            value={rgbaToColorObj(value)}
            onChange={(color) => handlePickerColorChange(color)}
            trigger={({ ...triggerProps }) => {
                return (
                    <InputMask
                        mask="\#******"
                        maskChar="_"
                        formatChars={{
                            '*': '[A-Fa-f0-9]',
                        }}
                        onChange={onChange}
                        onBlur={handleOnBlur}
                        onFocus={onFocus}
                        onKeyDown={inputOnKeyDown}
                        placeholder={isMixed ? 'Mixed' : ''}
                        value={isMixed && showMixedPlacehodler ? '' : inputColor}
                        disabled={disabled}
                        {...inputTestId}
                    >
                        {(inputProps) => (
                            <StyledInput
                                {...inputProps}
                                isUppercase={!(isMixed && showMixedPlacehodler)}
                                id={id}
                                icon={
                                    <InteractiveIcon
                                        label={label}
                                        {...triggerProps}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            triggerProps.onClick();
                                        }}
                                        icon={
                                            <Thumbnail
                                                variant="content"
                                                backgroundColor={interactiveIconColor}
                                                shape="circle"
                                                size="xs"
                                            />
                                        }
                                        isDisabled={disabled}
                                    />
                                }
                                aria-label={label}
                                aria-labelledby={htmlFor}
                                aria-describedby={describedby}
                                inputRef={inputRef}
                                isDisabled={disabled}
                            />
                        )}
                    </InputMask>
                );
            }}
        />
    );
};

export default ColorInput;
