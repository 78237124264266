import React from 'react';
import { Thumbnail } from '@bynder/design-system';
import { IconAspectRatioOutline } from '@bynder/icons';

import { Name, Page, PageData, PageItemStyled } from './PageItem.styled';

type Props = {
    name: string;
    width: number;
    height: number;
    isThumbnailRendering?: boolean;
};

const PageItem = ({ name, width, height, isThumbnailRendering }: Props) => (
    <PageItemStyled gap="4">
        {isThumbnailRendering ? (
            <Thumbnail variant="clean" icon="">
                <Thumbnail.Overlay isLoading />
            </Thumbnail>
        ) : (
            <Thumbnail variant="clean" icon={<IconAspectRatioOutline />} size="m" />
        )}
        <PageData direction="column">
            <Name>{name}</Name>
            <Page>
                {width} &times; {height} px
            </Page>
        </PageData>
    </PageItemStyled>
);

export default PageItem;
