import { mat2d } from 'gl-matrix';
import {
    BaseCompositor,
    type BlendMode,
    BorderAlignmentTypes,
    type ClipSoftness,
    Dimension,
    equals,
    frameIndexToHTMLSeekTime,
    GroupCompElement,
    IAssetsLoader,
    ICompElement,
    ICompModel,
    ImageCompElement,
    Mask,
    MaskModeTypes,
    Position,
    Region,
    RegionTypes,
    ShapeCompElement,
    SvgCompElement,
    VideoAsset,
    VideoCompElement,
} from '@bynder-studio/render-core';
import { type ColorParams, StrokeType, toRGBA } from '@bynder-studio/misc';
import { CanvasCompLayer } from './CanvasCompLayer';

type SvgPathType = 'DECORATION' | 'BACKGROUND' | 'CUSTOM';

export class CanvasLayerCompositor extends BaseCompositor {
    private offscreenCanvas: HTMLCanvasElement;

    canvasWrapper: HTMLElement;

    canvas: HTMLCanvasElement;

    ctx: CanvasRenderingContext2D;

    private drawCompModelRequestId = 0;

    useFrameBufferForVideoAsset = false;

    devicePixelRatio: number;

    protected draggingId: number = null;

    protected baseLayer: CanvasCompLayer | null = null;

    constructor(canvasWrapperEl: HTMLElement, assetLoader: IAssetsLoader, dpr = 1) {
        super(assetLoader);

        this.canvasWrapper = canvasWrapperEl;
        this.canvas = document.createElement('canvas');
        this.canvas.tabIndex = 1;
        this.canvas.style.outline = 'none';
        this.canvasWrapper.appendChild(this.canvas);
        this.devicePixelRatio = dpr;
        this.ctx = this.canvas.getContext('2d');
    }

    getCanvas(): HTMLCanvasElement {
        return this.canvas;
    }

    setScale(scale: number) {
        this.scale = this.devicePixelRatio * scale;
    }

    getScaleTest() {
        return this.scale;
    }

    getScale(): number {
        return this.scale / this.devicePixelRatio;
    }

    getDpr(): number {
        return this.devicePixelRatio;
    }

    setPanPosition(panPosition: Position) {}

    setDraggingId(draggingId) {
        this.draggingId = draggingId;
    }

    getPanPosition(): Position {
        return new Position(0, 0);
    }

    getPanRegion(): Region {
        const { x, y } = this.getPanPosition();

        if (x === 0 && y === 0) {
            return new Region(0, 0, this.roi.getWidth(), this.roi.getHeight());
        }

        const diffWidth = (this.roi.getWidth() * this.scale - this.canvas.width) / 2;
        const diffHeight = (this.roi.getHeight() * this.scale - this.canvas.height) / 2;

        const leftX = (diffWidth - x) / this.scale;
        const topY = (diffHeight - y) / this.scale;

        return new Region(leftX, topY, leftX + this.canvas.width / this.scale, topY + this.canvas.height / this.scale);
    }

    setCompositorDimension(dimension: Dimension) {
        this.canvas.width = this.devicePixelRatio * dimension.getWidth();
        this.canvas.height = this.devicePixelRatio * dimension.getHeight();
        this.canvas.style.width = dimension.getWidth() + 'px';
        this.canvas.style.height = dimension.getHeight() + 'px';
    }

    getCompositorDimension(): Dimension {
        const { width, height } = this.canvas.style;

        return new Dimension(parseInt(width, 10), parseInt(height, 10));
    }

    getCompositorWrapperDimension(): Dimension {
        const { width, height } = this.canvasWrapper.getBoundingClientRect();

        // @ts-ignore
        return new Dimension(parseInt(width, 10), parseInt(height, 10));
    }

    s(n: number): number {
        // shorthand scale function, apply to everything related to coordinates
        return n * this.scale;
    }

    requestToDrawCompModel(compModel: ICompModel): void {
        if (this.drawCompModelRequestId) {
            cancelAnimationFrame(this.drawCompModelRequestId);
        }

        this.drawCompModelRequestId = requestAnimationFrame(() => {
            this.drawCompModelRequestId = 0;
            this.drawCompModel(compModel);
        });
    }

    createBaseLayer(compModel: ICompModel): CanvasCompLayer {
        this.roi = new Region(0, 0, compModel.getDimension().getWidth(), compModel.getDimension().getHeight());

        return new CanvasCompLayer(null, 'root', this.roi, this.roi, this.canvas, this.s(1));
    }

    createElementLayer(
        el: ICompElement,
        type: RegionTypes = RegionTypes.TRANSFORMED,
        allowCrop = true,
    ): CanvasCompLayer {
        const region = el.getRegion(type);

        if (region.getWidth() < 1 || region.getHeight() < 1) {
            return null;
        }

        const absRegion = el.getAbsoluteRegion(type);
        const layerName = el.id + '-' + type;
        let croppedRegion = region;

        // NOTE: Quick fix for turn off cropping for animations that demand scaling
        const check = (el: ICompElement) =>
            el?.parent?.scale !== 1 ? true : el?.parent?.parent ? check(el.parent.parent) : false;
        const hasAnimatedParent = el.parent ? check(el) : false;

        if (!this.debugNoLayerCrop && allowCrop && !hasAnimatedParent) {
            const pan = this.getPanRegion();
            const tempRegion = new Region(
                Math.max(region.left, pan.left - absRegion.left + region.left),
                Math.max(region.top, pan.top - absRegion.top + region.top),
                Math.min(region.right, pan.right - absRegion.right + region.right),
                Math.min(region.bottom, pan.bottom - absRegion.bottom + region.bottom),
            );

            // NOTE: Extend the drawing region to include the drop shadow
            tempRegion.applyDropShadow(el.dropShadow);

            if (tempRegion.getArea() < croppedRegion.getArea()) {
                croppedRegion = tempRegion;
            }
        }

        if (croppedRegion.getWidth() < 1 || croppedRegion.getHeight() < 1) {
            return null;
        }

        const layer = new CanvasCompLayer(el, layerName, croppedRegion, absRegion, null, this.s(1));

        BaseCompositor.layerAreaPxCreated += layer.getWidth() * layer.getHeight();
        BaseCompositor.layerAreasCreated += 1;

        super.printDebugInfo(
            `Created effect layer ${layer.id} with size (${layer.canvas.width},${
                layer.canvas.height
            }) at region (${layer.region.toArray()})`,
        );

        // only translate to the content
        layer.applyTranslation(-croppedRegion.left, -croppedRegion.top);

        return layer;
    }

    getDataUrl(): string {
        return this.canvas.toDataURL();
    }

    /**
     *
     * @param el Element to draw including transformation and effects
     * @param layer Layer transformed to content origin
     */
    drawAtomicElementOnLayer(el, layer, force = false) {
        const hasOverflowEffects = el.hasOverflowEffects();

        if (!hasOverflowEffects && !el.requiresIntermediateLayer()) {
            // if element has no mask, and no overflow effects, no intermediate layers are necessary
            this.applyTransform(el.getScaleAndRotationTransform(), layer.context);

            this.applyClipPath(el.clipPath, layer.context, 1);

            const opacity = this.draggingId === el.id ? el.opacity * 0.8 : el.opacity;
            this.applyOpacity(opacity, layer.context);
            this.applyBlendMode(el.blendMode, layer.context);

            // finally draw the content
            this.drawContentOnLayer(el, layer, force);

            this.applyClipSoftness(el.clipSoftness, layer.context);

            this.printDebugInfo(
                `Drawing element ${el.id} onto layer ${layer.id} at position (${layer.getTransform()})`,
            );
        } else {
            // Effects require intermediate layers to be created
            let tmpLayer = this.createElementLayer(el);

            if (!tmpLayer) {
                return;
            }

            this.applyTransform(el.getScaleAndRotationTransform(), tmpLayer.context);

            this.applyClipPath(el.clipPath, tmpLayer.context, 1);

            this.drawContentOnLayer(el, tmpLayer, force);

            this.applyMask(el.mask, tmpLayer);

            this.applyClipSoftness(el.clipSoftness, tmpLayer.context);

            if (hasOverflowEffects) {
                // if overflow effects are present, first precomp onto temporary layer
                const effectLayer = this.createElementLayer(el, RegionTypes.EFFECT);

                if (!effectLayer) {
                    return;
                }

                // apply effects
                this.applyBlur(el.blur, effectLayer.context);
                this.applyDropShadow(el.dropShadow, 0, effectLayer.context);

                // draw contentLayer with effects
                this.mergeLayers(tmpLayer, effectLayer);

                tmpLayer = effectLayer;
            }

            this.applyOpacity(el.opacity, layer.context);
            this.applyBlendMode(el.blendMode, layer.context);

            // draw contentLayer with effects
            this.mergeLayers(tmpLayer, layer);

            this.printDebugInfo(
                `Drawing layer ${tmpLayer.id} onto layer ${layer.id} with size (${tmpLayer.canvas.width},${
                    tmpLayer.canvas.height
                }) at position (${layer.getTransform()})`,
            );

            this.displayDebugLayer(tmpLayer);
        }
    }

    drawContentOnLayer(el, layer, force = false) {
        if (el instanceof SvgCompElement || el instanceof ShapeCompElement) {
            this.drawSvgCompElement(el, layer);
        } else if (el instanceof VideoCompElement) {
            this.drawVideoCompElement(el, layer);
        } else if (el instanceof ImageCompElement) {
            this.drawImageCompElement(el, layer);
        } else if (el instanceof GroupCompElement) {
            // this is for merging underline and strikethrough decoration paths
            // todo: this part of code need to be improved and moved out from here
            if (el.name?.startsWith('line_') && this.isElementVisible(el)) {
                const collectProps = (el: SvgCompElement, word: GroupCompElement) => ({
                    opacity: el.opacity,
                    rotation: el.rotation,
                    scale: el.scale === 1 ? 1 : NaN,
                    blur: el.blur,
                    blendMode: el.blendMode,
                    strokeType: el.strokeType,
                    strokeWidth: el.strokeWidth,
                    clipPath: el.clipPath,
                    color: el.color,
                    horizontalPathScale: el.horizontalPathScale,
                    translatedX: el.translatedX,
                    translatedY: el.translatedY,
                    wordOpacity: word.opacity,
                    wordRotation: word.rotation,
                    wordScale: word.scale === 1 ? 1 : NaN,
                    wordBlur: word.blur,
                    wordBlendMode: word.blendMode,
                    wordClipPath: word.clipPath,
                    wordTranslatedX: word.translatedX,
                    wordTranslatedY: word.translatedY,
                });
                let decorationPath = new Path2D();
                const toPath2D = (path: string | Path2D) => (typeof path === 'string' ? new Path2D(path) : path);
                const getNextLetter = (
                    words: GroupCompElement[],
                    wordIndex: number,
                    index = -1,
                ): {
                    letter: SvgCompElement;
                    word: GroupCompElement;
                } | null => {
                    const word = words[wordIndex];

                    if (!word) {
                        return null;
                    }

                    const letters = (word.getChildren() as SvgCompElement[]).filter((e) => this.isElementVisible(e));

                    if (!letters.length) {
                        return getNextLetter(words, wordIndex + 1);
                    }

                    const letter = letters[index + 1];

                    if (letter) {
                        return { letter, word };
                    }

                    return getNextLetter(words, wordIndex + 1);
                };
                const decorationPaths = (item: any) => [...(item._decorationPaths || item.decorationPaths || [])];
                el.getChildren()
                    .filter((e) => this.isElementVisible(e))
                    .forEach((word, wordIndex, wordArr) => {
                        if (!this.isElementVisible(word)) {
                            return;
                        }

                        const x = word.boundingBox.position.x + word.contentBox.position.x;

                        (word as GroupCompElement).getChildren().forEach((letter: SvgCompElement, i) => {
                            const lx =
                                (x +
                                    letter.boundingBox.position.x +
                                    letter.contentBox.position.x +
                                    letter.pathPosition.x) /
                                letter.horizontalPathScale;
                            decorationPaths(letter).forEach((path) => {
                                const path2D = toPath2D(path);
                                const m = new DOMMatrix();
                                m.translateSelf(lx, 0);
                                decorationPath.addPath(path2D, m);
                            });
                            (letter as any)._decorationPaths = decorationPaths(letter);
                            letter.decorationPaths = [];
                            const props = collectProps(letter, word as GroupCompElement);
                            const next = getNextLetter(wordArr as GroupCompElement[], wordIndex, i);
                            const isDifferentProps = !next || !equals(props, collectProps(next.letter, next.word));

                            if (isDifferentProps) {
                                const decoration = new Path2D();
                                const m = new DOMMatrix();
                                m.translateSelf(-lx, 0);
                                decoration.addPath(decorationPath, m);
                                letter.decorationPaths.push(decoration as unknown as string);
                                decorationPath = new Path2D();
                            }
                        });
                    });
            }

            el.getChildren().forEach((childEl) => {
                layer.save();
                layer.applyTranslation(
                    childEl.boundingBox.position.x + childEl.contentBox.position.x,
                    childEl.boundingBox.position.y + childEl.contentBox.position.y,
                );

                this.drawElementOnLayer(childEl, layer, force);

                layer.restore();
            });
        }
    }

    applyMask(mask: Mask, layer: CanvasCompLayer) {
        if (!mask) {
            return;
        }

        if (mask.mode === MaskModeTypes.ALPHA_INVERTED || mask.mode === MaskModeTypes.ALPHA) {
            const maskLayer = this.getMaskLayer(String(mask.elementId)) as CanvasCompLayer;

            if (!maskLayer) {
                return;
            }

            if (!maskLayer.absoluteRegion.getIntersection(layer.absoluteRegion)) {
                if (mask.mode === MaskModeTypes.ALPHA) {
                    layer.clear();
                }

                return;
            }

            const ctx = layer.context;
            layer.save();

            if (mask.mode === MaskModeTypes.ALPHA_INVERTED) {
                ctx.globalCompositeOperation = 'destination-out';
            } else if (mask.mode === MaskModeTypes.ALPHA) {
                // normal
                ctx.globalCompositeOperation = 'destination-in';
            }

            // transform from masked element content back to main layer origin
            let transform = mat2d.invert(mat2d.create(), layer.el.getAbsContentTransform());

            // tranform to mask element parent from origin
            if (maskLayer.el.parent) {
                transform = mat2d.multiply(transform, transform, maskLayer.el.parent.getAbsContentTransform());
            }

            // since scaling and rotation were applied to the masklayer, we only need to translate to mask content origin
            // additionally we correct for layer effects
            transform = mat2d.translate(transform, transform, [
                maskLayer.el.boundingBox.position.x + maskLayer.el.contentBox.position.x + maskLayer.region.left,
                maskLayer.el.boundingBox.position.y + maskLayer.el.contentBox.position.y + maskLayer.region.top,
            ]);

            this.applyTransform(transform, ctx);

            ctx.drawImage(maskLayer.canvas, 0, 0);

            this.displayDebugLayer(maskLayer);

            ctx.globalCompositeOperation = 'source-over';

            layer.restore();
        }
    }

    private drawImageCompElement(el: ImageCompElement | VideoCompElement, layer: CanvasCompLayer) {
        const asset = this.assetLoader.getAsset(el.assetId);

        // @ts-ignore
        if (!asset || !asset.object || !asset.isAccessible()) {
            // this.drawPlaceholderRectangle(el.contentBox, el.boundingBox);
            return;
        }

        // @ts-ignore
        const { object, width, height } = asset;
        layer
            .getContext()
            .drawImage(
                object,
                el.cropPositionPct.getX() * width,
                el.cropPositionPct.getY() * height,
                el.cropDimensionPct.getWidth() * width,
                el.cropDimensionPct.getHeight() * height,
                0,
                0,
                this.s(el.contentBox.dimension.getWidth()),
                this.s(el.contentBox.dimension.getHeight()),
            );
    }

    private drawVideoCompElement(el: VideoCompElement, layer: CanvasCompLayer) {
        const asset = this.assetLoader.getAsset(el.assetId) as VideoAsset;

        if (!asset || !asset.object || !asset.isAccessible()) {
            // this.drawPlaceholderRectangle(el.contentBox, el.boundingBox);
            return;
        }

        const seekTime = frameIndexToHTMLSeekTime(el.frameIndex, asset.frameRate);
        const key = parseFloat(seekTime.toFixed(2));
        const videoFrame = asset.frameBuffer.get(key);
        const objectToDraw =
            this.useFrameBufferForVideoAsset && videoFrame
                ? videoFrame
                : asset.isAlpha
                ? this.getAlphaVideoFrame(asset.object)
                : asset.object;
        const videoWidth = asset.object.videoWidth;
        const videoHeight = !asset.isAlpha ? asset.object.videoHeight : asset.object.videoHeight / 2;

        if (!videoWidth || !videoHeight) {
            return;
        }

        layer
            .getContext()
            .drawImage(
                objectToDraw,
                el.cropPositionPct.getX() * videoWidth,
                el.cropPositionPct.getY() * videoHeight,
                el.cropDimensionPct.getWidth() * videoWidth,
                el.cropDimensionPct.getHeight() * videoHeight,
                0,
                0,
                this.s(el.contentBox.dimension.getWidth()),
                this.s(el.contentBox.dimension.getHeight()),
            );
    }

    private drawSvgByPoints = (el, layer) => {
        const ctx = layer.getContext();

        ctx.save();
        ctx.scale(this.s(1), this.s(1));
        ctx.translate(el.pathPosition.getX(), el.pathPosition.getY());
        ctx.scale(el.horizontalPathScale, el.verticalPathScale);

        this.applyColor(el.color, ctx);

        ctx.beginPath();
        el.pathPoints.forEach((p, i) => {
            if (!i) {
                ctx.moveTo(p.in.x, p.in.y);
            }

            ctx.arcTo(p.x, p.y, p.out.x, p.out.y, p.arc.radius);
            ctx.lineTo(p.next.in.x, p.next.in.y);
        });

        ctx.fill();
        ctx.restore();
    };

    private drawSvgCompElement(el: SvgCompElement | ShapeCompElement, layer: CanvasCompLayer) {
        const ctx = layer.getContext();
        ctx.save();

        const toPath2D = (path) => (typeof path === 'string' ? new Path2D(path) : path);

        const drawPath = (path: string | Path2D, pathType: SvgPathType) => {
            const pathToDraw = toPath2D(path);

            if (el instanceof ShapeCompElement && el.borderWidth) {
                this.drawShapeWithBorder(el as ShapeCompElement, pathToDraw, layer);
            } else {
                this.drawAnySvg({ el, path: pathToDraw, layer, pathType });
            }
        };

        if (el.pathPoints.length) {
            this.drawSvgByPoints(el, layer);
        } else {
            // (el.bgPaths || []).forEach((path) => drawPath(path, 'BACKGROUND'));
            drawPath(el.path, 'CUSTOM');
            (el.decorationPaths || []).forEach((path) => drawPath(path, 'DECORATION'));
        }

        ctx.restore();
    }

    drawAnySvg({
        el,
        path,
        layer,
        pathType,
    }: {
        el: SvgCompElement;
        path: Path2D;
        layer: CanvasCompLayer;
        pathType: SvgPathType;
    }) {
        const ctx = layer.getContext();

        ctx.save();
        ctx.scale(this.s(1), this.s(1));
        ctx.translate(el.pathPosition.getX(), el.pathPosition.getY());
        ctx.scale(el.horizontalPathScale, el.verticalPathScale);

        const strokeScale = 1 / el.horizontalPathScale;
        const strokeWidth = el.strokeWidth * strokeScale;

        this.applyColor(el.color, ctx);

        if (pathType === 'BACKGROUND') {
            this.applyColor(el.bgColor, ctx);
            ctx.fill(path);
        } else if (el.strokeType === StrokeType.INSIDE) {
            ctx.lineWidth = strokeWidth * 2;
            ctx.clip(path);
            ctx.stroke(path);
        } else if (el.strokeType === StrokeType.OUTSIDE) {
            // Draw double stroke
            ctx.lineWidth = strokeWidth * 2;
            ctx.stroke(path);

            // Clip inside the letter
            ctx.globalCompositeOperation = 'destination-out';
            ctx.globalAlpha = 1;
            ctx.fillStyle = 'rgba(0, 0, 0, 1)';
            ctx.fill(path);
        } else if (el.strokeType === StrokeType.CENTER) {
            ctx.lineWidth = strokeWidth;
            ctx.stroke(path);
        } else {
            this.applyColor(el.color, ctx);
            ctx.fill(path);
        }

        ctx.closePath();
        ctx.restore();
    }

    /**
     *
     * @param el
     * @param p
     * @param layer
     */
    private drawShapeWithBorder(el: ShapeCompElement, p: Path2D, layer: CanvasCompLayer) {
        const ctx = layer.getContext();
        const lineWidth = el.borderWidth;
        const lineCap = el.borderLineCap;
        const borderAlignment = el.borderAlignment;
        const fillColor = el.color;
        const strokeColor = el.borderColor;

        ctx.save();

        // Correct scale of the paths by setting the scale on the canvas
        // Apply local SVG translate and scale(first translate then scale)
        // NOTE That since we already scaled the canvas, we should not scale pathPosition and pathScale again
        ctx.scale(this.s(1), this.s(1));
        ctx.translate(el.pathPosition.getX(), el.pathPosition.getY());
        ctx.scale(el.horizontalPathScale, el.verticalPathScale);

        if (lineCap) {
            ctx.lineCap = lineCap;
        }

        // draw shape on offscreen canvas
        if (borderAlignment === BorderAlignmentTypes.INSIDE) {
            if (!this.currentCompModel.isElementMaskEnabled(el.id) || fillColor.opacity != strokeColor.opacity) {
                /* Since we cannot disable anti-aliasing fully on a canvas context,
                we only apply an alternative way of drawing for the inside border type */
                ctx.clip(p);

                // draw fill
                ctx.lineWidth = lineWidth * 2;
                ctx.fillStyle = toRGBA(fillColor);
                ctx.fill(p);

                // remove border area from fill
                ctx.globalCompositeOperation = 'destination-out';
                ctx.strokeStyle = 'rgba(0, 0, 0, 1)';
                ctx.stroke(p);

                // re-draw border
                ctx.globalCompositeOperation = 'source-over';
                ctx.strokeStyle = toRGBA(strokeColor);
                ctx.stroke(p);
            } else {
                // To improve quality in case of a mask with the same opacity for fill and stroke, we use an alternative routine
                ctx.fillStyle = toRGBA(fillColor);
                ctx.fill(p);
            }
        } else if (borderAlignment === BorderAlignmentTypes.MIDDLE) {
            // draw fill
            ctx.lineWidth = lineWidth;
            ctx.fillStyle = toRGBA(fillColor);
            ctx.fill(p);

            // remove border area from fill
            ctx.globalCompositeOperation = 'destination-out';
            ctx.strokeStyle = 'rgba(0, 0, 0, 1)';
            ctx.stroke(p);

            // re-draw border
            ctx.globalCompositeOperation = 'source-over';
            ctx.strokeStyle = toRGBA(strokeColor);
            ctx.stroke(p);
        } else if (borderAlignment === BorderAlignmentTypes.OUTSIDE) {
            // draw border
            ctx.lineWidth = lineWidth * 2;
            ctx.strokeStyle = toRGBA(strokeColor);
            ctx.stroke(p);

            // remove fill
            ctx.globalCompositeOperation = 'destination-out';
            ctx.fillStyle = 'rgba(0, 0, 0, 1)';
            ctx.fill(p);

            // re-draw fill
            ctx.globalCompositeOperation = 'source-over';
            ctx.fillStyle = toRGBA(fillColor);
            ctx.fill(p);
        }

        ctx.restore();
    }

    mergeLayers(sourceLayer: CanvasCompLayer, targetLayer: CanvasCompLayer): void {
        if (!targetLayer.canvas.width || !targetLayer.canvas.height) {
            return;
        }

        targetLayer.context.drawImage(
            sourceLayer.canvas,
            this.s(sourceLayer.region.left),
            this.s(sourceLayer.region.top),
        );
    }

    // TODO: remove;
    clearFrame() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }

    applyColor(color: ColorParams, ctx) {
        ctx.fillStyle = toRGBA(color);
        ctx.strokeStyle = toRGBA(color);
    }

    applyOpacity(opacity, ctx) {
        if (opacity != 1) {
            ctx.globalAlpha *= opacity;
        }
    }

    applyBlendMode(blendMode: BlendMode, ctx) {
        ctx.globalCompositeOperation = blendMode?.getCompositeOperation() || 'source-over';
    }

    /**
     * Apply transform to a layer
     * @param transform
     * @param ctx
     */
    applyTransform(transform, ctx) {
        // apply scale for translation
        let t = mat2d.fromScaling(mat2d.create(), [this.s(1), this.s(1)]);
        t = mat2d.multiply(t, t, transform);
        // correct scale factor since this is already included in the other transforms
        t = mat2d.scale(t, t, [1 / this.s(1), 1 / this.s(1)]);
        ctx.transform(...t);
    }

    applyRotation(deltaX, deltaY, rotation, ctx) {
        if (rotation % 360 !== 0) {
            ctx.translate(this.s(deltaX), this.s(deltaY));
            ctx.rotate((Math.PI / 180) * rotation);
            ctx.translate(-this.s(deltaX), -this.s(deltaY));
        }
    }

    applyScale(deltaX, deltaY, scale, ctx) {
        if (scale !== 1) {
            ctx.translate(this.s(deltaX), this.s(deltaY));
            ctx.scale(scale, scale);
        }
    }

    applyBlur(blur, ctx) {
        if (blur !== 0) {
            ctx.filter = `blur(${blur * 100}px)`;
        }
    }

    applyDropShadow(dropShadow, counterRotation, ctx) {
        if (dropShadow && dropShadow.state !== 'DISABLE') {
            const color = dropShadow.color;
            ctx.shadowColor = `rgba(${color.red}, ${color.green}, ${color.blue}, ${color.opacity})`;
            ctx.shadowBlur = this.s(dropShadow.blurRadius);

            if (counterRotation) {
                const [dsOffsetX, dsOffsetY] = this.rotatePoint(
                    counterRotation,
                    dropShadow.offsetX,
                    dropShadow.offsetY,
                );
                ctx.shadowOffsetX = this.s(dsOffsetX);
                ctx.shadowOffsetY = this.s(dsOffsetY);
            } else {
                ctx.shadowOffsetX = this.s(dropShadow.offsetX);
                ctx.shadowOffsetY = this.s(dropShadow.offsetY);
            }
        }
    }

    applyBackgroundColor(color: ColorParams, ctx) {
        ctx.save();
        this.applyColor(color, ctx);
        const dimension = this.currentCompModel.getDimension();
        ctx.fillRect(0, 0, this.s(dimension.getWidth()), this.s(dimension.getHeight()));
        ctx.restore();
    }

    /**
     * Rotate coordinate around center point
     * https://stackoverflow.com/questions/17410809/how-to-calculate-rotation-in-2d-in-javascript
     */
    rotatePoint(angle, x, y, cx = 0, cy = 0) {
        if (angle % 360 !== 0) {
            const radians = (Math.PI / 180) * angle;
            const cos = Math.cos(radians);
            const sin = Math.sin(radians);
            const nx = cos * (x - cx) + sin * (y - cy) + cx;
            const ny = cos * (y - cy) - sin * (x - cx) + cy;

            return [nx, ny];
        }

        return [x, y];
    }

    /**
     * Apply clippath to the canvas.
     * Does not save and restore, which would erase the path
     * @param path
     * @param ctx
     * @param scale
     */
    applyClipPath(path, ctx, scale = 1) {
        if (!path) {
            return;
        }

        const transform = ctx.getTransform();
        // ctx.resetTransform();
        // draw clipPath at the scale of the canvas clipPath
        ctx.scale(this.s(scale), this.s(scale)); // set scale for path
        ctx.beginPath();
        ctx.clip(new Path2D(path));
        ctx.setTransform(transform);
    }

    applyClipSoftness(clipSoftness: ClipSoftness | undefined, ctx, scale = 1) {
        if (!clipSoftness) {
            return;
        }

        const transform = ctx.getTransform();
        ctx.scale(this.s(scale), this.s(scale)); // set scale for path
        ctx.beginPath();
        const { x0, y0, x1, y1 } = clipSoftness.gradient;
        const gradient = ctx.createLinearGradient(x0, y0, x1, y1);
        gradient.addColorStop(0, 'rgba(255, 255, 255, 0)'); // Start with transparent
        gradient.addColorStop(1, 'rgba(0, 0, 0, 1)'); // End with black

        const { x, y, w, h, angle, dx = 0, dy = 0 } = clipSoftness.rectangle;

        if (angle) {
            ctx.translate(x, y);
            ctx.rotate(angle);
            ctx.translate(-x, -y);
        }

        ctx.globalCompositeOperation = 'destination-out';
        ctx.fillStyle = gradient;
        ctx.fillRect(x + dx, y + dy, w, h);
        ctx.globalCompositeOperation = 'source-over';
        ctx.setTransform(transform);
    }

    drawCompModel(compModel: ICompModel): void {
        this.currentCompModel = compModel;
        this.printDebugInfo(['Drawing compmodel frame: ', compModel.getFrame()]);
        this.printDebugInfo(compModel);

        const startTime = performance.now();

        this.prepareDraw(compModel);

        if (this.baseLayer) {
            this.baseLayer.resetTransform();
            this.baseLayer.clear(null);
        }

        this.drawCheckerboardPattern(this.ctx);

        this.applyBackgroundColor(compModel.getBackgroundColor(), this.ctx);

        // TODO: Added filtering by only one id for testing!!
        compModel.getCompElements().forEach((el, idx) => {
            this.drawCompElement(el);
        });

        this.cleanupDraw();

        const endTime = performance.now();

        this.printDebugInfo(
            `Drew ${BaseCompositor.layerAreasCreated} areas with ${(
                BaseCompositor.layerAreaPxCreated / 1000000
            ).toFixed(2)} Megapixels`,
        );
        this.printDebugInfo(
            `Drew frame ${compModel.getFrame()} in ${(endTime - startTime).toFixed(2)} ms \
                    scale ${this.scale.toFixed(2)}`,
        );
    }

    protected drawCheckerboardPattern(ctx) {
        const orgTransform = ctx.getTransform();
        ctx.resetTransform();
        ctx.save();

        const size = this.devicePixelRatio * 6;
        const w = ctx.canvas.width;
        const h = ctx.canvas.height;
        const numX = Math.ceil(w / size);
        const numY = Math.ceil(h / size);

        ctx.fillStyle = '#FFFFFF';
        ctx.fillRect(0, 0, w, h);

        ctx.fillStyle = '#EDEEF0';

        for (let x = 0; x < numX; x++) {
            for (let y = 0; y < numY; y++) {
                if ((x + y) % 2 === 0) {
                    ctx.fillRect(x * size, y * size, size, size);
                }
            }
        }

        ctx.restore();
        ctx.setTransform(orgTransform);
    }

    private getAlphaVideoFrame(videoObject: HTMLVideoElement) {
        // calculations for alpha/rgb images mixing
        if (!this.offscreenCanvas) {
            this.offscreenCanvas = document.createElement('canvas');
        }

        this.offscreenCanvas.width = videoObject.videoWidth;
        this.offscreenCanvas.height = videoObject.videoHeight;
        const videoCanvasCtx = this.offscreenCanvas.getContext('2d');
        videoCanvasCtx.drawImage(videoObject, 0, 0, videoObject.videoWidth, videoObject.videoHeight);
        const imageDataRgb = videoCanvasCtx.getImageData(
            0,
            0,
            this.offscreenCanvas.width,
            this.offscreenCanvas.height / 2,
        );
        const imageDataAlpha = videoCanvasCtx.getImageData(
            0,
            this.offscreenCanvas.height / 2,
            this.offscreenCanvas.width,
            this.offscreenCanvas.height / 2,
        );
        const imageDataRgbData = imageDataRgb.data;
        const imageDataAlphaData = imageDataAlpha.data;

        // update every fourth pixel from the RGB image with transparency from the alpha image (0 - 255)
        for (let i = 3; i < imageDataRgbData.length; i += 4) {
            imageDataRgbData[i] = Math.floor(
                (imageDataAlphaData[i - 1] + imageDataAlphaData[i - 2] + imageDataAlphaData[i - 3]) / 3,
            );
        }

        // restore canvas height to correct dimension
        this.offscreenCanvas.height = this.offscreenCanvas.height / 2;
        videoCanvasCtx.putImageData(imageDataRgb, 0, 0);

        return this.offscreenCanvas;
    }

    /**
     * Draw overlay for non-groups based on region of interest
     * @param el
     */
    private drawDebugCompElementRoiOverlay(el) {
        if (el instanceof GroupCompElement) {
            el.getChildren().forEach((childEl) => {
                this.drawDebugCompElementRoiOverlay(childEl);
            });
        } else {
            const region = el.getAbsoluteRegion();
            const ctx = this.ctx;
            ctx.save();

            ctx.globalAlpha = 0.1;
            ctx.lineWidth = 2;
            ctx.fillStyle = '#f00';

            ctx.beginPath();
            ctx.rect(this.s(region.left), this.s(region.top), this.s(region.getWidth()), this.s(region.getHeight()));
            ctx.fill();
            ctx.closePath();

            ctx.restore();
        }
    }

    private displayDebugLayer(layer) {
        if (this.debug) {
            layer.canvas.convertToBlob().then((blob) => {
                const divEl = document.createElement('div');
                divEl.textContent = layer.id;
                document.body.appendChild(divEl);
                const imageEl = document.createElement('img');
                imageEl.src = window.URL.createObjectURL(blob);
                imageEl.style.border = '3px solid red';
                document.body.appendChild(imageEl);
                document.body.appendChild(document.createElement('br'));
            });
        }
    }
}
