import ProxyServerConnector from '~/services/connectors/ProxyServerConnector';

class FigmaService extends ProxyServerConnector {
    constructor(path = '/figma') {
        // eslint-disable-next-line no-undef
        super(path, GATEWAY_PREFIX);
    }

    getConnectionStatus() {
        return this.get('/auth/connection-status');
    }

    /**
     * @param location - The location to redirect to after login
     */
    getLoginUrl(location: string) {
        return this.get(`/auth/login-url?location=${encodeURIComponent(location)}`);
    }

    revokeAccess() {
        return this.delete('/auth/revoke-access');
    }

    getFile(fileKey: string) {
        return this.get(`/files/${fileKey}`, {}, {}, false);
    }
}

export default new FigmaService();
