import type { BaseAssetParams } from './BaseAsset';
import { BaseAsset } from './BaseAsset';
import { MediaController } from '../../types';

export type AudioAssetParams = BaseAssetParams & {
    startFrame: number;
    offsetTime: number;
    frameRate: number;
    duration: number;
};

export type AudioObject = HTMLAudioElement & { mediaController: MediaController };

export class AudioAsset extends BaseAsset {
    startFrame: number;
    offsetTime: number;
    frameRate: number;
    duration: number;
    object!: AudioObject;

    constructor(params: AudioAssetParams) {
        super(params);
        this.startFrame = params.startFrame;
        this.offsetTime = params.offsetTime;
        this.frameRate = params.frameRate;
        this.duration = params.duration;
    }

    setMediaData(audio: AudioObject) {
        this.object = audio;
    }

    destroy() {
        if (this.object && this.object.mediaController) {
            this.object.mediaController.destroy();
            (this.object as any).mediaController = null;
        }

        (this as any).object = null;
        this.accessible = false;
        this.loading = false;
    }
}
