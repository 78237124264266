import React, { useCallback, useEffect, useMemo } from 'react';
import { ElementUpdateTypes } from '@bynder-studio/render-core';
import { IconLock, IconLockOpen } from '@bynder/icons';
import useEditor from '../../../../../hooks/useEditor';
import useForceUpdate from '../../../../../hooks/useForceUpdate';
import { collectTopLevelElements, isElementLocked } from './utils';
import useGeneralHotkeys from 'packages/pages/editor/useGeneralHotKeys';
import generateTestId from '~/helpers/testIdHelpers';
import { TimelineActionsIcon } from '../TimelineActions.styled';
import useElementGeneralActions from 'packages/pages/editor/useElementGeneralActions';

export default function LockAction() {
    const { creativeModel, manipulationRenderer } = useEditor();
    const selectedElements = manipulationRenderer?.getSelectedElements() || [];
    const forceUpdate = useForceUpdate();
    const isActive = !!selectedElements.length;
    const isSelected = isActive && collectTopLevelElements(selectedElements).every(isElementLocked);
    const { handleLock } = useElementGeneralActions();

    const handleLockChangeAction = useCallback(
        ({ updateTypes, element }) => {
            if (updateTypes.has(ElementUpdateTypes.LOCK) && selectedElements.some((el) => el.id === element.id)) {
                forceUpdate();
            }
        },
        [selectedElements, forceUpdate],
    );

    useGeneralHotkeys({ handleLock });

    const testId = useMemo(() => generateTestId('timeline_actions_lock'), []);

    useEffect(() => {
        if (!manipulationRenderer) {
            return;
        }

        manipulationRenderer.eventEmitter.on('elementSelected', forceUpdate);

        return () => manipulationRenderer.eventEmitter.off('elementSelected', forceUpdate);
    }, [forceUpdate, manipulationRenderer]);

    useEffect(() => {
        if (!creativeModel) {
            return;
        }

        creativeModel.on('elementUpdated', handleLockChangeAction);

        return () => creativeModel.off('elementUpdated', handleLockChangeAction);
    }, [creativeModel, handleLockChangeAction]);
    const lockedAttr = useMemo(() => (DEFINE_TESTID_ATTRIBUTES ? { 'data-locked': isSelected } : {}), [isSelected]);

    return (
        <div title="Lock">
            <TimelineActionsIcon
                onClick={handleLock}
                active={isActive}
                selected={isSelected}
                {...testId}
                {...lockedAttr}
            >
                {isSelected ? <IconLock /> : <IconLockOpen />}
            </TimelineActionsIcon>
        </div>
    );
}
