import { BrandColorPalette } from 'packages/store/brandColors/types';
import { BrandColorOnElement } from '.';

export const getExistingBrandColor = (palettes: BrandColorPalette[], colorId: number): BrandColorOnElement | null => {
    const brandColor = palettes.reduce((acc, palette) => {
        const assignedColor = palette.brandColors.find((color) => color.id === colorId);

        if (assignedColor) {
            acc = { ...acc, ...assignedColor, paletteName: palette.name };
        }

        return acc;
    }, {} as BrandColorOnElement);

    return Object.keys(brandColor).length ? brandColor : null;
};

export default getExistingBrandColor;
