import styled from 'styled-components';
import { Card, textStyles, token } from '@bynder/design-system';

export const AspectRatioWrapper = styled.div`
    position: relative;
    background: ${token.gray200};
    max-width: 100%;
    max-height: 230px;
    box-sizing: border-box;
    resize: horizontal;
`;

export const AspectRatio = styled.div`
    width: 100%;
    padding-bottom: ${(props) => props.height}%;
`;

export const AspectRatioText = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    ${textStyles.headingXXL};
    color: ${token.white};
    left: 50%;
    top: 50%;
    width: 0;
    height: 0;
`;

export const AspectRatioDecorationTopLeft = styled.div`
    position: absolute;
    border: 4px solid ${token.gray300};
    width: 32px;
    height: 32px;
    border-right: none;
    border-bottom: none;
    left: 16px;
    top: 16px;
`;

export const AspectRatioDecorationBottomRight = styled.div`
    position: absolute;
    border: 4px solid ${token.gray300};
    width: 32px;
    height: 32px;
    border-left: none;
    border-top: none;
    right: 16px;
    bottom: 16px;
`;

export const CardBox = styled.div<{ defaultHeight: number }>`
    img {
        height: ${({ defaultHeight }) => `${defaultHeight < 132 ? 132 : defaultHeight > 320 ? 320 : defaultHeight}px`};
        max-height: 320px;
    }
    a > div > div > div > svg {
        height: ${({ defaultHeight }) => `${defaultHeight < 132 ? 132 : defaultHeight > 320 ? 320 : defaultHeight}px`};
        max-height: 320px;
    }
`;

export const ExportModalCardImage = styled(Card.Img)`
    object-fit: contain;
`;
