import React from 'react';
import { Button, Modal } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import modalContainer from 'packages/common/modalContainer';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    deleteTextStyle: () => void;
};

const DeleteStyleModal = ({ isOpen, onClose, deleteTextStyle }: Props) => {
    const { translate } = useTranslate();

    const handleDeleteClick = () => {
        deleteTextStyle();
        onClose();
    };

    return (
        <Modal
            container={modalContainer}
            isOpen={isOpen}
            onClose={onClose}
            title={translate('editor.sidebar.shots.text.textStyle.modal.delete.title')}
            actionPrimary={
                <Button variant="danger" onClick={handleDeleteClick}>
                    {translate('editor.sidebar.shots.text.textStyle.delete')}
                </Button>
            }
            actionSecondary={
                <Button variant="secondary" onClick={onClose}>
                    {translate('editor.sidebar.shots.text.textStyle.cancel')}
                </Button>
            }
        >
            {translate('editor.sidebar.shots.text.textStyle.delete_warning')}
        </Modal>
    );
};

export default DeleteStyleModal;
