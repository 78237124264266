import React from 'react';
import PageGrid from './PageGrid';
import PageList from './PageList';
import VariationGrid from './VariationGrid';
import VariationList from './VariationList';
import useExport from '../hooks/useExport';

const toPageItem = (item, pageMap) => (entry) => ({
    pageIndex: pageMap[entry.creativeVersionPageId || entry.sizeId]?.index,
    pageId: entry.creativeVersionPageId || entry.sizeId,
    variationSetId: item.variationSetId,
    variationId: entry.variationId,
    name: entry.pageName,
    width: entry.pageWidth,
    height: entry.pageHeight,
    aspectRatio: entry.aspectRatio,
    updated: entry.updated,
    containsTransparency: entry.containsTransparency,
    displayOrder: entry.displayOrder,
});

const toVariationItem = (entry) => ({
    variationSetId: entry.variationSetId,
    variationId: entry.variationId,
    name: entry.variationSetName,
    created: entry.created,
    updated: entry.updated,
    aspectRatio: entry.aspectRatio,
    hasDeletedAsset: entry.hasDeletedAsset,
    hasOversetText: entry.hasOversetText,
    failureReason: entry.failureReason,
    containsTransparency: entry.containsTransparency,
});

export const ExpandedRow = ({ item }) => {
    const { groupBy, pageMap, view, orderBy } = useExport();
    const items = item.entries.map(groupBy === 'variation' ? toPageItem(item, pageMap) : toVariationItem);

    if (groupBy === 'variation') {
        return view === 'grid' ? <PageGrid items={items} /> : <PageList items={items} />;
    }

    if (orderBy === 'name') {
        items.sort((a, b) => a.name.localeCompare(b.name));
    }

    if (orderBy === 'updated' || (groupBy === 'page' && orderBy === 'displayOrder')) {
        items.sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime());
    }

    const page = pageMap[item.creativeVersionPageId];

    return view === 'grid' ? (
        <VariationGrid items={items} pageIndex={page?.index} aspectRatio={item.aspectRatio} />
    ) : (
        <VariationList items={items} pageIndex={page?.index} />
    );
};
