import styled from 'styled-components';
import { token } from '@bynder/design-system';

const ExportTabContainer = styled.div`
    .export--job-title {
        padding: ${token.spacing4};
    }
`;

export default ExportTabContainer;
