import React, { useState } from 'react';
import VariationTranslateModal from 'packages/pages/design/sidebar/variations/VariationTranslateModal';
import { VariationTransformedSetItemType } from 'packages/variation-export/types';

const useVariationTranslateModal = ({ getVariationsAndSave, variationsTotal }) => {
    const [variation, setVariation] = useState<VariationTransformedSetItemType | null>(null);

    const onClose = () => {
        setVariation(null);
    };

    const renderVariationTranslateModal = () => (
        <VariationTranslateModal
            variation={variation}
            onClose={onClose}
            getVariationsAndSave={getVariationsAndSave}
            variationsTotal={variationsTotal}
        />
    );

    return {
        setVariationTranslateModal: setVariation,
        renderVariationTranslateModal,
    };
};

export default useVariationTranslateModal;
