import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const FiltersContainer = styled.div`
    display: flex;
    padding: ${token.spacing4} ${token.spacing6};
`;

export const FiltersLeftBlock = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex: 1;
`;

export const FiltersRightBlock = styled.div`
    display: flex;
    flex-wrap: nowrap;
`;

export const FiltersDivider = styled.div`
    width: 1px;
    background-color: ${token.gray100};
    margin: 0 ${token.spacing3};
`;
