class KeyFrame {
    frame: number;
    value: any;

    constructor(frame: number, value: any) {
        this.frame = frame;
        this.value = value;
    }

    getFrame(): number {
        return this.frame;
    }

    getValue(): number {
        return this.value;
    }
}

class KeyFrameHelper {
    keyFrames: KeyFrame[];

    constructor(keyFrames: KeyFrame[]) {
        this.keyFrames = keyFrames;

        this._sort();
    }

    _sort(): void {
        this.keyFrames.sort(function (a: KeyFrame, b: KeyFrame): number {
            // $FlowFixMe
            return a.getFrame() - b.getFrame();
        });
    }

    add(keyFrame: KeyFrame): void {
        this.keyFrames.push(keyFrame);

        this._sort();
    }

    getNext(frame: number): KeyFrame | null {
        if (this.keyFrames.length < 1) {
            return null;
        }

        let current = -1;

        for (let i = 0; i < this.keyFrames.length; i++) {
            // $FlowFixMe
            if (frame < this.keyFrames[i].getFrame()) {
                current = i;
                break;
            }
        }

        return current === -1 ? null : this.keyFrames[current];
    }

    getPrevious(frame: number): KeyFrame | null {
        if (this.keyFrames.length < 1) return null;
        let current = -1;

        // get last keyframe to time
        for (let i = this.keyFrames.length - 1; i >= 0; i--) {
            // $FlowFixMe
            if (frame > this.keyFrames[i].getFrame()) {
                current = i;
                break;
            }
        }

        return current === -1 ? null : this.keyFrames[current];
    }

    getBounding(frame: number): {
        previous: KeyFrame | null;
        next: KeyFrame | null;
    } {
        if (this.keyFrames.length < 1) {
            return {
                previous: null,
                next: null,
            };
        }

        for (let i = 0; i < this.keyFrames.length; i++) {
            // $FlowFixMe
            if (frame >= this.keyFrames[i].getFrame() && frame <= this.keyFrames[i + 1]?.getFrame()) {
                return {
                    previous: this.keyFrames[i] || null,
                    next: this.keyFrames[i + 1] || null,
                };
            }
        }

        return {
            previous: null,
            next: null,
        };
    }

    getValueLinear(frame: number): number {
        // calculate value for a frame, note that timing must have already been applied to determine frame
        const { previous: prevKeyFrame, next: nextKeyFrame } = this.getBounding(frame);

        if (!prevKeyFrame || !nextKeyFrame) {
            return 0;
        }

        const pFrame = (frame - prevKeyFrame.frame) / (nextKeyFrame.frame - prevKeyFrame.frame);
        const value = prevKeyFrame.getValue() + (nextKeyFrame.getValue() - prevKeyFrame.getValue()) * pFrame;
        return value;
    }
}

export { KeyFrame, KeyFrameHelper };
export default KeyFrameHelper;
