import styled from 'styled-components';

export const ElementTimelineTransitionsTrack = styled.div`
    z-index: 1;
    position: absolute;

    &:hover {
        z-index: 2;
    }
`;
