import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TextElement, VideoElement, ShapeElement, GroupElement } from '@bynder-studio/render-core';
import { Divider, Form } from '@bynder/design-system';
import useEditor from '~/hooks/useEditor';
import useForceUpdate from '~/hooks/useForceUpdate';
import { animationSelector } from 'packages/store/creativeEditor/creativeEditor.selectors';
import Animate from './Animate';
import { Section } from '../index.styled';
import TransitionIn from './TransitionIn';
import TransitionOut from './TransitionOut';

const Animations = ({ element, disabled }) => {
    const animation: any = useSelector(animationSelector);
    const { animationsConstant, animationsIn: transitionsIn, animationsOut: transitionsOut } = animation;

    const { creativeModel, frameRate } = useEditor();
    const forceUpdate = useForceUpdate();
    const {
        startFrame,
        duration,
        id,
        locked,
        animationIn: elementTransitionIn,
        animationOut: elementTransitionOut,
        animations: elementAnimations,
    } = element;

    const defaultAnimation = {
        animations: { ...animationsConstant },
        values: (elementAnimations && elementAnimations[0]?.config) || null,
    };

    const defaultTransitionIn = {
        animationIn: { ...transitionsIn },
        values: (elementTransitionIn && elementTransitionIn?.config) || null,
    };

    const defaultTransitionOut = {
        animationOut: { ...transitionsOut },
        values: (elementTransitionOut && elementTransitionOut?.config) || null,
    };

    const isText = element instanceof TextElement;
    const isVideo = element instanceof VideoElement;
    const isShape = element instanceof ShapeElement;
    const isGroup = element instanceof GroupElement;

    const elementType = isGroup ? 'GROUP' : isText ? 'TEXT' : isVideo ? 'VIDEO' : isShape ? 'SHAPE' : 'IMAGE';

    const handleAnimationsChange = (animationType, value) => {
        const param = {
            [animationType]: animationType === 'animations' ? [{ ...value }] : { ...value },
        } as any;

        if (elementAnimations) {
            if (animationType === 'animations') {
                param[animationType][0] = { ...value };
            } else {
                param[animationType] = { ...value };
            }
        }

        if (value === null) {
            param[animationType] = null;
        } else if (animationType === 'animationIn' || animationType === 'animationOut') {
            const inverseType = animationType === 'animationIn' ? 'animationOut' : 'animationIn';
            const activeElementData =
                animationType === 'animationIn'
                    ? elementTransitionOut?.el[inverseType]
                    : elementTransitionIn?.el[inverseType];
            if (activeElementData) {
                const allowedRemainingTime = duration - param[animationType].duration;
                if (allowedRemainingTime <= 0) {
                    creativeModel.updateElement(id, { [inverseType]: null });
                } else if (activeElementData.duration + param[animationType].duration > duration) {
                    const updatedInverseValue = { ...activeElementData.config, duration: allowedRemainingTime };
                    creativeModel.updateElement(id, { [inverseType]: updatedInverseValue });
                }
            }
        }

        creativeModel.updateElement(id, param);
    };

    useEffect(() => creativeModel?.onElementUpdate(id, forceUpdate), [creativeModel, forceUpdate]);

    return (
        <>
            <Section aria-labelledby="shots-transition-in-settings">
                <Form onSubmit={(event) => event.preventDefault()}>
                    <Form.Group>
                        <TransitionIn
                            selectedElement={element}
                            animations={transitionsIn}
                            values={(elementTransitionIn && elementTransitionIn?.config) || null}
                            defaults={defaultTransitionIn}
                            onChange={handleAnimationsChange}
                            frameRate={frameRate}
                            disabled={disabled || locked}
                            startFrame={startFrame}
                            elementType={elementType}
                        />
                    </Form.Group>
                </Form>
            </Section>
            <Divider />
            <Section aria-labelledby="shots-animate-settings">
                <Form onSubmit={(event) => event.preventDefault()}>
                    <Form.Group>
                        <Animate
                            selectedElement={element}
                            animations={animationsConstant}
                            values={(elementAnimations && elementAnimations[0]?.config) || null}
                            defaults={defaultAnimation}
                            onChange={handleAnimationsChange}
                            frameRate={frameRate}
                            disabled={disabled || locked}
                            startFrame={startFrame}
                        />
                    </Form.Group>
                </Form>
            </Section>
            <Divider />
            <Section aria-labelledby="shots-transition-out-settings">
                <Form onSubmit={(event) => event.preventDefault()}>
                    <Form.Group>
                        <TransitionOut
                            selectedElement={element}
                            animations={transitionsOut}
                            values={(elementTransitionOut && elementTransitionOut?.config) || null}
                            defaults={defaultTransitionOut}
                            onChange={handleAnimationsChange}
                            frameRate={frameRate}
                            disabled={disabled || locked}
                            startFrame={startFrame}
                            elementType={elementType}
                        />
                    </Form.Group>
                </Form>
            </Section>
        </>
    );
};

export default Animations;
