import React, { useEffect, useState } from 'react';
import { BaseMultiPageModel, ShapeElement } from '@bynder-studio/render-core';
import { Dropdown, Form } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconPlaylistAdd } from '@bynder/icons';
import { colorToCSS } from '~/common/editor/editorHelper';
import { useShotColor } from 'packages/pages/design/sidebar/shots/useShotColor';
import { useColorStyleModal } from 'packages/pages/editor/RightSideMenu/FormComponents/EditorColor/components/modals/useColorStylesModal';
import EditorColor from '../../../FormComponents/EditorColor';

type Props = {
    selectedElement: ShapeElement;
    creativeModel: BaseMultiPageModel;
    disabled: boolean;
};

export const FillColor = ({ selectedElement, creativeModel, disabled }: Props) => {
    const { translate } = useTranslate();
    const { id, locked } = selectedElement as ShapeElement;
    const [selectedFillColor, setSelectedFillColor] = useState(colorToCSS(selectedElement.fillColor));
    const baseColorParams = {
        selectedElement,
        brandColorProperty: 'fillBrandColors',
        propertyName: 'fillColor',
    };
    const { appliedCustomColors, elementColors, onAppliedClick, handleChangeColor } = useShotColor({
        ...baseColorParams,
        creativeModel,
    });
    const { renderColorStylesModal, openConfigureColorsModal } = useColorStyleModal({
        ...baseColorParams,
        title: translate('editor.sidebar.shots.shape.fill_color.configure.modal.title'),
    });

    useEffect(() => {
        setSelectedFillColor(colorToCSS(selectedElement.fillColor));
    }, [selectedElement.fillColor, id]);

    return (
        <Form.Group>
            <Form.Label>{translate('editor.sidebar.shots.shape.fill_color.label')}</Form.Label>
            <EditorColor
                property="fillColor"
                testId="shots_shape_fill__color"
                color={selectedFillColor}
                disabled={locked}
                onChange={handleChangeColor}
                elementColors={elementColors}
                onAppliedClick={onAppliedClick}
                selectedCustomColors={appliedCustomColors}
                selectedElement={selectedElement}
                configureColorsButton={
                    <Dropdown.Item isDisabled={disabled} icon={<IconPlaylistAdd />} onClick={openConfigureColorsModal}>
                        {translate('editor.sidebar.shots.shape.configure-fill-color-styles')}
                    </Dropdown.Item>
                }
            />
            {renderColorStylesModal()}
        </Form.Group>
    );
};

export default FillColor;
