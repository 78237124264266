import { SocketSubscribeConfig } from '../socketSubscribe';

export type ThumbnailWSData = {
    creativeId: number;
    thumbnailUrl: string | null;
    thumbnailProgress: number;
    thumbnailStatus: 'PROCESSING' | 'PROCESSED' | 'FAILED' | 'UNKNOWN' | 'NOT_FOUND';
};

type Options = SocketSubscribeConfig<
    {
        items: ThumbnailWSData[];
    },
    {
        creativeIds: number[];
    }
>;
type Params = {
    customerId: number;
    creativeIds: Options['publishBody']['creativeIds'];
    onMessage: Options['onMessage'];
};

export function subscribeDesignsThumbnailsOptions({ customerId, creativeIds, onMessage }: Params): Options {
    return {
        destination: `designs.${customerId}.thumbnails`,
        publishBody: {
            creativeIds,
        },
        onMessage,
    };
}
