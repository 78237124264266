import React from 'react';
import { Tooltip } from '@bynder/design-system';
import generateTestId from '~/helpers/testIdHelpers';

type TooltipProps = React.ComponentProps<typeof Tooltip>;
type Props = Omit<TooltipProps, 'children'> & {
    hasTooltip: boolean;
    children: React.JSX.Element;
    'data-testid'?: string;
};

const MaybeTooltip = ({ children, hasTooltip, 'data-testid': testId, ...tooltipProps }: Props) => {
    if (hasTooltip) {
        if (testId) {
            return (
                <Tooltip {...tooltipProps}>
                    <span {...generateTestId(testId)}>{children}</span>
                </Tooltip>
            );
        }

        return <Tooltip {...tooltipProps}>{children}</Tooltip>;
    }

    return children;
};

export default MaybeTooltip;
