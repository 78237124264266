import React from 'react';
import AuthorizationHelper from '../../../../helpers/AuthorizationHelper';
import permissions from '../../../../configs/permissions';
import PointerAction from './components/PointerAction';
import GroupAction from './components/GroupAction';
import UngroupAction from './components/UngroupAction';
import PresetAction from './components/PresetAction';
import VisibilityAction from './components/VisibilityAction';
import LockAction from './components/LockAction';
import DuplicateAction from './components/DuplicateAction';
import DeleteAction from './components/DeleteAction';
import { TimelineActionsWrapper } from './TimelineActions.styled';

export const TimelineActions = () => (
    <TimelineActionsWrapper>
        <PointerAction />
        <GroupAction />
        <UngroupAction />
        {AuthorizationHelper.hasPermission(permissions.PRESETS.WRITE) && <PresetAction />}
        <VisibilityAction />
        <LockAction />
        <DuplicateAction />
        <DeleteAction />
    </TimelineActionsWrapper>
);
