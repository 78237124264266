import styled from 'styled-components';
import { Flex, textStyles, token } from '@bynder/design-system';

export const Title = styled.div`
    ${textStyles.uiTextM}
    color: ${token.gray800};
`;

export const SubTitle = styled.div`
    ${textStyles.uiTextS}
    color: ${token.gray600};
`;

export const FullWidthFlex = styled(Flex)`
    & > div:first-child {
        flex-grow: 1;
    }
`;
