import React from 'react';
import { Flex } from '@bynder/design-system';
import { IconCheck } from '@bynder/icons';
import { VariationItemBySizeIdType } from 'packages/variation-export/types';
import VariationsThumbnail from '../sidebar/variations/VariationsThumbnail';
import { VariationDropdownItem, VariationDropdownItemInner } from './previewHeader.styled';
import { useTranslate } from '@bynder/localization';

type Props = {
    sizes: VariationItemBySizeIdType;
    id: string;
    name: string;
    created: string;
    onSelect: (event: any, id: any) => void;
    isIncluded: boolean;
    isInvalid: boolean;
    isCurrentVariation: boolean;
};

export const PreviewHeaderVariationDropdownItem = ({
    sizes,
    id,
    name,
    created,
    onSelect,
    isIncluded,
    isInvalid,
    isCurrentVariation,
}: Props) => {
    const { translate } = useTranslate();

    return (
        <VariationDropdownItem>
            <VariationDropdownItemInner onClick={(e) => onSelect(e, id)}>
                <Flex alignItems="center" className="w-100">
                    <VariationsThumbnail
                        variationId={id}
                        sizes={sizes}
                        isInvalid={isInvalid}
                        isCurrentVariation={isCurrentVariation}
                    />
                    <div className="variation--dropdown-item-name">
                        <span>{name}</span>
                        <span>{translate('design.header.variations.dropdown.edited', { created })}</span>
                    </div>
                    <div className="flex-shrink-0">{isIncluded && <IconCheck />}</div>
                </Flex>
            </VariationDropdownItemInner>
        </VariationDropdownItem>
    );
};

export default PreviewHeaderVariationDropdownItem;
