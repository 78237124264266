import React from 'react';
import { IconArrowDown, IconArrowUp } from '@bynder/icons';
import { ActionButton } from './List.styled';

type Props = {
    expand: boolean;
    isDisabled: boolean;
    onToggle?: () => {};
};

export const ExpandCollapseRow = ({ expand, onToggle, isDisabled }: Props) => {
    const icon = expand ? <IconArrowUp /> : <IconArrowDown />;

    return <ActionButton onClick={onToggle} variant="clean" icon={icon} isDisabled={isDisabled} />;
};

ExpandCollapseRow.defaultProps = {
    onToggle: () => {},
};
