import React, { useCallback } from 'react';
import { Dropdown } from '@bynder/design-system';
import { IconRename } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { ActionStatus, Entity, TemplateAction } from 'packages/hooks/useAccessRights/types';
import type { IsAvailable, Props } from './types';
import useAccessRights from 'packages/hooks/useAccessRights';

export const isRenameAvailable: IsAvailable = ({ isEntityActionAllowed, hiddenActions, template }) =>
    isEntityActionAllowed(TemplateAction.RENAME, Entity.TEMPLATE, template.grantedPermissions) !==
        ActionStatus.NOT_ALLOWED && !hiddenActions.includes('setRenameModalTemplate');

export const Rename = ({ template, menuActions }: Props) => {
    const { isEntityActionAllowed } = useAccessRights();
    const { translate } = useTranslate();

    const onClick = useCallback(() => menuActions.setRenameModalTemplate?.(template), [menuActions, template]);

    const isDisabled =
        isEntityActionAllowed(TemplateAction.RENAME, Entity.TEMPLATE, template.grantedPermissions) ===
        ActionStatus.DISABLED;

    const text = translate('pages.categories.category.template.menu.editDetails');

    return (
        <Dropdown.Item isDisabled={isDisabled} icon={<IconRename />} onClick={onClick}>
            {text}
        </Dropdown.Item>
    );
};
