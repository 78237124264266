import {
    BackgroundColor,
    ColorParams,
    GroupElement,
    ImageElement,
    ShapeElement,
    TextElement,
    VideoElement,
} from '@bynder-studio/render-core';
import { path, equals, clone } from 'rambda';
import { hexToRgb } from 'packages/pages/editor/RightSideMenu/FormComponents/ColorPicker/utils';
import { BrandColor, BrandColorPalette } from 'packages/store/brandColors/types';
import { rgbaToObject } from '~/common/editor/editorHelper';

export type AllElement = TextElement | ImageElement | VideoElement | ShapeElement | GroupElement;
type ElementColorProperties = { colorProperty: string; elementPropertyToUpdate: string };
type ElementColor = [ElementColorProperties, ColorParams];

// Support only 2 levels of nesting. If you want more think about some sort of recursion or lodash _.set() alternative
const elementColorProperties: ElementColorProperties[] = [
    { colorProperty: '', elementPropertyToUpdate: 'color' }, // is case of BackgroundColor element
    { colorProperty: '', elementPropertyToUpdate: 'fillColor' },
    { colorProperty: '', elementPropertyToUpdate: 'borderColor' },
    { colorProperty: 'color', elementPropertyToUpdate: 'dropShadow' },
    { colorProperty: 'color', elementPropertyToUpdate: 'textBackground' },
];

// get all color properties from element with brand color attached
export const getElementColors = (element: AllElement): ElementColor[] =>
    elementColorProperties.reduce((acc, properties) => {
        const { colorProperty, elementPropertyToUpdate } = properties;
        const colorPath = [elementPropertyToUpdate, colorProperty].filter((prop) => !!prop).join('.');
        const color: ColorParams | undefined = path(colorPath, element);
        if (color && color.brandColorId) {
            return [...acc, [properties, color]];
        }
        return acc;
    }, [] as ElementColor[]);

export const findBrandColorById = (id: number, palettes: BrandColorPalette[]): BrandColor | undefined =>
    palettes
        .reduce((acc, palette) => (palette.brandColors ? [...acc, ...palette.brandColors] : acc), [] as BrandColor[])
        .find((brandColor) => brandColor.id === id);

export const createNewElementColor = (brandColorRGBA: string, elementColor: ColorParams): ColorParams => ({
    ...elementColor,
    ...rgbaToObject(brandColorRGBA),
});

const updateTextColors = (
    formattedText: TextElement['formattedText'],
    palettes: BrandColorPalette[],
): TextElement['formattedText'] => {
    const { runs } = formattedText;

    return {
        ...formattedText,
        runs: runs.map((item) => {
            const run = clone(item);
            const brandColorId = run.color.brandColorId;

            if (brandColorId) {
                const brandColor = findBrandColorById(brandColorId, palettes);
                if (brandColor) {
                    run.color = createNewElementColor(hexToRgb(brandColor.hexCode, brandColor.alpha), {
                        ...run.color,
                        brandColorId,
                    });
                }
            }

            return run;
        }),
    };
};

// find difference between element color and brand color. If there is no diff return nothing
export const matchColors = (elementColor: ColorParams, brandColor: BrandColor) => {
    const brandColorRGBA = hexToRgb(brandColor.hexCode, brandColor.alpha);
    const { red, green, blue, opacity } = elementColor;
    const elementColorRGBA = `rgba(${red},${green},${blue},${opacity})`;
    return brandColorRGBA === elementColorRGBA ? null : createNewElementColor(brandColorRGBA, elementColor);
};

// Support only 2 levels of nesting. If you want more think about some sort of recursion or lodash _.set() alternative
export const updateElementColor = (
    acc: any,
    properties: ElementColorProperties,
    element: any,
    elementColorToBeUpdated: any,
) => {
    if (properties.colorProperty) {
        acc[properties.elementPropertyToUpdate] = { ...element[properties.elementPropertyToUpdate] };
        acc[properties.elementPropertyToUpdate][properties.colorProperty] = {
            ...acc[properties.elementPropertyToUpdate][properties.colorProperty],
            ...elementColorToBeUpdated,
        };
    } else {
        acc[properties.elementPropertyToUpdate] = {
            ...element[properties.elementPropertyToUpdate],
            ...elementColorToBeUpdated,
        };
    }
    return acc;
};

// Match and update all color properties to up-to-date brand colors
export const syncBrandColors = (creativeModel: any, palettes: BrandColorPalette[], save: Function) => {
    let isChanged = false;
    [creativeModel.getBackgroundColor(), ...creativeModel.getAllElementsRecursively()].forEach((element) => {
        const elementColors = getElementColors(element);
        const elementColorsToBeUpdated = elementColors.reduce(
            (acc, elementColor) => {
                const [properties, elementColorObj] = elementColor;
                const brandColor = findBrandColorById(elementColorObj.brandColorId as number, palettes);

                if (!brandColor) {
                    return updateElementColor(acc, properties, element, { brandColorId: null });
                }

                const elementColorToBeUpdated = matchColors(elementColorObj, brandColor);

                if (elementColorToBeUpdated) {
                    return updateElementColor(acc, properties, element, elementColorToBeUpdated);
                }

                return acc;
            },
            {} as Record<string, any>,
        );

        if (element instanceof TextElement) {
            const formattedText = element.getTextProps();
            const newFormattedText = updateTextColors(formattedText, palettes);

            if (!equals(newFormattedText, formattedText)) {
                elementColorsToBeUpdated.formattedText = newFormattedText;
            }
        }

        // update element only if it is necessary
        if (Object.keys(elementColorsToBeUpdated).length) {
            isChanged = true;
            if (element instanceof BackgroundColor) {
                creativeModel.updateGlobalProperty('BACKGROUND_COLOR', elementColorsToBeUpdated);
            } else {
                creativeModel.updateElement(element.id, elementColorsToBeUpdated);
            }
        }
    });

    if (isChanged) save();
};
