import { token, List } from '@bynder/design-system';
import styled from 'styled-components';

export const DropdownButton = styled(List.Item)`
    margin-top: 0;
`;

export const DropdownWrapper = styled.div`
    padding-left: ${token.spacing6};
`;

export const PermissionGroup = styled.span``;

export const PermissionWithSeparator = styled.span`
    &:after {
        content: '•';
        margin-left: ${token.spacing3};
        margin-right: ${token.spacing3};
    }
`;
