import { dropLast } from 'rambda';

export const elementHasAnimations = (element) => elementHasAnimation(element) || elementHasTransitions(element);
export const elementHasTransitions = (element) => element.animationOut || element.animationIn;
export const elementHasAnimation = (element) => element.animations && element.animations.length > 0;
export const elementHasDurationalAnimation = (element) => element.animations && element.animations.length;

export const getAnimationsDepth = (item) => {
    let depth = 0;

    if (item.animationsExpanded) {
        if (elementHasTransitions(item.element)) {
            depth++;
        }

        if (elementHasAnimation(item.element)) {
            depth++;
        }
    }

    return depth;
};

export const computeElementDepth = (item, idx = 0, depth = 1, lastRenderOrder = 0, lastIncrementForAnimations = 0) => {
    const isGroup = !!item.element.children;
    const isExpanded = isGroup && item.expanded;
    const isTopLevel = depth === 1;

    if (isTopLevel) {
        depth += getAnimationsDepth(item);

        if (!isGroup || !isExpanded) {
            return depth;
        }

        if (isExpanded) {
            return computeElementDepth(item, 0, depth + 1, lastRenderOrder, false);
        }
    }

    const child = isGroup && item.children[idx];
    const childIsGroup = child && !!child.children;
    const childIsExpanded = childIsGroup && child.expanded;

    const hasSameRenderOrder = child && child.element.renderOrder === lastRenderOrder;

    if (!child) {
        return depth;
    }

    const incrementForAnimations = getAnimationsDepth(child);

    if (!childIsGroup || !childIsExpanded) {
        depth = depth + Math.max(incrementForAnimations - hasSameRenderOrder * lastIncrementForAnimations, 0);
        const incrementedDepth = hasSameRenderOrder || !idx ? depth : depth + 1;

        return computeElementDepth(item, idx + 1, incrementedDepth, child.element.renderOrder, incrementForAnimations);
    }

    let incrementedDepth = hasSameRenderOrder || !idx ? depth + 1 : depth + 2;

    incrementedDepth += Math.max(incrementForAnimations - hasSameRenderOrder * lastIncrementForAnimations, 0);
    const newDepth = computeElementDepth(child, 0, incrementedDepth, 0);

    return computeElementDepth(item, idx + 1, newDepth, child.element.renderOrder, false);
};

export const createTrackRepresentations = (items) => {
    const elementsWithSubTracks = items.map((item) => ({
        id: item.element.id,
        renderOrder: item.element.renderOrder,
        subTracks: computeElementDepth(item),
    }));

    const trackRepresentations = elementsWithSubTracks.reduce((acc, curr) => {
        if (!acc.length) {
            return [curr];
        }

        const lastElement = acc[acc.length - 1];
        const haveSameRenderOrder =
            lastElement.renderOrder && curr.renderOrder && lastElement.renderOrder === curr.renderOrder;

        if (haveSameRenderOrder) {
            if (lastElement.subTracks > curr.subTracks) {
                return acc;
            }

            return dropLast(1, acc).concat(curr);
        }

        return acc.concat(curr);
    }, []);

    return trackRepresentations.map((track, idx) => ({ ...track, name: `V${idx + 1}` }));
};
