import React, { useEffect, useRef, createContext, useState, useMemo } from 'react';
import type { PagePreviewRenderer } from '@bynder-studio/render-core';
import useDesign from 'packages/pages/design/hooks/useDesign';
import useEditor from '~/hooks/useEditor';
import useVariations from 'packages/pages/design/hooks/useVariations';

type ContextData = {
    pagePreviewImages: { src: string; pageId: string; loading: boolean }[];
    pagePreviewRenderer: PagePreviewRenderer | null;
};

export const PagePreviewContext = createContext({} as ContextData);

export function PagePreviewProvider({ children, isEditor = false }) {
    const { creativeModelFactory: designCreativeFactory } = useDesign();
    const { creativeModelFactory: editorCreativeFactory } = useEditor();
    const [pagePreviewImages, setPagePreviewImages] = useState<{ src: string; pageId: string; loading: boolean }[]>([]);
    const pagePreviewRendererInstance = useRef<PagePreviewRenderer | null>(null);
    const { currentVariation } = useVariations();

    const creativeModelFactory = isEditor ? editorCreativeFactory : designCreativeFactory;

    useEffect(() => {
        if (!creativeModelFactory) {
            return;
        }

        const pagePreviewRenderer = creativeModelFactory.getPagePreviewRenderer();
        pagePreviewRendererInstance.current = pagePreviewRenderer;

        pagePreviewRenderer.setPreviewSize(128, 128);

        const renderStartListener = ({ index }) => {
            setPagePreviewImages((prevState) => {
                const temp = [...prevState];
                temp[index] = { ...temp[index], loading: true };

                return temp;
            });
        };

        const renderEndListener = ({ index, dataUrl, metaData }) => {
            setPagePreviewImages((prevState) => {
                const temp = [...prevState];
                temp[index] = { src: dataUrl, pageId: metaData.id, loading: false };

                return temp;
            });
        };

        const renderStartUnsubscribe = pagePreviewRenderer.onRenderStart(renderStartListener);
        const renderEndUnsubscribe = pagePreviewRenderer.onRenderEnd(renderEndListener);

        return () => {
            renderStartUnsubscribe();
            renderEndUnsubscribe();
        };
    }, [creativeModelFactory]);

    useEffect(() => {
        if (!pagePreviewRendererInstance.current || (!isEditor && !currentVariation)) {
            return;
        }

        pagePreviewRendererInstance.current.render();
    }, [currentVariation]);

    const value = useMemo(
        () => ({
            pagePreviewRenderer: pagePreviewRendererInstance.current,
            pagePreviewImages,
        }),
        [pagePreviewImages],
    );

    return <PagePreviewContext.Provider value={value}>{children}</PagePreviewContext.Provider>;
}
