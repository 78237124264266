import React from 'react';
import { Table } from '@bynder/design-system';
import { getFormattedTime } from 'packages/pages/components/card/utils';
import { Creative } from 'packages/store/creatives/types';

const DurationCell = ({ design }: { design: Creative }) => (
    <Table.Cell textFlow="no-wrap">{getFormattedTime(design)}</Table.Cell>
);

export default DurationCell;
