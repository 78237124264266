import React from 'react';
import { Dropdown, Button, Divider } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconDelete, IconMore, IconRename } from '@bynder/icons';
import { PageMeta } from '../types';

type OptionsDropdownType = {
    actions: {
        onRename: (page: PageMeta) => void;
        onDelete: (page: PageMeta) => void;
    };
    page: PageMeta;
    pageCount: number;
};

const PageManageOptions = ({ actions, page, pageCount }: OptionsDropdownType) => {
    const { translate } = useTranslate();
    const { onRename, onDelete } = actions;
    const isDeleteDisabled = pageCount < 2;

    return (
        <Dropdown
            trigger={({ isOpen, ...triggerProps }) => (
                <Button
                    title={translate('editor.page.options.title')}
                    variant="clean"
                    icon={<IconMore />}
                    {...triggerProps}
                />
            )}
            position="bottom-right"
        >
            <Dropdown.Item icon={<IconRename />} onClick={() => onRename(page)}>
                {translate('editor.page.options.rename')}
            </Dropdown.Item>
            <Divider />
            <Dropdown.Item icon={<IconDelete />} isDisabled={isDeleteDisabled} onClick={() => onDelete(page)}>
                {translate('editor.page.options.delete')}
            </Dropdown.Item>
        </Dropdown>
    );
};

export default PageManageOptions;
