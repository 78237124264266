import React, { useCallback } from 'react';
import { Dropdown } from '@bynder/design-system';
import { IconFileMove } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { ActionStatus, Entity, TemplateAction } from 'packages/hooks/useAccessRights/types';
import type { IsAvailable, Props } from './types';
import useAccessRights from 'packages/hooks/useAccessRights';

export const isMoveToCategoryAvailable: IsAvailable = ({ isEntityActionAllowed, hiddenActions, template }) =>
    isEntityActionAllowed(TemplateAction.MOVE, Entity.TEMPLATE, template.grantedPermissions) !==
        ActionStatus.NOT_ALLOWED && !hiddenActions.includes('setMoveModalTemplate');

export const MoveToCategory = ({ template, menuActions }: Props) => {
    const { isEntityActionAllowed } = useAccessRights();
    const { translate } = useTranslate();

    const onClick = useCallback(() => menuActions.setMoveModalTemplate?.([template]), [menuActions, template]);

    const isDisabled =
        isEntityActionAllowed(TemplateAction.MOVE, Entity.TEMPLATE, template.grantedPermissions) ===
        ActionStatus.DISABLED;

    return (
        <Dropdown.Item isDisabled={isDisabled} icon={<IconFileMove />} onClick={onClick}>
            {translate('pages.categories.category.template.menu.move')}
        </Dropdown.Item>
    );
};
