import React, { useCallback, useState, memo, useMemo } from 'react';
import { Tooltip, Dropdown, Button, Divider } from '@bynder/design-system';
import {
    IconAdd,
    IconBuildShape,
    IconCircle,
    IconImage,
    IconRectangle,
    IconTextField,
    IconVideoTemplate,
} from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import useElementCreator from 'packages/pages/editor/useElementCreator';
import useCreateElementHotkeys from 'packages/pages/editor/useCreateElementHotkeys';
import PresetModal from '~/common/editor/presets/PresetsModal';
import permissions from '~/configs/permissions';
import AuthorizationHelper from '~/helpers/AuthorizationHelper';
import ContentPickerModal from 'packages/pages/editor/ContentPickerModal';
import { isImageOrVideo } from '~/common/editor/helpers';
import usePresets from '~/common/editor/presets/usePresets';
import { NEW_LAYER } from '~/helpers/textConstants';
import { AddElementWrapper } from './AddLayerButton.styled';

const EMPTY_SELECTED_OBJECT = {};

const AddLayerButton = () => {
    const elementCreator = useElementCreator();
    const [assetType, setAssetType] = useState('');
    const [showContentSelectorModal, setShowContentSelectorModal] = useState(false);
    const [showPresetModal, setShowPresetModal] = useState(false);
    const { applyPreset } = usePresets();
    const { translate } = useTranslate();

    const handleAddPreset = useCallback(
        (selectedPreset) => {
            // Temporary solution
            const finalSelectedElement = selectedPreset;
            finalSelectedElement.elements[Object.keys(finalSelectedElement.elements)].name = selectedPreset.name;

            applyPreset(finalSelectedElement);
            setShowPresetModal(false);
        },
        [applyPreset],
    );

    const handleConfirmSelection = useCallback(
        (source) => {
            elementCreator.createImageElement(source);
            setShowContentSelectorModal(false);
        },
        [elementCreator.createImageElement],
    );

    const openContentPicker = useCallback(() => {
        setAssetType('IMAGE');
        setShowContentSelectorModal(true);
    }, []);

    useCreateElementHotkeys(elementCreator, openContentPicker);

    const contentPickerModalProps = useMemo(
        () => ({
            type: assetType,
            show: showContentSelectorModal,
            selectedObject: EMPTY_SELECTED_OBJECT,
            frameRate: undefined,
            toggleModal: setShowContentSelectorModal,
            confirmSelection: handleConfirmSelection,
            resizableRange: true,
            isEditorPage: true,
        }),
        [assetType, showContentSelectorModal, isImageOrVideo],
    );

    return (
        <div>
            {AuthorizationHelper.hasPermission(permissions.PRESETS.READ) && showPresetModal && (
                <PresetModal
                    toggleModal={(show) => setShowPresetModal(show)}
                    confirmSelection={handleAddPreset}
                    show={showPresetModal}
                />
            )}
            <Dropdown
                position="bottom-left"
                trigger={({ isOpen, ...triggerProps }) => (
                    <Tooltip content={NEW_LAYER}>
                        <AddElementWrapper>
                            <Button variant="clean" className="add--layer-btn" icon={<IconAdd />} {...triggerProps} />
                        </AddElementWrapper>
                    </Tooltip>
                )}
            >
                {AuthorizationHelper.hasPermission(permissions.ASSETS.READ) && (
                    <Dropdown.Item icon={<IconImage />} onClick={openContentPicker}>
                        {translate('editor.layers.sidebar.image')}
                    </Dropdown.Item>
                )}
                <Dropdown.Item icon={<IconTextField />} onClick={elementCreator.createTextElement}>
                    {translate('editor.layers.sidebar.text')}
                </Dropdown.Item>
                <Dropdown.Item
                    icon={<IconBuildShape />}
                    submenu={
                        <>
                            <Dropdown.Item icon={<IconRectangle />} onClick={elementCreator.createRectangleElement}>
                                {translate('editor.layers.sidebar.rectangle')}
                            </Dropdown.Item>
                            <Dropdown.Item icon={<IconCircle />} onClick={elementCreator.createEllipseElement}>
                                {translate('editor.layers.sidebar.ellipse')}
                            </Dropdown.Item>
                        </>
                    }
                >
                    {translate('editor.layers.sidebar.shape')}
                </Dropdown.Item>
                <Divider />
                {AuthorizationHelper.hasPermissions([permissions.ASSETS.READ, permissions.PRESETS.READ]) && (
                    <Dropdown.Item icon={<IconVideoTemplate />} onClick={() => setShowPresetModal(true)}>
                        {translate('editor.layers.sidebar.preset')}
                    </Dropdown.Item>
                )}
            </Dropdown>
            {showContentSelectorModal && <ContentPickerModal {...contentPickerModalProps} />}
        </div>
    );
};

export default memo(AddLayerButton);
