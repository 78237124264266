import { useEffect } from 'react';

const useClickAway = (ref, callback) => {
    useEffect(() => {
        const listener = (e) => {
            if (ref?.current?.contains(e.target)) {
                return;
            }

            callback(e);
        };

        document.addEventListener('mousedown', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
        };
    }, [ref, callback]);
};

export default useClickAway;
