import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { subscribeAssetsStatusOptions, useSocketSubscribe } from 'packages/socket';
import { customerIdSelector } from 'packages/store/user/user.selectors';
import { getPendingAssets } from '~/store/assets/assets.selectors';
import { updateAssetsStatus } from '~/store/assets/assets.actions';
import { updateCollectionsThumbnails } from '~/store/collections/collections.actions';
import useSuperSet from './useSuperSet';

const emptyAssetsIds: string[] = [];

/**
 * Listen for assets thumbnail updates via websockets
 * unsubscribe when component unmounts
 */
// eslint-disable-next-line import/no-unused-modules
export default function useAssetStatusUpdates() {
    const pendingAssets: string[] = useSelector(getPendingAssets);
    const customerId = useSelector(customerIdSelector);

    const dispatch = useDispatch();

    // prevents unnecessary re-subscriptions when pendingIds change
    // the array stays the same until there is no new items in pendingIds
    const memoizedPendingIds = useSuperSet(pendingAssets);
    const assetIds = pendingAssets.length ? memoizedPendingIds : emptyAssetsIds;

    const subscribe = useSocketSubscribe(
        subscribeAssetsStatusOptions({
            customerId,
            assetIds,
            onMessage: ({ items }) => {
                dispatch(updateAssetsStatus(items));
                dispatch(updateCollectionsThumbnails(items));
            },
        }),
    );

    useEffect(() => {
        if (!assetIds.length) {
            return;
        }

        return subscribe();
    }, [subscribe, assetIds]);
}
