import React from 'react';
import { token } from '@bynder/design-system';
import { IconReplay } from '@bynder/icons';
import { ApplyFont, LettersThumbnail, OverrideIcon } from '../Component.styled';

const FontThumbnail = ({ id, url, overrides = 0 }: { id: string; url: string; overrides?: number }) => (
    <LettersThumbnail
        variant="clean"
        backgroundColor="white"
        icon={
            <ApplyFont name={id} fontUrl={url}>
                Aa
            </ApplyFont>
        }
    >
        {overrides > 0 && (
            <OverrideIcon>
                <IconReplay style={{ color: token.gray10 }} size="12" />
            </OverrideIcon>
        )}
    </LettersThumbnail>
);

export default FontThumbnail;
