import styled from 'styled-components';
import { Form } from '@bynder/design-system';

export const Dot = styled.span`
    margin-left: 7px;
    margin-right: 7px;
`;

export const StyledLabel = styled(Form.Label)`
    margin-bottom: 0;
`;

export const PagesDropdownContainer = styled('div')`
    max-width: 400px;
`;
