import { token, VerticalNav } from '@bynder/design-system';
import styled from 'styled-components';

export const VerticalNavBackAction = styled(VerticalNav.Item)`
    margin-bottom: ${token.spacing6} !important;
`;

// TODO: remove it once DS modal sidebar will have a property hasNoBorder
export const ModalSidebar = styled.div`
    grid-area: sidebar;
`;

export const StyledSidebar = styled.aside`
    overflow-y: auto;
    box-sizing: border-box;
    width: 272px;
    height: 100%;
    padding: ${token.spacing5} 0;
`;
