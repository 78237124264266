import { useCallback, useEffect, useMemo, useState } from 'react';
import { type CreativeModelFactory, PlaybackRenderer } from '@bynder-studio/render-web';
import debounce from '~/helpers/debounce';
import useCurrentPage from './useCurrentPage';

export default function usePlaybackRenderer(
    factory: CreativeModelFactory | null | undefined,
    canvasRenderer: ReturnType<CreativeModelFactory['getCreativeRenderer']> | null,
) {
    const isPlaybackRenderer = canvasRenderer instanceof PlaybackRenderer;
    const [isPlaying, setIsPlaying] = useState(false);

    // TODO: fix PlaybackRenderer and remove this hack
    // quicky togglining canvasRenderer.startPlayback() after canvasRenderer.pausePlayback()
    // leads to a bug when the playback speed is increased
    const debouncedSetIsPlaying = useMemo(() => {
        const debouncedSetIsPlaying = debounce(setIsPlaying, 10, true);

        return debouncedSetIsPlaying;
    }, []);

    const duration = isPlaybackRenderer ? canvasRenderer.getPlaybackDuration().getDuration() : 0;

    useEffect(() => {
        if (!isPlaybackRenderer) {
            return;
        }

        if (isPlaying) {
            canvasRenderer.startPlayback();
        } else {
            canvasRenderer.pausePlayback();
        }
    }, [isPlaying, isPlaybackRenderer]);

    const setPausePlayback = useCallback(() => {
        debouncedSetIsPlaying(false);
    }, []);

    const setPlayPlayback = useCallback(() => {
        debouncedSetIsPlaying(true);
    }, []);

    const onPageChange = useCallback(() => {
        if (!isPlaybackRenderer) {
            return;
        }

        canvasRenderer.pausePlayback();
        setIsPlaying(false);
    }, [isPlaybackRenderer, canvasRenderer]);

    useCurrentPage(factory, onPageChange);

    // Pause playback when the component is unmounted
    useEffect(() => {
        return onPageChange;
    }, [onPageChange]);

    return {
        duration,
        setPausePlayback,
        setPlayPlayback,
    };
}
