export const leftToStartFrame = (trackDuration, left) => {
    return left && Math.round((trackDuration / 100) * left);
};

export const widthToDuration = (trackDuration, width) => {
    return width && Math.round((trackDuration / 100) * width);
};

export const TRACK_HEIGHT = 44;

export const computeOverflow = (elementContainerRef) => {
    const elementNodes = elementContainerRef.current.querySelectorAll('.timeline__element');
    const rightMost = [].reduce.call(
        elementNodes,
        (acc, el) => {
            const { right } = el.getBoundingClientRect();
            return right > acc ? right : acc;
        },
        0,
    );

    const containerBoundingBox = elementContainerRef.current.getBoundingClientRect();
    const containerWidth = containerBoundingBox.width;
    const overflowAmount = rightMost - containerBoundingBox.right;

    const overflow = 100 / (1 + containerWidth / overflowAmount);
    const overflowRaw = overflowAmount / (containerWidth / 100);

    return [Math.max(overflow, 0), Math.max(overflowRaw, 0)];
};

export const pixelToPercentage = (trackWidth, subWidth) => {
    return (subWidth / trackWidth) * 100;
};

export const getDropCommand = (dropParams) => {
    const { isCtrlHeld, onGroup, inGroup, isExpanded, onElement } = dropParams;

    if (onElement) {
        return isCtrlHeld ? 'createGroup' : 'update';
    }

    if (inGroup || isExpanded) {
        return 'addToGroup';
    }

    if (onGroup && isCtrlHeld) {
        return 'addToGroup';
    }

    return 'update';
};
