import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ModalBase, Select } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconUpload, IconVideoFrame } from '@bynder/icons';
import useAccessRights from 'packages/hooks/useAccessRights';
import useQueryParams from 'packages/hooks/useQueryParams';
import { initCategories } from 'packages/store/categories/categories.actions';
import { getCategories } from 'packages/store/categories/categories.selectors';
import { CollectionSubType, CollectionType } from 'packages/store/collections/types';
import { getCreativesActionProgress } from 'packages/store/creatives/creatives.selectors';
import { dropImportedTemplate } from 'packages/store/importTemplate/importTemplate.actions';
import {
    getImportActionProgress,
    getImportedTemplate,
    getImportTemplateAnalysis,
    getImportTemplateErrors,
    getImportTemplateMultiDesignMode,
    getImportTemplateType,
    getImportTemplateValidationErrors,
} from 'packages/store/importTemplate/importTemplate.selectors';
import { ImportedTemplate, ImportType } from 'packages/store/importTemplate/types';
import { setPage } from 'packages/store/newDesign/newDesign.actions';
import {
    getBlankDesignActionProgress,
    getNewDesignPage,
    getNewDesignPageHistory,
} from 'packages/store/newDesign/newDesign.selectors';
import { getFigmaMultiDesignMode, getFigmaState } from 'packages/store/figma/figma.selectors';
import { Template } from 'packages/store/templates/types';
import features from '~/configs/features';
import roles from '~/configs/roles';
import { deselectTemplate, selectTemplate, initializeTemplates } from '~/store/templates/templates.actions';
import { selectedTemplateSelector } from '~/store/templates/templates.selectors';
import { selectedGallerySelector } from '~/store/gallery/gallery.selectors';
import { OwnershipFilterType } from '../components/filters/components/Ownership/types';
import DesignCreateSidebar, { getSidebarItems } from './components/Sidebar';
import { DesignSubPages } from './components/Sidebar/types';
import getTemplateManagementSidebarItems from './components/TemplateManagementSidebar';
import { ModalProps } from './types';
import useCreateActionMap from './useCreateActionMap';
import useCreationDisabledMap from './useCreationDisabledMap';
import useRouter from './useRouter';
import useTitleMap from './useTitleMap';
import { TemplateModalContent } from '../categories/useTemplatePreviewModal';
import useTemplateRenameModal from '../components/templates/useTemplateRenameModal';
import useTemplateDeleteModal from './useTemplateDeleteModal';
import useTemplateShareModal from 'packages/pages/shareModal/useTemplateShareModal';
import useTemplateMoveModal from '../components/templates/useTemplateMoveModal';
import filtersStorage from 'packages/hooks/filtersStorage';
import generateTestId from '~/helpers/testIdHelpers';
import { setFigmaMultiDesignMode } from 'packages/store/figma/figma.actions';
import modalContainer from 'packages/common/modalContainer';

const DesignCreateModal = ({ isOpen, onClose, collectionId = undefined }: ModalProps) => {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const { isPlatformAllowed, isAccountRoleAssigned } = useAccessRights();
    const { resetQueryParams, getQueryParam, setQueryParam } = useQueryParams();
    const activePage = useSelector(getNewDesignPage);
    const selectedTemplate: Template = useSelector(selectedTemplateSelector);
    const selectedGalleryTemplate: Template = useSelector(selectedGallerySelector);
    const categories = useSelector(getCategories);
    const { addingInProgress: isCreatingFromTemplate } = useSelector(getCreativesActionProgress);
    const { addingInProgress: isCreatingBlank } = useSelector(getBlankDesignActionProgress);
    const { addingInProgress: isCreatingFromDesignFile } = useSelector(getImportActionProgress);
    const pageHistory = useSelector(getNewDesignPageHistory);
    const importedTemplate: ImportedTemplate = useSelector(getImportedTemplate);
    const importedErrors = useSelector(getImportTemplateErrors);
    const importedValidationErrors = useSelector(getImportTemplateValidationErrors);
    const importType = useSelector(getImportTemplateType);
    const analysis = useSelector(getImportTemplateAnalysis);
    const multiDesignMode = useSelector(getImportTemplateMultiDesignMode);
    const { selectedFrameIds: selectedFrames } = useSelector(getFigmaState);
    const figmaImportType = useSelector(getFigmaMultiDesignMode) ? 'designs' : 'pages';

    const { setRenameModalTemplate, renderModalTemplateRename } = useTemplateRenameModal();

    const figmaImportTypeLabels = {
        pages: translate('modal.design.create.design.figma.import-type.pages'),
        designs: translate('modal.design.create.design.figma.import-type.designs'),
    };

    const setFigmaImportType = (value) => {
        if (value === 'pages') {
            dispatch(setFigmaMultiDesignMode(false));
            return;
        }

        dispatch(setFigmaMultiDesignMode(true));
    };

    const categoryId = getQueryParam('categoryId');
    const { clearFiltersFromStorage } = filtersStorage();
    const clearCategoryFilters = () => {
        if (!categoryId) {
            return;
        }

        if (categoryId === 'ALL') {
            clearFiltersFromStorage('newDesign:categories');
        }

        clearFiltersFromStorage(`newDesign:categoryDetails:${categoryId}`);
    };

    const { setDeleteModalTemplate, renderModalTemplateDelete } = useTemplateDeleteModal(clearCategoryFilters);
    const { setShareModalTemplate, renderModalTemplateShare } = useTemplateShareModal({});

    const selectedTemplateId = getQueryParam('template');

    const { setMoveModalTemplate, renderModalTemplateMove } = useTemplateMoveModal({
        isOverview:
            activePage === DesignSubPages.CATEGORIES ||
            (activePage === DesignSubPages.TEMPLATE_PREVIEW && categoryId === 'ALL'),
    });

    useEffect(() => {
        if (activePage === DesignSubPages.GALLERY_PREVIEW && !selectedGalleryTemplate) {
            dispatch(setPage(DesignSubPages.GALLERY));
        }

        if (activePage === DesignSubPages.TEMPLATE_PREVIEW && !selectedTemplateId) {
            let subPage;

            if (categoryId === 'ALL') {
                subPage = DesignSubPages.CATEGORIES;
            } else if (categoryId) {
                subPage = DesignSubPages.CATEGORY_DETAILS;
            } else {
                subPage = DesignSubPages.ALL;
            }

            dispatch(setPage(subPage));
        }
    }, [activePage, selectedTemplate, selectedGalleryTemplate, dispatch, selectedTemplateId, categoryId]);

    const type: CollectionType = 'CREATIVE';
    const subType: CollectionSubType = 'TEMPLATE';
    const isTrialAccount =
        isPlatformAllowed([features.TRIAL_ENABLED]) && isAccountRoleAssigned(roles.video_brand_studio.video.manage);

    useLayoutEffect(() => {
        if (activePage !== DesignSubPages.CATEGORIES && activePage !== DesignSubPages.ALL) {
            dispatch(
                initCategories({
                    type,
                    subType,
                    ownershipSource: isTrialAccount
                        ? OwnershipFilterType.OWNED_BY_OTHERS
                        : OwnershipFilterType.OWNED_BY_ANYONE,
                    includeEmptyCollection: false,
                }),
            );
        }
    }, []);

    const modalRouter = useRouter();
    const { isCreationDisabled } = useCreationDisabledMap();
    const { createActionMap } = useCreateActionMap(collectionId);
    const modalTitleMap = useTitleMap();

    const onHandleClose = () => {
        onClose();
        dispatch(setPage(DesignSubPages.ALL));
        dispatch(dropImportedTemplate());
        dispatch(deselectTemplate());
        resetQueryParams(['template', 'create']);
    };

    const permissions = {
        isImportDisabled: !isPlatformAllowed([features.VIDEOS_ENABLED, features.IMAGES_ENABLED], false),
        isGalleryDisabled: !isPlatformAllowed([features.GALLERY_ENABLED]),
        isFigmaImportDisabled: !isPlatformAllowed([features.FIGMA_IMPORT]),
    };

    const isTemplatePreviewEnabled = () => {
        return activePage !== DesignSubPages.TEMPLATE_PREVIEW && (selectedTemplate || selectedGalleryTemplate);
    };

    const onCreate = () => {
        if (isCreationDisabled()) {
            return;
        }

        if (importType === ImportType.DESIGN_FILE || activePage === DesignSubPages.FIGMA_IMPORT) {
            onClose();
        }

        if (isCreatingFromTemplate || isCreatingBlank || isCreatingFromDesignFile) {
            return;
        }

        createActionMap[activePage]();
    };

    const getModalTitle = () => modalTitleMap[activePage]();

    const onKeyEnter = (e) => {
        if (e.target.tagName === 'INPUT') {
            return;
        }

        if (e.key === 'Enter') {
            onCreate();
        }
    };

    const getValidArtboardsNumber = analysis?.designCreationResponses?.length - importedValidationErrors?.length || 1;

    useEffect(() => {
        dispatch(deselectTemplate());

        return () => {
            dispatch(deselectTemplate());
        };
    }, [getQueryParam('categoryId')]);

    useEffect(() => {
        const id = Number(selectedTemplateId);

        if (selectedTemplate || !id) {
            return;
        }

        dispatch(selectTemplate({ id: id, name: '' }));
    }, [selectedTemplateId, selectedTemplate, dispatch]);

    const getTertiaryButton = () => {
        if (
            activePage === DesignSubPages.IMPORT_STATUS &&
            importType === ImportType.XML &&
            (!importedTemplate || importedErrors || importedValidationErrors?.length)
        ) {
            return (
                <Button
                    variant="secondary"
                    title={translate('modal.design.create.another')}
                    onClick={() => dispatch(setPage(DesignSubPages.IMPORT))}
                    icon={<IconUpload />}
                >
                    {translate('modal.design.create.another')}
                </Button>
            );
        } else if (isTemplatePreviewEnabled()) {
            return (
                <Button
                    variant="secondary"
                    title={translate('modal.design.create.template.preview')}
                    onClick={() => {
                        if (selectedTemplate) {
                            setQueryParam('template', selectedTemplate.id.toString());
                        }
                        dispatch(
                            setPage(
                                selectedGalleryTemplate
                                    ? DesignSubPages.GALLERY_PREVIEW
                                    : DesignSubPages.TEMPLATE_PREVIEW,
                            ),
                        );
                    }}
                    icon={<IconVideoFrame />}
                >
                    {translate('modal.design.create.template.preview')}
                </Button>
            );
        } else if (activePage === DesignSubPages.FIGMA_IMPORT && selectedFrames.length) {
            return (
                <Select
                    value={figmaImportType}
                    onChange={setFigmaImportType}
                    label={figmaImportTypeLabels[figmaImportType]}
                    aria-labelledby="figma-import-type"
                    {...generateTestId('figma-import-type-select')}
                >
                    {Object.entries(figmaImportTypeLabels).map(([key, label]) => (
                        <Select.Item key={key} value={key}>
                            {label}
                        </Select.Item>
                    ))}
                </Select>
            );
        }

        return null;
    };

    const getBackButtonAction = () => {
        if (
            activePage === DesignSubPages.IMPORT_STATUS ||
            activePage === DesignSubPages.TEMPLATE_PREVIEW ||
            activePage === DesignSubPages.GALLERY_PREVIEW
        ) {
            const pageHistoryIndex = pageHistory[0] === activePage ? 1 : 0;

            return {
                title: translate('modal.design.create.back'),
                onClick: () => {
                    dispatch(setPage(DesignSubPages[pageHistory[pageHistoryIndex]]));
                    dispatch(deselectTemplate());
                    dispatch(dropImportedTemplate());
                    resetQueryParams(['template']);
                },
            };
        }

        return;
    };

    const isTemplatePreview = [DesignSubPages.TEMPLATE_PREVIEW, DesignSubPages.GALLERY_PREVIEW].includes(activePage);

    return (
        <>
            <ModalBase container={modalContainer} isOpen={isOpen} onClose={onHandleClose} size="fullscreen">
                {!isTemplatePreview ? (
                    <>
                        {activePage !== DesignSubPages.CATEGORIES && activePage !== DesignSubPages.CATEGORY_DETAILS ? (
                            <DesignCreateSidebar sidebarItems={getSidebarItems(permissions)} />
                        ) : (
                            <DesignCreateSidebar
                                sidebarItems={getTemplateManagementSidebarItems(categories)}
                                backButton={
                                    !isAccountRoleAssigned(roles.video_brand_studio.video.manage)
                                        ? {
                                              title: translate('modal.design.create.back'),
                                              onClick: () => {
                                                  dispatch(setPage(DesignSubPages.ALL));
                                                  dispatch(deselectTemplate());
                                                  dispatch(initializeTemplates());
                                                  resetQueryParams([
                                                      'typeTemplate',
                                                      'categoryId',
                                                      'ownership',
                                                      'search',
                                                  ]);
                                              },
                                          }
                                        : undefined
                                }
                            />
                        )}
                        <ModalBase.Header {...getModalTitle()} backButton={getBackButtonAction()} />
                        <ModalBase.Content>
                            <div id="new_design_modal_body" onKeyDown={onKeyEnter}>
                                {modalRouter[activePage] || modalRouter[Object.keys(modalRouter)[0]]}
                            </div>
                        </ModalBase.Content>
                        <ModalBase.Footer
                            actionPrimary={
                                <Button
                                    variant="primary"
                                    title={translate('modal.design.create.actionButton')}
                                    isDisabled={isCreationDisabled()}
                                    onClick={onCreate}
                                >
                                    {!multiDesignMode
                                        ? translate('modal.design.create.actionButton.sizes', {
                                              count:
                                                  importType === ImportType.DESIGN_FILE ? getValidArtboardsNumber : 1,
                                          })
                                        : translate('modal.design.create.actionButton', {
                                              count:
                                                  importType === ImportType.DESIGN_FILE ? getValidArtboardsNumber : 1,
                                          })}
                                </Button>
                            }
                            actionSecondary={
                                <Button
                                    variant="secondary"
                                    title={translate('modal.cancel.title')}
                                    onClick={onHandleClose}
                                >
                                    {translate('modal.cancel.title')}
                                </Button>
                            }
                            actionTertiary={getTertiaryButton()}
                        />
                    </>
                ) : (
                    <TemplateModalContent
                        template={
                            activePage === DesignSubPages.GALLERY_PREVIEW ? selectedGalleryTemplate : selectedTemplate
                        }
                        createDesign={onCreate}
                        backButton={getBackButtonAction()}
                        setDeleteModalTemplate={setDeleteModalTemplate}
                        setRenameModalTemplate={setRenameModalTemplate}
                        setShareModalTemplate={setShareModalTemplate}
                        setMoveModalTemplate={setMoveModalTemplate}
                    />
                )}
            </ModalBase>
            {renderModalTemplateDelete()}
            {renderModalTemplateRename()}
            {renderModalTemplateShare()}
            {renderModalTemplateMove()}
        </>
    );
};

export default DesignCreateModal;
