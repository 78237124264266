import React, { useEffect } from 'react';
import { IconGridView, IconListView } from '@bynder/icons';
import { Button, Tooltip } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import useQueryParams from 'packages/hooks/useQueryParams';
import { Props } from './types';

const ListViewToggle = ({
    isActive,
    setListViewActive,
    isDisabled,
    icons = { grid: <IconGridView />, list: <IconListView /> },
    hints = { grid: 'listview.toggle.hint.grid', list: 'listview.toggle.hint.listview' },
}: Props) => {
    const { translate } = useTranslate();
    const { setQueryParam, getQueryParam } = useQueryParams();

    const onListViewChange = () => {
        setListViewActive(!isActive);
        setQueryParam('view', !isActive ? 'list' : 'grid');
    };

    useEffect(() => {
        if (getQueryParam('view') === 'list') {
            setListViewActive(true);
        } else {
            setListViewActive(false);
        }
    }, []);

    return (
        <Tooltip content={isActive ? translate(hints.grid) : translate(hints.list)} position="top-right">
            <Button
                variant="clean"
                icon={isActive ? icons.list : icons.grid}
                onClick={onListViewChange}
                aria-label={translate('listview.toggle.label')}
                isDisabled={isDisabled}
                data-testid="list view toggle"
            />
        </Tooltip>
    );
};

export default ListViewToggle;
