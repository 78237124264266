import { useCallback } from 'react';

export const useInputBlur = () => {
    const handleClickOutside = useCallback(() => {
        if (document.activeElement?.tagName === 'INPUT') {
            (document.activeElement as HTMLInputElement).blur();
        }
    }, []);

    const onFocus = useCallback(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
    }, [handleClickOutside]);

    const unsubscribeListener = useCallback(() => {
        document.removeEventListener('mousedown', handleClickOutside, true);
    }, [handleClickOutside]);

    return {
        onFocus,
        unsubscribeListener,
    };
};
