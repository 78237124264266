import { IMessage, StompSubscription } from '@stomp/stompjs';
import { socketConnect } from './socketConnect';

export type SocketSubscribeConfig<TRes, TReq> = {
    destination: string;
    onMessage: (data: TRes, unsubscribe: () => void) => void;
    publishBody: TReq;
};

export async function socketSubscribe<TRes = any, TReq = any>({
    destination,
    onMessage,
    publishBody,
}: SocketSubscribeConfig<TRes, TReq>) {
    const { client, disconnect, publishMessage } = await socketConnect();
    let subscription: StompSubscription | null = null;
    let resubInterval = -1;

    const unsubscribe = () => {
        window.clearInterval(resubInterval);
        subscription?.unsubscribe();
        subscription = null;

        disconnect();
    };

    const handler = (message: IMessage) => {
        const parsedBody = JSON.parse(message.isBinaryBody ? String.fromCharCode(...message.binaryBody) : message.body);

        onMessage(parsedBody, unsubscribe);
        message.ack();
    };

    subscription = client.subscribe(`/topic/${destination}`, handler);

    const publish = () => publishMessage(`/app/${destination}`, publishBody);

    // BE stops sending updates after 90 seconds, so we have to ask for them again
    // todo: ask BE to send some message when they stop sending updates
    // it can by done as a part of initiative
    resubInterval = window.setInterval(publish, 90_000);
    await publish();

    return unsubscribe;
}
