import React from 'react';
import { Table } from '@bynder/design-system';
import { UploadType } from 'packages/store/uploads/types';

const ResolutionCell = ({ upload }: { upload: UploadType }) => (
    <Table.Cell textFlow="no-wrap">
        {upload.type !== 'AUDIO' && upload.width && upload.height ? `${upload.width} × ${upload.height} px` : null}
    </Table.Cell>
);

export default ResolutionCell;
