import styled, { css } from 'styled-components';
import { token } from '@bynder/design-system';
import { CardBoxProps, ContainerProps, ModalContainerProps } from './types';

export const DesignCreateContainer = styled.div<ModalContainerProps>`
    display: flex;
    height: 100%;
    margin-left: ${({ withSidebar }) => (withSidebar ? '272px' : '0px')};
    flex-direction: column;
`;

export const NegativeSpace = styled.div`
    height: calc(100% - ${token.spacing6} * 2);
    margin: calc(${token.spacing6} * -1);
`;

export const ModalContent = styled.div`
    flex: 1 1 auto;
    min-height: 0;
    position: relative;
    overflow-y: auto;
    padding: 0 ${token.spacing6};
`;

export const StyledContentContainer = styled.div<ContainerProps>`
    margin-left: calc(${token.spacing6} * -1);
    margin-right: calc(${token.spacing6} * -1);
    padding: ${token.spacing6} ${token.spacing2};
    height: calc(100vh - 300px);
    overflow-y: scroll;

    ${({ pageTitle }) =>
        pageTitle &&
        css`
            & .ReactVirtualized__Masonry:before {
                content: '${pageTitle}';
                font-size: ${token.fontSize200};
                line-height: 40px;
                margin-bottom: ${token.spacing5};
                margin-left: ${token.spacing2};
                display: flex;
            }
        `}
`;

export const CardBox = styled.div<CardBoxProps>`
    padding: ${token.spacing2}; // reserve space for outline
    img {
        height: ${({ defaultHeight }) => `${defaultHeight}px`};
    }
    a:hover {
        text-decoration: none;
    }
`;

export const PageTitle = styled.h2`
    font-weight: normal;
    font-size: ${token.fontSize200};
    line-height: 40px;
    margin-top: ${token.spacing5};
    margin-bottom: ${token.spacing5};
`;
