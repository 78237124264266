import {
    FontFamily,
    IStructuredGlyph,
    LayoutElement,
    Placement,
    RunProps,
    ShapedLine,
} from '@bynder-studio/structured-text';
import { TextDecoration, TextTransform, TextScript, StrokeType } from '@bynder-studio/misc';
import { ContentTransformTypes } from '../../../Enums/ContentTransformTypes';
import { ElementTypes } from '../../../Enums/ElementTypes';
import type { DimensionObject } from '../../Shared/Dimension';
import { Dimension } from '../../Shared/Dimension';
import type { PositionObject } from '../../Shared/Position';
import { Position } from '../../Shared/Position';
import { PlaybackDuration } from '../../Shared/PlaybackDuration';
import { GroupElement } from '../GroupElement';
import type { IElement } from '../IElement';
import type { ImageElementParams } from '../ImageElement';
import { ImageElement } from '../ImageElement';
import { TextElement } from '../TextElement';
import type { VideoElementParams } from '../VideoElement';
import { VideoElement } from '../VideoElement';
import { BaseVisualElement } from '../BaseVisualElement';
import { ShapeElement } from '../ShapeElement';
import { ShapeTypes } from '../../../Enums/ShapeTypes';
import { BorderAlignmentTypes } from '../../../Enums/BorderAlignmentTypes';
import { LeadingTypes } from '../../../Enums/LeadingTypes';

export const getNextElementRenderOrder = (elements: IElement[]) => {
    return (
        Object.values(elements).reduce((acc, cur) => {
            const currentElemRenderOrder = typeof cur.renderOrder === 'number' ? cur.renderOrder : -1;

            return Math.max(acc, currentElemRenderOrder);
        }, -1) + 1
    );
};

export const createVideoElement = (
    rawParams: VideoElementParams,
    elements: IElement[],
    canvasDimension: Dimension,
    playbackDuration: PlaybackDuration,
): IElement => {
    const elId = Date.now();
    let startFrame = rawParams.startFrame ?? 0;

    if (startFrame >= playbackDuration.getDuration()) {
        startFrame = 0;
    }

    const el = new VideoElement({
        ...rawParams,
        id: elId,
        name: 'New Video element',
        renderOrder: getNextElementRenderOrder(elements),
        parent: null,
        position: {
            x: 0,
            y: 0,
        },
        dimension: {
            width: canvasDimension.getWidth(),
            height: canvasDimension.getHeight(),
        },
        contentTransform: {
            type: ContentTransformTypes.COVER,
            verticalAlignment: 0,
            horizontalAlignment: 0,
        },
        startFrame,
        duration: playbackDuration.getDuration() - startFrame,
        naturalDimension: rawParams.naturalDimension || {
            width: 0,
            height: 0,
        },
    });
    el.setCanvasDimension?.(canvasDimension);

    return el;
};

export const createImageElement = (
    rawParams: ImageElementParams,
    elements: IElement[],
    canvasDimension: Dimension,
    playbackDuration: PlaybackDuration,
    creativeType: string,
): IElement => {
    const elId = Date.now();
    const generatePositionAndDimension = ({ naturalDimension }: ImageElementParams) => {
        const sourceW = naturalDimension.width || 0;
        const sourceH = naturalDimension.height || 0;
        const canvasW = canvasDimension.getWidth();
        const canvasH = canvasDimension.getHeight();
        const scale = Math.min(canvasW / sourceW, canvasH / sourceH);
        let width = sourceW * scale;
        let height = sourceH * scale;

        if (sourceW <= canvasW && sourceH <= canvasH) {
            width = sourceW;
            height = sourceH;
        }

        if (!sourceW || !sourceH) {
            width = canvasW / 2;
            height = canvasH / 2;
        }

        return {
            position: {
                x: (canvasW - width) / 2,
                y: (canvasH - height) / 2,
            },
            dimension: {
                width,
                height,
            },
        };
    };

    const el = new ImageElement({
        ...rawParams,
        ...generatePositionAndDimension(rawParams),
        id: elId,
        name: 'New Image element',
        renderOrder: getNextElementRenderOrder(elements),
        parent: null,
        contentTransform: {
            type: ContentTransformTypes.COVER,
            verticalAlignment: 0,
            horizontalAlignment: 0,
        },
        naturalDimension: rawParams.naturalDimension || {
            width: 0,
            height: 0,
        },
        duration: 1,
    });

    if (creativeType !== 'IMAGE') {
        let startFrame = rawParams.startFrame ?? 0;

        if (startFrame >= playbackDuration.getDuration()) {
            startFrame = 0;
        }

        el.startFrame = startFrame;
        el.duration = playbackDuration.getDuration() - startFrame;
    }

    el.setCanvasDimension?.(canvasDimension);

    return el;
};

export const createTextElement = (
    rawParams: any,
    elements: IElement[],
    canvasDimension: Dimension,
    playbackDuration: PlaybackDuration,
    creativeType: string,
): IElement => {
    const elId = Date.now();
    const el = new TextElement({
        ...rawParams,
        id: elId,
        name: 'New Text element',
        renderOrder: getNextElementRenderOrder(elements),
        parent: null,
        position: {
            x: canvasDimension.getWidth() / 4,
            y: canvasDimension.getHeight() / 4,
        },
        dimension: {
            width: canvasDimension.getWidth() / 2,
            height: canvasDimension.getHeight() / 2,
        },
        formattedText: {
            value: rawParams.text,
            layoutRuns: [
                {
                    type: LayoutElement.PARAGRAPH,
                    length: rawParams.text.length,
                },
            ],
            runs: [
                {
                    length: rawParams.text.length,
                    styleId: null,
                    fontId: rawParams.fontId.toString(),
                    color: { red: 0, green: 0, blue: 0, opacity: 1, brandColorId: null },
                    fontSize: 50,
                    leading: 1.3,
                    tracking: 0,
                    textTransform: TextTransform.NONE,
                    textScript: TextScript.BASE,
                    textDecoration: TextDecoration.NONE,
                    stroke: {
                        type: StrokeType.NONE,
                        width: 0,
                    },
                    paragraphSpacing: 0,
                },
            ],
        },
        contentTransform: {
            type: ContentTransformTypes.TEXT,
            verticalAlignment: 0,
            horizontalAlignment: 0,
        },
        leadingType: LeadingTypes.LEADING_PCT,
        textControl: Placement.WRAP,
        duration: 1,
        lockUniScaling: false,
    });

    if (creativeType !== 'IMAGE') {
        let startFrame = rawParams.startFrame ?? 0;

        if (startFrame >= playbackDuration.getDuration()) {
            startFrame = 0;
        }

        el.startFrame = startFrame;
        el.duration = playbackDuration.getDuration() - startFrame;
    }

    el.setCanvasDimension?.(canvasDimension);

    return el;
};

export const createShapeElement = (
    rawParams: any,
    elements: IElement[],
    canvasDimension: Dimension,
    playbackDuration: PlaybackDuration,
    creativeType: string,
): IElement => {
    const elId = Date.now();
    const a = Math.min(canvasDimension.getWidth(), canvasDimension.getHeight()) / 2;
    const el = new ShapeElement({
        shapeType: ShapeTypes.RECTANGLE,
        fillColor: {
            red: 204,
            green: 204,
            blue: 204,
            opacity: 1,
            brandColorId: null,
        },
        borderColor: {
            red: 0,
            green: 0,
            blue: 0,
            opacity: 1,
            brandColorId: null,
        },
        borderWidth: 0,
        borderRadius: 0,
        borderAlignment: BorderAlignmentTypes.INSIDE,
        duration: 1,
        ...rawParams,
        id: elId,
        name: 'New Shape element',
        renderOrder: getNextElementRenderOrder(elements),
        parent: null,
        position: {
            x: canvasDimension.getWidth() / 2 - a / 2,
            y: canvasDimension.getHeight() / 2 - a / 2,
        },
        dimension: {
            width: a,
            height: a,
        },
        lockUniScaling: false,
    });

    if (creativeType !== 'IMAGE') {
        let startFrame = rawParams.startFrame ?? 0;

        if (startFrame >= playbackDuration.getDuration()) {
            startFrame = 0;
        }

        el.startFrame = startFrame;
        el.duration = playbackDuration.getDuration() - startFrame;
    }

    el.setCanvasDimension?.(canvasDimension);

    return el;
};

export const createElement = (
    type: ElementTypes,
    rawParams: any,
    elements: IElement[],
    canvasDimension: Dimension,
    playbackDuration?: PlaybackDuration,
) => {
    switch (type) {
        case ElementTypes.VIDEO: {
            return createVideoElement(rawParams, elements, canvasDimension, playbackDuration);
        }

        case ElementTypes.IMAGE: {
            return createImageElement(rawParams, elements, canvasDimension, playbackDuration, rawParams?.creativeType);
        }

        case ElementTypes.TEXT: {
            return createTextElement(rawParams, elements, canvasDimension, playbackDuration, rawParams?.creativeType);
        }

        case ElementTypes.SHAPE: {
            return createShapeElement(rawParams, elements, canvasDimension, playbackDuration, rawParams?.creativeType);
        }
    }
};

const compareByRenderOrder = (elementA: IElement, elementB: IElement) =>
    (elementB.renderOrder || 0) - (elementA.renderOrder || 0);

const groupByRenderOrder = (rows: IElement[][], element: IElement) => {
    const row = rows[rows.length - 1];

    if (!row || row[row.length - 1].renderOrder !== element.renderOrder) {
        rows.push([element]);
    } else {
        row.push(element);
    }

    return rows;
};

export const calculateElementsRenderOrder = (
    elements: IElement[],
    data: {
        element: IElement;
        renderOrder: number;
    }[] = [],
    renderOrder = 1,
): number =>
    elements
        .sort(compareByRenderOrder)
        .reverse()
        .reduce(groupByRenderOrder, [])
        .reduce((lastRenderOrder, row) => {
            const renderOrder = calculateElementsRenderOrder(
                row
                    .filter((element) => element instanceof GroupElement)
                    .reduce<IElement[]>((acc, group) => [...acc, ...(group as GroupElement).children], []),
                data,
                lastRenderOrder,
            );
            row.forEach((element) => {
                if (element.renderOrder === renderOrder) {
                    return;
                }

                data.push({
                    element,
                    renderOrder,
                });
            });

            return renderOrder + 1;
        }, renderOrder);

// ------------------------------------------------------------------
const findSiblings = (flattenElements: IElement[], element: IElement) => {
    return flattenElements.filter((el) => el.id !== element.id && el.renderOrder === element.renderOrder);
};

const getOverlappingElementIds = (valuesToApply: { startFrame: number; duration: number }, siblings: IElement[]) => {
    return siblings
        .filter((sibling) => {
            const { startFrame, duration } = sibling;
            const largerStartFrame = startFrame >= valuesToApply.startFrame;
            const shorterDuration = startFrame + duration <= valuesToApply.startFrame + valuesToApply.duration;

            return largerStartFrame && shorterDuration;
        })
        .map((el) => el.id);
};

const applyCropToSiblingsOnLeft = (
    valuesToApply: {
        startFrame: number;
        duration: number;
    },
    applicableSiblings: IElement[],
    updateFn: (
        arg0: IElement,
        arg1: {
            duration: number;
        },
    ) => void,
): void => {
    applicableSiblings
        .filter((el: IElement) => {
            const { startFrame, duration } = el;

            return startFrame + duration > valuesToApply.startFrame && startFrame < valuesToApply.startFrame;
        })
        .forEach((element: IElement) => {
            updateFn(element, {
                duration: valuesToApply.startFrame - element.startFrame,
            });
        });
};

const applyCropToSiblingsOnRight = (
    valuesToApply: {
        startFrame: number;
        duration: number;
    },
    applicableSiblings: IElement[],
    updateFn: (
        arg0: IElement,
        arg1: {
            duration: number;
            startFrame: number;
        },
    ) => void,
): void => {
    applicableSiblings
        .filter((el: IElement) => {
            const { startFrame } = el;

            return (
                startFrame < valuesToApply.startFrame + valuesToApply.duration && startFrame >= valuesToApply.startFrame
            );
        })
        .forEach((element: IElement) => {
            const endFrame = valuesToApply.startFrame + valuesToApply.duration;
            const diff = endFrame - element.startFrame;
            updateFn(element, {
                startFrame: endFrame,
                duration: element.duration - diff,
            });
        });
};

export const applyCropToSiblings = (
    flattenElements: IElement[],
    element: IElement,
    updateFn: (
        arg0: IElement,
        arg1: {
            duration: number;
            startFrame?: number;
        },
    ) => void,
): number[] => {
    const { startFrame, duration } = element;
    const valuesToApply = {
        startFrame,
        duration,
    };
    const siblings = findSiblings(flattenElements, element);
    const overlappingElementIds = getOverlappingElementIds(valuesToApply, siblings);
    const applicableSiblings = siblings.filter((sibling) => !overlappingElementIds.includes(sibling.id));
    applyCropToSiblingsOnLeft(valuesToApply, applicableSiblings, updateFn);
    applyCropToSiblingsOnRight(valuesToApply, applicableSiblings, updateFn);

    return overlappingElementIds;
};

export const getScaledElementParams = (
    oldDimension: Dimension,
    newDimension: Dimension,
    elementPosition: Position,
    elementDimension: Dimension,
) => {
    const scaleHorizontal = newDimension.getWidth() / oldDimension.getWidth();
    const scaleVertical = newDimension.getHeight() / oldDimension.getHeight();
    const scaleFactor = Math.min(scaleHorizontal, scaleVertical);

    const isCoverWidth = elementPosition.getX() <= 0 && elementDimension.getWidth() >= oldDimension.getWidth();
    const isCoverHeight = elementPosition.getY() <= 0 && elementDimension.getHeight() >= oldDimension.getHeight();

    const position = new Position(elementPosition.getX() * scaleHorizontal, elementPosition.getY() * scaleVertical);

    const dimension = new Dimension(
        elementDimension.getWidth() * (isCoverWidth ? scaleHorizontal : scaleFactor),
        elementDimension.getHeight() * (isCoverHeight ? scaleVertical : scaleFactor),
    );

    return { position, dimension };
};

// -------------------------------------------------------------------
export const changeElementsPositionAndDimension = (
    elements: BaseVisualElement[],
    oldDimension: Dimension,
    newDimension: Dimension,
    updateFn: (args: { element: BaseVisualElement; dimension: DimensionObject; position: PositionObject }) => void,
): void => {
    elements.forEach((element: BaseVisualElement) => {
        if (element instanceof GroupElement) {
            changeElementsPositionAndDimension(element.children, oldDimension, newDimension, updateFn);
            const { position = element.position.toObject(), dimension = element.dimension.toObject() } =
                element.getDiffBasedOnChildren();
            updateFn({
                element,
                dimension: new Dimension(dimension.width, dimension.height),
                position: new Position(position.x, position.y),
            });

            return;
        }

        const { position, dimension } = getScaledElementParams(
            oldDimension,
            newDimension,
            element.position,
            element.dimension,
        );

        updateFn({
            element,
            position,
            dimension,
        });
    });
};
