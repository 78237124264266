import ProxyServerConnector from '~/services/connectors/ProxyServerConnector';

class DesignsService extends ProxyServerConnector {
    constructor(path = '/creative') {
        super(path);
        // @ts-ignore
        // eslint-disable-next-line no-undef
        this.gateway = new ProxyServerConnector(path, GATEWAY_PREFIX);
    }

    createBlankDesign(data, collectionId) {
        let path = ``;

        if (collectionId) {
            path += `?collectionId=${collectionId}`;
        }

        // @ts-ignore
        return this.gateway.put(path, data);
    }

    createFromXML(data, collectionId) {
        // @ts-ignore
        return this.gateway.post(`/from-xml${collectionId ? `?collectionId=${collectionId}` : ''}`, data);
    }

    duplicateCreatives(ids, collectionId) {
        // @ts-ignore
        return this.gateway.put('/duplicate', { ids, collectionId });
    }

    createVideoCreative(data) {
        // @ts-ignore
        return this.gateway.put('/convert', data);
    }
}

export default new DesignsService();
