import { useCallback, useEffect, useMemo, useRef } from 'react';
import { equals } from 'rambda';
import { socketSubscribe, SocketSubscribeConfig } from './socketSubscribe';

export function useSocketSubscribe<TRes, TReq>({
    destination,
    onMessage,
    publishBody: publishBodyRaw,
}: SocketSubscribeConfig<TRes, TReq>) {
    // avoid resubscribing when onMessage is not memoized
    const callbackRef = useRef(onMessage);
    useEffect(() => {
        callbackRef.current = onMessage;
    }, [onMessage]);

    // avoid resubscribing when publishBody is not memoized
    const bodyRef = useRef<TReq>();
    const publishBody = useMemo(() => {
        if (!equals(bodyRef.current, publishBodyRaw)) {
            bodyRef.current = publishBodyRaw;
        }

        return bodyRef.current;
    }, [publishBodyRaw]);

    const subscriptionRef = useRef<ReturnType<typeof socketSubscribe<TRes, TReq>> | null>(null);

    const unsubscribe = useCallback(() => {
        subscriptionRef.current?.then((cb) => cb());
        subscriptionRef.current = null;
    }, []);

    const subscribe = useCallback(() => {
        if (subscriptionRef.current) {
            return unsubscribe;
        }

        subscriptionRef.current = socketSubscribe({
            destination,
            publishBody,
            onMessage: (data) => callbackRef.current(data, unsubscribe),
        });

        return unsubscribe;
    }, [destination, publishBody, unsubscribe]);

    useEffect(() => {
        if (!subscriptionRef.current) {
            return unsubscribe;
        }

        unsubscribe();
        subscribe();

        return unsubscribe();
    }, [subscribe, unsubscribe]);

    return subscribe;
}
