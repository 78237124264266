import React, { useEffect, useState } from 'react';
import { Button, InputField, Modal, Thumbnail, token } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconSync } from '@bynder/icons';
import generateTestId from '~/helpers/testIdHelpers';
import { RenameModalProps } from '../../types';
import modalContainer from 'packages/common/modalContainer';

const RenameModal = ({ propertyName, isOpen, onClose, onRename }: RenameModalProps) => {
    const MAX_INPUT_SIZE = 120;
    const [name, setName] = useState<string>(propertyName);
    const [isInputValid, setIsInputValid] = useState(propertyName.length <= MAX_INPUT_SIZE);
    const [isActionDisabled, setIsActionDisabled] = useState(propertyName === name);

    const { translate } = useTranslate();

    const onInputChange = (newname: string) => {
        setIsActionDisabled(!newname.length || newname.length > MAX_INPUT_SIZE || newname === propertyName);
        setIsInputValid(newname.length <= MAX_INPUT_SIZE);
        setName(newname);
    };

    const handleOnRename = () => {
        onRename(name);
        onClose();
    };

    const onKeyEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();

            if (isInputValid && !isActionDisabled) {
                handleOnRename();
            }
        }
    };

    useEffect(() => {
        if (isOpen && propertyName !== name) {
            setName(propertyName);
        }
    }, [isOpen, propertyName]);

    useEffect(() => () => setIsInputValid(true), [isOpen]);

    return (
        <Modal
            container={modalContainer}
            isClosedOnOverlayClick
            title={translate('editor.sidebar.shots.contentProperty.rename.modal.title')}
            isOpen={isOpen}
            onClose={onClose}
            actionPrimary={
                <Button
                    variant="primary"
                    onClick={handleOnRename}
                    isDisabled={isActionDisabled || !isInputValid}
                    {...generateTestId('editor : modal : rename content property save button')}
                >
                    {translate('editor.sidebar.shots.contentProperty.rename.modal.save')}
                </Button>
            }
            actionSecondary={
                <Button
                    variant="secondary"
                    onClick={onClose}
                    {...generateTestId('editor : modal : rename content property cancel button')}
                >
                    {translate('editor.sidebar.shots.contentProperty.rename.modal.cancel')}
                </Button>
            }
        >
            <InputField
                label={translate('editor.sidebar.shots.contentProperty.rename.modal.label')}
                value={name}
                placeholder={translate('editor.sidebar.shots.contentProperty.rename.modal.placeholder')}
                onChange={onInputChange}
                onKeyDown={onKeyEnter}
                isInvalid={!isInputValid}
                icon={
                    <Thumbnail
                        shape="circle"
                        size="xs"
                        backgroundColor={token.teal500}
                        icon={<IconSync size="16" style={{ color: token.white }} />}
                    />
                }
                hasClearButton
                helperText={!isInputValid && translate('input.error.max.length')}
                {...generateTestId('editor : modal : rename content property name input')}
            />
        </Modal>
    );
};

export default RenameModal;
