import { useEffect } from 'react';
import { isArrowKey } from 'packages/pages/utils';

const useArrowScrollBlocker = () => {
    useEffect(() => {
        const onKeyDown = (event: any) => {
            if (
                isArrowKey(event) &&
                event.target?.scrollHeight &&
                event.target.nodeName !== 'INPUT' &&
                // UCV uses shadowDOM, so the root element is always a div but with shadowRoot
                !event.target.shadowRoot
            ) {
                event.preventDefault();
            }
        };

        document.addEventListener('keydown', onKeyDown);

        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, []);
};

export default useArrowScrollBlocker;
