import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const Container = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: 26px;
    align-items: center;
`;

export const BackgroundControlLine = styled.div`
    position: absolute;
    height: 4px;
    border-radius: 4px;
    background-color: ${token.gray100a};
    left: 0;
    width: 100%;
`;

export const Control = styled.div`
    position: relative;
    display: flex;
    height: 4px;
    border-radius: 4px;
    background-color: ${token.brandPrimary500};
    justify-content: space-between;
    cursor: pointer;
    left: 0;
    width: 100%;
`;

export const Handle = styled.div`
    flex-shrink: 0;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: ${token.white};
    transform: translateY(-6px);
    transition: 0.2s ease-out;
    cursor: ew-resize;
    box-shadow: 0 0 1px rgba(0, 34, 51, 0.1), 0 1px 4px -1px rgba(0, 34, 51, 0.1);
    border: 1px solid rgba(0, 34, 51, 0.07);

    &:hover {
        transform: scale(1.25) translateY(-6px);
    }
`;
