import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const Box = styled.div`
    display: flex;

    & > :first-child {
        flex: 1;
        padding-right: calc(25px + ${token.spacing2});
    }
    & > :last-child {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
    }
`;
