import { useCallback, useState } from 'react';
import {
    BaseMultiPageModel,
    BaseVisualElement,
    TextElement,
    getElementType,
    ContentProperty,
    ElementForContentProperty,
    IBaseMultiPageModel,
} from '@bynder-studio/render-core';
import useEditor from '~/hooks/useEditor';

export function useContentProperty(
    creativeModel: BaseMultiPageModel<IBaseMultiPageModel>,
    selectedElement: ElementForContentProperty,
) {
    const { manipulationRenderer } = useEditor();
    const contentPropertiesManager = creativeModel.getContentPropertiesManager();
    const [searchValue, setSearchValue] = useState('');
    const [creationTime, setCreationTime] = useState(0);
    const searchTerm = searchValue.trim().toLowerCase();

    const contentPropertyId = selectedElement?.contentPropertyId ?? '';
    const currentProperty = contentPropertiesManager.getContentProperty(contentPropertyId);
    const elementType = getElementType(selectedElement as BaseVisualElement);

    const handleSearchChange = useCallback((value: string) => {
        setSearchValue(value);
    }, []);

    const applyProperty = (contentPropertyId: string) => {
        if (!selectedElement) {
            return;
        }

        if (selectedElement instanceof TextElement && selectedElement.isTextEditorActivated) {
            manipulationRenderer.canvasManipulation.disableTextEditor();
        }

        creativeModel.updateElement(selectedElement.id, { contentPropertyId });
    };

    const onCreate = (name: string) => {
        if (!selectedElement) {
            return;
        }

        // In order to prevent double click
        if (Date.now() - creationTime < 500) {
            return;
        }

        setCreationTime(Date.now());

        const propertyId = contentPropertiesManager.createContentPropertyFromElement(selectedElement, name);

        if (propertyId) {
            applyProperty(propertyId);
        }
    };

    const onDetach = () => {
        if (!selectedElement) {
            return;
        }

        creativeModel.updateElement(selectedElement.id, { contentPropertyId: '' });
    };

    const onRename = (name: string) => {
        const updatedProperty = { ...currentProperty, name };
        contentPropertiesManager.updateContentProperty(updatedProperty);
    };

    const onDelete = () => {
        if (currentProperty) {
            contentPropertiesManager.deleteContentProperty(currentProperty.uuid);
        }
    };

    const onSave = (updatedProperties: ContentProperty[]) => {
        contentPropertiesManager.updateContentProperties(updatedProperties);
    };

    const allTypesProperties = contentPropertiesManager.allContentProperties;
    const propertiesByType = contentPropertiesManager.getContentPropertiesByType(elementType);

    const filteredProperties =
        !searchTerm || !searchTerm.length
            ? propertiesByType
            : propertiesByType.filter((property) => property.name.toLowerCase().includes(searchTerm));

    return {
        allTypesProperties,
        filteredProperties,
        currentProperty,
        searchValue,
        handleSearchChange,
        applyProperty,
        onCreate,
        onDetach,
        onRename,
        onDelete,
        onSave,
    };
}
