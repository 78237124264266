export enum FontStyle {
    BOLD = 'BOLD',
    ITALIC = 'ITALIC',
    BOLD_ITALIC = 'BOLD_ITALIC',
    REGULAR = 'REGULAR',
}

export type Font = {
    id: number | string;
    name: string;
    tag: string;
    url: string;
    weight: string;
    style: FontStyle;
    includeLineGap?: boolean;
};

export type FontFamily = {
    fonts: Font[];
    gallery: boolean;
    global: boolean;
    id: number;
    name: string;
};

let fontFamilies: FontFamily[] = [];

export const setFontFamilies = (data: FontFamily[]) => {
    fontFamilies = data;
};

export const getFontFamilies = (): Readonly<FontFamily[]> => {
    if (!fontFamilies) {
        throw new Error('Font families are not set');
    }

    return fontFamilies;
};

export const findFontById = (families: Readonly<FontFamily[]>, fontId: Font['id']) =>
    families.flatMap((family) => family.fonts).find((item) => item.id === fontId);

export const getFontById = (id: number | string) => {
    let parsedId: number | string = Number(id);

    if (Number.isNaN(parsedId)) {
        parsedId = id;
    }

    return findFontById(fontFamilies, parsedId);
};
