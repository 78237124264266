import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Dropdown } from '@bynder/design-system';
import { IconVideoTemplate } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { ActionStatus, Entity, TemplateAction } from 'packages/hooks/useAccessRights/types';
import useAccessRights from 'packages/hooks/useAccessRights';
import features from '~/configs/features';
import { changePage } from '~/store/general/general.actions';
import type { IsAvailable, Props } from './types';

export const isEditAvailable: IsAvailable = ({ isEntityActionAllowed, isPlatformAllowed, hiddenActions, template }) =>
    isEntityActionAllowed(TemplateAction.EDIT, Entity.TEMPLATE, template.grantedPermissions) !==
        ActionStatus.NOT_ALLOWED &&
    isPlatformAllowed([features.EDITABLE_TEMPLATES]) &&
    !hiddenActions.includes('editTemplate');

export const Edit = ({ template, menuActions }: Props) => {
    const { isEntityActionAllowed } = useAccessRights();
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const onClick = useCallback(() => {
        dispatch(
            changePage({
                back: location.pathname + location.search,
            }),
        );

        navigate(`/templates/${template.id}/edit`);
    }, [template, dispatch]);

    const isDisabled =
        isEntityActionAllowed(TemplateAction.EDIT, Entity.TEMPLATE, template.grantedPermissions) ===
        ActionStatus.DISABLED;

    return (
        <Dropdown.Item icon={<IconVideoTemplate />} onClick={onClick} isDisabled={isDisabled}>
            {translate('pages.categories.category.template.menu.edit')}
        </Dropdown.Item>
    );
};
