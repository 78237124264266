import React, { useContext, useEffect } from 'react';
import { isVariationInvalid } from 'packages/pages/design/sidebar/variations/utils';
import { Column, ColumnSelect, ListContainer, RowContainer } from './List.styled';
import VariationItem from './VariationItem';
import { SelectItemCheckbox } from './SelectItemCheckbox';
import { VariationsThumbnailsContext } from 'packages/pages/design/VariationsContext/VariationsThumbnailsProvider';
import { variationsThumbnailsStore } from 'packages/pages/design/VariationsContext/variationsThumbnailsStore';

const VariationList = ({ items, pageIndex }) => {
    const { variationThumbnailRenderer } = useContext(VariationsThumbnailsContext);

    useEffect(() => {
        variationThumbnailRenderer.getVariationIds().forEach((variationId) => {
            if (variationsThumbnailsStore.isThumbnailsReady(variationId, pageIndex)) {
                return;
            }
            variationThumbnailRenderer.render(variationId, pageIndex);
        });
    }, [variationThumbnailRenderer, pageIndex]);

    return (
        <ListContainer>
            {items.map((item) => {
                const isInvalid = isVariationInvalid(item);
                return (
                    <RowContainer key={item.variationId} gap="4" alignItems="center" bordered>
                        <ColumnSelect>
                            <SelectItemCheckbox item={item} isDisabled={isInvalid} />
                        </ColumnSelect>
                        <Column>
                            <VariationItem {...item} isInvalid={isInvalid} pageIndex={pageIndex} />
                        </Column>
                    </RowContainer>
                );
            })}
        </ListContainer>
    );
};

export default VariationList;
