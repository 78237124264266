import styled from 'styled-components';
import { Button, List } from '@bynder/design-system';

export const DurationListItem = styled(List.Item)`
    & span:first-child {
        overflow: visible;
    }
`;
export const DynamicLengthButton = styled(Button)`
    justify-content: space-between;
    overflow: visible;
`;
