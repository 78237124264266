import React from 'react';
import { Modal, Button } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import modalContainer from 'packages/common/modalContainer';

type PreventRequestApprovalModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const PreventRequestApprovalModal = ({ isOpen, onClose }: PreventRequestApprovalModalProps) => {
    const { translate } = useTranslate();

    return (
        <Modal
            container={modalContainer}
            title={translate('design.sidebar.shots.modal.prevent-request-approval.title')}
            isOpen={isOpen}
            onClose={onClose}
            actionPrimary={
                <Button variant="primary" onClick={onClose}>
                    {translate('design.sidebar.shots.modal.prevent-request-approval.button')}
                </Button>
            }
        >
            {translate('design.sidebar.shots.modal.prevent-request-approval.content')}
        </Modal>
    );
};

export default PreventRequestApprovalModal;
