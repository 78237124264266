import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { colorPalettes } from 'packages/store/brandColors/brandColors.selectors';
import { BrandColor } from 'packages/store/brandColors/types';

export const usePalettes = () => {
    const { palettes } = useSelector(colorPalettes);

    const byId = useMemo(() => {
        const temp: { [brandColorId: number]: BrandColor } = {};
        palettes.forEach((palette) => {
            palette.brandColors.forEach((c) => {
                temp[c.id] = c;
            });
        });

        return temp;
    }, [palettes]);

    return {
        palettes,
        byId,
    };
};
