import React, { Fragment, useState } from 'react';
import { CreativeTypes } from '@bynder-studio/render-core';
import { Button, Dropdown, Form, Input, InputField, Modal } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import useSizes from 'packages/pages/designCreateModal/components/Blank/useSizes';
import { DropdownButton } from 'packages/pages/editor/RightSideMenu/Globals/SizeSelector/index.styled';
import generateTestId from '~/helpers/testIdHelpers';
import editorAutocorrect from 'packages/pages/editor/EditorAutocorrectRules';
import modalContainer from 'packages/common/modalContainer';

type SizeModalProps = {
    creativeModel: any;
    onToggle: () => void;
    handleConfirm: (name: string, { width, height, format }: { width: number; height: number; format: string }) => void;
    creativeType: CreativeTypes;
};

enum DimensionType {
    HEIGHT = 'HEIGHT',
    WIDTH = 'WIDTH',
}

const MultiSizeModal = ({ creativeModel, onToggle, handleConfirm, creativeType }: SizeModalProps) => {
    const dimension = creativeModel?.getDimensions();
    const modelMetaData = creativeModel.getModelsMetaData();
    const selectedPageIndex = creativeModel.getCurrentPageIndex();

    const [width, setWidth] = useState(dimension.width);
    const [height, setHeight] = useState(dimension.height);
    const [isValidated, setIsValidated] = useState(true);

    const [videoSearch, setVideoSearch] = useState('');
    const [imageSearch, setImageSearch] = useState('');
    const { translate } = useTranslate();
    const dropdownTestId = generateTestId('multisize_format');
    const sizeWidthTestId = generateTestId('multisize_format_width');
    const sizeHeightTestId = generateTestId('multisize_format_height');

    const { videoCategories, imageCategories, findByFormatKey, customSizeCategory } = useSizes(
        videoSearch,
        imageSearch,
    );

    const isVideoCreative = creativeType === CreativeTypes.VIDEO;
    const sizeCategories = isVideoCreative ? videoCategories : imageCategories;
    const MAX_INPUT_SIZE = 120;

    const matchFormatChangeWithSelector = (format) => findByFormatKey(sizeCategories, format);

    const [selected, setSelected] = useState(matchFormatChangeWithSelector(modelMetaData[selectedPageIndex].format));
    const [pageName, setPageName] = useState(selected.label || '');

    const isCreateSizeDisabled = !pageName.length || pageName.length > MAX_INPUT_SIZE || !isValidated;

    const onSizeSelect = (item) => {
        const { size } = item;
        setSelected(item);
        setWidth(size.width);
        setHeight(size.height);

        if (pageName === selected.label || !pageName.length) {
            setPageName(item.label);
        }
    };

    const handleNewSize = () => {
        if (!isValidated) {
            return;
        }

        handleConfirm(pageName.length ? pageName : selected.label, { width, height, format: selected.size.format });
    };

    const onChange = (type, value) => {
        setIsValidated(false);

        if (type === DimensionType.HEIGHT) {
            setHeight(value);
        } else {
            setWidth(value);
        }
    };

    const onBlur = () => {
        const newDimensions = { width, height };
        const correctDimensions = editorAutocorrect('global_dimensions', newDimensions, creativeType);

        if (
            correctDimensions.width === dimension.width &&
            correctDimensions.height === dimension.height &&
            selected.label !== 'Custom'
        )
            return;

        onSizeSelect(customSizeCategory);
        setWidth(correctDimensions.width);
        setHeight(correctDimensions.height);

        setIsValidated(true);
    };

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            onBlur();
        }
    };

    return (
        <Modal
            isOpen
            container={modalContainer}
            title={translate('editor.header.page.modal.title')}
            onClose={onToggle}
            actionPrimary={
                <Button
                    variant="primary"
                    onClick={handleNewSize}
                    isDisabled={isCreateSizeDisabled}
                    data-testid="editor : modal : add new page button"
                >
                    {translate('editor.header.page.modal.add')}
                </Button>
            }
            actionSecondary={
                <Button variant="secondary" onClick={onToggle} data-testid="editor : modal : cancel button">
                    {translate('editor.header.page.modal.cancel')}
                </Button>
            }
        >
            <InputField
                value={pageName}
                onChange={(name) => setPageName(name)}
                isInvalid={pageName.length > MAX_INPUT_SIZE}
                label=""
                placeholder={translate('editor.header.page.modal.input.placeholder')}
                helperText={pageName.length > MAX_INPUT_SIZE && translate('input.error.max.length')}
                hasClearButton
            />
            <Form.Group>
                <Dropdown
                    trigger={({ isOpen, ...triggerProps }) => (
                        <DropdownButton
                            isPressed={isOpen}
                            isFullWidth
                            variant="secondary"
                            icon={selected.icon}
                            rightIcon={<Dropdown.Arrow />}
                            aria-label={selected.label}
                            {...dropdownTestId}
                            {...triggerProps}
                        >
                            {selected.label}
                        </DropdownButton>
                    )}
                    position="bottom"
                >
                    <Dropdown.SearchInput
                        value={isVideoCreative ? videoSearch : imageSearch}
                        onChange={(value) => (isVideoCreative ? setVideoSearch(value) : setImageSearch(value))}
                        placeholder={translate('editor.header.page.modal.search.placeholder')}
                        aria-label={translate('editor.header.page.modal.search.label')}
                        data-testid="multisize_selector_search_input"
                    />
                    {sizeCategories.map((category, index) => (
                        <Fragment key={category.label}>
                            <Dropdown.SectionTitle>{category.label}</Dropdown.SectionTitle>
                            {category.items.map((item) => (
                                <Dropdown.Item
                                    key={item.label}
                                    icon={item.icon}
                                    isChecked={item.label === selected.label}
                                    onClick={() => {
                                        if (selected.size.format === item.size.format) return;
                                        onSizeSelect(item);
                                    }}
                                    isDisabled={!item.size.width || !item.size.height}
                                >
                                    {item.label}
                                </Dropdown.Item>
                            ))}
                            {sizeCategories.length - 1 !== index ? <Dropdown.Divider /> : null}
                        </Fragment>
                    ))}
                    {!sizeCategories.length ? (
                        <Dropdown.Text>{translate('editor.header.page.modal.search.no_resulsts')}</Dropdown.Text>
                    ) : null}
                </Dropdown>
            </Form.Group>
            <Form.Row>
                <Form.Group>
                    <Input
                        type="number"
                        id="child-size-selector-width"
                        aria-label="child-size-selector-width"
                        aria-describedby="multisize_selector_width_message"
                        value={width}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        onChange={(newWidth) => onChange(DimensionType.WIDTH, newWidth)}
                        suffix="px"
                        prefix="W"
                        {...sizeWidthTestId}
                    />
                </Form.Group>
                <Form.Group>
                    <Input
                        type="number"
                        id="child-size-selector-height"
                        aria-label="child-size-selector-height"
                        aria-describedby="multisize_selector_height_message"
                        value={height}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        onChange={(newHeight) => onChange(DimensionType.HEIGHT, newHeight)}
                        suffix="px"
                        prefix="H"
                        {...sizeHeightTestId}
                    />
                </Form.Group>
            </Form.Row>
        </Modal>
    );
};

export default MultiSizeModal;
