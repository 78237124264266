import React, { FC } from 'react';
import { ContentTransformTypes } from '@bynder-studio/render-core';
import { Dropdown, Form, List, Thumbnail } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconArrowDown, IconArrowUp, IconCrop } from '@bynder/icons';
import useEditorSelectedElementContentTransform from '~/hooks/useEditorSelectedElementContentTransform';
import useEditorSelectedElement from '~/hooks/useEditorSelectedElement';
import useEditor from '~/hooks/useEditor';
import { getFreeCropModeParams } from '~/common/editor/helpers';
import { CropModeBaseProps, CropModeProps, TransformOptions, TransformSetting } from './types';
import { CropModeButton, CropModeListItem } from './styled';
import CropSetting from './CropSetting';
import generateTestId from '~/helpers/testIdHelpers';

const TRANSFORM_OPTOINS: TransformOptions = {
    [ContentTransformTypes.CONTAIN]: {
        value: ContentTransformTypes.CONTAIN,
        label: 'editor.sidebar.shots.crop.mode.contain',
        icon: <IconCrop />,
    },
    [ContentTransformTypes.COVER]: {
        value: ContentTransformTypes.COVER,
        label: 'editor.sidebar.shots.crop.mode.cover',
        icon: <IconCrop />,
    },
    [ContentTransformTypes.COVER_HEIGHT]: {
        value: ContentTransformTypes.COVER_HEIGHT,
        label: 'editor.sidebar.shots.crop.mode.cover-height',
        icon: <IconCrop />,
    },
    [ContentTransformTypes.COVER_WIDTH]: {
        value: ContentTransformTypes.COVER_WIDTH,
        label: 'editor.sidebar.shots.crop.mode.cover-width',
        icon: <IconCrop />,
    },
    [ContentTransformTypes.FREE]: {
        value: ContentTransformTypes.FREE,
        label: 'editor.sidebar.shots.crop.mode.free',
        icon: <IconCrop />,
    },
};

const testId = generateTestId('shots_crop_mode');
const testIdDropdown = generateTestId('shots_crop_mode__dropdown');
const testIdScale = generateTestId('shots_crop_mode__scale');
const testIdHorizontalOffset = generateTestId('shots_crop_mode__horizontal_offset');
const testIdVerticalOffset = generateTestId('shots_crop_mode__vertical_offset');
const testIdHorizontalAlignment = generateTestId('shots_crop_mode__horizontal_alignment');
const testIdVerticalAlignment = generateTestId('shots_crop_mode__vertical_alignment');

const CropModeBase: FC<CropModeBaseProps> = ({
    transformType,
    setTransformType,
    translate,
    opened,
    onToggle,
    setSettingValue,
    horizontalAlignment,
    verticalAlignment,
    horizontalOffset,
    minHorizontalOffset,
    maxHorizontalOffset,
    verticalOffset,
    minVerticalOffset,
    maxVerticalOffset,
    scale,
    disabled,
}) => {
    const selectedOption = TRANSFORM_OPTOINS[transformType];
    const isFreeform = selectedOption.value === ContentTransformTypes.FREE;

    const options = Object.values(TRANSFORM_OPTOINS).map(({ label, ...props }) => (
        <Dropdown.Item
            key={label}
            onClick={() => !disabled && setTransformType(ContentTransformTypes[props.value])}
            isChecked={selectedOption.label === label}
            isDisabled={disabled}
        >
            {translate(label)}
        </Dropdown.Item>
    ));

    return (
        <>
            <List.Item
                subtext={translate(selectedOption.label)}
                thumbnail={<Thumbnail shape="square" icon={selectedOption.icon} />}
                rightElements={opened ? <IconArrowUp /> : <IconArrowDown />}
                onClick={onToggle}
                isDisabled={disabled}
                {...testId}
            >
                {translate('editor.sidebar.shots.crop.title')}
            </List.Item>

            {opened && (
                <CropModeListItem>
                    <Form.Group>
                        <Form.Label>{translate('editor.sidebar.shots.crop.label')}</Form.Label>
                        <Dropdown
                            trigger={({ isOpen, onClick, ...triggerProps }) => (
                                <CropModeButton
                                    isPressed={isOpen}
                                    rightIcon={<Dropdown.Arrow />}
                                    isFullWidth
                                    isDisabled={disabled}
                                    onClick={!disabled ? onClick : () => {}}
                                    {...triggerProps}
                                    {...testIdDropdown}
                                >
                                    {translate(selectedOption.label)}
                                </CropModeButton>
                            )}
                            position="bottom"
                        >
                            {options}
                        </Dropdown>
                    </Form.Group>
                </CropModeListItem>
            )}
            {opened &&
                (isFreeform ? (
                    <>
                        <CropSetting
                            label={translate('editor.sidebar.shots.crop.scale')}
                            name="scale"
                            format="percent"
                            min={0}
                            max={2}
                            value={scale}
                            isFromCenter={false}
                            setSettingValue={setSettingValue}
                            disabled={disabled}
                            {...testIdScale}
                        />
                        <CropSetting
                            label={translate('editor.sidebar.shots.crop.horizontal-offset')}
                            name="horizontalOffset"
                            format="pixels"
                            min={minHorizontalOffset}
                            max={maxHorizontalOffset}
                            value={horizontalOffset}
                            setSettingValue={setSettingValue}
                            disabled={disabled}
                            {...testIdHorizontalOffset}
                        />
                        <CropSetting
                            label={translate('editor.sidebar.shots.crop.vertical-offset')}
                            name="verticalOffset"
                            format="pixels"
                            min={minVerticalOffset}
                            max={maxVerticalOffset}
                            value={verticalOffset}
                            setSettingValue={setSettingValue}
                            disabled={disabled}
                            {...testIdVerticalOffset}
                        />
                    </>
                ) : (
                    <>
                        <CropSetting
                            label={translate('editor.sidebar.shots.crop.horizontal-align')}
                            name="horizontalAlignment"
                            format="percent"
                            min={-1}
                            max={1}
                            value={horizontalAlignment}
                            setSettingValue={setSettingValue}
                            disabled={disabled}
                            {...testIdHorizontalAlignment}
                        />
                        <CropSetting
                            label={translate('editor.sidebar.shots.crop.vertical-align')}
                            name="verticalAlignment"
                            format="percent"
                            min={-1}
                            max={1}
                            value={verticalAlignment}
                            setSettingValue={setSettingValue}
                            disabled={disabled}
                            {...testIdVerticalAlignment}
                        />
                    </>
                ))}
        </>
    );
};

const CropMode: FC<CropModeProps> = ({ disabled, ...rest }) => {
    const { translate } = useTranslate();

    const { creativeModel } = useEditor();
    const { selectedElement } = useEditorSelectedElement();

    // todo: types, error handling
    const { contentTransform } = useEditorSelectedElementContentTransform();

    const {
        id,
        locked,
        naturalDimension: { width: naturalWidth, height: naturalHeight },
        dimension,
    } = selectedElement;

    const setTransformType = (newValue: ContentTransformTypes) => {
        const params = {
            contentTransform: {
                ...contentTransform,
                ...(newValue === 'FREE' ? getFreeCropModeParams(selectedElement) : {}),
                type: newValue,
            },
        };

        creativeModel.updateElement(id, params);
    };

    const setSettingValue = (newValue: number, setting: TransformSetting) => {
        const newTransform = { ...contentTransform };
        const { scale, horizontalOffset, verticalOffset } = contentTransform;

        switch (setting) {
            case 'horizontalAlignment':
            case 'verticalAlignment':
            case 'horizontalOffset':
            case 'verticalOffset':
                newTransform[setting] = newValue;
                break;
            case 'scale':
                newTransform.horizontalOffset = Math.round(
                    horizontalOffset + (naturalWidth * scale - naturalWidth * newValue) / 2,
                );
                newTransform.verticalOffset = Math.round(
                    verticalOffset + (naturalHeight * scale - naturalHeight * newValue) / 2,
                );
                newTransform.scale = newValue;
                break;
            default:
        }

        creativeModel.updateElement(id, { contentTransform: newTransform });
    };

    return (
        <CropModeBase
            {...rest}
            transformType={contentTransform.type}
            setTransformType={setTransformType}
            verticalAlignment={contentTransform?.verticalAlignment ?? 0.5}
            horizontalAlignment={contentTransform?.horizontalAlignment ?? 0.5}
            scale={contentTransform?.scale ?? 1}
            verticalOffset={contentTransform?.verticalOffset}
            minVerticalOffset={Math.floor(-1 * contentTransform.scale * naturalHeight)}
            maxVerticalOffset={Math.ceil(dimension.height)}
            horizontalOffset={contentTransform?.horizontalOffset}
            minHorizontalOffset={Math.floor(-1 * contentTransform.scale * naturalWidth)}
            maxHorizontalOffset={Math.ceil(dimension.width)}
            setSettingValue={setSettingValue}
            disabled={locked}
            translate={translate}
        />
    );
};

export default CropMode;
