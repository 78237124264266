import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslate } from '@bynder/localization';
import useAccessRights from 'packages/hooks/useAccessRights';
import { StatusFilterType } from 'packages/pages/components/filters/components/Status/types';
import ProtectedPage from 'packages/pages/components/ProtectedPage';
import { currentTemplateSelector } from 'packages/store/creativeEditor/creativeEditor.selectors';
import { getCreative } from 'packages/store/creatives/creatives.selectors';
import features from '~/configs/features';
import permissions from '~/configs/permissions';
import { PageWithAppcues } from '~/helpers/RouteWithAppcues';
import authorizationHelper from '~/helpers/AuthorizationHelper';
import CreativeEditor from './CreativeEditor';
import { colorPalettesFetching } from 'packages/store/brandColors/brandColors.selectors';
import { getColorPalettes } from 'packages/store/brandColors/colorPalettes.actions';
import { fetchCreative } from '~/store/creatives/common.actions';
import { getCreativeTemplate, resetEditor } from 'packages/store/creativeEditor/creativeEditor.actions';
import { initReview } from 'packages/store/review/review.actions';
import { hideLoading, showLoading } from 'react-redux-loading-bar';

export default function DesignEditor() {
    const { isPlatformAllowed } = useAccessRights();
    const params = useParams<{ creativeId: string }>();
    const dispatch = useDispatch();
    const template = useSelector(currentTemplateSelector);
    const creative = useSelector(getCreative);
    const isFetchingPalettes = useSelector(colorPalettesFetching);
    const { translate } = useTranslate();

    useEffect(() => {
        const id = params.creativeId;

        dispatch(fetchCreative(id, true));
        dispatch(getCreativeTemplate(id, false, 'creative'));
        dispatch(getColorPalettes());

        return () => {
            dispatch(resetEditor());
            dispatch(initReview());
        };
    }, [dispatch, params.creativeId]);

    const isLoaded = Boolean(template && creative && !isFetchingPalettes);

    useEffect(() => {
        dispatch(isLoaded ? hideLoading() : showLoading());
    }, [isLoaded, dispatch]);

    if (!isLoaded) {
        return null;
    }

    const hasEditPermission = authorizationHelper.hasPermissions([permissions.CREATIVES_SPECIFICATIONS.WRITE]);
    const isInApprovalFlow =
        isPlatformAllowed([features.APPROVALS_ENABLED]) &&
        template &&
        template.approvalEnabled &&
        [StatusFilterType.IN_REVIEW, StatusFilterType.APPROVED].includes(template.approvalState?.status);

    const getTitle = () => {
        if (!hasEditPermission) {
            // todo: is this the correct title?
            return translate('editor.approvals.locked.title');
        }

        if (isInApprovalFlow) {
            return translate('editor.approvals.locked.title');
        }

        return undefined;
    };

    const getDescription = () => {
        if (!hasEditPermission) {
            return translate('editor.no-access.desc');
        }

        if (isInApprovalFlow) {
            return translate('editor.approvals.locked.desc');
        }

        return undefined;
    };

    const getPrimaryAction = () => {
        if (!hasEditPermission) {
            return translate('modal.design.not_published.okay');
        }

        if (isInApprovalFlow) {
            return translate('editor.approvals.locked.primaryAction');
        }

        return undefined;
    };

    return (
        <PageWithAppcues>
            <ProtectedPage
                isAllowed={hasEditPermission && !isInApprovalFlow}
                redirectPath={template && creative ? `/designs/${creative.id}` : '/designs'}
                title={getTitle()}
                description={getDescription()}
                primaryActionText={getPrimaryAction()}
            >
                <CreativeEditor />
            </ProtectedPage>
        </PageWithAppcues>
    );
}
