import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const TimelineResizingStats = styled.div`
    position: fixed;
    z-index: 1;
    display: flex;
    justify-content: space-around;
    padding: 4px 0;
    top: 3px;
    left: 0;
    width: 200px;
    border-radius: 3px;
    background-color: ${token.gray800};
    color: ${token.white};
    font-size: 0.9em;
    opacity: 0;
    transition: opacity 0.2s ease-out;
    pointer-events: none;

    > span {
        display: inline-block;
        text-align: center;

        &:first-child,
        &:last-child {
            width: 66px;
        }
    }

    .timeline__resizing-duration-name {
        font-weight: bold;
    }
`;
