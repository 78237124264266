import React from 'react';
import { Button, Dropdown } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IdentityRole, IdentityType } from 'packages/store/sharing/types';
import useAccessRights from 'packages/hooks/useAccessRights';
import { ActionStatus, Entity } from 'packages/hooks/useAccessRights/types';
import features from '~/configs/features';
import roles from '~/configs/roles';
import { Props } from './types';

const ShareRuleDropdown = ({
    id,
    type,
    entityIndex,
    permissions,
    creativeActionType,
    creativeType,
    entityGrantedPermissions,
    projectGrantedPermissions,
    setTransferModalEntity,
    onShareRuleRemove,
}: Props) => {
    const { translate } = useTranslate();
    const { isEntityActionAllowed, isPlatformAllowed, isAccountRoleAssigned } = useAccessRights();

    const isContributorChecked = () =>
        permissions[entityIndex].grantedPermissions.some(
            (entity) => entity.permission === IdentityRole.CONTRIBUTOR && entity.granted,
        );

    const isTrialAccount =
        isPlatformAllowed([features.TRIAL_ENABLED]) && isAccountRoleAssigned(roles.video_brand_studio.video.manage);

    return (
        <Dropdown
            trigger={({ isOpen, ...triggerProps }) => (
                <Button
                    variant="clean"
                    isPressed={isOpen}
                    rightIcon={<Dropdown.Arrow />}
                    isDisabled={
                        isEntityActionAllowed(creativeActionType.UPDATE_PERMISSIONS, creativeType, [
                            ...entityGrantedPermissions,
                            ...projectGrantedPermissions,
                        ]) === ActionStatus.DISABLED || isTrialAccount
                    }
                    title={
                        creativeType === Entity.TEMPLATE || creativeType === Entity.CATEGORY
                            ? translate('modal.design.share.can-access')
                            : translate('modal.design.share.contributor')
                    }
                    {...triggerProps}
                >
                    {creativeType === Entity.TEMPLATE || creativeType === Entity.CATEGORY
                        ? translate('modal.design.share.can-access')
                        : translate('modal.design.share.contributor')}
                </Button>
            )}
            position="bottom-right"
        >
            <Dropdown.Item onClick={() => {}} isChecked={isContributorChecked()}>
                {creativeType === Entity.TEMPLATE || creativeType === Entity.CATEGORY
                    ? translate('modal.design.share.can-access')
                    : translate('modal.design.share.contributor')}
            </Dropdown.Item>
            <Dropdown.Divider />
            {type !== IdentityType.GROUP &&
                type !== IdentityType.PROFILE &&
                isEntityActionAllowed(creativeActionType.TRANSFER_OWNERSHIP, creativeType, [
                    ...entityGrantedPermissions,
                    ...projectGrantedPermissions,
                ]) === ActionStatus.ALLOWED &&
                setTransferModalEntity && (
                    <Dropdown.Item
                        onClick={() => {
                            setTransferModalEntity(entityIndex);
                        }}
                        isDisabled={
                            isEntityActionAllowed(creativeActionType.TRANSFER_OWNERSHIP, creativeType, [
                                ...entityGrantedPermissions,
                                ...projectGrantedPermissions,
                            ]) === ActionStatus.DISABLED
                        }
                    >
                        {translate('modal.design.share.transfer')}
                    </Dropdown.Item>
                )}
            <Dropdown.Item onClick={() => onShareRuleRemove(id, type)}>
                {translate('modal.design.share.remove')}
            </Dropdown.Item>
        </Dropdown>
    );
};

export default ShareRuleDropdown;
