import React, { useState } from 'react';
import { useTranslate } from '@bynder/localization';
import { Box, Button, Dropdown, Flex, Form, Tabs } from '@bynder/design-system';
import { IconGridFill, IconListView } from '@bynder/icons';
import { type FigmaPage as FigmaPageType } from 'packages/store/figma/types';
import { PagesDropdownContainer, StyledLabel } from './figma.styled';
import FigmaFrames, { type FigmaFramesProps } from './FigmaFrames';
import generateTestId from '~/helpers/testIdHelpers';

type PageProps = {
    page?: FigmaPageType;
} & Pick<FigmaFramesProps, 'selectedFramesIds' | 'toggleFrame' | 'isListView'>;

export type FigmaPagesProps = {
    pages: FigmaPageType[];
    selectedPageId?: string;
    selectPage: (pageId: string) => void;
} & Pick<PageProps, 'selectedFramesIds' | 'toggleFrame'>;

export default function FigmaPages({
    pages,
    selectedPageId,
    selectPage,
    selectedFramesIds,
    toggleFrame,
}: FigmaPagesProps) {
    const { translate } = useTranslate();
    const [view, setView] = useState<'grid' | 'list'>('grid');

    const selectedPage = pages.find((page) => page.id === selectedPageId) ?? pages[0];

    return (
        <>
            <Flex justifyContent="space-between" gap="4">
                <Form.Group>
                    <Flex gap="4" alignItems="center">
                        <StyledLabel>{translate('modal.design.create.design.figma.file.page')}</StyledLabel>
                        <PagesDropdownContainer>
                            <Dropdown
                                trigger={({ isOpen, ...triggerProps }) => (
                                    <Button
                                        variant="clean"
                                        isPressed={isOpen}
                                        rightIcon={<Dropdown.Arrow />}
                                        role="combobox"
                                        isFullWidth
                                        aria-label="Select page"
                                        {...triggerProps}
                                        {...generateTestId('figma-page-dropdown')}
                                        aria-haspopup="listbox"
                                    >
                                        <Dropdown.SelectButtonText>
                                            {selectedPage?.name || ''}
                                        </Dropdown.SelectButtonText>
                                    </Button>
                                )}
                            >
                                {pages.map((page) => (
                                    <Dropdown.Item
                                        onClick={() => {
                                            selectPage(page.id);
                                        }}
                                        key={page.id}
                                        isChecked={page.id === selectedPage.id}
                                        role="option"
                                    >
                                        {page.name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown>
                        </PagesDropdownContainer>
                    </Flex>
                </Form.Group>
                <Tabs variant="clean">
                    <Tabs.Tab
                        label={translate('modal.design.create.design.figma.frames.grid-view')}
                        isLabelHidden
                        icon={<IconGridFill />}
                        isActive={view === 'grid'}
                        onClick={() => setView('grid')}
                    />
                    <Tabs.Tab
                        label={translate('modal.design.create.design.figma.frames.list-view')}
                        isLabelHidden
                        icon={<IconListView />}
                        isActive={view === 'list'}
                        onClick={() => setView('list')}
                    />
                </Tabs>
            </Flex>
            <Box paddingBlock="4">
                <FigmaPage
                    key={selectedPage.id}
                    page={selectedPage}
                    selectedFramesIds={selectedFramesIds}
                    toggleFrame={toggleFrame}
                    isListView={view === 'list'}
                />
            </Box>
        </>
    );
}

function FigmaPage({ page, toggleFrame, selectedFramesIds, isListView }: PageProps) {
    if (!page) {
        return null;
    }

    return (
        <FigmaFrames
            frames={page.frames}
            toggleFrame={toggleFrame}
            selectedFramesIds={selectedFramesIds}
            isListView={isListView}
        />
    );
}
