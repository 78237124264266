import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Form, Input } from '@bynder/design-system';
import editorAutocorrect from 'packages/pages/editor/EditorAutocorrectRules';
import generateTestId from '~/helpers/testIdHelpers';
import { rgbToHex, hexToRgb, getAlpha } from './utils';
import ColorInput from './components/ColorInput';
import { toPercent, toValue } from '../../Shots/utils';

const ColorPicker = ({
    isMixed = false,
    isAlphaMixed = false,
    handleOpacityOnChange = null,
    value,
    disableAlpha,
    disabled,
    testId,
    id,
    handleOnChange,
    getSetData,
    label,
    htmlFor,
    describedby,
}: any) => {
    const [placeholder, setPlaceholder] = useState(isAlphaMixed ? 'Mixed' : '');
    const [state, setState] = useState<any>({
        show: false,
        inputColor: rgbToHex(value),
        alpha: toPercent(getAlpha(value)),
        alphaDisplay: `${toPercent(getAlpha(value))}%`,
        getColorMode: false,
    });

    const inputRef: any = useRef(null);
    const [changedByUser, setChangedByUser] = useState(false);

    const getSetDataFn = (v) => {
        if (getSetData) {
            return getSetData(v);
        }

        return v;
    };

    const pickerOnChange = (color) => {
        const newValue = getSetDataFn(color);
        handleOnChange(newValue);
    };

    const onColorInputChange = (newValue) => {
        setState((prevState) => ({
            ...prevState,
            inputColor: newValue,
        }));
    };

    useEffect(() => {
        if (isAlphaMixed) {
            setState({ ...state, alphaDisplayValue: '' });
            setPlaceholder('Mixed');
        }
    }, [isAlphaMixed, value, id]);

    useEffect(() => {
        const alpha = toPercent(getAlpha(value));
        setState((prevState) => ({
            ...prevState,
            inputColor: rgbToHex(value),
            alpha,
            alphaDisplayValue: `${toPercent(getAlpha(value))}%`,
            getColorMode: false,
        }));
    }, [value, id]);

    const inputOnBlur = () => {
        if (isMixed && state.inputColor === '#_____' && !changedByUser) {
            setChangedByUser(false);

            return;
        }

        const newValue = getSetDataFn(hexToRgb(state.inputColor, toValue(state.alpha)));

        setChangedByUser(false);
        setState((prevState) => ({
            ...prevState,
            inputColor: rgbToHex(value),
        }));

        handleOnChange(newValue);
    };

    const inputOnKeyDown = (e) => {
        setChangedByUser(true);

        if (e.keyCode === 13) {
            inputOnBlur();
            e.target.blur();
        }
    };

    const applyOpacityChange = (val: number) => {
        const correctValue = editorAutocorrect('element_opacity', val);

        setState((prevState) => ({ ...prevState, alpha: correctValue, alphaDisplayValue: `${correctValue}%` }));
        const newValue = getSetDataFn(hexToRgb(state.inputColor, toValue(correctValue)));
        const fnToUpdate = handleOpacityOnChange || handleOnChange;
        fnToUpdate(newValue);
    };

    const onOpacityBlur = () => {
        applyOpacityChange(state.alpha);
    };

    const onOpacityKeyDown = (event) => {
        switch (event.key) {
            case 'Enter':
                onOpacityBlur();
                event.target.blur();
                break;
            case 'ArrowUp':
            case 'ArrowDown': {
                event.preventDefault();
                const numberToAdd = event.key === 'ArrowDown' ? -1 : 1;
                const factor = event.shiftKey ? 10 : 1;
                applyOpacityChange(state.alpha + numberToAdd * factor);
                break;
            }
        }
    };

    const testIdProp = generateTestId(`${testId}__alpha`);

    return (
        <Form.Row>
            <ColorInput
                id={id}
                testId={testId}
                isMixed={isMixed}
                isAlphaMixed={isAlphaMixed}
                inputRef={inputRef}
                value={value}
                labels={{ label, htmlFor, describedby }}
                state={state}
                actions={{
                    inputOnBlur,
                    inputOnKeyDown,
                    pickerOnChange,
                    // toggle,
                    // toggleColorMode,
                    onChange: onColorInputChange,
                }}
                disabled={disabled}
            />
            {!disableAlpha && (
                <Input
                    type="text"
                    id={`${id}-text`}
                    value={state.alphaDisplayValue || ''}
                    placeholder={placeholder}
                    onBlur={onOpacityBlur}
                    onKeyDown={onOpacityKeyDown}
                    onChange={(alphaDisplayValue: any) => {
                        setState({ ...state, alpha: alphaDisplayValue.replace('%', ''), alphaDisplayValue });
                    }}
                    isDisabled={disabled}
                    {...testIdProp}
                    // icon={<MdOpacity />}
                    // suffix="%"
                />
            )}
        </Form.Row>
    );
};

export default ColorPicker;
