import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconPermission, IconUserGroup, IconUserRemoved } from '@bynder/icons';
import { Combobox, Spinner, List, Thumbnail } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import debounce from '~/helpers/debounce';
import { getSearchIdentities } from 'packages/store/sharing/sharing.selectors';
import { GroupSharingRole, IdentityType, Profiles, UserSharingRole } from 'packages/store/sharing/types';
import { dropSharingSearchResults, fetchBynderIdentities } from 'packages/store/sharing/sharing.actions';
import { Props, SharingRoleProps } from './types';

const INPUT_ID = 'search_identities';

const User = ({ item, onSelect, selected }: SharingRoleProps<UserSharingRole>) => {
    const onSelectUser = useCallback(() => {
        if (!selected) {
            onSelect({
                ...item,
                bynderUserId: item.userId,
                securityIdentityType: IdentityType.USER,
                fullName: item.fullName,
            });
        }
    }, [item, onSelect, selected]);

    const isDeletedUser = item.email === 'user@removed.com';

    return (
        <Combobox.ListItem
            onClick={onSelectUser}
            isChecked={selected}
            thumbnail={
                <Thumbnail
                    icon={
                        !isDeletedUser ? (
                            item.fullName && item.fullName[0] && item.fullName[0].toUpperCase()
                        ) : (
                            <IconUserRemoved />
                        )
                    }
                    shape="circle"
                />
            }
            subtext={!isDeletedUser ? item.email : ''}
        >
            {item.fullName}
        </Combobox.ListItem>
    );
};

const Group = ({ item, onSelect, selected }: SharingRoleProps<GroupSharingRole>) => {
    const { translate } = useTranslate();

    const onSelectGroup = useCallback(() => {
        if (!selected) {
            onSelect({ ...item, securityIdentityType: IdentityType.GROUP });
        }
    }, [item, onSelect, selected]);

    return (
        <Combobox.ListItem
            onClick={onSelectGroup}
            isChecked={selected}
            thumbnail={<Thumbnail icon={<IconUserGroup />} shape="circle" />}
            subtext={translate('modal.design.share.group')}
        >
            {item.groupName}
        </Combobox.ListItem>
    );
};

const Profile = ({ item, onSelect, selected }: SharingRoleProps<Profiles>) => {
    const { translate } = useTranslate();

    const onSelectProfile = useCallback(() => {
        if (!selected) {
            onSelect({ ...item, securityIdentityType: IdentityType.PROFILE });
        }
    }, [item, onSelect, selected]);

    return (
        <Combobox.ListItem
            onClick={onSelectProfile}
            isChecked={selected}
            thumbnail={<Thumbnail icon={<IconPermission />} shape="circle" />}
            subtext={translate('modal.design.share.profile')}
        >
            {item.profileName}
        </Combobox.ListItem>
    );
};

const IdentitiesSearch = ({ onIdentitySelect, existingIdentities, placeholder, isDisabled }: Props) => {
    const { translate } = useTranslate();
    const [query, setQuery] = useState('');
    const [isNewSearch, setIsNewSearch] = useState(true);
    const dispatch = useDispatch();

    const {
        identities: { users, groups, profiles },
        loading,
    } = useSelector(getSearchIdentities);

    const onSearch = useMemo(() => {
        const callback = (text: string) => {
            if (text.length) {
                dispatch(fetchBynderIdentities(text));
            } else {
                setIsNewSearch(true);
                dispatch(dropSharingSearchResults());
            }
        };

        if (isNewSearch) {
            return (text) => {
                setIsNewSearch(false);
                callback(text);
            };
        }

        return debounce(callback, 200, false);
    }, [dispatch, isNewSearch]);

    const handleQueryChange = useCallback(
        (text: string) => {
            if (isDisabled) {
                return;
            }

            setQuery(text);
            onSearch(text);
        },
        [onSearch, isDisabled],
    );

    const onSelect = useCallback(
        (data) => {
            onIdentitySelect(data);
            handleQueryChange('');
        },
        [onIdentitySelect, handleQueryChange],
    );

    useEffect(
        () => {
            document.querySelector<HTMLInputElement>(`#${INPUT_ID}`)?.focus();

            return () => {
                dispatch(dropSharingSearchResults());
            };
        },
        // focus on mount and cleanup on unmount, the dependency array should be empty
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const isSelected = (id: string) => existingIdentities.includes(id);

    return (
        <Combobox
            id={INPUT_ID}
            openOn="type"
            value={query}
            placeholder={placeholder || translate('modal.design.share.search.placeholder')}
            onChange={handleQueryChange}
            iconRight={loading ? <Spinner /> : undefined}
            isAnyValueAllowed
        >
            {users.length > 0 && (
                <>
                    {users.map((item) => (
                        <User onSelect={onSelect} item={item} selected={isSelected(item.userId)} key={item.userId} />
                    ))}
                </>
            )}
            {groups.length > 0 && (
                <>
                    {users.length > 0 && <List.Divider />}
                    {groups.map((item) => (
                        <Group item={item} onSelect={onSelect} selected={isSelected(item.groupId)} key={item.groupId} />
                    ))}
                </>
            )}
            {profiles.length > 0 && (
                <>
                    {(groups.length > 0 || users.length > 0) && <List.Divider />}
                    {profiles.map((item) => (
                        <Profile
                            item={item}
                            onSelect={onSelect}
                            selected={isSelected(item.profileId)}
                            key={item.profileId}
                        />
                    ))}
                </>
            )}
            {!loading && !users.length && !groups.length && !profiles.length && (
                <Combobox.Text>{translate('modal.sharing.search.no-results')}</Combobox.Text>
            )}
        </Combobox>
    );
};

export default IdentitiesSearch;
