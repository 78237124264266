import { createSelector } from 'reselect';

export const presetsSelector = (state) => state.presets.items;
export const selectedPresetIdSelector = (state) => state.presets.selectedItemId;

export const selectedPresetSelector = createSelector(presetsSelector, selectedPresetIdSelector, (items, selectedId) => {
    if (!items) return null;
    const item = items.find((i) => i.id === selectedId);
    return item && item.elements ? item : null;
});

export const selectedPresetElementSelector = createSelector(selectedPresetSelector, (preset) => ({
    ...Object.values(preset.elements)[0],
    presetId: preset.id,
    name: preset.name,
}));
