import React, { useCallback } from 'react';
import { Dropdown } from '@bynder/design-system';
import { IconMore } from '@bynder/icons';
import { ActionButton } from './List.styled';

export const ExpandCollapseAll = ({ rowRefs }) => {
    const expandAll = useCallback(() => {
        rowRefs.forEach((rowRef) => {
            rowRef.current.expand();
        });
    }, [rowRefs]);

    const collapseAll = useCallback(() => {
        rowRefs.forEach((rowRef) => {
            rowRef.current.collapse();
        });
    }, [rowRefs]);

    return (
        <Dropdown
            position="bottom-right"
            trigger={({ isOpen, ...triggerProps }) => (
                <ActionButton variant="clean" isPressed={isOpen} icon={<IconMore />} {...triggerProps} />
            )}
        >
            <Dropdown.Item onClick={expandAll}>Expand all rows</Dropdown.Item>
            <Dropdown.Item onClick={collapseAll}>Collapse all rows</Dropdown.Item>
        </Dropdown>
    );
};
