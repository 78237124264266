import React from 'react';
import { ALL_EXPORTS, DOWNLOAD, SEND_TO_WAITING_ROOM } from '~/helpers/textConstants';
import { Tabs } from '../enums';

const TabTitle = ({ tab }) => {
    const titlesMap = {
        [Tabs.Download]: DOWNLOAD,
        [Tabs.WaitingRoom]: SEND_TO_WAITING_ROOM,
        [Tabs.AllExports]: ALL_EXPORTS,
    };

    return <span>{titlesMap[tab] || ''}</span>;
};

export default React.memo(TabTitle);
