import { Directions } from '../../Enums/Directions';
import { createRectanglePath, createTrianglePath } from '../../Helpers/pathFunctions';
import { type ClipSoftness } from '../../types';

export const calculateWipe = (
    direction: Directions,
    softness: number,
    params: {
        ox: number;
        oy: number;
        w: number;
        h: number;
        p: number;
    },
) => {
    const { ox, oy, w, h, p } = params;
    const compEl: { clipPath: string; clipSoftness?: ClipSoftness } = { clipPath: '' };

    if (
        [Directions.BOTTOM_LEFT, Directions.BOTTOM_RIGHT, Directions.TOP_LEFT, Directions.TOP_RIGHT].includes(direction)
    ) {
        const angle = Math.atan2(h, w);
        const softnessW = softness / Math.sin(angle) || 0;
        const softnessH = softness / Math.cos(angle) || 0;
        const p1 = p * ((2 * w + softnessW) / (2 * w));
        const p2 = p * ((2 * h + softnessH) / (2 * h));
        const w2p = w * 2 * p1;
        const h2p = h * 2 * p2;
        const length = Math.sqrt(w2p ** 2 + h2p ** 2);

        switch (direction) {
            case Directions.BOTTOM_LEFT: {
                compEl.clipPath = createTrianglePath(ox + w, oy, w - w2p, 0, w, h2p);

                if (softness) {
                    compEl.clipSoftness = {
                        rectangle: {
                            x: ox + w - w2p,
                            y: oy,
                            w: length,
                            h: -softness - 2,
                            angle,
                            dx: 1,
                            dy: 1,
                        },
                        gradient: {
                            x0: w + softness,
                            y0: -softness,
                            x1: w + softness,
                            y1: 0,
                        },
                    };
                }

                break;
            }
            case Directions.BOTTOM_RIGHT: {
                compEl.clipPath = createTrianglePath(ox, oy, w2p, 0, 0, h2p);

                if (softness) {
                    compEl.clipSoftness = {
                        rectangle: {
                            x: ox,
                            y: oy + h2p,
                            w: length,
                            h: -softness - 2,
                            angle: -angle,
                            dx: 1,
                            dy: 1,
                        },
                        gradient: {
                            x0: softness,
                            y0: h2p - softness,
                            x1: softness,
                            y1: h2p,
                        },
                    };
                }

                break;
            }
            case Directions.TOP_LEFT: {
                compEl.clipPath = createTrianglePath(ox + w, oy + h, w - w2p, h, w, h - h2p);

                if (softness) {
                    compEl.clipSoftness = {
                        rectangle: {
                            x: ox + w - w2p,
                            y: oy + h,
                            w: length,
                            h: softness + 2,
                            angle: -angle,
                            dx: -1,
                            dy: -1,
                        },
                        gradient: {
                            x0: w + softness,
                            y0: h + softness,
                            x1: w + softness,
                            y1: h,
                        },
                    };
                }

                break;
            }
            case Directions.TOP_RIGHT: {
                compEl.clipPath = createTrianglePath(ox, oy + h, w2p, h, 0, h - h2p);

                if (softness) {
                    compEl.clipSoftness = {
                        rectangle: {
                            x: ox,
                            y: oy + h - h2p,
                            w: length,
                            h: softness + 2,
                            angle,
                            dx: -1,
                            dy: -1,
                        },
                        gradient: {
                            x0: softness,
                            y0: h - h2p + softness,
                            x1: softness,
                            y1: h - h2p,
                        },
                    };
                }

                break;
            }
        }
    }

    switch (direction) {
        case Directions.UP: {
            const p1 = p * ((h + (softness || 0)) / h);
            compEl.clipPath = createRectanglePath(ox, oy + h * (1 - p1), w, h * p1);

            if (softness) {
                compEl.clipSoftness = {
                    rectangle: {
                        x: ox,
                        y: oy + h * (1 - p1),
                        w,
                        h: softness + 2,
                        dy: -1,
                    },
                    gradient: {
                        x0: ox + w / 2,
                        y0: oy + h * (1 - p1) + softness,
                        x1: ox + w / 2,
                        y1: oy + h * (1 - p1),
                    },
                };
            }

            break;
        }
        case Directions.RIGHT: {
            const p1 = p * ((w + (softness || 0)) / w);
            compEl.clipPath = createRectanglePath(ox, oy, w * p1, h);

            if (softness) {
                compEl.clipSoftness = {
                    rectangle: {
                        x: ox + w * p1 - softness,
                        y: oy,
                        w: softness + 2,
                        h,
                        dx: 1,
                    },
                    gradient: {
                        x0: ox + w * p1 - softness,
                        y0: oy + h / 2,
                        x1: ox + w * p1,
                        y1: oy + h / 2,
                    },
                };
            }

            break;
        }
        case Directions.DOWN: {
            const p1 = p * ((h + (softness || 0)) / h);
            compEl.clipPath = createRectanglePath(ox, oy, w, h * p1);

            if (softness) {
                compEl.clipSoftness = {
                    rectangle: {
                        x: ox,
                        y: oy + h * p1 - softness,
                        w,
                        h: softness + 2,
                        dy: 1,
                    },
                    gradient: {
                        x0: ox + w / 2,
                        y0: oy + h * p1 - softness,
                        x1: ox + w / 2,
                        y1: oy + h * p1,
                    },
                };
            }

            break;
        }
        case Directions.LEFT: {
            const p1 = p * ((w + (softness || 0)) / w);
            compEl.clipPath = createRectanglePath(ox + w * (1 - p1), oy, w * p1, h);

            if (softness) {
                compEl.clipSoftness = {
                    rectangle: {
                        x: ox + w * (1 - p1),
                        y: oy,
                        w: softness + 2,
                        h,
                        dx: -1,
                    },
                    gradient: {
                        x0: ox + w * (1 - p1) + softness,
                        y0: oy + h / 2,
                        x1: ox + w * (1 - p1),
                        y1: oy + h / 2,
                    },
                };
            }

            break;
        }
    }

    return compEl;
};
