import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const StyledModalBasedContent = styled.div<{ isLocal: boolean }>`
    margin: ${(props) => (props.isLocal ? 0 : `calc(${token.spacing5} * -1) calc(${token.spacing6} * -1)`)};
    padding: 0;
    height: 100%;
`;

export const FullHeightWrapper = styled.div`
    height: 100%;
    padding-bottom: ${token.spacing6};
`;

export const StyledSidebar = styled.div`
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
`;

export const GlobalNav = styled.div`
    padding-bottom: ${token.spacing6};
`;
