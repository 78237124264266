import { OwnershipFilterType } from 'packages/pages/components/filters/components/Ownership/types';
import { updateThumbnails } from 'packages/store/creatives/utils';
import { updateThumbnails as updateThumbnailsForAssets } from 'packages/store/uploads/utils';
import * as types from '../types';

const initialState = {
    collections: [],
    collection: null,
    isFetching: false,
    checked: [],
    allChecked: false,
    totalItems: 0,
    searching: false,
    fetchOptions: {
        sort: ['date_created', 'desc'],
        size: 40,
        page: 0,
        search: '',
        searchTime: null,
        includeCollectionId: null,
        type: null,
        requestedType: null, // for race condition between pages
        ownershipSource: OwnershipFilterType.OWNED_BY_ANYONE,
    },
    isCollectionFetching: false,
    renamingInProgress: false,
    deleteInProgress: false,
    creatingInProgress: false,
    addingInProgress: false,
    removingFromCollectionInProgress: false,
    editingInProgress: false,
};

export default function collections(state = initialState, action) {
    switch (action.type) {
        case types.COLLECTIONS_RESET:
            return {
                ...initialState,
            };
        case types.COLLECTIONS_RESET_OWNERSHIP:
            return {
                ...initialState,
                fetchOptions: {
                    ...initialState.fetchOptions,
                    ownershipSource: action.ownershipSource,
                },
            };
        case types.COLLECTION_RESET:
            return {
                ...state,
                collection: null,
            };
        case types.COLLECTIONS_START_FETCH:
            return {
                ...state,
                isFetching: true,
                searching: action.searching,
                collections: action.emptyBeforeFetch ? [] : state.collections,
                fetchOptions: {
                    ...state.fetchOptions,
                    searchTime: action.searchTime,
                    requestedType: action.requestedType,
                },
            };
        case types.COLLECTIONS_FAIL_FETCH:
            return {
                ...state,
                isFetching: false,
            };
        case types.COLLECTIONS_SUCCESS_FETCH: {
            const newCollections = action.collections.map((item) => ({
                ...item,
                checked: state.checked.includes(item.id),
            }));

            return {
                ...state,
                isFetching: false,
                collections: [...state.collections, ...newCollections],
                totalItems: action.totalItems,
                fetchOptions: action.fetchOptions,
            };
        }
        case types.COLLECTIONS_CHANGE_SOME: {
            if (!action.creativeThumbnails.length || (!state.collections.length && !state.collection)) {
                return state;
            }

            const newCollections = new Array(state.collections.length);
            let isCollectionsUpdated = false;

            state.collections.forEach((collection, collectionIdx) => {
                if (!collection.previewItems) {
                    newCollections[collectionIdx] = collection;

                    return;
                }

                const { creatives: newPreviewItems, isUpdated: isPreviewItemsUpdated } = updateThumbnails(
                    collection.previewItems,
                    action.creativeThumbnails,
                );

                if (!isPreviewItemsUpdated) {
                    newCollections[collectionIdx] = collection;

                    return;
                }

                isCollectionsUpdated = true;

                newCollections[collectionIdx] = {
                    ...collection,
                    checked: state.checked.includes(collection.id),
                    previewItems: newPreviewItems,
                };
            });

            let newCollection = null;
            let isCollectionUpdated = false;

            if (state.collection && state.collection.type === 'ASSET') {
                const { uploads: newPreviewItems, isUpdated: isPreviewItemsUpdated } = updateThumbnailsForAssets(
                    state.collection.previewItems,
                    action.creativeThumbnails,
                );

                if (!isPreviewItemsUpdated) {
                    newCollection = state.collection;
                } else {
                    newCollection = {
                        ...state.collection,
                        previewItems: newPreviewItems,
                    };

                    isCollectionUpdated = true;
                }
            } else {
                newCollection = state.collection;
            }

            if (!(isCollectionsUpdated || isCollectionUpdated)) {
                return state;
            }

            return {
                ...state,
                collection: newCollection,
                collections: newCollections,
            };
        }
        case types.COLLECTIONS_TOGGLE_ITEM:
            return {
                ...state,
                checked: state.checked.includes(action.id)
                    ? state.checked.filter((e) => e !== action.id)
                    : [...state.checked, action.id],
                allChecked: state.collections.every((collection) => {
                    return (
                        (collection.checked && collection.id !== action.id) ||
                        (!collection.checked && collection.id === action.id)
                    );
                }),
                collections: state.collections.map((collection) =>
                    collection.id === action.id ? { ...collection, checked: !collection.checked } : collection,
                ),
            };
        case types.COLLECTIONS_TOGGLE_ALL:
            return {
                ...state,
                allChecked: !state.allChecked,
                collections: state.collections.map((collection) => ({ ...collection, checked: !state.allChecked })),
                checked: state.allChecked
                    ? (() => {
                          const res = Object.assign(state.checked);
                          state.collections.forEach((c) => {
                              res.splice(res.indexOf(c.id), 1);
                          });

                          return res;
                      })()
                    : [
                          ...state.checked,
                          ...state.collections.map((c) => c.id).filter((c) => !state.checked.includes(c)),
                      ],
            };
        case types.COLLECTION_START_FETCH:
            return {
                ...state,
                isCollectionFetching: true,
            };
        case types.COLLECTION_SUCCESS_FETCH:
            return {
                ...state,
                isCollectionFetching: false,
                collection: action.collection,
            };
        case types.COLLECTION_FAILURE_FETCH:
            return {
                ...state,
                isCollectionFetching: false,
                collection: action.collection,
            };
        case types.COLLECTION_DELETE_IN_PROGRESS:
            return {
                ...state,
                deleteInProgress: true,
            };
        case types.COLLECTION_DELETE_SUCCESS:
            return {
                ...state,
                deleteInProgress: false,
            };
        case types.COLLECTION_DELETE_FAILURE:
            return {
                ...state,
                deleteInProgress: false,
            };
        case types.COLLECTION_RENAME_IN_PROGRESS:
            return {
                ...state,
                renamingInProgress: true,
            };
        case types.COLLECTION_RENAME_SUCCESS:
            return {
                ...state,
                renamingInProgress: false,
            };
        case types.COLLECTION_RENAME_FAILURE:
            return {
                ...state,
                renamingInProgress: false,
            };
        case types.COLLECTION_ADD_CREATIVE_IN_PROGRESS:
            return {
                ...state,
                addingInProgress: true,
            };
        case types.COLLECTION_ADD_CREATIVE_SUCCESS:
            return {
                ...state,
                addingInProgress: false,
            };
        case types.COLLECTION_ADD_CREATIVE_FAILURE:
            return {
                ...state,
                addingInProgress: false,
            };
        case types.COLLECTION_REMOVE_FROM_CREATIVE_IN_PROGRESS:
            return {
                ...state,
                removingFromCollectionInProgress: true,
            };
        case types.COLLECTION_REMOVE_FROM_CREATIVE_SUCCESS:
            return {
                ...state,
                removingFromCollectionInProgress: false,
            };
        case types.COLLECTION_REMOVE_FROM_CREATIVE_FAILURE:
            return {
                ...state,
                removingFromCollectionInProgress: false,
            };
        case types.COLLECTION_ADD_NEW_IN_PROGRESS:
            return {
                ...state,
                creatingInProgress: true,
            };
        case types.COLLECTION_ADD_NEW_SUCCESS:
            return {
                ...state,
                creatingInProgress: false,
            };
        case types.COLLECTION_ADD_NEW_FAILURE:
            return {
                ...state,
                creatingInProgress: false,
            };
        case types.COLLECTION_EDIT_IN_PROGRESS:
            return {
                ...state,
                editingInProgress: true,
            };
        case types.COLLECTION_EDIT_SUCCESS:
            return {
                ...state,
                editingInProgress: false,
            };
        case types.COLLECTION_EDIT_FAILURE:
            return {
                ...state,
                editingInProgress: false,
            };
        default:
            return state;
    }
}
