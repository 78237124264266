import { equals } from 'rambda';

export const extractUnique = <T>(itemsList: T[]) => {
    const temp: T[] = [];
    itemsList.forEach((item) => {
        if (!temp.find((existedItem) => equals(existedItem, item))) {
            temp.push(item);
        }
    });

    return temp;
};
