import React from 'react';
import { getUnixTime } from 'date-fns';
import { notify, Thumbnail } from '@bynder/design-system';
import { IconCancel } from '@bynder/icons';
import { Translate } from '@bynder/localization';
import { OwnershipFilterType } from 'packages/pages/components/filters/components/Ownership/types';
import { isTrialAccountStorageFn, catchResponseError } from 'packages/helpers/helpers';
import CollectionsService, { createQuery } from '~/services/CollectionsService';
import { searchActionTypes } from './types';

export function searchCategories(options, customerId, emptyBeforeFetch = false) {
    return (dispatch, getState) => {
        const state = getState();
        const searchTime = getUnixTime(new Date(Date.now()));

        const fetchOptions = options || state.categories.fetchOptions;

        if (emptyBeforeFetch) {
            fetchOptions.page = 0;
        }

        const query = createQuery({ ...fetchOptions, customerId });

        if (options && state.categories.fetchOptions.search !== options.search) {
            dispatch({
                type: searchActionTypes.CATEGORIES_SEARCH_START_FETCH,
                searching: true,
                searchTime,
                emptyBeforeFetch,
            });
        } else {
            dispatch({ type: searchActionTypes.CATEGORIES_SEARCH_START_FETCH, searching: false, emptyBeforeFetch });
        }

        CollectionsService.getCollections(query)
            .then(({ status, json }) => {
                const { items: categories, totalItems, page } = json;

                if (status !== 200) {
                    dispatch({ type: searchActionTypes.CATEGORIES_SEARCH_FAIL_FETCH });
                    notify({
                        thumbnail: (
                            <Thumbnail
                                variant="clean"
                                shape="circle"
                                backgroundColor="red500"
                                icon={<IconCancel />}
                                iconColor="white"
                            />
                        ),
                        title: <Translate id="pages.categories.message.failure" />,
                    });
                } else {
                    if (
                        state.categories.searching &&
                        state.categories.fetchOptions.searchTime &&
                        state.categories.fetchOptions.searchTime > searchTime
                    ) {
                        return;
                    }

                    if (page) {
                        fetchOptions.page = page;
                    }

                    dispatch({
                        type: searchActionTypes.CATEGORIES_SEARCH_SUCCESS_FETCH,
                        categories,
                        totalItems,
                        emptyBeforeFetch,
                        fetchOptions,
                    });
                }
            })
            .catch(catchResponseError);
    };
}

export function resetOwnership() {
    return (dispatch, getState) => {
        dispatch({
            type: searchActionTypes.CATEGORIES_SEARCH_RESET_OWNERSHIP,
            ownershipSource: OwnershipFilterType.OWNED_BY_OTHERS,
        });
    };
}

export function initCategories(params = {}) {
    return (dispatch, getState) => {
        dispatch({ type: searchActionTypes.CATEGORIES_SEARCH_RESET });

        if (isTrialAccountStorageFn(getState())) {
            dispatch(resetOwnership());
        }

        const options = { ...getState().categories.fetchOptions, ...params };
        dispatch(searchCategories(options, getState().user.currentCompany, true));
    };
}
