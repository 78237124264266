import React from 'react';
import { Form, Select } from '@bynder/design-system';
import {
    IconArrowBottomLeft,
    IconArrowBottomRight,
    IconArrowTop,
    IconArrowTopLeft,
    IconArrowForward,
    IconArrowBottom,
    IconArrowBack,
    IconArrowTopRight,
} from '@bynder/icons';
import generateTestId from '~/helpers/testIdHelpers';

const NAMES = ['direction', 'timing'] as const;

export type SelectFieldProp = (typeof NAMES)[number];

export const isSelectField = (name: string): name is SelectFieldProp => NAMES.includes(name as SelectFieldProp);

const DIRECTION_ICON = {
    UP: <IconArrowTop />,
    TOP_RIGHT: <IconArrowTopRight />,
    RIGHT: <IconArrowForward />,
    BOTTOM_RIGHT: <IconArrowBottomRight />,
    DOWN: <IconArrowBottom />,
    BOTTOM_LEFT: <IconArrowBottomLeft />,
    LEFT: <IconArrowBack />,
    TOP_LEFT: <IconArrowTopLeft />,
} as const;

type Option = { value: string; displayValue: string; isSubtitle?: boolean };

const SelectFieldItem = (props: Option & { name: SelectFieldProp }) => (
    <Select.Item value={props.value} isDisabled={props.isSubtitle}>
        {props.name === 'direction' ? (
            <>
                {DIRECTION_ICON[props.value]}
                {props.displayValue.substring(1)}
            </>
        ) : (
            props.displayValue
        )}
    </Select.Item>
);

type SelectLabelProps = { name: SelectFieldProp; defaultOption: Option };

const SelectLabel = ({ name, defaultOption }: SelectLabelProps) => {
    switch (name) {
        case 'direction':
            return (
                <>
                    {DIRECTION_ICON[defaultOption.value]}
                    {defaultOption.displayValue.substring(1)}
                </>
            );
        default:
            // we need the fragment here in order to return React.JSX.Element
            // eslint-disable-next-line react/jsx-no-useless-fragment
            return <>{defaultOption.displayValue}</>;
    }
};

type SecletProps = {
    label: string;
    disabled?: boolean;
    id: string;
    value: string;
    onChange: (name: SelectFieldProp, value: string) => void;
    options: Option[];
    name: SelectFieldProp;
    testId: string;
};

const SelectField = ({ label, disabled = false, id, value, onChange, options, name, testId }: SecletProps) => {
    const labelId = `${id}_label`;
    const testIdProp = generateTestId(testId);
    const defaultOption = options.find((i) => i.value === value);

    const handleChange = (newValue: string) => {
        onChange(name, newValue);
    };

    if (!defaultOption) {
        throw new Error('No option matches with the default value');
    }

    return (
        <>
            <Form.Label id={labelId}>{label}</Form.Label>
            <Select
                {...testIdProp}
                value={value}
                label={<SelectLabel defaultOption={defaultOption} name={name} />}
                aria-labelledby={labelId}
                onChange={handleChange}
                isDisabled={disabled}
            >
                {options.map((item) => (
                    <SelectFieldItem {...item} name={name} key={item.value} />
                ))}
            </Select>
        </>
    );
};

export default SelectField;
