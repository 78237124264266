import React, { useEffect, useState } from 'react';
import { BaseMultiPageModel, ShapeElement } from '@bynder-studio/render-core';
import { useTranslate } from '@bynder/localization';
import { Dropdown, Form } from '@bynder/design-system';
import { IconPlaylistAdd } from '@bynder/icons';
import { useShotColor } from 'packages/pages/design/sidebar/shots/useShotColor';
import { useColorStyleModal } from 'packages/pages/editor/RightSideMenu/FormComponents/EditorColor/components/modals/useColorStylesModal';
import { colorToCSS } from '~/common/editor/editorHelper';
import EditorColor from '../../../FormComponents/EditorColor';

type Props = {
    selectedElement: ShapeElement;
    creativeModel: BaseMultiPageModel;
    disabled: boolean;
};

// eslint-disable-next-line import/no-unused-modules
export const BorderColor = ({ selectedElement, creativeModel, disabled }: Props) => {
    const { id, locked } = selectedElement;
    const { translate } = useTranslate();
    const [selectedBorderColor, setSelectedBorderColor] = useState(colorToCSS(selectedElement.borderColor));
    const baseColorParams = {
        selectedElement,
        brandColorProperty: 'borderBrandColors',
        propertyName: 'borderColor',
    };
    const { appliedCustomColors, elementColors, onAppliedClick, handleChangeColor } = useShotColor({
        ...baseColorParams,
        creativeModel,
    });
    const { renderColorStylesModal, openConfigureColorsModal } = useColorStyleModal({
        ...baseColorParams,
        title: translate('editor.sidebar.shots.shape.border_color.configure.modal.title'),
    });

    useEffect(() => {
        setSelectedBorderColor(colorToCSS(selectedElement.borderColor));
    }, [selectedElement.borderColor, id]);

    return (
        <>
            <Form.Group>
                <Form.Label>{translate('editor.sidebar.shots.shape.border_color.label')}</Form.Label>
                <EditorColor
                    name="borderColor"
                    property="borderColor"
                    color={selectedBorderColor}
                    disabled={locked}
                    onChange={handleChangeColor}
                    elementColors={elementColors}
                    onAppliedClick={onAppliedClick}
                    selectedCustomColors={appliedCustomColors}
                    testId="shots_shape_border__color"
                    selectedElement={selectedElement}
                    configureColorsButton={
                        <Dropdown.Item
                            isDisabled={disabled}
                            icon={<IconPlaylistAdd />}
                            onClick={openConfigureColorsModal}
                        >
                            {translate('editor.sidebar.shots.shape.configure-border-color-styles')}
                        </Dropdown.Item>
                    }
                />
            </Form.Group>
            {renderColorStylesModal()}
        </>
    );
};

export default BorderColor;
