import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const PortionSelectorLoaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% + 48px);
    margin-left: -24px;
    padding-left: 24px;
    padding-right: 24px;
`;

export const PortionSelectorLoaderWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    .portion--selector-fake-player {
        width: 100%;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 240px;
        background: ${token.colorShadow};
        border-radius: 5px;
        overflow: hidden;

        .loader {
            border-left-color: ${token.gray400a};
        }

        > svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -80%);

            path {
                fill: ${token.gray200a};
            }
        }

        &.fake--player-audio {
            height: 48px;
        }
    }

    .portion--selector-fake-controls-panel {
        display: flex;
        align-items: center;
        width: 100%;
        height: 48px;
        margin-block-start: auto;
        background: ${token.gray900a};

        p {
            font-size: 16px;
            line-height: 20px;
            color: ${token.colorText};
        }
    }

    .portion--selector-fake-duration-container p {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: ${token.colorTextMuted};
    }

    .portion--selector-fake-duration-title {
        font-size: 16px;
        line-height: 20px;
        color: ${token.colorText};
    }

    .portion--selector-fake-duration-status {
        font-size: 16px;
        line-height: 20px;
        color: ${token.colorTextMuted};
    }

    .portion--selector-duration-fake-bar {
        display: block;
        width: 100%;
        height: 4px;
        background: ${token.gray100a};
        border-radius: 4px;
    }

    .portion--selector-fake-control-item {
        display: block;
        width: 24px;
        height: 24px;

        svg path {
            fill: ${token.gray400a};
        }
    }
`;
