import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useTextSelection from 'packages/hooks/useTextSelection';
import { fontsElementsSelector } from 'packages/store/creativeEditor/creativeEditor.selectors';
import type { FontFamily, ExtendedFont, AllFontsMap, FontsMapByFamily, ExtendedFontByFamily } from './types';

export default function useFonts() {
    const { fontIdData } = useTextSelection();
    const fonts: FontFamily[] = useSelector(fontsElementsSelector);
    const [fontId, isMixed, mixedValue] = fontIdData;

    const fontsList: { [fontName: string]: FontFamily } = useMemo(() => {
        const temp = {};
        fonts.forEach((font) => {
            temp[font.name] = font;
        });

        return temp;
    }, [fonts]);

    const [familyList, idToFont]: [FontsMapByFamily, AllFontsMap] = useMemo(() => {
        const tempFamilyList = {} as FontsMapByFamily;
        const tempIdToFont = {} as AllFontsMap;

        fonts.forEach((font) => {
            tempFamilyList[font.name] = font.fonts;
            font.fonts.forEach((f) => {
                tempIdToFont[f.id.toString()] = {
                    ...f,
                    familyName: font.name,
                    name: f.name || f.style || f.id.toString(),
                    style: f.style,
                    weight: f.weight,
                };
            });
        });

        return [tempFamilyList, tempIdToFont];
    }, [fonts]);

    const currentFont: ExtendedFontByFamily = useMemo(() => idToFont[Number(fontId)], [idToFont, fontId]);

    const loadDefaultFontByFamily = (fonts): ExtendedFont =>
        fonts.find((f) => f?.style === currentFont?.style) || fonts.find((f) => f?.style === 'REGULAR') || fonts[0];

    return {
        fontId,
        isMixed,
        mixedValue,
        fonts,
        fontsList,
        familyList,
        currentFont,
        idToFont,
        loadDefaultFontByFamily,
    };
}
