import * as React from 'react';
import {
    type BaseMultiPageModel,
    type TextElement,
    TextDecoration as TextDecorationEnum,
} from '@bynder-studio/render-core';
import { Tabs, Form, Tooltip } from '@bynder/design-system';
import { IconMin, IconStrikeThrough, IconUnderline } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import generateTestId from '~/helpers/testIdHelpers';
import useTextSelection from 'packages/hooks/useTextSelection';
import { OutlineButton } from './index.styled';

type Props = {
    creativeModel: BaseMultiPageModel;
    selectedElement: TextElement;
};

const OUTLINE_OPTIONS = [
    { name: TextDecorationEnum.NONE, icon: <IconMin /> },
    { name: TextDecorationEnum.UNDERLINE, icon: <IconUnderline /> },
    { name: TextDecorationEnum.STRIKETHROUGH, icon: <IconStrikeThrough /> },
] as const;

const testId = generateTestId('shots_text_decoration');

const Decoration = ({ creativeModel, selectedElement }: Props) => {
    const { translate } = useTranslate();
    const { textDecorationData } = useTextSelection();
    const [textDecoration, isMixed] = textDecorationData;
    const { id, locked } = selectedElement;

    const handleChange = (newValue: TextDecorationEnum) => () => {
        const param = {
            updateTextSettingForSelection: {
                settings: {
                    textDecoration: newValue,
                },
            },
        };

        creativeModel.updateElement(id, param);
    };

    return (
        <Form.Group>
            <Form.Label>{translate('editor.sidebar.shots.text.decoration.title')}</Form.Label>
            <Tabs variant="clean" {...testId}>
                {OUTLINE_OPTIONS.map((option) => (
                    <Tooltip
                        key={option.name}
                        content={translate(`editor.sidebar.shots.text.decoration.${option.name.toLowerCase()}.label`)}
                    >
                        <OutlineButton
                            title=""
                            variant="clean"
                            icon={option.icon}
                            isDisabled={locked}
                            isPressed={textDecoration === option.name && !isMixed}
                            onClick={handleChange(option.name)}
                            {...generateTestId(
                                `shots_text_decoration_${option.name.toLowerCase()}${
                                    !isMixed && textDecoration === option.name ? '_selected' : ''
                                }`,
                            )}
                        />
                    </Tooltip>
                ))}
            </Tabs>
        </Form.Group>
    );
};

export default Decoration;
