import React, { useState } from 'react';
import { Button, ModalBase } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import UploadButton from 'packages/pages/components/Uploader/UploadButton';
import AssetsPicker from 'packages/pages/components/AssetsPickerModal/components/AssetsPicker';
import useActions from 'packages/pages/components/AssetsPickerModal/components/useActions';
import Sidebar from 'packages/pages/components/AssetsPickerModal/components/AssetsPickerSidebar';
import { StyledModalBasedContent } from 'packages/pages/components/AssetsPickerModal/components/index.styled';
import { AssetGroup } from 'packages/pages/editor/ContentPickerModal/types';

type Props = {
    type: string;
    compactViewProps: any;
    confirmSelection: (asset: any) => void;
    cancelSelection: () => void;
};

const useDocumentPickerModal = (props: Props) => {
    const { translate } = useTranslate();
    const [isOpen, setIsOpen] = useState(false);
    const {
        assetType,
        wrapperRef,
        collectionId,
        selectedLocalAsset,
        allowInternalAsset,
        handleDamClick,
        setSelectedCollection,
        onLocalAssetClick,
    } = useActions({});

    const { type, cancelSelection } = props;

    const toggleDocumentPicker = () => {
        setIsOpen(!isOpen);
    };

    const renderDocumentPicker = () => (
        <ModalBase
            size="fullscreen"
            isOpen={isOpen}
            onClose={() => {
                cancelSelection();
                toggleDocumentPicker();
            }}
        >
            <ModalBase.Header
                title={translate('modal.design.create.design.import.document_picker.title')}
                additionalActions={
                    assetType === AssetGroup.LOCAL ? (
                        <UploadButton iconType="upload" assetTypes={['document']} collectionId={collectionId} />
                    ) : (
                        <></>
                    )
                }
            />
            {allowInternalAsset && (
                <ModalBase.Sidebar>
                    <Sidebar
                        assetType={assetType}
                        type={type}
                        selectedCollectionId={collectionId}
                        setSelectedCollection={setSelectedCollection}
                        handleDamClick={handleDamClick}
                    />
                </ModalBase.Sidebar>
            )}

            <ModalBase.Content>
                <StyledModalBasedContent isLocal={assetType === AssetGroup.LOCAL} ref={wrapperRef}>
                    <AssetsPicker
                        type={type}
                        assetType={assetType}
                        uploadTypes={['document']}
                        filterTypes={['PSD', 'SKETCH']}
                        collectionId={collectionId}
                        selectedItem={selectedLocalAsset}
                        compactViewProps={props.compactViewProps}
                        onSelectLocal={onLocalAssetClick}
                    />
                </StyledModalBasedContent>
            </ModalBase.Content>

            {assetType === AssetGroup.LOCAL && selectedLocalAsset && (
                <ModalBase.Footer
                    actionPrimary={<></>}
                    actionSecondary={
                        <Button
                            variant="primary"
                            isDisabled={!selectedLocalAsset}
                            onClick={() => {
                                props.confirmSelection(selectedLocalAsset);
                            }}
                        >
                            {translate('modal.assetsPicker.button.add_asset')}
                        </Button>
                    }
                />
            )}
        </ModalBase>
    );

    return {
        renderDocumentPicker,
        toggleDocumentPicker,
    };
};

export default useDocumentPickerModal;
