import React from 'react';
import { InlineBanner } from '@bynder/design-system';
import { IconLockOpenSmall, IconLockSmall } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { useReviewStatus } from 'packages/pages/design/sidebar/shots/useReviewStatus';

const ShotReviewBanner = () => {
    const { translate } = useTranslate();
    const { isReviewer, isInReviewMode, isReviewerAndInReview, isInApprovedMode } = useReviewStatus();

    return (
        <>
            {isInReviewMode && !isReviewer && (
                <InlineBanner variant="neutral" icon={<IconLockSmall />}>
                    {translate('pages.design.in_review_banner')}
                </InlineBanner>
            )}
            {isReviewerAndInReview && (
                <InlineBanner variant="info" icon={<IconLockOpenSmall />}>
                    {translate('pages.design.reviewer_banner')}
                </InlineBanner>
            )}
            {isInApprovedMode && (
                <InlineBanner variant="neutral" icon={<IconLockSmall />}>
                    {translate('pages.design.approved_banner')}
                </InlineBanner>
            )}
        </>
    );
};

export default ShotReviewBanner;
