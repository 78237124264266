import styled from 'styled-components';
import { Flex, token } from '@bynder/design-system';

export const SizeLabel = styled(Flex)`
    display: flex;
    align-items: center;

    svg {
        margin-right: 9px;
        color: ${token.gray600};
    }
`;
