import 'regenerator-runtime/runtime';
import { TextBackground } from '@bynder-studio/misc';
import { BoundedText } from '../Core/BoundedText';
import type { TextProps } from '../Core/StructuredText';
import { TextController } from '../Core/TextController';
import { convertLeadingValue } from './utils';
import { Direction, Placement } from '../Core/Types';

export type TextConfig = {
    text: TextProps;
    placement: Placement;
    boxWidth: number;
    boxHeight: number;
    xAlign: string;
    yAlign: string;
    fontSize: number;
    tracking: number;
    leading: number;
    minFontScale: number;
    textBackground: TextBackground;
    textDirection: Direction;
};

export type Glyph = any;

// export type StructuredTextLayout = {
//     lines: {
//         glyphs: Glyph[];
//     }[];
// };

// export type BoundedText = {
//     // getData(): StructuredTextLayout;
//     // getConfig(): TextConfig;
//     getData(): any;
//     getConfig(): any;
//     updateConfig(config: Partial<TextConfig>): void;
// };

export class TextRenderer {
    private static instance: TextRenderer;

    private textController: TextController;

    private defaultFontBlob: ArrayBuffer | undefined;

    private loadedFontIds: Set<string> = new Set();

    static async init(hbWasmFile: Buffer) {
        const textRenderer = new TextRenderer();
        textRenderer.textController = await TextController.init(() => hbWasmFile);
        TextRenderer.instance = textRenderer;

        return TextRenderer.instance;
    }

    static getInstance() {
        if (!TextRenderer.instance) {
            throw new Error('Need to initialization asynchronously');
        }

        return TextRenderer.instance;
    }

    setDefaultFont(defaultFontBlob: ArrayBuffer) {
        this.defaultFontBlob = defaultFontBlob;
        this.setFont('default', defaultFontBlob);

        return this;
    }

    getDefaultFont(): ArrayBuffer {
        return this.defaultFontBlob!;
    }

    setFont(fontId: string, fontBlob: ArrayBuffer) {
        if (this.loadedFontIds.has(fontId)) {
            return;
        }

        this.loadedFontIds.add(fontId);
        this.textController.addExternallyLoadedFonts(new Map([[fontId, fontBlob]]));
    }

    createBoundedText(textStruct: TextProps, config: any): BoundedText {
        return this.textController.createBoundedText(textStruct, config);
    }

    convertLeadingValue(fontId: string, fontLeading: number) {
        const _fontId = this.loadedFontIds.has(fontId) ? fontId : 'default';

        return convertLeadingValue(this.textController, _fontId, fontLeading);
    }
}
