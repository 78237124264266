import { CreativeTypes } from '@bynder-studio/render-core';
import { imageModelFactory } from './imageModelFactory';
import { videoModelFactory } from './videoModelFactory';
import { CreativeModelFactoryConfig } from './types';

export const creativeModelFactory = (
    // todo: use only CreativeTypes here
    creativeType: CreativeTypes | string,
    config: CreativeModelFactoryConfig,
) => {
    if (creativeType.toUpperCase() === CreativeTypes.VIDEO) {
        return videoModelFactory(config);
    }

    if (creativeType.toUpperCase() === CreativeTypes.IMAGE) {
        return imageModelFactory(config);
    }

    throw new Error(`Unsupported creative type: ${creativeType}`);
};

export type CreativeModelFactory = Awaited<ReturnType<typeof creativeModelFactory>>;
