export class PlaybackDuration {
    duration: number;
    frameRate: number;

    constructor(duration: number, frameRate: number) {
        this.duration = duration;
        this.frameRate = frameRate;
    }

    getDuration(): number {
        return this.duration;
    }

    setDuration(duration: number): void {
        this.duration = duration;
    }

    getFrameRate(): number {
        return this.frameRate;
    }

    setFrameRate(frameRate: number): void {
        this.frameRate = frameRate;
    }

    toObject() {
        return {
            duration: this.duration,
            frameRate: this.frameRate,
        };
    }

    getCopy() {
        return new PlaybackDuration(this.duration, this.frameRate);
    }
}
