import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, InteractiveIcon } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconSearch } from '@bynder/icons';
import useOnClickOutside from 'packages/hooks/useOnClickOutside';
import useQueryParams from 'packages/hooks/useQueryParams';
import { Box } from './Search.styled';
import { Props } from './types';

const Search = ({ onSearch, isExpandedByDefault = false, isDisabled, ...props }: Props) => {
    const { translate } = useTranslate();
    const searchRef = useRef<HTMLInputElement>(null);
    const { getQueryParam } = useQueryParams();

    const [searchValue, setSearchValue] = useState('');
    const [isExpanded, setIsExpanded] = useState(isExpandedByDefault);

    useEffect(() => {
        if (isExpanded && searchRef.current) {
            searchRef.current.focus();
        }
    }, [isExpanded]);

    useEffect(() => {
        const queriedSearch = getQueryParam('search');

        if (queriedSearch) {
            setSearchValue(queriedSearch);
            setIsExpanded(true);
        }
    }, []);

    const handleSearchButtonClick = (state: boolean) => {
        setIsExpanded(state);
    };

    const handleClickOutside = () => {
        if (searchValue.length === 0) {
            handleSearchButtonClick(false);
        }
    };

    useOnClickOutside(searchRef, handleClickOutside);

    const handleOnChange = (newSearchValue) => {
        setSearchValue(newSearchValue);
        onSearch(newSearchValue);
    };

    const handleKeyEvent = (keyEvent) => {
        if (keyEvent.key === 'Enter') {
            keyEvent.preventDefault();
            onSearch(searchValue);
        }
    };

    return (
        <Box role="search">
            {isExpandedByDefault || isExpanded ? (
                <Input
                    inputRef={searchRef}
                    placeholder={translate('filters.search.placeholder')}
                    aria-label={translate('filters.search.placeholder')}
                    value={searchValue}
                    onChange={handleOnChange}
                    onKeyDown={handleKeyEvent}
                    icon={
                        <InteractiveIcon
                            onClick={() => handleSearchButtonClick(false)}
                            icon={<IconSearch />}
                            label={translate('filters.search.placeholder')}
                        />
                    }
                    hasClearButton
                    isDisabled={isDisabled}
                    {...props}
                />
            ) : (
                <Button
                    variant="clean"
                    icon={<IconSearch />}
                    onClick={() => handleSearchButtonClick(true)}
                    aria-label={translate('filters.search.placeholder')}
                    isDisabled={isDisabled}
                />
            )}
        </Box>
    );
};

export default Search;
