import styled from 'styled-components';
import { List, Dropdown, Button, token, Thumbnail } from '@bynder/design-system';

export const DropdownNewButton = styled(Button)`
    margin-right: calc(${token.spacing4} * -1);
`;

export const FullWidthButton = styled(List.Item)`
    width: 100%;
`;

export const FullWidthDropdown = styled(Dropdown)`
    width: 100%;
`;

export const TextStyleName = styled.div`
    max-width: 116px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const LettersThumbnail: typeof Thumbnail = styled(Thumbnail)`
    background-color: ${token.gray10};
    border: 1px solid ${token.gray100};
    position: relative;
`;

export const OverrideIcon = styled.span`
    position: absolute;
    bottom: -25%;
    right: -25%;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${token.gray400};
`;

export const ApplyFont = styled.span<{ name: string; fontUrl: string }>`
    @font-face {
        font-family: 'Custom Font ${(props) => props.name}';
        src: url(${(props) => props.fontUrl}) format('truetype');
    }
    font-family: 'Custom Font ${(props) => props.name}';
    font-size: ${token.fontSize200};
    color: ${token.gray800};
`;
