import React from 'react';
import { SecondaryText } from './List.styled';
import useExport from '../hooks/useExport';
import { useTranslate } from '@bynder/localization';

export const SelectedSizes = ({ item }) => {
    const { selectedIds } = useExport();
    const { translate } = useTranslate();

    const count = item.entries.length;
    const selectedCount = item.entries.reduce((acc, entry) => acc + (selectedIds[entry.variationId] ? 1 : 0), 0);

    return <SecondaryText>{translate('modal.export.pages.count', { selectedCount, count })}</SecondaryText>;
};
