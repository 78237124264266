import { Err, Ok, Result } from './types';

export function ok<T>(value: T): Ok<T> {
    return {
        tag: 'ok',
        value,
    };
}

export function err<E>(error: E): Err<E> {
    return {
        tag: 'error',
        error,
    };
}

export function isOk<T, E = unknown>(result: Result<T, E>): result is Ok<T> {
    return result.tag === 'ok';
}

export function isErr<E, T = unknown>(result: Result<T, E>): result is Err<E> {
    return result.tag === 'error';
}
