import { Region } from '../Shared/Region';
import { EffectTypes } from '../../Enums/EffectTypes';
import { ICompElement } from '../CompModels/ICompElement';

export abstract class BaseEffect {
    static type: EffectTypes;

    /**
     * Calculate the maximum region of definition of the effect.
     * @param region input region
     */
    static getRegionOfDefinition: (region: Region, compModel: ICompElement) => Region;

    /**
     * Calculate the actual region of interest of the effect.
     * @param region input region
     */
    static getRegionOfInterest: (region: Region, compModel: ICompElement) => Region;
}
