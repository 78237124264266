import React, { FC, useCallback } from 'react';
import { IBaseMultiPageModel, TextElement } from '@bynder-studio/render-core';
import { Button, List, Tooltip } from '@bynder/design-system';
import { IconWritingDirectionLTR, IconWritingDirectionRTL } from '@bynder/icons';
import generateTestId from '~/helpers/testIdHelpers';
import { useTranslate } from '@bynder/localization';
import { Direction } from '@bynder-studio/structured-text';
import features from '~/configs/features';
import useAccessRights from 'packages/hooks/useAccessRights';

export type TextDirectionProps = {
    creativeModel: IBaseMultiPageModel;
    selectedElement: TextElement;
};

export const TextDirection: FC<TextDirectionProps> = ({ selectedElement, creativeModel }) => {
    const { translate } = useTranslate();
    const { isPlatformAllowed } = useAccessRights();
    const elementId = selectedElement.id;
    const { rtl, detectedDirection } = selectedElement.detectedTextDirections;
    const direction = selectedElement.textDirection || detectedDirection;
    const isLocked = Boolean(selectedElement?.locked);

    const handleDirectionChange = useCallback(() => {
        creativeModel.updateElement(elementId, {
            textDirection: direction === Direction.RTL ? Direction.LTR : Direction.RTL,
        });
    }, [creativeModel, elementId, direction]);

    // fixme: remove the feature flag check after the full implementation
    if (!isPlatformAllowed([features.BIDIRECTIONAL_TEXT])) {
        return null;
    }

    if (!selectedElement.textDirection && detectedDirection === Direction.LTR && !rtl) {
        return null;
    }

    return (
        <List.Item
            rightElements={
                <Tooltip
                    position="top-right"
                    content={translate('editor.sidebar.shots.text.direction.switch')}
                    isOpen={isLocked ? false : undefined}
                >
                    <Button
                        onClick={handleDirectionChange}
                        variant="clean"
                        isDisabled={isLocked}
                        icon={direction === Direction.RTL ? <IconWritingDirectionRTL /> : <IconWritingDirectionLTR />}
                        aria-label={translate('editor.sidebar.shots.text.direction.label')}
                    />
                </Tooltip>
            }
            {...generateTestId('shots-text-direction')}
        >
            {translate('editor.sidebar.shots.text.direction.label')}
        </List.Item>
    );
};
