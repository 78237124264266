import React from 'react';
import { useSelector } from 'react-redux';
import { Template } from 'packages/store/templates/types';
import { selectedGallerySelector } from '~/store/gallery/gallery.selectors';
import PreviewNew from './Preview';

const PreviewGallery = () => {
    const gallery: Template = useSelector(selectedGallerySelector);

    return <PreviewNew entity={gallery} />;
};

export default PreviewGallery;
