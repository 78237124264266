import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Input, Form, Flex } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import generateTestId from '~/helpers/testIdHelpers';
import useFonts from '../../Fonts/useFonts';
import FontThumbnail from '../shared/FontThumbnail';
import modalContainer from 'packages/common/modalContainer';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    createTextStyle: (styleName: string) => void;
};

const CreateStyleModal = ({ isOpen, onClose, createTextStyle }: Props) => {
    const { currentFont } = useFonts();
    const [value, setNewValue] = useState('Untitled Style');
    const { translate } = useTranslate();
    const inputRef = useRef<HTMLInputElement>(null);

    const MAX_INPUT_SIZE = 120;
    const isLongName = value.trim().length > MAX_INPUT_SIZE;
    const isEmptyName = value.trim().length === 0;

    const handleCreateClick = () => {
        createTextStyle(value.trim());
        onClose();
    };

    useEffect(() => {
        // Dirty hack for close dropdown because it doesn't have such fn
        setTimeout(() => {
            if (isOpen && inputRef.current) {
                inputRef.current.click();
            }
        });

        setNewValue('Untitled Style');
    }, [isOpen]);

    return (
        <Modal
            container={modalContainer}
            isOpen={isOpen}
            onClose={onClose}
            title={translate('editor.sidebar.shots.text.textStyle.modal.create.title')}
            actionPrimary={
                <Button
                    variant="primary"
                    onClick={handleCreateClick}
                    isDisabled={isEmptyName || isLongName}
                    {...generateTestId('shots_text_styles_modal_create_btn')}
                >
                    {translate('editor.sidebar.shots.text.textStyle.modal.create.title')}
                </Button>
            }
            actionSecondary={
                <Button variant="secondary" onClick={onClose}>
                    {translate('editor.sidebar.shots.text.textStyle.cancel')}
                </Button>
            }
        >
            <Form onSubmit={handleCreateClick}>
                <Form.Group>
                    <Form.Label htmlFor="label-prefix">
                        {translate('editor.sidebar.shots.text.textStyle.name')}
                    </Form.Label>
                    <Flex gap="3">
                        <FontThumbnail id={currentFont?.id.toString()} url={currentFont?.url} />
                        <Flex direction="column">
                            <Input
                                inputRef={inputRef}
                                placeholder={translate('editor.sidebar.shots.text.textStyle.placeholder')}
                                value={value}
                                onChange={setNewValue}
                                isInvalid={isLongName}
                                hasClearButton
                                {...generateTestId('shots_text_styles_modal_create_input')}
                            />
                            {isLongName && (
                                <Form.HelperText isInvalid={isLongName}>
                                    {translate('input.error.max.length')}
                                </Form.HelperText>
                            )}
                        </Flex>
                    </Flex>
                </Form.Group>
            </Form>
        </Modal>
    );
};

export default CreateStyleModal;
