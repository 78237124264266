export const isCtrlKey = (e) => {
    return e.metaKey || e.ctrlKey;
};
export const isShiftKey = (e) => {
    return e.shiftKey;
};
export const isOptionAltKey = (e) => e.altKey;

/**
 * @param event
 *
 * 1 – left button,
 * 2 – middle button,
 * 3 – right button.
 * @returns {number}
 */
export const getWhich = (event: MouseEvent) =>
    event.which ? event.which : event.button === 1 ? 1 : event.button === 2 ? 3 : event.button === 4 ? 2 : 1;

export const isArrowKey = (event: KeyboardEvent) =>
    event.key === 'ArrowLeft' || event.key === 'ArrowUp' || event.key === 'ArrowRight' || event.key === 'ArrowDown';
