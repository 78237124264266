import { RefObject, useEffect, useRef } from 'react';

function useOnClickOutside(ref: RefObject<HTMLElement>, handler: (e: PointerEvent) => void) {
    // Keep the handler in a ref so it can be updated without causing the effect to re-run
    const handlerRef = useRef(handler);

    useEffect(() => {
        handlerRef.current = handler;
    }, [handler]);

    useEffect(() => {
        const listener = (event) => {
            if (!ref.current || ref.current?.contains(event.target)) {
                return;
            }

            handlerRef.current(event);
        };

        document.addEventListener('pointerdown', listener);

        return () => document.removeEventListener('pointerdown', listener);
    }, [ref]);
}

export default useOnClickOutside;
