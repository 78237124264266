import { useEffect, useState } from 'react';
import { type CreativeModelFactoryConfig } from '@bynder-studio/render-web';
import { type FontFamily } from '@bynder-studio/structured-text';
import { catchResponseError } from 'packages/helpers/helpers';
import { CreativeEditorService } from '~/services/CreativeEditorService';

export type CreativeTemplate = Pick<CreativeModelFactoryConfig, 'template' | 'textStyles' | 'contentProperties'> & {
    galleryFonts: FontFamily[];
};

export default function useCreativeTemplate(id?: number, lastPublished = true) {
    const [template, setTemplate] = useState<CreativeTemplate | null>(null);

    useEffect(() => {
        if (!id) {
            return;
        }

        const req = CreativeEditorService.getCreativeTemplate(id, lastPublished);

        req.then((res) => {
            if (res.status >= 400) {
                throw new Error('Failed to fetch creative template');
            }

            setTemplate(res.json);
        }).catch(catchResponseError);

        return () => CreativeEditorService.abortRequest(req);
    }, [id]);

    return template;
}
