import React from 'react';
import { Button, List, Thumbnail, token } from '@bynder/design-system';
import { IconUserGroup, IconPermission } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { EntityGrantedPermissions } from 'packages/store/sharing/types';

type Translate = ReturnType<typeof useTranslate>['translate'];

export const renderUserPermission = (
    item: EntityGrantedPermissions,
    permissions: EntityGrantedPermissions[],
    translate: Translate,
) => {
    return (
        <List.Item
            id={`user_role_${permissions.indexOf(item)}`}
            thumbnail={
                <Thumbnail
                    size="m"
                    shape="circle"
                    backgroundColor={token.gray100}
                    icon={
                        (item.securityIdentity.fullName &&
                            item.securityIdentity.fullName[0] &&
                            item.securityIdentity.fullName[0].toUpperCase()) ||
                        ''
                    }
                />
            }
            rightElements={
                <Button variant="clean" isDisabled>
                    {translate('modal.sharing.reviewer')}
                </Button>
            }
            key={item.securityIdentity.email}
            subtext={item.securityIdentity.email}
        >
            {item.securityIdentity.fullName}
        </List.Item>
    );
};

export const renderGroupPermission = (
    item: EntityGrantedPermissions,
    permissions: EntityGrantedPermissions[],
    translate: Translate,
) => {
    return (
        <List.Item
            id={`group_role_${permissions.indexOf(item)}`}
            thumbnail={<Thumbnail size="m" shape="circle" backgroundColor={token.gray100} icon={<IconUserGroup />} />}
            subtext={translate('modal.design.share.group')}
            rightElements={
                <Button variant="clean" isDisabled>
                    {translate('modal.sharing.reviewer')}
                </Button>
            }
            key={item.securityIdentity.groupId}
        >
            {item.securityIdentity.groupName}
        </List.Item>
    );
};

export const renderProfilePermission = (
    item: EntityGrantedPermissions,
    permissions: EntityGrantedPermissions[],
    translate: Translate,
) => {
    return (
        <List.Item
            id={`profile_role_${permissions.indexOf(item)}`}
            thumbnail={<Thumbnail size="m" shape="circle" backgroundColor={token.gray100} icon={<IconPermission />} />}
            subtext={translate('modal.design.share.profile')}
            rightElements={
                <Button variant="clean" isDisabled>
                    {translate('modal.sharing.reviewer')}
                </Button>
            }
            key={item.securityIdentity.profileId}
        >
            {item.securityIdentity.profileName}
        </List.Item>
    );
};
