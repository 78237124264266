import { Region } from '../Shared/Region';
import { EffectTypes } from '../../Enums/EffectTypes';
import { ICompElement } from '../CompModels/ICompElement';
import { BaseEffect } from './BaseEffect';

export class ClipEffect extends BaseEffect {
    static type = EffectTypes.CLIP;

    static getRegionOfDefinition(region: Region, compElement: ICompElement) {
        return region;
    }

    static getRegionOfInterest(region: Region, compElement: ICompElement) {
        //TODO: Implement intersection calculation between clip path and region.
        return region;
    }
}
