import { FontFamily, Font } from '@bynder-studio/structured-text';

type ElementFont = {
    font: {
        fonts: Font[];
        gallery: boolean;
        global: boolean;
        id: number;
        name: string;
    };
};

export type UserState = {
    user: any;
    companies: any;
    permissions: string[];
    roles: string[];
    accessibleFeatures: string[];
    resetRequestFail: any;
    updateFail: any;
    currentCompany: number;
    timeZones: any[];
    isActivating: boolean;
    isActivated: boolean;
    tokenValdiationStatus: 'REQUEST' | 'SUCCESS' | 'FAIILURE';
    groups: string[];
    fonts: FontFamily[];
    elementsFonts: ElementFont[];
    splitFeatures: Record<ActiveSplits, 'on' | 'off' | 'control'>;
};

export enum ActiveSplits {
    studioInitialTest = 'studio_initial_test',
    DSBrandUpdate = 'ds-brand-update',
}
