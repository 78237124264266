import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAccessRights from 'packages/hooks/useAccessRights';
import useQueryParams from 'packages/hooks/useQueryParams';
import { setPage } from 'packages/store/newDesign/newDesign.actions';
import { getNewDesignPage } from 'packages/store/newDesign/newDesign.selectors';
import roles from '~/configs/roles';
import { DesignSubPages } from '../designCreateModal/components/Sidebar/types';
import DesignCreateModal from '../designCreateModal/DesignCreateModal';

const useNewDesignModal = () => {
    const dispatch = useDispatch();
    const { isAccountRoleAssigned } = useAccessRights();
    const [newDesignModal, setNewDesignModal] = useState(false);
    const [previousParams, setPreviousParams] = useState('');
    const { setQueryParam, resetQueryParams, getAllQueryParams, restoreQueryParams } = useQueryParams();

    const modalActivePage = useSelector(getNewDesignPage);

    const setNewDesignModalHandler = (value) => {
        if (isAccountRoleAssigned(roles.video_brand_studio.video.manage)) {
            dispatch(setPage(DesignSubPages.CATEGORIES));
        }
        setNewDesignModal(value);
    };

    const onClose = () => {
        setNewDesignModal(false);
        resetQueryParams([
            'ownership',
            'typeDesign',
            'typeTemplate',
            'sortedBy',
            'sortOrder',
            'view',
            'categoryId',
            'create',
            'page',
            'search',
        ]);
        restoreQueryParams(previousParams);
    };

    const renderNewDesignModal = () =>
        newDesignModal && <DesignCreateModal isOpen={newDesignModal} onClose={onClose} />;

    useLayoutEffect(() => {
        if (newDesignModal) {
            const queriedParams = getAllQueryParams();

            if (queriedParams && !queriedParams.includes('create=true')) {
                setPreviousParams(queriedParams);
                resetQueryParams(['ownership', 'typeDesign', 'sortedBy', 'sortOrder', 'view']);
            }

            setQueryParam('create', 'true');
        }
    }, [newDesignModal]);

    useLayoutEffect(() => {
        if (newDesignModal) {
            setQueryParam('page', modalActivePage.toLowerCase());
        }
    }, [newDesignModal, modalActivePage]);

    return {
        setNewDesignModal: setNewDesignModalHandler,
        renderNewDesignModal,
    };
};

export default useNewDesignModal;
