import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, InputField, Modal } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { getCreativesActionProgress } from 'packages/store/creatives/creatives.selectors';
import { editCreativeName } from '~/store/creatives/creatives.actions';
import generateTestId from '~/helpers/testIdHelpers';
import { Creative } from 'packages/store/creatives/types';
import modalContainer from 'packages/common/modalContainer';

type Props = {
    inEditor?: boolean;
    isTemplate?: boolean;
};

const MAX_INPUT_SIZE = 120;

const actionId = generateTestId('editor_header_rename_save');
const cancelId = generateTestId('editor_header_rename_cancel');
const titleId = generateTestId('editor_header_rename_title');

const useDesignRenameModal = (props?: Props) => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const { renamingInProgress } = useSelector(getCreativesActionProgress);
    const [renameModalDesign, setRenameModalDesign] = useState<Creative | null>(null);
    const [name, setName] = useState('');

    const isSavingDisabled = useMemo(() => {
        if (!renameModalDesign) {
            return true;
        }

        const trimmedName = name.trim().length;
        const hasInvalidName = trimmedName > MAX_INPUT_SIZE || trimmedName <= 0;
        const isNameChanged = name !== renameModalDesign.name;

        return renamingInProgress || hasInvalidName || !isNameChanged;
    }, [name, renameModalDesign?.name]);

    useEffect(() => {
        setName(renameModalDesign?.name || '');
    }, [renameModalDesign?.name]);

    const renderModalDesignRename = () => (
        <Modal
            container={modalContainer}
            title={translate('modal.design.rename.title')}
            isOpen={renameModalDesign !== null}
            onClose={() => setRenameModalDesign(null)}
            actionPrimary={
                <Button
                    variant="primary"
                    onClick={() => {
                        dispatch(editCreativeName(renameModalDesign?.id, name, props?.inEditor));
                        setRenameModalDesign(null);
                    }}
                    isDisabled={isSavingDisabled}
                    isLoading={renamingInProgress}
                    title={translate('modal.design.rename.save')}
                    {...actionId}
                    {...{ id: 'editor_header_rename_save' }}
                >
                    {translate('modal.design.rename.save')}
                </Button>
            }
            actionSecondary={
                <Button
                    variant="secondary"
                    onClick={() => setRenameModalDesign(null)}
                    title={translate('modal.cancel.title')}
                    {...cancelId}
                    {...{ id: 'editor_header_rename_cancel' }}
                >
                    {translate('modal.cancel.title')}
                </Button>
            }
        >
            <InputField
                label={translate('modal.project.rename.label')}
                hasClearButton={name?.length > 0}
                value={name}
                onChange={(newName) => setName(newName)}
                isInvalid={name?.length > MAX_INPUT_SIZE}
                helperText={name?.length > MAX_INPUT_SIZE && translate('modal.project.rename.max.length')}
                placeholder={translate('modal.design.rename.placeholder')}
                {...titleId}
                {...{ id: 'editor_header_rename_title' }}
            />
        </Modal>
    );

    return {
        setRenameModalDesign,
        renderModalDesignRename,
    };
};

export default useDesignRenameModal;
