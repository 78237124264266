import React from 'react';
import { Divider } from '@bynder/design-system';
import editorAutocorrect from 'packages/pages/editor/EditorAutocorrectRules';

import PropertyField, { PropertyFieldProps } from './PropertyField';
import { Position } from '@bynder-studio/render-core';

// todo: update types
type AnimationFieldProps = {
    field: {
        name: PropertyFieldProps['name'];
        autoCorrectionKey: string;
        label: string;
        withDivider?: boolean;
    };
    id: string;
    onChange: PropertyFieldProps['onChange'];
    disabled: PropertyFieldProps['disabled'];
    options: any;
    frameRate: any;
    // validationMessages,
    testId: string;
    // linkProperty,
    minValue: number | undefined;
    elementDuration: any;
    startFrame: any;
    animationStartFrame: any;
    inverseAnimationValue: any;
    value: any;
    position?: Position | undefined;
    tooltip: string;
};

const AnimationField = (props: AnimationFieldProps) => {
    const {
        field,
        id,
        onChange,
        disabled,
        options,
        frameRate,
        testId,
        elementDuration,
        startFrame,
        animationStartFrame,
        inverseAnimationValue,
        minValue,
        position,
        tooltip,
    } = props;

    if (field.name === 'horizontalPosition' && position) {
        const defaultX = Math.round(position.x);
        field.defaultValue = defaultX;
    }

    if (field.name === 'verticalPosition' && position) {
        const defaultY = Math.round(position.y);
        field.defaultValue = defaultY;
    }

    const getShowValue = (val) => {
        if (field.name === 'timing') {
            return (val && val.value) || null;
        }

        if (field.name === 'startFrame') {
            return val <= props.startFrame ? props.startFrame : val;
        }

        if (field.name === 'mask' && !val) {
            return false;
        }

        return val;
    };

    const autocorrect: PropertyFieldProps['autocorrect'] = (newValue) => {
        if (!field.autoCorrectionKey) {
            return newValue;
        }

        return editorAutocorrect(field.autoCorrectionKey, newValue, {
            frameRate,
            elementDuration,
            startFrame,
            animationStartFrame,
            inverseAnimationValue,
        });
    };

    const getSaveValue = (val) => {
        const correctValue = autocorrect(val);

        if (field.name === 'timing') {
            return { ...props.value, value: correctValue };
        }

        return correctValue;
    };

    const value = getShowValue(props.value);
    const { label, name, withDivider } = field;

    const handleChange = (prop: string, newValue: any) => {
        onChange(prop, getSaveValue(newValue));
    };

    return (
        // use this group to place inputs in one row
        <>
            {withDivider && <Divider />}
            <PropertyField
                label={label}
                onChange={handleChange}
                autocorrect={autocorrect}
                name={name}
                value={value}
                options={options}
                frameRate={frameRate}
                disabled={disabled}
                tooltip={tooltip}
                testId={testId}
                id={id}
                minValue={minValue}
                fallbackValue={field.defaultValue}
            />
        </>
    );
};

export default AnimationField;
