import { useEffect, useRef } from 'react';
import { GeneralRefType } from 'packages/pages/design/timeline/TimelineShotContainer/utils';

type Props = {
    containerRef: GeneralRefType<HTMLElement>;
    isOpen: boolean;
    isLoading: boolean;
    loadMore: () => void;
    hasNextPage: boolean;
};

export const useInfinityLoading = ({ containerRef, isOpen, isLoading, loadMore, hasNextPage }: Props) => {
    const isBlocked = useRef(false);

    useEffect(() => {
        if (!isLoading && hasNextPage) {
            isBlocked.current = false;
        }
    }, [isLoading, hasNextPage]);

    useEffect(() => {
        const handleScroll = (e) => {
            if (isBlocked.current) {
                return;
            }

            const { scrollTop, scrollHeight, clientHeight } = e.target;
            const t = scrollTop / (scrollHeight - clientHeight);

            if (t > 0.75 && !isLoading) {
                isBlocked.current = true;
                loadMore();
            }
        };

        const container = containerRef.current;

        if (isOpen) {
            container?.addEventListener('scroll', handleScroll);
        }

        return () => {
            container?.removeEventListener('scroll', handleScroll);
        };
    }, [isOpen, isLoading, loadMore, containerRef]);
};
