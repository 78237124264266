import { useCallback } from 'react';
import { isNil } from 'rambda';
import useQueryParams from 'packages/hooks/useQueryParams';
import { Tabs } from '../enums';
import { TabVal } from './ExportModal.types';

export const TAB_QUERY_PARAM = 'tab';

const useExportModalTab = () => {
    const { setQueryParam, getQueryParam } = useQueryParams();
    const tabFromQuery = getQueryParam(TAB_QUERY_PARAM);
    const isTabFromQueryValid = !isNil(tabFromQuery) && (Object.values(Tabs) as string[]).includes(tabFromQuery);

    const selectedTab = isTabFromQueryValid ? (tabFromQuery as TabVal) : Tabs.Download;
    const setSelectedTab = useCallback((tab: TabVal) => setQueryParam(TAB_QUERY_PARAM, tab), [setQueryParam]);

    return [selectedTab, setSelectedTab] as const;
};

export default useExportModalTab;
