import React from 'react';
import { Dropdown, Button } from '@bynder/design-system';
import {
    StyledSkeletonButton,
    StyledSkeletonText,
    StyledSkeletonThumbnail,
    StyledSkeletonTextWhite,
} from './Designs.styled';

export const DesignCreateSkeletonButton = () => (
    <StyledSkeletonButton variant="primary" isDisabled icon={<StyledSkeletonThumbnail size="xs" />}>
        <StyledSkeletonTextWhite variant="secondary" />
    </StyledSkeletonButton>
);

export const DropdownSkeleton = () => (
    <StyledSkeletonButton variant="clean" rightIcon={<Dropdown.Arrow />} isDisabled>
        <StyledSkeletonText variant="secondary" />
    </StyledSkeletonButton>
);

export const ButtonSkeleton = () => (
    <Button variant="clean" icon={<StyledSkeletonThumbnail size="xs" />} isDisabled title="design skeleton button" />
);
