import { useCallback, useRef } from 'react';
import { widthToDuration } from '../helper';
import { framesToTime } from '../../../../../helpers/helpers';
import { useDragEvents } from './useDragEvents';

export const useResizeLine = () => {
    const resizeLineRef = useRef(null);

    const onResize = useCallback((fromRight, left, width) => {
        if (resizeLineRef.current.style.opacity !== '1') {
            resizeLineRef.current.style.opacity = '1';
        }

        const leftToApply = fromRight ? left + width : left;

        requestAnimationFrame(() => {
            resizeLineRef.current.style.left = leftToApply + 'px';
        });
    }, []);

    const onEnd = useCallback(() => {
        if (!resizeLineRef.current) {
            return;
        }

        resizeLineRef.current.style.opacity = '0';
    }, []);

    return [resizeLineRef, onResize, onEnd];
};

export const useResizeStats = (maxDuration, duration) => {
    const resizeStatsRef = useRef(null);

    const onResize = useCallback(
        (element, fromRight, left, width, leftPercentage, widthPercentage, containerBoundingBox, boundingBox) => {
            if (resizeStatsRef.current.style.opacity !== '1') {
                resizeStatsRef.current.style.opacity = '1';
            }

            const newDuration = widthToDuration(maxDuration, widthPercentage);

            const durationDiff = newDuration - (element ? element.duration : duration);
            const leftToApply = fromRight ? left + width : left;

            requestAnimationFrame(() => {
                resizeStatsRef.current.style.left = leftToApply + containerBoundingBox.left + 3 + 'px';
                resizeStatsRef.current.style.top = boundingBox.bottom + 3 + 'px';

                const durationText = framesToTime(durationDiff);
                resizeStatsRef.current.firstChild.innerText = durationText.startsWith('-')
                    ? durationText
                    : '+' + durationText;
                resizeStatsRef.current.lastChild.innerText = framesToTime(newDuration);
            });
        },
        [duration, maxDuration],
    );

    const onEnd = useCallback(() => {
        if (!resizeStatsRef.current) {
            return;
        }

        resizeStatsRef.current.style.opacity = '0';
    }, []);

    return [resizeStatsRef, onResize, onEnd];
};

const defaultOptions = {
    minLeft: 10,
    minRight: 10,
    blockLeftEdge: true,
    blockRightEdge: true,
};

export const useResizing = (elementRef, containerRef, onResize, onResizeEnd, element, externalOptions = {}) => {
    const options = { ...defaultOptions, ...externalOptions };
    const { minLeft, minRight, blockLeftEdge, blockRightEdge, setMaxIfOnEdge, minWidth, maxWidth } = options;

    const boundingBoxRef = useRef(null);
    const containerBoundingBoxRef = useRef(null);

    const resizeModeRef = useRef(null);

    const onResizeMouseDown = (mode) => {
        resizeModeRef.current = mode;
        boundingBoxRef.current = elementRef.current.getBoundingClientRect();
        containerBoundingBoxRef.current = containerRef.current.getBoundingClientRect();
    };

    const onResizeMouseMove = (delta) => {
        const fromRight = resizeModeRef.current === 'right';
        let elementWidth = boundingBoxRef.current.width;
        let elementLeft = boundingBoxRef.current.left - containerBoundingBoxRef.current.left;

        if (fromRight) {
            const potentialWidth = boundingBoxRef.current.width + delta;
            const width = setMaxIfOnEdge
                ? Math.min(potentialWidth, containerBoundingBoxRef.current.right - boundingBoxRef.current.left)
                : potentialWidth;
            const onEdge = boundingBoxRef.current.left + width > containerBoundingBoxRef.current.right + 1;

            if ((blockRightEdge && onEdge) || width < minLeft || width < minWidth) {
                return;
            }

            const desiredWidth = width > maxWidth ? maxWidth : width;
            elementWidth = desiredWidth;
            elementRef.current.style.width = desiredWidth + 'px';
        } else {
            const potentialLeft = Math.abs(containerBoundingBoxRef.current.left - boundingBoxRef.current.left) + delta;
            const left = setMaxIfOnEdge ? Math.max(0, potentialLeft) : potentialLeft;
            const width = boundingBoxRef.current.right - containerBoundingBoxRef.current.left - left;
            const onEdge = left < 0;

            if ((blockLeftEdge && onEdge) || width < minRight || width < minWidth) {
                return;
            }

            if (width > maxWidth) {
                elementWidth = maxWidth;
                elementRef.current.style.width = maxWidth + 'px';
            } else {
                elementWidth = width;
                elementLeft = left;
                elementRef.current.style.left = left + 'px';
                elementRef.current.style.width = width + 'px';
            }
        }

        const leftPercentage = 100 / (containerBoundingBoxRef.current.width / elementLeft);
        const widthPercentage = 100 / (containerBoundingBoxRef.current.width / elementWidth);

        onResize(
            fromRight,
            elementLeft,
            elementWidth,
            leftPercentage,
            widthPercentage,
            containerBoundingBoxRef.current,
            boundingBoxRef.current,
        );
    };

    const onResizeMouseUp = () => {
        if (!elementRef.current) {
            resizeModeRef.current = null;

            if (onResizeEnd) {
                onResizeEnd();
            }
        }

        const boundingBox = elementRef.current.getBoundingClientRect();
        const left = boundingBox.left - containerBoundingBoxRef.current.left;
        const leftPercentage = 100 / (containerBoundingBoxRef.current.width / left);
        const widthPercentage = 100 / (containerBoundingBoxRef.current.width / boundingBox.width);

        setTimeout(() => {
            resizeModeRef.current = null;

            if (onResizeEnd) {
                onResizeEnd(leftPercentage, widthPercentage);
            }
        });
    };

    const [onMouseDown] = useDragEvents({
        onDragStart: onResizeMouseDown,
        onDrag: onResizeMouseMove,
        onDragEnd: onResizeMouseUp,
    });

    return [onMouseDown, resizeModeRef];
};
