export enum CompositeOperationTypes {
    SOURCE_OVER = 'SOURCE_OVER',
    SOURCE_IN = 'SOURCE_IN',
    SOURCE_OUT = 'SOURCE_OUT',
    SOURCE_ATOP = 'SOURCE_ATOP',
    DESTINATION_OVER = 'DESTINATION_OVER',
    DESTINATION_IN = 'DESTINATION_IN',
    DESTINATION_OUT = 'DESTINATION_OUT',
    DESTINATION_ATOP = 'DESTINATION_ATOP',
    LIGHTER = 'LIGHTER',
    COPY = 'COPY',
    XOR = 'XOR',
    MULTIPLY = 'MULTIPLY',
    SCREEN = 'SCREEN',
    OVERLAY = 'OVERLAY',
    DARKEN = 'DARKEN',
    LIGHTEN = 'LIGHTEN',
    COLOR_DODGE = 'COLOR_DODGE',
    COLOR_BURN = 'COLOR_BURN',
    HARD_LIGHT = 'HARD_LIGHT',
    SOFT_LIGHT = 'SOFT_LIGHT',
    DIFFERENCE = 'DIFFERENCE',
    EXCLUSION = 'EXCLUSION',
    HUE = 'HUE',
    SATURATION = 'SATURATION',
    COLOR = 'COLOR',
    LUMINOSITY = 'LUMINOSITY',
}
