import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useEditor from './useEditor';
import useDesign from 'packages/pages/design/hooks/useDesign';
import { currentEditorType } from 'packages/store/creativeEditor/creativeEditor.selectors';

export default function usePageValidation(pageIndex) {
    const editorType = useSelector(currentEditorType);
    const { validationManager: validationManagerForMultiPage } = editorType === 'create' ? useDesign() : useEditor();
    const validationManager = validationManagerForMultiPage.getValidationManager(pageIndex);
    const [isValid, setValid] = useState(() => validationManager?.isValid() || editorType === 'create');

    useEffect(() => {
        if (!validationManager) {
            return;
        }

        setValid(validationManager.isValid());
        const validationChangeListener = () => {
            setValid(validationManager.isValid());
        };

        // eslint-disable-next-line consistent-return
        return validationManager.onValidationChange(validationChangeListener);
    }, [validationManager]);

    return isValid;
}
