import { useCallback, useState } from 'react';
import { compose, flatten, map, prop, values } from 'rambda';

/**
 * Apply property changes for group and its children, that can be both as well groups
 * @param group
 * @param updateFn
 */
export const applyPropsChangeToGroup = (group, updateFn) => {
    //If not the root group, and is a child itself
    if (typeof group.parentId !== 'undefined') {
        updateFn(group);
    }
    Object.values(group.children).forEach((child) => {
        child.type !== 'GROUP' ? updateFn(child) : applyPropsChangeToGroup(child, updateFn);
    });
};

export const useToggleExpand = (defaultExpandedIds) => {
    const [expandedIds, setExpandedIds] = useState(defaultExpandedIds);

    const handler = useCallback((layerId) => {
        setExpandedIds((expandedIds) => {
            if (expandedIds.includes(layerId)) {
                return expandedIds.filter((id) => id !== layerId);
            } else {
                return [...expandedIds, layerId];
            }
        });
    }, []);

    return [expandedIds, handler];
};

export const getGroupChildren = compose(
    flatten,
    map((child) => (child.type === 'GROUP' ? getGroupChildren(child).concat(child) : child)),
    values,
    prop('children'),
);

export const getGroupChildrenIds = compose(
    map((el) => el.id),
    getGroupChildren,
);
