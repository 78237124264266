import ProxyServerConnector from './connectors/ProxyServerConnector';
import { createQuery } from '~/helpers/queryString';

class CreativesService extends ProxyServerConnector {
    gateway: ProxyServerConnector;

    constructor(path = '/creative') {
        super(path);
        this.gateway = new ProxyServerConnector('/creatives', GATEWAY_PREFIX);
    }

    fetchCreative(id) {
        return this.gateway.get(`/${id}`).then(({ json, status }) => {
            return { status, creative: json };
        });
    }

    fetchCreatives(id, opt) {
        const path =
            '?' +
            ('customerId=' + id + '&') +
            createQuery(opt) +
            (opt.creativeType ? '&creativeType=' + opt.creativeType : '') +
            (opt.collectionId ? '&collectionId=' + opt.collectionId : '') +
            (opt.aspectRatio ? '&aspectRatio=' + opt.aspectRatio : '') +
            (opt.ownershipSource ? '&ownershipSource=' + opt.ownershipSource : '') +
            (opt.approvalStatus ? '&approvalStatus=' + opt.approvalStatus : '') +
            (opt.hasPublishedVersion ? '&hasPublishedVersion=' + opt.hasPublishedVersion : '') +
            (opt.hasOwnProperty('isCorrupted') ? '&isCorrupted=' + opt.isCorrupted : '');

        return this.gateway.get(path).then(({ json, status }) => {
            return { json, creatives: json.items, status };
        });
    }

    generateVideos({ rows, id }) {
        return this.post('/generate', { rows, id });
    }

    deleteCreatives(ids) {
        return this.gateway.delete('', { ids });
    }

    renameCreativeOrTemplate(id: number, data: { name: string; description?: string }) {
        return this.gateway.put(`/${id}`, data);
    }

    createExportJob(creativeId, data) {
        return this.post(`/${creativeId}/exports`, data);
    }

    getExportJobs(creativeId, data) {
        const searchParams = new URLSearchParams();
        Object.entries(data).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach((val) => {
                    searchParams.append(key, val);
                });
            } else {
                searchParams.append(key, value);
            }
        });

        return this.get(`/${creativeId}/exports?${searchParams.toString()}`);
    }

    deleteExportJobs(creativeId, data) {
        return this.delete(`/${creativeId}/deleteExports`, data);
    }

    deleteFailedExportJob(creativeId) {
        return this.delete(`/exports/${creativeId}`);
    }

    publishCreativeTemplate(creativeId, data) {
        return this.gateway.put(`/${creativeId}/editor/publish`, data);
    }

    saveCreativeTemplate(creativeId, data) {
        return this.gateway.put(`/v2/${creativeId}/editor`, data, {}, false);
    }
}

export default new CreativesService();
