import React, { Component } from 'react';

import EditorComponent from '~/common/editor/_Editor';
import Controls from './Controls';

export default class Editor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDiscardModal: false,
            showPublishModal: false,
            showSaveAsTemplateModal: false,
            showSaveAsVideoModal: false,
            showSaveAsVideoNotAcceptableErrorModal: false,
            creativeVersionName: '',
        };
    }

    save = (creativeModel, exportIntegrations, approvalEnabled, reviewers, silent) => {
        this.props.handleOnSave(creativeModel, exportIntegrations, approvalEnabled, reviewers, silent);
    };

    discard = () => {
        this.setState({
            showDiscardModal: false,
        });
        this.props.handleOnDiscard();
    };

    publish = (creativeModel, exportIntegrations, approvalEnabled, reviewers) => {
        this.props.handleOnPublish(creativeModel, exportIntegrations, approvalEnabled, reviewers);
        this.setState({ creativeVersionName: '' });
    };

    saveAsTemplate = (tmplData) => {
        this.props.saveAsTemplate(tmplData);
        this.setState({
            showSaveAsTemplateModal: false,
        });
    };

    saveAsVideoModal = () => (name) => {
        this.props.saveAsVideo(name, (errorPayload) => {
            if (errorPayload?.appErrorCode === 11001) {
                this.setState({ showSaveAsVideoNotAcceptableErrorModal: true });
            }
        });
        this.setState({
            showSaveAsVideoModal: false,
        });
    };

    handleDiscardModal = (show) => {
        this.setState({ showDiscardModal: show });
    };

    handlePublishModal = (show) => {
        this.setState({ showPublishModal: show });
    };

    closePublishModal = () => {
        this.setState({ showPublishModal: false });
    };

    handleSaveAsTemplateModal = (show) => {
        this.setState({ showSaveAsTemplateModal: show });
    };

    handleSaveAsVideoModal = (show) => {
        this.setState({ showSaveAsVideoModal: show });
    };

    closeSaveAsVideoNotAcceptableErrorModal = () => {
        this.setState({ showSaveAsVideoNotAcceptableErrorModal: false });
    };

    handleNameChange = (value) => {
        this.setState({ creativeVersionName: value });
    };

    render() {
        const { template, isInAction, creativeName, videosCount, saved, hasCorruptionReason, setSaveStatus } =
            this.props;

        if (!template) return <div />;

        const {
            discard,
            handlePublishModal,
            handleDiscardModal,
            save,
            publish,
            handleNameChange,
            handleSaveAsTemplateModal,
            handleSaveAsVideoModal,
            closeSaveAsVideoNotAcceptableErrorModal,
            saveAsTemplate,
            saveAsVideoModal,
            closePublishModal,
        } = this;

        const controlsProps = {
            template,
            saved,
            discard,
            handlePublishModal,
            handleDiscardModal,
            save,
            publish,
            handleNameChange,
            isInAction,
            videosCount,
            handleSaveAsVideoModal,
            closeSaveAsVideoNotAcceptableErrorModal,
            handleSaveAsTemplateModal,
            saveAsTemplate,
            saveAsVideoModal,
            creativeName,
            hasCorruptionReason,
            setSaveStatus,
            closePublishModal,
            ...this.state,
        };

        return (
            <EditorComponent key="spec_key" handleOnResize={() => null} {...this.props} controlsProps={controlsProps}>
                <Controls {...controlsProps} />
            </EditorComponent>
        );
    }
}
