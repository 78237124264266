import { catchResponseError } from 'packages/helpers/helpers';
import { Auth, Ok, ResponseJson, Result, Variables } from './types';
import { err, isErr, ok } from './utils';
import Cache from './Cache';

const MAX_RETRIES = 3;
const CACHE_SIZE = 50;

class GraphQlService {
    #cache = new Cache(CACHE_SIZE);

    async #makeRequest({
        domain,
        query,
        variables,
        auth,
    }: {
        domain: string;
        query: string;
        variables: Variables;
        auth: Auth;
    }) {
        try {
            const response = await fetch(`${domain}/graphql`, {
                method: 'post',
                headers: getHeaders(auth.accessToken),
                body: JSON.stringify({ query, variables }),
            });

            if (response.status >= 400) {
                throw new Error(`Failed request to graphql, status: ${response.status}`);
            }

            return ok((await response.json()) as ResponseJson);
        } catch (e) {
            catchResponseError(e);

            return err((e as Error).message);
        }
    }

    #parseResponse<T>(json: ResponseJson, parse: (data: unknown) => T): Result<T, string> {
        if ('errors' in json) {
            return err(json.errors.map((e: { message: string }) => e.message).join('\n'));
        }

        try {
            return ok(parse(json.data));
        } catch (e) {
            console.error('Error parsing graphql response', e);

            return err((e as Error).message);
        }
    }

    async fetch<T>({
        query,
        variables,
        auth,
        parse = (data) => data as T,
        retryCount = 0,
    }: {
        query: string;
        variables: Variables;
        auth: Auth;
        parse?: (data: unknown) => T;
        retryCount?: number;
    }): Promise<Result<T, string>> {
        const domain = parseUrl(auth.portalDomain);
        const cacheKey = `${domain}:${query}:${JSON.stringify(variables)}:${auth.accessToken}`;

        if (this.#cache.has(cacheKey)) {
            return this.#cache.get(cacheKey) as Ok<T>;
        }

        const res = await this.#makeRequest({ domain, query, variables, auth });

        if (isErr(res)) {
            return res;
        }

        const parsedRes = this.#parseResponse(res.value, parse);

        if (isErr(parsedRes)) {
            if (retryCount >= MAX_RETRIES) {
                // return e;
                return parsedRes;
            }

            await new Promise((resolve) => setTimeout(resolve, 100 + 10 ** (1 + retryCount)));

            return this.fetch({
                query,
                variables,
                auth,
                parse,
                retryCount: retryCount + 1,
            });
        }

        this.#cache.set(cacheKey, parsedRes);

        return parsedRes;
    }
}

export default new GraphQlService();

function parseUrl(url?: string) {
    if (!url) {
        return `https://dam.bynder.com`;
    }

    const withoutProtocol = url.replace(/^https?:\/\//i, '');
    const withoutTrailingSlashes = withoutProtocol.replace(/\/+$/i, '');

    return `https://${withoutTrailingSlashes}`;
}

function getHeaders(accessToken?: string) {
    const baseHeaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    return accessToken === undefined ? baseHeaders : { ...baseHeaders, Authorization: `Bearer ${accessToken}` };
}
