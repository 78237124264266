import React from 'react';
import { Box, SkeletonImage, SkeletonText } from '@bynder/design-system';
import { StyledFlex } from './SkeletonGridView.styled';

const SkeletonGridView = () => (
    <StyledFlex gap="6" wrap="wrap">
        <Box>
            <Box marginBottom="3">
                <SkeletonImage width="230px" height="132px" />
            </Box>
            <SkeletonText variant="primary" />
            <SkeletonText variant="secondary" />
        </Box>
        <Box>
            <Box marginBottom="3">
                <SkeletonImage width="230px" height="132px" />
            </Box>
            <SkeletonText variant="primary" />
            <SkeletonText variant="secondary" />
        </Box>
        <Box>
            <Box marginBottom="3">
                <SkeletonImage width="230px" height="132px" />
            </Box>
            <SkeletonText variant="primary" />
            <SkeletonText variant="secondary" />
        </Box>
        <Box>
            <Box marginBottom="3">
                <SkeletonImage width="230px" height="132px" />
            </Box>
            <SkeletonText variant="primary" />
            <SkeletonText variant="secondary" />
        </Box>
    </StyledFlex>
);

export default SkeletonGridView;
