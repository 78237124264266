import React, { useEffect } from 'react';
import { Button, Dropdown } from '@bynder/design-system';
import { IconForkTopRight, IconResolutions } from '@bynder/icons';
import useQueryParams from 'packages/hooks/useQueryParams';
import useExport from '../hooks/useExport';
import { Translate } from '@bynder/localization';

type OptionProps = {
    variation: {
        name: React.JSX.Element;
        icon: React.JSX.Element;
    };
    page: {
        name: React.JSX.Element;
        icon: React.JSX.Element;
    };
};

const options = {
    variation: {
        name: <Translate id="modal.export.filters.group-by.variations" />,
        icon: <IconForkTopRight />,
    },
    page: {
        name: <Translate id="modal.export.filters.group-by.pages" />,
        icon: <IconResolutions size="22" />,
    },
} as OptionProps;

export const GroupBy = () => {
    const { setQueryParam, getQueryParam } = useQueryParams();
    const { groupBy, setGroupBy, setOrderBy } = useExport();
    const selected = options[groupBy];

    const onGroupByChange = (key: string) => {
        setGroupBy(key);
        setQueryParam('groupBy', key);

        if (key === 'page') {
            setOrderBy('displayOrder');
        } else {
            setOrderBy('updated');
        }
    };

    useEffect(() => {
        if (!getQueryParam('groupBy')?.length) {
            setQueryParam('groupBy', groupBy);
        }
    }, [getQueryParam, groupBy, setQueryParam]);

    return (
        <Dropdown
            position="bottom-right"
            trigger={({ isOpen, ...triggerProps }) => (
                <Button
                    variant="clean"
                    isPressed={isOpen}
                    icon={selected.icon}
                    rightIcon={<Dropdown.Arrow />}
                    {...triggerProps}
                >
                    {selected.name}
                </Button>
            )}
        >
            {Object.entries(options).map(([key, option]) => (
                <Dropdown.Item
                    key={key}
                    icon={option.icon}
                    isChecked={key === groupBy}
                    onClick={() => onGroupByChange(key)}
                >
                    {option.name}
                </Dropdown.Item>
            ))}
        </Dropdown>
    );
};
