import type { IAssetsLoader } from '../../AssetLoader/IAssetsLoader';
import type { ICompositor } from '../../Compositor/ICompositor';
import { ElementTypes } from '../../Enums/ElementTypes';
import { DynamicEventEmitter } from '../../Helpers/DynamicEventEmitter';
import { getElementType } from '../../Helpers/elementUtils';
import { frameIndexToHTMLSeekTime } from '../../Helpers/framesToTime';
import type { IAsset } from '../../Models/Assets/IAsset';
import type { VideoAsset } from '../../Models/Assets/VideoAsset';
import type { IBaseModel } from '../../Models/BaseModel/IBaseModel';
import type { IBaseMultiPageModel } from '../../Models/BaseMultiPageModel/IBaseMultiPageModel';
import type { VideoElement } from '../../Models/Elements/VideoElement';
import { Dimension } from '../../Models/Shared/Dimension';
import type { ElementUpdatedEventData, EventData } from '../../event-types';
import type {
    IPagePreviewRenderer,
    OnPagePreviewRenderEndData,
    OnPagePreviewRenderStartData,
} from './IPagePreviewRenderer';
import { VideoModel } from '../../Models/VideoModel/VideoModel';

export class PagePreviewRenderer implements IPagePreviewRenderer {
    private eventEmitter: DynamicEventEmitter = new DynamicEventEmitter();

    private multiPageModel: IBaseMultiPageModel;

    private assetLoader: IAssetsLoader;

    private compositor: ICompositor;

    private previewDimension: Dimension;

    private renderingQueue: (number | any)[] = [];

    private seekFrame = -1;

    private isSeeking = false;

    private seekWaitingCallbacks: Function[] = [];

    private assetLoadListener = ({ asset }: { asset: IAsset }) => {
        const elements = this.multiPageModel.getElementsByAssetUsage(asset);

        // can't find element for AUDIO
        if (!elements.length) {
            return;
        }

        this.multiPageModel.getModels().forEach((model, pageIndex) => {
            const { frameIndex } = this.getFrameInfo(model);
            elements.forEach((element) => {
                if (frameIndex >= element.startFrame && frameIndex <= element.startFrame + element.duration - 1) {
                    return this.render(pageIndex);
                }
            });
        });
    };

    private elementUpdatedListener = ({ element, pageIndex = -1 }: ElementUpdatedEventData & EventData) => {
        if (pageIndex === -1) {
            return;
        }

        const { frameIndex } = this.getFrameInfo(this.multiPageModel.getModels()[pageIndex]);

        if (frameIndex >= element.startFrame && frameIndex <= element.startFrame + element.duration - 1) {
            return this.render(pageIndex);
        }
    };

    private backgroundColorUpdatedListener = ({ pageIndex = -1 }) => {
        return this.render(pageIndex);
    };

    constructor(multiPageModel: IBaseMultiPageModel, assetLoader: IAssetsLoader, compositor: ICompositor) {
        this.previewDimension = new Dimension(256, 150);
        this.multiPageModel = multiPageModel;
        this.assetLoader = assetLoader;
        this.compositor = compositor;

        this.assetLoader.eventEmitter.on('asset.load', this.assetLoadListener);
        this.multiPageModel.eventEmitter.on('elementUpdated', this.elementUpdatedListener);
        this.multiPageModel.eventEmitter.on('backgroundColorUpdated', this.backgroundColorUpdatedListener);

        this.multiPageModel.getModels().forEach((model, pageIndex) => {
            model.eventEmitter.on('elementUpdated', (params) =>
                this.elementUpdatedListener({
                    ...params,
                    pageIndex,
                }),
            );
        });
    }

    setPreviewSize(width: number, height: number): void {
        this.previewDimension = new Dimension(width, height);
    }

    public onRenderStart(listener: (data: OnPagePreviewRenderStartData) => void) {
        this.eventEmitter.on('renderStart', listener);

        return () => {
            this.eventEmitter.off('renderStart', listener);
        };
    }

    public onRenderEnd(listener: (data: OnPagePreviewRenderEndData) => void) {
        this.eventEmitter.on('renderEnd', listener);

        return () => {
            this.eventEmitter.off('renderEnd', listener);
        };
    }

    public async render(pageIndex = -1) {
        const models = this.multiPageModel.getModels();

        for (const [index, model] of models.entries()) {
            if (pageIndex !== -1 && index !== pageIndex) {
                continue;
            }

            await this.renderPagePreview(model, index);
        }
    }

    private async renderPagePreview(model: IBaseModel, index: number) {
        const { frameRate, frameIndex } = this.getFrameInfo(model);
        const modelsMetaData = this.multiPageModel.getModelsMetaData();

        const videoElements = model
            .getAllElementsRecursively()
            .map((el) => {
                if (
                    frameIndex >= el.startFrame &&
                    frameIndex <= el.startFrame + el.duration - 1 &&
                    getElementType(el) === ElementTypes.VIDEO
                ) {
                    return el as VideoElement;
                }

                return null;
            })
            .filter((el) => el !== null);

        if (this.seekFrame === frameIndex && this.isSeeking) {
            await new Promise((resolve) => this.seekWaitingCallbacks.push(resolve));
        } else if (videoElements.length) {
            this.seekFrame = frameIndex;
            this.isSeeking = true;
            await Promise.all(videoElements.map((el) => this.seekVideoElement(el, frameIndex, frameRate)));
            this.isSeeking = false;
            this.seekWaitingCallbacks.reverse().forEach((cb) => cb());
            this.seekWaitingCallbacks = [];
        }

        if (this.renderingQueue[index]) {
            clearTimeout(this.renderingQueue[index]);
        }

        if (!this.renderingQueue[index]) {
            const metaData = modelsMetaData[index];
            this.eventEmitter.emit('renderStart', {
                index,
                metaData,
            });
        }

        this.renderingQueue[index] = setTimeout(() => {
            this.renderingQueue[index] = 0;
            const metaData = modelsMetaData[index];
            const compModel = model.getCompModel(frameIndex);
            compModel.setElements(model.getVisibleElements(frameIndex));
            this.calculateAndSetScale(model);
            this.compositor.drawCompModel(compModel);
            const dataUrl = this.compositor.getDataUrl();

            this.eventEmitter.emit('renderEnd', {
                index,
                metaData,
                dimension: this.compositor.getCompositorDimension(),
                dataUrl,
            });
        });
    }

    private getFrameInfo(pageModel: IBaseModel) {
        let frameRate = 25;
        let frameIndex = 0;

        if (pageModel instanceof VideoModel) {
            frameRate = pageModel.getPlaybackDuration().getFrameRate();
            frameIndex = pageModel.getPosterFrame().frame;
        }

        return { frameRate, frameIndex };
    }

    private async seekVideoElement(el: VideoElement, frameIndex: number, frameRate: number, attempt = 0) {
        const loadedVideoAsset = this.assetLoader.getAsset(el.generateAssetId()) as VideoAsset;

        if (!loadedVideoAsset || !loadedVideoAsset.object) {
            return;
        }

        const seekTime = frameIndexToHTMLSeekTime(frameIndex, frameRate);
        const key = parseFloat(seekTime.toFixed(2));

        if (!loadedVideoAsset.frameBuffer.has(key)) {
            const isBufferCreated = await loadedVideoAsset.createFrameBuffer(frameIndex);

            if (!isBufferCreated && attempt < 256) {
                await this.seekVideoElement(el, frameIndex, frameRate, attempt + 1);
            }
        }
    }

    private calculateAndSetScale(mode: IBaseModel) {
        // current shot wrapper page
        const kBox = this.previewDimension.getWidth() / this.previewDimension.getHeight();
        // template page
        const templateDimension = mode.getDimensions();
        const kTemplate = templateDimension.getWidth() / templateDimension.getHeight();
        let scale = 1;
        let newDimension;

        if (kTemplate < kBox) {
            const newWidth = kTemplate * this.previewDimension.getHeight();
            newDimension = new Dimension(newWidth, this.previewDimension.getHeight());
            scale = newWidth / templateDimension.getWidth();
        } else {
            const newHeight = this.previewDimension.getWidth() / kTemplate;
            newDimension = new Dimension(this.previewDimension.getWidth(), newHeight);
            scale = this.previewDimension.getWidth() / templateDimension.getWidth();
        }

        this.compositor.setCompositorDimension(newDimension);
        this.compositor.setScale(scale);
    }
}
