import styled from 'styled-components';
import { Button, token } from '@bynder/design-system';
import { LoadingLayer } from 'packages/pages/components/grid/Grid.styled';

export const Header = styled.div`
    // margin-bottom: ${token.spacing6};
    padding-bottom: ${token.spacing6};
    background-color: white;
`;

export const OrderingButton = styled(Button)<{ isReversed: boolean }>`
    & svg {
        transform: ${(props) => (props.isReversed ? 'scale(1, -1)' : 'none')};
    }
`;

export const AssetsWrapper = styled.div<{ isScrollable: boolean }>`
    width: 100%;
    height: calc(100% - 64px); //toolbar height
    overflow-y: ${(props) => (props.isScrollable ? 'auto' : '')};
    position: relative;

    ${LoadingLayer} {
        position: sticky;
        bottom: -${token.spacing6};
    }
`;

export const DividerWrapper = styled.div`
    height: 40px;
`;
