import { ElementAlign, ElementDistribute } from './types';

export const degreesToRad = (degrees) => (degrees * Math.PI) / 180;

export const getMaxX = (boundingbox) => Math.max(...boundingbox.map((values) => values.x));
export const getMaxY = (boundingbox) => Math.max(...boundingbox.map((values) => values.y));
export const getMinX = (boundingbox) => Math.min(...boundingbox.map((values) => values.x));
export const getMinY = (boundingbox) => Math.min(...boundingbox.map((values) => values.y));

export const getElementBoundingBox = (element) => {
    const { position, dimension, rotation } = element;
    const { x, y } = position;
    const { width, height } = dimension;

    const centerX = x + width / 2;
    const centerY = y + height / 2;

    const halfWidth = width / 2;
    const halfHeight = height / 2;

    const angle = degreesToRad(rotation);

    // Element's bounding box
    //
    //      0 ---|--- 1
    //      |         |
    //      -    *    -
    //      |         |
    //      3 ---|--- 2
    //

    let boundingBox = [
        { x, y },
        { x: x + width, y },
        { x: x + width, y: y + height },
        { x, y: y + height },
    ];

    if (rotation) {
        const rotatedCoordinates = [
            {
                x: centerX - (halfWidth * Math.cos(angle) - halfHeight * Math.sin(angle)),
                y: centerY - (halfWidth * Math.sin(angle) + halfHeight * Math.cos(angle)),
            },
            {
                x: centerX + (halfWidth * Math.cos(angle) + halfHeight * Math.sin(angle)),
                y: centerY + (halfWidth * Math.sin(angle) - halfHeight * Math.cos(angle)),
            },
            {
                x: centerX + (halfWidth * Math.cos(angle) - halfHeight * Math.sin(angle)),
                y: centerY + (halfWidth * Math.sin(angle) + halfHeight * Math.cos(angle)),
            },
            {
                x: centerX - (halfWidth * Math.cos(angle) + halfHeight * Math.sin(angle)),
                y: centerY - (halfWidth * Math.sin(angle) - halfHeight * Math.cos(angle)),
            },
        ];

        boundingBox = [
            { x: getMinX(rotatedCoordinates), y: getMinY(rotatedCoordinates) },
            { x: getMaxX(rotatedCoordinates), y: getMinY(rotatedCoordinates) },
            { x: getMaxX(rotatedCoordinates), y: getMaxY(rotatedCoordinates) },
            { x: getMinX(rotatedCoordinates), y: getMaxY(rotatedCoordinates) },
        ];
    }

    return boundingBox;
};

export const alignFn = (type: ElementAlign, selectedElements, callback) => {
    if (!selectedElements.length) {
        return;
    }

    const onElementAlign = () => {
        let maxX = Number.NEGATIVE_INFINITY;
        let minX = Number.POSITIVE_INFINITY;
        let maxY = Number.NEGATIVE_INFINITY;
        let minY = Number.POSITIVE_INFINITY;

        const selectedElementsFiltered = selectedElements.filter((element) => !element.parent);
        const selectedGroupElements = selectedElements.filter((element) => element.parent);

        selectedElements.forEach((element) => {
            const boundingBox = getElementBoundingBox(element);

            maxX = Math.max(maxX, ...boundingBox.map((values) => values.x));
            maxY = Math.max(maxY, ...boundingBox.map((values) => values.y));
            minX = Math.min(minX, ...boundingBox.map((values) => values.x));
            minY = Math.min(minY, ...boundingBox.map((values) => values.y));
        });

        // Element's top left and bottom right corners offsets from
        // recalculated bounding box respective corner plus
        // bounding box dimensions

        const dBoundingBox = (element, isSingle) => {
            let offset = { dx: 0, dy: 0 };

            let selectionBoxWidth = maxX - minX;
            let selectionBoxHeight = maxY - minY;

            const box = getElementBoundingBox(element);

            if (isSingle) {
                offset = {
                    dx: element.position.x - minX,
                    dy: element.position.y - minY,
                };
            } else {
                selectionBoxWidth = getMaxX(box) - getMinX(box);
                selectionBoxHeight = getMaxY(box) - getMinY(box);

                offset = {
                    dx: minX + (getMinX(box) - element.position.x),
                    dy: minY + (getMinY(box) - element.position.y),
                };
            }

            return {
                offset,
                selectionBoxWidth,
                selectionBoxHeight,
            };
        };

        const elementAlignment = (element) => {
            const { position, canvasDimension } = element;

            const singleElementAlignment = () => {
                const { offset, selectionBoxWidth, selectionBoxHeight } = dBoundingBox(element, true);
                const box = getElementBoundingBox(element);

                const currentPosition = {
                    x: position.x,
                    y: position.y,
                };

                return {
                    [ElementAlign.HORIZONTAL_LEFT]: () => {
                        if (getMinX(box) === 0) {
                            return currentPosition;
                        }

                        return {
                            x: offset.dx,
                            y: position.y,
                        };
                    },
                    [ElementAlign.HORIZONTAL_RIGHT]: () => {
                        if (getMaxX(box) === canvasDimension.width) {
                            return currentPosition;
                        }

                        return {
                            x: canvasDimension.width + offset.dx - selectionBoxWidth,
                            y: position.y,
                        };
                    },
                    [ElementAlign.HORIZONTAL_CENTER]: () => ({
                        x: (canvasDimension.width - selectionBoxWidth) / 2 + offset.dx,
                        y: position.y,
                    }),
                    [ElementAlign.VERTICAL_TOP]: () => ({
                        x: position.x,
                        y: offset.dy,
                    }),
                    [ElementAlign.VERTICAL_BOTTOM]: () => ({
                        x: position.x,
                        y: canvasDimension.height + offset.dy - selectionBoxHeight,
                    }),
                    [ElementAlign.VERTICAL_CENTER]: () => ({
                        x: position.x,
                        y: (canvasDimension.height - selectionBoxHeight) / 2 + offset.dy,
                    }),
                };
            };

            const multipleElementsAlignment = () => {
                const { selectionBoxWidth, selectionBoxHeight } = dBoundingBox(element, false);
                const elementBox = getElementBoundingBox(element);

                const currentPosition = {
                    x: position.x,
                    y: position.y,
                };

                const elementOriginOffset = {
                    dx: elementBox[0].x - position.x,
                    dy: elementBox[0].y - position.y,
                };

                return {
                    [ElementAlign.HORIZONTAL_LEFT]: () => {
                        if (getMinX(elementBox) === minX) {
                            return currentPosition;
                        }

                        return {
                            x: minX - elementOriginOffset.dx,
                            y: position.y,
                        };
                    },
                    [ElementAlign.HORIZONTAL_RIGHT]: () => {
                        if (getMaxX(elementBox) === maxX) {
                            return currentPosition;
                        }

                        return {
                            x: maxX - selectionBoxWidth - elementOriginOffset.dx,
                            y: position.y,
                        };
                    },
                    [ElementAlign.HORIZONTAL_CENTER]: () => {
                        const isElementCentered =
                            minX + (maxX - minX) / 2 === position.x + selectionBoxWidth / 2 - elementOriginOffset.dx;

                        if (isElementCentered) {
                            return currentPosition;
                        }

                        return {
                            x: minX + (maxX - minX) / 2 - selectionBoxWidth / 2 - elementOriginOffset.dx,
                            y: position.y,
                        };
                    },
                    [ElementAlign.VERTICAL_TOP]: () => {
                        if (getMinY(elementBox) === minY) {
                            return {
                                x: position.x,
                                y: position.y,
                            };
                        }

                        return {
                            x: position.x,
                            y: minY + (position.y - getMinY(elementBox)) + elementOriginOffset.dy,
                        };
                    },
                    [ElementAlign.VERTICAL_BOTTOM]: () => ({
                        x: position.x,
                        y: maxY - (getMaxY(elementBox) - position.y),
                    }),
                    [ElementAlign.VERTICAL_CENTER]: () => {
                        const isElementCentered =
                            minY + (maxY - minY) / 2 === position.y + selectionBoxHeight / 2 - elementOriginOffset.dy;

                        if (isElementCentered) {
                            return currentPosition;
                        }

                        return {
                            x: position.x,
                            y: minY + (maxY - minY) / 2 - selectionBoxHeight / 2 - elementOriginOffset.dy,
                        };
                    },
                };
            };

            return {
                singleElementAlignment,
                multipleElementsAlignment,
            };
        };

        if (selectedElementsFiltered.length === 1) {
            if (!selectedElementsFiltered[0].locked) {
                const { singleElementAlignment } = elementAlignment(selectedElementsFiltered[0]);
                const param = {
                    position: singleElementAlignment()[type](),
                };

                callback.updateElement(selectedElementsFiltered[0].id, param);
            }
        } else if (selectedGroupElements.length === 1) {
            selectedGroupElements.forEach((element) => {
                if (!element.locked) {
                    const { singleElementAlignment } = elementAlignment(selectedGroupElements[0]);
                    const param = {
                        position: singleElementAlignment()[type](),
                    };

                    callback.updateElement(selectedGroupElements[0].id, param);
                }
            });
        } else if (selectedElementsFiltered.length) {
            selectedElementsFiltered.forEach((element) => {
                if (!element.locked) {
                    const { multipleElementsAlignment } = elementAlignment(element);

                    const param = {
                        position: multipleElementsAlignment()[type](),
                    };

                    callback.updateElement(element.id, param);
                }
            });
        } else if (selectedGroupElements.length) {
            selectedGroupElements.forEach((element) => {
                if (!element.locked) {
                    const { multipleElementsAlignment } = elementAlignment(element);

                    const param = {
                        position: multipleElementsAlignment()[type](),
                    };

                    callback.updateElement(element.id, param);
                }
            });
        }
    };

    onElementAlign();
};

export const distributeFn = (type: ElementDistribute, selectedElements, callback) => {
    let totalWidth = 0;
    let totalHeight = 0;

    let maxX = Number.NEGATIVE_INFINITY;
    let minX = Number.POSITIVE_INFINITY;
    let maxY = Number.NEGATIVE_INFINITY;
    let minY = Number.POSITIVE_INFINITY;

    let selectionWidth = 0;
    let selectionHeight = 0;

    let withHorizontalGap = true;
    let withVerticalGap = true;

    selectedElements.forEach((element) => {
        const boundingBox = getElementBoundingBox(element);

        maxX = Math.max(maxX, ...boundingBox.map((values) => values.x));
        maxY = Math.max(maxY, ...boundingBox.map((values) => values.y));
        minX = Math.min(minX, ...boundingBox.map((values) => values.x));
        minY = Math.min(minY, ...boundingBox.map((values) => values.y));

        totalWidth +=
            Math.max(...boundingBox.map((values) => values.x)) - Math.min(...boundingBox.map((values) => values.x));
        totalHeight +=
            Math.max(...boundingBox.map((values) => values.y)) - Math.min(...boundingBox.map((values) => values.y));
    });

    selectionWidth = maxX - minX;
    selectionHeight = maxY - minY;

    const elementsOrdered = () => {
        withHorizontalGap = selectionWidth > totalWidth;
        withVerticalGap = selectionHeight > totalHeight;

        const isWithGap = type === ElementDistribute.DISTRIBUTE_HORIZONTALLY ? withHorizontalGap : withVerticalGap;

        const elementsNormalized = selectedElements.reduce(
            (acc, element) => [
                ...acc,
                {
                    ...element,
                    position: {
                        x: getElementBoundingBox(element)[0].x,
                        y: getElementBoundingBox(element)[0].y,
                    },
                    center: {
                        x:
                            getElementBoundingBox(element)[0].x +
                            (getElementBoundingBox(element)[1].x - getElementBoundingBox(element)[0].x) / 2,
                        y:
                            getElementBoundingBox(element)[0].y +
                            (getElementBoundingBox(element)[3].y - getElementBoundingBox(element)[0].y) / 2,
                    },
                },
            ],
            [],
        );

        const sortedByX = () =>
            elementsNormalized.sort((a, b) => (isWithGap ? a.position.x - b.position.x : a.center.x - b.center.x));
        const sortedByY = () =>
            elementsNormalized.sort((a, b) => (isWithGap ? a.position.y - b.position.y : a.center.y - b.center.y));

        return type === ElementDistribute.DISTRIBUTE_HORIZONTALLY ? sortedByX() : sortedByY();
    };

    const onElementDistribute = () => {
        const selectedNumber = selectedElements.length;

        const gapHorizontal = (selectionWidth - totalWidth) / (selectedNumber - 1);
        const gapVertical = (selectionHeight - totalHeight) / (selectedNumber - 1);

        const centersDistanceX =
            (elementsOrdered()[selectedNumber - 1].center.x - elementsOrdered()[0].center.x) / (selectedNumber - 1);

        const centersDistanceY =
            (elementsOrdered()[selectedNumber - 1].center.y - elementsOrdered()[0].center.y) / (selectedNumber - 1);

        elementsOrdered().forEach((element, index) => {
            if (index === 0 || element.locked || index === elementsOrdered().length - 1) return;
            const boundingBox = getElementBoundingBox(element);

            const getDx = (el) => el.position.x - getElementBoundingBox(el)[0].x;
            const getDy = (el) => el.position.y - getElementBoundingBox(el)[0].y;

            let param = {
                position: {
                    x: element.position.x + getDx(element),
                    y: element.position.y + getDy(element),
                },
            };

            const elementWidth = boundingBox[1].x - boundingBox[0].x;
            const elementHeight = boundingBox[3].y - boundingBox[0].y;
            const previousElement = elementsOrdered()[index - 1];
            const getPreviousEdgeX = getElementBoundingBox(previousElement)[1].x + getDx(previousElement);
            const getPreviousEdgeY = getElementBoundingBox(previousElement)[2].y + getDy(previousElement);
            const getPreviousCenterX =
                getElementBoundingBox(previousElement)[0].x +
                (getElementBoundingBox(previousElement)[1].x - getElementBoundingBox(previousElement)[0].x) / 2;
            const getPreviousCenterY =
                getElementBoundingBox(previousElement)[0].y +
                (getElementBoundingBox(previousElement)[3].y - getElementBoundingBox(previousElement)[0].y) / 2;

            if (type === ElementDistribute.DISTRIBUTE_HORIZONTALLY) {
                if (withHorizontalGap) {
                    param = {
                        position: {
                            x: getPreviousEdgeX + gapHorizontal + getDx(element),
                            y: element.position.y + getDy(element),
                        },
                    };
                } else {
                    param = {
                        position: {
                            x: getPreviousCenterX + centersDistanceX - elementWidth / 2 + getDx(element),
                            y: element.position.y + getDy(element),
                        },
                    };
                }

                callback.updateElement(element.id, param);
            }

            if (type === ElementDistribute.DISTRIBUTE_VERTICALLY) {
                if (withVerticalGap) {
                    param = {
                        position: {
                            x: element.position.x + getDx(element),
                            y: getPreviousEdgeY + gapVertical + getDy(element),
                        },
                    };
                } else {
                    param = {
                        position: {
                            x: element.position.x + getDx(element),
                            y: getPreviousCenterY + centersDistanceY - elementHeight / 2 + getDy(element),
                        },
                    };
                }

                callback.updateElement(element.id, param);
            }
        });
    };

    onElementDistribute();
};
