import React, { useState } from 'react';
import { Button, Modal } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import modalContainer from 'packages/common/modalContainer';

export default function useRevokeAccessModal(revokeAccess: () => void) {
    const [revokeAccessModalOpen, setRevokeAccessModalOpen] = useState(false);
    const { translate } = useTranslate();

    const onRevokeAccess = () => {
        revokeAccess();
        setRevokeAccessModalOpen(false);
    };

    const renderRevokeAccessModal = () => (
        <Modal
            container={modalContainer}
            title={translate('modal.design.create.design.figma.access.revoke.title')}
            isOpen={revokeAccessModalOpen}
            onClose={() => setRevokeAccessModalOpen(false)}
            actionPrimary={
                <Button variant="danger" onClick={onRevokeAccess}>
                    {translate('modal.design.create.design.figma.access.revoke')}
                </Button>
            }
            actionSecondary={
                <Button variant="secondary" onClick={() => setRevokeAccessModalOpen(false)}>
                    {translate('modal.design.create.design.figma.cancel')}
                </Button>
            }
        >
            {translate('modal.design.create.design.figma.access.revoke.text')}
        </Modal>
    );

    return {
        setRevokeAccessModalOpen,
        renderRevokeAccessModal,
    };
}
