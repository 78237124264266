import React, { useEffect, useRef } from 'react';
import { useHorizontalDragging } from '../../tracks/dragging/useHorizontalDragging';
import { PlayheadStyled } from '../../tracks/playhead/Playhead.styled';

const ThumbnailSelector = ({
    shotsContainerRef,
    shotContainerRef,
    onDrop,
    duration,
    thumbnailFrame,
    elementWrapperRef,
}) => {
    const controlRef = useRef(null);
    const frameRef = useRef<number>(null);

    useEffect(() => {
        const observer = new MutationObserver(() => {
            const { x: elementsWrapperX, width: elementsWrapperWidth } =
                elementWrapperRef.current.getBoundingClientRect();

            const { x: controlX } = controlRef.current?.getBoundingClientRect();

            if (controlX < elementsWrapperX || controlX > elementsWrapperWidth + elementsWrapperX) {
                controlRef.current.style.visibility = 'hidden';
            } else {
                controlRef.current.style.visibility = 'initial';
            }
        });

        observer.observe(shotsContainerRef.current, { attributes: true, childList: false, subtree: false });

        return () => observer.disconnect();
    });

    const left = `${(thumbnailFrame / duration) * 100}%`;

    const onDrag = (leftPercentage) => {
        let newFrame = (duration * leftPercentage) / 100;
        newFrame = Math.round(newFrame);
        frameRef.current = newFrame;
    };

    const handleDrag = useHorizontalDragging({
        controlRef,
        containerRef: shotContainerRef,
        onDrag,
        onDrop: () => {
            onDrop(frameRef.current);
        },
    });

    return (
        <PlayheadStyled ref={controlRef} onClick={(e) => e.stopPropagation()} onMouseDown={handleDrag} style={{ left }}>
            <div className="Playhead-dragger">
                <div className="Playhead-dragger-punch" />
            </div>
            <div className="Playhead-line" />
        </PlayheadStyled>
    );
};

export default ThumbnailSelector;
