import React from 'react';
import { Button, List, Thumbnail, token, Tooltip } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconCancel, IconPermission, IconUserGroup } from '@bynder/icons';
import { IdentityType } from 'packages/store/sharing/types';
import { ReviewierIdentity } from './types';

type Translate = ReturnType<typeof useTranslate>['translate'];

const renderUserPermission = (item: ReviewierIdentity, translate: Translate, dropdownProps: any = {}) => {
    const renderRemoval = () => {
        const { removeReviewer } = dropdownProps;

        return (
            <List.ConcealedAction>
                <Tooltip content={translate('editor.sidebar.globals.requestApproval.reviewers.remove')}>
                    <Button
                        variant="clean"
                        icon={<IconCancel />}
                        aria-label="Actions"
                        onClick={() => removeReviewer && removeReviewer(item)}
                    />
                </Tooltip>
            </List.ConcealedAction>
        );
    };

    const getInitial = () => {
        if (item?.fullName && item.fullName[0]) {
            return item.fullName[0].toUpperCase();
        }

        if (item?.firstName) {
            return item?.firstName[0].toUpperCase();
        }

        return '';
    };

    return (
        <List.Item
            thumbnail={<Thumbnail size="m" shape="circle" backgroundColor={token.gray100} icon={getInitial()} />}
            rightElements={renderRemoval()}
            subtext={item?.email}
            key={item.email}
        >
            {item?.fullName ? item?.fullName : `${item?.firstName} ${item?.lastName}`}
        </List.Item>
    );
};

const renderGroupPermission = (item: ReviewierIdentity, translate: Translate, dropdownProps: any = {}) => {
    const renderRemoval = () => {
        const { removeReviewer } = dropdownProps;

        return (
            <List.ConcealedAction>
                <Tooltip content={translate('editor.sidebar.globals.requestApproval.reviewers.remove')}>
                    <Button
                        variant="clean"
                        icon={<IconCancel />}
                        aria-label="Actions"
                        onClick={() => removeReviewer && removeReviewer(item)}
                    />
                </Tooltip>
            </List.ConcealedAction>
        );
    };

    return (
        <List.Item
            thumbnail={<Thumbnail size="m" shape="circle" backgroundColor={token.gray100} icon={<IconUserGroup />} />}
            subtext={translate('modal.design.share.group')}
            rightElements={renderRemoval()}
            key={item.groupId}
        >
            {item.groupName}
        </List.Item>
    );
};

const renderProfilePermission = (item: ReviewierIdentity, translate: Translate, dropdownProps: any = {}) => {
    const renderRemoval = () => {
        const { removeReviewer } = dropdownProps;

        return (
            <List.ConcealedAction>
                <Tooltip content={translate('editor.sidebar.globals.requestApproval.reviewers.remove')}>
                    <Button
                        variant="clean"
                        icon={<IconCancel />}
                        aria-label="Actions"
                        onClick={() => removeReviewer && removeReviewer(item)}
                    />
                </Tooltip>
            </List.ConcealedAction>
        );
    };

    return (
        <List.Item
            thumbnail={<Thumbnail size="m" shape="circle" backgroundColor={token.gray100} icon={<IconPermission />} />}
            subtext={translate('modal.design.share.profile')}
            rightElements={renderRemoval()}
            key={item.profileId}
        >
            {item.profileName}
        </List.Item>
    );
};

export const ReviewersList = ({ reviewers, removeReviewer }) => {
    const { translate } = useTranslate();

    return (
        <List>
            {reviewers.map((item) => {
                switch (item.securityIdentityType) {
                    case IdentityType.USER:
                        return renderUserPermission(item, translate, { removeReviewer });
                    case IdentityType.GROUP:
                        return renderGroupPermission(item, translate, { removeReviewer });
                    case IdentityType.PROFILE:
                        return renderProfilePermission(item, translate, { removeReviewer });
                    default:
                        return null;
                }
            })}
        </List>
    );
};
