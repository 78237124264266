import {
    ContentPropertiesManager,
    IAssetsLoader,
    IImageModel,
    ImageRenderer,
    ImageWebSpecificationParser,
    PagePreviewRenderer,
    TextRenderer,
    TextStyles,
    ThumbnailRenderer,
    VariationThumbnailRenderer,
} from '@bynder-studio/render-core';
// @ts-ignore
import hbWasmURL from '@bynder-studio/hbjs/hb.wasm';
import { setFontFamilies } from '@bynder-studio/structured-text';
import { BrowserAssetsLoader } from '../AssetLoader/BrowserAssetsLoader';
import { CanvasLayerCompositor } from '../Compositor/CanvasLayerCompositor/CanvasLayerCompositor';
import CanvasManipulation from '../Compositor/CanvasManipulationCompositor/CanvasManipulation';
import CanvasManipulationCompositor from '../Compositor/CanvasManipulationCompositor/CanvasManipulationCompositor';
import CanvasShotCompositor from '../Compositor/CanvasShotCompositor/CanvasShotCompositor';
import CanvasPagePreviewCompositor from '../Compositor/CanvasPagePreviewCompositor/CanvasPagePreviewCompositor';
import { ImageManipulationRenderer } from '../Renderers/ImageManipulationRenderer/ImageManipulationRenderer';
import { CreativeModelFactoryConfig } from './types';

export const imageModelFactory = async ({
    template,
    defaultFontBlob,
    fontFamilies,
    textStyles,
    contentProperties,
    containerDocument,
}: CreativeModelFactoryConfig) => {
    // todo: improve
    const assetLoader = new BrowserAssetsLoader();
    const devicePixelRatio = window.devicePixelRatio;

    setFontFamilies(fontFamilies);

    const hbWasmFile: any = await fetch(hbWasmURL).then((res) => res.arrayBuffer());
    (await TextRenderer.init(hbWasmFile)).setDefaultFont(defaultFontBlob);

    const textStylesInstance = new TextStyles();
    const contentPropertiesManager = new ContentPropertiesManager();
    const imageWebSpecificationParser = new ImageWebSpecificationParser();

    textStylesInstance.loadStyles(textStyles);
    contentPropertiesManager.loadContentProperties(
        ImageWebSpecificationParser.parseContentProperties(contentProperties, fontFamilies, template),
    );

    const multiPageImageModel = imageWebSpecificationParser
        .parseMultiPage(template, fontFamilies)
        .setAssetLoader(assetLoader as IAssetsLoader)
        .setTextStyles(textStylesInstance)
        .setContentPropertiesManager(contentPropertiesManager)
        .setup();

    return {
        getCreativeModel: () => multiPageImageModel,
        getAssetLoader: () => assetLoader,
        getCreativeRenderer: (canvasWrapperEl) => {
            const canvasCompositor = new CanvasLayerCompositor(
                canvasWrapperEl,
                assetLoader as IAssetsLoader,
                devicePixelRatio,
            );

            return new ImageRenderer(
                multiPageImageModel as unknown as IImageModel,
                assetLoader as IAssetsLoader,
                canvasCompositor,
            );
        },
        getCreativeManipulationRenderer: (canvasWrapperEl) => {
            const canvasCompositor = new CanvasManipulationCompositor(
                canvasWrapperEl,
                assetLoader as IAssetsLoader,
                devicePixelRatio,
            );
            const canvasManipulation = new CanvasManipulation(canvasCompositor, containerDocument, fontFamilies);

            return new ImageManipulationRenderer(
                multiPageImageModel,
                assetLoader as IAssetsLoader,
                canvasCompositor,
                canvasManipulation,
            );
        },
        getThumbnailRenderer: () => {
            const canvasShotCompositor = new CanvasShotCompositor(assetLoader as IAssetsLoader, devicePixelRatio);

            return new ThumbnailRenderer(
                multiPageImageModel as unknown as IImageModel,
                assetLoader as IAssetsLoader,
                canvasShotCompositor,
            );
        },
        getPagePreviewRenderer: () => {
            const canvasSizePreviewCompositor = new CanvasPagePreviewCompositor(
                assetLoader as IAssetsLoader,
                devicePixelRatio,
            );

            return new PagePreviewRenderer(
                multiPageImageModel,
                assetLoader as IAssetsLoader,
                canvasSizePreviewCompositor,
            );
        },
        getVariationThumbnailRenderer: () => {
            const canvasVariationThumbnailCompositor = new CanvasPagePreviewCompositor(
                assetLoader as IAssetsLoader,
                devicePixelRatio,
            );

            return new VariationThumbnailRenderer(assetLoader as IAssetsLoader, canvasVariationThumbnailCompositor);
        },
    };
};
