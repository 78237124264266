import { MouseEvent, useCallback } from 'react';
import { GeneralRefType } from './utils';

type useRedLineFn = (
    redLineRef: GeneralRefType<HTMLDivElement | null>,
    timelineWrapperRef: GeneralRefType<HTMLDivElement | null>,
    pointerLineInterval?: number,
    timeTooltipRef?: GeneralRefType<HTMLDivElement> | null,
) => { handleMouseMove: (ev: MouseEvent) => void };

export const useRedLine: useRedLineFn = (
    redLineRef,
    timelineWrapperRef,
    pointerLineInterval = 10,
    timeTooltipRef = null,
) => {
    const handleMouseMove = useCallback((ev: MouseEvent): void => {
        if (timelineWrapperRef.current) {
            const { x } = timelineWrapperRef.current.getBoundingClientRect();
            const posX = ev.pageX - x + pointerLineInterval;
            redLineRef.current?.setAttribute('style', `transform: translate3d(${posX}px,0,0); display: block;`);
            if (timeTooltipRef?.current) {
                timeTooltipRef.current?.setAttribute(
                    'style',
                    `transform: translate3d(${posX}px,0,0) translateX(-50%); display: flex;`,
                );
            }
        }
    }, []);

    return { handleMouseMove };
};
