import React from 'react';
import Loader from '~/common/loader/Loader';
import generateTestId from '~/helpers/testIdHelpers';

const loaderTestId = generateTestId('old-page-loader');

export const PageLoader = () => {
    return (
        <div className="w-100 h-100 d-flex align-items-center justify-content-center" {...loaderTestId}>
            <Loader size={50} />
        </div>
    );
};
