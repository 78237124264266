import React, { createContext, useDeferredValue, useEffect, useMemo, useState } from 'react';
import useEditor from '../hooks/useEditor';
import type { TextElement, ShapeElement, ImageElement, VideoElement, GroupElement } from '@bynder-studio/render-core';

type Element = TextElement | ShapeElement | ImageElement | VideoElement | GroupElement;

type ContextData = {
    selectedElement: Element;
    selectedElements: Element[];
    topLevelSelectedElements: Element[];
    selectedElementDeferred: Element;
    selectedElementsDeferred: Element[];
    topLevelSelectedElementsDeferred: Element[];
};

export const EditorSelectedElementContext = createContext({} as ContextData);

export function EditorSelectedElementProvider({ children }) {
    const { manipulationRenderer } = useEditor();
    const [selectedElement, setSelectedElement] = useState(manipulationRenderer?.getSelectedElement());
    const [selectedElements, setSelectedElements] = useState(manipulationRenderer?.getSelectedElements());
    const [topLevelSelectedElements, setTopLevelSelectedElements] = useState(
        manipulationRenderer?.getTopLevelSelectedElements(),
    );

    const selectedElementDeferred = useDeferredValue(selectedElement);
    const selectedElementsDeferred = useDeferredValue(selectedElements);
    const topLevelSelectedElementsDeferred = useDeferredValue(topLevelSelectedElements);

    const value = useMemo(
        () => ({
            selectedElement,
            selectedElements,
            topLevelSelectedElements,
            selectedElementDeferred,
            selectedElementsDeferred,
            topLevelSelectedElementsDeferred,
        }),
        [
            selectedElement,
            selectedElements,
            topLevelSelectedElements,
            selectedElementDeferred,
            selectedElementsDeferred,
            topLevelSelectedElementsDeferred,
        ],
    );

    useEffect(() => {
        if (!manipulationRenderer) {
            return;
        }

        const onElementSelected = () => {
            setSelectedElement(manipulationRenderer.getSelectedElement());
            setSelectedElements(manipulationRenderer.getSelectedElements());
            setTopLevelSelectedElements(manipulationRenderer.getTopLevelSelectedElements());
        };

        manipulationRenderer.eventEmitter.on('elementSelected', onElementSelected);

        return () => {
            manipulationRenderer.eventEmitter.off('elementSelected', onElementSelected);
        };
    }, [manipulationRenderer]);

    return <EditorSelectedElementContext.Provider value={value}>{children}</EditorSelectedElementContext.Provider>;
}
