import React, { useRef } from 'react';
import { Button, Modal } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import generateTestId from '~/helpers/testIdHelpers';
import { DeleteModalProps } from '../../types';
import { HiddenInput } from '../../ContentProperty.styled';
import modalContainer from 'packages/common/modalContainer';

const DeleteModal = ({ isOpen, onClose, onDelete }: DeleteModalProps) => {
    const { translate } = useTranslate();

    const inputRef = useRef<HTMLInputElement>(null);

    const handleDelete = () => {
        onDelete();
        onClose();
    };

    const onKeyEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleDelete();
        }
    };

    return (
        <Modal
            container={modalContainer}
            isClosedOnOverlayClick
            title={translate('editor.sidebar.shots.contentProperty.delete.modal.title')}
            onClose={onClose}
            isOpen={isOpen}
            actionSecondary={
                <Button
                    variant="secondary"
                    onClick={onClose}
                    {...generateTestId('shots_content_property_delete_modal_cancel')}
                    {...{ id: 'shots_content_property_delete_modal_cancel' }}
                >
                    {translate('editor.header.publish.modal.cancel')}
                </Button>
            }
            actionPrimary={
                <Button
                    variant="danger"
                    onClick={handleDelete}
                    {...generateTestId('shots_content_property_delete_modal_action')}
                    {...{ id: 'shots_content_property_delete_modal_action' }}
                >
                    {translate('editor.sidebar.shots.contentProperty.delete.modal.action')}
                </Button>
            }
        >
            {translate('editor.sidebar.shots.contentProperty.delete.modal.desc')}
            <HiddenInput autoFocus ref={inputRef} onKeyDown={onKeyEnter} onClick={(e) => e.stopPropagation()} />
        </Modal>
    );
};

export default DeleteModal;
