import React, { useEffect, useState } from 'react';
import { useDraggable, useDroppable } from '@dnd-legacy-kit/core';
import { checkMaskConflict } from 'packages/pages/editor/RightSideMenu/Shots/Masking/utils';
import { isCtrlKey } from '~/helpers/hotKeys';
import LayerItem from './LayerItem';
import { DraggableStyled } from './Draggable.styled';

const isParent = (element, id) => (element.parent ? element.parent.id === id || isParent(element.parent, id) : false);

export const Draggable = ({ element, level, toggleGroupExpand, expandedGroupIds, draggingElementId }) => {
    const id = element.id;
    const type = element.children ? 'group' : 'element';
    const [isCtrPressed, setCtrPressed] = useState(false);
    const disableDroppable =
        !draggingElementId ||
        id === draggingElementId ||
        isParent(element, draggingElementId) ||
        (type === 'element' && !isCtrPressed) ||
        checkMaskConflict(element, draggingElementId);

    const { attributes, listeners, setNodeRef } = useDraggable({ id, disabled: draggingElementId !== null });
    const { isOver, setNodeRef: setDropNodeRef } = useDroppable({
        id: `droppable-${id}`,
        disabled: disableDroppable,
        data: {
            renderOrder: element.renderOrder + 0.5,
            type,
            id,
        },
    });

    useEffect(() => {
        const listener = (event) => {
            setCtrPressed(isCtrlKey(event));
        };
        document.addEventListener('keydown', listener);
        document.addEventListener('keyup', listener);

        return () => {
            document.removeEventListener('keydown', listener);
            document.removeEventListener('keyup', listener);
        };
    }, []);

    return (
        <DraggableStyled ref={setNodeRef} {...listeners} {...attributes}>
            {!disableDroppable && <div className="layers--item-droppable" ref={setDropNodeRef} />}
            <LayerItem
                level={level}
                element={element}
                childrenLength={(element.children || []).length}
                onGroupExpand={toggleGroupExpand}
                isDragging={element.id === draggingElementId}
                isGroupExpand={!!~expandedGroupIds.indexOf(element.id)}
                isDragOver={isOver}
            />
        </DraggableStyled>
    );
};
