import { BaseAnimation } from '../../Animations/BaseAnimation';
import { BaseVisualElement } from '../BaseVisualElement';
import { truthy } from '@bynder-studio/misc';

export const computeAndApplyAnimations = (element: BaseVisualElement, diffProps: any): void => {
    if (diffProps.duration) {
        const oldDuration = element.duration - diffProps.duration;
        const inDuration = (element.animationIn && element.animationIn.duration) || 0;
        const outDuration = (element.animationOut && element.animationOut.duration) || 0;

        if (inDuration + outDuration > element.duration && element.duration < oldDuration) {
            const newOutDuration = element.duration - inDuration;

            if (newOutDuration > 0) {
                if (element.animationOut) {
                    const { config } = element.animationOut;
                    config.duration = newOutDuration;
                    element.animationOut.update(config);
                }
            } else {
                element.animationOut = null;

                if (element.animationIn) {
                    const { config } = element.animationIn;
                    config.duration = element.duration;
                    element.animationIn.update(config);
                }
            }
        } else if (element.animationOut) {
            const { config } = element.animationOut;
            element.animationOut.update(config);
        }
    }

    if (diffProps.startFrame) {
        if (element.animationIn) {
            const { config } = element.animationIn;
            element.animationIn.update(config);
        }

        if (element.animationOut) {
            const { config } = element.animationOut;
            element.animationOut.update(config);
        }
    }

    if (element.animations && element.animations.length) {
        element.animations = element.animations
            .map((animation: BaseAnimation) => {
                const { config } = animation;

                if (diffProps.startFrame) {
                    config.startFrame = animation.startFrame + diffProps.startFrame;
                    animation.update(config.startFrame, config.duration, config);
                    return animation;
                }

                if (diffProps.duration) {
                    const oldDuration = element.duration - diffProps.duration;

                    if (
                        animation.startFrame - element.startFrame + animation.duration > element.duration &&
                        element.duration < oldDuration
                    ) {
                        const relativeStartFrame = animation.startFrame - element.startFrame;
                        const newAnimationDuration = element.duration - relativeStartFrame;

                        if (newAnimationDuration > 0) {
                            config.duration = newAnimationDuration;
                            animation.update(config.startFrame, config.duration, config);
                        } else {
                            return null;
                        }
                    }

                    return animation;
                }

                return animation;
            })
            .filter(truthy);
    }
};
