import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getBynderAccessToken } from 'packages/store/platform/platform.selectors';
import authService from '~/services/authService';

const useDirectAccessToken = () => {
    const bynderAccessToken = useSelector(getBynderAccessToken);

    const getDirectAccessToken = useCallback(() => {
        if (!bynderAccessToken) {
            return Promise.resolve('');
        }

        return authService.getDirectToken(bynderAccessToken);
    }, [bynderAccessToken]);

    return { getDirectAccessToken };
};

export default useDirectAccessToken;
