const getImageFrame = async (videoObject: HTMLVideoElement, isAlpha = false): Promise<HTMLImageElement | false> => {
    if (!videoObject) {
        return false;
    }

    const videoCanvas = document.createElement('canvas');
    videoCanvas.width = videoObject.videoWidth;
    videoCanvas.height = videoObject.videoHeight;
    const videoCanvasCtx = videoCanvas.getContext('2d');
    videoCanvasCtx.drawImage(videoObject, 0, 0, videoObject.videoWidth, videoObject.videoHeight);

    if (isAlpha) {
        let imageDataRgb = videoCanvasCtx.getImageData(0, 0, videoCanvas.width, videoCanvas.height / 2);
        let imageDataAlpha = videoCanvasCtx.getImageData(
            0,
            videoCanvas.height / 2,
            videoCanvas.width,
            videoCanvas.height / 2,
        );
        let imageDataRgbData = imageDataRgb.data;
        let imageDataAlphaData = imageDataAlpha.data;

        // update every fourth pixel from the RGB image with transparency from the alpha image (0 - 255)
        for (let i = 3; i < imageDataRgbData.length; i += 4) {
            imageDataRgbData[i] = Math.floor(
                (imageDataAlphaData[i - 1] + imageDataAlphaData[i - 2] + imageDataAlphaData[i - 3]) / 3,
            );
        }

        videoCanvas.height = videoCanvas.height / 2;
        videoCanvasCtx.putImageData(imageDataRgb, 0, 0);
    }

    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = videoCanvas.toDataURL(isAlpha ? 'image/png' : 'image/jpeg');

        img.onload = () => resolve(img);
        img.onerror = () => reject(img);
    });
};

export default getImageFrame;
