import { useCallback, useRef, useSyncExternalStore } from 'react';
import { MultiPageVideoModel, VideoModel } from '@bynder-studio/render-core';
import { type CreativeModelFactory } from '@bynder-studio/render-web';

export default function useAudio(factory?: CreativeModelFactory | null) {
    const creativeModel = factory?.getCreativeModel();
    const listenersRef = useRef(new Set<() => void>());

    const subscribe = useCallback(
        (listener: () => void) => {
            listenersRef.current.add(listener);

            return () => {
                listenersRef.current.delete(listener);
            };
        },
        [listenersRef],
    );

    const onChange = useCallback(() => listenersRef.current.forEach((listener) => listener()), [listenersRef]);

    const control =
        creativeModel instanceof MultiPageVideoModel || creativeModel instanceof VideoModel
            ? creativeModel.getAudioControl()
            : null;

    const getVolume = useCallback(() => control?.getVolume() || 0, [control]);
    const setVolume = useCallback(
        (volume: number) => {
            control?.setVolume(volume);
            onChange();
        },
        [control, onChange],
    );

    const getMutedStatus = useCallback(() => control?.getMutedStatus() || 0, [control]);
    const setMutedStatus = useCallback(
        (isMuted: boolean) => {
            control?.setMutedStatus(isMuted);
            onChange();
        },
        [control, onChange],
    );

    const toggleMutedStatus = useCallback(() => {
        control?.setMutedStatus(!control?.getMutedStatus());
        onChange();
    }, [control, onChange]);

    return {
        volume: useSyncExternalStore(subscribe, getVolume),
        isMuted: useSyncExternalStore(subscribe, getMutedStatus),
        setVolume,
        setMutedStatus,
        toggleMutedStatus,
    };
}
