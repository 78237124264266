import React, { Fragment } from 'react';
import { Button, Divider, Dropdown, Form } from '@bynder/design-system';
import generateTestId from '~/helpers/testIdHelpers';
import { FormSectionContent } from 'packages/pages/editor/RightSideMenu/index.styled';
import { EXPORT_TYPE_OPTIONS } from './enums';
import { useExportSettings } from './useExportSettings';
import { ExportSettingsOption } from './ExportSettings.styled';

export const ExportSettings = () => {
    const { handleExportTypeClick, chosenExportValue } = useExportSettings();
    const testId = generateTestId('global_export_settings');

    return (
        <FormSectionContent onSubmit={(event) => event.preventDefault()}>
            <Form.Group>
                <Form.Label>Export</Form.Label>
                <Dropdown
                    position="bottom"
                    trigger={({ isOpen, ...triggerProps }) => (
                        <Button
                            isPressed={isOpen}
                            rightIcon={<Dropdown.Arrow />}
                            isFullWidth
                            {...triggerProps}
                            {...testId}
                        >
                            <Dropdown.SelectButtonText>{chosenExportValue.title}</Dropdown.SelectButtonText>
                        </Button>
                    )}
                >
                    {EXPORT_TYPE_OPTIONS.map((item, idx) => (
                        <Fragment key={item.name}>
                            <ExportSettingsOption
                                isChecked={chosenExportValue.name === item.name}
                                onClick={() => {
                                    handleExportTypeClick(item.name);
                                }}
                                subtext={item.description}
                            >
                                {item.title}
                            </ExportSettingsOption>
                            {idx !== EXPORT_TYPE_OPTIONS.length - 1 && <Divider />}
                        </Fragment>
                    ))}
                </Dropdown>
                <Form.HelperText>{chosenExportValue.description}</Form.HelperText>
            </Form.Group>
        </FormSectionContent>
    );
};
