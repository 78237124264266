import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IconEdit } from '@bynder/icons';
import * as types from '../../store/types';
import { isCtrlKey } from '~/helpers/hotKeys';

type Props = {
    withDoubleClick: boolean;
    hideIcon: boolean;
    editable: boolean;
    className: string;
    editOnlyWithIcon: boolean;
    allowOpen: boolean;
    iconSize: number;
    emptyValueStyles: any;
    confirmEmpty: boolean;
    onMountCb: () => void;
};

class Editable extends Component {
    valueLayerProps = {};

    constructor(props: Props) {
        super(props);
        this.state = {
            ignoreChanges: props.ignoreChanges || (() => false),
            invalid: false,
            value: props.value,
            icon: false,
            validations: props.validations || {},
        };

        this.inputRef = React.createRef();

        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.moveCaretAtEnd = this.moveCaretAtEnd.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);

        if (!this.props.editOnlyWithIcon) {
            if (this.props.withDoubleClick) {
                this.valueLayerProps.onDoubleClick = () => this.open();
            } else {
                this.valueLayerProps.onClick = () => this.open();
            }
        }
    }

    componentDidMount() {
        if (this.props.onMountCb) {
            this.props.onMountCb();
        }
    }

    componentDidUpdate(prevProps) {
        if (
            !this.state.ignoreChanges(prevProps, this.props) &&
            JSON.stringify(this.props) !== JSON.stringify(prevProps)
        ) {
            this.setState({
                value: this.props.value,
            });
        }

        if (!this.props.editable) {
            document.removeEventListener('click', this.handleOutsideClick, !!this.props.useCapture);
        } else if (!prevProps.editable && this.props.allowOpen) {
            this.open();
        }
    }

    componentWillUnmount() {
        this.close();

        if (this.props.confirmBeforeUnmount) {
            this.handleConfirm();
        }
    }

    open() {
        const { onToggle, isEditingBatchComponent, setIsEditingBatchComponent, scrollContainerRef } = this.props;
        setTimeout(() => {
            const findBatchPlayButton = document.querySelector('.active--row .batch--generation-play-btn');
            findBatchPlayButton?.setAttribute('disabled', true);

            onToggle(true);
            setIsEditingBatchComponent(!isEditingBatchComponent);
            document.addEventListener('click', this.handleOutsideClick, !!this.props.useCapture);

            if (scrollContainerRef && scrollContainerRef.current) {
                scrollContainerRef.current.scrollLeft = 0;
            }
        }, 200);
    }

    close() {
        const { onToggle, isEditingBatchComponent, setIsEditingBatchComponent } = this.props;
        const findBatchPlayButton = document.querySelectorAll('.batch--generation-play-btn');
        findBatchPlayButton?.forEach((btn) => btn.removeAttribute('disabled'));

        onToggle(false);
        setIsEditingBatchComponent(!isEditingBatchComponent);
        document.removeEventListener('click', this.handleOutsideClick, !!this.props.useCapture);
    }

    handleOutsideClick(e) {
        if (!this.node || this.node.contains(e.target)) {
            return;
        }

        this.handleConfirm();
    }

    handleChange(e) {
        const handleChange = this.props.handleChange;

        if (handleChange) {
            handleChange(e.target);
        } else {
            let value = e.target.value;

            const error = this._validate(value);

            if (error) {
                if (Object.keys(error).includes('maxLength')) {
                    value = value.substr(0, value.length - 1);
                }
            }

            this.setState({
                value,
                width: e.target.scrollWidth,
            });
        }
    }

    _validate(v) {
        const validations = this.state.validations;

        if (validations) {
            if (validations.maxLength && validations.maxLength < v.length) {
                if (this.props.handleValidationFail) {
                    this.props.handleValidationFail(
                        `Name should not be longer than ${validations.maxLength} characters. Please shorten the name to proceed`,
                    );

                    return {
                        maxLength: true,
                    };
                }

                return true;
            }
        }

        return false;
    }

    handleCancel() {
        this.setState({ value: this.props.value });
        this.close();
    }

    /**
     * Handle the confirmation of the value passed for the text
     */
    handleConfirm() {
        if (this.state.invalid) {
            return;
        }

        if (!!this.state.value && !!this.state.value.trim() && this.state.value !== this.props.value) {
            this.props.onConfirm(this.state.value);
        } else if (this.props.confirmEmpty && this.state.value !== this.props.value) {
            this.props.onConfirm('');
        } else {
            this.handleCancel();
        }
    }

    showIcon() {
        this.setState({ icon: true });
    }

    moveCaretAtEnd(e) {
        e.target.value = this.state.value;
        this.setState({
            width: e.target.scrollWidth,
        });
        e.target.select();
    }

    handleKeyUp(e) {
        if (e.key === 'Enter' && !e.shiftKey && !isCtrlKey(e)) {
            this.handleConfirm();
        }
    }

    render() {
        const {
            editable,
            handleChange,
            hideIcon,
            EditableInput,
            valueNodeRef,
            iconSize,
            emptyValueStyles,
            wrapperClassName,
        } = this.props;
        const value = handleChange ? this.props.value : this.state.value;
        const displayValue = this.props.displayValue || value;
        const EditableElem = EditableInput || (
            <div className="input-group">
                <input
                    type="text"
                    onScroll={(e) => e.stopPropagation()}
                    maxLength={this.state.validations.maxLength ? this.state.validations.maxLength + 1 : ''}
                    onChange={(e) => this.handleChange(e)}
                    style={{ width: this.state.width }}
                    value={value}
                    className={`form-control-plaintext mw-100 p-0 ${this.props.className || ''}`}
                    onFocus={this.moveCaretAtEnd}
                    onBlur={() => this.handleConfirm()}
                    autoFocus
                />
            </div>
        );

        return (
            <div className={`${wrapperClassName || ''}`}>
                {editable && (
                    <div
                        onKeyUp={this.handleKeyUp}
                        ref={(node) => {
                            this.node = node;
                        }}
                    >
                        {EditableElem}
                    </div>
                )}
                {!editable && (
                    <p className={`m-0 cursor-pointer ${this.props.className || ''}`}>
                        <span {...this.valueLayerProps} ref={valueNodeRef} style={displayValue ? {} : emptyValueStyles}>
                            {displayValue}
                        </span>
                        {!hideIcon && (
                            <button
                                type="button"
                                onClick={() => this.open()}
                                className={`btn btn-secondary btn-sm ${
                                    this.props.alwaysShowButton ? '' : 'text-hover-show'
                                } ml-2 p-0`}
                            >
                                <IconEdit size={iconSize} />
                            </button>
                        )}
                    </p>
                )}
            </div>
        );
    }
}

function mapActions(dispatch) {
    return {
        setIsEditingBatchComponent: (isEditingBatchComponent) => {
            dispatch({
                type: types.BATCH_IS_EDITING_COMPONENT,
                isEditingBatchComponent,
            });
        },
    };
}

function mapProperties(state) {
    const { isEditingBatchComponent } = state.general;

    return {
        isEditingBatchComponent,
    };
}

Editable.defaultProps = {
    withDoubleClick: false,
    editOnlyWithIcon: false,
    confirmEmpty: false,
};

export default connect(mapProperties, mapActions)(Editable);
