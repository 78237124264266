import React from 'react';
import { IconLayers } from '@bynder/icons';
import { token } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { EmptyLayersContainer, EmptyLayersWrap } from './LayerTree.styled';

const LayersEmpty = () => {
    const { translate } = useTranslate();

    return (
        <EmptyLayersContainer>
            <EmptyLayersWrap>
                <IconLayers size="48" style={{ color: token.gray200 }} />
                {translate('editor.layers.empty')}
            </EmptyLayersWrap>
        </EmptyLayersContainer>
    );
};

export default LayersEmpty;
