import ProxyServerConnector from '~/services/connectors/ProxyServerConnector';

class BynderIdentitiesService extends ProxyServerConnector {
    constructor(path = '/bynder/identity') {
        super(path);
        // @ts-ignore
        // eslint-disable-next-line no-undef
        this.gateway = new ProxyServerConnector(path, GATEWAY_PREFIX);
    }

    fetchIdentities(query: string) {
        // @ts-ignore
        return this.gateway.get(`?query=${encodeURIComponent(query)}`);
    }
}

export default new BynderIdentitiesService();
