import { useCallback, useEffect } from 'react';
import { isInteractiveElement } from '@bynder-studio/misc';
import { isCtrlKey } from '~/helpers/hotKeys';
import useArrangeActions from './useArrangeActions';
import { MultiPageVideoModel, MultiPageImageModel, BaseVisualElement } from '@bynder-studio/render-core';

type Props = {
    creativeModel: MultiPageVideoModel | MultiPageImageModel;
    elements: BaseVisualElement[];
    selectedElements: BaseVisualElement[];
};

export const useArrangeShortcuts = ({ creativeModel, elements, selectedElements }: Props) => {
    const { handleBringForward, handleBringToFront, handleSendBackward, handleSendToBack } = useArrangeActions({
        creativeModel,
        elements,
        selectedElements,
    });
    const handleKeyDown = useCallback(
        (e: KeyboardEvent): void => {
            if (isInteractiveElement(e)) {
                return;
            }

            if (isCtrlKey(e)) {
                switch (e.code) {
                    case 'BracketLeft': {
                        e.preventDefault();
                        handleBringForward();
                        break;
                    }

                    case 'BracketRight': {
                        e.preventDefault();
                        handleSendBackward();
                        break;
                    }

                    default:
                        break;
                }
            }

            if (!isCtrlKey(e)) {
                switch (e.code) {
                    case 'BracketLeft': {
                        e.preventDefault();
                        handleBringToFront();
                        break;
                    }

                    case 'BracketRight': {
                        e.preventDefault();
                        handleSendToBack();
                        break;
                    }

                    default:
                        break;
                }
            }
        },
        [handleBringForward, handleSendBackward, handleBringToFront, handleSendToBack],
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);
};
