import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const ShotStyled = styled.div`
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    background-color: ${token.white};
    cursor: pointer;

    &:not(:first-child) {
        border-left: 1px solid ${token.colorShadow};
    }

    .shots__thumbnail {
        height: 32px;
    }

    .shots__shotPreview {
        border-radius: 5px;
        box-shadow: 0px 0px 5px 0px #ccc;
        margin-right: 10px;
        margin-left: 10px;
        height: 32px;
        overflow: hidden;
        border-color: ${token.brandPrimary500} !important;
    }
`;

export const ShotsStyled = styled.div`
    height: 100%;
    position: relative;
`;
