import React from 'react';
import { Table } from '@bynder/design-system';

import DateCreatedCell from 'packages/pages/components/list/cell/DateCell';
import UploadTitleCell from 'packages/pages/components/list/cell/UploadTitleCell';
import ResolutionCell from 'packages/pages/components/list/cell/ResolutionCell';
import MediaTypeCell from 'packages/pages/components/list/cell/MediaTypeCell';

export const columnWidths = {
    6: ['auto', '40%', '20%', '20%', '20%', 'auto'],
    5: ['auto', '40%', '30%', '30%', 'auto'],
};

type ListViewItemProps = {
    asset: any;
    actions: any;
    isSelected: boolean;
    headersMap?: string[];
};

const ListViewItem = ({ asset, actions, isSelected, headersMap = [] }: ListViewItemProps) => (
    <Table.Row
        isSelected={isSelected}
        onSelect={() => {
            actions.onClick(asset);
        }}
        onClick={() => {
            actions.onClick(asset);
        }}
    >
        {(headersMap?.includes('name') || !headersMap?.length) && (
            <UploadTitleCell upload={asset} isUploadDetails={false} />
        )}
        {(headersMap?.includes('date_created') || !headersMap?.length) && <DateCreatedCell date={asset.created} />}
        {(headersMap?.includes('resolution') || !headersMap?.length) && <ResolutionCell upload={asset} />}
        {(headersMap?.includes('type') || !headersMap?.length) && <MediaTypeCell type={asset.type} />}
    </Table.Row>
);

export const renderItems = (assets: any, actions: any, selectedId: number, headersMap = [] as string[]) =>
    assets.map((asset) => (
        <ListViewItem
            key={asset.id}
            asset={asset}
            actions={actions}
            headersMap={headersMap}
            isSelected={asset.id === selectedId}
        />
    ));
