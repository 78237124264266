import React, { useCallback, useRef } from 'react';
import ThumbnailSelector from './thumbnailSelector';
import useShot from '../../../../hooks/useShot';
import { ShotStyled } from './Shots.styled';

type Props = {
    index: number;
    shotsContainerRef: any;
    elementWrapperRef: any;
    shotStyle: any;
    selected: boolean;
    onClick: (shot: any) => void;
    onMouseDown: () => void;
    onShotThumbnailChange: (shot: any, frame: number) => void;
};

export default function Shot({
    index,
    elementWrapperRef,
    shotsContainerRef,
    shotStyle,
    selected,
    onClick,
    onMouseDown,
    onShotThumbnailChange,
    hideThumbnail,
}: Props) {
    const { shot, image } = useShot(index);
    const ref = useRef();

    const handleClick = useCallback(() => {
        onClick && onClick(shot);
    }, [onClick, shot]);

    const handleShotThumbnailChange = useCallback(
        (frame) => {
            onShotThumbnailChange && onShotThumbnailChange(shot, frame);
        },
        [onShotThumbnailChange, shot],
    );

    return (
        <ShotStyled ref={ref} onClick={handleClick} onMouseDown={onMouseDown} style={shotStyle}>
            <div className={`shots__shotPreview pointer ${selected ? 'border-bottom border-md border-success' : ''}`}>
                <img
                    className={`shots__thumbnail ${hideThumbnail ? 'd-none' : ''}`}
                    src={image}
                    alt={`Shot ${index + 1}`}
                    draggable={false}
                />
            </div>
            <ThumbnailSelector
                {...shot}
                shotsContainerRef={shotsContainerRef}
                elementWrapperRef={elementWrapperRef}
                shotContainerRef={ref}
                onDrop={handleShotThumbnailChange}
            />
            {shot.name}
        </ShotStyled>
    );
}
