import React from 'react';
import { notify, Thumbnail } from '@bynder/design-system';
import { IconCancel } from '@bynder/icons';
import { Translate } from '@bynder/localization';
import ReviewRequestService, { RequestApprovalData } from 'packages/services/ReviewRequestService';
import AccessService from 'packages/services/AccessControl';
import { catchResponseError } from 'packages/helpers/helpers';
import { actionTypes } from './types';
import { EntityType } from '../sharing/types';

export function createReview(entityId: number, reviewData: RequestApprovalData) {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.REVIEW_CREATE_REQUEST, status: reviewData.status });

        ReviewRequestService.requestApproval(entityId, reviewData)
            .then(({ status: reviewStatus, json: { approvalStatus: previousApprovalStatus } }) => {
                if (reviewStatus === 200) {
                    const { status: approvalStatus, comment } = reviewData;

                    AccessService.getAccess([entityId], EntityType.CREATIVE).then(({ status, json }) => {
                        if (status === 200) {
                            const data = json[0];

                            dispatch({
                                type: actionTypes.REVIEW_CREATE_SUCCESS,
                                status: approvalStatus === 'CANCELLED' ? previousApprovalStatus : approvalStatus,
                                comment,
                                actionDate: new Date().toISOString(),
                                entityGrantedPermissions: data.entityGrantedPermissions,
                            });
                        } else {
                            dispatch({ type: actionTypes.REVIEW_CREATE_FAILURE });
                        }
                    });

                    // sendAppcuesEvent('Entity review updated', { entityId });
                } else {
                    dispatch({ type: actionTypes.REVIEW_CREATE_FAILURE });
                    notify({
                        thumbnail: (
                            <Thumbnail
                                variant="clean"
                                shape="circle"
                                backgroundColor="red500"
                                icon={<IconCancel />}
                                iconColor="white"
                            />
                        ),
                        title: <Translate id="design.save.approval.error" />,
                    });
                }
            })
            .catch(catchResponseError);
    };
}

export function initReview() {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.REVIEW_CREATE_INIT });
    };
}
