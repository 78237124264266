import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Progress, Buffer } from '@bynder/design-system';
import { IconImageSearch, IconUpload } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import {
    dropImportedTemplate,
    importTemplateFromDesignFileForAnalysis,
    importTemplateFromXML,
    sendNotify,
} from 'packages/store/importTemplate/importTemplate.actions';
import {
    isImportTemplateLoading,
    getImportTemplateType,
    getImportTemplateAnalysis,
} from 'packages/store/importTemplate/importTemplate.selectors';
import { ImportType } from 'packages/store/importTemplate/types';
import useAccessRights from 'packages/hooks/useAccessRights';
import useDocumentPickerModal from 'packages/pages/components/useDocumentPickerModal';
import { fetchCollection } from '~/store/collections/collections.actions';
import features from '~/configs/features';
import {
    AdditionalInfo,
    ImportContainer,
    ImportInner,
    IconContainer,
    Title,
    ProgressContainer,
    UploadButtons,
} from './Import.styled';
import useInterval from './useInterval';
import { initializeTemplates } from '~/store/templates/templates.actions';
import { initializeGallery } from '~/store/gallery/gallery.actions';
import { deselectBlankType } from 'packages/store/newDesign/newDesign.actions';

const ImportDesign = () => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const { isPlatformAllowed } = useAccessRights();
    const inputFileRef = useRef<HTMLInputElement>(null);

    const [fakeProgress, setProgress] = useState(0);

    const isLoading = useSelector(isImportTemplateLoading);
    const type = useSelector(getImportTemplateType);
    const designFileAnalysis = useSelector(getImportTemplateAnalysis);
    const [designFileProgress, setDesignFileProgress] = useState(false);
    const isDesignFileEnabled = isPlatformAllowed([features.IMAGES_ENABLED]);
    const isXMLImportEnabled = isPlatformAllowed([features.VIDEOS_ENABLED]);

    useLayoutEffect(() => {
        dispatch(initializeTemplates());
        dispatch(initializeGallery());
        dispatch(deselectBlankType());
    }, [dispatch]);

    const isXMLLoading = (isLoading || fakeProgress > 0) && type === ImportType.XML;
    const isDesignFileLoading = (isLoading || fakeProgress > 0) && type === ImportType.DESIGN_FILE;
    const isDesignFileLoaded = type === ImportType.DESIGN_FILE && designFileAnalysis;

    useInterval(() => {
        if (isXMLLoading) {
            setProgress((prev) => {
                if (prev >= 90 && prev < 98) {
                    return prev + 1;
                }
                if (prev === 98) {
                    return 98;
                }

                return prev + 10;
            });
        }

        if (isDesignFileLoaded) {
            setDesignFileProgress(true);
            setProgress((prev) => {
                if (prev >= 60 && prev < 90) {
                    return prev + 15;
                }
                if (prev >= 90 && prev < 100) {
                    return prev + 5;
                }
                if (prev === 100) {
                    return 100;
                }

                return prev + 30;
            });
        }
    }, 1000);

    const onImportXML = () => {
        inputFileRef?.current?.click();
    };

    const onXMLFileDrop = (event) => {
        event.preventDefault();

        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            const formData = new FormData();
            formData.append('file', event.dataTransfer.files[0], event.dataTransfer.files[0].name);
            dispatch(importTemplateFromXML(formData));
        }
    };

    const onXMLFileAdd = (event) => {
        const formData = new FormData();
        formData.append('file', event.target.files[0], event.target.files[0].name);
        dispatch(importTemplateFromXML(formData));
    };

    const { projectId } = useParams<{ projectId: string }>();

    const restoreCollections = () => {
        if (projectId) {
            dispatch(fetchCollection(projectId, true));
        }
    };

    const onDesignFileAdd = (event) => {
        const file = Array.isArray(event) ? event[0] : event;

        if (!file) {
            sendNotify('modal.design.create.design.import.file.type_error.undefined');
            return;
        }

        toggleDocumentPicker();
        dispatch(importTemplateFromDesignFileForAnalysis(file));
        restoreCollections();
    };

    const compactViewProps = {
        language: 'en_US',
        mode: 'SingleSelect',
        onSuccess: onDesignFileAdd,
        assetTypes: ['IMAGE', 'DOCUMENT'],
        isContainerMode: true,
    };

    const { renderDocumentPicker, toggleDocumentPicker } = useDocumentPickerModal({
        compactViewProps,
        confirmSelection: onDesignFileAdd,
        type: 'document',
        cancelSelection: restoreCollections,
    });

    const onImportDesignFile = () => {
        toggleDocumentPicker();
    };

    const renderProgressBar = () => {
        if (type === ImportType.XML) {
            return <Progress width={fakeProgress} color="brand" />;
        }
        return designFileProgress === false ? (
            <Buffer color="brand" />
        ) : (
            <Progress width={fakeProgress} color="brand" />
        );
    };

    const getInfoCopy = () => {
        if (isDesignFileEnabled && isXMLImportEnabled) {
            return translate('modal.design.create.design.import.info');
        }
        if (isDesignFileEnabled && !isXMLImportEnabled) {
            return translate('modal.design.create.design.import.info-image');
        }

        return translate('modal.design.create.design.import.info-nofeature');
    };

    useEffect(() => {
        // Reset progresses on first load
        dispatch(dropImportedTemplate());
        setDesignFileProgress(false);
        setProgress(0);
    }, []);

    return (
        <>
            <ImportContainer
                id="new_design_modal_import"
                data-testid="import design container"
                onDragOver={(e) => e.preventDefault()}
                onDrop={onXMLFileDrop}
            >
                <ImportInner>
                    <IconContainer data-testid="import design icon">
                        <IconUpload />
                    </IconContainer>
                    <Title id="new_design_modal_import_title" data-testid="import design title">
                        {translate('modal.design.create.design.import.title')}
                    </Title>
                    <AdditionalInfo id="new_design_modal_import_info" data-testid="Import design info">
                        {getInfoCopy()}
                    </AdditionalInfo>
                    {!isXMLLoading && !isDesignFileLoading && !isDesignFileLoaded && (
                        <>
                            <UploadButtons>
                                {isDesignFileEnabled && (
                                    <Button
                                        data-testid="import design file button"
                                        variant="primary"
                                        onClick={onImportDesignFile}
                                        icon={<IconImageSearch />}
                                        title={translate('modal.design.create.design.import.upload.design_file')}
                                    >
                                        {translate('modal.design.create.design.import.upload.design_file')}
                                    </Button>
                                )}
                                {isXMLImportEnabled && (
                                    <>
                                        <Button
                                            data-testid="import xml design button"
                                            variant={isDesignFileEnabled ? 'secondary' : 'primary'}
                                            onClick={onImportXML}
                                            icon={<IconUpload />}
                                            title={translate('modal.design.create.design.import.upload.xml')}
                                        >
                                            {translate('modal.design.create.design.import.upload.xml')}
                                        </Button>
                                        <input
                                            ref={inputFileRef}
                                            type="file"
                                            accept="text/xml"
                                            style={{ display: 'none' }}
                                            onChange={onXMLFileAdd}
                                        />
                                    </>
                                )}
                            </UploadButtons>
                            {isDesignFileEnabled && (
                                <p>{translate('modal.design.create.design.import.upload.details')}</p>
                            )}
                        </>
                    )}
                    {(isXMLLoading || isDesignFileLoading || isDesignFileLoaded) && (
                        <ProgressContainer>{renderProgressBar()}</ProgressContainer>
                    )}
                </ImportInner>
            </ImportContainer>
            {renderDocumentPicker()}
        </>
    );
};

export default ImportDesign;
