import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VerticalNav, Button, Flex } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconCollection, IconFolder, IconUpload, IconVideoLibrary } from '@bynder/icons';
import { AssetGroup } from 'packages/pages/editor/ContentPickerModal/types';
import SidebarSkeleton from 'packages/pages/components/sidebar/SidebarSkeleton';
import { getCollections } from 'packages/store/collections/collections.selectors';
import { customerIdSelector } from 'packages/store/user/user.selectors';
import { StyledSidebar, GlobalNav } from 'packages/pages/components/AssetsPickerModal/components/index.styled';
import useAccessRights from 'packages/hooks/useAccessRights';
import { OwnershipFilterType } from 'packages/pages/components/filters/components/Ownership/types';
import AuthorizationHelper from '~/helpers/AuthorizationHelper';
import features from '~/configs/features';
import roles from '~/configs/roles';
import generateTestId from '~/helpers/testIdHelpers';
import { init, fetchCollections } from '~/store/collections/collections.actions';

type Props = {
    assetType: AssetGroup;
    type: string;
    setSelectedCollection: (collecctionId: number | null) => void;
    selectedCollectionId: number | null;
    handleDamClick: () => void;
};

const Sidebar = ({ assetType, type, setSelectedCollection, selectedCollectionId, handleDamClick }: Props) => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const { isPlatformAllowed, isAccountRoleAssigned } = useAccessRights();

    const customerId = useSelector(customerIdSelector);
    const { fetchOptions, isFetching, collections, totalItems } = useSelector(getCollections);
    const wrapperRef = useRef(null);

    const isTrialAccount =
        isPlatformAllowed([features.TRIAL_ENABLED]) && isAccountRoleAssigned(roles.video_brand_studio.video.manage);

    useEffect(() => {
        // todo: another bycicle waiting for DS update
        setTimeout(() => {
            if (!wrapperRef.current) return;

            const parent = wrapperRef.current.parentElement;
            if (parent) parent.style.height = '100%';
        }, 0);
    }, [isFetching, collections]);

    useEffect(() => {
        const options = {
            ...fetchOptions,
            type: 'ASSET',
            subType: undefined,
            ownershipSource: isTrialAccount ? OwnershipFilterType.OWNED_BY_ME : OwnershipFilterType.OWNED_BY_ANYONE,
        };

        dispatch(init(options, customerId, true));
    }, []);

    const loadMoreAssets = () => {
        const options = {
            ...fetchOptions,
            page: (fetchOptions.page += 1),
        };

        dispatch(fetchCollections(options, customerId, false, false));
    };

    const onScroll = (event: any) => {
        const { scrollTop, scrollHeight, clientHeight } = event.target;
        const t = scrollTop / (scrollHeight - clientHeight);

        if (t > 0.75 && !isFetching && collections.length < totalItems) {
            loadMoreAssets();
        }
    };

    if (isFetching && !collections.length) {
        return <SidebarSkeleton />;
    }

    return (
        <VerticalNav>
            <StyledSidebar onScroll={onScroll} ref={wrapperRef}>
                {AuthorizationHelper.isFeatureAvailable(features.BYNDER_DAM_ASSETS) && (
                    <GlobalNav>
                        <VerticalNav.Title>{translate('modal.assetsPicker.sidebar.global_assets')}</VerticalNav.Title>
                        <VerticalNav.Item
                            icon={type === 'image' || type === 'document' ? <IconCollection /> : <IconVideoLibrary />}
                            isActive={assetType === AssetGroup.GLOBAL}
                            onClick={() => {
                                handleDamClick();
                            }}
                        >
                            {translate('modal.assetsPicker.sidebar.all_assets', {
                                count: type === 'audio' ? 1 : 2,
                                type,
                            })}
                        </VerticalNav.Item>
                    </GlobalNav>
                )}
                <VerticalNav.Title>{translate('modal.assetsPicker.sidebar.uploads')}</VerticalNav.Title>
                <VerticalNav.Item
                    icon={<IconUpload />}
                    isActive={selectedCollectionId === null && assetType === 'LOCAL'}
                    onClick={() => {
                        setSelectedCollection(null);
                    }}
                    {...generateTestId('assets_picker_sidebar_all_uploads')}
                >
                    {translate('modal.assetsPicker.collections.all_uploads')}
                </VerticalNav.Item>
                {collections.map((collection) => (
                    <VerticalNav.Item
                        key={collection.id}
                        icon={<IconFolder />}
                        isActive={collection.id === selectedCollectionId}
                        onClick={() => {
                            setSelectedCollection(collection.id);
                        }}
                    >
                        {collection.name}
                    </VerticalNav.Item>
                ))}
                {isFetching && collections.length && (
                    <div>
                        <Flex justifyContent="center" alignItems="center">
                            <Button title="" variant="clean" isLoading />
                        </Flex>
                    </div>
                )}
            </StyledSidebar>
        </VerticalNav>
    );
};

export default Sidebar;
