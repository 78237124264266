import { createSelector } from 'reselect';
import { CategoriesState } from './types';
import { CREATIVES_PENDING_STATUSES } from '../creatives/creatives.constants';

export const getCategoriesState = (state: any): CategoriesState => state.categories;
export const getCategories = (state: any): CategoriesState['categories'] => state.categories.categories;
export const getCategory = (state: any): CategoriesState['category'] => state.categories.category;

export const getCategoriesActionProgress = (
    state: any,
): {
    creatingInProgress: CategoriesState['creatingInProgress'];
    editingInProgress: CategoriesState['editingInProgress'];
    deleteInProgress: CategoriesState['deleteInProgress'];
    movingInProgress: CategoriesState['movingInProgress'];
    editingDetailsInProgress: CategoriesState['editingDetailsInProgress'];
} => ({
    creatingInProgress: state.categories.creatingInProgress,
    editingInProgress: state.categories.editingInProgress,
    deleteInProgress: state.categories.deleteInProgress,
    movingInProgress: state.categories.movingInProgress,
    editingDetailsInProgress: state.categories.editingDetailsInProgress,
});

export const getPendingCategories = createSelector(getCategories, (categories) =>
    categories
        // todo: check if null check is required
        // according to the type of collection, previewItems is always defined, but the old code had the check
        .flatMap((categories) => categories.previewItems ?? [])
        .filter((previewItem) => CREATIVES_PENDING_STATUSES.includes(previewItem.thumbnailStatus))
        .map((previewItem) => previewItem.creativeId),
);
