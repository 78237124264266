import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Template } from 'packages/store/templates/types';
import { selectedTemplateSelector } from '~/store/templates/templates.selectors';
import { deselectTemplate } from '~/store/templates/templates.actions';
import useQueryParams from 'packages/hooks/useQueryParams';
import * as types from '~/store/types';
import Preview from './Preview';

const PreviewTemplate = () => {
    const template: Template = useSelector(selectedTemplateSelector);
    const dispatch = useDispatch();

    const { getQueryParam, resetQueryParams, setQueryParam } = useQueryParams();

    const templateID = getQueryParam('template');

    useEffect(() => {
        if (template) {
            setQueryParam('template', template.id.toString());
        }

        if (templateID) {
            dispatch(deselectTemplate());
            dispatch({ type: types.SELECT_TEMPLATE, templateId: +templateID });
        }

        return () => {
            if (templateID) {
                dispatch(deselectTemplate());
            }

            resetQueryParams(['template']);
        };
    }, []);

    return <Preview entity={template} />;
};

export default PreviewTemplate;
