import styled from 'styled-components';
import { Thumbnail, token } from '@bynder/design-system';

export const VideoThumbnail = styled(Thumbnail)`
    border: 1px solid ${token.gray100a};
    border-radius: ${token.radiusBase};

    & > div:first-child {
        background-color: transparent !important;
    }
`;
