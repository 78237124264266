import React, { useCallback } from 'react';
import { GroupElement, ImageElement, ShapeElement, TextElement, VideoElement } from '@bynder-studio/render-core';
import { useTranslate } from '@bynder/localization';
import { Button, Dropdown, List, Thumbnail, Tooltip } from '@bynder/design-system';
import {
    IconAdd,
    IconBuildShape,
    IconFolder,
    IconFolderMask,
    IconImage,
    IconImageMask,
    IconMask,
    IconMaskAlt,
    IconMaskAlt3,
    IconMaskDisabled,
    IconMaskInversedAlt,
    IconMore,
    IconNoMask,
    IconTextField,
    IconTextMask,
    IconVideo,
    IconVideoMask,
} from '@bynder/icons';
import generateTestId from '~/helpers/testIdHelpers';
import useForceUpdate from '~/hooks/useForceUpdate';
import { MaskContainer, MaskModeContainer, StyledListItem } from './Masking.styled';
import { MaskMode } from './types';
import useMaskApply from './useMaskApply';
import useSearch from './useSearch';

type Props = {
    disabled: boolean;
    element: any;
    creativeModel: any;
    manipulationRenderer: any;
    creativeType: string;
};
const testId = generateTestId('shots_masking');

const Masking = ({ disabled, element: selectedElement, creativeModel, manipulationRenderer, creativeType }: Props) => {
    const { translate } = useTranslate();
    const { locked } = selectedElement;
    const { searchValue, searchResults, setSearch } = useSearch(creativeModel, selectedElement);
    const forceUpdate = useForceUpdate();

    useMaskApply();

    const getElementIcon = (el: any) => {
        const isUsedAsMask = creativeModel
            .getAllElementsRecursively()
            .filter((editorEl) => editorEl?.mask?.elementId === el.id).length;
        const isMaskedElement = el?.mask;

        if (isUsedAsMask) {
            return <IconMask />;
        }

        if (el instanceof ImageElement) {
            if (isMaskedElement) {
                return <IconImageMask />;
            }

            if (el.src) {
                return <Thumbnail variant="content" imageUrl={el.src ? el.src : undefined} size="xs" />;
            }

            return <IconImage />;
        }

        if (el instanceof VideoElement) {
            return isMaskedElement ? <IconVideoMask /> : <IconVideo />;
        }

        if (el instanceof TextElement) {
            return isMaskedElement ? <IconTextMask /> : <IconTextField />;
        }

        if (el instanceof ShapeElement) {
            return isMaskedElement ? <IconMaskAlt3 /> : <IconBuildShape />;
        }

        if (el instanceof GroupElement) {
            return isMaskedElement ? <IconFolderMask /> : <IconFolder />;
        }

        return <></>;
    };

    const maskElement = () => {
        if (selectedElement?.mask?.elementId) {
            return creativeModel.getElementById(selectedElement?.mask?.elementId);
        }

        return null;
    };

    const maskMode = () => {
        if (selectedElement?.mask) {
            return selectedElement?.mask?.mode;
        }

        return MaskMode.ALPHA;
    };

    const onMaskSelect = (newMaskElement) => {
        if (selectedElement.mask !== null) {
            const isMaskAttached = creativeModel
                .getAllElementsRecursively()
                .filter(
                    (editorEl) =>
                        editorEl?.mask?.elementId === selectedElement.mask.elementId &&
                        editorEl.id !== selectedElement.id,
                ).length;

            if (!isMaskAttached) {
                creativeModel.updateElement(selectedElement.mask.elementId, { mask: null });
            }
        }

        creativeModel.updateElement(newMaskElement.id, {
            mask: newMaskElement.mask,
        });

        creativeModel.updateElement(selectedElement.id, {
            mask: { elementId: newMaskElement.id, mode: maskMode() },
        });

        creativeModel.updateElement(newMaskElement.id, {
            mask: null,
        });

        forceUpdate();
    };

    const onMaskModeSelect = (mode) => {
        creativeModel.updateElement(selectedElement.id, {
            mask: { elementId: maskElement().id, mode },
        });
        forceUpdate();
    };

    const onMaskEdit = () => {
        manipulationRenderer.selectElement(maskElement().id, false);
    };

    const onMaskDelete = () => {
        creativeModel.updateElement(selectedElement.mask.elementId, { mask: null });
        creativeModel.updateElement(selectedElement.id, { mask: null });
    };

    const getDropdownIcon = () => {
        if (maskElement()) {
            return <IconMask />;
        }

        return <IconNoMask />;
    };

    const onShapeMask = useCallback(() => {
        if (selectedElement) {
            const initialElements = creativeModel.getAllElementsRecursively();

            creativeModel.createElement('SHAPE', {
                startFrame: selectedElement.startFrame,
                shapeType: 'RECTANGLE',
                creativeType,
            });

            const updatedElements = creativeModel.getAllElementsRecursively();
            const newMaskElement = updatedElements.filter((el) => !initialElements.includes(el));

            creativeModel.updateElement(newMaskElement[0].id, {
                position: selectedElement.position,
                dimension: selectedElement.dimension,
                duration: selectedElement.duration,
                renderOrder: selectedElement.renderOrder - 0.5,
            });

            creativeModel.updateElement(selectedElement.id, {
                mask: { elementId: newMaskElement[0].id, mode: maskMode() },
            });

            manipulationRenderer.redraw();
            manipulationRenderer.selectElement(selectedElement.id, false);
        }
    }, [selectedElement]);

    return (
        <>
            <MaskContainer>
                <Dropdown
                    trigger={({ isOpen, ...triggerProps }) => (
                        <StyledListItem
                            isDisabled={locked || disabled}
                            thumbnail={<Thumbnail icon={getDropdownIcon()} />}
                            isSelected={isOpen}
                            rightElements={<Dropdown.Arrow />}
                            {...triggerProps}
                            {...testId}
                        >
                            {(maskElement() && maskElement().name) || translate('editor.element.mask.dropdown.select')}
                        </StyledListItem>
                    )}
                >
                    <Dropdown.SearchInput
                        value={searchValue}
                        onChange={(value) => setSearch(value)}
                        placeholder={translate('editor.element.mask.dropdown.search')}
                        aria-label={translate('editor.element.mask.dropdown.search')}
                        isDisabled={locked || disabled}
                    />
                    <Dropdown.Item icon={<IconAdd />} onClick={onShapeMask}>
                        {translate('editor.element.mask.dropdown.new-shape')}
                    </Dropdown.Item>
                    {searchResults.length > 0 && <Dropdown.Divider />}
                    {searchResults.length > 0 &&
                        searchResults.map((el) => (
                            <Dropdown.Item key={el.id} icon={getElementIcon(el)} onClick={() => onMaskSelect(el)}>
                                {el.name}
                            </Dropdown.Item>
                        ))}
                </Dropdown>
                <Dropdown
                    trigger={({ isOpen, ...triggerProps }) => (
                        <Button
                            title=""
                            isPressed={isOpen}
                            {...triggerProps}
                            icon={<IconMore />}
                            variant="clean"
                            isDisabled={!maskElement() || disabled || locked}
                        />
                    )}
                    position="bottom-right"
                >
                    <Dropdown.Item onClick={onMaskEdit}>{translate('editor.element.mask.edit')}</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={onMaskDelete}>{translate('editor.element.mask.remove')}</Dropdown.Item>
                </Dropdown>
            </MaskContainer>

            {maskElement() !== null && (
                <MaskModeContainer>
                    <List.Group title={translate('editor.element.mask.mode')}>
                        <List.Item>
                            <Tooltip content={translate('editor.element.mask.mode.alpha')}>
                                <Button
                                    isPressed={maskMode() === MaskMode.ALPHA}
                                    title=""
                                    icon={<IconMaskAlt />}
                                    variant="clean"
                                    onClick={() => onMaskModeSelect(MaskMode.ALPHA)}
                                    isDisabled={!maskElement() || disabled || locked}
                                    {...generateTestId(
                                        `shots_masking__alpha${maskMode() === MaskMode.ALPHA ? '__selected' : ''}`,
                                    )}
                                />
                            </Tooltip>
                            <Tooltip content={translate('editor.element.mask.mode.alpha-inverted')}>
                                <Button
                                    isPressed={maskMode() === MaskMode.ALPHA_INVERTED}
                                    title=""
                                    icon={<IconMaskInversedAlt />}
                                    variant="clean"
                                    onClick={() => onMaskModeSelect(MaskMode.ALPHA_INVERTED)}
                                    isDisabled={!maskElement() || disabled || locked}
                                    {...generateTestId(
                                        `shots_masking__alpha_inverted${
                                            maskMode() === MaskMode.ALPHA_INVERTED ? '__selected' : ''
                                        }`,
                                    )}
                                />
                            </Tooltip>
                            <Tooltip content={translate('editor.element.mask.mode.none')}>
                                <Button
                                    isPressed={maskMode() === MaskMode.NONE}
                                    title=""
                                    icon={<IconMaskDisabled />}
                                    variant="clean"
                                    onClick={() => onMaskModeSelect(MaskMode.NONE)}
                                    isDisabled={!maskElement() || disabled || locked}
                                    {...generateTestId(
                                        `shots_masking__none${maskMode() === MaskMode.NONE ? '__selected' : ''}`,
                                    )}
                                />
                            </Tooltip>
                        </List.Item>
                    </List.Group>
                </MaskModeContainer>
            )}
        </>
    );
};

export default Masking;
