import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, List, ModalBase, RadioGroup, TextareaField } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { ReviewStatus } from 'packages/store/review/types';
import { createReview } from 'packages/store/review/review.actions';
import { getReviewState } from 'packages/store/review/review.selectors';
import { ReviewersList } from './reviewers';
import { StyledForm, StyledRadio, StyledTitle, TextMuted } from './index.styled';
import { RequestApprovalModalProps, ApprovalRequestType } from './types';
import modalContainer from 'packages/common/modalContainer';

const RequestApprovalModal = ({
    type,
    templateId,
    isOpen,
    reviewers,
    isSaving,
    handleOnClose,
    saveChanges,
}: RequestApprovalModalProps) => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const reviewState = useSelector(getReviewState);

    const [comment, setComment] = useState('');
    const [reviewStatus, setReviewStatus] = useState(
        type !== ApprovalRequestType.REQUEST ? ReviewStatus.APPROVED : null,
    );

    const onReviewRequest = () => {
        saveChanges(() => {
            dispatch(createReview(templateId, { comment, status: reviewStatus || ReviewStatus.IN_REVIEW }));
            handleOnClose();
        });
    };

    const onApprovalTypeChange = (newValue) => {
        setReviewStatus(ReviewStatus[newValue]);

        if (ReviewStatus[newValue] === ReviewStatus.EDITS_REQUESTED) {
            document.querySelector('#edits-requested-textarea')?.focus();
        }
    };

    const onCommentChange = (newValue) => {
        setComment(newValue);
    };

    const isInputValid = comment.length <= 500;

    return (
        <ModalBase
            container={modalContainer}
            isOpen={isOpen}
            onClose={handleOnClose}
            data-testid="design request approval modal"
        >
            <ModalBase.Header
                title={
                    type === ApprovalRequestType.REQUEST
                        ? translate('design.requestApproval.request.title')
                        : translate('design.requestApproval.add-review.title')
                }
            />
            <ModalBase.Content>
                {type === ApprovalRequestType.REQUEST && (
                    <StyledTitle>{translate('design.requestApproval.request.content')}</StyledTitle>
                )}
                {type === ApprovalRequestType.REVIEW && reviewStatus && (
                    <RadioGroup onChange={onApprovalTypeChange} selectedValue={reviewStatus}>
                        <StyledRadio value={ReviewStatus.APPROVED}>
                            {translate('design.requestApproval.review.approve')}
                        </StyledRadio>
                        <StyledRadio value={ReviewStatus.EDITS_REQUESTED}>
                            {translate('design.requestApproval.review.edits_requested')}
                        </StyledRadio>
                    </RadioGroup>
                )}
                {type === ApprovalRequestType.REQUEST && (
                    <List.Group title={translate('design.requestApproval.request.list.title')}>
                        <ReviewersList reviewers={reviewers} />
                    </List.Group>
                )}
                <StyledForm>
                    <TextareaField
                        id="edits-requested-textarea"
                        label={
                            <>
                                {translate('design.requestApproval.request.comment.label')}{' '}
                                <TextMuted>
                                    {translate('design.requestApproval.request.comment.label.optional')}
                                </TextMuted>
                            </>
                        }
                        placeholder={translate('design.requestApproval.request.comment.placeholder')}
                        value={comment}
                        onChange={onCommentChange}
                        isInvalid={!isInputValid}
                        data-testid="design approval request comment"
                        helperText={`${comment.length} ${translate('design.requestApproval.request.comment.counter')}`}
                    />
                </StyledForm>
            </ModalBase.Content>
            <ModalBase.Footer
                actionPrimary={
                    <Button
                        variant="primary"
                        onClick={onReviewRequest}
                        isDisabled={!isInputValid || isSaving}
                        isLoading={reviewState.saving}
                        data-testid="request approval button"
                    >
                        {type === ApprovalRequestType.REQUEST
                            ? translate('design.requestApproval.request.action')
                            : translate('design.requestApproval.review.action')}
                    </Button>
                }
                actionSecondary={
                    <Button variant="secondary" onClick={handleOnClose} data-testid="cancel approval button">
                        {translate('design.requestApproval.request.cancel')}
                    </Button>
                }
            />
        </ModalBase>
    );
};

export default RequestApprovalModal;
