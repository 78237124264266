import { useCallback, useSyncExternalStore } from 'react';
import { useSelector } from 'react-redux';
import { customerIdSelector } from 'packages/store/user/user.selectors';
import { AssetType, UploadService, UploaderState } from './types';
import { uploadAsset } from './uploadAsset';

const DEFAULT_STATE: UploaderState = {
    status: 'ready',
};

let globalState: UploaderState = DEFAULT_STATE;

let abortController: AbortController | null = null;
const subscriptions = new Set<() => void>();

const subscribe = (callback) => {
    subscriptions.add(callback);

    return () => subscriptions.delete(callback);
};

const uploadFile = async (
    file: File,
    customerId: number,
    assetTypes: AssetType[],
    collectionId?: number,
    uploadService?: UploadService,
    hideNotification?: boolean,
) => {
    abortController = new AbortController();
    const currentUploadService = uploadService || uploadAsset;

    for await (globalState of currentUploadService({
        file,
        customerId,
        abortSignal: abortController.signal,
        assetTypes,
        collectionId,
        hideNotification,
    })) {
        subscriptions.forEach((callback) => callback());
    }

    globalState = DEFAULT_STATE;
    subscriptions.forEach((callback) => callback());
    abortController = null;
};

const cancel = () => {
    abortController?.abort();
};

const useUploader = <T>({ selector }: { selector: (state: UploaderState) => T }) => {
    const customerId = useSelector(customerIdSelector);
    const state = useSyncExternalStore(subscribe, () => selector(globalState));

    const upload = useCallback(
        (
            file: File,
            assetTypes: AssetType[],
            collectionId?: number,
            uploadService?: UploadService,
            hideNotification?: boolean,
        ) => {
            uploadFile(file, customerId, assetTypes, collectionId, uploadService, hideNotification);
        },
        [customerId],
    );

    return {
        state,
        upload,
        cancel,
    };
};

export default useUploader;
