import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const TimelineButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 40px;
    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none;
    border: none;
    outline: 0;
    background-color: transparent;
    padding: 0;
    transition: background-color 0.3s ease;
    border-radius: 5px;

    &:hover:not(:disabled) {
        background-color: rgba(0, 34, 51, 0.03);
    }

    svg {
        vertical-align: unset;

        path {
            fill: rgba(0, 34, 51, 0.6);
        }
    }

    &:disabled path {
        fill: rgba(0, 34, 51, 0.3);
    }
`;

export const TimelineHeaderBtns = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TimelineHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 24px;
    background-color: ${token.white};
    box-shadow: ${token.elevationStatic}, ${token.elevation2};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: flex-start;

    ${TimelineHeaderBtns} {
        flex-grow: 1;
    }
`;

export const TimelineHeaderTracker = styled.div`
    font-size: 14px;
    line-height: 20px;
    color: ${token.colorTextMuted};
    flex: 0 0 150px;

    span {
        color: ${token.colorText};
    }
`;
