import React from 'react';
import { Button, EmptyState } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconFigma, IconMigrate } from '@bynder/icons';
import { type FigmaFileResult } from 'packages/store/figma/types';
import FigmaPages, { type FigmaPagesProps } from './FigmaPages';

type Props = {
    file: FigmaFileResult;
    switchAccount: () => void;
} & Pick<FigmaPagesProps, 'selectedFramesIds' | 'toggleFrame' | 'selectPage' | 'selectedPageId'>;

export default function FigmaFile({
    file,
    selectedPageId,
    selectedFramesIds,
    selectPage,
    toggleFrame,
    switchAccount,
}: Props) {
    const { translate } = useTranslate();

    if (file.status === 'not started' || file.status === 'loading' || file.status === 'error') {
        return null;
    }

    if (file.status === 'not found') {
        return (
            <EmptyState
                icon={<IconFigma />}
                title={translate('File not found')}
                text={translate('modal.design.create.design.figma.file.not-found.text')}
                actions={
                    <Button onClick={switchAccount} icon={<IconMigrate />}>
                        {translate('modal.design.create.design.figma.accounts.switch')}
                    </Button>
                }
            />
        );
    }

    return (
        <FigmaPages
            pages={file.value.pages}
            toggleFrame={toggleFrame}
            selectPage={selectPage}
            selectedFramesIds={selectedFramesIds}
            selectedPageId={selectedPageId}
        />
    );
}
