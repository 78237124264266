import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const TimelineTimestamps = styled.div`
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const TimelineTimestamp = styled.div`
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    color: ${token.colorTextMuted};
    align-items: center;
    justify-content: space-between;

    span {
        display: block;
        font-size: 11px;
        margin-top: 2px;
    }
`;

export const TimelineTimestampSpace = styled.div`
    flex: 0.5;
`;

export const TimelineTimestampMark = styled.div`
    width: 1px;
    height: 6px;
    background: ${token.gray200};
`;
