import { useCallback, useEffect, useState } from 'react';

export type Reviewer =
    | {
          securityIdentityType: 'USER';
          identityId: string;
          bynderUserId: string;
          firstName: string;
          lastName: string;
          email: string;
      }
    | {
          securityIdentityType: 'PROFILE';
          profileId: string;
          profileName: string;
          customerId: string;
      }
    | {
          securityIdentityType: 'GROUP';
          groupId: string;
          groupName: string;
          customerId: string;
      };

const useVirtualProperties = ({
    integrations,
    approvalEnabled: approvalEnabledDefaultValue,
    reviewers: reviewersDefaultValue = [],
}) => {
    const [exportIntegrations, setExportIntegrations] = useState({});
    const [approvalEnabled, setApprovalEnabled] = useState(approvalEnabledDefaultValue || false);
    const [reviewers, setReviewers] = useState<Reviewer[]>(reviewersDefaultValue || []);
    const [globalsTab, setGlobalsTab] = useState<'Page' | 'Design'>('Page');

    const extractInitialData = () => {
        const tempIntegrations = {};
        (integrations || []).forEach((item: any) => {
            tempIntegrations[item.channelType] = item;
        });
        setExportIntegrations(tempIntegrations);
    };

    const toggleExportIntegration = (exportName) => {
        if (exportIntegrations[exportName]) {
            exportIntegrations[exportName].enabled = !exportIntegrations[exportName].enabled;
        } else {
            exportIntegrations[exportName] = {
                channelType: exportName,
                enabled: true,
            };
        }
    };

    const changeExportValue = (newValue) => {
        if (newValue === 'DOWNLOAD_BYNDER') {
            // @ts-ignore
            setExportIntegrations({
                ...exportIntegrations,
                DOWNLOAD: {
                    channelType: 'DOWNLOAD',
                    enabled: true,
                },
                BYNDER: {
                    channelType: 'BYNDER',
                    enabled: true,
                },
            });
        } else {
            // @ts-ignore
            setExportIntegrations({
                ...exportIntegrations,
                DOWNLOAD: {
                    channelType: 'DOWNLOAD',
                    enabled: false,
                },
                BYNDER: {
                    channelType: 'BYNDER',
                    enabled: false,
                },
                [newValue]: {
                    channelType: newValue,
                    enabled: true,
                },
            });
        }
    };

    const addReviewer = useCallback<(reviewer: Reviewer) => void>((reviewer) => {
        setReviewers((prevReviewers) => [...prevReviewers, reviewer]);
    }, []);

    const removeReviewer = useCallback<(reviewer: Reviewer) => void>((reviewer) => {
        setReviewers((prevReviewers) =>
            prevReviewers.filter((item) => {
                if (reviewer.securityIdentityType === 'USER' && item.securityIdentityType === 'USER') {
                    return item.bynderUserId !== reviewer.bynderUserId;
                }

                if (reviewer.securityIdentityType === 'PROFILE' && item.securityIdentityType === 'PROFILE') {
                    return item.profileId !== reviewer.profileId || item.customerId !== reviewer.customerId;
                }

                if (reviewer.securityIdentityType === 'GROUP' && item.securityIdentityType === 'GROUP') {
                    return item.groupId !== reviewer.groupId;
                }

                return true;
            }),
        );
    }, []);

    useEffect(() => {
        extractInitialData();
    }, []);

    return {
        exportIntegrations,
        toggleExportIntegration,
        changeExportValue,
        approvalEnabled,
        setApprovalEnabled,
        reviewers,
        addReviewer,
        removeReviewer,
        globalsTab,
        setGlobalsTab,
    };
};

export default useVirtualProperties;
