import { equals } from './utils';

enum Color {
    RED = 1,
    BLACK = 2,
}

class Node {
    value: number;
    data: any[];
    left: Node | null;
    right: Node | null;
    color: Color;

    constructor(value: number, data: any) {
        this.value = value;
        this.data = [data];
        this.left = null;
        this.right = null;
        this.color = Color.BLACK;
    }
}

export class RangeTree<T> {
    root: Node | null;

    constructor() {
        this.root = null;
    }

    insert(value: number, data: T) {
        this.root = recInsert(this.root, value, data);
        this.root.color = Color.BLACK;
        return this;
    }

    remove(value: number, data: T) {
        if (!this.root) {
            return this;
        }

        this.root = recRemoveData(this.root, value, data)!;

        const newData = recGet(this.root, value);

        if (newData && newData.length === 0) {
            if (!isRed(this.root.left) && !isRed(this.root.right)) {
                this.root.color = Color.RED;
            }

            this.root = recRemoveNode(this.root, value);

            if (this.root) {
                this.root.color = Color.BLACK;
            }
        }

        return this;
    }

    update(value: number, oldData: T, newData: T) {
        this.root = recUpdate(this.root, value, oldData, newData);
        return this;
    }

    get(value: number) {
        return recGet(this.root, value);
    }

    rangeQuery(fromValue: number, toValue: number): { value: number; data: T[] }[] {
        return recRangeQuery<T>(this.root, fromValue, toValue, []);
    }

    height() {
        return recHeight(this.root);
    }

    isEmpty() {
        return !this.root;
    }

    toString() {
        const result: string[] = [];
        recToString(this.root, result);
        return result.join(', ');
    }

    asMap() {
        const result = {};
        recTreeAsMap(this.root, result);
        return result;
    }

    clean() {
        this.root = null;
    }
}

function isRed(branch: Node | null) {
    return branch && branch.color === Color.RED;
}

// Insert recursively in the tree
function recInsert(branch: Node | null, value: number, data: any) {
    if (!branch) {
        const ret = new Node(value, data);
        ret.color = Color.RED;
        return ret;
    }

    if (branch.value === value) {
        // Find node we'll add to the end of the list
        branch.data.push(data);
    } else if (branch.value > value) {
        // Target value is less than the current value we go left
        branch.left = recInsert(branch.left, value, data);
    } else if (branch.value < value) {
        branch.right = recInsert(branch.right, value, data);
    }

    if (isRed(branch.right) && !isRed(branch.left)) {
        branch = rotateLeft(branch);
    }
    if (isRed(branch.left) && isRed(branch.left!.left)) {
        branch = rotateRight(branch);
    }
    if (isRed(branch.left) && isRed(branch.right)) {
        flipColors(branch);
    }
    return branch;
}

// Search for the min node
function searchMin(branch: Node): Node {
    if (!branch.left) {
        return branch;
    }

    return searchMin(branch.left);
}

// Remove the leftmost node of the current branch
function recRemoveMin(branch: Node) {
    if (!branch.left) {
        return null;
    }

    if (!isRed(branch.left) && !isRed(branch.left.left)) {
        branch = moveRedLeft(branch);
    }

    branch.left = recRemoveMin(branch.left!);
    return balance(branch);
}

// Remove the data element for the value given
// this will not remove the node, we have to remove the empty node afterwards
function recRemoveData(branch: Node | null, value: number, data: any): Node | null {
    if (!branch) {
        // Not found
        return branch;
    }
    if (branch.value === value) {
        // Node found, we remove the data
        branch.data = branch.data.filter((it) => !equals(it, data));
        return branch;
    }
    if (branch.value > value) {
        branch.left = recRemoveData(branch.left, value, data);
        return branch;
    }
    if (branch.value < value) {
        branch.right = recRemoveData(branch.right, value, data);
        return branch;
    }
    return null;
}

function recRemoveNode(branch: Node, value: number) {
    if (value < branch.value) {
        if (!isRed(branch.left) && !isRed(branch.left!.left)) {
            branch = moveRedLeft(branch);
        }
        branch.left = recRemoveNode(branch.left!, value);
    } else {
        if (isRed(branch.left)) {
            branch = rotateRight(branch);
        }
        if (value === branch.value && !branch.right) {
            return null;
        }
        if (!isRed(branch.right) && !isRed(branch.right!.left)) {
            branch = moveRedRight(branch);
        }

        if (value === branch.value) {
            const x = searchMin(branch.right!);
            branch.value = x.value;
            branch.data = x.data;
            branch.right = recRemoveMin(branch.right!);
        } else {
            branch.right = recRemoveNode(branch.right!, value);
        }
    }
    return balance(branch);
}

// Retrieve all the data related to value
function recGet(branch: Node | null, value: number): any | null {
    if (!branch) {
        return null;
    }
    if (branch.value === value) {
        return branch.data;
    }
    if (branch.value > value) {
        return recGet(branch.left, value);
    }
    if (branch.value < value) {
        return recGet(branch.right, value);
    }
}

function recUpdate(branch: Node | null, value: number, oldData: any, newData: any): Node | null {
    if (!branch) {
        return branch;
    }
    if (branch.value === value) {
        branch.data = branch.data.map((it) => (equals(it, oldData) ? newData : it));
        return branch;
    }
    if (branch.value > value) {
        return recUpdate(branch.left, value, oldData, newData);
    }
    if (branch.value < value) {
        return recUpdate(branch.right, value, oldData, newData);
    }
    return null;
}

function recRangeQuery<T>(
    branch: Node | null,
    fromValue: number,
    toValue: number,
    result: { value: number; data: T[] }[],
) {
    if (!branch) {
        return result;
    }
    if (fromValue < branch.value) {
        recRangeQuery(branch.left, fromValue, toValue, result);
    }
    if (fromValue <= branch.value && toValue >= branch.value) {
        result.push({ value: branch.value, data: [...branch.data] });
    }
    if (toValue > branch.value) {
        recRangeQuery(branch.right, fromValue, toValue, result);
    }
    return result;
}

function rotateLeft(branch: Node) {
    const x = branch.right!;
    branch.right = x.left;
    x.left = branch;
    x.color = x.left.color;
    x.left.color = Color.RED;
    return x;
}

function rotateRight(branch: Node) {
    const x = branch.left!;
    branch.left = x.right;
    x.right = branch;
    x.color = x.right.color;
    x.right.color = Color.RED;
    return x;
}

function balance(branch: Node) {
    if (isRed(branch.right)) {
        branch = rotateLeft(branch);
    }
    if (isRed(branch.left) && isRed(branch.left!.left)) {
        branch = rotateRight(branch);
    }
    if (isRed(branch.left) && isRed(branch.right)) {
        flipColors(branch);
    }
    return branch;
}

function moveRedLeft(branch: Node) {
    flipColors(branch);
    if (branch.right && isRed(branch.right.left)) {
        branch.right = rotateRight(branch.right);
        branch = rotateLeft(branch);
        flipColors(branch);
    }
    return branch;
}

function moveRedRight(branch: Node) {
    flipColors(branch);
    if (branch.left && isRed(branch.left.left)) {
        branch = rotateRight(branch);
        flipColors(branch);
    }
    return branch;
}

function flip(color: Color) {
    return color === Color.RED ? Color.BLACK : Color.RED;
}

function flipColors(branch: Node) {
    branch.color = flip(branch.color);
    if (branch.left) {
        branch.left.color = flip(branch.left.color);
    }
    if (branch.right) {
        branch.right.color = flip(branch.right.color);
    }
}

function recHeight(branch: Node | null) {
    let curHeight = 0;
    if (branch !== null) {
        curHeight = Math.max(recHeight(branch.left), recHeight(branch.right));
    }
    return 1 + curHeight;
}

// This will return the string representation. We don't care about internal structure
// only the data
function recToString(branch: Node | null, result: string[]) {
    if (!branch) {
        return;
    }

    recToString(branch.left, result);
    result.push(`${branch.value}: [${branch.data.join(', ')}]`);
    recToString(branch.right, result);
}

// This function prints the tree structure, not the data
function printTree(tree: Node | null): string {
    if (!tree) {
        return '';
    }
    const c = { [Color.RED]: 'R', [Color.BLACK]: 'B' };
    const val = c[tree.color] + '(' + tree.value + ')';
    return '[' + printTree(tree.left) + ' ' + val + ' ' + printTree(tree.right) + ']';
}

function recTreeAsMap(branch: Node | null, result: any) {
    if (!branch) {
        return result;
    }
    recTreeAsMap(branch.left, result);
    result[branch.value] = branch.data;
    recTreeAsMap(branch.right, result);
    return result;
}
