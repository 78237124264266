import React, { useRef } from 'react';
import { DesignProvider } from './DesignContext';
import { Design } from './Design';
import VariationsProvider from './VariationsContext/VariationsContext';
import { DownloadModal } from './Batch/Download/DownloadModal';
import { UploadModal } from './Batch/Upload/UploadModal';

export default ({
    creativeType,
    creativeId,
    creativeVersionId,
    creativeName,
    template,
    fonts,
    textStyles,
    contentProperties,
    isMultiVersionCreative,
}) => {
    const downloadModalRef = useRef(null);
    const uploadModalRef = useRef(null);
    return (
        <DesignProvider
            creativeType={creativeType}
            creativeId={creativeId}
            creativeVersionId={creativeVersionId}
            creativeName={creativeName}
            template={template}
            fonts={fonts}
            textStyles={textStyles}
            contentProperties={contentProperties}
            isMultiVersionCreative={isMultiVersionCreative}
            downloadModalRef={downloadModalRef}
            uploadModalRef={uploadModalRef}
        >
            <VariationsProvider>
                <DownloadModal ref={downloadModalRef} />
                <UploadModal ref={uploadModalRef} />
                <Design />
            </VariationsProvider>
        </DesignProvider>
    );
};
