import { Input, token } from '@bynder/design-system';
import styled from 'styled-components';

// mimic placeholder style for masked input
export const LinkableInput = styled(Input)<{ linked?: boolean }>`
    & input:not(:focus) {
        color: ${(props) => props.linked && `${token.colorTextDisabled} !important`};
    }
`;

export default {};
