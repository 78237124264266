import { CORNERS, HandlerInteractionStates, SIDES, SnapLines } from '@bynder-studio/render-core';
import { isInteractiveElement } from '@bynder-studio/misc';
import BaseManipulation from '../BaseManipulation';
import SnapLinesManipulationCompositor from './SnapLinesManipulationCompositor';
import { isArrowKey } from '../../../Helpers/hotKeys';

class SnapLinesManipulation extends BaseManipulation {
    private snapLines: SnapLines = [];
    private drawSnapLinesRequestId: number = null;
    private snapLineManipulationCompositor: SnapLinesManipulationCompositor;
    private readonly redraw: (handleIndex: CORNERS | SIDES | null, state: HandlerInteractionStates | null) => void =
        null;

    constructor(compositor, containerDocument, emitter, redraw) {
        super(compositor, containerDocument, emitter);
        this.redraw = redraw;
        this.snapLineManipulationCompositor = new SnapLinesManipulationCompositor(
            this.compositor,
            this.canvas.getContext('2d'),
            this.compositor.devicePixelRatio,
        );
        this.canvas.addEventListener('keydown', this.onKeyChange);
        this.canvas.addEventListener('keyup', this.onKeyChange);
        containerDocument.addEventListener('keyup', this.onKeyUp);
        containerDocument.addEventListener('keydown', this.onKeyDown);
        containerDocument.addEventListener('mouseup', this.onMouseUp);
    }

    onMouseUp = () => {
        this.snapLines = [];

        if (this.compositor.isDragging) {
            this.compositor.showSnapLines = false;
        }
    };

    setElementSnapLines(xAxisSnapLines: SnapLines, yAxisSnapLines: SnapLines) {
        this.snapLines = [...xAxisSnapLines, ...yAxisSnapLines];
        this.requestToDrawSnapLines();
    }

    requestToDrawSnapLines() {
        if (this.drawSnapLinesRequestId) {
            cancelAnimationFrame(this.drawSnapLinesRequestId);
        }

        if (!this.compositor.showSnapLines) return;

        this.drawSnapLinesRequestId = requestAnimationFrame(() => {
            this.drawSnapLinesRequestId = 0;
            this.drawSnapLines();
        });
    }

    drawSnapLines() {
        this.snapLineManipulationCompositor.drawSnapLines(this.snapLines);
    }

    onKeyDown = (e: KeyboardEvent) => {
        if (isArrowKey(e) && !isInteractiveElement(e)) {
            // this needs to go to snapLines
            this.compositor.showSnapLines = true;
        }
    };

    onKeyChange = (e: KeyboardEvent) => {
        if (['Meta', 'Shift', 'Alt', 'Control'].includes(e.key)) {
            this.canvas.dispatchEvent(new MouseEvent('mousemove', e));
        }
    };

    onKeyUp = (e: KeyboardEvent) => {
        if (this.compositor.showSnapLines && isArrowKey(e)) {
            this.snapLines = [];
            this.compositor.showSnapLines = false;
            this.redraw(null, null);
        }
    };

    unsubscribe() {
        this.canvas.removeEventListener('keydown', this.onKeyChange);
        this.canvas.removeEventListener('keyup', this.onKeyChange);
        this.containerDocument.removeEventListener('keyup', this.onKeyUp);
        this.containerDocument.removeEventListener('keydown', this.onKeyDown);
        this.containerDocument.removeEventListener('mouseup', this.onMouseUp);
    }
}

export default SnapLinesManipulation;
