import React from 'react';
import { BaseMultiPageModel } from '@bynder-studio/render-core';
import { Button, ListItem, Thumbnail, token } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconTextStyle } from '@bynder/icons';
import generateTestId from '~/helpers/testIdHelpers';
import useManageStyleModal from '../../Shots/Text/TextStyles/modals/useManageStyleModal';

const TextStyleSettings = ({ creativeModel }: { creativeModel: BaseMultiPageModel }) => {
    const { translate } = useTranslate();
    const allTextStyles = creativeModel.getTextStyles().list;

    const { setManageStyleModalOpen, renderManageStyleModal } = useManageStyleModal({
        creativeModel,
        allStyleList: allTextStyles,
    });

    return (
        <>
            <ListItem
                thumbnail={<Thumbnail backgroundColor={token.gray50a} icon={<IconTextStyle />} />}
                rightElements={
                    <Button
                        variant="clean"
                        title={translate('editor.sidebar.globals.tab.design.text_styles.edit')}
                        isDisabled={!allTextStyles.length}
                        onClick={() => setManageStyleModalOpen(true)}
                        {...generateTestId('design settings: text styles edit button')}
                    >
                        {translate('editor.sidebar.globals.tab.design.text_styles.edit')}
                    </Button>
                }
                subtext={
                    allTextStyles.length > 0
                        ? translate('editor.sidebar.globals.tab.design.text_styles.subtext', {
                              count: allTextStyles.length,
                          })
                        : translate('editor.sidebar.globals.tab.design.text_styles.subtext.no-styles')
                }
                {...generateTestId('design settings: text styles list item')}
            >
                {translate('editor.sidebar.globals.tab.design.text_styles.title')}
            </ListItem>
            {renderManageStyleModal()}
        </>
    );
};

export default TextStyleSettings;
