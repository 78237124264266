import React, { useCallback, useState } from 'react';
import { Buffer, Card, token } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconError } from '@bynder/icons';
import { parseAspectRatio } from 'packages/pages/components/card/utils';
import { useFriendlyTime } from '~/hooks/useFriendlyTime';
import { VariationItemCard } from './VariationItem.styled';
import useExport from '../hooks/useExport';
import { CardAspectRatio } from './CardAspectRatio';
import { CardBox, ExportModalCardImage } from './Card.styled';
import { useVariationPageThumbnail } from 'packages/pages/design/VariationsContext/useVariationPageThumbnail';

const VariationCard = ({
    variationSetId,
    variationId,
    name,
    created,
    updated,
    aspectRatio,
    containsTransparency,
    isInvalid,
    pageIndex,
}) => {
    const { translate } = useTranslate();
    const { getFriendlyTime } = useFriendlyTime();
    const { selectedIds, toggleSelect } = useExport();
    const isSelected = selectedIds[variationId];
    const { variationPageThumbnail, loading } = useVariationPageThumbnail(
        variationSetId,
        pageIndex,
        variationSetId,
        300,
        300,
    );

    const handleChange = useCallback(() => {
        if (isInvalid) {
            return;
        }

        toggleSelect(variationId);
    }, [variationId, toggleSelect]);

    const [currentWidth, setCurrentWidth] = useState(0);

    const measuredRef = useCallback((node) => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const isUpdated = new Date(updated).getTime() > new Date(created).getTime();

    return (
        <CardBox
            ref={measuredRef}
            defaultHeight={Math.round(((currentWidth / parseAspectRatio(aspectRatio)) * 100) / 100)}
        >
            <VariationItemCard
                variant="clean"
                selectButtonAriaLabel={translate('modal.export.variation.label')}
                onSelect={handleChange}
                isSelected={isSelected}
                isDisabled={isInvalid}
            >
                <Card.Frame
                    height="fluid"
                    isCheckeredBackground={containsTransparency}
                    progressBar={loading && <Buffer noCornerRadius />}
                >
                    {variationPageThumbnail?.dataUrl ? (
                        <Card.Images>
                            <ExportModalCardImage src={variationPageThumbnail.dataUrl} />
                        </Card.Images>
                    ) : (
                        <CardAspectRatio aspectRatio={aspectRatio} />
                    )}
                </Card.Frame>
                <Card.Header
                    title={name}
                    detail={
                        isUpdated
                            ? translate('modal.export.variation.edited', { time: getFriendlyTime(updated) })
                            : translate('modal.export.variation.created', { time: getFriendlyTime(created) })
                    }
                    icon={isInvalid && <IconError size="16" style={{ color: token.red500 }} />}
                />
            </VariationItemCard>
        </CardBox>
    );
};

export default VariationCard;
