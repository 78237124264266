import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslate } from '@bynder/localization';
import { Grid } from 'packages/ds/Grid/Grid';
import useFluidWidth from 'packages/pages/components/grid/useFluidWidth';
import { getGallery } from 'packages/store/gallery/gallery.selectors';
import { dropImportedTemplate } from 'packages/store/importTemplate/importTemplate.actions';
import useAccessRights from 'packages/hooks/useAccessRights';
import { InfinityLoader } from 'packages/pages/components/InfinityLoader/InfinityLoader';
import filtersStorage from 'packages/hooks/filtersStorage';
import useQueryParams from 'packages/hooks/useQueryParams';
import { GALLERY_PAGE_SIZE, removeEmptyProps } from 'packages/pages/utils';
import { MediaFilterType } from 'packages/pages/components/filters/components/MediaType/types';
import EmptyFilteredState, { isFilteredList } from 'packages/pages/components/empty/EmptyFilteredState';
import Filters from 'packages/pages/components/filters';
import features from '~/configs/features';
import { fetchGallery, initializeGallery, selectGalleryItem } from '~/store/gallery/gallery.actions';
import { deselectBlankType, setPage } from 'packages/store/newDesign/newDesign.actions';
import { deselectTemplate } from '~/store/templates/templates.actions';
import useGalleryFilters from './useGalleryFilters';
import { ModalContent, StyledContentContainer } from '../../DesignCreateModal.styled';
import { GalleryTemplateCard } from 'packages/pages/components/templates/TemplateCard';
import { Template } from 'packages/store/templates/types';
import { DesignSubPages } from '../../components/Sidebar/types';

const Gallery = () => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const { isPlatformAllowed } = useAccessRights();
    const { gridContainer } = useFluidWidth();
    const { getAllQueryParams, getAllQueryParamsRaw, getQueryParam, restoreQueryParams } = useQueryParams();
    const { getFiltersFromStorage, setFiltersToStorage } = filtersStorage();
    const location = useLocation();
    const { onSearch, onMediaTypeSelect } = useGalleryFilters();
    const { templates, loadingData, fetchOptions: options, totalItems, checked } = useSelector(getGallery);

    const handleSelect = useCallback(
        (template: Template) => {
            dispatch(selectGalleryItem(template));
            dispatch(deselectBlankType(true));
            dispatch(deselectTemplate());
        },
        [dispatch],
    );

    const showPreview = useCallback(
        (template: Template) => {
            dispatch(selectGalleryItem(template));
            dispatch(setPage(DesignSubPages.GALLERY_PREVIEW));
        },
        [dispatch],
    );

    useLayoutEffect(() => {
        const storedQueries = getFiltersFromStorage('newDesign:gallery');
        restoreQueryParams(storedQueries);

        const querySearch = getQueryParam('search');
        const queriedMediaType = getQueryParam('typeDesign')?.toUpperCase();

        const queriedParams = removeEmptyProps({
            search: querySearch || '',
            creativeType: queriedMediaType?.toUpperCase() === MediaFilterType.ANY ? undefined : queriedMediaType,
        });

        dispatch(initializeGallery());
        dispatch(fetchGallery(queriedParams, false, true));
        dispatch(dropImportedTemplate());
    }, []);

    useEffect(() => {
        if (location.search.includes('page=gallery') && location.search.includes('create=true')) {
            setFiltersToStorage('newDesign:gallery', getAllQueryParams());
        }
    }, [getAllQueryParams()]);

    const onScrollGrid = () => {
        const notLastPage = templates.length < totalItems;

        if (!loadingData && notLastPage) {
            const newOptions = { ...options };
            newOptions.size = GALLERY_PAGE_SIZE;
            newOptions.page = Math.trunc(templates.length / newOptions.size);
            dispatch(fetchGallery(newOptions));
        }
    };

    const renderEmpty = () => {
        const isFiltered = isFilteredList(getAllQueryParamsRaw());

        return isFiltered ? (
            <EmptyFilteredState
                storageKey="newDesign:gallery"
                onResetFilters={() => dispatch(fetchGallery({ ...options, search: '', creativeType: undefined }))}
            />
        ) : null;
    };

    const renderGrid = () =>
        gridContainer.current &&
        gridContainer.current.clientWidth && (
            <>
                <Grid variant="masonry">
                    {templates.map((template) => (
                        <GalleryTemplateCard
                            key={template.id}
                            template={template}
                            isSelected={checked.has(template.id)}
                            onSelect={handleSelect}
                            onClick={handleSelect}
                            showPreview={showPreview}
                        />
                    ))}
                </Grid>
                <InfinityLoader
                    isLoading={loadingData}
                    count={templates.length}
                    totalItems={totalItems}
                    loadMore={onScrollGrid}
                />
            </>
        );

    return (
        <>
            <Filters>
                <Filters.FiltersLeftBlock>
                    <Filters.Search onSearch={onSearch} />
                    {isPlatformAllowed([features.IMAGES_ENABLED, features.VIDEOS_ENABLED], true) && (
                        <>
                            <Filters.FiltersDivider />
                            <Filters.MediaType value={options.creativeType} onSelect={onMediaTypeSelect} />
                        </>
                    )}
                </Filters.FiltersLeftBlock>
            </Filters>
            <ModalContent>
                <StyledContentContainer
                    id="new_design_modal_gallery_templates"
                    ref={gridContainer}
                    data-testid="design create gallery overview"
                    pageTitle={translate('modal.design.create.gallery.title')}
                >
                    {!templates.length && !loadingData ? renderEmpty() : renderGrid()}
                </StyledContentContainer>
            </ModalContent>
        </>
    );
};

export default Gallery;
