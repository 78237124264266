import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslate } from '@bynder/localization';
import ProtectedPage from 'packages/pages/components/ProtectedPage';
import { currentTemplateSelector } from 'packages/store/creativeEditor/creativeEditor.selectors';
import permissions from '~/configs/permissions';
import { PageWithAppcues } from '~/helpers/RouteWithAppcues';
import authorizationHelper from '~/helpers/AuthorizationHelper';
import CreativeEditor from './CreativeEditor';
import { colorPalettesFetching } from 'packages/store/brandColors/brandColors.selectors';
import { getColorPalettes } from 'packages/store/brandColors/colorPalettes.actions';
import { fetchCreative } from '~/store/creatives/common.actions';
import { getCreativeTemplate, resetEditor } from 'packages/store/creativeEditor/creativeEditor.actions';
import { initReview } from 'packages/store/review/review.actions';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { getCreative } from 'packages/store/creatives/creatives.selectors';

export default function TemplateEditor() {
    const dispatch = useDispatch();
    const params = useParams<{ creativeId: string }>();
    const { translate } = useTranslate();
    const template = useSelector(currentTemplateSelector);
    const creative = useSelector(getCreative);
    const isFetchingPalettes = useSelector(colorPalettesFetching);

    useEffect(() => {
        const id = params.creativeId;

        dispatch(fetchCreative(id, true));
        dispatch(getCreativeTemplate(id, false, 'creative'));
        dispatch(getColorPalettes());

        return () => {
            dispatch(resetEditor());
            dispatch(initReview()); // todo: is it necessary here?
        };
    }, [dispatch, params.creativeId]);

    const isLoaded = Boolean(template && creative && !isFetchingPalettes);

    useEffect(() => {
        dispatch(isLoaded ? hideLoading() : showLoading());
    }, [isLoaded, dispatch]);

    if (!isLoaded) {
        return null;
    }

    const hasEditPermission = authorizationHelper.hasPermissions([permissions.TEMPLATES.WRITE]);

    const getTitle = () => {
        if (!hasEditPermission) {
            // todo: is this the correct title?
            return translate('editor.approvals.locked.title');
        }

        return undefined;
    };

    const getDescription = () => {
        if (!hasEditPermission) {
            return translate('editor.no-access.desc');
        }

        return undefined;
    };

    return (
        <PageWithAppcues>
            <ProtectedPage
                isAllowed={hasEditPermission}
                redirectPath="/categories"
                title={getTitle()}
                description={getDescription()}
            >
                <CreativeEditor />
            </ProtectedPage>
        </PageWithAppcues>
    );
}
