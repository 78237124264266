import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@bynder/design-system';
import { NotificationPopupContainer } from './NotificationPopup.styled';

const NotificationPopup = ({ onClose }) => {
    const modalRef = useRef(null);
    const showTimeoutRef = useRef(null);
    const countDownRef = useRef(null);
    const disableUpdateRef = useRef(false);
    const [counter, setCounter] = useState(30);

    const handleShow = React.useCallback(() => {
        !disableUpdateRef.current
            ? modalRef.current?.classList.add('active')
            : modalRef.current?.classList.remove('active');
    }, [disableUpdateRef.current, modalRef.current]);

    const handleCountDown = React.useCallback(() => {
        setCounter((counter) => counter - 1);
    }, []);

    const handleLaterReloadBtnClick = () => {
        clearTimeout(countDownRef.current);
        disableUpdateRef.current = true;
        modalRef.current?.classList.remove('active');
        onClose();
    };

    const handleReloadBtnClick = () => {
        disableUpdateRef.current = true;
        modalRef.current?.classList.remove('active');
        clearTimeout(countDownRef.current);
        location?.reload(true);
        onClose();
    };

    useEffect(() => {
        if (!disableUpdateRef.current) {
            showTimeoutRef.current = setTimeout(handleShow, 1000);
        }

        disableUpdateRef.current && clearTimeout(showTimeoutRef.current);

        return () => {
            if (showTimeoutRef.current) {
                clearTimeout(showTimeoutRef.current);
            }
        };
    });

    useEffect(() => {
        if (counter > 0) {
            countDownRef.current = setTimeout(handleCountDown, 1000);
        }
        if (counter === 0 && !disableUpdateRef.current) {
            clearTimeout(countDownRef.current);
            location?.reload(true);
        }

        return () => {
            clearTimeout(countDownRef.current);
        };
    }, [counter, disableUpdateRef]);

    return (
        <NotificationPopupContainer ref={modalRef} className="modal shadow-sm">
            <div className="modal-dialog m-0">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5>A new version is available</h5>
                        <button type="button" className="close p-0 m-0" onClick={handleLaterReloadBtnClick}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p className="m-0">{`The page will be reloaded in ${counter} seconds, please save any changes`}</p>
                    </div>
                    <div className="modal-footer">
                        <Button variant="secondary" onClick={handleReloadBtnClick}>
                            Refresh now
                        </Button>
                        <Button variant="secondary" onClick={handleLaterReloadBtnClick}>
                            Reload later
                        </Button>
                    </div>
                </div>
            </div>
        </NotificationPopupContainer>
    );
};

export default NotificationPopup;
