import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useThunkDispatch from 'packages/hooks/useThunkDispatch';
import useAccessRights from 'packages/hooks/useAccessRights';
import { getImportedTemplate, getImportTemplateType } from 'packages/store/importTemplate/importTemplate.selectors';
import { Template } from 'packages/store/templates/types';
import { ImportedTemplate, ImportType } from 'packages/store/importTemplate/types';
import { createBlankDesign } from 'packages/store/newDesign/newDesign.actions';
import {
    createDesignFromFigma,
    createDesignsFromDesignFile,
} from 'packages/store/importTemplate/importTemplate.actions';
import { createCreative } from '~/store/creatives/creatives.actions';
import { selectedTemplateSelector } from '~/store/templates/templates.selectors';
import { selectedGallerySelector } from '~/store/gallery/gallery.selectors';
import roles from '~/configs/roles';
import { DesignSubPages } from './components/Sidebar/types';

const useCreateActionMap = (collectionId) => {
    const dispatch = useThunkDispatch();
    const { isAccountRoleAssigned } = useAccessRights();
    const selectedTemplate: Template = useSelector(selectedTemplateSelector);
    const selectedGallery: Template = useSelector(selectedGallerySelector);
    const importedTemplate: ImportedTemplate = useSelector(getImportedTemplate);
    const importType = useSelector(getImportTemplateType);

    const navigate = useNavigate();
    const location = useLocation();

    // redirects to the correct place after design creation
    const redirectDecorator =
        <T extends any[]>(create: (...args: T) => Promise<string | void>) =>
        async (...args: T) => {
            const currentPath = `${location.pathname}${location.search}`;

            const newDesignPath = await create(...args);

            if (!newDesignPath) {
                navigate(currentPath, { replace: true });
                return;
            }

            const redirectSuffix = isAccountRoleAssigned(roles.video_brand_studio.video.manage) ? '' : 'edit';
            navigate(`${newDesignPath}${redirectSuffix}`);
        };

    const handleCreateFromTemplate = redirectDecorator((template: Template | ImportedTemplate) =>
        dispatch(createCreative(template, collectionId)),
    );

    const handleCreateBlank = redirectDecorator(() => dispatch(createBlankDesign(collectionId)));

    const createActionMap = {
        [DesignSubPages.ALL]: () => {
            const template = selectedTemplate || selectedGallery;

            if (template) {
                handleCreateFromTemplate(template);
            } else {
                handleCreateBlank();
            }
        },
        [DesignSubPages.BLANK]: () => handleCreateBlank(),
        [DesignSubPages.CATEGORIES]: () => handleCreateFromTemplate(selectedTemplate),
        [DesignSubPages.CATEGORY_DETAILS]: () => handleCreateFromTemplate(selectedTemplate),
        [DesignSubPages.GALLERY]: () => handleCreateFromTemplate(selectedGallery),
        [DesignSubPages.IMPORT]: () => {},
        [DesignSubPages.IMPORT_STATUS]: () =>
            importType === ImportType.XML
                ? handleCreateFromTemplate(importedTemplate)
                : dispatch(createDesignsFromDesignFile(collectionId)),
        [DesignSubPages.TEMPLATE_PREVIEW]: () => handleCreateFromTemplate(selectedTemplate),
        [DesignSubPages.GALLERY_PREVIEW]: () => handleCreateFromTemplate(selectedGallery),
        [DesignSubPages.FIGMA_IMPORT]: () => dispatch(createDesignFromFigma(collectionId)),
    };

    return { createActionMap };
};

export default useCreateActionMap;
