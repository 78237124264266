import React, { useCallback } from 'react';
import { Dropdown } from '@bynder/design-system';
import { IconSlideshow } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import type { IsAvailable, Props } from './types';

export const isShowPreviewAvailable: IsAvailable = ({ hiddenActions }) => !hiddenActions.includes('showPreview');

export const ShowPreview = ({ template, menuActions, isPreviewDisabled }: Props & { isPreviewDisabled?: boolean }) => {
    const { translate } = useTranslate();

    const onClick = useCallback(() => menuActions.showPreview?.(template), [menuActions, template]);

    return (
        <Dropdown.Item icon={<IconSlideshow />} onClick={onClick} isDisabled={isPreviewDisabled}>
            {translate('modal.design.create.template.preview')}
        </Dropdown.Item>
    );
};
