import { isFileSizeValid, isResolutionValid } from '~/common/editor/contentSelection/utils';

type Asset = {
    width: number;
    height: number;
    files: { [key: string]: File };
    extensions: string[];
    originalUrl: string;
    fileSize: number;
    isWatermarked: boolean;
    isLimitedUse: boolean;
    isArchived: boolean;
};

type VerifiableFileData = {
    extension: string;
    isWatermarked: boolean;
    isLimitedUse: boolean;
    isArchived: boolean;
    width: number;
    height: number;
    fileSize: number;
};

const DEFAULT_TRANSFORMATION_EXT = 'jpg';

export const supportedExtensions = [
    'jpeg',
    'jpg',
    'png',
    'bmp',
    // 'tiff',
    // 'tif',
    'webp',
    // 'svg',
    // 'eps',
    // 'epsf',
    // 'epsi',
    'avi',
    'm4v',
    'mov',
    'mp4',
    'mpg',
    'webm',
    'aac',
    'aif',
    'mp3',
    'wav',
];

export enum DERIVATIVES {
    transform = 'transform',
    custom = 'custom',
}

export const getGeneratedDerivativeType = (derivativeType: string) => {
    if (derivativeType === DERIVATIVES.transform) {
        return DERIVATIVES.transform.toUpperCase();
    }

    return DERIVATIVES.custom.toUpperCase();
};

export const getDerivativeType = (asset: Asset, url: string) =>
    Object.keys(asset.files).find((fileKey) => url === asset.files[fileKey].url) || DERIVATIVES.transform;

const getExtByDerivativeType = (derivativeType: string, url: string) => {
    if (!url) {
        return '';
    }

    // URL can be without the ext at the end. If it doesn't exist -
    // we are using default UCV format.
    if (derivativeType === DERIVATIVES.transform) {
        try {
            const parsedUrl = new URL(url);
            const format = parsedUrl.searchParams.getAll('format')?.at(-1);

            return format || DEFAULT_TRANSFORMATION_EXT;
        } catch (e) {
            // if URL is not valid - we treat it as no url
            return '';
        }
    }

    return url.split('/').pop()!.split('.')[1];
};

export const getExtFromAsset = (asset: Asset): string => {
    const dotIndex = asset.originalUrl?.lastIndexOf('.') ?? -1;

    return dotIndex !== -1 ? asset.originalUrl.substring(dotIndex + 1) : asset.extensions[0];
};

export const extractVerifiableData = (asset: Asset, derivativeType: string, selectedUrl = ''): VerifiableFileData => {
    const baseProps = {
        isWatermarked: asset.isWatermarked,
        isLimitedUse: asset.isLimitedUse,
        isArchived: asset.isArchived,
    };

    if (derivativeType === DERIVATIVES.transform) {
        return {
            ...baseProps,
            width: 0,
            height: 0,
            fileSize: 0,
            extension: getExtByDerivativeType(derivativeType, selectedUrl),
        };
    }

    if (derivativeType) {
        return {
            ...baseProps,
            ...asset.files[derivativeType],
            extension: getExtByDerivativeType(derivativeType, selectedUrl),
        };
    }

    return {
        ...baseProps,
        width: asset.width,
        height: asset.height,
        fileSize: asset.fileSize,
        extension: getExtFromAsset(asset),
    };
};

export const validateFile = (file, checkUCVProps = true) => {
    const error = {
        status: 'error',
        type: 'generic',
        displayMode: 'notify',
    };

    if (file.isArchived) {
        error.type = 'archived';
        error.displayMode = 'modal';

        return error;
    }

    if (checkUCVProps) {
        if (file.isWatermarked) {
            error.type = 'watermarked';
            error.displayMode = 'modal';

            return error;
        }

        if (file.isLimitedUse) {
            error.type = 'limited';
            error.displayMode = 'modal';

            return error;
        }
    }

    if (file.extension && !supportedExtensions.includes(file.extension.toLowerCase())) {
        error.type = 'extension';

        return error;
    }

    if (!isFileSizeValid(file.fileSize)) {
        error.type = 'size';

        return error;
    }

    if (!isResolutionValid(file)) {
        error.type = 'resolution';

        return error;
    }

    return { status: 'success' };
};
