// There is no `disabled` prop for Slider from our design-system, so
// we adjust some styles for Slider to make it look disabled
// and use in Input which we can disable instead of build-in one

import React from 'react';
import { Flex } from '@bynder/design-system';
import { IconBlur } from '@bynder/icons';
import { BlurInput, BlurSlider } from './BlurField.styled';

type BlurSliderProps = {
    value: number;
    onChange: (name: 'blur', value: number) => void;
    disabled?: boolean;
};

const BlurField = ({ disabled = false, value, onChange }: BlurSliderProps) => {
    const displayValue = Math.round(value * 100);
    const min = disabled ? displayValue : 0;
    const max = disabled ? displayValue + 100 : 100;

    const handleInputChange = (newValue: number | string | [number, number]) => {
        if (Array.isArray(newValue)) {
            // should not be the case
            console.error('unexpected type of newValue, should be either string or number', newValue);

            return;
        }

        onChange('blur', Number(newValue) / 100);
    };

    return (
        <Flex gap="3">
            <BlurSlider
                min={min}
                max={max}
                value={displayValue}
                onChange={handleInputChange}
                iconLeft={<IconBlur />}
                isInactive={disabled}
                isTooltipHidden={disabled}
                disabled={disabled}
            />
            <BlurInput
                value={displayValue.toString()}
                onChange={handleInputChange}
                type="number"
                isDisabled={disabled}
            />
        </Flex>
    );
};

export default BlurField;
