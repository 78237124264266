import React, { type ReactNode, useMemo } from 'react';
import { Button, Flex } from '@bynder/design-system';
import { IconPause, IconPlay, IconReplay, IconSkipNext, IconSkipPrevious } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { EllapsedTime, TimelineHeaderContainer, TotalTime } from './TimelineHeader.styled';
import { convertFrameToTime } from '~/common/editor/timeline/helpers/frame-to-time-converter';

type Props = {
    isPlaying: boolean;
    currentFrame: number;
    togglePlayback: () => void;
    toNextShot: () => void;
    toPrevShot: () => void;
    selectedShotIndex: number;
    shots: [];
    duration: number;
    frameRate: number;
    volumeControl: ReactNode;
    className?: string;
};

const TimelineHeader = ({
    isPlaying,
    currentFrame,
    togglePlayback,
    toNextShot,
    toPrevShot,
    selectedShotIndex,
    shots,
    duration,
    frameRate,
    volumeControl,
    className = '',
}: Props) => {
    const { translate } = useTranslate();
    const convertedDuration = useMemo(() => convertFrameToTime(duration, frameRate), [duration, frameRate]);

    const isFirstFrameCurrent = currentFrame === 0;
    const isLastFrameCurrent = currentFrame === duration - 1;

    return (
        <TimelineHeaderContainer className={`${className || ''}`}>
            <Flex alignItems="center">
                <EllapsedTime>{convertFrameToTime(currentFrame, frameRate)}</EllapsedTime>
                &nbsp;/&nbsp;
                <TotalTime>{convertedDuration}</TotalTime>
            </Flex>
            <Flex alignItems="center">
                <Button
                    variant="clean"
                    title={translate('editor.timeline.shots.previous')}
                    isDisabled={isFirstFrameCurrent}
                    onClick={toPrevShot}
                    icon={<IconSkipPrevious />}
                />
                <Button
                    title={translate('editor.timeline.shots.play')}
                    onClick={togglePlayback}
                    variant="clean"
                    icon={isPlaying ? <IconPause /> : isLastFrameCurrent ? <IconReplay /> : <IconPlay />}
                />
                <Button
                    title={translate('editor.timeline.shots.next')}
                    isDisabled={
                        (!isPlaying && selectedShotIndex === shots.length - 1) ||
                        (isPlaying && selectedShotIndex === shots.length - 1)
                    }
                    onClick={toNextShot}
                    variant="clean"
                    icon={<IconSkipNext />}
                />
            </Flex>
            {volumeControl}
        </TimelineHeaderContainer>
    );
};

export default TimelineHeader;
