import React from 'react';
import { Box, CenterBlock, Divider, LeftBlock, RightBlock, Title } from './Header.styled';
import { Props } from './types';

const Header = ({ children, ...props }: Props) => (
    <Box {...props} data-testid="page header">
        {children}
    </Box>
);

export default Header;
Header.LeftBlock = LeftBlock;
Header.RightBlock = RightBlock;
Header.CenterBlock = CenterBlock;
Header.Divider = Divider;
Header.Title = Title;
