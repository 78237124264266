enum CREATIVE_TYPES {
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
}

export enum FAILURE_REASON_TYPES {
    EXCEEDING_MAX_DURATION = 'EXCEEDING_MAX_DURATION',
}

export enum STATUS {
    RENDERED = 'RENDERED',
    PENDING = 'PENDING',
    PENDING_EXTERNAL_ASSET_PROCESSING = 'PENDING_EXTERNAL_ASSET_PROCESSING',
    IN_PROGRESS = 'IN_PROGRESS',
    FAILED = 'FAILED',
}

export type PreviewRenditionType = {
    id: string;
    type: CREATIVE_TYPES;
    containerType: null;
    downloadable: boolean;
    extension: '.jpg'; // NOTE: edit type
    fileSize: number;
    name: string;
    renderProgress: number;
    renditionConfigurationId: number;
    status: STATUS;
    storageDetails: {
        bucket: string;
        filename: string;
        path: string;
    };
    storagePath: string;
    targets: string[];
    width: number;
    height: number;
};

export type VariationSetItemEntryType = {
    creativeThumbnailUrl: string;
    aspectRatio: string;
    containsTransparency: boolean;
    creativeVersionPageId: number;
    previewRendition: PreviewRenditionType;
    properties: null; // NOTE: not always null
    sizeHeight: number;
    sizeName: string;
    sizeWidth: number;
    variationId: string;
    variationSetId: string;
    hasDeletedAsset: boolean;
    failureReason: FAILURE_REASON_TYPES;
};

export type VariationSetItemType = {
    created: string;
    entries: VariationSetItemEntryType[];
    name: string;
    updated: string;
    variationSetId: string;
};

export type VariationItemBySizeIdType = {
    [key: string]: VariationSetItemEntryType;
};

export type VariationTransformedSetItemType = {
    id: string;
    created: string;
    sizes: VariationSetItemEntryType[];
    name: string;
    updated: string;
    variationSetId: string;
};
