import React, { ReactNode, useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, DescriptionList, Inspector, List, ListItem, Spinner, token } from '@bynder/design-system';
import { IconVideoComponent } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { PlaybackRenderer } from '@bynder-studio/render-web';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { useLocation } from 'react-router-dom';
import { type Template } from 'packages/store/templates/types';
import useCreativeModelFactory from 'packages/hooks/useCreativeModelFactory';
import useCreativeTemplate from 'packages/hooks/useCreativeTemplate';
import { useCreativeRenderer } from 'packages/hooks/useCreativeRenderer';
import generateTestId from '~/helpers/testIdHelpers';
import {
    CanvasContainer,
    CanvasContainerWrapper,
    Grid,
    Sidebar,
    SidebarFooter,
    StyledDescription,
    StyledHeader,
    StyledConfiguratorTimeline,
    StyledPagePreviews,
} from './Preview.styled';
import useShots from 'packages/hooks/useShots';
import usePlaybackRenderer from 'packages/hooks/usePlaybackRenderer';
import usePageHotkeys from 'packages/pages/usePageHotKeys';
import { usePagePreviews } from 'packages/hooks/usePagePreviews';
import useAccessRights from 'packages/hooks/useAccessRights';
import useQueryParams from 'packages/hooks/useQueryParams';
import { getFonts, getUserTimeZone } from 'packages/store/user/user.selectors';
import useCurrentPage from 'packages/hooks/useCurrentPage';
import SimpleVolumeControl from 'packages/pages/design/timeline/TimelineHeader/SimpleVolumeControl';
import { renderSharePermissions } from 'packages/pages/shareModal/items';
import { EntityType, IdentityRole } from 'packages/store/sharing/types';
import { getEntityGrantedPermissions, getProjectGrantedPermissions } from 'packages/store/sharing/sharing.selectors';
import { getCategories } from 'packages/store/categories/categories.selectors';
import CategoryThumbnail from 'packages/pages/categoryDetails/components/CategoryThumbnail';
import { fetchSharing } from 'packages/store/sharing/sharing.actions';
import { deselectTemplate } from '~/store/templates/templates.actions';
import { sortPermissions } from 'packages/pages/shareModal/utils';
import ProjectPermissionsDropdown from 'packages/pages/shareModal/components/ProjectPermissionsDropdown';
import roles from '~/configs/roles';
import { EmptyLayersContainer, EmptyLayersWrap } from '~/common/editor/layers/LayerTree.styled';

const testId = generateTestId('design_create_entity_preview');

const noop = () => null;

const formatData = (date: string, timeZone: string) => format(toZonedTime(date, timeZone), 'MMM dd, yyyy');

const Preview = ({
    entity,
    header,
    createDesign,
}: {
    entity?: Template;
    header?: ReactNode;
    createDesign?: () => void;
}) => {
    const { isAccountRoleAssigned } = useAccessRights();
    const { translate } = useTranslate();
    const { resetQueryParams } = useQueryParams();
    const dispatch = useDispatch();
    const canvasWrapperRef = useRef<HTMLDivElement | null>(null);
    const gridRef = useRef<HTMLDivElement | null>(null);
    const location = useLocation();

    const timeZone = useSelector(getUserTimeZone);
    const fonts = useSelector(getFonts);
    const entityGrantedPermissions = useSelector(getEntityGrantedPermissions);
    const projectGrantedPermissions = useSelector(getProjectGrantedPermissions);
    const categories = useSelector(getCategories);

    const category = categories.find((cat) => cat.id === entity?.categoryId);

    const template = useCreativeTemplate(entity?.id);
    const factory = useCreativeModelFactory(entity?.creativeType, fonts, template);
    const { pagePreviews } = usePagePreviews(factory, [180, 180]);
    const creativeModel = useMemo(() => factory?.getCreativeModel(), [factory]);
    const lastModifiedBy = template?.template?.lastModifiedBy;

    const { pageIdx, selectPageByIdx } = useCurrentPage(factory);

    const canvasRenderer = useCreativeRenderer(factory, canvasWrapperRef);
    const { duration, setPausePlayback, setPlayPlayback } = usePlaybackRenderer(factory, canvasRenderer);

    const { shots, selectShot, selectedShotIndex, toNextShot, toPrevShot } = useShots(
        template,
        canvasRenderer,
        pageIdx,
    );

    useEffect(() => {
        const wrapper = gridRef.current;
        const parent = wrapper?.parentElement;

        if (!parent) {
            return;
        }

        const onResize = () => {
            wrapper.style.height = parent.clientHeight + 'px';
        };

        onResize();

        const observer = new ResizeObserver(onResize);
        observer.observe(parent);

        return () => {
            observer.disconnect();
        };
    }, [gridRef]);

    const selectedTemplatePage = template?.template.pages[pageIdx];

    const maxHeight = selectedTemplatePage?.height || '100%';
    const maxWidth = selectedTemplatePage?.width || '100%';
    const frameRate = selectedTemplatePage?.frameRate || 25;

    const getTemplateOwner = useMemo(
        () =>
            entityGrantedPermissions.find((permission) =>
                permission.grantedPermissions.find((perm) => perm.permission === IdentityRole.OWNER),
            ),
        [entityGrantedPermissions],
    );

    useLayoutEffect(() => {
        // remove default focus from context menu to enable keyboard shortcuts
        window.setTimeout(() => document.activeElement?.blur(), 50);
    }, []);

    const handlePageChangePrevious = useCallback(() => {
        if (pageIdx > 0) {
            selectPageByIdx(pageIdx - 1);
        }
    }, [pageIdx, selectPageByIdx]);

    const handlePageChangeNext = useCallback(() => {
        if (pageIdx < pagePreviews.length - 1) {
            selectPageByIdx(pageIdx + 1);
        }
    }, [pageIdx, pagePreviews.length, selectPageByIdx]);

    usePageHotkeys({ handlePageChangePrevious, handlePageChangeNext });

    useEffect(() => {
        return () => {
            resetQueryParams(['template', 'page']);
            dispatch(deselectTemplate());

            if (location.pathname.includes('categories')) {
                resetQueryParams(['create']);
            }
        };
    }, []);

    useEffect(() => {
        if (entity?.type !== 'TEMPLATE' || !entity?.id) {
            return;
        }

        dispatch(fetchSharing(entity.id, EntityType.TEMPLATE));
    }, [category?.id, entity?.id, entity?.type]);

    return (
        <Grid ref={gridRef}>
            {header && <StyledHeader>{header}</StyledHeader>}
            {pagePreviews.length > 1 && (
                <StyledPagePreviews previews={pagePreviews} selectedIdx={pageIdx} onSelect={selectPageByIdx} />
            )}
            <CanvasContainerWrapper>
                <CanvasContainer ref={canvasWrapperRef} {...testId} style={{ maxHeight, maxWidth }}>
                    <Spinner />
                </CanvasContainer>
            </CanvasContainerWrapper>
            {canvasRenderer instanceof PlaybackRenderer && entity?.creativeType === 'VIDEO' && (
                <StyledConfiguratorTimeline
                    creativeModelFactory={factory}
                    creativeModel={creativeModel}
                    canvasRenderer={canvasRenderer}
                    shots={shots}
                    selectedShotIndex={selectedShotIndex}
                    toNextShot={toNextShot}
                    toPrevShot={toPrevShot}
                    selectShot={selectShot}
                    toggleToolbarAccess={noop}
                    duration={duration}
                    frameRate={frameRate}
                    setPausePlayback={setPausePlayback}
                    setPlayPlayback={setPlayPlayback}
                    volumeControl={<SimpleVolumeControl factory={factory} />}
                    hideZoomControl
                />
            )}
            <Sidebar>
                <Inspector.Header title={translate('modal.design.create.template.preview.details')} />
                <Inspector.Content>
                    {entity?.type === 'GALLERY' || entity?.type === 'GALLERY_GLOBAL' ? (
                        <EmptyLayersContainer>
                            <EmptyLayersWrap>
                                <IconVideoComponent size="48" style={{ color: token.gray200 }} />
                                {translate('modal.design.create.template.preview.no-content')}
                            </EmptyLayersWrap>
                        </EmptyLayersContainer>
                    ) : (
                        <>
                            <Inspector.Divider />
                            {entity?.description && (
                                <>
                                    <DescriptionList>
                                        <DescriptionList.Item>
                                            <DescriptionList.Term>
                                                {translate('modal.design.create.template.preview.description')}
                                            </DescriptionList.Term>
                                            <StyledDescription>{entity.description}</StyledDescription>
                                        </DescriptionList.Item>
                                    </DescriptionList>
                                    <Inspector.Divider />
                                </>
                            )}
                            {entity?.type === 'TEMPLATE' && (
                                <>
                                    <List>
                                        <ListItem>
                                            {translate('modal.design.create.template.preview.category')}
                                        </ListItem>
                                        <ListItem thumbnail={<CategoryThumbnail category={category ?? null} />}>
                                            {category?.name ??
                                                entity.categoryName ??
                                                translate('modal.template.move.none')}
                                        </ListItem>
                                    </List>
                                    <Inspector.Divider />
                                </>
                            )}
                            {entity?.type === 'TEMPLATE' && (
                                <>
                                    {!isAccountRoleAssigned(roles.video_brand_studio.video.manage) && (
                                        <>
                                            <Box paddingBlock="3">
                                                <Box paddingBlock="3">{translate('modal.design.share.access')}</Box>
                                                {category?.name && (
                                                    <Box paddingBlock="3">
                                                        <ProjectPermissionsDropdown
                                                            projectPermissions={sortPermissions(
                                                                projectGrantedPermissions,
                                                                'projectGrantedPermissions',
                                                            )}
                                                            projectName={
                                                                category?.name ?? translate('modal.template.move.none')
                                                            }
                                                            isPreview
                                                        />
                                                    </Box>
                                                )}
                                                <Box paddingBlock="3">
                                                    {renderSharePermissions(
                                                        entityGrantedPermissions,
                                                        translate,
                                                        EntityType.TEMPLATE,
                                                        null,
                                                    )}
                                                </Box>
                                            </Box>
                                            <Inspector.Divider />
                                        </>
                                    )}
                                    <DescriptionList>
                                        <DescriptionList.Item>
                                            <DescriptionList.Term>
                                                {translate('modal.design.create.template.preview.owner')}
                                            </DescriptionList.Term>
                                            <DescriptionList.Description>
                                                {getTemplateOwner?.securityIdentity?.firstName}{' '}
                                                {getTemplateOwner?.securityIdentity?.lastName}
                                            </DescriptionList.Description>
                                        </DescriptionList.Item>
                                        <DescriptionList.Item>
                                            <DescriptionList.Term>
                                                {translate('modal.design.create.template.preview.created')}
                                            </DescriptionList.Term>
                                            <DescriptionList.Description>
                                                {formatData(entity.created, timeZone)}
                                            </DescriptionList.Description>
                                        </DescriptionList.Item>
                                        <DescriptionList.Item>
                                            <DescriptionList.Term>
                                                {translate('modal.design.create.template.preview.modified')}
                                            </DescriptionList.Term>
                                            <DescriptionList.Description>
                                                {formatData(entity.updated, timeZone)}
                                                {lastModifiedBy &&
                                                    (lastModifiedBy?.firstName || lastModifiedBy?.lastName) &&
                                                    ' by ' + lastModifiedBy.firstName + ' ' + lastModifiedBy.lastName}
                                            </DescriptionList.Description>
                                        </DescriptionList.Item>
                                    </DescriptionList>
                                </>
                            )}
                        </>
                    )}
                </Inspector.Content>
                {createDesign && (
                    <SidebarFooter>
                        <Button variant="primary" onClick={createDesign}>
                            {translate('modal.design.create.template.preview.create')}
                        </Button>
                    </SidebarFooter>
                )}
            </Sidebar>
        </Grid>
    );
};

export default Preview;
