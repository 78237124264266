import { useEffect, useState } from 'react';
import { ElementUpdateTypes, MultiPageVideoModel } from '@bynder-studio/render-core';

const calculateTimelineDuration = (elements) =>
    elements.reduce((acc, element) => Math.max(acc, (element.startFrame || 0) + (element.duration || 0)), 0);

export default function useEditorTimeframe(creativeModel: MultiPageVideoModel | undefined, creativeType = 'video') {
    const isVideoCreative = creativeType.toLowerCase() === 'video';
    const [duration, setDuration] = useState(
        isVideoCreative ? creativeModel?.getPlaybackDuration()?.getDuration() || 0 : 0,
    );
    const [frameRate, setFrameRate] = useState<number | null>(
        isVideoCreative ? creativeModel?.getPlaybackDuration()?.getFrameRate() || 25 : null,
    );
    const [timelineDuration, setTimelineDuration] = useState(duration);

    useEffect(() => {
        if (!creativeModel || !isVideoCreative) {
            return;
        }

        setFrameRate(creativeModel.getPlaybackDuration().getFrameRate());
        setDuration(creativeModel.getPlaybackDuration().getDuration());
        setTimelineDuration(calculateTimelineDuration(creativeModel.getElements()));

        const durationUpdatedListener = () => {
            setDuration(creativeModel.getPlaybackDuration().getDuration());
        };

        const elementUpdatedListener = ({ updateTypes }) => {
            if (updateTypes.has(ElementUpdateTypes.TIMEFRAME)) {
                setTimelineDuration(calculateTimelineDuration(creativeModel.getElements()));
            }
        };

        const elementTreeUpdateListener = () => {
            setTimelineDuration(calculateTimelineDuration(creativeModel.getElements()));
        };

        creativeModel.on('durationUpdated', durationUpdatedListener);
        creativeModel.on('currentPageChange', durationUpdatedListener);
        creativeModel.on('elementUpdated', elementUpdatedListener);
        creativeModel.on('elementsTreeUpdated', elementTreeUpdateListener);

        return () => {
            creativeModel.off('durationUpdated', durationUpdatedListener);
            creativeModel.off('currentPageChange', durationUpdatedListener);
            creativeModel.off('elementUpdated', elementUpdatedListener);
            creativeModel.off('elementsTreeUpdated', elementTreeUpdateListener);
        };
    }, [creativeModel, creativeType]);

    return { frameRate, duration, timelineDuration };
}
