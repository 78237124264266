import { token, List } from '@bynder/design-system';
import { IconCheck } from '@bynder/icons';
import styled from 'styled-components';

export const DropdownButton = styled(List.Item)`
    margin-bottom: ${token.spacing5};
`;

export const DropdownList = styled.div`
    position: absolute;
    left: ${token.spacing4};
    display: flex;
    border-radius: ${token.radiusBase};
    box-shadow: ${token.elevationStatic};
    flex-direction: column;
    padding: 0 ${token.spacing5};
    background: ${token.white};
    z-index: 10;
    width: calc(100% - ${token.spacing6});
    margin-top: calc(${token.spacing4} * -1);
`;

export const DropdownListItem = styled(List.Item)`
    border-radius: 0;
    margin-left: calc(${token.spacing5} * -1);
    margin-right: calc(${token.spacing5} * -1);
`;

export const ListItemCheckMark = styled(IconCheck)`
    fill: ${token.gray800};
`;

export const Box = styled.div<{ isDisable: boolean }>`
    ${DropdownButton} {
        pointer-events: ${({ isDisable }) => (isDisable ? 'none' : 'unset')};
    }
`;
