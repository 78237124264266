import styled from 'styled-components';
import { Input, Slider } from '@bynder/design-system';

export const BlurInput = styled(Input)`
    width: 64px;

    & input {
        text-align: center;
    }
`;

export const BlurSlider = styled(Slider)<{ disabled: boolean; width?: string }>`
    width: ${(props) => props.width || '215px'};
    cursor: ${(props) => props.disabled && 'not-allowed'};

    & input {
        pointer-events: none;
    }
`;
