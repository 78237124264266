import type { IAssetsLoader } from '../../AssetLoader/IAssetsLoader';
import type { ICompModel } from '../../Models/CompModels/ICompModel';
import type { ICompElement } from '../../Models/CompModels/ICompElement';
import { GroupCompElement } from '../../Models/CompModels/Elements/GroupCompElement';
import { Dimension } from '../../Models/Shared/Dimension';
import { Position } from '../../Models/Shared/Position';
import { Region } from '../../Models/Shared/Region';
import { RegionTypes } from '../../Enums/RegionTypes';

import type { ICompositor } from '../ICompositor';
import type { ICompLayer } from '../ICompLayer';

export abstract class BaseCompositor implements ICompositor {
    protected assetLoader: IAssetsLoader;

    protected scale = 1;

    protected maxFitScale = 1;

    protected baseLayer: ICompLayer | null = null;

    public currentCompModel: ICompModel | null = null;

    // TODO: doublecheck this infinity region
    protected roi: Region = new Region(
        Number.POSITIVE_INFINITY,
        Number.POSITIVE_INFINITY,
        Number.NEGATIVE_INFINITY,
        Number.NEGATIVE_INFINITY,
    );

    protected elementRoiMap: Map<string, Region> = new Map();

    protected elements: Map<string, ICompElement> = new Map(); // TODO: move this logic to compmodel;

    // Debugging and stats
    protected debug = false;

    protected debugNoLayerCrop = false;

    static layerAreasCreated = 0;

    static layerAreaPxCreated = 0;

    constructor(assetLoader: IAssetsLoader) {
        this.assetLoader = assetLoader;
    }

    abstract createBaseLayer(compModel: ICompModel): ICompLayer;

    abstract createElementLayer(compModel: ICompElement, type: string, allowCrop: boolean): ICompLayer;

    abstract drawAtomicElementOnLayer(el: ICompElement, layer: ICompLayer, force: boolean): void;

    abstract setCompositorDimension(dimension: Dimension): void;

    abstract clearFrame(): void;

    abstract requestToDrawCompModel(compModel: ICompModel): void;

    abstract setPanPosition(position: Position): void;

    abstract getPanPosition(): Position;

    abstract getCompositorWrapperDimension(): Dimension;

    getCompositorWrapperPadding(): number {
        return 0;
    }

    abstract setDraggingId(draggingId: number | null): void;

    // TODO: Check if the following are necessary
    abstract getDataUrl(): string;

    abstract getDpr(): number;

    getAssetLoader(): IAssetsLoader {
        return this.assetLoader;
    }

    setScale(scale: number) {
        this.scale = scale;
    }

    getScale() {
        return this.scale;
    }

    setMaxFitScale(scale: number) {
        this.maxFitScale = scale;
    }

    getMaxFitScale() {
        return this.maxFitScale;
    }

    getCompositorDimension() {
        return this.baseLayer ? this.baseLayer.getDimension() : new Dimension(0, 0);
    }

    drawCompModel(compModel: ICompModel): void {
        this.currentCompModel = compModel;
        this.printDebugInfo(['Drawing compmodel frame: ', compModel.getFrame()]);
        this.printDebugInfo(compModel);

        const startTime = performance.now();

        this.prepareDraw(compModel);

        if (this.baseLayer) {
            this.baseLayer.resetTransform();
            this.baseLayer.clear(compModel.getBackgroundColor());
        }

        // TODO: Added filtering by only one id for testing!!
        compModel.getCompElements().forEach((el, idx) => {
            this.drawCompElement(el);
        });

        this.cleanupDraw();

        const endTime = performance.now();

        this.printDebugInfo(
            `Drew ${BaseCompositor.layerAreasCreated} areas with ${(
                BaseCompositor.layerAreaPxCreated / 1000000
            ).toFixed(2)} Megapixels`,
        );
        this.printDebugInfo(
            `Drew frame ${compModel.getFrame()} in ${(endTime - startTime).toFixed(2)} ms \
                    scale ${this.scale.toFixed(2)}`,
        );
    }

    /**
     * Create layer for element and merge it to the base layer
     * @param el
     */
    drawCompElement(el: ICompElement) {
        if (!this.baseLayer) {
            return;
        }

        this.baseLayer.save();

        // translate layer to content position before drawing
        this.baseLayer.applyTranslation(
            el.boundingBox.position.x + el.contentBox.position.x,
            el.boundingBox.position.y + el.contentBox.position.y,
        );
        this.drawElementOnLayer(el, this.baseLayer);

        this.baseLayer.restore();
    }

    prepareDraw(compModel: ICompModel) {
        BaseCompositor.layerAreasCreated = 0;
        BaseCompositor.layerAreaPxCreated = 0;

        // TODO: manage this from outside
        this.roi = new Region(0, 0, compModel.getDimension().getWidth(), compModel.getDimension().getHeight());

        // TODO: just clear and update permissions, instead of recreating
        if (this.baseLayer) {
            this.baseLayer.delete();
        }

        this.baseLayer = this.createBaseLayer(compModel);

        // register mask layers
        // TODO: move this logic to compmodel
        this.elementRoiMap = new Map();
        this.elements = new Map();

        const processElements = (el: ICompElement | ICompElement[]) => {
            if (Array.isArray(el)) {
                el.forEach((childEl) => processElements(childEl));
            } else {
                this.elements.set(String(el.id), el);

                if (el instanceof GroupCompElement) {
                    processElements(el.getChildren());
                }
            }
        };
        processElements(compModel.getCompElements());
    }

    /**
     * Draw an element onto a layer
     * @param el CompElement to draw
     * @param layer Layer transformed to content origin
     * @param force Force drawing even if element is not visible
     */
    drawElementOnLayer(el: ICompElement, layer: ICompLayer, force = false): void {
        this.printDebugInfo([
            `Compositing element ${el.id} onto layer ${layer.id} with transform ${layer.getTransform()}`,
        ]);

        if (!this.isElementVisible(el)) {
            return;
        }

        if (!force && this.currentCompModel?.isElementHiddenBecauseOfMask(el.id)) {
            return;
        }

        layer.save();

        this.drawAtomicElementOnLayer(el, layer, force);

        layer.restore();
    }

    /**
     * Check if element is visible in the current viewport (this.roi)
     */
    protected isElementVisible(el: ICompElement) {
        const roi = el.getAbsoluteRegion(RegionTypes.EFFECT);

        if (!roi) {
            return false;
        }

        // TODO: Improve it with the pan position for not drawing invisible elements
        const roiIntersect = this.roi.getIntersection(roi);
        const elWidth = el.contentBox.dimension.getHeight();
        const elHeight = el.contentBox.dimension.getWidth();

        if (roiIntersect === null || elWidth <= 0 || elHeight <= 0 || el.scale <= 0) {
            this.printDebugInfo([
                `Element ${el.id} will not be drawn with width ${elWidth} and height ${elHeight} and scale ${el.scale}`,
            ]);

            return false;
        }

        return true;
    }

    protected getMaskLayer(elementId: string) {
        const maskElement = this.elements.get(elementId);

        if (maskElement) {
            // NOTE: Here it tricky moment, we need to leave the layer as big as it is,
            // because of possible using dropshadow
            const layer = this.createElementLayer(maskElement, RegionTypes.EFFECT, false);

            if (!layer) {
                return null;
            }

            this.drawElementOnLayer(maskElement, layer, true);

            return layer;
        }

        return null;
    }

    /**
     * Make sure we dispose all layers and free memory
     */
    protected cleanupDraw() {}

    /**
     * Shorthand scale function, apply to everything related to coordinates
     */
    protected s(n: number): number {
        return n * this.scale;
    }

    protected printDebugInfo(data: any): void {
        if (this.debug) {
            if (Array.isArray(data)) {
                data.forEach((d) => console.log(d));
                console.log('---');
            } else {
                console.log(data);
            }
        }
    }
}
