import React, { useEffect, useState } from 'react';
import {
    BaseMultiPageModel,
    IBaseMultiPageModel,
    ImageElement,
    ShapeElement,
    TextElement,
    VideoElement,
} from '@bynder-studio/render-core';
import { Form, List, Thumbnail } from '@bynder/design-system';
import { IconGrayscale } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { getDropShadowValueFromElement } from '~/common/editor/helpers';
import { rgbaToObject, colorToCSS } from '~/common/editor/editorHelper';
import generateTestId from '~/helpers/testIdHelpers';
import { rgbToHex } from '../../FormComponents/ColorPicker/utils';
import ShadowColor from './Color';
import ShadowAngle from './Angle';
import ShadowOffset from './Offset';
import ShadowBlur from './Blur';
import { StyledList } from './index.styled';

type Props = {
    disabled: boolean;
    selectedElement: TextElement | ImageElement | VideoElement | ShapeElement;
    creativeModel: BaseMultiPageModel<IBaseMultiPageModel>;
};

const Shadow = ({ disabled, selectedElement, creativeModel }: Props) => {
    const { translate } = useTranslate();
    const { id, locked } = selectedElement;
    const elementDropShadow = getDropShadowValueFromElement(selectedElement);
    const [isActive, setIsActive] = useState(getDropShadowValueFromElement(selectedElement).state === 'ENABLE');

    const [value, setValue] = useState(elementDropShadow);

    const selectedShadowColor = colorToCSS(elementDropShadow.color);
    const selectedShadowOpacity = (rgbaToObject(selectedShadowColor).opacity * 100).toFixed(0);

    const handleOnChange = (key, newValue) => {
        const param = {
            dropShadow: {
                ...value,
                [key]: newValue,
            },
        };

        if (key === 'fillColor') {
            param.dropShadow.color = newValue;
        } else {
            param.dropShadow.color = getDropShadowValueFromElement(selectedElement).color;
        }

        if (key === 'state') {
            param.dropShadow.state = newValue === true ? 'ENABLE' : 'DISABLE';
        }

        creativeModel.updateElement(id, param);
    };

    const onToggle = () => {
        setIsActive(!isActive);
        handleOnChange('state', !isActive);
    };

    useEffect(() => {
        setValue(getDropShadowValueFromElement(selectedElement));
    }, [id, selectedElement, selectedElement.dropShadow]);

    useEffect(() => {
        setIsActive(getDropShadowValueFromElement(selectedElement).state === 'ENABLE');
    }, [id, selectedElement]);

    const listItem = generateTestId('shots_styling_shadow');

    return (
        <StyledList>
            <List.Item
                checkedVariant="switch"
                onClick={onToggle}
                isChecked={isActive}
                isDisabled={disabled || locked}
                subtext={
                    isActive ? `${rgbToHex(selectedShadowColor).toUpperCase()} • ${selectedShadowOpacity}%` : 'None'
                }
                thumbnail={<Thumbnail icon={<IconGrayscale />} />}
                {...listItem}
            >
                {translate('editor.sidebar.shots.shadow.label')}
            </List.Item>
            {isActive && (
                <>
                    <Form.Row>
                        <Form.Group>
                            <ShadowColor
                                disabled={disabled || locked}
                                onChange={handleOnChange}
                                selectedElement={selectedElement}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group>
                            <Form.Label>{translate('editor.sidebar.shots.shadow.angle.label')}</Form.Label>
                            <ShadowAngle
                                disabled={disabled || locked}
                                onChange={handleOnChange}
                                selectedElement={selectedElement}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{translate('editor.sidebar.shots.shadow.offset.label')}</Form.Label>
                            <ShadowOffset
                                disabled={disabled || locked}
                                onChange={handleOnChange}
                                selectedElement={selectedElement}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group>
                            <Form.Label>{translate('editor.sidebar.shots.shadow.blur.label')}</Form.Label>
                            <ShadowBlur
                                disabled={disabled || locked}
                                onChange={handleOnChange}
                                selectedElement={selectedElement}
                            />
                        </Form.Group>
                    </Form.Row>
                </>
            )}
        </StyledList>
    );
};

export default Shadow;
