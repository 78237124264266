import styled from 'styled-components';
import { Button } from '@bynder/design-system';

export const AlignmentItem = styled.div`
    width: calc(100% / 3);
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const AlignmentButton = styled(Button)`
    width: 100%;
`;
