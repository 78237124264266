import React from 'react';

import { CommonJobFnType, ExportJobItemType } from './types';

import CompletedJobRow from './CompletedJobRow';

type Props = {
    jobs: ExportJobItemType[];
    onSelect: CommonJobFnType;
    onDelete: CommonJobFnType;
};

const CompletedJobBlock = ({ jobs, onSelect, onDelete }: Props) => {
    if (!jobs.length) {
        return null;
    }

    return jobs.map((job) => <CompletedJobRow key={job.id} job={job} onSelect={onSelect} onDelete={onDelete} />);
};

export default CompletedJobBlock;
