export class MouseTracker {
    private startX = 0;

    private startY = 0;

    private curX = 0;

    private curY = 0;

    private active = false;

    isActive() {
        return this.active;
    }

    setDown(x: number, y: number) {
        this.startX = x;
        this.curX = x;
        this.startY = y;
        this.curY = y;
        this.active = true;
    }

    setMove(x: number, y: number) {
        this.curX = x;
        this.curY = y;
    }

    setUp(x: number, y: number) {
        this.curX = x;
        this.curY = y;
        this.active = false;
    }

    getArea(): [number, number, number, number] {
        return [this.startX, this.startY, this.curX, this.curY];
    }
}
