import React from 'react';
import { format } from 'date-fns-tz';
import { Button, Dropdown, EmptyState, Flex, ListItem, Thumbnail } from '@bynder/design-system';
import { IconFigmaColor, IconMigrate, IconMore } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { Connection } from './types';
import { Dot } from './figma.styled';
import generateTestId from '~/helpers/testIdHelpers';

type Props = {
    connection: Connection;
    login: () => void;
    isLoading: boolean;
    switchAccount: () => void;
    setRevokeAccessModalOpen: (isOpen: boolean) => void;
};

const listItemTestId = generateTestId('figma_import_logged_in');
const listItemDropdownTestId = generateTestId('figma_import_account_options');
const emptyStateTestId = generateTestId('figma_import_empty_state');

export default function FigmaConnection({
    connection,
    login,
    isLoading,
    switchAccount,
    setRevokeAccessModalOpen,
}: Props) {
    const { translate } = useTranslate();

    const onRevokeAccess = () => {
        setRevokeAccessModalOpen(true);
    };

    if (!connection) {
        return null;
    }

    if (!connection.connected) {
        return (
            <Flex justifyContent="center" alignItems="center">
                {!connection?.connected && (
                    <EmptyState
                        icon={<IconFigmaColor size={48} />}
                        title={translate('modal.design.create.design.figma.connect.title')}
                        text={translate('modal.design.create.design.figma.connect.text')}
                        actions={
                            <Button variant="primary" onClick={login} isDisabled={isLoading} isLoading={isLoading}>
                                {translate('modal.design.create.design.figma.connect.button')}
                            </Button>
                        }
                        {...emptyStateTestId}
                    />
                )}
            </Flex>
        );
    }

    const connectedSubText = connection?.connected ? (
        <>
            {connection.email}
            <Dot>•</Dot>
            {translate('modal.design.create.design.figma.created-on')}{' '}
            {format(connection.created ? new Date(`${connection.created}Z`) : '', "EEEE, MMMM d, yyyy 'at' h:mm a")}
        </>
    ) : (
        ''
    );

    return (
        <ListItem
            thumbnail={<Thumbnail icon={<IconFigmaColor />} />}
            subtext={connectedSubText}
            rightElements={
                <Dropdown
                    trigger={({ isOpen, ...triggerProps }) => (
                        <Button
                            isPressed={isOpen}
                            isFullWidth
                            variant="clean"
                            icon={<IconMore />}
                            {...triggerProps}
                            title=""
                        />
                    )}
                    position="bottom-right"
                    {...listItemDropdownTestId}
                >
                    <Dropdown.Item icon={<IconMigrate />} onClick={switchAccount}>
                        {translate('modal.design.create.design.figma.accounts.switch')}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={onRevokeAccess}>
                        {translate('modal.design.create.design.figma.access.revoke')}
                    </Dropdown.Item>
                </Dropdown>
            }
            {...listItemTestId}
        >
            {translate('modal.design.create.design.figma.connected')}
        </ListItem>
    );
}
