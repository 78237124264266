import * as React from 'react';
import { Button, Form, Tooltip } from '@bynder/design-system';
import { IconArrowUp, IconArrowDown, IconTitleCase } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { ButtonTextWrapper } from './index.styled';
import generateTestId from '~/helpers/testIdHelpers';

type Props = {
    open?: boolean;
    onChange: (val: boolean) => void;
};

const testId = generateTestId('shots_text_more_text_options');

const MoreTextOptions = ({ open, onChange }: Props) => {
    const { translate } = useTranslate();
    const label = translate(
        open
            ? 'editor.sidebar.shots.more_text_options.less.label'
            : 'editor.sidebar.shots.more_text_options.more.label',
    );

    const tooltipContent = translate(
        open
            ? 'editor.sidebar.shots.more_text_options.less.tooltip'
            : 'editor.sidebar.shots.more_text_options.more.tooltip',
    );

    return (
        <Form.Group>
            <Tooltip content={tooltipContent} position="top-right">
                <Button
                    icon={<IconTitleCase />}
                    variant="clean"
                    isFullWidth
                    onClick={() => onChange(!open)}
                    rightIcon={open ? <IconArrowUp /> : <IconArrowDown />}
                    {...testId}
                >
                    <ButtonTextWrapper>
                        <span>{label}</span>
                    </ButtonTextWrapper>
                </Button>
            </Tooltip>
        </Form.Group>
    );
};

export default MoreTextOptions;
