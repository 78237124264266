import React, { useCallback } from 'react';
import { InfiniteScrollContainer } from './InfiniteScroll.styled';

type Props = {
    handleLoadMore: () => void;
    hasMore: boolean;
    loading: boolean;
    inverse: boolean;
};

const InfiniteScroll = (props: Props) => {
    const { handleLoadMore, hasMore, loading, inverse } = props;

    const loadMore = hasMore && !loading;

    const handleVerticalScroll = useCallback(
        (event) => {
            if (!loadMore) return;
            const { scrollTop, scrollHeight, clientHeight } = event.target;
            const t = scrollTop / (scrollHeight - clientHeight);
            if ((inverse && t === 0) || (!inverse && t > 0.998)) {
                handleLoadMore();
            }
        },
        [loadMore, inverse],
    );

    return (
        <InfiniteScrollContainer onScroll={(e) => handleVerticalScroll(e)}>{props.children}</InfiniteScrollContainer>
    );
};

export default InfiniteScroll;
