import React, { useEffect, useState } from 'react';
import { useTranslate } from '@bynder/localization';
import { IconVideoFrame } from '@bynder/icons';
import { Form } from '@bynder/design-system';
import editorAutocorrect from 'packages/pages/editor/EditorAutocorrectRules';
import FramePicker from '../../FormComponents/FramePicker';
import { FormSectionContent } from '../../index.styled';

const PosterFrameInput = ({ creativeModel, frameRate, disabled, id }) => {
    const { translate } = useTranslate();
    const [posterFrame, setPosterFrame] = useState(creativeModel?.getPosterFrame());

    const handlePosterFrameChange = (newValue) =>
        creativeModel.updateGlobalProperty('POSTER_FRAME', { frame: newValue });

    const autocorrect = (newValue) => {
        const duration = creativeModel?.getPlaybackDuration()?.getDuration();

        return editorAutocorrect('global_poster_frame', newValue, duration);
    };

    useEffect(() => {
        setPosterFrame(creativeModel?.getPosterFrame());
    }, [creativeModel?.getPosterFrame()]);

    return (
        <FormSectionContent onSubmit={(event) => event.preventDefault()}>
            <Form.Group>
                <Form.Label htmlFor="global_poster_frame">
                    {translate('editor.sidebar.globals.poster_frame.title')}
                </Form.Label>
                <FramePicker
                    id={id}
                    name="posterFrame"
                    handleOnChange={handlePosterFrameChange}
                    autocorrect={autocorrect}
                    value={posterFrame.frame}
                    frameRate={frameRate}
                    disabled={disabled}
                    testId="global_poster_frame"
                    icon={<IconVideoFrame />}
                    label={posterFrame.name}
                    htmlFor="global_poster_frame"
                    describedby="global_poster_frame_message"
                />
                <Form.HelperText id="global_poster_frame_message">
                    {translate('editor.sidebar.globals.frame.message')}
                </Form.HelperText>
            </Form.Group>
        </FormSectionContent>
    );
};

export default PosterFrameInput;
