import ProxyServerConnector from 'packages/web/src/services/connectors/ProxyServerConnector';

class EditorService extends ProxyServerConnector {
    constructor(path = '/creatives') {
        super(path);
        this.gateway = new ProxyServerConnector(path, GATEWAY_PREFIX);
    }

    getCreativeTemplate(creativeId, lastPublish) {
        return this.gateway.get(`/v2/${creativeId}/editor?lastPublish=${lastPublish}`);
    }

    discardCreativeTemplate(creativeId) {
        return this.delete(`/v2/${creativeId}`).then(({ json, status }) => {
            return { status, json };
        });
    }
}

export const CreativeEditorService = new EditorService();
export default {
    CreativeEditorService,
};
