export default class Cache<T> {
    // we could use a WeakMap if the keys were objects with a single reference
    #cache: Map<string, T> = new Map();

    #size: number;

    constructor(size: number) {
        this.#size = size;
    }

    set(key: string, value: T) {
        this.#cache.set(key, value);

        if (this.#cache.size > this.#size) {
            // Delete first item from cache to prevent
            // cache taking up too much memory.
            this.#cache.delete(this.#cache.keys().next().value);
        }
    }

    get(key: string) {
        return this.#cache.get(key);
    }

    has(key: string) {
        return this.#cache.has(key);
    }
}
