import { useCallback, useEffect, useRef } from 'react';
import { useDragEvents } from './useDragEvents';
import { isCtrlKey } from '~/helpers/hotKeys';

const padding = 4;
const height = 44;

const checkIfNewTrack = (clientY, mouseTop, containerBoundingBoxRef) => {
    const threshold = padding / height;
    const maxMouseTop = containerBoundingBoxRef.current.bottom;

    let x = (mouseTop.current / height) % 1;
    x = x < 0.5 ? x : 1 - x;

    return x < threshold && clientY < maxMouseTop - 10;
};

type ElementPosition = {
    top: number;
    left: number;
};

// TODO: Refactor both dragging and resizing hooks, a lot of spaghetti code
export const useDragging = ({
    elementRef,
    containerRef,
    scrollContainerRef,
    tracksRef,
    onDragCb,
    onDrop,
    onCancel = () => {},
    handleMouseUp,
}) => {
    const boundingBoxRef = useRef(null);
    const containerBoundingBoxRef = useRef(null);
    const isDragging = useRef(false);
    const mouseTop = useRef<number | null>(null);
    const clientYRef = useRef(0);
    const timeoutRef = useRef<NodeJS.Timeout | number>(0);
    const isBetweenTracks = useRef(false);
    const initialPositionOfElement = useRef<ElementPosition | null>(null);
    const elementCloneRef = useRef<Element | null>(null);
    const rafId = useRef<number | null>(null);
    const lastMove = useRef([0, 0]);
    const eventCanceled = useRef(false);

    const getScroll = useCallback(
        () => [scrollContainerRef?.current?.scrollLeft ?? 0, scrollContainerRef?.current?.scrollTop ?? 0],
        [scrollContainerRef],
    );
    const initialScroll = useRef(getScroll());

    const onDragStart = () => {
        boundingBoxRef.current = elementRef.current.getBoundingClientRect();
        containerBoundingBoxRef.current = containerRef.current.getBoundingClientRect();
        elementCloneRef.current = elementRef.current.cloneNode(true);
        initialScroll.current = getScroll();

        const initialLeftPercentage =
            ((boundingBoxRef.current.left - containerBoundingBoxRef.current.left) /
                containerBoundingBoxRef.current.width) *
            100;
        initialPositionOfElement.current = {
            top: boundingBoxRef.current.top - containerBoundingBoxRef.current.top,
            left: initialLeftPercentage,
        };

        timeoutRef.current = setTimeout(() => {
            isDragging.current = true;
            elementRef.current.classList.add('dragging');
            elementCloneRef.current?.classList?.add('ghost-element');

            containerRef.current.insertBefore(elementCloneRef.current, elementRef.current);
            containerRef.current.parentNode.classList.add('dragging');
            tracksRef.current.classList.add('dragging');
        }, 170);
    };

    const onDrag = useCallback(
        (deltaX, deltaY, evt) => {
            lastMove.current = [deltaX, deltaY];

            const elementTop = boundingBoxRef.current.top - containerBoundingBoxRef.current.top;
            const elementLeft = boundingBoxRef.current.left - containerBoundingBoxRef.current.left;
            const newScroll = getScroll();
            const scrollDiff = [newScroll[0] - initialScroll.current[0], newScroll[1] - initialScroll.current[1]];

            const maxTop = containerBoundingBoxRef.current.height - boundingBoxRef.current.height;
            const maxLeft = containerBoundingBoxRef.current.width - boundingBoxRef.current.width;
            const top = Math.min(Math.max(elementTop + deltaY + scrollDiff[1], 0), maxTop);
            const left = Math.min(Math.max(elementLeft + deltaX + scrollDiff[0], 0), maxLeft);

            if (evt) {
                clientYRef.current = evt.clientY;
            }

            mouseTop.current = clientYRef.current - containerBoundingBoxRef.current.top + scrollDiff[1];
            isBetweenTracks.current = checkIfNewTrack(clientYRef.current, mouseTop, containerBoundingBoxRef);

            rafId.current = requestAnimationFrame(() => {
                if (isDragging.current && elementRef.current) {
                    elementRef.current.style.top = `${top}px`;
                    elementRef.current.style.left = `${left}px`;
                }
            });

            if (isDragging.current) {
                onDragCb(left, boundingBoxRef.current);
            }
        },
        [elementRef, getScroll, onDragCb],
    );

    const onDragEnd = (evt: MouseEvent) => {
        if (eventCanceled.current) {
            eventCanceled.current = false;

            return;
        }

        if (!isDragging.current) {
            clearTimeout(timeoutRef.current);

            if (rafId.current) {
                cancelAnimationFrame(rafId.current as number);
            }

            handleMouseUp(evt);

            return;
        }

        const boundingBox = elementRef.current.getBoundingClientRect();
        const { clientX, clientY } = evt;

        elementCloneRef.current?.remove();
        containerRef.current.parentNode.classList.remove('dragging');
        tracksRef.current.classList.remove('dragging');

        setTimeout(() => {
            const elementUnderCursor = document.elementFromPoint(clientX, clientY);
            const closestElement = elementUnderCursor?.closest('.timeline__element');

            if (!closestElement && !elementUnderCursor) {
                elementRef.current.style.top = `${boundingBoxRef.current.top - containerBoundingBoxRef.current.top}px`;
                elementRef.current.style.left = `${
                    boundingBoxRef.current.left - containerBoundingBoxRef.current.left
                }px`;
                elementRef.current.classList.remove('dragging');
                isDragging.current = false;

                return;
            }

            elementRef.current.style.top = `${initialPositionOfElement.current.top}px`;

            const groupId = elementUnderCursor.dataset.groupId || (closestElement && closestElement.dataset.groupId);

            const isCtrlHeld = isCtrlKey(evt);
            const onGroup = closestElement && closestElement.dataset.isGroup === 'true';
            const inGroup =
                elementUnderCursor.dataset.groupId || (closestElement && !onGroup && !!closestElement.dataset.groupId);
            const isExpanded = closestElement && closestElement.dataset.isExpanded === 'true';
            const onElement = !onGroup && closestElement && closestElement.dataset.id;

            const left = boundingBox.left - containerBoundingBoxRef.current.left;
            const leftPercentage = 100 / (containerBoundingBoxRef.current.width / left);

            elementRef.current.classList.remove('dragging');
            isDragging.current = false;
            initialPositionOfElement.current = null;

            if (!mouseTop.current) {
                handleMouseUp(evt);

                return;
            }

            const dropParams = {
                groupId,
                isCtrlHeld,
                onGroup,
                inGroup,
                isExpanded,
                onElement,
            };

            onDrop(mouseTop.current, leftPercentage, dropParams, isBetweenTracks.current);
        });
    };

    const cleanUp = useCallback(() => {
        clearTimeout(timeoutRef.current);

        if (rafId.current) {
            cancelAnimationFrame(rafId.current as number);
        }

        elementRef.current.classList.remove('dragging');
        elementRef.current.style.top = `${initialPositionOfElement.current?.top}px`;
        initialPositionOfElement.current = null;

        elementCloneRef.current?.remove();
        containerRef.current.parentNode.classList.remove('dragging');
        tracksRef.current.classList.remove('dragging');
    }, []);

    const onDragCancel = useCallback(() => {
        isDragging.current = false;
        eventCanceled.current = true;

        onCancel();
        cleanUp();
    }, [onCancel, cleanUp]);

    useEffect(() => {
        const onScroll = () => {
            if (isDragging.current) {
                onDrag(...lastMove.current);
            }
        };

        scrollContainerRef.current?.addEventListener('scroll', onScroll);
        const ref = scrollContainerRef.current;

        return () => {
            ref?.removeEventListener('scroll', onScroll);
        };
    }, [onDrag, scrollContainerRef, lastMove]);

    const [onMouseDown] = useDragEvents({ onDragStart, onDrag, onDragEnd, cancelCb: onDragCancel });

    return [isDragging, onMouseDown];
};
