import React from 'react';
import { LoadingDots, Dot } from './DotLoader.styled';

const DotLoader = () => (
    <LoadingDots>
        <Dot />
        <Dot />
        <Dot />
    </LoadingDots>
);

export default DotLoader;
