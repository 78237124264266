import styled from 'styled-components';
import { CheckboxGroup, Dropdown } from '@bynder/design-system';

export const ExportSettingsOption = styled(Dropdown.ListItem)`
    > div span {
        white-space: unset;
    }
`;

export const StyledCheckboxGroup = styled(CheckboxGroup)`
    position: relative;
`;
