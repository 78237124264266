import styled from 'styled-components';

export const DraggableStyled = styled.div`
    position: relative;

    .layers--item-droppable {
        position: absolute;
        height: 1px;
        width: 100%;
        top: 18.5px;
    }
`;
