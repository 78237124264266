import React from 'react';
import { UnifiedCommonСolor, ExtendedColorParams } from '@bynder-studio/misc';
import { useSelector } from 'react-redux';
import { Dropdown, List, Thumbnail, Form } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconArrowDown } from '@bynder/icons';
import { colorToCSS } from '~/common/editor/editorHelper';
import { hexToRgb } from 'packages/pages/editor/RightSideMenu/FormComponents/ColorPicker/utils';
import { colorPalettes } from 'packages/store/brandColors/brandColors.selectors';

type Props = {
    title: string;
    handleColorClick: (color: ExtendedColorParams) => void;
    elementId: number;
    colors: ExtendedColorParams[];
    selectedColor: UnifiedCommonСolor;
    isDisabled: boolean;
};

const ShotColor = ({ title, handleColorClick, elementId, colors, selectedColor, isDisabled }: Props) => {
    const { translate } = useTranslate();
    const { palettes } = useSelector(colorPalettes);
    const selectedPalette = palettes.find((palette) =>
        palette.brandColors.some((color) => color.id === selectedColor.id),
    );
    const paletteName = selectedPalette?.name;

    return (
        <Form.Group>
            <Form.Label htmlFor={elementId}>{title}</Form.Label>
            <Dropdown
                position="bottom"
                trigger={({ isOpen, ...triggerProps }) => (
                    <List.Item
                        subtext={paletteName ? paletteName : translate('editor.sidebar.shots.color.brand_colors.label')}
                        isDisabled={isDisabled}
                        isSelected={isOpen}
                        thumbnail={
                            <Thumbnail
                                variant="content"
                                size="s"
                                shape="circle"
                                backgroundColor={hexToRgb(selectedColor.hexCode, selectedColor.opacity)}
                            />
                        }
                        rightElements={<IconArrowDown />}
                        {...triggerProps}
                    >
                        {selectedColor?.name || `#${selectedColor.hexCode.toUpperCase()}`}
                    </List.Item>
                )}
            >
                <Dropdown.SectionTitle>
                    {translate('editor.sidebar.shots.color.brand_colors.label')}
                </Dropdown.SectionTitle>
                {colors.map((color) => (
                    <Dropdown.Item
                        key={color.brandColorId}
                        isChecked={color.brandColorId === selectedColor.id}
                        icon={
                            <Thumbnail variant="content" shape="circle" size="xs" backgroundColor={colorToCSS(color)} />
                        }
                        onClick={() => handleColorClick(color)}
                    >
                        {color.name}
                    </Dropdown.Item>
                ))}
            </Dropdown>
        </Form.Group>
    );
};

export default ShotColor;
