import React, { useCallback } from 'react';
import { Button, InlineBanner, Thumbnail, token } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import useDesign from 'packages/pages/design/hooks/useDesign';
import MaybeTooltip from 'packages/common/MaybeTooltip';
import { FILE_NAME, TYPE_AND_SIZE_DOT_SEPARATOR } from '~/helpers/textConstants';
import ExportTabContainer from './ExportTab.styled';

import { download, makeUrlToDownload } from '../../utils';
import {
    ExportItemRowWrapper,
    ExportItemRow,
    ExportItemSubtitle,
    ExportItemSubtitleSeparator,
    ExportSectionTitle,
    InlineBannerWr,
    SyledList,
} from '../AllExports.styled';
import useExport from '../../hooks/useExport';
import { IconDownload, IconDownloadDisabled, IconImage, IconVideo } from '@bynder/icons';

const ExportTab = ({ job }) => {
    const { creativeName: designName } = useDesign();
    const { isDownloadBlocked } = useExport();
    const { translate } = useTranslate();
    const items = job.jobVariations;

    const getRendition = (renditions) => {
        const rendition = renditions.find(
            (renditionItem) =>
                renditionItem && renditionItem.renditionConfigurationId === job.renditionConfiguration.id,
        );

        if (rendition) {
            return rendition;
        }

        return renditions?.find((renditionItem) => renditionItem.type === job.renditionConfiguration.type);
    };

    const handleDownloadClick = useCallback(
        (jobVariationId) => {
            return () => {
                download(makeUrlToDownload(job.id, { jobVariationId }))();
            };
        },
        [job.id],
    );

    return (
        <>
            {isDownloadBlocked && (
                <InlineBannerWr>
                    <InlineBanner variant="info">{translate('modal.export.downloads.blocked-info')}</InlineBanner>
                </InlineBannerWr>
            )}
            <ExportTabContainer>
                <div>
                    <ExportSectionTitle className="export--job-title">{FILE_NAME}</ExportSectionTitle>
                </div>
                <div>
                    {items?.map(({ id, variation }) => {
                        const { renditions, creativeVersionPage, name: variationName } = variation;
                        const { type: renditionType, fileSize, extension, width, height } = getRendition(renditions);
                        const parsedSize = (fileSize / 1000000).toFixed(2);
                        const extensionParsed = extension?.replace('.', '')?.toUpperCase();
                        const icon = renditionType.toLowerCase() === 'video' ? <IconVideo /> : <IconImage />;

                        return (
                            <ExportItemRowWrapper className="w-100">
                                <ExportItemRow hoverdisabled="true">
                                    <SyledList
                                        key={id}
                                        fullWidth
                                        thumbnail={<Thumbnail icon={icon} backgroundColor={token.gray50a} />}
                                        subtext={
                                            <ExportItemSubtitle>
                                                {extensionParsed}
                                                <ExportItemSubtitleSeparator>
                                                    {TYPE_AND_SIZE_DOT_SEPARATOR}
                                                </ExportItemSubtitleSeparator>
                                                {width} &times; {height} px
                                                {fileSize && (
                                                    <>
                                                        <ExportItemSubtitleSeparator>
                                                            {TYPE_AND_SIZE_DOT_SEPARATOR}
                                                        </ExportItemSubtitleSeparator>
                                                        <span className="parsed--size">{parsedSize} MB</span>
                                                    </>
                                                )}
                                            </ExportItemSubtitle>
                                        }
                                        rightElements={
                                            <MaybeTooltip
                                                content={translate('modal.export.downloads.download')}
                                                hasTooltip={!isDownloadBlocked}
                                            >
                                                <Button
                                                    isDisabled={isDownloadBlocked}
                                                    title=""
                                                    variant="clean"
                                                    onClick={handleDownloadClick(id)}
                                                    icon={
                                                        isDownloadBlocked ? <IconDownloadDisabled /> : <IconDownload />
                                                    }
                                                />
                                            </MaybeTooltip>
                                        }
                                    >
                                        {designName} - {variationName} - {creativeVersionPage.name}
                                    </SyledList>
                                </ExportItemRow>
                            </ExportItemRowWrapper>
                        );
                    })}
                </div>
            </ExportTabContainer>
        </>
    );
};

export default ExportTab;
