import { clone } from 'rambda';

type Properties = {
    hidden: boolean;
};

export type BaseProp<TId, TValue = unknown> = {
    propertyName: string;
    value: TValue;
    templateElementId: TId;
};

const filterElementsRecursively = <TElement extends { id: TId; properties: Properties }, TId extends number>(
    elements: Record<TId, TElement>,
    selectedSize: { properties: BaseProp<TId>[] },
) => {
    let obj = {};
    Object.values(elements).forEach((el: any) => {
        const { hidden, allowToggleVisibility } = el.properties;
        let hiddenProp;
        if (selectedSize.properties) {
            hiddenProp = selectedSize.properties.find(
                (prop) => prop.propertyName === 'hidden' && prop.templateElementId === el.id,
            ) as BaseProp<TId, boolean> | undefined;
        }

        if ((hiddenProp?.value ?? hidden) && !allowToggleVisibility) return;

        if (el.children) {
            obj = { ...obj, ...filterElementsRecursively(el.children, selectedSize) };
        } else {
            obj[el.id] = el;
        }
    });

    return obj;
};

// eslint-disable-next-line import/prefer-default-export
export const getVisibleElements = <TElement extends { id: TId; properties: Properties }, TId extends number>(
    pages: { properties: BaseProp<TId>[]; elements: Record<TId, TElement> }[],
    selectedPageIdx: number,
) => {
    const elements = pages[selectedPageIdx].elements;
    const selectedPageSize = pages[selectedPageIdx];

    return filterElementsRecursively(clone(elements), selectedPageSize);
};
