import { MaskModeTypes } from '../Enums/MaskModeTypes';

export const resolverMaksModeTypes = (oldValue: MaskModeTypes, newValue: MaskModeTypes): MaskModeTypes => {
    if (oldValue === MaskModeTypes.NONE) {
        return newValue;
    }
    if (newValue === MaskModeTypes.NONE) {
        return oldValue;
    }
    return newValue;
};

export class Ranges<T> {
    private points: { id: string; time: number; type: 'start' | 'end'; value: T }[];
    private resolver: (oldValue: T, newValue: T) => T = (oldValue: T, newValue: T) => newValue;

    constructor() {
        this.points = [];
    }

    setResolver(resolver: (oldValue: T, newValue: T) => T) {
        this.resolver = resolver;
    }

    add(id: string, start: number, end: number, value: T) {
        this.points.push(
            {
                time: start,
                type: 'start',
                value,
                id,
            },
            {
                time: end,
                type: 'end',
                value,
                id,
            },
        );
    }

    get(time: number): T | null {
        const pointsWithSingle = this.points.filter(
            (p) => (p.type === 'start' && p.time <= time) || (p.type === 'end' && p.time > time),
        );
        const duplicates = new Map<string, number>();
        pointsWithSingle.forEach((p) => {
            if (duplicates.has(p.id)) {
                duplicates.set(p.id, duplicates.get(p.id)! + 1);
            } else {
                duplicates.set(p.id, 1);
            }
        });
        const points = pointsWithSingle
            .filter((p) => duplicates.get(p.id) === 2 && p.type === 'start')
            .sort((a, b) => a.time - b.time);

        if (!points.length) {
            return null;
        }
        const point = points.shift()!;
        let val = point.value;

        points.forEach((p) => {
            val = this.resolver(val, p.value);
        });
        return val;
    }
}
