import { useCallback, useMemo, useState } from 'react';
import { type CreativeTemplate } from './useCreativeTemplate';
import { PlaybackRenderer, type CreativeModelFactory } from '@bynder-studio/render-web';
import { getShotsFromPageAccordingDuration } from '~/helpers/template';

const EMPTY_SHOTS = [];

export default function useShots(
    template: CreativeTemplate | null,
    canvasRenderer: ReturnType<CreativeModelFactory['getCreativeRenderer']> | null,
    pageIndex = 0,
) {
    const [selectedShotIndex, setSelectedShotIndex] = useState(0);

    const selectedTemplatePage = template?.template.pages[pageIndex];

    const shots = useMemo(() => {
        if (!selectedTemplatePage) {
            return EMPTY_SHOTS;
        }

        return getShotsFromPageAccordingDuration(selectedTemplatePage);
    }, [selectedTemplatePage]);

    const selectShot = useCallback(
        (index: number, shouldSetFrame = true) => {
            const adjustedIndex = Math.max(0, index);
            if (!(canvasRenderer instanceof PlaybackRenderer) || adjustedIndex >= shots.length) {
                return;
            }

            if (shots[adjustedIndex] && shouldSetFrame) {
                canvasRenderer.setCurrentFrame(shots[adjustedIndex].startFrame);
            }

            setSelectedShotIndex(adjustedIndex);
        },
        [shots, canvasRenderer],
    );

    const toNextShot = useCallback(() => {
        selectShot(selectedShotIndex + 1);
    }, [selectedShotIndex, selectShot]);

    const toPrevShot = useCallback(() => {
        selectShot(selectedShotIndex - 1);
    }, [selectedShotIndex, selectShot]);

    return {
        shots,
        selectedShotIndex,
        selectShot,
        toNextShot,
        toPrevShot,
    };
}
