import React from 'react';
import { Divider } from '@bynder/design-system';
import { Content, Right } from './Filters.styled';
import { Search } from './Search';
import { Pages } from './Pages';
import { GroupBy } from './GroupBy';
import { OrderBy } from './OrderBy';
import { View } from './View';
import { VariationSets } from './VariationSets';
import useExport from '../hooks/useExport';

export const Filters = () => {
    const { groupBy } = useExport();

    return (
        <Content id="export_modal_filters">
            <Search />
            <Divider direction="vertical" />
            {groupBy === 'page' && <VariationSets />}
            {groupBy === 'variation' && <Pages />}
            <Right>
                <GroupBy />
                <OrderBy />
                <Divider direction="vertical" />
                <View />
            </Right>
        </Content>
    );
};
