import type { ImageElement, VideoElement } from '@bynder-studio/render-web';

type Head<T extends string> = T extends `${infer First}.${string}` ? First : T;
type Tail<T extends string> = T extends `${string}.${infer Rest}` ? Rest : never;

// looks rather complicated, but it's just a recursive type that picks the properties from the given path
// havind such a type allows us testing utility functions without having to mock the whole elements
type DeepPick<T, K extends string> = T extends object
    ? {
          [P in Head<K> & keyof T]: T[P] extends readonly unknown[]
              ? DeepPick<T[P][number], Tail<Extract<K, `${P}.${string}`>>>[]
              : DeepPick<T[P], Tail<Extract<K, `${P}.${string}`>>>;
      }
    : T;

type Element = DeepPick<ImageElement | VideoElement, 'id' | 'virtualData.allowPersonalUpload'>;
type ElementId = Element['id'];
type ElementData = Pick<Element, 'virtualData'>;
type CreativeModel = {
    updateElement: (id: ElementId, payload: ElementData) => void;
};

export const isPersonalUploadsAllowed = (element: ElementData) => element.virtualData.allowPersonalUpload;
export const createOnTogglePersonalUploads = (creativeModel: CreativeModel, element: Element) => {
    const { virtualData } = element;
    creativeModel.updateElement(element.id, {
        virtualData: { ...virtualData, allowPersonalUpload: !virtualData.allowPersonalUpload },
    });
};
