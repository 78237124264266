export enum MediaFilterType {
    VIDEO = 'VIDEO',
    IMAGE = 'IMAGE',
    AUDIO = 'AUDIO',
    ANY = 'ANY',
}
export type MediaTypeFilterProps = {
    value?: MediaFilterType;
    onSelect: (type: MediaFilterType) => void;
    isDisabled?: boolean;
    showAudio?: boolean;
};
