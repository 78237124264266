import React, { FC } from 'react';
import { Button, Modal } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import generateTestId from '~/helpers/testIdHelpers';
import modalContainer from 'packages/common/modalContainer';

type PublishModalProps = {
    variationCount: number;
    open: boolean;
    loading: boolean;
    onClose: () => void;
    onPublish: () => void;
};

const PublishModal: FC<PublishModalProps> = ({ variationCount, open, loading, onClose, onPublish }) => {
    const { translate } = useTranslate();

    const actionId = generateTestId('editor_header_publish_action');
    const cancelId = generateTestId('editor_header_publish_cancel');

    return (
        <Modal
            container={modalContainer}
            title={translate('editor.header.publish.modal.title')}
            isCloseButtonHidden={loading}
            onClose={onClose}
            isOpen={open}
            actionSecondary={
                <Button
                    variant="secondary"
                    onClick={onClose}
                    isDisabled={loading}
                    {...cancelId}
                    {...{ id: 'editor_header_publish_cancel' }}
                >
                    {translate('editor.header.publish.modal.cancel')}
                </Button>
            }
            actionPrimary={
                <Button
                    variant="primary"
                    onClick={onPublish}
                    isLoading={loading}
                    isDisabled={loading}
                    {...actionId}
                    {...{ id: 'editor_header_publish_action' }}
                >
                    {loading
                        ? translate('editor.header.publish.modal.publishing')
                        : translate('editor.header.publish.modal.publish')}
                </Button>
            }
        >
            {translate('editor.header.publish.modal.desc', { count: variationCount })}
        </Modal>
    );
};

export default PublishModal;
