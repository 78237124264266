import { useContext } from 'react';
import { ShotsContext } from '~/contexts/ShotsContext';
import shotDefault from '../../../../src/assets/images/shot.png';

export function useShots() {
    return useContext(ShotsContext);
}

export default function useShot(index) {
    const { shots, shotsImages } = useContext(ShotsContext);

    return {
        shot: shots[index],
        image: shotsImages[index] || shotDefault,
    };
}
