import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import FigmaService from 'packages/services/FigmaService';
import { catchResponseError } from 'packages/helpers/helpers';
import { ResponseModel } from '~/services/connectors/ResponseModel';
import { hostUrlSelector } from 'packages/store/platform/platform.selectors';

const REDIRECT_PATH = '#/designs?create=true&page=figma_import';

type ActiveRequests = {
    login?: Promise<ResponseModel>;
    connection?: Promise<ResponseModel>;
    switch?: Promise<ResponseModel>;
    revokeAccess?: Promise<ResponseModel>;
};

export default function useFigmaConnection() {
    const hostUrl = useSelector(hostUrlSelector);
    const [connection, setConnection] = useState<
        | null
        | { connected: false; message: string }
        | { connected: true; figmaUserId: string; email: string; created: string }
    >(null);

    const [activeRequests, setActiveRequests] = useState<ActiveRequests>({});
    const isLoading = useMemo(() => Object.values(activeRequests).some((req) => !!req), [activeRequests]);

    const login = useCallback(() => {
        const req = FigmaService.getLoginUrl(`${hostUrl}${REDIRECT_PATH}`);
        setActiveRequests((prev) => ({ ...prev, login: req }));

        req.then((res) => {
            if (res.status >= 400) {
                throw new Error(JSON.stringify(res.json, null, 2));
            }

            window.open(res.json.url, '_parent');
        }).catch((err) => {
            catchResponseError(err);
            setActiveRequests((prev) => ({ ...prev, login: undefined }));
        });
    }, [hostUrl]);

    const fetchConnectionStatus = useCallback(() => {
        const connectionReq = FigmaService.getConnectionStatus();
        setActiveRequests((prev) => ({ ...prev, connection: connectionReq }));

        connectionReq
            .then((res) => {
                if (res.status >= 400) {
                    setConnection(null);
                    throw new Error(JSON.stringify(res.json, null, 2));
                }

                setConnection(res.json);
            })
            .catch(catchResponseError)
            .finally(() => {
                setActiveRequests((prev) => ({ ...prev, connection: undefined }));
            });
    }, []);

    const revokeAccess = useCallback(() => {
        const req = FigmaService.revokeAccess();
        setActiveRequests((prev) => ({ ...prev, revokeAccess: req }));

        req.then(() => {
            fetchConnectionStatus();
        })
            .catch(catchResponseError)
            .finally(() => {
                setActiveRequests((prev) => ({ ...prev, revokeAccess: undefined }));
            });
    }, [fetchConnectionStatus]);

    const switchAccount = useCallback(() => {
        const revokeReq = FigmaService.revokeAccess();
        setActiveRequests((prev) => ({ ...prev, switch: revokeReq }));

        revokeReq
            .then(() => {
                const loginReq = FigmaService.getLoginUrl(`${hostUrl}${REDIRECT_PATH}`);
                setActiveRequests((prev) => ({ ...prev, switch: loginReq }));

                return loginReq;
            })
            .then((res) => {
                if (res.status >= 400) {
                    throw new Error(JSON.stringify(res.json, null, 2));
                }

                const loginUrl = res.json.url;
                const switchUserUrl = loginUrl.replace('oauth?', 'switch_user?cont=/oauth?');
                const figmaUrl = 'https://www.figma.com/switch_user?cont=/oauth?client_id=';

                window.open(`${figmaUrl}${encodeURIComponent(switchUserUrl.split(figmaUrl)[1])}`, '_parent');
            })
            .catch((err) => {
                catchResponseError(err);
                setActiveRequests((prev) => ({ ...prev, switch: undefined }));
            });
    }, [hostUrl]);

    useEffect(() => {
        fetchConnectionStatus();
    }, [fetchConnectionStatus]);

    return {
        fetchConnectionStatus,
        connection,
        login,
        revokeAccess,
        switchAccount,
        isLoading,
    };
}
