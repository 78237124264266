import { mat2d, vec2 } from 'gl-matrix';
import { Region } from '../Models/Shared/Region';
import { Position } from '../Models/Shared/Position';

/**
 * Transform a source region around a centerPosition and translate back to a destination region
 * @param sourceRegion
 * @param destinationRegion
 * @param centerPosition
 * @param rotation
 * @param scale
 * @returns mat2d
 */
const getRegionTransform = (
    sourceRegion: Region,
    destinationRegion: Region,
    centerPosition: Position,
    rotation: number,
    scale: number,
) => {
    let transform = mat2d.create();

    if (scale != 1 || rotation) {
        //translate from effect region (0,0) to the center position
        mat2d.translate(transform, transform, [
            centerPosition.x - sourceRegion.left,
            centerPosition.y - sourceRegion.top,
        ]);

        //apply rotation and scale
        if (rotation) {
            mat2d.rotate(transform, transform, rotation * (Math.PI / 180));
        }

        if (scale != 1) {
            mat2d.scale(transform, transform, [scale, scale]);
        }

        //translate back to content region
        mat2d.translate(transform, transform, [
            destinationRegion.left - centerPosition.x,
            destinationRegion.top - centerPosition.y,
        ]);
    } else {
        //only translate from effect to content region
        mat2d.translate(transform, transform, [
            destinationRegion.left - sourceRegion.left,
            destinationRegion.top - sourceRegion.top,
        ]);
    }

    return transform;
};

/**
 * Create transformation by translating (to centerpoint), rotating and scaling, and then translating back
 * @param translateX
 * @param translateY
 * @param rotation
 * @param scale
 * @param postTranslateX
 * @param postTranslateY
 * @returns mat2d
 */
const getSrcDestTransform = (
    translateX: number,
    translateY: number,
    rotation: number,
    scale: number,
    postTranslateX: number = 0,
    postTranslateY: number = 0,
): mat2d => {
    let transform = mat2d.create();

    if (scale != 1 || rotation) {
        //translate to the center position
        mat2d.translate(transform, transform, [translateX, translateY]);

        //apply rotation and scale
        if (rotation) {
            mat2d.rotate(transform, transform, rotation * (Math.PI / 180));
        }

        if (scale != 1) {
            mat2d.scale(transform, transform, [scale, scale]);
        }

        //apply post translation
        mat2d.translate(transform, transform, [postTranslateX, postTranslateY]);
    } else {
        //only apply translations
        mat2d.translate(transform, transform, [translateX + postTranslateX, translateY + postTranslateY]);
    }

    return transform;
};

/**
 * Get absolute Region after transforming a bounding box
 * for the original bounding box a position of 0, 0 is assumed.
 * @param {mat2d} transform The mat2d transformation matrix
 * @param {*} width The original width
 * @param {*} height The original height
 * @returns {Region} The bounding box region of the transformed region
 */
const getTransformedRegion = (transform: mat2d, width: number, height: number) => {
    const tl = vec2.transformMat2d(vec2.create(), [0, 0], transform);
    const tr = vec2.transformMat2d(vec2.create(), [width, 0], transform);
    const bl = vec2.transformMat2d(vec2.create(), [0, height], transform);
    const br = vec2.transformMat2d(vec2.create(), [width, height], transform);
    return new Region(
        Math.min(tl[0], tr[0], bl[0], br[0]),
        Math.min(tl[1], tr[1], bl[1], br[1]),
        Math.max(tl[0], tr[0], bl[0], br[0]),
        Math.max(tl[1], tr[1], bl[1], br[1]),
    );
};

/**
 *
 * @param transform
 * @param region {Region}
 */
const transformRegion = (transform: mat2d, region: Region) => {
    const tl = vec2.transformMat2d(vec2.create(), [region.left, region.top], transform);
    const tr = vec2.transformMat2d(vec2.create(), [region.right, region.top], transform);
    const bl = vec2.transformMat2d(vec2.create(), [region.left, region.bottom], transform);
    const br = vec2.transformMat2d(vec2.create(), [region.right, region.bottom], transform);

    return new Region(
        Math.min(tl[0], tr[0], bl[0], br[0]),
        Math.min(tl[1], tr[1], bl[1], br[1]),
        Math.max(tl[0], tr[0], bl[0], br[0]),
        Math.max(tl[1], tr[1], bl[1], br[1]),
    );
};

export { getRegionTransform, getSrcDestTransform, getTransformedRegion, transformRegion };
