import { AnimationTimingFunctionTypes } from '../../Enums/AnimationTimingFunctionTypes';
import { ValueOf } from '../../types';
import * as timingFns from './AnimationTimingFunctions';

export const getTimingFunction = (timing: { value: string; type: string }): ValueOf<typeof timingFns> => {
    if (!timing) {
        //no timing is defined in case of data import
        return timingFns.linear;
    }

    switch (timing.value) {
        case AnimationTimingFunctionTypes.LINEAR: {
            return timingFns.linear;
        }

        case AnimationTimingFunctionTypes.EASE: {
            return timingFns.ease;
        }

        case AnimationTimingFunctionTypes.EASE_IN_OUT: {
            return timingFns.easeInOut;
        }

        case AnimationTimingFunctionTypes.EASE_IN_MINIMUM: {
            return timingFns.easeInMinimum;
        }

        case AnimationTimingFunctionTypes.EASE_IN_MEDIUM: {
            return timingFns.easeInMedium;
        }

        case AnimationTimingFunctionTypes.EASE_IN_MAXIMUM: {
            return timingFns.easeInMaximum;
        }

        case AnimationTimingFunctionTypes.EASE_OUT_MINIMUM: {
            return timingFns.easeOutMinimum;
        }

        case AnimationTimingFunctionTypes.EASE_OUT_MEDIUM: {
            return timingFns.easeOutMedium;
        }

        case AnimationTimingFunctionTypes.EASE_OUT_MAXIMUM: {
            return timingFns.easeOutMaximum;
        }

        default: {
            return timingFns.linear;
        }
    }
};
