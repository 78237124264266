import ProxyServerConnector from './connectors/ProxyServerConnector';

class VariationSetsService extends ProxyServerConnector {
    gateway: ProxyServerConnector;

    constructor(path = '/variation-sets') {
        super(path);
        this.gateway = new ProxyServerConnector(path, GATEWAY_PREFIX);
    }

    /**
     * @param creativeVersionId {string|number}
     * @param params {{
     *     [sizes]: string[],
     *     [variationSets]: string[],
     *     [name]: string,
     *     [orderBy]: 'updated' | 'name',
     *     [groupBy]: 'variation' | 'size',
     *     [sortingOrder]: 'ASC' | 'DESC',
     *     [includeProperties]: boolean,
     *     [page]: number,
     *     [limit]: number,
     * }}
     *
     * @return {Promise<ResponseModel & {json: {
     *     items: Object[],
     *     totalItems: number,
     * }}>}
     */
    fetchVariationSetsByAggregate(creativeVersionId, params) {
        const searchParams = new URLSearchParams({ creativeVersionId });

        Object.entries(params).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach((val) => {
                    searchParams.append(key, val);
                });
            } else if (key === 'groupBy' && value === 'page') {
                searchParams.append(key, 'page');
            } else {
                searchParams.append(key, value);
            }
        });

        return this.gateway.get(`/aggregate?${searchParams.toString()}`);
    }

    deleteVideos(ids) {
        return this.gateway.delete(`?${new URLSearchParams({ variationSetIds: ids }).toString()}`);
    }

    deleteVariationSets(ids) {
        return this.deleteVideos(ids);
    }

    updateVideo(id, data) {
        return this.gateway.put(`/${id}`, data);
    }

    renameVariationSet(id, newName) {
        return this.updateVideo(id, { name: newName });
    }

    /**
     * @param creativeId {string|number}
     * @param fileType {'CSV' | 'EXCEL'}
     * @param [variationSetIds] {string[]}
     *
     * @return {Promise<ResponseModel & {status:number}>}
     */
    batchDownload(creativeId, fileType, variationSetIds = []) {
        const searchParams = new URLSearchParams({ fileType });
        variationSetIds.forEach((variationSetId) => {
            searchParams.append('variationSetIds', variationSetId);
        });

        return this.gateway.download(`/batch/${creativeId}/download?${searchParams.toString()}`);
    }

    /**
     * @param creativeId {string|number}
     * @param data {FormData}
     *
     * @return {Promise<ResponseModel & {status:number, json: {
     *     items: Object[],
     *     totalItems: number,
     *     [error]: string,
     *     [message]: string,
     *     [appErrorCode]: number,
     * }}>}
     */
    batchUpload(creativeId, data) {
        return this.gateway.post(`/batch/${creativeId}/upload`, data);
    }

    addCreativeVariation(creativeId, name, creativeVersionPageNamedProperties) {
        return this.gateway.post(`/v3/${creativeId}/generate`, { name, creativeVersionPageNamedProperties });
    }

    addBatchCreativeVariations(creativeId, data) {
        return this.gateway.post(`/v3/${creativeId}/batch-generate`, data);
    }

    saveCreativeVariation(creativeId, variationId, name, creativeVersionPageNamedProperties) {
        return this.gateway.put(`/v3/${creativeId}/variations/${variationId}`, {
            name,
            creativeVersionPageNamedProperties,
        });
    }

    saveVariationsOrdering(variations: { id: string; name: string; displayOrder: number }) {
        return this.gateway.put(`/batch-update`, variations);
    }

    translateVariation(variationId: string, languages: { languages: string[] }) {
        return this.gateway.post(`/${variationId}/translate`, languages);
    }
}

export default new VariationSetsService();
