import { notify } from '@bynder/design-system';
import CreativesService from '~/services/CreativesService';
import { catchResponseError } from 'packages/helpers/helpers';
import * as types from '~/store/types';

export function fetchCreative(id, inEditor = false) {
    return (dispatch) => {
        if (!inEditor) {
            dispatch({ type: types.GET_CREATIVE_REQUEST });
        }

        CreativesService.fetchCreative(id)
            .then(({ status, creative }) => {
                if (status === 200) {
                    const sortable = [] as any;

                    if (creative.data_schema) {
                        for (const key in creative.data_schema.properties) {
                            sortable.push([key, creative.data_schema.properties[key].displayOrder]);
                        }
                    }

                    sortable.sort((a, b) => a[1] - b[1]);
                    const dynamicFields = [] as any;
                    sortable.forEach((value) => dynamicFields.push(value[0]));
                    dispatch({ type: types.GET_CREATIVE_SUCCESS, dynamicFields, creative });
                } else {
                    notify({
                        title: 'Oops! Something went wrong. Please refresh your page.',
                        variant: 'error',
                    });
                }
            })
            .catch(catchResponseError);
    };
}
