import React, { type FC } from 'react';
import { Button, Tooltip } from '@bynder/design-system';
import { IconWritingDirectionLTR, IconWritingDirectionRTL } from '@bynder/icons';
import { RightBox } from 'packages/pages/design/components/TextElement/Component.styled';
import { useTranslate } from '@bynder/localization';
import { useTextElementDirection } from 'packages/pages/design/hooks/useTextElementDirection';
import { Direction } from '@bynder-studio/structured-text';

export type TextDirectionProps = {
    elementId: number;
};

export const TextDirection: FC<TextDirectionProps> = ({ elementId }) => {
    const { translate } = useTranslate();
    const { handleDirectionChange, direction } = useTextElementDirection(elementId);

    return (
        <RightBox>
            <Tooltip position="top-right" content={translate('editor.sidebar.shots.text.direction.switch')}>
                <Button
                    isSmall
                    onClick={handleDirectionChange}
                    variant="clean"
                    icon={direction === Direction.RTL ? <IconWritingDirectionRTL /> : <IconWritingDirectionLTR />}
                    aria-label={translate('editor.sidebar.shots.text.direction.label')}
                />
            </Tooltip>
        </RightBox>
    );
};
