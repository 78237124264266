import React, { useCallback, useMemo } from 'react';
import { type TextElement } from '@bynder-studio/render-core';
import ShotColor from 'packages/pages/design/sidebar/shots/ShotColor';
import { flattenTree } from '~/common/editor/helpers/elementtree';
import useElement from '../../hooks/useElement';
import { usePalettes } from '../../hooks/usePalettes';
import { collectConfigureColors, getUnifiedColor } from '../ShapeElement/utils'; // todo: move to a shared location
import { ExtendedColorParams } from '@bynder-studio/misc';

type Props = {
    elementId: number;
    template: any;
    selectedPageIndex: number;
};

const BackgroundColor = ({ elementId, template, selectedPageIndex }: Props) => {
    const { updateElement, element } = useElement<TextElement>(elementId);
    const { byId } = usePalettes();

    const selectedColor = useMemo(
        () => (element?.textBackground?.color ? getUnifiedColor(element.textBackground.color, byId) : null),
        [element?.textBackground?.color, byId],
    );

    const colors = useMemo(() => {
        const templateElements = flattenTree(template.pages[selectedPageIndex].elements);
        const templateElement = templateElements[elementId];

        if (
            !templateElement ||
            !templateElement.properties.textBackground ||
            !templateElement.properties.textBackgroundBrandColors
        ) {
            return [];
        }

        return collectConfigureColors(templateElement, 'textBackgroundBrandColors', 'textBackground.color', byId);
    }, [template, selectedPageIndex, elementId, byId]);

    const handleColorClick = useCallback(
        ({ name, ...color }: ExtendedColorParams) => {
            if (!element) {
                return;
            }

            updateElement({ textBackground: { ...element.textBackground, color } });
        },
        [updateElement, element],
    );

    if (!element || !selectedColor || colors.length < 2) {
        return null;
    }

    return (
        <ShotColor
            title=""
            elementId={elementId}
            selectedColor={selectedColor}
            colors={colors}
            isDisabled={false}
            handleColorClick={handleColorClick}
        />
    );
};

export default BackgroundColor;
