import type { ColorParams } from '@bynder-studio/misc';
import { StrokeType } from '@bynder-studio/misc';
import { RoundedPoint } from '../../../Helpers/pathFunctions';
import { Position } from '../../Shared/Position';
import type { BaseCompElementParams } from './BaseCompElement';
import { BaseCompElement } from './BaseCompElement';

export type SvgCompElementParams = BaseCompElementParams & {
    path: string;
    decorationPaths?: string[];
    bgPaths?: string[];
    bgColor: ColorParams | null;
    pathPoints: RoundedPoint[];
    horizontalPathScale: number;
    verticalPathScale: number;
    color: ColorParams;
    pathPosition: Position;
    strokeType: StrokeType;
    strokeWidth: number;
};

export class SvgCompElement extends BaseCompElement {
    path!: string;

    decorationPaths?: string[];

    horizontalPathScale = 1; // horizontal scaling to apply to SVG

    verticalPathScale = 1; // vertical scaling to apply to SVG

    color: ColorParams = { red: 0, green: 0, blue: 0, opacity: 1, brandColorId: null };

    bgPaths?: string[] = [];

    bgColor?: ColorParams | null = null;

    pathPoints: RoundedPoint[] = [];

    // position to draw from (can be different from topleft)
    pathPosition!: Position;

    strokeType = StrokeType.NONE;

    strokeWidth = 0;

    setProperties(params: SvgCompElementParams) {
        super.setProperties(params);
        this.path = params.path ?? this.path;
        this.decorationPaths = params.decorationPaths ?? this.decorationPaths;
        this.bgPaths = params.bgPaths ?? this.bgPaths;
        this.bgColor = params.bgColor ?? this.bgColor;
        this.pathPoints = params.pathPoints ?? this.pathPoints;
        this.horizontalPathScale = params.horizontalPathScale ?? this.horizontalPathScale;
        this.verticalPathScale = params.verticalPathScale ?? this.verticalPathScale;
        this.color = params.color;
        this.pathPosition = params.pathPosition
            ? new Position(params.pathPosition.x, params.pathPosition.y)
            : this.pathPosition;
        this.strokeType = params.strokeType || StrokeType.NONE;
        this.strokeWidth = params.strokeWidth || 0;
    }

    getLocalHashData() {
        return {
            ...super.getLocalHashData(),
            path: this.path,
            horizontalPathScale: this.horizontalPathScale,
            verticalPathScale: this.verticalPathScale,
            color: this.color,
            pathPosition: this.pathPosition.toObject(),
        };
    }

    toObject() {
        return {
            ...super.toObject(),
            type: 'SVG',
            path: this.path,
            decorationPaths: this.decorationPaths,
            bgPaths: this.bgPaths,
            bgColor: this.bgColor,
            pathPoints: this.pathPoints,
            horizontalPathScale: this.horizontalPathScale,
            verticalPathScale: this.verticalPathScale,
            color: this.color,
            pathPosition: this.pathPosition.toObject(),
            strokeType: this.strokeType,
            strokeWidth: this.strokeWidth,
        };
    }
}
