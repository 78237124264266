export type DimensionObject = {
    width: number;
    height: number;
};

export class Dimension {
    width: number;
    height: number;

    constructor(w: number, h: number) {
        this.width = w;
        this.height = h;
    }

    getWidth(): number {
        return this.width;
    }

    setWidth(width: number): void {
        this.width = width;
    }

    getHeight(): number {
        return this.height;
    }

    setHeight(height: number): void {
        this.height = height;
    }

    add(dimension: Dimension): Dimension {
        return new Dimension(this.width + dimension.getWidth(), this.height + dimension.getHeight());
    }

    addWidth(dimension: Dimension): Dimension {
        return new Dimension(this.width + dimension.getWidth(), this.height);
    }

    addHeight(dimension: Dimension): Dimension {
        return new Dimension(this.width, this.height + dimension.getHeight());
    }

    remove(dimension: Dimension): Dimension {
        return new Dimension(this.width - dimension.getWidth(), this.height - dimension.getHeight());
    }

    removeWidth(dimension: Dimension): Dimension {
        return new Dimension(this.width - dimension.getWidth(), this.height);
    }

    removeHeight(dimension: Dimension): Dimension {
        return new Dimension(this.width, this.height - dimension.getHeight());
    }

    multiply(scale: number): Dimension {
        return new Dimension(this.width * scale, this.height * scale);
    }

    multiplyWidth(scale: number): Dimension {
        return new Dimension(this.width * scale, this.height);
    }

    multiplyHeight(scale: number): Dimension {
        return new Dimension(this.width, this.height * scale);
    }

    equals(dimension: Dimension): boolean {
        return this.width === dimension.width && this.height === dimension.height;
    }

    getCopy(): Dimension {
        return new Dimension(this.width, this.height);
    }

    toObject(): DimensionObject {
        return {
            width: this.width,
            height: this.height,
        };
    }
}
