import React from 'react';
import { Button } from '@bynder/design-system';
import { IconSlideshow } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import generateTestId from '~/helpers/testIdHelpers';
import useMultiPageValidation from '~/hooks/useMultiPageValidation';
import MaybeTooltip from 'packages/common/MaybeTooltip';
import { ToggleShowUnpublishedChanges } from './types';

type Props = {
    template: any;
    isInAction: boolean;
    saved: boolean;
    navigateToCC: () => void;
    showUnpublishedChangesModal: boolean;
    toggleShowUnpublishedChanges: ToggleShowUnpublishedChanges;
};

const contentViewId = generateTestId('editor_header_content_view');
const tooltipId = generateTestId('editor_header_content_view_tooltip');

const ContentView = (props: Props) => {
    const { template, isInAction, saved, showUnpublishedChangesModal, toggleShowUnpublishedChanges, navigateToCC } =
        props;
    const { translate } = useTranslate();
    const isValid = useMultiPageValidation();
    const enablePublish =
        !isInAction && isValid && (!saved || template.status === 'DRAFT' || !template.lastPublishDate);

    const onClickPreview = () => {
        if (showUnpublishedChangesModal) {
            toggleShowUnpublishedChanges(false, 'preview');
        }

        if (enablePublish) {
            toggleShowUnpublishedChanges(true, 'preview');

            return;
        }

        navigateToCC();
    };

    const contentViewTitle = translate('editor.header.content_view');
    const enableView = !isInAction && isValid && (saved || template.lastPublishDate);

    return (
        <MaybeTooltip hasTooltip={enableView} {...tooltipId} content={contentViewTitle}>
            <Button
                title="" // even though the field is required, adding title here makes additional tooltip
                icon={<IconSlideshow />}
                onClick={onClickPreview}
                variant="clean"
                isDisabled={!enableView}
                {...contentViewId}
                {...{ id: 'editor_header_content_view' }}
            />
        </MaybeTooltip>
    );
};

export default ContentView;
