import { useCallback, useEffect, useMemo } from 'react';
import { TemplateElement } from '@bynder-studio/render-core';
import useElement from 'packages/pages/design/hooks/useElement';
import useDesign from 'packages/pages/design/hooks/useDesign';
import useForceUpdate from '~/hooks/useForceUpdate';

type Props = {
    shotElement: TemplateElement;
    isToolbarDisabled: boolean;
    isLoading: boolean;
    isDisabledByReview: boolean;
};

export const useShotItem = ({ shotElement, isToolbarDisabled, isLoading, isDisabledByReview }: Props) => {
    const { id } = shotElement;
    const { element, updateElement } = useElement(id);
    const { assetLoader, creativeModel } = useDesign();
    const forceUpdate = useForceUpdate();

    const { allowToggleVisibility, hidden, locked } = element || {};
    const type = useMemo(() => shotElement.type.toLocaleLowerCase(), [shotElement.type]);
    const isTextElement = type === 'text';
    const isImageElement = type === 'image';
    const isShapeElement = type === 'shape';

    const blockedByColorsLack = useMemo(() => {
        if (type === 'shape') {
            const { fillBrandColors, borderBrandColors } = shotElement.properties;

            return !fillBrandColors.length && !borderBrandColors.length;
        }

        return false;
    }, [shotElement]);

    const isDisabled = isToolbarDisabled || isLoading || isDisabledByReview;

    const isVisibleOnCanvas = allowToggleVisibility ? !hidden : true;
    const shotToBeHidden =
        (!allowToggleVisibility && (locked || hidden)) || (!allowToggleVisibility && blockedByColorsLack);
    const isBaseContentVisible = isVisibleOnCanvas && !locked && !blockedByColorsLack;
    const isContentProperty = !!element?.contentPropertyId;

    const toggleShow = useCallback(() => {
        if (!creativeModel) {
            return;
        }

        updateElement({ hidden: !hidden });
    }, [id, hidden, creativeModel]);

    useEffect(() => {
        if (isTextElement || isShapeElement) {
            return;
        }

        assetLoader.eventEmitter.on('asset.load', forceUpdate);

        return () => {
            assetLoader.eventEmitter.off('asset.load', forceUpdate);
        };
    }, [assetLoader]);

    return {
        isTextElement,
        isImageElement,
        isShapeElement,
        isDisabled,
        element,
        shotToBeHidden,
        isBaseContentVisible,
        allowToggleVisibility,
        toggleShow,
        id,
        type,
        isVisibleOnCanvas,
        isContentProperty,
    };
};
