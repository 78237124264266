import React from 'react';
import { Form } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import FontColorManipulator from './FontColorManipulator';

const FontColor = ({ creativeModel, selectedElement }) => {
    const { translate } = useTranslate();
    const { locked } = selectedElement;

    return (
        <Form.Group>
            <Form.Label>{translate('editor.sidebar.shots.text.font.color.label')}</Form.Label>
            <FontColorManipulator creativeModel={creativeModel} selectedElement={selectedElement} disabled={locked} />
        </Form.Group>
    );
};

export default FontColor;
