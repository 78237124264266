import { AnimationInTypes } from '../../Enums/AnimationInTypes';
import { Directions } from '../../Enums/Directions';
import { RegionTypes } from '../../Enums/RegionTypes';
import KeyFrameHelper, { KeyFrame } from '../../Helpers/KeyFrames';
import { createCirclePath, createRectanglePath } from '../../Helpers/pathFunctions';
import { Position } from '../../Models/Shared/Position';
import { BaseAnimationConfig, BaseAnimationIn } from './BaseAnimation';
import { BaseVisualElement } from '../Elements/BaseVisualElement';
import { BaseCompElement } from '../CompModels/Elements/BaseCompElement';
import { calculateWipe } from './wipeCalculation';

export class FadeInAnimation extends BaseAnimationIn {
    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        if (!this.containsFrame(frameIndex) || this.duration <= 1) return compEl;
        const finalOpacity = this.el.opacity;
        const startOpacity = this.config.opacity || 0;
        const newOpacity = this.timingFn(
            frameIndex - this.startFrame,
            startOpacity,
            finalOpacity - startOpacity,
            this.duration - 1,
        );
        compEl.opacity = newOpacity;
        return compEl;
    }
}

/**
 * MoveInAnimation animates the content of an element from outside the canvas to the element position.
 */

export class MoveInAnimation extends BaseAnimationIn {
    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        if (!this.containsFrame(frameIndex) || this.duration <= 1) {
            return compEl;
        }

        const direction = this.config.direction;
        const distance = this.config.distance;
        const mask = this.config.mask;
        const finalPosition = compEl.getAbsContentPosition();
        const currentDimension = compEl.contentBox.dimension;
        const canvasDimension = this.el.canvasDimension;
        let startPosition = new Position(finalPosition.getX(), finalPosition.getY());

        switch (direction) {
            case Directions.UP: {
                startPosition.setY(
                    finalPosition.getY() + (canvasDimension.getHeight() - finalPosition.getY()) * distance,
                );
                break;
            }

            case Directions.DOWN: {
                startPosition.setY(
                    finalPosition.getY() - (currentDimension.getHeight() + finalPosition.getY()) * distance,
                );
                break;
            }

            case Directions.RIGHT: {
                startPosition.setX(
                    finalPosition.getX() - (currentDimension.getWidth() + finalPosition.getX()) * distance,
                );
                break;
            }

            case Directions.LEFT: {
                startPosition.setX(
                    finalPosition.getX() + (canvasDimension.getWidth() - finalPosition.getX()) * distance,
                );
                break;
            }
        }

        const newX = this.timingFn(
            frameIndex - this.startFrame,
            startPosition.getX(),
            finalPosition.getX() - startPosition.getX(),
            this.duration - 1,
        );
        const newY = this.timingFn(
            frameIndex - this.startFrame,
            startPosition.getY(),
            finalPosition.getY() - startPosition.getY(),
            this.duration - 1,
        );

        const translateX = finalPosition.getX() - newX;
        const translateY = finalPosition.getY() - newY;

        if (mask && mask !== 'false') {
            const contentOffsetX = compEl.contentBox.position.getX();
            const contentOffsetY = compEl.contentBox.position.getY();
            const BBWidth = compEl.boundingBox.dimension.width;
            const BBHeight = compEl.boundingBox.dimension.height;

            switch (direction) {
                case Directions.UP:
                    compEl.clipPath = createRectanglePath(
                        0,
                        translateY,
                        BBWidth - contentOffsetX,
                        BBHeight - contentOffsetY,
                    );
                    break;
                case Directions.DOWN:
                    compEl.clipPath = createRectanglePath(
                        0 - contentOffsetX,
                        translateY - contentOffsetY,
                        BBWidth + contentOffsetX,
                        BBHeight + contentOffsetY,
                    );
                    break;
                case Directions.LEFT:
                    compEl.clipPath = createRectanglePath(
                        translateX,
                        0,
                        BBWidth - contentOffsetX,
                        BBHeight - contentOffsetY,
                    );
                    break;
                case Directions.RIGHT:
                    compEl.clipPath = createRectanglePath(
                        translateX - contentOffsetX,
                        0 - contentOffsetY,
                        BBWidth + contentOffsetX,
                        BBHeight + contentOffsetY,
                    );
                    break;
            }
        }

        // translate to relative box position
        compEl.boundingBox.position.setX(compEl.boundingBox.position.getX() - translateX);
        compEl.boundingBox.position.setY(compEl.boundingBox.position.getY() - translateY);
        compEl.translatedX = translateX;
        compEl.translatedY = translateY;
        // set motion blur if enabled
        compEl.motionBlur = compEl.motionBlur || (this.config.motionBlur ?? false);
        return compEl;
    }
}

export class ScaleInAnimation extends BaseAnimationIn {
    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        if (!this.containsFrame(frameIndex) || this.duration <= 1) return compEl;
        const scaleFrom = this.config.scale;
        const scaleTo = 1;
        const newScale = this.timingFn(frameIndex - this.startFrame, scaleFrom, scaleTo - scaleFrom, this.duration - 1);
        compEl.scale = newScale;
        // set motion blur if enabled
        compEl.motionBlur = compEl.motionBlur || (this.config.motionBlur ?? false);
        return compEl;
    }
}

class BlurInAnimation extends BaseAnimationIn {
    updateCompEl(frameIndex: number, compEl: BaseCompElement): BaseCompElement {
        if (!this.containsFrame(frameIndex) || this.duration <= 1) return compEl;
        const blurFrom = this.config.blur;
        const blurTo = 0;
        const newBlur = this.timingFn(frameIndex - this.startFrame, blurFrom, blurTo - blurFrom, this.duration - 1);
        compEl.blur = newBlur;
        return compEl;
    }
}

class IrisInAnimation extends BaseAnimationIn {
    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        if (!this.containsFrame(frameIndex) || this.duration <= 1) {
            return compEl;
        }

        //The transformed region is used to center the effect, while the content is used to determine the radius
        //TODO: this might fail when combined with scaling
        const contentRegion = compEl.getRegion(RegionTypes.TRANSFORMED);
        const regionWidth = contentRegion.getWidth();
        const regionHeight = contentRegion.getHeight();
        //const contentWidth = compEl.contentBox.dimension.getWidth();
        //const contentHeight = compEl.contentBox.dimension.getHeight();
        const ox = contentRegion.left;
        const oy = contentRegion.top;

        const distance = Math.sqrt((regionWidth / 2) ** 2 + (regionHeight / 2) ** 2);
        const newRadius = this.timingFn(frameIndex - this.startFrame, 0, distance, this.duration - 1);
        compEl.clipPath = createCirclePath(ox + regionWidth / 2, oy + regionHeight / 2, newRadius);
        // set motion blur if enabled
        compEl.motionBlur = compEl.motionBlur || (this.config.motionBlur ?? false);
        return compEl;
    }
}

class WipeInAnimation extends BaseAnimationIn {
    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        if (!this.containsFrame(frameIndex) || this.duration <= 1) {
            return compEl;
        }

        const direction = this.config.direction;
        const softness = this.config.softness;

        const contentRegion = compEl.getRegion(RegionTypes.TRANSFORMED);
        const w = contentRegion.getWidth();
        const h = contentRegion.getHeight();
        const ox = contentRegion.left;
        const oy = contentRegion.top;

        const p = this.timingFn(frameIndex - this.startFrame, 0, 1, this.duration - 1); // get distance percentage

        const { clipPath, clipSoftness } = calculateWipe(direction as Directions, softness, { ox, oy, w, h, p });

        compEl.clipPath = clipPath;
        compEl.clipSoftness = clipSoftness;
        // set motion blur if enabled
        compEl.motionBlur = compEl.motionBlur || (this.config.motionBlur ?? false);

        return compEl;
    }
}

class PopInAnimation extends BaseAnimationIn {
    // scale to 110% in 80% of time then to 100% in the remaining 20%;
    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        if (!this.containsFrame(frameIndex) || this.duration <= 1) {
            return compEl;
        }

        const keyFrameHelper = new KeyFrameHelper([
            new KeyFrame(0.0, 0.0), // scale is 0.0 at 0%
            new KeyFrame(0.8, 1.1), // scale to 1.1 at 80%
            new KeyFrame(1.0, 1.0), // scale to 1.0 at 100%
        ]);
        // get percentage of the animation finished
        const p = this.timingFn(frameIndex - this.startFrame, 0, 1, this.duration - 1); // get distance percentage

        // apply percentage to relative keyframes
        const newScale = keyFrameHelper.getValueLinear(p);
        compEl.scale = newScale;
        // set motion blur if enabled
        compEl.motionBlur = compEl.motionBlur || (this.config.motionBlur ?? false);
        return compEl;
    }
}

class FadeMoveInAnimation extends BaseAnimationIn {
    fadeInAnimation!: FadeInAnimation;
    moveInAnimation!: MoveInAnimation;

    constructor(config: BaseAnimationConfig, el: BaseVisualElement) {
        super(config, el);
        this.fadeInAnimation = new FadeInAnimation(config, el);
        this.moveInAnimation = new MoveInAnimation(config, el);
    }

    update(config: BaseAnimationConfig): void {
        super.update(config);
        this.fadeInAnimation.update(config);
        this.moveInAnimation.update(config);
    }

    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        if (!this.containsFrame(frameIndex) || this.duration <= 1) return compEl;
        compEl = this.fadeInAnimation.updateCompEl(frameIndex, compEl);
        compEl = this.moveInAnimation.updateCompEl(frameIndex, compEl);
        // set motion blur if enabled
        compEl.motionBlur = compEl.motionBlur || (this.config.motionBlur ?? false);
        return compEl;
    }
}

class FadeScaleInAnimation extends BaseAnimationIn {
    fadeInAnimation!: FadeInAnimation;
    scaleInAnimation!: ScaleInAnimation;

    constructor(config: BaseAnimationConfig, el: BaseVisualElement) {
        super(config, el);
        this.fadeInAnimation = new FadeInAnimation(config, el);
        this.scaleInAnimation = new ScaleInAnimation(config, el);
    }

    update(config: BaseAnimationConfig): void {
        super.update(config);
        this.fadeInAnimation.update(config);
        this.scaleInAnimation.update(config);
    }

    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        if (!this.containsFrame(frameIndex) || this.duration <= 1) return compEl;
        compEl = this.fadeInAnimation.updateCompEl(frameIndex, compEl);
        compEl = this.scaleInAnimation.updateCompEl(frameIndex, compEl);
        // set motion blur if enabled
        compEl.motionBlur = compEl.motionBlur || (this.config.motionBlur ?? false);
        return compEl;
    }
}

class FadeBlurInAnimation extends BaseAnimationIn {
    fadeInAnimation: FadeInAnimation;
    blurInAnimation: BlurInAnimation;

    constructor(config: BaseAnimationConfig, el: BaseVisualElement) {
        super(config, el);
        this.fadeInAnimation = new FadeInAnimation(config, el);
        this.blurInAnimation = new BlurInAnimation(config, el);
    }

    update(config: BaseAnimationConfig): void {
        super.update(config);
        this.fadeInAnimation.update(config);
        this.blurInAnimation.update(config);
    }

    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        if (!this.containsFrame(frameIndex) || this.duration <= 1) return compEl;
        compEl = this.fadeInAnimation.updateCompEl(frameIndex, compEl);
        compEl = this.blurInAnimation.updateCompEl(frameIndex, compEl);
        return compEl;
    }
}

class EmptyAnimationIn extends BaseAnimationIn {
    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        return compEl;
    }
}

// ------------------------------------------------------------------------------

export const createAnimationIn = (config: BaseAnimationConfig, el: BaseVisualElement) => {
    if (!el.animationIn) {
        const elementDuration = el.duration;
        const animationOutDuration = el.animationOut?.duration ?? 0;
        const freeDuration = elementDuration - animationOutDuration;
        config.duration = freeDuration > 0 ? Math.min(freeDuration, config.duration) : config.duration;
    }

    if (config) {
        switch (config.type) {
            case AnimationInTypes.FADE_IN:
                return new FadeInAnimation(config, el);

            case AnimationInTypes.MOVE_IN:
                return new MoveInAnimation(config, el);

            case AnimationInTypes.FADE_MOVE_IN:
                return new FadeMoveInAnimation(config, el);

            case AnimationInTypes.SCALE_IN:
                return new ScaleInAnimation(config, el);

            case AnimationInTypes.FADE_SCALE_IN:
                return new FadeScaleInAnimation(config, el);

            case AnimationInTypes.BLUR_IN:
                return new BlurInAnimation(config, el);

            case AnimationInTypes.FADE_BLUR_IN:
                return new FadeBlurInAnimation(config, el);

            case AnimationInTypes.IRIS_IN:
                return new IrisInAnimation(config, el);

            case AnimationInTypes.WIPE_IN:
                return new WipeInAnimation(config, el);

            case AnimationInTypes.POP_IN:
                return new PopInAnimation(config, el);

            default:
                return new EmptyAnimationIn(config, el);
        }
    }

    return null;
};
