export const horizontalAlignment: Record<number, string> = {
    [-1]: 'left',
    [0]: 'center',
    [1]: 'right',
    [2]: 'justify',
};

export const verticalAlignment: Record<number, string> = {
    [-1]: 'top',
    [0]: 'middle',
    [1]: 'bottom',
};
