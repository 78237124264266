import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseMultiPageModel, BaseVisualElement } from '@bynder-studio/render-core';
import { List, Thumbnail } from '@bynder/design-system';
import { IconShow } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import generateTestId from '~/helpers/testIdHelpers';

type Props = {
    selectedElement: BaseVisualElement;
    creativeModel: BaseMultiPageModel<any>;
};

const AllowToggleVisibility = ({ selectedElement, creativeModel }: Props) => {
    const { locked, id } = selectedElement;
    const { translate } = useTranslate();
    const [visibility, setVisibility] = useState(selectedElement.allowToggleVisibility);
    const testId = useMemo(() => generateTestId('shots_properties_visibility_control'), []);

    const onToggle = useCallback(() => {
        const newVisibility = !visibility;

        creativeModel.updateElement(id, { allowToggleVisibility: newVisibility });
        setVisibility(newVisibility);
    }, [creativeModel, id, visibility]);

    useEffect(() => {
        setVisibility(selectedElement.allowToggleVisibility);
    }, [selectedElement.allowToggleVisibility]);

    return (
        <List.Item
            checkedVariant="switch"
            onClick={onToggle}
            isChecked={visibility}
            isDisabled={locked}
            thumbnail={<Thumbnail icon={<IconShow />} />}
            {...testId}
        >
            {translate('editor.sidebar.shots.visibility_control.label')}
        </List.Item>
    );
};

export default AllowToggleVisibility;
