import { ImportedTemplate, ImportType, DesignFileAnalysis, ImportTemplateState, MultiDesignMode } from './types';

export const getImportedTemplate = (state: any): ImportedTemplate => state.importTemplate.template;
export const isImportTemplateLoading = (state: any): boolean => state.importTemplate.loading;
export const getImportTemplateErrors = (state: any): any => state.importTemplate.errors;
export const getImportTemplateValidationErrors = (state: any): any[] => state.importTemplate.validationErrors;
export const getImportTemplateType = (state: any): ImportType => state.importTemplate.type;
export const getImportTemplateAnalysis = (state: any): DesignFileAnalysis => state.importTemplate.designFileAnalysis;
export const getImportTemplateMultiDesignMode = (state: any): MultiDesignMode => state.importTemplate.multiDesignMode;
export const getImportTemplateProgress = (state: any): number => state.importTemplate.progress;
export const getImportActionProgress = (state: any): { addingInProgress: ImportTemplateState['addingInProgress'] } => ({
    addingInProgress: state.importTemplate.addingInProgress,
});
