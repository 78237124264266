import React, { FC } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Flex,
    SkeletonList,
    SkeletonText,
    SkeletonThumbnail,
    Table,
} from '@bynder/design-system';
import { IconMore } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { LoadingLayer } from 'packages/pages/components/grid/Grid.styled';
import { StyledSkeletonTableRow } from './SkeletonListView.styled';

const SkeletonListView: FC<{ columnWidths: string[] }> = ({ columnWidths }) => {
    const { translate } = useTranslate();

    const renderHeader = () => (
        <>
            <Table.HeadCell>
                <Checkbox onChange={() => {}} isDisabled />
            </Table.HeadCell>
            <Table.HeadCell>
                <SkeletonText />
            </Table.HeadCell>
            <Table.HeadCell>
                <SkeletonText />
            </Table.HeadCell>
            <Table.HeadCell>
                <SkeletonText />
            </Table.HeadCell>
            <Table.HeadCell>
                <SkeletonText />
            </Table.HeadCell>
            <Table.HeadCell />
        </>
    );

    const renderItem = () => (
        <>
            <Table.Cell>
                <Checkbox onChange={() => {}} isDisabled />
            </Table.Cell>
            <Table.Cell>
                <SkeletonList.Item isSubtextShow isThumbnailShow />
            </Table.Cell>
            <Table.Cell>
                <SkeletonText />
            </Table.Cell>
            <Table.Cell>
                <Flex alignItems="center">
                    <Box marginRight="4">
                        <SkeletonThumbnail shape="circle" />
                    </Box>
                    <SkeletonText />
                </Flex>
            </Table.Cell>
            <Table.Cell>
                <SkeletonText />
            </Table.Cell>
            <Table.Cell>
                <Table.StopPropagation>
                    <Button
                        onClick={() => {}}
                        title={translate('pages.design.menu')}
                        variant="clean"
                        icon={<IconMore />}
                        isDisabled
                    />
                </Table.StopPropagation>
            </Table.Cell>
        </>
    );

    const renderItems = () => (
        <>
            <StyledSkeletonTableRow index={0}>{renderItem()}</StyledSkeletonTableRow>
            <StyledSkeletonTableRow index={1}>{renderItem()}</StyledSkeletonTableRow>
            <StyledSkeletonTableRow index={2}>{renderItem()}</StyledSkeletonTableRow>
        </>
    );

    return (
        <>
            <Table columnWidths={columnWidths}>
                <Table.Head>{renderHeader()}</Table.Head>
                <Table.Body>{renderItems()}</Table.Body>
            </Table>
            <LoadingLayer data-testid="list view loader">
                <Button isLoading variant="clean" aria-label={translate('pages.loading')} />
            </LoadingLayer>
        </>
    );
};

export default SkeletonListView;
