import { ElementUpdateTypes } from '../../Enums/ElementUpdateTypes';
import { compareByRenderOrder } from '../../Helpers/elementUtils';
import type { IAsset } from '../Assets/IAsset';
import { GroupCompElement } from '../CompModels/Elements/GroupCompElement';
import { Dimension } from '../Shared/Dimension';
import { Position } from '../Shared/Position';
import type { BaseVisualElementParams } from './BaseVisualElement';
import { BaseVisualElement } from './BaseVisualElement';
import { ElementUpdateOptions, IElement } from './IElement';
import { TextElement, TextElementParams } from './TextElement';
import { ImageElement, ImageElementParams } from './ImageElement';
import { VideoElement, VideoElementParams } from './VideoElement';
import { BaseCompElement } from '../CompModels/Elements/BaseCompElement';
import { ShapeElement, ShapeElementParams } from './ShapeElement';
import { getBoundingBoxAfterRotation } from './Helpers/GroupElementHelpers';

export type ChildElement = GroupElement | TextElement | ImageElement | VideoElement | ShapeElement;
type ChildElementParams =
    | GroupElementParams
    | TextElementParams
    | ImageElementParams
    | VideoElementParams
    | ShapeElementParams;

export type GroupElementParams = BaseVisualElementParams & {
    children?: ChildElementParams[];
};

export type GroupElementParamsWithChildren = Omit<GroupElementParams, 'children'> & { children: ChildElement[] };

export class GroupElement extends BaseVisualElement implements IElement {
    children: ChildElement[] = [];

    constructor(params: Partial<GroupElementParams & { children: ChildElement[] }>) {
        super();
        const updateTypes: Set<ElementUpdateTypes> = this.setProperties(params);

        if (updateTypes.has(ElementUpdateTypes.CHILDREN)) {
            this.setProperties(this.getDiffBasedOnChildren());
        }
    }

    setProperties(params: Partial<GroupElementParamsWithChildren>): Set<ElementUpdateTypes> {
        const updateTypes: Set<ElementUpdateTypes> = super.setProperties(params as BaseVisualElementParams);

        if (params.children !== undefined) {
            this.children = params.children;
            this.children.forEach((child) => {
                child.parent = this;
            });
            updateTypes.add(ElementUpdateTypes.CHILDREN);
        }

        return updateTypes;
    }

    update(params: Partial<GroupElementParamsWithChildren>, options?: ElementUpdateOptions): Set<ElementUpdateTypes> {
        return this.setProperties(params);
    }

    getDiffBasedOnChildren(): Partial<GroupElementParams & { children: ChildElement[] }> {
        let smallestStartFrame = Number.POSITIVE_INFINITY;
        let latestEndingFrame = Number.NEGATIVE_INFINITY;
        let minPositionX = Number.POSITIVE_INFINITY;
        let minPositionY = Number.POSITIVE_INFINITY;
        let maxPositionX = Number.NEGATIVE_INFINITY;
        let maxPositionY = Number.NEGATIVE_INFINITY;

        this.children.forEach((child: ChildElement) => {
            smallestStartFrame = Math.min(smallestStartFrame, child.startFrame);
            latestEndingFrame = Math.max(latestEndingFrame, child.startFrame + child.duration);

            const boundingBox = getBoundingBoxAfterRotation(child);

            maxPositionX = Math.max(maxPositionX, ...boundingBox.map((values) => values.x));
            maxPositionY = Math.max(maxPositionY, ...boundingBox.map((values) => values.y));
            minPositionX = Math.min(minPositionX, ...boundingBox.map((values) => values.x));
            minPositionY = Math.min(minPositionY, ...boundingBox.map((values) => values.y));
        });

        const width = maxPositionX - minPositionX;
        const height = maxPositionY - minPositionY;

        const locked = this.children.every((child) => child.locked);
        const position = new Position(minPositionX, minPositionY);
        const dimension = new Dimension(width, height);
        const startFrame = Math.floor(smallestStartFrame);
        const duration = Math.ceil(latestEndingFrame - smallestStartFrame);
        const diff: Partial<GroupElementParams & { children: ChildElement[] }> = {};

        if (this.locked !== locked) {
            diff.locked = locked;
        }

        if (this.startFrame !== startFrame) {
            diff.startFrame = startFrame;
        }

        if (this.duration !== duration) {
            diff.duration = duration;
        }

        if (!this.position || !this.position.equals(position)) {
            diff.position = position.toObject();
        }

        if (!this.dimension || !this.dimension.equals(dimension)) {
            diff.dimension = dimension.toObject();
        }

        return diff;
    }

    isContainsAsset(asset: IAsset): boolean {
        return this.children.some((child) => child.isContainsAsset(asset));
    }

    isContainsElement(elementId: number): boolean {
        return this.children.some(
            (child) => child.id === elementId || (child instanceof GroupElement && child.isContainsElement(elementId)),
        );
    }

    getCompElement(frameIndex: number) {
        // Collect children first
        const compElChildren: BaseCompElement[] = [];
        this.children.sort(compareByRenderOrder).forEach((el) => {
            if (el.isVisible(frameIndex)) {
                const compElChild = el.getCompElement(frameIndex);

                if (compElChild) {
                    compElChildren.push(compElChild as BaseCompElement);
                }
            }
        });

        if (!compElChildren.length) {
            return null;
        }

        const compEl = new GroupCompElement(compElChildren);

        compEl.originalElement = this;
        compEl.id = this.id;
        compEl.hidden = this.hidden;
        compEl.renderOrder = this.renderOrder;
        compEl.opacity = this.opacity;
        compEl.rotation = this.rotation;
        compEl.scale = this.scale;
        compEl.dropShadow = this.dropShadow;
        compEl.mask = this.mask;
        compEl.blendMode = this.blendMode;

        return this.applyAnimations(frameIndex, compEl);
    }

    toObject(): any {
        const baseObject = super.toObject();

        return { ...baseObject, children: this.children.map((child) => child.toObject()) };
    }

    getCopy(): GroupElement {
        return new GroupElement({
            ...this.toObject(),
            children: this.children.map((child) => child.getCopy() as ChildElement),
        });
    }

    hasMotionBlur(): boolean {
        // check if motion blur is applied to any of the animations or to the children
        // NOTE: deleted param from super
        return super.hasMotionBlur() || this.children.some((el) => el.hasMotionBlur());
    }
}
