import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const TimelineShotContainerStyled = styled.div`
    position: relative;
    flex: 1;
    align-items: center;
    width: 100%;
    padding: 0 8px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    overflow: hidden;

    .timeline--shot-item-container {
        padding-right: 8px;
        width: auto;
        will-change: min-width;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        flex-shrink: 0;
        height: 100%;
        cursor: pointer;
    }

    .timeline--shot-item-container:last-child {
        padding-right: 0;
    }

    .timeline--inner-wrapper {
        display: flex;
        height: 100%;
        position: relative;
        width: calc(100% - 20px);
    }

    .timeline--shot-item {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        height: 40px;
        border-radius: 6px;
        overflow: hidden;
        box-shadow: inset 0 0 0 1px ${token.colorShadow};
        transition: box-shadow 0.3s ease;
        padding: 8px;
        color: ${token.colorTextMuted};
    }

    .timeline--shot-item img {
        width: 36px;
        height: 24px;
        transition: height 0.3s ease-out;
        -o-object-fit: contain;
        object-fit: contain;
        -o-object-position: left center;
        object-position: left center;
        border-radius: 5px;
    }

    .timeline--shot-item.active {
        color: ${token.colorText};
        box-shadow: inset 0 0 0 2px ${token.brandPrimary500};
    }

    .timeline--shot-item-name {
        white-space: nowrap;
        margin-inline-start: 8px;
        width: auto;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .timeline--shot-wrapper {
        position: relative;
        flex: 1;
        margin: 0 4px;
        padding: 0 0 0 16px;
        height: 100%;
        width: 100%;
        align-items: center;
    }

    .timeline--playhead {
        position: absolute;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: ${token.brandPrimary500};
        cursor: ew-resize;
        z-index: 90;
    }

    .timeline--playhead .timeline--playhead-drag-punch {
        position: absolute;
        top: 0;
        left: -4px;
        width: 12px;
        height: 12px;
        transform: translateX(-1px);
        background-color: ${token.brandPrimary500};
        border-radius: 100%;
    }
`;

export const RedLineStyled = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    display: none;
    width: 1px;
    height: calc(100% - 8px);
    background-color: ${token.red500};
    z-index: 90;
    cursor: pointer;
    pointer-events: none;

    &.timeline--red-line-full {
        height: 100%;
        z-index: 2;
    }
`;
