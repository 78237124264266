import { Flex, token } from '@bynder/design-system';
import styled from 'styled-components';

export const Content = styled(Flex)`
    height: auto;
    padding-bottom: ${token.spacing4};
`;

export const Right = styled(Flex)`
    margin-left: auto;
    justify-content: flex-end;
`;

export const DropdownItemSWrapper = styled.div`
    max-height: 270px;
    overflow-y: auto;
`;
