import React from 'react';
import { Form, Input, Select } from '@bynder/design-system';
import generateTestId from '~/helpers/testIdHelpers';
import editorAutocorrect from 'packages/pages/editor/EditorAutocorrectRules';

const TextBreakup = (props) => {
    const { value, disabled, testId, labelId, handleOnChange, name } = props;
    const { type = null } = value || {};

    const textBreakupOptions = [
        { label: 'No text breakup', value: null },
        null,
        { label: 'Line', value: 'LINE_BREAKUP' },
        { label: 'Word', value: 'WORD_BREAKUP' },
        { label: 'Character', value: 'CHARACTER_BREAKUP' },
    ];

    const directionOptions = [
        { label: 'Forward', value: 'FORWARD' },
        { label: 'Backward', value: 'BACKWARD' },
    ];

    const setProperty = (key, newValue) => {
        let breakup = { ...value };

        if (key === 'type') {
            if (newValue) {
                breakup.type = newValue;
                breakup.direction = breakup.direction || 'FORWARD';
                breakup.delay = breakup.delay || 0;
            } else {
                breakup = null;
            }
        } else {
            breakup[key] =
                key === 'delay' ? editorAutocorrect('animation_positive_limited_percentage', newValue) : newValue;
        }

        handleOnChange(name, breakup);
    };

    const testIdProp = generateTestId(testId);
    const testIdDirection = generateTestId(`${testId}_direction`);
    const testIdDelay = generateTestId(`${testId}_delay`);

    return (
        <>
            <Select
                {...props}
                value={type}
                label={textBreakupOptions.find((i) => i && i.value === type)?.label}
                aria-labelledby={labelId}
                onChange={(v) => setProperty('type', v)}
                isDisabled={disabled}
                {...testIdProp}
            >
                {textBreakupOptions.map((item) =>
                    item ? (
                        <Select.Item value={item.value as any} key={item.value}>
                            {item.label}
                        </Select.Item>
                    ) : (
                        <Select.Divider />
                    ),
                )}
            </Select>
            {value && (
                <Form.Row>
                    <Form.Group>
                        <Form.Label id={`${labelId}_direction`}>Direction</Form.Label>
                        <Select
                            value={value.direction || 'FORWARD'}
                            label={directionOptions.find((i) => i.value === (value.direction || 'FORWARD'))?.label}
                            aria-labelledby={`${labelId}_direction`}
                            onChange={(v) => setProperty('direction', v)}
                            isDisabled={disabled}
                            {...testIdDirection}
                        >
                            {directionOptions.map((item) => (
                                <Select.Item value={item.value as any} key={item.value}>
                                    {item.label}
                                </Select.Item>
                            ))}
                        </Select>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label id={`${labelId}_delay`}>Delay</Form.Label>
                        <Input
                            aria-labelledby={`${labelId}_delay`}
                            onChange={(v) => setProperty('delay', Number(v) / 100)}
                            value={Math.round(value.delay * 100).toString()}
                            suffix="%"
                            isDisabled={disabled}
                            {...testIdDelay}
                        />
                    </Form.Group>
                </Form.Row>
            )}
        </>
    );
};

export default TextBreakup;
