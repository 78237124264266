import React, { useCallback, useEffect, useState } from 'react';
import { ShapeElement } from '@bynder-studio/render-web';
import { BorderAlignmentTypes } from '@bynder-studio/render-core';
import { IconBorderWidth } from '@bynder/icons';
import { Dropdown, Form, Input } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import editorAutocorrect from 'packages/pages/editor/EditorAutocorrectRules';
import { BorderAlignButton } from './index.styled';

type Props = {
    disabled: boolean;
    selectedElement: any;
    creativeModel: any;
};

const BorderWeight = ({ disabled, selectedElement, creativeModel }: Props) => {
    const { translate } = useTranslate();
    const { id, locked, borderWidth, borderAlignment } = selectedElement as ShapeElement;

    const [selectedWeight, setSelectedBorderWeight] = useState(borderWidth || 0);
    const [selectedAlignment, setSelectedBorderAlignment] = useState(borderAlignment);

    const borderAlignmentTitles = {
        [BorderAlignmentTypes.INSIDE]: translate('editor.sidebar.shots.shape.border_align.inside'),
        [BorderAlignmentTypes.MIDDLE]: translate('editor.sidebar.shots.shape.border_align.center'),
        [BorderAlignmentTypes.OUTSIDE]: translate('editor.sidebar.shots.shape.border_align.outside'),
    };

    const applyBorderWeight = (val: number) => {
        const correctWeight = editorAutocorrect('element_border_weight', val);

        setSelectedBorderWeight(correctWeight);
        creativeModel.updateElement(id, { borderWidth: correctWeight });
    };

    const onBorderWeightBlur = () => {
        applyBorderWeight(Number(selectedWeight));
    };

    const onBorderWeightKeyDown = (event) => {
        switch (event.key) {
            case 'Enter':
                onBorderWeightBlur();
                break;
            case 'ArrowUp':
            case 'ArrowDown':
                event.preventDefault();
                const numberToAdd = event.key === 'ArrowDown' ? -1 : 1;
                const factor = event.shiftKey ? 10 : 1;
                applyBorderWeight(Number(selectedWeight) + numberToAdd * factor);
                break;
        }
    };

    const setBorderAlignment = useCallback<(alignment: BorderAlignmentTypes) => void>(
        (alignment) => {
            setSelectedBorderAlignment(alignment);
            creativeModel.updateElement(id, { borderAlignment: alignment });
        },
        [id, creativeModel],
    );

    useEffect(() => {
        setSelectedBorderWeight(selectedElement.borderWidth);
        setSelectedBorderAlignment(selectedElement.borderAlignment);
    }, [id, selectedElement.borderWidth, selectedElement.borderAlignment]);

    return (
        <Form.Group>
            <Form.Label>{translate('editor.sidebar.shots.shape.border_weight.label')}</Form.Label>
            <Form.Row>
                <Input
                    name={translate('editor.sidebar.shots.shape.border_weight.label')}
                    aria-label={translate('editor.sidebar.shots.shape.border_weight.label')}
                    type="number"
                    value={selectedWeight?.toString()}
                    onBlur={onBorderWeightBlur}
                    onChange={(newVal) => setSelectedBorderWeight(+newVal)}
                    onKeyDown={onBorderWeightKeyDown}
                    isDisabled={locked || disabled}
                    icon={<IconBorderWidth />}
                    suffix="px"
                    data-testid="shots_shape_border__weight"
                />
                <Dropdown
                    trigger={({ isOpen, ...triggerProps }) => (
                        <BorderAlignButton
                            rightIcon={<Dropdown.Arrow />}
                            isDisabled={locked || disabled}
                            isPressed={isOpen}
                            isFullWidth
                            {...triggerProps}
                        >
                            {borderAlignmentTitles[selectedAlignment]}
                        </BorderAlignButton>
                    )}
                    position="bottom"
                    data-testid="shots_shape_border__alignment"
                >
                    <Dropdown.Item
                        isChecked={borderAlignment.toUpperCase() === BorderAlignmentTypes.INSIDE}
                        onClick={() => setBorderAlignment(BorderAlignmentTypes.INSIDE)}
                    >
                        {borderAlignmentTitles[BorderAlignmentTypes.INSIDE]}
                    </Dropdown.Item>
                    <Dropdown.Item
                        isChecked={borderAlignment.toUpperCase() === BorderAlignmentTypes.MIDDLE}
                        onClick={() => setBorderAlignment(BorderAlignmentTypes.MIDDLE)}
                    >
                        {borderAlignmentTitles[BorderAlignmentTypes.MIDDLE]}
                    </Dropdown.Item>
                    <Dropdown.Item
                        isChecked={borderAlignment.toUpperCase() === BorderAlignmentTypes.OUTSIDE}
                        onClick={() => setBorderAlignment(BorderAlignmentTypes.OUTSIDE)}
                    >
                        {borderAlignmentTitles[BorderAlignmentTypes.OUTSIDE]}
                    </Dropdown.Item>
                </Dropdown>
            </Form.Row>
        </Form.Group>
    );
};

export default BorderWeight;
