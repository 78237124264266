import React from 'react';
import { List, Thumbnail } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconNoUpload, IconUpload } from '@bynder/icons';
import generateTestId from '~/helpers/testIdHelpers';
import { StyledList } from './PersonalUploadsBase.styled';

type Props = {
    isAllowed: boolean;
    // eslint-disable-next-line react/require-default-props
    disabled?: boolean;
    onToggle: () => void;
};

const TEST_ID = generateTestId('shots_personal_uploads');
const ALLOWED_ICON_TEST_ID = generateTestId('shots_personal_uploads_allowed_icon');
const DISALLOWED_ICON_TEST_ID = generateTestId('shots_personal_uploads_disallowed_icon');

const PersonalUploadsBase = ({ isAllowed, onToggle, disabled }: Props) => {
    const { translate } = useTranslate();

    return (
        <StyledList>
            <List.Item
                thumbnail={
                    <Thumbnail
                        icon={
                            isAllowed ? (
                                <IconUpload {...ALLOWED_ICON_TEST_ID} />
                            ) : (
                                <IconNoUpload {...DISALLOWED_ICON_TEST_ID} />
                            )
                        }
                    />
                }
                onClick={onToggle}
                isChecked={isAllowed}
                checkedVariant="switch"
                isDisabled={disabled}
                {...TEST_ID}
            >
                {translate('editor.sidebar.shots.personal_uploads.label')}
            </List.Item>
        </StyledList>
    );
};

export default PersonalUploadsBase;
