import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { AnimationInTypes } from '@bynder-studio/render-core';
import { Divider, Dropdown, Form, List } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { isImportedAnimation } from '~/common/editor/editorHelper';
import generateTestId from '~/helpers/testIdHelpers';
import {
    FLIP_TYPES,
    getAnimationPropertyOptions,
    getAnimationType,
    getEnhancedAnimations,
    getGroupedTypes,
} from '../utils';
import { TransitionInMap } from '../animationOptions';
import AnimationField from '../components/AnimationField';

const keepOldValues = ['duration', 'timing', 'textBreakup'];

const TransitionIn = ({
    selectedElement,
    animations,
    values,
    defaults,
    onChange,
    frameRate,
    disabled,
    startFrame,
    elementType,
}) => {
    const [activeAnimation, setActiveAnimation] = useState(TransitionInMap[selectedElement?.animationIn?.config?.type]);
    const { translate } = useTranslate();
    const { locked, duration } = selectedElement;

    const isActive = useMemo(
        () => Object.keys(TransitionInMap).includes(selectedElement?.animationIn?.config?.type),
        [selectedElement?.animationIn?.config?.type],
    );

    const types: any = useMemo(() => getEnhancedAnimations(animations, TransitionInMap), [animations]);

    const getTypeDefaults = (type) => {
        if (type.type === 'OFF') {
            return null;
        }

        const result = {};
        type.properties.forEach((property) => {
            // To clarify if it's needed
            if (elementType !== 'TEXT' && property.name === 'textBreakup') {
                return;
            }

            result[property.name] =
                (defaults && defaults[property.name]) ||
                (keepOldValues.includes(property.name) && values
                    ? values[property.name] || property.defaultValue
                    : property.defaultValue);
        });

        return { ...result, type: type.type };
    };

    const onToggle = (animation) => {
        if (animation.type === 'OFF') {
            onChange('animationIn', null);
        } else {
            const data = getTypeDefaults(animation) as any;

            setActiveAnimation(animation);
            onChange('animationIn', data);
        }
    };

    const onAnimationChange = (animation) => {
        const data = getTypeDefaults(animation) as any;

        setActiveAnimation(animation);
        onChange('animationIn', data);
    };

    const handleChangeProperty = (property, newValue) => {
        const animationType = getAnimationType(values);
        const newValues: any = { [property]: newValue };

        if (
            FLIP_TYPES.includes(animationType) &&
            property === 'breakup' &&
            newValue === '1' &&
            values.direction === 'RANDOM'
        ) {
            newValues.direction = 'BOTTOM_TO_TOP';
        }

        const data = { ...values, ...newValues };

        onChange('animationIn', data);
    };

    const testId = 'shots_styling_transition_in';
    const transitionInItem = generateTestId(testId);

    const sortedTypes =
        (activeAnimation &&
            types[activeAnimation.type]?.properties
                .filter(
                    (property) =>
                        property.visible &&
                        (property.name !== 'textBreakup' ||
                            (elementType === 'TEXT' && property.name === 'textBreakup')),
                )
                .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))) ||
        [];

    const groupedTypes = getGroupedTypes(sortedTypes);

    const renderAnimationField = (property) => {
        if (!values) {
            return null;
        }

        const value = values[property.name];
        const options = getAnimationPropertyOptions(activeAnimation.type, property, values);
        const isTextBreakup = property.name === 'textBreakup';
        const isTextBgApplied = selectedElement.textBackground?.isActive();
        const isDisabled = locked || (disabled && property.name !== 'duration') || (isTextBreakup && isTextBgApplied);

        return value !== undefined ? (
            <AnimationField
                key={`key_${activeAnimation.type}_${property.name}`}
                id={`key_${activeAnimation.type}_${property.name}`}
                field={property}
                value={value}
                options={options}
                frameRate={frameRate}
                onChange={handleChangeProperty}
                disabled={isDisabled}
                tooltip={
                    isTextBreakup && isTextBgApplied
                        ? translate('editor.sidebar.shots.animation.text-breakup.disabled.tooltip')
                        : ''
                }
                col={isImportedAnimation(activeAnimation.type) ? 12 : undefined}
                inverseAnimationValue={selectedElement?.animationOut?.config?.duration}
                testId={`${testId}__${property.name}`}
                elementDuration={duration}
            />
        ) : null;
    };

    useEffect(() => {
        setActiveAnimation(TransitionInMap[selectedElement?.animationIn?.config?.type]);
    }, [selectedElement]);

    useEffect(() => {
        if (!values) {
            onChange('animationIn', null);
        }
    }, [values]);

    return (
        <>
            <List.Item
                checkedVariant="switch"
                onClick={() => onToggle(isActive ? types.OFF : types.FADE_IN)}
                isChecked={isActive}
                isDisabled={disabled}
                {...transitionInItem}
            >
                {translate('editor.sidebar.shots.animation.transitionIn.label')}
            </List.Item>
            {isActive && (
                <>
                    <Dropdown
                        trigger={({ isOpen, ...triggerProps }) => (
                            <List.Item
                                isSelected={isOpen}
                                isDisabled={disabled}
                                rightElements={<Dropdown.Arrow />}
                                thumbnail={TransitionInMap[activeAnimation?.type]?.icon}
                                {...triggerProps}
                                {...generateTestId(`${testId}__dropdown`)}
                            >
                                {TransitionInMap[activeAnimation?.type]?.value}
                            </List.Item>
                        )}
                        position="bottom"
                    >
                        {Object.values(TransitionInMap).map((animation) => (
                            <Fragment key={animation.value.toString()}>
                                {animation.type === AnimationInTypes.FADE_BLUR_IN && <Divider />}
                                <Dropdown.Item
                                    onClick={() => onAnimationChange(types[animation.type])}
                                    isChecked={animation.type === activeAnimation?.type}
                                >
                                    {animation.value}
                                </Dropdown.Item>
                            </Fragment>
                        ))}
                    </Dropdown>
                    {groupedTypes.map((property) => {
                        if (!Array.isArray(property)) {
                            return renderAnimationField(property);
                        }

                        return (
                            <Form.Row key={property[0]?.name}>
                                {property.map((nestedProperty) => renderAnimationField(nestedProperty))}
                            </Form.Row>
                        );
                    })}
                </>
            )}
        </>
    );
};

export default TransitionIn;
