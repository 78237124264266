import React from 'react';
import { Dropdown, Button } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconMore } from '@bynder/icons';
import generateTestId from '~/helpers/testIdHelpers';

const ColorDetach = ({ disabled, onColorDetach, testId, hasMultipleColors = false }) => {
    const { translate } = useTranslate();

    const testIdProp = generateTestId(`${testId}_context_menu`);
    const testIdDetachProp = generateTestId(`${testId}_context_menu_detach`);

    return (
        <Dropdown
            trigger={({ isOpen, onClick, onKeyDown, ...triggerProps }, { open, close }) => (
                <Button
                    isPressed={isOpen}
                    icon={<IconMore />}
                    aria-label="access brand colors"
                    onClick={() => (isOpen ? close() : open())}
                    variant="clean"
                    isDisabled={disabled}
                    {...testIdProp}
                    {...triggerProps}
                />
            )}
            position="bottom-right"
        >
            <Dropdown.Item onClick={onColorDetach} {...testIdDetachProp}>
                {translate(
                    hasMultipleColors ? 'editor.sidebar.shots.color.detach.all' : 'editor.sidebar.shots.color.detach',
                )}
            </Dropdown.Item>
        </Dropdown>
    );
};

export default ColorDetach;
