import React, { useState } from 'react';
import RenameStyleModal from './RenameStyleModal';

const useRenameStyleModal = ({ item, renameTextStyle }) => {
    const [isRenameStyleModalOpen, setRenameStyleModalOpen] = useState(false);

    const onClose = () => {
        setRenameStyleModalOpen(false);
    };

    const renderRenameStyleModal = () => (
        <RenameStyleModal
            item={item}
            isOpen={isRenameStyleModalOpen}
            onClose={onClose}
            renameTextStyle={renameTextStyle}
        />
    );

    return {
        setRenameStyleModalOpen,
        renderRenameStyleModal,
    };
};

export default useRenameStyleModal;
