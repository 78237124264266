import { createSelector } from 'reselect';
import { CreativesState } from './types';
import { CREATIVES_PENDING_STATUSES } from './creatives.constants';

export const getCreatives = (state: any): CreativesState => state.creatives;
const getCurrentCreatives = (state: any) => getCreatives(state).creatives;
export const getCreative = (state: any): CreativesState['creative'] => state.creatives.creative;
export const getCreativesActionProgress = (
    state: any,
): {
    renamingInProgress: CreativesState['renamingInProgress'];
    duplicationInProgress: CreativesState['duplicationInProgress'];
    deleteInProgress: CreativesState['deleteInProgress'];
    addingInProgress: CreativesState['addingInProgress'];
} => ({
    renamingInProgress: state.creatives.renamingInProgress,
    duplicationInProgress: state.creatives.duplicationInProgress,
    deleteInProgress: state.creatives.deleteInProgress,
    addingInProgress: state.creatives.addingInProgress,
});
export const getCreativesSelection = (
    state: any,
): {
    selectedCreatives: CreativesState['checked'];
    selectedCreativesIds: number[];
} => ({
    selectedCreatives: state.creatives.checked,
    selectedCreativesIds: state.creatives?.checked.map((creative) => creative.id),
});

export const isCreativesFirstLoaded = (state): CreativesState['firstLoad'] => state.creatives.firstLoad;

export const getPendingCreatives = createSelector(getCurrentCreatives, (creatives) =>
    creatives
        .filter((creative) => CREATIVES_PENDING_STATUSES.includes(creative.thumbnailStatus))
        .map((creative) => creative.id),
);
