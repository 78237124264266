import React, { Suspense, useEffect, useState } from 'react';
import { CreativeTypes, ElementUpdateTypes } from '@bynder-studio/render-core';
import { Divider, Form } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import useAccessRights from 'packages/hooks/useAccessRights';
import useEditor from '~/hooks/useEditor';
import useEditorSelectedElement from '~/hooks/useEditorSelectedElement';
import useEditorSelectedElementContentTransform from '~/hooks/useEditorSelectedElementContentTransform';
import useForceUpdate from '~/hooks/useForceUpdate';
import features from '~/configs/features';
import { SizeFormRow } from '../index.styled';
import { FormSectionContent, InspectorContent, InspectorHeader, Section } from '../../index.styled';
import SettingsTabs from '../Tabs';
import ElementAlignment from '../ElementAlignment';
import Width from '../Size/Width';
import LockToggler from '../Size/LockToggle';
import Height from '../Size/Height';
import VerticalPosition from '../Position/Vertical';
import HorizontalPosition from '../Position/Horizontal';
import Rotation from '../Rotation';
import Opacity from '../Opacity';
import Fonts from './Fonts';
import LineHeight from './LineHeight';
import LetterSpacing from './LetterSpacing';
import HorizontalAlignment from './TextAlignment/Horizontal';
import VerticalAlignment from './TextAlignment/Vertical';
import FontColor from './Fonts/FontColor';
import TextStyles from './TextStyles';
import TextWrap from './TextWrap';
import Masking from '../Masking';
import Shadow from '../Shadow';
import Duration from '../Duration';
import Animations from '../../Animations';
import { BlendMode } from '../BlendMode';
import AllowToggleVisibility from '../AllowToggleVisibility';
import ContentProperty from '../ContentProperty';
import { TextTransform } from './TextTransform/TextTransform';
import FontScale from './TextScale/FontScale';
import ParagraphSpacing from './ParagraphSpacing';
import MoreTextOptions from './MoreTextOptions';
import Outline from './Outline';
import OutlineModes from './Outline/OutlineModes';
import { FormRow2to1 } from './index.styled';
import Script from './Script';
import Decoration from './Decoration';
import TextBackground from 'packages/pages/editor/RightSideMenu/Shots/Text/TextBackground';
import { TextDirection } from 'packages/pages/editor/RightSideMenu/Shots/Text/TextDirection';

const Text = () => {
    const { translate } = useTranslate();
    const { isPlatformAllowed } = useAccessRights();
    const [moreOptions, setMoreOptions] = useState(false);
    const { creativeModel, creativeType, manipulationRenderer, frameRate } = useEditor();
    const { selectedElement, selectedElements, selectedElementDeferred } = useEditorSelectedElement();
    const { contentTransform } = useEditorSelectedElementContentTransform();
    const forceUpdate = useForceUpdate();

    useEffect(() => {
        if (!creativeModel) {
            return;
        }

        return creativeModel.onElementsPartialUpdate(ElementUpdateTypes.LOCK, forceUpdate);
    }, [creativeModel, forceUpdate]);

    const tabs = [
        { value: 'text', label: translate('editor.sidebar.shots.text.tabs.text') },
        { value: 'text_animation', label: translate('editor.sidebar.shots.animation.tabs.text') },
    ];
    const [activeTab, setTab] = useState(tabs[0].value);

    return (
        <>
            {creativeType === 'VIDEO' && (
                <InspectorHeader aria-labelledby="shots-text-tabs-settings">
                    <SettingsTabs tabs={tabs} activeTab={activeTab} onChange={(value) => setTab(value)} />
                </InspectorHeader>
            )}
            <InspectorContent withTabs={creativeType === 'VIDEO'}>
                {activeTab === tabs[0].value && (
                    <>
                        <Section aria-labelledby="shots-alignment-settings">
                            <ElementAlignment creativeModel={creativeModel} selectedElements={selectedElements} />
                        </Section>
                        <Divider />
                        <Section aria-labelledby="shots-size-settings">
                            <FormSectionContent onSubmit={preventSubmit}>
                                <SizeFormRow>
                                    <Width
                                        creativeModel={creativeModel}
                                        selectedElement={selectedElement}
                                        creativeType={creativeType}
                                        isLinked={false}
                                    />
                                    <LockToggler selectedElement={selectedElement} forceUpdate={forceUpdate} />
                                    <Height
                                        creativeModel={creativeModel}
                                        selectedElement={selectedElement}
                                        creativeType={creativeType}
                                        isLinked={false}
                                    />
                                </SizeFormRow>
                                <Form.Row>
                                    <HorizontalPosition
                                        creativeModel={creativeModel}
                                        selectedElement={selectedElement}
                                        isLinked={false}
                                    />
                                    <VerticalPosition
                                        creativeModel={creativeModel}
                                        selectedElement={selectedElement}
                                        isLinked={false}
                                    />
                                </Form.Row>
                                <Form.Row>
                                    <Rotation
                                        creativeModel={creativeModel}
                                        selectedElement={selectedElement}
                                        disabled={false}
                                    />
                                    <Opacity
                                        creativeModel={creativeModel}
                                        selectedElement={selectedElement}
                                        disabled={false}
                                    />
                                </Form.Row>
                            </FormSectionContent>
                        </Section>
                        <Divider />
                        {isPlatformAllowed([features.CONTENT_PROPERTIES]) && (
                            <>
                                <Section aria-labelledby="shots-text-content-property">
                                    <Suspense>
                                        <ContentProperty
                                            creativeModel={creativeModel}
                                            selectedElement={selectedElementDeferred}
                                        />
                                    </Suspense>
                                </Section>
                                <Divider />
                            </>
                        )}
                        <Section aria-labelledby="shots-text-font-settings">
                            <FormSectionContent onSubmit={preventSubmit}>
                                <Form.Group>
                                    <Form.Label>{translate('editor.sidebar.shots.textarea.label')}</Form.Label>
                                    <Suspense>
                                        <TextStyles
                                            creativeModel={creativeModel}
                                            selectedElement={selectedElementDeferred}
                                        />
                                    </Suspense>
                                    <Fonts
                                        creativeModel={creativeModel}
                                        selectedElement={selectedElement}
                                        disabled={false}
                                    />
                                    <Form.Row>
                                        <LineHeight
                                            creativeModel={creativeModel}
                                            selectedElement={selectedElement}
                                            disabled={false}
                                        />
                                        <LetterSpacing
                                            creativeModel={creativeModel}
                                            selectedElement={selectedElement}
                                            disabled={false}
                                        />
                                    </Form.Row>
                                    <Form.Row>
                                        <ParagraphSpacing
                                            creativeModel={creativeModel}
                                            selectedElement={selectedElement}
                                        />
                                        <MoreTextOptions open={moreOptions} onChange={setMoreOptions} />
                                    </Form.Row>
                                    {moreOptions && (
                                        <>
                                            <Form.Row>
                                                <Decoration
                                                    creativeModel={creativeModel}
                                                    selectedElement={selectedElement}
                                                />
                                                <Script
                                                    creativeModel={creativeModel}
                                                    selectedElement={selectedElement}
                                                />
                                            </Form.Row>
                                            <FormRow2to1>
                                                <TextTransform
                                                    withLabel
                                                    creativeModel={creativeModel}
                                                    selectedElement={selectedElement}
                                                    disabled={false}
                                                />
                                                <Outline
                                                    creativeModel={creativeModel}
                                                    selectedElement={selectedElement}
                                                />
                                            </FormRow2to1>
                                            <OutlineModes
                                                creativeModel={creativeModel}
                                                selectedElement={selectedElement}
                                            />
                                        </>
                                    )}
                                </Form.Group>
                            </FormSectionContent>
                        </Section>
                        <Divider />
                        <Section aria-labelledby="shots-text-font-color">
                            <FormSectionContent onSubmit={preventSubmit}>
                                <FontColor creativeModel={creativeModel} selectedElement={selectedElement} />
                            </FormSectionContent>
                        </Section>
                        <Divider />
                        <Section aria-labelledby="shots-text-alignment">
                            <FormSectionContent onSubmit={preventSubmit}>
                                <Form.Group>
                                    <Form.Label>{translate('editor.sidebar.shots.text.alignment.title')}</Form.Label>
                                    <Form.Row>
                                        <HorizontalAlignment
                                            creativeModel={creativeModel}
                                            selectedElement={selectedElement}
                                            contentTransform={contentTransform}
                                        />
                                    </Form.Row>
                                    <Form.Row>
                                        <VerticalAlignment
                                            creativeModel={creativeModel}
                                            selectedElement={selectedElement}
                                            contentTransform={contentTransform}
                                        />
                                    </Form.Row>
                                    <Form.Row>
                                        <TextDirection
                                            creativeModel={creativeModel}
                                            selectedElement={selectedElement}
                                        />
                                    </Form.Row>
                                </Form.Group>
                            </FormSectionContent>
                        </Section>
                        <Section aria-labelledby="shots-text-controls-settings">
                            <FormSectionContent onSubmit={preventSubmit}>
                                <TextWrap creativeModel={creativeModel} selectedElement={selectedElement} />
                            </FormSectionContent>
                        </Section>
                        <Divider />
                        <Section aria-labelledby="shots-text-font-scale">
                            <FormSectionContent onSubmit={preventSubmit}>
                                <FontScale creativeModel={creativeModel} selectedElement={selectedElement} />
                            </FormSectionContent>
                        </Section>
                        <Divider />
                        {creativeType === CreativeTypes.VIDEO && (
                            <>
                                <Section aria-labelledby="shots-text-duration">
                                    <Form onSubmit={preventSubmit}>
                                        <Duration
                                            disabled={false}
                                            selectedElement={selectedElement}
                                            creativeModel={creativeModel}
                                            frameRate={frameRate}
                                        />
                                    </Form>
                                </Section>
                                <Divider />
                            </>
                        )}
                        <Section aria-labelledby="shots-image-visibility">
                            <Form onSubmit={preventSubmit}>
                                <Form.Group>
                                    <Form.Label>{translate('editor.element.visibility_control')}</Form.Label>
                                    <AllowToggleVisibility
                                        selectedElement={selectedElement}
                                        creativeModel={creativeModel}
                                    />
                                </Form.Group>
                            </Form>
                        </Section>
                        <Divider />
                        <Section aria-labelledby="shots-text-masking-settings">
                            <FormSectionContent onSubmit={preventSubmit}>
                                <Form.Group>
                                    <Form.Label>{translate('editor.element.mask')}</Form.Label>
                                    <Masking
                                        element={selectedElement}
                                        creativeModel={creativeModel}
                                        manipulationRenderer={manipulationRenderer}
                                        creativeType={creativeType}
                                        disabled={false}
                                    />
                                </Form.Group>
                            </FormSectionContent>
                        </Section>
                        <Divider />
                        <Section aria-labelledby="shots-text-shadow">
                            <FormSectionContent onSubmit={preventSubmit}>
                                <Form.Group>
                                    <Form.Label>{translate('editor.sidebar.shots.text.shadow.label')}</Form.Label>
                                    <Shadow
                                        disabled={false}
                                        selectedElement={selectedElement}
                                        creativeModel={creativeModel}
                                    />
                                </Form.Group>
                                <Divider />
                                {isPlatformAllowed([features.TEXT_BACKGROUND]) && (
                                    <>
                                        <Form.Group>
                                            <TextBackground
                                                creativeModel={creativeModel}
                                                selectedElement={selectedElement}
                                            />
                                        </Form.Group>
                                        <Divider />
                                    </>
                                )}
                                <Form.Group>
                                    <BlendMode disabled={false} />
                                </Form.Group>
                            </FormSectionContent>
                        </Section>
                    </>
                )}
                {activeTab === tabs[1].value && <Animations element={selectedElement} disabled={false} />}
            </InspectorContent>
        </>
    );
};

export default Text;

function preventSubmit(e: React.FormEvent) {
    e.preventDefault();
}
