import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { token } from '@bynder/design-system';

type SpacingValue = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';

type GridProps = {
    children: React.ReactNode;
    variant?: 'default' | 'masonry';
    columnMaxWidth?: number;
    gap?: SpacingValue;
};

export function Grid({ children, variant = 'default', columnMaxWidth = 224, gap = '6', ...props }: GridProps) {
    if (variant === 'masonry') {
        return (
            <MasonryGrid columnMaxWidth={columnMaxWidth} gap={gap} {...props}>
                {children}
            </MasonryGrid>
        );
    }

    return (
        <GridStyled columnMaxWidth={columnMaxWidth} gap={gap} {...props}>
            {children}
        </GridStyled>
    );
}

const resizeGridItem = (rowHeight: number, rowGap: number, card: HTMLElement) => {
    const rowSpan = Math.ceil((card.getBoundingClientRect().height + rowGap) / (rowHeight + rowGap));

    card.style.gridRowEnd = 'span ' + rowSpan;
};

const MasonryGrid = ({ children, ...props }: GridProps) => {
    const ref = useRef<HTMLDivElement>(null);

    const calculateSpans = useCallback(() => {
        window.requestAnimationFrame(() => {
            if (!ref.current) {
                return;
            }

            const grid = ref.current;

            const rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'), 10) || 1;
            const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'), 10) || 0;

            Array.from(grid.children).forEach((child) => {
                resizeGridItem(rowHeight, rowGap, child as HTMLElement);
            });
        });
    }, []);

    const resizeObserver = useMemo(() => new ResizeObserver(calculateSpans), [calculateSpans]);

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        Array.from(ref.current.children).forEach((child) => {
            resizeObserver.unobserve(child);
            resizeObserver.observe(child);
        });
    }, [children, resizeObserver]);

    useEffect(() => {
        calculateSpans();
    }, [children, props.columnMaxWidth, calculateSpans]);

    useEffect(() => {
        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [resizeObserver]);

    return (
        <GridStyled ref={ref} {...props} variant="masonry">
            {children}
        </GridStyled>
    );
};

const GridStyled = styled.div<GridProps>`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(${(props) => props.columnMaxWidth}px, 1fr));
    grid-auto-rows: ${(props) => (props.variant === 'masonry' ? token.spacing1 : undefined)};
    gap: ${(props) => (props.gap ? token[`spacing${props.gap}`] : undefined)};

    > * {
        height: fit-content;
    }
`;
