import { BaseMultiPageModel, TextElement } from '@bynder-studio/render-core';

export enum SCALE_PROPS {
    FONT_SCALE = 'fontScale',
    MIN_FONT_SCALE = 'minFontScale',
}

export type TextScaleProps = {
    creativeModel: BaseMultiPageModel;
    selectedElement: TextElement;
};

export type FontScaleProps = TextScaleProps & { propName: SCALE_PROPS };
