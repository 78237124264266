import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { hostPortSelector } from '~/store/platform/platform.selectors';
import { registerBynderUser } from '~/store/user/user.actions';

const useHistoryListener = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const hostPort = useSelector(hostPortSelector);

    useEffect(() => {
        let hashChangeListener = () => {};

        if (hostPort) {
            let lastLocation = '';

            hashChangeListener = () => {
                const newLocation = location.pathname + location.search;

                if (lastLocation && lastLocation === newLocation) {
                    return;
                }

                lastLocation = newLocation;

                hostPort.postMessage({
                    type: 'hash_update',
                    hash: newLocation,
                });
            };

            if (['/not-found', '/no-access'].includes(location.pathname)) {
                hostPort.postMessage({
                    type: 'hash_update',
                    hash: location.pathname,
                });
            }

            if (location.pathname === '/bynder-auth') {
                if (location.search) {
                    const searchParams = new URLSearchParams(location.search);
                    const authCode = searchParams.get('code');
                    const state = searchParams.get('state');

                    dispatch(registerBynderUser(authCode, state, () => navigate('/', { replace: true })));
                } else {
                    navigate('/', { replace: true });
                }
            }

            hashChangeListener();
        }

        return () => hashChangeListener();
    }, [hostPort, location, navigate, dispatch]);
};

export default useHistoryListener;
