export type ColorParams = {
    red: number;
    green: number;
    blue: number;
    opacity: number;
    brandColorId: number | null;
};

export type UnifiedCommonСolor = { hexCode: string; opacity: number; id?: number; name?: string };

export type ExtendedColorParams = ColorParams & { name: string };

export const defaultColor: ColorParams = {
    red: 0,
    green: 0,
    blue: 0,
    opacity: 1,
    brandColorId: null,
};

export const toRGBA = (c: ColorParams): string => `rgba(${c.red}, ${c.green}, ${c.blue}, ${c.opacity})`;

export const toRGBAWithBrandId = (c: ColorParams): string =>
    `rgba(${c.red}, ${c.green}, ${c.blue}, ${c.opacity}, ${c.brandColorId || 0})`;

export const toRGBAWithBrandIdObj = (rgbaString: string): ColorParams => {
    const [red, green, blue, opacity, brandColorId] = rgbaString.slice('rgba('.length, -1).split(',');

    return {
        red: Number(red.trim()),
        green: Number(green.trim()),
        blue: Number(blue.trim()),
        opacity: Number(opacity.trim()),
        brandColorId: brandColorId === '0' ? null : Number(brandColorId.trim()),
    };
};

export const ORDINARY_STROKE_COLOR_OBJ: ColorParams = { red: 0, green: 170, blue: 255, opacity: 1, brandColorId: null };

export const DEFAULT_BG_TEXT_COLOR: ColorParams = { red: 204, green: 204, blue: 204, opacity: 1, brandColorId: null };
