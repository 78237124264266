import React, { useEffect, useState } from 'react';
import { Input } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { ImageElement, ShapeElement, TextElement, VideoElement } from '@bynder-studio/render-core';
import editorAutocorrect from 'packages/pages/editor/EditorAutocorrectRules';
import { getDropShadowValueFromElement } from '~/common/editor/helpers';
import { useInputBlur } from 'packages/hooks/useInputBlur';
import generateTestId from '~/helpers/testIdHelpers';

type ShadowAngleProps = {
    disabled: boolean;
    onChange: (prop: string, value: any) => void;
    selectedElement: TextElement | ImageElement | VideoElement | ShapeElement;
};

const ShadowAngle = ({ disabled, onChange, selectedElement }: ShadowAngleProps) => {
    const { translate } = useTranslate();
    const { onFocus, unsubscribeListener } = useInputBlur();

    const [selectedShadowAngle, setSelectedShadowAngle] = useState(
        getDropShadowValueFromElement(selectedElement).angle,
    );

    useEffect(() => {
        setSelectedShadowAngle(getDropShadowValueFromElement(selectedElement).angle);
    }, [selectedElement]);

    const onAngleChange = (newValue) => {
        setSelectedShadowAngle(newValue);
    };

    const applyChanges = (val: number) => {
        const correctAngle = editorAutocorrect('element_rotation', val);
        setSelectedShadowAngle(correctAngle.toString());
        onChange('angle', correctAngle);
    };

    const handleOnBlur = () => {
        unsubscribeListener();
        applyChanges(Number(selectedShadowAngle));
    };

    const onKeyDown = (event) => {
        switch (event.key) {
            case 'Enter':
                applyChanges(Number(selectedShadowAngle));
                break;
            case 'ArrowUp':
            case 'ArrowDown': {
                event.preventDefault();
                const numberToAdd = event.key === 'ArrowDown' ? -1 : 1;
                const factor = event.shiftKey ? 10 : 1;
                applyChanges(Number(selectedShadowAngle) + numberToAdd * factor);
                break;
            }
        }
    };

    const testId = generateTestId('shots_styling_shadow__angle');

    return (
        <Input
            name={translate('editor.sidebar.shots.shadow.angle.label')}
            aria-label={translate('editor.sidebar.shots.shadow.angle.label')}
            type="number"
            value={selectedShadowAngle}
            onFocus={onFocus}
            onBlur={handleOnBlur}
            onChange={onAngleChange}
            onKeyDown={onKeyDown}
            isDisabled={disabled}
            suffix="°"
            {...testId}
        />
    );
};

export default ShadowAngle;
