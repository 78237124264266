import React, { useState } from 'react';
import RenameModal from './components/modals/RenameModal';

type Props = {
    onRename: (name: string) => void;
    propertyName?: string;
};

export function useContentPropertyRenameModal({ onRename, propertyName }: Props) {
    const [isOpen, setIsModalOpen] = useState(false);

    const renderContentPropertyRenameModal = () => {
        return (
            <RenameModal
                isOpen={isOpen}
                onClose={() => setIsModalOpen(false)}
                onRename={onRename}
                propertyName={propertyName || ''}
            />
        );
    };

    return {
        renderContentPropertyRenameModal,
        setIsContentPropertyRenameModalOpen: setIsModalOpen,
    };
}
