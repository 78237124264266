import BaseManipulation from '../BaseManipulation';
import {
    BaseCompElement,
    CORNERS,
    FontFamily,
    HandlerInteractionStates,
    Position,
    SIDES,
    TextElement,
} from '@bynder-studio/render-core';
import { TextEditor, TextEditorData, TextProps } from '@bynder-studio/structured-text';
import { createContextMenu } from '../../../Helpers/textContextMenu';

class TextManipulation extends BaseManipulation {
    fontFamilies: FontFamily[] = [];
    contextMenuRef: HTMLDivElement | null = null;
    redrawStart: number = null;
    offsetCache: Position | null = null;
    loopRedrawRequestId: number = null;
    redraw: (handleIndex: CORNERS | SIDES | null, state: HandlerInteractionStates | null, withHover?: boolean) => void =
        null;

    constructor(compositor, containerDocument, emitter, redraw, fontFamilies) {
        super(compositor, containerDocument, emitter);
        this.redraw = redraw;
        this.fontFamilies = fontFamilies;

        this.canvas.addEventListener('dblclick', this.onDblClick);
        this.canvas.addEventListener('mousedown', this.onMouseDown);
        window.addEventListener('contextmenu', this.onContextMenu);
    }

    prepareCanvasForSizeChange() {
        this.disableTextEditor();
    }

    selectElement = ({ selectedElements }) => {
        if (
            this.compositor.textEditorElement &&
            (selectedElements.length > 1 || selectedElements[0] !== this.compositor.textEditorElement)
        ) {
            this.disableTextEditor();
        }
    };

    initTextEditor = (fontFamilies) => {
        this.compositor.textEditor = new TextEditor({
            canvas: this.canvas,
            scale: this.compositor.getScale(),
            dpr: this.compositor.getDpr(),
            containerDocument: this.containerDocument,
            emitter: this.emitter,
            fontFamilies: fontFamilies,
            deactivate: this.disableTextEditor,
        });
    };

    clearTextEditor = () => {
        this.compositor.textEditor = null;
    };

    onClicks = (e) => {
        const actionName = e.target.dataset.name;

        switch (actionName) {
            case 'cut': {
                this.compositor.textEditor.cut();
                this.closeContextMenu();
                return;
            }
            case 'copy': {
                this.compositor.textEditor.copy();
                this.closeContextMenu();
                return;
            }
            case 'paste': {
                this.compositor.textEditor.paste();
                this.closeContextMenu();
                return;
            }

            default:
                return;
        }
    };

    onContextMenu = (e) => {
        if (e.target.tagName !== 'CANVAS') return;

        e.preventDefault();

        if (this.compositor.isContextMenuOpen) {
            this.closeContextMenu();
        }

        const elementId = this.getElementToSelect();
        if (!elementId || elementId !== this.compositor.textEditorElement) return;

        this.contextMenuRef = createContextMenu(this.onClicks);
        this.compositor.textEditor.renderContextMenu(this.contextMenuRef);
        this.compositor.isContextMenuOpen = true;
    };

    closeContextMenu() {
        this.contextMenuRef.remove();
        this.compositor.isContextMenuOpen = false;
    }

    clearOffsetCache() {
        this.offsetCache = null;
    }

    redrawText = () => {
        if (this.compositor.textEditor) {
            this.compositor.textEditor.draw();
        }
    };

    private handleTextChange = (
        id,
        formattedText: TextProps,
        textEditorData: TextEditorData,
        isTextEditorActivated: boolean,
    ) => {
        if (!this.getElementById(id)) return;

        const params = {
            id,
            formattedText,
            textEditorData,
            isTextEditorActivated,
        };

        this.emitter.emit<'requestElementTextUpdate'>('requestElementTextUpdate', params);
    };

    private onMouseDown = (e) => {
        if (e.button === 2) return;

        if (this.compositor.isContextMenuOpen && !e.target.closest('.text-editor-dropdown')) {
            this.closeContextMenu();
        }

        if (this.compositor.textEditorElement) {
            const elementId = this.getElementToSelect();
            if (this.compositor.resizeHandleIndex === -1 && elementId !== this.compositor.selectedElIds[0]) {
                // Because our connection with console is based on events and they are really async -
                // it means that we need to release all sidebar elements before textEditor will be closed.
                if (this.compositor.textEditor.isExternalInputFocused) {
                    (document.activeElement as HTMLInputElement)?.blur();
                }

                this.disableTextEditor();
            }
        }
    };

    disableTextEditor = () => {
        if (!this.compositor.textEditorElement) return;

        cancelAnimationFrame(this.loopRedrawRequestId);

        this.compositor.toggleTextEditor(null);
        this.compositor.textEditor.deactivate();
        this.clearTextEditor();
        this.redraw(null, null);
    };

    private loopRedraw = () => {
        if (this.loopRedrawRequestId) {
            cancelAnimationFrame(this.loopRedrawRequestId);
        }

        this.loopRedrawRequestId = requestAnimationFrame(() => {
            this.loopRedrawRequestId = 0;
            this.redraw(null, null, false);
            this.loopRedrawRequestId = requestAnimationFrame(this.loopRedraw);
        });
    };

    private setOffsetCache = (offsetCache: Position) => {
        this.offsetCache = offsetCache;
    };

    private getOffsets = (element: BaseCompElement) => {
        const elementWidth = element.originalElement.position.getX();
        const elementHeight = element.originalElement.position.getY();

        if (this.offsetCache) {
            return [this.offsetCache.getX() + elementWidth, this.offsetCache.getY() + elementHeight];
        }

        const dimension = this.compositor.currentCompModel.getDimension();
        const pan = this.compositor.getPanPosition();
        const factor = this.compositor.getScale() * this.compositor.devicePixelRatio;

        const x = pan.getX() / factor + (this.compositor.canvas.width / factor - dimension.getWidth()) / 2;
        const y = pan.getY() / factor + (this.compositor.canvas.height / factor - dimension.getHeight()) / 2;

        this.setOffsetCache(new Position(x, y));
        return [x + elementWidth, y + elementHeight];
    };

    requestToDraw() {
        this.checkTextEditorPermissions();
        this.updateOffsets();
        this.redrawText();
    }

    // As the only one component that can be drawn and delete/locked at the same time -
    // we need to this method to check do we need to disable editor or not.
    checkTextEditorPermissions = () => {
        const element = this.getElementById(this.compositor.textEditorElement);
        if (!element || element.originalElement.locked) {
            this.disableTextEditor();
        }
    };

    updateOffsets = () => {
        if (!this.compositor.textEditorElement) return;

        const element = this.getElementById(this.compositor.textEditorElement);
        const [offsetX, offsetY] = this.getOffsets(element);

        this.compositor.textEditor.updateDynamicData(offsetX, offsetY, this.compositor.getScale());
    };

    private requestActivateTextEditor(elementId: number, event) {
        this.emitter.emit<'requestActivateTextEditor'>('requestActivateTextEditor', { elementId, event });
    }

    activateTextEditor({ elementId, event }: { elementId: number; event: MouseEvent }) {
        this.initTextEditor(this.fontFamilies);

        const element = this.getElementById(elementId);
        const [offsetX, offsetY] = this.getOffsets(element);
        this.compositor.textEditor.setBText(element.originalElement as TextElement);
        this.compositor.textEditor.updateDynamicData(offsetX, offsetY, this.compositor.getScale());
        this.compositor.textEditor.activate(this.emitter, this.handleTextChange, event);
        this.compositor.toggleTextEditor(elementId);
        this.compositor.requestToChangeCursor('text');
        this.loopRedraw();
    }

    private onDblClick = (event: MouseEvent) => {
        const [elementId] = this.compositor.selectedElIds;
        const element = this.getElementById(elementId)?.originalElement;

        if (
            elementId &&
            elementId !== this.compositor.textEditorElement &&
            element instanceof TextElement &&
            !element?.locked
        ) {
            this.requestActivateTextEditor(elementId, event);
        }
    };

    unsubscribe() {
        this.canvas.removeEventListener('dblclick', this.onDblClick);
        this.canvas.removeEventListener('mousedown', this.onMouseDown);
        window.removeEventListener('contextmenu', this.onContextMenu);
        this.disableTextEditor();
    }
}

export default TextManipulation;
