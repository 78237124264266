import type { IAssetsLoader } from '@bynder-studio/render-core';
import { CanvasLayerCompositor } from '../CanvasLayerCompositor/CanvasLayerCompositor';

export default class CanvasShotCompositor extends CanvasLayerCompositor {
    constructor(assetLoader: IAssetsLoader, devicePixelRatio: number) {
        const virtualCanvasWrapperEl = document.createElement('div');
        super(virtualCanvasWrapperEl, assetLoader, devicePixelRatio);
        this.useFrameBufferForVideoAsset = true;
    }
}
