import styled from 'styled-components';
import { Button } from '@bynder/design-system';

export const AlignmentItem = styled.div`
    width: 25%;
`;

export const AlignmentButton = styled(Button)`
    width: 100%;
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;
