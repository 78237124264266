import styled from 'styled-components';
import { Form, Switch, textStyles, token } from '@bynder/design-system';

export const StyledFormLabel = styled(Form.Label)`
    margin-bottom: 0;
`;

export const StyledSwitch = styled(Switch)`
    ${textStyles.uiTextS};
    color: ${token.gray600};
    margin-bottom: ${token.spacing3};
`;

export const Box = styled.div`
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
`;
