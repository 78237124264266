import styled, { keyframes } from 'styled-components';
import { token } from '@bynder/design-system';

const dotKeyframes = keyframes`
    0% {
        opacity: 0.4;
        transform: scale(1, 1);
    }
    50% {
        opacity: 1;
        transform: scale(1.2, 1.2);
    }
    100% {
        opacity: 0.4;
        transform: scale(1, 1);
    }
`;

export const LoadingDots = styled.div`
    width: 100%;
`;

export const Dot = styled.div`
    animation: ${dotKeyframes} 1.5s infinite ease-in-out;
    background-color: ${token.brandPrimary500};
    border-radius: 6px;
    display: inline-block;
    height: 6px;
    width: 6px;
    margin: 0 2px;

    &:nth-child(2) {
        animation-delay: 0.5s;
    }

    &:nth-child(3) {
        animation-delay: 1s;
    }
`;
