import React, { FC } from 'react';
import { CompositeOperationTypes } from '@bynder-studio/render-core';
import { Dropdown, List, Thumbnail } from '@bynder/design-system';
import { IconBlendMode } from '@bynder/icons';
import { Translate, useTranslate } from '@bynder/localization';
import generateTestId from '~/helpers/testIdHelpers';
import { useBlendMode } from './useBlendMode';

export type BlendModeProps = {
    disabled: boolean;
};

const blendModeList = [
    [<Translate id="editor.element.blend-mode.normal" />, CompositeOperationTypes.SOURCE_OVER],
    [],
    [<Translate id="editor.element.blend-mode.darken" />, CompositeOperationTypes.DARKEN],
    [<Translate id="editor.element.blend-mode.multiply" />, CompositeOperationTypes.MULTIPLY],
    [<Translate id="editor.element.blend-mode.color-burn" />, CompositeOperationTypes.COLOR_BURN],
    [],
    [<Translate id="editor.element.blend-mode.lighten" />, CompositeOperationTypes.LIGHTEN],
    [<Translate id="editor.element.blend-mode.screen" />, CompositeOperationTypes.SCREEN],
    [<Translate id="editor.element.blend-mode.color-dodge" />, CompositeOperationTypes.COLOR_DODGE],
    [],
    [<Translate id="editor.element.blend-mode.overlay" />, CompositeOperationTypes.OVERLAY],
    [<Translate id="editor.element.blend-mode.soft-light" />, CompositeOperationTypes.SOFT_LIGHT],
    [<Translate id="editor.element.blend-mode.hard-light" />, CompositeOperationTypes.HARD_LIGHT],
    [],
    [<Translate id="editor.element.blend-mode.difference" />, CompositeOperationTypes.DIFFERENCE],
    [<Translate id="editor.element.blend-mode.exclusion" />, CompositeOperationTypes.EXCLUSION],
    [],
    [<Translate id="editor.element.blend-mode.hue" />, CompositeOperationTypes.HUE],
    [<Translate id="editor.element.blend-mode.saturation" />, CompositeOperationTypes.SATURATION],
    [<Translate id="editor.element.blend-mode.color" />, CompositeOperationTypes.COLOR],
    [<Translate id="editor.element.blend-mode.luminosity" />, CompositeOperationTypes.LUMINOSITY],
];
const blendModeMap = new Map();
blendModeList.forEach(([name, value]) => value && blendModeMap.set(value, name));

export const BlendMode: FC<BlendModeProps> = ({ disabled }) => {
    const { translate } = useTranslate();
    const { operation, locked, handleBlendModeChange } = useBlendMode();
    const testId = generateTestId('shots_blend_mode');

    return (
        <Dropdown
            trigger={({ isOpen, ...triggerProps }) => (
                <List.Item
                    subtext={blendModeMap.get(operation)}
                    isDisabled={locked || disabled}
                    thumbnail={<Thumbnail icon={<IconBlendMode />} />}
                    isSelected={isOpen}
                    rightElements={<Dropdown.Arrow />}
                    {...triggerProps}
                    {...testId}
                >
                    {translate('editor.element.blend-mode')}
                </List.Item>
            )}
            position="bottom"
        >
            {blendModeList.map(([name, value], index) =>
                value ? (
                    <Dropdown.Item
                        key={value.toString()}
                        onClick={() => handleBlendModeChange(value as CompositeOperationTypes)}
                        isChecked={value === operation}
                    >
                        {name}
                    </Dropdown.Item>
                ) : (
                    <Dropdown.Divider key={`divider_${name}`} />
                ),
            )}
        </Dropdown>
    );
};
