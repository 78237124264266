import React, { useCallback, useEffect, useState } from 'react';
import { catchResponseError } from 'packages/helpers/helpers';
import { checkAppVersion } from '../../helpers/appVersion';
import NotificationPopup from './NotificationPopup';

const UpdateChecker = () => {
    const [newVersionReleased, setNewVersionReleased] = useState(false);

    const handleClose = useCallback(() => {
        setNewVersionReleased(false);
    }, []);

    useEffect(() => {
        const checkVersionTimeout = APP_VERSION_CHECK_INTERVAL * 1000;

        let latestUpdate = Date.now();
        let checkTimeoutId = -1;

        const checkForUpdate = async () => {
            const isUpdateAvailable = await checkAppVersion().catch(catchResponseError);
            latestUpdate = Date.now();
            setNewVersionReleased(!!isUpdateAvailable);
        };

        // previously we relayed on setInterval for checking updates, but it didn't work well
        // for inactive tabs, so we switched to setTimeout and comparing time since last update
        const scheduleCheck = () => {
            const timeSinceLastUpdate = Date.now() - latestUpdate;

            checkTimeoutId = setTimeout(async () => {
                await checkForUpdate();
                scheduleCheck();
            }, checkVersionTimeout - timeSinceLastUpdate);
        };

        const stopChecking = () => {
            clearTimeout(checkTimeoutId);
        };

        const onVisibilityChange = async () => {
            if (document.visibilityState !== 'visible') {
                stopChecking();

                return;
            }

            const timeSinceLastUpdate = Date.now() - latestUpdate;

            if (timeSinceLastUpdate >= checkVersionTimeout) {
                await checkForUpdate();
            }

            scheduleCheck();
        };

        scheduleCheck();
        document.addEventListener('visibilitychange', onVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', onVisibilityChange);
            stopChecking();
        };
    }, []);

    return newVersionReleased ? <NotificationPopup onClose={handleClose} /> : null;
};

export default UpdateChecker;
