import styled, { CSSObject } from 'styled-components';
import { token } from '@bynder/design-system';

export const Box = styled.div`
    position: relative;
    height: 100%;
    // margin-right: calc(-1 * (${token.spacing6} - ${token.spacing2}));
`;

export const Cell = styled.div<{ cellStyle: CSSObject }>`
    ${(props) => ({ ...props.cellStyle })}
`;

export const LoadingLayer = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 1px;
    left: 0;
    right: 0;
`;
