import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCreatives } from 'packages/store/creatives/creatives.selectors';
import debounce from '~/helpers/debounce';
import {
    fetchCreativesByOptions,
    fetchCreativesBySearchQuery,
    handleDeselectAll,
} from '~/store/creatives/creatives.actions';
import useQueryParams from 'packages/hooks/useQueryParams';
import { MediaFilterType } from '../components/filters/components/MediaType/types';
import { OrderFilterType, SortingFilterType } from '../components/filters/components/Ordering/types';
import { OwnershipFilterType } from '../components/filters/components/Ownership/types';
import { StatusFilterType } from '../components/filters/components/Status/types';

const useFilters = () => {
    // if you want to predefine filters value on page load
    // create the filters state here and pass a value as a prop directly to filters
    const dispatch = useDispatch();
    const { setQueryParam } = useQueryParams();

    const { options } = useSelector(getCreatives);

    const onSearch = useMemo(() => {
        return debounce(
            (newSearchValue: string) => {
                dispatch(handleDeselectAll());
                dispatch(fetchCreativesBySearchQuery(newSearchValue));

                setQueryParam('search', newSearchValue);
            },
            1000,
            false,
        );
    }, [dispatch]);

    const onMediaTypeSelect = (type: MediaFilterType) => {
        const newOptions = { ...options };

        if (type === MediaFilterType.ANY) {
            newOptions.creativeType = undefined;
        } else {
            newOptions.creativeType = type;
        }

        dispatch(handleDeselectAll());
        dispatch(fetchCreativesByOptions(newOptions, true));

        setQueryParam('typeDesign', type.toLowerCase());
    };

    const onSort = (sortBy: SortingFilterType, sortOrder: OrderFilterType) => {
        const newOptions = { ...options };
        newOptions.sort = [sortBy, sortOrder];

        dispatch(handleDeselectAll());
        dispatch(fetchCreativesByOptions(newOptions, true));

        setQueryParam('sortedBy', sortBy);
        setQueryParam('sortOrder', sortOrder);
    };

    const onOwnershipSelect = (ownershipSource: OwnershipFilterType) => {
        const newOptions = { ...options };
        newOptions.ownershipSource = ownershipSource;

        dispatch(handleDeselectAll());
        dispatch(fetchCreativesByOptions(newOptions, true));

        setQueryParam('ownership', ownershipSource.toLowerCase());
    };

    const onStatusSelect = (status: StatusFilterType) => {
        const newOptions = { ...options };

        if (status === StatusFilterType.ANY_STATUS) {
            delete newOptions.approvalStatus;
        } else {
            newOptions.approvalStatus = status;
        }

        dispatch(handleDeselectAll());
        dispatch(fetchCreativesByOptions(newOptions, true));

        setQueryParam('approvalStatus', status.toLowerCase());
    };

    return { onSearch, onMediaTypeSelect, onSort, onOwnershipSelect, onStatusSelect };
};

export default useFilters;
