import React, { useMemo, useCallback, useEffect, useRef, useState } from 'react';
import { TruncatedText, Input, ListDragAndDrop, List } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { VariationItemBySizeIdType } from 'packages/variation-export/types';
import { catchResponseError } from 'packages/helpers/helpers';
import { useFriendlyTime } from '~/hooks/useFriendlyTime';
import useClickAway from '~/hooks/useClickAway';
import VariationsThumbnail from './VariationsThumbnail';
import VariationDropdownActions from './VariationDropdownActions';
import { MAX_VARIATION_NAME_LENGTH } from '../../VariationsContext/VariationsContext';
import { isVariationInvalid } from './utils';

type VariationItemProps = {
    variation: {
        id: string;
        oldId?: string;
        name: string;
        created: string;
        updated: string;
        sizes: VariationItemBySizeIdType;
    };
    idx: number;
    isSelected: boolean;
    isCurrentVariation: boolean;
    total: number;
    isDisabledByReview: boolean;
    isTextOverset: boolean;
    onSelect: ({
        id,
        idx,
        isSelected,
        shiftKey,
        ctrlKey,
    }: {
        id: string;
        idx: number;
        isSelected: boolean;
        shiftKey: boolean;
        ctrlKey: boolean;
    }) => void;
    onRename?: (id: string, trimedName: string) => void;
    onRenameLongError?: () => void;
    onChangeRenameMode?: (isInRenameMode: boolean) => void;
    onDelete?: (id: string) => void;
    onDuplicate?: (id: string) => void;
    isDuplicateDisabled?: boolean;
    onChangeOrder?: (from: number, to?: number) => void;
    onTranslateClick?: (id: number) => void;
    onReset?: (id: string) => void;
    isTranslateDisabled?: boolean;
};

const VariationItem = ({
    idx,
    variation,
    total,
    isSelected,
    isCurrentVariation,
    onSelect,
    onRename,
    onRenameLongError,
    onChangeRenameMode,
    onDelete,
    onDuplicate,
    onChangeOrder,
    onTranslateClick,
    onReset,
    isTranslateDisabled,
    isDuplicateDisabled,
    isDisabledByReview,
    isTextOverset,
}: VariationItemProps) => {
    const { id, oldId, sizes, name, created, updated } = variation;
    const { translate } = useTranslate();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [isInRenameMode, setIsInRenameMode] = useState<boolean>(false);
    const [newName, setNewName] = useState<string>('');
    const [showValidityStatus, setShowValidityStatus] = useState<boolean>(false);
    const { getFriendlyTime } = useFriendlyTime();

    const isUpdated = new Date(updated).getTime() > new Date(created).getTime();

    const description = isUpdated
        ? translate('design.sidebar.variations.variation.desc.edited', { time: getFriendlyTime(updated) })
        : translate('design.sidebar.variations.variation.desc.created', { time: getFriendlyTime(created) });

    const handleClick = useCallback(
        (e: MouseEvent) => {
            if (e.shiftKey) {
                e.stopPropagation();
                e.preventDefault();
            }

            if (onSelect) {
                onSelect({
                    id,
                    idx,
                    isSelected,
                    shiftKey: e.shiftKey,
                    ctrlKey: e.metaKey || e.ctrlKey,
                });
            }
        },
        [id, onSelect, isSelected],
    );

    const onResetClick = useCallback(() => {
        if (onReset) {
            onReset(id);
        }
    }, [onReset, id]);

    const enterRenameMode = useCallback(
        (e: MouseEvent): void => {
            if (isDisabledByReview) {
                return;
            }

            e.stopPropagation();
            setNewName(name);
            setIsInRenameMode(true);
            if (onChangeRenameMode) {
                onChangeRenameMode(true);
            }

            requestAnimationFrame(() => {
                inputRef.current?.querySelector('input')?.focus();
            });
        },
        [name],
    );

    const handleNameInputChange = useCallback(
        (value: string) => {
            setNewName(value);
        },
        [isInRenameMode, setNewName],
    );

    const exitRenameMode = useCallback(() => {
        setNewName('');
        setShowValidityStatus(false);
        setIsInRenameMode(false);
        if (onChangeRenameMode) {
            onChangeRenameMode(false);
        }
    }, []);

    const getInputNameStates = useCallback(() => {
        const trimedName = newName.trim();
        const isLonger = trimedName.length > MAX_VARIATION_NAME_LENGTH;

        return {
            trimedName,
            isLonger,
            isValid: !!trimedName && !isLonger,
        };
    }, [newName]);

    const handleClickOutside = useCallback(() => {
        if (!isInRenameMode) return;

        const { trimedName, isLonger, isValid } = getInputNameStates();

        if (isLonger) {
            if (onRenameLongError) {
                onRenameLongError();
            }
        } else if (isValid && newName !== name) {
            if (onRename) {
                onRename(id, trimedName);
            }
        }

        exitRenameMode();
    }, [newName, isInRenameMode, id, onRenameLongError, onRename]);

    const handleNameInputKeyUp = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                setShowValidityStatus(true);

                const { trimedName, isLonger, isValid } = getInputNameStates();

                if (isLonger) {
                    if (onRenameLongError) {
                        onRenameLongError();
                    }

                    exitRenameMode();
                    return;
                }

                if (isValid) {
                    if (onRename) {
                        onRename(id, trimedName);
                    }

                    setIsInRenameMode(false);
                    exitRenameMode();
                }
            } else if (e.key === 'Escape') {
                exitRenameMode();
            }
        },
        [id, newName, onRenameLongError, onRename],
    );

    const handleDocumentKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (e.shiftKey && !isInRenameMode) {
                document.onselectstart = () => false;
            }
        },
        [isInRenameMode],
    );

    const onDeleteClick = useCallback(() => {
        if (onDelete) {
            onDelete(id);
        }
    }, [id]);

    const onDuplicateClick = useCallback(() => {
        if (onDuplicate) {
            onDuplicate(id).catch(catchResponseError);
        }
    }, [id, onDuplicate]);

    const isInvalid = useMemo(
        () => isTextOverset || Object.values(sizes).some((entry) => isVariationInvalid(entry)),
        [sizes],
    );

    useClickAway(inputRef, handleClickOutside);

    useEffect(() => {
        const handleKeyUp = () => {
            document.onselectstart = null;
        };

        document.addEventListener('keydown', handleDocumentKeyDown);
        document.addEventListener('keyup', handleKeyUp);

        return () => {
            document.removeEventListener('keydown', handleDocumentKeyDown);
            document.removeEventListener('keydown', handleKeyUp);
        };
    }, []);

    return !isDisabledByReview ? (
        <ListDragAndDrop.Item
            isSelected={isSelected}
            onDoubleClick={enterRenameMode}
            isRenameMode={isInRenameMode}
            onClick={!isInRenameMode ? handleClick : undefined}
            rightElements={
                !isInRenameMode && !isDisabledByReview ? (
                    <VariationDropdownActions
                        variationId={id}
                        onRenameClick={enterRenameMode}
                        onDeleteClick={onDeleteClick}
                        onDuplicateClick={onDuplicateClick}
                        onTranslateClick={onTranslateClick}
                        onResetClick={onResetClick}
                        isTranslateDisabled={isTranslateDisabled || isInvalid}
                        isDuplicateDisabled={isDuplicateDisabled || isInvalid}
                        isOrderingDisabled={total <= 1}
                        isMoveTopUpDisabled={idx === 0}
                        isMoveDownBottomDisabled={idx === total - 1}
                        onTopClick={() => {
                            onChangeOrder && onChangeOrder(idx, 0);
                        }}
                        onUpClick={() => {
                            onChangeOrder && onChangeOrder(idx, idx - 1);
                        }}
                        onDownClick={() => {
                            onChangeOrder && onChangeOrder(idx, idx + 1);
                        }}
                        onBottomClick={() => {
                            onChangeOrder && onChangeOrder(idx);
                        }}
                    />
                ) : null
            }
            subtext={isInRenameMode ? '' : description}
            thumbnail={
                <VariationsThumbnail
                    variationId={id.toString()}
                    oldVariationId={oldId}
                    sizes={sizes}
                    isCurrentVariation={isCurrentVariation}
                    isInvalid={isInvalid}
                />
            }
        >
            {isInRenameMode ? (
                <Input
                    autoFocus
                    inputRef={inputRef}
                    value={newName}
                    onChange={handleNameInputChange}
                    onKeyUp={handleNameInputKeyUp} // Add the onKeyUp prop
                    isInvalid={showValidityStatus && !newName.trim()}
                    placeholder={translate('design.sidebar.variations.variation.edit.placeholder')}
                    hasClearButton
                />
            ) : (
                <TruncatedText>{name}</TruncatedText>
            )}
        </ListDragAndDrop.Item>
    ) : (
        <List.Item
            isSelected={isSelected}
            onClick={handleClick}
            subtext={description}
            thumbnail={
                <VariationsThumbnail
                    variationId={id.toString()}
                    oldVariationId={oldId}
                    sizes={sizes}
                    isCurrentVariation={isCurrentVariation}
                    isInvalid={isInvalid}
                />
            }
        >
            <TruncatedText>{name}</TruncatedText>
        </List.Item>
    );
};

export default VariationItem;
