import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs, Inspector } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import CreativeTypes from '~/helpers/CreativeTypes';
import features from '~/configs/features';
import generateTestId from '~/helpers/testIdHelpers';
import useAccessRights from 'packages/hooks/useAccessRights';
import { useReviewStatus } from 'packages/pages/design/sidebar/shots/useReviewStatus';
import Variations from './variations/Variations';
import ResetPage from './variations/ResetPage';
import { DesignTab } from './types';

const Sidebar = ({ children }) => {
    const { translate } = useTranslate();
    const { isPlatformAllowed } = useAccessRights();
    const { isReviewer, isInReviewMode, isEditsRequestedMode, isCollaborator, isInApprovedMode } = useReviewStatus();
    const [designTab, setDesignTab] = useState(DesignTab.SCENE);
    const { creativeType } = useSelector((state) => ({
        creativeType: state.creatives.creative.creativeType,
    }));

    const isMultiVariationAvailable = useMemo(() => isPlatformAllowed([features.MULTI_VARIATION]));

    const showResetButton = useMemo(() => {
        if (designTab !== DesignTab.SCENE) {
            return false;
        }

        if (isReviewer) {
            return isCollaborator && !isInApprovedMode && !isInReviewMode;
        }

        return !isInApprovedMode;
    }, [designTab, isReviewer, isCollaborator, isEditsRequestedMode, isInApprovedMode, isInReviewMode]);

    return (
        <Inspector {...generateTestId('design_configuration_sidebar')}>
            {isMultiVariationAvailable && (
                <Inspector.Tabs>
                    <Tabs>
                        <Tabs.Tab
                            label={
                                creativeType === CreativeTypes.Video
                                    ? translate('design.sidebar.scene')
                                    : translate('design.sidebar.image')
                            }
                            isActive={designTab === DesignTab.SCENE}
                            onClick={() => {
                                setDesignTab(DesignTab.SCENE);
                            }}
                        />
                        <Tabs.Tab
                            label={translate('design.sidebar.variations')}
                            isActive={designTab === DesignTab.VARIATIONS}
                            onClick={() => {
                                setDesignTab(DesignTab.VARIATIONS);
                            }}
                        />
                    </Tabs>
                </Inspector.Tabs>
            )}

            <Inspector.Content>{designTab === DesignTab.SCENE ? <>{children}</> : <Variations />}</Inspector.Content>
            {showResetButton && <ResetPage />}
        </Inspector>
    );
};

export default Sidebar;
