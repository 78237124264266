import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const TimelineElementStyled = styled.div`
    position: absolute;
    height: 38px;
    width: 200px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    color: ${token.white};
    cursor: pointer;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: ${token.brandPrimary500};
    box-shadow: inset 3px 0 0 ${token.colorShadow};

    &.short-element-name {
        height: 38px;
    }

    &.derivative {
        background-color: ${token.teal500};
        box-shadow: inset 3px 0 0 ${token.colorShadow};
    }

    &.unlinked {
        background-color: ${token.gray400};
        box-shadow: inset 3px 0 0 ${token.colorShadow};
    }

    &.dragging {
        background-color: ${token.gray800} !important;
        z-index: 10001;
        pointer-events: none;
    }

    &.dragging .timeline__element__left-edge {
        background-color: ${token.gray800};
    }

    &.ghost-element {
        opacity: 0.4;
        pointer-events: none;
    }

    &.hasMinorError:not(.hasMajorError) .timeline__element__left-edge {
        border-left: 5px solid ${token.red400} !important;
    }

    &.hasMajorError {
        background-color: ${token.red500};
    }

    &.hasMajorError .timeline__element__left-edge {
        background-color: ${token.red500} !important;
    }

    &.selected {
        background-color: ${token.gray800} !important;
        box-shadow:
            0 2px 8px -2px ${token.colorShadow},
            inset 3px 0px 0px ${token.colorShadow};
    }

    &.selected .timeline__element__left-edge {
        background-color: ${token.gray900};
        border: none !important;
    }

    &.is-group .timeline__element__left-edge {
        flex-basis: 44px;
    }

    &.hidden {
        opacity: 0.4;
        z-index: 10;
    }

    .tooltip-inner {
        height: 38px;
        padding-top: 0;
        padding-bottom: 0;
    }

    &.content-property {
        background-color: ${token.teal500};
    }

    .timeline__element__left-edge {
        display: flex;
        flex: 0 0 5px;
        align-items: center;
        justify-content: center;
        width: 5px;
        height: 100%;
        background-color: ${token.gray200a};
    }

    .timeline__element__content {
        flex-grow: 1;
        display: flex;
        align-items: center;
        padding-right: 10px;
    }

    .timeline__element__resize-handle {
        position: absolute;
        top: 0;
        width: 5px;
        height: 100%;
        cursor: ew-resize;
    }

    .resize-handle-left {
        left: 0;
    }

    .resize-handle-right {
        right: 0;
    }

    .timeline__element__expand-icon {
        width: 16px;
        height: 16px;
        transition: transform 0.2s ease-out;
    }

    .timeline__element__expand-icon > svg {
        display: block;
        margin: -3px;
    }

    .timeline__element__expand-icon.expanded {
        transform: rotate(90deg);
    }

    .timeline__element__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 8px 0 12px;
    }

    .timeline__element_icon_unlinked {
        margin-left: 0;
        margin-right: 8px;
        color: ${token.white};
    }

    .timeline__element__name {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1em;
    }

    .timeline__element__name .timeline__element__name-editable {
        color: ${token.white};
    }

    .tooltip .timeline__element__name {
        overflow: initial;
    }

    .timeline__element__indicators {
        display: flex;
        height: inherit;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        padding: 2px 0;
    }

    .tooltip .timeline__element__indicators {
        margin: 0 10px 0 5px;
    }

    .timeline__element__indicators .timeline__element__indicators__line {
        display: flex;
        flex: 1 0 50%;
        align-items: center;
    }

    .timeline__element__visibility {
        width: 16px;
        height: 16px;
        pointer-events: none;
    }

    .timeline__element__lock {
        width: 16px;
        height: 16px;
        pointer-events: none;
    }

    .timeline__element__animation {
        width: 18px;
        height: 15px;
    }

    &.is-group > .timeline__element__content {
        max-width: calc(100% - 44px);
    }
`;
