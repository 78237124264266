import { useEffect, useRef } from 'react';

export const useChanges = (callback, deps) => {
    const isMounted = useRef(false);

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            return;
        }
        callback();
    }, deps);
};
