import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Slider } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconVerticalConstraints } from '@bynder/icons';
import { getScaleValueFromElement, getVerticalOffsetValueFromElement } from '~/common/editor/helpers';
import useElement from '../../hooks/useElement';
import useDesignElementContentTransform from '../../hooks/useDesignElementContentTransform';

function useVerticalOffset(id) {
    const { key, element, updateElement } = useElement(id);
    const contentTransform = useDesignElementContentTransform(id);
    const { naturalDimension, dimension } = element;
    const { height: naturalHeight } = naturalDimension;
    const { height } = dimension;
    const [scale, setScale] = useState(() => getScaleValueFromElement(element));
    const [value, setValue] = useState(() => Math.min(Math.ceil(height), getVerticalOffsetValueFromElement(element)));

    const controlOptions = useMemo(
        () => ({
            min: Math.floor(-1 * scale * naturalHeight),
            max: Math.ceil(height),
        }),
        [naturalHeight, height, scale],
    );

    const handleOnChange = useCallback(
        (newValue) => {
            setValue(newValue);
            const param = {
                contentTransform: {
                    ...contentTransform,
                    verticalOffset: newValue,
                },
            };
            updateElement(param);
        },
        [contentTransform, updateElement],
    );

    useEffect(() => {
        setScale(contentTransform.scale);
        setValue(Math.min(contentTransform.verticalOffset, Math.ceil(height)));
    }, [key, contentTransform]);

    useEffect(() => {
        setScale(getScaleValueFromElement(element));
        setValue(Math.min(Math.ceil(height), getVerticalOffsetValueFromElement(element)));
    }, [key, element]);

    return { value, handleOnChange, controlOptions };
}

const VerticalOffset = ({ id, disabled }) => {
    const { translate } = useTranslate();
    const { value, handleOnChange, controlOptions } = useVerticalOffset(id);

    return (
        <Form.Group>
            <Form.Label>{translate('design.sidebar.scene.options.crop-mode.vertical-offset.label')}</Form.Label>
            <Slider
                value={value}
                min={controlOptions.min}
                max={controlOptions.max}
                onChange={handleOnChange}
                isDisabled={disabled}
                isFromCenter
                inputPosition="right"
                iconLeft={<IconVerticalConstraints />}
            />
        </Form.Group>
    );
};

export default VerticalOffset;
