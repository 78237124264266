import { type RefObject, useCallback, useRef } from 'react';
import { useDragEvents } from './useDragEvents';

type Props = {
    controlRef: RefObject<Element>;
    containerRef: RefObject<HTMLElement>;
    maxContainerRef?: RefObject<HTMLElement>;
    onDrag: (leftPercentage: number) => void;
    onDrop?: (leftPercentage: number) => void;
    useWidthForMaxLeft?: boolean;
};

export const useHorizontalDragging = ({
    controlRef,
    containerRef,
    maxContainerRef,
    onDrag,
    onDrop,
    useWidthForMaxLeft = false,
}: Props) => {
    const bBoxRect = useRef<DOMRect | null>(null);
    const containerBBoxRect = useRef<DOMRect | null>(null);
    const maxLeft = useRef(0);

    const onDragStart = useCallback(() => {
        bBoxRect.current = controlRef.current.getBoundingClientRect();
        containerBBoxRect.current = containerRef.current.getBoundingClientRect();

        if (!bBoxRect instanceof DOMRect || !containerBBoxRect instanceof DOMRect) {
            return;
        }

        if (maxContainerRef?.current) {
            const maxContainerBoxRef = maxContainerRef.current.getBoundingClientRect();
            maxLeft.current = maxContainerBoxRef.width + maxContainerBoxRef.left - containerBBoxRect.current.left;
        } else {
            maxLeft.current = containerBBoxRect.current.width - Number(useWidthForMaxLeft) * bBoxRect.current.width;
        }
    }, [containerRef, controlRef, maxContainerRef, useWidthForMaxLeft]);

    const onDragEvent = useCallback(
        (deltaX: number) => {
            if (!bBoxRect.current instanceof DOMRect || !containerBBoxRect.current instanceof DOMRect) {
                return;
            }

            const elementLeft = bBoxRect.current.left - containerBBoxRect.current.left;
            const left = Math.min(Math.max(elementLeft + deltaX, 0), maxLeft.current);

            const leftPercentage =
                100 / (containerBBoxRect.current.width ? containerBBoxRect.current.width / left : left);

            requestAnimationFrame(() => {
                controlRef.current.style.left = `${leftPercentage}%`;
            });

            onDrag(leftPercentage);
        },
        [controlRef, onDrag],
    );

    const onDragEnd = useCallback(() => {
        if (!containerBBoxRect instanceof DOMRect || !controlRef.current instanceof Element) {
            return;
        }

        const boundingBoxStyle = getComputedStyle(controlRef.current);
        const styleLeft = parseFloat(boundingBoxStyle.left);

        const left = styleLeft - containerBBoxRect.current.left;
        const leftPercentage = 100 / (containerBBoxRect.current.width / left);

        if (onDrop) {
            onDrop(leftPercentage);
        }
    }, [controlRef, onDrop]);

    const [onMouseDown] = useDragEvents({ onDragStart, onDrag: onDragEvent, onDragEnd });

    return onMouseDown;
};
