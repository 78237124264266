import React, { FC } from 'react';
import { Container, Control, Handle, BackgroundControlLine } from './ZoomControl.styled.';
import { useZoomControl, ZoomControlChangeHandler } from './useZoomControl';

type ZoomControlProps = {
    onChange: ZoomControlChangeHandler;
    duration: number;
};

export const ZoomControl: FC<ZoomControlProps> = ({ onChange, duration }) => {
    const {
        containerRef,
        controlRef,
        handleControlMouseDown,
        handleControlDoubleClick,
        handleLeftHandleMouseDown,
        handleRightHandleMouseDown,
    } = useZoomControl(onChange, duration);

    return (
        <Container ref={containerRef}>
            <BackgroundControlLine />
            <Control ref={controlRef} onMouseDown={handleControlMouseDown} onDoubleClick={handleControlDoubleClick}>
                <Handle onMouseDown={handleLeftHandleMouseDown} />
                <Handle onMouseDown={handleRightHandleMouseDown} />
            </Control>
        </Container>
    );
};
