import styled from 'styled-components';
import { Thumbnail, Button, token, List } from '@bynder/design-system';

export const FakeAssetOption = styled.div`
    padding: 8px 16px;
    color: ${token.gray600};
`;

export const AssetThumbnail = styled(Thumbnail)`
    min-width: 40px;
`;

export const AssetButton = styled(List.Item)`
    width: ${(props) => (props.subtext ? 'calc(100% - 50px)' : 'calc(100% + 12px)')};
    //width: ;
`;

export const RemoveButton = styled(Button)`
    min-width: 50px;
`;
