import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, notify } from '@bynder/design-system';
import { Translate } from '@bynder/localization';
import { FileType } from '~/store/amplitude/types';
import VariationSetsService from '~/services/VariationSetsService';
import { sendAmplitudeVariationEvent } from '~/store/amplitude/actions';
import { AMPLITUDE_TYPES } from '~/store/amplitude/constants';
import useDesign from '../../hooks/useDesign';
import useVariations from '../../hooks/useVariations';
import { catchResponseError } from 'packages/helpers/helpers';
import modalContainer from 'packages/common/modalContainer';

export type UploadModalRef = {
    open: () => void;
};

export type UploadModalProps = {};

const limitErrorAppCode: number[] = [11401, 19001];
const limitErrorTitle: string = 'Limit of 50 variations reached';
const limitErrorMessage: string = `<p>
    The total amount of variations can’t be more than 50
    <br />
    <br />
    This is what you can do:
    <br />
    <ul>
        <li>Edit the data file by removing variation rows</li>
        <li>Manually remove variations from the variations sidebar</li>
        <li>Choose another file and try again</li>
    </ul>
</p>`;

export const UploadModal = forwardRef<UploadModalRef, UploadModalProps>((props, ref) => {
    const dispatch = useDispatch();
    const { creativeId } = useDesign();
    const { getVariationsAndSave } = useVariations();
    const inputRef = useRef<HTMLInputElement>(null);
    const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
    const [errorTitle, setErrorTitle] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    useImperativeHandle(ref, () => ({
        open() {
            inputRef.current?.click();
        },
    }));

    const closeErrorModal = useCallback(() => {
        setOpenErrorModal(false);
    }, []);

    const handleFileChange = useCallback<(event: React.ChangeEvent) => void>(
        (event) => {
            const files = (event.target as HTMLInputElement).files || [];
            const selectedFile = files[0];
            if (!selectedFile) return;

            const formData = new FormData();
            formData.append('file', selectedFile);
            const id = selectedFile.name;
            const notificationTitle = `Uploading ${selectedFile.name}`;

            notify({
                id,
                title: notificationTitle,
                description: 'Uploading...',
                isPersistent: true,
                variant: 'loading',
                button: <></>,
            });

            VariationSetsService.batchUpload(creativeId, formData)
                .then(({ status, json }) => {
                    if (inputRef.current) {
                        inputRef.current.value = '';
                    }

                    if (status === 200) {
                        notify({
                            id,
                            title: `Successfully created ${json.totalItems} variation${
                                json.totalItems === 1 ? '' : 's'
                            }`,
                            isPersistent: false,
                            variant: 'success',
                        });

                        const fileType = selectedFile.name
                            .slice(selectedFile.name.lastIndexOf('.') + 1)
                            .toUpperCase() as FileType;
                        dispatch(
                            sendAmplitudeVariationEvent({
                                eventType: AMPLITUDE_TYPES.UPLOAD_VARIATION,
                                additionalProps: {
                                    variationAmount: json.items.length || 1,
                                    fileType: fileType,
                                },
                            }),
                        );

                        getVariationsAndSave();
                    } else if (limitErrorAppCode.includes(json.appErrorCode)) {
                        setErrorTitle(limitErrorTitle);
                        setErrorMessage(limitErrorMessage);
                        setOpenErrorModal(true);
                        notify.dismiss(id);
                    } else if (json && json.appErrorCode && json.appErrorCode === 15004) {
                        notify({
                            id,
                            variant: 'error',
                            isPersistent: false,
                            title: <Translate id="design.save.approval.error" />,
                        });
                    } else {
                        notify({
                            id,
                            title: notificationTitle,
                            description: 'File not supported or invalid. Please choose another file and try again.',
                            isPersistent: false,
                            variant: 'error',
                        });
                    }
                })
                .catch((err) => {
                    notify({
                        id,
                        title: `Error uploading ${selectedFile.name}`,
                        description: 'Something went wrong. Please try again.',
                        isPersistent: false,
                        variant: 'error',
                    });
                    catchResponseError(err);
                });
        },
        [creativeId, getVariationsAndSave],
    );

    return (
        <>
            <input
                ref={inputRef}
                onChange={handleFileChange}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                type="file"
                hidden
            />
            <Modal
                container={modalContainer}
                title={errorTitle}
                isOpen={openErrorModal}
                onClose={closeErrorModal}
                actionPrimary={
                    <Button variant="primary" onClick={closeErrorModal}>
                        Ok, got it
                    </Button>
                }
            >
                <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
            </Modal>
        </>
    );
});
