import { useSelector } from 'react-redux';
import { getCreativesActionProgress } from 'packages/store/creatives/creatives.selectors';
import {
    getImportActionProgress,
    getImportedTemplate,
    getImportTemplateAnalysis,
    getImportTemplateErrors,
    getImportTemplateType,
    getImportTemplateValidationErrors,
} from 'packages/store/importTemplate/importTemplate.selectors';
import { getNewDesignPage, getSelectedBlankType } from 'packages/store/newDesign/newDesign.selectors';
import { Template } from 'packages/store/templates/types';
import { ImportedTemplate, ImportType } from 'packages/store/importTemplate/types';
import { selectedTemplateSelector } from '~/store/templates/templates.selectors';
import { selectedGallerySelector } from '~/store/gallery/gallery.selectors';
import { getFigmaState } from 'packages/store/figma/figma.selectors';
import { DesignSubPages } from './components/Sidebar/types';

const useCreationDisabledMap = () => {
    const activePage = useSelector(getNewDesignPage);
    const selectedType = useSelector(getSelectedBlankType);
    const selectedTemplate: Template = useSelector(selectedTemplateSelector);
    const selectedGallery: Template = useSelector(selectedGallerySelector);
    const importedTemplate: ImportedTemplate = useSelector(getImportedTemplate);
    const importedErrors = useSelector(getImportTemplateErrors);
    const importedValidationErrors = useSelector(getImportTemplateValidationErrors);
    const importType = useSelector(getImportTemplateType);
    const analysis = useSelector(getImportTemplateAnalysis);
    const { selectedFrameIds: selectedFrames } = useSelector(getFigmaState);
    const { addingInProgress: addingInProgressXML } = useSelector(getCreativesActionProgress);
    const { addingInProgress: addingInProgressDesign } = useSelector(getImportActionProgress);

    const creationDisabledMap = {
        [DesignSubPages.ALL]: !selectedTemplate && !selectedGallery && !selectedType,
        [DesignSubPages.BLANK]: !selectedType,
        [DesignSubPages.CATEGORIES]: !selectedTemplate,
        [DesignSubPages.CATEGORY_DETAILS]: !selectedTemplate,
        [DesignSubPages.TEMPLATE_PREVIEW]: !selectedTemplate,
        [DesignSubPages.GALLERY]: !selectedGallery,
        [DesignSubPages.GALLERY_PREVIEW]: !selectedGallery,
        [DesignSubPages.IMPORT]: true,
        [DesignSubPages.IMPORT_STATUS]: (() => {
            if (importType === ImportType.XML) {
                return !importedTemplate || importedErrors || importedValidationErrors?.length || addingInProgressXML;
            }

            return (
                analysis?.designCreationResponses?.length === importedValidationErrors?.length || addingInProgressDesign
            );
        })(),
        [DesignSubPages.FIGMA_IMPORT]: !selectedFrames.length,
    };
    const isCreationDisabled = () => creationDisabledMap[activePage] || false;

    return { isCreationDisabled };
};

export default useCreationDisabledMap;
