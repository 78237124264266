import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isInteractiveElement } from '@bynder-studio/misc/src/utils';
import { setTimelineZoom } from 'packages/store/creativeEditor/creativeEditor.actions';
import { isCtrlKey } from '~/helpers/hotKeys';
import { ShotsProvider } from '~/contexts/ShotsContext';
import { creativeEditorSelector } from 'packages/store/creativeEditor/creativeEditor.selectors';
import { collectTopLevelElements } from '~/common/editor/timeline/timeline-actions/components/utils';
import { type ControlShortcutMode } from 'packages/hooks/usePlaybackShortcuts';
import useEditor from '../../../hooks/useEditor';
import { AddElementDropdown } from '../editorComponents/AddElementDropdown';
import { Shots } from './shots/Shots';
import Tracks from './tracks';
import { TimelineActions } from './timeline-actions/TimelineActions';
import { ZoomControl } from './zoom/ZoomControl';
import { useTimelineResizing } from './helpers/resize-timeline';
import PlaybackControls from './PlaybackControls/PlaybackControls';
import {
    TimelineActionsWrapper,
    TimelineContainer,
    TimelinePrimaryAction,
    TimelineResizeLine,
    TimelineShotsWrapper,
    TimelineTracksWrapper,
    TimelineWrapper,
    TimelineZoomWrapper,
} from './Timeline.styled';
import { useArrangeShortcuts } from 'packages/hooks/useArrangeShortcuts';

export const Timeline = ({ onResize }) => {
    const dispatch = useDispatch();
    const { creativeId } = useSelector(creativeEditorSelector);
    const { creativeModel, manipulationRenderer } = useEditor();
    const shotsContainerRef = useRef<HTMLDivElement | null>(null);
    const elementContainerRef = useRef<HTMLDivElement | null>(null);
    const timestampsContainerRef = useRef<HTMLDivElement | null>(null);
    const elementWrapperRef = useRef<HTMLDivElement | null>(null);
    const [containerRef, onMouseDown] = useTimelineResizing(creativeId, onResize);
    const elements = creativeModel.getAllElementsRecursively();
    const selectedElements = manipulationRenderer?.getSelectedElements() || [];
    const [controlShortcutMode, setControlShortcutMode] = useState<ControlShortcutMode>('playhead');

    const onChangeControlShortcutMode = useCallback(
        (mode: ControlShortcutMode) => {
            setControlShortcutMode(mode);

            if (mode === 'playhead') {
                window.requestAnimationFrame(() => {
                    if (!manipulationRenderer) {
                        return;
                    }

                    manipulationRenderer.canvasManipulation.disableTextEditor();
                });
            }
        },
        [manipulationRenderer],
    );

    const handleSelectAll = useCallback(() => {
        const selectedElements = manipulationRenderer.getSelectedElements();
        const editorElements = creativeModel.getAllElementsRecursively();

        const notSelectedElements = editorElements.filter(
            (el) => !selectedElements.some((selected) => selected.id === el.id),
        );

        collectTopLevelElements(notSelectedElements).forEach((el) => {
            manipulationRenderer.selectElement(el.id, true);
        });
    }, [creativeModel, manipulationRenderer]);

    const handleLocking = useCallback(() => {
        const selectedElements = manipulationRenderer?.getSelectedElements() || [];

        if (selectedElements.length > 0) {
            selectedElements.forEach((el) => {
                creativeModel.updateElement(el.id, {
                    locked: !el.locked,
                });
            });
        }
    }, [creativeModel, manipulationRenderer]);

    const handleHiding = useCallback(() => {
        const selectedElements = manipulationRenderer?.getSelectedElements() || [];

        if (selectedElements.length > 0) {
            selectedElements.forEach((el) => {
                creativeModel.updateElement(el.id, {
                    hidden: !el.hidden,
                });
            });
        }
    }, [creativeModel, manipulationRenderer]);

    const handleHotKeys = useCallback(
        (event) => {
            if (isCtrlKey(event)) {
                if (!event.shiftKey && !isInteractiveElement(event) && event.code === 'KeyA') {
                    event.preventDefault();
                    handleSelectAll();
                }

                if (event.shiftKey && !isInteractiveElement(event) && event.code === 'KeyL') {
                    event.preventDefault();
                    handleLocking();
                }

                if (event.shiftKey && !isInteractiveElement(event) && event.code === 'KeyH') {
                    event.preventDefault();
                    handleHiding();
                }
            }
        },
        [handleSelectAll, manipulationRenderer, handleLocking, handleHiding],
    );

    const onResizeEnd = useCallback(() => {
        dispatch(setTimelineZoom());
    }, []);

    useArrangeShortcuts({
        creativeModel,
        elements,
        selectedElements,
    });

    const handleTimelineClick = useCallback(
        (event: MouseEvent) => {
            const isElement = Boolean(event.target.closest('.timeline__element'));

            if (!isElement) {
                onChangeControlShortcutMode('playhead');
            }
        },
        [onChangeControlShortcutMode],
    );

    useEffect(() => {
        window.addEventListener('keydown', handleHotKeys);

        return () => {
            window.removeEventListener('keydown', handleHotKeys);
        };
    }, [handleHotKeys]);

    useEffect(() => {
        if (!manipulationRenderer) {
            return;
        }

        const listener = ({ elements, selectedByShot }) => {
            if (elements.length && !selectedByShot) {
                setControlShortcutMode('element');
            }
        };

        manipulationRenderer.eventEmitter.on('elementSelected', listener);

        return () => {
            manipulationRenderer.eventEmitter.off('elementSelected', listener);
        };
    }, [manipulationRenderer]);

    return (
        <>
            <TimelineResizeLine onMouseDown={onMouseDown} />
            <TimelineContainer ref={containerRef} onClick={handleTimelineClick}>
                <PlaybackControls controlShortcutMode={controlShortcutMode} />
                <TimelineWrapper>
                    <TimelinePrimaryAction>
                        <AddElementDropdown />
                    </TimelinePrimaryAction>
                    <TimelineShotsWrapper>
                        <ShotsProvider>
                            <Shots
                                controlShortcutMode={controlShortcutMode}
                                shotsContainerRef={shotsContainerRef}
                                elementWrapperRef={elementWrapperRef}
                            />
                        </ShotsProvider>
                    </TimelineShotsWrapper>
                    <TimelineActionsWrapper>
                        <TimelineActions />
                    </TimelineActionsWrapper>
                    <TimelineTracksWrapper>
                        <Tracks
                            elementContainerRef={elementContainerRef}
                            timestampsContainerRef={timestampsContainerRef}
                            elementWrapperRef={elementWrapperRef}
                        />
                    </TimelineTracksWrapper>
                    <TimelineZoomWrapper>
                        <ZoomControl
                            shotsContainerRef={shotsContainerRef}
                            timestampsContainerRef={timestampsContainerRef}
                            elementContainerRef={elementContainerRef}
                            onResizeEnd={onResizeEnd}
                        />
                    </TimelineZoomWrapper>
                </TimelineWrapper>
            </TimelineContainer>
        </>
    );
};
