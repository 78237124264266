import React, { memo, useCallback } from 'react';
import { Dropdown, Flex, token, TruncatedText } from '@bynder/design-system';
import { IconError } from '@bynder/icons';
import usePageValidation from '~/hooks/usePageValidation';
import useSizes from 'packages/pages/designCreateModal/components/Blank/useSizes';
import generateTestId from '~/helpers/testIdHelpers';
import { PageMeta } from '../types';
import { WarningIconWrapper } from 'packages/pages/editor/MultiPageDropdown/components/Component.styled';

type PageItemProps = {
    sizeIndex: number;
    isSelected?: boolean;
    name: string;
    onSelect: (sizeIndex: number) => void;
    sizeMetaData: PageMeta;
    isVideoModel: boolean;
};

function PageItem({ sizeIndex, isSelected, name, onSelect, isVideoModel, sizeMetaData }: PageItemProps) {
    const isValid = usePageValidation(sizeIndex);

    const { videoCategories, imageCategories, findByFormatKey } = useSizes('', '', true);

    const displayItem = findByFormatKey(isVideoModel ? videoCategories : imageCategories, sizeMetaData.format);

    const handleClick = useCallback(() => {
        if (onSelect) {
            onSelect(sizeIndex);
        }
    }, [sizeIndex, onSelect]);

    return (
        <Dropdown.Item
            checkedVariant="checkmark"
            isChecked={isSelected}
            icon={displayItem.icon}
            onClick={handleClick}
            {...generateTestId('editor-multipage-dropdown-page-item')}
        >
            <Flex alignItems="center">
                {!isValid && (
                    <WarningIconWrapper>
                        <IconError style={{ color: token.red400 }} />
                    </WarningIconWrapper>
                )}
                <TruncatedText>{name}</TruncatedText>
            </Flex>
        </Dropdown.Item>
    );
}

export default memo(PageItem);
