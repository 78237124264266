import React, { useState } from 'react';
import { Button, InputField, Modal } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import modalContainer from 'packages/common/modalContainer';

type ModalProps = {
    show: boolean;
    pageName: string;
    onClose: () => void;
    onConfirm: (name: string) => void;
};

const PageRenameModal = ({ show, pageName, onClose, onConfirm }: ModalProps) => {
    const [name, setName] = useState(pageName);
    const [isValid, setIsValid] = useState(true);
    const { translate } = useTranslate();
    const MAX_INPUT_SIZE = 120;

    const isInputLong = name.length > MAX_INPUT_SIZE;

    const onChange = (name) => {
        if (!name) {
            setIsValid(false);
        } else {
            setIsValid(true);
        }

        setName(name);
    };

    const onKeyEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();

            if (isValid) {
                onChange(name);
                onConfirm(name);
            }
        }
    };

    return (
        <Modal
            container={modalContainer}
            title={translate('editor.page.rename.modal.title')}
            isOpen={show}
            onClose={onClose}
            actionSecondary={
                <Button variant="secondary" onClick={onClose}>
                    {translate('editor.page.rename.modal.cancel')}
                </Button>
            }
            actionPrimary={
                <Button
                    variant="primary"
                    onClick={() => onConfirm(name)}
                    isDisabled={pageName === name || !isValid || isInputLong}
                >
                    {translate('editor.page.rename.modal.rename')}
                </Button>
            }
        >
            <InputField
                value={name}
                onChange={onChange}
                isInvalid={isInputLong}
                label=""
                helperText={isInputLong && translate('input.error.max.length')}
                onKeyDown={onKeyEnter}
            />
        </Modal>
    );
};

export default PageRenameModal;
