import { setFontFamilies, type FontFamily } from '@bynder-studio/structured-text';
import { Dimension } from '../Models/Shared/Dimension';
import { ImageModel } from '../Models/ImageModel/ImageModel';
import { MultiPageImageModel } from '../Models/ImageModel/MultiPageImageModel';
import { WebSpecificationParser } from './WebSpecificationParser';
import type { Template, TemplatePage } from '../Renderers/BaseRenderer/IBaseRenderer';
import type { IMultiPageImageModel } from '../Models/ImageModel/IMultiPageImageModel';
import type { IImageModel } from '../Models/ImageModel/IImageModel';

export class ImageWebSpecificationParser extends WebSpecificationParser {
    // TODO: Seems useles, delete later
    parse = (imageSpecification: Template, pageIndex: number, fontFamilies: FontFamily[]): IImageModel => {
        setFontFamilies(fontFamilies);
        const testSpec = imageSpecification.pages[0];

        return new ImageModel()
            .setElements(this.parseElements(testSpec, fontFamilies))
            .setBackgroundColor(this.parseBackgroundColor(testSpec))
            .setDimensions(this.parseDimensions(imageSpecification, pageIndex));
    };

    parseMultiPage = (imageSpecification: Template, fontFamilies: FontFamily[]): IMultiPageImageModel => {
        const pages = this.parsePages(imageSpecification);

        return new MultiPageImageModel()
            .setModels(
                pages.map((page: TemplatePage) => {
                    const { width, height } = page;
                    const dimension = new Dimension(width, height);

                    return new ImageModel()
                        .setDimensions(dimension)
                        .setElements(this.parseElements(page, fontFamilies))
                        .setBackgroundColor(this.parseBackgroundColor(page));
                }),
            )
            .setModelsMetaData(
                pages.map(({ id, name, displayOrder, format }: TemplatePage) => ({
                    id,
                    name,
                    displayOrder,
                    format,
                })),
            );
    };
}
