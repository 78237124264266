import React from 'react';
import { ActionCreators } from 'redux-undo';
import { SpecificationExporter } from '@bynder-studio/render-core';
import { notify } from '@bynder/design-system';
import { Translate } from '@bynder/localization';
import { catchResponseError } from 'packages/helpers/helpers';
import { CreativeEditorService } from '~/services/CreativeEditorService';
import CreativesService from '~/services/CreativesService';
import { prepareDataForSave } from '~/common/editor/editor.bloc';
import { AMPLITUDE_TYPES } from '~/store/amplitude/constants';
import { sendAmplitudeDesignEvent } from '~/store/amplitude/actions';
import { sendAppcuesEvent } from '~/helpers/RouteWithAppcues';
import features from '~/configs/features';
import authorizationHelper from '~/helpers/AuthorizationHelper';
import * as types from '~/store/types';
import presetsService from 'packages/services/PresetsService';
import { customerIdSelector } from '~/store/user/user.selectors';
import { animations } from '~/helpers/animations';
import { isSavedSelector } from './creativeEditor.selectors';

export const IS_PUBLISHED = 'isPublished';

const TYPES_NAMES = {
    REGULAR: 'design',
    TEMPLATE: 'template',
};

const createAmplitudeDesignData = (saveData, state) => {
    const newDesign = { pages: saveData.pages, creativeType: saveData.type };
    newDesign.pages[0].aspectRatio = state.creatives.creative.aspectRatio;

    return newDesign;
};

export function setAllowValidate(allowValidate = true) {
    return (dispatch) =>
        dispatch({
            type: types.TOGGLE_ALLOW_VALIDATE,
            allowValidate,
        });
}

export function setElements(elements, push = false, setSavedFalse = true) {
    return (dispatch, getState) => {
        const saved = isSavedSelector(getState());
        dispatch({
            type: types.SET_EDITOR_ELEMENTS,
            elements,
            pushToHistory: push,
            saved: setSavedFalse ? false : saved,
        });
    };
}

export function getCreativeTemplate(creativeId, lastPublish = false, type) {
    return async (dispatch, getState) => {
        const res = await CreativeEditorService.getCreativeTemplate(creativeId, lastPublish).catch(catchResponseError);

        if (!res || res.status >= 400) {
            return;
        }

        let fonts, elementsFonts;

        // fonts might not be loaded yet
        while (true) {
            const userState = getState().user;

            fonts = userState.fonts;
            elementsFonts = userState.elementsFonts;

            if (fonts?.length) {
                break;
            }

            await new Promise((resolve) => setTimeout(resolve, 50));
        }

        const json = res.json;
        const { galleryFonts } = json;
        const combinedFonts = galleryFonts ? [...fonts, ...galleryFonts] : fonts;

        dispatch({
            type: types.GET_EDITOR_TEMPLATE,
            creativeId,
            animations,
            galleryFonts,
            elementsFonts,
            fonts: combinedFonts,
            editorType: type,
            template: json.template,
            _template: json._template,
            variationSetsValues: json.variationSetsValues ?? [],
            textStyles: json.textStyles,
            contentProperties: json.contentProperties ?? [],
            defaultRenditionProfileId: json.defaultRenditionProfileId,
        });
        dispatch(setAllowValidate());
    };
}

export function saveCreativeTemplate(
    id,
    creativeModel,
    exportIntegrations,
    // todo: return from thunk a path to redirect and handle it in the component
    redirect: (to: { path: string; search?: string }) => void,
    approvalEnabled = false,
    reviewers = [],
    type = 'REGULAR',
    silent = false,
) {
    return (dispatch, getState) => {
        const { creativeType } = getState().creatives.creative;

        const rawCreativeTemplate = SpecificationExporter.exportSpecificationTemplate(creativeModel, creativeType);

        rawCreativeTemplate.pages.forEach((page) => {
            const { elements: newElements } = prepareDataForSave(false, page.elements, {}, 0, creativeType);
            const { elements: newGlobalElements } = prepareDataForSave(false, page.globalElements, {}, 0, creativeType);
            page.elements = newElements;
            page.globalElements = newGlobalElements;

            // todo: fix types or remove if not present
            delete (page as any).index;
            delete (page as any).dimensions;
            delete (page as any).unlinkedElements;
        });

        const mapToSecurityIdentities = (newIdentities) =>
            newIdentities.reduce((identities, permission) => {
                const { securityIdentityType, bynderUserId, fullName, groupId, groupName, profileId, profileName } =
                    permission;

                return [
                    ...identities,
                    {
                        type: securityIdentityType,
                        aclRole: 'REVIEWER',
                        granting: true,
                        ...(securityIdentityType === 'GROUP' && { groupId, groupName }),
                        ...(securityIdentityType === 'PROFILE' && { profileId, profileName }),
                        ...(securityIdentityType === 'USER' && { bynderUserId, fullName }),
                    },
                ];
            }, []);

        const reviewersArgs = authorizationHelper.isFeatureAvailable(features.APPROVALS_ENABLED) && {
            approvalEnabled,
            reviewers: mapToSecurityIdentities(reviewers),
        };

        const dataToSave = {
            ...rawCreativeTemplate,
            type: creativeType,
            exportIntegrations: Object.values(exportIntegrations),
            ...reviewersArgs,
        };

        dispatch({ type: types.EDITOR_TEMPLATE_ACTION_REQUEST });

        CreativesService.saveCreativeTemplate(id, dataToSave)
            .then(({ status, json }) => {
                const { elementsFonts, template, message } = json as any;
                const saved = status === 200;
                dispatch({
                    type: types.SET_SAVE_STATUS,
                    saved,
                    template: !saved ? null : template,
                    elementsFonts: !saved ? null : elementsFonts,
                    selectedShotIndex: !saved ? null : 0,
                    selectedElementIds: null,
                    elements: !saved ? null : template.elements,
                });

                if (status === 200) {
                    const newMetaData = template.pages
                        .map((page) => ({
                            id: page.id,
                            displayOrder: page.displayOrder,
                            name: page.name,
                            format: page.format,
                        }))
                        .sort((a, b) => a.displayOrder - b.displayOrder);
                    dispatch(
                        sendAmplitudeDesignEvent({
                            eventType: AMPLITUDE_TYPES.SAVE_DESIGN,

                            // todo: check the required data
                            design: createAmplitudeDesignData(dataToSave, getState()) as any,
                        }),
                    );
                    creativeModel.setModelsMetaData(newMetaData);

                    if (!silent) {
                        notify({
                            title: <Translate id="notification.design.save.success" />,
                            variant: 'success',
                        });
                    }

                    sendAppcuesEvent('Design saved as template', { id, name: dataToSave.name });
                } else if (
                    authorizationHelper.isFeatureAvailable(features.APPROVALS_ENABLED) &&
                    status === 500 &&
                    message === 'operation is not valid!'
                ) {
                    redirect({ path: type === 'TEMPLATE' ? `/templates/${id}/edit` : `/designs/${id}/edit` });
                    notify({
                        variant: 'error',
                        title: <Translate id="editor.approvals.locked.desc" />,
                    });
                } else {
                    notify({
                        title: <Translate id="notification.design.save.failure" />,
                        variant: 'error',
                    });
                }
            })
            .catch(catchResponseError)
            .finally(() => {
                dispatch({ type: types.EDITOR_TEMPLATE_ACTION_FINISH });
            });
    };
}

export function publishCreativeTemplate(
    id,
    creativeModel,
    exportIntegrations,
    // todo: return from thunk a path to redirect and handle it in the component
    redirect: (to: { path: string; search?: string }) => void,
    approvalEnabled = false,
    reviewers = [],
    type = 'REGULAR',
) {
    return (dispatch, getState) => {
        const isTemplate = type === 'TEMPLATE';
        const state = getState();
        const back = state.general?.currentPage?.back;

        let redirectBack: {
            path: string;
            search?: string;
        } = { path: '/categories' };

        if (back) {
            const backArray = back.split('?');

            redirectBack = {
                path: backArray[0],
                search: backArray[1],
            };
        }

        const { creativeType, name: creativeName, videosCount: variationCount } = state.creatives.creative;

        const rawCreativeTemplate = SpecificationExporter.exportSpecificationTemplate(creativeModel, creativeType);
        rawCreativeTemplate.pages.forEach((page) => {
            const { elements: newElements } = prepareDataForSave(false, page.elements, {}, 0, creativeType);
            const { elements: newGlobalElements } = prepareDataForSave(false, page.globalElements, {}, 0, creativeType);
            page.elements = newElements;
            page.globalElements = newGlobalElements;

            // todo: fix types or remove if not present
            delete (page as any).index;
            delete (page as any).dimensions;
            delete (page as any).unlinkedElements;
        });

        const mapToSecurityIdentities = (newIdentities) =>
            newIdentities.reduce((identities, permission) => {
                const { securityIdentityType, bynderUserId, fullName, groupId, groupName, profileId, profileName } =
                    permission;

                return [
                    ...identities,
                    {
                        type: securityIdentityType,
                        aclRole: 'REVIEWER',
                        granting: true,
                        ...(securityIdentityType === 'GROUP' && { groupId, groupName }),
                        ...(securityIdentityType === 'PROFILE' && { profileId, profileName }),
                        ...(securityIdentityType === 'USER' && { bynderUserId, fullName }),
                    },
                ];
            }, []);

        const reviewersArgs = authorizationHelper.isFeatureAvailable(features.APPROVALS_ENABLED) && {
            approvalEnabled,
            reviewers: mapToSecurityIdentities(reviewers),
        };

        const dataToSave = {
            ...rawCreativeTemplate,
            type: creativeType,
            exportIntegrations: Object.values(exportIntegrations),
            ...reviewersArgs,
        };

        const noteId = `publishing-creative`;

        if (!variationCount) {
            notify({
                id: noteId,
                title: isTemplate ? <Translate id="editor.template.publishing" /> : `Publish "${creativeName}"`,
                description: isTemplate ? null : 'Publishing...',
                isPersistent: true,
                variant: 'loading',
                button: <></>,
            });
        }

        dispatch({ type: types.EDITOR_TEMPLATE_ACTION_REQUEST });

        CreativesService.publishCreativeTemplate(id, dataToSave)
            .then(({ status, json }) => {
                const { complexityScore } = json as any;
                const typeName = TYPES_NAMES[type];

                dispatch({
                    type: types.SET_SAVE_STATUS,
                    saved: status === 200,
                });

                const to = isTemplate ? redirectBack : { path: `/designs/${id}`, search: `?${IS_PUBLISHED}=true` };

                if (status === 200) {
                    dispatch(
                        sendAmplitudeDesignEvent({
                            eventType: AMPLITUDE_TYPES.PUBLISH_DESIGN,

                            // todo: check the required data
                            design: createAmplitudeDesignData(dataToSave, getState()) as any,
                        }),
                    );
                    dispatch({
                        type: types.PUBLISH_EDITOR_TEMPLATE,
                    });

                    redirect(to);
                    notify({
                        id: noteId,
                        variant: 'success',
                        title: (
                            <Translate
                                id={
                                    isTemplate
                                        ? 'editor.template.publish.success.note'
                                        : 'editor.design.publish.success.note'
                                }
                            />
                        ),
                    });
                    sendAppcuesEvent('Design published', { id, name: creativeName });
                } else if (complexityScore) {
                    notify({
                        id: noteId,
                        variant: 'error',
                        title: `Sorry, this ${typeName} is too complex to process. Please remove some elements and try again.`,
                    });
                } else if (
                    authorizationHelper.isFeatureAvailable(features.APPROVALS_ENABLED) &&
                    status === 500 &&
                    (json as any).message.includes('Updating content is not permitted!')
                ) {
                    // todo: check if the redirect is necessary
                    redirect({ path: isTemplate ? `/templates/${id}/edit` : `/designs/${id}/edit` });
                    notify({
                        id: noteId,
                        variant: 'error',
                        title: <Translate id="editor.approvals.locked.desc" />,
                    });
                } else {
                    notify({
                        id: noteId,
                        variant: 'error',
                        title: 'Failed to publish. Please try again.',
                    });
                }
            })
            .catch(catchResponseError)
            .finally(() => {
                dispatch({ type: types.EDITOR_TEMPLATE_ACTION_FINISH });
            });
    };
}

export function discardCreativeTemplate(creativeId) {
    return (dispatch) => {
        CreativeEditorService.discardCreativeTemplate(creativeId)
            .then(({ status, json: { elementsFonts, template, fonts } }) => {
                if (status === 200) {
                    dispatch(ActionCreators.clearHistory());
                    dispatch({
                        type: types.DISCARD_EDITOR_TEMPLATE,
                        template,
                        elementsFonts,
                        fonts,
                    });
                    dispatch(setElements(template.elements));
                    sendAppcuesEvent('Design changes discarded', { id: creativeId });
                }
            })
            .catch(catchResponseError);
    };
}

export function setSaveStatus(status) {
    return (dispatch) => {
        dispatch({
            type: types.SET_SAVE_STATUS,
            saved: status,
        });
    };
}

export function resetEditor() {
    ActionCreators.clearHistory();

    return {
        type: types.CREATIVE_EDITOR_RESET,
    };
}

export function setRTEStyle(styles, isSelection, isBlur) {
    let rteStyle = 'unknown';
    if (styles.bold) {
        rteStyle = 'BOLD';
        if (styles.italic) {
            rteStyle = 'BOLD_ITALIC';
        }
    } else if (styles.italic) {
        rteStyle = 'ITALIC';
    } else if (styles.multiple) {
        rteStyle = 'MULTIPLE';
    }

    return (dispatch) => {
        dispatch({
            type: types.EDITOR_SET_RTE_FONT,
            rteStyle,
            isSelection,
            isBlur,
        });
    };
}

export function createPresetAction(element, textStyles) {
    return (dispatch, getState) => {
        const state = getState();
        const { creativeType } = state.creatives.creative;
        const { elements } = prepareDataForSave(false, { [element.id]: element }, {}, 0, creativeType);
        const customerId = customerIdSelector(state);
        const { name, contentProperties } = element;

        let options;

        if (textStyles && textStyles.length) {
            options = { elements, textStyles, contentProperties, customerId, name, type: creativeType };
        } else {
            options = { elements, customerId, contentProperties, name, type: creativeType };
        }

        presetsService
            .addPreset(options)
            .then(({ status }) => {
                if (status === 200) {
                    notify({
                        title: 'The group has been saved as a preset',
                        variant: 'success',
                    });
                    sendAppcuesEvent('Preset created', { name });
                }
            })
            .catch(catchResponseError);
    };
}

export function setShowAssetSelection(show) {
    return (dispatch) => {
        dispatch({
            type: types.EDITOR_SET_SHOW_ASSETS_SELECTION,
            show,
        });
    };
}

export function setTimelineZoom() {
    return {
        type: types.EDITOR_SET_TIMELINE_ZOOM,
        timelineZoom: -1, // not used value
    };
}

export function markAsUnsaved() {
    return {
        type: types.MARK_AS_UNSAVED,
    };
}
