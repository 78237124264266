import React from 'react';
import { Translate } from '@bynder/localization';

export const FLIP_TYPES = ['FLIP_IN', 'FLIP_OUT'];

export function getAnimationPropertyOptions(animationType, property, values) {
    const breakup = parseInt(values.breakup, 10);

    if (FLIP_TYPES.includes(animationType) && property.name === 'direction' && breakup === 1) {
        return property.options.filter((option) => option.value !== 'RANDOM');
    }

    return property.options;
}

export function getAnimationType(values) {
    return values ? values.type : 'OFF';
}

export function isImportedAnimation(type) {
    return ['CORNER_PIN', 'DATA_IMPORT'].includes(type);
}

export default {
    getAnimationType,
    getAnimationPropertyOptions,
    FLIP_TYPES,
};

export const getEnhancedAnimations = (animations, AnimationsMap) => {
    const enhancedAnimations = Object.entries(animations).reduce((acc, [animationKey, animation]: any) => {
        const newProperties = animation.properties.map((property) => {
            if (!AnimationsMap[animationKey]) return property;

            const newCurrentProperty = AnimationsMap[animationKey].properties.find(
                (newProperty) => newProperty.name === property.name,
            );

            return {
                ...property,
                ...newCurrentProperty,
            };
        });
        acc[animationKey] = { ...animation, properties: newProperties };
        return acc;
    }, {});
    return {
        ...enhancedAnimations,
        OFF: { type: 'OFF', label: <Translate id="editor.sidebar.animation.off" />, properties: [] },
    };
};

export const getGroupedTypes = (sortedTypes) => {
    let reducerCache: any = [];
    return sortedTypes.reduce((acc, type) => {
        if (!type.inRow) {
            if (reducerCache.length) {
                acc = [...acc, reducerCache];
                reducerCache = [];
            }
            acc.push(type);
            return acc;
        }
        if (!reducerCache.length) {
            reducerCache.push(type);
            return acc;
        }
        if (reducerCache[reducerCache.length - 1].inRow !== type.inRow) {
            acc = [...acc, reducerCache];
            reducerCache = [];
            reducerCache.push(type);
            return acc;
        }
        reducerCache.push(type);
        return acc;
    }, []);
};
