import { useCallback, useRef } from 'react';

import { drawLine, findSnapPoints, getOtherElementsBoundingBoxes, removeLine } from './snapping';

export const useSnap = (containerRef) => {
    const containerBoundingBox = useRef(containerRef.current && containerRef.current.getBoundingClientRect());

    const otherElements = useRef(null);
    const lineRef = useRef(null);
    const snapPoint = useRef(null);

    const onDragStart = useCallback(
        (elementId, parentId) => {
            containerBoundingBox.current = containerRef.current.getBoundingClientRect();
            otherElements.current = getOtherElementsBoundingBoxes(containerBoundingBox.current, elementId, parentId);
        },
        [containerRef],
    );

    const onDragEnd = useCallback(() => {
        otherElements.current = null;
        removeLine(lineRef.current);
    }, []);

    const onDrag = useCallback(
        (elementId, parentId, _fromRight, boundingBox, left, width) => {
            if (!otherElements.current) {
                onDragStart(elementId, parentId);
            }

            // use element default if not provided
            const newWidth = width || boundingBox.width;

            const [lowestPoint, topPoint] = findSnapPoints(
                { x: left, width, y: boundingBox.y },
                otherElements.current,
                _fromRight,
            );

            if (lowestPoint && topPoint) {
                const fromRight = _fromRight || lowestPoint.fromRight || topPoint.fromRight;

                // add width if fromRight
                const lineLeft = left + !!fromRight * newWidth;
                snapPoint.current = [topPoint, lowestPoint].find((e) => e.elementId);
                drawLine(fromRight, lineRef.current, lineLeft, [lowestPoint, topPoint], containerBoundingBox.current.y);
            } else {
                removeLine(lineRef.current);
                snapPoint.current = null;
            }
        },
        [onDragStart],
    );

    const getSnapPoint = useCallback(() => snapPoint.current || {}, []);

    return [lineRef, onDrag, onDragEnd, getSnapPoint];
};
