import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Tooltip } from '@bynder/design-system';
import { IconDownload, IconDownloadDisabled, IconDelete, IconArrowLeft } from '@bynder/icons';
import { sendAmplitudeExportEvent } from '~/store/amplitude/actions';
import { getAmplitudeDataFromExportJob } from '~/store/amplitude/utils';
import { AMPLITUDE_TYPES } from '~/store/amplitude/constants';
import { useFriendlyTime } from '~/hooks/useFriendlyTime';
import { sendAppcuesEvent } from '~/helpers/RouteWithAppcues';
import MaybeTooltip from 'packages/common/MaybeTooltip';
import { download, makeUrlToDownload } from '../utils';
import { CommonJobFnType, ExportJobItemType } from './types';
import { ExportItemActionsWrapper, ExportItemArrow, ExportItemRow, ExportItemRowWrapper } from './AllExports.styled';
import useExport from '../hooks/useExport';
import ExportItemSubtitleBar from './ExportItemSubtitleBar';

type Props = {
    job: ExportJobItemType;
    onSelect: CommonJobFnType;
    onDelete: CommonJobFnType;
};

const CompletedJobRow = ({ job, onSelect, onDelete }: Props) => {
    const dispatch = useDispatch();
    const { isDownloadBlocked } = useExport();
    const { getFriendlyTime } = useFriendlyTime();
    const count = job.jobVariations?.length || 0;
    const handleSelect = useCallback(() => {
        onSelect(job);
    }, [job, onSelect]);

    const handleDelete = useCallback(
        (event) => {
            event.stopPropagation();
            onDelete(job);
        },
        [job, onDelete],
    );

    const handleUploadClick = useCallback(
        (event) => {
            dispatch(
                sendAmplitudeExportEvent({
                    eventType: AMPLITUDE_TYPES.DOWNLOAD_EXPORT,
                    additionalProps: getAmplitudeDataFromExportJob(job),
                }),
            );
            sendAppcuesEvent('Export job - Download clicked', {
                name: job.name,
                type: job.type,
            });
            event.stopPropagation();
            download(makeUrlToDownload(job.id))();
        },
        [job.id],
    );

    return (
        <ExportItemRowWrapper className="w-100">
            <ExportItemRow justifyContent="space-between" onClick={handleSelect}>
                <ExportItemSubtitleBar
                    name={job.name}
                    count={count}
                    statusText={`Completed ${getFriendlyTime(job.updated)}`}
                    jobType={job.type.toLowerCase()}
                />
                <ExportItemActionsWrapper alignItems="center">
                    <MaybeTooltip content="Download all" hasTooltip={!isDownloadBlocked}>
                        <Button
                            isDisabled={isDownloadBlocked}
                            title="Download all"
                            variant="clean"
                            onClick={handleUploadClick}
                            icon={isDownloadBlocked ? <IconDownloadDisabled /> : <IconDownload />}
                        />
                    </MaybeTooltip>
                    <Tooltip content="Remove from activity log">
                        <Button
                            title="Remove from activity log"
                            variant="clean"
                            onClick={handleDelete}
                            icon={<IconDelete />}
                        />
                    </Tooltip>
                    <ExportItemArrow>
                        <IconArrowLeft />
                    </ExportItemArrow>
                </ExportItemActionsWrapper>
            </ExportItemRow>
        </ExportItemRowWrapper>
    );
};

export default CompletedJobRow;
