import { flattenTree } from '~/common/editor/helpers/elementtree';

function getShotsElementsAccordingDuration(shotElements, templateElements, duration) {
    const elements = [];

    shotElements.forEach((shotElement) => {
        const element = templateElements[shotElement.id];
        if (element.properties.startFrame < duration) {
            elements.push(shotElement);
        }
    });

    return elements;
}

export function getShotsFromPageAccordingDuration(page) {
    const { shots = [], duration } = page;
    const elements = flattenTree(page.elements, true);
    const data = [];
    let shotsDurations = 0;

    shots
        .sort((a, b) => a.startFrame - b.startFrame)
        .forEach((shot) => {
            shotsDurations += shot.duration;

            if (duration >= shotsDurations) {
                data.push(shot);

                return;
            }

            const shotRealDuration = shot.duration - (shotsDurations - duration);

            if (shotRealDuration <= 0) {
                return;
            }

            data.push({
                ...shot,
                elements: getShotsElementsAccordingDuration(shot.elements, elements, duration),
                duration: shotRealDuration,
            });
        });

    return data;
}

export function getElementShot(element, template) {
    const { shots } = template;
    let shotsDurations = 0;

    const shot = shots.find((shot) => {
        shotsDurations += shot.duration;
        return element.startFrame <= shotsDurations;
    });
    return shot || shots[shots.length - 1];
}

export function getElementDefaultDropShadow(element, template, type) {
    const shot = getElementShot(element, template);
    const definition = shot.definitions[type.toUpperCase()];
    // TODO: Seems like backend now doesn't send metadata here
    const { dropShadow } = definition.metadata;
    return { ...dropShadow, state: 'DISABLE' };
}
