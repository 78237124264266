import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setSaveStatus } from 'packages/store/creativeEditor/creativeEditor.actions';
import useEditor from '~/hooks/useEditor';
import { EXPORT_TYPE_OPTIONS } from './enums';

export const useExportSettings = () => {
    const dispatch = useDispatch();
    const { exportIntegrations, toggleExportIntegration, changeExportValue } = useEditor();

    const handleIntegrationChange = (name) => () => {
        toggleExportIntegration(name);
        dispatch(setSaveStatus(false));
    };

    const handleExportTypeClick = (name) => {
        changeExportValue(name);
        dispatch(setSaveStatus(false));
    };

    const bothExportChosen = exportIntegrations.BYNDER?.enabled && exportIntegrations.DOWNLOAD?.enabled;
    const isWaitingChosen = exportIntegrations.BYNDER?.enabled;
    const isDownloadChosen = exportIntegrations.DOWNLOAD?.enabled;

    const chosenExportValue = useMemo(() => {
        if (!bothExportChosen && !isDownloadChosen && !isWaitingChosen) {
            return EXPORT_TYPE_OPTIONS[0];
        }

        if (bothExportChosen) {
            return EXPORT_TYPE_OPTIONS[0];
        } else if (isDownloadChosen) {
            return EXPORT_TYPE_OPTIONS[1];
        }

        return EXPORT_TYPE_OPTIONS[2];
    }, [exportIntegrations]);

    return {
        handleIntegrationChange,
        handleExportTypeClick,
        chosenExportValue,
        exportIntegrations,
    };
};
