import React, { useEffect, useRef } from 'react';
import { ElementUpdateTypes } from '@bynder-studio/render-core';
import { Form } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import editorAutocorrect from 'packages/pages/editor/EditorAutocorrectRules';
import InputLinked from '../../../FormComponents/InputLinked';
import useForceUpdate from '~/hooks/useForceUpdate';

const VerticalPosition = ({ creativeModel, selectedElement, isLinked }) => {
    const { translate } = useTranslate();
    const {
        id,
        locked,
        position: { y: verticalPosition },
    } = selectedElement;
    const inputRef = useRef<HTMLInputElement>(null);
    const value = useRef(verticalPosition);
    const forceUpdate = useForceUpdate();

    const setValue = (val) => {
        value.current = val;

        if (inputRef.current) {
            inputRef.current.value = val;
        }
    };

    const applyChanges = (val: number) => {
        const correctValue = editorAutocorrect('element_vertical_position', val);
        setValue(correctValue);
        const param = {
            position: {
                ...selectedElement.position,
                y: +correctValue,
            },
        };

        if (Math.trunc(selectedElement.position.y) !== param.position.y) {
            creativeModel.updateElement(id, param);
        }
    };

    const onBlur = () => {
        applyChanges(value.current);
    };

    const onChange = (newValue) => {
        setValue(newValue);
        forceUpdate();
    };

    const onKeyDown = (event) => {
        switch (event.key) {
            case 'Enter':
                onBlur();
                break;
            case 'ArrowUp':
            case 'ArrowDown':
                event.preventDefault();
                const numberToAdd = event.key === 'ArrowDown' ? -1 : 1;
                const factor = event.shiftKey ? 10 : 1;
                applyChanges(Number(value.current) + numberToAdd * factor);
                break;
        }
    };

    // because value can be changed on the canvas
    useEffect(() => {
        const correctValue = editorAutocorrect('element_vertical_position', verticalPosition);
        setValue(correctValue);
    }, [id, verticalPosition]);

    useEffect(() => {
        return creativeModel.onElementPartialUpdate(id, ElementUpdateTypes.POSITION, ({ element }) => {
            setValue(editorAutocorrect('element_vertical_position', element.position.getY()));
        });
    }, [creativeModel, id, verticalPosition]);

    return (
        <Form.Group>
            <InputLinked
                inputRef={inputRef}
                name={translate('editor.sidebar.shots.position.vertical.label')}
                aria-label={translate('editor.sidebar.shots.position.vertical.label')}
                type="number"
                value={value.current}
                elementValue={Math.trunc(selectedElement.position.y)}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onChange={onChange}
                isDisabled={locked}
                prefix="Y"
                suffix="px"
                data-testid="shots_properties_position_y"
                isLinked={isLinked}
            />
        </Form.Group>
    );
};

export default VerticalPosition;
