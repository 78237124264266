import { useCallback, useEffect } from 'react';
import { isInteractiveElement } from '@bynder-studio/misc';
import { type ElementCreator } from 'packages/pages/editor/useElementCreator';
import { isCtrlKey } from '~/helpers/hotKeys';

export default function useCreateElementHotkeys(
    elementCreator: ElementCreator,
    showImagePicker: () => void,
    showVideoPicker?: () => void,
) {
    const handleKeyDown = useCallback(
        (event) => {
            if (isInteractiveElement(event)) {
                return;
            }

            if (event.shiftKey && isCtrlKey(event)) {
                switch (event.code) {
                    case 'KeyI': {
                        event.preventDefault();
                        showImagePicker();
                        break;
                    }
                    case 'KeyK': {
                        if (!showVideoPicker) {
                            return;
                        }

                        event.preventDefault();
                        showVideoPicker();
                        break;
                    }
                }
            } else if (!event.shiftKey && !isCtrlKey(event)) {
                switch (event.code) {
                    case 'KeyT': {
                        elementCreator.createTextElement();
                        break;
                    }
                    case 'KeyE': {
                        elementCreator.createEllipseElement();
                        break;
                    }
                    case 'KeyR': {
                        elementCreator.createRectangleElement();
                        break;
                    }
                }
            }
        },
        [showImagePicker, showVideoPicker, elementCreator],
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);
}
