import { Region } from '../Shared/Region';
import { EffectTypes } from '../../Enums/EffectTypes';
import { ICompElement } from '../CompModels/ICompElement';
import { getTransformedRegion } from '../../Helpers/compositorUtils';
import { BaseEffect } from './BaseEffect';

export class TransformEffect extends BaseEffect {
    static type = EffectTypes.TRANSFORM;

    static getRegionOfDefinition(region: Region, compElement: ICompElement) {
        return new Region(
            Number.NEGATIVE_INFINITY,
            Number.NEGATIVE_INFINITY,
            Number.POSITIVE_INFINITY,
            Number.POSITIVE_INFINITY,
        );
    }

    // TODO: refactor this, since it is not using the region as an input
    static getRegionOfInterest(region: Region | null, compElement: ICompElement) {
        const transform = compElement.getAbsContentTransform(); // transformation relative to root canvas

        // use width and height of the content;
        const width = compElement.contentBox.dimension.width;
        const height = compElement.contentBox.dimension.height;

        return getTransformedRegion(transform, width, height);
    }
}
