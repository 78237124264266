import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Slider } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconHorizontalConstraints } from '@bynder/icons';
import { getHorizontalOffsetValueFromElement, getScaleValueFromElement } from '~/common/editor/helpers';
import useElement from '../../hooks/useElement';
import useDesignElementContentTransform from '../../hooks/useDesignElementContentTransform';

function useHorizontalOffset(id) {
    const { element, updateElement } = useElement(id);
    const contentTransform = useDesignElementContentTransform(id);
    const { naturalDimension, dimension } = element;
    const { width: naturalWidth } = naturalDimension;
    const { width } = dimension;
    const [scale, setScale] = useState(() => getScaleValueFromElement(element));
    const [value, setValue] = useState(() => getHorizontalOffsetValueFromElement(element));

    const controlOptions = useMemo(
        () => ({
            min: Math.floor(-1 * scale * naturalWidth),
            max: Math.ceil(width),
        }),
        [naturalWidth, width, scale],
    );

    const handleOnChange = useCallback(
        (newValue) => {
            setValue(newValue);
            const param = {
                contentTransform: {
                    ...contentTransform,
                    horizontalOffset: newValue,
                },
            };
            updateElement(param);
        },
        [contentTransform, updateElement],
    );

    useEffect(() => {
        setScale(contentTransform?.scale);
        setValue(contentTransform?.horizontalOffset);
    }, [contentTransform]);

    useEffect(() => {
        setScale(getScaleValueFromElement(element));
        setValue(getHorizontalOffsetValueFromElement(element));
    }, [element]);

    return { value, handleOnChange, controlOptions };
}

const HorizontalOffset = ({ id, disabled }) => {
    const { translate } = useTranslate();
    const { value, handleOnChange, controlOptions } = useHorizontalOffset(id);

    return (
        <Form.Group>
            <Form.Label>{translate('design.sidebar.scene.options.crop-mode.horizontal-offset.label')}</Form.Label>
            <Slider
                value={value}
                min={controlOptions.min}
                max={controlOptions.max}
                onChange={handleOnChange}
                isDisabled={disabled}
                isFromCenter
                inputPosition="right"
                iconLeft={<IconHorizontalConstraints />}
            />
        </Form.Group>
    );
};

export default HorizontalOffset;
