import styled, { css } from 'styled-components';
import { token } from '@bynder/design-system';

export const TimelineActionsWrapper = styled.div`
    height: 100%;
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
`;

export const TimelineActionsIcon = styled.div<{ active?: boolean; selected?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 22px;
    height: 22px;
    color: ${token.gray200};
    transition: 0.2s ease-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

    ${(props) =>
        props.active &&
        css`
            color: ${token.gray600};

            &:hover {
                color: ${token.gray800};
            }
        `}

    ${(props) =>
        props.selected &&
        css`
            color: ${token.gray800};
        `}
`;
