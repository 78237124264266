import { includes, identity } from 'rambda';
import { produce } from 'immer';
import {
    GroupElement,
    ImageElement,
    TextElement,
    VideoElement,
    ElementTypes,
    ShapeElement,
    AudioElement,
    BackgroundColor,
} from '@bynder-studio/render-core';

export const createNewTreeFromDraft = produce;

const searchInGroups = (elements, findFn, updateFn) => {
    for (let element of Object.values(elements)) {
        if (!!element.children) {
            const foundElement = searchForElement(element.children, findFn, updateFn);

            if (foundElement) {
                return foundElement;
            }
        }
    }
};

const searchForElement = (elements, findFn, updateFn) => {
    const element = Object.values(elements).find(findFn);

    if (element) {
        return updateFn ? updateFn(elements, element) : element;
    }

    return searchInGroups(elements, findFn, updateFn);
};

export const findElementById = (elements, elementId) =>
    elementId && searchForElement(elements, (el) => el.id.toString() === elementId.toString());

export const findElementByIdAndUpdate = (elements, elementId, updateFn) =>
    searchForElement(elements, (el) => el.id.toString() === elementId.toString(), updateFn);

export const updateElementsInTree = (elements, elementsIds, updateFn, newTree = true) => {
    const updateElements = (elementTree) => {
        elementsIds.forEach((id) => {
            const el = findElementById(elementTree, id);
            updateFn(el);
        });
    };

    return newTree
        ? produce(elements, (draftElements) => {
              updateElements(draftElements);
          })
        : updateElements(elements);
};

export const flattenTree = (elementTree, withGroups = false, elements = {}) => {
    if (!elementTree) {
        return elements;
    }
    Object.values(elementTree).map((element) => {
        const include = withGroups || element.type !== 'GROUP';

        if (include) {
            elements[element.id] = element;
        }

        if (element.type === 'GROUP') {
            return flattenTree(element.children, withGroups, elements);
        }
    });

    return elements;
};

export const elementSortFn =
    (lookup = identity, descending = true) =>
    (aElement, bElement) => {
        const aRenderOrder =
            lookup(aElement).properties.renderOrder === undefined ? -1 : lookup(aElement).properties.renderOrder;
        const bRenderOrder =
            lookup(bElement).properties.renderOrder === undefined ? -1 : lookup(bElement).properties.renderOrder;

        return descending ? bRenderOrder - aRenderOrder : aRenderOrder - bRenderOrder;
    };

const keepElementsInTree = (elementTree, elementsToKeep, updateFn) => {
    Object.values(elementTree).forEach((element) => {
        const isGroup = element.type === 'GROUP';
        let shouldKeep = includes(element.id, elementsToKeep) || isGroup;

        if (isGroup) {
            const filteredChildren = keepElementsInTree(element.children, elementsToKeep, updateFn);

            if (!Object.keys(filteredChildren).length) {
                shouldKeep = false;
            } else {
                element.children = filteredChildren;
            }
        }

        if (!shouldKeep) {
            delete elementTree[element.id];
        }

        updateFn && updateFn(element);
    });

    return elementTree;
};

export const filterTree = (elements, elementsToKeep, updateFn) => {
    return produce(elements, (draftElements) => {
        keepElementsInTree(draftElements, elementsToKeep, updateFn);
    });
};

export const isUsedAsMask = (creativeModel, selectedElement) =>
    creativeModel.getAllElementsRecursively().some((element) => {
        if (!selectedElement.id && selectedElement.uuid) {
            return element?.mask?.elementId === selectedElement.uuid;
        }

        return selectedElement.id && element?.mask?.elementId === selectedElement.id;
    });

export const getElementType = (el) => {
    if (el instanceof TextElement) {
        return ElementTypes.TEXT;
    }

    if (el instanceof VideoElement) {
        return ElementTypes.VIDEO;
    }

    if (el instanceof ImageElement) {
        return ElementTypes.IMAGE;
    }

    if (el instanceof ShapeElement) {
        return ElementTypes.SHAPE;
    }

    if (el instanceof GroupElement) {
        return ElementTypes.GROUP;
    }

    if (el instanceof AudioElement) {
        return ElementTypes.GLOBAL_AUDIO;
    }

    if (el instanceof BackgroundColor) {
        return ElementTypes.BACKGROUND_COLOR;
    }

    return '';
};
