import { token, textStyles } from '@bynder/design-system';
import styled from 'styled-components';

export const EmptyLayersContainer = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

export const EmptyLayersWrap = styled.div`
    display: flex;
    flex-direction: column;
    color: ${token.gray400};
    align-items: center;
    ${textStyles.uiTextS};
`;

export const LayersContainer = styled.div`
    flex: 0 0 272px;
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    padding-top: 8px; // we don't use token from DS because the other classes here don't
    background-color: ${token.white};
    display: flex;
    flex-direction: column;

    .layers--header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 12px 4px 16px;
        font-size: 16px;
        color: ${token.colorText};
        line-height: 20px;
        font-weight: 400;
    }

    .layers--header-btn {
        width: 40px;
        height: 32px;
        padding: 4px 8px !important;
        background-color: transparent;
        transition:
            background-color 0.3s ease,
            color 0.3s ease;
    }

    .layers--header-btn svg {
        fill: ${token.gray600a};
        color: ${token.gray600a};
    }

    .layers--header-btn:not(:disabled):hover {
        background-color: ${token.gray25a};
    }

    .layers--header-btn:not(:disabled):hover svg {
        fill: ${token.gray800a};
        color: ${token.gray800a};
    }

    .layers--header-text {
        font-size: 14px;
        line-height: 20px;
        color: ${token.colorText};
    }

    .layers--header-title {
        font-weight: 600;
        flex-grow: 1;
    }

    .layers--header .dropdown-menu,
    .layers--header .dropdown--body {
        padding: 0 !important;
    }

    .layers--header .dropdown--body {
        padding-block-end: 8px !important;
        padding-block-start: 8px !important;
    }

    .layers--header .dropdown-item {
        width: 224px;
        height: 36px;
        display: flex;
        align-items: center;
        padding: 0 18px !important;
    }

    .layers--header .dropdown-item svg {
        width: 24px;
        height: 24px;
        fill: ${token.gray600};
        margin-inline-end: 10px;
    }

    .layers--header .dropdown-item:hover svg {
        fill: ${token.gray800};
    }

    .layers--header .dropdown-divider {
        width: 100%;
        height: 1px;
        background: ${token.gray50a};
        margin: 8px 0;
        border: none;
    }

    .layers--wrapper {
        position: relative;
        padding: 5px 12px 0;
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        padding-bottom: 60px;
    }

    .layers--wrapper::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        border-radius: 3px;
    }

    .layers--wrapper::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: ${token.gray25a};
    }
`;
