import React, { Suspense } from 'react';
import { Button, token } from '@bynder/design-system';
import { IconCancel, IconArrowLeft, IconArrowRight } from '@bynder/icons';
import { lazyPrefetching } from 'packages/helpers/helpers';
import * as persistVolume from '~/helpers/persistVolume';
import Loader from '../loader/Loader';

export const VideoPlayer = lazyPrefetching(() =>
    import(
        /* webpackChunkName: "videojs" */
        /* webpackPrefetch: true */
        './Video'
    ),
);

export const AudioPlayer = lazyPrefetching(() =>
    import(
        /* webpackChunkName: "videojs" */
        /* webpackPrefetch: true */
        './Audio'
    ),
);

export default class Player extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullScreen: false,
            loaded: false,
        };

        this.volume = +(persistVolume.getVolume() ?? 0.25);
        this.isMuted = persistVolume.getMutedStatus();

        this.toggleFullScreen = this.toggleFullScreen.bind(this);
        this.clickOutside = this.clickOutside.bind(this);
        this.handleLoad = this.handleLoad.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        if (this.props.carousel) {
            document.addEventListener('keydown', this.handleKeyDown);
        }
    }

    componentWillUnmount() {
        this._isUnMounted = true;
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    componentDidUpdate(prevProps) {
        if (this.props.src !== prevProps.src) {
            this.setState({
                loaded: false,
            });
        }
    }

    handleKeyDown = (e) => {
        if (!this.isFullScreen()) return;
        if (e.keyCode === 37) {
            /* Left  */
            this.props.prev();
        }
        if (e.keyCode === 39) {
            /* Right  */
            this.props.next();
        }
    };

    clickOutside = (e) => {
        if (e.target === e.currentTarget) {
            this.toggleFullScreen();
        }
    };

    toggleFullScreen = () => {
        const toggle =
            this.props.handleToggle ||
            (() => {
                this.setState({
                    fullScreen: !this.state.fullScreen,
                });
            });
        toggle();
    };

    handleLoad = () => {
        const that = this;
        if (this._isUnMounted) return;
        that.setState({
            loaded: true,
        });
    };

    isFullScreen() {
        return this.props.alwaysFullScreen || this.state.fullScreen;
    }

    handleVolumeChange(volume, muted) {
        persistVolume.setVolume(volume);
        persistVolume.setMutedStatus(muted);
    }

    render() {
        const { src, poster, type, carousel, disabled, prev, next, download, onlyPoster = false } = this.props;
        const { loaded } = this.state;
        const fullScreen = this.isFullScreen();
        const showNextPrev = fullScreen && carousel;

        return (
            <>
                <div className={`${fullScreen ? 'modal-backdrop fade show' : ''}`} />
                <div className="h-100">
                    <div
                        className={`${
                            fullScreen ? 'modal player--modal fade show d-block p-5 text-center' : 'text-right h-100'
                        }`}
                        onClick={this.clickOutside}
                    >
                        {(!loaded || !src) && (
                            <div className={`d-flex flex-column justify-content-center align-items-center h-100`}>
                                <Loader poster={poster} size={50} />
                                {!onlyPoster && <small className="mt-2">Loading media</small>}
                            </div>
                        )}
                        {fullScreen && (
                            <Button
                                title="Close"
                                variant="clean"
                                className="position-absolute"
                                style={{ right: 15, top: 15 }}
                                onClick={this.toggleFullScreen}
                                icon={<IconCancel style={{ color: token.white }} />}
                            />
                        )}
                        {showNextPrev && (
                            <div
                                onClick={prev}
                                className={`fullscreen-arrow fullscreen-arrow-prev ${
                                    disabled.prevButton ? 'disabled' : ''
                                }`}
                            >
                                <IconArrowLeft />
                            </div>
                        )}

                        {src && (
                            <div
                                className={`${
                                    loaded
                                        ? `h-100 modal--player-wrapper ${
                                              fullScreen ? 'd-inline-block position-relative full-screen' : ''
                                          }`
                                        : 'd-none'
                                }`}
                            >
                                {/* {download && (
                                <div style={{ left: 0, top: -50 }} className="position-absolute text-white">
                                    {download}
                                </div>
                            )} */}
                                {type === 'VIDEO' && (
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <VideoPlayer
                                            key={src}
                                            {...this.props}
                                            // src='https://storage.googleapis.com/weadapt-development-storage/6252/UsjjulgftScKneihSckUZRbBlpEJODkFkkHb.mp4'
                                            // fullScreen={fullScreen}
                                            onFullScreen={this.toggleFullScreen}
                                            onPlayerReady={this.handleLoad}
                                            volume={this.volume}
                                            isMuted={this.isMuted}
                                            onVolumeChange={this.handleVolumeChange}
                                        />
                                    </Suspense>
                                )}
                                {type === 'AUDIO' && (
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <AudioPlayer
                                            key={src}
                                            {...this.props}
                                            // fullScreen={fullScreen}
                                            onFullScreen={this.toggleFullScreen}
                                            onPlayerReady={this.handleLoad}
                                            volume={this.volume}
                                            isMuted={this.isMuted}
                                            onVolumeChange={this.handleVolumeChange}
                                        />
                                    </Suspense>
                                )}
                                {/* {type === 'IMAGE' && (
                                <ImageViewer
                                    key={src}
                                    {...this.props}
                                    fullScreen={fullScreen}
                                    onFullScreen={this.toggleFullScreen}
                                    handleLoad={this.handleLoad}
                                />
                            )} */}
                            </div>
                        )}
                        {showNextPrev && (
                            <div
                                onClick={next}
                                className={`fullscreen-arrow fullscreen-arrow-next ${
                                    disabled.nextButton ? 'disabled' : ''
                                }`}
                            >
                                <IconArrowRight />
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}
