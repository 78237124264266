import React from 'react';
import { List, Thumbnail, token } from '@bynder/design-system';
import { IconUserGroup, IconPermission } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { IdentityType } from 'packages/store/sharing/types';
import { ReviewierIdentity } from 'packages/pages/design/RequestApprovalModal/types';

type TranslateFn = ReturnType<typeof useTranslate>['translate'];

const renderUserPermission = (item: ReviewierIdentity) => {
    const getInitial = () => {
        if (item?.fullName && item.fullName[0]) {
            return item.fullName[0].toUpperCase();
        }

        if (item?.firstName) {
            return item?.firstName[0].toUpperCase();
        }

        return '';
    };

    return (
        <List.Item
            thumbnail={<Thumbnail size="m" shape="circle" backgroundColor={token.gray100} icon={getInitial()} />}
            key={item.email}
        >
            {item?.fullName ? item?.fullName : `${item?.firstName} ${item?.lastName}`}
        </List.Item>
    );
};

const renderGroupPermission = (item: ReviewierIdentity, translate: TranslateFn) => {
    return (
        <List.Item
            thumbnail={<Thumbnail size="m" shape="circle" backgroundColor={token.gray100} icon={<IconUserGroup />} />}
            subtext={translate('modal.design.share.group')}
            key={item.groupId}
        >
            {item.groupName}
        </List.Item>
    );
};

const renderProfilePermission = (item: ReviewierIdentity, translate: TranslateFn) => {
    return (
        <List.Item
            thumbnail={<Thumbnail size="m" shape="circle" backgroundColor={token.gray100} icon={<IconPermission />} />}
            subtext={translate('modal.design.share.profile')}
            key={item.profileId}
        >
            {item.profileName}
        </List.Item>
    );
};

export const ReviewersList = ({ reviewers }: { reviewers: ReviewierIdentity[] }) => {
    const { translate } = useTranslate();

    return (
        <List>
            {reviewers.map((item) => {
                switch (item.securityIdentityType) {
                    case IdentityType.USER:
                        return renderUserPermission(item);
                    case IdentityType.GROUP:
                        return renderGroupPermission(item, translate);
                    case IdentityType.PROFILE:
                        return renderProfilePermission(item, translate);
                    default:
                        return null;
                }
            })}
        </List>
    );
};
