import React, { memo } from 'react';

import AssetsPickerLocal from 'packages/pages/components/AssetsPickerModal/components/AssetsPickerLocal';
import useCompactView from 'packages/pages/components/useCompactView';
import { AssetGroup } from 'packages/pages/editor/ContentPickerModal/types';

type Props = {
    type: 'image' | 'video' | 'audio';
    collectionId: number | null;
    assetType: string;
    selectedItem: any;
    onSelectLocal: any;
    compactViewProps: any;
    isPersonalUploadsAllowed: boolean;
    filterTypes?: string[];
    uploadTypes?: string[];
};

export const AssetsPicker = ({
    type,
    selectedItem,
    collectionId,
    assetType,
    onSelectLocal,
    compactViewProps,
    isPersonalUploadsAllowed,
    filterTypes = [],
    uploadTypes = [],
}: Props) => {
    const { renderCompactView } = useCompactView(compactViewProps, false);

    const renderLocalCompactView = () => (
        <AssetsPickerLocal
            type={type}
            collectionId={collectionId}
            selectedObject={selectedItem}
            handleSelectRow={onSelectLocal}
            filterTypes={filterTypes}
            uploadTypes={uploadTypes}
            isPersonalUploadsAllowed={isPersonalUploadsAllowed}
        />
    );

    return <>{assetType === AssetGroup.LOCAL ? renderLocalCompactView() : renderCompactView()}</>;
};

export default memo(AssetsPicker);
