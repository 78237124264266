import React, { useCallback } from 'react';
import { Dropdown } from '@bynder/design-system';
import { IconAddPerson } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import type { IsAvailable, Props } from './types';

export const isShareAvailable: IsAvailable = ({ hiddenActions }) => !hiddenActions.includes('setShareModalTemplate');

export const Share = ({ template, menuActions }: Props) => {
    const { translate } = useTranslate();

    const onClick = useCallback(() => menuActions.setShareModalTemplate?.(template), [menuActions, template]);

    return (
        <Dropdown.Item icon={<IconAddPerson />} onClick={onClick}>
            {translate('pages.categories.category.template.menu.share')}
        </Dropdown.Item>
    );
};
