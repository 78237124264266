import styled from 'styled-components';
import { token } from '@bynder/design-system';
import { Props } from './types';

export const Box = styled.div<Props>`
    display: flex;
    padding: ${token.spacing6};
    align-items: center;
`;

export const LeftBlock = styled.div<{ isContained?: boolean }>`
    display: flex;
    flex-wrap: nowrap;
    ${({ isContained }) => !isContained && `flex: 1`};
`;

export const CenterBlock = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    flex: 2;
`;

export const RightBlock = styled.div`
    display: flex;
    flex-wrap: nowrap;
    gap: ${token.spacing4};
`;

export const Divider = styled.div`
    width: 1px;
    background-color: ${token.gray100};
    margin: 0 ${token.spacing3};
`;

export const Title = styled.h1`
    font-weight: normal;
    font-size: ${token.fontSize200};
    line-height: 40px;
    margin-bottom: 0;
    margin-right: ${token.spacing3};
`;

export const ActionBox = styled.div``;
