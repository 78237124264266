import React, { useCallback, useEffect, useState } from 'react';
import { Buffer, Button, Dropdown } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import useExport from '../hooks/useExport';

import { DropdownItemSWrapper } from './Filters.styled';

const useVariationSelectList = () => {
    const [isAllSelected, setAllSelected] = useState(true);
    const {
        variationSets,
        variationSetIds: ids,
        setVariationSetIds: setIds,
        variationSetsLoading,
        getNextVariationSetsPart,
    } = useExport();

    const select = useCallback(
        (id) => {
            setIds((ids) => {
                const list = isAllSelected ? variationSets.map((variationSet) => variationSet.variationSetId) : ids;
                const newIds = list.includes(id) ? list.filter((id2) => id2 !== id) : [...list, id];
                setAllSelected(variationSets.length === newIds.length);
                return newIds;
            });
        },
        [isAllSelected, variationSets],
    );

    const toggleSelectAll = useCallback(() => {
        setIds([]);
        setAllSelected((isAllSelected) => !isAllSelected);
    }, []);

    useEffect(() => {
        getNextVariationSetsPart();
    }, []);

    const handleScroll = useCallback(
        (e) => {
            if (variationSetsLoading) return;
            const { scrollTop, scrollHeight, clientHeight } = e.target;
            const t = scrollTop / (scrollHeight - clientHeight);

            if (t > 0.998) {
                getNextVariationSetsPart();
            }
        },
        [variationSetsLoading, getNextVariationSetsPart],
    );

    return [
        variationSets,
        ids,
        select,
        isAllSelected,
        toggleSelectAll,
        variationSetsLoading,
        handleScroll,
        setAllSelected,
    ];
};

export const VariationSets = () => {
    const [variationSets, selected, select, isAllSelected, toggleSelectAll, loading, handleScroll, setAllSelected] =
        useVariationSelectList();
    const { variationSetIds, setSelectedCount, setSelectedIds, selectedIds, pageSets } = useExport();

    const { translate } = useTranslate();

    useEffect(() => {
        if (!variationSetIds.length || !selected.length) {
            return;
        }

        const pageSetVariationIds = pageSets.reduce((acc, page) => {
            page.entries.forEach((entry) => acc.push(entry.variationId));

            return acc;
        }, []);

        const filteredIds = Object.entries(selectedIds).reduce((acc, [key, value]) => {
            if (pageSetVariationIds.includes(key)) {
                acc[key] = value;
            }
            return acc;
        }, {});

        setAllSelected(variationSets.length === Object.keys(filteredIds).length);
        setSelectedIds(filteredIds);
        setSelectedCount(Object.keys(filteredIds).length);
    }, [selected, pageSets]);

    return (
        <Dropdown
            trigger={({ isOpen, ...triggerProps }) => (
                <Button variant="clean" isPressed={isOpen} rightIcon={<Dropdown.Arrow />} {...triggerProps}>
                    {translate('modal.export.filters.any-variation')}
                </Button>
            )}
        >
            <Dropdown.Item checkedVariant="checkbox" isChecked={isAllSelected} onClick={toggleSelectAll}>
                {translate('modal.export.filters.all-variations')}
            </Dropdown.Item>
            <Dropdown.Divider />
            <DropdownItemSWrapper onScroll={handleScroll}>
                {variationSets.map((variationSet) => (
                    <Dropdown.Item
                        key={variationSet.variationSetId}
                        checkedVariant="checkbox"
                        isChecked={isAllSelected || selected.includes(variationSet.variationSetId)}
                        onClick={() => {
                            select(variationSet.variationSetId);
                        }}
                    >
                        {variationSet.name}
                    </Dropdown.Item>
                ))}
                {loading && <Buffer />}
            </DropdownItemSWrapper>
        </Dropdown>
    );
};
