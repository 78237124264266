import styled from 'styled-components';
import { textStyles, token } from '@bynder/design-system';
import { BlankItemProps } from './types';

export const Title = styled.h2`
    font-weight: normal;
    font-size: ${token.fontSize200};
    line-height: 40px;
    margin-bottom: ${token.spacing5};
`;

export const Container = styled.div`
    display: flex;

    & > :first-child {
        margin-right: ${token.spacing6};
    }
`;

export const Item = styled.div<BlankItemProps>`
    display: flex;
    flex: 0 0 calc(50% - ${token.spacing4});
    justify-content: space-between;
    padding: calc(${token.spacing5} - ${({ selected }) => (selected ? `2px` : `1px`)});
    border: ${({ selected }) => (selected ? `2px solid ${token.brandPrimary500}` : `1px solid ${token.gray100a}`)};
    border-radius: ${token.radiusMedium};
    box-shadow: ${token.elevation1};
    cursor: pointer;

    &:hover {
        border: ${({ selected }) => (selected ? `2px solid ${token.brandPrimary500}` : `1px solid ${token.gray200a}`)};
        box-shadow: ${token.elevation2};
    }
`;

export const MediaType = styled.div`
    display: flex;
`;

export const MediaTypeText = styled.div`
    margin-left: ${token.spacing4};
`;

export const Name = styled.div`
    ${textStyles.uiTextM};
`;

export const Details = styled.div`
    ${textStyles.uiTextS}
    color: ${token.gray600};
`;
