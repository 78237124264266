import { createSelector } from 'reselect';
import { CollectionsState } from './types';
import { CREATIVES_PENDING_STATUSES } from 'packages/store/creatives/creatives.constants';

export const getCollections = (state: any): CollectionsState => state.collections;
export const getCollection = (state: any): CollectionsState['collection'] => state.collections.collection;
const getCurrentCollections = (state: any) => getCollections(state).collections;

export const getCollectionActionProgress = (
    state: any,
): {
    renamingInProgress: CollectionsState['renamingInProgress'];
    deleteInProgress: CollectionsState['deleteInProgress'];
    creatingInProgress: CollectionsState['creatingInProgress'];
    addingInProgress: CollectionsState['addingInProgress'];
    removingFromCollectionInProgress: CollectionsState['removingFromCollectionInProgress'];
    editingInProgress: CollectionsState['editingInProgress'];
} => ({
    renamingInProgress: state.collections.renamingInProgress,
    deleteInProgress: state.collections.deleteInProgress,
    creatingInProgress: state.collections.creatingInProgress,
    addingInProgress: state.collections.addingInProgress,
    removingFromCollectionInProgress: state.collections.removingFromCollectionInProgress,
    editingInProgress: state.collections.editingInProgress,
});

export const getPendingCollectionThumbnails = createSelector(getCurrentCollections, (collections) => {
    return (
        collections
            // todo: check if null check is required
            // according to the type of collection, previewItems is always defined, but the old code had the check
            .flatMap((collection) => collection.previewItems ?? [])
            .filter((previewItem) => CREATIVES_PENDING_STATUSES.includes(previewItem.thumbnailStatus))
            .map((previewItem) => previewItem.creativeId)
    );
});
