import { useEffect, useRef, useState } from 'react';
import useHeightHack from 'packages/pages/components/AssetsPickerModal/components/useHeightHack';
import AuthorizationHelper from '~/helpers/AuthorizationHelper';
import { AssetGroup } from 'packages/pages/editor/ContentPickerModal/types';
import features from '~/configs/features';

type Props = {
    selectedObject?: any;
    isEditorPage?: boolean;
    preSelectedAsset?: any;
};

const useActions = (props: Props) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [assetType, setAssetType] = useState(AssetGroup.GLOBAL);
    const [selectedLocalAsset, setSelectedLocalAsset] = useState<any>(null);
    const [collectionId, setCollectionId] = useState(
        !props.isEditorPage ? props.selectedObject?.element?.virtualData?.bynderCollectionId : null,
    );

    useHeightHack({ assetType, wrapperRef });

    useEffect(() => {
        if (assetType === AssetGroup.LOCAL && props.preSelectedAsset?.sourceType === 'INTERNAL') {
            setSelectedLocalAsset(props.preSelectedAsset);
        }
    }, [assetType]);

    const setSelectedCollection = (selectedCollectionId) => {
        setAssetType(AssetGroup.LOCAL);
        setCollectionId(selectedCollectionId);
    };

    const handleDamClick = () => {
        setAssetType(AssetGroup.GLOBAL);
        setCollectionId(null);
    };

    const onLocalAssetClick = (asset) => {
        if (asset.status !== 'PROCESSED' && asset.status !== 'COMPLETED') {
            return;
        }

        if (asset.id === selectedLocalAsset?.id) {
            setSelectedLocalAsset(null);
        } else {
            setSelectedLocalAsset(asset);
        }
    };

    const handleBackClick = () => {
        setAssetType(AssetGroup.GLOBAL);
        setCollectionId(null);
    };

    const allowInternalAsset = AuthorizationHelper.isFeatureAvailable(features.INTERNAL_ASSET);

    return {
        assetType,
        wrapperRef,
        collectionId,
        selectedLocalAsset,
        allowInternalAsset,
        handleDamClick,
        setSelectedCollection,
        onLocalAssetClick,
        handleBackClick,
    };
};

export default useActions;
