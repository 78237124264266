export const NAMES_MAP = new Map([
    ['all', ''],
    ['active', 'ACTIVE'],
    ['inactive', 'INACTIVE'],
    ['archived', 'ARCHIVED'],
    ['draft', 'DRAFT'],
    ['desc', 'DESC'],
    ['DESC', 'DESC'],
    ['asc', 'ASC'],
    ['ASC', 'ASC'],
    ['name', 'NAME'],
    ['date', 'UPDATED'],
    ['videos', 'VIDEOS'],
    ['imp', 'UPDATED'],
    ['impressions', 'IMPRESSIONS'],
    ['date_updated', 'UPDATED'],
    ['date_created', 'CREATED'],
    ['duration', 'DURATION'],
    ['videoId', 'ID'],
    ['updated', 'UPDATED'],
    ['version_count', 'UPDATED'],
    ['template_ratio', 'ASPECT_RATIO'],
    ['template_name', 'TEMPLATE_NAME'],
    ['template_duration', 'DURATION'],
]);
