import React from 'react';
import { notify } from '@bynder/design-system';
import { Translate } from '@bynder/localization';
import ColorPalettesService from 'packages/services/ColorPalettesService';
import { catchResponseError } from 'packages/helpers/helpers';
import { sendAppcuesEvent } from '~/helpers/RouteWithAppcues';
import { actionTypes } from './types';

export const getColorPalettes = () => (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_COLOR_PALETTES_REQUEST });

    ColorPalettesService.getPalettes()
        .then(({ status, json: { palettes, totalItems } }) => {
            if (status !== 200) {
                notify({
                    title: <Translate id="pages.brand.colors.error.load" />,
                    variant: 'error',
                });
                dispatch({
                    type: actionTypes.GET_COLOR_PALETTES_FAILURE,
                    errors: status,
                });
            } else {
                dispatch({
                    type: actionTypes.GET_COLOR_PALETTES_SUCCESS,
                    palettes,
                    totalItems,
                });
            }
        })
        .catch(catchResponseError);
};

export const getColorPalette = (id) => (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_COLOR_PALETTE_REQUEST });

    ColorPalettesService.getPalette(id)
        .then(({ status, palette }) => {
            if (status !== 200) {
                notify({
                    title: <Translate id="pages.brand.colors.error.singlePalette.load" />,
                    variant: 'error',
                });
                dispatch({
                    type: actionTypes.GET_COLOR_PALETTE_FAILURE,
                    errors: status,
                });
            } else {
                dispatch({
                    type: actionTypes.GET_COLOR_PALETTE_SUCCESS,
                    palette,
                });
            }
        })
        .catch(catchResponseError);
};

export const createColorPalette = (name) => (dispatch) => {
    dispatch({ type: actionTypes.ADD_COLOR_PALETTE_REQUEST });

    ColorPalettesService.addPalette(name)
        .then(({ status, json }) => {
            if (status !== 200) {
                notify({
                    title: <Translate id="pages.brand.colors.error.create" />,
                    variant: 'error',
                });
                dispatch({
                    type: actionTypes.ADD_COLOR_PALETTE_FAILURE,
                });
            } else {
                dispatch({
                    type: actionTypes.ADD_COLOR_PALETTE_SUCCESS,
                });

                dispatch(getColorPalettes());
                sendAppcuesEvent('Brand color palette created', { id: json.id, name });
            }
        })
        .catch(catchResponseError);
};

export const renameColorPalette =
    (id, name, isOverview = true) =>
    (dispatch, getState) => {
        dispatch({ type: actionTypes.EDIT_COLOR_PALETTE_REQUEST });

        ColorPalettesService.renamePalette(id, name)
            .then(({ status, json }) => {
                if (status !== 200) {
                    notify({
                        title: <Translate id="pages.brand.colors.error.edit" />,
                        variant: 'error',
                    });
                    dispatch({
                        type: actionTypes.EDIT_COLOR_PALETTE_FAILURE,
                    });
                } else {
                    dispatch({
                        type: actionTypes.EDIT_COLOR_PALETTE_SUCCESS,
                    });

                    isOverview ? dispatch(getColorPalettes()) : dispatch(getColorPalette(id));
                    sendAppcuesEvent('Brand color palette renamed', { id: json.id, name });
                }
            })
            .catch(catchResponseError);
    };

export const deleteColorPalette = (ids, redirectAfter?) => (dispatch, getState) => {
    dispatch({ type: actionTypes.DELETE_COLOR_PALETTE_REQUEST });

    ColorPalettesService.deletePalette(ids)
        .then(({ status }) => {
            if (status !== 200) {
                notify({
                    title: <Translate id="pages.brand.colors.error.delete" />,
                    variant: 'error',
                });
                dispatch({
                    type: actionTypes.DELETE_COLOR_PALETTE_FAILURE,
                });
            } else {
                dispatch({
                    type: actionTypes.DELETE_COLOR_PALETTE_SUCCESS,
                });

                !redirectAfter ? dispatch(getColorPalettes()) : redirectAfter();
                sendAppcuesEvent('Brand color palettes removed', { ids });
            }
        })
        .catch(catchResponseError);
};
