import React, { useCallback } from 'react';
import { Button, Tooltip, Slider } from '@bynder/design-system';
import { IconVolumeOff, IconVolumeUp, IconVolumeDown } from '@bynder/icons';
import useForceUpdate from '~/hooks/useForceUpdate';
import useVolumeControl from '~/hooks/useVolumeControl';
import { useAudioShortcuts } from 'packages/hooks/useAudioShortcuts';
import { VolumeControlWrapper } from './VolumeControl.styled';

// Editor volume control
// TODO: Make one shared component for volume (see SimpleVolumeControl)
const VolumeControl = () => {
    const forceUpdate = useForceUpdate();
    const { volume, isMuted, changeVolume, toggleMute, setMutedStatus } = useVolumeControl();

    const handleToggleMute = useCallback(() => {
        toggleMute();
        forceUpdate();
    }, [toggleMute, forceUpdate]);

    useAudioShortcuts(handleToggleMute);

    const handleVolumeChange = (value) => {
        changeVolume(value / 100);
        setMutedStatus(value === 0);
        forceUpdate();
    };

    const getIcon = () => {
        if (isMuted) {
            return <IconVolumeOff />;
        } else if (volume > 0.5) {
            return <IconVolumeUp />;
        }

        return <IconVolumeDown />;
    };

    return (
        <VolumeControlWrapper>
            <Slider
                iconLeft={
                    <Tooltip content={isMuted ? 'Unmute' : 'Mute'}>
                        <Button variant="clean" type="button" icon={getIcon()} onClick={handleToggleMute} />
                    </Tooltip>
                }
                value={isMuted ? 0 : Math.floor(volume * 100)}
                min={0}
                max={100}
                onChange={handleVolumeChange}
            />
        </VolumeControlWrapper>
    );
};

export default VolumeControl;
