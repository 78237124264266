import { createSelector } from 'reselect';

// todo: add correct types for redux-undo or remove it if it's not needed
type Undoable<T> = {
    present: T;
};

// todo: find and add correct types for template
type Template = Record<string, any> & { frameRate: number };

// todo: add creativeEditor type
type CreativeEditor = {
    fonts: any;
    elements: any;
    elementsFonts: any; // are we still using them?
    creativeId: number;
    selectedElementIds: any;
    duration: any;
    editorType: any;
    currentFrame: any;
    template?: Template;
    showAssetSelection: any;
    animations: Record<string, any>;
    saved: boolean;
    textStyles: any;
    contentProperties: any;
    isInAction: boolean;
    redirect: any; // todo: remove
};

export const creativeEditorSelector = (state: { creativeEditor: Undoable<CreativeEditor> }) =>
    state.creativeEditor.present;
export const fontsElementsSelector = (state) => creativeEditorSelector(state).fonts;
export const pureElementsSelector = (state) => creativeEditorSelector(state).elements;
export const showAssetSelectionSelector = (state) => creativeEditorSelector(state).showAssetSelection;
export const currentEditorType = (state) => creativeEditorSelector(state).editorType;
export const currentTemplateSelector = (state) => creativeEditorSelector(state).template;
export const isSavedSelector = (state) => creativeEditorSelector(state).saved;
const animationsSelector = (state) => creativeEditorSelector(state).animations;

export const animationSelector = createSelector(animationsSelector, (animations) =>
    Object.keys(animations).reduce(
        (allanimations, key) => ({
            ...allanimations,
            [key]: animations[key].reduce((acc, item) => ({ ...acc, [item.type]: item }), {}),
        }),
        {},
    ),
);
