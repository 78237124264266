import React from 'react';
import { Button, Dropdown } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconDelete, IconEdit, IconMore, IconUnlinked } from '@bynder/icons';
import generateTestId from '~/helpers/testIdHelpers';
import { ContextProps } from '../types';

function ContextMenu({
    selectedElement,
    detachContentProperty,
    renameContentProperty,
    manageContentProperty,
    deleteContentProperty,
    isInModal = false,
}: ContextProps) {
    const { locked } = selectedElement;
    const { translate } = useTranslate();

    return (
        <Dropdown
            position="bottom-right"
            trigger={({ isOpen, ...triggerProps }) => (
                <Button
                    isDisabled={locked}
                    isPressed={isOpen}
                    variant="clean"
                    title={translate('editor.sidebar.shots.contentProperty.context.dropdown')}
                    icon={<IconMore />}
                    {...generateTestId('shots_content_property_context_btn')}
                    {...{ id: 'shots_content_property_context_btn' }}
                    {...triggerProps}
                />
            )}
        >
            {detachContentProperty && (
                <>
                    <Dropdown.Item onClick={detachContentProperty} icon={<IconUnlinked />}>
                        {translate('editor.sidebar.shots.contentProperty.context.detach')}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                </>
            )}
            {isInModal ? (
                <Dropdown.Item onClick={renameContentProperty} icon={<IconEdit />}>
                    {translate('editor.sidebar.shots.contentProperty.context.rename')}
                </Dropdown.Item>
            ) : (
                <Dropdown.Item onClick={renameContentProperty}>
                    {translate('editor.sidebar.shots.contentProperty.context.rename')}
                </Dropdown.Item>
            )}
            {manageContentProperty && (
                <>
                    <Dropdown.Item onClick={manageContentProperty}>
                        {translate('editor.sidebar.shots.contentProperty.context.manage')}
                    </Dropdown.Item>
                </>
            )}
            <Dropdown.Divider />
            {isInModal ? (
                <Dropdown.Item onClick={deleteContentProperty} icon={<IconDelete />}>
                    {translate('editor.sidebar.shots.contentProperty.context.delete')}
                </Dropdown.Item>
            ) : (
                <Dropdown.Item onClick={deleteContentProperty}>
                    {translate('editor.sidebar.shots.contentProperty.context.delete')}
                </Dropdown.Item>
            )}
        </Dropdown>
    );
}

export default ContextMenu;
