import React from 'react';

import { CommonJobFnType, ExportJobItemType } from './types';

import FailedJobRow from './FailedJobRow';

type Props = {
    jobs: ExportJobItemType[];
    onDelete: CommonJobFnType;
};

const FailedJobBlock = ({ jobs, ...props }: Props) => {
    if (!jobs.length) {
        return null;
    }

    return jobs.map((job) => <FailedJobRow key={job.id} job={job} {...props} />);
};

export default FailedJobBlock;
