import React, { useEffect, useState } from 'react';
import { Form, Input } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconOpacity } from '@bynder/icons';
import editorAutocorrect from 'packages/pages/editor/EditorAutocorrectRules';
import { toPercent, toValue } from '../utils';

const Opacity = ({ creativeModel, selectedElement, disabled }) => {
    const { translate } = useTranslate();
    const { id, locked, opacity } = selectedElement;
    const [value, setValue] = useState<any>(toPercent(opacity).toString());

    const applyChanges = (val: number) => {
        const correctValue = editorAutocorrect('element_opacity', val);
        setValue(correctValue);
        const transformNewValue = toValue(correctValue);

        if (selectedElement.opacity !== transformNewValue) {
            creativeModel.updateElement(id, { opacity: transformNewValue });
        }
    };

    const onBlur = () => {
        applyChanges(value);
    };

    const onKeyDown = (event) => {
        switch (event.key) {
            case 'Enter':
                onBlur();
                break;
            case 'ArrowUp':
            case 'ArrowDown':
                event.preventDefault();
                const numberToAdd = event.key === 'ArrowDown' ? -1 : 1;
                const factor = event.shiftKey ? 10 : 1;
                applyChanges(Number(value) + numberToAdd * factor);
                break;
        }
    };

    useEffect(() => {
        setValue(toPercent(opacity).toString());
    }, [id, opacity]);

    return (
        <Form.Group>
            <Input
                name={translate('editor.sidebar.shots.opacity.label')}
                aria-label={translate('editor.sidebar.shots.opacity.label')}
                type="number"
                value={value}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onChange={(newValue) => setValue(newValue)}
                isDisabled={locked || disabled}
                icon={<IconOpacity />}
                suffix="%"
                data-testid="shots_properties_opacity"
            />
        </Form.Group>
    );
};

export default Opacity;
