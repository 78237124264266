import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import LoadingBar from 'react-redux-loading-bar';
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import { Notifications, token } from '@bynder/design-system';
import UploaderNotifications from 'packages/pages/components/Uploader/UploaderNotifications';
import { hostUrlSelector } from 'packages/store/platform/platform.selectors';
import { getUser } from 'packages/store/user/user.selectors';
import Body from '~/pages/app/Body';
import UpdateChecker from '~/common/checkAppUpdate/UpdateChecker';
import SkeletonProvider from 'packages/skeleton/SkeletonContext';
import Sidebar from './components/sidebar';
import { AppContainer } from './App.styled';
import { useLocation } from 'react-router-dom';
import useHistoryListener from './useHistoryListener';
import { usePageLoader } from './usePageLoader';
import { PageLoader } from './PageLoader';
import useShortcutsRenderModal from './useShortcutsInfoModal';

// This implements the default behavior from styled-components v5
const shouldForwardProp = (propName: string, target: any) => {
    if (typeof target === 'string') {
        // For HTML elements, forward the prop if it is a valid HTML attribute
        return isPropValid(propName);
    }

    // For other elements, forward all props
    return true;
};

const App = (props) => {
    const { user, companies, currentCompany } = useSelector(getUser);
    const location = useLocation();
    const hostPort = useSelector(hostUrlSelector);
    const { renderShortcutsModal } = useShortcutsRenderModal();
    const fullProps = {
        ...props,
        user,
        companies,
        currentCompany,
        hostPort,
    };
    const { shouldShowLoader } = usePageLoader();

    const ShortcutsModal = renderShortcutsModal;

    useHistoryListener();

    useEffect(() => {
        window.focus();
    }, []);

    return (
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            {shouldShowLoader ? (
                <PageLoader />
            ) : (
                <SkeletonProvider>
                    <LoadingBar
                        key="loadingBar"
                        showFastActions
                        className="position-absolute bg-success rounded-right"
                        style={{ height: 5, zIndex: 1010, backgroundColor: `${token.brandPrimary500} !important` }}
                    />
                    <AppContainer>
                        <UpdateChecker />
                        <Sidebar pathname={location.pathname} />
                        <Notifications />
                        <UploaderNotifications />
                        <Body {...fullProps} />
                        <ShortcutsModal />
                    </AppContainer>
                </SkeletonProvider>
            )}
        </StyleSheetManager>
    );
};

export default App;
