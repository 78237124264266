import React, { useState } from 'react';
import { Button, Modal } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import modalContainer from 'packages/common/modalContainer';

const useDesignEditRestrictionModal = () => {
    const { translate } = useTranslate();
    const [isOpen, setRestrictionModalOpen] = useState<boolean>(false);
    const [textData, setTextData] = useState({ title: '', description: '' });

    const handleClose = () => {
        setRestrictionModalOpen(false);
    };

    const renderRestrictionModal = () => (
        <Modal
            container={modalContainer}
            title={textData.title}
            isOpen={isOpen}
            onClose={handleClose}
            actionPrimary={
                <Button variant="primary" onClick={handleClose} title={translate('modal.design.not_published.okay')}>
                    {translate('modal.design.not_published.okay')}
                </Button>
            }
        >
            {textData.description}
        </Modal>
    );

    return {
        setTextData,
        setRestrictionModalOpen,
        renderRestrictionModal,
    };
};

export default useDesignEditRestrictionModal;
