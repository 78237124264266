import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslate } from '@bynder/localization';
import { ElementUpdateTypes } from '@bynder-studio/render-core';
import type { TextElement } from '@bynder-studio/render-web';
import useEditorSelectedElement from '~/hooks/useEditorSelectedElement';
import useEditor from '~/hooks/useEditor';
import { equals } from 'rambda';

type AppliedStyles = ReturnType<TextElement['getCurrentTextSettings']>;
type SettingData<T extends keyof AppliedStyles> = [NonNullable<AppliedStyles[T][0]>, boolean, string];
type EditorSettings = {
    styleIdData: SettingData<'styleId'>;
    colorData: SettingData<'color'>;
    trackingData: SettingData<'tracking'>;
    leadingData: SettingData<'leading'>;
    fontIdData: SettingData<'fontId'>;
    fontSizeData: SettingData<'fontSize'>;
    textTransformData: SettingData<'textTransform'>;
    textDecorationData: SettingData<'textDecoration'>;
    textScriptData: SettingData<'textScript'>;
    strokeData: SettingData<'stroke'>;
};

const stylesToData = (styles: AppliedStyles, mixedLabel: string): EditorSettings => {
    const obj = { ...styles } as AppliedStyles & EditorSettings;

    Object.keys(obj).forEach((key) => {
        const newKey = `${key}Data`;
        const value = obj[key];
        const isMixed = value.length > 1;

        obj[newKey] = [value[0], isMixed, mixedLabel];
    });

    return obj;
};

export default function useTextSelection() {
    const { translate } = useTranslate();
    const { creativeModel } = useEditor();
    const { selectedElement } = useEditorSelectedElement();
    const mixedLabel = useMemo(() => translate('editor.sidebar.shots.text.font.styles'), []);
    const [stylesData, setStylesData] = useState<EditorSettings>(() =>
        stylesToData(selectedElement.getCurrentTextSettings(), mixedLabel),
    );

    const getRawStyles = useCallback(() => selectedElement.getCurrentTextSettings(false), [selectedElement]);

    useEffect(() => {
        setStylesData((data) => {
            const obj = stylesToData(selectedElement.getCurrentTextSettings(), mixedLabel);
            return equals(data, obj) ? data : obj;
        });

        return creativeModel.onElementPartialUpdates(
            selectedElement.id,
            [ElementUpdateTypes.TEXT, ElementUpdateTypes.TEXT_RUNS, ElementUpdateTypes.TEXT_EDITOR_DATA],
            () => {
                setStylesData((data) => {
                    const obj = stylesToData(selectedElement.getCurrentTextSettings(), mixedLabel);
                    return equals(data, obj) ? data : obj;
                });
            },
        );
    }, [creativeModel, selectedElement, mixedLabel]);

    return {
        ...stylesData,
        getRawStyles,
    };
}
