import React, { ReactElement } from 'react';
import { AnimationInTypes, AnimationOutTypes, AnimationTypes } from '@bynder-studio/render-core';
import { IconBlur } from '@bynder/icons';
import { Thumbnail, token } from '@bynder/design-system';
import { Translate } from '@bynder/localization';
import { IconAnimate, IconExposure, IconGradient, IconMove, IconRotate, IconScale, IconSmart } from '@bynder/icons';

export const AnimationsMap: {
    [s: string]: { type: string; icon: ReactElement; value: ReactElement | string; properties: any };
} = {
    [AnimationTypes.BLINK]: {
        type: AnimationTypes.BLINK,
        icon: (
            <Thumbnail
                icon={<IconSmart style={{ color: token.purple600 }} />}
                shape="square"
                backgroundColor={token.purple200}
            />
        ),
        value: <Translate id="editor.sidebar.shots.animation.animate.blink" />,
        properties: [
            {
                name: 'startFrame',
                label: <Translate id="editor.sidebar.animation.start-frame.label" />,
                displayOrder: 2,
                inRow: 'group1',
                autoCorrectionKey: 'animation_start_frame',
            },
            {
                name: 'duration',
                label: <Translate id="editor.sidebar.animation.duration.label" />,
                displayOrder: 3,
                inRow: 'group1',
                autoCorrectionKey: 'animation_duration',
            },
            {
                name: 'timing',
                label: <Translate id="editor.sidebar.animation.timing.label" />,
                displayOrder: 1,
                inRow: false,
            },
            {
                name: 'blinks',
                label: <Translate id="editor.sidebar.animation.blinks.label" />,
                displayOrder: 4,
                inRow: false,
                autoCorrectionKey: 'animation_positive_integer',
            },
        ],
    },
    [AnimationTypes.MOVE]: {
        type: AnimationTypes.MOVE,
        icon: (
            <Thumbnail
                icon={<IconMove style={{ color: token.purple600 }} />}
                shape="square"
                backgroundColor={token.purple200}
            />
        ),
        value: <Translate id="editor.sidebar.shots.animation.animate.move" />,
        properties: [
            {
                name: 'startFrame',
                label: <Translate id="editor.sidebar.animation.start-frame.label" />,
                displayOrder: 2,
                inRow: 'group1',
                autoCorrectionKey: 'animation_start_frame',
            },
            {
                name: 'duration',
                label: <Translate id="editor.sidebar.animation.duration.label" />,
                displayOrder: 3,
                inRow: 'group1',
                autoCorrectionKey: 'animation_duration',
            },
            {
                name: 'timing',
                label: <Translate id="editor.sidebar.animation.timing.label" />,
                displayOrder: 1,
                inRow: false,
            },
            {
                name: 'horizontalPosition',
                label: <Translate id="editor.sidebar.animation.move-to.label" />,
                displayOrder: 4,
                inRow: 'group2',
                autoCorrectionKey: 'animation_integer',
            },
            {
                name: 'verticalPosition',
                label: <>&nbsp;</>,
                displayOrder: 5,
                inRow: 'group2',
                autoCorrectionKey: 'animation_integer',
            },
            {
                name: 'motionBlur',
                label: <Translate id="editor.sidebar.animation.motion-blur.label" />,
                displayOrder: 6,
                inRow: false,
                withDivider: true,
            },
        ],
    },
    [AnimationTypes.POP]: {
        type: AnimationTypes.POP,
        icon: (
            <Thumbnail
                icon={<IconAnimate style={{ color: token.purple600 }} />}
                shape="square"
                backgroundColor={token.purple200}
            />
        ),
        value: <Translate id="editor.sidebar.shots.animation.animate.pop" />,
        properties: [
            {
                name: 'startFrame',
                label: <Translate id="editor.sidebar.animation.start-frame.label" />,
                displayOrder: 2,
                inRow: 'group1',
                autoCorrectionKey: 'animation_start_frame',
            },
            {
                name: 'duration',
                label: <Translate id="editor.sidebar.animation.duration.label" />,
                displayOrder: 3,
                inRow: 'group1',
                autoCorrectionKey: 'animation_duration',
            },
            {
                name: 'timing',
                label: <Translate id="editor.sidebar.animation.timing.label" />,
                displayOrder: 1,
                inRow: false,
            },
            {
                name: 'scale',
                label: <Translate id="editor.sidebar.animation.scale.label" />,
                displayOrder: 4,
                inRow: false,
                autoCorrectionKey: 'animation_positive_percentage',
            },
            {
                name: 'motionBlur',
                label: <Translate id="editor.sidebar.animation.motion-blur.label" />,
                displayOrder: 5,
                inRow: false,
                withDivider: true,
            },
        ],
    },
    [AnimationTypes.ROTATE]: {
        type: AnimationTypes.ROTATE,
        icon: (
            <Thumbnail
                icon={<IconRotate style={{ color: token.purple600 }} />}
                shape="square"
                backgroundColor={token.purple200}
            />
        ),
        value: <Translate id="editor.sidebar.shots.animation.animate.rotate" />,
        properties: [
            {
                name: 'startFrame',
                label: <Translate id="editor.sidebar.animation.start-frame.label" />,
                displayOrder: 2,
                inRow: 'group1',
                autoCorrectionKey: 'animation_start_frame',
            },
            {
                name: 'duration',
                label: <Translate id="editor.sidebar.animation.duration.label" />,
                displayOrder: 3,
                inRow: 'group1',
                autoCorrectionKey: 'animation_duration',
            },
            {
                name: 'timing',
                label: <Translate id="editor.sidebar.animation.timing.label" />,
                displayOrder: 1,
                inRow: false,
            },
            {
                name: 'angle',
                label: <Translate id="editor.sidebar.animation.angle.label" />,
                displayOrder: 4,
                inRow: 'group2',
                autoCorrectionKey: 'animation_rotation',
            },
            {
                name: 'rotations',
                label: <Translate id="editor.sidebar.animation.rotations.label" />,
                displayOrder: 5,
                inRow: 'group2',
                autoCorrectionKey: 'animation_positive_integer',
            },
            {
                name: 'motionBlur',
                label: <Translate id="editor.sidebar.animation.motion-blur.label" />,
                displayOrder: 6,
                inRow: false,
                withDivider: true,
            },
        ],
    },
    [AnimationTypes.SCALE]: {
        type: AnimationTypes.SCALE,
        icon: (
            <Thumbnail
                icon={<IconScale style={{ color: token.purple600 }} />}
                shape="square"
                backgroundColor={token.purple200}
            />
        ),
        value: <Translate id="editor.sidebar.shots.animation.animate.scale" />,
        properties: [
            {
                name: 'startFrame',
                label: <Translate id="editor.sidebar.animation.start-frame.label" />,
                displayOrder: 2,
                inRow: 'group1',
                autoCorrectionKey: 'animation_start_frame',
            },
            {
                name: 'duration',
                label: <Translate id="editor.sidebar.animation.duration.label" />,
                displayOrder: 3,
                inRow: 'group1',
                autoCorrectionKey: 'animation_duration',
            },
            {
                name: 'timing',
                label: <Translate id="editor.sidebar.animation.timing.label" />,
                displayOrder: 1,
                inRow: false,
            },
            {
                name: 'scale',
                label: 'Scale',
                displayOrder: 4,
                inRow: false,
                autoCorrectionKey: 'animation_positive_percentage',
            },
            {
                name: 'motionBlur',
                label: <Translate id="editor.sidebar.animation.motion-blur.label" />,
                displayOrder: 6,
                inRow: false,
                withDivider: true,
            },
        ],
    },
};

export const TransitionInMap: {
    [s: string]: { type: string; icon: ReactElement; value: ReactElement | string; properties: any };
} = {
    [AnimationInTypes.FADE_IN]: {
        type: AnimationInTypes.FADE_IN,
        icon: (
            <Thumbnail
                icon={<IconGradient style={{ color: token.purple600 }} />}
                shape="square"
                backgroundColor={token.purple200}
            />
        ),
        value: <Translate id="editor.sidebar.shots.animation.animate.fade" />,
        properties: [
            {
                name: 'duration',
                label: <Translate id="editor.sidebar.animation.duration.label" />,
                displayOrder: 2,
                inRow: 'group1',
                autoCorrectionKey: 'animation_duration',
            },
            {
                name: 'timing',
                label: <Translate id="editor.sidebar.animation.timing.label" />,
                displayOrder: 1,
                inRow: 'group1',
            },
            {
                name: 'textBreakup',
                label: <Translate id="editor.sidebar.animation.text-breakup.label" />,
                displayOrder: 4,
                inRow: false,
                withDivider: true,
            },
            {
                name: 'opacity',
                label: <Translate id="editor.sidebar.animation.fade-from.label" />,
                displayOrder: 3,
                inRow: false,
                autoCorrectionKey: 'animation_positive_limited_percentage',
            },
        ],
    },
    [AnimationInTypes.IRIS_IN]: {
        type: AnimationInTypes.IRIS_IN,
        icon: (
            <Thumbnail
                icon={<IconExposure style={{ color: token.purple600 }} />}
                shape="square"
                backgroundColor={token.purple200}
            />
        ),
        value: <Translate id="editor.sidebar.shots.animation.animate.iris" />,
        properties: [
            {
                name: 'duration',
                label: <Translate id="editor.sidebar.animation.duration.label" />,
                displayOrder: 2,
                inRow: 'group1',
                autoCorrectionKey: 'animation_duration',
            },
            {
                name: 'timing',
                label: <Translate id="editor.sidebar.animation.timing.label" />,
                displayOrder: 1,
                inRow: 'group1',
            },
            {
                name: 'textBreakup',
                label: <Translate id="editor.sidebar.animation.text-breakup.label" />,
                displayOrder: 3,
                inRow: false,
                withDivider: true,
            },
            {
                name: 'motionBlur',
                label: <Translate id="editor.sidebar.animation.motion-blur.label" />,
                displayOrder: 4,
                inRow: false,
                withDivider: true,
            },
        ],
    },
    [AnimationInTypes.MOVE_IN]: {
        type: AnimationInTypes.MOVE_IN,
        icon: (
            <Thumbnail
                icon={<IconMove style={{ color: token.purple600 }} />}
                shape="square"
                backgroundColor={token.purple200}
            />
        ),
        value: <Translate id="editor.sidebar.shots.animation.animate.move" />,
        properties: [
            {
                name: 'duration',
                label: <Translate id="editor.sidebar.animation.duration.label" />,
                displayOrder: 2,
                inRow: 'group1',
                autoCorrectionKey: 'animation_duration',
            },
            {
                name: 'timing',
                label: <Translate id="editor.sidebar.animation.timing.label" />,
                displayOrder: 1,
                inRow: 'group1',
            },
            {
                name: 'direction',
                label: <Translate id="editor.sidebar.animation.direction.label" />,
                displayOrder: 3,
                inRow: 'group2',
            },
            {
                name: 'distance',
                label: <Translate id="editor.sidebar.animation.distance.label" />,
                displayOrder: 4,
                inRow: 'group2',
                autoCorrectionKey: 'animation_positive_percentage',
            },
            {
                name: 'textBreakup',
                label: <Translate id="editor.sidebar.animation.text-breakup.label" />,
                displayOrder: 5,
                inRow: false,
                withDivider: true,
            },
            {
                name: 'mask',
                label: <Translate id="editor.sidebar.animation.mask.label" />,
                displayOrder: 7,
                inRow: false,
                withDivider: true,
            },
            {
                name: 'motionBlur',
                label: <Translate id="editor.sidebar.animation.motion-blur.label" />,
                displayOrder: 6,
                inRow: false,
                withDivider: true,
            },
        ],
    },
    [AnimationInTypes.POP_IN]: {
        type: AnimationInTypes.POP_IN,
        icon: (
            <Thumbnail
                icon={<IconAnimate style={{ color: token.purple600 }} />}
                shape="square"
                backgroundColor={token.purple200}
            />
        ),
        value: <Translate id="editor.sidebar.shots.animation.animate.pop" />,
        properties: [
            {
                name: 'duration',
                label: <Translate id="editor.sidebar.animation.duration.label" />,
                displayOrder: 2,
                inRow: 'group1',
                autoCorrectionKey: 'animation_duration',
            },
            {
                name: 'timing',
                label: <Translate id="editor.sidebar.animation.timing.label" />,
                displayOrder: 1,
                inRow: 'group1',
            },
            {
                name: 'textBreakup',
                label: <Translate id="editor.sidebar.animation.text-breakup.label" />,
                displayOrder: 3,
                inRow: false,
                withDivider: true,
            },
            {
                name: 'motionBlur',
                label: <Translate id="editor.sidebar.animation.motion-blur.label" />,
                displayOrder: 4,
                inRow: false,
                withDivider: true,
            },
        ],
    },
    [AnimationInTypes.SCALE_IN]: {
        type: AnimationInTypes.SCALE_IN,
        icon: (
            <Thumbnail
                icon={<IconScale style={{ color: token.purple600 }} />}
                shape="square"
                backgroundColor={token.purple200}
            />
        ),
        value: <Translate id="editor.sidebar.shots.animation.animate.scale" />,
        properties: [
            {
                name: 'duration',
                label: <Translate id="editor.sidebar.animation.duration.label" />,
                displayOrder: 2,
                inRow: 'group1',
                autoCorrectionKey: 'animation_duration',
            },
            {
                name: 'timing',
                label: <Translate id="editor.sidebar.animation.timing.label" />,
                displayOrder: 1,
                inRow: 'group1',
            },
            {
                name: 'scale',
                label: <Translate id="editor.sidebar.animation.scale-from.label" />,
                displayOrder: 3,
                inRow: false,
                autoCorrectionKey: 'animation_positive_percentage',
            },
            {
                name: 'textBreakup',
                label: <Translate id="editor.sidebar.animation.text-breakup.label" />,
                displayOrder: 4,
                inRow: false,
                withDivider: true,
            },
            {
                name: 'motionBlur',
                label: <Translate id="editor.sidebar.animation.motion-blur.label" />,
                displayOrder: 5,
                inRow: false,
                withDivider: true,
            },
        ],
    },
    [AnimationInTypes.WIPE_IN]: {
        type: AnimationInTypes.WIPE_IN,
        icon: (
            <Thumbnail
                icon={<IconMove style={{ color: token.purple600 }} />}
                shape="square"
                backgroundColor={token.purple200}
            />
        ),
        value: <Translate id="editor.sidebar.shots.animation.animate.wipe" />,
        properties: [
            {
                name: 'duration',
                label: <Translate id="editor.sidebar.animation.duration.label" />,
                displayOrder: 2,
                inRow: 'group1',
                autoCorrectionKey: 'animation_duration',
            },
            {
                name: 'timing',
                label: <Translate id="editor.sidebar.animation.timing.label" />,
                displayOrder: 1,
                inRow: 'group1',
            },
            {
                name: 'direction',
                label: <Translate id="editor.sidebar.animation.direction.label" />,
                displayOrder: 3,
                inRow: 'group2',
            },
            {
                name: 'textBreakup',
                label: <Translate id="editor.sidebar.animation.text-breakup.label" />,
                displayOrder: 5,
                inRow: false,
                withDivider: true,
            },
            {
                name: 'softness',
                label: <Translate id="editor.sidebar.animation.softness.label" />,
                displayOrder: 4,
                inRow: 'group2',
                autoCorrectionKey: 'animation_positive_integer',
            },
            {
                name: 'motionBlur',
                label: <Translate id="editor.sidebar.animation.motion-blur.label" />,
                displayOrder: 6,
                inRow: false,
                withDivider: true,
            },
        ],
    },
    [AnimationInTypes.FADE_BLUR_IN]: {
        type: AnimationInTypes.FADE_BLUR_IN,
        icon: (
            <Thumbnail
                icon={<IconBlur style={{ color: token.purple600 }} />}
                shape="square"
                backgroundColor={token.purple200}
            />
        ),
        value: <Translate id="editor.sidebar.shots.animation.animate.fade-blur" />,
        properties: [
            {
                name: 'duration',
                label: <Translate id="editor.sidebar.animation.duration.label" />,
                displayOrder: 2,
                inRow: 'group1',
                autoCorrectionKey: 'animation_duration',
            },
            {
                name: 'timing',
                label: <Translate id="editor.sidebar.animation.timing.label" />,
                displayOrder: 1,
                inRow: 'group1',
            },
            {
                name: 'blur',
                label: <Translate id="editor.sidebar.animation.blur-from.label" />,
                displayOrder: 3,
                inRow: false,
                autoCorrectionKey: 'animation_positive_limited_percentage',
            },
            {
                name: 'textBreakup',
                label: <Translate id="editor.sidebar.animation.text-breakup.label" />,
                displayOrder: 4,
                inRow: false,
                withDivider: true,
            },
        ],
    },
    [AnimationInTypes.FADE_MOVE_IN]: {
        type: AnimationInTypes.FADE_MOVE_IN,
        icon: (
            <Thumbnail
                icon={<IconMove style={{ color: token.purple600 }} />}
                shape="square"
                backgroundColor={token.purple200}
            />
        ),
        value: <Translate id="editor.sidebar.shots.animation.animate.fade-move" />,
        properties: [
            {
                name: 'duration',
                label: <Translate id="editor.sidebar.animation.duration.label" />,
                displayOrder: 2,
                inRow: 'group1',
                autoCorrectionKey: 'animation_duration',
            },
            {
                name: 'timing',
                label: <Translate id="editor.sidebar.animation.timing.label" />,
                displayOrder: 1,
                inRow: 'group1',
            },
            {
                name: 'direction',
                label: <Translate id="editor.sidebar.animation.direction.label" />,
                displayOrder: 3,
                inRow: 'group2',
            },
            {
                name: 'distance',
                label: <Translate id="editor.sidebar.animation.distance.label" />,
                displayOrder: 4,
                inRow: 'group2',
                autoCorrectionKey: 'animation_positive_percentage',
            },
            {
                name: 'textBreakup',
                label: <Translate id="editor.sidebar.animation.text-breakup.label" />,
                displayOrder: 5,
                inRow: false,
                withDivider: true,
            },
            {
                name: 'mask',
                label: <Translate id="editor.sidebar.animation.mask.label" />,
                displayOrder: 7,
                inRow: false,
                withDivider: true,
            },
            {
                name: 'motionBlur',
                label: <Translate id="editor.sidebar.animation.motion-blur.label" />,
                displayOrder: 6,
                inRow: false,
                withDivider: true,
            },
        ],
    },
    [AnimationInTypes.FADE_SCALE_IN]: {
        type: AnimationInTypes.FADE_SCALE_IN,
        icon: (
            <Thumbnail
                icon={<IconScale style={{ color: token.purple600 }} />}
                shape="square"
                backgroundColor={token.purple200}
            />
        ),
        value: <Translate id="editor.sidebar.shots.animation.animate.fade-scale" />,
        properties: [
            {
                name: 'duration',
                label: <Translate id="editor.sidebar.animation.duration.label" />,
                displayOrder: 2,
                inRow: 'group1',
                autoCorrectionKey: 'animation_duration',
            },
            {
                name: 'timing',
                label: <Translate id="editor.sidebar.animation.timing.label" />,
                displayOrder: 1,
                inRow: 'group1',
            },
            {
                name: 'scale',
                label: <Translate id="editor.sidebar.animation.scale-from.label" />,
                displayOrder: 3,
                inRow: false,
                autoCorrectionKey: 'animation_positive_percentage',
            },
            {
                name: 'textBreakup',
                label: <Translate id="editor.sidebar.animation.text-breakup.label" />,
                displayOrder: 4,
                inRow: false,
                withDivider: true,
            },
            {
                name: 'motionBlur',
                label: <Translate id="editor.sidebar.animation.motion-blur.label" />,
                displayOrder: 5,
                inRow: false,
                withDivider: true,
            },
        ],
    },
};

export const TransitionOutMap: {
    [s: string]: { type: string; icon: ReactElement; value: ReactElement | string; properties: any };
} = {
    [AnimationOutTypes.FADE_OUT]: {
        type: AnimationOutTypes.FADE_OUT,
        icon: (
            <Thumbnail
                icon={<IconGradient style={{ color: token.purple600 }} />}
                shape="square"
                backgroundColor={token.purple200}
            />
        ),
        value: <Translate id="editor.sidebar.shots.animation.animate.fade" />,
        properties: [
            {
                name: 'duration',
                label: <Translate id="editor.sidebar.animation.duration.label" />,
                displayOrder: 2,
                inRow: 'group1',
                autoCorrectionKey: 'animation_duration',
            },
            {
                name: 'timing',
                label: <Translate id="editor.sidebar.animation.timing.label" />,
                displayOrder: 1,
                inRow: 'group1',
            },
            {
                name: 'opacity',
                label: <Translate id="editor.sidebar.animation.fade-to.label" />,
                displayOrder: 3,
                inRow: false,
                autoCorrectionKey: 'animation_positive_limited_percentage',
            },
            {
                name: 'textBreakup',
                label: <Translate id="editor.sidebar.animation.text-breakup.label" />,
                displayOrder: 4,
                inRow: false,
                withDivider: true,
            },
        ],
    },
    [AnimationOutTypes.IRIS_OUT]: {
        type: AnimationOutTypes.IRIS_OUT,
        icon: (
            <Thumbnail
                icon={<IconExposure style={{ color: token.purple600 }} />}
                shape="square"
                backgroundColor={token.purple200}
            />
        ),
        value: <Translate id="editor.sidebar.shots.animation.animate.iris" />,
        properties: [
            {
                name: 'duration',
                label: <Translate id="editor.sidebar.animation.duration.label" />,
                displayOrder: 2,
                inRow: 'group1',
                autoCorrectionKey: 'animation_duration',
            },
            {
                name: 'timing',
                label: <Translate id="editor.sidebar.animation.timing.label" />,
                displayOrder: 1,
                inRow: 'group1',
            },
            {
                name: 'textBreakup',
                label: <Translate id="editor.sidebar.animation.text-breakup.label" />,
                displayOrder: 3,
                inRow: false,
                withDivider: true,
            },
            {
                name: 'motionBlur',
                label: <Translate id="editor.sidebar.animation.motion-blur.label" />,
                displayOrder: 4,
                inRow: false,
                withDivider: true,
            },
        ],
    },
    [AnimationOutTypes.MOVE_OUT]: {
        type: AnimationOutTypes.MOVE_OUT,
        icon: (
            <Thumbnail
                icon={<IconMove style={{ color: token.purple600 }} />}
                shape="square"
                backgroundColor={token.purple200}
            />
        ),
        value: <Translate id="editor.sidebar.shots.animation.animate.move" />,
        properties: [
            {
                name: 'duration',
                label: <Translate id="editor.sidebar.animation.duration.label" />,
                displayOrder: 2,
                inRow: 'group1',
                autoCorrectionKey: 'animation_duration',
            },
            {
                name: 'timing',
                label: <Translate id="editor.sidebar.animation.timing.label" />,
                displayOrder: 1,
                inRow: 'group1',
            },
            {
                name: 'direction',
                label: <Translate id="editor.sidebar.animation.direction.label" />,
                displayOrder: 3,
                inRow: 'group2',
            },
            {
                name: 'distance',
                label: <Translate id="editor.sidebar.animation.distance.label" />,
                displayOrder: 4,
                inRow: 'group2',
                autoCorrectionKey: 'animation_positive_percentage',
            },
            {
                name: 'textBreakup',
                label: <Translate id="editor.sidebar.animation.text-breakup.label" />,
                displayOrder: 5,
                inRow: false,
                withDivider: true,
            },
            {
                name: 'mask',
                label: <Translate id="editor.sidebar.animation.mask.label" />,
                displayOrder: 7,
                inRow: false,
                withDivider: true,
            },
            {
                name: 'motionBlur',
                label: <Translate id="editor.sidebar.animation.motion-blur.label" />,
                displayOrder: 6,
                inRow: false,
                withDivider: true,
            },
        ],
    },
    [AnimationOutTypes.POP_OUT]: {
        type: AnimationOutTypes.POP_OUT,
        icon: (
            <Thumbnail
                icon={<IconAnimate style={{ color: token.purple600 }} />}
                shape="square"
                backgroundColor={token.purple200}
            />
        ),
        value: <Translate id="editor.sidebar.shots.animation.animate.pop" />,
        properties: [
            {
                name: 'duration',
                label: <Translate id="editor.sidebar.animation.duration.label" />,
                displayOrder: 2,
                inRow: 'group1',
                autoCorrectionKey: 'animation_duration',
            },
            {
                name: 'timing',
                label: <Translate id="editor.sidebar.animation.timing.label" />,
                displayOrder: 1,
                inRow: 'group1',
            },
            {
                name: 'textBreakup',
                label: <Translate id="editor.sidebar.animation.text-breakup.label" />,
                displayOrder: 3,
                inRow: false,
                withDivider: true,
            },
            {
                name: 'motionBlur',
                label: <Translate id="editor.sidebar.animation.motion-blur.label" />,
                displayOrder: 4,
                inRow: false,
                withDivider: true,
            },
        ],
    },
    [AnimationOutTypes.SCALE_OUT]: {
        type: AnimationOutTypes.SCALE_OUT,
        icon: (
            <Thumbnail
                icon={<IconScale style={{ color: token.purple600 }} />}
                shape="square"
                backgroundColor={token.purple200}
            />
        ),
        value: <Translate id="editor.sidebar.shots.animation.animate.scale" />,
        properties: [
            {
                name: 'duration',
                label: <Translate id="editor.sidebar.animation.duration.label" />,
                displayOrder: 2,
                inRow: 'group1',
                autoCorrectionKey: 'animation_duration',
            },
            {
                name: 'timing',
                label: <Translate id="editor.sidebar.animation.timing.label" />,
                displayOrder: 1,
                inRow: 'group1',
            },
            {
                name: 'scale',
                label: <Translate id="editor.sidebar.animation.scale-to.label" />,
                displayOrder: 3,
                inRow: false,
                autoCorrectionKey: 'animation_positive_percentage',
            },
            {
                name: 'textBreakup',
                label: <Translate id="editor.sidebar.animation.text-breakup.label" />,
                displayOrder: 4,
                inRow: false,
                withDivider: true,
            },
            {
                name: 'motionBlur',
                label: <Translate id="editor.sidebar.animation.motion-blur.label" />,
                displayOrder: 5,
                inRow: false,
                withDivider: true,
            },
        ],
    },
    [AnimationOutTypes.WIPE_OUT]: {
        type: AnimationOutTypes.WIPE_OUT,
        icon: (
            <Thumbnail
                icon={<IconMove style={{ color: token.purple600 }} />}
                shape="square"
                backgroundColor={token.purple200}
            />
        ),
        value: <Translate id="editor.sidebar.shots.animation.animate.wipe" />,
        properties: [
            {
                name: 'duration',
                label: <Translate id="editor.sidebar.animation.duration.label" />,
                displayOrder: 2,
                inRow: 'group1',
                autoCorrectionKey: 'animation_duration',
            },
            {
                name: 'timing',
                label: <Translate id="editor.sidebar.animation.timing.label" />,
                displayOrder: 1,
                inRow: 'group1',
            },
            {
                name: 'direction',
                label: <Translate id="editor.sidebar.animation.direction.label" />,
                displayOrder: 3,
                inRow: 'group2',
            },
            {
                name: 'textBreakup',
                label: <Translate id="editor.sidebar.animation.text-breakup.label" />,
                displayOrder: 5,
                inRow: false,
                withDivider: true,
            },
            {
                name: 'softness',
                label: <Translate id="editor.sidebar.animation.softness.label" />,
                displayOrder: 4,
                inRow: 'group2',
                autoCorrectionKey: 'animation_positive_integer',
            },
            {
                name: 'motionBlur',
                label: <Translate id="editor.sidebar.animation.motion-blur.label" />,
                displayOrder: 6,
                inRow: false,
                withDivider: true,
            },
        ],
    },
    [AnimationOutTypes.FADE_BLUR_OUT]: {
        type: AnimationOutTypes.FADE_BLUR_OUT,
        icon: (
            <Thumbnail
                icon={<IconBlur style={{ color: token.purple600 }} />}
                shape="square"
                backgroundColor={token.purple200}
            />
        ),
        value: <Translate id="editor.sidebar.shots.animation.animate.fade-blur-out" />,
        properties: [
            {
                name: 'duration',
                label: <Translate id="editor.sidebar.animation.duration.label" />,
                displayOrder: 2,
                inRow: 'group1',
                autoCorrectionKey: 'animation_duration',
            },
            {
                name: 'timing',
                label: <Translate id="editor.sidebar.animation.timing.label" />,
                displayOrder: 1,
                inRow: 'group1',
            },
            {
                name: 'blur',
                label: <Translate id="editor.sidebar.animation.blur-to.label" />,
                displayOrder: 3,
                inRow: false,
                autoCorrectionKey: 'animation_positive_limited_percentage',
            },
            {
                name: 'textBreakup',
                label: <Translate id="editor.sidebar.animation.text-breakup.label" />,
                displayOrder: 4,
                inRow: false,
                withDivider: true,
            },
        ],
    },
    [AnimationOutTypes.FADE_MOVE_OUT]: {
        type: AnimationOutTypes.FADE_MOVE_OUT,
        icon: (
            <Thumbnail
                icon={<IconMove style={{ color: token.purple600 }} />}
                shape="square"
                backgroundColor={token.purple200}
            />
        ),
        value: <Translate id="editor.sidebar.shots.animation.animate.fade-move-out" />,
        properties: [
            {
                name: 'duration',
                label: <Translate id="editor.sidebar.animation.duration.label" />,
                displayOrder: 2,
                inRow: 'group1',
                autoCorrectionKey: 'animation_duration',
            },
            {
                name: 'timing',
                label: <Translate id="editor.sidebar.animation.timing.label" />,
                displayOrder: 1,
                inRow: 'group1',
            },
            {
                name: 'direction',
                label: <Translate id="editor.sidebar.animation.direction.label" />,
                displayOrder: 3,
                inRow: 'group2',
            },
            {
                name: 'distance',
                label: <Translate id="editor.sidebar.animation.distance.label" />,
                displayOrder: 4,
                inRow: 'group2',
                autoCorrectionKey: 'animation_positive_percentage',
            },
            {
                name: 'textBreakup',
                label: <Translate id="editor.sidebar.animation.text-breakup.label" />,
                displayOrder: 5,
                inRow: false,
                withDivider: true,
            },
            {
                name: 'mask',
                label: <Translate id="editor.sidebar.animation.mask.label" />,
                displayOrder: 7,
                inRow: false,
                withDivider: true,
            },
            {
                name: 'motionBlur',
                label: <Translate id="editor.sidebar.animation.motion-blur.label" />,
                displayOrder: 6,
                inRow: false,
                withDivider: true,
            },
        ],
    },
    [AnimationOutTypes.FADE_SCALE_OUT]: {
        type: AnimationOutTypes.FADE_SCALE_OUT,
        icon: (
            <Thumbnail
                icon={<IconScale style={{ color: token.purple600 }} />}
                shape="square"
                backgroundColor={token.purple200}
            />
        ),
        value: <Translate id="editor.sidebar.shots.animation.animate.fade-scale-out" />,
        properties: [
            {
                name: 'duration',
                label: <Translate id="editor.sidebar.animation.duration.label" />,
                displayOrder: 2,
                inRow: 'group1',
                autoCorrectionKey: 'animation_duration',
            },
            {
                name: 'timing',
                label: <Translate id="editor.sidebar.animation.timing.label" />,
                displayOrder: 1,
                inRow: 'group1',
            },
            {
                name: 'scale',
                label: <Translate id="editor.sidebar.animation.scale-to.label" />,
                displayOrder: 3,
                inRow: false,
                autoCorrectionKey: 'animation_positive_percentage',
            },
            {
                name: 'textBreakup',
                label: <Translate id="editor.sidebar.animation.text-breakup.label" />,
                displayOrder: 4,
                inRow: false,
                withDivider: true,
            },
            {
                name: 'motionBlur',
                label: <Translate id="editor.sidebar.animation.motion-blur.label" />,
                displayOrder: 5,
                inRow: false,
                withDivider: true,
            },
        ],
    },
};
