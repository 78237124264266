import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getShortcutsModalStatusSelector } from '~/store/general/general.selectors';
import { toggleShortcutsModal } from '~/store/general/general.actions';
import { isCtrlKey } from '~/helpers/hotKeys';
import renderShortcutsInfoModal from './shortcutsModal';

export default function useShortcutsRenderModal() {
    const dispatch = useDispatch();
    const isModalOpen: boolean = useSelector(getShortcutsModalStatusSelector);
    const setModalOpen = (show: boolean) => dispatch(toggleShortcutsModal(show));

    const renderShortcutsModal = () => {
        return renderShortcutsInfoModal({ isOpen: isModalOpen, setModalOpen });
    };

    useEffect(() => {
        window.addEventListener('keydown', (e) => {
            if ((isCtrlKey(e) && e.code === 'Slash') || e.code === 'F1') {
                e.preventDefault();
                setModalOpen(true);
            }
        });
    });

    return { renderShortcutsModal, setShortcutsModalOpen: setModalOpen };
}
