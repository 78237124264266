import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { customerIdSelector } from 'packages/store/user/user.selectors';
import { subscribeDesignsThumbnailsOptions, useSocketSubscribe } from 'packages/socket';
import { updateThumbnails } from '~/store/creatives/creatives.actions';
import { updateCollectionsThumbnails } from '~/store/collections/collections.actions';
import { updateTemplateThumbnails } from '~/store/templates/templates.actions';
import { updateCategoryThumbnails } from 'packages/store/categories/categories.actions';
import useSuperSet from 'packages/hooks/useSuperSet';

const emptyCreativeIds: number[] = [];

/** Listen for design thumbnail updates via ws */
export default function useCreativeThumbnailsUpdates(pendingIds: number[]) {
    const customerId = useSelector(customerIdSelector);

    const dispatch = useDispatch();

    // prevents unnecessary re-subscriptions when pendingIds change
    // the array stays the same until there is no new items in pendingIds
    const memoizedPendingIds = useSuperSet(pendingIds);
    const creativeIds = pendingIds.length ? memoizedPendingIds : emptyCreativeIds;

    const subscribeDesignThumbnails = useSocketSubscribe(
        subscribeDesignsThumbnailsOptions({
            customerId,
            creativeIds,
            onMessage: ({ items }) => {
                dispatch(updateThumbnails(items));
                dispatch(updateTemplateThumbnails(items));
                dispatch(updateCollectionsThumbnails(items));
                dispatch(updateCategoryThumbnails(items));
            },
        }),
    );

    useEffect(() => {
        if (!creativeIds.length) {
            return;
        }

        return subscribeDesignThumbnails();
    }, [creativeIds, subscribeDesignThumbnails]);
}
