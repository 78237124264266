import React, { useMemo, useRef, useState } from 'react';
import { MAX_CP_NAME_LENGTH } from '@bynder-studio/misc';
import { BaseMultiPageModel, IBaseMultiPageModel } from '@bynder-studio/render-core';
import { Form, Input, ListItem } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import useOnClickOutside from 'packages/hooks/useOnClickOutside';
import ContextMenu from '../../ContextMenu';
import { useGenerateIcon } from '../../useGenerateIcon';

interface ItemActions {
    onRename: (id: string | null) => void;
    onDelete: (id: string) => void;
    onChange: (id: string, name: string) => void;
}

type ItemProps = {
    property: any;
    propertyToRenameId: string | null;
    actions: ItemActions;
};

const ContentPropertyItem = ({ property, propertyToRenameId, actions }: ItemProps) => {
    const [propertyName, setPropertyName] = useState<string>(property.name);
    const { onRename, onDelete, onChange } = actions;
    const onPropertyRename = () => onRename(property.uuid);
    const { translate } = useTranslate();

    const inputRef = useRef(null);

    const isLongName = propertyName.length > MAX_CP_NAME_LENGTH;

    const { generateIcon } = useGenerateIcon({ isInModal: true });

    const getSubtext = () => {
        const { properties, type } = property;

        if (isLongName) {
            return null;
        }

        switch (type) {
            case 'TEXT':
                return properties?.formattedText?.value || '';
            case 'IMAGE':
            case 'VIDEO':
                return properties?.fileName || '';
            default:
                return '';
        }
    };

    const onKeyEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onChange(property.uuid, propertyName);
        }
    };

    useOnClickOutside(inputRef, () => {
        onChange(property.uuid, propertyName);

        if (!propertyName.length || isLongName) {
            setPropertyName(property.name);
        }
    });

    return (
        <ListItem
            thumbnail={generateIcon(property, false)}
            subtext={getSubtext()}
            rightElements={
                <ContextMenu
                    selectedElement={property}
                    renameContentProperty={onPropertyRename}
                    deleteContentProperty={() => onDelete(property.uuid)}
                    isInModal
                />
            }
        >
            {property.uuid === propertyToRenameId ? (
                <Form>
                    <Form.Group>
                        <Input
                            ref={inputRef}
                            value={propertyName}
                            onChange={setPropertyName}
                            hasClearButton
                            isInvalid={isLongName}
                            onKeyDown={onKeyEnter}
                            autoFocus
                        />
                        {isLongName && (
                            <Form.HelperText isInvalid={isLongName}>
                                {translate('input.error.max.length')}
                            </Form.HelperText>
                        )}
                    </Form.Group>
                </Form>
            ) : (
                property.name
            )}
        </ListItem>
    );
};

export default ContentPropertyItem;
