const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

export const getDiff = (text1: string, text2: string, cursorStartPos: number, cursorEndPos: number) => {
    let start2 = clamp(cursorStartPos, 0, text2.length);
    let start1 = clamp(cursorStartPos, 0, start2);

    while (text1.substring(0, start1) !== text2.substring(0, start2)) {
        start1 = Math.max(start1 - 1, 0);
        start2 = Math.max(start2 - 1, 0);
    }

    const back = clamp(cursorStartPos, 0, text2.length) - start2;

    let end2 = clamp(cursorStartPos - back, 0, text2.length);
    let end1 = clamp(cursorEndPos - back, 0, text1.length);

    while (text1.substring(end1) !== text2.substring(end2)) {
        const l1 = text1.length - end1;
        const l2 = text2.length - end2;

        if (l1 > l2) {
            end1 = Math.min(end1 + 1, text1.length);
        }

        if (l2 > l1) {
            end2 = Math.min(end2 + 1, text2.length);
        }

        if (l1 === l2) {
            end1 = Math.min(end1 + 1, text1.length);
            end2 = Math.min(end2 + 1, text2.length);
        }
    }

    const diff = text2.substring(start2, end2);

    return { start: start1, end: end1, diff };
};
