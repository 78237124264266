import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from '~/helpers/debounce';
import { OrderFilterType, SortingFilterType } from 'packages/pages/components/filters/components/Ordering/types';
import { fetchAssetsByOptions } from '~/store/assets/assets.actions';
import { getUploads } from 'packages/store/uploads/uploads.selectors';

const useFilters = () => {
    // if you want to predefine filters value on page load
    // create the filters state here and pass a value as a prop directly to filters
    const dispatch = useDispatch();
    const { fetchOptions } = useSelector(getUploads);

    const onSearch = useMemo(
        () =>
            debounce(
                (newSearchValue: string) => {
                    const newOptions = { ...fetchOptions };
                    newOptions.search = newSearchValue;

                    dispatch(fetchAssetsByOptions({ ...newOptions }, true));
                },
                1000,
                false,
            ),
        [fetchOptions],
    );

    const onSort = (sortBy: SortingFilterType, sortOrder: OrderFilterType) => {
        const newOptions = { ...fetchOptions };
        newOptions.sort = [sortBy, sortOrder];

        dispatch(fetchAssetsByOptions({ ...newOptions }, true));
    };

    return { onSearch, onSort };
};

export default useFilters;
