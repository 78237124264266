import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import TransitionControl from '../../../../TransitionControl/TransitionControl';
import { widthToDuration } from '../../tracks/helper';
import { TransitionInOutControlStyled } from './TransitionInOutControl.styled';

const pixelToPercentage = (trackWidth, subWidth) => {
    return (subWidth / trackWidth) * 100;
};

const overlappingModifierClass = 'TransitionControl--overlapping';

const overlapIfNeeded = (animationIn, animationOut, containerRef, elementInRef, elementOutRef) => {
    if (animationIn && animationOut) {
        const containerStyles = getComputedStyle(containerRef.current);
        const inStyles = getComputedStyle(elementInRef.current);
        const outStyles = getComputedStyle(elementOutRef.current);

        const gapBetween =
            parseFloat(containerStyles.width) - (parseFloat(inStyles.width) + parseFloat(outStyles.width));
        if (gapBetween <= 18) {
            elementInRef.current.classList.add(overlappingModifierClass);
            elementOutRef.current.classList.add(overlappingModifierClass);
        } else {
            elementInRef.current.classList.remove(overlappingModifierClass);
            elementOutRef.current.classList.remove(overlappingModifierClass);
        }
    } else {
        animationIn && elementInRef.current.classList.remove(overlappingModifierClass);
        animationOut && elementOutRef.current.classList.remove(overlappingModifierClass);
    }
};

const updateTransitionControl = (field, animationIn, animationOut, containerRef, elementInRef, elementOutRef) => {
    const containerStyles = getComputedStyle(containerRef.current);
    const inStyles = animationIn ? getComputedStyle(elementInRef.current) : null;
    const outStyles = animationOut ? getComputedStyle(elementOutRef.current) : null;

    if (field === 'in' && animationOut) {
        if (parseFloat(inStyles.width) > parseFloat(outStyles.left)) {
            elementOutRef.current.style.left = parseFloat(inStyles.width) + 'px';
            elementOutRef.current.style.width = parseFloat(containerStyles.width) - parseFloat(inStyles.width) + 'px';
        }
    } else if (field === 'out' && animationIn) {
        if (parseFloat(outStyles.left) < parseFloat(inStyles.width)) {
            elementInRef.current.style.width = parseFloat(outStyles.left) + 'px';
        }
    }

    overlapIfNeeded(animationIn, animationOut, containerRef, elementInRef, elementOutRef);
};

const getInOutStyles = (animationIn, animationOut, elementDuration, animationInIsValid, animationOutIsValid) => {
    const animationInStyles = animationIn
        ? {
              left: 0,
              width: `${pixelToPercentage(elementDuration, animationIn.duration)}%`,
          }
        : null;

    if (animationIn && !animationInIsValid) {
        animationInStyles.width = 0;
    }

    const animationOutStyles = animationOut
        ? {
              left: `${100 - pixelToPercentage(elementDuration, animationOut.duration)}%`,
              width: `${pixelToPercentage(elementDuration, animationOut.duration)}%`,
          }
        : null;

    if (animationOut && !animationOutIsValid) {
        animationOutStyles.left = '100%';
        animationOutStyles.width = 0;
    }

    return {
        animationIn: animationInStyles,
        animationOut: animationOutStyles,
    };
};

const applyTransitionChanges = (
    animationIn,
    animationOut,
    trackContainerRef,
    elementInRef,
    elementOutRef,
    trackDuration,
    elementDuration,
    onChangeEnd,
) => {
    const trackStyles = getComputedStyle(trackContainerRef.current);
    const inStyles = animationIn ? getComputedStyle(elementInRef.current) : null;
    const outStyles = animationOut ? getComputedStyle(elementOutRef.current) : null;

    let animationInDuration = animationIn
        ? widthToDuration(trackDuration, pixelToPercentage(parseFloat(trackStyles.width), parseFloat(inStyles.width)))
        : null;
    let animationOutDuration = animationOut
        ? widthToDuration(trackDuration, pixelToPercentage(parseFloat(trackStyles.width), parseFloat(outStyles.width)))
        : null;

    if (
        animationInDuration !== null &&
        animationOutDuration !== null &&
        animationInDuration + animationOutDuration === elementDuration - 1
    ) {
        animationOutDuration += 1;
    }

    if (animationIn && animationInDuration === animationIn.duration) {
        animationInDuration = null;
        const {
            animationIn: { width },
        } = getInOutStyles(animationIn, null, elementDuration, true, true);
        elementInRef.current.style.width = width;
    }

    if (animationOut && animationOutDuration === animationOut.duration) {
        animationOutDuration = null;
        const {
            animationOut: { width, left },
        } = getInOutStyles(null, animationOut, elementDuration, true, true);
        elementOutRef.current.style.width = width;
        elementOutRef.current.style.left = left;
    }

    onChangeEnd({
        animationInDuration,
        animationOutDuration,
    });
};

type Props = {
    onChangeEnd: () => void;
    elementDuration: number;
    trackDuration: number;
    animationIn: any;
    animationOut: any;
    containerRef: any;
    trackContainerRef: any;
    animationInIsValid: boolean;
    animationOutIsValid: boolean;
    disabled: boolean;
};

const TransitionInOutControl = ({
    elementDuration,
    animationIn,
    animationOut,
    containerRef,
    onChangeEnd,
    trackDuration,
    trackContainerRef,
    animationInIsValid,
    animationOutIsValid,
    disabled,
    color,
    backgroundColor,
}: Props) => {
    const elementInRef = useRef();
    const elementOutRef = useRef();

    useEffect(() => {
        overlapIfNeeded(animationIn, animationOut, containerRef, elementInRef, elementOutRef);
    }, [animationIn && animationIn.duration, animationOut && animationOut.duration]);

    const inOutStyles = useMemo(
        () => getInOutStyles(animationIn, animationOut, elementDuration, animationInIsValid, animationOutIsValid),
        [
            animationIn && animationIn.duration,
            animationOut && animationOut.duration,
            animationInIsValid,
            animationOutIsValid,
            elementDuration,
        ],
    );

    const onAnimationInChange = useCallback(() => {
        updateTransitionControl('in', animationIn, animationOut, containerRef, elementInRef, elementOutRef);
    }, [animationIn && animationIn.duration, animationOut && animationOut.duration, containerRef]);

    const onAnimationInChangeEnd = useCallback(() => {
        applyTransitionChanges(
            animationIn,
            animationOut,
            trackContainerRef,
            elementInRef,
            elementOutRef,
            trackDuration,
            elementDuration,
            onChangeEnd,
        );
    }, [animationIn && animationIn.duration, trackDuration, elementDuration, onChangeEnd]);

    const onAnimationOutChange = useCallback(() => {
        updateTransitionControl('out', animationIn, animationOut, containerRef, elementInRef, elementOutRef);
    }, [animationIn && animationIn.duration, animationOut && animationOut.duration, containerRef, elementOutRef]);

    const onAnimationOutChangeEnd = useCallback(() => {
        applyTransitionChanges(
            animationIn,
            animationOut,
            trackContainerRef,
            elementInRef,
            elementOutRef,
            trackDuration,
            elementDuration,
            onChangeEnd,
        );
    }, [animationOut && animationOut.duration, trackDuration, elementDuration, onChangeEnd]);

    return (
        <TransitionInOutControlStyled>
            {animationIn && (
                <TransitionControl
                    label="Transition in"
                    onChange={onAnimationInChange}
                    onChangeEnd={onAnimationInChangeEnd}
                    elementRef={elementInRef}
                    containerRef={containerRef}
                    elementDuration={elementDuration}
                    duration={animationIn.duration}
                    style={inOutStyles.animationIn}
                    startControl={false}
                    disabled={disabled}
                    color={color}
                    backgroundColor={backgroundColor}
                />
            )}
            {animationOut && (
                <TransitionControl
                    label="Transition out"
                    onChange={onAnimationOutChange}
                    onChangeEnd={onAnimationOutChangeEnd}
                    elementRef={elementOutRef}
                    containerRef={containerRef}
                    elementDuration={elementDuration}
                    duration={animationOut.duration}
                    style={inOutStyles.animationOut}
                    endControl={false}
                    disabled={disabled}
                    color={color}
                    backgroundColor={backgroundColor}
                />
            )}
        </TransitionInOutControlStyled>
    );
};

export default TransitionInOutControl;
