import React, { useEffect, useRef, useState } from 'react';
import { clone } from 'rambda';
import { ListDragAndDrop, Modal, Button, Input, Thumbnail, Form } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import PageManageOptions from './PageManageOptions';
import useOnClickOutside from 'packages/hooks/useOnClickOutside';
import useForceUpdate from '~/hooks/useForceUpdate';
import { PageManageProps, PageMeta } from '../types';
import { usePagePreview } from 'packages/pages/design/hooks/usePagePreview';
import modalContainer from 'packages/common/modalContainer';

const PageManageModal = ({ pages, show, onClose, onConfirm }: PageManageProps) => {
    const [renamedPage, setRenamedPage] = useState<PageMeta | null>(null);
    const [pageLocalName, setLocalPageName] = useState<string>('');
    const pagesOrder = pages.map((page) => page.id);
    const pagesRef = useRef(clone(pages));
    const inputRef = useRef<HTMLInputElement>(null);
    const newPagesOrder = pagesRef.current.map((page) => page.id);
    const hasOrderChanges = pagesOrder.some((id, index) => id !== newPagesOrder[index]);
    const { pagePreviewImages } = usePagePreview();

    const MAX_INPUT_SIZE = 120;
    const isInputLong = pageLocalName.length > MAX_INPUT_SIZE;

    const getOriginalPage = (page: PageMeta) => pages.find((p) => p.id === page.id);

    const hasPageNameOrderChanges = pagesRef.current.some((page) => {
        const originalPageName = getOriginalPage(page)?.name;

        return page.name !== originalPageName;
    });

    const hasChanges = hasOrderChanges || hasPageNameOrderChanges;

    const { translate } = useTranslate();
    const forceUpdate = useForceUpdate();

    const handleRename = (page: PageMeta) => {
        setRenamedPage(page);
        setLocalPageName(page.name);
    };

    const onConfirmRename = () => {
        if (pageLocalName) {
            if (pageLocalName.length > MAX_INPUT_SIZE) {
                setRenamedPage(null);
                return;
            }

            const modifiedPage = pagesRef.current.find((page) => page.id === renamedPage?.id);

            if (modifiedPage) {
                modifiedPage.name = pageLocalName;
            }
        }

        setRenamedPage(null);
    };

    const handleDelete = (page: PageMeta) => {
        pagesRef.current = pagesRef.current.filter((p) => p.id !== page.id);
        pagesRef.current.forEach((p, index) => {
            p.index = index;
            p.displayOrder = index + 1;
        });
        forceUpdate();
    };

    const actionsMap = {
        onRename: handleRename,
        onDelete: handleDelete,
    };

    const getPagePreview = (page: PageMeta) => {
        return pagePreviewImages.find((p) => p.pageId === page.id)?.src || '';
    };

    const renderPageItem = (page: PageMeta) => {
        return (
            <ListDragAndDrop.Item
                thumbnail={
                    <Thumbnail imageUrl={getPagePreview(page)} variant="content">
                        {pagePreviewImages[page.index]?.loading && (
                            <Thumbnail.Overlay isLoading={pagePreviewImages[page.index]?.loading} />
                        )}
                    </Thumbnail>
                }
                rightElements={
                    page.id === renamedPage?.id ? null : (
                        <PageManageOptions actions={actionsMap} page={page} pageCount={pagesRef.current.length} />
                    )
                }
                data-testid={`pages management : list item : ${page.id}`}
                subtext={!renamedPage && `${page.dimensions?.width} × ${page.dimensions?.height}`}
            >
                {page.id === renamedPage?.id ? (
                    <Form.Group>
                        <Input
                            ref={inputRef}
                            value={pageLocalName}
                            onChange={setLocalPageName}
                            onKeyDown={onKeyEnter}
                            isInvalid={isInputLong}
                        />
                        {isInputLong && (
                            <Form.HelperText isInvalid={isInputLong}>
                                {translate('input.error.max.length')}
                            </Form.HelperText>
                        )}
                    </Form.Group>
                ) : (
                    page.name
                )}
            </ListDragAndDrop.Item>
        );
    };

    const onKeyEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();

            onConfirmRename();
        }
    };

    const onOrderChange = (reorderedItems) => {
        reorderedItems.forEach((item, index) => {
            item.displayOrder = index + 1;
            item.index = index;
        });
        pagesRef.current = reorderedItems;
        forceUpdate();
    };

    useOnClickOutside(inputRef, () => {
        if (inputRef) {
            onConfirmRename();
        }
    });

    useEffect(() => {
        if (renamedPage?.id) {
            requestAnimationFrame(() => {
                inputRef?.current?.querySelector('input')?.focus();
            });
        }
    }, [renamedPage?.id]);

    return (
        <Modal
            container={modalContainer}
            title={translate('editor.page.manage.modal.title')}
            isOpen={show}
            onClose={onClose}
            actionPrimary={
                <Button
                    variant="primary"
                    onClick={() => onConfirm(pagesRef.current)}
                    isDisabled={!hasChanges || isInputLong}
                >
                    {translate('editor.page.manage.modal.save')}
                </Button>
            }
            actionSecondary={
                <Button variant="secondary" onClick={onClose}>
                    {translate('editor.page.manage.modal.cancel')}
                </Button>
            }
        >
            {show && (
                <ListDragAndDrop items={pagesRef.current} onOrderChange={onOrderChange} renderItem={renderPageItem} />
            )}
        </Modal>
    );
};

export default PageManageModal;
