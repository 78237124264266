import { ReactElement } from 'react';
import { BaseMultiPageModel, TextElement } from '@bynder-studio/render-core';

export type TextWrapProps = {
    creativeModel: BaseMultiPageModel;
    selectedElement: TextElement;
};

export type TextWrapOption = {
    value: WRAP_MODE;
    label: ReactElement;
    icon: ReactElement;
};

export enum WRAP_MODE {
    WRAP = 'WRAP',
    FIT = 'FIT',
    AUTO_RESIZE = 'AUTO_RESIZE',
}
