import React, { useEffect, useRef, useState } from 'react';
import { Dropdown, List, Thumbnail } from '@bynder/design-system';
import { IconUserGroup, IconBuilding } from '@bynder/icons';
import { Translate } from '@bynder/localization';
import useOnClickOutside from 'packages/hooks/useOnClickOutside';
import { GroupAccessEntry, GroupAccessDropdownItem, Props } from './types';
import { Box, DropdownButton, DropdownList, DropdownListItem, ListItemCheckMark } from './GroupAccessDropdown.styled';

const DropdownListItems = (entityType) => ({
    [GroupAccessEntry.ALL_USERS]: {
        key: GroupAccessEntry.ALL_USERS,
        icon: <IconBuilding />,
        title: <Translate id="modal.sharing.ownership.group.title.all" />,
        subtitle: <Translate id="modal.sharing.ownership.group.info.all" type={entityType} />,
    },
    [GroupAccessEntry.RESTRICTED]: {
        key: GroupAccessEntry.RESTRICTED,
        icon: <IconUserGroup />,
        title: <Translate id="modal.sharing.ownership.group.title.restricted" />,
        subtitle: <Translate id="modal.sharing.ownership.group.info.restricted" type={entityType} />,
    },
});

const GroupAccessDropDown = ({ onSelect, restriction, isDisabled, entityType }: Props) => {
    const [selected, setSelected] = useState<GroupAccessDropdownItem>(DropdownListItems(entityType)[restriction]);
    const [expanded, setIsExpanded] = useState(false);

    const dropdownRef = useRef(null);

    const onItemSelect = (item, key) => {
        setSelected(item);
        onSelect(key);
        setIsExpanded(false);
    };

    useOnClickOutside(dropdownRef, () => setIsExpanded(false));

    useEffect(() => {
        setSelected(DropdownListItems(entityType)[restriction]);
    }, [restriction, entityType]);

    return (
        <Box id="group_permissions" ref={dropdownRef} isDisable={isDisabled}>
            <DropdownButton
                id="group_permissions_button"
                thumbnail={<Thumbnail icon={selected.icon} />}
                onClick={() => !isDisabled && setIsExpanded(!expanded)}
                subtext={selected.subtitle}
                isSelected={expanded}
                rightElements={!isDisabled && <Dropdown.Arrow />}
                isDisabled={isDisabled}
            >
                {selected.title}
            </DropdownButton>
            {expanded && (
                <DropdownList>
                    <List>
                        {Object.keys(DropdownListItems(entityType)).map((key) => (
                            <DropdownListItem
                                id={`group_permissions_${key}`}
                                thumbnail={<Thumbnail icon={DropdownListItems(entityType)[key].icon} />}
                                subtext={DropdownListItems(entityType)[key].subtitle}
                                onClick={() =>
                                    restriction !== key && onItemSelect(DropdownListItems(entityType)[key], key)
                                }
                                rightElements={selected.key === key && <ListItemCheckMark />}
                                isDisabled={isDisabled}
                            >
                                {DropdownListItems(entityType)[key].title}
                            </DropdownListItem>
                        ))}
                    </List>
                </DropdownList>
            )}
        </Box>
    );
};

export default GroupAccessDropDown;
