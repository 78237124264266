import styled from 'styled-components';
import { Card, token } from '@bynder/design-system';

export const TemplateCardImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90px;
`;

export const TemplateCardImage = styled(Card.Img)<{ fitting: string }>`
    object-fit: ${({ fitting }) => fitting};
`;

export const TemplateCardPlaceholder = styled(Card.IconPlaceholder)`
    & svg {
        fill: ${token.gray400a};
        width: ${token.spacing9};
        height: ${token.spacing9};
    }
`;
