import React, { type ReactNode, useMemo } from 'react';
import { Dropdown } from '@bynder/design-system';
import useAccessRights from 'packages/hooks/useAccessRights';
import generateTestId from '~/helpers/testIdHelpers';
import { Rename, isRenameAvailable } from './actions/Rename';
import { Share, isShareAvailable } from './actions/Share';
import { MoveToCategory, isMoveToCategoryAvailable } from './actions/MoveToCategory';
import { RemoveFromCategory, isRemoveFromCategoryAvailable } from './actions/RemoveFromCategory';
import { ShowPreview, isShowPreviewAvailable } from './actions/ShowPreview';
import { isEditAvailable, Edit } from './actions/Edit';
import { Delete, isDeleteAvailable } from './actions/Delete';
import { Trigger } from './Trigger';
import { Props } from './types';

const testId = generateTestId('category template context menu');

const TemplateContextMenu = ({
    template,
    menuActions,
    isCardAction,
    onContextMenuTriggerClick,
    isPreviewDisabled,
    hiddenActions = [],
}: Props) => {
    const { isEntityActionAllowed, isPlatformAllowed } = useAccessRights();

    const isAvailableParams = useMemo(
        () => ({
            isEntityActionAllowed,
            isPlatformAllowed,
            hiddenActions,
            template,
        }),
        [isEntityActionAllowed, isPlatformAllowed, hiddenActions, template],
    );

    const groups: ReactNode[][] = [[], [], [], []];

    if (isRenameAvailable(isAvailableParams)) {
        groups[0].push(<Rename template={template} menuActions={menuActions} key="rename" />);
    }

    if (isShareAvailable(isAvailableParams)) {
        groups[1].push(<Share template={template} menuActions={menuActions} key="share" />);
    }

    if (isMoveToCategoryAvailable(isAvailableParams)) {
        groups[1].push(<MoveToCategory template={template} menuActions={menuActions} key="move" />);
    }

    if (isRemoveFromCategoryAvailable(isAvailableParams)) {
        groups[1].push(<RemoveFromCategory template={template} menuActions={menuActions} key="remove" />);
    }

    if (isShowPreviewAvailable(isAvailableParams)) {
        groups[1].push(
            <ShowPreview
                template={template}
                menuActions={menuActions}
                isPreviewDisabled={isPreviewDisabled}
                key="preview"
            />,
        );
    }

    if (isEditAvailable(isAvailableParams)) {
        groups[2].push(<Edit template={template} menuActions={menuActions} key="edit" />);
    }

    if (isDeleteAvailable(isAvailableParams)) {
        groups[3].push(<Delete template={template} menuActions={menuActions} key="delete" />);
    }

    const actions = groups
        .filter(({ length }) => length > 0)
        .reduce<ReactNode[]>((acc, curr, i) => {
            if (i !== 0) {
                acc.push(<Dropdown.Divider key={`divider-${i}`} />);
            }

            acc.push(...curr);

            return acc;
        }, []);

    return (
        <Dropdown
            trigger={(props) => (
                <Trigger {...props} isCardAction={isCardAction} onContextMenuTriggerClick={onContextMenuTriggerClick} />
            )}
            position={isCardAction ? 'bottom-left' : 'bottom-right'}
            {...testId}
        >
            {actions}
        </Dropdown>
    );
};

export default TemplateContextMenu;
