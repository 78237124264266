import React, { useMemo } from 'react';
import { Card, Buffer } from '@bynder/design-system';
import { IconFolderOpen } from '@bynder/icons';
import { Category } from 'packages/store/categories/types';
import { StyledCardImage } from 'packages/pages/components/card/card.styled';

type CategoryThumbnailProps = {
    category: Category;
};

const PROCESSED_STATUSES = ['PROCESSED', 'UNKNOWN', 'NOT_FOUND', 'FAILED'];

const CategoryThumbnail = ({ category }: CategoryThumbnailProps) => {
    const isProcessingDone =
        (category.previewItems?.length &&
            category.previewItems.every((preview) => PROCESSED_STATUSES.includes(preview.thumbnailStatus))) ||
        !category.previewItems?.length;

    const previewItems = useMemo(() => {
        if (category.previewItems && category.previewItems.length) {
            return [...category.previewItems].reverse();
        }

        return [];
    }, [category.previewItems]);

    const renderCover = () => {
        if (category.coverImageUrl) {
            if (category.coverBgColor) {
                return (
                    <Card.Images>
                        <StyledCardImage
                            key={category.id}
                            src={category.coverImageUrl}
                            backgroundColor={`#${category.coverBgColor}`}
                        />
                    </Card.Images>
                );
            }

            return (
                <Card.Images>
                    <Card.Img key={category.id} src={category.coverImageUrl} />
                </Card.Images>
            );
        }

        if (category.coverBgColor) {
            return (
                <Card.IconPlaceholder backgroundColor={`#${category.coverBgColor}`}>
                    <></>
                </Card.IconPlaceholder>
            );
        }

        if (previewItems.length && isProcessingDone) {
            return (
                <Card.Images>
                    {previewItems.map(
                        (item) => item.previewUrl && <Card.Img key={item.creativeId} src={item.previewUrl} />,
                    )}
                </Card.Images>
            );
        }

        return (
            <Card.IconPlaceholder>
                <IconFolderOpen />
            </Card.IconPlaceholder>
        );
    };

    return (
        <Card.Frame progressBar={previewItems.length && !isProcessingDone && <Buffer noCornerRadius />}>
            {renderCover()}
        </Card.Frame>
    );
};

export default CategoryThumbnail;
