import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Flex, Button, EmptyState, token } from '@bynder/design-system';
import { IconFolderOpen } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { CompactView, Login, Modal } from '@bynder/compact-view';
import { getPortalDomain } from 'packages/store/platform/platform.selectors';
import useDirectAccessToken from 'packages/hooks/useDirectAccessToken';
import AssetSourceService from 'packages/services/assetSource/AssetSourceService';
import { isErr } from 'packages/services/graphql/utils';
import { getDesignSystemToken } from 'packages/helpers/helpers';

const defaultAssetFieldSelection = `
  name
  type
  fileSize
  originalUrl
  extensions
  databaseId
  isPublic
  ... on Video {
    previewUrls
  }
`;

const useCompactView = (compactViewProps, modal = true) => {
    const { getDirectAccessToken } = useDirectAccessToken();
    const { translate } = useTranslate();
    const portalDomain = useSelector(getPortalDomain);
    const [directAccessToken, setDirectAccessToken] = useState('');
    const [isCompactViewOpen, toggleCompactView] = useState(false);
    const [collectionData, setCollectionData] = useState({
        isEmpty: true,
        isLoading: true,
        isChecked: false,
        error: false,
    });

    const portal = useMemo(() => ({ url: portalDomain || 'https://weadapt.bynder.com' }), [portalDomain]);

    const authentication = useMemo(
        () => ({
            getAccessToken: () => directAccessToken,
            hideLogout: true,
        }),
        [directAccessToken],
    );

    const checkCollectionExisting = async () => {
        let error = false;
        let isEmpty = true;

        try {
            const id = compactViewProps.assetFilter.collectionId;

            if (!id) {
                throw new Error('Collection ID is required');
            }

            const res = await AssetSourceService.fetchCollection(portalDomain, getDirectAccessToken, id);

            if (isErr(res)) {
                throw new Error(res.error);
            }

            isEmpty = res.value.node.assetCount === 0;
        } catch (e) {
            error = true;
        } finally {
            setCollectionData({
                isLoading: false,
                isChecked: true,
                isEmpty,
                error,
            });
        }
    };

    const onSuccessRaw = compactViewProps.onSuccess;

    const onSuccess = useCallback(
        (...params: any[]) => {
            if (modal) {
                toggleCompactView(false);
            }

            onSuccessRaw(...params);
        },
        [onSuccessRaw, modal, toggleCompactView],
    );

    const selectedAssets = useMemo(
        () => (collectionData.isEmpty && compactViewProps.collectionId ? [] : compactViewProps.selectedAssets),
        [collectionData.isEmpty, compactViewProps.collectionId, compactViewProps.selectedAssets],
    );

    const loadCollectionData = () => {
        getDirectAccessToken().then((token) => {
            setDirectAccessToken(token);

            if (compactViewProps?.assetFilter?.collectionId) {
                checkCollectionExisting(token);
            } else {
                setCollectionData((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isChecked: true,
                }));
            }
        });
    };

    useEffect(() => {
        loadCollectionData();
    }, []);

    const renderUnavailableCollection = () => (
        <Flex justifyContent="center" alignItems="center">
            <EmptyState
                icon={<IconFolderOpen />}
                title={translate('modal.assetsPicker.collections.not_found.title')}
                text={translate('modal.assetsPicker.collections.not_found.text')}
            />
        </Flex>
    );

    const renderLoading = () => (
        <Flex justifyContent="center" alignItems="center">
            <Button title="" variant="clean" isLoading isFullWidth />
        </Flex>
    );

    const renderCompactView = () => {
        const { error, isChecked, isLoading } = collectionData;

        const Wrapper = modal ? Modal : React.Fragment;
        const wrapperProps = modal
            ? {
                  isOpen: isCompactViewOpen,
                  onClose: () => toggleCompactView(false),
              }
            : {};

        return (
            <>
                {error && renderUnavailableCollection()}
                {isLoading && renderLoading()}
                {isChecked && !error && !isLoading && (
                    <Wrapper {...wrapperProps}>
                        <Login portal={portal} authentication={authentication}>
                            <CompactView
                                {...compactViewProps}
                                __shouldAddOriginal__
                                noCache
                                onSuccess={onSuccess}
                                selectedAssets={selectedAssets}
                                assetFieldSelection={compactViewProps.assetFieldSelection || defaultAssetFieldSelection}
                                theme={{
                                    colorPrimary: getDesignSystemToken(token.brandPrimary500) || '#00aaff',
                                    colorButtonPrimary: getDesignSystemToken(token.brandPrimary500) || '#00aaff',
                                    colorButtonPrimaryActive: getDesignSystemToken(token.brandPrimary700) || '#0087e0',
                                    colorButtonPrimaryHover: getDesignSystemToken(token.brandPrimary600) || '#0095eb',
                                }}
                            />
                        </Login>
                    </Wrapper>
                )}
            </>
        );
    };

    return {
        renderCompactView,
        toggleCompactView,
    };
};

export default useCompactView;
