import React from 'react';
import { Column, ColumnAction, ColumnSelect, HeaderContainer } from './List.styled';
import { ExpandCollapseAll } from './ExpandCollapseAll';
import { SelectAllCheckbox } from './SelectAllCheckbox';
import useExport from '../hooks/useExport';

export const ListHeader = ({ rowRefs }) => {
    const { groupBy } = useExport();

    return (
        <HeaderContainer gap="4" alignItems="center">
            <ColumnSelect>
                <SelectAllCheckbox />
            </ColumnSelect>
            {groupBy === 'variation' && <Column>Variation</Column>}
            {groupBy === 'variation' && <Column>Page</Column>}
            {groupBy === 'page' && <Column>Page</Column>}
            {groupBy === 'page' && <Column>Variation</Column>}
            <ColumnAction>
                <ExpandCollapseAll rowRefs={rowRefs} />
            </ColumnAction>
        </HeaderContainer>
    );
};
