import React, { useCallback } from 'react';
import { ActionCheckbox } from './List.styled';

import useExport from '../hooks/useExport';

export const SelectItemCheckbox = ({ item, ...others }) => {
    const { selectedIds, toggleSelect } = useExport();
    const id = item.variationId;
    const isSelected = selectedIds[id];

    const handleChange = useCallback(() => {
        toggleSelect(id);
    }, [id, toggleSelect]);

    return <ActionCheckbox isChecked={isSelected} onChange={handleChange} {...others} />;
};
