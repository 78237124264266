import React from 'react';
import { Button, List, Thumbnail, token } from '@bynder/design-system';
import { IconPermission, IconUserGroup, IconUserRemoved } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { EntityGrantedPermissions, IdentityRole, IdentityType, EntityType } from 'packages/store/sharing/types';
import { Entity } from 'packages/hooks/useAccessRights/types';
import { OwnershipWithSeparator } from './shareModal.styled';
import ShareRuleDropdown from './components/Dropdown';
import { sortPermissions } from './utils';

type Translate = ReturnType<typeof useTranslate>['translate'];

export const renderUserPermission = (
    item: EntityGrantedPermissions,
    permissions: EntityGrantedPermissions[],
    isProjectPermissions: boolean,
    translate: Translate,
    dropdownProps?: any,
) => {
    const isOwner = item.grantedPermissions?.some((entry) => entry.permission === IdentityRole.OWNER) || false;

    const renderDropDown = () => {
        if (!dropdownProps) {
            return;
        }

        if (isProjectPermissions) {
            return (
                <Button isDisabled variant="clean">
                    {translate('modal.design.share.contributor')}
                </Button>
            );
        }

        if (item.grantedPermissions.some((entry) => entry.permission === IdentityRole.OWNER && entry.granted)) {
            return (
                <Button isDisabled variant="clean">
                    {translate('modal.design.share.owner')}
                </Button>
            );
        }

        return (
            <ShareRuleDropdown
                id={item.securityIdentity.bynderUserId}
                type={item.securityIdentity.securityIdentityType}
                entityIndex={permissions.indexOf(item)}
                permissions={permissions}
                {...dropdownProps}
            />
        );
    };

    const isDeletedUser = item.securityIdentity.email === 'user@removed.com';

    const itemSubtext = () => {
        if (!dropdownProps) {
            const ownerPrefix = isProjectPermissions
                ? 'modal.sharing.ownership.category-owner'
                : 'modal.sharing.ownership.owner';

            return (
                <span>
                    {translate(isOwner ? ownerPrefix : 'modal.sharing.ownership.can-access')}
                    <OwnershipWithSeparator />
                    {!isDeletedUser ? item.securityIdentity.email : ''}
                </span>
            );
        }

        if (
            isProjectPermissions &&
            item.projectGrantedPermissions.some((entry) => entry.permission === IdentityRole.OWNER && entry.granted)
        ) {
            return (
                <span>
                    {!isDeletedUser ? item.securityIdentity.email : ''}
                    <OwnershipWithSeparator>
                        {translate('modal.sharing.ownership.project.inherit.owner')}
                    </OwnershipWithSeparator>
                </span>
            );
        }

        return <>{!isDeletedUser ? item.securityIdentity.email : ''}</>;
    };

    const userIcon = () => {
        if (isDeletedUser) {
            return <IconUserRemoved />;
        }

        if (item.securityIdentity.fullName && item.securityIdentity.fullName[0]) {
            return item.securityIdentity.fullName[0].toUpperCase();
        }

        return '';
    };

    return (
        <List.Item
            id={`user_role_${permissions.indexOf(item)}`}
            thumbnail={<Thumbnail size="m" shape="circle" backgroundColor={token.gray100} icon={userIcon()} />}
            subtext={itemSubtext()}
            rightElements={renderDropDown()}
            key={!isDeletedUser ? item.securityIdentity.email : ''}
        >
            {!isDeletedUser ? item.securityIdentity.fullName : translate('modal.design.share.removed')}
        </List.Item>
    );
};

export const renderAllUsersPermission = (item, translate: Translate, dropdownProps, isPreview?: boolean) => {
    const getRightElements = () => {
        if (!dropdownProps) {
            return;
        }

        return item.grantedPermissions.some((entry) => entry.permission === IdentityRole.OWNER && entry.granted) ? (
            <Button isDisabled variant="clean">
                {translate('modal.design.share.owner')}
            </Button>
        ) : (
            <Button isDisabled variant="clean">
                {dropdownProps.creativeType === Entity.TEMPLATE || dropdownProps.creativeType === Entity.CATEGORY
                    ? translate('modal.design.share.can-access')
                    : translate('modal.design.share.contributor')}
            </Button>
        );
    };

    return (
        <List.Item
            id="all_users_role"
            thumbnail={<Thumbnail size="m" shape="circle" backgroundColor={token.white} icon={<IconUserGroup />} />}
            subtext={isPreview && translate('modal.design.share.can-access')}
            rightElements={getRightElements()}
            key="ALL_USERS"
        >
            {translate('modal.sharing.ownership.group.title.item.all')}
        </List.Item>
    );
};

export const renderGroupPermission = (
    item: EntityGrantedPermissions,
    permissions: EntityGrantedPermissions[],
    isProjectPermissions: boolean,
    translate: Translate,
    dropdownProps: any = {},
) => {
    const renderDropdown = () => {
        if (!dropdownProps) {
            return;
        }

        if (isProjectPermissions) {
            return (
                <Button isDisabled variant="clean">
                    {translate('modal.design.share.contributor')}
                </Button>
            );
        }

        return item.grantedPermissions.some((entry) => entry.permission === IdentityRole.OWNER && entry.granted) ? (
            <Button isDisabled variant="clean">
                {translate('modal.design.share.owner')}
            </Button>
        ) : (
            <ShareRuleDropdown
                id={item.securityIdentity.groupId}
                type={item.securityIdentity.securityIdentityType}
                entityIndex={permissions.indexOf(item)}
                permissions={permissions}
                {...dropdownProps}
            />
        );
    };

    return (
        <List.Item
            id={`group_role_${permissions.indexOf(item)}`}
            thumbnail={<Thumbnail size="m" shape="circle" backgroundColor={token.gray100} icon={<IconUserGroup />} />}
            subtext={translate('modal.design.share.group')}
            rightElements={renderDropdown()}
            key={item.securityIdentity.groupId}
        >
            {item.securityIdentity.groupName}
        </List.Item>
    );
};

export const renderProfilePermission = (
    item: EntityGrantedPermissions,
    permissions: EntityGrantedPermissions[],
    isProjectPermissions: boolean,
    translate: Translate,
    dropdownProps: any = {},
) => {
    const renderDropdown = () => {
        if (!dropdownProps) {
            return;
        }

        if (isProjectPermissions) {
            return (
                <Button isDisabled variant="clean">
                    {translate('modal.design.share.contributor')}
                </Button>
            );
        }

        return item.grantedPermissions.some((entry) => entry.permission === IdentityRole.OWNER && entry.granted) ? (
            <Button isDisabled variant="clean">
                {translate('modal.design.share.owner')}
            </Button>
        ) : (
            <ShareRuleDropdown
                id={item.securityIdentity.profileId}
                type={item.securityIdentity.securityIdentityType}
                entityIndex={permissions.indexOf(item)}
                permissions={permissions}
                {...dropdownProps}
            />
        );
    };

    return (
        <List.Item
            id={`profile_role_${permissions.indexOf(item)}`}
            thumbnail={<Thumbnail size="m" shape="circle" backgroundColor={token.gray100} icon={<IconPermission />} />}
            subtext={translate('modal.design.share.profile')}
            rightElements={renderDropdown()}
            key={item.securityIdentity.profileId}
        >
            {item.securityIdentity.profileName}
        </List.Item>
    );
};

export const renderSharePermissions = (
    permissions: EntityGrantedPermissions[],
    translate: Translate,
    entityType: EntityType,
    dropdownProps?: any,
) => {
    const sortedItems = sortPermissions(permissions);

    return sortedItems.map((item) => {
        if (item.securityIdentity.securityIdentityType === IdentityType.USER) {
            return renderUserPermission(item, permissions, false, translate, dropdownProps);
        }

        if (
            item.securityIdentity.securityIdentityType === IdentityType.ALL_USERS &&
            (entityType === EntityType.COLLECTION ||
                entityType === EntityType.CATEGORY ||
                entityType === EntityType.TEMPLATE)
        ) {
            return renderAllUsersPermission(item, translate, dropdownProps);
        }

        if (item.securityIdentity.securityIdentityType === IdentityType.GROUP) {
            return renderGroupPermission(item, permissions, false, translate, dropdownProps);
        }

        if (item.securityIdentity.securityIdentityType === IdentityType.PROFILE) {
            return renderProfilePermission(item, permissions, false, translate, dropdownProps);
        }

        return null;
    });
};
