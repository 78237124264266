import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ColorParams } from '@bynder-studio/render-core';
import useTextSelection from 'packages/hooks/useTextSelection';
import { colorPalettes } from 'packages/store/brandColors/brandColors.selectors';
import { getExistingBrandColor } from 'packages/pages/editor/RightSideMenu/FormComponents/EditorColor/utils';
import { hexToRgb, rgbToHex } from 'packages/pages/editor/RightSideMenu/FormComponents/ColorPicker/utils';
import { BrandColor } from 'packages/store/brandColors/types';
import { BrandColorOnElement } from 'packages/pages/editor/RightSideMenu/FormComponents/EditorColor';
import { extractUnique } from 'packages/pages/editor/RightSideMenu/Shots/Text/Fonts/utils';
import { collectColorsFromElement } from 'packages/pages/editor/RightSideMenu/Shots/Text/TextStyles/utils';
import { colorToCSS, rgbaToObject } from '~/common/editor/editorHelper';

const useFontColorManipulator = ({ creativeModel, selectedElement }) => {
    const id = selectedElement.id;
    const { color: colors, getRawStyles } = useTextSelection();
    const { palettes } = useSelector(colorPalettes);
    const elementColors = collectColorsFromElement(selectedElement, palettes);

    const onChange = (property, newFontColor) => {
        const param = {
            updateTextSettingForSelection: {
                settings: {
                    color: newFontColor,
                },
            },
        };

        creativeModel.updateElement(id, param);
    };

    const setColor = (newValue) => {
        if (colors.length > 1) {
            const styles = getRawStyles().color.map((c) => ({
                color: {
                    ...rgbaToObject(newValue),
                    brandColorId: null,
                    opacity: c.opacity,
                },
            }));
            const param = { updateTextSettingsByEachRun: { styles } };
            creativeModel.updateElement(id, param);
        } else {
            onChange('color', { brandColorId: null, ...rgbaToObject(newValue) });
        }
    };

    const setOpacity = (newValue) => {
        if (colors.length > 1) {
            const { opacity } = rgbaToObject(newValue);
            const styles = getRawStyles().color.map((c) => ({ color: { ...c, opacity } }));
            const param = { updateTextSettingsByEachRun: { styles } };

            creativeModel.updateElement(id, param);
        } else {
            setColor(newValue);
        }
    };

    const [brandColors, customColors]: [BrandColorOnElement[] | [], ColorParams[] | []] = useMemo(() => {
        const brandColorObj = {};
        const customColorObj = {};

        (colors as ColorParams[]).forEach((c) => {
            const brandColor = !c.brandColorId ? null : getExistingBrandColor(palettes, c.brandColorId);

            if (brandColor && c.brandColorId) {
                brandColorObj[c.brandColorId] = brandColor;
            } else {
                const hexCode = rgbToHex(colorToCSS(c));
                customColorObj[hexCode] = c;
            }
        });

        return [Object.values(brandColorObj), Object.values(customColorObj)];
    }, [colors, palettes]);

    const setBrandColor = (color: BrandColor) => {
        const { hexCode, alpha, id } = color;
        onChange('color', { brandColorId: id, ...rgbaToObject(hexToRgb(hexCode, alpha)) });
    };

    const onColorDetach = () => {
        if (colors.length === 1) {
            const { hexCode, alpha } = brandColors[0];
            onChange('color', { brandColorId: null, ...rgbaToObject(hexToRgb(hexCode, alpha)) });
        } else {
            const styles = (getRawStyles().color as ColorParams[]).map((c) => ({
                color: { brandColorId: null, ...rgbaToObject(colorToCSS(c)) },
            }));

            const param = { updateTextSettingsByEachRun: { styles } };
            creativeModel.updateElement(id, param);
        }
    };

    const onAppliedClick = (color: BrandColor | ColorParams) =>
        'hexCode' in color ? setBrandColor(color as BrandColor) : setColor(colorToCSS(color));

    const isMixed = useMemo(
        () =>
            extractUnique(
                colors.map((c) => {
                    const { opacity, brandColorId, ...others } = c;

                    return others;
                }),
            ).length > 1,
        [colors],
    );

    const isAlphaMixed = useMemo(() => [...new Set(colors.map((c) => c.opacity))].length > 1, [colors]);

    return {
        hasBrandColors: !!brandColors.length,
        isMixed,
        isAlphaMixed,
        setColor,
        setOpacity,
        palettes,
        brandColors,
        customColors,
        onAppliedClick,
        setBrandColor,
        onColorDetach,
        colors: colors as ColorParams[],
        elementColors,
    };
};

export default useFontColorManipulator;
