import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const PlayheadStyled = styled.div`
    z-index: 11;
    position: absolute;
    top: 0;
    left: 0;
    grid-area: 2/2/2/3;
    display: flex;
    width: 0;
    height: 100%;
    flex-direction: column;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

    .Playhead-dragger {
        width: 17px;
        height: 11px;
        cursor: ew-resize;
        position: absolute;
        top: 0;
    }

    .Playhead-dragger .Playhead-dragger-punch {
        position: absolute;
        top: -4px;
        z-index: 9999;
        width: 17px;
        height: 11px;
        display: block;
        background-color: ${token.gray1000};
        clip-path: polygon(0 0, 100% 0, 50% 100%);
    }

    .Playhead-line {
        position: relative;
        z-index: 500;
        width: 1px;
        flex: 1;
        background-color: ${token.gray1000};
        pointer-events: none;
    }

    &.tooltipied .Playhead-dragger .tooltiptext {
        visibility: hidden;
        background-color: ${token.white};
        color: ${token.colorText};
        text-align: center;
        border: 1px solid ${token.gray200};
        border-radius: 6px;
        font-size: 11px;
        padding: 2px 0;
        width: 70px;
        position: absolute;
        top: 0;
        margin: -26px 0 0 -27px;
    }

    &.tooltipied:hover .tooltiptext {
        visibility: visible;
    }

    &.tooltipied:not(:hover) .tooltiptext {
        visibility: hidden;
    }

    &.tooltipied.visible {
        visibility: visible;
    }
`;
