import React, { Fragment, useRef } from 'react';
import { isValidProperty } from '../../../../metadata.bloc';
import TransitionInOutControl from '../../../transitions/TransitionInOutControl/TransitionInOutControl';
import AnimationControl from '../../../transitions/AnimationControl/AnimationControl';
import useEditor from '../../../../../../hooks/useEditor';
import { elementHasAnimation, elementHasDurationalAnimation, elementHasTransitions } from '../../track-renderer';
import { ElementTimelineTransitionsTrack } from './ElementTimelineTransition.styled';

type Props = {
    trackContainerRef: any;
    element: any;
    elementStyle: any;
    trackDuration: number;
    color: any;
    backgroundColor: any;
};

const calculateSubTrackTop = (index, elementStyle) => {
    let top = parseInt(elementStyle.top);

    if (index === 0) {
        top += 41;
    } else if (index === 1) {
        top += 85;
    }

    return top;
};

const ElementTimelineTransitions = ({
    element,
    elementStyle,
    trackDuration,
    trackContainerRef,
    color,
    backgroundColor,
}: Props) => {
    const transitionInOutContainerRef = useRef();
    const animationContainerRef = useRef();
    const { creativeModel } = useEditor();

    const onTransitionInOutChanged = ({ animationInDuration, animationOutDuration }) => {
        const dataToUpdate = {};

        if (animationInDuration !== null) {
            dataToUpdate.animationIn = { ...element.animationIn.config, duration: animationInDuration };
        }

        if (animationOutDuration !== null) {
            dataToUpdate.animationOut = { ...element.animationOut.config, duration: animationOutDuration };
        }

        creativeModel.updateElement(element.id, dataToUpdate);
    };

    const onAnimationChanged = ({ startFrame, duration }) => {
        creativeModel.updateElement(element.id, {
            animations: [
                {
                    ...element.animations[0].config,
                    startFrame: element.startFrame + startFrame,
                    duration,
                },
            ],
        });
    };

    const hasTransitions = elementHasTransitions(element);
    const hasAnimation = elementHasAnimation(element);
    const hasDurationalAnimation = elementHasDurationalAnimation(element);
    const { locked } = element;

    return (
        <Fragment>
            {hasTransitions && (
                <ElementTimelineTransitionsTrack
                    ref={transitionInOutContainerRef}
                    style={{ ...elementStyle, top: calculateSubTrackTop(0, elementStyle) }}
                >
                    <TransitionInOutControl
                        onChangeEnd={onTransitionInOutChanged}
                        trackDuration={trackDuration}
                        elementDuration={element.duration}
                        animationIn={element.animationIn}
                        animationOut={element.animationOut}
                        containerRef={transitionInOutContainerRef}
                        trackContainerRef={trackContainerRef}
                        color={color}
                        backgroundColor={backgroundColor}
                        animationInIsValid={isValidProperty(element, 'animationIn')}
                        animationOutIsValid={isValidProperty(element, 'animationOut')}
                        disabled={locked}
                    />
                </ElementTimelineTransitionsTrack>
            )}
            {hasAnimation && (
                <ElementTimelineTransitionsTrack
                    ref={animationContainerRef}
                    style={{ ...elementStyle, top: calculateSubTrackTop(hasTransitions ? 1 : 0, elementStyle) }}
                >
                    <AnimationControl
                        onChangeEnd={onAnimationChanged}
                        trackDuration={trackDuration}
                        elementStartFrame={element.startFrame}
                        elementDuration={element.duration}
                        animations={element.animations[0]}
                        containerRef={animationContainerRef}
                        trackContainerRef={trackContainerRef}
                        color={color}
                        backgroundColor={backgroundColor}
                        animationsIsValid={isValidProperty(element, 'animations')}
                        disabled={!hasDurationalAnimation || locked}
                    />
                </ElementTimelineTransitionsTrack>
            )}
        </Fragment>
    );
};

export default ElementTimelineTransitions;
