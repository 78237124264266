import React, { FC, useCallback } from 'react';
import {
    type BaseMultiPageModel,
    type TextElement,
    TextTransform as TextTransformEnum,
} from '@bynder-studio/render-core';
import { Tabs, Form } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconMin, IconUpperCase, IconLowerCase, IconTitleCase } from '@bynder/icons';
import useTextSelection from 'packages/hooks/useTextSelection';
import MaybeTooltip from 'packages/common/MaybeTooltip';
import { TransformButton } from './Component.styled';
import generateTestId from '~/helpers/testIdHelpers';

type TextTransformProps = {
    creativeModel: BaseMultiPageModel;
    selectedElement: TextElement;
    disabled?: boolean;
    withLabel?: boolean;
};

const TRANSFORM_OPTIONS = [
    { name: TextTransformEnum.NONE, icon: <IconMin /> },
    { name: TextTransformEnum.UPPERCASE, icon: <IconUpperCase /> },
    { name: TextTransformEnum.LOWERCASE, icon: <IconLowerCase /> },
    { name: TextTransformEnum.CAPITALIZE, icon: <IconTitleCase /> },
];

const testId = generateTestId('shots_text_transform');

export const TextTransform: FC<TextTransformProps> = ({ selectedElement, disabled, creativeModel, withLabel }) => {
    const { translate } = useTranslate();
    const { id, locked } = selectedElement;
    const { textTransformData } = useTextSelection();
    const [textTransform, isMixed] = textTransformData;

    const handleChange = useCallback(
        (value: TextTransformEnum) => {
            const param = {
                updateTextSettingForSelection: {
                    settings: {
                        textTransform: value,
                    },
                },
            };

            creativeModel.updateElement(id, param);
        },
        [creativeModel, id],
    );

    return (
        <Form.Group>
            {withLabel && <Form.Label>{translate('editor.sidebar.shots.text.text_transform.title')}</Form.Label>}
            <Tabs variant="clean" {...testId}>
                {TRANSFORM_OPTIONS.map((option) => (
                    <MaybeTooltip
                        key={option.name}
                        content={translate(
                            `editor.sidebar.shots.text.text_transform.${option.name.toLowerCase()}.label`,
                        )}
                        hasTooltip={!(disabled || locked)}
                    >
                        <TransformButton
                            title=""
                            variant="clean"
                            icon={option.icon}
                            isPressed={!isMixed && textTransform === option.name}
                            isDisabled={disabled || locked}
                            onClick={() => {
                                handleChange(option.name);
                            }}
                            {...generateTestId(
                                `shots_text_transform_${option.name.toLowerCase()}${
                                    !isMixed && textTransform === option.name ? '_selected' : ''
                                }`,
                            )}
                        />
                    </MaybeTooltip>
                ))}
            </Tabs>
        </Form.Group>
    );
};
