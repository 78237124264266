import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Select, Divider, CheckboxGroup, Checkbox } from '@bynder/design-system';
import { IconTextFit, IconWrapText } from '@bynder/icons';
import { Translate, useTranslate } from '@bynder/localization';
import MinFontScale from 'packages/pages/editor/RightSideMenu/Shots/Text/TextScale/MinFontScale';
import generateTestId from '~/helpers/testIdHelpers';
import {
    TextWrapOption,
    TextWrapProps,
    WRAP_MODE,
} from 'packages/pages/editor/RightSideMenu/Shots/Text/TextWrap/types';
import { GroupTop, LimitWrapper } from './index.styled';

const options: TextWrapOption[] = [
    {
        value: WRAP_MODE.WRAP,
        label: <Translate id="editor.sidebar.shots.text.text.wrap.wrap" />,
        icon: <IconWrapText />,
    },
    {
        value: WRAP_MODE.AUTO_RESIZE,
        label: <Translate id="editor.sidebar.shots.text.text.wrap.auto_resize" />,
        icon: <IconTextFit />,
    },
    { value: WRAP_MODE.FIT, label: <Translate id="editor.sidebar.shots.text.text.wrap.fit" />, icon: <IconTextFit /> },
];

const getSelectedItem = (value) => options.find((i) => i.value === value) || options[0];

const TextWrap = ({ creativeModel, selectedElement }: TextWrapProps) => {
    const { translate } = useTranslate();
    const { id, locked, textControl } = selectedElement;
    const [value, setValue] = useState<WRAP_MODE>(textControl as WRAP_MODE);

    const handleOnChange = useCallback(
        (newValue: string) => {
            creativeModel.updateElement(id, { textControl: newValue });
        },
        [creativeModel, id],
    );

    const [mainOptions, deprecatedOption] = useMemo(() => [options.slice(0, 2), options[2]], []);

    useEffect(() => {
        setValue(textControl as WRAP_MODE);
    }, [textControl]);

    return (
        <>
            <Form.Group>
                <Form.Label id="text_wrap">{translate('editor.sidebar.shots.text.text.wrap.label')}</Form.Label>
                <GroupTop>
                    <Select
                        aria-labelledby="text_wrap"
                        value={value}
                        onChange={handleOnChange}
                        label={getSelectedItem(value).label}
                        icon={getSelectedItem(value).icon}
                        isDisabled={locked}
                        {...generateTestId('shots_text_placement')}
                    >
                        <>
                            {mainOptions.map((item) => (
                                <Select.Item value={item.value} key={item.value} icon={item.icon}>
                                    {item.label}
                                </Select.Item>
                            ))}
                            {value === WRAP_MODE.FIT && (
                                <>
                                    <Divider />
                                    <Select.Item
                                        key={deprecatedOption.value}
                                        value={deprecatedOption.value}
                                        icon={deprecatedOption.icon}
                                        isDisabled
                                    >
                                        {deprecatedOption.label}
                                    </Select.Item>
                                </>
                            )}
                        </>
                    </Select>
                </GroupTop>
                <Form.HelperText>
                    {value === WRAP_MODE.WRAP && translate('editor.sidebar.shots.text.text.wrap.wrap.help')}
                    {value === WRAP_MODE.FIT && translate('editor.sidebar.shots.text.text.wrap.fit.help')}
                    {value === WRAP_MODE.AUTO_RESIZE &&
                        translate('editor.sidebar.shots.text.text.wrap.auto_resize.help')}
                </Form.HelperText>
            </Form.Group>
            {value === WRAP_MODE.AUTO_RESIZE && (
                <MinFontScale creativeModel={creativeModel} selectedElement={selectedElement} />
            )}
            <LimitWrapper>
                <CheckboxGroup>
                    <Checkbox
                        onChange={() => {
                            creativeModel.updateElement(id, {
                                limitTextToBounds: !selectedElement.limitTextToBounds,
                            });
                        }}
                        isChecked={selectedElement.limitTextToBounds}
                        {...generateTestId('shots_text_limit')}
                    >
                        {translate('editor.sidebar.shots.text.text.limit.label')}
                    </Checkbox>
                </CheckboxGroup>
            </LimitWrapper>
        </>
    );
};

export default TextWrap;
