import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const ZoomHandle = styled.div`
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: ${token.white};
    transform: translateY(-6px);
    transition: 0.2s ease-out;
    cursor: ew-resize;
    box-shadow: ${token.elevationStatic}, ${token.elevation2};
    border: 1px solid ${token.colorShadow};

    &.--scalable:hover {
        transform: scale(1.25) translateY(-6px);
    }
`;

export const ZoomControlBox = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: ${token.brandPrimary500};
    justify-content: space-between;
    cursor: grab;
`;

export const ZoomContainer = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    align-items: center;
    margin: 0 10px;
`;
