import React, { useState } from 'react';
import CreateStyleModal from './CreateStyleModal';

const useCreateStyleModal = ({ createTextStyle }) => {
    const [isCreateStyleModalOpen, setCreateStyleModalOpen] = useState(false);

    const onClose = () => {
        setCreateStyleModalOpen(false);
    };

    const renderCreateStyleModal = () => (
        <CreateStyleModal isOpen={isCreateStyleModalOpen} onClose={onClose} createTextStyle={createTextStyle} />
    );

    return {
        setCreateStyleModalOpen,
        renderCreateStyleModal,
    };
};

export default useCreateStyleModal;
