type Resolution = {
    width: number;
    height: number;
};

export const MAX_RESOLUTION = 16384;
export const MAX_FILE_SIZE = 2 ** 31; // 2GB;

const getImageResolution = async (file: File) => {
    const element = document.createElement('img');
    const url = URL.createObjectURL(file);

    return new Promise<Resolution | null>((resolve) => {
        element.onload = () => {
            resolve({ width: element.naturalWidth, height: element.naturalHeight });
            URL.revokeObjectURL(url);
        };

        element.onerror = () => {
            resolve(null);
        };

        element.src = url;
    });
};

const getVideoResolution = async (file: File) => {
    const element = document.createElement('video');
    const url = URL.createObjectURL(file);

    if (element.canPlayType(file.type) === '') {
        return null;
    }

    return new Promise<Resolution | null>((resolve) => {
        element.onloadedmetadata = () => {
            resolve({ width: element.videoWidth, height: element.videoHeight });
            URL.revokeObjectURL(url);
        };

        element.onerror = () => {
            resolve(null);
        };

        element.src = url;
    });
};

const getMediaResolution = async (file: File) => {
    const fileType = file.type.split('/')[0] as 'image' | 'video';

    if (!['image', 'video'].includes(fileType)) {
        return null;
    }

    return fileType === 'image' ? getImageResolution(file) : getVideoResolution(file);
};

export const isResolutionValid = ({ width, height }: Resolution) => width <= MAX_RESOLUTION && height <= MAX_RESOLUTION;
export const isFileSizeValid = (fileSize: number) => fileSize <= MAX_FILE_SIZE;
export const isMediaResolutionValid = async (file: File) => {
    const resolution = await getMediaResolution(file);

    return resolution ? isResolutionValid(resolution) : true;
};
