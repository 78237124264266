import styled from 'styled-components';
import { Form, token } from '@bynder/design-system';

export const SidebarForm = styled(Form)`
    & > :first-child {
        border-top: none;
    }
`;

export const InspectorHeader = styled.div`
    padding: ${token.spacing5} ${token.spacing6};
    height: 72px;
`;

export const InspectorContent = styled.div<{ withTabs?: boolean }>`
    height: ${({ withTabs }) => (withTabs ? 'calc(100% - 72px)' : '100%')}; // 72px is a height of the tabs
    overflow-y: auto;
`;

export const InspectorFooter = styled.div``;

export const Section = styled.div`
    padding: 0 ${token.spacing6};
`;

export const StaticSectionContent = styled.div`
    padding: ${token.spacing3} 0;
`;
export const FormSectionContent = styled(Form)`
    padding: ${token.spacing3} 0;
`;

export const SectionContentTopPadding = styled(Section)`
    padding-top: ${token.spacing3};
`;

export const SectionContentBottomPadding = styled(Section)`
    padding-bottom: ${token.spacing3};
`;
