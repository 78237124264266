import React from 'react';
import ContentLoader from 'react-content-loader';

export default function CreativeEditorContentLoader(props) {
    return (
        <ContentLoader
            viewBox="0 0 2000 1000"
            width="100%"
            height="100%"
            speed={2}
            backgroundColor="#ccc"
            foregroundColor="#aaa"
        >
            <rect x="15%" y="20" rx="6" ry="6" width="40%" height="55%" />

            <rect x="20" y="70%" rx="0" ry="0" width="70%" height="20" />
            <rect x="20" y="75%" rx="0" ry="0" width="60%" height="20" />
            <rect x="20" y="80%" rx="0" ry="0" width="54%" height="20" />
            <rect x="20" y="85%" rx="0" ry="0" width="70%" height="20" />
            <rect x="20" y="90%" rx="0" ry="0" width="65%" height="20" />
            <rect x="20" y="95%" rx="0" ry="0" width="68%" height="20" />

            <rect x="80%" y="70" rx="0" ry="0" width="250" height="10" />
            <rect x="80%" y="85" rx="0" ry="0" width="200" height="10" />
            <rect x="80%" y="100" rx="0" ry="0" width="220" height="10" />
            <rect x="80%" y="115" rx="0" ry="0" width="230" height="10" />
            <rect x="80%" y="130" rx="0" ry="0" width="210" height="10" />
            <rect x="80%" y="145" rx="0" ry="0" width="250" height="10" />

            <rect x="80%" y="175" rx="0" ry="0" width="240" height="10" />
            <rect x="80%" y="190" rx="0" ry="0" width="220" height="10" />
            <rect x="80%" y="205" rx="0" ry="0" width="250" height="10" />
            <rect x="80%" y="220" rx="0" ry="0" width="250" height="10" />
            <rect x="80%" y="235" rx="0" ry="0" width="210" height="10" />
            <rect x="80%" y="250" rx="0" ry="0" width="230" height="10" />
            <rect x="80%" y="265" rx="0" ry="0" width="240" height="10" />
            <rect x="80%" y="280" rx="0" ry="0" width="220" height="10" />
            <rect x="80%" y="295" rx="0" ry="0" width="250" height="10" />
            <rect x="80%" y="310" rx="0" ry="0" width="240" height="10" />
            <rect x="80%" y="325" rx="0" ry="0" width="210" height="10" />
            <rect x="80%" y="340" rx="0" ry="0" width="230" height="10" />
            <rect x="80%" y="355" rx="0" ry="0" width="250" height="10" />
            <rect x="80%" y="370" rx="0" ry="0" width="210" height="10" />
            <rect x="80%" y="385" rx="0" ry="0" width="230" height="10" />
            <rect x="80%" y="400" rx="0" ry="0" width="220" height="10" />

            <rect x="80%" y="430" rx="0" ry="0" width="250" height="10" />
            <rect x="80%" y="445" rx="0" ry="0" width="200" height="10" />
            <rect x="80%" y="460" rx="0" ry="0" width="220" height="10" />
            <rect x="80%" y="475" rx="0" ry="0" width="230" height="10" />
            <rect x="80%" y="490" rx="0" ry="0" width="210" height="10" />
            <rect x="80%" y="505" rx="0" ry="0" width="250" height="10" />

            <rect x="80%" y="535" rx="0" ry="0" width="240" height="10" />
            <rect x="80%" y="550" rx="0" ry="0" width="230" height="10" />
            <rect x="80%" y="565" rx="0" ry="0" width="220" height="10" />
            <rect x="80%" y="580" rx="0" ry="0" width="250" height="10" />
            <rect x="80%" y="595" rx="0" ry="0" width="200" height="10" />
            <rect x="80%" y="610" rx="0" ry="0" width="250" height="10" />
            <rect x="80%" y="625" rx="0" ry="0" width="220" height="10" />
            <rect x="80%" y="640" rx="0" ry="0" width="210" height="10" />
            <rect x="80%" y="655" rx="0" ry="0" width="240" height="10" />
            <rect x="80%" y="670" rx="0" ry="0" width="230" height="10" />
        </ContentLoader>
    );
}
