import React from 'react';
import { Inspector } from '@bynder/design-system';
import BackgroundElement from 'packages/pages/design/components/BackgroundElement';

type Props = {
    id: string;
    isLast: boolean;
    isDisabled: boolean;
};

const ShotItemGlobal = ({ id, isDisabled, isLast = true }: Props) => (
    <BackgroundElement elementId={id} isDisabled={isDisabled}>
        {isLast ? <Inspector.Divider /> : null}
    </BackgroundElement>
);

export default ShotItemGlobal;
