import React, { Fragment } from 'react';
import { Box, Button, Divider, List, Modal } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import generateTestId from '~/helpers/testIdHelpers';
import { useShortcutListItems, type ShortcutItem } from './items';
import { StyledHeading } from './ShortcutsInfoModal.styled';

type Props = {
    isOpen: boolean;
    setModalOpen: (isOpen: boolean) => void;
};

export default function renderShortcutsInfoModal({ isOpen, setModalOpen }: Props): React.JSX.Element {
    const { translate } = useTranslate();

    const renderListItem = (item: ShortcutItem, isLast: boolean) => {
        return (
            <Fragment key={`section-item-${item.title}`}>
                <List.Item rightElements={item.badges.map((badge) => badge)}>{item.title}</List.Item>
                {!isLast && <Divider />}
            </Fragment>
        );
    };

    return (
        <Modal
            title={translate('modal.shortcuts.title')}
            isOpen={isOpen}
            onClose={() => setModalOpen(false)}
            actionPrimary={
                <Button variant="primary" onClick={() => setModalOpen(false)}>
                    {translate('modal.shortcuts.action')}
                </Button>
            }
        >
            {Object.entries(useShortcutListItems()).map(([key, value]) => {
                return (
                    <Box key={`section-${key}`} {...generateTestId(`shortcuts-modal-section-${key}`)} marginBlock="4">
                        <StyledHeading {...generateTestId(`shortcuts-modal-heading-${key}`)}>{key}</StyledHeading>
                        <List>
                            {Object.values(value).map((item, index) => {
                                return renderListItem(item, index === Object.values(value).length - 1);
                            })}
                        </List>
                    </Box>
                );
            })}
        </Modal>
    );
}
