import { useContext } from 'react';
import { ExportContext } from '../ExportContext';

export default function useExport() {
    const {
        loading,
        hadLoading,
        loadNextPart,
        loadingNextPart,
        view,
        setView,
        groupBy,
        setGroupBy,
        orderBy,
        setOrderBy,
        searchValue,
        setSearchValue,
        pages,
        pageIds,
        setPageIds,
        variationSets,
        variationSetIds,
        setVariationSetIds,
        variationSetsLoading,
        getNextVariationSetsPart,
        total,
        totalItems,
        totalValidItems,
        items,
        selectedIds,
        selectedCount,
        toggleSelect,
        selectAll,
        deselectAll,
        selectItemEntries,
        creativeType,
        isDownloadBlocked,
        isWaitingRoomBlocked,
        selectedJob,
        setSelectedJob,
        setSelectedIds,
        setSelectedCount,
        pageSets,
        fetchVariations,
        setFetchVariations,
    } = useContext(ExportContext);

    const pageMap = {};
    pages.forEach((page) => {
        pageMap[+page.id] = page;
    });

    return {
        loading,
        hadLoading,
        loadNextPart,
        loadingNextPart,
        view,
        setView,
        groupBy,
        setGroupBy,
        orderBy,
        setOrderBy,
        searchValue,
        setSearchValue,
        pages,
        pageMap,
        pageIds,
        setPageIds,
        variationSets,
        variationSetIds,
        setVariationSetIds,
        variationSetsLoading,
        getNextVariationSetsPart,
        total,
        totalItems,
        items,
        selectedIds,
        selectedCount,
        toggleSelect,
        selectAll,
        deselectAll,
        selectItemEntries,
        creativeType,
        totalValidItems,
        isDownloadBlocked,
        isWaitingRoomBlocked,
        selectedJob,
        setSelectedJob,
        setSelectedIds,
        setSelectedCount,
        pageSets,
        fetchVariations,
        setFetchVariations,
    };
}
