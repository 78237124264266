import React from 'react';
import { getUnixTime } from 'date-fns';
import { notify, Thumbnail } from '@bynder/design-system';
import { IconCheck, IconDelete, IconCancel, IconRename, IconFileMove, IconUngroup } from '@bynder/icons';
import { Translate } from '@bynder/localization';
import AccessService from 'packages/services/AccessControl';
import { EntityType } from 'packages/store/sharing/types';
import { OwnershipFilterType } from 'packages/pages/components/filters/components/Ownership/types';
import { isTrialAccountStorageFn, catchResponseError } from 'packages/helpers/helpers';
import { fetchCreativesByOptions } from '~/store/creatives/creatives.actions';
import { fetchAssetsByOptions } from '~/store/assets/assets.actions';
import { sendAppcuesEvent } from '~/helpers/RouteWithAppcues';
import * as types from '../types';
import CollectionsService, { createQuery } from '../../services/CollectionsService';

export function updateCollectionsThumbnails(creativeThumbnails) {
    return {
        type: types.COLLECTIONS_CHANGE_SOME,
        creativeThumbnails,
    };
}

export function fetchCollections(options, customerId, emptyBeforeFetch, includeCollectionId) {
    return (dispatch, getState) => {
        const searchTime = getUnixTime(new Date(Date.now()));
        const requestedType = options?.type;

        const fetchOptions = options || getState().collections.fetchOptions;

        if (emptyBeforeFetch) {
            fetchOptions.page = 0;
        }

        const query = createQuery({ ...fetchOptions, customerId });

        if (options && getState().collections.fetchOptions.search !== options.search) {
            dispatch({
                type: types.COLLECTIONS_START_FETCH,
                searching: true,
                searchTime,
                emptyBeforeFetch,
                requestedType,
            });
        } else {
            dispatch({ type: types.COLLECTIONS_START_FETCH, searching: false, emptyBeforeFetch, requestedType });
        }

        CollectionsService.getCollections(query, includeCollectionId)
            .then(({ status, json: { items: collections, totalItems, page } }) => {
                if (status !== 200) {
                    notify({
                        title: 'Fail to load collections',
                        variant: 'error',
                    });
                    dispatch({
                        type: types.COLLECTIONS_FAIL_FETCH,
                    });
                } else {
                    if (
                        getState().collections.searching &&
                        getState().collections.fetchOptions.searchTime &&
                        getState().collections.fetchOptions.searchTime > searchTime
                    ) {
                        return;
                    }

                    // in case of projects and assets pages involved in race condition
                    if (getState().collections.fetchOptions.requestedType !== requestedType) {
                        return;
                    }

                    if (page) {
                        fetchOptions.page = page;
                    }

                    dispatch({
                        type: types.COLLECTIONS_SUCCESS_FETCH,
                        collections,
                        totalItems,
                        emptyBeforeFetch,
                        fetchOptions,
                    });
                }
            })
            .catch(catchResponseError);
    };
}

// because we get fetchOptions inside the action creator we can memoise and debouce callbacks that use it
export function fetchCollectionsBySearchQuery(query, type, subtype, currentCompany) {
    return (dispatch, getState) => {
        const options = {
            ...getState().collections.fetchOptions,
            type,
            subtype,
            search: query,
        };

        dispatch(fetchCollections(options, currentCompany, true));
    };
}

export function fetchCollection(id, withPermissions = false) {
    return (dispatch, getState) => {
        dispatch({ type: types.COLLECTION_START_FETCH });

        if (withPermissions) {
            Promise.all([CollectionsService.getCollection(id), AccessService.getAccess([id], EntityType.COLLECTION)])
                .then(([{ status: statusData, collection }, { status: statusPermissions, json }]) => {
                    if (statusData === 200 && statusPermissions === 200) {
                        dispatch({
                            type: types.COLLECTION_SUCCESS_FETCH,
                            collection: { ...collection, grantedPermissions: json[0].entityGrantedPermissions },
                        });
                    } else {
                        dispatch({ type: types.COLLECTION_FAILURE_FETCH });
                    }
                })
                .catch(catchResponseError);
        } else {
            CollectionsService.getCollection(id)
                .then(({ status, collection }) => {
                    if (status === 200) {
                        dispatch({
                            type: types.COLLECTION_SUCCESS_FETCH,
                            collection,
                        });
                    } else {
                        dispatch({ type: types.COLLECTION_FAILURE_FETCH });
                    }
                })
                .catch(catchResponseError);
        }
    };
}

export function deleteCollection(elements = null, redirectAfter) {
    return (dispatch, getState) => {
        const options = getState().collections.fetchOptions;
        dispatch({ type: types.COLLECTION_DELETE_IN_PROGRESS });
        elements =
            elements ||
            getState()
                .collections.collections.filter((c) => c.checked)
                .map((c) => c.id);
        const customerId = getState().user.currentCompany;
        CollectionsService.deleteCollections(elements)
            .then(({ status }) => {
                if (status === 200) {
                    dispatch({ type: types.COLLECTION_DELETE_SUCCESS });

                    if (redirectAfter) {
                        redirectAfter();
                    } else {
                        dispatch(fetchCollections(options, customerId, true));
                    }

                    if (options.type === 'ASSET') {
                        notify({
                            thumbnail: <Thumbnail variant="clean" shape="circle" icon={<IconDelete />} />,
                            title: <Translate id="modal.uploads.collection.delete.success" />,
                        });
                        sendAppcuesEvent('Collection removed', { ids: elements });
                    } else {
                        notify({
                            thumbnail: (
                                <Thumbnail
                                    variant="clean"
                                    shape="circle"
                                    backgroundColor="green500"
                                    icon={<IconCheck />}
                                    iconColor="white"
                                />
                            ),
                            title: <Translate id="modal.project.delete.success" />,
                        });
                        sendAppcuesEvent('Projects removed', { ids: elements });
                    }
                } else {
                    dispatch({ type: types.COLLECTION_RENAME_FAILURE });

                    if (options.type === 'ASSET') {
                        notify({
                            thumbnail: (
                                <Thumbnail
                                    variant="clean"
                                    shape="circle"
                                    backgroundColor="red500"
                                    icon={<IconCancel />}
                                    iconColor="white"
                                />
                            ),
                            title: <Translate id="modal.uploads.collection.delete.failure" />,
                        });
                    } else {
                        notify({
                            thumbnail: (
                                <Thumbnail
                                    variant="clean"
                                    shape="circle"
                                    backgroundColor="red500"
                                    icon={<IconCancel />}
                                    iconColor="white"
                                />
                            ),
                            title: <Translate id="modal.project.delete.failure" />,
                        });
                    }
                }
            })
            .catch(catchResponseError);
    };
}

export function moveToCollection(collectionId, elements, type) {
    return (dispatch, getState) => {
        const { options } = getState().creatives;
        const customerId = getState().user.currentCompany;

        dispatch({ type: types.COLLECTION_ADD_CREATIVE_IN_PROGRESS });

        CollectionsService.moveToCollection(collectionId, elements, type)
            .then(({ status }) => {
                if (status === 200) {
                    dispatch({ type: types.COLLECTION_ADD_CREATIVE_SUCCESS });

                    if (type === 'ASSET') {
                        dispatch(fetchAssetsByOptions(getState().assets.fetchOptions, true));

                        notify({
                            thumbnail: <Thumbnail variant="clean" shape="circle" icon={<IconFileMove />} />,
                            title: <Translate id="modal.uploads.move.success" count={elements.length} />,
                        });
                        sendAppcuesEvent('File moved to collection', { id: collectionId, ids: elements });
                    } else {
                        dispatch(fetchCreativesByOptions(options, customerId, true));

                        notify({
                            thumbnail: (
                                <Thumbnail
                                    variant="clean"
                                    shape="circle"
                                    backgroundColor="green500"
                                    icon={<IconCheck />}
                                    iconColor="white"
                                />
                            ),
                            title: <Translate id="modal.design.move.success" count={elements.length} />,
                        });
                        sendAppcuesEvent('Items moved to project', { id: collectionId, ids: elements });
                    }
                } else {
                    dispatch({ type: types.COLLECTION_ADD_CREATIVE_FAILURE });

                    if (type === 'ASSET') {
                        notify({
                            thumbnail: (
                                <Thumbnail
                                    variant="clean"
                                    shape="circle"
                                    backgroundColor="red500"
                                    icon={<IconCancel />}
                                    iconColor="white"
                                />
                            ),
                            title: <Translate id="modal.uploads.move.failure" />,
                        });
                    } else {
                        notify({
                            thumbnail: (
                                <Thumbnail
                                    variant="clean"
                                    shape="circle"
                                    backgroundColor="red500"
                                    icon={<IconCancel />}
                                    iconColor="white"
                                />
                            ),
                            title: <Translate id="modal.design.move.failure" />,
                        });
                    }
                }
            })
            .catch(catchResponseError);
    };
}

export function removeFromCollection(collectionId, elements, type) {
    return (dispatch, getState) => {
        const { options } = getState().creatives;
        const customerId = getState().user.currentCompany;

        dispatch({ type: types.COLLECTION_REMOVE_FROM_CREATIVE_IN_PROGRESS });

        CollectionsService.removeFromCollection(collectionId, elements, type)
            .then(({ status }) => {
                if (status === 200) {
                    dispatch({ type: types.COLLECTION_REMOVE_FROM_CREATIVE_SUCCESS });

                    if (type === 'ASSET') {
                        dispatch(fetchAssetsByOptions(getState().assets.fetchOptions, true));
                        dispatch(fetchCollection(collectionId, false));

                        notify({
                            thumbnail: <Thumbnail variant="clean" shape="circle" icon={<IconUngroup />} />,
                            title: (
                                <Translate id="modal.uploads.remove-from-collection.success" count={elements.length} />
                            ),
                        });
                        sendAppcuesEvent('File removed from collection', { id: collectionId, ids: elements });
                    } else {
                        dispatch(fetchCreativesByOptions(options, customerId, true));
                        dispatch(fetchCollection(collectionId, type === 'CREATIVE'));

                        notify({
                            thumbnail: (
                                <Thumbnail
                                    variant="clean"
                                    shape="circle"
                                    backgroundColor="green500"
                                    icon={<IconCheck />}
                                    iconColor="white"
                                />
                            ),
                            title: <Translate id="pages.projectDetail.removeFromProject.success" />,
                        });
                        sendAppcuesEvent('Items removed from project', { id: collectionId, ids: elements });
                    }
                } else {
                    dispatch({ type: types.COLLECTION_REMOVE_FROM_CREATIVE_FAILURE });

                    if (type === 'ASSET') {
                        notify({
                            thumbnail: (
                                <Thumbnail
                                    variant="clean"
                                    shape="circle"
                                    backgroundColor="red500"
                                    icon={<IconCancel />}
                                    iconColor="white"
                                />
                            ),
                            title: <Translate id="modal.uploads.remove-from-collection.failure" />,
                        });
                    } else {
                        notify({
                            thumbnail: (
                                <Thumbnail
                                    variant="clean"
                                    shape="circle"
                                    backgroundColor="red500"
                                    icon={<IconCancel />}
                                    iconColor="white"
                                />
                            ),
                            title: <Translate id="pages.projectDetail.removeFromProject.failure" />,
                        });
                    }
                }
            })
            .catch(catchResponseError);
    };
}

export function createNewCollection(name, type) {
    return (dispatch, getState) => {
        const options = getState().collections.fetchOptions;
        const customerId = getState().user.currentCompany;

        dispatch({ type: types.COLLECTION_ADD_NEW_IN_PROGRESS });

        CollectionsService.addToCollection({ collectionId: null, customerId, name, type, subType: 'REGULAR' })
            .then(({ status }) => {
                if (status === 200) {
                    dispatch({ type: types.COLLECTION_ADD_NEW_SUCCESS });
                    dispatch(fetchCollections({ ...options, type, subType: 'REGULAR' }, customerId, true));

                    notify({
                        thumbnail: (
                            <Thumbnail
                                variant="clean"
                                shape="circle"
                                backgroundColor="green500"
                                icon={<IconCheck />}
                                iconColor="white"
                            />
                        ),
                        title: <Translate id="modal.project.create.success" />,
                    });
                    sendAppcuesEvent('Project created', { name });
                } else if (status === 409) {
                    dispatch({ type: types.COLLECTION_ADD_NEW_FAILURE });

                    notify({
                        thumbnail: (
                            <Thumbnail
                                variant="clean"
                                shape="circle"
                                backgroundColor="red500"
                                icon={<IconCancel />}
                                iconColor="white"
                            />
                        ),
                        title: <Translate id="modal.project.create.failure.duplicate" />,
                    });
                } else {
                    dispatch({ type: types.COLLECTION_ADD_NEW_FAILURE });

                    notify({
                        thumbnail: (
                            <Thumbnail
                                variant="clean"
                                shape="circle"
                                backgroundColor="red500"
                                icon={<IconCancel />}
                                iconColor="white"
                            />
                        ),
                        title: <Translate id="modal.project.create.failure" />,
                    });
                }
            })
            .catch(catchResponseError);
    };
}

export function createNewUploadsCollection(name, type) {
    return (dispatch, getState) => {
        const options = getState().collections.fetchOptions;
        const customerId = getState().user.currentCompany;

        dispatch({ type: types.COLLECTION_ADD_NEW_IN_PROGRESS });

        CollectionsService.addToCollection({
            collectionId: null,
            customerId,
            name,
            type,
        })
            .then(({ status, json: collection }) => {
                if (status === 200) {
                    dispatch({ type: types.COLLECTION_ADD_NEW_SUCCESS });
                    dispatch(fetchCollections({ ...options, type }, customerId, true));

                    notify({
                        thumbnail: (
                            <Thumbnail
                                variant="clean"
                                shape="circle"
                                backgroundColor="green500"
                                icon={<IconCheck />}
                                iconColor="white"
                            />
                        ),
                        title: <Translate id="modal.uploads.collection.create.success" />,
                    });
                    sendAppcuesEvent('Uploads collection created', { name });
                } else if (status === 409) {
                    dispatch({ type: types.COLLECTION_ADD_NEW_FAILURE });

                    notify({
                        thumbnail: (
                            <Thumbnail
                                variant="clean"
                                shape="circle"
                                backgroundColor="red500"
                                icon={<IconCancel />}
                                iconColor="white"
                            />
                        ),
                        title: <Translate id="modal.uploads.collection.create.failure.duplicate" />,
                    });
                } else {
                    dispatch({ type: types.COLLECTION_ADD_NEW_FAILURE });

                    notify({
                        thumbnail: (
                            <Thumbnail
                                variant="clean"
                                shape="circle"
                                backgroundColor="red500"
                                icon={<IconCancel />}
                                iconColor="white"
                            />
                        ),
                        title: <Translate id="modal.uploads.collection.create.failure" />,
                    });
                }
            })
            .catch(catchResponseError);
    };
}

export function handleToggleAll() {
    sendAppcuesEvent('All projects selected', {});

    return {
        type: types.COLLECTIONS_TOGGLE_ALL,
    };
}

export function handleToggleItem(id, allChecked) {
    sendAppcuesEvent('Project selected/deselected/toggled', { id });

    return {
        type: types.COLLECTIONS_TOGGLE_ITEM,
        id,
        allChecked,
    };
}

export function editCollectionName(id, name, isOverview) {
    return (dispatch, getState) => {
        const customerId = getState().user.currentCompany;
        const options = getState().collections.fetchOptions;
        dispatch({ type: types.COLLECTION_RENAME_IN_PROGRESS });
        CollectionsService.renameCollection(id, name)
            .then(({ status }) => {
                if (status === 200) {
                    dispatch({ type: types.COLLECTION_RENAME_SUCCESS });
                    dispatch(!isOverview ? fetchCollection(id) : fetchCollections(options, customerId, true));

                    if (options.type === 'ASSET') {
                        notify({
                            thumbnail: <Thumbnail variant="clean" shape="circle" icon={<IconRename />} />,
                            title: <Translate id="modal.uploads.collection.rename.success" />,
                        });
                        sendAppcuesEvent('Collection renamed', { id, name });
                    } else {
                        notify({
                            thumbnail: (
                                <Thumbnail
                                    variant="clean"
                                    shape="circle"
                                    backgroundColor="green500"
                                    icon={<IconCheck />}
                                    iconColor="white"
                                />
                            ),
                            title: <Translate id="modal.project.rename.success" />,
                        });
                        sendAppcuesEvent('Project renamed', { id, name });
                    }
                } else {
                    dispatch({ type: types.COLLECTION_RENAME_FAILURE });

                    if (options.type === 'ASSET') {
                        notify({
                            thumbnail: (
                                <Thumbnail
                                    variant="clean"
                                    shape="circle"
                                    backgroundColor="red500"
                                    icon={<IconCancel />}
                                    iconColor="white"
                                />
                            ),
                            title: <Translate id="modal.uploads.collection.rename.failure" />,
                        });
                    } else {
                        notify({
                            thumbnail: (
                                <Thumbnail
                                    variant="clean"
                                    shape="circle"
                                    backgroundColor="red500"
                                    icon={<IconCancel />}
                                    iconColor="white"
                                />
                            ),
                            title: <Translate id="modal.project.rename.failure" />,
                        });
                    }
                }
            })
            .catch(catchResponseError);
    };
}

export function editCollection(id, collectionUpdates, isOverview) {
    return (dispatch, getState) => {
        const customerId = getState().user.currentCompany;
        const options = getState().collections.fetchOptions;

        dispatch({ type: types.COLLECTION_EDIT_IN_PROGRESS });

        CollectionsService.editCollection(id, collectionUpdates)
            .then(({ status }) => {
                if (status === 200) {
                    dispatch({ type: types.COLLECTION_EDIT_SUCCESS });
                    dispatch(!isOverview ? fetchCollection(id) : fetchCollections(options, customerId, true));

                    notify({
                        thumbnail: (
                            <Thumbnail
                                variant="clean"
                                shape="circle"
                                backgroundColor="green500"
                                icon={<IconCheck />}
                                iconColor="white"
                            />
                        ),
                        title: <Translate id="modal.project.editDetails.success" />,
                    });
                    sendAppcuesEvent('Project edit details', { id });
                } else {
                    dispatch({ type: types.COLLECTION_EDIT_FAILURE });

                    notify({
                        thumbnail: (
                            <Thumbnail
                                variant="clean"
                                shape="circle"
                                backgroundColor="red500"
                                icon={<IconCancel />}
                                iconColor="white"
                            />
                        ),
                        title: <Translate id="modal.project.editDetails.failure" />,
                    });
                }
            })
            .catch(catchResponseError);
    };
}

export function resetOwnership() {
    return (dispatch, getState) => {
        dispatch({
            type: types.COLLECTIONS_RESET_OWNERSHIP,
            ownershipSource: OwnershipFilterType.OWNED_BY_ME,
        });
    };
}

export function init(params = {}) {
    return (dispatch, getState) => {
        dispatch({ type: types.COLLECTIONS_RESET });

        if (isTrialAccountStorageFn(getState())) {
            dispatch(resetOwnership());
        }

        const options = { ...getState().collections.fetchOptions, ...params };
        dispatch(fetchCollections(options, getState().user.currentCompany, true));
    };
}
