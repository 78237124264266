import { useEffect } from 'react';
import { isInteractiveElement } from '@bynder-studio/misc';

type Props = {
    handlePageChangePrevious: () => void;
    handlePageChangeNext: () => void;
};

export default function usePageHotkeys({ handlePageChangePrevious, handlePageChangeNext }: Props) {
    const handlePageChange = (event) => {
        if (isInteractiveElement(event)) {
            return;
        }

        // Select next page
        if (['ArrowUp', 'PageUp', 'PgUp'].includes(event.code)) {
            event.preventDefault();

            if (handlePageChangePrevious) {
                handlePageChangePrevious();
            }
        }

        // Select previous page
        if (['ArrowDown', 'PageDown', 'PgDn'].includes(event.code)) {
            event.preventDefault();

            if (handlePageChangeNext) {
                handlePageChangeNext();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handlePageChange);

        return () => {
            document.removeEventListener('keydown', handlePageChange);
        };
    }, [handlePageChange]);
}
