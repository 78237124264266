import { Box, Dimension, Position } from '@bynder-studio/render-core';

class BoundingBox extends Box {
    _elementId: number = null;

    isUsedAsMask = false;

    isContentProperty = false;

    isInvalid = false;

    checkIsElementIsMask: (elementId: number) => boolean = () => false;

    constructor(
        position: Position,
        dimension: Dimension,
        rotation: number,
        checkIsElementIsMask: (elementId: number) => boolean,
        elementId = null,
    ) {
        super(position, dimension, rotation);
        this.checkIsElementIsMask = checkIsElementIsMask;
        this.elementId = elementId;
    }

    get elementId(): number {
        return this._elementId;
    }

    set elementId(elementId) {
        this._elementId = elementId;
        this.isUsedAsMask = this.checkIsElementIsMask(elementId);
    }
}

export default BoundingBox;
