import React from 'react';
import { Navigate } from 'react-router-dom';
import authorizationHelper from '../../helpers/AuthorizationHelper';

type Props = {
    permissions: string[];
    features: string[];
    redirectTo: string;
    children: React.ReactNode;
    checkAllPermissions: boolean;
    renderForbidden: React.ReactNode;
};

const AuthorizationAllowBlock = ({
    permissions,
    features,
    redirectTo,
    children,
    checkAllPermissions,
    renderForbidden,
}: Props) => {
    let permissionsAllowed = true;
    let featureIsAvailable = true;

    if (permissions) {
        if (checkAllPermissions) {
            permissionsAllowed = authorizationHelper.hasPermissions(permissions);
        } else {
            permissionsAllowed = authorizationHelper.hasOneOfThePermissions(permissions);
        }
    }

    if (features) {
        featureIsAvailable = authorizationHelper.isOneOfTheFeaturesAvailable(features);
    }

    if (permissionsAllowed && featureIsAvailable) {
        return children;
    }

    if (redirectTo) {
        return <Navigate to={redirectTo} replace={true} />;
    }

    if (renderForbidden) {
        return renderForbidden;
    }

    return null;
};

export default AuthorizationAllowBlock;
