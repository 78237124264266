import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import type { TextStyle as TextStyleType } from '@bynder-studio/render-core';
import { getFontById } from '@bynder-studio/structured-text';
import { Dropdown, Button, Thumbnail, Tooltip } from '@bynder/design-system';
import { IconFont } from '@bynder/icons';
import generateTestId from '~/helpers/testIdHelpers';
import { TextStyleItem } from 'packages/pages/design/components/TextElement/TextStyleItem';
import { ApplyFont, FontThumbnailSmall, TextStyleName } from './Component.styled';

type TextStyleProps = {
    list: TextStyleType[];
    elementId: string;
    onChange: ({ styleId }: { styleId: string }) => void;
    disabled?: boolean;
};

export type TextStyleRef = {
    setStyles: (styleId: string[]) => void;
};

export const TextStyle = forwardRef<TextStyleRef, TextStyleProps>(({ list, elementId, disabled, onChange }, ref) => {
    const testId = useMemo(() => generateTestId(`element_text_style_${elementId}`), [elementId]);
    const [values, setValues] = useState<string[]>([]);
    const item = useMemo(
        () => (values?.length === 1 ? list.find((item) => item.uuid === values[0]) : undefined),
        [values, list],
    );

    const handleClick = useCallback<(item: TextStyleType) => void>(
        (item) => {
            setValues([item.uuid]);
            onChange({ styleId: item.uuid });
        },
        [onChange],
    );

    useImperativeHandle(ref, () => ({
        setStyles(styleId: string[]) {
            setValues(styleId);
        },
    }));

    return (
        <Dropdown
            trigger={({ isOpen, ...triggerProps }) => (
                <Tooltip content="Text style">
                    <Button
                        variant="clean"
                        isSmall
                        isDisabled={disabled}
                        isPressed={isOpen}
                        rightIcon={<Dropdown.Arrow />}
                        icon={
                            item ? (
                                <FontThumbnailSmall>
                                    <ApplyFont name={item.uuid} fontUrl={getFontById(item.fontId)?.url ?? ''}>
                                        A
                                    </ApplyFont>
                                </FontThumbnailSmall>
                            ) : (
                                <Thumbnail
                                    variant="clean"
                                    icon={<IconFont />}
                                    backgroundColor="transparent"
                                    size="xs"
                                />
                            )
                        }
                        {...triggerProps}
                        {...testId}
                    >
                        <TextStyleName>{item ? item.name : values.length ? 'Mixed' : 'No text style'}</TextStyleName>
                    </Button>
                </Tooltip>
            )}
        >
            {list.map((item) => (
                <TextStyleItem
                    key={item.uuid}
                    item={item}
                    onClick={handleClick}
                    selected={values.includes(item.uuid)}
                    useMultiselectIcon={values.length > 1}
                />
            ))}
        </Dropdown>
    );
});
