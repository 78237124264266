import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ExportJobItemProgressType, ExportJobItemType } from 'packages/variation-export/AllExports/types';
import { IN_PROGRESS } from '~/helpers/textConstants';
import { InProgressJobRow } from './InProgressJobRow';
import { ExportSectionTitle } from './AllExports.styled';
import { useSocketSubscribe } from 'packages/socket';
import useDesign from 'packages/pages/design/hooks/useDesign';
import { isJobFailed } from '../utils';
import { InProgressJobData, subscribeExportJobsOptions } from 'packages/socket/subscribeOptions/exportJobs';
import useExport from 'packages/variation-export/hooks/useExport';

type InProgressJobBlockProps = {
    jobs: ExportJobItemProgressType[];
    // onStart: (item: ExportJobItemProgressType) => void;
    onComplete: (item: ExportJobItemProgressType) => void;
    onFailed: (item: ExportJobItemProgressType) => void;
};

const InProgressJobBlock = ({ jobs, onComplete, onFailed }: InProgressJobBlockProps) => {
    const { setFetchVariations } = useExport();
    const { creativeId } = useDesign();
    const [items, setItems] = useState<ExportJobItemProgressType[]>([]);

    useEffect(() => {
        setItems((items) => {
            const ids = new Set(items.map((item) => item.id));
            jobs.forEach((job) => {
                if (ids.has(job.id)) {
                    return;
                }

                items.push(job);
            });

            return [...items];
        });
    }, [jobs]);

    const onMessage = (data: InProgressJobData, unsbscribe: () => void) => {
        if (
            data.totalItems === 0 ||
            data.items.every((item) => item.jobVariations.every((variation) => variation.status === 'FAILED'))
        ) {
            unsbscribe();
            setFetchVariations(false);
        }

        const jobIds = new Set(jobs.map((job) => job.id));
        const newItems = data.items.filter((item) => jobIds.has(item.id));

        const inProggressStage = data.items.reduce((acc, item) => {
            if (item.progresses.some((progress) => progress.status === 'CREATING' && progress.progress > 0)) {
                acc = true;
            }

            return acc;
        }, false);

        if (inProggressStage) {
            setFetchVariations(true);
        }

        setItems((items) => {
            data.items.forEach((item) => {
                if (!jobIds.has(item.id)) {
                    // should be open for show jobs that started by other users or tabs
                    // onStart(item);
                    return;
                }

                if (isJobFailed(item)) {
                    onFailed(item);

                    return;
                }

                jobIds.delete(item.id);
            });

            jobIds.forEach((id) => {
                const job = items.find((item) => item.id === id) || jobs.find((item) => item.id === id);

                if (job) {
                    onComplete(job);
                }
            });

            return newItems;
        });
    };

    const subscribe = useSocketSubscribe<InProgressJobData, {}>(subscribeExportJobsOptions({ creativeId, onMessage }));

    useEffect(() => {
        if (!items.length) {
            return;
        }

        subscribe();
    }, [subscribe, items.length]);

    if (!items.length) {
        return null;
    }

    return (
        <>
            <div>
                <ExportSectionTitle>{IN_PROGRESS}</ExportSectionTitle>
            </div>
            <div>
                {items.map((job) => (
                    <InProgressJobRow key={job.id} job={job} />
                ))}
            </div>
        </>
    );
};

export default InProgressJobBlock;
