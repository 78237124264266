import { IdentityType } from 'packages/store/sharing/types';

export enum ApprovalRequestType {
    REQUEST = 'REQUEST',
    REVIEW = 'REVIEW',
}

export type ReviewierIdentity = {
    securityIdentityType: IdentityType;
    identityId?: string;
    bynderUserId?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    email?: string;
    groupId?: string;
    groupName?: string;
    profileId?: string;
    profileName?: string;
};

export type RequestApprovalModalProps = {
    type: ApprovalRequestType;
    templateId: number;
    reviewers: ReviewierIdentity[];
    isOpen: boolean;
    isSaving: boolean;
    handleOnClose: () => void;
    saveChanges: (cb: () => void) => void;
};
