import { useCallback, useEffect } from 'react';
import { isInteractiveElement } from '@bynder-studio/misc';
import { isCtrlKey } from '~/helpers/hotKeys';

export const useAudioShortcuts = (handleMuteClick: () => void) => {
    const handleKeyDown = useCallback(
        (e: KeyboardEvent): void => {
            if (isInteractiveElement(e) || isCtrlKey(e) || e.shiftKey) {
                return;
            }

            switch (e.code) {
                case 'KeyM': {
                    handleMuteClick();
                    break;
                }

                default:
                    break;
            }
        },
        [handleMuteClick],
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);
};
