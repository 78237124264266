import React from 'react';
import { Flex, Form, Input, Slider } from '@bynder/design-system';
import { IconFontSmall, IconFontLarge } from '@bynder/icons';
import {
    FontIconWrapper,
    InputWrapper,
    SliderWrapper,
} from 'packages/pages/editor/RightSideMenu/Shots/Text/TextWrap/index.styled';
import { useFontScale } from 'packages/pages/editor/RightSideMenu/Shots/Text/TextScale/useFontScale';
import { BlurSlider } from 'packages/pages/editor/RightSideMenu/Animations/components/PropertyField/BlurField.styled';
import { FontScaleProps } from 'packages/pages/editor/RightSideMenu/Shots/Text/TextScale/types';

const TextScale = (props: FontScaleProps) => {
    const {
        isFromCenter,
        isDisabled,
        max,
        inputTestId,
        label,
        sliderValue,
        onSliderChanged,
        sliderTestId,
        inputValue,
        handleInputChange,
        handleKeyDown,
        handleBlur,
    } = useFontScale(props);

    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Flex alignItems="center" justifyContent="space-between">
                <FontIconWrapper>
                    <IconFontSmall style={{ width: 24 }} />
                </FontIconWrapper>
                <SliderWrapper>
                    {/* TODO: USE TILL DESIGN-TEAM WILL IMPLEMENT DISABLED STATE FOR SLIDER */}
                    {isDisabled ? (
                        <BlurSlider
                            width="100%"
                            min={1}
                            max={max}
                            value={0}
                            onChange={() => {}}
                            isInactive
                            isTooltipHidden
                            disabled
                        />
                    ) : (
                        <Slider
                            min={1}
                            max={max}
                            value={sliderValue}
                            onChange={onSliderChanged}
                            isFromCenter={isFromCenter}
                            {...sliderTestId}
                        />
                    )}
                </SliderWrapper>
                <FontIconWrapper>
                    <IconFontLarge />
                </FontIconWrapper>
                <InputWrapper>
                    <Input
                        isDisabled={isDisabled}
                        value={inputValue.toString()}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        onBlur={handleBlur}
                        suffix="%"
                        {...inputTestId}
                    />
                </InputWrapper>
            </Flex>
        </Form.Group>
    );
};

export default TextScale;
