import { useCallback, useEffect, useRef } from 'react';
import useEditor from '~/hooks/useEditor';

export const usePanningShortcuts = () => {
    const { manipulationRenderer } = useEditor();
    const isPanFired = useRef(false);

    const panHandler = useCallback(() => {
        isPanFired.current = true;
    }, []);

    const togglePanning = useCallback(
        (panMode) => {
            if (!manipulationRenderer || manipulationRenderer.getPanMode() === panMode) {
                return;
            }

            if (panMode && !manipulationRenderer.getIsPlaying()) {
                manipulationRenderer.togglePanMode(panMode);
            } else if (!panMode) {
                manipulationRenderer.togglePanMode(panMode);
            }
        },
        [manipulationRenderer],
    );

    const handleSpaceBarKeyDown = useCallback(
        (e) => {
            const isInteractiveElement = e.target.tagName !== 'BODY' && e.target.tagName !== 'CANVAS';

            if (e.repeat || isInteractiveElement || e.keyCode !== 32) {
                return;
            }

            isPanFired.current = false;
            togglePanning(true);
        },
        [togglePanning],
    );

    const handleSpaceBarKeyUp = useCallback(
        (e) => {
            const isInteractiveElement = e.target.tagName !== 'BODY' && e.target.tagName !== 'CANVAS';

            if (isInteractiveElement || e.keyCode !== 32) {
                return;
            }

            togglePanning(false);
        },
        [togglePanning],
    );

    useEffect(() => {
        if (!manipulationRenderer) {
            return;
        }

        manipulationRenderer.canvasManipulation.emitter.on('requestCanvasPan', panHandler);

        return () => {
            manipulationRenderer.eventEmitter.off('requestCanvasPan', panHandler);
        };
    }, [manipulationRenderer, handleSpaceBarKeyUp, panHandler]);

    useEffect(() => {
        window.addEventListener('keydown', handleSpaceBarKeyDown);
        window.addEventListener('keyup', handleSpaceBarKeyUp);

        return () => {
            window.removeEventListener('keyup', handleSpaceBarKeyUp);
            window.removeEventListener('keydown', handleSpaceBarKeyDown);
        };
    }, [handleSpaceBarKeyUp, handleSpaceBarKeyDown]);
};
