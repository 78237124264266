import { Region } from '../Shared/Region';
import { EffectTypes } from '../../Enums/EffectTypes';
import { ICompElement } from '../CompModels/ICompElement';
import { BaseEffect } from './BaseEffect';

export class BlurEffect extends BaseEffect {
    static type = EffectTypes.BLUR;

    static getRegionOfDefinition(region: Region, compElement: ICompElement) {
        return new Region(
            Number.NEGATIVE_INFINITY,
            Number.NEGATIVE_INFINITY,
            Number.POSITIVE_INFINITY,
            Number.POSITIVE_INFINITY,
        );
    }

    static getRegionOfInterest(region: Region, compElement: ICompElement) {
        if (compElement.blur != 0.0) {
            const blurRadius = compElement.blur * 100 * 2;
            return new Region(
                region.left - blurRadius,
                region.top - blurRadius,
                region.right + blurRadius,
                region.bottom + blurRadius,
            );
        }

        return region;
    }
}
