import { useRef } from 'react';
import { useShortcutsBlocker } from 'packages/hooks/useShortcutsBlocker';

type Props = {
    onDragStart?: (...args: never[]) => void;
    onDrag?: (deltaX: number, deltaY: number, evt: MouseEvent) => void;
    onDragEnd?: (evt: MouseEvent) => void;
    cancelCb?: () => void;
};

export const useDragEvents = ({ onDragStart, onDrag, onDragEnd, cancelCb }: Props) => {
    const { toggleShortcutsBlocker } = useShortcutsBlocker(cancelCb);
    const mouseStartingPos = useRef([0, 0]);

    const onMouseMove = (evt) => {
        const deltaX = evt.pageX - mouseStartingPos.current[0];
        const deltaY = evt.pageY - mouseStartingPos.current[1];

        if (onDrag) {
            onDrag(deltaX, deltaY, evt);
        }
    };

    const onMouseUp = (evt) => {
        document.body.style.userSelect = 'initial';

        if (onDragEnd) {
            onDragEnd(evt);
        }

        toggleShortcutsBlocker(false);
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
    };

    const onMouseDown = (evt, ...args) => {
        evt.stopPropagation();
        document.body.style.userSelect = 'none';

        mouseStartingPos.current[0] = evt.pageX;
        mouseStartingPos.current[1] = evt.pageY;

        if (onDragStart) {
            onDragStart(...args);
        }

        toggleShortcutsBlocker(true);
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    return [onMouseDown, onMouseMove, onMouseUp];
};
