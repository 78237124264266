import React, { useCallback, useMemo } from 'react';
import { ExtendedColorParams } from '@bynder-studio/misc';
import { Form } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { usePalettes } from 'packages/pages/design/hooks/usePalettes';
import { collectConfigureColors, getUnifiedColor } from 'packages/pages/design/components/ShapeElement/utils';
import { flattenTree } from '~/common/editor/helpers/elementtree';
import ShotColor from 'packages/pages/design/sidebar/shots/ShotColor';
import useElement from '../../hooks/useElement';
import useDesign from '../../hooks/useDesign';

const preventSubmit = (event) => event.preventDefault();

const ShapeElement = ({ elementId, disabled }: { elementId: number; disabled: boolean }) => {
    const { translate } = useTranslate();
    const { template, creativeModel } = useDesign();
    const { element, syncContentProperty, updateElement } = useElement(elementId);
    const { byId } = usePalettes();

    const selectedPageIndex = creativeModel.getCurrentPageIndex();

    const fillBrandColors = useMemo(
        () =>
            collectConfigureColors(
                flattenTree(template.pages[selectedPageIndex].elements)[elementId],
                'fillBrandColors',
                'fillColor',
                byId,
            ),
        [template.elements, elementId, byId],
    );

    const borderBrandColors = useMemo(
        () =>
            collectConfigureColors(
                flattenTree(template.pages[selectedPageIndex].elements)[elementId],
                'borderBrandColors',
                'borderColor',
                byId,
            ),
        [template.elements, elementId, byId],
    );

    const handleColorClick = useCallback(
        (propName: 'fillColor' | 'borderColor') => (color: ExtendedColorParams) => {
            const { name, ...colorObj } = color;

            if (element.contentPropertyId) {
                syncContentProperty({ [propName]: colorObj });
            } else {
                updateElement({ [propName]: colorObj });
            }
        },
        [updateElement, syncContentProperty],
    );

    const [selectedFillColor, selectedBorderColor] = useMemo(
        () => [getUnifiedColor(element.fillColor, byId), getUnifiedColor(element.borderColor, byId)],
        [byId, element.fillColor, element.borderColor],
    );

    const [isFillVisible, isBorderVisible] = useMemo(
        () => [fillBrandColors.length > 1, borderBrandColors.length > 1],
        [fillBrandColors.length, borderBrandColors.length],
    );

    return (
        <Form onSubmit={preventSubmit}>
            {isFillVisible && (
                <ShotColor
                    title={translate('editor.sidebar.shots.shape.fill_color')}
                    elementId={elementId}
                    colors={fillBrandColors}
                    handleColorClick={handleColorClick('fillColor')}
                    selectedColor={selectedFillColor}
                    isDisabled={disabled}
                />
            )}
            {isBorderVisible && (
                <ShotColor
                    title={translate('editor.sidebar.shots.shape.border_color')}
                    elementId={elementId}
                    colors={borderBrandColors}
                    handleColorClick={handleColorClick('borderColor')}
                    selectedColor={selectedBorderColor}
                    isDisabled={disabled}
                />
            )}
        </Form>
    );
};

export default ShapeElement;
