import ProxyServerConnector from './connectors/ProxyServerConnector';
import { readCookie } from '../helpers/cookie';
import { createQuery as createQueryGeneric } from '../helpers/queryString';

export function createQuery(params) {
    const {
        customerId,
        ownershipSource,
        subType,
        system,
        status,
        gallery,
        collectionId,
        assignedToCollection,
        creativeType,
    } = params;
    const safeCustomerId = customerId || readCookie('currentCompany');

    return `type=${gallery ? `GALLERY&type=GALLERY_GLOBAL` : `TEMPLATE`}\
${subType ? `&subType=${subType}` : ''}\
&customerId=${safeCustomerId}\
&ownershipSource=${ownershipSource}\
&system=${system}\
${status ? `&status=${status}` : ''}\
${collectionId ? `&collectionId=${collectionId}` : ''}\
${typeof assignedToCollection !== 'undefined' ? `&assignedToCollection=${assignedToCollection}` : ''}\
${creativeType ? `&creativeType=${creativeType}` : ''}\
&${createQueryGeneric(params)}`;
}

class TemplatesService extends ProxyServerConnector {
    constructor(path = '/templates') {
        super(path);
    }

    fetchTemplates(params) {
        const assignedToCollection = !!params.collectionId || (params.search?.length > 0 ? undefined : false);

        const query = createQuery({ ...params, assignedToCollection });
        const path = `?${query}`;

        return this.get(path);
    }

    fetchTemplate(id) {
        return this.get(`/${id}`);
    }

    calculateShots(data) {
        return this.post(`/shots/calculate`, data);
    }

    deleteTemplates(ids) {
        return this.delete('', ids);
    }
}

export default new TemplatesService();
