import React from 'react';
import { Button, Tooltip } from '@bynder/design-system';
import {
    IconDistributeVerticalCenter,
    IconDistributeHorizontally,
    IconDistributeVerticalBottom,
    IconDistributeVertically,
    IconDistributeHorizontalLeft,
    IconDistributeHorizontalRight,
    IconDistributeVerticalTop,
    IconDistributeHorizontalCenter,
} from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { ElementAlign, ElementDistribute } from './types';
import { ElementAlignmentButtonsContainer, ElementAlignmentContainer } from './index.styled';
import { alignFn, distributeFn } from './alignmentDistribution';

const isElementParent = (elements, element) => element.parent && elements.some((el) => el.id === element.parent.id);

const filterParents = (elements) =>
    elements.reduce((acc, element) => {
        if (!isElementParent(elements, element)) {
            return [...acc, element];
        }
        return acc;
    }, []);

const ElementAlignment = ({ creativeModel, selectedElements }) => {
    const { translate } = useTranslate();

    const isSelectionLocked = selectedElements.every((element) => element.locked);

    const selection = filterParents(selectedElements);

    const isDistributionVisible = selection.length > 1;
    const isDistributionActive = selection.length > 2;

    return (
        <ElementAlignmentContainer>
            <ElementAlignmentButtonsContainer>
                <Tooltip content={translate('editor.sidebar.shots.alignment.left')} position="bottom-left">
                    <Button
                        title={translate('editor.sidebar.shots.alignment.left')}
                        variant="clean"
                        icon={<IconDistributeHorizontalLeft />}
                        onClick={() => alignFn(ElementAlign.HORIZONTAL_LEFT, selectedElements, creativeModel)}
                        isDisabled={isSelectionLocked}
                        data-testid="alignment left"
                    />
                </Tooltip>
                <Tooltip
                    content={translate('editor.sidebar.shots.alignment.center.horizontally')}
                    position="bottom-center"
                >
                    <Button
                        title={translate('editor.sidebar.shots.alignment.center.horizontally')}
                        variant="clean"
                        icon={<IconDistributeHorizontalCenter />}
                        onClick={() => alignFn(ElementAlign.HORIZONTAL_CENTER, selectedElements, creativeModel)}
                        isDisabled={isSelectionLocked}
                        data-testid="alignment center horizontally"
                    />
                </Tooltip>
                <Tooltip content={translate('editor.sidebar.shots.alignment.right')} position="bottom-center">
                    <Button
                        title={translate('editor.sidebar.shots.alignment.right')}
                        variant="clean"
                        icon={<IconDistributeHorizontalRight />}
                        onClick={() => alignFn(ElementAlign.HORIZONTAL_RIGHT, selectedElements, creativeModel)}
                        isDisabled={isSelectionLocked}
                        data-testid="alignment right"
                    />
                </Tooltip>
                <Tooltip content={translate('editor.sidebar.shots.alignment.top')} position="bottom-center">
                    <Button
                        title={translate('editor.sidebar.shots.alignment.top')}
                        variant="clean"
                        icon={<IconDistributeVerticalTop />}
                        onClick={() => alignFn(ElementAlign.VERTICAL_TOP, selectedElements, creativeModel)}
                        isDisabled={isSelectionLocked}
                        data-testid="alignment top"
                    />
                </Tooltip>
                <Tooltip
                    content={translate('editor.sidebar.shots.alignment.center.vertically')}
                    position="bottom-center"
                >
                    <Button
                        title={translate('editor.sidebar.shots.alignment.center.vertically')}
                        variant="clean"
                        icon={<IconDistributeVerticalCenter />}
                        onClick={() => alignFn(ElementAlign.VERTICAL_CENTER, selectedElements, creativeModel)}
                        isDisabled={isSelectionLocked}
                        data-testid="alignment center vertically"
                    />
                </Tooltip>
                <Tooltip content={translate('editor.sidebar.shots.alignment.bottom')} position="bottom-right">
                    <Button
                        title={translate('editor.sidebar.shots.alignment.bottom')}
                        variant="clean"
                        icon={<IconDistributeVerticalBottom />}
                        onClick={() => alignFn(ElementAlign.VERTICAL_BOTTOM, selectedElements, creativeModel)}
                        isDisabled={isSelectionLocked}
                        data-testid="alignment bottom"
                    />
                </Tooltip>
            </ElementAlignmentButtonsContainer>
            {isDistributionVisible && (
                <>
                    <ElementAlignmentButtonsContainer>
                        <Tooltip
                            content={translate('editor.sidebar.shots.alignment.distribution.horizontal')}
                            position="bottom-left"
                        >
                            <Button
                                title={translate('editor.sidebar.shots.alignment.distribution.horizontal')}
                                variant="clean"
                                icon={<IconDistributeHorizontally />}
                                onClick={() =>
                                    distributeFn(ElementDistribute.DISTRIBUTE_HORIZONTALLY, selection, creativeModel)
                                }
                                isDisabled={isSelectionLocked || !isDistributionActive}
                                data-testid="distribution horizontal"
                            />
                        </Tooltip>
                        <Tooltip
                            content={translate('editor.sidebar.shots.alignment.distribution.vertical')}
                            position="bottom-center"
                        >
                            <Button
                                title={translate('editor.sidebar.shots.alignment.distribution.vertical')}
                                variant="clean"
                                icon={<IconDistributeVertically />}
                                onClick={() =>
                                    distributeFn(ElementDistribute.DISTRIBUTE_VERTICALLY, selection, creativeModel)
                                }
                                isDisabled={isSelectionLocked || !isDistributionActive}
                                data-testid="distribution vertical"
                            />
                        </Tooltip>
                    </ElementAlignmentButtonsContainer>
                </>
            )}
        </ElementAlignmentContainer>
    );
};

export default ElementAlignment;
