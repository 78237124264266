import React, { createContext, FC, PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import useVariations from 'packages/pages/design/hooks/useVariations';
import useDesign from 'packages/pages/design/hooks/useDesign';
import { applyVariationDataToCreativeModel } from 'packages/pages/design/sidebar/variations/utils';
import { variationsThumbnailsStore } from 'packages/pages/design/VariationsContext/variationsThumbnailsStore';
import { IVariationThumbnailRenderer } from '@bynder-studio/render-core';

export type VariationsThumbnailsProviderProps = PropsWithChildren<{}>;

export type VariationsThumbnailsContextValue = {
    variationThumbnailRenderer: IVariationThumbnailRenderer;
};

export const VariationsThumbnailsContext = createContext({} as VariationsThumbnailsContextValue);

export const VariationsThumbnailsProvider: FC<VariationsThumbnailsProviderProps> = ({ children }) => {
    const { creativeModelFactory, creativeModel } = useDesign();
    const { variations = [] } = useVariations();
    const idKeys = variations
        .map((variation) => variation.id)
        .sort()
        .join('_');
    const variationThumbnailRenderer = useMemo(
        () => creativeModelFactory.getVariationThumbnailRenderer(),
        [creativeModelFactory],
    );
    const pageIndex = creativeModel.getCurrentPageIndex();

    const swapVariationThumbnailModel = useCallback(
        (variation: { oldId: string; id: string }) => {
            const variationIdsSet = variationThumbnailRenderer.getVariationIds();
            const model = creativeModel.getCopy();

            applyVariationDataToCreativeModel(variation, model, { skipGlobalAudio: true });

            variationIdsSet.delete(variation.oldId);
            variationThumbnailRenderer.removeModel(variation.oldId);
            variationThumbnailRenderer.addModel(variation.id, model);
            variationsThumbnailsStore.deleteVariationThumbnails(variation.oldId);
        },
        [variationThumbnailRenderer, creativeModel, variationsThumbnailsStore],
    );

    useEffect(() => {
        variationsThumbnailsStore.setDefaultThumbnailSize(variationThumbnailRenderer.getThumbnailSize());
        const unsubscribes = [
            variationThumbnailRenderer.onRenderStart(variationsThumbnailsStore.variationsThumbnailRenderStart),
            variationThumbnailRenderer.onRenderEnd(variationsThumbnailsStore.variationsThumbnailRenderEnd),
            variationThumbnailRenderer.onPageChanged(variationsThumbnailsStore.pageChanged),
        ];
        return () => {
            unsubscribes.forEach((unsubscribe) => unsubscribe());
        };
    }, [variationThumbnailRenderer]);

    // on variations change
    useEffect(() => {
        const variationIdsSet = variationThumbnailRenderer.getVariationIds();
        variations.forEach((variation) => {
            if (variationIdsSet.has(variation.oldId) || variationIdsSet.has(variation.id)) {
                variationIdsSet.delete(variation.id);
                if (variation.oldId && variationIdsSet.has(variation.oldId)) {
                    variationIdsSet.delete(variation.oldId);
                    variationThumbnailRenderer.swapModel(variation.oldId, variation.id);
                    variationsThumbnailsStore.swapVariationThumbnails(variation.oldId, variation.id);
                }
                return;
            }
            const model = creativeModel.getCopy();
            applyVariationDataToCreativeModel(variation, model, { skipGlobalAudio: true });
            variationThumbnailRenderer.addModel(variation.id, model);
        });
        variationIdsSet.forEach((variationId) => {
            variationThumbnailRenderer.removeModel(variationId);
            variationsThumbnailsStore.deleteVariationThumbnails(variationId);
        });
    }, [variationThumbnailRenderer, creativeModel, idKeys, variations, variations.length]);

    // on page change
    useEffect(() => {
        variationThumbnailRenderer.setCurrentPage(pageIndex);
        variationThumbnailRenderer.getVariationIds().forEach((variationId) => {
            if (variationsThumbnailsStore.isThumbnailsReady(variationId, pageIndex)) {
                return;
            }

            variationThumbnailRenderer.render(variationId, pageIndex);
        });
    }, [variationThumbnailRenderer, pageIndex]);

    const value = useMemo(
        () => ({ variationThumbnailRenderer, swapVariationThumbnailModel }),
        [variationThumbnailRenderer, swapVariationThumbnailModel],
    );

    return <VariationsThumbnailsContext.Provider value={value}>{children}</VariationsThumbnailsContext.Provider>;
};
