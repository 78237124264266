const getVideoType = (transformations, isAlpha) => {
    if (isAlpha) {
        return 'VIDEO_TRANSPARENT_LOW';
    }

    const transformationTypes = transformations.map((transformation) => transformation.type);

    if (transformationTypes.includes('VIDEO_NHD')) {
        return 'VIDEO_NHD';
    }

    if (transformationTypes.includes('VIDEO_QHD')) {
        return 'VIDEO_QHD';
    }

    return 'VIDEO_HD';
};

export const typesMap = new Map([
    [
        'video',
        {
            name: 'VIDEO',
            makeObj: ({
                fileName,
                thumbnails,
                fileUrl,
                id,
                transformations,
                mimeType,
                width,
                height,
                isAlpha,
                duration,
            }) => {
                const type = getVideoType(transformations, isAlpha);
                const getUrl = (videoType) => videoType?.url || fileUrl;

                const prewievTransformation =
                    (transformations && transformations.find((t) => t.type === 'VIDEO_HD')) || {};

                return {
                    name: fileName,
                    src: thumbnails['1920x1920'],
                    id,
                    url: getUrl(transformations.find((t) => t.type === 'VIDEO_HD')),
                    mimeType,
                    width: prewievTransformation?.width || width || 1920,
                    height: prewievTransformation?.height || height || 1920,
                    isAlpha,
                    videoPreviewUrl: getUrl(transformations.find((t) => t.type === type)),
                    duration,
                };
            },
        },
    ],
    [
        'image',
        {
            name: 'IMAGE',
            makeObj: ({ fileName, thumbnails, externalPreviewUrl, id, width, height, transformations }) => {
                const prewievTransformation =
                    (transformations && transformations.find((t) => t.type === 'IMAGE_NORMALIZED')) || {};

                return {
                    id,
                    name: fileName,
                    src: thumbnails['1920x1920'] || externalPreviewUrl,
                    width: prewievTransformation?.width || width || 1920,
                    height: prewievTransformation?.height || height || 1920,
                };
            },
        },
    ],
    [
        'audio',
        {
            name: 'AUDIO',
            makeObj: ({ fileName, id, transformations, mimeType, fileUrl, duration }) => {
                const getUrl = (audioType) => audioType?.url || fileUrl;

                return {
                    id,
                    name: fileName,
                    source: id,
                    url: getUrl(transformations.find((t) => t.browserFriendly)),
                    mimeType,
                    duration,
                };
            },
        },
    ],
]);
