import React, { useCallback, useEffect, useRef, useState } from 'react';
import { notify } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconClear } from '@bynder/icons';
import { isCtrlKey } from '~/helpers/hotKeys';
import { MAX_ELEMENT_NAME_LENGTH } from '~/common/editor/helpers';
import GenerateIcon from '../GenerateIcon';
import { LayerItemContainer } from '../LayerItem.styled';
import { EditableLayerItemStyled } from './EditableLayerItem.styled';

const EditableLayerItem = ({
    groupClassName,
    setEnableEditor,
    isGroup,
    element,
    name = '',
    handleElementNameChange,
}) => {
    const [value, setValue] = useState(name);
    const inputRef = useRef(null);
    const editableContainerRef = useRef(null);
    const { translate } = useTranslate();

    const handleKeyUp = useCallback(
        (evt) => {
            if (evt.shiftKey || isCtrlKey(evt)) {
                return;
            }

            if (evt.key === 'Enter') {
                handleElementNameChange(value);
            } else if (evt.key === 'Escape') {
                setEnableEditor(false);
            }
        },
        [handleElementNameChange, value, setEnableEditor],
    );

    const handleEditorBlur = useCallback(() => {
        if (value && value !== name) {
            setEnableEditor(false);
            handleElementNameChange(value);

            return;
        }

        setEnableEditor(false);
    }, [handleElementNameChange, value]);

    const handleOnChange = useCallback((evt) => {
        setValue(evt.target.value);

        if (evt.target.value.length >= MAX_ELEMENT_NAME_LENGTH) {
            notify({
                id: 'elementNameEditingError',
                title: translate('editor.sidebar.element.validation.max-length.text', { MAX_ELEMENT_NAME_LENGTH }),
                variant: 'error',
            });
        }
    }, []);

    const handleDeleteBtn = useCallback(() => {
        setValue('');
    }, []);

    const handleOutsideClick = useCallback(
        (e) => {
            if (!editableContainerRef.current || editableContainerRef.current.contains(e.target)) {
                return;
            }

            handleEditorBlur();
        },
        [handleEditorBlur],
    );

    useEffect(() => {
        window?.addEventListener('click', handleOutsideClick);

        return () => {
            window?.removeEventListener('click', handleOutsideClick);
        };
    }, [handleOutsideClick]);

    useEffect(() => {
        if (!editableContainerRef.current) {
            return;
        }

        const input = editableContainerRef.current.querySelector('input');
        input?.focus();
    }, []);

    const propagationStopper = useCallback((e) => e.stopPropagation(), []);

    return (
        <EditableLayerItemStyled className="layers--item-wrapper" onMouseDown={propagationStopper}>
            <LayerItemContainer
                ref={editableContainerRef}
                onKeyUp={handleKeyUp}
                className={`layers--item-input-container ${groupClassName || ''} d-flex align-items-center`}
            >
                {isGroup && (
                    <button type="button" className="layers--item-group-expand btn p-0 border-0">
                        <span className="triangle--down" />
                    </button>
                )}
                <GenerateIcon element={element} />
                <div className="layers--item-content">
                    <input
                        ref={inputRef}
                        type="text"
                        onChange={handleOnChange}
                        value={value}
                        className={`layers--item-content-input ${isGroup ? 'pr-4' : ''}`}
                        maxLength={MAX_ELEMENT_NAME_LENGTH}
                    />
                </div>
                <button type="button" className="layers--item-input-clear-btn btn p-0 m-0" onClick={handleDeleteBtn}>
                    <IconClear />
                </button>
            </LayerItemContainer>
        </EditableLayerItemStyled>
    );
};

export default EditableLayerItem;
