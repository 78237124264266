import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconAdd, IconUpload } from '@bynder/icons';
import { Button } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { getUploadOptions } from '~/store/assets/assets.actions';
import useUploader from './useUploader';
import { StyledLabel } from './Component.styled';
import { ACCEPT, AssetType, UploadService, UploaderState } from './types';

type Props = {
    collectionId?: number;
    assetTypes?: AssetType[];
    iconType: 'add' | 'upload';
    disabled?: boolean;
    children?: React.ReactNode;
    buttonProps?: React.ComponentProps<typeof Button>;
    inputProps?: React.HTMLProps<HTMLInputElement>;
    uploadService?: UploadService;
    hideNotification?: boolean;
    onChange?: (file: File) => void;
};

const selector = (state: UploaderState) => state.status !== 'ready';
const uploadOptionsSelector = (state: any): any => state.assets.uploadOptions;

const UploadButton = forwardRef(
    (
        {
            disabled = false,
            iconType,
            assetTypes = ['all'],
            collectionId,
            children,
            buttonProps,
            inputProps,
            uploadService,
            hideNotification,
            onChange,
        }: Props,
        ref?,
    ) => {
        const { translate } = useTranslate();
        const inputRef = useRef<HTMLInputElement>(null);
        const { upload, state: isLoading } = useUploader({ selector });
        const uploadOptions = useSelector(uploadOptionsSelector);
        const dispatch = useDispatch();

        useEffect(() => {
            if (!uploadOptions) {
                dispatch(getUploadOptions());
            }
        }, []);

        useImperativeHandle(
            ref,
            () => {
                return {
                    onClick() {
                        inputRef.current?.click();
                    },
                };
            },
            [],
        );

        const isDisabled = isLoading || disabled;

        const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (isDisabled) {
                return;
            }

            const file = event.target.files?.[0];

            if (!file) {
                return;
            }

            upload(file, assetTypes, collectionId, uploadService, hideNotification);
            inputRef.current!.value = '';
            onChange && onChange(file);
        };

        const label = translate(isLoading ? 'pages.uploader.button.uploading' : 'pages.uploader.button.upload');
        const icon = !isLoading && (iconType === 'upload' ? <IconUpload /> : <IconAdd />);

        if (!uploadOptions) {
            return null;
        }

        return (
            <StyledLabel>
                <Button
                    variant="primary"
                    isLoading={isLoading}
                    isDisabled={isDisabled}
                    onClick={() => inputRef.current?.click()}
                    rightIcon={iconType === 'add' && icon}
                    icon={iconType === 'upload' && icon}
                    {...(buttonProps || {})}
                >
                    {children || label}
                </Button>
                <input
                    ref={inputRef}
                    hidden
                    disabled={isDisabled}
                    type="file"
                    onChange={handleUpload}
                    accept={assetTypes.map((assetType) => ACCEPT[assetType]).join(', ')}
                    {...(inputProps || {})}
                />
            </StyledLabel>
        );
    },
);

export default UploadButton;
