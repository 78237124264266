import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from '@bynder/localization';
import { Button, Divider, Dropdown, token, Tooltip } from '@bynder/design-system';
import { IconCancel, IconCheck, IconError } from '@bynder/icons';
import {
    getImportTemplateAnalysis,
    getImportTemplateErrors,
    getImportTemplateValidationErrors,
} from 'packages/store/importTemplate/importTemplate.selectors';
import { DesignFileAnalysisGlobal, DesignFileAnalysisLocal, errorKeys } from 'packages/store/importTemplate/types';
import { formatBytes } from 'packages/pages/utils';
import { setImportDesignMode } from 'packages/store/importTemplate/importTemplate.actions';
import useAccessRights from 'packages/hooks/useAccessRights';
import features from '~/configs/features';
import {
    ImportContainer,
    ImportInner,
    IconContainer,
    Title,
    FileImportColumnTitle,
    FileImportListItem,
    FileImportListItemTitle,
    StyledTableTitle,
    StyledTableCell,
    StyledIcon,
    DropdownContainer,
} from './Import.styled';

const ImportStatusDesignFile = () => {
    const [multiDesign, setMultidesign] = useState(true);
    const { translate } = useTranslate();
    const analysis = useSelector(getImportTemplateAnalysis);
    const errors = useSelector(getImportTemplateErrors);
    const validationErrors = useSelector(getImportTemplateValidationErrors);
    const dispatch = useDispatch();
    const { isPlatformAllowed } = useAccessRights();

    const isMultiArtboard = analysis.designCreationResponses.length > 1;

    const getFormattedDimensions = (item: { width: number; height: number }) => `${item.width} × ${item.height} px`;

    const getErrorIcon = (error: string) => (
        <Tooltip content={error}>
            <IconError style={{ color: token.red500 }} size="24" />
        </Tooltip>
    );

    const getTemplateValue = () => {
        if ('extensions' in analysis) {
            const file: DesignFileAnalysisGlobal = analysis;

            return { name: file.name, extension: file.extensions?.[0] };
        }

        const file: DesignFileAnalysisLocal = analysis;
        let name = file.fileName;
        const extFromName = file.fileName.split('.').pop();
        const lastDotIdx = file.fileName.lastIndexOf('.');

        if (lastDotIdx !== -1 && extFromName === file.extension) {
            name = file.fileName.slice(0, lastDotIdx);
        }

        return { name, extension: file.extension };
    };

    const { name, extension } = getTemplateValue();

    const templateName = {
        value: (analysis && `${name}.${extension}`) || (errors && errors.name),
        error: () => {
            if (validationErrors.includes(errorKeys.EMPTY_NAME)) {
                return translate('modal.design.create.design.import.file.empty_name');
            }

            return '';
        },
    };

    const generalDetails = [
        {
            title: translate('modal.design.create.design.import.file.type'),
            value: translate('modal.design.create.design.import.file.type.image'),
            error: () => {},
        },
        {
            title: translate('modal.design.create.design.import.file.file_size'),
            value: (analysis && formatBytes(analysis.fileSize)) || '0 Bytes',
            error: () => {},
        },
        {
            title: !isMultiArtboard ? translate('modal.design.create.design.import.file.dimensions') : null,
            value:
                analysis &&
                analysis.designCreationResponses &&
                analysis.designCreationResponses.length &&
                !isMultiArtboard
                    ? getFormattedDimensions(analysis.designCreationResponses[0])
                    : null,
            error: () => {
                const templateErrors = validationErrors && validationErrors.filter((error) => !error.artboardIndex);

                if (templateErrors && templateErrors.length) {
                    if (typeof templateErrors[0] === 'string') {
                        return '';
                    }

                    if (templateErrors[0].some(({ error }) => error && error.includes(errorKeys.MIN_SIZE))) {
                        return translate('modal.design.create.design.import.file.min_size');
                    }

                    if (templateErrors[0].some(({ error }) => error && error.includes(errorKeys.MIN_RESOLUTION))) {
                        return translate('modal.design.create.design.import.file.min_resolution');
                    }

                    if (templateErrors[0].some(({ error }) => error && error.includes(errorKeys.MAX_RESOLUTION))) {
                        return translate('modal.design.create.design.import.file.max_resolution');
                    }
                }

                return '';
            },
        },
    ];

    const artboardsDetails = () =>
        analysis &&
        analysis.designCreationResponses.reduce(
            (acc: { title: string; value: string; error: Function }[], item, index) => {
                const { name } = item;
                acc.push({
                    title: name,
                    value: getFormattedDimensions(item),
                    error: () => {
                        const artboardErrors = validationErrors.filter((error) => error[0].artboardIndex === index);

                        const errorArray: string[] = [];

                        if (artboardErrors.length > 0) {
                            if (artboardErrors[0].some(({ error }) => error && error === errorKeys.MIN_SIZE))
                                errorArray.push(translate('modal.design.create.design.import.file.min_size'));
                            if (artboardErrors[0].some(({ error }) => error && error === errorKeys.MIN_RESOLUTION))
                                errorArray.push(translate('modal.design.create.design.import.file.min_resolution'));
                            if (artboardErrors[0].some(({ error }) => error && error === errorKeys.MAX_RESOLUTION))
                                errorArray.push(translate('modal.design.create.design.import.file.max_resolution'));
                        }

                        return errorArray;
                    },
                });
                return acc;
            },
            [],
        );

    const generateDetailsRow = (data) =>
        data
            .map(({ title, value, error }) => {
                return title && (value || error()) ? (
                    <tr key={title}>
                        <StyledTableTitle>
                            <FileImportListItemTitle>{title}</FileImportListItemTitle>
                        </StyledTableTitle>
                        <StyledTableCell>
                            {error() && error().length ? (
                                <FileImportListItem
                                    thumbnail={getErrorIcon(Array.isArray(error()) ? error().join('. ') : error())}
                                >
                                    {value}
                                </FileImportListItem>
                            ) : (
                                <FileImportListItem>{value}</FileImportListItem>
                            )}
                        </StyledTableCell>
                    </tr>
                ) : null;
            })
            .filter((item) => item);

    const generalRows = generateDetailsRow(generalDetails);
    const artboardRows = generateDetailsRow([...artboardsDetails()]);

    const onImportModeSelect = (isMultiDesign) => {
        setMultidesign(isMultiDesign);

        dispatch(setImportDesignMode(isMultiDesign));
    };

    return (
        <ImportContainer id="new_design_modal_import_status_design" data-testid="Import status container">
            <ImportInner>
                <IconContainer data-testid="Import status icon" importStatus={!errors && !validationErrors.length}>
                    {!errors && !validationErrors.length ? <IconCheck /> : <IconCancel />}
                </IconContainer>
                <Title id="new_design_modal_import_status_design_title" data-testid="Import status title">
                    {templateName.value}
                    {templateName.error() && <StyledIcon>{getErrorIcon(templateName.error())}</StyledIcon>}
                </Title>
                {generalRows.length ? (
                    <>
                        <Divider />
                        <table data-testid="Import general details">
                            <thead>
                                <th colSpan={2}>
                                    <FileImportColumnTitle>
                                        {translate('modal.design.create.design.import.file.details')}
                                    </FileImportColumnTitle>
                                </th>
                            </thead>
                            <tbody>{generalRows}</tbody>
                        </table>
                    </>
                ) : null}
                {artboardRows.length && analysis.designCreationResponses.length > 1 ? (
                    <>
                        <Divider />
                        <table data-testid="Import artboards details">
                            <tbody>
                                <th colSpan={2}>
                                    <FileImportColumnTitle data-testid="Import artboard title">
                                        {translate('modal.design.create.design.import.file.artboards')}
                                    </FileImportColumnTitle>
                                </th>
                                {artboardRows}
                            </tbody>
                        </table>
                    </>
                ) : null}
                {isPlatformAllowed([features.MULTI_SIZE_CREATIVE]) &&
                artboardRows.length &&
                analysis.designCreationResponses.length > 1 ? (
                    <DropdownContainer>
                        <Dropdown
                            trigger={({ isOpen, ...triggerProps }) => (
                                <Button
                                    aria-label={translate('modal.design.create.design.import.multidesign')}
                                    title={translate('modal.design.create.design.import.multidesign')}
                                    data-testid="design create : multidesign button"
                                    rightIcon={<Dropdown.Arrow />}
                                    {...triggerProps}
                                >
                                    {!multiDesign
                                        ? translate('modal.design.create.design.import.pages')
                                        : translate('modal.design.create.design.import.designs')}
                                </Button>
                            )}
                        >
                            <Dropdown.Item onClick={() => onImportModeSelect(false)} isChecked={!multiDesign}>
                                {translate('modal.design.create.design.import.pages')}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => onImportModeSelect(true)} isChecked={multiDesign}>
                                {translate('modal.design.create.design.import.designs')}
                            </Dropdown.Item>
                        </Dropdown>
                    </DropdownContainer>
                ) : null}
            </ImportInner>
        </ImportContainer>
    );
};

export default ImportStatusDesignFile;
