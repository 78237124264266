import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Modal, Thumbnail } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconFolder, IconFolderOpen } from '@bynder/icons';
import { getCollection, getCollections } from 'packages/store/collections/collections.selectors';
import { customerIdSelector } from 'packages/store/user/user.selectors';
import { getCreativesActionProgress, getCreativesSelection } from 'packages/store/creatives/creatives.selectors';
import { Creative } from 'packages/store/creatives/types';
import { Collection, CollectionSubType, CollectionType } from 'packages/store/collections/types';
import useAccessRights from 'packages/hooks/useAccessRights';
import { ActionStatus, DesignAction, Entity } from 'packages/hooks/useAccessRights/types';
import { handleDeselectAll } from '~/store/creatives/creatives.actions';
import debounce from '~/helpers/debounce';
import { fetchCollection, fetchCollections, init, moveToCollection } from '~/store/collections/collections.actions';
import features from '~/configs/features';
import roles from '~/configs/roles';
import { OwnershipFilterType } from '../components/filters/components/Ownership/types';
import AccessNotificationModal from './accessNotificationModal';
import { hasPreviewImageURL, isProcessingDone } from '../components/thumbnail/utils';
import modalContainer from 'packages/common/modalContainer';

const useDesignMoveModal = () => {
    const currentCompany = useSelector(customerIdSelector);
    const [searchValue, setSearchValue] = useState('');
    const [moveModalDesign, setMoveModalDesign] = useState<Creative[] | null>(null);
    const { fetchOptions, collections: projects, isFetching, isCollectionFetching } = useSelector(getCollections);

    const project = useSelector(getCollection);
    const { isEntityActionAllowed, isPlatformAllowed, isAccountRoleAssigned } = useAccessRights();
    const { addingInProgress } = useSelector(getCreativesActionProgress);
    const { selectedCreatives } = useSelector(getCreativesSelection);

    const [selectedProject, setSelectedProject] = useState<Collection | null>(project || null);

    const [accessNotificationModal, setAccessNotificationModal] = useState<number[] | undefined>(undefined);

    const isMovingAllowed = (item: Creative) => {
        const status = isEntityActionAllowed(DesignAction.MOVE, Entity.DESIGN, item.grantedPermissions);

        return status !== ActionStatus.NOT_ALLOWED && status !== ActionStatus.DISABLED;
    };

    const getAllowedToMoveDesignIds = moveModalDesign?.reduce((acc: number[], design: Creative) => {
        if (isMovingAllowed(design)) {
            acc.push(design.id);
        }

        return acc;
    }, []);

    const { translate } = useTranslate();
    const dispatch = useDispatch();

    const type: CollectionType = 'CREATIVE';
    const subType: CollectionSubType = 'REGULAR';
    const isTrialAccount =
        isPlatformAllowed([features.TRIAL_ENABLED]) && isAccountRoleAssigned(roles.video_brand_studio.video.manage);

    const projectSearchArgs = {
        sort: ['NAME', 'ASC'],
        type,
        subType,
        size: 20,
        ownershipSource: isTrialAccount ? OwnershipFilterType.OWNED_BY_ME : OwnershipFilterType.OWNED_BY_ANYONE,
    };

    const showModal = moveModalDesign !== null;

    const onSearch = useCallback(
        debounce(
            (newSearchValue: string) => {
                const newOptions = { ...fetchOptions, ...projectSearchArgs };
                newOptions.search = newSearchValue;

                dispatch(fetchCollections({ ...newOptions }, currentCompany, true));
            },
            1000,
            false,
        ),
        [currentCompany],
    );

    const handleSearchValueChange = (newValue) => {
        setSearchValue(newValue);
        onSearch(newValue);
    };

    const onModalClose = () => {
        setMoveModalDesign(null);
        setSearchValue('');
    };

    const onMoveDesign = React.useCallback(() => {
        if (!getAllowedToMoveDesignIds?.length) {
            setAccessNotificationModal(getAllowedToMoveDesignIds);
        } else {
            if (selectedCreatives.length && getAllowedToMoveDesignIds?.length !== selectedCreatives.length) {
                setAccessNotificationModal(getAllowedToMoveDesignIds);
            }

            dispatch(moveToCollection(selectedProject?.id, getAllowedToMoveDesignIds, 'CREATIVE'));
        }

        dispatch(handleDeselectAll());
        setMoveModalDesign(null);
    }, [getAllowedToMoveDesignIds, selectedProject]);

    const getActiveProject = () => {
        if (moveModalDesign?.length === 1 && moveModalDesign[0]?.projectId) {
            dispatch(fetchCollection(moveModalDesign[0]?.projectId, true));
        } else {
            setSelectedProject(null);
        }
    };

    const getActiveProjectName = useCallback(() => {
        if (selectedProject && !isCollectionFetching) {
            return selectedProject?.name;
        }

        return translate('modal.design.move.none');
    }, [isCollectionFetching, selectedProject]);

    const getProjectThumbnail = (item: Collection) => {
        if (item.coverImageUrl) {
            return (
                <Thumbnail
                    size="xs"
                    variant="content"
                    imageUrl={item.coverImageUrl}
                    backgroundColor={`#${item.coverBgColor}`}
                />
            );
        }

        if (item.coverBgColor) {
            return <Thumbnail size="xs" variant="content" backgroundColor={`#${item.coverBgColor}`} />;
        }

        if (hasPreviewImageURL(item) && isProcessingDone(item)) {
            return (
                <Thumbnail
                    size="xs"
                    variant="content"
                    imageUrl={item?.previewItems[item?.previewItems?.length - 1].previewUrl}
                />
            );
        }

        return (
            <Thumbnail size="xs" variant="clean" icon={<IconFolder />}>
                {!isProcessingDone(item) && <Thumbnail.Overlay isLoading={!isProcessingDone(item)} />}
            </Thumbnail>
        );
    };

    const getSelectedProjectThumbnail = useCallback(() => {
        if (isCollectionFetching) {
            return <Thumbnail size="xs" variant="clean" icon={<IconFolderOpen />} backgroundColor="transparent" />;
        }

        if (selectedProject) {
            if (selectedProject.coverImageUrl) {
                return (
                    <Thumbnail
                        size="xs"
                        variant="content"
                        imageUrl={selectedProject.coverImageUrl}
                        backgroundColor={`#${selectedProject.coverBgColor}`}
                    />
                );
            }

            if (selectedProject.coverBgColor) {
                return <Thumbnail size="xs" variant="content" backgroundColor={`#${selectedProject.coverBgColor}`} />;
            }

            if (hasPreviewImageURL(selectedProject) && isProcessingDone(selectedProject)) {
                return (
                    <Thumbnail
                        size="xs"
                        variant="content"
                        imageUrl={selectedProject.previewItems[selectedProject.previewItems.length - 1].previewUrl}
                    />
                );
            }
        }

        return (
            <Thumbnail size="xs" variant="clean" icon={<IconFolderOpen />}>
                {!isProcessingDone(selectedProject) && (
                    <Thumbnail.Overlay isLoading={!isProcessingDone(selectedProject)} />
                )}
            </Thumbnail>
        );
    }, [isCollectionFetching, selectedProject]);

    useEffect(() => {
        if (showModal && moveModalDesign?.length === 1 && selectedProject?.id !== moveModalDesign?.[0].id) {
            getActiveProject();
        } else {
            setSelectedProject(null);
        }
    }, [showModal]);

    useEffect(() => {
        if (project) {
            setSelectedProject(project);
        }
    }, [project]);

    useLayoutEffect(() => {
        if (showModal) {
            setSearchValue('');
            dispatch(init(projectSearchArgs));
        }
    }, [showModal]);

    const renderModalDesignMove = () => (
        <>
            {!accessNotificationModal ? (
                <Modal
                    container={modalContainer}
                    title={translate('modal.design.move.title')}
                    isOpen={showModal}
                    onClose={onModalClose}
                    actionPrimary={
                        <Button
                            variant="primary"
                            onClick={onMoveDesign}
                            isDisabled={isCollectionFetching || !selectedProject}
                            isLoading={addingInProgress}
                            title={translate('modal.design.move.move')}
                        >
                            {translate('modal.design.move.move')}
                        </Button>
                    }
                    actionSecondary={
                        <Button variant="secondary" onClick={onModalClose} title={translate('modal.cancel.title')}>
                            {translate('modal.cancel.title')}
                        </Button>
                    }
                >
                    <Dropdown
                        position="bottom"
                        trigger={({ isOpen, ...triggerProps }) => (
                            <Button
                                isFullWidth
                                isPressed={isOpen}
                                isDisabled={isCollectionFetching}
                                rightIcon={<Dropdown.Arrow />}
                                icon={getSelectedProjectThumbnail()}
                                {...triggerProps}
                            >
                                <Dropdown.SelectButtonText>{getActiveProjectName()}</Dropdown.SelectButtonText>
                            </Button>
                        )}
                    >
                        <Dropdown.SearchInput
                            value={searchValue}
                            onChange={handleSearchValueChange}
                            placeholder={translate('modal.design.move.search.project')}
                            aria-label={translate('modal.design.move.search.project')}
                        />
                        <Dropdown.SectionTitle> </Dropdown.SectionTitle>
                        {isFetching && (
                            <Dropdown.Item
                                onClick={() => null}
                                isDisabled
                                icon={<Button isLoading variant="clean" aria-label="Loading..." />}
                            >
                                <Dropdown.SelectButtonText>
                                    {translate('modal.design.move.fetch.projects')}
                                </Dropdown.SelectButtonText>
                            </Dropdown.Item>
                        )}

                        {projects?.length === 0 && !isFetching && (
                            <Dropdown.Text>{translate('modal.design.move.results.none')}</Dropdown.Text>
                        )}

                        {projects?.length > 0 ? (
                            <>
                                {projects.map((singleProject) => (
                                    <Dropdown.Item
                                        onClick={() => {
                                            setSelectedProject((prevSelected) =>
                                                prevSelected === singleProject ? null : singleProject,
                                            );
                                        }}
                                        key={singleProject.id}
                                        isChecked={singleProject.name === selectedProject?.name}
                                        role="menuitemradio"
                                        icon={getProjectThumbnail(singleProject)}
                                    >
                                        {singleProject.name}
                                    </Dropdown.Item>
                                ))}
                            </>
                        ) : null}
                    </Dropdown>
                </Modal>
            ) : (
                <AccessNotificationModal
                    title={translate('modal.design.move.title')}
                    description={
                        !accessNotificationModal?.length
                            ? translate('modal.design.access.not-allowed')
                            : translate('modal.design.access.not-all', { action: 'move' })
                    }
                    loading={addingInProgress}
                    isOpen={accessNotificationModal}
                    toggleOpen={() => setAccessNotificationModal(undefined)}
                />
            )}
        </>
    );

    return {
        setMoveModalDesign,
        renderModalDesignMove,
    };
};

export default useDesignMoveModal;
