import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Dropdown, Flex } from '@bynder/design-system';
import useQueryParams from 'packages/hooks/useQueryParams';
import filtersStorage from 'packages/hooks/filtersStorage';
import useDesign from 'packages/pages/design/hooks/useDesign';
import { catchResponseError } from 'packages/helpers/helpers';
import Loader from '~/common/loader/Loader';
import { COMPLETED } from '~/helpers/textConstants';
import CreativesService from '~/services/CreativesService';
import ExportService from '~/services/ExportService';
import { sendAmplitudeDesignEvent } from '~/store/amplitude/actions';
import { getAmplitudeDataFromExportJob } from '~/store/amplitude/utils';
import { AMPLITUDE_TYPES } from '~/store/amplitude/constants';

import { ExportTypes, Tabs } from '../enums';
import { ExportJobsContainer, ExportSectionTitle, ExportsItemContainer } from './AllExports.styled';
import ExportTab from './ExportTab/ExportTab';
import FailedJobBlock from './FailedJobBlock';
import CompletedJobBlock from './CompletedJobBlock';
import InProgressJobBlock from './InProgressJobBlock';

import { isJobCompleted, isJobFailed, upperFirst } from '../utils';
import useExport from 'packages/variation-export/hooks/useExport';

const inProgressJobsStatus = ['PREPARING', 'CREATING', 'UPLOADING'];
const completedJobsStatus = ['COMPLETED', 'FAILED', 'UPLOADING_FAILED'];

export const filterTypes = [
    {
        title: 'Any type',
        exportJobType: '',
    },
    {
        title: 'Waiting Room',
        exportJobType: ExportTypes[Tabs.WaitingRoom],
    },
    {
        title: upperFirst(Tabs.Download),
        exportJobType: ExportTypes[Tabs.Download],
    },
];

const filterStatus = [
    {
        title: 'Any status',
        exportJobVariationStatus: '',
    },
    {
        title: COMPLETED,
        exportJobVariationStatus: completedJobsStatus,
    },
    {
        title: 'In progress',
        exportJobVariationStatus: inProgressJobsStatus,
    },
];

const AllExports = ({ setIsJobsInProgress, visibleTabs }) => {
    const dispatch = useDispatch();
    const {
        getQueryParam,
        getQueryParamArray,
        setQueryParam,
        resetQueryParams,
        getAllQueryParams,
        restoreQueryParams,
    } = useQueryParams();
    const queriedChannel = getQueryParam('channel');
    const queriedStatus = getQueryParamArray('status');

    const { getFiltersFromStorage, setFiltersToStorage } = filtersStorage();

    const { creativeId } = useDesign();
    const [completedJobs, setCompletedJobs] = useState([]);
    const [inProgressJobs, setInProgressJobs] = useState([]);
    const [failedJobs, setFailedJobs] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState(
        filterTypes.find((item) => item.exportJobType === queriedChannel) || filterTypes[0],
    );
    const [selectedStatus, setSelectedStatus] = useState(
        filterStatus.find((item) => item.exportJobVariationStatus.includes(queriedStatus[0])) || filterStatus[0],
    );
    const { selectedJob, setSelectedJob, fetchVariations } = useExport();
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    const getExports = useCallback(
        (payload) => {
            CreativesService.getExportJobs(creativeId, payload)
                .then((res) => {
                    if (res.status === 200) {
                        const { items } = res.json;
                        unstable_batchedUpdates(() => {
                            setCompletedJobs((items || []).filter((job) => isJobCompleted(job)));
                            setInProgressJobs((items || []).filter((job) => !isJobCompleted(job) && !isJobFailed(job)));
                            setFailedJobs((items || []).filter((job) => isJobFailed(job)));
                            setLoading(false);
                        });
                    }
                })
                .catch(catchResponseError);
        },
        [creativeId],
    );

    const handleJobStart = useCallback((job) => {
        setInProgressJobs((items) => {
            if (items.some((item) => item.id === job.id)) {
                return items;
            }
            return [...items, job];
        });
    }, []);

    const handleJobComplete = useCallback((job) => {
        setInProgressJobs((items) => {
            const completedJob = items.find((item) => item.id === job.id);

            if (completedJob) {
                setCompletedJobs((items2) => [completedJob, ...items2.filter((item) => item.id !== completedJob.id)]);
            }

            return items.filter((item) => item.id !== job.id);
        });
    }, []);

    const handleFailedJobs = useCallback((job) => {
        setInProgressJobs((items) => {
            const failedJob = items.find((item) => item.id === job.id);

            if (failedJob) {
                setFailedJobs((items2) => [failedJob, ...items2.filter((item) => item.id !== failedJob.id)]);
            }

            return items.filter((item) => item.id !== job.id);
        });
    }, []);

    const deleteJob = async (job) => {
        dispatch(
            sendAmplitudeDesignEvent({
                eventType: AMPLITUDE_TYPES.DELETE_EXPORT,
                additionalProps: getAmplitudeDataFromExportJob(job),
            }),
        );

        try {
            const exportJobId = job.id;
            const deleteExport = await CreativesService.deleteExportJobs(creativeId, { exportJobId });

            if (deleteExport?.status === 200) {
                setCompletedJobs((jobs) => jobs.filter((job) => job.id !== exportJobId));
            }
        } catch (error) {
            return error;
        }
    };

    const deleteFailedJob = async (exportJobId) => {
        try {
            const deleteExport = await ExportService.deleteFailedExportJob(exportJobId);
            if (deleteExport?.status !== 200) return;

            setFailedJobs((jobs) => jobs.filter(({ id }) => id !== exportJobId));
        } catch (error) {
            return error;
        }
    };

    const handleJobTypeClick = (channel) => {
        const findChannel = filterTypes.find((ch) => ch.title === channel.title);

        if (!findChannel) return;

        const { exportJobType } = findChannel;
        const { exportJobVariationStatus } = selectedStatus;

        setSelectedChannel(findChannel);
        setQueryParam('channel', findChannel.exportJobType);

        const payload = {
            creativeId,
            page: 0,
            size: 20,
            exportJobType,
            exportJobVariationStatus,
        };

        getExports(payload);
    };

    const handleJobStatusClick = (channel) => {
        const findChannel = filterStatus.find((ch) => ch.title === channel.title);

        if (!findChannel && !findChannel?.exportJobVariationStatus) return;

        const { exportJobVariationStatus } = findChannel;
        const { exportJobType } = selectedChannel;

        setSelectedStatus(findChannel);
        setQueryParam('status', findChannel.exportJobVariationStatus);

        const payload = {
            creativeId,
            page: 0,
            size: 20,
            exportJobType,
            exportJobVariationStatus,
        };

        getExports(payload);
    };

    useLayoutEffect(() => {
        const storedQueries = getFiltersFromStorage('all_exports');
        restoreQueryParams(storedQueries);

        const queriedChannelInit = getQueryParam('channel');
        const queriedStatusInit = getQueryParamArray('status');

        if (queriedChannelInit)
            setSelectedChannel(filterTypes.find((item) => item.exportJobType === queriedChannelInit) || filterTypes[0]);
        if (queriedStatusInit)
            setSelectedStatus(
                filterStatus.find((item) => item.exportJobVariationStatus.includes(queriedStatusInit[0])) ||
                    filterStatus[0],
            );

        const payload = {
            creativeId,
            page: 0,
            size: 20,
            exportJobType: queriedChannelInit || '',
            exportJobVariationStatus: queriedStatusInit || '',
        };

        getExports(payload);
    }, []);

    useEffect(() => {
        if (fetchVariations) {
            const queriedChannel = getQueryParam('channel');
            const queriedStatus = getQueryParamArray('status');

            const payload = {
                creativeId,
                page: 0,
                size: 20,
                exportJobType: queriedChannel || '',
                exportJobVariationStatus: queriedStatus || '',
            };

            getExports(payload);
        }
    }, [fetchVariations]);

    useEffect(() => () => resetQueryParams(['channel', 'status']), []);

    useEffect(() => {
        resetQueryParams(['groupBy']);
    }, []);

    useEffect(() => {
        if (!!inProgressJobs.length) {
            setIsJobsInProgress(true);
        } else {
            setIsJobsInProgress(false);
        }
    }, [inProgressJobs]);

    useEffect(() => {
        if (location.search.includes('tab=all_exports') && location.search.includes('export=true')) {
            setFiltersToStorage('all_exports', getAllQueryParams());
        }
    }, [getAllQueryParams()]);

    const visibleFilterTypes = useMemo(
        () => filterTypes.filter(({ exportJobType }) => !exportJobType || visibleTabs[exportJobType]),
        [visibleTabs],
    );

    if (selectedJob) {
        return <ExportTab job={selectedJob} onBack={() => setSelectedJob(null)} />;
    }

    return (
        <>
            {loading ? (
                <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                    <Loader size={50} />
                </div>
            ) : (
                <div id="export_modal_all">
                    <ExportsItemContainer className="export--filters">
                        <Flex>
                            <Dropdown
                                trigger={({ isOpen, ...triggerProps }) => (
                                    <Button
                                        variant={isOpen ? 'secondary' : 'clean'}
                                        isPressed={isOpen}
                                        {...triggerProps}
                                        rightIcon={<Dropdown.Arrow />}
                                    >
                                        {selectedChannel?.title || ''}
                                    </Button>
                                )}
                            >
                                {visibleFilterTypes.map((ch) => (
                                    <Dropdown.Item
                                        key={ch.title}
                                        isChecked={ch.title === selectedChannel?.title}
                                        onClick={() => handleJobTypeClick(ch)}
                                    >
                                        <span>{ch.title}</span>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown>
                            <Dropdown
                                trigger={({ isOpen, ...triggerProps }) => (
                                    <Button
                                        variant={isOpen ? 'secondary' : 'clean'}
                                        isPressed={isOpen}
                                        {...triggerProps}
                                        rightIcon={<Dropdown.Arrow />}
                                    >
                                        {selectedStatus?.title || ''}
                                    </Button>
                                )}
                            >
                                {filterStatus.map((ch) => (
                                    <Dropdown.Item
                                        key={ch.title}
                                        isChecked={ch.title === selectedStatus?.title}
                                        onClick={() => handleJobStatusClick(ch)}
                                    >
                                        <span>{ch.title}</span>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown>
                        </Flex>
                    </ExportsItemContainer>
                    <ExportJobsContainer>
                        <InProgressJobBlock
                            jobs={inProgressJobs}
                            onStart={handleJobStart}
                            onComplete={handleJobComplete}
                            onFailed={handleFailedJobs}
                        />
                        {!!(completedJobs.length || failedJobs.length) && (
                            <div>
                                <ExportSectionTitle>{COMPLETED}</ExportSectionTitle>
                            </div>
                        )}
                        <CompletedJobBlock jobs={completedJobs} onSelect={setSelectedJob} onDelete={deleteJob} />
                        <FailedJobBlock jobs={failedJobs} onDelete={deleteFailedJob} />
                    </ExportJobsContainer>
                </div>
            )}
        </>
    );
};

export default AllExports;
