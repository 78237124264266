import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Flex, Modal, Spinner } from '@bynder/design-system';
import InfiniteScroll from '../../infiniteScroll/InfiniteScroll';
import { presetsSelector, selectedPresetSelector } from '../../../store/presets/presets.selectors';
import {
    getNextPageData as getNextPageDataAction,
    getPresets as getPresetsAction,
    removePreset as removePresetAction,
    renamePreset as renamePresetAction,
    selectPreset as selectPresetAction,
} from '../../../store/presets/presets.actions';
import PresetItems from './PresetItems';
import PresetListItem from './PresetListItem';
import { IconSearch } from '@bynder/icons';
import {
    PresetDefaultInfo,
    PresetDetails,
    PresetsContainer,
    PresetsList,
    PresetsListContainer,
    StyledInput,
} from './PresetModal.styled';
import modalContainer from 'packages/common/modalContainer';

const PresetsModal = ({
    toggleModal,
    getPresets,
    selected,
    getNextPageData,
    selectPreset,
    confirmSelection,
    removePreset,
    renamePreset,
    requestingRename,
    presets,
    isFetching,
    totalItems,
}) => {
    const [search, setSearch] = useState('');

    useEffect(() => {
        if (!isFetching) {
            getPresets({ search });
        }
    }, [search]);

    const handleAdd = useCallback(() => {
        confirmSelection(selected);
    }, [confirmSelection, selected]);

    const hasMore = !!presets && presets.length < totalItems;

    return (
        <Modal
            container={modalContainer}
            size="medium"
            title="Browse preset"
            onClose={() => toggleModal()}
            isOpen
            actionPrimary={
                <Button variant="primary" onClick={handleAdd} isDisabled={!selected}>
                    Add
                </Button>
            }
            actionSecondary={
                <Button variant="secondary" onClick={() => toggleModal()}>
                    Cancel
                </Button>
            }
        >
            <Flex direction="column">
                <StyledInput
                    icon={<IconSearch />}
                    placeholder={`Search (${totalItems ? totalItems : 0})`}
                    value={search}
                    onChange={setSearch}
                    hasClearButton
                />
                <PresetsContainer>
                    <PresetsListContainer>
                        <InfiniteScroll handleLoadMore={getNextPageData} hasMore={hasMore} loading={isFetching}>
                            {presets && (
                                <PresetsList>
                                    {presets.map((preset) => (
                                        <PresetListItem
                                            key={preset.id}
                                            preset={preset}
                                            selected={selected}
                                            selectPreset={selectPreset}
                                            renamePreset={renamePreset}
                                            removePreset={removePreset}
                                            requestingRename={requestingRename}
                                        />
                                    ))}
                                </PresetsList>
                            )}
                            {isFetching && <Spinner />}
                        </InfiniteScroll>
                    </PresetsListContainer>
                    {selected ? (
                        <PresetDetails>
                            <PresetItems preset={selected} />
                        </PresetDetails>
                    ) : (
                        <PresetDefaultInfo>
                            Select a group
                            <br />
                            to see preset items
                        </PresetDefaultInfo>
                    )}
                </PresetsContainer>
            </Flex>
        </Modal>
    );
};

function mapProperties(state) {
    const { isFetching, totalItems, requestingRename } = state.presets;
    return {
        presets: presetsSelector(state),
        isFetching,
        requestingRename,
        totalItems,
        selected: selectedPresetSelector(state),
    };
}

function mapActions(dispatch) {
    return bindActionCreators(
        {
            getPresets: getPresetsAction,
            getNextPageData: getNextPageDataAction,
            selectPreset: selectPresetAction,
            renamePreset: renamePresetAction,
            removePreset: removePresetAction,
        },
        dispatch,
    );
}

export default connect(mapProperties, mapActions)(PresetsModal);
