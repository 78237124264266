import React, { useRef, useEffect, useMemo, useCallback, useState } from 'react';
import { AutoSizer, CellMeasurer, CellMeasurerCache, createMasonryCellPositioner, Masonry } from 'react-virtualized';
import { Button } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import usePreviousValue from 'packages/hooks/usePrevious';
import { Box, Cell, LoadingLayer } from './Grid.styled';
import { GridProps } from './types';
import { parseAspectRatio } from '../card/utils';
import { calculateColumnCount } from './utils';

const GRID_COLUMN_WIDTH = 224;
const GRID_GUTTER_SIZE = 24;
const DEFAULT_CARD_HEIGHT = 214;
const COMMON_WIDTH = 1062; // based on 1366 screen width
const NUMBER_OF_CARDS_TO_PRERENDER = 3;

const Grid = ({
    items = [],
    renderItem,
    isLoading = false,
    columnWidth = GRID_COLUMN_WIDTH,
    gutterSize = GRID_GUTTER_SIZE,
    defaultCardHeight = DEFAULT_CARD_HEIGHT,
    onScroll,
    overscanByPixels = (defaultCardHeight + gutterSize) * NUMBER_OF_CARDS_TO_PRERENDER,
    scrollThreshold = defaultCardHeight + gutterSize,
}: GridProps<any>) => {
    const { translate } = useTranslate();
    const masonryGridRef = useRef<typeof Masonry>();
    const box = useRef<HTMLDivElement>(null);
    const [boxHeight, setBoxHeight] = useState(0);

    useEffect(() => {
        if (!box.current) {
            return;
        }

        const observer = new MutationObserver(() => {
            setBoxHeight(box.current?.clientHeight!);
        });

        observer.observe(box.current, { attributes: true, childList: true, subtree: true });

        return () => {
            observer.disconnect();
        };
    }, []);

    const measurerCache = useMemo(
        () =>
            new CellMeasurerCache({
                defaultHeight: defaultCardHeight,
                defaultWidth: columnWidth,
                fixedWidth: true,
            }),
        [],
    );

    const cellPositioner = useCallback(
        createMasonryCellPositioner({
            cellMeasurerCache: measurerCache,
            columnCount: calculateColumnCount(COMMON_WIDTH, columnWidth, gutterSize),
            columnWidth,
            spacer: gutterSize,
        }),
        [],
    );

    const resetCells = (width: number) => {
        cellPositioner.reset({
            cellMeasurerCache: measurerCache,
            columnCount: calculateColumnCount(width, columnWidth, gutterSize),
            columnWidth,
            spacer: gutterSize,
        });

        masonryGridRef.current?.recomputeCellPositions();
    };

    const clearCells = (width: number) => {
        measurerCache.clearAll();
        cellPositioner.reset({
            cellMeasurerCache: measurerCache,
            columnCount: calculateColumnCount(width, columnWidth, gutterSize),
            columnWidth,
            spacer: gutterSize,
        });

        masonryGridRef.current.clearCellPositions();
    };

    const prevItems = usePreviousValue(items);

    const checkDiff = (prev, next) => {
        if (!prev?.length) {
            return false;
        }

        const order = prev.length < next.length ? [prev, next] : [next, prev];

        return order[0].every((item, index) => {
            const isInPlace = item.id === order[1][index].id;
            const isNameTheSame = item.name === order[1][index].name;
            const isStatusTheSame = item.status === order[1][index].status;

            return isInPlace && isNameTheSame && isStatusTheSame;
        });
    };

    useEffect(() => {
        // it will skip first load but reset cells on each new page loaded
        // onGridResize will handle first load
        if (masonryGridRef.current && masonryGridRef.current.props.width) {
            if (checkDiff(prevItems, items)) {
                resetCells(masonryGridRef.current.props.width);
            } else {
                clearCells(masonryGridRef.current.props.width);
                // eslint-disable-next-line no-underscore-dangle
                masonryGridRef.current._scrollingContainer.scrollTop = 0;
            }
        }
    }, [items]);

    // in case if loaded, for example, 20 items as first page
    // but that is not enough to fill application page and free space left
    // then x function will call next page until application page is full
    const onCellsRendered = () => {
        if (masonryGridRef.current) {
            // eslint-disable-next-line no-underscore-dangle
            const { scrollHeight } = masonryGridRef.current._scrollingContainer;

            if (boxHeight !== 0 && scrollHeight !== 0 && boxHeight >= scrollHeight) {
                onScroll();
            }
        }
    };

    useEffect(() => onCellsRendered(), [boxHeight]);

    const onGridResize = ({ width }) => {
        resetCells(width);
    };

    const handleOnScroll = ({ clientHeight, scrollHeight, scrollTop }) => {
        if (clientHeight + scrollTop >= scrollHeight - scrollThreshold) {
            onScroll();
        }
    };

    const renderGridItem = ({ index, key, parent, style }) => {
        const item = items[index];
        let aspectRatio;

        if (item && !item.aspectRatio && item.height && item.width) {
            aspectRatio = item.width / item.height;
        } else {
            aspectRatio = parseAspectRatio(item && item.aspectRatio);
        }

        // columnWidth calculated with 4 * 2 padding for outline
        // but outline space not available for card internals
        const cardWidth = columnWidth - 4 * 2;
        const defaultHeight = Math.round(((cardWidth / aspectRatio) * 100) / 100);

        return (
            item && (
                <CellMeasurer key={key} cache={measurerCache} index={index} parent={parent}>
                    <Cell cellStyle={style} role="listitem">
                        {renderItem(item, defaultHeight)}
                    </Cell>
                </CellMeasurer>
            )
        );
    };

    return (
        <Box ref={box} data-testid="masonry grid">
            <AutoSizer onResize={onGridResize} overscanByPixels={overscanByPixels} disableHeight>
                {({ width }) => (
                    <Masonry
                        ref={masonryGridRef}
                        width={width}
                        height={boxHeight}
                        cellCount={items.length}
                        cellRenderer={renderGridItem}
                        cellMeasurerCache={measurerCache}
                        cellPositioner={cellPositioner}
                        onCellsRendered={onCellsRendered}
                        onScroll={handleOnScroll}
                        overscanByPixels={overscanByPixels}
                        role="list"
                    />
                )}
            </AutoSizer>
            {isLoading && (
                <LoadingLayer data-testid="masonry grid loader">
                    <Button isLoading variant="clean" aria-label={translate('pages.loading')} />
                </LoadingLayer>
            )}
        </Box>
    );
};

export default Grid;
