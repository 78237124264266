import ProxyServerConnector from './connectors/ProxyServerConnector';

class AppVersionService extends ProxyServerConnector {
    constructor() {
        super('');
    }

    getVersion() {
        return this.get('/app-version').then(({ json, status }) => {
            if (status === 200) {
                return { version: json.version, status };
            }
        });
    }
}

export default new AppVersionService();
