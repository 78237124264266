import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { transferOwnership } from 'packages/store/sharing/sharing.actions';
import { getSharing } from 'packages/store/sharing/sharing.selectors';
import { Props } from './types';
import modalContainer from 'packages/common/modalContainer';

const TransferModal = ({
    entityId,
    entityType,
    permissions,
    transferModalEntity,
    onTransferModalClose,
    resetTransferModal,
}: Props) => {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const { transferInProgress } = useSelector(getSharing);

    return (
        <Modal
            container={modalContainer}
            title={translate('modal.sharing.ownership.transfer')}
            actionPrimary={
                <Button
                    variant="primary"
                    title={
                        !transferInProgress
                            ? translate('modal.sharing.ownership.transfer')
                            : translate('modal.sharing.ownership.transferring')
                    }
                    onClick={() =>
                        dispatch(
                            transferOwnership(
                                {
                                    entityId,
                                    entityType,
                                    bynderUserId:
                                        transferModalEntity !== null &&
                                        permissions[transferModalEntity].securityIdentity.bynderUserId,
                                },
                                onTransferModalClose,
                            ),
                        )
                    }
                    isLoading={transferInProgress}
                >
                    {!transferInProgress
                        ? translate('modal.sharing.ownership.transfer')
                        : translate('modal.sharing.ownership.transferring')}
                </Button>
            }
            actionSecondary={
                <Button
                    variant="secondary"
                    onClick={resetTransferModal}
                    title={translate('modal.sharing.ownership.transfer.cancel')}
                >
                    {translate('modal.sharing.ownership.transfer.cancel')}
                </Button>
            }
            isOpen={transferModalEntity !== null}
            onClose={resetTransferModal}
        >
            {translate('modal.sharing.ownership.transfer.info', {
                name:
                    (transferModalEntity !== null && permissions[transferModalEntity].securityIdentity?.fullName) || '',
                type: entityType,
            })}
        </Modal>
    );
};

export default TransferModal;
