import styled from 'styled-components';
import { Sidebar, token } from '@bynder/design-system';

export const StyledSidebar = styled(Sidebar)`
    border-right: none;
    z-index: 1;
    width: 272px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const StyledSectionTitle = styled.p`
    font-weight: 600;
    margin-bottom: 0;
`;
export const StyledSectionDivider = styled.div`
    margin-top: ${token.spacing6};
`;

export const StyledSkeletonNavContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 ${token.spacing5};
`;

export const StyledSkeletonNavItem = styled.div`
    display: flex;
    gap: ${token.spacing5};
    padding: ${token.spacing3} ${token.spacing4};
`;
