import React, { useCallback, useState } from 'react';
import { Buffer, Button, Card, Form, InteractiveIcon } from '@bynder/design-system';
import { IconCut, IconDelete } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import ContentPickerModal from 'packages/pages/editor/ContentPickerModal';
import generateTestId from '~/helpers/testIdHelpers';
import { contentIconMap, getFormattedTime } from 'packages/pages/components/card/utils';
import { VideoPreview } from './VideoPreview';
import FormLabelLock from '../FormLabelWithLock';
import { BrowseInput, CardFrame } from './index.styled';
import { ContentType, Props } from './types';

const BrowseButton = (props: Props) => {
    // for ContentSelectorModal
    const {
        type,
        frameRate,
        resizableRange = false,
        selectedObject,
        collectionId,
        show: showFromProps,
        handleSelectSource,
        setShowAssetSelection,
        isEditorPage,
    } = props;

    // for the rest of component
    const {
        value,
        label,
        id,
        htmlFor,
        placeholder,
        toggleLock,
        locked,
        disabled,
        testId,
        previewURL,
        isAlpha,
        addOn,
        duration,
        loading,
    } = props;

    const [show, setShow] = useState(showFromProps || false);
    const [mode, setMode] = useState(false); // false - browse files; true - trim mode

    const { translate } = useTranslate();

    const handleButtonClick = () => setShow(true);

    const handleToggleModal = (nextShow: boolean) => {
        if (setShowAssetSelection) {
            setShowAssetSelection(nextShow);
        }

        setShow(nextShow);
    };
    const confirmSelection = useCallback(
        (item) => {
            setShow(false);
            handleSelectSource(item);

            if (setShowAssetSelection) {
                // todo: check if the passed show value is correct
                setShowAssetSelection(show);
            }
        },
        [setShow, show, handleSelectSource, setShowAssetSelection],
    );

    const buttonText = {
        [ContentType.AUDIO]: translate('editor.sidebar.globals.audio.button.audio'),
        [ContentType.IMAGE]: translate('editor.sidebar.globals.audio.button.image'),
        [ContentType.VIDEO]: translate('editor.sidebar.globals.audio.button.video'),
    };

    const buttonTestId = generateTestId(testId);
    const inputTestId = generateTestId(`${testId}_input`);
    const selectionTestId = generateTestId(`${testId}_select`);
    const trimTestId = generateTestId(`${testId}_trim`);

    return (
        <>
            {show && (
                <ContentPickerModal
                    type={type}
                    show={show}
                    step={mode}
                    frameRate={frameRate}
                    resizableRange={resizableRange}
                    selectedObject={selectedObject}
                    collectionId={collectionId}
                    isEditorPage={isEditorPage}
                    toggleModal={handleToggleModal}
                    confirmSelection={confirmSelection}
                />
            )}
            {previewURL ? (
                <Card
                    variant="clean"
                    isDisabled={disabled}
                    aria-label={translate('editor.sidebar.shots.asset.card.label')}
                    contextAction={
                        type === ContentType.VIDEO && (
                            <Card.ContextActionButton
                                tooltip={translate('editor.sidebar.shots.asset.card.action.label')}
                                aria-label={translate('editor.sidebar.shots.asset.card.action.label')}
                                onClick={() => {
                                    setMode(true);
                                    handleToggleModal(true);
                                }}
                                isDisabled={disabled}
                                {...trimTestId}
                            >
                                <IconCut />
                            </Card.ContextActionButton>
                        )
                    }
                    onClick={() => {
                        if (disabled) {
                            return;
                        }

                        setMode(false);
                        handleToggleModal(true);
                    }}
                    data-src={previewURL}
                    {...selectionTestId}
                >
                    {type === ContentType.VIDEO && (
                        <CardFrame
                            badge={getFormattedTime({ duration, frameRate } as any)}
                            icon={contentIconMap.VIDEO}
                            progressBar={loading && <Buffer noCornerRadius />}
                            isDisabled={disabled}
                        >
                            <VideoPreview src={previewURL} isAlpha={!!isAlpha} />
                        </CardFrame>
                    )}
                    {type === ContentType.IMAGE && (
                        <CardFrame
                            icon={contentIconMap.IMAGE}
                            progressBar={loading && <Buffer noCornerRadius />}
                            isDisabled={disabled}
                        >
                            <Card.Images>
                                <Card.Img src={previewURL} />
                            </Card.Images>
                        </CardFrame>
                    )}
                </Card>
            ) : (
                <>
                    <FormLabelLock
                        testId={`${testId}_input`}
                        label={label}
                        locked={locked}
                        toggleLock={toggleLock}
                        htmlFor={htmlFor}
                        isDisabled={disabled}
                    />
                    <Form.InputRow>
                        <BrowseInput
                            id={id}
                            value={value || ''}
                            type="text"
                            placeholder={placeholder}
                            iconRight={
                                value && type === ContentType.AUDIO ? (
                                    <InteractiveIcon
                                        onClick={() => addOn && addOn()}
                                        icon={<IconDelete />}
                                        isDisabled={disabled || locked}
                                        label={translate('editor.sidebar.globals.audio.input.icon.label')}
                                    />
                                ) : undefined
                            }
                            aria-label={label}
                            aria-labelledby={htmlFor}
                            isDisabled={disabled || !value || locked}
                            isReadOnly
                            {...inputTestId}
                        />
                        <Button onClick={handleButtonClick} isDisabled={disabled || locked} {...buttonTestId}>
                            {buttonText[type]}
                        </Button>
                    </Form.InputRow>
                </>
            )}
        </>
    );
};

export default BrowseButton;
