import ProxyServerConnector from '~/services/connectors/ProxyServerConnector';

export enum RequestTypes {
    ANALYSIS = 'ANALYSIS',
    IMPORT = 'IMPORT',
}

type CreateTemplateBaseData = {
    type: RequestTypes;
    customerId: number;
    collectionId: string;
    creatorId: string;
    multiDesignMode: boolean;
};

type CreateTemplateGlobalData = CreateTemplateBaseData & {
    mediaId: string;
};

type CreateTemplateLocalData = CreateTemplateBaseData & {
    isLocalFile: true;
    fileUrl: string;
};

type CreateTemplateFigmaData = CreateTemplateBaseData & {
    isLocalFile: true;
    figmaImportPropertiesDto: {
        fileKey: string;
        frameIds: string[];
    };
};

export type CreateTemplateData = CreateTemplateGlobalData | CreateTemplateLocalData | CreateTemplateFigmaData;

class TemplatesServiceFromDesignFile extends ProxyServerConnector {
    constructor(path = '/design-creation-requests') {
        super(path);
    }

    createTemplate(data: CreateTemplateData) {
        return this.post('', data);
    }
}

export default new TemplatesServiceFromDesignFile();
