import * as v from 'valibot';
import GraphQlService from 'packages/services/graphql/GraphQlService';
import type { Ordering } from 'packages/services/graphql/types';
import { PageInfoFragment, pageInfoSchema } from 'packages/services/graphql/fragments';
import { CollectionFragment, collectionSchema } from './fragments';

type Options = { searchTerm?: string | null; orderBy: Ordering };

const fetchCollectionsQuery = `
${CollectionFragment}
${PageInfoFragment}
query getCollections($searchTerm: String, $orderBy: CollectionOrder!, $after: Cursor) {
  collections(after: $after, first: 50, filter: {name_contains: $searchTerm, assetCount_gte: 0}, orderBy: $orderBy) {
    nodes {
      ...CollectionFragment
    }
    totalCount
    pageInfo {
      ...PageInfoFragment
    }
  }
}`;

const fetchCollectionsSchema = v.object({
    collections: v.object({
        nodes: v.array(collectionSchema),
        totalCount: v.number(),
        pageInfo: pageInfoSchema,
    }),
});

const fetchCollectionQuery = `
${CollectionFragment}
query getCollection($id: ID) {
  node(id: $id) {
    ...CollectionFragment
  }
}`;

const fetchCollectionSchema = v.object({
    node: collectionSchema,
});

export type Collections = v.InferOutput<typeof fetchCollectionsSchema>;

class AssetSourceService {
    async fetchCollections(
        portalDomain: string | undefined,
        getDirectAccessToken: () => Promise<string>,
        variables: Required<Options> & { after: string | null },
    ) {
        const auth = {
            accessToken: await getDirectAccessToken(),
            portalDomain,
        };

        return GraphQlService.fetch({
            variables,
            auth,
            query: fetchCollectionsQuery,
            parse: (data) => v.parse(fetchCollectionsSchema, data),
        });
    }

    async fetchCollection(portalDomain: string | undefined, getDirectAccessToken: () => Promise<string>, id: string) {
        const auth = {
            accessToken: await getDirectAccessToken(),
            portalDomain,
        };

        return GraphQlService.fetch({
            variables: { id },
            auth,
            query: fetchCollectionQuery,
            parse: (data) => v.parse(fetchCollectionSchema, data),
        });
    }
}

export default new AssetSourceService();
