import { token, textStyles } from '@bynder/design-system';
import styled from 'styled-components';

export const UserGroupTitle = styled.p`
    ${textStyles.uiTextM};
    margin-top: ${token.spacing5};
    margin-bottom: 0;
`;

export const SearchResultsContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 0px 1px ${token.gray100a}, 0px 2px 4px -1px ${token.gray100a};
    border-radius: ${token.radiusBase};
    background: ${token.white};
    width: calc(100% - ${token.spacing6} - ${token.spacing6});
    z-index: 10;
    padding: ${token.spacing3} ${token.spacing4};
    max-height: 257px;
    overflow: scroll;
`;

export const Box = styled.div`
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const BannerBox = styled.div`
    margin-bottom: ${token.spacing5};
`;

export const OwnershipWithSeparator = styled.span`
    &:before {
        content: '•';
        margin-left: ${token.spacing3};
        margin-right: ${token.spacing3};
    }
`;
