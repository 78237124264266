import * as React from 'react';
import { useTranslate } from '@bynder/localization';
import { Button, Dropdown, Form, Input } from '@bynder/design-system';
import { IconBorderWidth, IconTextCenter, IconTextOutline } from '@bynder/icons';
import { Stroke, StrokeType } from '@bynder-studio/misc';
import generateTestId from '~/helpers/testIdHelpers';
import useFontSettingInput from 'packages/pages/editor/RightSideMenu/Shots/Text/Fonts/useFontSettingInput';
import useTextSelection from 'packages/hooks/useTextSelection';

const OPTIONS = [
    { name: StrokeType.INSIDE, icon: <IconTextOutline /> },
    { name: StrokeType.CENTER, icon: <IconTextCenter /> },
    { name: StrokeType.OUTSIDE, icon: <IconTextOutline /> },
];

const OutlineModes = ({ selectedElement, creativeModel }) => {
    const { translate } = useTranslate();
    const { id, locked } = selectedElement;
    const { strokeData } = useTextSelection();
    const [stroke, isMixed] = strokeData;
    const attrs = useFontSettingInput({
        creativeModel,
        selectedElement,
        disabled: locked,
        propName: 'stroke',
        autoCorrectKey: 'text_element_stroke_width',
        mapDisplayValue: (v) => v.width,
        mapSubmitValue: (v) => ({ ...stroke, width: v }),
    });

    const isOpen = React.useMemo(() => stroke.type !== StrokeType.NONE && !isMixed, [stroke.type]);

    const handleChange = (strokeProp: Partial<Stroke>) => {
        const param = {
            updateTextSettingForSelection: {
                settings: {
                    stroke: {
                        ...stroke,
                        ...strokeProp,
                    },
                },
            },
        };

        creativeModel.updateElement(id, param);
    };

    if (!isOpen) {
        return null;
    }

    return (
        isOpen && (
            <Form.Group>
                <Form.Label>{translate('editor.sidebar.shots.text.outline_weight.title')}</Form.Label>
                <Form.Row>
                    <Form.Group>
                        <Input
                            {...attrs}
                            name="Outline width"
                            icon={<IconBorderWidth />}
                            suffix="px"
                            {...generateTestId('shots_text_outline_width')}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Dropdown
                            minWidth="136px"
                            trigger={({ isOpen, ...triggerProps }) => (
                                <Button
                                    title={translate(
                                        `editor.sidebar.shots.text.outline.${stroke.type.toLowerCase()}.label`,
                                    )}
                                    isPressed={isOpen}
                                    isDisabled={locked}
                                    rightIcon={<Dropdown.Arrow />}
                                    isFullWidth
                                    {...triggerProps}
                                    {...generateTestId('shots_text_outline_dropdown_button')}
                                >
                                    <Dropdown.SelectButtonText>
                                        {translate(
                                            `editor.sidebar.shots.text.outline.${stroke.type.toLowerCase()}.label`,
                                        )}
                                    </Dropdown.SelectButtonText>
                                </Button>
                            )}
                        >
                            {OPTIONS.map((option) => (
                                <Dropdown.Item
                                    isDisabled={locked}
                                    isChecked={option.name === stroke.type}
                                    onClick={() => {
                                        handleChange({ type: option.name });
                                    }}
                                    {...generateTestId(`shots_text_outline_mode_${option.name.toLowerCase()}`)}
                                >
                                    {translate(`editor.sidebar.shots.text.outline.${option.name.toLowerCase()}.label`)}
                                </Dropdown.Item>
                            ))}
                        </Dropdown>
                    </Form.Group>
                </Form.Row>
            </Form.Group>
        )
    );
};

export default OutlineModes;
