import styled, { css } from 'styled-components';
import { token } from '@bynder/design-system';
import { LoadingLayer } from '../grid/Grid.styled';

export const PageBodyList = css`
    position: relative;
    padding: 0 ${token.spacing6} ${token.spacing6} ${token.spacing6};

    thead {
        background-color: ${token.white};
    }
    td {
        position: relative;
    }
    th {
        white-space: nowrap;
    }
    ${LoadingLayer} {
        position: sticky;
        bottom: -${token.spacing6};
    }
`;

// Needed to hide table header box-shadow before you start scrolling the table
export const StickyHack = styled.div`
    height: 1px;
`;
