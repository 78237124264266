import { OwnershipFilterType } from 'packages/pages/components/filters/components/Ownership/types';
import { isTrialAccountStorageFn, catchResponseError } from 'packages/helpers/helpers';
import { sendAppcuesEvent } from '~/helpers/RouteWithAppcues';
import * as types from '../types';
import TemplatesService from '../../services/TemplatesService';

export function resetOwnership() {
    return (dispatch, getState) => {
        dispatch({
            type: types.GALLERY_RESET_OWNERSHIP,
            ownershipSource: OwnershipFilterType.OWNED_BY_ME,
        });
    };
}

export function initializeGallery() {
    return (dispatch, getState) => {
        const state = getState();

        dispatch({ type: types.INITIALIZE_GALLERY });

        if (isTrialAccountStorageFn(getState())) {
            dispatch(resetOwnership());
        }
    };
}

export function fetchGallery(newOptions = {}, setSelected = false, fetchFromStart = false, deselect) {
    return (dispatch, getState) => {
        const oldState = getState();
        const { fetchOptions } = oldState.gallery;
        const options = {
            ...fetchOptions,
            ...newOptions,
            customerId: oldState.user.currentCompany,
        };

        dispatch({ type: types.GET_GALLERY_REQUEST, options, searching: fetchOptions.search !== options.search });

        const optionsToSend = { ...options, gallery: true };

        if (fetchFromStart) {
            optionsToSend.size = (optionsToSend.page + 1) * optionsToSend.size;
            optionsToSend.page = 0;
            dispatch({
                type: types.RESET_FETCHED_GALLERY,
            });
        }

        TemplatesService.fetchTemplates(optionsToSend)
            .then(({ status, json }) => {
                if (status === 200) {
                    const { totalItems, items } = json;
                    dispatch({
                        type: types.GET_GALLERY_SUCCESS,
                        templates:
                            options.page > 0 && !fetchFromStart ? [...oldState.gallery.templates, ...items] : items,
                        totalItems,
                        setSelected,
                        deselect,
                    });
                } else {
                    dispatch({ type: types.GET_GALLERY_FAIL });
                }
            })
            .catch(catchResponseError);
    };
}

export function selectGalleryItem(template) {
    return (dispatch, getState) => {
        dispatch({ type: types.SELECT_GALLERY_ITEM, templateId: template.id });
        sendAppcuesEvent('Gallery template selected', { id: template.id, name: template.name });
    };
}

export function deselectGallery() {
    return {
        type: types.DESELECT_GALLERY_ITEM,
    };
}
