export enum StrokeType {
    NONE = 'NONE',
    INSIDE = 'INSIDE',
    CENTER = 'CENTER',
    OUTSIDE = 'OUTSIDE',
}

export type Stroke = {
    type: StrokeType;
    width: number;
};
