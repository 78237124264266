import React from 'react';
import { Card } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import useAccessRights from 'packages/hooks/useAccessRights';
import { ActionStatus, Entity, CategoryAction } from 'packages/hooks/useAccessRights/types';
import { getCollectionSharingIcon } from 'packages/pages/components/card/utils';
import { Category } from 'packages/store/categories/types';
import generateTestId from '~/helpers/testIdHelpers';
import { Link } from './CategoryCard.styled';
import CategoryThumbnail from './CategoryThumbnail';

type CategoryCardProps = {
    category: Category;
    onClick?: () => void;
    contextAction?: React.ReactNode;
    includeSharedIndicator?: boolean;
    to: string;
};

const CategoryCard = ({ category, contextAction, includeSharedIndicator, to, onClick }: CategoryCardProps) => {
    const { translate } = useTranslate();
    const { isEntityActionAllowed, getIdentityRole, getIdentityRoleAllUsers } = useAccessRights();

    const isViewAllowed =
        isEntityActionAllowed(CategoryAction.VIEW, Entity.CATEGORY, category.grantedPermissions) ===
        ActionStatus.ALLOWED;

    return (
        <Card
            variant="outlined"
            as={Link}
            to={isViewAllowed ? to : '#'}
            onClick={onClick}
            contextAction={contextAction}
            aria-label={category.name}
            selectButtonAriaLabel={translate('pages.categories.card.context.button', { categoryName: category.name })}
            isSelected={category.checked}
            {...generateTestId(`category_card_${category.id}`)}
        >
            <CategoryThumbnail category={category} />
            <Card.Header
                title={category.name}
                detail={
                    category.imageCreativesCount + category.videoCreativesCount
                        ? translate('pages.categories.grid.numberOfTemplates', {
                              count: category.imageCreativesCount + category.videoCreativesCount,
                          })
                        : translate('pages.categories.grid.numberOfTemplates.empty')
                }
                icon={
                    includeSharedIndicator &&
                    getCollectionSharingIcon(category, { getIdentityRole, getIdentityRoleAllUsers })
                }
            />
        </Card>
    );
};

export default CategoryCard;
