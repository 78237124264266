import styled from 'styled-components';
import { Box } from '@bynder/design-system';

export const FullWidthBox = styled(Box)`
    flex: 1;
    max-width: 213px;
`;

export const HiddenInput = styled.input`
    width: 1px;
    height: 1px;
    opacity: 0;
    margin: 0;
    padding: 0;
`;
