import React, { useCallback, useState } from 'react';
import { getUnixTime } from 'date-fns';
import { useTranslate } from '@bynder/localization';
import { Button, Modal } from '@bynder/design-system';
import useVariations from 'packages/pages/design/hooks/useVariations';

type Props = {
    variationId: string;
    onClose: () => void;
};

const ResetVariationModal = ({ variationId, onClose }: Props) => {
    const { translate } = useTranslate();
    const { saveVariation, variations, swapVariationThumbnailModel } = useVariations();
    const [isSaving, setIsSaving] = useState(false);

    const resetVariation = useCallback(async () => {
        setIsSaving(true);

        const variation = variations.find((v) => v.id === variationId);
        variation.hasUnsavedChangesTimestamp = getUnixTime(new Date());

        await saveVariation(variationId, onClose, Object.keys(variation.sizes));
        swapVariationThumbnailModel(variation);
        setIsSaving(false);
    }, [variationId, variations, saveVariation, onClose, swapVariationThumbnailModel]);

    return (
        <Modal
            isOpen={!!variationId}
            onClose={onClose}
            title={translate('design.sidebar.variations.modal.reset.title')}
            actionPrimary={
                <Button variant="primary" onClick={resetVariation} isLoading={isSaving}>
                    {translate('design.sidebar.variations.modal.reset.button.reset')}
                </Button>
            }
            actionSecondary={
                <Button variant="secondary" onClick={onClose}>
                    {translate('design.sidebar.variations.modal.reset.button.cancel')}
                </Button>
            }
        >
            {translate('design.sidebar.variations.modal.reset.description')}
        </Modal>
    );
};

export default ResetVariationModal;
