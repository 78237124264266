import ProxyServerConnector from './connectors/ProxyServerConnector';

class ExportService extends ProxyServerConnector {
    constructor(path = '') {
        super(path);
    }

    deleteFailedExportJob(jobId) {
        return this.delete(`/variation-sets/exports/${jobId}`);
    }
}

export default new ExportService();
