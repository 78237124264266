import React from 'react';
import usePageValidation from '~/hooks/usePageValidation';
import { PagePreviewItemBase, PagePreviewItemBaseProps } from './PagePreviewItemBase';

type Props = Omit<PagePreviewItemBaseProps, 'isValid'> & { hasDeletedAsset: boolean };

const PagePreviewItem = (props: Props) => {
    const isValid = usePageValidation(props.idx) && !props.hasDeletedAsset;

    return <PagePreviewItemBase {...props} isValid={isValid} />;
};

export default PagePreviewItem;
