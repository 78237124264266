import React, { useEffect, useId, useMemo, useState } from 'react';
import { Combobox, Dropdown, Form, Spinner } from '@bynder/design-system';
import { IconFigma, IconFigmaColor } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import generateTestId from '~/helpers/testIdHelpers';
import { FigmaLink } from './types';

type Props = {
    links: Record<string, FigmaLink>;
    fetchFile: (link: string) => void;
    isLoading: boolean;
    removeHistory: () => void;
    cancelRequest: () => void;
};

export default function FigmaLinkInput({ links, fetchFile, isLoading, removeHistory, cancelRequest }: Props) {
    const id = useId();
    const { translate } = useTranslate();
    const [value, setValue] = useState('');
    const [comboboxKey, setComboboxKey] = useState('');

    const linksArray = useMemo(() => Object.values(links).sort((a, b) => b.createdAt - a.createdAt), [links]);

    useEffect(() => {
        const found = Object.values(links).find((item) => item.link === value)?.name;

        if (found) {
            setValue(found);
        }
        // do not disturb the user on input change, only on links change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [links]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement> | React.FocusEvent) => {
        e.preventDefault();
        const isUserClearInput = e.type === 'blur' && !URL.canParse(value);

        if (!value || isUserClearInput) {
            return;
        }

        fetchFile(value);
        setComboboxKey(value);
    };

    const createHandleSelect = (link: string, name: string) => () => {
        setValue(name);
        fetchFile(link);
        setComboboxKey(value);
    };

    const getIcon = () => {
        if (isLoading) {
            return <Spinner />;
        }

        if (linksArray.some((link) => link.link === value || link.name === value)) {
            return <IconFigmaColor />;
        }

        return <IconFigma />;
    };

    return (
        // Combobox doesn't have onBlur event, so we need to wrap it in a div to handle it
        <div onBlur={handleSubmit}>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label htmlFor={id}>
                        {translate('modal.design.create.design.figma.link-input.label')}
                    </Form.Label>
                    <Combobox
                        id={id}
                        openOn="focus"
                        value={value}
                        onChange={setValue}
                        onClean={() => {
                            cancelRequest();
                            setValue('');
                        }}
                        placeholder={translate('modal.design.create.design.figma.link-input.placeholder')}
                        icon={getIcon()}
                        isAnyValueAllowed
                        key={comboboxKey} // This is a workaround to reset dropdown state when currentLink changes
                        {...generateTestId('figma-link-input')}
                    >
                        {linksArray.length > 0 && (
                            <>
                                {linksArray.map(({ link, name }) => (
                                    <Combobox.Item
                                        key={name}
                                        onClick={createHandleSelect(link, name)}
                                        icon={<IconFigmaColor />}
                                    >
                                        {name}
                                    </Combobox.Item>
                                ))}
                                <Dropdown.Divider />
                                <Combobox.Item onClick={removeHistory}>
                                    {translate('modal.design.create.design.figma.link-input.clear-history')}
                                </Combobox.Item>
                            </>
                        )}

                        {linksArray.length === 0 && <Combobox.Text>No Figma links to show yet</Combobox.Text>}
                    </Combobox>
                </Form.Group>
            </Form>
        </div>
    );
}
