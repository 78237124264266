import { useEffect, useState } from 'react';
import useEditor from './useEditor';
import useEditorSelectedElement from '~/hooks/useEditorSelectedElement';
import { ElementUpdateTypes } from '@bynder-studio/render-core';

export default function useEditorSelectedElementContentTransform() {
    const { creativeModel } = useEditor();
    const { selectedElement } = useEditorSelectedElement();
    const { id } = selectedElement;
    const [contentTransform, setContentTransform] = useState(selectedElement.contentTransform);

    useEffect(() => {
        setContentTransform(selectedElement.contentTransform);
    }, [selectedElement]);

    useEffect(() => {
        if (!creativeModel || !id) {
            return;
        }

        return creativeModel.onElementPartialUpdate(id, ElementUpdateTypes.CONTENT_TRANSFORM, ({ element }) => {
            setContentTransform(element.contentTransform);
        });
    }, [creativeModel, id]);

    return { contentTransform };
}
