import React, { useState } from 'react';
import { useTranslate } from '@bynder/localization';
import { Creative } from 'packages/store/creatives/types';
import { EntityType } from 'packages/store/sharing/types';
import ShareModal from '../shareModal';

const useDesignShareModal = () => {
    const [shareModalDesign, setShareModalDesign] = useState<Creative | null>(null);
    const { translate } = useTranslate();

    const showModal = shareModalDesign !== null;

    const renderModalDesignShare = () => (
        <ShareModal
            title={translate('modal.design.share.title')}
            entityId={shareModalDesign && shareModalDesign.id}
            entityType={EntityType.CREATIVE}
            isModalOpen={showModal}
            onClose={() => setShareModalDesign(null)}
            collectionName={showModal ? shareModalDesign.projectName : null}
            approvalStatus={shareModalDesign ? shareModalDesign.approvalStatus : undefined}
        />
    );

    return {
        setShareModalDesign,
        renderModalDesignShare,
    };
};

export default useDesignShareModal;
