import React from 'react';
import { Form, Input } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import generateTestId from '~/helpers/testIdHelpers';
import { Props } from './types';
import useFontSettingInput from '../useFontSettingInput';

const FontSize = ({ creativeModel, selectedElement, disabled }: Props) => {
    const { translate } = useTranslate();
    const label = translate('editor.sidebar.shots.fonts.size');
    const attrs = useFontSettingInput({
        creativeModel,
        selectedElement,
        disabled,
        propName: 'fontSize',
        autoCorrectKey: 'text_element_font_size',
    });

    return (
        <Form.Group>
            <Input {...attrs} name={label} aria-label={label} suffix="px" {...generateTestId('shots_text_size')} />
        </Form.Group>
    );
};

export default FontSize;
