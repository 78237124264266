import React, { FC, useCallback } from 'react';
import type { TextStyle } from '@bynder-studio/render-core';
import { getFontById } from '@bynder-studio/structured-text';
import { Dropdown, token } from '@bynder/design-system';
import { IconMin } from '@bynder/icons';
import { textStyleSubtext } from 'packages/pages/editor/RightSideMenu/Shots/Text/TextStyles/utils';
import { ApplyFont, FontThumbnail } from './Component.styled';

type TextStyleItemProps = {
    item: TextStyle;
    selected?: boolean;
    useMultiselectIcon?: boolean;
    onClick: (item: TextStyle) => void;
};

export const TextStyleItem: FC<TextStyleItemProps> = ({ item, selected, useMultiselectIcon, onClick }) => {
    const handleClick = useCallback(() => {
        onClick(item);
    }, [item, onClick]);

    return (
        <Dropdown.ListItem
            isChecked={!useMultiselectIcon && selected}
            onClick={handleClick}
            subtext={textStyleSubtext(item)}
            thumbnail={
                <FontThumbnail>
                    <ApplyFont name={item.uuid} fontUrl={getFontById(item.fontId)?.url ?? ''}>
                        Aa
                    </ApplyFont>
                </FontThumbnail>
            }
            rightElements={selected && useMultiselectIcon && <IconMin style={{ color: token.gray800 }} />}
        >
            {item.name}
        </Dropdown.ListItem>
    );
};
