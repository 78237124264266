export enum ContentType {
    AUDIO = 'audio',
    IMAGE = 'image',
    VIDEO = 'video',
}
export type Props = {
    // for ContentSelectorModal
    type: ContentType;
    frameRate: number;
    resizableRange?: boolean;
    selectedObject: any;
    collectionId?: number;
    show?: boolean;
    handleSelectSource: (elem: any) => void;
    setShowAssetSelection?: (show: boolean) => void;
    isEditorPage: boolean;

    // for the rest of component
    value: string;
    label: string;
    id: string;
    htmlFor: string;
    placeholder: string;
    toggleLock?: () => void;
    locked: boolean;
    disabled: boolean;
    loading?: boolean;
    testId: string;
    previewURL?: string;
    isAlpha?: boolean;
    addOn?: () => void;
    duration?: number;
};
