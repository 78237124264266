import type { ManipualtionEvents, ManipulationEventParams } from '@bynder-studio/render-core';
import { DynamicEventEmitter } from '@bynder-studio/render-core';

class ManipulationEmitter extends DynamicEventEmitter {
    constructor(...props) {
        super(...props);
    }

    emit<T extends ManipualtionEvents>(
        event: ManipualtionEvents,
        values: ManipulationEventParams[T],
        ...rest
    ): boolean {
        return super.emit(event, ...[values, ...rest]);
    }
}

export default ManipulationEmitter;
