import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { ElementTypes } from '@bynder-studio/render-core';
import { ExtendedColorParams } from '@bynder-studio/misc';
import { usePalettes } from 'packages/pages/design/hooks/usePalettes';
import { collectConfigureColors, getUnifiedColor } from 'packages/pages/design/components/ShapeElement/utils';
import { flattenTree } from '~/common/editor/helpers/elementtree';
import ShotColor from 'packages/pages/design/sidebar/shots/ShotColor';
import useForceUpdate from '~/hooks/useForceUpdate';
import useDesign from '../../hooks/useDesign';
import useVariations from '../../hooks/useVariations';

type Props = {
    children: React.ReactChild | null;
    elementId: string;
    isDisabled: boolean;
};

const preventSubmit = (event) => event.preventDefault();

const BackgroundElement = ({ children, elementId, isDisabled }: Props) => {
    const forceUpdate = useForceUpdate();
    const { translate } = useTranslate();
    const { byId } = usePalettes();
    const { creativeModel, template } = useDesign();
    const { updateVariationGlobalProperty } = useVariations();
    const [selectedColor, setSelectedColor] = useState(getUnifiedColor(creativeModel.getBackgroundColor().color, byId));

    const elements = template.pages[creativeModel.getCurrentPageIndex()].elements;
    const {
        properties: { locked },
    } = elements.find((element) => element.id === elementId);

    const colors = useMemo(
        () => collectConfigureColors(flattenTree(elements)[elementId], 'brandColors', 'value', byId),
        [elementId, elements, byId],
    );

    const handleColorClick = useCallback(
        (color: ExtendedColorParams) => {
            const { name, ...newColor } = color;

            creativeModel.updateGlobalProperty(ElementTypes.BACKGROUND_COLOR, { color: newColor });

            updateVariationGlobalProperty(ElementTypes.BACKGROUND_COLOR, elementId, { color: newColor });
            forceUpdate();
        },
        [creativeModel, updateVariationGlobalProperty],
    );

    useEffect(() => {
        if (!creativeModel) {
            return;
        }

        const updateSelectedColor = () => {
            setSelectedColor(getUnifiedColor(creativeModel.getBackgroundColor().color, byId));
        };

        creativeModel.on('backgroundColorUpdated', updateSelectedColor);

        return () => {
            creativeModel.off('backgroundColorUpdated', updateSelectedColor);
        };
    }, [creativeModel, byId]);

    if (colors.length <= 1) return null;

    return (
        <>
            <Form onSubmit={preventSubmit}>
                <ShotColor
                    selectedColor={selectedColor}
                    title={translate('editor.sidebar.shots.background.title')}
                    elementId={elementId}
                    colors={colors}
                    handleColorClick={handleColorClick}
                    isDisabled={isDisabled || locked}
                />
            </Form>
            {children}
        </>
    );
};

export default BackgroundElement;
