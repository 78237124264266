import ProxyServerConnector from '~/services/connectors/ProxyServerConnector';

class ShotsCalculateService extends ProxyServerConnector {
    constructor(path = '/templates') {
        // eslint-disable-next-line no-undef
        super(path, GATEWAY_PREFIX);
    }

    calculate(data: Record<string, unknown>): Promise<any> {
        return this.post('/shots/calculate', data);
    }
}

export default new ShotsCalculateService();
