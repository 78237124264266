import styled from 'styled-components';
import { Slider, textStyles, token } from '@bynder/design-system';

export const VolumeSlider = styled(Slider)`
    min-width: calc(100% - 58px);
`;

export const EllapsedTime = styled.span`
    ${textStyles.uiTextM}
    color: ${token.colorText};
`;

export const TotalTime = styled.span`
    ${textStyles.uiTextM}
    color: ${token.colorTextMuted};
`;

export const TimelineHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding: 0 24px;
    background-color: ${token.white};
    box-shadow: none;

    &.timeline--without-zoom {
        justify-content: flex-start;
    }

    &.timeline--without-zoom .timeline--header-btns,
    &.timeline--without-zoom .timeline--header-tracker {
        flex-grow: 1;
    }

    .timeline--header-tracker {
        font-size: 16px;
        line-height: 20px;
        color: ${token.colorTextMuted};
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }

    .timeline--header-tracker .timeline--tracker-current-time {
        color: ${token.colorText};
    }

    .timeline--header-btns {
        display: flex;
        align-items: center;
    }
`;
