import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useEditor from 'packages/web/src/hooks/useEditor';
import useDesign from 'packages/pages/design/hooks/useDesign';
import { currentEditorType } from 'packages/store/creativeEditor/creativeEditor.selectors';

export default function useMultiPageValidation() {
    const editorType = useSelector(currentEditorType);
    const { validationManager } = editorType === 'create' ? useDesign() : useEditor();
    const [isValid, setValid] = useState(() => validationManager?.isValid() || false);

    useEffect(() => {
        if (!validationManager) {
            return;
        }

        setValid(validationManager.isValid());
        const validationChangeListener = () => {
            setValid(validationManager.isValid());
        };

        return validationManager.onValidationChange(validationChangeListener);
    }, [validationManager]);

    return isValid;
}
