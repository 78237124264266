import styled from 'styled-components';
import { List, textStyles, token } from '@bynder/design-system';

export const ImportContainer = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
    padding: ${token.spacing7};
    overflow: auto;
`;

export const ImportInner = styled.div`
    display: flex;
    flex-flow: column nowrap;
    width: calc(472px + ${token.spacing2} + ${token.spacing2});
    align-self: flex-start;
    text-align: center;
    padding: ${token.spacing2};
    align-items: center;
    & hr {
        width: 100%;
    }
    & table {
        margin-bottom: ${token.spacing4};
    }
`;

export const IconContainer = styled.div<{ importStatus?: boolean }>`
    align-self: center;
    height: 128px;
    width: 128px;
    border-radius: 50%;
    background-color: ${({ importStatus }) => {
        if (importStatus === undefined) return token.gray25;
        return importStatus ? token.blue500a10 : token.red500a10;
    }};
    margin-bottom: ${token.spacing5};
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
        fill: ${({ importStatus }) => {
            if (importStatus === undefined) return token.gray400;
            return importStatus ? token.blue500 : token.red500;
        }};
        width: 40px;
        height: auto;
        max-height: 40px;
    }
`;

export const Title = styled.div`
    ${textStyles.headingM}
    color: ${token.gray1000};
    word-break: break-word;
`;

export const AdditionalInfo = styled.div`
    margin-top: ${token.spacing4};
    margin-bottom: calc(${token.spacing6} + 4px);
    display: flex;
    width: 472px;
    justify-content: center;
`;

export const FileImportColumn = styled.div`
    flex-wrap: wrap;
    margin-top: ${token.spacing2};
    display: flex;
    width: 472px;
    justify-content: center;
`;

export const FileImportColumnTitle = styled.div`
    ${textStyles.paragraphM}
    color: ${token.gray800};
    width: 100%;
    text-align: center;
    margin-top: ${token.spacing4};
`;

export const FileImportListItem = styled(List.Item)`
    ${textStyles.paragraphM}
    color: ${token.gray800};
    margin: ${token.spacing2} 0;
    text-align: left;
    justify-content: flex-start;
    padding: 0;

    & > div {
        line-height: inherit !important;
    }

    & > div:first-child {
        height: 24px;
        margin-right: ${token.spacing2};

        div {
            height: 20px;

            svg {
                vertical-align: unset !important;
            }
        }
    }
`;

export const StyledTableCell = styled.td`
    text-align: left;
    width: 50%;
    padding: 0 0 0 6px;
`;

export const StyledTableTitle = styled(StyledTableCell)`
    text-align: right;
    padding: 0 6px 0 0;
`;

export const FileImportListItemTitle = styled(FileImportListItem)`
    ${textStyles.paragraphM}
    color: ${token.gray600};
    text-align: right;
    justify-content: flex-end;
    padding: 0;
    max-width: 220px;
`;

export const ProgressContainer = styled.div`
    width: 224px;
`;

export const StyledIcon = styled.div`
    display: inline;
    margin-left: ${token.spacing3};

    & > div {
        display: inline;

        svg {
            vertical-align: unset !important;
        }
    }
`;

export const UploadButtons = styled.div`
    & > :first-child {
        margin-right: ${token.spacing3};
    }
    margin-bottom: ${token.spacing4};
`;

export const DropdownContainer = styled.div`
    padding: ${token.spacing4} 0 0 0;
`;
