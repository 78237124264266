import React, { useContext } from 'react';
import { matchPath, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { VerticalNav } from '@bynder/design-system';
import {
    IconMasonry,
    IconFolder,
    IconUserGroup,
    IconCollection,
    IconVideoTemplate,
    IconColorStyle,
} from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { IconUpload } from '@bynder/icons';
import { getCreatives } from 'packages/store/creatives/creatives.selectors';
import AuthorizationHelper from '~/helpers/AuthorizationHelper';
import {
    getDesignsDefaultLink,
    getSharedWithMeDefaultLink,
    getProjectsDefaultLink,
    getBrandColorsLink,
    getCategoriesLink,
    getUploadsDefaultLink,
} from '~/helpers/defaultLinks';
import features from '~/configs/features';
import permissions from '~/configs/permissions';
import useAccessRights from 'packages/hooks/useAccessRights';
import roles from '~/configs/roles';
import { SkeletonContext } from 'packages/skeleton/SkeletonContext';
import { GetDefaultSidebarItems, SidebarGroupItemType, SidebarItemType, SidebarProps } from './types';
import { StyledSectionTitle, StyledSidebar, StyledSectionDivider } from './Sidebar.styled';
import SidebarSkeleton from './SidebarSkeleton';

const filterHidden = <T extends { hide?: boolean }>(item: T) => !item.hide;
const getDefaultSidebarItems: GetDefaultSidebarItems = (isPlatformAllowed, isAccountRoleAssigned) => ({
    top: [
        {
            title: 'pages.sidebar.designs',
            to: getDesignsDefaultLink(),
            icon: <IconMasonry size="24" />,
            hide: !AuthorizationHelper.hasPermission(permissions.CREATIVES.READ),
        },
        {
            title: 'pages.sidebar.sharedWithMe',
            to: getSharedWithMeDefaultLink(),
            icon: <IconUserGroup />,
            hide:
                !AuthorizationHelper.hasPermission(permissions.CREATIVES.READ) ||
                (isPlatformAllowed([features.TRIAL_ENABLED]) &&
                    isAccountRoleAssigned(roles.video_brand_studio.video.manage)),
        },
        {
            title: 'pages.sidebar.projects',
            to: getProjectsDefaultLink(),
            icon: <IconFolder />,
            hide: !AuthorizationHelper.hasPermission(permissions.CREATIVES.READ),
        },
        {
            title: 'pages.sidebar.assets',
            to: getUploadsDefaultLink(),
            icon: <IconCollection />,
            hide:
                !AuthorizationHelper.hasPermission(permissions.ASSETS.READ) ||
                !AuthorizationHelper.isFeatureAvailable(features.INTERNAL_ASSET),
            matchPath: ['/assets', '/uploads', '/collections'],
        },
        {
            title: 'pages.sidebar.exports',
            to: '',
            icon: <IconUpload />,
            hide: true,
        },
        {
            isGroup: true,
            title: 'pages.sidebar.brand.group.title',
            items: [
                {
                    title: 'pages.sidebar.categories',
                    to: getCategoriesLink(),
                    hide: isAccountRoleAssigned(roles.video_brand_studio.video.manage),
                    icon: <IconVideoTemplate />,
                },
                {
                    title: 'pages.sidebar.brand.colors',
                    to: getBrandColorsLink(),
                    hide: isAccountRoleAssigned(roles.video_brand_studio.video.manage),
                    icon: <IconColorStyle />,
                },
            ].filter(filterHidden),
        },
    ].filter(filterHidden),
    bottom: [].filter(filterHidden),
});

const pathToRegExp = (path: string) => {
    const pattern = path.replace(/:[^:\/]*/g, '[^/]*').replace(/\//g, '\\/');

    return new RegExp(`\^${pattern}\/?$`, 'i');
};

const hidePaths: RegExp[] = [
    '/designs/:creativeId',
    '/designs/:creativeId/edit',
    '/templates/:creativeId/edit',
    // ...
].map(pathToRegExp);

const hasSidebar = (path: string) => !hidePaths.some((pattern) => pattern.test(path));

const Sidebar = ({ pathname, getSidebarItems = getDefaultSidebarItems }: SidebarProps) => {
    const { isPlatformAllowed, isAccountRoleAssigned } = useAccessRights();
    const { translate } = useTranslate();
    const { skeletonShow } = useContext(SkeletonContext);
    const { loadingData } = useSelector(getCreatives);
    const sanitizedPath = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;

    if (!hasSidebar(sanitizedPath)) {
        return null;
    }

    const isActive = (item: SidebarItemType) => {
        if (item.matchPath) {
            return item.matchPath.some(
                (path) =>
                    !!matchPath(
                        {
                            path: path.split('?')[0],
                            end: true,
                            caseSensitive: false,
                        },
                        sanitizedPath,
                    ),
            );
        }

        return !!matchPath(
            {
                path: item.to.split('?')[0],
                end: true,
                caseSensitive: false,
            },
            sanitizedPath,
        );
    };

    const preventRaceConditionOnClick = (item: SidebarItemType) => (e?: Event) => {
        if (isActive(item)) {
            e?.preventDefault();
        }

        const isDesign = matchPath(
            {
                path: getDesignsDefaultLink(),
                strict: false,
            },
            sanitizedPath,
        );
        const isShared = matchPath(
            {
                path: getSharedWithMeDefaultLink(),
                strict: false,
            },
            sanitizedPath,
        );

        if (loadingData && (isDesign || isShared)) {
            e?.preventDefault();
        }
    };

    const renderSidebarItem = (item: SidebarItemType) => (
        <VerticalNav.Item
            key={item.to}
            as={NavLink}
            to={item.to}
            icon={item.icon}
            onClick={preventRaceConditionOnClick(item)}
            isActive={isActive(item)}
            data-testid="page sidebar item"
        >
            {translate(item.title)}
        </VerticalNav.Item>
    );

    const renderGroup = (item: SidebarGroupItemType) =>
        !item.hide &&
        item.items.some((sidebarItem) => !sidebarItem.hide) && (
            <React.Fragment key={item.title}>
                <StyledSectionDivider />
                <VerticalNav.Title>
                    <StyledSectionTitle>{translate(item.title)}</StyledSectionTitle>
                </VerticalNav.Title>
                {item.items.map((nestedItem) => renderSidebarItem(nestedItem))}
            </React.Fragment>
        );

    return (
        <StyledSidebar data-testid="page sidebar">
            {skeletonShow ? (
                <SidebarSkeleton />
            ) : (
                ['top', 'bottom'].map((type) => (
                    <VerticalNav key={type}>
                        {getSidebarItems(isPlatformAllowed, isAccountRoleAssigned)[type].map((item) =>
                            !item.isGroup ? renderSidebarItem(item) : item.items.length ? renderGroup(item) : null,
                        )}
                    </VerticalNav>
                ))
            )}
        </StyledSidebar>
    );
};

export default Sidebar;
