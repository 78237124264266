import { CompositeOperationTypes } from '../../Enums/CompositeOperationTypes';

export type BlendModeParams = {
    operation: CompositeOperationTypes;
};

type SnakeToKebabCase<S extends string> = S extends `${infer T}_${infer U}`
    ? `${Lowercase<T>}-${SnakeToKebabCase<U>}`
    : Lowercase<S>;

export type BlendModeOperation = SnakeToKebabCase<keyof typeof CompositeOperationTypes>;

export class BlendMode {
    operation: CompositeOperationTypes = CompositeOperationTypes.SOURCE_OVER;

    constructor(params: BlendModeParams) {
        this.operation = params.operation;
    }

    getCompositeOperation(): BlendModeOperation {
        return this.operation.toLowerCase().replace('_', '-') as BlendModeOperation;
    }

    toObject() {
        return {
            operation: this.operation,
        };
    }
}
