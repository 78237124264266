import styled from 'styled-components';
import { Button, token } from '@bynder/design-system';

export const EditorImageWrapper = styled.div`
    padding-left: 16px;
    background-color: ${token.gray25};
    min-height: 600px;
    overflow: hidden;
    position: relative;
`;

export const ShortcutsInfoButton = styled(Button)<{ type: string }>`
    position: absolute;
    bottom: ${({ type }) => (type === 'VIDEO' ? 0 : token.spacing5)};
    right: ${({ type }) => (type === 'VIDEO' ? '20px' : 0)};
`;
