import React, { useEffect, useState } from 'react';
import { Input } from '@bynder/design-system';

export type Props = { isLinked: boolean; elementValue: any } & React.ComponentPropsWithRef<typeof Input>;

function InputLinked({ isLinked, value, elementValue, ...props }: Props) {
    const [linked, setLinked] = useState(isLinked);

    useEffect(() => {
        setLinked(isLinked);
    }, [isLinked]);

    const onFocus = (event) => {
        setLinked(false);

        if (props.onFocus) {
            props.onFocus(event);
        }
    };

    const onBlur = (event) => {
        if (elementValue.toString() === value.toString() && isLinked) {
            setLinked(true);
        }

        if (props.onBlur) {
            props.onBlur(event);
        }
    };

    return (
        <Input
            {...props}
            isReadOnly={linked}
            placeholder={linked ? value : undefined}
            value={linked ? '' : value}
            onFocus={onFocus}
            onBlur={onBlur}
        />
    );
}

export default InputLinked;
