import React, { useMemo } from 'react';
import { TemplateElement } from '@bynder-studio/render-core';
import { List } from '@bynder/design-system';
import ShotItem from 'packages/pages/design/sidebar/shots/ShotItem';
import { flattenTree } from '~/common/editor/helpers/elementtree';
import ShotItemGlobal from 'packages/pages/design/sidebar/shots/ShotItemGlobal';
import { useReviewStatus } from 'packages/pages/design/sidebar/shots/useReviewStatus';
import ShotReviewBanner from 'packages/pages/design/sidebar/shots/ShotReviewBanner';
import { getImageDesignEditableElements, getGlobalEditableElements } from './utils';

type Props = {
    elements: TemplateElement;
    isToolbarDisabled: boolean;
    isLoading: boolean;
};

const ShotImageDesign = ({ elements, isToolbarDisabled, isLoading }: Props) => {
    const { isDisabledByReview } = useReviewStatus();

    const editableElements = useMemo(
        () => getImageDesignEditableElements(flattenTree(elements) as TemplateElement),
        [elements],
    );
    const globalEditableElements = useMemo(
        () => getGlobalEditableElements(flattenTree(elements) as TemplateElement),
        [elements],
    );

    return (
        <List>
            <ShotReviewBanner />
            {globalEditableElements.map((element, idx) => (
                <ShotItemGlobal
                    key={element.id}
                    id={element.id}
                    isDisabled={isDisabledByReview || isToolbarDisabled}
                    isLast={idx === globalEditableElements.length - 1}
                />
            ))}
            {editableElements.map((element, idx) => (
                <ShotItem
                    key={element.id}
                    isLast={editableElements.length === idx + 1}
                    shotElement={element}
                    isToolbarDisabled={isToolbarDisabled}
                    isLoading={isLoading}
                    isDisabledByReview={isDisabledByReview}
                />
            ))}
        </List>
    );
};

export default ShotImageDesign;
