import ProxyServerConnector from './connectors/ProxyServerConnector';

const fieldsMap = new Map([
    ['name', 'FILENAME'],
    ['size', 'FILESIZE'],
    ['date', 'CREATED'],
    ['date_created', 'CREATED'],
    ['UPDATED', 'UPDATED'],
    ['date_updated', 'UPDATED'],
    ['type', 'EXTENSION'],
    ['resolution', 'RESOLUTION'],
    ['duration', 'DURATION'],
]);

class AssetsService extends ProxyServerConnector {
    constructor(path = '/assets') {
        super(path);
        this.gateway = new ProxyServerConnector(path, GATEWAY_PREFIX);
    }

    getAssets({ sort, page, filter, search, size, customerId, thumbnails, type, collectionId, includeAssetId }) {
        const types = Array.isArray(type) ? type : [type];

        return this.get(
            `?customerId=${customerId}&` +
                `thumbnails=${thumbnails}&` +
                `size=${size}&` +
                `sortingField=${fieldsMap.get(sort[0])}&` +
                `sortingOrder=${sort[1].toUpperCase()}${filter !== 'all' ? '&status=' + filter : ''}&` +
                `page=${page}` +
                `${search ? '&query=' + search : ''}` +
                `${type ? types.reduce((accum, current) => (accum += '&type=' + current), '') : ''}` +
                `${collectionId ? '&collectionId=' + collectionId : ''}` +
                `${includeAssetId ? '&includeAssetId=' + includeAssetId : ''}` +
                '&includeCollectionIds=true',
        ).then(({ json, status }) => {
            return {
                assets: json ? json.items : null,
                status,
                totalCount: json ? json.totalItems : null,
                page: json.page,
            };
        });
    }

    getAsset(id, thumbnails) {
        const path = `/${id}?` + `${thumbnails ? 'thumbnails=' + thumbnails : ''}` + '&includeCollectionIds=true';

        return this.get(path).then(({ json, status }) => {
            return {
                asset: json,
                status,
            };
        });
    }

    getUploadOptions(customerId) {
        return this.get(`/uploadOptions/${customerId}`).then(({ json, status }) => {
            return {
                json,
                status,
            };
        });
    }

    saveAssets(opt, queryParams) {
        const { thumbnails } = queryParams;

        let path = '';
        if (thumbnails) {
            path += `?thumbnails=${thumbnails}`;
        }

        return this.gateway.post(path, opt).then(({ json, status }) => {
            return {
                path: json,
                status,
            };
        });
    }

    deleteAssets(opt) {
        return this.delete('/', opt).then(({ json, status }) => {
            return {
                path: json,
                status,
            };
        });
    }

    editFileName(id, fileName) {
        return this.put(`/${id}`, { fileName }).then(({ json, status }) => {
            return {
                asset: json,
                status,
            };
        });
    }

    createAssets(customerId, items) {
        return this.gateway.post('/store-path', { customerId, items }).then(({ json }) => json?.items || []);
    }

    updateAssetStatus(data) {
        return this.gateway.post('/update-status', data);
    }
}

export default new AssetsService();
