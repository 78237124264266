import React from 'react';
import { Button, EmptyState, token } from '@bynder/design-system';
import { IconSearch } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import filtersStorage from 'packages/hooks/filtersStorage';
import useQueryParams from 'packages/hooks/useQueryParams';

export const isFilteredList = (queryParams: URLSearchParams) =>
    queryParams.has('search') ||
    queryParams.has('typeTemplate') ||
    queryParams.has('typeDesign') ||
    queryParams.has('approvalStatus');

type Props = { storageKey: string; onResetFilters: Function };
const EmptyFilteredState = ({ storageKey, onResetFilters }: Props) => {
    const { translate } = useTranslate();
    const { setFiltersToStorage } = filtersStorage();
    const { getAllQueryParams, resetQueryParams } = useQueryParams();

    return (
        <EmptyState
            icon={<IconSearch size="48" style={{ color: token.gray400a }} />}
            title={translate('pages.empty.filtered.title')}
            text={translate('pages.empty.filtered.info')}
            actions={
                <Button
                    variant="secondary"
                    onClick={() => {
                        resetQueryParams(['search', 'typeTemplate', 'typeDesign', 'approvalStatus']);

                        setFiltersToStorage(storageKey, getAllQueryParams());
                        onResetFilters();
                    }}
                >
                    {translate('pages.empty.filtered.reset')}
                </Button>
            }
        />
    );
};

export default EmptyFilteredState;
