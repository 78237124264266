import React, { useEffect, useMemo, useState } from 'react';
import { AnimationTypes } from '@bynder-studio/render-core';
import { Dropdown, Form, List } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import generateTestId from '~/helpers/testIdHelpers';
import { AnimationsMap } from '../animationOptions';
import {
    FLIP_TYPES,
    getAnimationPropertyOptions,
    getAnimationType,
    getEnhancedAnimations,
    getGroupedTypes,
    isImportedAnimation,
} from '../utils';
import AnimationField from '../components/AnimationField';

const keepOldValues = ['duration', 'timing', 'startFrame', 'textBreakup'];
const VERTICAL_POSITION = 'verticalPosition';
const HORIZONTALPOSITION = 'horizontalPosition';
const MIN_ROTATE_DURATION = 2;

const Animate = ({ selectedElement, animations, values, defaults, onChange, frameRate, disabled, startFrame }) => {
    const [activeAnimation, setActiveAnimation] = useState(
        AnimationsMap[selectedElement?.animations?.[0]?.config?.type],
    );
    const { translate } = useTranslate();
    const { locked, duration } = selectedElement;
    const animationStartFrame = selectedElement?.animations?.[0]?.startFrame;

    const isActive = useMemo(
        () => Object.keys(AnimationsMap).includes(selectedElement?.animations?.[0]?.config?.type) || false,
        [selectedElement?.animations?.[0]?.config?.type],
    );

    const types = useMemo(() => getEnhancedAnimations(animations, AnimationsMap), [animations]);

    const { x, y } = selectedElement.position;
    const moveInConfigs = types[AnimationTypes.MOVE]?.properties;

    const findPositionProps = moveInConfigs?.filter(
        (prop) => prop.name === VERTICAL_POSITION || prop.name === HORIZONTALPOSITION,
    );

    findPositionProps?.map((prop) => {
        if (prop.label === 'X') {
            prop.defaultValue = parseInt(x, 10);
        }

        if (prop.label === 'Y') {
            prop.defaultValue = parseInt(y, 10);
        }
    });

    const getTypeDefaults = (type) => {
        if (type.type === 'OFF') return null;

        const result = {};
        type.properties.forEach((property) => {
            result[property.name] =
                (defaults && defaults[property.name]) ||
                (keepOldValues.includes(property.name) && values
                    ? values[property.name] || property.defaultValue
                    : property.defaultValue);
        });

        return { ...result, type: type.type };
    };

    const handleChangeProperty = (property, newValue) => {
        const animationType = getAnimationType(values);
        const newValues: any = { [property]: newValue };

        if (
            FLIP_TYPES.includes(animationType) &&
            property === 'breakup' &&
            newValue === '1' &&
            values.direction === 'RANDOM'
        ) {
            newValues.direction = 'BOTTOM_TO_TOP';
        }

        const data = { ...values, ...newValues };
        onChange('animations', data);
    };

    const onToggle = (animation) => {
        if (animation.type === 'OFF') {
            onChange('animations', null);
        } else {
            const data = getTypeDefaults(animation) as any;
            const newData = {
                ...data,
                duration: values && typeof values.duration === 'number' ? values.duration : duration,
            };

            newData.startFrame = (values && values.startFrame) || selectedElement.startFrame;

            setActiveAnimation(animation);
            onChange('animations', newData);
        }
    };

    const onAnimationChange = (animation) => {
        const data = getTypeDefaults(animation) as any;

        setActiveAnimation(animation);

        if (animation.type === 'MOVE') {
            data.horizontalPosition = Math.round(x);
            data.verticalPosition = Math.round(y);
        }

        onChange('animations', data);
    };

    const testId = 'shots_styling_animation';
    const animationItem = generateTestId(testId);

    const sortedTypes =
        (activeAnimation &&
            types[activeAnimation.type]?.properties
                .filter((property) => property.visible && property.name !== 'textBreakup')
                .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))) ||
        [];

    const groupedTypes = getGroupedTypes(sortedTypes);

    const renderAnimationField = (property) => {
        if (!values) {
            return null;
        }

        const value = values ? values[property.name] : defaults[property.name];
        const options = getAnimationPropertyOptions(activeAnimation.type, property, values);

        return value !== undefined ? (
            <AnimationField
                key={`key_${activeAnimation.type}_${property.name}`}
                id={`key_${activeAnimation.type}_${property.name}`}
                field={property}
                value={value}
                minValue={property.name === 'duration' ? MIN_ROTATE_DURATION : undefined}
                options={options}
                frameRate={frameRate}
                startFrame={startFrame}
                animationStartFrame={animationStartFrame}
                inverseAnimationValue={property.name === 'duration' ? animationStartFrame : 0}
                onChange={handleChangeProperty}
                disabled={locked || (disabled && property.name !== 'duration')}
                col={isImportedAnimation(activeAnimation.type) ? 12 : undefined}
                testId={`${testId}__${property.name}`}
                elementDuration={duration}
                position={selectedElement.position}
            />
        ) : null;
    };

    useEffect(() => {
        setActiveAnimation(AnimationsMap[selectedElement?.animations?.[0]?.config?.type]);
    }, [selectedElement.id]);

    useEffect(() => {
        if (!values) {
            onChange('animations', null);
        }
    }, [values]);

    return (
        <>
            <List.Item
                checkedVariant="switch"
                onClick={() => onToggle(isActive ? types.OFF : types[AnimationsMap.BLINK.type])}
                isChecked={isActive}
                isDisabled={disabled}
                {...animationItem}
            >
                {translate('editor.sidebar.shots.animation.animate.label')}
            </List.Item>
            {isActive && (
                <>
                    <Dropdown
                        trigger={({ isOpen, ...triggerProps }) => (
                            <List.Item
                                isSelected={isOpen}
                                isDisabled={disabled}
                                rightElements={<Dropdown.Arrow />}
                                thumbnail={AnimationsMap[activeAnimation?.type]?.icon}
                                {...triggerProps}
                                {...generateTestId(`${testId}__dropdown`)}
                            >
                                {AnimationsMap[activeAnimation?.type]?.value}
                            </List.Item>
                        )}
                        position="bottom"
                    >
                        {Object.values(AnimationsMap).map((animation) => (
                            <Dropdown.Item
                                key={animation.value.toString()}
                                onClick={() => onAnimationChange(types[animation.type])}
                                isChecked={animation.type === activeAnimation?.type}
                            >
                                {animation.value}
                            </Dropdown.Item>
                        ))}
                    </Dropdown>

                    {groupedTypes.map((property) => {
                        if (!Array.isArray(property)) {
                            return renderAnimationField(property);
                        }

                        return (
                            <Form.Row key={property[0]?.name}>
                                {property.map((nestedProperty) => renderAnimationField(nestedProperty))}
                            </Form.Row>
                        );
                    })}
                </>
            )}
        </>
    );
};

export default Animate;
