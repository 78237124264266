export enum AnimationTimingFunctionTypes {
    LINEAR = 'LINEAR',
    EASE = 'EASE',
    EASE_IN_OUT = 'EASE_IN_OUT',
    EASE_IN_MINIMUM = 'EASE_IN_MINIMUM',
    EASE_IN_MEDIUM = 'EASE_IN_MEDIUM',
    EASE_IN_MAXIMUM = 'EASE_IN_MAXIMUM',
    EASE_OUT_MINIMUM = 'EASE_OUT_MINIMUM',
    EASE_OUT_MEDIUM = 'EASE_OUT_MEDIUM',
    EASE_OUT_MAXIMUM = 'EASE_OUT_MAXIMUM',
}
