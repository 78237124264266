import React, { useCallback, useMemo } from 'react';
import useExport from '../hooks/useExport';
import useDesign from 'packages/pages/design/hooks/useDesign';
import { isVariationInvalid } from 'packages/pages/design/sidebar/variations/utils';
import { catchResponseError } from 'packages/helpers/helpers';
import VariationSetsService from '~/services/VariationSetsService';
import { ActionCheckbox } from './List.styled';

export const SelectAllCheckbox = () => {
    const {
        items,
        totalValidItems,
        selectedCount,
        selectAll,
        deselectAll,
        setSelectedIds,
        setSelectedCount,
        total,
        selectedIds,
    } = useExport();

    const { creativeVersionId } = useDesign();

    const fetchAndSelectAll = () => {
        VariationSetsService.fetchVariationSetsByAggregate(creativeVersionId, { limit: total })
            .then(({ status, json }) => {
                if (status === 200) {
                    const data = json.items;
                    const selectedIds = {};

                    if (data) {
                        data.forEach((item) => {
                            item.entries.forEach((entry) => {
                                if (!isVariationInvalid(entry)) {
                                    selectedIds[entry.variationId] = true;
                                }
                            });
                        });
                    }

                    setSelectedIds(selectedIds);
                    setSelectedCount(Object.keys(selectedIds).length);
                }
            })
            .catch(catchResponseError);
    };

    const isAllSelected = useMemo(() => {
        return (
            totalValidItems > 0 &&
            selectedCount !== 0 &&
            (selectedCount === totalValidItems || selectedCount === Object.keys(selectedIds).length)
        );
    }, [totalValidItems, selectedCount]);

    const handleChange = useCallback(() => {
        if (isAllSelected) {
            deselectAll();
        } else {
            if (items.length < total) {
                fetchAndSelectAll();
            } else {
                selectAll();
            }
        }
    }, [isAllSelected, selectAll, deselectAll]);

    return (
        <ActionCheckbox
            isChecked={isAllSelected}
            isIndeterminate={!!selectedIds.length && !isAllSelected}
            isDisabled={!totalValidItems}
            onChange={handleChange}
        />
    );
};
