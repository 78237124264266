import styled from 'styled-components';
import { Flex, token } from '@bynder/design-system';

export const DesignContainer = styled(Flex)`
    flex-grow: 1;
`;

export const DesignCanvasWrapper = styled(Flex)<{ creativetype: 'IMAGE' | 'VIDEO' }>`
    height: ${(props) => (props.creativetype === 'VIDEO' ? 'calc(100% - 200px)' : '100%')};
    flex: 0 0 100%;
`;

export const DesignCanvasPaddingWrapper = styled.div<{ showpagepreviews: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-shrink: 0;

    width: 100%;
    max-width: ${(props) => (props.showpagepreviews === 'true' ? 'calc(100% - 148px)' : '100%')};
    padding-left: ${token.spacing6};
    padding-right: ${token.spacing6};
    height: ${`calc(100% - ${token.spacing9})`};
    padding-top: ${token.spacing6};
`;

export const DesignBuffer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
`;

export const DesignCanvas = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
`;

export const DesignCanvasBorder = styled.div<{ isloading: string }>`
    position: absolute;
    top: 50%;
    left: 50%;

    width: 100%;
    height: 100%;

    transform: translate(-50%, -50%);

    box-shadow: inset 0 0 0 1px ${token.gray100a};
    pointer-events: none;
    background-color: ${({ isloading }) => (isloading === 'true' ? token.gray10 : 'none')};
`;

export const DesignSidebar = styled.div`
    flex: 0 0 auto;
    height: 100%;
    overflow: hidden;
`;

export const DesignWrapper = styled.div`
    display: flex;
    flex: 0 0 100%;
    margin-bottom: -20px;
    overflow: hidden;
`;

export const DesignMain = styled(Flex)`
    flex-grow: 1;
`;

export default DesignContainer;
