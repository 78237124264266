import { BaseCompElement, Position, Scrollbars, truthy } from '@bynder-studio/render-core';
import CanvasManipulationCompositor from './CanvasManipulationCompositor';
import ManipulationEmitter from './ManipulationEmitter';

abstract class BaseManipulation {
    containerDocument: Document;

    canvas: HTMLCanvasElement = null;

    compositor: CanvasManipulationCompositor;

    emitter: ManipulationEmitter;

    abstract unsubscribe(): void;

    constructor(compositor: CanvasManipulationCompositor, containerDocument, emitter) {
        this.compositor = compositor;
        this.containerDocument = containerDocument;
        this.emitter = emitter;
        this.canvas = this.compositor.getCanvas();
    }

    getElementToSelect(): number | null {
        if (!this.compositor.currentCompModel) {
            return null;
        }

        this.compositor.drawGhostCompModel(this.compositor.currentCompModel);
        // @ts-ignore
        const elements: BaseCompElement[] = this.compositor.currentCompModel
            .getAllCompElementsRecursively()
            .filter(truthy);
        const calculatedMousePosition: Position = this.compositor.mousePosition.multiply(
            this.compositor.getScale() * this.compositor.devicePixelRatio,
        );
        const elId = this.compositor.getElementIdForPoint(calculatedMousePosition);

        if (([Scrollbars.HORIZONTAL, Scrollbars.VERTICAL] as number[]).includes(elId as number)) {
            return elId as number;
        }

        const elementToSelect = elements.find((el) => el.id === elId);

        const checkElementParent = (elementToCheck, childEl) => {
            if (elementToCheck) {
                if (elementToCheck?.parent?.id) {
                    const parentAlreadyChosen =
                        elementToCheck?.parent?.id === this.compositor.currentSelectedGroupId ||
                        elementToCheck?.parent?.id === this.compositor.currentParentId;

                    if (parentAlreadyChosen) {
                        return elementToCheck.id;
                    }

                    const parentElement = elements.find((el) => el.id === elementToCheck.parent.id);

                    return checkElementParent(parentElement, childEl);
                }

                if (elementToCheck.id === this.compositor.currentParentId) {
                    return null;
                }

                return elementToCheck.id;
            }

            return null;
        };

        return checkElementParent(elementToSelect, elementToSelect);
    }

    getElementById(id: number): BaseCompElement {
        if (!this.compositor.currentCompModel) {
            return null;
        }

        // @ts-ignore
        return this.compositor.currentCompModel.getAllCompElementsRecursively().find((el) => el.id === id);
    }

    requestToChangeCursor(newValue) {
        if (this.canvas.style.cursor !== newValue) {
            this.canvas.style.cursor = newValue;
        }
    }
}

export default BaseManipulation;
