import React, { useCallback } from 'react';
import { Dropdown } from '@bynder/design-system';
import { IconDelete } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { ActionStatus, Entity, TemplateAction } from 'packages/hooks/useAccessRights/types';
import type { IsAvailable, Props } from './types';
import useAccessRights from 'packages/hooks/useAccessRights';

export const isDeleteAvailable: IsAvailable = ({ isEntityActionAllowed, hiddenActions, template }) =>
    isEntityActionAllowed(TemplateAction.DELETE, Entity.TEMPLATE, template.grantedPermissions) !==
        ActionStatus.NOT_ALLOWED && !hiddenActions.includes('setDeleteModalTemplate');

export const Delete = ({ template, menuActions }: Props) => {
    const { isEntityActionAllowed } = useAccessRights();
    const { translate } = useTranslate();

    const onClick = useCallback(() => menuActions.setDeleteModalTemplate?.([template]), [menuActions, template]);

    const isDisabled =
        isEntityActionAllowed(TemplateAction.DELETE, Entity.TEMPLATE, template.grantedPermissions) ===
        ActionStatus.DISABLED;

    return (
        <Dropdown.Item isDisabled={isDisabled} icon={<IconDelete />} onClick={onClick}>
            {translate('pages.categories.category.template.menu.delete')}
        </Dropdown.Item>
    );
};
