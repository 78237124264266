import { createSelector } from 'reselect';
import { EntityGrantedPermissions, SharingState } from './types';

export const getSharing = (state: any): SharingState => state.sharing;
export const getSearchIdentities = (state: any): SharingState['searchIdentities'] => state.sharing.searchIdentities;

const getGrantedPermissions = createSelector(
    (state: any) => getSharing(state),
    (sharing): EntityGrantedPermissions[] => {
        const entityPermissions = (sharing.entityGrantedPermissions ?? []).map((element) => {
            let fullName = '';

            if (element.securityIdentity.firstName) {
                fullName = `${element.securityIdentity.firstName}`;

                if (element.securityIdentity.lastName) {
                    fullName += ` ${element.securityIdentity.lastName}`;
                }
            } else if (element.securityIdentity.lastName) {
                fullName = `${element.securityIdentity.lastName}`;
            }

            return {
                ...element,
                securityIdentity: {
                    ...element.securityIdentity,
                    fullName,
                },
                entityId: sharing.entityId,
            };
        });

        return entityPermissions;
    },
);

export const getEntityGrantedPermissions = createSelector(getGrantedPermissions, (permissions) =>
    permissions.filter((item) => item.grantedPermissions && item.grantedPermissions.length),
);

export const getProjectGrantedPermissions = createSelector(getGrantedPermissions, (permissions) =>
    permissions.filter((item) => item.projectGrantedPermissions && item.projectGrantedPermissions.length),
);
