import type { ColorParams } from '@bynder-studio/misc';

export type DropShadowParams = {
    state: string;
    offset: number;
    angle: number;
    blurRadius: number;
    color: ColorParams;
};

export class DropShadow {
    state: string;
    offset: number;
    offsetX: number;
    offsetY: number;
    angle: number;
    blurRadius: number;
    color: ColorParams;

    constructor(rawDropShadow: DropShadowParams) {
        this.state = rawDropShadow?.state ?? 'ENABLE';
        this.offset = rawDropShadow.offset;
        this.angle = rawDropShadow.angle;
        this.blurRadius = rawDropShadow.blurRadius;
        this.color = rawDropShadow.color;
        const angleRadian = this.angle * (Math.PI / 180);
        this.offsetX = Math.round(this.offset * Math.sin(angleRadian));
        this.offsetY = -Math.round(this.offset * Math.cos(angleRadian));
    }

    getState(): string {
        return this.state;
    }

    getOffset(): number {
        return this.offset;
    }

    getOffsetX(): number {
        return this.offsetX;
    }

    getOffsetY(): number {
        return this.offsetY;
    }

    getAngle(): number {
        return this.angle;
    }

    getBlurRadius(): number {
        return this.blurRadius;
    }

    getColor(): ColorParams {
        return this.color;
    }

    toObject() {
        // todo: Uncomment after release
        // if (this.state === 'DISABLE') {
        //     return null;
        // }

        return {
            offset: this.offset,
            angle: this.angle,
            blurRadius: this.blurRadius,
            color: this.color,
            state: this.state,
        };
    }
}
