import { SocketSubscribeConfig } from '../socketSubscribe';

type Options = SocketSubscribeConfig<
    {
        jobId: number;
        status: string;
    },
    {
        variationSetId: string;
    }
>;
type Params = {
    jobId: number;
    variationSetId: string;
    onMessage: Options['onMessage'];
};

export function subscribeVariationTranslationsOptions({ jobId, variationSetId, onMessage }: Params): Options {
    return {
        destination: `translation.${jobId}.status`,
        publishBody: {
            variationSetId,
        },
        onMessage,
    };
}
