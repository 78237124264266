import styled from 'styled-components';

export const PortionSelectorContainer = styled.div`
    width: calc(100% + 48px);
    margin-left: -24px;
    padding-left: 24px;
    padding-right: 24px;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const PortionSelectorPlayerWrapper = styled.div`
    margin: 0 auto 20px auto;
`;

export const PortionSelectorPlayer = styled.div`
    height: calc(100% - 158px);
    min-height: 200px;
    border-radius: 5px;
    overflow: hidden;
`;
