import React from 'react';
import { Section, SidebarForm } from '../../index.styled';
import ElementAlignment from '../ElementAlignment';

const MultiSelection = ({ creativeModel, selectedElements }) => (
    <SidebarForm>
        <Section aria-labelledby="shots-multi-selection-settings">
            <ElementAlignment creativeModel={creativeModel} selectedElements={selectedElements} />
        </Section>
    </SidebarForm>
);

export default MultiSelection;
