import React, { useEffect, useState } from 'react';
import { useTranslate } from '@bynder/localization';
import { Form } from '@bynder/design-system';
import { IconTime } from '@bynder/icons';
import editorAutocorrect from 'packages/pages/editor/EditorAutocorrectRules';
import FramePicker from '../../FormComponents/FramePicker';
import { FormSectionContent } from '../../index.styled';

const DurationInput = ({ creativeModel, frameRate, disabled, id }) => {
    const { translate } = useTranslate();
    const [duration, setDuration] = useState(creativeModel?.getPlaybackDuration()?.getDuration());
    const posterFrame = creativeModel.getPosterFrame().frame;

    const handleDurationChange = (newValue) => {
        creativeModel.updatePlaybackDuration(newValue);

        if (newValue <= posterFrame) {
            creativeModel.updateGlobalProperty('POSTER_FRAME', { frame: newValue - 1 });
        }
    };
    const autocorrect = (newValue) => editorAutocorrect('global_duration', newValue, frameRate);

    useEffect(() => {
        setDuration(creativeModel?.getPlaybackDuration()?.getDuration());
    }, [creativeModel?.getPlaybackDuration()?.getDuration()]);

    return (
        <FormSectionContent onSubmit={(event) => event.preventDefault()}>
            <Form.Group>
                <Form.Label htmlFor="global_duration">{translate('editor.sidebar.globals.duration.label')}</Form.Label>
                <FramePicker
                    id={id}
                    name="duration"
                    handleOnChange={handleDurationChange}
                    autocorrect={autocorrect}
                    value={duration}
                    frameRate={frameRate}
                    disabled={disabled}
                    testId="global_duration"
                    icon={<IconTime />}
                    label={translate('editor.sidebar.globals.duration.label')}
                    htmlFor="global_duration"
                    describedby="global_duration_message"
                />
                <Form.HelperText id="global_duration_message">
                    {translate('editor.sidebar.globals.duration.message')}
                </Form.HelperText>
            </Form.Group>
        </FormSectionContent>
    );
};

export default DurationInput;
