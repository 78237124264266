import React, { useEffect, useState } from 'react';
import { Button, Modal, Input, Flex, Form } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { TextStyle } from '@bynder-studio/render-core';
import FontThumbnail from 'packages/pages/editor/RightSideMenu/Shots/Text/TextStyles/shared/FontThumbnail';
import useFonts from 'packages/pages/editor/RightSideMenu/Shots/Text/Fonts/useFonts';
import generateTestId from '~/helpers/testIdHelpers';
import modalContainer from 'packages/common/modalContainer';

type Props = {
    item?: TextStyle;
    isOpen: boolean;
    onClose: () => void;
    renameTextStyle: (styleName: string) => void;
};

const RenameStyleModal = ({ item, isOpen, onClose, renameTextStyle }: Props) => {
    const { currentFont } = useFonts();
    const [value, setNewValue] = useState(item?.name || '');
    const { translate } = useTranslate();

    const MAX_INPUT_SIZE = 120;
    const isLongName = value.trim().length > MAX_INPUT_SIZE;
    const isEmptyName = value.trim().length === 0;

    const handleRenameClick = () => {
        const name = value.trim();

        if (name) {
            renameTextStyle(name);
        }
        onClose();
    };

    useEffect(() => {
        if (item?.name) {
            setNewValue(item.name);
        }
    }, [isOpen]);

    return (
        <Modal
            container={modalContainer}
            isOpen={isOpen}
            onClose={onClose}
            title={translate('editor.sidebar.shots.text.textStyle.modal.rename.title')}
            actionPrimary={
                <Button variant="primary" onClick={handleRenameClick} isDisabled={isEmptyName || isLongName}>
                    {translate('editor.sidebar.shots.text.textStyle.modal.rename.save')}
                </Button>
            }
            actionSecondary={
                <Button variant="secondary" onClick={onClose}>
                    {translate('editor.sidebar.shots.text.textStyle.cancel')}
                </Button>
            }
        >
            <Form onSubmit={handleRenameClick}>
                <Form.Group>
                    <Form.Label htmlFor="label-prefix">
                        {translate('editor.sidebar.shots.text.textStyle.name')}
                    </Form.Label>
                    <Flex gap="3">
                        <FontThumbnail id={currentFont?.id.toString()} url={currentFont?.url} />
                        <Flex direction="column">
                            <Input
                                placeholder={translate('editor.sidebar.shots.text.textStyle.placeholder')}
                                isInvalid={isLongName}
                                value={value}
                                onChange={setNewValue}
                                hasClearButton
                                {...generateTestId('shots_text_styles_modal_rename_input')}
                            />
                            {isLongName && (
                                <Form.HelperText isInvalid={isLongName}>
                                    {translate('input.error.max.length')}
                                </Form.HelperText>
                            )}
                        </Flex>
                    </Flex>
                </Form.Group>
            </Form>
        </Modal>
    );
};

export default RenameStyleModal;
