import BaseManipulationCompositor from '../BaseManipulationCompositor';

class ScrollbarsManipulationCompositor extends BaseManipulationCompositor {
    constructor(compositor, ctx, dpr) {
        super(compositor, ctx, dpr);
    }

    drawScrollbars() {
        const padding = this.devicePixelRatio * 4;
        const thickness = this.devicePixelRatio * 6;
        const radius = this.devicePixelRatio * 4;
        const stroke = Number(this.devicePixelRatio);
        const offset = padding + thickness + 2 * stroke;
        const workspaceW = this.ctx.canvas.width;
        const workspaceH = this.ctx.canvas.height;
        const { horizontalScaleRatio, horizontalScrollbarPosition, verticalScaleRatio, verticalScrollbarPosition } =
            this.compositor.scrollbarsCalculations();

        const orgTransform = this.ctx.getTransform();
        this.ctx.resetTransform();
        this.ctx.save();

        //set scrollbar style
        this.ctx.globalAlpha = 0.8;
        this.ctx.fillStyle = '#888';
        this.ctx.strokeStyle = '#fff';
        this.ctx.lineWidth = stroke;

        if (1 - Math.abs(horizontalScaleRatio) > 0.0001) {
            this.ctx.beginPath();
            const w = workspaceW - (padding + offset);
            this.drawRoundedRect(
                padding + horizontalScrollbarPosition * w,
                workspaceH - offset,
                horizontalScaleRatio * w,
                thickness,
                radius,
            );
            this.ctx.fill();
        }

        if (1 - Math.abs(verticalScaleRatio) > 0.0001) {
            this.ctx.beginPath();
            const h = workspaceH - (padding + offset);
            this.drawRoundedRect(
                workspaceW - offset,
                padding + verticalScrollbarPosition * h,
                thickness,
                verticalScaleRatio * h,
                radius,
            );
            this.ctx.fill();
        }

        this.ctx.restore();
        this.ctx.setTransform(orgTransform);
    }
}

export default ScrollbarsManipulationCompositor;
