import React from 'react';
import { Grid } from 'packages/ds/Grid/Grid';
import { TemplateCard, TemplateCardProps } from './TemplateCard';
import { Template } from 'packages/store/templates/types';
import { InfinityLoader, type InfinityLoaderProps } from 'packages/pages/components/InfinityLoader/InfinityLoader';

type TemplateGridProps = Pick<
    TemplateCardProps,
    'hiddenMenuActions' | 'menuActions' | 'includeSharedIndicator' | 'onSelect'
> &
    Pick<InfinityLoaderProps, 'isLoading' | 'totalItems' | 'loadMore'> & {
        templates: Template[];
        isTemplateSelected?: (template: Template) => boolean;
        onCardClick: TemplateCardProps['onClick'];
        onContextMenuTriggerClick?: () => void;
    };

const TemplateGrid = ({
    templates,
    isTemplateSelected,
    menuActions,
    hiddenMenuActions,
    includeSharedIndicator,
    isLoading,
    loadMore,
    totalItems,
    onSelect,
    onCardClick,
    onContextMenuTriggerClick,
}: TemplateGridProps) => {
    return (
        <>
            <Grid variant="masonry">
                {templates.map((template) => (
                    <TemplateCard
                        key={template.id}
                        template={template}
                        menuActions={menuActions}
                        hiddenMenuActions={hiddenMenuActions}
                        onClick={onCardClick}
                        isSelected={isTemplateSelected && isTemplateSelected(template)}
                        onSelect={onSelect}
                        includeSharedIndicator={includeSharedIndicator}
                        onContextMenuTriggerClick={onContextMenuTriggerClick}
                    />
                ))}
            </Grid>
            <InfinityLoader
                isLoading={isLoading}
                count={templates.length}
                totalItems={totalItems}
                loadMore={loadMore}
            />
        </>
    );
};

export default TemplateGrid;
