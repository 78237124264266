import { Button } from '@bynder/design-system';
import styled from 'styled-components';

export const BorderAlignButton = styled(Button)`
    justify-content: flex-start;
    text-transform: capitalize;

    & div:last-child {
        margin-left: auto;
    }
`;
