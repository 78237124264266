import BezierEasing from 'bezier-easing';

// t = time lapse from animation beginning
// b = property's start value
// c = property's diff value at the end of animation
// d = animation duration
// In terms of frames:
// t: current frame
// b: start value
// c: value difference (percentage of this will be added to start value)
// d: duration (total frames)
export const linear = (t: number, b: number, c: number, d: number): number => {
    return b + (c * t) / d;
};

export const ease = (t: number, b: number, c: number, d: number): number => {
    const easing = BezierEasing(0.3, 0, 0.2, 1);
    return b + easing(t / d) * c;
};

export const easeInOut = (t: number, b: number, c: number, d: number): number => {
    const easing = BezierEasing(0.5, 0, 0.5, 1);
    return b + easing(t / d) * c;
};

export const easeInMinimum = (t: number, b: number, c: number, d: number): number => {
    const easing = BezierEasing(0.33, 0, 1, 1);
    return b + easing(t / d) * c;
};

export const easeInMedium = (t: number, b: number, c: number, d: number): number => {
    const easing = BezierEasing(0.66, 0, 1, 1);
    return b + easing(t / d) * c;
};

export const easeInMaximum = (t: number, b: number, c: number, d: number): number => {
    const easing = BezierEasing(1, 0, 1, 1);
    return b + easing(t / d) * c;
};

export const easeOutMinimum = (t: number, b: number, c: number, d: number): number => {
    const easing = BezierEasing(0, 0, 0.66, 1);
    return b + easing(t / d) * c;
};

export const easeOutMedium = (t: number, b: number, c: number, d: number): number => {
    const easing = BezierEasing(0, 0, 0.33, 1);
    return b + easing(t / d) * c;
};

export const easeOutMaximum = (t: number, b: number, c: number, d: number): number => {
    const easing = BezierEasing(0, 0, 0, 1);
    return b + easing(t / d) * c;
};
