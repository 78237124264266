import type { IAssetsLoader } from '../../AssetLoader/IAssetsLoader';
import type { ICompositor } from '../../Compositor/ICompositor';
import type { IImageModel } from '../../Models/ImageModel/IImageModel';
import { BaseRenderer } from '../BaseRenderer/BaseRenderer';

export class ImageRenderer extends BaseRenderer {
    constructor(imageModel: IImageModel, assetLoader: IAssetsLoader, compositor: ICompositor) {
        super(imageModel, assetLoader, compositor);
    }

    private handleCompModelUpdate = () => this.redrawCompModel();

    // @ts-ignore
    async init() {
        super.init();
        // @ts-ignore
        const compModel = this.creativeModel.getCompModel();
        this.getCreativeModel().eventEmitter.on('requireCreativeUpdate', this.handleCompModelUpdate);

        // drawing comp model takes time (especially when the tab is not active),
        // all the subscription should be done before drawing
        await this.drawCompModel(compModel);
    }

    redraw() {
        this.redrawCompModel();
    }

    private async redrawCompModel() {
        // @ts-ignore
        await this.drawCompModel(this.creativeModel.getCompModel());
    }
}
