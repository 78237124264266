import styled from 'styled-components';
import { Dropdown, List } from '@bynder/design-system';

export const EditorBrandColorDropdown = styled(Dropdown)<{ isDefault: boolean }>`
    ${({ isDefault }) =>
        !isDefault &&
        `
        min-width: 220px;
    `}
`;

export const StyledDropdown = styled(Dropdown)`
    max-width: calc(100% - 50px);
`;

export const FullWidthButton = styled(List.Item)`
    width: 100%;
    min-width: 220px;
`;
