type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T;

export const truthy = <T>(value: T): value is Truthy<T> => !!value;

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const snakeToCamel = (str: string) =>
    str.toLowerCase().replace(/([_][a-z])/g, (group) => group.toUpperCase().replace('_', ''));

export const isInteractiveElement = (event: Event) => {
    const { target } = event;

    // UCV uses shadowDOM, so the root element is always a div but with shadowRoot
    return (
        target instanceof Element && (['INPUT', 'TEXTBOX', 'TEXTAREA'].includes(target?.tagName) || target?.shadowRoot)
    );
};
