import React, { useState } from 'react';
import DeleteModal from './components/modals/DeleteModal';

export function useContentPropertyDeleteModal({ onDelete }: { onDelete: () => void }) {
    const [isOpen, setIsModalOpen] = useState(false);

    const renderContentPropertyDeleteModal = () => {
        return <DeleteModal isOpen={isOpen} onClose={() => setIsModalOpen(false)} onDelete={onDelete} />;
    };

    return {
        renderContentPropertyDeleteModal,
        setIsContentPropertyDeleteModalOpen: setIsModalOpen,
    };
}
