import React from 'react';
import Modal from './Modal';
import { ExportProvider } from './ExportContext';

const ExportModal = (props) => (
    <ExportProvider>
        <Modal {...props} />
    </ExportProvider>
);

export default ExportModal;
