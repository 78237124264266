import React, { useCallback, useEffect, useState } from 'react';
import useEditorSelectedElement from '~/hooks/useEditorSelectedElement';
import useEditor from '~/hooks/useEditor';
import PersonalUploadsBase from './PersonalUploadsBase';
import { createOnTogglePersonalUploads, isPersonalUploadsAllowed } from './utils';

const PersonalUploads = () => {
    const { selectedElement } = useEditorSelectedElement();
    const { creativeModel } = useEditor();
    const [isAllowed, setAllowed] = useState(() => isPersonalUploadsAllowed(selectedElement));

    const disabled = selectedElement.locked;
    const onToggle = useCallback(() => {
        createOnTogglePersonalUploads(creativeModel, selectedElement);
        setAllowed(isPersonalUploadsAllowed(selectedElement));
    }, [creativeModel, selectedElement]);

    useEffect(() => {
        setAllowed(isPersonalUploadsAllowed(selectedElement));
    }, [selectedElement?.virtualData?.allowPersonalUpload]);

    return <PersonalUploadsBase isAllowed={isAllowed} onToggle={onToggle} disabled={disabled} />;
};

export default PersonalUploads;
