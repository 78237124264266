import { truthy } from '@bynder-studio/misc';
import { ElementTypes } from '../Enums/ElementTypes';
import type { IAsset } from '../Models/Assets/IAsset';
import { AudioElement } from '../Models/Elements/AudioElement';
import { GroupElement } from '../Models/Elements/GroupElement';
import type { IElement } from '../Models/Elements/IElement';
import { ImageElement } from '../Models/Elements/ImageElement';
import { TextElement } from '../Models/Elements/TextElement';
import { VideoElement } from '../Models/Elements/VideoElement';
import { BaseVisualElement } from '../Models/Elements/BaseVisualElement';
import { Ranges, resolverMaksModeTypes } from './Ranges';
import { MaskModeTypes } from '../Enums/MaskModeTypes';
import { ShapeElement } from '../Models/Elements/ShapeElement';
import type { ContentPropertiesSettings } from '../Models/BaseMultiPageModel/IBaseMultiPageModel';

// Returns all elements including groups as a flat structure
export const getAllElementsRecursively = <T extends object>(children: T[]): T[] => {
    const result: T[] = [];
    const stack = [...children.filter<T>(truthy)];

    while (stack.length > 0) {
        const current = stack.pop();
        result.push(current);

        if ('children' in current && Array.isArray(current.children)) {
            const currentChildren = current.children.filter<T>(truthy);

            if (currentChildren.length) {
                stack.push(...currentChildren);
            }
        }
    }

    return result;
};

// Returns elements' children (only non-group elements) as a flat structure
export const getAllChildrenRecursively = <
    TChild extends object,
    TGroup extends TChild & { children: Array<TChild | TGroup> },
>(
    element: TGroup | TChild,
): TChild[] => {
    const result: TChild[] = [];
    const stack = [element];

    while (stack.length > 0) {
        // we use shift to preserve the order of elements
        const current = stack.shift();

        if ('children' in current) {
            stack.push(...current.children);
        } else {
            result.push(current);
        }
    }

    return result;
};

// Returns the quantity of elements' children including groups except
// the element itself as a flast structure
export const getAllElementsQuantityRecursively = <T extends object>(element: T): number => {
    if (!('children' in element)) {
        return 0;
    }

    if (!Array.isArray(element.children) || (Array.isArray(element.children) && !element.children.length)) {
        return 0;
    }

    const stack = [...element.children];
    let result = 0;

    while (stack.length > 0) {
        const current = stack.pop();
        result++;

        if (Array.isArray(current.children) && current.children.length) {
            stack.push(...current.children);
        }
    }

    return result;
};

export const getTopLevelElements = (elements: BaseVisualElement[]) => {
    const topLevelElements = [];

    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const isGroup = element instanceof GroupElement;

        if (isGroup) {
            topLevelElements.push(element);
            i += getAllElementsQuantityRecursively(element);
        } else {
            topLevelElements.push(element);
        }
    }

    return topLevelElements;
};

export const calculateTimelineDuration = (elements: IElement[]): number =>
    elements.reduce((acc, element) => Math.max(acc, (element.startFrame || 0) + (element.duration || 0)), 0);

export const compareByRenderOrder = (elementA: IElement, elementB: IElement): number =>
    (elementA.renderOrder || 0) - (elementB.renderOrder || 0);

export const compareByRenderOrderDesc = (elementA: IElement, elementB: IElement): number =>
    (elementB.renderOrder || 0) - (elementA.renderOrder || 0);

export const getElementType = (element: IElement) => {
    if (element instanceof VideoElement) {
        return ElementTypes.VIDEO;
    }

    if (element instanceof ImageElement) {
        return ElementTypes.IMAGE;
    }

    if (element instanceof TextElement) {
        return ElementTypes.TEXT;
    }

    if (element instanceof GroupElement) {
        return ElementTypes.GROUP;
    }

    if (element instanceof ShapeElement) {
        return ElementTypes.SHAPE;
    }

    if (element instanceof AudioElement) {
        return ElementTypes.GLOBAL_AUDIO;
    }

    return null;
};

export const getElementDiffByAsset = (el: IElement, asset: IAsset) => {
    const keys = ['src', 'isAlpha', 'frameRate'];
    const diff: Record<string, any> = {};

    if (el.naturalDimension && asset.naturalDimension && !el.naturalDimension.equals(asset.naturalDimension)) {
        diff.naturalDimension = el.naturalDimension;
    }

    keys.forEach((key) => {
        if (key in el && key in asset && (el as any)[key] !== (asset as any)[key]) {
            diff[key] = (asset as any)[key];
        }
    });

    return diff;
};

export const getMaskElementRanges = <
    T extends {
        id: number | string;
        startFrame: number;
        duration?: number;
        mask?: {
            elementId: number | string;
            mode: MaskModeTypes;
        };
    },
>(
    elements: T[],
): Map<string, Ranges<MaskModeTypes>> => {
    const maskMap = new Map<string, Ranges<MaskModeTypes>>();
    const elementMap = new Map<number | string, T>();

    const list = getAllElementsRecursively(elements);

    list.forEach((element) => {
        elementMap.set(element.id, element);
    });

    list.forEach((el) => {
        if (!el.mask || el.mask.mode === MaskModeTypes.NONE) {
            return;
        }

        const { elementId, mode } = el.mask;
        const maskEl = elementMap.get(elementId);

        if (!maskEl) {
            return;
        }

        getAllElementsRecursively([maskEl]).forEach((maskElement) => {
            const key = maskElement.id.toString();

            if (!maskMap.has(key)) {
                const ranges = new Ranges<MaskModeTypes>();
                ranges.setResolver(resolverMaksModeTypes);
                maskMap.set(key, ranges);
            }

            maskMap.get(key)!.add(el.id.toString(), el.startFrame || 0, el.startFrame + el.duration || 1, mode);
        });
    });

    return maskMap;
};

export const matchElementTypeToContentPropertySetting = (
    element: BaseVisualElement,
    contentPropertiesSettings: ContentPropertiesSettings,
) => {
    const mapElementType = {
        [ElementTypes.VIDEO]: contentPropertiesSettings.video,
        [ElementTypes.IMAGE]: contentPropertiesSettings.image,
        [ElementTypes.TEXT]: contentPropertiesSettings.text,
        [ElementTypes.SHAPE]: contentPropertiesSettings.shape,
    };

    return mapElementType[getElementType(element)];
};
