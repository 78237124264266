import React from 'react';
import { KeyboardBadge } from '@bynder/design-system';
import { StyledBadgeText } from '../ShortcutsInfoModal.styled';
import { useTranslate } from '@bynder/localization';
import { Command, Space, ArrowLeft, ArrowRight, ArrowUp, ArrowDown, Shift, BackSpace, Options } from './badges';

export type ShortcutItem = {
    title: string;
    badges: React.JSX.Element[];
};

type ShortcutCategory = {
    [key: string]: ShortcutItem;
};

type ShortcutListItemsMap = {
    [key: string]: ShortcutCategory;
};

export const useShortcutListItems = (): ShortcutListItemsMap => {
    const { translate } = useTranslate();

    return {
        Essential: {
            Undo: {
                title: translate('modal.shortcuts.actions.undo'),
                badges: [<Command />, <KeyboardBadge>Z</KeyboardBadge>],
            },
            Redo: {
                title: translate('modal.shortcuts.actions.redo'),
                badges: [<Command />, <Shift />, <KeyboardBadge>Z</KeyboardBadge>],
            },
            Save: {
                title: translate('modal.shortcuts.actions.save'),
                badges: [<Command />, <KeyboardBadge>S</KeyboardBadge>],
            },
        },
        Zoom: {
            Pan: {
                title: translate('modal.shortcuts.actions.pan'),
                badges: [<Space />, <KeyboardBadge>Drag</KeyboardBadge>],
            },
            ZoomIn: {
                title: translate('modal.shortcuts.actions.zoom-in'),
                badges: [<Command />, <KeyboardBadge>+</KeyboardBadge>],
            },
            ZoomOut: {
                title: translate('modal.shortcuts.actions.zoom-out'),
                badges: [<Command />, <KeyboardBadge>-</KeyboardBadge>],
            },
            ZoomTo100: {
                title: translate('modal.shortcuts.actions.zoom-100'),
                badges: [<Command />, <KeyboardBadge>0</KeyboardBadge>],
            },
            ZoomToFit: {
                title: translate('modal.shortcuts.actions.zoom-fit'),
                badges: [<Command />, <KeyboardBadge>1</KeyboardBadge>],
            },
        },
        Selection: {
            SelectAll: {
                title: translate('modal.shortcuts.actions.select-all'),
                badges: [<Command />, <KeyboardBadge>A</KeyboardBadge>],
            },
            DeselectAll: {
                title: translate('modal.shortcuts.actions.deselect-all'),
                badges: [<KeyboardBadge>Escape</KeyboardBadge>],
            },
            DeepSelect: {
                title: translate('modal.shortcuts.actions.select-deep'),
                badges: [<Command />, <KeyboardBadge>Click</KeyboardBadge>],
            },
            MoveByOne: {
                title: translate('modal.shortcuts.actions.move-one'),
                badges: [<ArrowLeft />, <ArrowRight />, <ArrowUp />, <ArrowDown />],
            },
            MoveByTen: {
                title: translate('modal.shortcuts.actions.move-ten'),
                badges: [
                    <Shift />,
                    <StyledBadgeText>+</StyledBadgeText>,
                    <ArrowLeft />,
                    <ArrowRight />,
                    <ArrowUp />,
                    <ArrowDown />,
                ],
            },
            Delete: {
                title: translate('modal.shortcuts.actions.delete'),
                badges: [<BackSpace />],
            },
            Group: {
                title: translate('modal.shortcuts.actions.group'),
                badges: [<Command />, <KeyboardBadge>G</KeyboardBadge>],
            },
            Ungroup: {
                title: translate('modal.shortcuts.actions.ungroup'),
                badges: [<Shift />, <Command />, <KeyboardBadge>G</KeyboardBadge>],
            },
        },
        Edit: {
            Copy: {
                title: translate('modal.shortcuts.actions.copy'),
                badges: [<Command />, <KeyboardBadge>C</KeyboardBadge>],
            },
            Cut: {
                title: translate('modal.shortcuts.actions.cut'),
                badges: [<Command />, <KeyboardBadge>X</KeyboardBadge>],
            },
            Paste: {
                title: translate('modal.shortcuts.actions.paste'),
                badges: [<Command />, <KeyboardBadge>V</KeyboardBadge>],
            },
            Duplicate: {
                title: translate('modal.shortcuts.actions.duplicate'),
                badges: [<Command />, <KeyboardBadge>D</KeyboardBadge>],
            },
            Rename: {
                title: translate('modal.shortcuts.actions.rename'),
                badges: [<Shift />, <Command />, <KeyboardBadge>R</KeyboardBadge>],
            },
            ShowHide: {
                title: translate('modal.shortcuts.actions.show-hide'),
                badges: [<Shift />, <Command />, <KeyboardBadge>H</KeyboardBadge>],
            },
            LockUnlock: {
                title: translate('modal.shortcuts.actions.lock-unlock'),
                badges: [<Shift />, <Command />, <KeyboardBadge>L</KeyboardBadge>],
            },
        },
        Arrange: {
            BringForward: {
                title: translate('modal.shortcuts.actions.bring-forward'),
                badges: [<Command />, <KeyboardBadge>{']'}</KeyboardBadge>],
            },
            BringBackward: {
                title: translate('modal.shortcuts.actions.send-backward'),
                badges: [<Command />, <KeyboardBadge>{'['}</KeyboardBadge>],
            },
            BringToFront: {
                title: translate('modal.shortcuts.actions.bring-front'),
                badges: [<KeyboardBadge>{']'}</KeyboardBadge>],
            },
            SendToBack: {
                title: translate('modal.shortcuts.actions.send-back'),
                badges: [<KeyboardBadge>{'['}</KeyboardBadge>],
            },
        },
        Video: {
            PlayPause: {
                title: translate('modal.shortcuts.actions.play-pause'),
                badges: [<Space />],
            },
            MovePlayheadForwardOne: {
                title: translate('modal.shortcuts.actions.playhead.move-one-forward'),
                badges: [<ArrowRight />],
            },
            MovePlayheadBackwardOne: {
                title: translate('modal.shortcuts.actions.playhead.move-one-backward'),
                badges: [<ArrowLeft />],
            },
            MovePlayheadForwardTen: {
                title: translate('modal.shortcuts.actions.playhead.move-ten-forward'),
                badges: [<Shift />, <ArrowRight />],
            },
            MovePlayheadBackwardTen: {
                title: translate('modal.shortcuts.actions.playhead.move-ten-backward'),
                badges: [<Shift />, <ArrowLeft />],
            },
            NextScene: {
                title: translate('modal.shortcuts.actions.scene.next'),
                badges: [<Command />, <ArrowRight />],
            },
            PreviousScene: {
                title: translate('modal.shortcuts.actions.scene.previous'),
                badges: [<Command />, <ArrowLeft />],
            },
            SkipToStart: {
                title: translate('modal.shortcuts.actions.scene.skip-start'),
                badges: [<Shift />, <Command />, <ArrowLeft />],
            },
            SkipToEnd: {
                title: translate('modal.shortcuts.actions.scene.skip-end'),
                badges: [<Shift />, <Command />, <ArrowRight />],
            },
            MuteUnmute: {
                title: translate('modal.shortcuts.actions.scene.mute-unmute'),
                badges: [<KeyboardBadge>M</KeyboardBadge>],
            },
        },
        Text: {
            AlignLeft: {
                title: translate('modal.shortcuts.actions.text.left'),
                badges: [<Options />, <Command />, <KeyboardBadge>L</KeyboardBadge>],
            },
            AlignCenter: {
                title: translate('modal.shortcuts.actions.text.center'),
                badges: [<Options />, <Command />, <KeyboardBadge>E</KeyboardBadge>],
            },
            AlignRight: {
                title: translate('modal.shortcuts.actions.text.right'),
                badges: [<Options />, <Command />, <KeyboardBadge>R</KeyboardBadge>],
            },
            AlignJustified: {
                title: translate('modal.shortcuts.actions.text.justified'),
                badges: [<Options />, <Command />, <KeyboardBadge>J</KeyboardBadge>],
            },
        },
        Tools: {
            AddText: {
                title: translate('modal.shortcuts.actions.text.add'),
                badges: [<KeyboardBadge>T</KeyboardBadge>],
            },
            AddEllipsis: {
                title: translate('modal.shortcuts.actions.ellipsis.add'),
                badges: [<KeyboardBadge>E</KeyboardBadge>],
            },
            AddRectangle: {
                title: translate('modal.shortcuts.actions.rectangle.add'),
                badges: [<KeyboardBadge>R</KeyboardBadge>],
            },
            AddImage: {
                title: translate('modal.shortcuts.actions.image.add'),
                badges: [<Shift />, <Command />, <KeyboardBadge>I</KeyboardBadge>],
            },
            AddVideo: {
                title: translate('modal.shortcuts.actions.video.add'),
                badges: [<Shift />, <Command />, <KeyboardBadge>K</KeyboardBadge>],
            },
        },
    };
};
