import { SnapLines } from '@bynder-studio/render-core';
import BaseManipulationCompositor from '../BaseManipulationCompositor';

class SnapLinesManipulationCompositor extends BaseManipulationCompositor {
    constructor(compositor, ctx, dpr) {
        super(compositor, ctx, dpr);
    }

    drawSnapLines(snapLines: SnapLines) {
        if (!snapLines.length) return;

        this.ctx.save();
        this.ctx.globalAlpha = 1;
        this.ctx.strokeStyle = '#E6457A';
        this.ctx.lineWidth = this.devicePixelRatio;

        snapLines.forEach((line) => {
            this.ctx.beginPath();
            this.ctx.moveTo(this.s(line.start[0]), this.s(line.start[1]));
            this.ctx.lineTo(this.s(line.end[0]), this.s(line.end[1]));
            this.ctx.stroke();

            line.points.forEach((point) => this.drawPoint(...point));
        });

        this.ctx.restore();
    }

    drawPoint(x: number, y: number) {
        x = this.s(x);
        y = this.s(y);
        this.ctx.save();
        this.ctx.globalAlpha = 1;
        this.ctx.strokeStyle = '#E6457A';
        this.ctx.lineWidth = this.devicePixelRatio;
        const n = 3 * this.devicePixelRatio;

        this.ctx.beginPath();

        this.ctx.moveTo(x - n, y - n);
        this.ctx.lineTo(x + n, y + n);
        this.ctx.stroke();

        this.ctx.moveTo(x + n, y - n);
        this.ctx.lineTo(x - n, y + n);
        this.ctx.stroke();

        this.ctx.restore();
    }
}

export default SnapLinesManipulationCompositor;
