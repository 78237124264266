import React, { useContext, useEffect } from 'react';
import { GridContainer } from './List.styled';
import PageCard from './PageCard';
import { VariationsThumbnailsContext } from 'packages/pages/design/VariationsContext/VariationsThumbnailsProvider';
import { variationsThumbnailsStore } from 'packages/pages/design/VariationsContext/variationsThumbnailsStore';
import useExport from '../hooks/useExport';

const PageGrid = ({ items }) => {
    const { variationThumbnailRenderer } = useContext(VariationsThumbnailsContext);
    const { pageIds, orderBy } = useExport();

    let filteredItems = items.filter((item) => item?.pageId && pageIds.includes(item?.pageId.toString())) || [];

    if (filteredItems.length === 0) {
        filteredItems = items;
    }

    if (orderBy === 'name') {
        filteredItems.sort((a, b) => a.name.localeCompare(b.name));
    }

    if (orderBy === 'updated') {
        filteredItems.sort((a, b) => a.displayOrder - b.displayOrder);
    }

    useEffect(() => {
        filteredItems.forEach((item) => {
            if (variationsThumbnailsStore.isThumbnailsReady(item.variationSetId, item.pageIndex, 300, 300)) {
                return;
            }
            variationThumbnailRenderer.render(item.variationSetId, item.pageIndex, 300, 300);
        });
    }, [variationThumbnailRenderer, items]);

    return (
        <GridContainer>
            {filteredItems.map((item, index) => (
                <PageCard key={index} {...item} />
            ))}
        </GridContainer>
    );
};

export default PageGrid;
