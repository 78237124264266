import { MultiPageVideoModel, MultiPageImageModel, IElement } from '@bynder-studio/render-core';

type Props = {
    creativeModel: MultiPageVideoModel | MultiPageImageModel;
    elements: IElement[];
    selectedElements: IElement[];
};

const useArrangeActions = ({ creativeModel, elements, selectedElements }: Props) => {
    const handleBringForward = () => {
        const maxRenderOrder = Math.max(...elements.map((el) => el.renderOrder ?? 0));

        creativeModel.beginAccumulation();

        selectedElements
            .sort((a, b) => (b.renderOrder ?? 0) - (a.renderOrder ?? 0))
            .forEach((selection, index) => {
                const nextSibling = creativeModel.getNextElementSibling(selection.id);

                if (!nextSibling) {
                    return;
                }

                if (maxRenderOrder - index === selection.renderOrder) {
                    return;
                }

                creativeModel.updateElement(selection.id, { renderOrder: nextSibling.renderOrder + 0.1 });
            });
        creativeModel.endAccumulation();
    };

    const handleSendBackward = () => {
        creativeModel.beginAccumulation();

        selectedElements
            .sort((a, b) => (b.renderOrder ?? 0) - (a.renderOrder ?? 0))
            .forEach((selection, index) => {
                const prevSibling = creativeModel.getPrevElementSibling(selection.id);

                if (!prevSibling) {
                    return;
                }

                if (selectedElements.length - index === selection.renderOrder) {
                    return;
                }

                creativeModel.updateElement(selection.id, { renderOrder: prevSibling.renderOrder - 0.1 });
            });
        creativeModel.endAccumulation();
    };

    const handleBringToFront = () => {
        const firstElementOrder = Math.max(...elements.map((el) => el.renderOrder ?? 0));
        let topElementOrder = 0;

        creativeModel.beginAccumulation();
        selectedElements
            .sort((a, b) => (b.renderOrder ?? 0) - (a.renderOrder ?? 0))
            .forEach((selection, index) => {
                if (index === 0) {
                    topElementOrder = firstElementOrder + selectedElements.length + 1;
                    creativeModel.updateElement(selection.id, { renderOrder: topElementOrder });
                } else {
                    creativeModel.updateElement(selection.id, { renderOrder: topElementOrder - index });
                }
            });

        creativeModel.endAccumulation();
    };

    const handleSendToBack = () => {
        let topElementOrder = 0.9;

        creativeModel.beginAccumulation();
        selectedElements
            .sort((a, b) => (b.renderOrder ?? 0) - (a.renderOrder ?? 0))
            .forEach((selection, index) => {
                if (index === 0) {
                    creativeModel.updateElement(selection.id, { renderOrder: topElementOrder });
                } else {
                    creativeModel.updateElement(selection.id, {
                        renderOrder: topElementOrder + index / selectedElements.length,
                    });
                }
            });

        creativeModel.endAccumulation();
    };

    return {
        handleBringForward,
        handleSendBackward,
        handleBringToFront,
        handleSendToBack,
    };
};

export default useArrangeActions;
