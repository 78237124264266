import { UploadsState } from './types';

export const getUploads = (state: any) => state.assets;

export const getUploadsSelection = (
    state: UploadsState,
): {
    selectedUploads: UploadsState['checked'];
} => ({ selectedUploads: state.assets.checked });

export const getAssetsActionProgress = (
    state: any,
): {
    renamingInProgress: UploadsState['renamingInProgress'];
    deleteInProgress: UploadsState['deleteInProgress'];
} => ({
    renamingInProgress: state.assets.renamingInProgress,
    deleteInProgress: state.assets.deleteInProgress,
});
