import React, { FC } from 'react';
import { Button, Modal } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import generateTestId from '~/helpers/testIdHelpers';
import modalContainer from 'packages/common/modalContainer';

type RefreshModalProps = {
    isOpen: boolean;
    loading: boolean;
    toggle: (show: boolean) => void;
    discard: () => void;
    onPublish: () => void;
    isPublishDisabled: boolean;
    hasUnsavedChanges: boolean;
};

const RefreshModal: FC<RefreshModalProps> = ({
    isOpen,
    loading,
    toggle,
    discard,
    onPublish,
    isPublishDisabled,
    hasUnsavedChanges,
}) => {
    const { translate } = useTranslate();

    const discardId = generateTestId('editor_header_refresh_modal_discard');
    const cancelId = generateTestId('editor_header_refresh_modal_cancel');
    const refreshModalPublishId = generateTestId('editor_header_refresh_modal_publish');

    return (
        <Modal
            title={
                hasUnsavedChanges
                    ? translate('editor.header.unsaved.modal.title')
                    : translate('editor.header.unpublished.modal.title')
            }
            container={modalContainer}
            isCloseButtonHidden={loading}
            onClose={() => toggle(false)}
            isOpen={isOpen}
            actionSecondary={
                <Button
                    variant="secondary"
                    onClick={() => toggle(false)}
                    isDisabled={loading}
                    {...cancelId}
                    {...{ id: 'editor_header_refresh_modal_cancel' }}
                >
                    {translate('editor.header.unpublished.modal.cancel')}
                </Button>
            }
            actionPrimary={
                <Button
                    variant="primary"
                    onClick={onPublish}
                    isLoading={loading}
                    isDisabled={loading || isPublishDisabled}
                    {...refreshModalPublishId}
                    {...{ id: 'editor_header_refresh_modal_publish' }}
                >
                    {loading
                        ? translate('editor.header.unpublished.modal.publishing')
                        : translate('editor.header.unpublished.modal.publish')}
                </Button>
            }
            actionTertiary={
                <Button
                    variant="secondary"
                    onClick={discard}
                    isDisabled={loading}
                    {...discardId}
                    {...{ id: 'editor_header_refresh_modal_discard' }}
                >
                    {hasUnsavedChanges
                        ? translate('editor.header.unpublished.modal.discard')
                        : translate('editor.header.unpublished.modal.skip-publishing')}
                </Button>
            }
        >
            {hasUnsavedChanges
                ? translate('editor.header.unsaved.modal.desc')
                : translate('editor.header.unpublished.modal.desc')}
        </Modal>
    );
};

export default RefreshModal;
