export enum SIDES {
    UP = 5,
    BOTTOM = 6,
    LEFT = 7,
    RIGHT = 8,
}

export enum CORNERS {
    LEFT_TOP = 0,
    RIGHT_TOP = 1,
    LEFT_BOTTOM = 2,
    RIGHT_BOTTOM = 3,
}

export enum ROTATIONS {
    LEFT_TOP = 9,
    RIGHT_TOP = 10,
    LEFT_BOTTOM = 11,
    RIGHT_BOTTOM = 12,
}
