import { useEffect, useReducer, useState } from 'react';
import { type PagePreviewRenderer, type OnRenderEndData, type OnRenderStartData } from '@bynder-studio/render-core';
import { type CreativeModelFactory } from '@bynder-studio/render-web';
import { ignoreAbortErrors, makeAbortable } from 'packages/helpers/helpers';

type PreviewState =
    | ({
          status: 'RENDER_START' | 'INIT';
      } & OnRenderStartData)
    | ({
          status: 'RENDER_END';
      } & OnRenderEndData);

type PreviewsAction =
    | {
          type: 'INIT';
          payload: {
              creativeModel: ReturnType<CreativeModelFactory['getCreativeModel']>;
          };
      }
    | {
          type: 'RENDER_START';
          payload: OnRenderStartData;
      }
    | {
          type: 'RENDER_END';
          payload: OnRenderEndData;
      }
    | {
          type: 'RESET';
          payload?: never;
      };

export type PagePreviews = PreviewState[];

function previewsReducer(previews: PreviewState[], { type, payload }: PreviewsAction): PagePreviews {
    switch (type) {
        case 'INIT':
            return payload.creativeModel.getModelsMetaData().map((metaData, index) => ({
                status: 'INIT',
                index,
                metaData,
            }));

        case 'RENDER_START':
            return previews.map((preview, index) => (index === payload.index ? { status: type, ...payload } : preview));

        case 'RENDER_END':
            return previews.map((preview, index) =>
                index === payload.index
                    ? {
                          status: type,
                          ...payload,
                      }
                    : preview,
            );

        case 'RESET':
            return [];

        default:
            return previews;
    }
}

export function usePagePreviews(factory: CreativeModelFactory | null, [width, height]: [number, number]) {
    const [renderer, setRenderer] = useState<PagePreviewRenderer | null>(null);
    const [pagePreviews, dispatch] = useReducer(previewsReducer, []);

    useEffect(() => {
        setRenderer(null);

        if (!factory) {
            return;
        }

        setRenderer(factory.getPagePreviewRenderer());
    }, [factory]);

    useEffect(() => {
        if (!renderer || !factory) {
            return;
        }

        renderer.setPreviewSize(width, height);

        dispatch({
            type: 'INIT',
            payload: {
                creativeModel: factory.getCreativeModel(),
            },
        });

        const removeStartListener = renderer.onRenderStart((payload) => {
            dispatch({ type: 'RENDER_START', payload });
        });

        const removeEndListener = renderer.onRenderEnd((payload) => {
            dispatch({
                type: 'RENDER_END',
                payload,
            });
        });

        const abortController = new AbortController();

        makeAbortable(renderer.render(), abortController).catch(ignoreAbortErrors).catch(console.error);

        return () => {
            abortController.abort();
            removeStartListener();
            removeEndListener();
            dispatch({ type: 'RESET' });
        };
    }, [factory, renderer, width, height]);

    return {
        pagePreviews,
    };
}
