import ProxyServerConnector from './connectors/ProxyServerConnector';

class RenditionsService extends ProxyServerConnector {
    defaultRenditionProfile = {};

    constructor(path = '/renditions') {
        super(path);
    }

    getRenditionProfiles(customerId) {
        return this.get(`/profiles/?customerId=${customerId}`).then(({ json, status }) => {
            return { status, renditionProfiles: json.items };
        });
    }
}

export default new RenditionsService();
