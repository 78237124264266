import { createSelector } from 'reselect';
import { FigmaSelectionState } from './types';

export const getFigmaState = (state): FigmaSelectionState => state.figma;
export const getFigmaMultiDesignMode = (state) => getFigmaState(state).multiDesignMode;
export const getFigmaFile = (state) => getFigmaState(state).fileResult;
export const getSelectedFigmaFrameIds = (state) => getFigmaState(state).selectedFrameIds;
export const getSelectedFigmaFrames = createSelector(
    getFigmaFile,
    getSelectedFigmaFrameIds,
    (file, selectedFrameIds) => {
        if (!file || file.status !== 'success') {
            return [];
        }

        return file.value.pages.flatMap((page) => page.frames).filter((frame) => selectedFrameIds.includes(frame.id));
    },
);
