import { MaskModeTypes } from '../../Enums/MaskModeTypes';

export type MaskParams = {
    elementId: number;
    mode: MaskModeTypes;
};

export class Mask {
    elementId: number;

    mode: MaskModeTypes;

    constructor(rawMask: MaskParams) {
        this.elementId = rawMask.elementId;
        this.mode = rawMask.mode;
    }

    getElementId(): number {
        return this.elementId;
    }

    getMode(): MaskModeTypes {
        return this.mode;
    }

    toObject() {
        return {
            elementId: this.elementId,
            mode: this.mode,
        };
    }
}
