import { useCallback, useMemo } from 'react';
import { equals } from 'rambda';
import { BaseMultiPageModel, TextStyle } from '@bynder-studio/render-core';
import { Stroke } from '@bynder-studio/misc';
import useFonts from 'packages/pages/editor/RightSideMenu/Shots/Text/Fonts/useFonts';
import useTextSelection from 'packages/hooks/useTextSelection';
import { cleanUpRawStyles } from './utils';
import { CleanedRunStyle, OverrideKey, TextStyleKey } from './types';

export default function useOverrides(creativeModel: BaseMultiPageModel) {
    const textStyles = creativeModel.getTextStyles();
    const textSelection = useTextSelection();
    const { idToFont } = useFonts();
    const rawStyles = useMemo(() => textSelection.getRawStyles(), [textSelection]);
    const styleIds = rawStyles.styleId;
    const selectedStyles = styleIds.map((id) => (id ? textStyles.getStyle(id) : null)) || [];

    const currentRunsStyles: CleanedRunStyle[] = useMemo(() => {
        return rawStyles.styleId.map((styleId, runIdx) => cleanUpRawStyles(rawStyles, runIdx));
    }, [rawStyles]);

    const getOverrideProperties = useCallback(
        (textStyle: TextStyle, runStyle: CleanedRunStyle) => {
            if (!currentRunsStyles.length) {
                return [] as OverrideKey[];
            }

            const overriddenKeys = [] as OverrideKey[];
            Object.entries(textStyle).forEach(([key, val]) => {
                if (['uuid', 'name'].includes(key)) {
                    return;
                }

                if (val != runStyle[key]) {
                    if (key === 'fontId') {
                        const styleFont = idToFont[Number(textStyle.fontId)];
                        const currentFont = idToFont[Number(runStyle.fontId)];

                        if (!styleFont) {
                            overriddenKeys.push('fontStyle');
                            overriddenKeys.push(key);

                            return;
                        }

                        if (styleFont?.name !== currentFont?.name) {
                            overriddenKeys.push('fontStyle');
                        }

                        if (styleFont.familyName !== currentFont.familyName) {
                            overriddenKeys.push(key);
                        }
                    }
                    if (key === 'stroke') {
                        if (!equals(val, runStyle[key] as unknown as Stroke)) {
                            overriddenKeys.push(key);
                        }
                    } else {
                        overriddenKeys.push(key as TextStyleKey);
                    }
                }
            });

            return overriddenKeys;
        },
        [idToFont, currentRunsStyles],
    );

    const overrides = useMemo(() => {
        if (!styleIds.length) {
            return [];
        }

        return selectedStyles
            .flatMap((current, idx) => (!current ? null : getOverrideProperties(current, currentRunsStyles[idx])))
            .filter((item) => !!item);
    }, [styleIds, selectedStyles, currentRunsStyles, getOverrideProperties]);

    const isOverridden = useCallback(
        (property: OverrideKey) => {
            if (!styleIds.length) {
                return false;
            }

            return overrides.includes(property);
        },
        [styleIds, overrides],
    );

    const getOriginalStyleProp = useCallback(
        (property: OverrideKey) => {
            if (!styleIds.length) {
                return 0;
            }

            return selectedStyles[0][property];
        },
        [styleIds, selectedStyles],
    );

    const getStylesWithOverrides = useCallback(() => {
        return selectedStyles
            .map((style, idx) => {
                if (!style) {
                    return null;
                }

                const { uuid, name, ...styleProps } = style;

                if (equals(styleProps, currentRunsStyles[idx])) {
                    return null;
                }

                return { ...style, ...currentRunsStyles[idx] };
            })
            .filter((item) => !!item) as TextStyle[];
    }, [selectedStyles, currentRunsStyles]);

    return {
        overrides: overrides.length,
        getStylesWithOverrides,
        getOriginalStyleProp,
        isOverridden,
    };
}
