import React, { createRef, useCallback, useEffect, useRef } from 'react';
import { Buffer } from '@bynder/design-system';
import { PageWithAppcues } from '~/helpers/RouteWithAppcues';
import { ListHeader } from './ListHeader';
import { ListRow } from './ListRow';
import { Container } from './Container';
import useExport from '../hooks/useExport';
import { LoaderTrigger } from './List.styled';

export const List = () => {
    const [rowRefs, setRowRefs] = React.useState([]);
    const { items, loadingNextPart, loadNextPart, total } = useExport();
    const loaderTriggerRef = useRef(null);
    const length = items.length;

    const handleScroll = useCallback(() => {
        if (loadingNextPart) {
            return;
        }

        loadNextPart();
    }, [loadingNextPart, loadNextPart]);

    useEffect(() => {
        if (!loaderTriggerRef.current || loadingNextPart || length >= total) {
            return;
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    handleScroll();
                }
            });
        });

        observer.observe(loaderTriggerRef.current);

        return () => {
            observer.disconnect();
        };
    }, [handleScroll, loadingNextPart, total]);

    useEffect(() => {
        setRowRefs((rowRefs) =>
            Array(length)
                .fill()
                .map((_, i) => rowRefs[i] || createRef()),
        );
    }, [length]);

    return (
        <PageWithAppcues>
            <ListHeader rowRefs={rowRefs} />
            <div id="export_modal_list">
                <Container>
                    {items.map((item, index) => (
                        <ListRow
                            ref={rowRefs[index]}
                            key={index}
                            item={item}
                            isLast={index === items.length - 1}
                            isSingle={items.length === 1}
                        />
                    ))}
                    {loadingNextPart && <Buffer />}
                </Container>
            </div>
            <LoaderTrigger ref={loaderTriggerRef} />
        </PageWithAppcues>
    );
};
