import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { TextElement } from '@bynder-studio/render-core';
import { ListDragAndDrop } from '@bynder/design-system';
import useDesign from 'packages/pages/design/hooks/useDesign';
import { catchResponseError } from 'packages/helpers/helpers';
import DotLoader from '~/common/dotLoader/DotLoader';
import { useReviewStatus } from 'packages/pages/design/sidebar/shots/useReviewStatus';
import VariationsHeader from './VariationsHeader';
import VariationItem from './VariationItem';
import useVariations from '../../hooks/useVariations';
import useVariationTranslateModal from 'packages/pages/design/sidebar/variations/useVariationTranslateModal';
import useVariationResetModal from 'packages/pages/design/sidebar/variations/useVariationResetModal';
import DeleteModal from './DeleteModal';
import { LoadingWrapper } from './Variations.styled';
import { isVariationInvalid } from './utils';

const Variations = () => {
    const {
        total,
        variations,
        currentVariation,
        selectVariation,
        addVariation,
        renameVariation,
        renameVariationLongError,
        deleteVariations,
        duplicateVariation,
        batchDuplicateVariations,
        getVariationsAndSave,
        isLoadingVariations,
        isLoadingNextPage,
        isLimitExceeded,
        maxVariationCount,
        saveVariation,
        saveVariationsOrdering,
        oversetVariationsSet,
    } = useVariations();
    const { creativeModel } = useDesign();
    const { isDisabledByReview } = useReviewStatus();
    const [selectedIds, setSelectedIds] = useState([]);
    const [isAdding, setIsAdding] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDuplicatePending, setIsDuplicatePending] = useState(false);
    const [isDragAndDropDisabled, setIsDragAndDropDisabled] = useState(false);
    const variationsToDelete = useRef(null);
    const deleteModalTitle = useRef('');
    const isFirstMount = useRef(true);
    const { renderVariationTranslateModal, setVariationTranslateModal } = useVariationTranslateModal({
        getVariationsAndSave,
        variationsTotal: total,
    });
    const { renderVariationResetModal, setVariationResetModal } = useVariationResetModal();

    const onAddClick = useCallback(() => {
        setIsAdding(true);

        addVariation()
            .catch(catchResponseError)
            .finally(() => setIsAdding(false));
    }, [addVariation]);

    const selectedHasDeletedAsset = useMemo(() => {
        return variations
            .filter((v) => selectedIds.includes(v.id))
            .some((v) => Object.values(v.sizes).some((s) => isVariationInvalid(s)));
    }, [variations, selectedIds]);

    const onSelect = useCallback(
        ({
            id,
            idx,
            isSelected,
            shiftKey,
            ctrlKey,
        }: {
            id: number;
            idx: number;
            isSelected: boolean;
            shiftKey: boolean;
            ctrlKey: boolean;
        }) => {
            if (shiftKey) {
                const currentIdx = variations.findIndex((v) => v.id === currentVariation);
                const startIdx = Math.min(currentIdx, idx);
                const endIdx = Math.max(currentIdx, idx);

                setSelectedIds(variations.slice(startIdx, endIdx + 1).map((v) => v.id));
                return;
            }

            let ids = [id];

            if (ctrlKey) {
                ids = isSelected ? selectedIds.filter((selectedId) => selectedId !== id) : [...selectedIds, id];

                if (!ids.length) {
                    return;
                }
            }

            if (ids.length === 1) {
                selectVariation(ids[0]);
            }

            setSelectedIds(ids);
        },
        [variations, currentVariation, selectedIds, selectVariation],
    );

    const onRename = useCallback(
        (id, newName) => {
            renameVariation(id, newName).catch(catchResponseError);
        },
        [renameVariation],
    );

    const onDelete = useCallback(
        (id) => {
            deleteModalTitle.current = variations.find((v) => v.id === id)?.name;
            variationsToDelete.current = [id];
            setIsDeleteModalOpen(true);
        },
        [variations],
    );

    const closeDeleteModal = useCallback(() => {
        setIsDeleteModalOpen(false);
        variationsToDelete.current = null;
    }, []);

    const onDeleteConfirm = useCallback(() => {
        if (!variationsToDelete.current) {
            return;
        }

        deleteVariations(variationsToDelete.current).catch(catchResponseError);
        closeDeleteModal();
    }, [deleteVariations, selectVariation]);

    const onBatchDelete = useCallback(() => {
        variationsToDelete.current = selectedIds;
        deleteModalTitle.current = `${selectedIds.length} variation${selectedIds.length > 1 ? 's' : ''}`;

        setIsDeleteModalOpen(true);
    }, [selectedIds]);

    const onBatchDuplicate = useCallback(() => {
        setIsDuplicatePending(true);
        batchDuplicateVariations(selectedIds)
            .then(() => {
                setSelectedIds([currentVariation]);
                setIsDuplicatePending(false);
            })
            .catch((err) => {
                setIsDuplicatePending(false);
                catchResponseError(err);
            });
    }, [batchDuplicateVariations, selectedIds, currentVariation]);

    const sortedVariations = useMemo(() => variations.sort((a, b) => a.displayOrder - b.displayOrder), [variations]);

    const onChangeOrder = useCallback(
        (startIdx: number, endIdx = total - 1) => {
            const [slicedVariation] = variations.slice(startIdx, startIdx + 1);
            const sortedArray = variations.slice(0);
            sortedArray.splice(startIdx, 1);
            sortedArray.splice(endIdx, 0, slicedVariation);

            saveVariationsOrdering(sortedArray);
        },
        [saveVariationsOrdering, variations],
    );

    const onTranslateClick = useCallback(
        (id) => {
            setVariationTranslateModal(variations.find((v) => v.id === id || null));
        },
        [variations],
    );

    const onReset = useCallback((variationId) => {
        setVariationResetModal(variationId);
    }, []);

    const isTranslateDisabled = useMemo(() => {
        if (!creativeModel || !currentVariation) {
            return true;
        }

        const variationTextElements = creativeModel
            .getModels()
            .map((model) => model.getAllElementsRecursively().filter((el) => el instanceof TextElement))
            .flat();

        const isAllTextElementsLocked = variationTextElements.every((el) => el.locked);

        return !variationTextElements.length || isAllTextElementsLocked;
    }, [currentVariation, creativeModel]);

    useEffect(() => {
        setSelectedIds([currentVariation]);
    }, [currentVariation]);

    useEffect(() => {
        const totalMoreThanViewed = variations?.length > 0 && total > variations?.length;

        if (totalMoreThanViewed && !isFirstMount.current) {
            getVariationsAndSave();
        }
    }, [getVariationsAndSave, total, variations]);

    useEffect(() => {
        isFirstMount.current = false;

        const currentVariationOBJ = variations.find((v) => v.id === currentVariation);

        if (!currentVariationOBJ) {
            return;
        }

        const { hasUnsavedChanges } = currentVariationOBJ;

        if (hasUnsavedChanges === true) {
            saveVariation(currentVariation);
        }
    }, []);

    return (
        <>
            <VariationsHeader
                variationCount={total}
                selectedVariationCount={selectedIds ? selectedIds.length : 1}
                isAdding={isAdding}
                selectedHasDeletedAsset={selectedHasDeletedAsset}
                isDuplicatePending={isDuplicatePending}
                isAddingDisabled={isLimitExceeded || total >= maxVariationCount}
                onAddClick={onAddClick}
                onDeleteClick={onBatchDelete}
                onDuplicateClick={onBatchDuplicate}
                maxVariationCount={maxVariationCount}
                isDisabledByReview={isDisabledByReview}
            />
            {isLoadingVariations && (
                <LoadingWrapper>
                    <DotLoader />
                </LoadingWrapper>
            )}
            {!isDisabledByReview ? (
                <ListDragAndDrop
                    isDisabled={isDragAndDropDisabled}
                    items={sortedVariations}
                    onOrderChange={(reorderedList) => {
                        saveVariationsOrdering(reorderedList);
                    }}
                    showIndicator={false}
                    renderItem={(v: any, index) => (
                        <VariationItem
                            key={v.id}
                            variation={v}
                            idx={index}
                            total={total}
                            isSelected={selectedIds.includes(v.id)}
                            isCurrentVariation={currentVariation === v.id}
                            isDuplicateDisabled={isLimitExceeded || total >= maxVariationCount}
                            isTranslateDisabled={isTranslateDisabled || isLimitExceeded || total >= maxVariationCount}
                            onRename={onRename}
                            onRenameLongError={renameVariationLongError}
                            onChangeRenameMode={setIsDragAndDropDisabled}
                            onSelect={onSelect}
                            onDelete={onDelete}
                            onDuplicate={duplicateVariation}
                            onChangeOrder={onChangeOrder}
                            onTranslateClick={onTranslateClick}
                            onReset={onReset}
                            isDisabledByReview={isDisabledByReview}
                            isTextOverset={oversetVariationsSet.has(v.id)}
                        />
                    )}
                />
            ) : (
                sortedVariations.map((v, index) => (
                    <VariationItem
                        key={v.id}
                        id={v.id}
                        idx={index}
                        oldId={v.oldId}
                        sizes={v.sizes}
                        name={v.name}
                        created={v.created}
                        updated={v.updated}
                        total={total}
                        isSelected={selectedIds.includes(v.id)}
                        isCurrentVariation={currentVariation === v.id}
                        onSelect={onSelect}
                        isDisabledByReview={isDisabledByReview}
                        isTextOverset={oversetVariationsSet.has(v.id)}
                    />
                ))
            )}
            {isLoadingNextPage && (
                <LoadingWrapper>
                    <DotLoader />
                </LoadingWrapper>
            )}
            <DeleteModal
                isOpen={isDeleteModalOpen}
                variationName={deleteModalTitle.current}
                onClose={closeDeleteModal}
                onConfirm={onDeleteConfirm}
            />
            {renderVariationTranslateModal()}
            {renderVariationResetModal()}
        </>
    );
};

export default Variations;
