import React from 'react';
import { Box, Progress } from '@bynder/design-system';
import { IconVideo, IconPictureInPicture, IconFullscreen } from '@bynder/icons';
import Loader from '~/common/loader/Loader';
import { CALCULATING, DURATION, PROCESSING, TRIM_IN_PROCESSING_CONTENT } from '~/helpers/textConstants';
import { PortionSelectorLoaderContainer, PortionSelectorLoaderWrapper } from './PortionSelectorLoader.styled';

// TODO: Old code here, need to be refactored.
const PortionSelectorLoader = ({ type, processProgress }) => {
    return (
        <PortionSelectorLoaderContainer className="h-100">
            <PortionSelectorLoaderWrapper>
                <div className={`portion--selector-fake-player mb-20 ${type === 'audio' ? 'fake--player-audio' : ''}`}>
                    <IconVideo size="48" />
                    <div className="portion--selector-fake-controls-panel px-3">
                        <Loader size={24} />
                        <p className="mb-0 ml-3">{`${PROCESSING} ${type} . ${processProgress}%`}</p>
                        <div className="portion--selector-fake-controls d-flex align-items-center ml-auto">
                            <span className="portion--selector-fake-control-item mr-4">
                                <IconPictureInPicture size="24" />
                            </span>
                            <span className="portion--selector-fake-control-item">
                                <IconFullscreen size="24" />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="portion--selector-fake-duration-container">
                    <div className="d-flex align-items-center justify-content-between mb-20">
                        <h5 className="portion--selector-fake-duration-title m-0">{`${type.replace(
                            type.slice(0, 1),
                            type.charAt(0).toUpperCase(),
                        )} ${DURATION}`}</h5>
                        <span className="portion--selector-fake-duration-status">{CALCULATING}</span>
                    </div>
                    <Box marginBottom="4">
                        <Progress color="brand" width={processProgress} />
                    </Box>
                    <p>{`${TRIM_IN_PROCESSING_CONTENT} ${type}.`}</p>
                </div>
            </PortionSelectorLoaderWrapper>
        </PortionSelectorLoaderContainer>
    );
};

export default PortionSelectorLoader;
