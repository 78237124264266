const CONTEXT_MENU_OPTIONS = ['Copy', 'Cut', 'Paste'];

export const createContextMenu = (onClick: (...args: any[]) => void) => {
    const dropdownElement = document.createElement('div');
    dropdownElement.className = 'text-editor-dropdown';
    CONTEXT_MENU_OPTIONS.forEach((option) => {
        const button = document.createElement('button');
        button.dataset.name = option.toLowerCase();
        button.textContent = option;
        button.onclick = onClick;

        dropdownElement.appendChild(button);
    });

    return dropdownElement;
};
