import store from '~/store';
import { updateAssetList } from '~/store/assets/assets.actions';
import { fetchCollection } from '~/store/collections/collections.actions';
import AssetsService from '~/services/AssetsService';
import { createStream, determineMimeType, validateFile, XHRUpload } from './helpers';
import { AssetStatus, AssetType, Task, UploaderError, UploaderProgress, UploaderState } from './types';

const updateAssetListAfterUpload = ({ collectionId }: { collectionId?: number }) => {
    store.dispatch(updateAssetList());

    // Fetch collection to update assets count and thumbnail
    if (collectionId) {
        store.dispatch(fetchCollection(collectionId, false));
    }
};

export const updateAssetStatus = async (data: {
    assetId: string;
    customerId: number;
    status: AssetStatus;
}): Promise<Task<null>> => {
    try {
        await AssetsService.updateAssetStatus(data);

        return { status: 'success', data: null };
    } catch (error) {
        return { status: 'error', stage: 'statusUpdate', type: 'generic' };
    }
};

export const createAsset = async (
    customerId: number,
    file: File,
    collectionId?: number,
): Promise<Task<{ signedUrl: string; assetId: string }>> => {
    try {
        const options = [
            {
                fileName: file.name,
                mimeType: determineMimeType(file),
                fileSize: file.size,
            } as any,
        ];

        if (collectionId) {
            options[0].collectionId = collectionId;
        }

        const res: { signedUrl: string; assetId: string }[] = await AssetsService.createAssets(customerId, options);

        if (res.length !== 1) {
            throw new Error();
        }

        return { status: 'success', data: res[0] };
    } catch (error) {
        return { status: 'error', stage: 'gettingUploadUrl', type: 'generic' };
    }
};

// the function supposed to return ReadableStream, but async iteration over it is not supported yet
export const uploadToBucket = (url: string, file: File, abortSignal: AbortSignal) => {
    const [stream, enqueue] = createStream<UploaderProgress | UploaderError>();

    XHRUpload({
        url,
        file,
        abortSignal,
        onProgress: (event) => {
            const { total, loaded } = event;
            const percent = Math.ceil((loaded / total) * 100);
            enqueue({ status: 'inProgress', stage: 'uploading', total, loaded, percent });
        },
        onSuccess: (data) => {
            enqueue({ status: 'inProgress', stage: 'uploaded', url: data }, true);
        },
        onAbort: () => {
            enqueue({ status: 'error', stage: 'uploading', type: 'cancelled' }, true);
        },
        onError: () => {
            enqueue({ status: 'error', stage: 'uploading', type: 'generic' }, true);
        },
    });

    return stream;
};

export async function* uploadAsset({
    file,
    customerId,
    abortSignal,
    collectionId,
    assetTypes = [],
}: {
    file: File;
    customerId: number;
    collectionId?: number;
    abortSignal: AbortSignal;
    assetTypes: AssetType[];
}): AsyncGenerator<UploaderState, void, void> {
    yield { status: 'inProgress', stage: 'validating' };
    const validation = await validateFile(file, assetTypes);

    if (validation.status === 'error') {
        yield validation;

        return;
    }

    yield { status: 'inProgress', stage: 'gettingUploadUrl' };
    const uploadUrl = await createAsset(customerId, file, collectionId);

    if (uploadUrl.status === 'error') {
        yield uploadUrl;

        return;
    }

    const setProgressStatus = await updateAssetStatus({
        assetId: uploadUrl.data.assetId,
        customerId,
        status: 'UPLOAD_IN_PROGRESS',
    });

    if (setProgressStatus.status === 'error') {
        yield setProgressStatus;

        return;
    }

    yield { status: 'inProgress', stage: 'statusUpdate', value: 'UPLOAD_IN_PROGRESS' };

    let uploadedFileUrl: string;

    for await (const state of uploadToBucket(uploadUrl.data.signedUrl, file, abortSignal)) {
        if (state.status === 'error') {
            if (state.type === 'cancelled') {
                await updateAssetStatus({
                    assetId: uploadUrl.data.assetId,
                    customerId,
                    status: 'CANCELLED',
                });
            }

            yield state;

            return;
        }

        if (state.stage === 'uploaded') {
            uploadedFileUrl = state.url;
        }

        yield state;
    }

    const setCompletedStatus = await updateAssetStatus({
        assetId: uploadUrl.data.assetId,
        customerId,
        status: 'COMPLETED',
    });

    if (setCompletedStatus.status === 'error') {
        yield setCompletedStatus;

        return;
    }

    yield { status: 'inProgress', stage: 'statusUpdate', value: 'COMPLETED' };

    updateAssetListAfterUpload({ collectionId });

    yield { status: 'success' };
}
