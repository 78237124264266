import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDragEvents } from '../tracks/dragging/useDragEvents';
import { computeOverflow } from '../tracks/helper';
import { LogSlider } from '~/helpers/LogSlider';

const setWidth = (width, elementContainerRef, shotsContainerRef, timestampsContainerRef, timestampWidth) => {
    elementContainerRef.current.style.width = width + '%';
    shotsContainerRef.current.style.width = width + '%';
    timestampsContainerRef.current.style.width = timestampWidth + '%';
};

const setScroll = (scrollRight, elementContainerRef, shotsContainerRef, timestampsContainerRef) => {
    elementContainerRef.current.style.right = scrollRight + '%';
    shotsContainerRef.current.style.right = scrollRight + '%';
    timestampsContainerRef.current.style.right = scrollRight + '%';
};

const scrollElementsContainer = (
    elementContainerRef,
    shotsContainerRef,
    timestampsContainerRef,
    widthPercentage,
    left,
    maxLeft,
    elementsWidth,
) => {
    const width = elementsWidth || 100 + (100 - widthPercentage) * 2;
    const completion = left / (maxLeft / 100);
    const completionPercentage = isNaN(completion) ? 0 : completion;
    const scrollRight = ((width - 100) / 100) * completionPercentage;

    requestAnimationFrame(() => {
        setScroll(scrollRight, elementContainerRef, shotsContainerRef, timestampsContainerRef);
    });
};

export const useZoomDragging = (
    controlRef,
    containerRef,
    elementContainerRef,
    shotsContainerRef,
    timestampsContainerRef,
    dragEnd,
    maxValue,
) => {
    const boundingBoxRef = useRef(null);
    const containerBoundingBoxRef = useRef(null);
    const logZoomIn = useMemo(
        () =>
            new LogSlider({
                minpos: 0,
                maxpos: 100,
                minval: 100,
                maxval: Math.max(maxValue, 300),
            }),
        [maxValue],
    );
    const rangeToZoom = useCallback((range) => logZoomIn.value(range), [logZoomIn]);

    const onDragStart = () => {
        boundingBoxRef.current = controlRef.current.getBoundingClientRect();
        containerBoundingBoxRef.current = containerRef.current.getBoundingClientRect();
    };

    const onDrag = (deltaX) => {
        const elementLeft = boundingBoxRef.current.left - containerBoundingBoxRef.current.left;
        const maxLeft = containerBoundingBoxRef.current.width - boundingBoxRef.current.width;
        const left = Math.min(Math.max(elementLeft + deltaX, 0), maxLeft);
        const elementWidth = boundingBoxRef.current.width;
        const widthPercentage = 100 / (containerBoundingBoxRef.current.width / elementWidth);
        const growPercentage = Math.round(rangeToZoom(100 - widthPercentage));

        scrollElementsContainer(
            elementContainerRef,
            shotsContainerRef,
            timestampsContainerRef,
            widthPercentage,
            left,
            maxLeft,
            growPercentage,
        );

        requestAnimationFrame(() => {
            controlRef.current.style.left = left + 'px';
        });
    };

    const onDragEnd = () => {
        const left = boundingBoxRef.current.left - containerBoundingBoxRef.current.left;
        const leftPercentage = 100 / (containerBoundingBoxRef.current.width / left);
        dragEnd(leftPercentage);
    };

    const onWheel = useCallback((evt) => {
        boundingBoxRef.current = controlRef.current.getBoundingClientRect();
        containerBoundingBoxRef.current = containerRef.current.getBoundingClientRect();
        onDrag(evt.deltaX / 2);
    }, []);

    useEffect(() => {
        elementContainerRef.current.addEventListener('wheel', onWheel);
    }, []);

    const [onMouseDown] = useDragEvents({ onDragStart, onDrag, onDragEnd });

    return onMouseDown;
};

export const useZoomHandleResize = (
    elements,
    controlRef,
    elementContainerRef,
    shotsContainerRef,
    timestampsContainerRef,
    maxValue,
) => {
    const [isFullView, setFullView] = useState(true);
    const overflowPercentage = useRef(0);
    const overflowRawPercentage = useRef(0);
    const logZoomIn = useMemo(
        () =>
            new LogSlider({
                minpos: 0,
                maxpos: 100,
                minval: 100,
                maxval: Math.max(maxValue, 300),
            }),
        [maxValue],
    );

    useEffect(() => {
        const [overflow, overflowRaw] = computeOverflow(elementContainerRef);

        if (Math.abs(overflow - overflowPercentage.current) < 0.1) {
            return;
        }

        overflowPercentage.current = overflow;
        overflowRawPercentage.current = overflowRaw;

        controlRef.current.style.width = '100%';
        controlRef.current.style.left = '0%';

        setWidth(100 - overflowPercentage.current, elementContainerRef, shotsContainerRef, timestampsContainerRef, 100);
        setScroll(0, elementContainerRef, shotsContainerRef, timestampsContainerRef);
    }, [elements]);

    const rangeToZoom = (range) => logZoomIn.value(range);

    const onResize = useCallback(
        (fromRight, left, width, leftPercentage, widthPercentage, containerBoundingBox, boundingBox) => {
            const maxLeft = containerBoundingBox.width - width;
            const growPercentage = Math.round(rangeToZoom(100 - widthPercentage));
            const growFactor = growPercentage / 100;

            const elementsWidth = growPercentage - growFactor * overflowPercentage.current;

            scrollElementsContainer(
                elementContainerRef,
                shotsContainerRef,
                timestampsContainerRef,
                widthPercentage,
                left,
                maxLeft,
                growPercentage,
            );

            requestAnimationFrame(() => {
                setWidth(elementsWidth, elementContainerRef, shotsContainerRef, timestampsContainerRef, growPercentage);
            });
        },
        [logZoomIn],
    );

    const onDoubleClick = useCallback(() => {
        const width = isFullView ? 100 : 100 - overflowPercentage.current;
        const controlWidth = isFullView ? 100 - overflowRawPercentage.current / 2 : 100;
        const timestampWidth = isFullView ? 100 + overflowRawPercentage.current : 100;

        setWidth(width, elementContainerRef, shotsContainerRef, timestampsContainerRef, timestampWidth);
        setScroll(0, elementContainerRef, shotsContainerRef, timestampsContainerRef);

        controlRef.current.style.width = controlWidth + '%';
        controlRef.current.style.left = '0%';

        setFullView(!isFullView);
    });

    return [onResize, onDoubleClick];
};
