import React, { useCallback, useEffect, useState } from 'react';
import { Template } from '@bynder-studio/render-core';
import Editable from '../../editable/Editable';
import { Button, ListItem, Modal } from '@bynder/design-system';
import { IconDelete, IconFolderOpen } from '@bynder/icons';

type Props = {
    preset: Template;
    selected: any;
    selectPreset: (presetId: number) => void;
    renamePreset: (presetId: number, value: any) => void;
    removePreset: (presetId: number) => void;
    requestingRename: boolean;
};

const PresetListItem = ({ preset, selected, renamePreset, selectPreset, removePreset, requestingRename }: Props) => {
    const [editable, setEditable] = useState(false);
    const [removeModalVisible, setRemoveModalVisible] = useState(false);

    const confirmRemoving = useCallback(
        (presetId) => {
            setRemoveModalVisible(false);
            removePreset(presetId);
        },
        [setRemoveModalVisible, removePreset],
    );

    useEffect(() => {
        if (!requestingRename) {
            setEditable(false);
        }
    }, [requestingRename]);

    return (
        <>
            <ListItem
                key={preset.id}
                onClick={() => {
                    if (preset.id !== selected?.id) {
                        selectPreset(preset.id);
                    }
                }}
                isSelected={selected && selected.id === preset.id}
                thumbnail={<IconFolderOpen />}
                rightElements={
                    !editable ? (
                        <Button
                            title="delete preset"
                            icon={<IconDelete />}
                            variant="clean"
                            onClick={() => setRemoveModalVisible(true)}
                        />
                    ) : null
                }
            >
                <Editable
                    value={preset.name}
                    editable={editable}
                    onToggle={(value) => setEditable(value)}
                    onConfirm={(value) => renamePreset(preset.id, value)}
                    alwaysShowButton={false}
                    hideIcon
                    withDoubleClick
                />
            </ListItem>
            <Modal
                isOpen={removeModalVisible}
                onClose={() => setRemoveModalVisible(false)}
                title="Delete preset"
                actionPrimary={
                    <Button variant="primary" onClick={() => confirmRemoving(preset.id)}>
                        Delete
                    </Button>
                }
                actionSecondary={<Button onClick={() => setRemoveModalVisible(false)}>Cancel</Button>}
            >
                Are you sure you want to delete this preset?
            </Modal>
        </>
    );
};

export default PresetListItem;
