import React, { useCallback, useEffect, useState } from 'react';
import { Form, Slider } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { getScaleValueFromElement } from '~/common/editor/helpers';
import useElement from '../../hooks/useElement';
import useDesignElementContentTransform from '../../hooks/useDesignElementContentTransform';

function useScale(id) {
    const { key, element, updateElement } = useElement(id);
    const { naturalDimension } = element;
    const contentTransform = useDesignElementContentTransform(id);

    const [value, setValue] = useState(() => Math.round(getScaleValueFromElement(element) * 100));

    const handleOnChange = useCallback(
        (newValue) => {
            setValue(newValue);
            const { scale, horizontalOffset, verticalOffset } = contentTransform;
            const { width: naturalWidth, height: naturalHeight } = naturalDimension;
            const newScale = +(newValue / 100).toFixed(2);

            const param = {
                contentTransform: {
                    ...contentTransform,
                    horizontalOffset: Math.round(
                        horizontalOffset + (naturalWidth * scale - naturalWidth * newScale) / 2,
                    ),
                    verticalOffset: Math.round(verticalOffset + (naturalHeight * scale - naturalHeight * newScale) / 2),
                    scale: newScale,
                },
            };
            updateElement(param);
        },
        [contentTransform, naturalDimension, updateElement],
    );

    useEffect(() => {
        setValue(Math.round(contentTransform.scale * 100));
    }, [key, contentTransform]);

    useEffect(() => {
        setValue(Math.round(getScaleValueFromElement(element) * 100));
    }, [key, element]);

    return { value, handleOnChange };
}

const Scale = ({ id, disabled }) => {
    const { translate } = useTranslate();
    const { value, handleOnChange } = useScale(id);

    return (
        <Form.Group>
            <Form.Label>{translate('design.sidebar.scene.options.crop-mode.scale.label')}</Form.Label>
            <Slider
                value={value}
                min={0}
                max={200}
                isFromCenter
                inputPosition="right"
                onChange={handleOnChange}
                isDisabled={disabled}
            />
        </Form.Group>
    );
};

export default Scale;
