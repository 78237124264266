import React, { useCallback, useLayoutEffect, useState, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Form, InputField, Modal, TextareaField, Thumbnail } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconFolder, IconFolderOpen } from '@bynder/icons';
import { customerIdSelector } from 'packages/store/user/user.selectors';
import { CollectionSubType, CollectionType } from 'packages/store/collections/types';
import { searchCategories, initCategories } from 'packages/store/searchCategory/searchCategory.actions';
import { Category } from 'packages/store/categories/types';
import { getSearchedCategoriesState } from 'packages/store/searchCategory/searchCategory.selectors';
import { getCategoriesActionProgress } from 'packages/store/categories/categories.selectors';
import { OwnershipFilterType } from 'packages/pages/components/filters/components/Ownership/types';
import useAccessRights from 'packages/hooks/useAccessRights';
import debounce from '~/helpers/debounce';
import { saveAsTemplate } from '~/store/creatives/creatives.actions';
import features from '~/configs/features';
import roles from '~/configs/roles';
import generateTestId from '~/helpers/testIdHelpers';
import { hasPreviewImageURL, isProcessingDone } from 'packages/pages/components/thumbnail/utils';
import modalContainer from 'packages/common/modalContainer';

const descriptionTestId = generateTestId('editor_header_save_as_template_description');

type Props = {
    show: boolean;
    toggle: (value: boolean) => void;
    creativeName: string;
};

const MAX_DESCRIPTION_LENGTH = 500;
const MAX_INPUT_SIZE = 120;

const SaveAsTemplateModal = ({ show, toggle, creativeName }: Props) => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const currentCompany = useSelector(customerIdSelector);
    const { fetchOptions, categories, isFetching } = useSelector(getSearchedCategoriesState);
    const { movingInProgress } = useSelector(getCategoriesActionProgress);
    const { isPlatformAllowed, isAccountRoleAssigned } = useAccessRights();
    const [name, setName] = useState(creativeName);
    const [description, setDescription] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);

    const type: CollectionType = 'CREATIVE';
    const subType: CollectionSubType = 'TEMPLATE';
    const isTrialAccount =
        isPlatformAllowed([features.TRIAL_ENABLED]) && isAccountRoleAssigned(roles.video_brand_studio.video.manage);

    const categorySearchArgs = {
        sort: ['NAME', 'ASC'],
        type,
        subType,
        size: 1000,
        ownershipSource: isTrialAccount ? OwnershipFilterType.OWNED_BY_OTHERS : OwnershipFilterType.OWNED_BY_ANYONE,
        includeEmptyCollection: true,
    };

    const onSearch = debounce(
        (newSearchValue: string) => {
            const newOptions = { ...fetchOptions, ...categorySearchArgs };
            newOptions.search = newSearchValue;

            dispatch(searchCategories({ ...newOptions }, currentCompany, true));
        },
        1000,
        false,
    );

    const isSaveDisabled = isFetching || !name.length || name.length > MAX_INPUT_SIZE;

    const handleSearchValueChange = useCallback((newValue) => {
        setSearchValue(newValue);
        onSearch(newValue);
    }, []);

    const onModalClose = useCallback(() => {
        toggle(false);
        setSearchValue('');
    }, [toggle]);

    const onSaveAsTemplate = useCallback(() => {
        dispatch(
            saveAsTemplate({
                name,
                description,
                categoryId: selectedCategory?.id,
            }),
        );

        onModalClose();
    }, [dispatch, onModalClose, name, description, selectedCategory]);

    const getActiveCategoryName = useCallback(() => {
        if (selectedCategory && !isFetching) {
            return selectedCategory?.name;
        }

        return translate('modal.template.move.none');
    }, [isFetching, selectedCategory]);

    const getCategoryThumbnail = (item: Category) => {
        if (item.coverImageUrl) {
            return (
                <Thumbnail
                    size="xs"
                    variant="content"
                    imageUrl={item.coverImageUrl}
                    backgroundColor={`#${item.coverBgColor}`}
                />
            );
        }

        if (item.coverBgColor) {
            return <Thumbnail size="xs" variant="content" backgroundColor={`#${item.coverBgColor}`} />;
        }

        if (hasPreviewImageURL(item) && isProcessingDone(item)) {
            return (
                <Thumbnail
                    size="xs"
                    variant="content"
                    imageUrl={item?.previewItems[item?.previewItems?.length - 1].previewUrl}
                />
            );
        }

        return (
            <Thumbnail size="xs" variant="clean" icon={<IconFolder />}>
                {!isProcessingDone(item) && <Thumbnail.Overlay isLoading={!isProcessingDone(item)} />}
            </Thumbnail>
        );
    };

    const getSelectedCategoryThumbnail = useCallback(() => {
        if (isFetching) {
            return <Thumbnail size="xs" variant="clean" icon={<IconFolderOpen />} backgroundColor="transparent" />;
        }

        if (selectedCategory) {
            if (selectedCategory.coverImageUrl) {
                return (
                    <Thumbnail
                        size="xs"
                        variant="content"
                        imageUrl={selectedCategory.coverImageUrl}
                        backgroundColor={`#${selectedCategory.coverBgColor}`}
                    />
                );
            }

            if (selectedCategory.coverBgColor) {
                return <Thumbnail size="xs" variant="content" backgroundColor={`#${selectedCategory.coverBgColor}`} />;
            }

            if (hasPreviewImageURL(selectedCategory) && isProcessingDone(selectedCategory)) {
                return (
                    <Thumbnail
                        size="xs"
                        variant="content"
                        imageUrl={selectedCategory.previewItems[selectedCategory.previewItems.length - 1].previewUrl}
                    />
                );
            }
        }

        return (
            <Thumbnail size="xs" variant="clean" icon={<IconFolderOpen />}>
                {!isProcessingDone(selectedCategory) && (
                    <Thumbnail.Overlay isLoading={!isProcessingDone(selectedCategory)} />
                )}
            </Thumbnail>
        );
    }, [isFetching, selectedCategory]);

    useEffect(() => {
        if (show) {
            setName(creativeName);
            dispatch(initCategories(categorySearchArgs));
        }

        return () => {
            if (!show) {
                setSearchValue('');
                setDescription('');
            }
        };
    }, [show]);

    const actionId = generateTestId('editor_header_save_as_template_save');
    const cancelId = generateTestId('editor_header_save_as_template_cancel');
    const templateNameId = generateTestId('editor_header_save_as_template_template_name');
    const categoryDropdownId = generateTestId('editor_header_save_as_template_category_dropdown');
    const categoryDropdownSearchId = generateTestId('editor_header_save_as_template_category_dropdown_search');

    return (
        <Modal
            container={modalContainer}
            title={translate('modal.save-as-template.title')}
            isOpen={show}
            onClose={onModalClose}
            actionPrimary={
                <Button
                    variant="primary"
                    onClick={onSaveAsTemplate}
                    isDisabled={isSaveDisabled}
                    isLoading={movingInProgress}
                    {...actionId}
                    {...{ id: 'editor_header_save_as_template_save' }}
                >
                    {translate('modal.save-as-template.create')}
                </Button>
            }
            actionSecondary={
                <Button
                    variant="secondary"
                    onClick={onModalClose}
                    {...cancelId}
                    {...{ id: 'editor_header_save_as_template_cancel' }}
                >
                    {translate('modal.cancel.title')}
                </Button>
            }
        >
            <InputField
                value={name}
                onChange={setName}
                isInvalid={name.length > MAX_INPUT_SIZE}
                label={translate('modal.save-as-template.label.name')}
                helperText={name.length > MAX_INPUT_SIZE && translate('input.error.max.length')}
                hasClearButton
                {...templateNameId}
                {...{ id: 'editor_header_save_as_template_template_name' }}
            />
            <Form.Group>
                <Form.Label>{translate('modal.save-as-template.label.category')}</Form.Label>
                <Dropdown
                    position="bottom"
                    trigger={({ isOpen, ...triggerProps }) => (
                        <Button
                            isFullWidth
                            isPressed={isOpen}
                            isDisabled={isFetching}
                            rightIcon={<Dropdown.Arrow />}
                            icon={getSelectedCategoryThumbnail()}
                            {...triggerProps}
                            {...categoryDropdownId}
                            {...{ id: 'editor_header_save_as_template_category_dropdown' }}
                        >
                            <Dropdown.SelectButtonText>{getActiveCategoryName()}</Dropdown.SelectButtonText>
                        </Button>
                    )}
                >
                    <Dropdown.SearchInput
                        value={searchValue}
                        onChange={handleSearchValueChange}
                        placeholder={translate('modal.template.move.search.category')}
                        aria-label={translate('modal.template.move.search.category')}
                        {...categoryDropdownSearchId}
                        {...{ id: 'editor_header_save_as_template_category_dropdown_search' }}
                    />
                    <Dropdown.SectionTitle> </Dropdown.SectionTitle>
                    {isFetching && (
                        <Dropdown.Item
                            onClick={() => null}
                            isDisabled
                            icon={
                                <Button
                                    isLoading
                                    variant="clean"
                                    aria-label={translate('modal.template.move.search.loading')}
                                />
                            }
                        >
                            <Dropdown.SelectButtonText>
                                {translate('modal.template.move.fetch.category')}
                            </Dropdown.SelectButtonText>
                        </Dropdown.Item>
                    )}

                    {categories?.length === 0 && !isFetching && (
                        <Dropdown.Text>{translate('modal.template.move.results.none')}</Dropdown.Text>
                    )}

                    {categories?.length > 0 ? (
                        <>
                            {categories.map((singleCategory) => (
                                <Dropdown.Item
                                    onClick={() => {
                                        setSelectedCategory((prevSelected) =>
                                            prevSelected === singleCategory ? null : singleCategory,
                                        );
                                    }}
                                    key={singleCategory.id}
                                    isChecked={singleCategory.name === selectedCategory?.name}
                                    role="menuitemradio"
                                    icon={getCategoryThumbnail(singleCategory)}
                                >
                                    {singleCategory.name}
                                </Dropdown.Item>
                            ))}
                        </>
                    ) : null}
                </Dropdown>
            </Form.Group>
            <TextareaField
                label={translate('modal.save-as-template.label.description')}
                onChange={setDescription}
                value={description}
                placeholder={translate('modal.save-as-template.description.placeholder')}
                helperText={translate('modal.save-as-template.label.description.helperText', {
                    symbols: description.length,
                    limit: MAX_DESCRIPTION_LENGTH,
                })}
                maxLength={MAX_DESCRIPTION_LENGTH}
                {...descriptionTestId}
                {...{ id: 'editor_header_save_as_template_description' }}
            />
        </Modal>
    );
};

export default memo(SaveAsTemplateModal);
