import styled from 'styled-components';
import { Card, token } from '@bynder/design-system';
import { CardBoxProps } from 'packages/pages/designs/types';

export const AssetCardPlaceholder = styled(Card.IconPlaceholder)`
    & svg {
        color: ${token.gray400a};
        fill: ${token.gray400a};
        width: ${token.spacing9};
        height: 140px;
    }
`;

export const AssetCardImage = styled(Card.Img)`
    object-fit: contain;
`;

export const CardBox = styled.div<CardBoxProps>`
    padding: ${token.spacing2}; // reserve space for outline
    img {
        height: ${({ defaultHeight }) => `${defaultHeight}px`};
    }
    a > div > div > div > svg {
        height: ${({ defaultHeight }) => `${defaultHeight}px`};
    }
`;
