import React from 'react';
import {
    IconSketch,
    IconPhotoshop,
    IconSharedFolder,
    IconUserGroup,
    IconImage,
    IconVideo,
    IconBuilding,
    IconSound,
} from '@bynder/icons';
import { Creative } from 'packages/store/creatives/types';
import { ImportedTemplate } from 'packages/store/importTemplate/types';
import { IdentityRole, IdentityType } from 'packages/store/sharing/types';
import { Template } from 'packages/store/templates/types';
import { UploadType } from 'packages/store/uploads/types';

export const NO_PREVIEW_TYPES = ['AUDIO', 'PSD', 'SKETCH'];

export const NO_PENDING_TYPES = ['PSD', 'SKETCH'];

export const contentIconMap = {
    VIDEO: <IconVideo />,
    IMAGE: <IconImage />,
    AUDIO: <IconSound />,
    PSD: <IconPhotoshop />,
    SKETCH: <IconSketch />,
};

export const getDurationInSec = (item: Creative | Template | ImportedTemplate): number => {
    if (item.duration && item.frameRate) {
        return item.duration / item.frameRate;
    }

    return 0;
};

export const getFormattedTime = (
    item: Creative | Template | ImportedTemplate | { duration: number; frameRate: number },
): string => {
    if (item.duration && item.frameRate) {
        const sec = item.duration / item.frameRate;

        return new Date(1000 * sec).toISOString().substring(14, 19);
    }

    return '';
};

export const getUploadsFormattedTime = (item: UploadType | { duration: number }): string => {
    if (item.duration) {
        return new Date(item.duration).toISOString().substring(14, 19);
    }

    return '';
};

export const parseAspectRatio = (ratio: string) => {
    if (!ratio) {
        return 16 / 10;
    }

    const values = ratio.split(':');

    return Number(values[0]) / Number(values[1]);
};

export const getCreativeSharingIcon = (design, isProjectDetails, identityRole = IdentityRole.CONTRIBUTOR) => {
    const getContributors =
        design.grantedPermissions &&
        design.grantedPermissions.filter(
            (perm) =>
                perm.securityIdentity.securityIdentityType !== IdentityType.ALL_USERS &&
                perm.grantedPermissions.find((item) => item.permission === identityRole && item.granted),
        );

    const getProjectPermissions =
        design.grantedPermissions &&
        design.grantedPermissions.filter(
            (perm) =>
                perm.projectGrantedPermissions !== null &&
                perm.projectGrantedPermissions.some(
                    (projectPerm) =>
                        (projectPerm.permission === identityRole || projectPerm.permission === IdentityRole.OWNER) &&
                        projectPerm.granted,
                ),
        );

    if (getProjectPermissions && getProjectPermissions.length > 0 && !isProjectDetails) {
        return <IconSharedFolder />;
    }

    if (getContributors && getContributors.length > 0) {
        return <IconUserGroup />;
    }

    return null;
};

export const getCollectionSharingIcon = (
    project,
    { getIdentityRoleAllUsers, getIdentityRole },
    identityRole = IdentityRole.CONTRIBUTOR,
) => {
    if (getIdentityRoleAllUsers(project.grantedPermissions) === identityRole) {
        return <IconBuilding />;
    }

    const getContributors =
        project.grantedPermissions &&
        project.grantedPermissions.filter(
            (perm) =>
                perm.securityIdentity.securityIdentityType !== IdentityType.ALL_USERS &&
                perm.grantedPermissions.find((item) => item.permission === identityRole && item.granted),
        );

    if (getContributors && getContributors.length > 0) {
        return <IconUserGroup />;
    }

    return getIdentityRole(project.grantedPermissions) === identityRole ? <IconUserGroup /> : null;
};
