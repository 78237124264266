export enum SnapPointTypes {
    NW,
    SW,
    NE,
    SE,
    CENTER,
}

export class SnapPoint {
    elementId: number | string;
    type: SnapPointTypes;
    x: number;
    y: number;

    constructor(elementId: number | string, type: SnapPointTypes, x: number, y: number) {
        this.elementId = elementId;
        this.type = type;
        this.x = x;
        this.y = y;
    }
}
