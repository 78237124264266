import React from 'react';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { Thumbnail, notify } from '@bynder/design-system';
import { IconDelete, IconRename } from '@bynder/icons';
import { Translate } from '@bynder/localization';
import { catchResponseError } from 'packages/helpers/helpers';
import { fetchCollection } from '~/store/collections/collections.actions';
import * as types from '../types';
import AssetsService from '../../services/AssetsService';

export function fetchAssetsByOptions(fetchOptions, clearData = true, showLoadingBar = false) {
    return (dispatch, getState) => {
        const lastRequestDate = Date.now();

        dispatch({
            type: types.GET_ASSETS_REQUEST,
            searching: getState().assets.fetchOptions.search !== fetchOptions.search,
            lastRequestDate,
        });
        const customerId = getState().user.currentCompany;

        if (clearData) {
            fetchOptions.page = 0;
        }

        if (showLoadingBar) {
            dispatch(showLoading());
        }

        AssetsService.getAssets({ ...fetchOptions, customerId })
            .then((res: any) => {
                const { assets, totalCount, status, page } = res;

                if (showLoadingBar) {
                    dispatch(hideLoading());
                }

                if (status !== 200) {
                    return (
                        showLoadingBar &&
                        notify({
                            title: 'Oops! Something went wrong. Please refresh your page.',
                            variant: 'error',
                        })
                    );
                }

                if (page) {
                    fetchOptions.page = page;
                }

                const currentLastRequestDate = getState().assets.lastRequestDate;

                if (!currentLastRequestDate || currentLastRequestDate <= lastRequestDate) {
                    dispatch({ type: types.GET_ASSETS_SUCCESS, assets, fetchOptions, clearData, totalCount });
                }
            })
            .catch(catchResponseError);
    };
}

export function updateAssetsStatus(assets) {
    return {
        type: types.ASSETS_CHANGE_SOME,
        assets,
    };
}

let isFetchingUploadOptions = false;

export function getUploadOptions() {
    return async (dispatch, getState) => {
        const customerId = getState().user.currentCompany;
        if (isFetchingUploadOptions) {
            return;
        }

        isFetchingUploadOptions = true;

        dispatch({ type: types.GET_PATH_REQUEST });

        try {
            const { status, json } = await AssetsService.getUploadOptions(customerId);
            if (status === 200) {
                dispatch({ type: types.GET_PATH_SUCCESS, uploadOptions: json });
            } else {
                notify({
                    title: 'Oops! Something went wrong. Please refresh your page.',
                    variant: 'error',
                });
            }
        } catch (error) {
            catchResponseError(error);
        } finally {
            isFetchingUploadOptions = false;
        }
    };
}

export function deleteAssets(ids) {
    return (dispatch, getState) => {
        const customerId = getState().user.currentCompany;
        dispatch({ type: types.DELETE_ASSETS_REQUEST });
        AssetsService.deleteAssets({ ids, customerId })
            .then(({ status }) => {
                if (status === 200) {
                    dispatch({ type: types.DELETE_ASSETS_SUCCESS });
                    dispatch(fetchAssetsByOptions(getState().assets.fetchOptions, true));
                    notify({
                        thumbnail: <Thumbnail variant="clean" shape="circle" icon={<IconDelete />} />,
                        title: <Translate id="modal.uploads.delete.success" count={ids.length} />,
                        variant: 'success',
                    });
                } else {
                    dispatch({ type: types.DELETE_ASSETS_FAIL });
                    notify({
                        title: <Translate id="modal.uploads.rename.failure" count={ids.length} />,
                        variant: 'error',
                    });
                }
            })
            .then(() => {
                const collectionId = getState().collections.collection?.id;

                // Fetch collection to update assets count and thumbnail
                if (collectionId) {
                    dispatch(fetchCollection(collectionId, false));
                }
            })
            .catch(catchResponseError);
    };
}

export function updateAssetList() {
    return (dispatch, getState) => {
        const { fetchOptions } = getState().assets;

        dispatch(fetchAssetsByOptions(fetchOptions, true));
    };
}

export function handleToggleItem(id, allChecked) {
    return {
        type: types.TOGGLE_ASSETS_ITEM,
        id,
        allChecked,
    };
}

export function handleDeselectAll() {
    return {
        type: types.ASSETS_DESELECT_ALL,
    };
}

export function editAssetName(id, name) {
    return (dispatch, getState) => {
        dispatch({ type: types.RENAME_ASSET_IN_PROGRESS, id });
        AssetsService.editFileName(id, name)
            .then(({ asset, status }) => {
                if (status === 200) {
                    dispatch({ type: types.RENAME_ASSET_SUCCESS, id });
                    dispatch({
                        type: types.ASSETS_CHANGE_SOME,
                        assets: [asset],
                    });
                    notify({
                        thumbnail: <Thumbnail variant="clean" shape="circle" icon={<IconRename />} />,
                        title: <Translate id="modal.uploads.rename.success" />,
                        variant: 'success',
                    });
                } else {
                    dispatch({ type: types.RENAME_ASSET_FAILURE, id });
                    notify({
                        title: <Translate id="modal.uploads.rename.failure" />,
                        variant: 'error',
                    });
                }
            })
            .catch(catchResponseError);
    };
}
