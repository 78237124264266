import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { getTemplatesActionProgress } from 'packages/store/templates/templates.selectors';
import { type Template } from 'packages/store/templates/types';
import { deleteTemplates } from '~/store/templates/templates.actions';
import useQueryParams from 'packages/hooks/useQueryParams';
import modalContainer from 'packages/common/modalContainer';

const useTemplateDeleteModal = (deleteCallback = () => {}) => {
    const [deleteModalTemplate, setDeleteModalTemplate] = useState<Template[] | null>(null);
    const { deleteInProgress } = useSelector(getTemplatesActionProgress);
    const { translate } = useTranslate();
    const { getQueryParam, resetQueryParams } = useQueryParams();
    const dispatch = useDispatch();

    const showModal = deleteModalTemplate !== null;
    const selectedTemplatesIds = deleteModalTemplate && deleteModalTemplate.map((template) => template.id);
    const categoryId =
        (deleteModalTemplate && deleteModalTemplate.length === 1 && deleteModalTemplate[0].categoryId) || null;

    const renderModalTemplateDelete = () => (
        <Modal
            container={modalContainer}
            title={translate('modal.design.create.template.delete.title')}
            isOpen={showModal}
            onClose={() => setDeleteModalTemplate(null)}
            actionPrimary={
                <Button
                    variant="primary"
                    onClick={() => {
                        dispatch(deleteTemplates(selectedTemplatesIds, categoryId));

                        const templateId = getQueryParam('template');

                        if (templateId && selectedTemplatesIds && selectedTemplatesIds.includes(Number(templateId))) {
                            resetQueryParams(['template']);
                        }

                        setDeleteModalTemplate(null);
                        deleteCallback();
                    }}
                    isLoading={deleteInProgress}
                    title={translate('modal.design.create.template.delete.actionButton')}
                >
                    {translate('modal.design.create.template.delete.actionButton')}
                </Button>
            }
            actionSecondary={
                <Button
                    variant="secondary"
                    onClick={() => setDeleteModalTemplate(null)}
                    title={translate('modal.cancel.title')}
                >
                    {translate('modal.cancel.title')}
                </Button>
            }
        >
            {translate('modal.design.create.template.delete.info')}
        </Modal>
    );

    return {
        setDeleteModalTemplate,
        renderModalTemplateDelete,
    };
};

export default useTemplateDeleteModal;
