import styled, { css } from 'styled-components';
import { Card, token } from '@bynder/design-system';

const pageLimits = css`
    min-width: 48px;
    max-width: 100px;
    min-height: 48px;
    max-height: 100px;
`;

export const PagesContainer = styled.div`
    padding-top: ${token.spacing5};
    padding-bottom: ${token.spacing5};
    position: relative;
    width: 148px;
    height: 100%;
`;

export const PagesWrapper = styled.div`
    position: relative;
    width: 148px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const PagesList = styled.div<{ alignment: string }>`
    position: absolute;
    top: 0;
    width: 100px;
    height: 100%;
    padding-top: ${token.spacing5};
    padding-bottom: ${token.spacing5};
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => props.alignment};
    align-items: center;
`;

export const PageTooltip = styled.div`
    margin-bottom: ${token.spacing5};
    &:first-child {
        padding-top: ${token.spacing5};
    }
    &:last-child {
        margin-bottom: 0;
        padding-bottom: ${token.spacing5};
    }
`;

export const PageCard = styled(Card)`
    ${pageLimits};
    display: flex;
    justify-content: center;
    width: 100px;
    & img {
        max-height: 100px;
        object-fit: initial;
    }
    > div {
        min-height: auto;
    }
    button {
        width: auto;
    }
`;

export const PageCardFrame = styled(Card.Frame)`
    ${pageLimits};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const PageBlur = styled.div<{ top: number | string; bottom: number | string }>`
    width: 100%;
    height: 16px;
    position: absolute;
    z-index: 2;
    bottom: ${(props) => props.bottom};
    top: ${(props) => props.top};
    background: ${(props) =>
        props.top !== 'auto'
            ? 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)'
            : 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)'};
`;

export const PageCardIconWrapper = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    width: 24px;
    height: 24px;
    transform: translate(50%, -50%);
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 50%;
`;

export const AspectRatioWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const AspectRatio = styled.div<{ width: number; height: number }>`
    ${pageLimits};
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
`;
