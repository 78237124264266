import React from 'react';
import { Thumbnail } from '@bynder/design-system';
import { formatBytes } from 'packages/pages/utils';
import { UploadType } from 'packages/store/uploads/types';
import { Box } from './TitleCell.styled';
import { contentIconMap, NO_PENDING_TYPES, NO_PREVIEW_TYPES } from '../../card/utils';
import { StyledTableCell } from './TableCell.styled';

const UploadTitleCell = ({ upload }: { upload: UploadType; isUploadDetails: boolean }) => {
    const hasPreview = !NO_PREVIEW_TYPES.includes(upload.type);
    const canHaveProgress = !NO_PENDING_TYPES.includes(upload.type);

    const imageUrl = () => {
        if (
            hasPreview &&
            upload.thumbnails &&
            upload.thumbnails['320x320'] &&
            upload.thumbnails['320x320'] !== 'https://storage.googleapis.com/null'
        ) {
            return upload.thumbnails['320x320'];
        }

        return '';
    };

    const getSubText = () => formatBytes(upload.fileSize);

    const isUploadDone =
        upload.status === 'PROCESSED' ||
        upload.status === 'COMPLETED' ||
        upload.status === 'FAILED' ||
        upload.status === 'CANCELLED';

    return (
        <StyledTableCell
            thumbnail={
                canHaveProgress && !isUploadDone ? (
                    <Thumbnail variant="clean" icon={contentIconMap[upload.type]}>
                        <Thumbnail.Overlay isLoading />
                    </Thumbnail>
                ) : (
                    <>
                        {imageUrl().length > 0 && hasPreview ? (
                            <Thumbnail variant="content" imageUrl={imageUrl()} />
                        ) : (
                            <Thumbnail variant="clean" icon={contentIconMap[upload.type]} />
                        )}
                    </>
                )
            }
            subtext={getSubText()}
        >
            <Box>
                <div>{upload.fileName}</div>
                <div />
            </Box>
        </StyledTableCell>
    );
};

export default UploadTitleCell;
