import React from 'react';
import { useTranslate } from '@bynder/localization';
import { colorToCSS, PREVIEW_CANVAS_ID } from '~/common/editor/editorHelper';
import { useElementStyleModal } from 'packages/pages/editor/RightSideMenu/Shots/Text/TextStyles/modals/useElementStyleModal';
import { FullWidthFlex } from './Component.styled';
import { SidebarSection } from '../../../../FormComponents/EditorColor/EditorColor.styled';
import ColorSearch from '../../../../FormComponents/EditorColor/components/ColorSearch';
import ColorPicker from '../../../../FormComponents/ColorPicker';
import ColorDropdown from '../../../../FormComponents/EditorColor/components/ColorsDropdown';
import ColorDetach from '../../../../FormComponents/EditorColor/components/ColorDetachDropdown';
import useFontColorManipulator from './useFontColorManipulator';

type Props = {
    creativeModel: any;
    selectedElement: any;
    disabled: boolean;
};

// eslint-disable-next-line import/no-unused-modules
export const FontColorManipulator = ({ creativeModel, selectedElement, disabled }: Props) => {
    const {
        hasBrandColors,
        isMixed,
        isAlphaMixed,
        setColor,
        setOpacity,
        colors,
        palettes,
        brandColors,
        customColors,
        onAppliedClick,
        setBrandColor,
        onColorDetach,
        elementColors,
    } = useFontColorManipulator({ creativeModel, selectedElement });
    const { openElementColorStylesModal, renderElementStyleModal } = useElementStyleModal({
        creativeModel,
        selectedElement,
    });
    const { translate } = useTranslate();

    const Wrapper = !hasBrandColors ? SidebarSection : React.Fragment;

    return (
        <FullWidthFlex justifyContent="space-between" alignItems="center">
            <Wrapper>
                {!hasBrandColors && (
                    <ColorPicker
                        isMixed={isMixed}
                        isAlphaMixed={isAlphaMixed}
                        id={selectedElement?.id}
                        name="elementPropertyColor"
                        handleOnChange={setColor}
                        handleOpacityOnChange={setOpacity}
                        value={colorToCSS(colors[0])}
                        canvas={PREVIEW_CANVAS_ID}
                        disabled={disabled}
                        label={translate('editor.sidebar.shots.colorpicker.label')}
                        htmlFor="element_property_color"
                        describedby="element_property_color_message"
                        testId="shots-text-font-color"
                        isValid
                    />
                )}
                <ColorDropdown
                    testId="shots-text-font-color"
                    colors={colors}
                    palettes={palettes}
                    selectedBrandColors={brandColors}
                    disabled={disabled}
                >
                    <ColorSearch
                        elementColors={elementColors}
                        onAppliedClick={onAppliedClick}
                        selectedBrandColors={brandColors}
                        selectedCustomColors={customColors}
                        setBrandColor={setBrandColor}
                        palettes={palettes}
                        openElementStylesModal={openElementColorStylesModal}
                        // TODO: use generateTestId and snake case
                        testId="shots-text-font-color"
                    />
                </ColorDropdown>
                {hasBrandColors && (
                    <ColorDetach
                        hasMultipleColors={isMixed}
                        testId="shots-text-font-color"
                        onColorDetach={onColorDetach}
                        disabled={disabled}
                    />
                )}
            </Wrapper>
            {renderElementStyleModal()}
        </FullWidthFlex>
    );
};

export default FontColorManipulator;
