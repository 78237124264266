import styled from 'styled-components';
import { Flex, textStyles } from '@bynder/design-system';

export const TitleWrapper = styled(Flex)`
    height: unset;
`;

export const Title = styled.h1`
    ${textStyles.uiTextL}
    margin-bottom: 0;
`;

export const PublicStatus = styled.p`
    ${textStyles.uiTextS}
    margin-bottom: 0;
`;
