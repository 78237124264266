import { useRef } from 'react';
import { calculateColumnCount } from './utils';

const useFluidWidth = () => {
    const gridContainer = useRef<HTMLDivElement>(null);

    const getColumnWidth = (width: number, minimalColumnWidth: number, gutterSize: number) => {
        const columnCount = calculateColumnCount(width, minimalColumnWidth, gutterSize);
        const freeSpaceForColumns = width - (minimalColumnWidth + gutterSize) * columnCount;
        if (freeSpaceForColumns > 0) {
            return minimalColumnWidth + freeSpaceForColumns / columnCount;
        }
        return minimalColumnWidth;
    };

    return { gridContainer, getColumnWidth };
};

export default useFluidWidth;
