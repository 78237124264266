import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Translate } from '@bynder/localization';
import { Table } from '@bynder/design-system';
import { Creative } from 'packages/store/creatives/types';
import { customerIdSelector } from 'packages/store/user/user.selectors';
import useAccessRights from 'packages/hooks/useAccessRights';
import { ActionStatus, DesignAction, Entity } from 'packages/hooks/useAccessRights/types';
import { getDesignEditLink } from 'packages/pages/designs/utils';
import OwnerNameCell from 'packages/pages/components/list/cell/OwnerNameCell';
import DateCreatedCell from 'packages/pages/components/list/cell/DateCell';
import DurationCell from 'packages/pages/components/list/cell/DurationCell';
import DesignTitleCell from 'packages/pages/components/list/cell/DesignTitleCell';
import { handleToggleItem } from '~/store/creatives/creatives.actions';
import { getCreatives } from 'packages/store/creatives/creatives.selectors';
import { OrderFilterType, SortingFilterType } from 'packages/pages/components/filters/components/Ordering/types';
import { MenuActions } from '../ContextualMenu/types';
import { ListItemProps } from './types';
import DesignContextMenu from '../ContextualMenu';

export const headers = [
    { id: 'checkboxes', title: '', notSortable: true },
    { id: 'name', title: <Translate id="listview.design.column.name" /> },
    { id: 'date_created', title: <Translate id="listview.design.column.date_created" /> },
    { id: 'date_updated', title: <Translate id="listview.design.column.last_modified" /> },
    { id: 'ownership', title: <Translate id="listview.design.column.ownership" />, notSortable: true },
    { id: 'duration', title: <Translate id="listview.design.column.duration" /> },
    { id: 'menu', title: '', notSortable: true },
];
export const columnWidths = ['auto', '40%', '20%', '20%', '20%', 'auto'];

const ListViewItem = ({ design, sort, menuActions }: ListItemProps) => {
    const { creatives: designs } = useSelector(getCreatives);
    const { isEntityActionAllowed } = useAccessRights();
    const currentCompany = useSelector(customerIdSelector);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [sortBy, sortingOrder] = sort;

    const isViewAllowed =
        isEntityActionAllowed(DesignAction.VIEW, Entity.DESIGN, design.grantedPermissions) === ActionStatus.ALLOWED;

    const handleOnClick = () => (isViewAllowed ? navigate(getDesignEditLink(design.id)) : null);

    const handleSelect = () => {
        const allChecked = designs.every(
            (i) => (i.checked && i.id !== design.id) || (!i.checked && i.id === design.id),
        );
        dispatch(handleToggleItem(design.id, allChecked));
    };

    return (
        <Table.Row onClick={handleOnClick} isSelected={design.checked} onSelect={handleSelect}>
            <DesignTitleCell design={design} isProjectDetails={false} />
            {sortBy !== 'date_updated' ? (
                <DateCreatedCell date={design.created} />
            ) : (
                <DateCreatedCell date={design.updated} />
            )}
            <OwnerNameCell item={design} />
            <DurationCell design={design} />
            <Table.Cell textFlow="no-wrap">
                <Table.StopPropagation>
                    <DesignContextMenu
                        design={design}
                        menuActions={menuActions}
                        companyId={currentCompany}
                        isListView
                    />
                </Table.StopPropagation>
            </Table.Cell>
        </Table.Row>
    );
};

export const renderItems = (
    designs: Creative[],
    sort: [SortingFilterType, OrderFilterType],
    menuActions: MenuActions,
) => designs.map((design) => <ListViewItem key={design.id} design={design} sort={sort} menuActions={menuActions} />);
