import styled, { css } from 'styled-components';
import { textStyles, token } from '@bynder/design-system';

export const PageBody = styled.div<{ isNewDesign?: boolean }>`
    ${({ isNewDesign }) =>
        isNewDesign
            ? css`
                  padding: ${token.spacing6} ${token.spacing2};
                  height: calc(100vh - 300px);
                  overflow-y: scroll;
              `
            : css`
                  padding: calc(${token.spacing6} - ${token.spacing2}) ${token.spacing6}
                      calc(${token.spacing6} - ${token.spacing2}) calc(${token.spacing6} - ${token.spacing2});
                  overflow-y: auto;
                  height: calc(100% - 150px);
              `}
`;

export const CategoriesCardBox = styled.div`
    a:hover {
        text-decoration: none;
        cursor: default;
    }
    a[href*='categories/'] {
        cursor: pointer;
    }
    a[href*='designs'] {
        cursor: pointer;
    }
`;

export const PageSectionHeader = styled.h2`
    ${textStyles.uiTextL}
    margin-left: ${token.spacing2};
    margin-bottom: ${token.spacing6};
`;

export const PageSectionSection = styled.div`
    padding: ${token.spacing6} 0;
`;

export const PageSectionGrid = styled.div``;
