import React, { useEffect, useRef, useState } from 'react';
import { Buffer, Card, token, Tooltip } from '@bynder/design-system';
import { IconError } from '@bynder/icons';
import PageFakeCard from 'packages/pages/design/components/PagePreview/PageFakeCard';
import {
    PageCard,
    PageTooltip,
    PageCardFrame,
    PageCardIconWrapper,
} from 'packages/pages/design/components/PagePreview/Component.styled';

export type PagePreviewItemBaseProps = {
    idx: number;
    onClick: (idx: number) => void;
    name: string;
    isLoading: boolean;
    isSelected: boolean;
    aspectRatio: string;
    url: string;
    isValid: boolean;
};

export const PagePreviewItemBase = ({
    idx,
    onClick,
    name,
    isLoading,
    isSelected,
    aspectRatio,
    url,
    isValid,
}: PagePreviewItemBaseProps) => {
    const isFirstLoading = useRef<boolean>(true);
    const timer = useRef<number | null>(null);
    const [showLoading, setShowLoading] = useState(isLoading);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isLoading) {
            setShowLoading(true);
            if (timer.current) window.clearTimeout(timer.current);
            return;
        }

        timer.current = window.setTimeout(() => {
            timer.current = null;
            isFirstLoading.current = !url;
            setShowLoading(false);
        }, 300);
    }, [isLoading]);

    useEffect(() => {
        if (isSelected && ref.current) {
            ref.current.scrollIntoView({ block: 'center' });
        }
    }, [isSelected]);

    return (
        <PageTooltip ref={ref}>
            <Tooltip content={name} position="right">
                <PageCard isSelected={isSelected} variant="clean" onClick={() => onClick(idx)}>
                    <PageCardFrame
                        height="fluid"
                        progressBar={!isFirstLoading.current && showLoading && <Buffer noCornerRadius />}
                        data-testid={`${name}_${idx}`}
                    >
                        {!url ? (
                            <PageFakeCard aspectRatio={aspectRatio} />
                        ) : (
                            <>
                                {!isValid && (
                                    <PageCardIconWrapper>
                                        <IconError style={{ color: token.red400 }} />
                                    </PageCardIconWrapper>
                                )}
                                <Card.Images>
                                    <Card.Img src={url} />
                                </Card.Images>
                            </>
                        )}
                    </PageCardFrame>
                </PageCard>
            </Tooltip>
        </PageTooltip>
    );
};
