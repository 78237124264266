import React from 'react';
import { notify, Thumbnail } from '@bynder/design-system';
import { IconCheck, IconCancel } from '@bynder/icons';
import { Translate } from '@bynder/localization';
import { BlankItem, MediaTypeSelection } from 'packages/pages/designCreateModal/components/Blank/types';
import { DesignSubPages } from 'packages/pages/designCreateModal/components/Sidebar/types';
import DesignsService from 'packages/services/Designs';
import { catchResponseError } from 'packages/helpers/helpers';
import { sendAppcuesEvent } from '~/helpers/RouteWithAppcues';
import { actionTypes } from './types';
import { sendAmplitudeNewDesignEvent } from '~/store/amplitude/actions';
import { AMPLITUDE_TYPES } from '~/store/amplitude/constants';

export function setPage(page: DesignSubPages) {
    return {
        type: actionTypes.NEW_DESIGN_SET_PAGE,
        page,
    };
}

export function selectBlankType(selectedType: MediaTypeSelection) {
    return {
        type: actionTypes.NEW_DESIGN_BLANK_SELECT,
        selectedType,
    };
}

export function deselectBlankType(softMode?: boolean) {
    return {
        type: actionTypes.NEW_DESIGN_BLANK_DESELECT,
        softMode,
    };
}

export function setBlankType(selectedType: MediaTypeSelection, item: BlankItem) {
    return {
        type: actionTypes.NEW_DESIGN_BLANK_SET_VALUE,
        selectedType,
        item: { ...item, icon: undefined },
    };
}

export function createBlankDesign(collectionId?: string) {
    return async (dispatch, getState) => {
        dispatch({ type: actionTypes.NEW_DESIGN_BLANK_CREATE_REQUEST });

        const state = getState();
        const item: BlankItem = state.newDesign.blank[state.newDesign.blank.selectedType];

        const res = await DesignsService.createBlankDesign(
            {
                type: state.newDesign.blank.selectedType,
                pageName: item.label,
                ...item.size,
            },
            collectionId,
        ).catch(catchResponseError);

        if (!res || res.status >= 400) {
            dispatch({ type: actionTypes.NEW_DESIGN_BLANK_CREATE_FAILURE });
            notify({
                thumbnail: (
                    <Thumbnail
                        variant="clean"
                        shape="circle"
                        backgroundColor="red500"
                        icon={<IconCancel />}
                        iconColor="white"
                    />
                ),
                title: <Translate id="modal.design.create.blank.message.failure" />,
            });

            return;
        }

        const pagePreset = item.label;
        const origin = 'Blank';
        const newDesign = res.json;

        dispatch({
            type: actionTypes.NEW_DESIGN_BLANK_CREATE_SUCCESS,
            newDesign,
            origin,
            sizePreset: pagePreset,
        });

        dispatch(
            sendAmplitudeNewDesignEvent({
                eventType: AMPLITUDE_TYPES.CREATE_DESIGN,
                design: newDesign,
                additionalProps: {
                    origin,
                    pagePreset,
                },
            }),
        );

        notify({
            thumbnail: (
                <Thumbnail
                    variant="clean"
                    shape="circle"
                    backgroundColor="green500"
                    icon={<IconCheck />}
                    iconColor="white"
                />
            ),
            title: <Translate id="modal.design.create.blank.message.success" />,
        });

        sendAppcuesEvent('Blank design created', { id: res.json.id, name: res.json.name, collectionId });

        dispatch(setPage(DesignSubPages.ALL));

        return `/designs/${res.json.id}/`;
    };
}

export function blankDesignQueried(creativeType: string, size: string, collectionId?: string) {
    return async (dispatch) => {
        dispatch({ type: actionTypes.NEW_DESIGN_BLANK_CREATE_REQUEST });
        const width = size.split('x')[0];
        const height = size.split('x')[1];

        const res = await DesignsService.createBlankDesign(
            {
                type: creativeType,
                width,
                height,
            },
            collectionId,
        ).catch(catchResponseError);

        if (!res || res.status >= 400) {
            dispatch({ type: actionTypes.NEW_DESIGN_BLANK_CREATE_FAILURE });

            notify({
                thumbnail: (
                    <Thumbnail
                        variant="clean"
                        shape="circle"
                        backgroundColor="red500"
                        icon={<IconCancel />}
                        iconColor="white"
                    />
                ),
                title: <Translate id="modal.design.create.blank.message.failure" />,
            });
        }

        dispatch({ type: actionTypes.NEW_DESIGN_BLANK_CREATE_SUCCESS });

        dispatch(
            sendAmplitudeNewDesignEvent({
                eventType: AMPLITUDE_TYPES.CREATE_DESIGN,
                design: res.json,
                additionalProps: {
                    origin: 'Blank',
                    pagePreset: size,
                },
            }),
        );

        notify({
            thumbnail: (
                <Thumbnail
                    variant="clean"
                    shape="circle"
                    backgroundColor="green500"
                    icon={<IconCheck />}
                    iconColor="white"
                />
            ),
            title: <Translate id="modal.design.create.blank.message.success" />,
        });

        dispatch(setPage(DesignSubPages.ALL));

        return `/designs/${res.json.id}/`;
    };
}
