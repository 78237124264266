import type { IAssetsLoader, ICompositor, IMultiPageVideoModel, IShot } from '@bynder-studio/render-core';
import { Position } from '@bynder-studio/render-core';
import CanvasManipulation from '../../Compositor/CanvasManipulationCompositor/CanvasManipulation';
import { BaseManipulationRenderer } from '../BaseManipulationRenderer/BaseManipulationRenderer';
import { IPlaybackManipulationRenderer } from './IPlaybackManipulationRenderer';
import { LoopInterval } from '../PlaybackRenderer/IPlaybackRenderer';

export class PlaybackManipulationRenderer extends BaseManipulationRenderer implements IPlaybackManipulationRenderer {
    // @ts-ignore
    selectedShot: IShot;

    constructor(
        videoModel: IMultiPageVideoModel,
        assetLoader: IAssetsLoader,
        compositor: ICompositor,
        canvasManipulation: CanvasManipulation,
        loopInterval: LoopInterval,
    ) {
        super(videoModel, assetLoader, compositor, canvasManipulation, loopInterval);
        const shots = videoModel.getShots();

        if (shots && shots.length) {
            this.selectedShot = shots[0];
        }
    }

    redraw() {
        const compModel = this.creativeModel.getCompModel(this.frameIndex);
        this.redrawCompModel(compModel);
    }

    setPanPosition(panPosition: Position) {
        super.setPanPosition(panPosition);
        this.drawCompModel(this.creativeModel.getCompModel(this.frameIndex));

        return this;
    }

    selectShot(shotIndex: number): void {
        const shot = (this.creativeModel as IMultiPageVideoModel).getShots()[shotIndex];
        this.selectedShot = shot;
        this.eventEmitter.emit('shotSelected', {
            shot,
        });
    }

    getSelectedShot(): IShot {
        return this.selectedShot;
    }

    startPlayback(): void {
        super.startPlayback();
        this.canvasManipulation.onPlayingStateChange(this.getIsPlaying());
    }

    pausePlayback(): void {
        super.pausePlayback();
        this.canvasManipulation.onPlayingStateChange(this.getIsPlaying());
    }
}
