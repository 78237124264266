import React, { useEffect, useMemo, useState } from 'react';
import { MAX_CP_NAME_LENGTH } from '@bynder-studio/misc';
import { Button, InputField, Modal, Thumbnail, token } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconSync } from '@bynder/icons';
import generateTestId from '~/helpers/testIdHelpers';
import { ModalPropsType } from '../../types';
import modalContainer from 'packages/common/modalContainer';

const CreateModal = ({ elementName, isOpen, onClose, onCreate }: ModalPropsType) => {
    const [propertyName, setPropertyName] = useState<string>(elementName.slice(0, MAX_CP_NAME_LENGTH));

    const isInputValid = propertyName.length <= MAX_CP_NAME_LENGTH;
    const isActionDisabled = !propertyName.length || propertyName.length > MAX_CP_NAME_LENGTH;

    const { translate } = useTranslate();

    const handleCreate = () => {
        onCreate(propertyName);
        onClose();
    };

    const onKeyEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();

            if (isInputValid && !isActionDisabled) {
                handleCreate();
            }
        }
    };

    useEffect(() => {
        if (isOpen && elementName !== propertyName) {
            setPropertyName(elementName.slice(0, MAX_CP_NAME_LENGTH));
        }
    }, [isOpen, elementName]);

    return (
        <Modal
            container={modalContainer}
            isClosedOnOverlayClick
            title={translate('editor.sidebar.shots.contentProperty.create.modal.title')}
            isOpen={isOpen}
            onClose={onClose}
            actionPrimary={
                <Button
                    variant="primary"
                    onClick={handleCreate}
                    isDisabled={isActionDisabled || !isInputValid}
                    {...generateTestId('editor : modal : new content property create button')}
                >
                    {translate('editor.sidebar.shots.contentProperty.create.modal.create')}
                </Button>
            }
            actionSecondary={
                <Button
                    variant="secondary"
                    onClick={onClose}
                    {...generateTestId('editor : modal : new content property cancel button')}
                >
                    {translate('editor.sidebar.shots.contentProperty.create.modal.cancel')}
                </Button>
            }
        >
            <InputField
                label={translate('editor.sidebar.shots.contentProperty.create.modal.label')}
                value={propertyName}
                placeholder={translate('editor.sidebar.shots.contentProperty.create.modal.placeholder')}
                onChange={setPropertyName}
                onKeyDown={onKeyEnter}
                isInvalid={!isInputValid}
                helperText={!isInputValid && translate('input.error.max.length')}
                icon={
                    <Thumbnail
                        shape="circle"
                        size="xs"
                        backgroundColor={token.teal500}
                        icon={<IconSync size="16" style={{ color: token.white }} />}
                    />
                }
                hasClearButton
                {...generateTestId('editor : modal : new content property name input')}
            />
        </Modal>
    );
};

export default CreateModal;
