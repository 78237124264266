import { crossOrigin } from '~/helpers/video';

export function isRenderingCompleted(v) {
    return !['IN_PROGRESS', 'PENDING', 'PENDING_EXTERNAL_ASSET_PROCESSING'].includes(v.version_render_state);
}

export function framesToTime(frames: number, frameRate = 25) {
    const symbol = frames?.toString().startsWith('-') ? '-' : '';
    frames = Math.abs(frames);

    const frame = frames % frameRate;
    frames = (frames - frame) / frameRate;
    const second = frames % 60;
    frames = (frames - second) / 60;

    const format = (value) => (value < 10 ? '0' + value : value);

    return `${symbol}${format(frames)}:${format(second)}:${format(frame)}`;
}

export function timeToFrames(time, frameRate = 25) {
    const sign = time.startsWith('-') ? -1 : 1;
    const [minute, second, frame] = time
        .replace('-', '')
        .split(':')
        .map((s) => Number(s) || 0);

    return sign * (minute * 1500 + second * frameRate + Number(frame));
}

export function searchToQueryParams(search) {
    return search
        .substr(1)
        .split('&')
        .reduce((params, query) => {
            const [key, value] = query.split('=');
            params[key] = value;

            return params;
        }, {});
}

export function updateInArray(array, findFn, updateFn) {
    const index = array.findIndex(findFn);
    const elem = array.find(findFn);
    const newArray = [...array];
    newArray.splice(index, 1, updateFn(elem));

    return newArray;
}

export function dataURItoArrayBuffer(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const data = dataURI.split(',')[1];

    const byteString = atob(data);

    // separate out the mime component
    // const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return ab;
}

export function checkAssetAccessible(url, type): Promise<[boolean, any]> {
    return new Promise((resolve) => {
        if (type === 'image') {
            resolve([true, {}]);

            return;
        }

        const asset = document.createElement(type);
        Object.entries(crossOrigin(url)).forEach(([name, value]) => {
            asset[name] = value;
        });
        asset.preload = 'metadata';
        asset.onerror = (error: string) => {
            resolve([false, {}]);
        };
        asset.onloadedmetadata = () => {
            const duration = asset.duration * 1000;
            const { videoWidth: width, videoHeight: height } = asset;

            resolve([true, { duration, width, height }]);
        };
        asset.src = url;
    });
}
