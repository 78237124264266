import { ContentProperty, CreativeTypes, TextStyle } from '@bynder-studio/render-core';
import ProxyServerConnector from '../web/src/services/connectors/ProxyServerConnector';

type Preset = {
    elements: any[];
    textStyles: TextStyle[];
    contentProperties: ContentProperty[];
    customerId: number;
    name: string;
    type: CreativeTypes;
};

class PresetsService extends ProxyServerConnector {
    gateway: ProxyServerConnector;

    constructor(path = '/template-preset') {
        super(path);
        // @ts-ignore
        this.gateway = new ProxyServerConnector(path, GATEWAY_PREFIX);
    }

    getPresets({
        customerId,
        size,
        page,
        search,
        sort,
        creativeType,
    }: {
        customerId: number;
        size: number;
        page: number;
        search: string;
        sort: [string, string];
        creativeType: CreativeTypes;
    }) {
        const query =
            `?customerId=${customerId}&` +
            `size=${size}&` +
            `sortingField=${sort[0].toUpperCase()}&` +
            `sortingOrder=${sort[1]}&` +
            `page=${page}${search ? '&query=' + search : ''}` +
            `&type=${creativeType}`;

        return this.gateway.get(query);
    }

    getPresetById(id: string) {
        return this.gateway.get(`/${id}`);
    }

    addPreset(preset: Preset) {
        return this.gateway.put('', preset);
    }

    deletePreset(id: string) {
        return this.gateway.delete(`/${id}`);
    }

    updatePreset(id: string, presetData: { name: string }) {
        return this.gateway.put(`/${id}`, presetData);
    }
}

export default new PresetsService();
