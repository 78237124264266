import React, { useState } from 'react';
import { type BackgroundColorParams, type ShapeElement, type TextElement } from '@bynder-studio/render-core';
import { ColorStylesModal } from './ColorStylesModal';

/**
 * propertyName can be nested, e.g. 'shape.fill.color'
 */
export const useColorStyleModal = ({
    selectedElement,
    propertyName,
    brandColorProperty,
    title,
    globalPropertyName = '',
}: {
    selectedElement: ShapeElement | TextElement | BackgroundColorParams;
    propertyName: string;
    brandColorProperty: string;
    title: string;
    globalPropertyName?: string;
}) => {
    const [isModalOpen, setModalOpen] = useState(false);

    const onClose = () => {
        setModalOpen(false);
    };

    const openConfigureColorsModal = () => {
        setModalOpen(true);
    };

    const renderColorStylesModal = () => (
        <ColorStylesModal
            isOpen={isModalOpen}
            onClose={onClose}
            element={selectedElement}
            propertyName={propertyName}
            brandColorProperty={brandColorProperty}
            title={title}
            globalPropertyName={globalPropertyName}
        />
    );

    return {
        openConfigureColorsModal,
        renderColorStylesModal,
    };
};
