import swUrl from './service-worker';

if ('serviceWorker' in navigator) {
    navigator.serviceWorker
        .register(swUrl, { scope: '/' })
        .then(handleRegistration)
        .catch((error) => `Service worker registration failed with ${error}`);
} else {
    console.error('service worker is not available');
}

function handleRegistration(registration: ServiceWorkerRegistration) {
    if (registration.installing) {
        console.log('Service worker installing');

        return;
    }

    if (registration.waiting) {
        console.log('Service worker installed');

        return;
    }

    console.log('Service worker active');
}
