import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Modal } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import modalContainer from 'packages/common/modalContainer';
import useAccessRights from 'packages/hooks/useAccessRights';
import features from '~/configs/features';
import BrowseButton from '../../FormComponents/BrowseButton';
import { ContentType } from '../../FormComponents/BrowseButton/types';
import { AudioFeature } from '../../FormComponents/AudioFeature';
import { FormSectionContent } from '../../index.styled';

const GlobalAudio = ({ creativeModel, frameRate, disabled, id }) => {
    const { translate } = useTranslate();
    const [audioTrack, setAudioTrack] = useState(creativeModel?.getGlobalAudio());
    const [showModal, setShowModal] = useState(false);
    const { isPlatformAllowed } = useAccessRights();

    const duration = creativeModel?.getPlaybackDuration()?.getDuration();
    const durationSeconds = duration / (creativeModel?.getPlaybackDuration()?.getFrameRate() || frameRate);

    const handleSelectSource = useCallback(
        (data) => {
            let newParams: any = {
                src: null,
                srcType: null,
                srcId: null,
                fileName: '',
                offsetTime: 0,
            };
            const { source, offsetTime, url, name } = data;

            if (source) {
                newParams = {
                    src: url,
                    srcType: 'ASSET_MANAGER',
                    srcId: source,
                    fileName: name,
                    offsetTime,
                };
            }

            setAudioTrack({ ...audioTrack, ...newParams });
            creativeModel.updateGlobalProperty('GLOBAL_AUDIO', newParams);
        },
        [audioTrack, creativeModel],
    );

    const confirmRemove = () => {
        handleSelectSource({ id: audioTrack.id });
        setShowModal(false);
    };

    const toggleLock = () => {
        setAudioTrack({ ...audioTrack, locked: !audioTrack.locked });
        creativeModel.updateGlobalProperty('GLOBAL_AUDIO', { locked: !audioTrack.locked });
    };

    const handleVolumeChange = (volume: number) => {
        setAudioTrack({ ...audioTrack, volume });
        creativeModel.updateGlobalProperty('GLOBAL_AUDIO', { volume });
    };

    const handleFadeInChange = (fadeIn: number) => {
        setAudioTrack({ ...audioTrack, fadeIn });
        creativeModel.updateGlobalProperty('GLOBAL_AUDIO', { fadeIn });
    };

    const handleFadeOutChange = (fadeOut: number) => {
        setAudioTrack({ ...audioTrack, fadeOut });
        creativeModel.updateGlobalProperty('GLOBAL_AUDIO', { fadeOut });
    };

    useEffect(() => {
        setAudioTrack(creativeModel?.getGlobalAudio());
    }, [creativeModel?.getGlobalAudio()]);

    return (
        <FormSectionContent onSubmit={(event) => event.preventDefault()}>
            <Form.Group>
                <Modal
                    container={modalContainer}
                    isOpen={showModal}
                    title={translate('editor.sidebar.globals.audio.modal.title')}
                    onClose={() => setShowModal(false)}
                    actionPrimary={
                        <Button variant="primary" onClick={confirmRemove}>
                            {translate('editor.sidebar.globals.audio.modal.footer')}
                        </Button>
                    }
                    actionSecondary={
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            {translate('editor.sidebar.globals.audio.modal.cancel')}
                        </Button>
                    }
                >
                    {translate('editor.sidebar.globals.audio.modal.text')}
                </Modal>
                <BrowseButton
                    id={id}
                    label={translate('editor.sidebar.globals.audio.input.label')}
                    htmlFor={id}
                    placeholder={translate('editor.sidebar.globals.audio.input.placeholder')}
                    type={ContentType.AUDIO}
                    frameRate={frameRate}
                    value={audioTrack.fileName}
                    selectedObject={{ ...audioTrack, duration }}
                    toggleLock={toggleLock}
                    locked={audioTrack.locked}
                    disabled={disabled}
                    testId="global_audio"
                    handleSelectSource={handleSelectSource}
                    addOn={() => setShowModal(true)}
                    duration={duration}
                    isEditorPage
                />
            </Form.Group>
            {isPlatformAllowed([features.MULTI_TRACK_AUDIO]) && (
                <AudioFeature
                    isDisabled={disabled || !audioTrack.fileName || audioTrack.locked}
                    duration={durationSeconds}
                    volume={audioTrack.volume}
                    fadeIn={audioTrack.fadeIn}
                    fadeOut={audioTrack.fadeOut}
                    onFadeInChange={handleFadeInChange}
                    onFadeOutChange={handleFadeOutChange}
                    onVolumeChange={handleVolumeChange}
                />
            )}
        </FormSectionContent>
    );
};

export default GlobalAudio;
