import React, { useEffect, useState } from 'react';
import { ContentTransform, IBaseMultiPageModel, TextElement } from '@bynder-studio/render-core';
import { Form } from '@bynder/design-system';
import MaybeTooltip from 'packages/common/MaybeTooltip';
import generateTestId from '~/helpers/testIdHelpers';
import { HORIZNTAL_ALIGNMENTS } from 'packages/pages/editor/RightSideMenu/Shots/Text/TextAlignment/utils';
import { AlignmentItem, AlignmentButton, Wrapper } from './Component.styled';
import type { AlignmentNumber } from 'packages/pages/editor/RightSideMenu/Shots/Text/TextAlignment/utils';

type Props = {
    creativeModel: IBaseMultiPageModel;
    selectedElement: TextElement;
    contentTransform: ContentTransform;
};

const toTestId = (testIdName: string, selected: boolean) =>
    `shots_text_horizontal_alignment_${testIdName}${selected ? '_selected' : ''}`;

const HorizontalAlignment = ({ creativeModel, selectedElement, contentTransform }: Props) => {
    const { id, locked } = selectedElement;
    const { horizontalAlignment } = contentTransform || {};
    const [value, setValue] = useState(horizontalAlignment);

    const handleOnChange = (alignment: AlignmentNumber) => {
        setValue(alignment);
        const param = {
            contentTransform: {
                ...contentTransform,
                horizontalAlignment: alignment,
            },
        };
        creativeModel.updateElement(id, param);
    };

    useEffect(() => {
        setValue(horizontalAlignment);
    }, [horizontalAlignment]);

    return (
        <Form.Group>
            <Wrapper>
                {HORIZNTAL_ALIGNMENTS.map((alignment) => (
                    <AlignmentItem key={alignment.name}>
                        <MaybeTooltip hasTooltip={!locked} content={alignment.name}>
                            <AlignmentButton
                                variant="clean"
                                isPressed={alignment.value === value}
                                isDisabled={locked}
                                onClick={() => handleOnChange(alignment.value)}
                                {...generateTestId(toTestId(alignment.testIdName, alignment.value === value))}
                            >
                                {alignment.icon({})}
                            </AlignmentButton>
                        </MaybeTooltip>
                    </AlignmentItem>
                ))}
            </Wrapper>
        </Form.Group>
    );
};

export default HorizontalAlignment;
