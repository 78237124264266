import React, { useCallback } from 'react';
import { Button, ModalBase } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import modalContainer from 'packages/common/modalContainer';

export const useValidationModal = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [errorType, setErrorType] = React.useState('generic');
    const { translate } = useTranslate();

    const toggleValidationModal = useCallback(
        (value: boolean, errorType = '') => {
            if (errorType) {
                setErrorType(errorType);
            }

            setIsOpen(value);
        },
        [setErrorType, setIsOpen],
    );

    const renderValidationModal = () => {
        return (
            <ModalBase
                container={modalContainer}
                isOpen={isOpen}
                onClose={() => {
                    toggleValidationModal(false);
                }}
            >
                <ModalBase.Header title={translate(`modal.assetsPicker.validationModal.title.${errorType}`)} />

                <ModalBase.Content>
                    {translate(`modal.assetsPicker.validationModal.description.${errorType}`)}
                </ModalBase.Content>

                <ModalBase.Footer
                    actionPrimary={
                        <Button
                            variant="primary"
                            onClick={() => {
                                toggleValidationModal(false);
                            }}
                        >
                            {translate('modal.assetsPicker.validationModal.button')}
                        </Button>
                    }
                />
            </ModalBase>
        );
    };

    return { toggleValidationModal, renderValidationModal };
};
