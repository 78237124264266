import React from 'react';
import { Tabs } from '@bynder/design-system';

type Props = {
    tabs: { value: string; label: string }[];
    activeTab: string;
    onChange: (value: string) => void;
};
const SettingsTabs = ({ tabs, activeTab, onChange }: Props) => (
    <Tabs data-testid="settings_tabs">
        {tabs.map((tab) => (
            <Tabs.Tab
                key={tab.label}
                label={tab.label}
                isActive={tab.value === activeTab}
                onClick={(e) => {
                    e.preventDefault();
                    onChange(tab.value);
                }}
            />
        ))}
    </Tabs>
);

export default SettingsTabs;
