import { useCallback, useEffect, useMemo, useState } from 'react';
import type { TextElement } from '@bynder-studio/render-core';
import { BaseMultiPageModel, TextStyle } from '@bynder-studio/render-core';
import useTextSelection from 'packages/hooks/useTextSelection';
import useForceUpdate from '~/hooks/useForceUpdate';
import useOverrides from './useOverrides';
import { collectElementStyleIds, textStyleToRunStyle } from './utils';

export function useTextStyles(creativeModel: BaseMultiPageModel, selectedElement: TextElement) {
    const { id } = selectedElement ?? {};
    const [searchValue, setSearchValue] = useState('');
    const searchTerm = searchValue.trim().toLowerCase();
    const textSelection = useTextSelection();
    const [styleId, isMixed, mixedValue] = textSelection.styleIdData || ['', false, ''];
    const textStyles = creativeModel.getTextStyles();
    const item = textStyles.getStyle(styleId);
    const styleIds = (textSelection.styleId || []).filter((id) => !!id) as string[];
    const { overrides, getStylesWithOverrides } = useOverrides(creativeModel);
    const forceUpdate = useForceUpdate();
    const elementStyleIds = collectElementStyleIds(selectedElement);

    const isAllowedToCreateTextStyle = useMemo(
        () =>
            Object.entries(textSelection).reduce((acc, [key, value]) => {
                if (key.endsWith('Data')) {
                    return acc;
                }

                if (key.startsWith('color')) {
                    return acc;
                }

                return acc && !value[1];
            }, true),
        [textSelection],
    );

    const applyStyle = useCallback<(styleId: string | null) => void>(
        (styleId) => {
            const param = {
                updateTextSettingForSelection: {
                    settings: {
                        styleId,
                    },
                },
            };

            creativeModel.updateElement(id, param);
        },
        [id, creativeModel],
    );

    const createTextStyle = useCallback<(name: string) => void>(
        (name) => {
            const styleId = selectedElement.createTextStyle(name);

            if (styleId) {
                applyStyle(styleId);
            }
        },
        [selectedElement, applyStyle],
    );

    const detachTextStyle = useCallback(() => {
        applyStyle(null);
    }, [applyStyle]);

    const renameTextStyle = useCallback<(name: string) => void>(
        (name: string) => {
            const renamedItem = { ...item, name };
            textStyles.updateStyle(renamedItem);
        },
        [textStyles, item],
    );

    const deleteTextStyle = useCallback(() => {
        styleIds.forEach((styleId) => {
            textStyles.deleteStyle(styleId);
        });
    }, [textStyles, styleIds]);

    const updateStyleWithOverrides = useCallback(() => {
        textStyles.updateStyles(getStylesWithOverrides());
    }, [styleIds, textStyles, item, getStylesWithOverrides]);

    const resetAllOverrides = useCallback(() => {
        if (styleIds.length > 1) {
            const styles = textSelection
                .getRawStyles()
                .styleId.map((styleId) => (styleId ? textStyleToRunStyle(textStyles.getStyle(styleId)) : {}));
            const param = { updateTextSettingsByEachRun: { styles } };
            creativeModel.updateElement(id, param);
        } else {
            applyStyle(item.uuid);
        }
    }, [item, applyStyle, styleIds, textSelection, textStyles]);

    const allStyleList = textStyles.list.filter((item) => item.name.toLowerCase().includes(searchTerm));
    const elementStyleList = allStyleList.filter((item) => elementStyleIds.has(item.uuid));

    const handleSearchChange = useCallback((value) => {
        setSearchValue(value);
    }, []);

    const handleTextStyleSelect = useCallback<(item: TextStyle) => void>(
        (item: TextStyle) => {
            applyStyle(item.uuid);
        },
        [applyStyle],
    );

    useEffect(() => {
        creativeModel.eventEmitter.on('textStylesChanged', forceUpdate);

        return () => {
            creativeModel.eventEmitter.off('textStylesChanged', forceUpdate);
        };
    }, [creativeModel, forceUpdate]);

    return {
        textStyles,
        item,
        hasStyleList: textStyles.list.length > 0,
        stylesWithOverrides: getStylesWithOverrides().length,
        elementStyleList,
        allStyleList,
        searchValue,
        handleSearchChange,
        isAllowedToCreateTextStyle,
        createTextStyle,
        deleteTextStyle,
        detachTextStyle,
        renameTextStyle,
        updateStyleWithOverrides,
        resetAllOverrides,
        handleTextStyleSelect,
        styleIds,
        isMixed,
        mixedValue,
        overrides,
    };
}
