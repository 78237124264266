import { ElementTypes, TemplateElement } from '@bynder-studio/render-core';

export const compareByRenderOrder = (a, b) => b.properties.renderOrder - a.properties.renderOrder;

export const getImageDesignEditableElements = (elements: TemplateElement): TemplateElement[] =>
    Object.values(elements)
        .filter(({ type }) => ['IMAGE', 'TEXT', 'SHAPE'].includes(type))
        .sort(compareByRenderOrder);

const isBlockedByColorsLack = (element: TemplateElement) => {
    if (element.type === ElementTypes.BACKGROUND_COLOR) {
        return !element.properties.brandColors.length;
    }

    return false;
};

export const getGlobalEditableElements = (elements: TemplateElement): TemplateElement[] =>
    Object.values(elements).filter(
        (element) =>
            // NOTE: We can check global elements for additional colors HERE because they
            // can't be shown/hidden and don't need a header like not-global elements
            [ElementTypes.BACKGROUND_COLOR].includes(element.type) && !isBlockedByColorsLack(element),
        // will be included further
        // && !element.properties.locked
    );
