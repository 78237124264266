import React, { useMemo } from 'react';
import {
    AspectRatio,
    AspectRatioDecorationBottomRight,
    AspectRatioDecorationTopLeft,
    AspectRatioText,
    AspectRatioWrapper,
} from './Card.styled';

export const CardAspectRatio = ({ aspectRatio }) => {
    const height = useMemo(() => {
        const [w, h] = (aspectRatio || '1:1').split(':').map(Number);

        return Math.round((h / w) * 100);
    }, [aspectRatio]);

    return (
        <AspectRatioWrapper>
            <AspectRatio height={height} />
            <AspectRatioText>{aspectRatio}</AspectRatioText>
            <AspectRatioDecorationTopLeft />
            <AspectRatioDecorationBottomRight />
        </AspectRatioWrapper>
    );
};
