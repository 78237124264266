import * as types from './types';

export function toggleFigmaFrameId(id: string) {
    return { type: types.FIGMA_FRAMES_TOGGLE, id };
}

export function selectFigmaPageId(id: string | undefined) {
    return { type: types.FIGMA_PAGE_SELECTION, id };
}

export function closeFigmaSelectedLimitReachedModal() {
    return { type: types.FIGMA_CLOSE_LIMIT_REACHED_MODAL };
}

export function setFigmaFile(payload: types.FigmaFileResult) {
    return { type: types.FIGMA_SET_FILE, payload };
}

export function setFigmaMultiDesignMode(multiDesignMode: boolean) {
    return { type: types.FIGMA_SET_MULTI_DESIGN_MODE, multiDesignMode };
}
