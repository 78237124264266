import React, { useEffect, useMemo } from 'react';
import { notify, Button } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';

import { formatBytes } from 'packages/pages/utils';

import { UploaderErrorType, UploaderState } from './types';
import useUploader from './useUploader';

const notificationId = 'uploader-notification';

const DISPLAY_ERRORS: { [K in UploaderErrorType]: { title: string; description?: string } } = {
    cancelled: {
        title: 'pages.uploader.title.cancel',
    },
    generic: {
        title: 'pages.uploader.title.error.generic',
    },
    mimeType: {
        title: 'pages.uploader.title.error.format',
    },
    size: {
        title: 'pages.uploader.title.error.size',
        description: 'pages.uploader.description.error.size',
    },
    resolution: {
        title: 'pages.uploader.title.error.resolution',
        description: 'pages.uploader.description.error.resolution',
    },
};

// eslint-disable-next-line consistent-return
function getVariant({ status }: UploaderState) {
    switch (status) {
        case 'inProgress':
            return 'loading';
        case 'success':
            return 'success';
        case 'error':
            return 'error';
        default:
            return undefined;
    }
}

// eslint-disable-next-line consistent-return
function getTitle(state: UploaderState) {
    const { status } = state;
    switch (status) {
        case 'inProgress':
            return 'pages.uploader.title.progress';
        case 'success':
            return 'pages.uploader.title.success';
        case 'error':
            return DISPLAY_ERRORS[state.type].title;
        default:
            return undefined;
    }
}

function getIsPersistent({ status }: UploaderState) {
    return status === 'inProgress';
}

function getDescription(state: UploaderState) {
    if (state.status === 'error') {
        return DISPLAY_ERRORS[state.type].description;
    }

    if (
        state.status === 'ready' ||
        state.status === 'success' ||
        state.stage === 'uploaded' ||
        state.stage === 'gettingUploadUrl' ||
        state.stage === 'statusUpdate' ||
        state.stage === 'validating'
    ) {
        return undefined;
    }

    const loaded = formatBytes(state.loaded);
    const total = formatBytes(state.total);

    return `${loaded} / ${total}`;
}

export function getProgressPercentage(state: UploaderState) {
    if (state.status === 'inProgress' && state.stage === 'uploading') {
        return state.percent;
    }

    return undefined;
}

export function getIsUploading(state: UploaderState) {
    return state.status === 'inProgress' && state.stage === 'uploading';
}

const UploaderNotifications = () => {
    const { translate } = useTranslate();
    const { state: isUploading, cancel } = useUploader({ selector: getIsUploading });
    const { state: variant } = useUploader({ selector: getVariant });
    const { state: title } = useUploader({ selector: getTitle });
    const { state: isPersistent } = useUploader({ selector: getIsPersistent });
    const { state: description } = useUploader({ selector: getDescription });
    const { state: progressPercentage } = useUploader({ selector: getProgressPercentage });
    const { state: hideNotification } = useUploader({ selector: (state: UploaderState) => state?.hideNotification });

    const cancelButton = useMemo(
        () => (
            <Button
                onClick={() => {
                    cancel();
                }}
            >
                {translate('pages.uploader.button.cancel')}
            </Button>
        ),
        [cancel],
    );

    useEffect(() => {
        if (variant && title && !hideNotification) {
            notify({
                id: notificationId,
                variant,
                title: translate(title),
                isPersistent,
                description: description && (progressPercentage ? description : translate(description)),
                progressPercentage,
                button: isUploading ? cancelButton : undefined,
            });
        }
    }, [variant, title, isPersistent, description, progressPercentage, hideNotification]);

    return null;
};

export default UploaderNotifications;
