import React, { useEffect } from 'react';
import { Button, Dropdown } from '@bynder/design-system';
import { Translate, useTranslate } from '@bynder/localization';
import { IconSortOrder } from '@bynder/icons';
import useQueryParams from 'packages/hooks/useQueryParams';
import { OrderButton } from './Ordering.styled';
import { OrderFilterType, OrderingFilterProps, SortingFilterType } from './types';

export const sortTitleMapDefault = {
    [SortingFilterType.DATECREATED]: <Translate id="filters.order.date" />,
    [SortingFilterType.LASTMODIFIED]: <Translate id="filters.order.modified" />,
    [SortingFilterType.TITLE]: <Translate id="filters.order.title" />,
    [SortingFilterType.UPDATED]: <Translate id="filters.order.modified" />,
    [SortingFilterType.DURATION]: <Translate id="filters.order.duration" />,
    [SortingFilterType.RESOLUTION]: <Translate id="filters.order.resolution" />,
    [SortingFilterType.EXTENSION]: <Translate id="filters.order.extension" />,
};

const Ordering = ({
    sortBy: sortByProp = SortingFilterType.DATECREATED,
    sortOrder: sortOrderProp = OrderFilterType.DESCENDING,
    onSort,
    isDisabled,
    isDesignType,
    isUploadType,
    sortTitleMap = sortTitleMapDefault,
    ...props
}: OrderingFilterProps) => {
    const [sortBy, setSortBy] = React.useState(sortByProp);
    const [sortOrder, setSortOrder] = React.useState(sortOrderProp);

    const { translate } = useTranslate();
    const { getQueryParam, resetQueryParams } = useQueryParams();

    const onSortBySelect = (newSortBy: SortingFilterType) => {
        let newSortOrder = sortOrder;

        if (sortBy !== SortingFilterType.TITLE && newSortBy === SortingFilterType.TITLE) {
            newSortOrder = OrderFilterType.ASCENDING;
            setSortOrder(newSortOrder);
        } else if (sortBy === SortingFilterType.TITLE && newSortBy !== SortingFilterType.TITLE) {
            newSortOrder = OrderFilterType.DESCENDING;
            setSortOrder(newSortOrder);
        }

        setSortBy(newSortBy);
        onSort(newSortBy, newSortOrder);
    };

    const onSortOrderChanged = () => {
        const newSortOrder =
            sortOrder === OrderFilterType.DESCENDING ? OrderFilterType.ASCENDING : OrderFilterType.DESCENDING;
        setSortOrder(newSortOrder);
        onSort(sortBy, newSortOrder);
    };

    useEffect(() => {
        const queriedSorting: any = getQueryParam('sortedBy');
        const queriedOrder: any = getQueryParam('sortOrder');

        if (!queriedSorting && !queriedOrder) {
            return;
        }

        if (queriedSorting && Object.values(SortingFilterType).includes(queriedSorting)) {
            setSortBy(queriedSorting);
        }

        if (queriedOrder && Object.values(OrderFilterType).includes(queriedOrder)) {
            setSortOrder(queriedOrder);
        }
    }, []);

    return (
        <>
            <Dropdown
                position="top-right"
                trigger={({ isOpen, ...triggerProps }) => (
                    <Button
                        data-testid="overview ordering filter"
                        variant="clean"
                        isPressed={isOpen}
                        {...triggerProps}
                        aria-label={translate('filters.order.sortBy.label')}
                        isDisabled={isDisabled}
                        rightIcon={<Dropdown.Arrow />}
                    >
                        {sortTitleMap[sortBy]}
                    </Button>
                )}
                {...props}
            >
                <Dropdown.Item
                    onClick={() => onSortBySelect(SortingFilterType.DATECREATED)}
                    isChecked={sortBy === SortingFilterType.DATECREATED}
                >
                    {sortTitleMap[SortingFilterType.DATECREATED]}
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => onSortBySelect(SortingFilterType.LASTMODIFIED)}
                    isChecked={sortBy === SortingFilterType.LASTMODIFIED}
                >
                    {sortTitleMap[SortingFilterType.LASTMODIFIED]}
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => onSortBySelect(SortingFilterType.TITLE)}
                    isChecked={sortBy === SortingFilterType.TITLE}
                >
                    {sortTitleMap[SortingFilterType.TITLE]}
                </Dropdown.Item>
                {isDesignType && (
                    <Dropdown.Item
                        onClick={() => onSortBySelect(SortingFilterType.DURATION)}
                        isChecked={sortBy === SortingFilterType.DURATION}
                    >
                        {sortTitleMap[SortingFilterType.DURATION]}
                    </Dropdown.Item>
                )}
                {isUploadType && (
                    <>
                        <Dropdown.Item
                            onClick={() => onSortBySelect(SortingFilterType.RESOLUTION)}
                            isChecked={sortBy === SortingFilterType.RESOLUTION}
                        >
                            {sortTitleMap[SortingFilterType.RESOLUTION]}
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => onSortBySelect(SortingFilterType.EXTENSION)}
                            isChecked={sortBy === SortingFilterType.EXTENSION}
                        >
                            {sortTitleMap[SortingFilterType.EXTENSION]}
                        </Dropdown.Item>
                    </>
                )}
            </Dropdown>
            <OrderButton
                data-testid="overview sort order filter"
                variant="clean"
                icon={<IconSortOrder />}
                onClick={onSortOrderChanged}
                order={sortOrder}
                aria-label={translate('filters.order.sortOrder.label')}
                isDisabled={isDisabled}
            />
        </>
    );
};

export default Ordering;
