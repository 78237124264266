import React, { useMemo } from 'react';
import { AspectRatio, AspectRatioWrapper } from './Component.styled';

const calculatePage = (aspectRatio: string) => {
    const [w, h] = (aspectRatio || '1:1').split(':').map(Number);
    const isWidthBigger = w > h;
    let finalWidth;
    let finalHeight;
    if (isWidthBigger) {
        const coef = h / w;
        finalWidth = 100;
        finalHeight = finalWidth * coef;
    } else {
        const coef = w / h;
        finalHeight = 100;
        finalWidth = finalHeight * coef;
    }

    return [finalWidth, finalHeight];
};

const PageFakeCard = ({ aspectRatio }: { aspectRatio: string }) => {
    const [width, height] = useMemo(() => calculatePage(aspectRatio), [aspectRatio]);

    return (
        <AspectRatioWrapper>
            <AspectRatio height={height} width={width} />
        </AspectRatioWrapper>
    );
};

export default PageFakeCard;
