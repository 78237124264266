import React, { useCallback, memo } from 'react';
import { CreativeModelFactory } from '@bynder-studio/render-web';
import { Button, Tooltip, Flex } from '@bynder/design-system';
import { IconVolumeDown, IconVolumeOff, IconVolumeUp } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import useAudio from 'packages/hooks/useAudio';
import useCurrentPage from 'packages/hooks/useCurrentPage';
import { useAudioShortcuts } from 'packages/hooks/useAudioShortcuts';
import { VolumeSlider } from './TimelineHeader.styled';

type Props = {
    factory: CreativeModelFactory | null | undefined;
};

// Template volume control (should be the only for all editors (cc, editor, template)
const SimpleVolumeControl = ({ factory }: Props) => {
    const { translate } = useTranslate();
    const { toggleMutedStatus, isMuted, volume, setVolume, setMutedStatus } = useAudio(factory);
    useCurrentPage(factory); // rerender on page change
    useAudioShortcuts(toggleMutedStatus);

    const handleVolumeChange = useCallback(
        (val: number | [number, number]) => {
            if (Array.isArray(val)) {
                val = val[0];
            }

            setVolume(val / 100);
            setMutedStatus(val === 0);
        },
        [setVolume, setMutedStatus],
    );

    const getVolumeTooltipContent = () => {
        if (isMuted) {
            return translate('editor.timeline.shots.audio.unmute');
        }

        return translate('editor.timeline.shots.audio.mute');
    };

    const getInteractiveVolumeIcon = () => {
        if (isMuted || volume === 0) {
            return <IconVolumeOff />;
        }

        if (volume > 0.5) {
            return <IconVolumeUp />;
        }

        return <IconVolumeDown />;
    };

    return (
        <Flex alignItems="center" justifyContent="space-between" className="dtimeline--header-volume">
            <VolumeSlider
                value={isMuted ? 0 : Math.round(volume * 100)}
                min={0}
                max={100}
                onChange={handleVolumeChange}
                iconLeft={
                    <Tooltip content={getVolumeTooltipContent()} timing="instant">
                        <Button
                            title={translate('editor.timeline.shots.audio.status')}
                            variant="clean"
                            onClick={toggleMutedStatus}
                            icon={getInteractiveVolumeIcon()}
                        />
                    </Tooltip>
                }
                isInactive
            />
        </Flex>
    );
};

export default memo<Props>(SimpleVolumeControl);
