import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import modalContainer from 'packages/common/modalContainer';

type NoAccessProps = {
    title?: string;
    description?: string;
    primaryActionText?: string;
    redirectPath: string;
};

type Props = React.PropsWithChildren<{ isAllowed: boolean } & NoAccessProps>;

export default function ProtectedPage({ isAllowed, children, ...noAccessProps }: Props) {
    if (!isAllowed) {
        return <NoAccess {...noAccessProps} />;
    }

    return children;
}

function NoAccess({ title, description, primaryActionText, redirectPath }: NoAccessProps) {
    const { translate } = useTranslate();
    const navigate = useNavigate();
    const onClick = useCallback(() => navigate(redirectPath), [history, redirectPath]);

    return (
        <Modal
            container={modalContainer}
            title={title || translate('pages.errors.no-access.title')}
            actionPrimary={
                <Button variant="primary" onClick={onClick}>
                    {primaryActionText ?? translate('pages.errors.no-access.button')}
                </Button>
            }
            onClose={noop}
            isOpen
            isCloseButtonHidden
        >
            {description || translate('pages.errors.no-access.text')}
        </Modal>
    );
}

function noop() {}
