import { useCallback, useEffect, useRef } from 'react';

export const useTextareaSelectionChange = (elementId: string, onChange: (start: number, end: number) => void) => {
    const textarea = useRef<HTMLTextAreaElement>(null);
    const runId = useRef<number>(0);
    const lastSelection = useRef<{ start: number; end: number }>({ start: -1, end: -1 });

    useEffect(() => {
        if (!textarea.current) {
            return;
        }

        textarea.current.addEventListener('keypress', handleSelectionChange);
        textarea.current.addEventListener('keydown', handleSelectionChange);
        textarea.current.addEventListener('keyup', handleSelectionChange);
        textarea.current.addEventListener('mousedown', handleSelectionChange);
        textarea.current.addEventListener('mouseup', handleSelectionChange);
        textarea.current.addEventListener('touchstart', handleSelectionChange);
        textarea.current.addEventListener('touchend', handleSelectionChange);
        textarea.current.addEventListener('input', handleSelectionChange);
        textarea.current.addEventListener('paste', handleSelectionChange);
        textarea.current.addEventListener('cut', handleSelectionChange);
        textarea.current.addEventListener('select', handleSelectionChange);
        textarea.current.addEventListener('selectstart', handleSelectionChange);
        textarea.current.addEventListener('selectionchange', handleSelectionChange);

        return () => {
            if (textarea.current) {
                textarea.current.removeEventListener('keypress', handleSelectionChange);
                textarea.current.removeEventListener('keydown', handleSelectionChange);
                textarea.current.removeEventListener('keyup', handleSelectionChange);
                textarea.current.removeEventListener('mousedown', handleSelectionChange);
                textarea.current.removeEventListener('mouseup', handleSelectionChange);
                textarea.current.removeEventListener('touchstart', handleSelectionChange);
                textarea.current.removeEventListener('touchend', handleSelectionChange);
                textarea.current.removeEventListener('input', handleSelectionChange);
                textarea.current.removeEventListener('paste', handleSelectionChange);
                textarea.current.removeEventListener('cut', handleSelectionChange);
                textarea.current.removeEventListener('select', handleSelectionChange);
                textarea.current.removeEventListener('selectstart', handleSelectionChange);
                textarea.current.removeEventListener('selectionchange', handleSelectionChange);
            }

            if (runId.current) {
                cancelAnimationFrame(runId.current);
                runId.current = 0;
            }
        };
    }, [elementId]);

    const handleSelectionChange = useCallback(() => {
        if (!textarea.current) {
            return;
        }

        const el = textarea.current;

        if (runId.current) {
            cancelAnimationFrame(runId.current);
        }

        runId.current = requestAnimationFrame(() => {
            runId.current = 0;

            if (lastSelection.current.start !== el.selectionStart || lastSelection.current.end !== el.selectionEnd) {
                lastSelection.current.start = el.selectionStart;
                lastSelection.current.end = el.selectionEnd;
                onChange(el.selectionStart, el.selectionEnd);
            }
        });
    }, [onChange]);

    return textarea;
};
