import styled from 'styled-components';

export const InfinityLoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    bottom: 0;
    height: 1px;
    width: 100%;

    // for the loading icon container
    & > button > div {
        align-items: baseline;
    }
`;
