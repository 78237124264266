import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IconSearch } from '@bynder/icons';
import useQueryParams from 'packages/hooks/useQueryParams';
import { SearchButton, SearchInput } from './Search.styled';
import useExport from '../hooks/useExport';

// eslint-disable-next-line import/prefer-default-export
export const Search = () => {
    const { getQueryParam, setQueryParam } = useQueryParams();
    const { searchValue, setSearchValue } = useExport();
    const [showInput, setShowInput] = useState(!!searchValue || false);
    const inputRef = useRef(null);

    useEffect(() => {
        const queriedSearch = getQueryParam('search');

        if (queriedSearch) {
            setSearchValue(queriedSearch);
            setShowInput(true);
        }
    }, []);

    const handleSearchButtonPress = useCallback(() => {
        setShowInput(true);
    }, []);

    const handleSearchInputBlur = useCallback(() => {
        const val = searchValue.trim();

        setSearchValue(val);
        setShowInput(!!val);
    }, [searchValue]);

    const handleSearchValueChange = useCallback((newValue) => {
        setSearchValue(newValue);
        setQueryParam('search', newValue);
    }, []);

    useEffect(() => {
        showInput && inputRef.current?.focus();
    }, [showInput]);

    useEffect(() => {
        inputRef.current?.addEventListener('blur', handleSearchInputBlur);

        return () => {
            inputRef.current?.removeEventListener('blur', handleSearchInputBlur);
        };
    }, [handleSearchInputBlur]);

    return (
        <>
            <SearchButton onClick={handleSearchButtonPress} hide={showInput} />
            <SearchInput
                hasClearButton
                inputRef={inputRef}
                placeholder="Search"
                icon={<IconSearch />}
                value={searchValue}
                onChange={handleSearchValueChange}
                onBlur={handleSearchInputBlur}
                hide={!showInput}
            />
        </>
    );
};
