import React, { Fragment } from 'react';
import { IconTime } from '@bynder/icons';
import { Form, Tooltip } from '@bynder/design-system';
import generateTestId from '~/helpers/testIdHelpers';
import FramePicker from 'packages/pages/editor/RightSideMenu/FormComponents/FramePicker';
import TextBreakup from 'packages/pages/editor/RightSideMenu/FormComponents//TextBreakup';
import BlurField from './BlurField';
import SwitchField, { SwitchFieldProp, isSwitchField } from './SwitchField';
import SelectField, { SelectFieldProp, isSelectField } from './SelectField';
import InputField, { InputFieldProp, isInputField } from './InputField';

export type PropertyFieldProps = {
    minValue: number | undefined;
    label: string;
    disabled?: boolean;
    testId: string;
    id: string;
    onChange: (name: string, value: any) => void;
    autocorrect: <T>(val: T) => T;
    tooltip: string;
    fallbackValue?: any;
} & (
    | {
          name: InputFieldProp | 'blur';
          value: number;
      }
    | {
          name: SelectFieldProp | SwitchFieldProp;
          options: Array<{
              subtype?: string;
              isSubtitle?: boolean;
              value: string;
              displayValue: string;
          }>;
          value: string;
      }
    | {
          name: 'duration' | 'startFrame';
          frameRate: number;
          value: any;
      }
    | {
          name: 'textBreakup';
          value: any;
      }
);

function PropertyField(props: PropertyFieldProps) {
    const { name, label, disabled = false, onChange, testId, id, value, autocorrect, tooltip } = props;

    const testIdProp = generateTestId(testId);
    const labelId = `${id}_label`;

    const handleOnChange = (newValue: any) => {
        onChange(name, newValue);
    };

    const TextBreakupWrapper = tooltip ? Tooltip : Fragment;

    return (
        <Form.Group>
            {isSelectField(name) ? (
                <SelectField
                    id={id}
                    label={label}
                    options={props.name === name ? props.options : []}
                    name={name}
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                    testId={testId}
                />
            ) : (
                label && <Form.Label id={labelId}>{label}</Form.Label>
            )}
            {name === 'blur' && <BlurField value={value} disabled={disabled} onChange={onChange} {...testIdProp} />}
            {isSwitchField(name) && (
                <SwitchField name={name} value={value} disabled={disabled} onChange={handleOnChange} testId={testId} />
            )}
            {isInputField(name) && (
                <InputField
                    id={id}
                    testId={testId}
                    name={name}
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                    autocorrect={autocorrect}
                    fallbackValue={props?.fallbackValue}
                />
            )}
            {(name === 'startFrame' || name === 'duration') && (
                <FramePicker
                    {...props}
                    disabled={disabled}
                    handleOnChange={handleOnChange}
                    value={value}
                    htmlFor={labelId}
                    icon={<IconTime />}
                />
            )}
            {name === 'textBreakup' && (
                <TextBreakupWrapper size={tooltip ? 'medium' : undefined} content={tooltip} disabled={disabled}>
                    <TextBreakup
                        {...props}
                        disabled={disabled}
                        name={name}
                        handleOnChange={onChange}
                        value={value}
                        labelId={labelId}
                    />
                </TextBreakupWrapper>
            )}
        </Form.Group>
    );
}

export default React.memo(PropertyField);
