import { BaseVisualElement } from '../BaseVisualElement';
import { GroupElement } from '../GroupElement';
import { Position } from '../../Shared/Position';
import { Dimension } from '../../Shared/Dimension';

type TrackRecord = {
    renderOrder: number;
    parent?: GroupElement;
};

const getElementTrackRecords = (element: BaseVisualElement, trackRecords: TrackRecord[] = []): TrackRecord[] => {
    const trackRecord: TrackRecord = {
        renderOrder: element.renderOrder,
    };

    if (!element.parent) {
        return trackRecords.concat(trackRecord);
    } else {
        trackRecord.parent = element.parent as GroupElement;
        return getElementTrackRecords(element.parent, trackRecords.concat(trackRecord));
    }
};

export const getGroupPlacement = (elementsToAdd: BaseVisualElement[]) => {
    const elementTrackRecords = elementsToAdd
        .map((element) => getElementTrackRecords(element))
        .sort((a, b) => a.length - b.length);
    const leastDepthTrackRecord = elementTrackRecords[0];
    let renderOrder = 0;
    let lowestCommonAncestor = null;

    for (let trackRecord of leastDepthTrackRecord) {
        const matchingRecords: TrackRecord[] = [];
        elementTrackRecords.forEach((records) => {
            const matchingRecord = records.find((record) => record.parent && record.parent === trackRecord.parent);

            if (matchingRecord) {
                matchingRecords.push(matchingRecord);
            }
        });

        if (matchingRecords.length === elementTrackRecords.length) {
            lowestCommonAncestor = trackRecord.parent;
            renderOrder = Math.max(...matchingRecords.map((record) => record.renderOrder));
            break;
        } else {
            const lastRecords = elementTrackRecords.map((records) => records[records.length - 1]);
            renderOrder = Math.max(...lastRecords.map((record) => record.renderOrder));
        }
    }

    return {
        lowestCommonAncestor,
        renderOrder,
    };
};
export const createGroupElement = (children: BaseVisualElement[], groupNumber: number) => {
    const groupId = Date.now();
    const { lowestCommonAncestor, renderOrder } = getGroupPlacement(children);
    return new GroupElement({
        id: groupId,
        name: 'New Group element',
        parent: lowestCommonAncestor,
        renderOrder: renderOrder + 0.1,
        lockUniScaling: true,
        children: [],
    });
};

const degreesToRad = (degrees: number) => (degrees * Math.PI) / 180;

export const getBoundingBoxAfterRotation = (element: {
    position: Position;
    dimension: Dimension;
    rotation: number;
}) => {
    const { position, dimension, rotation } = element;
    const { x, y } = position;
    const { width, height } = dimension;

    const centerX = x + width / 2;
    const centerY = y + height / 2;

    const halfWidth = width / 2;
    const halfHeight = height / 2;

    // element's bounding box
    //
    //      0 ---|--- 1
    //      |         |
    //      -    *    -
    //      |         |
    //      3 ---|--- 2
    //

    if (rotation) {
        const angle = degreesToRad(rotation);
        const sin = Math.sin(angle);
        const cos = Math.cos(angle);
        const hwc = halfWidth * cos;
        const hws = halfWidth * sin;
        const hhs = halfHeight * sin;
        const hhc = halfHeight * cos;

        return [
            { x: centerX - (hwc - hhs), y: centerY - (hws + hhc) },
            { x: centerX + (hwc + hhs), y: centerY + (hws - hhc) },
            { x: centerX + (hwc - hhs), y: centerY + (hws + hhc) },
            { x: centerX - (hwc + hhs), y: centerY - (hws - hhc) },
        ];
    }

    return [
        { x, y },
        { x: x + width, y },
        { x: x + width, y: y + height },
        { x, y: y + height },
    ];
};

export const getBoundingBoxDimensionAfterRotation = (element: {
    position: Position;
    dimension: Dimension;
    rotation: number;
}) => {
    const boundingBox = getBoundingBoxAfterRotation(element);
    const xValues = boundingBox.map((point) => point.x);
    const yValues = boundingBox.map((point) => point.y);

    const x = Math.min(...xValues);
    const y = Math.min(...yValues);

    return {
        width: Math.max(...xValues) - Math.min(...xValues),
        height: Math.max(...yValues) - Math.min(...yValues),
        x,
        y,
    };
};

const calculateElementDimension = (
    element: {
        position: Position;
        dimension: Dimension;
        rotation: number;
    },
    width: number,
    height: number,
) => {
    if ([45, 135, 225, 315].includes(Math.abs(element.rotation))) {
        const wk = element.dimension.height / element.dimension.width;
        const hk = element.dimension.width / element.dimension.height;

        const w = (width * Math.SQRT2) / (1 + wk);
        const h = (height * Math.SQRT2) / (1 + hk);

        return { w, h };
    }

    const rotationRad = (element.rotation * Math.PI) / 180;
    const cosAlpha = Math.abs(Math.cos(rotationRad));
    const sinAlpha = Math.abs(Math.sin(rotationRad));
    const divider = sinAlpha ** 2 - cosAlpha ** 2;

    const h = (width * sinAlpha - height * cosAlpha) / divider;
    const w = (height * sinAlpha - width * cosAlpha) / divider;

    return { w, h };
};

export const changeElementInsideBox = (
    element: {
        position: Position;
        dimension: Dimension;
        rotation: number;
    },
    box: { startPosition: Position; startDimension: Dimension; position: Position; dimension: Dimension },
) => {
    const { startDimension, startPosition, position, dimension } = box;
    const scaleW = dimension.width / startDimension.width;
    const scaleH = dimension.height / startDimension.height;

    const { width: elementW, height: elementH, x, y } = getBoundingBoxDimensionAfterRotation(element);

    const width = elementW * scaleW;
    const height = elementH * scaleH;
    const { w, h } = calculateElementDimension(element, width, height);

    const startW = elementW - element.dimension.width;
    const startH = elementH - element.dimension.height;
    const endW = width - w;
    const endH = height - h;
    const deviationX = (endW - startW) / 2;
    const deviationY = (endH - startH) / 2;

    const dx = (x - startPosition.x) * (scaleW - 1);
    const dy = (y - startPosition.y) * (scaleH - 1);

    return {
        position: element.position
            .add(position.subtract(startPosition))
            .add(new Position(deviationX + dx, deviationY + dy)),
        dimension: new Dimension(w, h),
    };
};
