import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { currentEditorType } from 'packages/store/creativeEditor/creativeEditor.selectors';
import useEditor from './useEditor';
import useDesign from 'packages/pages/design/hooks/useDesign';

const defaultValidation = {
    isValid: true,
    isChildrenValid: true,
    validationMessages: [],
};

export default function useElementValidation(elementId: number) {
    const editorType = useSelector(currentEditorType);
    const { creativeModel, validationManager } = editorType === 'create' ? useDesign() : useEditor();
    const [validationState, setValidationState] = useState(
        () => (elementId && validationManager?.getElementValidationState(elementId)) || defaultValidation,
    );
    const elId = useRef(elementId);

    useEffect(() => {
        if (!validationManager || !creativeModel) {
            return;
        }

        if (elId.current !== elementId) {
            if (elementId) {
                setValidationState(validationManager.getElementValidationState(elementId) || defaultValidation);
                elId.current = elementId;
            } else {
                setValidationState(defaultValidation);
                return;
            }
        }

        const validationChangeListener = ({ isValid, isChildrenValid, validationMessages }) => {
            setValidationState({ isValid, isChildrenValid, validationMessages });
        };
        const sizeChangeListener = () => {
            elementId &&
                setValidationState(validationManager.getElementValidationState(elementId) || defaultValidation);
        };

        const unsubscribeElementValidationChange = validationManager.onElementValidationChange(
            elementId,
            validationChangeListener,
        );
        creativeModel.getEventEmitter().on('currentPageChange', sizeChangeListener);

        return () => {
            unsubscribeElementValidationChange();
            creativeModel.getEventEmitter().off('currentPageChange', sizeChangeListener);
        };
    }, [elementId, creativeModel, validationManager]);

    return validationState;
}
