import { useContext } from 'react';
import { EditorSelectedElementContext } from '~/contexts/EditorSelectedElementContext';

export default function useEditorSelectedElement() {
    const {
        selectedElement,
        selectedElements,
        topLevelSelectedElements,
        selectedElementDeferred,
        selectedElementsDeferred,
        topLevelSelectedElementsDeferred,
    } = useContext(EditorSelectedElementContext);

    return {
        selectedElement,
        selectedElements,
        topLevelSelectedElements,
        selectedElementDeferred,
        selectedElementsDeferred,
        topLevelSelectedElementsDeferred,
    };
}
