import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, List, InlineBanner, Thumbnail, notify } from '@bynder/design-system';
import { IconLink } from '@bynder/icons';
import { Translate, useTranslate } from '@bynder/localization';
import { createSharing, fetchSharing } from 'packages/store/sharing/sharing.actions';
import { EntityGrantedPermissions, EntityType, IdentityRole, IdentityType } from 'packages/store/sharing/types';
import {
    getEntityGrantedPermissions,
    getProjectGrantedPermissions,
    getSharing,
} from 'packages/store/sharing/sharing.selectors';
import useAccessRights from 'packages/hooks/useAccessRights';
import {
    ActionStatus,
    CategoryAction,
    DesignAction,
    Entity,
    ProjectAction,
    TemplateAction,
} from 'packages/hooks/useAccessRights/types';
import { hostUrlSelector } from 'packages/store/platform/platform.selectors';
import features from '~/configs/features';
import roles from '~/configs/roles';
import { ReviewStatus } from 'packages/store/review/types';
import { BannerBox, Box, UserGroupTitle } from './shareModal.styled';
import IdentitiesSearch from './components/IdentitiesSearch';
import { Props } from './types';
import GroupAccessDropDown from './components/GroupAccessDropdown';
import { GroupAccessEntry } from './components/GroupAccessDropdown/types';
import TransferModal from './components/TransferModal';
import { renderSharePermissions } from './items';
import ProjectPermissionsDropdown from './components/ProjectPermissionsDropdown';
import { sortPermissions, getTemplatePreviewLink } from './utils';
import { getDesignEditLink } from '../designs/utils';
import { getProjectDetailsPath } from '../projects/utils';
import { getCategoryDetailsLink } from '../categories/utils';
import ReviewersDropdown from './components/ReviewersDropdown';
import modalContainer from 'packages/common/modalContainer';

const ShareModal = ({
    title,
    isModalOpen,
    onClose,
    entityId,
    entityType,
    collectionName,
    isDetailsPage,
    approvalStatus,
}: Props) => {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const { isEntityActionAllowed, isPlatformAllowed, getIdentityRole, isAccountRoleAssigned } = useAccessRights();
    const { loading, saving } = useSelector(getSharing);
    const projectGrantedPermissions = useSelector(getProjectGrantedPermissions);
    const entityGrantedPermissions = useSelector(getEntityGrantedPermissions);
    const hostUrl = useSelector(hostUrlSelector);
    const [permissions, setPermissions] = useState<EntityGrantedPermissions[]>(entityGrantedPermissions);
    const [transferModalEntity, setTransferModalEntity] = useState(null);
    const [restrictionLevel, setRestrictionLevel] = useState<GroupAccessEntry>(GroupAccessEntry.RESTRICTED);
    const [reviewers, setReviewers] = useState<EntityGrantedPermissions[]>([]);

    const mapActionType = {
        [EntityType.CREATIVE]: DesignAction,
        [EntityType.COLLECTION]: ProjectAction,
        [EntityType.TEMPLATE]: TemplateAction,
        [EntityType.CATEGORY]: CategoryAction,
    };
    const creativeActionType = mapActionType[entityType];
    const mapType = {
        [EntityType.CREATIVE]: Entity.DESIGN,
        [EntityType.COLLECTION]: Entity.PROJECT,
        [EntityType.TEMPLATE]: Entity.TEMPLATE,
        [EntityType.CATEGORY]: Entity.CATEGORY,
    };
    const creativeType = mapType[entityType];

    useEffect(() => {
        if (entityId) {
            dispatch(fetchSharing(entityId, entityType));
        }

        return () => {
            setPermissions([]);
        };
    }, [entityId, entityType, dispatch]);

    useEffect(() => {
        if (loading) {
            setPermissions([]);

            return;
        }

        const filteredPermissions = entityGrantedPermissions.filter((perm) =>
            perm.grantedPermissions.find((grantedPerm) => grantedPerm.permission !== IdentityRole.REVIEWER),
        );

        if (filteredPermissions && permissions.length === 0 && entityId === entityGrantedPermissions[0]?.entityId) {
            setPermissions(filteredPermissions);
        }
    }, [entityGrantedPermissions, permissions.length, entityId, loading]);

    useEffect(() => {
        if (
            permissions &&
            permissions.some((perm) => perm.securityIdentity.securityIdentityType === IdentityType.ALL_USERS)
        ) {
            setRestrictionLevel(GroupAccessEntry.ALL_USERS);
        } else {
            setRestrictionLevel(GroupAccessEntry.RESTRICTED);
        }
    }, [permissions]);

    useEffect(() => {
        const entityReviewers = entityGrantedPermissions.filter((perm) =>
            perm.grantedPermissions.some((grantedPerm) => grantedPerm.permission === IdentityRole.REVIEWER),
        );

        if (permissions && entityReviewers.length) {
            setReviewers(entityReviewers);
        }
    }, [entityGrantedPermissions, permissions]);

    const existingIdentities = useMemo(
        () =>
            permissions.map((item) => {
                if (item.securityIdentity.securityIdentityType === IdentityType.USER) {
                    return item.securityIdentity.bynderUserId;
                }

                if (item.securityIdentity.securityIdentityType === IdentityType.GROUP) {
                    return item.securityIdentity.groupId;
                }

                if (item.securityIdentity.securityIdentityType === IdentityType.PROFILE) {
                    return item.securityIdentity.profileId;
                }

                return null;
            }),
        [permissions],
    );

    const onSetNewIdentity = useCallback(
        (newIdentity: EntityGrantedPermissions['securityIdentity']) => {
            const newPermission: EntityGrantedPermissions = {
                added: true,
                securityIdentity: newIdentity,
                grantedPermissions: [
                    {
                        permission: creativeType === Entity.TEMPLATE ? IdentityRole.CAN_USE : IdentityRole.CONTRIBUTOR,
                        granted: true,
                    },
                ],
                projectGrantedPermissions: [],
            };

            const existingPermission = entityGrantedPermissions?.find((permission) => {
                const isReviewer = permission.grantedPermissions.every(
                    (item) => item.permission === IdentityRole.REVIEWER && item.granted,
                );

                if (isReviewer) {
                    return false;
                }

                if (permission.securityIdentity.securityIdentityType === IdentityType.USER) {
                    return newPermission.securityIdentity.bynderUserId === permission.securityIdentity.bynderUserId;
                }

                if (permission.securityIdentity.securityIdentityType === IdentityType.GROUP) {
                    return newPermission.securityIdentity.groupId === permission.securityIdentity.groupId;
                }

                if (permission.securityIdentity.securityIdentityType === IdentityType.PROFILE) {
                    return newPermission.securityIdentity.profileId === permission.securityIdentity.profileId;
                }

                if (permission.securityIdentity.securityIdentityType === IdentityType.ALL_USERS) {
                    return (
                        newPermission.securityIdentity.securityIdentityType ===
                        permission.securityIdentity.securityIdentityType
                    );
                }

                return false;
            });

            if (existingPermission) {
                // in case if you remove existing permission and then restore it
                setPermissions([...permissions, existingPermission]);
            } else {
                // when you adding new permission, from scratch
                setPermissions([...permissions, newPermission]);
            }
        },
        [creativeType, entityGrantedPermissions, permissions],
    );

    const onShareRuleRemove = (id, type) => {
        if (type === IdentityType.USER) {
            setPermissions(permissions.filter((item) => item.securityIdentity.bynderUserId !== id));
        }

        if (type === IdentityType.GROUP) {
            setPermissions(permissions.filter((item) => item.securityIdentity.groupId !== id));
        }

        if (type === IdentityType.PROFILE) {
            setPermissions(permissions.filter((item) => item.securityIdentity.profileId !== id));
        }

        if (type === IdentityType.ALL_USERS) {
            setPermissions(
                permissions.filter((item) => item.securityIdentity.securityIdentityType !== IdentityType.ALL_USERS),
            );
            setRestrictionLevel(GroupAccessEntry.RESTRICTED);
        }
    };

    const onRestrictionLevelSelect = (restriction: GroupAccessEntry) => {
        setRestrictionLevel(restriction);

        if (restriction === GroupAccessEntry.RESTRICTED) {
            onShareRuleRemove(null, IdentityType.ALL_USERS);
        }

        if (
            restriction === GroupAccessEntry.ALL_USERS &&
            !permissions.some((perm) => perm.securityIdentity.securityIdentityType === IdentityType.ALL_USERS)
        ) {
            onSetNewIdentity({ securityIdentityType: IdentityType.ALL_USERS });
        }
    };

    // so we filtered removed permission from the list with onShareRuleRemove function
    // but now we need to track that change and send it to backend
    const getRemovedIdentities = useMemo(() => {
        const removedIdentitiesFindFn = (acc, permission, key) => {
            const isReviewer = permission.grantedPermissions.every(
                (item) => item.permission === IdentityRole.REVIEWER && item.granted,
            );

            if (isReviewer) {
                return acc;
            }

            if (!permissions.some((item) => permission.securityIdentity[key] === item.securityIdentity[key])) {
                return [
                    ...acc,
                    {
                        securityIdentity: permission.securityIdentity,
                        grantedPermissions: [
                            {
                                permission:
                                    creativeType === Entity.TEMPLATE ? IdentityRole.CAN_USE : IdentityRole.CONTRIBUTOR,
                                granted: false,
                            },
                        ],
                    },
                ];
            }

            return acc;
        };

        return entityGrantedPermissions?.reduce((acc: EntityGrantedPermissions[], permission) => {
            if (permission.securityIdentity.securityIdentityType === IdentityType.USER) {
                return removedIdentitiesFindFn(acc, permission, 'bynderUserId');
            }

            if (permission.securityIdentity.securityIdentityType === IdentityType.GROUP) {
                return removedIdentitiesFindFn(acc, permission, 'groupId');
            }

            if (permission.securityIdentity.securityIdentityType === IdentityType.PROFILE) {
                return removedIdentitiesFindFn(acc, permission, 'profileId');
            }

            if (permission.securityIdentity.securityIdentityType === IdentityType.ALL_USERS) {
                return removedIdentitiesFindFn(acc, permission, 'securityIdentityType');
            }

            return acc;
        }, []);
    }, [entityGrantedPermissions, permissions, creativeType]);

    // same idea, track new permissions
    const getNewOrChangedIdentities = useMemo(
        () => permissions.filter((permission) => permission.added),
        [permissions],
    );

    const getDiff = () => [...getNewOrChangedIdentities, ...getRemovedIdentities];

    const mapToSecurityIdentities = () =>
        getDiff().reduce((identities: any[], permission) => {
            const newSecurityIdentities = permission.grantedPermissions.reduce((roles: any[], item) => {
                if (item.permission === IdentityRole.OWNER) {
                    return roles;
                }

                const securityIdentityProps = () => {
                    if (permission.securityIdentity.securityIdentityType === IdentityType.GROUP) {
                        return {
                            groupId: permission.securityIdentity.groupId,
                            groupName: permission.securityIdentity.groupName,
                        };
                    }

                    if (permission.securityIdentity.securityIdentityType === IdentityType.PROFILE) {
                        return {
                            profileId: permission.securityIdentity.profileId,
                            profileName: permission.securityIdentity.profileName,
                        };
                    }

                    return {
                        bynderUserId: permission.securityIdentity.bynderUserId,
                        fullName: permission.securityIdentity.fullName,
                    };
                };

                return [
                    ...roles,
                    {
                        type: permission.securityIdentity.securityIdentityType,
                        aclRole: item.permission,
                        granting: item.granted,
                        ...securityIdentityProps(),
                    },
                ];
            }, []);

            return [...identities, ...newSecurityIdentities];
        }, []);

    const onSaveSharingRules = () => {
        const securityIdentities = mapToSecurityIdentities();

        if (securityIdentities && securityIdentities.length) {
            dispatch(
                createSharing(
                    {
                        entityId,
                        entityType,
                        securityIdentities: mapToSecurityIdentities(),
                        isDetailsPage,
                    },
                    onClose,
                ),
            );
        }
    };

    const resetTransferModal = () => {
        setTransferModalEntity(null);
    };

    const onTransferModalClose = () => {
        onClose();
        resetTransferModal();
    };

    const onEntityLinkCopy = async () => {
        if (!entityId) {
            return;
        }

        const getEntityLink = () => {
            if (entityType === EntityType.CREATIVE) {
                return getDesignEditLink(entityId);
            }

            if (entityType === EntityType.COLLECTION) {
                return getProjectDetailsPath(entityId);
            }

            if (entityType === EntityType.TEMPLATE) {
                return getTemplatePreviewLink(entityId);
            }

            if (entityType === EntityType.CATEGORY) {
                return getCategoryDetailsLink(entityId);
            }

            return '';
        };

        if (getEntityLink().length > 0) {
            try {
                await window.navigator.clipboard.writeText(`${hostUrl}#${getEntityLink()}`);

                notify({
                    thumbnail: <Thumbnail variant="clean" shape="circle" icon={<IconLink />} />,
                    title: <Translate id="modal.design.share.copy-link.success" />,
                });
            } catch (error) {
                notify({
                    title: <Translate id="modal.design.share.copy-link.error" />,
                    variant: 'error',
                });
            }
        }
    };

    const isReviewer =
        getIdentityRole(entityGrantedPermissions) === IdentityRole.REVIEWER &&
        isEntityActionAllowed(creativeActionType.UPDATE_PERMISSIONS, creativeType, [
            ...entityGrantedPermissions,
            ...projectGrantedPermissions,
        ]) !== ActionStatus.ALLOWED;

    const isTrialAccount =
        isPlatformAllowed([features.TRIAL_ENABLED]) && isAccountRoleAssigned(roles.video_brand_studio.video.manage);

    return (
        <>
            {loading && null}
            {!loading && transferModalEntity === null ? (
                <Modal
                    container={modalContainer}
                    title={title}
                    isOpen={isModalOpen}
                    onClose={() => onClose()}
                    actionPrimary={
                        isEntityActionAllowed(creativeActionType.UPDATE_PERMISSIONS, creativeType, [
                            ...entityGrantedPermissions,
                            ...projectGrantedPermissions,
                        ]) === ActionStatus.DISABLED || isReviewer ? (
                            <Button
                                variant="primary"
                                title={translate('modal.design.share.done')}
                                onClick={() => onClose()}
                            >
                                {translate('modal.design.share.done')}
                            </Button>
                        ) : (
                            <Button
                                variant="primary"
                                title={
                                    !saving
                                        ? translate('modal.design.share.save')
                                        : translate('modal.design.share.saving')
                                }
                                onClick={onSaveSharingRules}
                                isLoading={saving}
                                isDisabled={!getDiff().length || isReviewer || isTrialAccount}
                            >
                                {!saving
                                    ? translate('modal.design.share.save')
                                    : translate('modal.design.share.saving')}
                            </Button>
                        )
                    }
                    actionSecondary={
                        isEntityActionAllowed(creativeActionType.UPDATE_PERMISSIONS, creativeType, [
                            ...entityGrantedPermissions,
                            ...projectGrantedPermissions,
                        ]) === ActionStatus.DISABLED ? null : (
                            <Button
                                variant="secondary"
                                title={translate('modal.design.share.cancel')}
                                onClick={() => onClose()}
                            >
                                {translate('modal.design.share.cancel')}
                            </Button>
                        )
                    }
                    actionTertiary={
                        <Button
                            variant="secondary"
                            icon={<IconLink />}
                            title={translate('modal.design.share.copy-link')}
                            onClick={() => onEntityLinkCopy()}
                            data-testid="entity link copy"
                        >
                            {translate('modal.design.share.copy-link')}
                        </Button>
                    }
                >
                    {(((entityType === EntityType.COLLECTION || entityType === EntityType.CATEGORY) &&
                        isEntityActionAllowed(creativeActionType.UPDATE_PERMISSIONS, creativeType, [
                            ...entityGrantedPermissions,
                            ...projectGrantedPermissions,
                        ]) === ActionStatus.DISABLED) ||
                        isReviewer) && (
                        <BannerBox>
                            <InlineBanner variant="info">
                                {translate('modal.sharing.search.no-permission', { type: entityType })}
                            </InlineBanner>
                        </BannerBox>
                    )}
                    {(entityType === EntityType.COLLECTION || entityType === EntityType.CATEGORY) && (
                        <GroupAccessDropDown
                            restriction={restrictionLevel}
                            onSelect={onRestrictionLevelSelect}
                            isDisabled={
                                isEntityActionAllowed(creativeActionType.UPDATE_PERMISSIONS, creativeType, [
                                    ...entityGrantedPermissions,
                                    ...projectGrantedPermissions,
                                ]) === ActionStatus.DISABLED || isTrialAccount
                            }
                            entityType={entityType}
                        />
                    )}
                    {entityType === EntityType.CREATIVE &&
                        isEntityActionAllowed(DesignAction.UPDATE_PERMISSIONS, Entity.DESIGN, [
                            ...entityGrantedPermissions,
                            ...projectGrantedPermissions,
                        ]) === ActionStatus.DISABLED && (
                            <BannerBox>
                                <InlineBanner variant="info">
                                    {translate('modal.sharing.search.no-permission', { type: entityType })}
                                </InlineBanner>
                            </BannerBox>
                        )}
                    {isEntityActionAllowed(creativeActionType.UPDATE_PERMISSIONS, creativeType, [
                        ...entityGrantedPermissions,
                        ...projectGrantedPermissions,
                    ]) !== ActionStatus.DISABLED &&
                        !isReviewer && (
                            <IdentitiesSearch
                                existingIdentities={existingIdentities}
                                onIdentitySelect={onSetNewIdentity}
                                isDisabled={isTrialAccount}
                            />
                        )}

                    <UserGroupTitle>{translate('modal.design.share.access')}</UserGroupTitle>
                    <List>
                        {isPlatformAllowed([features.APPROVALS_ENABLED]) &&
                            approvalStatus &&
                            approvalStatus !== ReviewStatus.DRAFT &&
                            reviewers.length > 0 && <ReviewersDropdown entityPermissions={reviewers} />}
                        {collectionName && (
                            <ProjectPermissionsDropdown
                                projectPermissions={sortPermissions(
                                    projectGrantedPermissions,
                                    'projectGrantedPermissions',
                                )}
                                projectName={collectionName}
                            />
                        )}
                        <Box>
                            {renderSharePermissions(permissions, translate, entityType, {
                                entityType,
                                creativeActionType,
                                creativeType,
                                entityGrantedPermissions,
                                projectGrantedPermissions,
                                setTransferModalEntity,
                                onShareRuleRemove,
                            })}
                        </Box>
                    </List>
                </Modal>
            ) : (
                <TransferModal
                    entityId={entityId}
                    entityType={entityType}
                    permissions={permissions}
                    transferModalEntity={transferModalEntity}
                    onTransferModalClose={onTransferModalClose}
                    resetTransferModal={resetTransferModal}
                />
            )}
        </>
    );
};

export default ShareModal;
