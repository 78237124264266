import { createSelector } from 'reselect';
import { TemplatesState } from './types';
import { CREATIVES_PENDING_STATUSES } from '../creatives/creatives.constants';

export const getTemplates = (state: any): TemplatesState => state.templates;
const getCurrentTemplates = (state: any) => getTemplates(state).templates;
export const getTemplatesActionProgress = (
    state: any,
): {
    deleteInProgress: TemplatesState['deleteInProgress'];
    renamingInProgress: TemplatesState['renamingInProgress'];
} => ({
    deleteInProgress: state.templates.deleteInProgress,
    renamingInProgress: state.templates.renamingInProgress,
});

export const getPendingTemplates = createSelector(getCurrentTemplates, (templates) =>
    templates
        .filter((template) => CREATIVES_PENDING_STATUSES.includes(template.thumbnailStatus))
        .map((template) => template.id),
);
