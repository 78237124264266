import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const InspectorPanel = styled.div`
    flex: 0 1 1;
    width: 336px;
    max-width: 100%;
    height: 100%;
    background: ${token.white};
    border-left: 1px solid ${token.gray100a};
    overflow-y: auto;
    font-size: 1rem;
    padding: ${token.spacing3} 0;
`;
