import { maxBy, minBy, reduce } from 'rambda';
import { findElementById } from '../../../helpers/elementtree';

export const LEFT_BORDER_ID = 'LEFT_BORDER_ID';
export const RIGHT_BORDER_ID = 'RIGHT_BORDER_ID';

const isClose = (elem, to) => to - 4 < elem && to + 4 > elem;

export const closeTo = (left, right, to, fromRight) => {
    if (fromRight !== true && isClose(left, to)) return 'left';
    if (fromRight !== false && isClose(right, to)) return 'right';
    return 0;
};

const getLeftAndright = (el) => {
    const { x, width } = el;
    const left = parseInt(x);
    return {
        left,
        right: left + parseInt(width),
    };
};

export const drawLine = (fromRight, line, left, [lowestPoint, topPoint], y) => {
    line.style.opacity = '1';
    line.style.left = left + 'px';
    line.style.height = Math.abs(topPoint.y - lowestPoint.y) + 40 + 'px';
    line.style.top = topPoint.y - y + 'px';
};

export const removeLine = (line) => {
    line.style.opacity = '0';
};

export const findSnappingZones = (elementBoundingBox, otherElements, fromRight) => {
    const { left: elementLeftPoint, right: elementRightPoint } = getLeftAndright(elementBoundingBox);
    const snappingPoints = [];
    otherElements.forEach((elem) => {
        const { left: leftPoint, right: rightPoint } = getLeftAndright({ x: elem.relativeLeft, width: elem.width });

        const closeToLeft = closeTo(elementLeftPoint, elementRightPoint, leftPoint, fromRight);
        if (closeToLeft) {
            snappingPoints.push({ ...elem, toLeft: true, fromRight: closeToLeft === 'right' });
            return;
        }

        const closeToRight = closeTo(elementLeftPoint, elementRightPoint, rightPoint, fromRight);
        if (closeToRight) {
            snappingPoints.push({ ...elem, fromRight: closeToRight === 'right' });
        }
    });

    return snappingPoints;
};

export const findSnapPoints = (elementBoundingBox, otherElements, fromRight) => {
    const snapPoints = findSnappingZones(elementBoundingBox, otherElements, fromRight);

    if (!snapPoints.length) {
        return [];
    }

    snapPoints.push({ y: elementBoundingBox.y });

    return [
        reduce(
            maxBy((snapPoint) => snapPoint.y),
            { y: -Infinity },
            snapPoints,
        ),
        reduce(
            minBy((snapPoint) => snapPoint.y),
            { y: Infinity },
            snapPoints,
        ),
    ];
};

export const getOtherElementsBoundingBoxes = (containerBoundingBox, elementId, parentId) => {
    const allElements = document.getElementsByClassName('timeline__element');
    const boundingBoxes = [];
    for (let i = 0; i < allElements.length; i++) {
        const element = allElements[i];
        if (![parentId, elementId].includes(+element.dataset.id)) {
            const boundingBox = element.getBoundingClientRect();
            boundingBoxes.push({
                ...boundingBox.toJSON(),
                elementId: element.dataset.id,
                relativeLeft: Math.abs(boundingBox.x - containerBoundingBox.x),
            });
        }
    }

    return [...getContainerBox(containerBoundingBox), ...boundingBoxes];
};

const getContainerBox = ({ y, width, height }) => {
    return [
        { relativeLeft: 0, y, elementId: LEFT_BORDER_ID },
        { relativeLeft: 0, y: y + height, elementId: LEFT_BORDER_ID },
        { relativeLeft: width, y, elementId: RIGHT_BORDER_ID },
        { relativeLeft: width, y: y + height, elementId: RIGHT_BORDER_ID },
    ];
};

export const findElementByIdForSnap = (elements, elementId, trackDuration) => {
    if (elementId === RIGHT_BORDER_ID) return { startFrame: trackDuration, duration: 0 };
    if (elementId === LEFT_BORDER_ID) return { startFrame: 0, duration: 0 };
    return findElementById(elements, elementId);
};
