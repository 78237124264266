import React from 'react';
import styled, { css } from 'styled-components';
import { Button, Input, token } from '@bynder/design-system';
import { IconSearch } from '@bynder/icons';

const IconButton = styled(Button)`
    box-shadow: none !important;
    border: none !important;
    user-select: none !important;

    &:focus {
        box-shadow: none !important;
        border: none !important;
        color: ${token.componentButtonCleanColor} !important;
    }

    &:hover {
        color: ${token.componentButtonCleanColorHover} !important;
    }

    ${(props) =>
        props.hide &&
        css`
            display: none !important;
        `}

    ${(props) =>
        props.isPressed &&
        css`
            &:hover {
                background-color: ${token.componentButtonCleanFillActive} !important;
            }
        `}
`;

export const SearchButton = (props) => (
    <IconButton variant="clean" icon={<IconSearch />} aria-label="Search" {...props} />
);

export const SearchInput = styled(Input)`
    ${(props) =>
        props.hide &&
        css`
            display: none !important;
        `}
    max-width: 256px;
`;
