import React, { useEffect, useRef } from 'react';
import { ElementUpdateTypes, ElementUpdatedEventData, GroupElement } from '@bynder-studio/render-core';
import { Form } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import editorAutocorrect from 'packages/pages/editor/EditorAutocorrectRules';
import InputLinked from '../../../FormComponents/InputLinked';
import useForceUpdate from '~/hooks/useForceUpdate';

const Width = ({ creativeModel, creativeType, selectedElement, isLinked = false }) => {
    const { translate } = useTranslate();
    const {
        id,
        locked,
        dimension: { width },
    } = selectedElement;
    const inputRef = useRef<HTMLInputElement>(null);
    const value = useRef(width);
    const forceUpdate = useForceUpdate();

    const setValue = (val) => {
        value.current = val;

        if (inputRef.current) {
            inputRef.current.value = val;
        }
    };

    const isGroup = selectedElement instanceof GroupElement;

    const applyChanges = (val: number) => {
        const isUniScale = (isGroup || selectedElement.lockUniScaling) && selectedElement.dimension.getWidth();
        const correctValue = editorAutocorrect(
            'element_width',
            val,
            creativeType,
            selectedElement.dimension,
            isUniScale,
        );
        setValue(correctValue);
        const param = {
            dimension: {
                ...selectedElement.dimension,
                width: Number(correctValue),
            },
        };

        if (isUniScale) {
            const k = Number(correctValue) / selectedElement.dimension.getWidth();
            param.dimension.height = Math.ceil(selectedElement.dimension.getHeight() * k);
        }

        if (Math.trunc(selectedElement.dimension.width) !== param.dimension.width) {
            if (isGroup) {
                const scale = Number(correctValue) / selectedElement.dimension.getWidth();

                // update all child elements of a group
                selectedElement.children.forEach((groupElement) => {
                    const groupElParam = {
                        dimension: {
                            width: Math.ceil(groupElement.dimension.getWidth() * scale),
                            height: Math.ceil(groupElement.dimension.getHeight() * scale),
                        },
                        position: {
                            x: groupElement.position.x * scale,
                            y: groupElement.position.y * scale,
                        },
                    };

                    creativeModel.updateElement(groupElement.id, groupElParam);
                });
            } else {
                creativeModel.updateElement(id, param);
            }
        }
    };

    const onBlur = () => {
        applyChanges(value.current);
    };

    const onChange = (newValue) => {
        setValue(newValue);
        forceUpdate();
    };

    const onKeyDown = (event) => {
        switch (event.key) {
            case 'Enter':
                onBlur();
                break;
            case 'ArrowUp':
            case 'ArrowDown': {
                event.preventDefault();
                const numberToAdd = event.key === 'ArrowDown' ? -1 : 1;
                const factor = event.shiftKey ? 10 : 1;
                applyChanges(Number(value.current) + numberToAdd * factor);
                break;
            }
        }
    };

    // because value can be changed on the canvas
    useEffect(() => {
        const correctValue = editorAutocorrect('element_width', width, creativeType, selectedElement.dimension);
        setValue(correctValue);
    }, [id, width]);

    useEffect(() => {
        const listener = (data: ElementUpdatedEventData) => {
            if (data.element.id === id && data.updateTypes.has(ElementUpdateTypes.DIMENSION)) {
                setValue(
                    editorAutocorrect(
                        'element_width',
                        data.element.dimension.getWidth(),
                        creativeType,
                        data.element.dimension,
                    ),
                );
            }
        };

        creativeModel.on('elementUpdated', listener);

        return () => creativeModel.off('elementUpdated', listener);
    }, [creativeModel, creativeType, id]);

    return (
        <Form.Group>
            <InputLinked
                inputRef={inputRef}
                name={translate('editor.sidebar.shots.width.label')}
                aria-label={translate('editor.sidebar.shots.width.label')}
                type="number"
                value={Math.ceil(value.current).toString()}
                elementValue={Math.trunc(selectedElement.dimension.width)}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onChange={onChange}
                isDisabled={locked}
                prefix="W"
                suffix="px"
                data-testid="shots_properties_width"
                isLinked={isLinked}
            />
        </Form.Group>
    );
};

export default Width;
