import React, { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isAppInitializingSelector } from 'packages/store/platform/platform.selectors';
import { getCreatives } from 'packages/store/creatives/creatives.selectors';

export const SkeletonContext = createContext({
    skeletonShow: true,
    setSkeletonShow: (value: boolean) => {},
});

export default function SkeletonProvider({ children }) {
    const isAppInitializing = useSelector(isAppInitializingSelector);
    const { pendingDesignSavingRequest } = useSelector(getCreatives);
    const [skeletonShow, setSkeletonShow] = useState(isAppInitializing || pendingDesignSavingRequest);

    useEffect(() => {
        setSkeletonShow(isAppInitializing || pendingDesignSavingRequest);
    }, [isAppInitializing, pendingDesignSavingRequest]);

    const value = {
        skeletonShow,
        setSkeletonShow,
    };

    return <SkeletonContext.Provider value={value}>{children}</SkeletonContext.Provider>;
}
