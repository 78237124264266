import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslate } from '@bynder/localization';
import { toPercent, toSnakeCase, toValue } from 'packages/pages/editor/RightSideMenu/Shots/utils';
import editorAutocorrect from 'packages/pages/editor/EditorAutocorrectRules';
import generateTestId from '~/helpers/testIdHelpers';
import { FontScaleProps, SCALE_PROPS } from 'packages/pages/editor/RightSideMenu/Shots/Text/TextScale/types';

const MAX_FONT_SCALE_PERCENT = 1000;
const MAX_MIN_FONT_SCALE_PERCENT = 100;

export const useFontScale = ({ selectedElement, creativeModel, propName }: FontScaleProps) => {
    const { id, locked } = selectedElement;
    const scale = selectedElement[propName];
    const { translate } = useTranslate();
    const [sliderValue, setSliderValue] = useState(toPercent(scale));
    const [inputValue, setInputValue] = useState(toPercent(scale).toString());

    const isFontScale = useMemo(() => propName === SCALE_PROPS.FONT_SCALE, [propName]);
    const isDisabled = useMemo(() => locked, [locked]);

    const onSliderChanged = useCallback(
        (value: number) => {
            if (isDisabled) {
                return;
            }

            setSliderValue(value);
            setInputValue(value.toString());
            creativeModel.updateElement(id, { [propName]: toValue(value) });
        },
        [creativeModel, id, isDisabled],
    );

    const handleBlur = useCallback(() => {
        onSliderChanged(editorAutocorrect(toSnakeCase(propName), inputValue));
    }, [onSliderChanged, inputValue, propName]);

    const handleKeyDown = useCallback(
        (event) => {
            if (event.key === 'Enter') {
                handleBlur();
                event.target.blur();
            }
            if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                event.preventDefault();
                const numberToAdd = event.key === 'ArrowDown' ? -1 : 1;
                setInputValue(String(+inputValue + numberToAdd));
            }
        },
        [handleBlur, setInputValue, inputValue],
    );

    useEffect(() => {
        const value = toPercent(scale);

        setSliderValue(value);
        setInputValue(value.toString());
    }, [scale]);

    const label = translate(`editor.sidebar.shots.text.text.scale.${toSnakeCase(propName)}.label`);
    const sliderTestId = generateTestId(`shots_text_placement_${propName}_slider`);
    const inputTestId = generateTestId(`shots_text_placement_${propName}_input`);
    const max = isFontScale ? MAX_FONT_SCALE_PERCENT : MAX_MIN_FONT_SCALE_PERCENT;

    return {
        isFromCenter: isFontScale,
        max,
        inputTestId,
        isDisabled,
        label,
        sliderValue,
        onSliderChanged,
        sliderTestId,
        inputValue,
        handleInputChange: setInputValue,
        handleKeyDown,
        handleBlur,
    };
};
