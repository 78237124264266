import React, { ReactNode, useCallback } from 'react';
import { Card } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { Template } from 'packages/store/templates/types';
import generateTestId from '~/helpers/testIdHelpers';
import TemplateThumbnail from './TemplateThumbnail';

export type TemplateCardBaseProps = {
    template: Template;
    isSelected?: boolean;
    onSelect?: (template: Template) => void;
    onClick?: (template: Template) => void;
    testId: string;
    selectButtonAriaLabel: string;
    contextAction: ReactNode;
    headerIcon?: React.JSX.Element | null | false;
};

const TemplateCardBase = ({
    template,
    isSelected,
    onSelect,
    onClick,
    testId,
    selectButtonAriaLabel,
    contextAction,
    headerIcon,
}: TemplateCardBaseProps) => {
    const { translate } = useTranslate();

    const templatePagesInfo =
        template.pages.length > 1
            ? translate('pages.categories.template.numberOfPages', { count: template.pages.length })
            : `${template.pages[0].width} × ${template.pages[0].height} px`;

    const handleSelect = useCallback(() => {
        if (onSelect) {
            onSelect(template);
        }
    }, [onSelect, template]);

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.preventDefault();
            onClick?.(template);
        },
        [onClick, template],
    );

    const onCardSelect = onSelect && {
        onSelect: handleSelect,
        isSelected,
    };

    return (
        <Card
            variant="clean"
            onClick={handleClick}
            {...onCardSelect}
            selectButtonAriaLabel={selectButtonAriaLabel}
            aria-label={template.name}
            contextAction={contextAction}
            {...generateTestId(testId)}
        >
            <TemplateThumbnail template={template} />
            <Card.Header title={template.name} detail={templatePagesInfo} icon={headerIcon} isNotTruncated />
        </Card>
    );
};

export default TemplateCardBase;
