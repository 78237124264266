import { ColorParams, TextElement, TextStyle } from '@bynder-studio/render-core';
import { BrandColor, BrandColorPalette } from 'packages/store/brandColors/types';
import { colorToCSS, rgbaToObject } from '~/common/editor/editorHelper';
import { toPercent } from '../../utils';
import { hexToRgb, rgbToHex } from '../../../FormComponents/ColorPicker/utils';
import { AppliedStyles, CleanedRunStyle } from './types';

// eslint-disable-next-line import/no-unused-modules
export const fontSize = (fontSize: number) => (Number.isNaN(fontSize) ? 'Mixed' : fontSize + 'px');

// eslint-disable-next-line import/no-unused-modules
export const leading = (leading: number) => {
    if (Number.isNaN(leading)) {
        return 'Mixed';
    } else if (leading === 1) {
        return 'Auto';
    }

    return toPercent(leading);
};

// eslint-disable-next-line import/no-unused-modules
export const overridesText = (overrides) => `${overrides} override${overrides > 1 ? 's' : ''}`;

export const textStyleSubtext = (style?: TextStyle, overrides = 0) => {
    if (overrides) {
        return overridesText(overrides);
    } else if (style) {
        return `${fontSize(style.fontSize)} / ${leading(style.leading)}`;
    }

    return '';
};

export const collectStyleIds = (selectedElement?: TextElement) => {
    const styles = new Set<string | null>();

    if (!(selectedElement instanceof TextElement)) {
        return styles;
    }

    const { runs } = selectedElement.getTextProps();
    styles.add(null);
    runs.forEach((run) => styles.add(run.styleId || null));

    return styles;
};

export const collectElementStyleIds = (element?: TextElement) => {
    if (!(element instanceof TextElement)) {
        return new Set<string>();
    }

    const appliedStyles = collectStyleIds(element);

    return new Set([...([...appliedStyles].filter((s) => !!s) as string[]), ...element.textStyles]);
};

export const collectBrandColorIds = (selectedElement?: TextElement) => {
    const brandColors = new Set<number>();

    if (!(selectedElement instanceof TextElement)) {
        return brandColors;
    }

    const { runs } = selectedElement.getTextProps();
    runs.forEach((run) => {
        if (run.color && run.color.brandColorId) {
            brandColors.add(run.color.brandColorId);
        }
    });

    return brandColors;
};

export const collectCustomColors = (selectedElement?: TextElement) => {
    const list: { hexCode: string; alpha: number }[] = [];

    if (!(selectedElement instanceof TextElement)) {
        return list;
    }

    const customColors = new Set<string>();

    const { runs } = selectedElement.getTextProps();
    runs.forEach((run) => {
        if (run.color && !run.color.brandColorId) {
            const hexCode = rgbToHex(colorToCSS(run.color));

            if (customColors.has(hexCode)) {
                return;
            }

            customColors.add(hexCode);
            list.push({ hexCode, alpha: run.color.opacity });
        }
    });

    return list;
};

export const collectElementBrandColorIds = (element?: TextElement) => {
    if (!(element instanceof TextElement)) {
        return new Set<number>();
    }
    const brandColors = collectBrandColorIds(element);

    return new Set([...brandColors, ...element.brandColors]);
};

export const collectStylIdsFromTemplate = (templateElement: any) => {
    const styles = new Set<string | null>();

    if (templateElement?.properties) {
        styles.add(templateElement.properties?.styleId || null);
        templateElement.properties.value.runs.forEach((run) => styles.add(run.styleId || null));
        (templateElement.properties.textStyles || []).forEach((styleId) => styles.add(styleId));
    }

    return styles;
};

export const toRGBA = (c: ColorParams): string => `rgba(${c.red}, ${c.green}, ${c.blue}, ${c.opacity})`;

export const collectColorsFromTemplate = (templateElement: any, palettes: BrandColorPalette[]) => {
    const styles = new Set<string | null>();
    const colors: ColorParams[] = [];

    if (templateElement?.properties?.fontColor) {
        const key =
            toRGBA(templateElement.properties.fontColor) + (templateElement.properties.fontColor.brandColorId || '');

        if (!styles.has(key)) {
            styles.add(key);
            colors.push(templateElement.properties.fontColor);
        }
    }

    if (templateElement?.properties) {
        templateElement.properties.value.runs.forEach((run) => {
            if (run.color) {
                const key = toRGBA(run.color) + (run.color.brandColorId || '');

                if (!styles.has(key)) {
                    styles.add(key);
                    colors.push(run.color);
                }
            }
        });
    }

    const byId: { [brandColorId: number]: BrandColor } = {};
    palettes.forEach((palette) => {
        palette.brandColors.forEach((color) => {
            byId[color.id] = color;
        });
    });

    (templateElement?.properties?.brandColors || []).forEach((brandColorId: number) => {
        const brandColor = byId[brandColorId];

        if (brandColor) {
            const color: ColorParams = {
                brandColorId: brandColor.id,
                ...rgbaToObject(hexToRgb(brandColor.hexCode, brandColor.alpha)),
            };
            const key = toRGBA(color) + (color.brandColorId || '');

            if (!styles.has(key)) {
                styles.add(key);
                colors.push(color);
            }
        }
    });

    return colors;
};

export const collectColorsFromElement = (element: TextElement, palettes: BrandColorPalette[]) => {
    const styles = new Set<string | null>();
    const colors: ColorParams[] = [];
    const { runs } = element.getTextProps();

    const byId: { [brandColorId: number]: BrandColor } = {};
    palettes.forEach((palette) => {
        palette.brandColors.forEach((color) => {
            byId[color.id] = color;
        });
    });

    runs.forEach((run) => {
        if (run.color) {
            const key = toRGBA(run.color) + run.color.brandColorId;

            if (!styles.has(key)) {
                styles.add(key);
                colors.push(run.color);
            }
        }
    });

    (element.brandColors || []).forEach((brandColorId: number) => {
        const brandColor = byId[brandColorId];

        if (brandColor) {
            const color: ColorParams = {
                brandColorId: brandColor.id,
                ...rgbaToObject(hexToRgb(brandColor.hexCode, brandColor.alpha)),
            };
            const key = toRGBA(color) + color.brandColorId;

            if (!styles.has(key)) {
                styles.add(key);
                colors.push(color);
            }
        }
    });

    return colors;
};

export const cleanUpRawStyles = (rawStyles: AppliedStyles, runIdx: number) => {
    const obj = {} as CleanedRunStyle;
    Object.entries(rawStyles).forEach(([key, value]) => {
        if (['styleId', 'color'].includes(key)) {
            return;
        }

        obj[key] = key === 'fontId' ? Number(value[runIdx]) : value[runIdx];
    });

    return obj;
};

export const textStyleToRunStyle = (textStyle: TextStyle) => {
    const { uuid, name, ...styles } = textStyle;
    // TODO: This will be fixed after leading fontId property to common type!
    (styles as any).fontId = String(styles.fontId);

    return styles;
};
