import * as v from 'valibot';

export const CollectionFragment = `
fragment CollectionFragment on Collection {
    id
    name
    assetCount
    thumbnailUrls
    url
}`;

export const collectionSchema = v.object({
    id: v.string(),
    name: v.string(),
    assetCount: v.number(),
    thumbnailUrls: v.array(v.string()),
    url: v.string(),
});

export type Collection = v.InferOutput<typeof collectionSchema>;
