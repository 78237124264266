export enum ElementAlign {
    HORIZONTAL_LEFT = 'HORIZONTAL-LEFT',
    HORIZONTAL_RIGHT = 'HORIZONTAL-RIGHT',
    HORIZONTAL_CENTER = 'HORIZONTAL-CENTER',
    VERTICAL_TOP = 'VERTICAL-TOP',
    VERTICAL_BOTTOM = 'VERTICAL-BOTTOM',
    VERTICAL_CENTER = 'VERTICAL-CENTER',
}

export enum ElementDistribute {
    DISTRIBUTE_HORIZONTALLY = 'DISTRIBUTE_HORIZONTALLY',
    DISTRIBUTE_VERTICALLY = 'DISTRIBUTE_VERTICALLY',
}
