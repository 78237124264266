import { useCallback, useEffect } from 'react';
import { CompositeOperationTypes, ElementUpdateTypes } from '@bynder-studio/render-core';
import useEditor from '~/hooks/useEditor';
import useEditorSelectedElement from '~/hooks/useEditorSelectedElement';
import useForceUpdate from '~/hooks/useForceUpdate';

export const useBlendMode = () => {
    const { creativeModel } = useEditor();
    const { selectedElement } = useEditorSelectedElement();
    const { locked = false } = selectedElement;
    const { operation = CompositeOperationTypes.SOURCE_OVER } = selectedElement.blendMode || {};
    const forceUpdate = useForceUpdate();

    const handleBlendModeChange = useCallback<(operation: CompositeOperationTypes) => void>(
        (operation) => {
            const blendMode = selectedElement.blendMode?.toObject() || {};
            blendMode.operation = operation;
            creativeModel.updateElement(selectedElement.id, { blendMode });
        },
        [selectedElement.id],
    );

    useEffect(() => {
        return creativeModel.onElementPartialUpdate(selectedElement.id, ElementUpdateTypes.BLEND_MODE, forceUpdate);
    }, [selectedElement.id]);

    return { operation, locked, handleBlendModeChange };
};
