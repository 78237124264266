import React, { type KeyboardEventHandler, useEffect, useState } from 'react';
import { IconAngle, IconOpacity, IconBlur } from '@bynder/icons';
import generateTestId from '~/helpers/testIdHelpers';
import { StyledInput } from './InputField.styled';

const NAMES = [
    'blinks',
    'scale',
    'distance',
    'opacity',
    'softness',
    'angle',
    'rotations',
    'horizontalPosition',
    'verticalPosition',
] as const;

export type InputFieldProp = (typeof NAMES)[number];

export const isInputField = (name: string): name is InputFieldProp => NAMES.includes(name as InputFieldProp);

const ICONS: { [Key in InputFieldProp]?: React.JSX.Element } = {
    opacity: <IconOpacity />,
    softness: <IconBlur />,
    angle: <IconAngle />,
};

const PREFIXES: { [Key in InputFieldProp]?: string } = {
    horizontalPosition: 'X',
    verticalPosition: 'Y',
};

const SUFFIXES: { [Key in InputFieldProp]?: string } = {
    opacity: '%',
    distance: '%',
    scale: '%',
    horizontalPosition: 'px',
    verticalPosition: 'px',
    angle: '°',
};

const getInputValue = (name: InputFieldProp, value: number) => {
    let displayValue = value;

    if (SUFFIXES[name] === '%') {
        displayValue = Math.round(value * 100);
    }

    return displayValue.toString();
};

const parseInputValue = (name: InputFieldProp, value: string, increment = 0) => {
    if (SUFFIXES[name] !== '%') {
        return (parseInt(value, 10) || 0) + increment;
    }

    return ((parseInt(value, 10) || 0) + increment) / 100;
};

type InputFieldProps = {
    name: InputFieldProp;
    id: string;
    testId: string;
    disabled?: boolean;
    value: number;
    autocorrect: (val: number) => number;
    onChange: (name: InputFieldProp, value: number) => void;
    fallbackValue?: any;
};

const InputField = ({
    name,
    id,
    testId,
    disabled = false,
    value,
    onChange,
    autocorrect,
    fallbackValue,
}: InputFieldProps) => {
    const [inputValue, setInputValue] = useState(getInputValue(name, value));

    const handleOnChange = (newValue: string, force = false, increment = 0) => {
        if (disabled) {
            return;
        }

        if (!force && newValue === '') {
            setInputValue(newValue);

            return;
        }

        let updatedValue = parseInputValue(name, newValue, increment);

        if (!force) {
            setInputValue(getInputValue(name, updatedValue));

            return;
        }

        updatedValue = autocorrect(updatedValue);

        setInputValue(getInputValue(name, updatedValue));
        onChange(name, updatedValue);
    };

    const handleOnBlur = () => {
        if (!inputValue.length && fallbackValue) {
            handleOnChange(fallbackValue, true);
        } else {
            handleOnChange(inputValue, true);
        }
    };

    const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
        switch (event.key) {
            case 'ArrowUp':
            case 'ArrowDown': {
                event.preventDefault();
                const numberToAdd = event.key === 'ArrowDown' ? -1 : 1;
                const factor = event.shiftKey ? 10 : 1;

                handleOnChange(inputValue, true, numberToAdd * factor);
                break;
            }
            case 'Enter':
                (event.target as HTMLInputElement).blur();
                break;
        }
    };

    useEffect(() => {
        setInputValue(getInputValue(name, value));
    }, [value, name]);

    const labelId = `${id}_label`;
    const testIdProp = generateTestId(testId);

    return (
        <StyledInput
            {...testIdProp}
            type="number"
            isDisabled={disabled}
            onKeyDown={onKeyDown}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            value={inputValue}
            aria-labelledby={labelId}
            icon={ICONS[name]}
            prefix={PREFIXES[name]}
            suffix={SUFFIXES[name]}
        />
    );
};

export default InputField;
