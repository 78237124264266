import React from 'react';
import { KeyboardBadge } from '@bynder/design-system';
import { Translate } from '@bynder/localization';

const clientOS = navigator.userAgent.toLowerCase();
const isCommandKey = clientOS.includes('mac');

export function Shift(): React.JSX.Element {
    return (
        <KeyboardBadge icon="shift">
            <Translate id="modal.shortcuts.keys.shift" />
        </KeyboardBadge>
    );
}

export function Cmd(): React.JSX.Element {
    return (
        <KeyboardBadge icon="command">
            <Translate id="modal.shortcuts.keys.cmd" />
        </KeyboardBadge>
    );
}

export function Ctrl(): React.JSX.Element {
    return (
        <KeyboardBadge icon="control">
            <Translate id="modal.shortcuts.keys.control" />
        </KeyboardBadge>
    );
}

export function Space(): React.JSX.Element {
    return (
        <KeyboardBadge icon="spacebar">
            <Translate id="modal.shortcuts.keys.space" />
        </KeyboardBadge>
    );
}

export function BackSpace(): React.JSX.Element {
    return (
        <KeyboardBadge icon="backspace">
            <Translate id="modal.shortcuts.keys.backspace" />
        </KeyboardBadge>
    );
}

export function ArrowLeft(): React.JSX.Element {
    return (
        <KeyboardBadge icon="left" isLabelHidden>
            <Translate id="modal.shortcuts.arrows.left" />
        </KeyboardBadge>
    );
}

export function ArrowRight(): React.JSX.Element {
    return (
        <KeyboardBadge icon="right" isLabelHidden>
            <Translate id="modal.shortcuts.arrows.right" />
        </KeyboardBadge>
    );
}

export function ArrowUp(): React.JSX.Element {
    return (
        <KeyboardBadge icon="up" isLabelHidden>
            <Translate id="modal.shortcuts.arrows.up" />
        </KeyboardBadge>
    );
}

export function ArrowDown(): React.JSX.Element {
    return (
        <KeyboardBadge icon="down" isLabelHidden>
            <Translate id="modal.shortcuts.arrows.down" />
        </KeyboardBadge>
    );
}

export function Option(): React.JSX.Element {
    return (
        <KeyboardBadge icon="option">
            <Translate id="modal.shortcuts.keys.option" />
        </KeyboardBadge>
    );
}

export function Alt(): React.JSX.Element {
    return (
        <KeyboardBadge>
            <Translate id="modal.shortcuts.keys.alt" />
        </KeyboardBadge>
    );
}

export function Options(): React.JSX.Element {
    return isCommandKey ? <Option /> : <Alt />;
}

export function Command(): React.JSX.Element {
    return isCommandKey ? <Cmd /> : <Ctrl />;
}
