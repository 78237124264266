import React, { useCallback, useMemo } from 'react';

import { isVariationInvalid } from 'packages/pages/design/sidebar/variations/utils';
import { ActionCheckbox } from './List.styled';
import useExport from '../hooks/useExport';

export const SelectRowCheckbox = ({ item, isDisabled }) => {
    const { selectedIds, selectItemEntries } = useExport();
    const count = useMemo(() => item.entries.filter((e) => !isVariationInvalid(e)).length, [item]);
    const selectedCount = item.entries.reduce((acc, entry) => acc + (selectedIds[entry.variationId] ? 1 : 0), 0);
    const isAllSelected = selectedCount !== 0 && selectedCount === count;

    const handleChange = useCallback(() => {
        selectItemEntries(item, !isAllSelected);
    }, [item, isAllSelected, selectItemEntries]);

    return (
        <ActionCheckbox
            isDisabled={isDisabled}
            isChecked={isAllSelected && !isDisabled}
            isIndeterminate={!!selectedCount && !isAllSelected}
            onChange={handleChange}
        />
    );
};
