import React, { useEffect, useState } from 'react';
import { Input } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { ImageElement, ShapeElement, TextElement, VideoElement } from '@bynder-studio/render-core';
import { getDropShadowValueFromElement } from '~/common/editor/helpers';
import { useInputBlur } from 'packages/hooks/useInputBlur';
import editorAutocorrect from 'packages/pages/editor/EditorAutocorrectRules';
import generateTestId from '~/helpers/testIdHelpers';

type ShadowBlurProps = {
    disabled: boolean;
    onChange: (prop: string, value: any) => void;
    selectedElement: TextElement | ImageElement | VideoElement | ShapeElement;
};

const ShadowBlur = ({ disabled, onChange, selectedElement }: ShadowBlurProps) => {
    const { translate } = useTranslate();
    const { onFocus, unsubscribeListener } = useInputBlur();

    const [selectedShadowBlur, setSelectedShadowBlur] = useState(
        getDropShadowValueFromElement(selectedElement).blurRadius,
    );

    useEffect(() => {
        setSelectedShadowBlur(getDropShadowValueFromElement(selectedElement).blurRadius);
    }, [selectedElement]);

    const applyChanges = (val: number) => {
        const correctBlurValue = editorAutocorrect('shadow_blur_radius', val);

        setSelectedShadowBlur(correctBlurValue.toString());
        onChange('blurRadius', correctBlurValue);
    };

    const onBlur = () => {
        unsubscribeListener();
        applyChanges(Number(selectedShadowBlur));
    };

    const onKeyDown = (event) => {
        switch (event.key) {
            case 'Enter':
                applyChanges(Number(selectedShadowBlur));
                break;
            case 'ArrowUp':
            case 'ArrowDown': {
                event.preventDefault();
                const numberToAdd = event.key === 'ArrowDown' ? -1 : 1;
                const factor = event.shiftKey ? 10 : 1;
                applyChanges(Number(selectedShadowBlur) + numberToAdd * factor);
                break;
            }
        }
    };

    const testId = generateTestId('shots_styling_shadow__blur');

    return (
        <Input
            name={translate('editor.sidebar.shots.shadow.blur.label')}
            aria-label={translate('editor.sidebar.shots.shadow.blur.label')}
            type="number"
            value={selectedShadowBlur}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={setSelectedShadowBlur}
            onKeyDown={onKeyDown}
            isDisabled={disabled}
            isInvalid={false}
            suffix="px"
            {...testId}
        />
    );
};

export default ShadowBlur;
