import React from 'react';
import { Dropdown, Divider } from '@bynder/design-system';
import { IconAdd, IconMin } from '@bynder/icons';
import { ZOOM_VALUES } from '~/common/editor/CanvasZoomControls/CanvasZoomControls';
import {
    ZoomControlsStyled,
    ZoomControlButton,
    ZoomControlWrapper,
    ZoomValue,
    ZoomControlsFooter,
} from './ZoomControls.styled';

const ZoomControls = ({ onZoomIn, onZoomOut, onZoomToFit, onZoomClick, zoomPercentage, maxZoom, minZoom }) => {
    const disableZoomIn = zoomPercentage >= maxZoom;
    const disableZoomOut = zoomPercentage <= minZoom;

    return (
        <ZoomControlsStyled>
            <ZoomControlsFooter>
                <ZoomControlWrapper alignItems="center">
                    <ZoomControlButton
                        title="Zoom out"
                        variant="clean"
                        icon={<IconMin />}
                        isDisabled={disableZoomOut}
                        onClick={onZoomOut}
                    />
                    <Dropdown
                        position="bottom-left"
                        trigger={({ isOpen, ...triggerProps }) => (
                            <ZoomValue
                                data-testid="zoom current value"
                                isPressed={isOpen}
                                variant="clean"
                                isFullWidth
                                {...triggerProps}
                            >
                                {zoomPercentage}%
                            </ZoomValue>
                        )}
                    >
                        <Dropdown.Item onClick={onZoomToFit}>Zoom to fit</Dropdown.Item>
                        <Divider />
                        {ZOOM_VALUES.map((zoomVal) => (
                            <Dropdown.Item
                                key={zoomVal}
                                isChecked={zoomVal === zoomPercentage}
                                onClick={() => onZoomClick(zoomVal)}
                            >
                                {zoomVal}%
                            </Dropdown.Item>
                        ))}
                    </Dropdown>
                    <ZoomControlButton
                        title="Zoom in"
                        variant="clean"
                        icon={<IconAdd />}
                        isDisabled={disableZoomIn}
                        onClick={onZoomIn}
                    />
                </ZoomControlWrapper>
            </ZoomControlsFooter>
        </ZoomControlsStyled>
    );
};

export default ZoomControls;
