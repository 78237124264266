import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const MediaTypeContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
        margin-right: ${token.spacing3};
    }
`;

export default MediaTypeContainer;
