import type { ICompModel } from '@bynder-studio/render-core';
import {
    CORNERS,
    HandlerInteractionStates,
    InteractionStates,
    ManipulableObjectParams,
    Position,
    ROTATIONS,
    SIDES,
} from '@bynder-studio/render-core';
import { FontFamily } from '@bynder-studio/structured-text';
import CanvasManipulationCompositor from './CanvasManipulationCompositor';
import BoundingBoxManipulation from './BoundingBox/BoundingBoxManipulation';
import ScrollbarsManipulation from './Scrollbars/ScrollbarsManipulation';
import SnapLinesManipulation from './SnapLines/SnapLinesManipulation';
import BaseManipulation from './BaseManipulation';
import ManipulationEmitter from './ManipulationEmitter';
import TextManipulation from './Text/TextManipulation';

class CanvasManipulation extends BaseManipulation {
    boundingBoxManipulation: BoundingBoxManipulation;

    scrollbarsManipulation: ScrollbarsManipulation;

    snapLinesManipulation: SnapLinesManipulation;

    textManipulation: TextManipulation;

    compModel: ICompModel = null;

    drawCursorRequestId: number = null;

    constructor(compositor: CanvasManipulationCompositor, containerDocument: Document, fontFamilies: FontFamily[]) {
        const emitter = new ManipulationEmitter();
        super(compositor, containerDocument, emitter);
        const paramsForManipulators = [compositor, containerDocument, this.emitter, this.redraw] as const;
        this.boundingBoxManipulation = new BoundingBoxManipulation(...paramsForManipulators);
        this.scrollbarsManipulation = new ScrollbarsManipulation(...paramsForManipulators);
        this.snapLinesManipulation = new SnapLinesManipulation(...paramsForManipulators);
        this.textManipulation = new TextManipulation(...paramsForManipulators, fontFamilies);
        containerDocument.addEventListener('mousemove', this.onMouseMove);
    }

    setPageInfo(pageIndex: number, selectedElIds: ManipulableObjectParams['id'][]) {
        this.compositor.selectedElIds = selectedElIds;
    }

    prepareCanvasForSizeChange() {
        this.textManipulation.prepareCanvasForSizeChange();
    }

    setPanMode(value: boolean) {
        this.compositor.isPanMode = value;
        this.boundingBoxManipulation.requestHoverElement(null);
        this.requestToChangeCursor(value ? 'grab' : '');
    }

    getPanMode() {
        return this.compositor.isPanMode;
    }

    setCompModel(compModel: ICompModel) {
        this.compModel = compModel;
        this.textManipulation.clearOffsetCache();
        const handleIndex = this.compositor.isResizing ? this.compositor.resizeHandleIndex : null;
        const handleState = this.compositor.isResizing ? HandlerInteractionStates.PRESSED : null;
        this.redraw(handleIndex, handleState, false);
    }

    activateTextEditor(params) {
        this.textManipulation.activateTextEditor(params);
    }

    disableTextEditor() {
        this.textManipulation.disableTextEditor();
    }

    selectElement(args: {
        selectedElements: number[] | [];
        topLevelSelectedQuantity?: number;
        currentSelectedGroupId?: number | null;
        currentParentId?: number | null;
    }) {
        this.textManipulation.selectElement(args);
        this.boundingBoxManipulation.selectElement(args);
        this.redraw(null, null, false);
    }

    hoverElement(elementId: number) {
        this.boundingBoxManipulation.hoverElement(elementId);
        this.redraw();
    }

    interactElement({ interactionState }: { elementId: number; interactionState: InteractionStates }) {
        this.boundingBoxManipulation.transformerBox.setTransformerMode(interactionState);

        if (interactionState === InteractionStates.DRAGGING) {
            return;
        }

        this.redraw(null, null, false);
    }

    requestToDrawCursor() {
        if (this.drawCursorRequestId) {
            cancelAnimationFrame(this.drawCursorRequestId);
        }

        this.drawCursorRequestId = requestAnimationFrame(() => {
            this.drawCursorRequestId = 0;
            this.textManipulation.requestToDraw();
        });
    }

    redraw = (handleIndex: CORNERS | SIDES = null, handleState: HandlerInteractionStates = null, withHover = true) => {
        this.compositor.requestToDrawCompModel(this.compModel);

        if (this.compositor.textEditorElement) {
            this.boundingBoxManipulation.requestToDrawTransformerBox(handleIndex, handleState);
            this.scrollbarsManipulation.requestToDrawScrollbars();
            this.requestToDrawCursor();

            return;
        }

        if (withHover) {
            this.boundingBoxManipulation.requestToDrawHoverBox();
        }

        this.boundingBoxManipulation.requestToDrawTransformerBox(handleIndex, handleState);
        this.scrollbarsManipulation.requestToDrawScrollbars();
        this.snapLinesManipulation.requestToDrawSnapLines();
    };

    moveElementWithKeys = (event) => {
        this.boundingBoxManipulation.moveElementWithKeys(event);
    };

    onPlayingStateChange = (isPlayingState) => {
        this.compositor.isPlaying = isPlayingState;

        if (isPlayingState) {
            this.boundingBoxManipulation.transformerBox = null;
        } else {
            this.selectElement({ selectedElements: this.compositor.selectedElIds });
        }
    };

    onMouseMove = (e: MouseEvent) => {
        this.detectCanvasCursor();
    };

    detectCanvasCursor() {
        if (this.compositor.isRotating || this.compositor.isResizing) {
            return;
        }

        if (this.compositor.isPanning && this.compositor.isPanMode) {
            this.requestToChangeCursor('grabbing');

            return;
        } else if (this.compositor.isPanMode) {
            this.requestToChangeCursor('grab');

            return;
        } else if (this.compositor.isDragging) {
            this.requestToChangeCursor('move');

            return;
        }

        const calculatedMousePosition: Position = this.compositor.getCalculatedMousePosition();
        let isMouseOverBox = false;
        let handleIndex = -1;

        if (
            !this.compositor.isResizing &&
            this.boundingBoxManipulation.transformerBox &&
            !this.compositor.overScrollBar &&
            calculatedMousePosition
        ) {
            handleIndex = this.boundingBoxManipulation.transformerBox.isMouseOverHandles(calculatedMousePosition);
            isMouseOverBox = this.boundingBoxManipulation.transformerBox.isMouseOverBox(calculatedMousePosition);
            this.compositor.resizeHandleIndex = handleIndex;
            this.redraw(handleIndex, HandlerInteractionStates.HOVER);
        }

        switch (handleIndex) {
            case CORNERS.LEFT_TOP:
            case CORNERS.RIGHT_BOTTOM:
                this.requestToChangeCursor('nwse-resize');
                break;

            case CORNERS.RIGHT_TOP:
            case CORNERS.LEFT_BOTTOM:
                this.requestToChangeCursor('nesw-resize');
                break;

            case SIDES.LEFT:
            case SIDES.RIGHT:
                this.requestToChangeCursor('ew-resize');
                break;

            case SIDES.UP:
            case SIDES.BOTTOM:
                this.requestToChangeCursor('ns-resize');
                break;
            case ROTATIONS.LEFT_TOP:
            case ROTATIONS.LEFT_BOTTOM:
            case ROTATIONS.RIGHT_TOP:
            case ROTATIONS.RIGHT_BOTTOM: {
                this.boundingBoxManipulation.changeCursorWhileRotating(handleIndex);
                break;
            }
            default:
                if (isMouseOverBox && this.compositor.textEditorElement) {
                    this.requestToChangeCursor('text');
                } else {
                    this.requestToChangeCursor(
                        isMouseOverBox || this.boundingBoxManipulation.hoverElId ? 'pointer' : '',
                    );
                }
        }
    }

    unsubscribe() {
        this.containerDocument.removeEventListener('mousemove', this.onMouseMove);
    }

    unsubscribeEvents() {
        this.unsubscribe();
        this.boundingBoxManipulation.unsubscribe();
        this.snapLinesManipulation.unsubscribe();
        this.scrollbarsManipulation.unsubscribe();
        this.textManipulation.unsubscribe();
    }
}

export default CanvasManipulation;
