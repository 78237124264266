import React, { FC } from 'react';
import { Thumbnail } from '@bynder/design-system';
import { IconEdit } from '@bynder/icons';
import { usePageIndex, useVariationThumbnail } from './useVariationThumbnail';
import { WarningWrapper } from './Variations.styled';
import { useVariationPageThumbnail } from 'packages/pages/design/VariationsContext/useVariationPageThumbnail';

export type VariationsThumbnailProps = {
    variationId: string;
    sizes: any;
    isInvalid: boolean;
    isCurrentVariation: boolean;
    oldVariationId?: string;
};

const VariationsThumbnail: FC<VariationsThumbnailProps> = ({
    variationId,
    oldVariationId,
    sizes,
    isInvalid,
    isCurrentVariation,
}) => {
    const { pageIndex } = usePageIndex();
    const { variationPageThumbnail, loading } = useVariationPageThumbnail(variationId, pageIndex, oldVariationId);
    const { containsTransparency } = useVariationThumbnail(sizes);

    return (
        <Thumbnail
            variant="content"
            imageUrl={variationPageThumbnail?.dataUrl}
            backgroundColor={`rgb(255 255 255 / ${containsTransparency ? '0' : '100'}%)`}
            size="m"
        >
            {!isInvalid && isCurrentVariation && (
                <Thumbnail.Overlay>
                    <IconEdit />
                </Thumbnail.Overlay>
            )}
            {/* TODO: Temporary wrapper until DS fix it */}
            {isInvalid ? <WarningWrapper type="error" /> : loading && <Thumbnail.Indicator type="loading" />}
        </Thumbnail>
    );
};

export default VariationsThumbnail;
