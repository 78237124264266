import React, { type FC } from 'react';
import { Form, Slider } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { FadeInput } from './FadeInput';

type AudioFeatureProps = {
    isDisabled: boolean;
    duration: number;
    volume: number;
    fadeIn: number;
    fadeOut: number;
    onFadeInChange: (fadeIn: number) => void;
    onFadeOutChange: (fadeOut: number) => void;
    onVolumeChange: (volume: number) => void;
};

export const AudioFeature: FC<AudioFeatureProps> = ({
    isDisabled,
    duration,
    volume,
    fadeIn,
    fadeOut,
    onFadeInChange,
    onFadeOutChange,
    onVolumeChange,
}) => {
    const { translate } = useTranslate();

    return (
        <>
            <Form.Group>
                <Form.Label id="audio-volume-label" htmlFor="audio-volume">
                    {translate('editor.sidebar.audio.volume.label')}
                </Form.Label>
                <Slider
                    min={-100}
                    max={100}
                    step="0.1"
                    value={volume}
                    onChange={onVolumeChange}
                    isDisabled={isDisabled}
                    isFromCenter
                    inputPosition="right"
                    aria-labelledby="audio-volume-label"
                />
            </Form.Group>
            <Form.Row>
                <Form.Group>
                    <Form.Label htmlFor="audio-fade-in">{translate('editor.sidebar.audio.fade.in.label')}</Form.Label>
                    <FadeInput
                        value={fadeIn}
                        max={Math.max(0, duration - fadeOut)}
                        id="audio-fade-in"
                        onChange={onFadeInChange}
                        isDisabled={isDisabled}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="audio-fade-out">{translate('editor.sidebar.audio.fade.out.label')}</Form.Label>
                    <FadeInput
                        value={fadeOut}
                        max={Math.max(0, duration - fadeIn)}
                        id="audio-fade-out"
                        onChange={onFadeOutChange}
                        isDisabled={isDisabled}
                    />
                </Form.Group>
            </Form.Row>
        </>
    );
};
