import React from 'react';
import { EditorSelectedElementProvider } from '~/contexts/EditorSelectedElementContext';
import RightSideMenu from '.';
import { InspectorPanel } from './container.styled';

const RightSideMenuContainer = () => (
    <InspectorPanel>
        <EditorSelectedElementProvider>
            <RightSideMenu />
        </EditorSelectedElementProvider>
    </InspectorPanel>
);

export default RightSideMenuContainer;
