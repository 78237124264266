import React from 'react';
import { EntityType } from 'packages/store/sharing/types';

export enum GroupAccessEntry {
    ALL_USERS = 'ALL_USERS',
    RESTRICTED = 'RESTRICTED',
}

export type GroupAccessDropdownItem = {
    key: GroupAccessEntry;
    icon: React.ReactElement;
    title: React.ReactElement;
    subtitle: React.ReactElement;
};

export type Props = {
    onSelect: Function;
    restriction: GroupAccessEntry;
    isDisabled: boolean;
    entityType: EntityType;
};
