export enum AnimationOutTypes {
    FADE_OUT = 'FADE_OUT',
    MOVE_OUT = 'MOVE_OUT',
    FADE_MOVE_OUT = 'FADE_MOVE_OUT',
    SCALE_OUT = 'SCALE_OUT',
    BLUR_OUT = 'BLUR_OUT',
    FADE_BLUR_OUT = 'FADE_BLUR_OUT',
    FADE_SCALE_OUT = 'FADE_SCALE_OUT',
    FLIP_OUT = 'FLIP_OUT',
    IRIS_OUT = 'IRIS_OUT',
    WIPE_OUT = 'WIPE_OUT',
    POP_OUT = 'POP_OUT',
}
