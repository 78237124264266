import React, { useMemo } from 'react';
import { InteractiveIcon } from '@bynder/design-system';
import { IconLockOpenSmall, IconLockSmall } from '@bynder/icons';
import { CustomFormLabel } from './index.styled';
import generateTestId from '~/helpers/testIdHelpers';

const FormLabelLock = ({ label, locked, toggleLock, htmlFor, isDisabled = false, testId = '' }) => {
    const lockTestId = useMemo(() => (testId ? generateTestId(`${testId}__lock`) : {}), [testId]);

    return (
        <CustomFormLabel htmlFor={htmlFor}>
            <div>{label}</div>
            <div>
                {locked ? (
                    <InteractiveIcon
                        onClick={() => toggleLock()}
                        icon={<IconLockSmall />}
                        label="Unlock"
                        isDisabled={isDisabled}
                        {...lockTestId}
                    />
                ) : (
                    <InteractiveIcon
                        onClick={() => toggleLock()}
                        icon={<IconLockOpenSmall />}
                        label="Lock"
                        isDisabled={isDisabled}
                        {...lockTestId}
                    />
                )}
            </div>
        </CustomFormLabel>
    );
};

export default FormLabelLock;
