import React from 'react';
import { Button, Dropdown, Flex, List, token } from '@bynder/design-system';
import { IconAddLink, IconCheck } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import MaybeTooltip from 'packages/common/MaybeTooltip';
import generateTestId from '~/helpers/testIdHelpers';
import { ContentPropertyProps } from './types';
import { FullWidthBox } from './ContentProperty.styled';
import { useContentProperty } from './useContentProperty';
import { useContentPropertyCreateModal } from './useContentPropertyCreateModal';
import ContextMenu from './components/ContextMenu';
import { useContentPropertyDeleteModal } from './useContentPropertyDeleteModal';
import { useContentPropertyRenameModal } from './useContentPropertyRenameModal';
import { useGenerateIcon } from './components/useGenerateIcon';
import { useContentPropertyListModal } from './useContentPropertyListModal';

const ContentProperty = ({ creativeModel, selectedElement }: ContentPropertyProps) => {
    const { translate } = useTranslate();
    const { locked, contentPropertyId } = selectedElement ?? {};
    const { generateIcon } = useGenerateIcon({});

    const {
        allTypesProperties,
        currentProperty,
        filteredProperties,
        searchValue,
        handleSearchChange,
        applyProperty,
        onCreate,
        onDetach,
        onRename,
        onDelete,
        onSave,
    } = useContentProperty(creativeModel, selectedElement);

    const { renderContentPropertyCreateModal, setIsContentPropertyCreateModalOpen } = useContentPropertyCreateModal({
        onCreate,
        elementName: selectedElement?.name ?? '',
    });
    const { renderContentPropertyRenameModal, setIsContentPropertyRenameModalOpen } = useContentPropertyRenameModal({
        onRename,
        propertyName: currentProperty?.name ?? '',
    });
    const { renderContentPropertyDeleteModal, setIsContentPropertyDeleteModalOpen } = useContentPropertyDeleteModal({
        onDelete,
    });
    const { renderContentPropertyListModal, setIsContentPropertyListModalOpen } = useContentPropertyListModal({
        creativeModel,
        properties: allTypesProperties,
        onSave,
    });

    return (
        <>
            <Flex alignItems="center">
                <FullWidthBox marginRight="6">
                    <Dropdown
                        minWidth="288px"
                        trigger={({ isOpen, ...triggerProps }) => (
                            <List.Item
                                rightElements={<Dropdown.Arrow />}
                                isDisabled={locked}
                                thumbnail={generateIcon(selectedElement, true)}
                                {...generateTestId('shots_content_property_open_list')}
                                {...{ id: 'shots_content_property_open_list' }}
                                {...triggerProps}
                            >
                                {contentPropertyId ? currentProperty?.name : selectedElement?.name ?? ''}
                            </List.Item>
                        )}
                    >
                        <Dropdown.SearchInput
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder={translate('editor.sidebar.shots.contentProperty.search.placeholder')}
                            aria-label={translate('editor.sidebar.shots.contentProperty.search.placeholder')}
                            {...generateTestId('shots_content_property_search')}
                            {...{ id: 'shots_content_property_search' }}
                        />
                        {!filteredProperties.length && (
                            <Dropdown.Text
                                {...generateTestId('shots_content_property_no_items')}
                                {...{ id: 'shots_content_property_no_items' }}
                            >
                                {translate('editor.sidebar.shots.contentProperty.noItems')}
                            </Dropdown.Text>
                        )}
                        {!!filteredProperties.length && (
                            <>
                                {filteredProperties.map((item) => (
                                    <Dropdown.ListItem
                                        key={item.uuid}
                                        isChecked={false}
                                        onClick={() => {
                                            if (item.uuid !== contentPropertyId) {
                                                applyProperty(item.uuid);
                                            }
                                        }}
                                        thumbnail={generateIcon(item, false)}
                                        rightElements={
                                            item.uuid === contentPropertyId ? (
                                                <IconCheck style={{ color: token.gray800 }} />
                                            ) : (
                                                <></>
                                            )
                                        }
                                        {...generateTestId('shots_content_property_item')}
                                        {...{ id: 'shots_content_property_item' }}
                                    >
                                        {item.name}
                                    </Dropdown.ListItem>
                                ))}
                            </>
                        )}
                        <Dropdown.Divider />
                        <Dropdown.Item
                            icon={<IconAddLink />}
                            onClick={() => setIsContentPropertyCreateModalOpen(true)}
                            {...generateTestId('shots_content_property_create_option')}
                            {...{ id: 'shots_content_property_create_option' }}
                        >
                            {translate('editor.sidebar.shots.contentProperty.createOption')}
                        </Dropdown.Item>
                    </Dropdown>
                </FullWidthBox>

                {!contentPropertyId ? (
                    <MaybeTooltip
                        hasTooltip={!locked}
                        position="top-left"
                        content={translate('editor.sidebar.shots.contentProperty.tooltip')}
                        {...generateTestId('shots_content_property_button_create_tooltip')}
                        {...{ id: 'shots_content_property_button_create_tooltip' }}
                    >
                        <Button
                            variant="clean"
                            title={translate('editor.sidebar.shots.contentProperty.tooltip')}
                            icon={<IconAddLink />}
                            onClick={() => setIsContentPropertyCreateModalOpen(true)}
                            {...generateTestId('shots_content_property_button_create')}
                            {...{ id: 'shots_content_property_button_create' }}
                        />
                    </MaybeTooltip>
                ) : (
                    <ContextMenu
                        selectedElement={selectedElement}
                        renameContentProperty={() => setIsContentPropertyRenameModalOpen(true)}
                        detachContentProperty={onDetach}
                        manageContentProperty={() => setIsContentPropertyListModalOpen(true)}
                        deleteContentProperty={() => setIsContentPropertyDeleteModalOpen(true)}
                    />
                )}
            </Flex>
            {renderContentPropertyRenameModal()}
            {renderContentPropertyDeleteModal()}
            {renderContentPropertyCreateModal()}
            {renderContentPropertyListModal()}
        </>
    );
};

export default ContentProperty;
