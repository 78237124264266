import { ColorParams } from '@bynder-studio/misc';

export type FontParams = {
    fontId: string | number;
    fontUrl?: string;
    fontSize: number;
    fontColor: ColorParams;
    lineHeight: number;
    charSpacing: number;
};

export class Font {
    fontId: string | number;
    fontSize: number;
    fontColor: ColorParams;
    lineHeight: number;
    charSpacing: number;

    constructor({ fontId, fontSize, fontColor, lineHeight, charSpacing }: FontParams) {
        this.fontId = fontId;
        this.fontSize = fontSize;
        this.fontColor = fontColor;
        this.lineHeight = lineHeight;
        this.charSpacing = charSpacing;
    }

    getFontId(): string | number {
        return this.fontId;
    }

    getFontSize(): number {
        return this.fontSize;
    }

    getFontColor(): ColorParams {
        return this.fontColor;
    }

    toObject(): FontParams {
        return {
            fontId: this.fontId,
            fontSize: this.fontSize,
            fontColor: this.fontColor,
            lineHeight: this.lineHeight,
            charSpacing: this.charSpacing,
        };
    }
}
