import React from 'react';
import { SecondaryText } from './List.styled';
import useExport from '../hooks/useExport';

export const SelectedVariations = ({ item }) => {
    const { selectedIds } = useExport();
    const count = item.entries.length;
    const selectedCount = item.entries.reduce((acc, entry) => acc + (selectedIds[entry.variationId] ? 1 : 0), 0);

    return (
        <SecondaryText>
            {selectedCount} of {count} variations selected
        </SecondaryText>
    );
};
