import { Button } from '@bynder/design-system';
import styled from 'styled-components';

export const DropdownButton = styled(Button)`
    justify-content: flex-start;

    & div:last-child {
        margin-left: auto;
    }
`;
