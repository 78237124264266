import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, Modal } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { setShowAssetSelection } from 'packages/store/creativeEditor/creativeEditor.actions';
import { showAssetSelectionSelector } from 'packages/store/creativeEditor/creativeEditor.selectors';
import { getElementPropertiesFromSource } from '~/common/editor/helpers';
import BrowseButton from '../../../FormComponents/BrowseButton';
import { ContentType } from '../../../FormComponents/BrowseButton/types';
import modalContainer from 'packages/common/modalContainer';

const BrowseVideo = ({ creativeModel, selectedElement, frameRate, disabled, id }) => {
    const { translate } = useTranslate();
    const showAssetSelection: boolean = useSelector(showAssetSelectionSelector);
    const { fileName, locked, duration, dimension, src, srcId, id: elementId, isAlpha } = selectedElement;

    const [showModal, setShowModal] = useState(false);

    const handleSelectSource = useCallback(
        (selectedSource) => {
            const resetDefault = srcId !== selectedSource.id;

            const params = getElementPropertiesFromSource(selectedElement, 'VIDEO', selectedSource);

            if (resetDefault) {
                // Reset to default values
                if (params.contentTransform) {
                    const { width: naturalWidth, height: naturalHeight } = params.naturalDimension;
                    const { width, height } = dimension;
                    const scale = +Math.min(width / naturalWidth, height / naturalHeight, 1).toFixed(2);
                    params.contentTransform.scale = scale;
                    params.contentTransform.horizontalOffset = Math.round(width / 2 - (naturalWidth * scale) / 2);
                    params.contentTransform.verticalOffset = Math.round(height / 2 - (naturalHeight * scale) / 2);
                } else {
                    const { type } = selectedElement.contentTransform;
                    params.contentTransform = {
                        verticalAlignment: 0,
                        horizontalAlignment: 0,
                        type,
                    };
                }
            }

            creativeModel.updateElement(elementId, params);
        },
        [creativeModel, dimension, elementId, selectedElement, srcId],
    );

    const confirmRemove = () => {
        handleSelectSource({ id: elementId });
        setShowModal(false);
    };

    const toggleLock = () => {
        creativeModel.updateElement(elementId, { locked: !locked });
    };

    return (
        <Form.Group>
            {src && <Form.Label>{translate('editor.sidebar.shots.video.tabs.content')}</Form.Label>}
            <Modal
                container={modalContainer}
                isOpen={showModal}
                title={translate('editor.sidebar.shots.video.modal.title')}
                onClose={() => setShowModal(false)}
                actionPrimary={
                    <Button variant="primary" onClick={confirmRemove}>
                        {translate('editor.sidebar.globals.audio.modal.footer')}
                    </Button>
                }
                actionSecondary={
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        {translate('editor.sidebar.globals.audio.modal.cancel')}
                    </Button>
                }
            >
                {translate('editor.sidebar.shots.video.modal.text')}
            </Modal>
            <BrowseButton
                id={id}
                label={translate('editor.sidebar.shots.video.asset.label')}
                htmlFor={id}
                placeholder={translate('editor.sidebar.shots.video.input.placeholder')}
                type={ContentType.VIDEO}
                frameRate={frameRate}
                duration={duration}
                value={fileName}
                selectedObject={{ element: selectedElement, duration }}
                toggleLock={toggleLock}
                locked={locked}
                disabled={locked || disabled}
                resizableRange
                testId="shots_video_asset"
                handleSelectSource={handleSelectSource}
                show={showAssetSelection}
                setShowAssetSelection={setShowAssetSelection}
                addOn={() => setShowModal(true)}
                previewURL={src}
                isAlpha={isAlpha}
                isEditorPage
            />
        </Form.Group>
    );
};

export default BrowseVideo;
