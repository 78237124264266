import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const TimelineOverflowOverlay = styled.div`
    position: absolute;
    // NOTE: Maximum zoom scale is not more than 4, so 10vw should be enough for all cases
    right: -1000vw;
    width: 1000vw;
    height: 100%;
    background: ${token.white};
    opacity: 0.5;
    pointer-events: none;
`;

export const TimelineResizingLine = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background-color: ${token.red500};
    opacity: 0;
    transition: opacity 0.2s ease-out;
    pointer-events: none;
`;

export const TimelineElementsContainer = styled.div`
    position: absolute;
    top: 0;
    left: 78px;
    width: calc(100% - 78px);
    height: 100%;
    border-left: 1px solid ${token.colorShadow};
    overflow: visible;

    &.dragging {
        pointer-events: none;
    }
`;

export const TimelineSnapLine = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background-color: ${token.red500};
    opacity: 0;
    transition: opacity 0.2s ease-out;
    pointer-events: none;
    z-index: 10002;
`;

export const TimelineElements = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const TimelineTracksList = styled.div`
    pointer-events: none;

    &.dragging {
        pointer-events: initial;
    }
`;

export const TimelineTracksWrapper = styled.div`
    position: absolute;
    width: 100%;
    min-height: 100%;
    grid-area: 2/1/3/3;

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: ${token.gray25a};
    }
`;

export const TimelineTimestampsContainer = styled.div`
    grid-area: 1/2/2/3;
    border-left: 1px solid ${token.colorShadow};
    overflow: hidden;
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
    background-image: linear-gradient(to right, #acacac 20%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 5px 1px;
    background-repeat: repeat-x;
`;

export const TimelineTracksContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    display: grid;
    grid-template-rows: 26px auto;
    grid-template-columns: 78px auto;

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: ${token.gray25a};
    }
`;
