import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const NotificationPopupContainer = styled.div`
    display: block;
    max-width: 500px;
    height: auto;
    top: 10px;
    left: 50%;
    transform: translateX(-50%) scale(0);
    transform-origin: top center;
    transition: transform 0.5s ease;
    border-radius: 0.3rem;

    &.active {
        transform: translateX(-50%) scale(1);
    }

    .modal-content {
        box-shadow: none;
        background: ${token.white};
        border-bottom-left-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
    }

    .modal-header {
        align-items: center;
        background: transparent;
        padding: 15px;
        box-shadow: 0 1px 6px -2px ${token.colorShadow};
        border-bottom: 1px solid ${token.gray200a};
    }

    .modal-footer,
    .modal-body {
        padding: 10px 15px;
    }

    .btn {
        padding: 8px 15px;
    }
`;
