import React from 'react';
import { Form } from '@bynder/design-system';
import FontFamily from './FontFamily';
import FontSize from './FontSize';
import FontStyle from './FontStyle';
import type { Props } from './types';

const Fonts = ({ creativeModel, selectedElement, disabled }: Props) => (
    <>
        <FontFamily selectedElement={selectedElement} creativeModel={creativeModel} disabled={disabled} />
        <Form.Row>
            <FontStyle selectedElement={selectedElement} creativeModel={creativeModel} disabled={disabled} />
            <FontSize selectedElement={selectedElement} creativeModel={creativeModel} disabled={disabled} />
        </Form.Row>
    </>
);

export default Fonts;
