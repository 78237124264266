import { useCallback, useEffect, useMemo, useState } from 'react';
import store from 'store2';

export default function useLocalStorage<T, TAll = Record<string, T>>(
    namespace: string,
    parser: (data: unknown) => TAll,
) {
    const storage = useMemo(() => store.namespace(`vbs-${namespace}`), [namespace]);

    const [values, setValues] = useState({} as TAll);

    const extractValues = useCallback(() => {
        const rawValues = storage.getAll({});
        const parsedValues = parser(rawValues);
        setValues(parsedValues);
    }, [storage, parser]);

    const setValue = useCallback(
        (key: string, value: T) => {
            storage.set(key, value);
            extractValues();
        },
        [extractValues, storage],
    );

    const removeValues = useCallback(
        (keys: string[]) => {
            keys.forEach((key) => storage.remove(key));
            extractValues();
        },
        [storage, extractValues],
    );

    const clear = useCallback(() => {
        storage.clear();
        extractValues();
    }, [storage, extractValues]);

    useEffect(() => {
        extractValues();
    }, [extractValues]);

    return {
        values,
        setValue,
        removeValues,
        clear,
    };
}
