import React from 'react';
import { IconDownload, IconUpload } from '@bynder/icons';
import { Tabs } from '../enums';
import { TabVal } from '../Modal/ExportModal.types';

type Props = {
    // TabVal for better autocomplite
    tab: TabVal | string;
};

function TabIcon({ tab }: Props) {
    switch (tab) {
        case Tabs.Download:
            return <IconDownload />;
        case Tabs.WaitingRoom:
            return <IconUpload />;
        default:
            return null;
    }
}

export default React.memo(TabIcon);
