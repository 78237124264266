import React, { useEffect, useMemo } from 'react';
import { IconCopy } from '@bynder/icons';
import useEditor from '../../../../../hooks/useEditor';
import useForceUpdate from '../../../../../hooks/useForceUpdate';
import generateTestId from '~/helpers/testIdHelpers';
import { TimelineActionsIcon } from '../TimelineActions.styled';
import useGeneralHotkeys from 'packages/pages/editor/useGeneralHotKeys';
import useElementGeneralActions from 'packages/pages/editor/useElementGeneralActions';

export default function DuplicateAction() {
    const { manipulationRenderer } = useEditor();
    const selectedElements = manipulationRenderer?.getSelectedElements() || [];
    const forceUpdate = useForceUpdate();
    const isActive = !!selectedElements.length;
    const { handleDuplicate } = useElementGeneralActions();

    useGeneralHotkeys({ handleDuplicate });

    const testId = useMemo(() => generateTestId('timeline_actions_duplicate'), []);

    useEffect(() => {
        if (!manipulationRenderer) {
            return;
        }

        manipulationRenderer.eventEmitter.on('elementSelected', forceUpdate);

        return () => manipulationRenderer.eventEmitter.off('elementSelected', forceUpdate);
    }, [forceUpdate, manipulationRenderer]);

    return (
        <div title="Duplicate">
            <TimelineActionsIcon onClick={handleDuplicate} active={isActive} {...testId}>
                <IconCopy />
            </TimelineActionsIcon>
        </div>
    );
}
