import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { getTemplates, getTemplatesActionProgress } from 'packages/store/templates/templates.selectors';
import { Template } from 'packages/store/templates/types';
import { deleteTemplates } from '~/store/templates/templates.actions';
import useAccessRights from 'packages/hooks/useAccessRights';
import { ActionStatus, Entity, TemplateAction } from 'packages/hooks/useAccessRights/types';
import { getCategory } from 'packages/store/categories/categories.selectors';
import { handleDeselectAll } from '~/store/creatives/creatives.actions';
import AccessNotificationModal from './accessNotificationModal';
import modalContainer from 'packages/common/modalContainer';

const useTemplateDeleteModal = (deleteCallback = () => {}) => {
    const [deleteModalTemplate, setDeleteModalTemplate] = useState<Template[] | null>(null);
    const { deleteInProgress } = useSelector(getTemplatesActionProgress);
    const category = useSelector(getCategory);
    const { checked: selectedTemplates } = useSelector(getTemplates);
    const { isEntityActionAllowed } = useAccessRights();

    const [accessNotificationModal, setAccessNotificationModal] = useState<
        { getAllowedToDeleteTemplateIds: number[] | undefined; deleteModalTemplate: Template[] | null } | undefined
    >(undefined);

    const isDeletingAllowed = (item: Template) => {
        const status = isEntityActionAllowed(TemplateAction.DELETE, Entity.TEMPLATE, item.grantedPermissions);
        return status !== ActionStatus.NOT_ALLOWED && status !== ActionStatus.DISABLED;
    };

    const getAllowedToDeleteTemplateIds = deleteModalTemplate?.reduce((acc: number[], template: Template) => {
        if (isDeletingAllowed(template)) {
            acc.push(template.id);
        }

        return acc;
    }, []);

    const { translate } = useTranslate();
    const dispatch = useDispatch();

    const showModal = deleteModalTemplate !== null;

    const getModalTitle = () => {
        if (deleteModalTemplate?.length === 1) {
            return deleteModalTemplate?.[0].name;
        }

        if (deleteModalTemplate?.length) {
            return translate('modal.template.delete.title', {
                count: deleteModalTemplate.length,
            });
        }

        if (accessNotificationModal && accessNotificationModal.deleteModalTemplate?.length) {
            return translate('modal.template.delete.title', {
                count: accessNotificationModal.deleteModalTemplate.length,
            });
        }

        return translate('modal.template.delete.title', { count: 0 });
    };

    const onDeleteTemplate = useCallback(() => {
        if (!getAllowedToDeleteTemplateIds?.length) {
            setAccessNotificationModal({ getAllowedToDeleteTemplateIds, deleteModalTemplate });
        } else {
            if (selectedTemplates.size && getAllowedToDeleteTemplateIds?.length !== selectedTemplates.size) {
                setAccessNotificationModal({ getAllowedToDeleteTemplateIds, deleteModalTemplate });
            }
            dispatch(deleteTemplates(getAllowedToDeleteTemplateIds, category?.id));
        }
        dispatch(handleDeselectAll());
        setDeleteModalTemplate(null);
        deleteCallback();
    }, [getAllowedToDeleteTemplateIds]);

    const renderModalTemplateDelete = () => (
        <>
            {!accessNotificationModal ? (
                <Modal
                    container={modalContainer}
                    title={getModalTitle()}
                    isOpen={showModal}
                    onClose={() => setDeleteModalTemplate(null)}
                    actionPrimary={
                        <Button
                            variant="primary"
                            onClick={onDeleteTemplate}
                            isLoading={deleteInProgress}
                            title={translate('modal.template.delete.actionButton', {
                                count: getAllowedToDeleteTemplateIds?.length || 1,
                            })}
                        >
                            {translate('modal.template.delete.actionButton', {
                                count: getAllowedToDeleteTemplateIds?.length || 1,
                            })}
                        </Button>
                    }
                    actionSecondary={
                        <Button
                            variant="secondary"
                            onClick={() => setDeleteModalTemplate(null)}
                            title={translate('modal.cancel.title')}
                        >
                            {translate('modal.cancel.title')}
                        </Button>
                    }
                >
                    {translate('modal.template.delete.info', { count: getAllowedToDeleteTemplateIds?.length || 1 })}
                </Modal>
            ) : (
                <AccessNotificationModal
                    title={getModalTitle()}
                    description={
                        !accessNotificationModal?.getAllowedToDeleteTemplateIds?.length
                            ? translate('modal.template.access.not-allowed')
                            : translate('modal.template.access.not-all', { action: 'delete' })
                    }
                    loading={deleteInProgress}
                    isOpen={!!accessNotificationModal}
                    toggleOpen={() => setAccessNotificationModal(undefined)}
                />
            )}
        </>
    );

    return {
        setDeleteModalTemplate,
        renderModalTemplateDelete,
    };
};

export default useTemplateDeleteModal;
