import React from 'react';
import { Button, Modal } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import modalContainer from 'packages/common/modalContainer';

const AccessNotificationModal = ({ title, description, loading, isOpen, toggleOpen }) => {
    const { translate } = useTranslate();

    return (
        <Modal
            container={modalContainer}
            title={title}
            isOpen={isOpen}
            onClose={toggleOpen}
            actionPrimary={
                <Button
                    variant="primary"
                    onClick={toggleOpen}
                    isDisabled={loading}
                    isLoading={loading}
                    data-testid="ok-button"
                >
                    {translate('modal.template.access.button')}
                </Button>
            }
        >
            {description}
        </Modal>
    );
};

export default AccessNotificationModal;
