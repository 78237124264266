import React, { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { deselectBlankType } from 'packages/store/newDesign/newDesign.actions';
import Blank from '../../components/Blank';
import { dropImportedTemplate } from 'packages/store/importTemplate/importTemplate.actions';
import { initializeTemplates } from '~/store/templates/templates.actions';
import { initializeGallery } from '~/store/gallery/gallery.actions';

function BlankPage() {
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        dispatch(dropImportedTemplate());
        dispatch(initializeTemplates());
        dispatch(initializeGallery());
        dispatch(deselectBlankType());
    }, [dispatch]);

    return <Blank />;
}

export default BlankPage;
