import React, { useCallback, useMemo } from 'react';
import { IconSelect } from '@bynder/icons';
import useEditor from '../../../../../hooks/useEditor';
import generateTestId from '~/helpers/testIdHelpers';
import { TimelineActionsIcon } from '../TimelineActions.styled';

export default function PointerAction() {
    const { manipulationRenderer } = useEditor();
    const testId = useMemo(() => generateTestId('timeline_actions_pointer'), []);

    const handleUnselect = useCallback(() => {
        manipulationRenderer.selectElement(null);
    }, [manipulationRenderer]);

    return (
        <div title="Select">
            <TimelineActionsIcon onClick={handleUnselect} active {...testId}>
                <IconSelect />
            </TimelineActionsIcon>
        </div>
    );
}
