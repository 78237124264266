import styled from 'styled-components';
import { Button, Flex, token } from '@bynder/design-system';

export const ZoomControlButton = styled(Button)`
    width: 40px;
    height: 32px;
`;

export const ZoomValue = styled(Button)`
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
`;

export const ZoomControlWrapper = styled(Flex)`
    padding: 4px;
`;

export const ZoomControlsStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ZoomControlsFooter = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
    box-shadow: ${token.elevationStatic}, ${token.elevation2};
    background-color: ${token.white};
    border-radius: 5px;
`;
