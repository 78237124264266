import { useCallback, useEffect } from 'react';
import { isInteractiveElement } from '@bynder-studio/misc';
import { isCtrlKey } from '~/helpers/hotKeys';
import useEditor from '~/hooks/useEditor';
import useForceUpdate from '~/hooks/useForceUpdate';

type Props = {
    handleDuplicate?: () => void;
    handleSelection?: () => void;
    handleGroup?: () => void;
    handleUnGroup?: () => void;
    handleDelete?: () => void;
    handleLock?: () => void;
    handleHide?: () => void;
};

export default function useGeneralHotkeys({
    handleDuplicate,
    handleSelection,
    handleUnGroup,
    handleGroup,
    handleDelete,
    handleLock,
    handleHide,
}: Props) {
    const { creativeModel, manipulationRenderer } = useEditor();
    const forceUpdate = useForceUpdate();

    const handleKeyDown = useCallback(
        (event) => {
            if (isInteractiveElement(event)) {
                return;
            }

            // Ctrl only
            if (isCtrlKey(event) && !event.shiftKey) {
                switch (event.code) {
                    case 'KeyA': {
                        event.preventDefault();

                        if (handleSelection) {
                            handleSelection();
                        }
                        break;
                    }
                    case 'KeyD': {
                        event.preventDefault();

                        if (handleDuplicate) {
                            handleDuplicate();
                        }
                        break;
                    }
                    case 'KeyG': {
                        event.preventDefault();

                        if (handleGroup) {
                            handleGroup();
                        }
                        break;
                    }
                    default:
                        break;
                }
            }

            // No Ctrl and no Shift
            if (!isCtrlKey(event) && !event.shiftKey) {
                switch (event.code) {
                    case 'Delete':
                    case 'Backspace':
                        event.preventDefault();

                        if (handleDelete) {
                            handleDelete();
                            forceUpdate();
                        }
                        break;
                    case 'Escape':
                        manipulationRenderer.selectElement(null);
                        break;
                    default:
                        break;
                }
            }

            if (isCtrlKey(event) && event.shiftKey) {
                switch (event.code) {
                    case 'KeyH': {
                        event.preventDefault();

                        if (handleHide) {
                            handleHide();
                            forceUpdate();
                        }
                        break;
                    }
                    case 'KeyL': {
                        event.preventDefault();

                        if (handleLock) {
                            handleLock();
                            forceUpdate();
                        }
                        break;
                    }
                    case 'KeyG': {
                        event.preventDefault();

                        if (handleUnGroup) {
                            handleUnGroup();
                        }
                        break;
                    }
                }
            }
        },
        [
            creativeModel,
            manipulationRenderer,
            handleDelete,
            handleDuplicate,
            handleGroup,
            handleSelection,
            handleUnGroup,
            forceUpdate,
        ],
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    return {};
}
