import * as types from '../types';

export const initialState = {
    user: null,
    companies: [],
    permissions: [],
    roles: [],
    accessibleFeatures: [],
    bynderScopes: [],
    resetRequestFail: null,
    updateFail: null,
    currentCompany: 0,
    timeZones: [],
    isActivating: false,
    isActivated: false,
    tokenValdiationStatus: 'REQUEST', // REQUEST || SUCCESS || FAIILURE
    groups: [],
    fonts: [],
    elementsFonts: [],
    splitFeatures: {},
};
export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case types.USER_SET_DEFAULTS:
            return {
                ...initialState,
            };
        case types.SELECT_COMPANY_REQUEST:
            return {
                ...state,
                currentCompany: 0,
            };
        case types.SELECT_COMPANY_SUCCESS:
            return {
                ...state,
                currentCompany: action.company,
            };
        case types.GET_GROUPS_REQUEST:
        case types.GET_GROUPS_FAILURE:
            return {
                ...state,
                groups: [],
            };
        case types.GET_GROUPS_SUCCESS:
            return {
                ...state,
                groups: action.groups,
            };
        case types.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                updateSuccess: true,
                user: { ...state.user, ...action.user },
            };
        case types.SET_UPDATE_USER_SUCCESS:
            return {
                ...state,
                updateSuccess: action.success,
                updateFail: action.fail,
            };
        case types.UPDATE_PROFILE_FAIL_PASS:
            return {
                ...state,
                updateSuccess: false,
                updateFail: { msg: 'Wrong password' },
            };
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                loggingIn: false,
                user: action.user,
                currentCompany: action.currentCompany || state.currentCompany,
                companies: action.companies,
                permissions: action.permissions,
                roles: action.roles,
                accessibleFeatures: action.accessibleFeatures,
                bynderScopes: action.bynderScopes,
                lastLoginFail: false,
            };
        case types.GET_FONTS_REQUEST:
            return {
                ...state,
                fonts: [],
                elementsFonts: [],
            };
        case types.GET_FONTS_SUCCESS:
            return {
                ...state,
                fonts: action.items.fonts,
                elementsFonts: action.items.elementsFonts,
            };
        case types.SET_SPLIT_FEATURES:
            return {
                ...state,
                splitFeatures: { ...state.splitFeatures, [action.featureName]: action.featureValue },
            };
        default:
            return state;
    }
}
