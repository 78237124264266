import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useResizing } from '../tracks/dragging/useResizing';
import { useZoomDragging, useZoomHandleResize } from './zooming';
import useEditor from '../../../../hooks/useEditor';
import useForceUpdate from '../../../../hooks/useForceUpdate';
import useEditorTimeframe from '../../../../hooks/useEditorTimeframe';
import { ZoomHandle, ZoomControlBox, ZoomContainer } from './ZoomControl.styled';

export const ZoomControl = ({ elementContainerRef, shotsContainerRef, timestampsContainerRef, onResizeEnd }) => {
    const { creativeModel } = useEditor();
    const { duration, timelineDuration } = useEditorTimeframe(creativeModel);
    const forceUpdate = useForceUpdate();
    const data = useRef({ elements: [] });
    const controlRef = useRef(null);
    const containerRef = useRef(null);
    const [left, setLeft] = useState(0);
    const [width, setWidth] = useState(100);
    const style = useMemo(() => ({ left: `${left}%`, width: `${width}%` }), [left, width]);
    const { elements } = data.current;

    const updateElement = useCallback(() => {
        if (!creativeModel) {
            return;
        }

        data.current.elements = creativeModel.getElements().slice();
        forceUpdate();
    }, [creativeModel, forceUpdate]);

    useEffect(() => {
        updateElement();

        creativeModel.on('elementsTreeUpdated', updateElement);
        return () => creativeModel.off('elementsTreeUpdated', updateElement);
    }, [creativeModel, updateElement]);

    useEffect(() => {
        updateElement();
    }, [duration, timelineDuration]);

    const resizeEnd = useCallback(
        (leftPercentage, widthPercentage) => {
            onResizeEnd(leftPercentage, widthPercentage);
            setLeft(leftPercentage);
            setWidth(widthPercentage);
        },
        [onResizeEnd],
    );

    const dragEnd = useCallback((leftPercentage) => {
        setLeft(leftPercentage);
    }, []);

    const [onResize, onDoubleClick] = useZoomHandleResize(
        elements,
        controlRef,
        elementContainerRef,
        shotsContainerRef,
        timestampsContainerRef,
        duration,
    );

    const [onHandleMouseDown] = useResizing(controlRef, containerRef, onResize, resizeEnd, null, {
        minLeft: 20,
        minRight: 20,
    });

    const onControlMouseDown = useZoomDragging(
        controlRef,
        containerRef,
        elementContainerRef,
        shotsContainerRef,
        timestampsContainerRef,
        dragEnd,
        duration,
    );

    return (
        <ZoomContainer ref={containerRef}>
            <ZoomControlBox
                style={style}
                ref={controlRef}
                onMouseDown={onControlMouseDown}
                onDoubleClick={onDoubleClick}
            >
                <ZoomHandle className="--scalable" onMouseDown={(evt) => onHandleMouseDown(evt, 'left')} />
                <ZoomHandle className="--scalable" onMouseDown={(evt) => onHandleMouseDown(evt, 'right')} />
            </ZoomControlBox>
        </ZoomContainer>
    );
};
