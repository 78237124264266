import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button, Form, Dropdown, Flex } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { catchResponseError } from 'packages/helpers/helpers';
import VariationSetsService from '~/services/VariationSetsService';
import { sendAmplitudeVariationEvent } from '~/store/amplitude/actions';
import { AMPLITUDE_TYPES } from '~/store/amplitude/constants';
import { FileType } from '~/store/amplitude/types';
import { CleanCheckbox, FileFormatButton } from './Download.styled';
import useDesign from '../../hooks/useDesign';
import useVariations from '../../hooks/useVariations';
import modalContainer from 'packages/common/modalContainer';

const options: { value: string; label: string }[] = [
    { value: 'EXCEL', label: '.XLSX' },
    { value: 'CSV', label: '.CSV' },
];

type DownloadModalRef = {
    open: () => void;
};

type DownloadModalProps = {};

export const DownloadModal = forwardRef<DownloadModalRef, DownloadModalProps>((props, ref) => {
    const dispatch = useDispatch();
    const { creativeId } = useDesign();
    const { variations, saveVariation, currentVariation } = useVariations();
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [fileType, setFileType] = useState<string>(() => options[0].value);
    const [includeData, setIncludeData] = useState<boolean>(false);
    const selectedFormat = useMemo(() => options.find((optipn) => optipn.value === fileType), [fileType]);
    const { translate } = useTranslate();

    useImperativeHandle(ref, () => ({
        open() {
            setFileType(options[0].value);
            setIncludeData(false);
            setOpen(true);
        },
    }));

    const close = useCallback(() => {
        if (loading) {
            return;
        }

        setOpen(false);
    }, [loading]);

    const handleDownload = useCallback(async () => {
        setLoading(true);

        const variationSetIds: string[] = includeData ? variations.map((variationSet) => variationSet.id) : [];

        const currentVariationSetIdx: number = variations.findIndex(
            (variationSet) => variationSet.id === currentVariation,
        );
        const { hasUnsavedChanges } = variations[currentVariationSetIdx] || { hasUnsavedChanges: true };

        if (includeData && hasUnsavedChanges) {
            await saveVariation(currentVariation);
            variationSetIds[currentVariationSetIdx] = variations[currentVariationSetIdx].id;
        }

        const fileTypeForAnalytics = options.find((o) => o.value === fileType)!.label.slice(1) as FileType;
        dispatch(
            sendAmplitudeVariationEvent({
                eventType: AMPLITUDE_TYPES.DOWNLOAD_VARIATION,
                additionalProps: {
                    variationAmount: variationSetIds.length || 1,
                    fileType: fileTypeForAnalytics,
                },
            }),
        );

        VariationSetsService.batchDownload(creativeId, fileType, variationSetIds)
            .then(() => {
                setOpen(false);
            })
            .catch((err) => {
                setOpen(false);
                catchResponseError(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [creativeId, fileType, includeData, variations]);

    const handleIncludeDataChange = useCallback(() => {
        setIncludeData((i) => !i);
    }, []);

    return (
        <Modal
            container={modalContainer}
            title={translate('content.congigurator.variations.modal.download.title')}
            onClose={close}
            isOpen={open}
            actionSecondary={
                <Button variant="secondary" onClick={close} isDisabled={loading}>
                    {translate('content.congigurator.variations.modal.download.cancel')}
                </Button>
            }
            actionPrimary={
                <Button variant="primary" onClick={handleDownload} isLoading={loading} isDisabled={loading}>
                    {loading
                        ? translate('content.congigurator.variations.modal.download.preparing')
                        : translate('content.congigurator.variations.modal.download.download')}
                </Button>
            }
        >
            <Form>
                <Form.Group>
                    <Form.Label>{translate('content.congigurator.variations.modal.download.format')}</Form.Label>
                    <Flex gap="3" direction="column">
                        <Dropdown
                            trigger={({ isOpen, ...triggerProps }) => (
                                <FileFormatButton
                                    isFullWidth
                                    isDisabled={loading}
                                    isPressed={isOpen}
                                    rightIcon={<Dropdown.Arrow />}
                                    {...triggerProps}
                                >
                                    {selectedFormat?.label}
                                </FileFormatButton>
                            )}
                            position="bottom"
                        >
                            {options.map((option) => (
                                <Dropdown.Item
                                    key={option.value}
                                    onClick={() => setFileType(option.value)}
                                    isChecked={option.value === fileType}
                                >
                                    {option.label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown>
                        <CleanCheckbox isChecked={includeData} isDisabled={loading} onChange={handleIncludeDataChange}>
                            {translate('content.congigurator.variations.modal.download.options')}
                        </CleanCheckbox>
                    </Flex>
                </Form.Group>
            </Form>
        </Modal>
    );
});
