import styled from 'styled-components';
import { Card, Input } from '@bynder/design-system';

export const BrowseInput = styled(Input)`
    input {
        width: 154px !important;
        box-sizing: border-box;
    }
`;

export const CardFrame = styled(Card.Frame)<{ isDisabled?: boolean }>`
    opacity: ${({ isDisabled }) => (isDisabled ? 0.65 : 'unset')};
`;
