import React from 'react';
import { Table } from '@bynder/design-system';
import sortIcon from 'packages/pages/components/list/sortIcon';
import { OrderFilterType, SortingFilterType } from 'packages/pages/components/filters/components/Ordering/types';
import { Creative } from 'packages/store/creatives/types';
import { ListViewHeader } from '../types';

const renderHeaders = (
    headers: ListViewHeader[],
    isLoading: boolean,
    sort: [SortingFilterType, OrderFilterType],
    onSort: (sortBy: SortingFilterType, sortOrder: OrderFilterType) => void,
    designs?: Creative[],
) => {
    const [sortBy, sortingOrder] = sort;

    return headers.map((header) => {
        if (
            (header.id === 'date_created' && sortBy === 'date_updated') ||
            (header.id === 'date_updated' && sortBy !== 'date_updated')
        ) {
            return null;
        }

        if (designs && designs.length > 0 && header.id === 'checkboxes') {
            return <Table.HeadCell key={header.id} />;
        }

        if (header.notSortable) {
            return <Table.HeadCell key={header.id}>{header.title}</Table.HeadCell>;
        }

        return (
            <Table.HeadCell
                isActive={sortBy === header.id}
                sortIcon={sortIcon(isLoading, sortingOrder)}
                onClick={() => {
                    if (sortBy === header.id) {
                        const newSortOrder =
                            sortingOrder === OrderFilterType.DESCENDING
                                ? OrderFilterType.ASCENDING
                                : OrderFilterType.DESCENDING;
                        onSort(header.id as SortingFilterType, newSortOrder);
                    } else {
                        onSort(header.id as SortingFilterType, sortingOrder);
                    }
                }}
                key={header.id}
            >
                {header.title}
            </Table.HeadCell>
        );
    });
};

export default renderHeaders;
