import React, { useState } from 'react';
import ManageStyleModal from './ManageStyleModal';

const useManageStyleModal = (props) => {
    const [isManageStyleModal, setManageStyleModalOpen] = useState(false);

    const onClose = () => {
        setManageStyleModalOpen(false);
    };

    const renderManageStyleModal = () => <ManageStyleModal isOpen={isManageStyleModal} onClose={onClose} {...props} />;

    return {
        setManageStyleModalOpen,
        renderManageStyleModal,
    };
};

export default useManageStyleModal;
