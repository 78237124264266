import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Badge, Box, Button, Flex } from '@bynder/design-system';
import { IconArrowLeft } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { getGeneral } from 'packages/store/general/general.selectors';
import { getCreative } from 'packages/store/creatives/creatives.selectors';
import generateTestId from '~/helpers/testIdHelpers';
import useEditor from '~/hooks/useEditor';
import { PublicStatus, Title, TitleWrapper } from './Header.styled';
import PublishButton, { Props as PublishProps } from './PublishButton';
import OptionsDropdown, { Props as OptionsProps } from './OptionsDropdown';
import ContentView from './ContentView';
import { DiscardChangesClickSource, ToggleShowUnpublishedChanges } from './types';

type Props = {
    controlsProps: any;
    template: any;
    isInAction: boolean;
    saved: boolean;
} & PublishProps &
    OptionsProps;

const backButtonId = generateTestId('editor_header_back_button');
const titleId = generateTestId('editor_header_title');
const titleTextId = generateTestId('editor_header_title_text');
const titleDetailsId = generateTestId('editor_header_title_details');
const titleBadgeTestId = generateTestId('editor_header_title_badge');

const Header = (props: Props) => {
    const { template, isInAction, saved } = props;
    const { translate } = useTranslate();
    const navigate = useNavigate();
    const location = useLocation();
    const { canUndo, type, creativeModel, isPublishing } = useEditor();
    const { currentPage } = useSelector(getGeneral);
    const design = useSelector(getCreative);
    const isPublishedAndUpToDate = template?.lastPublishDate && template?.status === 'PUBLISHED';
    const isPublishedAtAll = template?.lastPublishDate;
    const modelsMeta = creativeModel.getModelsMetaData();
    const isTemplate = type === 'TEMPLATE';

    const hasUnsavedPages = () => {
        if (modelsMeta.length !== template.pages.length) {
            return true;
        }

        return modelsMeta.some((page) => page.name !== template.pages.find((p) => p.id === page.id)?.name);
    };

    const hasChanges = !isInAction && !saved && (canUndo || hasUnsavedPages());
    const discardChangesClickSource = useRef<DiscardChangesClickSource>('publish');

    const [showUnpublishedChangesModal, toggleShowUnpublishedChangesModal] = useState(false);

    const navigateBack = () => {
        if (currentPage && currentPage.back) {
            navigate(currentPage.back);

            return;
        }

        navigate(isTemplate ? '/categories' : '/designs');
    };

    const onClickBack = () => {
        if (hasChanges) {
            toggleShowUnpublishedChangesModal(true);

            return;
        }

        navigateBack();
    };

    const navigateToCC = () => {
        const designPath = location.pathname.replace('/edit', '');
        navigate(designPath);
    };

    const getStatus = () => {
        if (hasChanges) {
            return translate('editor.header.status.changes');
        }

        if (isPublishedAndUpToDate && !isInAction) {
            return translate('editor.header.status.published');
        }

        if (isPublishedAtAll) {
            return translate('editor.header.status.changes');
        }

        return translate('editor.header.status.unpublished');
    };

    const toggleShowUnpublishedChanges: ToggleShowUnpublishedChanges = (isOpen, source) => {
        discardChangesClickSource.current = source;
        toggleShowUnpublishedChangesModal(isOpen);
    };

    const discardUnpublishedChanges = () => {
        toggleShowUnpublishedChangesModal(false);

        switch (discardChangesClickSource.current) {
            case 'publish':
                navigateBack();
                break;
            case 'preview':
                navigateToCC();
                break;
            default:
        }
    };

    return (
        <Box marginBottom="6">
            <Flex className="app--header" alignItems="center" justifyContent="space-between" gap="4">
                <Button
                    variant="clean"
                    icon={<IconArrowLeft />}
                    aria-label={translate('editor.header.back')}
                    onClick={onClickBack}
                    isDisabled={isPublishing}
                    {...backButtonId}
                    {...{ id: 'editor_header_back_button' }}
                />
                <Flex {...titleId} {...{ id: 'editor_header_title' }}>
                    <div>
                        <TitleWrapper gap="3">
                            <Title {...titleTextId} {...{ id: 'editor_header_title_text' }}>
                                {design?.name}
                            </Title>
                            {isTemplate && (
                                <Badge {...titleBadgeTestId} isSecondary>
                                    {translate('editor.header.badge.template')}
                                </Badge>
                            )}
                        </TitleWrapper>
                        <PublicStatus {...titleDetailsId} {...{ id: 'editor_header_title_details' }}>
                            {getStatus()}
                        </PublicStatus>
                    </div>
                </Flex>
                <OptionsDropdown {...props} />
                {!isTemplate && (
                    <ContentView
                        {...props}
                        navigateToCC={navigateToCC}
                        showUnpublishedChangesModal={showUnpublishedChangesModal}
                        toggleShowUnpublishedChanges={toggleShowUnpublishedChanges}
                    />
                )}
                <PublishButton
                    {...props}
                    showUnpublishedChangesModal={showUnpublishedChangesModal}
                    toggleShowUnpublishedChanges={toggleShowUnpublishedChanges}
                    discardUnpublishedChanges={discardUnpublishedChanges}
                    discardChangesClickSource={discardChangesClickSource}
                />
            </Flex>
        </Box>
    );
};

export default Header;
