import React from 'react';
import { Thumbnail } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconError } from '@bynder/icons';
import { useFriendlyTime } from '~/hooks/useFriendlyTime';
import {
    Created,
    Name,
    VariationItemContainer,
    VariationItemData,
    VariationThumbnailWrapper,
} from './VariationItem.styled';
import { useVariationPageThumbnail } from 'packages/pages/design/VariationsContext/useVariationPageThumbnail';

const VariationItem = ({
    id,
    variationSetId,
    name,
    created,
    updated,
    isInvalid,
    containsTransparency,
    entries,
    pageIndex = 0,
}) => {
    const { variationPageThumbnail, loading } = useVariationPageThumbnail(id || variationSetId, pageIndex);
    const { getFriendlyTime } = useFriendlyTime();
    const { translate } = useTranslate();
    const isContainsTransparency = entries
        ? entries?.every((variation) => variation.containsTransparency)
        : containsTransparency;

    const isUpdated = new Date(updated).getTime() > new Date(created).getTime();

    return (
        <VariationItemContainer gap="4">
            <VariationThumbnailWrapper>
                <Thumbnail
                    size="m"
                    variant="content"
                    icon=""
                    backgroundColor={`rgb(255 255 255 / ${isContainsTransparency ? '0' : '100'}%)`}
                    imageUrl={variationPageThumbnail?.dataUrl}
                >
                    {isInvalid ? (
                        <Thumbnail.Indicator type="error" icon={<IconError />} />
                    ) : (
                        loading && <Thumbnail.Indicator type="loading" />
                    )}
                </Thumbnail>
            </VariationThumbnailWrapper>
            <VariationItemData direction="column">
                <Name>{name}</Name>
                <Created>
                    {isUpdated
                        ? translate('modal.export.variation.edited', { time: getFriendlyTime(updated) })
                        : translate('modal.export.variation.created', { time: getFriendlyTime(created) })}
                </Created>
            </VariationItemData>
        </VariationItemContainer>
    );
};

export default VariationItem;
