import { BlankItem, MediaTypeSelection } from 'packages/pages/designCreateModal/components/Blank/types';
import { DesignSubPages } from 'packages/pages/designCreateModal/components/Sidebar/types';
import { NewDesignState } from './types';

export const getNewDesignPage = (state: any): DesignSubPages => state.newDesign.activePage || DesignSubPages.ALL;
export const getNewDesignPageHistory = (state: any): DesignSubPages => state.newDesign.history;

export const getSelectedBlankType = (state: any): MediaTypeSelection => state.newDesign.blank.selectedType;
export const getSelectedBlankItems = (state: any): BlankItem[] => [
    state.newDesign.blank[MediaTypeSelection.VIDEO],
    state.newDesign.blank[MediaTypeSelection.IMAGE],
];

export const getBlankDesignActionProgress = (
    state: any,
): {
    addingInProgress: NewDesignState['addingInProgress'];
} => ({
    addingInProgress: state.newDesign.addingInProgress,
});
