import { useEffect } from 'react';
import { AssetGroup } from 'packages/pages/editor/ContentPickerModal/types';

// TODO: Bycicle for normalize scroll in modal - waiting for DS team to fix modal
// containers. Both of them should have 100% height!! Delete ASAP DS will fix this!
const useHeightHack = ({
    assetType,
    wrapperRef,
}: {
    assetType: AssetGroup;
    wrapperRef: { current: HTMLElement | null };
}) => {
    useEffect(() => {
        setTimeout(() => {
            requestAnimationFrame(() => {
                if (!wrapperRef.current) {
                    return;
                }

                const parent = wrapperRef.current.parentElement;
                const parentWrapper = parent?.parentElement;

                if (!parent || !parentWrapper) {
                    return;
                }

                if (assetType === 'GLOBAL') {
                    parent.style.height = 'calc(100% + 16px)';
                    parentWrapper.style.paddingBottom = '0';
                } else {
                    parent.style.height = '100%';
                }

                parentWrapper.classList.add('no_after');
                parentWrapper.style.overflowY = 'hidden';
            });
        }, 0);
    });
};

export default useHeightHack;
