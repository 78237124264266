// Entity Level
export enum Entity {
    PROJECT = 'PROJECT',
    DESIGN = 'DESIGN',
    VARIATION = 'VARIATION',
    EXPORT = 'EXPORT',
    TEMPLATE = 'TEMPLATE',
    GALLERY = 'GALLERY',
    CATEGORY = 'CATEGORY',
}

export enum IdentityRole {
    OWNER = 'OWNER',
    CONTRIBUTOR = 'CONTRIBUTOR',
    CAN_USE = 'CAN_USE',
    NO_ROLE = 'NO_ROLE',
    REVIEWER = 'REVIEWER',
    OWNER_REVIEWER = 'OWNER_REVIEWER',
    CONTRIBUTOR_REVIEWER = 'CONTRIBUTOR_REVIEWER',
}

export enum ProjectAction {
    LIST = 'LIST',
    VIEW = 'VIEW',
    RENAME = 'RENAME',
    DELETE = 'DELETE',
    VIEW_PERMISSIONS = 'VIEW_PERMISSIONS',
    UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS',
    TRANSFER_OWNERSHIP = 'TRANSFER_OWNERSHIP',
}

export enum DesignAction {
    LIST = 'LIST',
    VIEW = 'VIEW',
    RENAME = 'RENAME',
    EDIT = 'EDIT',
    MOVE = 'MOVE',
    MANAGE_SIZES = 'MANAGE_SIZES',
    DELETE = 'DELETE',
    DUPLICATE = 'DUPLICATE',
    VIEW_PERMISSIONS = 'VIEW_PERMISSIONS',
    UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS',
    TRANSFER_OWNERSHIP = 'TRANSFER_OWNERSHIP',
    ADD_REVIEW = 'ADD_REVIEW',
    CANCEL_REVIEW = 'CANCEL_REVIEW',
    REQUEST_REVIEW = 'REQUEST_REVIEW',
}

enum VariationAction {
    CREATE = 'CREATE',
    EDIT = 'EDIT',
    MANAGE_SIZES = 'MANAGE_SIZES',
    DELETE = 'DELETE',
    DUPLICATE = 'DUPLICATE',
    EXPORT_DATA = 'EXPORT_DATA',
    IMPORT_DATA = 'IMPORT_DATA',
    EXPORT = 'EXPORT',
}

enum ExportAction {
    DOWNLOAD = 'DOWNLOAD',
    PUSH_TO_WAITING_ROOM = 'PUSH_TO_WAITING_ROOM',
}

export enum TemplateAction {
    LIST = 'LIST',
    CREATE = 'CREATE',
    RENAME = 'RENAME',
    EDIT = 'EDIT',
    DELETE = 'DELETE',
    VIEW_PERMISSIONS = 'VIEW_PERMISSIONS',
    UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS',
    TRANSFER_OWNERSHIP = 'TRANSFER_OWNERSHIP',
    MOVE = 'MOVE',
    MOVE_IN_MODAL = 'MOVE_IN_MODAL',
    REQUEST_REVIEW = 'REQUEST_REVIEW',
}

enum GalleryAction {
    LIST = 'LIST',
}

export type EntityLevelActions =
    | ProjectAction
    | DesignAction
    | VariationAction
    | ExportAction
    | TemplateAction
    | GalleryAction
    | CategoryAction;

export enum ActionStatus {
    ALLOWED = 'ALLOWED',
    DISABLED = 'DISABLED',
    NOT_ALLOWED = 'NOT_ALLOWED',
}

// Account Level
export enum Account {
    GENERAL = 'GENERAL',
    ASSETS = 'ASSETS',
    LOCAL_ASSETS = 'LOCAL_ASSETS',
    SETTINGS = 'SETTINGS',
    COLOR_PALETTES = 'COLOR_PALETTES',
    BRAND_COLORS = 'BRAND_COLORS',
}

export enum GeneralAction {
    CREATE_PROJECT = 'CREATE_PROJECT',
    CREATE_DESIGN_FROM_TEMPLATE = 'CREATE_DESIGN_FROM_TEMPLATE',
    CREATE_DESIGN_FROM_SCRATCH = 'CREATE_DESIGN_FROM_SCRATCH',
    CREATE_DESIGN_FROM_IMPORT_XML = 'CREATE_DESIGN_FROM_IMPORT_XML',
    CREATE_CATEGORY = 'CREATE_CATEGORY',
}

export enum AssetsAction {
    LOCAL = 'LOCAL',
    BYNDER = 'BYNDER',
}

export enum LocalAssetsAction {
    MANAGE = 'MANAGE',
}

export enum SettingsAction {
    EDIT_TIMEZONE = 'EDIT_TIMEZONE',
    CONNECTION = 'CONNECTION',
}

export enum ColorPaletteAction {
    CREATE_COLOR_PALETTE = 'CREATE_COLOR_PALETTE',
    EDIT_COLOR_PALETTE = 'EDIT_COLOR_PALETTE',
    DELETE_COLOR_PALETTE = 'DELETE_COLOR_PALETTE',
}

export enum BrandColorsAction {
    ADD_COLOR = 'ADD_COLOR',
    EDIT_COLOR = 'EDIT_COLOR',
    DELETE_COLOR = 'DELETE_COLOR',
}

export enum CategoryAction {
    LIST = 'LIST',
    VIEW = 'VIEW',
    RENAME = 'RENAME',
    DELETE = 'DELETE',
    VIEW_PERMISSIONS = 'VIEW_PERMISSIONS',
    UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS',
    TRANSFER_OWNERSHIP = 'TRANSFER_OWNERSHIP',
}

export enum AccountType {
    BASIC = 'BASIC',
    BASIC_IMAGE_ONLY = 'BASIC_IMAGE_ONLY',
    BASIC_VBS_LEGACY = 'BASIC_VBS_LEGACY',
    ADVANCED = 'ADVANCED',
    ADVANCED_IMAGE_ONLY = 'ADVANCED_IMAGE_ONLY',
}

export type AccountLevelActions =
    | GeneralAction
    | AssetsAction
    | LocalAssetsAction
    | SettingsAction
    | ColorPaletteAction
    | BrandColorsAction;
