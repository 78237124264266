import React from 'react';
import { Table, Button } from '@bynder/design-system';
import { OrderFilterType } from '../../filters/components/Ordering/types';

const sortIcon = (isLoading: boolean, sortingOrder: string) => {
    if (isLoading) {
        return <Button isLoading variant="clean" aria-label="Loading..." />;
    }

    if (sortingOrder === OrderFilterType.ASCENDING) {
        return <Table.IconUp />;
    }

    if (sortingOrder === OrderFilterType.DESCENDING) {
        return <Table.IconDown />;
    }

    return <></>;
};

export default sortIcon;
