import { SocketSubscribeConfig } from '../socketSubscribe';

type Options = SocketSubscribeConfig<
    {
        items: {
            id: number;
            status: string;
            progress: number;
            errorMessage?: string;
            designCreationRequestItems: any[];
            designCreationData: {
                designCreationResponses: any[];
            };
        }[];
    },
    {
        designCreationRequestIds: number[];
    }
>;
type Params = {
    customerId: number;
    designCreationRequestIds: Options['publishBody']['designCreationRequestIds'];
    onMessage: Options['onMessage'];
};

export function subscribeDesignCreationProgressOptions({
    customerId,
    designCreationRequestIds,
    onMessage,
}: Params): Options {
    return {
        destination: `design-creation-requests.${customerId}.progresses`,
        publishBody: {
            designCreationRequestIds,
        },
        onMessage,
    };
}
