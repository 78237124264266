import { TextTransform } from '@bynder-studio/misc';
import bidiFactory from 'bidi-js';
import {
    type IStructuredGlyph,
    type LayoutRun,
    type LayoutRuns,
    type RunProps,
    type ShapedLine,
    type ShapedLineMetrics,
    type TextProps,
    type TextSegment,
} from './StructuredText';
import { cloneObj } from '../Helpers/utils';
import { Direction } from './Types';

const bidi = bidiFactory();

export const getRunsForExport = (runs: RunProps[], updateFonts = false) => {
    const hasNonEmpty = runs.some((run) => run.length > 0);

    const tmpRuns = hasNonEmpty ? runs.filter((run) => run.length > 0) : [runs[0]];

    if (!updateFonts) {
        return runs;
    }

    return tmpRuns.map((run) => {
        const newRun: Omit<RunProps, 'fontId'> & { fontId: number | string } = cloneObj(run);

        return newRun;
    });
};

export const equals = (a: any, b: any): boolean => {
    if (a === b) {
        return true;
    }

    if (a instanceof Date && b instanceof Date) {
        return a.getTime() === b.getTime();
    }

    if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) {
        return a === b;
    }

    if (a.prototype !== b.prototype) {
        return false;
    }

    const keys = Object.keys(a);

    if (keys.length !== Object.keys(b).length) {
        return false;
    }

    return keys.every((k) => equals(a[k], b[k]));
};

type RunTempProps = {
    start: number;
    end: number;
    index: number;
};
export type DenormalizedRun<T extends TextSegment> = T & RunTempProps;
type TextSettings = Omit<RunProps, 'length'>;

export const denormalizeRun = <T extends TextSegment>(runs: DenormalizedRun<T>[], run: T, index: number) => {
    const start = index === 0 ? 0 : runs[index - 1].end;
    const end = start + run.length;

    runs.push({
        ...run,
        start,
        end,
        index,
    });

    return runs;
};

export const normalizeRun = <T extends TextSegment>({ start, end, index, ...run }: DenormalizedRun<T>): T => run as any;

export const getlayoutRunsForExport = (layoutRuns: LayoutRuns, textIdxStart: number, textIdxEnd: number) => {
    const tmpLayoutRuns = layoutRuns
        .reduce(denormalizeRun, [] as DenormalizedRun<LayoutRun>[])
        .filter((run) => run.end >= textIdxStart && run.start <= textIdxEnd)
        .map((run, i, arr) => {
            const data = {
                ...run,
                start: run.start - textIdxStart,
                end: run.end - textIdxStart,
            };

            if (i === arr.length - 1) {
                const endOffset = run.end - textIdxEnd;

                data.end -= endOffset;
                data.length -= endOffset;
            }

            return data;
        });

    const startOffset = tmpLayoutRuns[0].start;

    tmpLayoutRuns[0].length += startOffset;

    return tmpLayoutRuns.map(normalizeRun).filter((run, i, arr) => run.length || i === arr.length - 1);
};

export const addRuns = (runs: RunProps[], newRuns: RunProps[], textIdxStart: number, startRunIdx: number) => {
    const clonedRuns = [...runs];
    let offset = 0;
    let result = [...clonedRuns];

    runs.forEach((run, runIdx) => {
        if (runIdx !== startRunIdx) {
            offset += run.length;

            return;
        }

        const startIdxRelativeToRun = textIdxStart - offset;

        if (startIdxRelativeToRun === run.length) {
            result = [...clonedRuns.slice(0, startRunIdx + 1), ...newRuns, ...clonedRuns.slice(startRunIdx + 1)];
        } else if (startIdxRelativeToRun === 0) {
            result = [...clonedRuns.slice(0, startRunIdx), ...newRuns, ...clonedRuns.slice(startRunIdx)];
        } else {
            const runToSplit = clonedRuns[startRunIdx];
            const firstPartRun = { ...runToSplit, length: startIdxRelativeToRun };
            const secPartRun = { ...runToSplit, length: runToSplit.length - startIdxRelativeToRun };

            result = [
                ...clonedRuns.slice(0, startRunIdx),
                firstPartRun,
                ...newRuns,
                secPartRun,
                ...clonedRuns.slice(startRunIdx + 1),
            ];
        }

        offset += run.length;
    });

    return result.filter((run) => run.length > 0);
};

export const addLayoutRuns = (runs: LayoutRuns, newRuns: LayoutRuns, textIdxStart: number) => {
    if (newRuns.length === 0) {
        return runs;
    }

    const [firstNewRun, ...restNewRuns] = newRuns;
    const denormalisedRuns = runs.reduce(denormalizeRun, [] as DenormalizedRun<LayoutRun>[]);

    const runToUpdate = denormalisedRuns.findLast((run) => run.end >= textIdxStart && run.start <= textIdxStart);

    runToUpdate.length += firstNewRun.length;
    const idxToAdd = runToUpdate.index + 1;

    const result = denormalisedRuns.map(normalizeRun);
    result.splice(idxToAdd, 0, ...restNewRuns);

    return result;
};

export const updateRuns = <T extends TextSegment>(runs: T[], start: number, end: number, text: string): T[] => {
    if (!runs.length) {
        throw new Error('Runs array should never be empty');
    }

    const runsWithStartEnd = runs.reduce(denormalizeRun, [] as DenormalizedRun<T>[]);
    const runsToUpdate = runsWithStartEnd.filter((run) => run.end >= start && run.start <= end);

    runsToUpdate.forEach((run) => {
        run.length = Math.max(start - run.start, 0) + Math.max(run.end - end, 0);
    });

    (start === end && runsToUpdate.length > 1 && runsToUpdate[1].length === 0
        ? runsToUpdate[1]
        : runsToUpdate.find((r) => r.start <= start && r.end >= end) || runsToUpdate[0]
    ).length += text.length;

    const newRuns = runsWithStartEnd.map(normalizeRun).filter((run) => run.length > 0);

    if (!newRuns.length) {
        // keep text settings from the first run
        newRuns.push({ ...runs[0], length: 0 });
    }

    return newRuns;
};

export const updateLayoutRuns = <T extends TextSegment>(
    layoutRuns: T[],
    start: number,
    end: number,
    text: string,
): T[] => {
    if (!layoutRuns.length) {
        throw new Error('Layout runs array should never be empty');
    }

    const runsWithStartEnd = layoutRuns.reduce(denormalizeRun, [] as DenormalizedRun<T>[]);
    const runsToUpdate = runsWithStartEnd.filter((layoutRun) => layoutRun.end >= start && layoutRun.start <= end);

    if (
        runsToUpdate.length > 1 &&
        runsToUpdate[0].end === start &&
        runsToUpdate[1].start === end &&
        runsToUpdate[1].length <= 1
    ) {
        runsToUpdate.shift();
    }

    runsToUpdate.forEach((run) => {
        run.length = Math.max(start - run.start, 0) + Math.max(run.end - end, 0);
    });

    const cleanedRunsToUpdate = runsToUpdate.filter((run, i, arr) => i === arr.length - 1 || run.length > 0);

    (start === end && cleanedRunsToUpdate.length > 1 && cleanedRunsToUpdate[1].length === 0
        ? cleanedRunsToUpdate[1]
        : cleanedRunsToUpdate.find((r) => r.start <= start && r.end >= end) || cleanedRunsToUpdate[0]
    ).length += text.length;

    const newRuns = runsWithStartEnd.map(normalizeRun).filter((run, i, arr) => i === arr.length - 1 || run.length > 0);

    if (!newRuns.length) {
        // keep paragraph settings from the first run
        newRuns.push({ ...layoutRuns[0], length: 0 });
    }

    return newRuns;
};

export const splitRun = <T extends TextSegment>(runs: T[], textIdx: number): T[] => {
    const denormalisedRuns = runs.reduce(denormalizeRun, [] as DenormalizedRun<T>[]);
    const runToUpdate = denormalisedRuns.find((run) => run.start <= textIdx && run.end >= textIdx);

    if (!runToUpdate) {
        return runs;
    }

    return runs.reduce((accum, run, i) => {
        const { start, end, index, ...data } = runToUpdate;

        if (i === index) {
            accum.push({ ...(data as unknown as T), length: textIdx - start });
            accum.push({ ...(data as unknown as T), length: end - textIdx });
        } else {
            accum.push(run);
        }

        return accum;
    }, [] as T[]);
};

export const getDenormalizedRunsForSelection = <T extends TextSegment>(
    runs: DenormalizedRun<T>[],
    start: number,
    end: number,
    strict = false,
) => {
    const selectedRuns = runs.filter((run) => {
        const isStartInside = run.start <= start && (strict ? start < run.end : start <= run.end);
        const isEndInside = (strict ? run.start < end : run.start <= end) && end <= run.end;
        const isFullRunInside = start <= run.start && end >= run.end;

        return isStartInside || isEndInside || isFullRunInside;
    });

    if (start === end && selectedRuns.length > 1) {
        // get first empty run under cursor
        return [selectedRuns.slice(0, 2).find((run) => run.length === 0) ?? selectedRuns[0]];
    }

    return selectedRuns;
};

const equalsRunStyles = (a: RunProps, b: RunProps) => {
    const { length: aLength, ...aStyle } = a;
    const { length: bLength, ...bStyle } = b;

    return equals(aStyle, bStyle);
};

export const cleanupRunValues = (obj: RunProps): Partial<RunProps> & { length: number } => {
    Object.keys(obj).forEach((key) => {
        if (key === 'styleId') {
            obj[key] = obj[key] || null;

            return;
        }

        if (obj[key] === null || obj[key] === undefined) {
            delete obj[key];
        }
    });

    return obj;
};

export const applyStyleOnRuns = (
    runs: RunProps[],
    start: number,
    end: number,
    style: Partial<TextSettings>,
): RunProps[] => {
    const letters: RunProps[] = [];
    const denormalisedRuns = runs.reduce(denormalizeRun, []);

    const getExistingRunProps = () => {
        const [run] = getDenormalizedRunsForSelection(denormalisedRuns, start, end, true);

        return normalizeRun(run);
    };

    runs.forEach((run) => {
        for (let i = 0; i < run.length; i++) {
            letters.push({
                ...run,
                length: 1,
            });
        }
    });

    if (start === end) {
        letters.splice(start, 0, { ...getExistingRunProps(), ...style, length: 0 });
    }

    // else, case when [start !== end]
    for (let i = start; i < end; i++) {
        Object.keys(style).forEach((key) => {
            letters[i][key] = style[key];
        });
    }

    // merge runs that have the same style
    const mergedRuns: RunProps[] = [];
    letters.forEach((run) => {
        const prevRun = mergedRuns[mergedRuns.length - 1];

        if (prevRun && prevRun.length > 0 && equalsRunStyles(prevRun, run)) {
            prevRun.length += run.length;
        } else {
            mergedRuns.push(run);
        }
    });

    return mergedRuns;
};

export const getUniqueObject = <T extends object>(items: T[]): T[] => {
    const result = [] as T[];

    for (const currentItem of items) {
        const alreadySaved = result.find((item) => equals(item, currentItem));

        if (!alreadySaved) {
            result.push(currentItem);
        }
    }

    return result;
};

export const isSpaceOrBreak = (letter: string) => letter === ' ' || letter === '\n';
export const isPunctuation = (letter: string) => /[\s\n.,\\\/#!?$%<>^&*;:{}=\-_`~()]/g.test(letter);

export const isOppositeSymbol = (letter: string, isLetterEmpty: boolean) =>
    (isLetterEmpty && letter !== ' ' && letter !== '\n') || (!isLetterEmpty && (letter === ' ' || letter === '\n'));

export const toPercent = (floatNumber: number) => Number((floatNumber * 100).toFixed(2));

export const toFloat = (percent: number) => Number((percent * 0.01).toFixed(2));

export const applyTextTransform = (text: string, textTransform: TextTransform) => {
    switch (textTransform) {
        case TextTransform.UPPERCASE:
            return text.toLocaleUpperCase();
        case TextTransform.LOWERCASE:
            return text.toLocaleLowerCase();
        case TextTransform.CAPITALIZE:
            return text.replace(/(?:^|\s)\S/g, (firstLetter) => firstLetter.toLocaleUpperCase());
    }

    return text;
};

export const createWordsIndexMap = (shapedLine: ShapedLine) => {
    const glyphs = reorderByLevels(shapedLine.glyphs);

    const mapping: { [glyphIdx: number]: number } = {};
    let inSpace = false;
    let lettersCount = 0;
    let wordsCount = 0;

    glyphs.forEach((glyph) => {
        const letter = glyph.data.text[0].trim();

        if (inSpace && letter && lettersCount) {
            wordsCount++;
        }

        inSpace = !letter;

        if (letter) {
            lettersCount++;
        }

        mapping[glyph.data.glyphIdx] = wordsCount;
    });

    if (lettersCount) {
        wordsCount++;
    }

    return { wordsCount, mapping };
};

export const getParagraphs = ({ value, layoutRuns }: TextProps) => {
    const starts: number[] = [];

    for (let i = 0; i < layoutRuns.length - 1; i++) {
        // we might want to check run type later
        const { length } = layoutRuns[i];

        const prev = starts[i - 1] || 0;

        starts.push(prev + length);
    }

    const paragraphs: number[] = [];
    let lineIdx = 0;

    for (let i = 0; i < value.length; i++) {
        if (value[i] === '\n') {
            lineIdx++;

            if (starts.includes(i + 1)) {
                paragraphs.push(lineIdx);
            }
        }
    }

    return paragraphs;
};

export const isRtlGlyph = (glyph: IStructuredGlyph) => glyph.data.direction === Direction.RTL;

export const splitTextByWords = (
    text: string,
    includeSpaces: boolean = false,
): {
    word: string;
    start: number;
    end: number;
}[] =>
    [...text.matchAll(includeSpaces ? /([^\n\s\t\r]+|[^\S\n]+)/gu : /[^\n\s\t\r]+/gu)].map((match) => {
        const word = match[0];
        const start = match.index;
        const end = start + word.length;

        return { word, start, end };
    });

export const reorderByLevels = (glyphs: IStructuredGlyph[]): IStructuredGlyph[] => {
    let maxLevel = glyphs.reduce((level, glyph) => Math.max(level, glyph.data.level), 0);

    const reverseSubset = <T>(array: T[], subset: T[], start: number) => {
        subset.reverse();

        array.splice(start, subset.length, ...subset);

        return array;
    };

    while (maxLevel > 0) {
        let start = -1;
        const subset = [];

        const reverse = () => {
            subset.forEach((g) => {
                g.data.level--;
            });

            if (subset.length <= 1) {
                return;
            }

            reverseSubset(glyphs, subset, start);
        };
        glyphs.forEach((glyph, index) => {
            if (glyph.data.level === maxLevel) {
                if (!subset.length) {
                    start = index;
                }

                subset.push(glyph);

                return;
            }

            reverse();
            subset.length = 0;
        });
        reverse();
        maxLevel--;
    }

    return glyphs;
};

export const getTextDirections = (text: string): { ltr: number; rtl: number; detectedDirection: Direction } => {
    const embeddingLevels = bidi.getEmbeddingLevels(text);
    let rtl = 0;
    let ltr = 0;
    embeddingLevels.levels.forEach((level) => {
        if (level % 2 === 0) {
            ltr++;
        } else {
            rtl++;
        }
    });

    const getDirectionFromParagraphs = () => {
        if (!embeddingLevels.paragraphs.length) {
            return Direction.LTR;
        }

        const firstParagraph = embeddingLevels.paragraphs[0];

        return firstParagraph.level % 2 === 0 ? Direction.LTR : Direction.RTL;
    };

    const detectedDirection = getDirectionFromParagraphs();

    return { ltr, rtl, detectedDirection };
};

export const splitRunsByDirection = (
    runs: RunProps[],
    text: string,
    direction?: Direction,
): (RunProps & {
    direction: Direction;
    startIdx: number;
    runIdx: number;
    level: number;
})[] => {
    const textIndexToRunIndex = new Map<number, number>();
    const textIndexToNewRunIndex = new Map<number, number>();
    let runPastLength = 0;
    let runIdx = 0;

    for (let i = 0; i < text.length; i++) {
        const run = runs[runIdx];
        const length = run.length + runPastLength;

        if (length === i) {
            runPastLength += run.length;
            runIdx++;
        }

        textIndexToRunIndex.set(i, runIdx);
    }

    const explicitDirection = direction ? (direction.toString().toLowerCase() as 'ltr' | 'rtl') : undefined;
    const embeddingLevels = bidi.getEmbeddingLevels(text, explicitDirection);

    type InnerRun = RunProps & { direction: Direction; startIdx: number; runIdx: number; level: number };
    const newRuns: InnerRun[] = [];

    let currentRunIdx = -1;
    let currentTmpLevel = -1;
    let length = 0;
    const newLineStarts = new Set<number>(embeddingLevels.paragraphs.map((paragraph) => paragraph.start));

    for (let i = 0; i < text.length; i++) {
        const runIndex = textIndexToRunIndex.get(i);
        const level = embeddingLevels.levels[i];

        if (runIndex !== currentRunIdx || currentTmpLevel !== level || newLineStarts.has(i)) {
            if (length) {
                newRuns[newRuns.length - 1].length = length;
                // newRuns[newRuns.length - 1].direction = isLtrText(str) ? Direction.LTR : Direction.RTL;
            }

            newRuns.push({
                ...runs[runIndex],
                direction: level % 2 === 0 ? Direction.LTR : Direction.RTL,
                startIdx: i,
                runIdx: runIndex,
                level,
            });
            length = 0;
            currentRunIdx = runIndex;
            currentTmpLevel = level;
        }

        length++;
        textIndexToNewRunIndex.set(i, newRuns.length - 1);
    }

    if (length) {
        newRuns[newRuns.length - 1].length = length;
    }

    return newRuns;
};

export const charMappingRange = (start: number, end: number) =>
    Array.from({ length: Math.abs(end - start) + 1 }, (_, i) => start + (start < end ? i : -i));

export const range = (start: number, end: number) => {
    const result = [];

    for (let i = start; i < end; i++) {
        result.push(i);
    }

    return result;
};

export const createRectanglePath = (offsetX: number, offsetY: number, width: number, height: number) => {
    return `M ${offsetX} ${offsetY} H ${offsetX + width} V ${offsetY + height} H ${offsetX} Z`;
};

export const reverseSubset = (array: any[], start: number, end: number) => {
    if (start < 0 || end >= array.length || start >= end) {
        throw new Error('Invalid start or end indices for reversing subset of the array.');
    }

    const subset = array.slice(start, end + 1);

    subset.reverse();

    array.splice(start, subset.length, ...subset);

    return array;
};

export const createRoundedRectanglePath = (
    offsetX: number,
    offsetY: number,
    width: number,
    height: number,
    borderRadius: number,
) => {
    const w1 = Math.max(width - 2 * borderRadius, 0);
    const h1 = Math.max(height - 2 * borderRadius, 0);
    const r = Math.max(Math.min((width - w1) / 2, (height - h1) / 2), 0);
    const w = width - 2 * r;
    const h = height - 2 * r;
    const x = offsetX;
    const y = offsetY;

    if (r === 0) {
        return createRectanglePath(x, y, w, h);
    }

    // https://stackoverflow.com/questions/10177985/svg-rounded-corner
    // prettier-ignore
    return `m ${x + r} ${y} h ${w} a ${r} ${r} 0 0 1 ${r} ${r} v ${h} a ${r} ${r} 0 0 1 -${r} ${r} h -${w} a ${r} ${r} 0 0 1 -${r} -${r} v -${h} a ${r} ${r} 0 0 1 ${r} -${r} z`;
};

export const reCalculateLeadingPx = (glyphs: IStructuredGlyph[], defaultLineMetrics: ShapedLineMetrics) => {
    if (!glyphs.length) {
        return defaultLineMetrics.leadingPx;
    }

    let leadingPx = 0;

    glyphs.forEach((g) => {
        if (!g.isNewLine() || glyphs.length === 1) {
            leadingPx = Math.max(leadingPx, g.emToPx(g.data.leadingEm / g.data.textScriptScale));
        }
    });

    return leadingPx;
};
