import React from 'react';
import { PageWithAppcues } from '~/helpers/RouteWithAppcues';
import Designs from './Designs';

export { SELECTED_CORRUPTED_DESIGN } from './Designs';

const DesignsContainer = () => (
    <PageWithAppcues>
        <Designs isSharedWithMe={false} />
    </PageWithAppcues>
);
export default DesignsContainer;
