import { useSelector } from 'react-redux';
import { isAccountRoleAssignedSelector } from 'packages/store/user/user.selectors';
import roles, { orderedRoles } from '~/configs/roles';
import {
    Account,
    AccountLevelActions,
    ActionStatus,
    AssetsAction,
    BrandColorsAction,
    ColorPaletteAction,
    GeneralAction,
    LocalAssetsAction,
    SettingsAction,
} from './types';

const accountPermissions = {
    [Account.GENERAL]: {
        [roles.video_brand_studio.handler.admin]: {
            [GeneralAction.CREATE_PROJECT]: ActionStatus.ALLOWED,
            [GeneralAction.CREATE_DESIGN_FROM_TEMPLATE]: ActionStatus.ALLOWED,
            [GeneralAction.CREATE_DESIGN_FROM_SCRATCH]: ActionStatus.ALLOWED,
            [GeneralAction.CREATE_DESIGN_FROM_IMPORT_XML]: ActionStatus.ALLOWED,
        },
        [roles.video_brand_studio.template.manage]: {
            [GeneralAction.CREATE_PROJECT]: ActionStatus.ALLOWED,
            [GeneralAction.CREATE_DESIGN_FROM_TEMPLATE]: ActionStatus.ALLOWED,
            [GeneralAction.CREATE_DESIGN_FROM_SCRATCH]: ActionStatus.ALLOWED,
            [GeneralAction.CREATE_DESIGN_FROM_IMPORT_XML]: ActionStatus.ALLOWED,
        },
        [roles.video_brand_studio.video.manage]: {
            [GeneralAction.CREATE_PROJECT]: ActionStatus.ALLOWED,
            [GeneralAction.CREATE_DESIGN_FROM_TEMPLATE]: ActionStatus.ALLOWED,
            [GeneralAction.CREATE_DESIGN_FROM_SCRATCH]: ActionStatus.NOT_ALLOWED,
            [GeneralAction.CREATE_DESIGN_FROM_IMPORT_XML]: ActionStatus.NOT_ALLOWED,
        },
    },
    [Account.ASSETS]: {
        [roles.video_brand_studio.handler.admin]: {
            [AssetsAction.LOCAL]: ActionStatus.ALLOWED,
            [AssetsAction.BYNDER]: ActionStatus.ALLOWED,
        },
        [roles.video_brand_studio.template.manage]: {
            [AssetsAction.LOCAL]: ActionStatus.ALLOWED,
            [AssetsAction.BYNDER]: ActionStatus.ALLOWED,
        },
        [roles.video_brand_studio.video.manage]: {
            [AssetsAction.LOCAL]: ActionStatus.ALLOWED,
            [AssetsAction.BYNDER]: ActionStatus.ALLOWED,
        },
    },
    [Account.LOCAL_ASSETS]: {
        [roles.video_brand_studio.handler.admin]: {
            [LocalAssetsAction.MANAGE]: ActionStatus.ALLOWED,
        },
        [roles.video_brand_studio.template.manage]: {
            [LocalAssetsAction.MANAGE]: ActionStatus.ALLOWED,
        },
        [roles.video_brand_studio.video.manage]: {
            [LocalAssetsAction.MANAGE]: ActionStatus.ALLOWED,
        },
    },
    [Account.SETTINGS]: {
        [roles.video_brand_studio.handler.admin]: {
            [SettingsAction.EDIT_TIMEZONE]: ActionStatus.ALLOWED,
            [SettingsAction.CONNECTION]: ActionStatus.ALLOWED,
        },
        [roles.video_brand_studio.template.manage]: {
            [SettingsAction.EDIT_TIMEZONE]: ActionStatus.ALLOWED,
            [SettingsAction.CONNECTION]: ActionStatus.NOT_ALLOWED,
        },
        [roles.video_brand_studio.video.manage]: {
            [SettingsAction.EDIT_TIMEZONE]: ActionStatus.ALLOWED,
            [SettingsAction.CONNECTION]: ActionStatus.NOT_ALLOWED,
        },
    },
    [Account.COLOR_PALETTES]: {
        [roles.video_brand_studio.handler.admin]: {
            [ColorPaletteAction.CREATE_COLOR_PALETTE]: ActionStatus.ALLOWED,
            [ColorPaletteAction.EDIT_COLOR_PALETTE]: ActionStatus.ALLOWED,
            [ColorPaletteAction.DELETE_COLOR_PALETTE]: ActionStatus.ALLOWED,
        },
        [roles.video_brand_studio.template.manage]: {
            [ColorPaletteAction.CREATE_COLOR_PALETTE]: ActionStatus.ALLOWED,
            [ColorPaletteAction.EDIT_COLOR_PALETTE]: ActionStatus.ALLOWED,
            [ColorPaletteAction.DELETE_COLOR_PALETTE]: ActionStatus.ALLOWED,
        },
        [roles.video_brand_studio.video.manage]: {
            [ColorPaletteAction.CREATE_COLOR_PALETTE]: ActionStatus.NOT_ALLOWED,
            [ColorPaletteAction.EDIT_COLOR_PALETTE]: ActionStatus.NOT_ALLOWED,
            [ColorPaletteAction.DELETE_COLOR_PALETTE]: ActionStatus.NOT_ALLOWED,
        },
    },
    [Account.BRAND_COLORS]: {
        [roles.video_brand_studio.handler.admin]: {
            [BrandColorsAction.ADD_COLOR]: ActionStatus.ALLOWED,
            [BrandColorsAction.EDIT_COLOR]: ActionStatus.ALLOWED,
            [BrandColorsAction.DELETE_COLOR]: ActionStatus.ALLOWED,
        },
        [roles.video_brand_studio.template.manage]: {
            [BrandColorsAction.ADD_COLOR]: ActionStatus.ALLOWED,
            [BrandColorsAction.EDIT_COLOR]: ActionStatus.ALLOWED,
            [BrandColorsAction.DELETE_COLOR]: ActionStatus.ALLOWED,
        },
        [roles.video_brand_studio.video.manage]: {
            [BrandColorsAction.ADD_COLOR]: ActionStatus.NOT_ALLOWED,
            [BrandColorsAction.EDIT_COLOR]: ActionStatus.NOT_ALLOWED,
            [BrandColorsAction.DELETE_COLOR]: ActionStatus.NOT_ALLOWED,
        },
    },
};

const useAccountPermissions = () => {
    const isAccountRoleAssignedSelectorResults = useSelector(isAccountRoleAssignedSelector);

    const getAccountAssignedRole = () => orderedRoles.find((role) => isAccountRoleAssignedSelectorResults(role));

    const isAccountRoleAssignedFn = (role: string) => {
        const accountRole = getAccountAssignedRole();

        if (!accountRole) {
            return false;
        }

        return role === accountRole;
    };

    const isAccountActionAllowed = (action: AccountLevelActions, accountType: Account) => {
        const accountRole = getAccountAssignedRole();

        if (!accountRole) {
            return ActionStatus.NOT_ALLOWED;
        }

        const actions = accountPermissions[accountType][accountRole];

        if (!actions) {
            return ActionStatus.NOT_ALLOWED;
        }

        if (!Object.prototype.hasOwnProperty.call(actions, action)) {
            return ActionStatus.NOT_ALLOWED;
        }

        return actions[action];
    };

    return {
        isAccountRoleAssigned: isAccountRoleAssignedFn,
        isAccountActionAllowed,
        getAccountAssignedRole,
    };
};

export default useAccountPermissions;
