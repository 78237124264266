import { ColorParams } from '@bynder-studio/misc';

// Glyph to be used as replacement for missing characters in a font
// UNICODE: FFFD, INDEX: 452
export const REPLACEMENT_GLYPH_DATA = {
    text: '�',
    fontId: 'fallback', // roboto
    upem: 2048,
    ascender: 1900,
    descender: -500,
    lineGap: 0,
    lineHeight: 2400,
    glyphId: 452,
    path: 'M1048,1618L2007,643L1048,-332L92,643L1048,1618ZM1131,402Q1131,460 1146,489Q1161,518 1197,542Q1271,581 1317,649Q1363,716 1363,790Q1363,918 1280,994Q1196,1069 1047,1069Q903,1069 823,1000Q743,930 741,803L944,803Q946,854 975,880Q1004,906 1047,906Q1104,906 1132,874Q1160,842 1160,790Q1160,695 1067,635Q976,570 953,524Q929,478 929,402L1131,402ZM1131,311L929,311L929,141L1131,141L1131,311ZM1056,-551L1060,-551L1060,-555L1056,-555L1056,-551ZM1054,2163L1058,2163L1058,2159L1054,2159L1054,2163Z',
    xAdvance: 2101,
    yAdvance: 0,
    xOffset: 0,
    yOffset: 0,
    xMin: 92,
    xMax: 2007,
    yMin: 4881,
    yMax: 2163,
    width: 1915,
    height: 2718,
    // leftSideBearing: 92,
    // rightSideBearing: 94,
};
export const DEFAULT_STRIKEOUT_POSITION = 502;
export const DEFAULT_THICKNESS = 102;
export const DEFAULT_UNDERLINE_POSITION = -154;

export enum LayoutElement {
    PARAGRAPH = 'PARAGRAPH',
}

export enum Placement {
    FIT = 'FIT',
    WRAP = 'WRAP',
    AUTO_RESIZE = 'AUTO_RESIZE',
}

export enum HorizontalAlignment {
    LEFT = 'LEFT',
    CENTER = 'CENTER',
    RIGHT = 'RIGHT',
    JUSTIFY = 'JUSTIFY',
}

export enum VerticalAlignment {
    TOP = 'TOP',
    MIDDLE = 'MIDDLE',
    BOTTOM = 'BOTTOM',
}

export enum Direction {
    LTR = 'LTR',
    RTL = 'RTL',
    TTB = 'TTB',
    BTT = 'BTT',
}

export enum CursorOrientation {
    Left = 'left',
    Right = 'right',
}

export type DropShadowParams = {
    enabled: boolean;
    offset: number;
    angle: number;
    blurRadius: number;
    color: ColorParams;
};

export type StrokeParams = {
    enabled: boolean;
    width: number;
    color: ColorParams;
    placement: string;
};

export type OS2Metrics = {
    ySubscriptXSize: number;
    ySubscriptYSize: number;
    ySubscriptXOffset: number;
    ySubscriptYOffset: number;
    ySuperscriptXSize: number;
    ySuperscriptYSize: number;
    ySuperscriptXOffset: number;
    ySuperscriptYOffset: number;
    yStrikeoutSize: number;
    yStrikeoutPosition: number;
    underlineThickness: number;
    underlinePosition: number;
    sTypoAscender: number;
    sTypoDescender: number;
    sTypoLineGap: number;
    upem: number;
    ascender: number;
    descender: number;
};

export class Region {
    left: number;

    top: number;

    width: number;

    height: number;

    constructor(
        left: number = Number.POSITIVE_INFINITY,
        top: number = Number.POSITIVE_INFINITY,
        width = 0,
        height = 0,
    ) {
        this.left = left;
        this.top = top;
        this.width = width;
        this.height = height;
    }

    getRight(): number {
        return this.left + this.width;
    }

    getBottom(): number {
        return this.top + this.height;
    }

    union(r: Region) {
        // keep track of abs right position
        // we check for existing width and heigh first to prevent summing infinities
        const right = this.width ? Math.max(this.left + this.width, r.left + r.width) : r.left + r.width;
        const bottom = this.height ? Math.max(this.top + this.height, r.top + r.height) : r.top + r.height;

        this.left = Math.min(this.left, r.left);
        this.top = Math.min(this.top, r.top);
        this.width = right - this.left;
        this.height = bottom - this.top;
    }
}
