import React, { useCallback, useEffect, useState } from 'react';
import { Form, Slider } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconVerticalConstraints } from '@bynder/icons';
import useElement from '../../hooks/useElement';
import useDesignElementContentTransform from '../../hooks/useDesignElementContentTransform';
import { fromPercent, toPercent } from 'packages/pages/utils';

const VerticalAlignment = ({ id, disabled }) => {
    const { translate } = useTranslate();
    const { updateElement } = useElement(id);
    const contentTransform = useDesignElementContentTransform(id);

    const value = toPercent(contentTransform?.verticalAlignment ?? 0);

    const handleOnChange = (newValue) => {
        if (value === newValue) {
            return;
        }

        updateElement({
            contentTransform: {
                ...contentTransform,
                verticalAlignment: fromPercent(newValue),
            },
        });
    };

    return (
        <Form.Group>
            <Form.Label>{translate('design.sidebar.scene.options.crop-mode.align-vertically.label')}</Form.Label>
            <Slider
                value={value}
                min={-100}
                max={100}
                onChange={handleOnChange}
                isDisabled={disabled}
                isFromCenter
                inputPosition="right"
                iconLeft={<IconVerticalConstraints />}
            />
        </Form.Group>
    );
};

export default VerticalAlignment;
