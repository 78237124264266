import React from 'react';
import { FiltersContainer, FiltersDivider, FiltersLeftBlock, FiltersRightBlock } from './Filters.styled';
import MediaType from './components/MediaType';
import Ordering from './components/Ordering';
import Search from './components/Search';
import Ownership from './components/Ownership';
import Status from './components/Status';

type Props = {
    children: React.ReactNode;
    className?: string;
};

const Filters = ({ children, className }: Props) => (
    <FiltersContainer className={className}>{children}</FiltersContainer>
);

export default Filters;

Filters.FiltersLeftBlock = FiltersLeftBlock;
Filters.FiltersRightBlock = FiltersRightBlock;
Filters.FiltersDivider = FiltersDivider;
Filters.Search = Search;
Filters.MediaType = MediaType;
Filters.Ordering = Ordering;
Filters.Ownership = Ownership;
Filters.Status = Status;
