import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState, useEffect } from 'react';
import { Divider } from '@bynder/design-system';
import { isVariationInvalid } from 'packages/pages/design/sidebar/variations/utils';
import { Column, ColumnAction, ColumnSelect, RowContainer } from './List.styled';
import PageItem from './PageItem';
import VariationItem from './VariationItem';
import { SelectedVariations } from './SelectedVariations';
import { SelectedSizes } from './SelectedSizes';
import { ExpandCollapseRow } from './ExpandCollapseRow';
import { ExpandedRow } from './ExpandedRow';
import useExport from '../hooks/useExport';
import { SelectRowCheckbox } from './SelectRowCheckbox';

export const ListRow = forwardRef(({ item, isLast = false, isSingle }, ref) => {
    const { groupBy, pageIds, orderBy } = useExport();
    const isInvalid = useMemo(() => {
        if (groupBy === 'variation') {
            return isVariationInvalid(item) || item.entries.some((entry) => isVariationInvalid(entry));
        }

        return item.entries.every((entry) => isVariationInvalid(entry));
    }, [item]);

    const [expand, setExpand] = useState(isSingle && !isInvalid);
    const toggleExpand = useCallback(() => {
        if (isInvalid) {
            return;
        }

        setExpand((e) => !e);
    }, [isInvalid]);

    useImperativeHandle(
        ref,
        () => ({
            expand: () => {
                if (isInvalid) {
                    return;
                }

                setExpand(true);
            },
            collapse: () => setExpand(false),
        }),
        [],
    );

    const filteredItem =
        groupBy === 'variation' && item.aggregateBase === 'SET' && pageIds.length !== 0
            ? {
                  ...item,
                  entries: item.entries.filter(
                      (entry) =>
                          pageIds.includes(entry?.sizeId?.toString()) ||
                          pageIds.includes(entry?.creativeVersionPageId?.toString()),
                  ),
              }
            : item;

    useEffect(() => {
        setExpand(false);
    }, [orderBy, pageIds]);

    return (
        <>
            <RowContainer
                role="button"
                gap="4"
                alignItems="center"
                onClick={toggleExpand}
                isDisabled={isInvalid}
                bordered
            >
                <ColumnSelect>
                    <SelectRowCheckbox item={filteredItem} isDisabled={isInvalid} />
                </ColumnSelect>
                {groupBy === 'variation' && (
                    <>
                        <Column>
                            <VariationItem created={item.created} isInvalid={isInvalid} {...filteredItem} />
                        </Column>
                        <Column>
                            <SelectedSizes item={filteredItem} />
                        </Column>
                    </>
                )}
                {groupBy === 'page' && (
                    <>
                        <Column>
                            <PageItem name={item.name} width={item.pageWidth} height={item.pageHeight} />
                        </Column>
                        <Column>
                            <SelectedVariations item={item} />
                        </Column>
                    </>
                )}
                <ColumnAction>
                    <ExpandCollapseRow expand={expand} isDisabled={isInvalid} />
                </ColumnAction>
            </RowContainer>
            {expand && <ExpandedRow item={item} />}
            {!isLast && <Divider />}
        </>
    );
});
