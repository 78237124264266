import React from 'react';
import { useDroppable } from '@dnd-legacy-kit/core';
import { DroppablePlaceholder } from './Droppable.styled';

export const Droppable = ({ renderOrder, parentId, level = 0, fillHeight = false }) => {
    const { isOver, setNodeRef } = useDroppable({
        id: `${renderOrder}-${parentId}`,
        data: {
            type: 'placeholder',
            renderOrder,
            parentId,
        },
    });

    return (
        <DroppablePlaceholder
            className={`${fillHeight ? 'layers--item-droppable-fill-height' : ''}`}
            ref={setNodeRef}
            style={{ marginLeft: level * 12 }}
        >
            <div className="layers--item-droppable-placeholder-view" style={{ opacity: isOver ? 1 : 0 }} />
        </DroppablePlaceholder>
    );
};
