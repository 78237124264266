import { IAssetsLoader } from '../../AssetLoader/IAssetsLoader';
import { IAsset } from '../Assets/IAsset';
import { ManipulableObject, ManipulableObjectParams } from '../Shared/ManipulableObject';
import type { IProperty } from './IProperty';
import type { CreativeTypes } from '../../Enums/CreativeTypes';
import type { PreviewTypes } from '../../Enums/PreviewTypes';

export type BasePropertyParams = ManipulableObjectParams;

export class BaseProperty extends ManipulableObject implements IProperty {
    toObject(): BasePropertyParams {
        return super.toObject();
    }

    constructAsset(frameRate: number): void {}

    getValidationRules(creativeType: CreativeTypes, previewType: PreviewTypes): any {}

    isContainsAsset(arg0: IAsset): boolean {
        return false;
    }

    setAssetLoader(loader: IAssetsLoader): void {}
}
