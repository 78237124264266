import React, { useMemo } from 'react';
import { Dropdown, Button, Form } from '@bynder/design-system';
import useFonts from 'packages/pages/editor/RightSideMenu/Shots/Text/Fonts/useFonts';
import generateTestId from '~/helpers/testIdHelpers';
import useTextSelection from 'packages/hooks/useTextSelection';
import type { Props } from './types';
import type { ExtendedFont } from '../types';

const FontStyle = ({ creativeModel, selectedElement, disabled }: Props) => {
    const { id, locked } = selectedElement;
    const { fontId } = useTextSelection();
    const { fontsList, currentFont, isMixed, mixedValue } = useFonts();
    const { fonts } = fontsList[currentFont?.familyName] || { fonts: [] };

    const fontFamilyIds = useMemo(() => {
        const fontFamilyIds = new Set();

        Object.values(fontsList).forEach(({ id, name, fonts }) => {
            if (fonts.some((f) => fontId.includes(String(f.id)))) {
                fontFamilyIds.add(id);
            }
        });

        return fontFamilyIds;
    }, [fontId, fontsList]);

    const matchFontById = (idToFind: number) => fonts.find((f) => f.id === idToFind);
    const fontStyleIds = new Set((fontId || []).map((fontId) => +(fontId || '')));

    const handleChange = (font: ExtendedFont) => {
        const param = {
            updateTextSettingForSelection: {
                settings: {
                    fontId: font.id.toString(),
                },
            },
        };

        creativeModel.updateElement(id, param);
    };

    return (
        <Form.Group>
            <Dropdown
                {...generateTestId('shots_text_style')}
                trigger={({ isOpen, ...triggerProps }) => (
                    <Button
                        aria-label="shots_text_style"
                        isPressed={isOpen}
                        isDisabled={fontFamilyIds.size > 1 || disabled || locked || fonts.length === 1}
                        rightIcon={<Dropdown.Arrow />}
                        isFullWidth
                        {...triggerProps}
                    >
                        <Dropdown.SelectButtonText>
                            {!isMixed ? matchFontById(+fontId)?.name : mixedValue}
                        </Dropdown.SelectButtonText>
                    </Button>
                )}
                position="bottom"
            >
                {fonts.map((font) => (
                    <Dropdown.Item
                        key={font.id}
                        isChecked={fontStyleIds.has(font.id)}
                        onClick={() => handleChange(font)}
                    >
                        {font.name}
                    </Dropdown.Item>
                ))}
            </Dropdown>
        </Form.Group>
    );
};

export default FontStyle;
