import styled from 'styled-components';
import { PageBody } from '../Page.styled';

export const StyledPageBody = styled(PageBody)`
    [role='button'] {
        cursor: default;
    }
`;

export const Dot = styled.span`
    margin-left: 7px;
    margin-right: 7px;
`;
