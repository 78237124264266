import { EntityType } from 'packages/store/sharing/types';
import ProxyServerConnector from '~/services/connectors/ProxyServerConnector';

class AccessControlService extends ProxyServerConnector {
    gateway: ProxyServerConnector;

    constructor(path = '/access-control') {
        super(path);
        this.gateway = new ProxyServerConnector(path, GATEWAY_PREFIX);
    }

    updateAccess(data) {
        return this.gateway.put('', data);
    }

    updateOwnership(data) {
        return this.gateway.put('/change-owner', data);
    }

    getAccess(entityId: number[], entityType: EntityType) {
        return this.gateway.get(`/entities/permissions-list?entityType=${entityType}&entityIds=${entityId[0]}`);
    }
}

export default new AccessControlService();
