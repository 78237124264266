import React, { FC, useEffect } from 'react';
import { getImageFrame } from '@bynder-studio/render-web';
import { StyledCanvas } from './VideoPreview.styled';

type VideoPreviewProps = {
    src: string;
    isAlpha: boolean;
};

const TIME = 0.039;

const VideoPreviewComponent: FC<VideoPreviewProps> = ({ src, isAlpha }) => {
    const canvasRef = React.useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const video = document.createElement('video');

        video.crossOrigin = 'anonymous';
        video.src = src;

        const handleLoadedmetadata = () => {
            video.currentTime = TIME;
        };

        const handleSeeked = () => {
            if (!canvasRef.current) {
                return;
            }

            const ctx = canvasRef.current?.getContext('2d')!;
            canvasRef.current.width = video.videoWidth;
            canvasRef.current.height = video.videoHeight / (isAlpha ? 2 : 1);

            getImageFrame(video, isAlpha)
                .then((img) => {
                    if (!img) {
                        return;
                    }

                    ctx?.drawImage(img, 0, 0, canvasRef.current?.width || 0, canvasRef.current?.height || 0);
                    video.remove();
                })
                .catch((error) => {
                    console.log(error, 'error');
                });
        };

        video.addEventListener('loadedmetadata', handleLoadedmetadata);
        video.addEventListener('seeked', handleSeeked);

        return () => {
            if (!video) {
                return;
            }

            video.removeEventListener('loadedmetadata', handleLoadedmetadata);
            video.removeEventListener('seeked', handleSeeked);
        };
    }, [src, isAlpha]);

    return <StyledCanvas hasAlpha={isAlpha} ref={canvasRef} data-src={src} />;
};

export const VideoPreview = React.memo(VideoPreviewComponent);
