import styled from 'styled-components';
import { Form, RadioGroup, textStyles, token } from '@bynder/design-system';

export const StyledForm = styled(Form)`
    p {
        margin-bottom: 0;
    }
`;

export const StyledTitle = styled.p`
    ${textStyles.paragraphM}
    margin-bottom: ${token.spacing4};
`;

export const TextMuted = styled.span`
    color: ${token.colorTextMuted};
`;

export const StyledRadio = styled(RadioGroup.Radio)`
    * > div:before {
        border: none;
        border-color: transparent;
    }
`;
