import { ContentTransformTypes } from '../../Enums/ContentTransformTypes';

export type ContentTransformParams =
    | {
          type:
              | ContentTransformTypes.CONTAIN
              | ContentTransformTypes.COVER
              | ContentTransformTypes.COVER_WIDTH
              | ContentTransformTypes.COVER_HEIGHT
              | ContentTransformTypes.TEXT;
          horizontalAlignment: number;
          verticalAlignment: number;
      }
    | {
          type: ContentTransformTypes.FREE;
          horizontalOffset: number;
          verticalOffset: number;
          scale: number;
      };

export class ContentTransform {
    type: ContentTransformTypes;
    horizontalAlignment: number;
    verticalAlignment: number;
    horizontalOffset: number;
    verticalOffset: number;
    scale: number;

    constructor(params: ContentTransformParams) {
        this.type = params.type;

        if (params.type === ContentTransformTypes.FREE) {
            this.horizontalAlignment = 0;
            this.verticalAlignment = 0;
            this.horizontalOffset = params.horizontalOffset;
            this.verticalOffset = params.verticalOffset;
            this.scale = params.scale;
        } else {
            this.horizontalAlignment = params.horizontalAlignment;
            this.verticalAlignment = params.verticalAlignment;
            this.horizontalOffset = 0;
            this.verticalOffset = 0;
            this.scale = 1;
        }
    }

    getType(): ContentTransformTypes {
        return this.type;
    }

    getHorizontalAlignment(): number {
        return this.horizontalAlignment;
    }

    getVerticalAlignment(): number {
        return this.verticalAlignment;
    }

    getHorizontalOffset(): number {
        return this.horizontalOffset;
    }

    getVerticalOffset(): number {
        return this.verticalOffset;
    }

    getScale(): number {
        return this.scale;
    }

    toObject() {
        if (this.type === ContentTransformTypes.FREE) {
            return {
                type: this.type,
                horizontalOffset: this.horizontalOffset,
                verticalOffset: this.verticalOffset,
                scale: this.scale,
            };
        } else {
            return {
                type: this.type,
                horizontalAlignment: this.horizontalAlignment,
                verticalAlignment: this.verticalAlignment,
            };
        }
    }
}
