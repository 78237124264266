export enum AnimationInTypes {
    FADE_IN = 'FADE_IN',
    MOVE_IN = 'MOVE_IN',
    FADE_MOVE_IN = 'FADE_MOVE_IN',
    SCALE_IN = 'SCALE_IN',
    BLUR_IN = 'BLUR_IN',
    FADE_BLUR_IN = 'FADE_BLUR_IN',
    FADE_SCALE_IN = 'FADE_SCALE_IN',
    FLIP_IN = 'FLIP_IN',
    IRIS_IN = 'IRIS_IN',
    WIPE_IN = 'WIPE_IN',
    POP_IN = 'POP_IN',
}
