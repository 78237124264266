import React from 'react';
import { Button, Modal } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import modalContainer from 'packages/common/modalContainer';

type ModalProps = {
    show: boolean;
    pageName: string;
    onClose: () => void;
    onConfirm: () => void;
};

const PageDeleteModal = ({ show, pageName, onClose, onConfirm }: ModalProps) => {
    const { translate } = useTranslate();

    return (
        <Modal
            container={modalContainer}
            title={translate('editor.page.delete.modal.title', { pageName: pageName })}
            isOpen={show}
            onClose={onClose}
            actionSecondary={
                <Button variant="secondary" onClick={onClose}>
                    {translate('editor.page.delete.modal.cancel')}
                </Button>
            }
            actionPrimary={
                <Button variant="primary" onClick={onConfirm}>
                    {translate('editor.page.delete.modal.delete')}
                </Button>
            }
        >
            {translate('editor.page.delete.modal.content')}
        </Modal>
    );
};

export default PageDeleteModal;
