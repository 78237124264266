import { type MutableRefObject, useCallback, useEffect } from 'react';

export const useCanvasBorder = (manipulationRenderer, borderElementRef: MutableRefObject<HTMLDivElement | null>) => {
    const setBorderDimensions = useCallback(
        (canvasEl) => {
            if (!borderElementRef || !borderElementRef.current) {
                return;
            }

            const { width, height } = canvasEl.getBoundingClientRect();

            borderElementRef.current.style.width = `${width}px`; // eslint-disable-line no-param-reassign
            borderElementRef.current.style.height = `${height}px`; // eslint-disable-line no-param-reassign
        },
        [borderElementRef],
    );

    const checkBorderDimensions = useCallback(() => {
        if (!manipulationRenderer) {
            return;
        }

        const canvas = manipulationRenderer.compositor.getCanvas();
        setBorderDimensions(canvas);
    }, [manipulationRenderer, setBorderDimensions]);

    useEffect(() => {
        if (!manipulationRenderer) {
            return;
        }

        const canvas = manipulationRenderer.compositor.getCanvas();
        const mutationObserver = new MutationObserver(() => setBorderDimensions(canvas));
        setBorderDimensions(canvas);

        mutationObserver.observe(canvas, {
            attributes: true,
            attributeFilter: ['style'],
        });

        return () => {
            mutationObserver.disconnect();
        };
    }, [manipulationRenderer]);

    useEffect(() => {
        window.addEventListener('resize', checkBorderDimensions);

        return () => {
            window.removeEventListener('resize', checkBorderDimensions);
        };
    }, [checkBorderDimensions]);
};
