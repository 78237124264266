import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const TimelineWrapper = styled.div`
    position: relative;
    width: 100%;
    display: grid;
    grid-template-rows: 48px auto 28px;
    grid-template-columns: 47px 78px auto;
    height: 100%;
    border-radius: 5px;
    border: solid 1px ${token.colorShadow};
    background-color: ${token.white};
    color: ${token.colorText};
    overflow: visible;
`;

export const TimelinePrimaryAction = styled.div`
    grid-area: 1/1/2/3;
    border-bottom: 1px solid ${token.colorShadow};
    background-color: ${token.brandPrimary500};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s ease-out;
    z-index: 100;

    &.disabled {
        background-color: ${token.brandPrimary300};
    }

    > div {
        width: 100%;
        height: 100%;
    }
`;

export const TimelineZoomWrapper = styled.div`
    grid-area: 3/1/4/4;
    border-top: 1px solid ${token.colorShadow};
    display: flex;
    align-items: center;
    position: relative;
`;

export const TimelineTracksWrapper = styled.div`
    grid-area: 2/2/3/4;
    overflow: hidden;
`;

export const TimelineActionsWrapper = styled.div`
    grid-area: 2/1/3/2;
    border-right: 1px solid ${token.colorShadow};
`;

export const TimelineShotsWrapper = styled.div`
    grid-area: 1/3/2/4;
    border-bottom: 1px solid ${token.colorShadow};
`;

export const TimelineContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 350px;
    overflow: hidden;
`;

export const TimelineResizeLine = styled.div`
    min-width: 100%;
    min-height: 12px;
    cursor: row-resize;
    transform: translateY(2px);
`;
