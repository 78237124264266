import { useState, useEffect } from 'react';
import {
    type CreativeModelFactory,
    creativeModelFactory,
    type CreativeModelFactoryConfig,
} from '@bynder-studio/render-web';
import { CreativeTypes, FontFamily } from '@bynder-studio/render-core';
import { dataURItoArrayBuffer } from '~/helpers/helpers';
import { ignoreAbortErrors, makeAbortable } from 'packages/helpers/helpers';
import { CreativeType } from 'packages/types/creative';

type DesignConfig = Pick<
    CreativeModelFactoryConfig,
    'template' | 'contentProperties' | 'textStyles' | 'containerDocument'
> & {
    galleryFonts: FontFamily[] | undefined;
};

let defaultFontBlob: ArrayBuffer;

type InitOptions = {
    type: CreativeTypes;
    fontFamilies: FontFamily[];
    config: DesignConfig;
};

async function initCreativeModelFactory({ type, fontFamilies, config }: InitOptions) {
    if (!defaultFontBlob) {
        const defaultFontModule = await import('../../src/assets/fonts/SourceSansPro-Regular.ttf');
        defaultFontBlob = dataURItoArrayBuffer(defaultFontModule.default);
    }

    return creativeModelFactory(type, {
        ...config,
        fontFamilies,
        defaultFontBlob,
    });
}

export default function useCreativeModelFactory(
    type?: CreativeType,
    fonts?: FontFamily[],
    config?: DesignConfig | null | undefined,
) {
    const [factory, setFactory] = useState<CreativeModelFactory | null>(null);

    useEffect(() => {
        const abortController = new AbortController();

        const cleanup = () => {
            abortController.abort();
        };

        setFactory(null);

        if (!fonts || !config) {
            return cleanup;
        }

        makeAbortable(
            initCreativeModelFactory({
                type: type as CreativeTypes,
                fontFamilies: [...fonts, ...(config.galleryFonts ?? [])],
                config,
            }),
            abortController,
        )
            .then(setFactory)
            .catch(ignoreAbortErrors)
            .catch(console.error);

        return cleanup;
    }, [type, config]);

    return factory;
}
