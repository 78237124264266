import React from 'react';
import { ModalConfirmation, Button } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import modalContainer from 'packages/common/modalContainer';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
};

const DeleteAudioModal = ({ isOpen, onClose, onConfirm }: Props) => {
    const { translate } = useTranslate();

    return (
        <ModalConfirmation
            container={modalContainer}
            title={translate('design.sidebar.variations.modal.delete-audio.title')}
            isOpen={isOpen}
            onClose={onClose}
            actionPrimary={
                <Button variant="danger" onClick={onConfirm}>
                    {translate('design.sidebar.variations.modal.delete-audio.action')}
                </Button>
            }
            actionSecondary={
                <Button onClick={onClose}>{translate('design.sidebar.variations.modal.delete-audio.cancel')}</Button>
            }
        >
            {translate('design.sidebar.variations.modal.delete-audio.text')}
        </ModalConfirmation>
    );
};

export default DeleteAudioModal;
