import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const TimelineTrack = styled.div`
    display: flex;
    background-image: linear-gradient(to right, #acacac 20%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 5px 1px;
    background-repeat: repeat-x;

    &:first-child .timeline__track-name {
        border-top: 1px dashed ${token.gray200a};
    }

    .timeline__track-name {
        position: relative;
        z-index: 11;
        background-color: ${token.white};
        flex: 0 0 78px;
        padding: 14px 0 0 14px;
        border-bottom: 1px dashed ${token.gray200a};
    }

    .timeline__track-name span {
        color: ${token.colorTextMuted};
        font-size: 0.9em;
    }

    .timeline__subtracks {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        position: relative;
    }

    .timeline__subtrack-hoverd {
        background-color: ${token.gray200};
    }

    .timeline__subtrack {
        position: relative;
        flex: 1 0 44px;
        transition: background-color 0.2s ease-out;
    }

    .timeline__subtrack:hover {
        background-color: ${token.gray200a};
    }

    .timeline__subtrack:not(:last-child) {
        background-image: linear-gradient(to right, #acacac 20%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size: 5px 1px;
        background-repeat: repeat-x;
    }

    .timeline__subtrack-add_zone {
        position: absolute;
        height: 4px;
        width: 100%;
        top: -2px;
        transition: 0.2s ease-out;
        background-color: ${token.brandPrimary500};
        opacity: 0;
    }

    .timeline__subtrack-add_zone:hover {
        opacity: 1;
    }
`;
