export const framesToTime = (frames: number, frameRate: number) => {
    const symbol = frames?.toString().startsWith('-') ? '-' : '';
    frames = Math.abs(frames);
    const frame = frames % frameRate;
    frames = (frames - frame) / frameRate;
    const second = frames % 60;
    frames = (frames - second) / 60;

    const format = (value: number) => (value < 10 ? '0' + value : value);

    return `${symbol}${format(frames)}:${format(second)}:${format(frame)}`;
};

export const frameIndexToHTMLSeekTime = (frameIndex: number, frameRate: number) => {
    //TODO: frame accurate seeking is not well supported
    //https://bugs.chromium.org/p/chromium/issues/detail?id=66631
    //seek to just before next frame (seek-to-closest-yet-not-past-keyframe)
    //NOTE: only use this for video seeking, not for displaying
    return frameIndex / frameRate + (1 / frameRate - 0.001);
};
