import styled from 'styled-components';
import { Thumbnail, MultiSelectChips, token } from '@bynder/design-system';

export const LoadingWrapper = styled.div`
    text-align: center;
`;

export const WarningWrapper = styled(Thumbnail.Indicator)`
    width: 16px;
    height: 16px;
    right: -4px;
    bottom: -4px;
`;

export const StyledMultiSelectChips = styled(MultiSelectChips)<{ disabled: boolean }>`
    & > div[class*='Container'] {
        ${({ disabled }) =>
            disabled &&
            `
        border-color: ${token.red500};
        `}
    }

    & > div[class*='Container'] > div > div[class*='IconRightContainer'] {
        display: none;
    }
`;
