import CanvasManipulationCompositor from './CanvasManipulationCompositor';

class BaseManipulationCompositor {
    compositor: CanvasManipulationCompositor;
    ctx: CanvasRenderingContext2D;
    devicePixelRatio: number;

    constructor(compositor, ctx, dpr) {
        this.ctx = ctx;
        this.compositor = compositor;
        this.devicePixelRatio = dpr;
    }

    applyTranslation(deltaX, deltaY, ctx) {
        ctx.translate(this.s(deltaX), this.s(deltaY));
    }

    applyRotation(deltaX, deltaY, rotation, ctx, withoutScale = false) {
        if (rotation % 360 !== 0) {
            const finalDeltaX = withoutScale ? deltaX : this.s(deltaX);
            const finalDeltaY = withoutScale ? deltaY : this.s(deltaY);

            ctx.translate(finalDeltaX, finalDeltaY);
            ctx.rotate((Math.PI / 180) * rotation);
            ctx.translate(-finalDeltaX, -finalDeltaY);
        }
    }

    drawRoundedRect = (x, y, w, h, radius) => {
        const r = x + w;
        const b = y + h;
        this.ctx.moveTo(x + radius, y);
        this.ctx.lineTo(r - radius, y);
        this.ctx.quadraticCurveTo(r, y, r, y + radius);
        this.ctx.lineTo(r, y + h - radius);
        this.ctx.quadraticCurveTo(r, b, r - radius, b);
        this.ctx.lineTo(x + radius, b);
        this.ctx.quadraticCurveTo(x, b, x, b - radius);
        this.ctx.lineTo(x, y + radius);
        this.ctx.quadraticCurveTo(x, y, x + radius, y);
        this.ctx.stroke();
    };

    s(n: number): number {
        // shorthand scale function, apply to everything related to coordinates
        return n * this.compositor.getScale() * this.devicePixelRatio;
    }
}

export default BaseManipulationCompositor;
