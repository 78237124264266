import React, { createContext, useCallback, useEffect, useRef } from 'react';
import useForceUpdate from '../hooks/useForceUpdate';
import useEditor from '../hooks/useEditor';

export const ShotsContext = createContext({});

export function ShotsProvider({ children }) {
    const forceUpdate = useForceUpdate();
    const { creativeModelFactory, creativeModel } = useEditor();
    const data = useRef({
        shotRenderer: null,
        shotsImages: [],
        shots: [],
    });

    const handleFrameUpdate = useCallback(
        ({ shotIndex, dataUrl }) => {
            data.current.shotsImages[shotIndex] = dataUrl;
            forceUpdate();
        },
        [forceUpdate],
    );

    useEffect(() => {
        const { shotRenderer } = data.current;

        if (!creativeModel || !shotRenderer) {
            return;
        }

        const handler = () => {
            shotRenderer.drawShots().catch(console.error);
        };

        creativeModel.on('dimensionUpdated', handler);
        creativeModel.on('currentPageChange', handler);

        return () => {
            creativeModel.off('dimensionUpdated', handler);
            creativeModel.off('currentPageChange', handler);
        };
    }, [creativeModel, data.current.shotRenderer]);

    useEffect(() => {
        if (!creativeModelFactory) {
            return;
        }

        const shotRenderer = creativeModelFactory.getShotRenderer();
        shotRenderer.setShotSize(32, 32);
        shotRenderer.drawShots().catch(console.error);
        data.current.shotRenderer = shotRenderer;

        shotRenderer.on('frameUpdate', handleFrameUpdate);

        return () => shotRenderer.off('frameUpdate', handleFrameUpdate);
    }, [creativeModelFactory, handleFrameUpdate]);

    useEffect(() => {
        if (!creativeModel) {
            return;
        }

        data.current.shots = creativeModel.getShots();
        const handleShotsReplaced = () => {
            data.current.shots = creativeModel.getShots();
            forceUpdate();
        };

        creativeModel.on('shotsReplaced', handleShotsReplaced);
        creativeModel.on('currentPageChange', handleShotsReplaced);

        return () => {
            creativeModel.off('shotsReplaced', handleShotsReplaced);
            creativeModel.off('currentPageChange', handleShotsReplaced);
        };
    }, [creativeModel, forceUpdate]);

    const value = {
        ...data.current,
    };

    return <ShotsContext.Provider value={value}>{children}</ShotsContext.Provider>;
}
