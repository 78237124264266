import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';
import AuthenticationHelper from '~/helpers/AuthenticationHelper';

const options = {
    host: `${WS_ENDPOINT}/ws`,
};

let clientPromise: Promise<Client> | null = null;
let removeClientTimeoutId = -1;
const disconnectCallbacks = new Set();

const getConnectionOptions = () => ({
    brokerURL: `ws://${options.host}`,
    connectHeaders: {
        'X-CSRF-TOKEN': '',
        // always use actual token
        get authorization() {
            return `Bearer ${AuthenticationHelper.getToken()}`;
        },
    },
    // debug: function (str) {
    //     console.log(str);
    // },
    reconnectDelay: 500,
    heartbeatIncoming: 3000,
    heartbeatOutgoing: 3000,
});

function createClient() {
    const newClient = new Client(getConnectionOptions());

    newClient.onStompError = (frame) => {
        // Will be invoked in case of error encountered at Broker
        // Bad login/passcode typically will cause an error
        // Complaint brokers will set `message` header with a brief message. Body may contain details.
        // Compliant brokers will terminate the connection after any error

        const msg = `
        Broker reported error: ${frame.headers.message}

        Additional details: ${frame.body}
        `;

        console.error(msg);
    };

    // Fallback code
    // if (typeof WebSocket !== 'function') {
    // For SockJS you need to set a factory that creates a new SockJS instance
    // to be used for each (re)connect
    newClient.webSocketFactory = () =>
        // Note that the URL is different from the WebSocket URL
        new SockJS(`https://${options.host}`);
    // }

    newClient.activate();

    return new Promise<Client>((resolve) => {
        newClient.onConnect = () => resolve(newClient);
    });
}

async function removeClient() {
    if (!clientPromise) {
        return;
    }

    const oldClientPromise = clientPromise;
    clientPromise = null;

    const oldClient = await oldClientPromise;
    await oldClient.deactivate();
}

async function publishMessage(destination: string, data: any) {
    if (!clientPromise) {
        return;
    }

    const client = await clientPromise;

    // handle JSON.stringify and publish errors
    try {
        client.publish({
            destination,
            headers: getConnectionOptions().connectHeaders,
            body: JSON.stringify(data),
        });
    } catch (e) {
        console.error(e);
    }
}

export async function socketConnect() {
    if (!clientPromise) {
        // do not await here, we need to assign clientPromise before it resolves
        // to prevent multiple clients from being created
        clientPromise = createClient();
    }

    const disconnect = () => {
        if (!disconnectCallbacks.has(disconnect)) {
            return;
        }

        disconnectCallbacks.delete(disconnect);

        window.clearTimeout(removeClientTimeoutId);
        removeClientTimeoutId = window.setTimeout(() => {
            if (disconnectCallbacks.size === 0) {
                removeClient();
            }
        }, 3000);
    };

    disconnectCallbacks.add(disconnect);

    return { client: await clientPromise, disconnect, publishMessage };
}
