import React, { useCallback, useEffect, useMemo } from 'react';
import { IconShow, IconHide } from '@bynder/icons';
import { ElementUpdateTypes } from '@bynder-studio/render-core';
import useEditor from '../../../../../hooks/useEditor';
import useForceUpdate from '../../../../../hooks/useForceUpdate';
import { collectTopLevelElements } from './utils';
import generateTestId from '~/helpers/testIdHelpers';
import useGeneralHotkeys from 'packages/pages/editor/useGeneralHotKeys';
import { TimelineActionsIcon } from '../TimelineActions.styled';

export default function VisibilityAction() {
    const { creativeModel, manipulationRenderer } = useEditor();
    const selectedElements = manipulationRenderer?.getSelectedElements() || [];
    const forceUpdate = useForceUpdate();
    const isActive = !!selectedElements.length;
    const isSelected = isActive && collectTopLevelElements(selectedElements).every((el) => el.hidden);

    const handleVisibilityChange = useCallback(() => {
        if (!isActive) {
            return;
        }

        collectTopLevelElements(selectedElements).forEach((element) => {
            creativeModel.updateElement(element.id, { hidden: !isSelected });
        });
        forceUpdate();
    }, [isActive, isSelected, selectedElements, creativeModel, forceUpdate]);

    const handleVisibilityChangeAction = useCallback(
        ({ updateTypes, element }) => {
            if (updateTypes.has(ElementUpdateTypes.VISIBILITY) && selectedElements.some((el) => el.id === element.id)) {
                forceUpdate();
            }
        },
        [selectedElements, forceUpdate],
    );

    useGeneralHotkeys({ handleHide: handleVisibilityChange });

    const testId = useMemo(() => generateTestId('timeline_actions_visibility'), []);

    useEffect(() => {
        if (!manipulationRenderer) {
            return;
        }

        manipulationRenderer.eventEmitter.on('elementSelected', forceUpdate);
        return () => manipulationRenderer.eventEmitter.off('elementSelected', forceUpdate);
    }, [forceUpdate, manipulationRenderer]);

    useEffect(() => {
        if (!creativeModel) {
            return;
        }

        creativeModel.on('elementUpdated', handleVisibilityChangeAction);
        return () => creativeModel.off('elementUpdated', handleVisibilityChangeAction);
    }, [creativeModel, handleVisibilityChangeAction]);

    return (
        <div title="Toggle Element Visibility">
            <TimelineActionsIcon onClick={handleVisibilityChange} active={isActive} selected={isSelected} {...testId}>
                {isSelected ? <IconHide /> : <IconShow />}
            </TimelineActionsIcon>
        </div>
    );
}
