import { useDispatch, useSelector } from 'react-redux';
import { getGallery } from 'packages/store/gallery/gallery.selectors';
import useQueryParams from 'packages/hooks/useQueryParams';
import debounce from '~/helpers/debounce';
import { fetchGallery } from '~/store/gallery/gallery.actions';
import { MediaFilterType } from '../../../components/filters/components/MediaType/types';

const useTemplatesFilters = () => {
    // if you want to predefine filters value on page load
    // create the filters state here and pass a value as a prop directly to filters
    const dispatch = useDispatch();
    const { setQueryParam } = useQueryParams();

    const { fetchOptions: options } = useSelector(getGallery);

    const onSearch = debounce(
        (newSearchValue: string) => {
            const newOptions = { ...options };
            newOptions.search = newSearchValue;

            dispatch(fetchGallery(newOptions, false, true));

            setQueryParam('search', newSearchValue);
        },
        1000,
        false,
    );

    const onMediaTypeSelect = (type: MediaFilterType) => {
        const newOptions = { ...options };

        if (type === MediaFilterType.ANY) {
            newOptions.creativeType = undefined;
        } else {
            newOptions.creativeType = type;
        }
        dispatch(fetchGallery(newOptions, false, true));

        setQueryParam('typeDesign', type.toLowerCase());
    };

    return { onSearch, onMediaTypeSelect };
};

export default useTemplatesFilters;
