export const Tabs = {
    Download: 'download',
    AllExports: 'all_exports',
    WaitingRoom: 'bynder',
} as const;

export const ExportTypes = {
    [Tabs.Download]: 'DOWNLOAD',
    [Tabs.WaitingRoom]: 'BYNDER',
} as const;

export const ExportNames = {
    [Tabs.Download]: 'Download',
    [Tabs.WaitingRoom]: 'Export to Waiting Room',
} as const;
