export const animations = {
    animationsOut: [
        {
            type: 'FADE_SCALE_OUT',
            applyType: 'OUT',
            label: 'Fade and scale out',
            properties: [
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: 15,
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: 'OUT',
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MAXIMUM',
                            displayValue: 'Ease in maximum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MINIMUM',
                            displayValue: 'Ease in minimum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MEDIUM',
                            displayValue: 'Ease in medium',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'scale',
                    type: 'NUMERIC',
                    elementType: null,
                    label: 'Scale to',
                    defaultValue: 0,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'textBreakup',
                    type: 'TEXT_BREAKUP',
                    elementType: null,
                    label: 'Text breakup',
                    defaultValue: null,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'motionBlur',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Motion blur',
                    defaultValue: 'true',
                    displayOrder: 11,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
            ],
        },
        {
            type: 'WIPE_OUT',
            applyType: 'OUT',
            label: 'Wipe out',
            properties: [
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: 15,
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: 'OUT',
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MAXIMUM',
                            displayValue: 'Ease in maximum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MINIMUM',
                            displayValue: 'Ease in minimum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MEDIUM',
                            displayValue: 'Ease in medium',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'direction',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Direction',
                    defaultValue: 'UP',
                    displayOrder: 4,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'DIRECTION',
                        filter: 'DIAGONAL',
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            filter: 'DIAGONAL',
                            value: 'BOTTOM_RIGHT',
                            displayValue: '↘ Bottom Right',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'RIGHT',
                            displayValue: '→ Right',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            filter: 'DIAGONAL',
                            value: 'BOTTOM_LEFT',
                            displayValue: '↙ Bottom Left',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            filter: 'DIAGONAL',
                            value: 'TOP_LEFT',
                            displayValue: '↖ Top Left',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'LEFT',
                            displayValue: '← Left',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            filter: 'DIAGONAL',
                            value: 'TOP_RIGHT',
                            displayValue: '↗ Top Right',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'UP',
                            displayValue: '↑ Up',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'DOWN',
                            displayValue: '↓ Down',
                        },
                    ],
                },
                {
                    name: 'textBreakup',
                    type: 'TEXT_BREAKUP',
                    elementType: null,
                    label: 'Text breakup',
                    defaultValue: null,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'softness',
                    type: 'NUMERIC',
                    elementType: null,
                    label: 'Softness',
                    defaultValue: 0,
                    displayOrder: 6,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'motionBlur',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Motion blur',
                    defaultValue: 'true',
                    displayOrder: 11,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
            ],
        },
        {
            type: 'POP_OUT',
            applyType: 'OUT',
            label: 'Pop out',
            properties: [
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: 15,
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: 'OUT',
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MAXIMUM',
                            displayValue: 'Ease in maximum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MINIMUM',
                            displayValue: 'Ease in minimum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MEDIUM',
                            displayValue: 'Ease in medium',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'textBreakup',
                    type: 'TEXT_BREAKUP',
                    elementType: null,
                    label: 'Text breakup',
                    defaultValue: null,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'motionBlur',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Motion blur',
                    defaultValue: 'true',
                    displayOrder: 11,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
            ],
        },
        {
            type: 'MOVE_OUT',
            applyType: 'OUT',
            label: 'Move out',
            properties: [
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: 15,
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: 'OUT',
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MAXIMUM',
                            displayValue: 'Ease in maximum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MINIMUM',
                            displayValue: 'Ease in minimum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MEDIUM',
                            displayValue: 'Ease in medium',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'direction',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Direction',
                    defaultValue: 'UP',
                    displayOrder: 4,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'DIRECTION',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'RIGHT',
                            displayValue: '→ Right',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'LEFT',
                            displayValue: '← Left',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'UP',
                            displayValue: '↑ Up',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'DOWN',
                            displayValue: '↓ Down',
                        },
                    ],
                },
                {
                    name: 'distance',
                    type: 'NUMERIC',
                    elementType: null,
                    label: 'Distance',
                    defaultValue: 1,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'textBreakup',
                    type: 'TEXT_BREAKUP',
                    elementType: null,
                    label: 'Text breakup',
                    defaultValue: null,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'mask',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Mask',
                    defaultValue: 'false',
                    displayOrder: 10,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
                {
                    name: 'motionBlur',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Motion blur',
                    defaultValue: 'true',
                    displayOrder: 11,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
            ],
        },
        {
            type: 'FADE_MOVE_OUT',
            applyType: 'OUT',
            label: 'Fade and Move out',
            properties: [
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: 15,
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: 'OUT',
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MAXIMUM',
                            displayValue: 'Ease in maximum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MINIMUM',
                            displayValue: 'Ease in minimum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MEDIUM',
                            displayValue: 'Ease in medium',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'direction',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Direction',
                    defaultValue: 'UP',
                    displayOrder: 4,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'DIRECTION',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'RIGHT',
                            displayValue: '→ Right',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'LEFT',
                            displayValue: '← Left',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'UP',
                            displayValue: '↑ Up',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'DOWN',
                            displayValue: '↓ Down',
                        },
                    ],
                },
                {
                    name: 'distance',
                    type: 'NUMERIC',
                    elementType: null,
                    label: 'Distance',
                    defaultValue: 1,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'textBreakup',
                    type: 'TEXT_BREAKUP',
                    elementType: null,
                    label: 'Text breakup',
                    defaultValue: null,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'mask',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Mask',
                    defaultValue: 'false',
                    displayOrder: 10,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
                {
                    name: 'motionBlur',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Motion blur',
                    defaultValue: 'true',
                    displayOrder: 11,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
            ],
        },
        {
            type: 'SCALE_OUT',
            applyType: 'OUT',
            label: 'Scale out',
            properties: [
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: 15,
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: 'OUT',
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MAXIMUM',
                            displayValue: 'Ease in maximum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MINIMUM',
                            displayValue: 'Ease in minimum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MEDIUM',
                            displayValue: 'Ease in medium',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'scale',
                    type: 'NUMERIC',
                    elementType: null,
                    label: 'Scale to',
                    defaultValue: 0,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'textBreakup',
                    type: 'TEXT_BREAKUP',
                    elementType: null,
                    label: 'Text breakup',
                    defaultValue: null,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'motionBlur',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Motion blur',
                    defaultValue: 'true',
                    displayOrder: 11,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
            ],
        },
        {
            type: 'FADE_OUT',
            applyType: 'OUT',
            label: 'Fade out',
            properties: [
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: 15,
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: 'OUT',
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MAXIMUM',
                            displayValue: 'Ease in maximum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MINIMUM',
                            displayValue: 'Ease in minimum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MEDIUM',
                            displayValue: 'Ease in medium',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'opacity',
                    type: 'NUMERIC',
                    elementType: null,
                    label: 'Fade to',
                    defaultValue: 0,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'textBreakup',
                    type: 'TEXT_BREAKUP',
                    elementType: null,
                    label: 'Text breakup',
                    defaultValue: null,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
            ],
        },
        {
            type: 'FLIP_OUT',
            applyType: 'OUT',
            label: 'Flip out',
            properties: [
                {
                    name: 'direction',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Direction',
                    defaultValue: 'BOTTOM_TO_TOP',
                    displayOrder: 1,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'FLIP_DIRECTION',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'FLIP_DIRECTION',
                            value: 'RANDOM',
                            displayValue: 'Random',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'FLIP_DIRECTION',
                            value: 'BOTTOM_TO_TOP',
                            displayValue: 'Bottom to top',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'FLIP_DIRECTION',
                            value: 'RIGHT_TO_LEFT',
                            displayValue: 'Right to left',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'FLIP_DIRECTION',
                            value: 'TOP_TO_BOTTOM',
                            displayValue: 'Top to bottom',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'FLIP_DIRECTION',
                            value: 'LEFT_TO_RIGHT',
                            displayValue: 'Left to right',
                        },
                    ],
                },
                {
                    name: 'breakup',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Breakup',
                    defaultValue: '1',
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'FLIP_BREAKUP',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'FLIP_BREAKUP',
                            value: '2',
                            displayValue: '2',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'FLIP_BREAKUP',
                            value: '1',
                            displayValue: '1',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'FLIP_BREAKUP',
                            value: '4',
                            displayValue: '4',
                        },
                    ],
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 4,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: 'OUT',
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MAXIMUM',
                            displayValue: 'Ease in maximum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MINIMUM',
                            displayValue: 'Ease in minimum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MEDIUM',
                            displayValue: 'Ease in medium',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: 15,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'textBreakup',
                    type: 'TEXT_BREAKUP',
                    elementType: null,
                    label: 'Text breakup',
                    defaultValue: null,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'motionBlur',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Motion blur',
                    defaultValue: 'true',
                    displayOrder: 11,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
            ],
        },
        {
            type: 'IRIS_OUT',
            applyType: 'OUT',
            label: 'Iris out',
            properties: [
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: 15,
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: 'OUT',
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MAXIMUM',
                            displayValue: 'Ease in maximum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MINIMUM',
                            displayValue: 'Ease in minimum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MEDIUM',
                            displayValue: 'Ease in medium',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'textBreakup',
                    type: 'TEXT_BREAKUP',
                    elementType: null,
                    label: 'Text breakup',
                    defaultValue: null,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'motionBlur',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Motion blur',
                    defaultValue: 'true',
                    displayOrder: 11,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
            ],
        },
        {
            type: 'FADE_BLUR_OUT',
            applyType: 'OUT',
            label: 'Fade and Blur out',
            properties: [
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: 15,
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: 'OUT',
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MAXIMUM',
                            displayValue: 'Ease in maximum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MINIMUM',
                            displayValue: 'Ease in minimum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'OUT',
                            value: 'EASE_IN_MEDIUM',
                            displayValue: 'Ease in medium',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'blur',
                    type: 'NUMERIC',
                    elementType: null,
                    label: 'Blur to',
                    defaultValue: 1,
                    displayOrder: 4,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'textBreakup',
                    type: 'TEXT_BREAKUP',
                    elementType: null,
                    label: 'Text breakup',
                    defaultValue: null,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
            ],
        },
    ],
    animationsConstant: [
        {
            type: 'BLINK',
            applyType: 'CONSTANT',
            label: 'Blink',
            properties: [
                {
                    name: 'startFrame',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Start frame',
                    defaultValue: 0,
                    displayOrder: 1,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: {
                        defaultValue: 0,
                    },
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'blinks',
                    type: 'NUMERIC',
                    elementType: null,
                    label: 'Blinks',
                    defaultValue: 0,
                    displayOrder: 4,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
            ],
        },
        {
            type: 'SCALE',
            applyType: 'CONSTANT',
            label: 'Scale',
            properties: [
                {
                    name: 'startFrame',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Start frame',
                    defaultValue: 0,
                    displayOrder: 1,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: {
                        defaultValue: 0,
                    },
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'scale',
                    type: 'NUMERIC',
                    elementType: null,
                    label: 'Scale',
                    defaultValue: 1,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'motionBlur',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Motion blur',
                    defaultValue: 'true',
                    displayOrder: 11,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
            ],
        },
        {
            type: 'DATA_IMPORT',
            applyType: 'CONSTANT',
            label: 'Import Data',
            properties: [
                {
                    name: 'data',
                    type: 'DATA_IMPORT_LIST',
                    elementType: null,
                    label: 'Import Data',
                    defaultValue: null,
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'motionBlur',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Motion blur',
                    defaultValue: 'true',
                    displayOrder: 11,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
            ],
        },
        {
            type: 'MOVE',
            applyType: 'CONSTANT',
            label: 'Move',
            properties: [
                {
                    name: 'startFrame',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Start frame',
                    defaultValue: 0,
                    displayOrder: 1,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: {
                        defaultValue: 0,
                    },
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'horizontalPosition',
                    type: 'NUMERIC',
                    elementType: null,
                    label: 'X',
                    defaultValue: 0,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'verticalPosition',
                    type: 'NUMERIC',
                    elementType: null,
                    label: 'Y',
                    defaultValue: 0,
                    displayOrder: 8,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'motionBlur',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Motion blur',
                    defaultValue: 'true',
                    displayOrder: 11,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
            ],
        },
        {
            type: 'CORNER_PIN',
            applyType: 'CONSTANT',
            label: 'Corner Pin',
            properties: [
                {
                    name: 'pins',
                    type: 'CORNER_PIN_LIST',
                    elementType: null,
                    label: 'Corner Pin',
                    defaultValue: null,
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'motionBlur',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Motion blur',
                    defaultValue: 'true',
                    displayOrder: 11,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
            ],
        },
        {
            type: 'ROTATE',
            applyType: 'CONSTANT',
            label: 'Rotate',
            properties: [
                {
                    name: 'startFrame',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Start frame',
                    defaultValue: 0,
                    displayOrder: 1,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: {
                        defaultValue: 0,
                    },
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'angle',
                    type: 'NUMERIC',
                    elementType: null,
                    label: 'Angle',
                    defaultValue: 0,
                    displayOrder: 4,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'rotations',
                    type: 'NUMERIC',
                    elementType: null,
                    label: 'Rotations',
                    defaultValue: 0,
                    displayOrder: 6,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'motionBlur',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Motion blur',
                    defaultValue: 'true',
                    displayOrder: 11,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
            ],
        },
        {
            type: 'POP',
            applyType: 'CONSTANT',
            label: 'Pop',
            properties: [
                {
                    name: 'startFrame',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Start frame',
                    defaultValue: 0,
                    displayOrder: 1,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: {
                        defaultValue: 0,
                    },
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'scale',
                    type: 'NUMERIC',
                    elementType: null,
                    label: 'Scale',
                    defaultValue: 1,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'motionBlur',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Motion blur',
                    defaultValue: 'true',
                    displayOrder: 11,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
            ],
        },
    ],
    animationsIn: [
        {
            type: 'IRIS_IN',
            applyType: 'IN',
            label: 'Iris in',
            properties: [
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: 15,
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: 'IN',
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MEDIUM',
                            displayValue: 'Ease out medium',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MINIMUM',
                            displayValue: 'Ease out minimum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MAXIMUM',
                            displayValue: 'Ease out maximum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'textBreakup',
                    type: 'TEXT_BREAKUP',
                    elementType: null,
                    label: 'Text breakup',
                    defaultValue: null,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'motionBlur',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Motion blur',
                    defaultValue: 'true',
                    displayOrder: 11,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
            ],
        },
        {
            type: 'FLIP_IN',
            applyType: 'IN',
            label: 'Flip in',
            properties: [
                {
                    name: 'direction',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Direction',
                    defaultValue: 'BOTTOM_TO_TOP',
                    displayOrder: 1,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'FLIP_DIRECTION',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'FLIP_DIRECTION',
                            value: 'RANDOM',
                            displayValue: 'Random',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'FLIP_DIRECTION',
                            value: 'BOTTOM_TO_TOP',
                            displayValue: 'Bottom to top',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'FLIP_DIRECTION',
                            value: 'RIGHT_TO_LEFT',
                            displayValue: 'Right to left',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'FLIP_DIRECTION',
                            value: 'TOP_TO_BOTTOM',
                            displayValue: 'Top to bottom',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'FLIP_DIRECTION',
                            value: 'LEFT_TO_RIGHT',
                            displayValue: 'Left to right',
                        },
                    ],
                },
                {
                    name: 'bounce',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Bounce',
                    defaultValue: 'false',
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'BOUNCE',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'BOUNCE',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'BOUNCE',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
                {
                    name: 'breakup',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Breakup',
                    defaultValue: '1',
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'FLIP_BREAKUP',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'FLIP_BREAKUP',
                            value: '2',
                            displayValue: '2',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'FLIP_BREAKUP',
                            value: '1',
                            displayValue: '1',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'FLIP_BREAKUP',
                            value: '4',
                            displayValue: '4',
                        },
                    ],
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 4,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: 'IN',
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MEDIUM',
                            displayValue: 'Ease out medium',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MINIMUM',
                            displayValue: 'Ease out minimum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MAXIMUM',
                            displayValue: 'Ease out maximum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'textBreakup',
                    type: 'TEXT_BREAKUP',
                    elementType: null,
                    label: 'Text breakup',
                    defaultValue: null,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: 15,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'motionBlur',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Motion blur',
                    defaultValue: 'true',
                    displayOrder: 11,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
            ],
        },
        {
            type: 'FADE_SCALE_IN',
            applyType: 'IN',
            label: 'Fade and scale in',
            properties: [
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: 15,
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: 'IN',
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MEDIUM',
                            displayValue: 'Ease out medium',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MINIMUM',
                            displayValue: 'Ease out minimum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MAXIMUM',
                            displayValue: 'Ease out maximum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'scale',
                    type: 'NUMERIC',
                    elementType: null,
                    label: 'Scale from',
                    defaultValue: 0,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'textBreakup',
                    type: 'TEXT_BREAKUP',
                    elementType: null,
                    label: 'Text breakup',
                    defaultValue: null,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'motionBlur',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Motion blur',
                    defaultValue: 'true',
                    displayOrder: 11,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
            ],
        },
        {
            type: 'FADE_BLUR_IN',
            applyType: 'IN',
            label: 'Fade and Blur in',
            properties: [
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: 15,
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: 'IN',
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MEDIUM',
                            displayValue: 'Ease out medium',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MINIMUM',
                            displayValue: 'Ease out minimum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MAXIMUM',
                            displayValue: 'Ease out maximum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'blur',
                    type: 'NUMERIC',
                    elementType: null,
                    label: 'Blur from',
                    defaultValue: 1,
                    displayOrder: 4,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'textBreakup',
                    type: 'TEXT_BREAKUP',
                    elementType: null,
                    label: 'Text breakup',
                    defaultValue: null,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
            ],
        },
        {
            type: 'FADE_MOVE_IN',
            applyType: 'IN',
            label: 'Fade and Move in',
            properties: [
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: 15,
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: 'IN',
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MEDIUM',
                            displayValue: 'Ease out medium',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MINIMUM',
                            displayValue: 'Ease out minimum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MAXIMUM',
                            displayValue: 'Ease out maximum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'direction',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Direction',
                    defaultValue: 'UP',
                    displayOrder: 4,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'DIRECTION',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'RIGHT',
                            displayValue: '→ Right',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'LEFT',
                            displayValue: '← Left',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'UP',
                            displayValue: '↑ Up',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'DOWN',
                            displayValue: '↓ Down',
                        },
                    ],
                },
                {
                    name: 'distance',
                    type: 'NUMERIC',
                    elementType: null,
                    label: 'Distance',
                    defaultValue: 1,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'textBreakup',
                    type: 'TEXT_BREAKUP',
                    elementType: null,
                    label: 'Text breakup',
                    defaultValue: null,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'mask',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Mask',
                    defaultValue: 'false',
                    displayOrder: 10,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
                {
                    name: 'motionBlur',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Motion blur',
                    defaultValue: 'true',
                    displayOrder: 11,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
            ],
        },
        {
            type: 'SCALE_IN',
            applyType: 'IN',
            label: 'Scale in',
            properties: [
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: 15,
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: 'IN',
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MEDIUM',
                            displayValue: 'Ease out medium',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MINIMUM',
                            displayValue: 'Ease out minimum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MAXIMUM',
                            displayValue: 'Ease out maximum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'scale',
                    type: 'NUMERIC',
                    elementType: null,
                    label: 'Scale from',
                    defaultValue: 0,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'textBreakup',
                    type: 'TEXT_BREAKUP',
                    elementType: null,
                    label: 'Text breakup',
                    defaultValue: null,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'motionBlur',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Motion blur',
                    defaultValue: 'true',
                    displayOrder: 11,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
            ],
        },
        {
            type: 'POP_IN',
            applyType: 'IN',
            label: 'Pop in',
            properties: [
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: 15,
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: 'IN',
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MEDIUM',
                            displayValue: 'Ease out medium',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MINIMUM',
                            displayValue: 'Ease out minimum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MAXIMUM',
                            displayValue: 'Ease out maximum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'textBreakup',
                    type: 'TEXT_BREAKUP',
                    elementType: null,
                    label: 'Text breakup',
                    defaultValue: null,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'motionBlur',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Motion blur',
                    defaultValue: 'true',
                    displayOrder: 11,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
            ],
        },
        {
            type: 'WIPE_IN',
            applyType: 'IN',
            label: 'Wipe in',
            properties: [
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: 15,
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: 'IN',
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MEDIUM',
                            displayValue: 'Ease out medium',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MINIMUM',
                            displayValue: 'Ease out minimum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MAXIMUM',
                            displayValue: 'Ease out maximum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'direction',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Direction',
                    defaultValue: 'UP',
                    displayOrder: 4,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'DIRECTION',
                        filter: 'DIAGONAL',
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            filter: 'DIAGONAL',
                            value: 'BOTTOM_RIGHT',
                            displayValue: '↘ Bottom Right',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'RIGHT',
                            displayValue: '→ Right',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            filter: 'DIAGONAL',
                            value: 'BOTTOM_LEFT',
                            displayValue: '↙ Bottom Left',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            filter: 'DIAGONAL',
                            value: 'TOP_LEFT',
                            displayValue: '↖ Top Left',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'LEFT',
                            displayValue: '← Left',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            filter: 'DIAGONAL',
                            value: 'TOP_RIGHT',
                            displayValue: '↗ Top Right',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'UP',
                            displayValue: '↑ Up',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'DOWN',
                            displayValue: '↓ Down',
                        },
                    ],
                },
                {
                    name: 'textBreakup',
                    type: 'TEXT_BREAKUP',
                    elementType: null,
                    label: 'Text breakup',
                    defaultValue: null,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'softness',
                    type: 'NUMERIC',
                    elementType: null,
                    label: 'Softness',
                    defaultValue: 0,
                    displayOrder: 6,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'motionBlur',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Motion blur',
                    defaultValue: 'true',
                    displayOrder: 11,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
            ],
        },
        {
            type: 'MOVE_IN',
            applyType: 'IN',
            label: 'Move in',
            properties: [
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: 15,
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: 'IN',
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MEDIUM',
                            displayValue: 'Ease out medium',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MINIMUM',
                            displayValue: 'Ease out minimum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MAXIMUM',
                            displayValue: 'Ease out maximum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'direction',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Direction',
                    defaultValue: 'UP',
                    displayOrder: 4,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'DIRECTION',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'RIGHT',
                            displayValue: '→ Right',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'LEFT',
                            displayValue: '← Left',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'UP',
                            displayValue: '↑ Up',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'DIRECTION',
                            value: 'DOWN',
                            displayValue: '↓ Down',
                        },
                    ],
                },
                {
                    name: 'distance',
                    type: 'NUMERIC',
                    elementType: null,
                    label: 'Distance',
                    defaultValue: 1,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'textBreakup',
                    type: 'TEXT_BREAKUP',
                    elementType: null,
                    label: 'Text breakup',
                    defaultValue: null,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'mask',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Mask',
                    defaultValue: 'false',
                    displayOrder: 10,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
                {
                    name: 'motionBlur',
                    type: 'SELECT',
                    elementType: null,
                    label: 'Motion blur',
                    defaultValue: 'true',
                    displayOrder: 11,
                    required: null,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'ONOFF',
                        filter: null,
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'false',
                            displayValue: 'Off',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'ONOFF',
                            value: 'true',
                            displayValue: 'On',
                        },
                    ],
                },
            ],
        },
        {
            type: 'FADE_IN',
            applyType: 'IN',
            label: 'Fade in',
            properties: [
                {
                    name: 'duration',
                    type: 'DURATION',
                    elementType: null,
                    label: 'Duration',
                    defaultValue: 15,
                    displayOrder: 2,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'timing',
                    type: 'ANIMATION_TIMING',
                    elementType: null,
                    label: 'Timing',
                    defaultValue: {
                        type: 'FUNCTION',
                        value: 'LINEAR',
                    },
                    displayOrder: 3,
                    required: true,
                    visible: true,
                    dataset: {
                        type: 'ANIMATION',
                        subtype: 'TIMING',
                        filter: 'IN',
                    },
                    options: [
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MEDIUM',
                            displayValue: 'Ease out medium',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MINIMUM',
                            displayValue: 'Ease out minimum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE',
                            displayValue: 'Ease',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'EASE_IN_OUT',
                            displayValue: 'Ease in out',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            filter: 'IN',
                            value: 'EASE_OUT_MAXIMUM',
                            displayValue: 'Ease out maximum',
                        },
                        {
                            type: 'ANIMATION',
                            subtype: 'TIMING',
                            value: 'LINEAR',
                            displayValue: 'Linear',
                        },
                    ],
                },
                {
                    name: 'textBreakup',
                    type: 'TEXT_BREAKUP',
                    elementType: null,
                    label: 'Text breakup',
                    defaultValue: null,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
                {
                    name: 'opacity',
                    type: 'NUMERIC',
                    elementType: null,
                    label: 'Fade from',
                    defaultValue: 0,
                    displayOrder: 5,
                    required: true,
                    visible: true,
                    dataset: null,
                    options: null,
                },
            ],
        },
    ],
};
