import React, { FC, useEffect, useState } from 'react';
import { Input } from '@bynder/design-system';
import generateTestId from '~/helpers/testIdHelpers';
import editorAutocorrect from 'packages/pages/editor/EditorAutocorrectRules';

type FadeInputProps = {
    id: string;
    value: number;
    max: number;
    onChange: (newValue: number) => void;
    isDisabled?: boolean;
};

const toValue = (n: number) => Math.round(n * 10) / 10;
const toDisplayValue = (n: number) => (n ? `${n.toFixed(1)}s` : '');

export const FadeInput: FC<FadeInputProps> = ({ id, value, max, onChange, isDisabled }) => {
    const testId = generateTestId(id);
    const [tmpValue, setTmpValue] = useState(value);
    const [displayValue, setDisplayValue] = useState(toDisplayValue(value));

    const applyChange = () => {
        const correctValue = editorAutocorrect('global_audio_fade', tmpValue, max);

        onChange(correctValue);
        setTmpValue(correctValue);
        setDisplayValue(toDisplayValue(correctValue));
    };

    const handleClean = () => {
        setDisplayValue('');
        setTmpValue(0);
        onChange(0);
    };

    const handleValueChange = (newValue: string) => {
        setDisplayValue(newValue);
        const parsedValue = parseFloat(newValue.replace('s', ''));

        if (isNaN(parsedValue)) {
            setTmpValue(0);

            return;
        }

        setTmpValue(toValue(parsedValue));
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        switch (event.key) {
            case 'Enter':
                applyChange();
                (event.target as HTMLInputElement).blur();
                break;
            case 'ArrowUp':
            case 'ArrowDown': {
                event.preventDefault();
                const numberToAdd = event.key === 'ArrowDown' ? -0.1 : 0.1;
                const factor = event.shiftKey ? 10 : 1;
                const val = toValue(tmpValue + numberToAdd * factor);
                setTmpValue(val);
                setDisplayValue(toDisplayValue(val));
                break;
            }
        }
    };

    useEffect(() => {
        const correctValue = editorAutocorrect('global_audio_fade', value, max);

        if (value !== correctValue) {
            onChange(correctValue);
        }

        setTmpValue(correctValue);
        setDisplayValue(toDisplayValue(correctValue));
    }, [value, max]);

    return (
        <Input
            id={id}
            value={displayValue}
            onBlur={applyChange}
            onKeyDown={handleKeyDown}
            onChange={handleValueChange}
            onClean={handleClean}
            isDisabled={isDisabled}
            type="text"
            autoComplete="off"
            placeholder="0s"
            hasClearButton
            {...testId}
        />
    );
};
