import React from 'react';
import { Dropdown, Tooltip, Flex, List } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconCollection, IconDelete } from '@bynder/icons';
import generateTestId from '~/helpers/testIdHelpers';
import { AssetButton, AssetThumbnail, FakeAssetOption, RemoveButton } from './AssetSource.styled';
import { useAssetSource } from './useAssetSource';

const testId = generateTestId('shots_asset_source');
const testIdRemove = generateTestId('shots_asset_source__remove');

const AssetSource = () => {
    const { translate } = useTranslate();
    const {
        containerRef,
        locked,
        isLoading,
        collections,
        isCollectionInvalid,
        chosenAsset,
        searchTerm,
        setIsOpen,
        setSearchTerm,
        handleRemoveClick,
        handleClick,
    } = useAssetSource();

    return (
        <List.Group title={translate('editor.sidebar.shots.asset_source.label')}>
            <Dropdown
                containerRef={containerRef}
                position="bottom"
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                trigger={({ isOpen, ...triggerProps }) => (
                    <Flex alignItems="center" justifyContent="space-between">
                        <AssetButton
                            aria-label="All assets"
                            isSelected={isOpen}
                            isDisabled={locked}
                            rightElements={<Dropdown.Arrow />}
                            thumbnail={
                                chosenAsset ? (
                                    <AssetThumbnail
                                        size="m"
                                        // @ts-ignore
                                        variant="content"
                                        imageUrl={chosenAsset?.thumbnailUrls?.[0]}
                                    />
                                ) : (
                                    <AssetThumbnail
                                        // @ts-ignore
                                        variant="clean"
                                        size="m"
                                        icon={<IconCollection />}
                                    />
                                )
                            }
                            subtext={
                                chosenAsset && !isCollectionInvalid
                                    ? `${chosenAsset.assetCount} asset${chosenAsset?.assetCount > 1 ? 's' : ''}`
                                    : ''
                            }
                            {...triggerProps}
                            {...testId}
                        >
                            {isCollectionInvalid
                                ? translate('editor.sidebar.shots.asset_source.not_found')
                                : chosenAsset?.name || 'All assets'}
                        </AssetButton>
                        {(chosenAsset || isCollectionInvalid) && (
                            <Tooltip
                                content={translate('editor.sidebar.shots.asset_source.remove_collection')}
                                position="top-left"
                            >
                                <RemoveButton
                                    isDisabled={locked}
                                    onClick={handleRemoveClick}
                                    title={translate('editor.sidebar.shots.asset_source.remove_collection')}
                                    variant="clean"
                                    icon={<IconDelete />}
                                    {...testIdRemove}
                                />
                            </Tooltip>
                        )}
                    </Flex>
                )}
            >
                <Dropdown.SearchInput
                    value={searchTerm}
                    onChange={setSearchTerm}
                    placeholder="Search"
                    aria-label="Search for collections"
                />
                <div>
                    {collections.map(
                        (item) =>
                            (
                                <Dropdown.ListItem
                                    key={item.id}
                                    aria-label={item.name}
                                    isChecked={item.id === chosenAsset?.id}
                                    role="menuitemradio"
                                    thumbnail={
                                        <AssetThumbnail
                                            // @ts-ignore
                                            variant="content"
                                            size="m"
                                            imageUrl={item.thumbnailUrls[0]}
                                        />
                                    }
                                    subtext={`${item.assetCount} asset${item.assetCount > 1 ? 's' : ''}`}
                                    onClick={() => handleClick(item)}
                                >
                                    {item.name}
                                </Dropdown.ListItem>
                            ) ?? [],
                    )}
                    {isLoading && <FakeAssetOption>Loading...</FakeAssetOption>}
                </div>
            </Dropdown>
        </List.Group>
    );
};

export default AssetSource;
