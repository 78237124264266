import styled from 'styled-components';

export const AddElementWrapper = styled.div`
    width: 40px;

    .add--layer-btn {
        max-width: 40px;
        max-height: 32px;
    }
`;
