import styled from 'styled-components';
import { Button, List, token } from '@bynder/design-system';

export const Container = styled.div`
    padding: ${token.spacing5} 0;
`;

export const CropModeListItem = styled(List.Item)`
    & span:first-child {
        overflow: visible;
    }
`;

export const CropModeButton = styled(Button)`
    justify-content: space-between;
    overflow: visible;
`;
