import { SocketSubscribeConfig } from '../socketSubscribe';
import { ExportJobItemProgressType } from 'packages/variation-export/AllExports/types';

export type InProgressJobData = {
    items: ExportJobItemProgressType[];
    totalItems: number;
};

type Options = SocketSubscribeConfig<InProgressJobData, {}>;

type Params = {
    creativeId: number;
    onMessage: Options['onMessage'];
};

export function subscribeExportJobsOptions({ creativeId, onMessage }: Params): Options {
    return {
        destination: `export-jobs.${creativeId}.progress`,
        publishBody: {},
        onMessage,
    };
}
