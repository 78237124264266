import { flatten } from 'rambda';
import { computeElementDepth, getAnimationsDepth } from './track-renderer';

const hasSameRenderOrder = (itemA, itemB) => itemA.element.renderOrder === itemB.element.renderOrder;

const addDepthCount = (group, idx) => {
    const items = group.children;
    let item = items[idx];
    let prevItem = idx ? items[idx - 1] : group;

    if (!idx) {
        return getAnimationsDepth(group) + 1;
    }

    if (hasSameRenderOrder(item, prevItem)) {
        return 0;
    }

    let animationDepth = 0;
    for (let i = 0; i < idx; i++) {
        if (hasSameRenderOrder(items[i], prevItem)) {
            let depth = getElementAnimationRowsCount(items[i], !idx);
            if (depth > animationDepth) {
                animationDepth = depth;
            }
        }
    }

    return animationDepth + 1;
};

const getElementAnimationRowsCount = (elem, skipExpandedCheck) => {
    if (!elem.expanded || skipExpandedCheck) {
        return getAnimationsDepth(elem);
    } else {
        return getElementAnimationRowsCount(elem.children[elem.children.length - 1], false);
    }
};

class VirtualTrack {
    constructor(renderOrder, verticalStartingPoint, verticalEndingPoint, verticalPadding, trackHeight) {
        this.renderOrder = renderOrder;
        this.depth = 0;
        this.verticalStartingPoint = verticalStartingPoint;
        this.verticalEndingPoint = verticalEndingPoint;
        this.verticalPadding = verticalPadding;
        this.trackHeight = trackHeight;
    }

    setVerticalEndingPoint(renderAbleItem) {
        let animationsDepth = getAnimationsDepth(renderAbleItem);
        const endingPoint =
            parseInt(renderAbleItem.style.top) - this.verticalPadding + this.trackHeight + 44 * animationsDepth;
        this.verticalEndingPoint = Math.max(this.verticalEndingPoint, endingPoint);
    }
}

export class ElementRenderer {
    constructor(trackHeight, verticalPadding = 3) {
        this.trackHeight = trackHeight;
        this.verticalPadding = verticalPadding;
    }

    init(templateDuration, firstItem, itemCount) {
        this.trackCount = 0;
        this.itemCount = itemCount;
        this.templateDuration = templateDuration;
        this.lastTrack = new VirtualTrack(0, 0, 0, this.verticalPadding, this.trackHeight);
        this.currentTrack = new VirtualTrack(
            firstItem.element.renderOrder,
            0,
            this.trackHeight,
            this.verticalPadding,
            this.trackHeight,
        );
    }

    createRenderAbleItems(items, templateDuration) {
        if (!items.length) {
            return [];
        }

        this.init(templateDuration, items[0], items.length);

        const renderAbleItems = items.map((item) => {
            if (item.element.renderOrder !== this.currentTrack.renderOrder) {
                this.lastTrack = this.currentTrack;
                const startingPoint = this.lastTrack.verticalEndingPoint;
                const endingPoint = this.lastTrack.verticalEndingPoint + this.trackHeight;
                this.currentTrack = new VirtualTrack(
                    item.element.renderOrder,
                    startingPoint,
                    endingPoint,
                    this.verticalPadding,
                    this.trackHeight,
                );
                this.trackCount++;
            }

            this.currentTrack.depth = 0;

            if (!!item.children) {
                return this.createRenderAbleGroup(item);
            } else {
                return this.createRenderAbleItem(item, true);
            }
        });

        return flatten(renderAbleItems);
    }

    createRenderAbleItem(item, topLevel) {
        if (topLevel) {
            this.currentTrack.depth = 0;
        }
        const { verticalStartingPoint, depth } = this.currentTrack;

        const top = verticalStartingPoint + depth * this.trackHeight;
        const start = item.element.startFrame || 0;
        const { duration } = item.element;
        const framesCount = this.templateDuration;

        const style = {
            top: `${top + this.verticalPadding}px`,
            left: `${(100 * start) / framesCount}%`,
            width: `${duration ? (100 * duration) / framesCount : 100}%`,
            height: `${this.trackHeight - 2 * this.verticalPadding}px`,
        };

        const renderAbleItem = { ...item, style };
        this.currentTrack.setVerticalEndingPoint(renderAbleItem);

        return renderAbleItem;
    }

    createRenderAbleGroup(group) {
        const renderAbleGroup = this.createRenderAbleItem(group);

        if (!group.expanded) {
            this.currentTrack.setVerticalEndingPoint(renderAbleGroup);
            return renderAbleGroup;
        }

        const renderAbleChildren = group.children.map((child, idx) => {
            this.currentTrack.depth += addDepthCount(group, idx);
            if (!!child.children) {
                return this.createRenderAbleGroup(child);
            } else {
                return this.createRenderAbleItem(child);
            }
        });

        renderAbleGroup.background = this.computeGroupBackground(renderAbleGroup, renderAbleChildren);
        return [renderAbleGroup].concat(flatten(renderAbleChildren));
    }

    computeGroupBackground(group) {
        const { left, width, backgroundColor } = group.style;
        const top = parseInt(group.style.top) - this.verticalPadding + 'px';
        const height = computeElementDepth(group) * this.trackHeight + 'px';

        return { top, left, width, height, backgroundColor };
    }
}
