import styled, { css } from 'styled-components';
import { Button, Checkbox, Flex, textStyles, token } from '@bynder/design-system';
import Loader from '~/common/loader/Loader';

export const LoaderTrigger = styled.div`
    display: flex;
    bottom: 0;
    height: 1px;
    width: 100%;
`;

export const ActionButton = styled(Button)`
    height: 20px !important;
    color: ${token.gray200} !important;
    background: none !important;
    border: none !important;
    box-shadow: none !important;

    ${(props) =>
        !props.isDisabled &&
        css`
            &:focus,
            &:hover,
            &:active {
                background: none !important;
                border: none !important;
                box-shadow: none !important;
                color: ${token.gray300} !important;
            }
        `};
`;

export const ActionCheckbox = styled(Checkbox)`
    padding: 0 !important;
    position: relative;

    & div:before {
        display: none !important;
    }
`;

export const ColumnSelect = styled.div``;

export const Column = styled.div`
    max-width: calc(50% - 56px);
    flex: 1;
    ${textStyles.uiTextM};
    color: ${token.gray800};
`;

export const ColumnAction = styled.div`
    margin-left: auto;
`;

export const HeaderContainer = styled(Flex)`
    padding: 14px 0 14px 12px;
`;

export const RowContainer = styled(Flex)`
    padding: 8px 0 8px 12px;
    user-select: none;
    cursor: ${(props) => (!props.isDisabled ? 'pointer' : 'auto')};
    border-radius: ${({ bordered }) => (bordered ? `${token.spacing3}` : '0')};

    ${(props) =>
        !props.isDisabled &&
        css`
            &:hover {
                background-color: ${token.gray25};

                ${ColumnAction} {
                    svg {
                        color: ${token.gray600a};
                    }
                }
            }
        `};
`;

export const SecondaryText = styled.span`
    ${textStyles.uiTextS};
    color: ${token.gray600};
`;

export const ListContainer = styled.div`
    padding: 16px 0 16px 40px;
`;

export const GridContainer = styled.div`
    padding: ${token.spacing5} ${token.spacing2};
    display: grid;
    grid-gap: ${token.spacing6};
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    grid-template-rows: masonry;
`;

export const LoaderContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

export const LoaderContainerWithContent = styled.div`
    background: ${token.gray25a};
    height: 100%;
    width: 100%;
`;

export const LoaderWithContent = styled(Loader)`
    position: absolute !important;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
`;
