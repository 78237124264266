const isBoolean = (_) => typeof _ === 'boolean';

export const createNavDestination = (pathname, props, pushCreative, search) => {
    const { collection, creative, location, isGridView } = props;
    const state = location && location.state ? location.state : {};

    if (collection) {
        state.collection = collection;
    }

    if (isBoolean(isGridView)) {
        state.isGridView = isGridView;
    }

    state.creative = pushCreative ? creative : null;

    return {
        pathname,
        search,
        state,
    };
};
