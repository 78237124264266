import { useEffect, useState } from 'react';
import { BaseMultiPageModel, UndoRedoManager } from '@bynder-studio/render-core';
import { isCtrlKey } from '../helpers/hotKeys';

export default function useEditorUndoRedo(creativeModel: BaseMultiPageModel) {
    const [canUndo, setCanUndo] = useState(false);

    useEffect(() => {
        if (!creativeModel) return;

        const undoRedoManagerInstance = new UndoRedoManager(creativeModel);

        const handleUndoRedo = (event) => {
            if (!isCtrlKey(event)) {
                return;
            }

            if (event.code === 'KeyZ' || event.keyCode === 90) {
                event.preventDefault();
                if (event.shiftKey) {
                    undoRedoManagerInstance.redo();
                } else {
                    undoRedoManagerInstance.undo();
                }
            }

            if (event.code === 'KeyY' || event.keyCode === 89) {
                event.preventDefault();
                undoRedoManagerInstance.redo();
            }
        };

        const handlerUpdate = (event) => {
            setCanUndo(event.canUndo);
        };

        creativeModel.eventEmitter.on('undoRedoStateUpdate', handlerUpdate);
        window.addEventListener('keydown', handleUndoRedo);

        return () => {
            creativeModel.eventEmitter.off('undoRedoStateUpdate', handlerUpdate);
            window.removeEventListener('keydown', handleUndoRedo);
        };
    }, [creativeModel]);

    return {
        canUndo,
    };
}
