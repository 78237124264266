import * as v from 'valibot';

export const PageInfoFragment = `
fragment PageInfoFragment on PageInfo {
    endCursor
    hasNextPage
}`;

export const pageInfoSchema = v.union([
    v.object({
        endCursor: v.string(),
        hasNextPage: v.literal(true),
    }),
    v.object({
        endCursor: v.null(),
        hasNextPage: v.literal(false),
    }),
]);

export type PageInfo = v.InferOutput<typeof pageInfoSchema>;
