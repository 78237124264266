import React, { useEffect, useRef, useState } from 'react';
import { token } from '@bynder/design-system';
import { type PagePreviews } from 'packages/hooks/usePagePreviews';
import { PageBlur, PagesContainer, PagesList, PagesWrapper } from './Component.styled';
import { PagePreviewItemBase } from './PagePreviewItemBase';
import { calculateAspectRatio } from './utils';

type Props = {
    selectedIdx: number;
    onSelect: (idx: number) => void;
    previews: PagePreviews;
    className?: string;
};

const PagePreviewsBase = ({ selectedIdx, onSelect, previews, className }: Props) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLDivElement>(null);
    const [alignment, setAlignment] = useState('center');

    useEffect(() => {
        if (!wrapperRef.current || !listRef.current) {
            return;
        }

        const listHeight = listRef.current.scrollHeight;
        const wrapperHeight = wrapperRef.current.getBoundingClientRect().height;

        if (listHeight > wrapperHeight) {
            setAlignment('flex-start');
        }
    }, []);

    const previewItems = [...previews]
        .sort((a, b) => a.metaData.displayOrder - b.metaData.displayOrder)
        .map((page) => {
            const { dimensions, name, id } = page.metaData;
            const aspectRatio = dimensions ? calculateAspectRatio(dimensions) : '1:1';
            const isLoaded = page.status === 'RENDER_END';

            return (
                <PagePreviewItemBase
                    isValid
                    key={id}
                    isSelected={selectedIdx === page.index}
                    isLoading={isLoaded}
                    aspectRatio={aspectRatio}
                    onClick={onSelect}
                    name={name}
                    idx={page.index}
                    url={isLoaded ? page.dataUrl : ''}
                />
            );
        });

    return (
        <PagesContainer className={className}>
            <PageBlur top={token.spacing5} bottom="auto" />
            <PagesWrapper ref={wrapperRef}>
                <PagesList ref={listRef} alignment={alignment}>
                    {previewItems}
                </PagesList>
            </PagesWrapper>
            <PageBlur top="auto" bottom={token.spacing5} />
        </PagesContainer>
    );
};

export default PagePreviewsBase;
