import React, { useCallback, useMemo, useState } from 'react';
import { Dropdown, Form, Select, Button } from '@bynder/design-system';
import useFonts from 'packages/pages/editor/RightSideMenu/Shots/Text/Fonts/useFonts';
import useTextSelection from 'packages/hooks/useTextSelection';
import generateTestId from '~/helpers/testIdHelpers';
import type { Props } from './types';
import type { FontFamily as FontFamilyType } from '../types';

const FontFamily = ({ creativeModel, selectedElement, disabled }: Props) => {
    const { id, locked } = selectedElement;
    const { fontIdData, fontId: rawFontIds } = useTextSelection();
    const [, , mixedValue] = fontIdData;
    const [searchValue, setSearchValue] = useState('');
    const { loadDefaultFontByFamily, fontsList } = useFonts();

    const [fontFamilyIds, familyName] = useMemo(() => {
        const fontFamilyIds = new Set();
        let familyName: string = '';

        Object.values(fontsList).forEach(({ id, name, fonts }) => {
            if (fonts.some((f) => rawFontIds.includes(String(f.id)))) {
                familyName = familyName || name;
                fontFamilyIds.add(id);
            }
        });

        if (fontFamilyIds.size > 1) {
            familyName = mixedValue;
        }

        return [fontFamilyIds, familyName];
    }, [rawFontIds, fontsList, mixedValue]);

    const familyListArray = useMemo(() => {
        if (searchValue === '') return Object.values(fontsList);
        return Object.values(fontsList).filter((f) => f.name.toLowerCase().includes(searchValue.toLowerCase()));
    }, [fontsList, searchValue]);

    const handleChange = useCallback<(family: FontFamilyType) => Promise<void>>(
        async (family) => {
            const font = loadDefaultFontByFamily(family.fonts);
            const param = {
                updateTextSettingForSelection: {
                    settings: {
                        fontId: font.id.toString(),
                    },
                },
            };

            creativeModel.updateElement(id, param);
        },
        [creativeModel, id, loadDefaultFontByFamily],
    );

    return (
        <Form.Group>
            <Dropdown
                minWidth="288px"
                onClose={() => setSearchValue('')}
                {...generateTestId('shots_text_typeface')}
                trigger={({ isOpen, ...triggerProps }) => (
                    <Button
                        aria-label="shots_text_typeface"
                        isPressed={isOpen}
                        rightIcon={<Dropdown.Arrow />}
                        isDisabled={disabled || locked}
                        isFullWidth
                        {...triggerProps}
                    >
                        <Dropdown.SelectButtonText>{familyName}</Dropdown.SelectButtonText>
                    </Button>
                )}
            >
                <Select.SearchInput
                    value={searchValue}
                    onChange={setSearchValue}
                    placeholder="Search"
                    aria-label="Search for font"
                />
                {familyListArray.map((family) => (
                    <Dropdown.Item
                        key={family.id}
                        onClick={() => handleChange(family)}
                        isChecked={fontFamilyIds.has(family.id)}
                    >
                        {family.name}
                    </Dropdown.Item>
                ))}
            </Dropdown>
        </Form.Group>
    );
};

export default FontFamily;
