import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { getCreativesActionProgress, getCreativesSelection } from 'packages/store/creatives/creatives.selectors';
import { Creative } from 'packages/store/creatives/types';
import { deleteCreatives, handleDeselectAll } from '~/store/creatives/creatives.actions';
import useAccessRights from 'packages/hooks/useAccessRights';
import { ActionStatus, DesignAction, Entity } from 'packages/hooks/useAccessRights/types';
import AccessNotificationModal from './accessNotificationModal';
import modalContainer from 'packages/common/modalContainer';

const useDesignDeleteModal = () => {
    const [deleteModalDesign, setDeleteModalDesign] = useState<Creative[] | null>(null);
    const { deleteInProgress } = useSelector(getCreativesActionProgress);
    const { selectedCreatives } = useSelector(getCreativesSelection);
    const { isEntityActionAllowed } = useAccessRights();

    const [accessNotificationModal, setAccessNotificationModal] = useState<
        { getAllowedToDeleteIds: number[] | undefined; deleteModalDesign: Creative[] | null } | undefined
    >(undefined);

    const isDeleteAllowed = (item: Creative) =>
        isEntityActionAllowed(DesignAction.DELETE, Entity.DESIGN, item.grantedPermissions) !==
            ActionStatus.NOT_ALLOWED &&
        isEntityActionAllowed(DesignAction.DELETE, Entity.DESIGN, item.grantedPermissions) !== ActionStatus.DISABLED;

    const getAllowedToDeleteIds = deleteModalDesign?.reduce((acc: number[], design: Creative) => {
        if (isDeleteAllowed(design)) {
            acc.push(design.id);
        }

        return acc;
    }, []);

    const { translate } = useTranslate();
    const dispatch = useDispatch();

    const showModal = deleteModalDesign !== null;
    const getModalTitle = () => {
        if (deleteModalDesign?.length === 1) {
            return translate('modal.design.delete.single.title', { name: deleteModalDesign?.[0].name });
        }

        if (deleteModalDesign?.length) {
            return translate('modal.design.delete.title', {
                count: deleteModalDesign.length,
            });
        }

        if (accessNotificationModal && accessNotificationModal.deleteModalDesign?.length) {
            return translate('modal.design.delete.title', {
                count: accessNotificationModal.deleteModalDesign.length,
            });
        }

        return translate('modal.design.delete.title', { count: 0 });
    };

    const onDeleteDesign = useCallback(() => {
        if (!getAllowedToDeleteIds?.length) {
            setAccessNotificationModal({ getAllowedToDeleteIds, deleteModalDesign });
        } else {
            if (selectedCreatives.length && getAllowedToDeleteIds?.length !== selectedCreatives.length) {
                setAccessNotificationModal({ getAllowedToDeleteIds, deleteModalDesign });
            }
            dispatch(deleteCreatives(getAllowedToDeleteIds));
        }
        dispatch(handleDeselectAll());
        setDeleteModalDesign(null);
    }, [getAllowedToDeleteIds]);

    const renderModalDesignDelete = () => (
        <>
            {!accessNotificationModal ? (
                <Modal
                    container={modalContainer}
                    title={getModalTitle()}
                    isOpen={showModal}
                    onClose={() => setDeleteModalDesign(null)}
                    actionPrimary={
                        <Button
                            variant="primary"
                            onClick={onDeleteDesign}
                            isLoading={deleteInProgress}
                            title={translate('modal.design.delete.actionButton', {
                                count: deleteModalDesign ? deleteModalDesign.length : 0,
                            })}
                        >
                            {translate('modal.design.delete.actionButton', {
                                count: deleteModalDesign ? deleteModalDesign.length : 0,
                            })}
                        </Button>
                    }
                    actionSecondary={
                        <Button
                            variant="secondary"
                            onClick={() => setDeleteModalDesign(null)}
                            title={translate('modal.cancel.title')}
                        >
                            {translate('modal.cancel.title')}
                        </Button>
                    }
                >
                    {translate('modal.design.delete.info', {
                        count: deleteModalDesign ? deleteModalDesign.length : 0,
                    })}
                </Modal>
            ) : (
                <AccessNotificationModal
                    title={getModalTitle()}
                    description={
                        !accessNotificationModal.getAllowedToDeleteIds?.length
                            ? translate('modal.design.access.not-allowed')
                            : translate('modal.design.access.not-all', { action: 'delete' })
                    }
                    loading={deleteInProgress}
                    isOpen={!!accessNotificationModal}
                    toggleOpen={() => setAccessNotificationModal(undefined)}
                />
            )}
        </>
    );

    return {
        setDeleteModalDesign,
        renderModalDesignDelete,
    };
};

export default useDesignDeleteModal;
