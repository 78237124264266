import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Dropdown, Input, Thumbnail, List, token } from '@bynder/design-system';
import { IconError, IconStateIndicatorXL, IconStateIndicatorXLOutline, IconVideoTemplate } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { IconAdd, IconArrowForward, IconArrowLeft, IconSearch } from '@bynder/icons';
import ExportModal from 'packages/variation-export';
import useQueryParams from 'packages/hooks/useQueryParams';
import useAccessRights from 'packages/hooks/useAccessRights';
import { useFriendlyTime } from '~/hooks/useFriendlyTime';
import { EDIT_DESIGN, EXPORT } from '~/helpers/textConstants';
import DotLoader from '~/common/dotLoader/DotLoader';
import AuthorizationHelper from '~/helpers/AuthorizationHelper';
import features from '~/configs/features';
import permissions from '~/configs/permissions';
import { ReviewStatus } from 'packages/store/review/types';
import { createReview } from 'packages/store/review/review.actions';
import { ActionStatus, DesignAction, Entity, TemplateAction } from 'packages/hooks/useAccessRights/types';
import { getReviewState } from 'packages/store/review/review.selectors';
import { getGeneral } from 'packages/store/general/general.selectors';
import { useReviewStatus } from 'packages/pages/design/sidebar/shots/useReviewStatus';
import { catchResponseError } from 'packages/helpers/helpers';
import RequestApprovalModal from '../RequestApprovalModal';
import useDesign from '../hooks/useDesign';
import PreviewHeaderVariationDropdownItem from './PreviewHeaderVariationDropdownItem';
import PreventExportModal from './PreventExportModal';
import { isVariationInvalid } from '../sidebar/variations/utils';
import { LoadingWrapper } from '../sidebar/variations/Variations.styled';
import {
    GoBackButton,
    PreviewDetailsBox,
    PreviewDetails,
    PreviewTitle,
    PreviewBadge,
    PreviewBtnsContainer,
    PreviewDropdown,
    PreviewHeaderWrapper,
    PreviewSearchIcon,
    PreviewVariationsDropdownBtn,
    VariationDropdownWrapper,
    VariationSearchInput,
    ButtonWithIndicator,
    WarningIconIndicator,
    StyledBadge,
    StyledTooltip,
    CommentContainer,
    ActionTime,
    CommentText,
} from './previewHeader.styled';
import useVariations from '../hooks/useVariations';
import { ApprovalRequestType } from '../RequestApprovalModal/types';
import PreventRequestApprovalModal from './PreventRequestApprovalModal';

const PreviewHeader = () => {
    const navigate: any = useNavigate();
    const location = useLocation();
    const { translate } = useTranslate();
    const dispatch = useDispatch();

    const { getFriendlyTime } = useFriendlyTime();
    const {
        variations,
        currentVariation,
        selectVariation,
        addVariation,
        total,
        isLimitExceeded,
        maxVariationCount,
        isLoadingVariations,
        isLoadingNextPage,
        saveVariation,
        hasValidVariations,
        hasInvalidVariations,
        oversetVariationsSet,
        isCurrentVariationPending,
        deletedAssets,
    } = useVariations();
    const reviewState = useSelector(getReviewState);
    const { template, creativeId } = useDesign();
    const { isReviewerAndInReview, isDisabledByReview } = useReviewStatus();
    const { reviewers } = template;
    const status = reviewState.status ? reviewState.status : template?.approvalState?.status || null;
    const savingStatus = reviewState.savingStatus ? reviewState.savingStatus : null;
    const { currentPage } = useSelector(getGeneral);
    const { isPlatformAllowed, isEntityActionAllowed } = useAccessRights();
    const isExportBlocked = !hasValidVariations();
    const isRequestApprovalBlocked = hasInvalidVariations();
    const [isPreventExportModalOpen, setPreventExportModal] = useState(false);
    const [isPreventRequestApprovalModalOpen, setPreventRequestApprovalModal] = useState(false);
    const [isRequestApprovalModalOpen, setRequestApprovalModalOpen] = useState(false);
    const [selectedIds, setSelectedIds] = useState([] as any);
    const [isAdding, setIsAdding] = useState(false);
    const [selectedVariation, setSelectedVariation] = useState('New Variation');
    const [variationSearchValue, setVariationSearchValue] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const listRef = useRef<HTMLDivElement>(null);
    const firstMount = useRef(false);
    const { currentCreative } = useSelector((state: any) => ({ currentCreative: state.creatives.creative }));

    const grantedPermissions = reviewState.entityGrantedPermissions
        ? reviewState.entityGrantedPermissions
        : currentCreative.grantedPermissions;

    const isMultiVariationAvailable = useMemo(
        () => AuthorizationHelper.isFeatureAvailable(features.MULTI_VARIATION),
        [],
    );

    const variationList = useMemo(() => {
        const term = variationSearchValue.trim().toLocaleLowerCase();

        if (!term) {
            return variations;
        }

        return variations
            .filter((item) => item.name.toLocaleLowerCase().includes(term))
            .sort((a, b) => a.name.toLocaleLowerCase().indexOf(term) - b.name.toLocaleLowerCase().indexOf(term));
    }, [variations, variationSearchValue]);
    const { getQueryParam, setQueryParam } = useQueryParams();
    const queryExport = getQueryParam('export');

    const { id: currentCreativeID, created, name: title } = currentCreative;
    const currentVariationObj = variationList.find((v) => v.id === currentVariation);

    const isApprovalsEnabled = isPlatformAllowed([features.APPROVALS_ENABLED]);

    const hasEditMode =
        AuthorizationHelper.hasPermission(permissions.CREATIVES_SPECIFICATIONS.WRITE) &&
        (!status || status === ReviewStatus.DRAFT || status === ReviewStatus.EDITS_REQUESTED) &&
        isEntityActionAllowed(DesignAction.EDIT, Entity.DESIGN, grantedPermissions) === ActionStatus.ALLOWED;

    const hasRequestMode =
        (status === ReviewStatus.DRAFT || status === ReviewStatus.EDITS_REQUESTED) &&
        (isEntityActionAllowed(TemplateAction.REQUEST_REVIEW, Entity.TEMPLATE, grantedPermissions) ===
            ActionStatus.ALLOWED ||
            isEntityActionAllowed(DesignAction.REQUEST_REVIEW, Entity.DESIGN, grantedPermissions) ===
                ActionStatus.ALLOWED);

    const hasCancelMode =
        status === ReviewStatus.IN_REVIEW &&
        isEntityActionAllowed(DesignAction.CANCEL_REVIEW, Entity.DESIGN, grantedPermissions) === ActionStatus.ALLOWED;
    const approvalRequestType = isReviewerAndInReview ? ApprovalRequestType.REVIEW : ApprovalRequestType.REQUEST;

    const onAddClick = useCallback(() => {
        setIsAdding(true);

        addVariation()
            .catch(catchResponseError)
            .finally(() => setIsAdding(false));
    }, [addVariation]);

    const onSelect = (e, id) => {
        if (e.shiftKey) {
            e.stopPropagation();
            e.preventDefault();
        }

        setSelectedIds([id]);
        selectVariation(id);
        setSelectedVariation(variationList.find((v) => v.id === id)?.name);
        setVariationSearchValue('');
    };

    const saveChanges = useCallback(
        (callback) => {
            if (!currentVariationObj) {
                callback();

                return;
            }

            const { hasUnsavedChanges, id } = currentVariationObj;

            if (hasUnsavedChanges && deletedAssets.length === 0) {
                saveVariation(id, callback);
            } else {
                callback();
            }
        },
        [variationList, currentVariation, saveVariation],
    );

    const handleGoBack = useCallback(() => {
        if (currentPage && currentPage.back) {
            navigate(currentPage.back);
        } else {
            navigate('/designs');
        }
    }, [history, currentPage]);

    const handleSearchValue = (newValue) => {
        setVariationSearchValue(newValue);
    };

    const openExportModal = useCallback(() => {
        if (isExportBlocked) {
            setPreventExportModal(true);
            return;
        }

        setIsModalOpen(true);
    }, [isExportBlocked, setIsModalOpen]);

    const openExportModalWithSave = useCallback(() => {
        saveChanges(() => {
            openExportModal();
        });
    }, [saveChanges, openExportModal]);

    const closeExportModal = useCallback(() => {
        setIsModalOpen(false);
    }, []);

    const handleEditDesign = useCallback(() => {
        navigate(`/designs/${currentCreativeID}/edit`);
    }, [currentCreativeID, history]);

    const handleApproval = useCallback(() => {
        if (isRequestApprovalBlocked) {
            setPreventRequestApprovalModal(true);
            return;
        }

        if (hasRequestMode) {
            if (currentVariationObj) {
                const { hasUnsavedChanges } = currentVariationObj;

                if (hasUnsavedChanges) {
                    saveVariation(currentVariation, () => {
                        setRequestApprovalModalOpen(true);
                    });
                } else {
                    setRequestApprovalModalOpen(true);
                }
            }
        } else {
            setRequestApprovalModalOpen(true);
        }
    }, [currentVariation, saveVariation, setRequestApprovalModalOpen, hasRequestMode]);

    const handleCancelApproval = () => {
        dispatch(createReview(currentCreativeID, { comment: null, status: ReviewStatus.CANCELLED }));
    };

    const getTooltipContent = () => {
        if (!status) {
            return null;
        }

        const reviewer = template?.approvalState?.actor;
        const comment = reviewState.comment ? reviewState.comment : template?.approvalState?.comment;
        const actionDate = reviewState.actionDate ? reviewState.actionDate : template?.approvalState?.actionDate;

        const getInitial = () => {
            if (reviewer?.firstName) {
                return reviewer?.firstName[0].toUpperCase();
            }

            return '';
        };

        return (
            <CommentContainer direction="column">
                <List.Item
                    thumbnail={
                        <Thumbnail size="s" shape="circle" backgroundColor={token.gray100} icon={getInitial()} />
                    }
                    key={reviewer.email}
                    rightElements={<ActionTime>{getFriendlyTime(actionDate)}</ActionTime>}
                >
                    {`${reviewer?.firstName} ${reviewer?.lastName}`}
                </List.Item>
                {comment?.length > 0 && <CommentText>{comment}</CommentText>}
            </CommentContainer>
        );
    };

    const iconsMap = {
        DRAFT: <IconStateIndicatorXL style={{ color: token.gray400 }} />,
        IN_REVIEW: <IconStateIndicatorXL style={{ color: token.yellow500 }} />,
        EDITS_REQUESTED: <IconStateIndicatorXL style={{ color: token.red500 }} />,
        APPROVED: <IconStateIndicatorXL style={{ color: token.green500 }} />,
        NO_STATUS: <IconStateIndicatorXLOutline style={{ color: token.gray400 }} />,
    };

    const renderBadge = () => {
        return (
            <StyledTooltip position="bottom-left" content={getTooltipContent()} isLightTheme>
                <StyledBadge isSecondary hasBorder icon={iconsMap[status]}>
                    {translate(`design.status.${status}`)}
                </StyledBadge>
            </StyledTooltip>
        );
    };

    useEffect(() => {
        if (!currentVariation) {
            return;
        }

        setSelectedIds([currentVariation]);
        setSelectedVariation(variations.find((v) => v.id === currentVariation)?.name);
    }, [variations, currentVariation, variationSearchValue]);

    useEffect(() => {
        if (!listRef.current) {
            return;
        }

        listRef.current.style.maxHeight = `${window.innerHeight - listRef.current.getBoundingClientRect().top}px`;
    }, []);

    useEffect(() => {
        if (!currentVariation || firstMount.current) {
            return;
        }

        if (location.state?.exportModalShow) {
            openExportModal();
        }

        if (queryExport === 'true') {
            openExportModal();
        }

        firstMount.current = true;
    }, [isExportBlocked, currentVariation]);

    useLayoutEffect(() => {
        if (!currentVariation) {
            return;
        }

        if (isModalOpen) {
            setQueryParam('export', 'true');
        }
    }, [isModalOpen]);

    return (
        <>
            <PreventExportModal isOpen={isPreventExportModalOpen} onClose={() => setPreventExportModal(false)} />
            <PreventRequestApprovalModal
                isOpen={isPreventRequestApprovalModalOpen}
                onClose={() => setPreventRequestApprovalModal(false)}
            />
            <PreviewHeaderWrapper id="preview_header">
                <GoBackButton title="back" onClick={handleGoBack} variant="clean" icon={<IconArrowLeft />} />

                <PreviewDetailsBox>
                    <PreviewDetails>
                        <PreviewTitle>{title}</PreviewTitle>
                        {isApprovalsEnabled && status && <PreviewBadge>{renderBadge()}</PreviewBadge>}
                        {isMultiVariationAvailable && (
                            <>
                                <div>
                                    <IconArrowForward
                                        size="20"
                                        style={{ color: token.gray400, marginLeft: token.spacing2 }}
                                    />
                                </div>
                                <PreviewDropdown
                                    trigger={({ isOpen, ...triggerProps }) => (
                                        <PreviewVariationsDropdownBtn
                                            isPressed={isOpen}
                                            rightIcon={<Dropdown.Arrow />}
                                            variant="clean"
                                            {...triggerProps}
                                        >
                                            <span className="selected--variation_name">{selectedVariation}</span>
                                        </PreviewVariationsDropdownBtn>
                                    )}
                                >
                                    <VariationSearchInput className="variations--dropdown-header">
                                        <Input
                                            variant="clean"
                                            placeholder="Variations"
                                            iconRight={
                                                <PreviewSearchIcon
                                                    icon={<IconSearch />}
                                                    label="Search"
                                                    onClick={() => {}}
                                                />
                                            }
                                            value={variationSearchValue}
                                            onChange={handleSearchValue}
                                        />
                                        {total < maxVariationCount ? (
                                            <Button
                                                isSmall
                                                variant="clean"
                                                icon={<IconAdd size="24" />}
                                                aria-label="Add Variant"
                                                onClick={onAddClick}
                                                isLoading={isAdding}
                                                isDisabled={isDisabledByReview}
                                            />
                                        ) : (
                                            <Button
                                                isSmall
                                                variant="clean"
                                                icon={<IconAdd size="24" />}
                                                aria-label="Add Variant"
                                                onClick={onAddClick}
                                                isDisabled={
                                                    isLimitExceeded || total >= maxVariationCount || isDisabledByReview
                                                }
                                                isLoading={isAdding}
                                            />
                                        )}
                                    </VariationSearchInput>
                                    <VariationDropdownWrapper ref={listRef} className="scroll w-100">
                                        {isLoadingVariations && (
                                            <LoadingWrapper>
                                                <DotLoader />
                                            </LoadingWrapper>
                                        )}
                                        {variationList?.map(({ id, name, created: itemCreated, sizes }) => (
                                            <PreviewHeaderVariationDropdownItem
                                                key={id}
                                                sizes={sizes}
                                                id={id}
                                                name={name}
                                                created={`${getFriendlyTime(itemCreated)}`}
                                                onSelect={onSelect}
                                                isCurrentVariation={currentVariation === id}
                                                isIncluded={selectedIds.includes(id)}
                                                isInvalid={
                                                    oversetVariationsSet.has(id) ||
                                                    Object.values(sizes).some((entry) => isVariationInvalid(entry))
                                                }
                                            />
                                        ))}
                                        {isLoadingNextPage && (
                                            <LoadingWrapper>
                                                <DotLoader />
                                            </LoadingWrapper>
                                        )}
                                    </VariationDropdownWrapper>
                                </PreviewDropdown>
                            </>
                        )}
                    </PreviewDetails>
                    <span>{translate('design.preview.time', { created: getFriendlyTime(created) })}</span>
                </PreviewDetailsBox>
                <PreviewBtnsContainer>
                    {hasEditMode && (
                        <Button
                            icon={<IconVideoTemplate />}
                            variant="secondary"
                            onClick={handleEditDesign}
                            title={EDIT_DESIGN}
                        >
                            {EDIT_DESIGN}
                        </Button>
                    )}
                    {(!status || status === ReviewStatus.APPROVED) && (
                        <ButtonWithIndicator
                            onClick={openExportModalWithSave}
                            variant="primary"
                            isDisabled={!currentVariation || isCurrentVariationPending}
                            rightIcon={
                                isExportBlocked &&
                                currentVariation && (
                                    <WarningIconIndicator>
                                        <IconError size="20" style={{ color: token.red500 }} />
                                    </WarningIconIndicator>
                                )
                            }
                            title={EXPORT}
                        >
                            {EXPORT}
                        </ButtonWithIndicator>
                    )}
                    {isApprovalsEnabled && hasCancelMode && (
                        <Button
                            variant="secondary"
                            onClick={handleCancelApproval}
                            isLoading={
                                (reviewState.saving && savingStatus === ReviewStatus.CANCELLED) || isLoadingVariations
                            }
                            title={translate('design.requestApproval.request.approval.cancel')}
                        >
                            {translate('design.requestApproval.request.approval.cancel')}
                        </Button>
                    )}
                    {isApprovalsEnabled && hasRequestMode && (
                        <ButtonWithIndicator
                            variant="primary"
                            onClick={handleApproval}
                            isLoading={
                                (reviewState.saving && savingStatus === ReviewStatus.IN_REVIEW) || isLoadingVariations
                            }
                            title={translate('design.requestApproval.request.action')}
                            rightIcon={
                                isRequestApprovalBlocked &&
                                currentVariation && (
                                    <WarningIconIndicator>
                                        <IconError size="20" style={{ color: token.red500 }} />
                                    </WarningIconIndicator>
                                )
                            }
                        >
                            {translate('design.requestApproval.request.action')}
                        </ButtonWithIndicator>
                    )}
                    {isApprovalsEnabled && isReviewerAndInReview && (
                        <Button
                            variant="primary"
                            onClick={handleApproval}
                            isLoading={
                                (reviewState.saving &&
                                    (savingStatus === ReviewStatus.EDITS_REQUESTED ||
                                        savingStatus === ReviewStatus.APPROVED)) ||
                                isLoadingVariations
                            }
                            title={translate('design.previewHeader.requestApproval.review.action')}
                        >
                            {translate('design.previewHeader.requestApproval.review.action')}
                        </Button>
                    )}
                </PreviewBtnsContainer>
            </PreviewHeaderWrapper>
            {isModalOpen && (
                <ExportModal isOpen={isModalOpen} handleOnClose={closeExportModal} variations={variations} />
            )}
            {isRequestApprovalModalOpen && (
                <RequestApprovalModal
                    type={approvalRequestType}
                    reviewers={reviewers}
                    templateId={creativeId}
                    isOpen={isRequestApprovalModalOpen}
                    isSaving={isCurrentVariationPending}
                    saveChanges={saveChanges}
                    handleOnClose={() => {
                        setRequestApprovalModalOpen(false);
                    }}
                />
            )}
        </>
    );
};

export default PreviewHeader;
