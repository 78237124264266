import React from 'react';
import { Button, Dropdown, Flex, Tooltip, token, List } from '@bynder/design-system';
import { IconAdd, IconCopy, IconDelete, IconDownload, IconError, IconMore, IconUpload } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import useDesign from 'packages/pages/design/hooks/useDesign';
import MaybeTooltip from 'packages/common/MaybeTooltip';
import AuthorizationHelper from '~/helpers/AuthorizationHelper';
import features from '~/configs/features';

const VariationsHeader = ({
    selectedHasDeletedAsset,
    variationCount,
    selectedVariationCount,
    onAddClick,
    onDuplicateClick,
    onDeleteClick,
    isDisabledByReview,
    isAdding,
    isDuplicatePending,
    isAddingDisabled,
    maxVariationCount,
}) => {
    const { translate } = useTranslate();
    const { openDownloadModal, openUploadModal } = useDesign();
    const selectedAndExistedInLimit = variationCount + selectedVariationCount <= maxVariationCount;
    const isVariationsCountMax = variationCount >= maxVariationCount;
    const hasVariations = variationCount && variationCount > 0;
    const isPlusBtnDisabled =
        isDisabledByReview || isAdding || isAddingDisabled || isVariationsCountMax || !hasVariations;

    return (
        <List.Item
            rightElements={
                <>
                    {selectedVariationCount > 1 && (
                        <>
                            <MaybeTooltip
                                position="top-left"
                                hasTooltip={!selectedAndExistedInLimit}
                                content={translate('design.sidebar.variations.header.title.duplication', {
                                    count: maxVariationCount,
                                })}
                            >
                                <Button
                                    variant="clean"
                                    isDisabled={
                                        selectedHasDeletedAsset ||
                                        !selectedAndExistedInLimit ||
                                        isDuplicatePending ||
                                        isDisabledByReview ||
                                        !hasVariations
                                    }
                                    onClick={onDuplicateClick}
                                    icon={<IconCopy />}
                                    title={translate('design.sidebar.variations.header.actions.duplicate')}
                                />
                            </MaybeTooltip>
                            <Button
                                variant="clean"
                                onClick={onDeleteClick}
                                icon={<IconDelete />}
                                isDisabled={!hasVariations}
                                title={translate('design.sidebar.variations.header.actions.delete')}
                            />
                        </>
                    )}
                    {selectedVariationCount <= 1 && (
                        <>
                            {AuthorizationHelper.isFeatureAvailable(features.MULTI_VARIATION) && (
                                <Dropdown
                                    position="top-right"
                                    trigger={({ isOpen, ...triggerProps }) => (
                                        <Button
                                            variant="clean"
                                            isPressed={isOpen}
                                            icon={<IconMore />}
                                            title={translate('design.sidebar.variations.header.actions.download')}
                                            isDisabled={!hasVariations}
                                            {...triggerProps}
                                        />
                                    )}
                                >
                                    <Dropdown.Item onClick={openDownloadModal} icon={<IconDownload />}>
                                        {translate('design.sidebar.variations.header.download')}
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item
                                        onClick={openUploadModal}
                                        icon={<IconUpload />}
                                        isDisabled={variationCount >= maxVariationCount || isDisabledByReview}
                                    >
                                        {translate('design.sidebar.variations.header.upload')}
                                    </Dropdown.Item>
                                </Dropdown>
                            )}
                            <MaybeTooltip
                                hasTooltip={isVariationsCountMax}
                                position="top-left"
                                content={translate('design.sidebar.variations.header.title.selected.tooltip', {
                                    count: maxVariationCount,
                                })}
                            >
                                <Button
                                    variant="clean"
                                    onClick={onAddClick}
                                    isLoading={isAdding}
                                    isDisabled={isPlusBtnDisabled}
                                    icon={<IconAdd />}
                                    title={translate('design.sidebar.variations.header.actions.add')}
                                />
                            </MaybeTooltip>
                        </>
                    )}
                </>
            }
        >
            {hasVariations && selectedVariationCount > 1 ? (
                <>{translate('design.sidebar.variations.header.title', { selectedVariationCount, variationCount })}</>
            ) : (
                <Flex alignItems="flex-start">
                    <span>
                        {translate('design.sidebar.variations.header.title.selected', { count: variationCount })}
                    </span>
                    {variationCount >= maxVariationCount && (
                        <Tooltip
                            content={translate('design.sidebar.variations.header.title.selected.tooltip', {
                                count: maxVariationCount,
                            })}
                        >
                            <IconError style={{ color: token.red400 }} />
                        </Tooltip>
                    )}
                </Flex>
            )}
        </List.Item>
    );
};

export default VariationsHeader;
