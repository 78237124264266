import { DropShadow } from './DropShadow';

export class Region {
    left: number;

    top: number;

    right: number;

    bottom: number;

    constructor(left = 0, top = 0, right = 0, bottom = 0) {
        this.left = left;
        this.top = top;
        this.right = right;
        this.bottom = bottom;
    }

    applyDropShadow(dropShadow: DropShadow) {
        if (dropShadow && dropShadow?.state !== 'DISABLE') {
            const { angle, offset, blurRadius } = dropShadow;

            const angleRadian = angle * (Math.PI / 180);
            const offsetX = Math.abs(Math.ceil(offset * Math.sin(angleRadian)));
            const offsetY = Math.abs(Math.ceil(offset * Math.cos(angleRadian)));
            const blur = blurRadius * 2;
            const padding = Math.max(offsetX, offsetY) + blur;

            this.left -= padding;
            this.top -= padding;
            this.right += padding;
            this.bottom += padding;
        }
    }

    getWidth() {
        return this.right - this.left;
    }

    getHeight() {
        return this.bottom - this.top;
    }

    getUnion(r: Region): Region {
        return new Region(
            Math.min(this.left, r.left),
            Math.min(this.top, r.top),
            Math.max(this.right, r.right),
            Math.max(this.bottom, r.bottom),
        );
    }

    getIntersection(r: Region): Region | null {
        const left = Math.max(this.left, r.left);
        const top = Math.max(this.top, r.top);
        const right = Math.min(this.right, r.right);
        const bottom = Math.min(this.bottom, r.bottom);

        if (left >= this.right || top >= this.bottom || right <= this.left || bottom <= this.top) {
            return null; // no overlap
        }

        return new Region(left, top, right, bottom);
    }

    getTranslation(x: number, y: number): Region {
        return new Region(this.left + x, this.top + y, this.right + x, this.bottom + y);
    }

    getArea() {
        return this.getWidth() * this.getHeight();
    }

    toArray() {
        return [this.left, this.top, this.right, this.bottom];
    }
}
