import React, { MutableRefObject } from 'react';
import { Button } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import useEditor from '~/hooks/useEditor';
import useMultiPageValidation from '~/hooks/useMultiPageValidation';
import generateTestId from '~/helpers/testIdHelpers';
import type { Reviewer } from 'packages/hooks/useVirtualProperties';
import PublishModal from 'packages/pages/editor/Header/PublishModal';
import useAccessRights from 'packages/hooks/useAccessRights';
import features from '~/configs/features';
import RefreshModal from './RefreshModal';
import { ToggleShowUnpublishedChanges, DiscardChangesClickSource } from './types';

export type Props = {
    template: any;
    isInAction: boolean;
    saved: boolean;
    videosCount: number;
    showPublishModal: any;
    handlePublishModal: (isOpen: boolean) => void;
    publish: (creativeModel: any, exportIntegrations: any, approvalEnabled?: boolean, reviewers?: Reviewer[]) => void;
    closePublishModal: any;
};
type LocalProps = {
    discardChangesClickSource: MutableRefObject<DiscardChangesClickSource>;
    showUnpublishedChangesModal: boolean;
    toggleShowUnpublishedChanges: ToggleShowUnpublishedChanges;
    discardUnpublishedChanges: () => void;
};
const PublishButton = ({
    template,
    isInAction,
    saved,
    videosCount,
    showPublishModal,
    handlePublishModal,
    publish,
    closePublishModal,
    showUnpublishedChangesModal,
    toggleShowUnpublishedChanges,
    discardUnpublishedChanges,
    discardChangesClickSource,
}: Props & LocalProps) => {
    const { translate } = useTranslate();
    const { creativeModel, exportIntegrations, approvalEnabled, reviewers, type, isPublishing, setIsPublishing } =
        useEditor();
    const { isPlatformAllowed } = useAccessRights();
    const isValid = useMultiPageValidation();
    const isTemplate = type === 'TEMPLATE';

    const enablePublish =
        !isInAction && isValid && (!saved || template.status === 'DRAFT' || !template.lastPublishDate);
    const showModalOnPublish = !!videosCount;

    const handlePublish = () => {
        setIsPublishing(true);

        if (showUnpublishedChangesModal) {
            toggleShowUnpublishedChanges(false, 'publish');
        }

        if (enablePublish && showModalOnPublish) {
            handlePublishModal(true);
        } else if (isPlatformAllowed([features.APPROVALS_ENABLED])) {
            publish(creativeModel, exportIntegrations, approvalEnabled, reviewers);
        } else {
            publish(creativeModel, exportIntegrations);
        }
    };

    const handleForcePublish = () => {
        setIsPublishing(true);
        publish(creativeModel, exportIntegrations, approvalEnabled, reviewers);
    };

    const publishId = generateTestId('editor_header_publish');

    return (
        <div>
            <Button
                variant="primary"
                onClick={handlePublish}
                isDisabled={!enablePublish}
                isLoading={isInAction && isPublishing}
                {...publishId}
                {...{ id: 'editor_header_publish' }}
            >
                {isInAction && isPublishing
                    ? translate('editor.header.publishing')
                    : translate(isTemplate ? 'editor.header.publishChanges' : 'editor.header.publish')}
            </Button>
            <PublishModal
                variationCount={videosCount}
                open={showPublishModal}
                loading={isInAction && isPublishing}
                onClose={() => {
                    closePublishModal();
                    setIsPublishing(false);
                }}
                onPublish={handleForcePublish}
            />
            <RefreshModal
                hasUnsavedChanges={!saved}
                isPublishDisabled={!enablePublish}
                isOpen={showUnpublishedChangesModal}
                loading={isInAction && isPublishing}
                toggle={(open) => {
                    toggleShowUnpublishedChanges(open, discardChangesClickSource?.current || 'preview');
                }}
                discard={discardUnpublishedChanges}
                onPublish={handlePublish}
            />
        </div>
    );
};

export default PublishButton;
