import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const ModalContent = styled.div`
    position: relative;
`;

export const StyledGridContainer = styled.div``;

export const EmptyContainer = styled.div`
    margin-top: ${token.spacing6};
`;
