import { CORNERS, HandlerInteractionStates, Position, Scrollbars, SIDES } from '@bynder-studio/render-core';
import BaseManipulation from '../BaseManipulation';
import { getWhich, isCtrlKey } from '../../../Helpers/hotKeys';
import ScrollbarsManipulationCompositor from './ScrollBarsManipulationCompositor';

const SCROLLBARS = Object.values(Scrollbars);

const clamp = (x: number, min: number, max: number) => (x < min ? min : x > max ? max : x);

class ScrollbarsManipulation extends BaseManipulation {
    private drawScrollbarsRequestId: number = null;

    scrollbarsManipulationCompositor: ScrollbarsManipulationCompositor;

    private redraw: (handleIndex: CORNERS | SIDES | null, state: HandlerInteractionStates | null) => void = null;

    constructor(compositor, containerDocument, emitter, redraw) {
        super(compositor, containerDocument, emitter);

        this.redraw = redraw;
        this.scrollbarsManipulationCompositor = new ScrollbarsManipulationCompositor(
            this.compositor,
            this.canvas.getContext('2d'),
            this.compositor.devicePixelRatio,
        );
        containerDocument.addEventListener('mousemove', this.onMouseMove);
        containerDocument.addEventListener('mouseup', this.onMouseUp);
        this.canvas.addEventListener('mousedown', this.onMouseDown);
        this.canvas.onwheel = this.onMouseWheel.bind(this);
    }

    requestToDrawScrollbars() {
        if (this.drawScrollbarsRequestId) {
            cancelAnimationFrame(this.drawScrollbarsRequestId);
        }

        this.drawScrollbarsRequestId = requestAnimationFrame(() => {
            this.drawScrollbarsRequestId = 0;
            this.drawScrollbars();
        });
    }

    drawScrollbars() {
        this.scrollbarsManipulationCompositor.drawScrollbars();
    }

    onMouseDown = (e: MouseEvent) => {
        if (this.compositor.isPlaying) {
            return;
        }

        this.compositor.calculateMousePosition(e, this.compositor.mousePosition);
        this.compositor.calculateMousePosition(e, this.compositor.mouseStartPosition);

        if (getWhich(e) === 2 || this.compositor.isPanMode) {
            e.preventDefault();
            e.stopPropagation();
            this.compositor.isPanning = true;

            return;
        }

        const selectedElId = this.getElementToSelect();

        if (selectedElId && SCROLLBARS.includes(selectedElId)) {
            this.compositor.scrollbarValues = this.compositor.scrollbarsCalculations();
            this.compositor.scrollbar = selectedElId;
        }
    };

    onMouseMove = (e: MouseEvent) => {
        if (this.compositor.isPlaying) {
            return;
        }

        const oldMousePosition: Position = this.compositor.mousePosition.getCopy();
        this.compositor.calculateMousePosition(e, this.compositor.mousePosition);

        if (this.compositor.isPanning || SCROLLBARS.includes(this.compositor.scrollbar)) {
            // this hack is for aborting panning when mouseup was outside the window
            // reported as a bug in chrome for middle (wheel) button.
            // https://bugs.chromium.org/p/chromium/issues/detail?id=1206068
            if (e.buttons === 0) {
                this.compositor.isPanning = false;

                return;
            }

            const delta = this.compositor.mousePosition
                .subtract(oldMousePosition)
                .multiply(-this.compositor.getScale() * this.compositor.devicePixelRatio);
            const { deltaXMin, deltaXMax, deltaYMin, deltaYMax } = this.compositor.getScrollLimits();
            const panPosition: Position = this.compositor.getPanPosition();

            if (!this.compositor.scrollbar) {
                delta.setX(clamp(delta.getX(), deltaXMin, deltaXMax));
                delta.setY(clamp(delta.getY(), deltaYMin, deltaYMax));
            }

            const position = panPosition.subtract(delta);

            if (this.compositor.scrollbar === Scrollbars.HORIZONTAL) {
                const deltaX = -delta.getX() / this.compositor.scrollbarValues.xScale;
                position.setY(panPosition.getY());
                position.setX(panPosition.getX() - clamp(deltaX, deltaXMin, deltaXMax));
            } else if (this.compositor.scrollbar === Scrollbars.VERTICAL) {
                const deltaY = -delta.getY() / this.compositor.scrollbarValues.yScale;
                position.setX(panPosition.getX());
                position.setY(panPosition.getY() - clamp(deltaY, deltaYMin, deltaYMax));
            }

            this.emitter.emit<'requestCanvasPan'>('requestCanvasPan', { position });
        }

        this.compositor.overScrollBar = false;

        if (
            !this.compositor.isResizing &&
            !this.compositor.isDragging &&
            !this.compositor.isRotating &&
            !this.compositor.isPanning &&
            !this.compositor.isPanMode
        ) {
            const elementId = this.getElementToSelect();

            if (elementId && SCROLLBARS.includes(elementId)) {
                this.compositor.overScrollBar = true;
            }
        }
    };

    onMouseWheel(e: WheelEvent) {
        e.preventDefault();
        e.stopImmediatePropagation();

        if (isCtrlKey(e) || e.ctrlKey) {
            const bounds: DOMRect = this.canvas.getBoundingClientRect();
            const x = e.clientX - bounds.left;
            const y = e.clientY - bounds.top;
            const direction = e.deltaY < 0 ? 1 : -1;
            const factor = e.ctrlKey ? Math.abs(e.deltaY / 100) : 0.2;
            const scale = clamp(this.compositor.getScale() * Math.exp(direction * factor), 0.1, 8);

            this.emitter.emit<'requestCanvasZoom'>('requestCanvasZoom', { scale, x, y });

            return;
        }

        const { deltaXMin, deltaXMax, deltaYMin, deltaYMax } = this.compositor.getScrollLimits();

        const position: Position = this.compositor.getPanPosition().getCopy();
        position.setX(position.getX() - clamp(e.deltaX, deltaXMin, deltaXMax));
        position.setY(position.getY() - clamp(e.deltaY, deltaYMin, deltaYMax));
        this.emitter.emit<'requestCanvasPan'>('requestCanvasPan', { position });
    }

    onMouseUp = () => {
        if (this.compositor.scrollbar) {
            this.compositor.scrollbar = null;
            this.compositor.requestToDrawCompModel(this.compositor.currentCompModel);
            this.requestToDrawScrollbars();
        }

        if (this.compositor.isPanning) {
            this.compositor.isPanning = false;
        }
    };

    unsubscribe() {
        this.containerDocument.removeEventListener('mousemove', this.onMouseMove);
        this.containerDocument.removeEventListener('mouseup', this.onMouseUp);
        this.canvas.removeEventListener('mousedown', this.onMouseDown);
    }
}

export default ScrollbarsManipulation;
