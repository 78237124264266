import React from 'react';
import { Table, Thumbnail } from '@bynder/design-system';
import { IdentityRole } from 'packages/hooks/useAccessRights/types';
import { Creative } from 'packages/store/creatives/types';
import { Collection } from 'packages/store/collections/types';

const OwnerNameCell = ({ item }: { item: Creative | Collection }) => {
    const getOwnerName = () => {
        if (!item.grantedPermissions) {
            return 'Unknown';
        }
        const ownerRecord = item.grantedPermissions.find((perm) =>
            perm.grantedPermissions.find((b) => b.permission === IdentityRole.OWNER),
        );

        let fullName = '';
        if (ownerRecord) {
            if (ownerRecord.securityIdentity.fullName) {
                return ownerRecord.securityIdentity.fullName;
            }

            if (ownerRecord.securityIdentity.firstName) {
                fullName += `${ownerRecord.securityIdentity.firstName}`;
                if (ownerRecord.securityIdentity.lastName) {
                    fullName += ` ${ownerRecord.securityIdentity.lastName}`;
                }
            } else if (ownerRecord.securityIdentity.lastName) {
                fullName += `${ownerRecord.securityIdentity.lastName}`;
            } else {
                fullName = 'Unknown';
            }
        }
        return fullName;
    };

    return (
        <Table.Cell
            thumbnail={<Thumbnail shape="circle" variant="clean" size="s" icon={getOwnerName()[0].toUpperCase()} />}
            textFlow="no-wrap"
        >
            {getOwnerName()}
        </Table.Cell>
    );
};

export default OwnerNameCell;
