import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, InputField } from '@bynder/design-system';
import generateTestId from '~/helpers/testIdHelpers';
import { useTranslate } from '@bynder/localization';
import modalContainer from 'packages/common/modalContainer';

type SaveAsTemplateModalProps = {
    handleSubmit: (name: string) => void;
    isOpen: boolean;
    onClose: () => void;
    creativeName: string;
};

const SaveAsTemplateModal = ({ handleSubmit, isOpen, onClose, creativeName }: SaveAsTemplateModalProps) => {
    const [name, setName] = useState(creativeName);
    const { translate } = useTranslate();

    const MAX_INPUT_SIZE = 120;

    useEffect(() => setName(creativeName), [creativeName]);
    useEffect(() => {
        if (isOpen) setName(creativeName);
    }, [isOpen]);

    const actionId = generateTestId('editor_header_save_as_video_save');
    const cancelId = generateTestId('editor_header_save_as_video_cancel');
    const videoNameId = generateTestId('editor_header_save_as_video_video_name');

    return (
        <Modal
            container={modalContainer}
            title={translate('modal.design.save-as.video.title')}
            actionPrimary={
                <Button
                    variant="primary"
                    onClick={() => handleSubmit(name)}
                    isDisabled={!name.trim() || name.length > MAX_INPUT_SIZE}
                    {...actionId}
                    {...{ id: 'editor_header_save_as_video_save' }}
                >
                    {translate('modal.design.save-as.video.title')}
                </Button>
            }
            actionSecondary={
                <Button
                    variant="secondary"
                    onClick={onClose}
                    {...cancelId}
                    {...{ id: 'editor_header_save_as_video_cancel' }}
                >
                    {translate('modal.design.save-as.video.cancel')}
                </Button>
            }
            isOpen={isOpen}
            onClose={onClose}
        >
            <Form
                onSubmit={(event) => {
                    event.preventDefault();
                    name.trim() && handleSubmit(name);
                }}
            >
                <InputField
                    label={translate('modal.design.save-as.video.name')}
                    placeholder={translate('modal.design.save-as.video.placeholder')}
                    value={name}
                    onChange={(newValue) => setName(newValue)}
                    isInvalid={name.length > MAX_INPUT_SIZE || name.trim().length === 0}
                    helperText={name.length > MAX_INPUT_SIZE && translate('input.error.max.length')}
                    {...videoNameId}
                    {...{ id: 'editor_header_save_as_video_video_name' }}
                />
            </Form>
        </Modal>
    );
};

export default SaveAsTemplateModal;
