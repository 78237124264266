type Point = { x: number; y: number };
type InitPoint = Point & { r?: number };

type RoundedSide = Point & { length: number };

type Angles = {
    main: number;
    prev: number;
    next: number;
    bis: number;
    dir: number;
    vel: number;
};

type Linked<T> = T & {
    id: number;
    prev: Linked<T>;
    next: Linked<T>;
};

type PreRoundedPoint = Linked<
    Point & {
        offset: number;
        angle: Angles;
        in: { length: number; rest: number };
        out: { length: number; rest: number };
        arc: { radius: number; hit: number; lim: number };
        locked: boolean;
    }
>;

export type RoundedPoint = Linked<
    Point & {
        offset: number;
        angle: Omit<Angles, 'vel'>;
        in: RoundedSide;
        out: RoundedSide;
        arc: Point & { radius: number };
    }
>;

const createCirclePath = (offsetX: number, offsetY: number, radius: number) => {
    // prettier-ignore
    return `M ${offsetX} ${offsetY} m -${radius}, 0 a ${radius},${radius} 0 1,0 ${radius * 2},0 a ${radius},${radius} 0 1,0 -${radius * 2},0 Z`;
};

const createRectanglePath = (offsetX: number, offsetY: number, width: number, height: number) => {
    return `M ${offsetX} ${offsetY} H ${offsetX + width} V ${offsetY + height} H ${offsetX} Z`;
};

const createRoundedRectanglePath = (
    offsetX: number,
    offsetY: number,
    width: number,
    height: number,
    borderRadius: number,
) => {
    const w1 = Math.max(width - 2 * borderRadius, 0);
    const h1 = Math.max(height - 2 * borderRadius, 0);
    const r = Math.max(Math.min((width - w1) / 2, (height - h1) / 2), 0);
    const w = width - 2 * r;
    const h = height - 2 * r;
    const x = offsetX;
    const y = offsetY;

    if (r === 0) {
        return createRectanglePath(x, y, w, h);
    }

    // https://stackoverflow.com/questions/10177985/svg-rounded-corner
    // prettier-ignore
    return `m ${x + r} ${y} h ${w} a ${r} ${r} 0 0 1 ${r} ${r} v ${h} a ${r} ${r} 0 0 1 -${r} ${r} h -${w} a ${r} ${r} 0 0 1 -${r} -${r} v -${h} a ${r} ${r} 0 0 1 ${r} -${r} z`;
};

const createEllipsePath = (offsetX: number, offsetY: number, cx: number, cy: number, rx: number, ry: number) => {
    rx = Math.max(rx, 0);
    ry = Math.max(ry, 0);

    if (!rx && !ry) {
        return '';
    }

    return `m ${offsetX + (cx - rx)} ${offsetY + cy} a ${rx} ${ry} 0 1 0 ${rx * 2} 0 a ${rx} ${ry} 0 1 0 -${
        rx * 2
    } 0 z`;
};

const createTrianglePath = (offsetX: number, offsetY: number, p1X: number, p1Y: number, p2X: number, p2Y: number) => {
    // draw triangle between offset, p1 and p2
    return `M ${offsetX} ${offsetY} L ${p1X} ${p1Y} L ${p2X} ${p2Y} Z`;
};

export const round = (n: number) => Math.round(n * 1e10) / 1e10;

export const getLength = (A: InitPoint, B: InitPoint) =>
    round(Math.sqrt((B.x - A.x) * (B.x - A.x) + (B.y - A.y) * (B.y - A.y)));

export const PI = Math.PI;
export const TAU = PI * 2;

export const getClockDir = (angle1: number, angle2: number) => {
    const diff = angle2 - angle1;

    return (diff > PI && diff < TAU) || (diff < 0 && diff > -PI) ? -1 : 1;
};

export const getAngles = (
    prevpoint: InitPoint,
    currpoint: InitPoint,
    nextpoint: InitPoint,
    prevlen: number,
    mainlen: number,
    nextlen: number,
) => {
    const prev = Math.atan2(prevpoint.y - currpoint.y, prevpoint.x - currpoint.x);
    const next = Math.atan2(nextpoint.y - currpoint.y, nextpoint.x - currpoint.x);
    const main = Math.acos((prevlen * prevlen + nextlen * nextlen - mainlen * mainlen) / (2 * prevlen * nextlen));
    const vel = 1 / Math.tan(main / 2);
    const dir = getClockDir(prev, next);
    const bis = prev + (dir * main) / 2;

    return { prev, next, main, vel, dir, bis };
};
// @ts-ignore
const createCurvedRectPoints = (points: InitPoint[], radius = 0): RoundedPoint[] => {
    const len = points.length;
    const preRoundedPoints: PreRoundedPoint[] = [];
    const limPoints: PreRoundedPoint[] = [];
    const noLimPoints: PreRoundedPoint[] = [];
    const zeroLimPoints: PreRoundedPoint[] = [];

    // prepare points, calc angles
    points.forEach((curr, id) => {
        const prev = points[(id - 1 + len) % len];
        const next = points[(id + 1) % len];
        const prevlen = getLength(prev, curr);
        const mainlen = getLength(prev, next);
        const nextlen = getLength(curr, next);
        const angle = getAngles(prev, curr, next, prevlen, mainlen, nextlen);

        if (angle.main === 0) {
            angle.main = Number.EPSILON;
            angle.vel = Number.MAX_SAFE_INTEGER;
        }

        if (angle.main === PI) {
            angle.vel = 0;
        }

        const preRoundedPoint = {
            x: curr.x,
            y: curr.y,
            angle,
            offset: 0,
            arc: {
                radius,
                hit: radius,
                // limit lim to prevent offset to become bigger than prev/next length
                lim: Math.min(nextlen / angle.vel, prevlen / angle.vel, curr.r || 0),
            },
            in: { length: prevlen, rest: prevlen },
            out: { length: nextlen, rest: nextlen },
            locked: false,
            id,
            get prev() {
                return preRoundedPoints[(id - 1 + len) % len];
            },
            get next() {
                return preRoundedPoints[(id + 1) % len];
            },
        };

        // if point overlaps another point
        if (isNaN(angle.main)) {
            angle.main = 0;
            angle.bis = angle.prev || angle.next; // :)
            zeroLimPoints.push(preRoundedPoint);
        }

        // spread points into either limited, zero-limited or none-limited lists
        // ( is limited if point has own radius to round )
        if (typeof curr.r === 'number') {
            if (curr.r === 0) {
                zeroLimPoints.push(preRoundedPoint);
            } else {
                limPoints.push(preRoundedPoint);
            }
        } else {
            noLimPoints.push(preRoundedPoint);
        }

        preRoundedPoints.push(preRoundedPoint);
    });

    // lock (overlapped | zero radius) points
    zeroLimPoints.forEach((p) => {
        p.angle.vel = 0;
        p.arc.radius = 0;
        lockPoint(p);
    });

    // calc collision radius for each point
    preRoundedPoints.forEach((p) => {
        p.arc.hit = Math.min(
            p.out.rest / (p.angle.vel + p.next.angle.vel),
            p.in.rest / (p.angle.vel + p.prev.angle.vel),
        );
    });

    // calc limit radius and its offsets
    let minHitPoint = getMinHit(limPoints);

    while (minHitPoint) {
        calcLimitRadius(minHitPoint);
        minHitPoint = getMinHit(limPoints);
    }

    // calc common radius and its offsets
    minHitPoint = getMinHit(preRoundedPoints);

    while (minHitPoint) {
        calcCommonRadius(minHitPoint, radius);
        minHitPoint = getMinHit(preRoundedPoints);
    }

    const roundedPoints = [];
    // final calc coordinates
    preRoundedPoints.forEach((p) => {
        const bislen = p.arc.radius / Math.sin(p.angle.main / 2);
        // const next = roundedPoints[(p.id + 1) % len];

        roundedPoints.push({
            id: p.id,
            x: p.x,
            y: p.y,
            angle: {
                main: round(p.angle.main),
                prev: p.angle.prev,
                next: p.angle.next,
                bis: p.angle.bis,
                dir: p.angle.dir,
            },
            offset: round(p.offset),
            arc: {
                radius: round(p.arc.radius),
                x: p.x + (Math.cos(p.angle.bis) * bislen || 0),
                y: p.y + (Math.sin(p.angle.bis) * bislen || 0),
            },
            in: {
                length: p.in.length,
                x: p.x + Math.cos(p.angle.prev) * p.offset,
                y: p.y + Math.sin(p.angle.prev) * p.offset,
            },
            out: {
                length: p.out.length,
                x: p.x + Math.cos(p.angle.next) * p.offset,
                y: p.y + Math.sin(p.angle.next) * p.offset,
            },
            get next() {
                return {
                    in: {
                        x: roundedPoints[(p.id + 1) % len].in.x,
                        y: roundedPoints[(p.id + 1) % len].in.y,
                    },
                };
            },
        });
    });

    return roundedPoints;
};

const calcLimitRadius = (curr: PreRoundedPoint) => {
    const { prev, next } = curr;

    // if prev locked
    if (prev.locked && !next.locked) {
        curr.arc.radius = Math.min(
            Math.max(
                (curr.out.length - next.arc.lim * next.angle.vel) / curr.angle.vel,
                curr.out.length / (curr.angle.vel + next.angle.vel),
            ),
            curr.in.rest / curr.angle.vel,
            curr.arc.lim,
        );
    } else if (next.locked && !prev.locked) {
        curr.arc.radius = Math.min(
            Math.max(
                (curr.in.length - prev.arc.lim * prev.angle.vel) / curr.angle.vel,
                curr.in.length / (curr.angle.vel + prev.angle.vel),
            ),
            curr.out.rest / curr.angle.vel,
            curr.arc.lim,
        );
    } else if (next.locked && prev.locked) {
        curr.arc.radius = Math.min(curr.in.rest / curr.angle.vel, curr.out.rest / curr.angle.vel, curr.arc.lim);
    } else {
        curr.arc.radius = Math.min(
            Math.max(
                (curr.in.length - prev.arc.lim * prev.angle.vel) / curr.angle.vel,
                curr.in.length / (curr.angle.vel + prev.angle.vel),
            ),
            Math.max(
                (curr.out.length - next.arc.lim * next.angle.vel) / curr.angle.vel,
                curr.out.length / (curr.angle.vel + next.angle.vel),
            ),
            curr.arc.lim,
        );
    }

    lockPoint(curr);
};

const calcCommonRadius = (curr: PreRoundedPoint, radius: number) => {
    if (radius > curr.arc.hit) {
        const { prev, next } = curr;

        // Math.max(..., 0) cased by somehow getting rest = -2.71e-15 from calcLimit
        if (prev.locked && !next.locked) {
            curr.arc.radius = Math.max(
                Math.min(
                    curr.in.rest / curr.angle.vel,
                    curr.out.length / (curr.angle.vel + next.angle.vel),
                    curr.arc.radius,
                ),
                0,
            );
        } else if (next.locked && !prev.locked) {
            curr.arc.radius = Math.max(
                Math.min(
                    curr.out.rest / curr.angle.vel,
                    curr.in.length / (curr.angle.vel + prev.angle.vel),
                    curr.arc.radius,
                ),
                0,
            );
        } else if (next.locked && prev.locked) {
            curr.arc.radius = Math.max(
                Math.min(curr.in.rest / curr.angle.vel, curr.out.rest / curr.angle.vel, curr.arc.radius),
                0,
            );
        } else {
            curr.arc.radius = curr.arc.hit;
        }
    }

    lockPoint(curr);
};

const lockPoint = (curr: PreRoundedPoint) => {
    const { prev, next } = curr;

    curr.offset = curr.arc.radius * curr.angle.vel;

    prev.out.rest -= curr.offset;
    curr.in.rest -= curr.offset;
    curr.out.rest -= curr.offset;
    next.in.rest -= curr.offset;

    curr.locked = true;

    // to get right getMinHit then
    prev.arc.hit = Math.min(
        prev.in.length / (prev.angle.vel + prev.prev.angle.vel),
        prev.in.rest / prev.angle.vel,
        prev.out.rest / prev.angle.vel,
    );
    next.arc.hit = Math.min(
        next.out.length / (next.angle.vel + next.next.angle.vel),
        next.out.rest / next.angle.vel,
        next.in.rest / next.angle.vel,
    );
};

const getMinHit = (arr: PreRoundedPoint[]) =>
    arr.reduce(
        (min: PreRoundedPoint | null, p) => (p.locked ? min : !min ? p : p.arc.hit < min.arc.hit ? p : min),
        null,
    );

export {
    createCirclePath,
    createRectanglePath,
    createTrianglePath,
    createRoundedRectanglePath,
    createEllipsePath,
    createCurvedRectPoints,
};
