import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Thumbnail } from '@bynder/design-system';
import { IconImage, IconVideo } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { useDispatch, useSelector } from 'react-redux';
import { selectBlankType, setBlankType } from 'packages/store/newDesign/newDesign.actions';
import { getSelectedBlankItems, getSelectedBlankType } from 'packages/store/newDesign/newDesign.selectors';
import { deselectGallery } from '~/store/gallery/gallery.actions';
import { deselectTemplate } from '~/store/templates/templates.actions';
import AuthorizationAllowBlock from '~/common/AuthorizationBlock/AuthorizationAllowBlock';
import features from '~/configs/features';
import { dropImportedTemplate } from 'packages/store/importTemplate/importTemplate.actions';
import { Item, Container, MediaType, Title, Name, Details, MediaTypeText } from './Blank.styled';
import useSizes from './useSizes';
import { MediaTypeSelection } from './types';

function Blank() {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const selectedType = useSelector(getSelectedBlankType);
    const [videoSelected, imageSelected] = useSelector(getSelectedBlankItems);

    const [videoSearch, setVideoSearch] = useState('');
    const [imageSearch, setImageSearch] = useState('');

    const { videoCategories, imageCategories } = useSizes(videoSearch, imageSearch);

    useEffect(() => {
        if (!videoSelected) dispatch(setBlankType(MediaTypeSelection.VIDEO, videoCategories[0].items[0]));
        if (!imageSelected) dispatch(setBlankType(MediaTypeSelection.IMAGE, imageCategories[0].items[0]));
    }, [videoSelected, imageSelected]);

    useEffect(() => {
        dispatch(dropImportedTemplate());
    }, []);

    return (
        <>
            <Title id="new_design_modal_blank_title">{translate('modal.design.create.blank.title')}</Title>
            <Container>
                <AuthorizationAllowBlock features={[features.VIDEOS_ENABLED]}>
                    {videoSelected && (
                        <Item
                            onClick={() => {
                                dispatch(selectBlankType(MediaTypeSelection.VIDEO));
                                dispatch(deselectTemplate());
                                dispatch(deselectGallery());
                            }}
                            selected={selectedType === MediaTypeSelection.VIDEO}
                            tabIndex={0}
                            key="video"
                        >
                            <MediaType>
                                <Thumbnail variant="clean" icon={<IconVideo />} />
                                <MediaTypeText>
                                    <Name>{translate('modal.design.create.blank.mediaType.video')}</Name>
                                    <Details>{`${videoSelected.size.width} × ${videoSelected.size.height} px`}</Details>
                                </MediaTypeText>
                            </MediaType>
                            <Dropdown
                                trigger={({ isOpen, ...triggerProps }) => (
                                    <Button
                                        isPressed={isOpen}
                                        variant="clean"
                                        icon={videoSelected.icon}
                                        rightIcon={<Dropdown.Arrow />}
                                        data-testid="blank video dropdown"
                                        title={videoSelected.label}
                                        {...triggerProps}
                                    >
                                        {videoSelected.label}
                                    </Button>
                                )}
                                position="bottom-right"
                            >
                                <Dropdown.SearchInput
                                    value={videoSearch}
                                    onChange={(value) => setVideoSearch(value)}
                                    placeholder={translate(
                                        'modal.design.create.blank.mediaType.video.search.placeholder',
                                    )}
                                    aria-label={translate('modal.design.create.blank.mediaType.video.search.label')}
                                />
                                {videoCategories.map((category, index) => (
                                    <>
                                        <Dropdown.SectionTitle>{category.label}</Dropdown.SectionTitle>
                                        {category.items.map((item) => (
                                            <Dropdown.Item
                                                key={item.label}
                                                icon={item.icon}
                                                isChecked={item.label === videoSelected.label}
                                                onClick={() => {
                                                    dispatch(selectBlankType(MediaTypeSelection.VIDEO));
                                                    dispatch(setBlankType(MediaTypeSelection.VIDEO, item));
                                                }}
                                            >
                                                {item.label}
                                            </Dropdown.Item>
                                        ))}
                                        {videoCategories.length - 1 !== index ? <Dropdown.Divider /> : null}
                                    </>
                                ))}
                                {!videoCategories.length ? (
                                    <Dropdown.Text>
                                        {translate('modal.design.create.blank.items.no_results')}
                                    </Dropdown.Text>
                                ) : null}
                            </Dropdown>
                        </Item>
                    )}
                </AuthorizationAllowBlock>
                <AuthorizationAllowBlock features={[features.IMAGES_ENABLED]}>
                    {imageSelected && (
                        <Item
                            onClick={() => {
                                dispatch(selectBlankType(MediaTypeSelection.IMAGE));
                                dispatch(deselectTemplate());
                                dispatch(deselectGallery());
                            }}
                            selected={selectedType === MediaTypeSelection.IMAGE}
                            tabIndex={0}
                            key="image"
                        >
                            <MediaType>
                                <Thumbnail variant="clean" icon={<IconImage />} />
                                <MediaTypeText>
                                    <Name>{translate('modal.design.create.blank.mediaType.image')}</Name>
                                    <Details>{`${imageSelected.size.width} × ${imageSelected.size.height} px`}</Details>
                                </MediaTypeText>
                            </MediaType>
                            <Dropdown
                                trigger={({ isOpen, ...triggerProps }) => (
                                    <Button
                                        isPressed={isOpen}
                                        variant="clean"
                                        icon={imageSelected.icon}
                                        rightIcon={<Dropdown.Arrow />}
                                        title={imageSelected.label}
                                        data-testid="blank image dropdown"
                                        {...triggerProps}
                                    >
                                        {imageSelected.label}
                                    </Button>
                                )}
                                position="bottom-right"
                            >
                                <Dropdown.SearchInput
                                    value={imageSearch}
                                    onChange={(value) => setImageSearch(value)}
                                    placeholder={translate(
                                        'modal.design.create.blank.mediaType.image.search.placeholder',
                                    )}
                                    aria-label={translate('modal.design.create.blank.mediaType.image.search.label')}
                                />

                                {imageCategories.map((category, index) => (
                                    <>
                                        <Dropdown.SectionTitle>{category.label}</Dropdown.SectionTitle>
                                        {category.items.map((item) => (
                                            <Dropdown.Item
                                                key={item.label}
                                                icon={item.icon}
                                                isChecked={item.label === imageSelected.label}
                                                onClick={() => {
                                                    dispatch(selectBlankType(MediaTypeSelection.IMAGE));
                                                    dispatch(setBlankType(MediaTypeSelection.IMAGE, item));
                                                }}
                                            >
                                                {item.label}
                                            </Dropdown.Item>
                                        ))}
                                        {imageCategories.length - 1 !== index ? <Dropdown.Divider /> : null}
                                    </>
                                ))}
                                {!imageCategories.length ? (
                                    <Dropdown.Text>
                                        {translate('modal.design.create.blank.items.no_results')}
                                    </Dropdown.Text>
                                ) : null}
                            </Dropdown>
                        </Item>
                    )}
                </AuthorizationAllowBlock>
            </Container>
        </>
    );
}

export default Blank;
