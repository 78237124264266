export const PROCESSING = 'Processing';
export const DURATION = 'duration';
export const CALCULATING = 'Calculating...';
export const TRIM_IN_PROCESSING_CONTENT = 'Before you can proceed we need to process and optimize the';
export const LAYERS = 'Layers';
export const NEW_LAYER = 'New layer';
export const FORMAT = 'Format';
export const GROUP_LAYERS = 'Group layers';
export const UNGROUP = 'Ungroup';
export const MORE_OPTIONS = 'More options';
export const DUPLICATE_LAYER = 'Duplicate layer';
export const DELETE_LAYER = 'Delete layer';
export const DELETE_GROUP = 'Delete group';
export const SAVE_AS_PRESET = 'Save as preset';
export const CANCEL = 'Cancel';
export const RENAMING_VARIATION = 'Renaming variation';
export const VARIATION_NAME_UPDATED = 'Variation name updated';
export const RENAMING_VARIATIONS_FAILED = 'Renaming variation failed';
export const VARIATION_NAME_TOO_LONG = 'Variation name too long';
export const VARIATION_NAME_TOO_LONG_DESCRIPTION = 'Variation names can’t be longer than 50 characters';
export const DELETING_VARIATIONS = 'Deleting variation(s)';
export const VARIATIONS_DELETED = 'Variation(s) deleted';
export const DELETING_VARIATIONS_FAILED = 'Deleting variation(s) failed';
export const ADD_VARIATION_FAILED = 'Adding variation failed';
export const SAVING_VARIATIONS_FAILED = 'Saving variation(s) failed';
export const DUPLICATING_VARIATION = 'Duplicating variation';
export const VARIATION_DUPLICATED = 'Variation duplicated';
export const VARIATION_DUPLICATION_FAILED = 'Variation duplication failed';
export const EDIT_DESIGN = 'Edit design';
export const EXPORT = 'Export';
export const SEND_TO_WAITING_ROOM = 'Send to Waiting Room';
export const DOWNLOAD = 'Download';
export const DOWNLOAD_ALL = 'Download all';
export const ACTIVITY_LOG = 'Activity log';
export const ALL_EXPORTS = 'All exports';
export const LOADING = 'Loading';
export const BACK_TO_VARIATIONS = 'Back to variations';
export const START_EXPORT = 'Start export';
export const START_DOWNLOAD = 'Start download';
export const CONTINUE = 'Continue';
export const DONE = 'Done';
export const COMPLETED = 'Completed';
export const FILE_NAME = 'File name';
export const IN_PROGRESS = 'In progress';
export const COPY = ' copy';
export const TYPE_AND_SIZE_DOT_SEPARATOR = '•';
export const REMOVE_FROM_ACTIVITY_LOG = 'Remove from activity log';
export const VARIATION = 'Variation';
