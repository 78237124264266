import { type ShapedTextPosition } from '../Core/StructuredText';

export function orderSelectPositions(
    x: ShapedTextPosition | null,
    y: ShapedTextPosition | null,
): [ShapedTextPosition, ShapedTextPosition] | [undefined, undefined] {
    if (x === null || y === null) {
        return [undefined, undefined];
    }

    if (x.textIdx > y.textIdx) {
        return [y, x];
    }

    return [x, y];
}

export function isSelectionInclude(pos: ShapedTextPosition, start: ShapedTextPosition, end: ShapedTextPosition) {
    const [x, y] = orderSelectPositions(start, end);

    return (
        pos.lineIdx >= x.lineIdx &&
        pos.lineIdx <= y.lineIdx &&
        pos.glyphIdx >= x.glyphIdx &&
        pos.glyphIdx <= y.glyphIdx &&
        pos.subTextIdx >= x.subTextIdx &&
        pos.subTextIdx <= y.subTextIdx
    );
}

export function getSelectionDirection(x: ShapedTextPosition | null, y: ShapedTextPosition | null): 'left' | 'right' {
    if (x === null || y === null) {
        return 'right';
    }

    if (
        x.lineIdx > y.lineIdx ||
        (x.lineIdx === y.lineIdx && x.glyphIdx > y.glyphIdx) ||
        (x.lineIdx === y.lineIdx && x.glyphIdx === y.glyphIdx && x.subTextIdx > y.subTextIdx)
    ) {
        return 'left';
    }

    return 'right';
}

const LeadingType = {
    // Treat leading configuration as a percentage of the external leading calculated from the font between baselines
    EXTERNAL_LEADING_PCT: 'EXTERNAL_LEADING_PCT',
    // Treat leading as a percentage of font size between baselines
    LEADING_PCT: 'LEADING_PCT',
};

export function convertLeadingValue(
    textController,
    fontId,
    leadingValue,
    oldLeadingType = LeadingType.EXTERNAL_LEADING_PCT,
    newLeadingType = LeadingType.LEADING_PCT,
) {
    // leading: this.font.lineHeight - 1, // vertical spacing use with vbs-structured-text 1.2.0

    if (oldLeadingType === LeadingType.EXTERNAL_LEADING_PCT && newLeadingType === LeadingType.LEADING_PCT) {
        // Determine line height that was used for the old value
        try {
            const fontData = textController.getFontData(fontId);

            const ascenderEm = fontData.ascender / fontData.upem;
            const descenderEm = fontData.descender / fontData.upem;
            const lineGapEm = fontData.line_gap / fontData.upem;
            const lineHeightEm = ascenderEm - descenderEm + lineGapEm;

            return lineHeightEm + (leadingValue - 1);
        } catch {
            console.warn(`Could not convert leadingType for font ${fontId}`);

            return leadingValue;
        }
    }

    return leadingValue;
}

export const cloneObj = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

export const isAccented = (letter: string) => letter !== letter.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
