import React, { useState } from 'react';
import DeleteStyleModal from './DeleteStyleModal';

const useDeleteStyleModal = ({ deleteTextStyle }) => {
    const [isDeleteStyleModalOpen, setDeleteStyleModalOpen] = useState(false);

    const onClose = () => {
        setDeleteStyleModalOpen(false);
    };

    const renderDeleteStyleModal = () => (
        <DeleteStyleModal isOpen={isDeleteStyleModalOpen} onClose={onClose} deleteTextStyle={deleteTextStyle} />
    );

    return {
        setDeleteStyleModalOpen,
        renderDeleteStyleModal,
    };
};

export default useDeleteStyleModal;
