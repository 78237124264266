import { getAllElementsRecursively } from '@bynder-studio/render-core';

export const excludeChildren = (selectedElement, allElements) => {
    if (!selectedElement.children) {
        return allElements;
    }

    const selectedElementChildren = getAllElementsRecursively([selectedElement]);
    const childrenIds = selectedElementChildren.map((element) => element.id);

    return allElements.filter((element) => !childrenIds.includes(element.id));
};

export const excludeParents = (selectedElement, allElements) => {
    if (!selectedElement.parent) {
        return allElements;
    }

    const parents: any[] = [];
    let currentElement = selectedElement.parent;

    while (currentElement) {
        parents.push(currentElement.id);
        currentElement = currentElement.parent;
    }

    return allElements.filter((element) => !parents.includes(element.id));
};

export const checkMaskConflict = (element, draggingId) => {
    let currentElement = element;

    while (currentElement) {
        if (currentElement.mask && draggingId === currentElement.mask.elementId) {
            return true;
        }
        currentElement = currentElement.parent;
    }

    return false;
};
