import styled from 'styled-components';
import { Button, token } from '@bynder/design-system';
import { OrderButtonProps, OrderFilterType } from './types';

export const Sorting = styled.div`
    flex: 50%;
    display: flex;
    justify-content: flex-end;

    button {
        margin-left: ${token.spacing3};
    }
`;

export const OrderButton = styled(Button)<OrderButtonProps>`
    ${(props) => props.order === OrderFilterType.ASCENDING && `transform: rotate(180deg);`}
    margin-left: ${token.spacing3};
`;

export default Sorting;
