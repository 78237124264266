import React, { useCallback } from 'react';
import { useTranslate } from '@bynder/localization';
import { Card } from '@bynder/design-system';
import { IconVideoFrame } from '@bynder/icons';
import { Template } from 'packages/store/templates/types';

type PreviewActionButtonProps = {
    template: Template;
    onClick?: (template: Template) => void;
    isDisabled: boolean;
};

export const PreviewActionButton = ({ template, isDisabled, onClick }: PreviewActionButtonProps) => {
    const { translate } = useTranslate();

    const handleClick = useCallback(() => {
        onClick?.(template);
    }, [onClick, template]);

    return (
        <Card.ContextActionButton
            tooltip={translate('modal.design.create.template.preview')}
            aria-label={translate('modal.design.create.template.preview')}
            onClick={handleClick}
            isDisabled={isDisabled}
        >
            <IconVideoFrame />
        </Card.ContextActionButton>
    );
};
