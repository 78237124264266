import React from 'react';
import { notify, Thumbnail } from '@bynder/design-system';
import { IconCancel } from '@bynder/icons';
import { Translate } from '@bynder/localization';
import { getUnixTime } from 'date-fns';
import BynderIdentity from 'packages/services/BynderIdentity';
import AccessService from 'packages/services/AccessControl';
import { catchResponseError } from 'packages/helpers/helpers';
import { fetchCreativesByOptions } from '~/store/creatives/creatives.actions';
import { fetchCollection, fetchCollections } from '~/store/collections/collections.actions';
import { fetchTemplates } from '~/store/templates/templates.actions';
// import { sendSharingCreateDesignEvent } from '~/store/amplitude/actions';
import { sendAppcuesEvent } from '~/helpers/RouteWithAppcues';
import { actionTypes, EntityType } from './types';
import { getCreatives } from '../creatives/creatives.selectors';
import { getTemplates } from '../templates/templates.selectors';
import { getCollections } from '../collections/collections.selectors';
import { customerIdSelector } from '../user/user.selectors';
import { getCategoriesState } from '../categories/categories.selectors';
import { fetchCategories, fetchCategory } from '../categories/categories.actions';

const getEntityType = (entityType) => {
    if (entityType === EntityType.TEMPLATE) {
        return EntityType.CREATIVE;
    }

    if (entityType === EntityType.CATEGORY) {
        return EntityType.COLLECTION;
    }

    return entityType;
};

export function fetchSharing(entityId: number, entityType: EntityType) {
    return (dispatch) => {
        dispatch({ type: actionTypes.SHARING_FETCH_REQUEST });
        AccessService.getAccess([entityId], getEntityType(entityType))
            .then(({ status, json }) => {
                if (status === 200) {
                    const data = json[0];
                    dispatch({
                        type: actionTypes.SHARING_FETCH_SUCCESS,
                        entityId,
                        entityType,
                        entityGrantedPermissions: data.entityGrantedPermissions,
                    });
                } else {
                    dispatch({ type: actionTypes.SHARING_FETCH_FAILURE });
                }
            })
            .catch(catchResponseError);
    };
}

export function createSharing({ entityId, entityType, securityIdentities, isDetailsPage }, onClose: () => void) {
    return (dispatch, getState) => {
        const { options: creativesOptions } = getCreatives(getState());
        const { fetchOptions: templatesOptions } = getTemplates(getState());
        const { fetchOptions: collectionOptions } = getCollections(getState());
        const { fetchOptions: categoriesOptions } = getCategoriesState(getState());
        const currentCompany = customerIdSelector(getState());

        const fetchActionMap = {
            [EntityType.CREATIVE]: () => fetchCreativesByOptions(creativesOptions, true),
            [EntityType.COLLECTION]: () =>
                isDetailsPage ? fetchCollection(entityId) : fetchCollections(collectionOptions, currentCompany, true),
            [EntityType.TEMPLATE]: () =>
                fetchTemplates({
                    newOptions: templatesOptions,
                    setSelected: false,
                    fetchFromStart: true,
                    sharedTemplates: [entityId],
                }),
            [EntityType.CATEGORY]: () =>
                isDetailsPage ? fetchCategory(entityId) : fetchCategories(categoriesOptions, currentCompany, true),
        };

        dispatch({ type: actionTypes.SHARING_CREATE_REQUEST });
        AccessService.updateAccess({ entityId, entityType: getEntityType(entityType), securityIdentities })
            .then(({ status }) => {
                if (status === 200) {
                    dispatch({ type: actionTypes.SHARING_CREATE_SUCCESS });
                    dispatch(fetchActionMap[entityType]());
                    // dispatch(sendSharingCreateDesignEvent());
                    sendAppcuesEvent('Entity permissions updated', { entityId, entityType });
                } else {
                    dispatch({ type: actionTypes.SHARING_CREATE_FAILURE });
                }

                onClose();
            })
            .catch(catchResponseError);
    };
}

export function transferOwnership({ entityId, entityType, bynderUserId }, onClose: () => void) {
    return (dispatch, getState) => {
        const { options: creativesOptions } = getCreatives(getState());
        const { fetchOptions: templatesOptions } = getTemplates(getState());
        const { fetchOptions: collectionOptions } = getCollections(getState());
        const { fetchOptions: categoriesOptions } = getCategoriesState(getState());
        const currentCompany = customerIdSelector(getState());

        const fetchActionMap = {
            [EntityType.CREATIVE]: () => fetchCreativesByOptions(creativesOptions, true),
            [EntityType.COLLECTION]: () => fetchCollections(collectionOptions, currentCompany, true),
            [EntityType.TEMPLATE]: () =>
                fetchTemplates({
                    newOptions: templatesOptions,
                    setSelected: false,
                    fetchFromStart: true,
                }),
            [EntityType.CATEGORY]: () => fetchCategories(categoriesOptions, currentCompany, true),
        };

        dispatch({ type: actionTypes.OWNERSHIP_TRANSFER_REQUEST });
        AccessService.updateOwnership({
            entityId,
            entityName: getEntityType(entityType),
            bynderUserId,
        })
            .then(({ status, json }) => {
                if (status === 200) {
                    dispatch({ type: actionTypes.OWNERSHIP_TRANSFER_REQUEST_SUCCESS });
                    dispatch(fetchActionMap[entityType]());
                    sendAppcuesEvent('Entity ownership transferred', { entityId, entityType });
                } else {
                    dispatch({ type: actionTypes.OWNERSHIP_TRANSFER_REQUEST_FAILURE });

                    if (
                        json.message ===
                        "Illegal access to resource you don't have permissions to transfer ownership of this template"
                    ) {
                        notify({
                            thumbnail: (
                                <Thumbnail
                                    variant="clean"
                                    shape="circle"
                                    backgroundColor="red500"
                                    icon={<IconCancel />}
                                    iconColor="white"
                                />
                            ),
                            title: <Translate id="modal.sharing.ownership.transfer.no-permission" />,
                        });
                    }
                }

                onClose();
            })
            .catch(catchResponseError);
    };
}

export function fetchBynderIdentities(query: string) {
    return (dispatch, getState) => {
        const searchTime = getUnixTime(new Date(Date.now()));
        dispatch({ type: actionTypes.SHARING_FETCH_BYNDER_IDENTITIES_REQUEST, searchTime });
        BynderIdentity.fetchIdentities(query)
            .then(({ status, json }) => {
                if (status === 200) {
                    const state = getState();

                    if (
                        state.sharing.searchIdentities.loading &&
                        state.sharing.searchIdentities.searchTime &&
                        state.sharing.searchIdentities.searchTime > searchTime
                    ) {
                        return;
                    }

                    dispatch({ type: actionTypes.SHARING_FETCH_BYNDER_IDENTITIES_SUCCESS, identities: json });
                } else {
                    dispatch({ type: actionTypes.SHARING_FETCH_BYNDER_IDENTITIES_FAILURE, error: json });
                }
            })
            .catch(catchResponseError);
    };
}

export function dropSharingState() {
    return {
        type: actionTypes.SHARING_DROP_STATE,
    };
}

export function dropSharingSearchResults() {
    return {
        type: actionTypes.SHARING_DROP_SEARCH_RESULTS,
    };
}
