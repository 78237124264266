enum exportTypes {
    'DOWNLOAD' = 'DOWNLOAD',
    'BYNDER' = 'BYNDER',
    'DOWNLOAD_BYNDER' = 'DOWNLOAD_BYNDER',
}

export const EXPORT_TYPE_OPTIONS = [
    {
        title: 'Download & Waiting Room',
        name: exportTypes.DOWNLOAD_BYNDER,
        description:
            'Files can be downloaded directly once the export is completed or send files to the Waiting Room for review',
    },
    {
        title: 'Download only',
        name: exportTypes.DOWNLOAD,
        description: 'Files can be downloaded directly once the export is completed',
    },
    {
        title: 'Waiting Room only',
        name: exportTypes.BYNDER,
        description:
            'Users are only allowed to send files to the Waiting Room for review — Downloading files from the activity log is disabled',
    },
];
