import React from 'react';
import { getUnixTime } from 'date-fns';
import { notify, Thumbnail } from '@bynder/design-system';
import { IconCancel } from '@bynder/icons';
import { Translate } from '@bynder/localization';
import { DesignSubPages } from 'packages/pages/designCreateModal/components/Sidebar/types';
import { getDurationInSec } from 'packages/pages/components/card/utils';
import { socketSubscribe, subscribeDesignCreationProgressOptions } from 'packages/socket';
import { catchResponseError } from 'packages/helpers/helpers';
import { fetchCreativesByOptions, designsGhostCards } from '~/store/creatives/creatives.actions';
import { fetchCollection } from '~/store/collections/collections.actions';
import { envVar } from '~/helpers/envVar';
import { sendAppcuesEvent } from '~/helpers/RouteWithAppcues';
import {
    actionTypes,
    DesignFileAnalysis,
    DesignFileAnalysisGlobal,
    DesignFileAnalysisLocal,
    errorKeys,
    ImportType,
    MAX_INPUT_SIZE,
} from './types';
import TemplatesServiceFromXML from '../../services/ImportTemplateFromXML';
import TemplatesServiceFromDesignFile, {
    CreateTemplateData,
    RequestTypes,
} from '../../services/ImportTemplateFromDesignFile';
import { setPage } from '../newDesign/newDesign.actions';
import { customerIdSelector, getIdentityId } from '../user/user.selectors';
import {
    getFigmaFile,
    getFigmaMultiDesignMode,
    getSelectedFigmaFrameIds,
    getSelectedFigmaFrames,
} from '../figma/figma.selectors';

const validationTemplateRulesFromXML = () => {
    const maxResolution = envVar.videoMaxDimensions;

    return [
        (json) => !json?.name && errorKeys.EMPTY_NAME,
        (json) => json?.name?.length > MAX_INPUT_SIZE && errorKeys.TOO_LONG_NAME,
        (json) => json?.duration === 0 && errorKeys.MIN_DURATION,
        (json) => json && getDurationInSec(json) > 300 && errorKeys.MAX_DURATION, // no longer then 5 minutes
        (json) =>
            json?.sizes &&
            json?.sizes?.length &&
            json?.sizes?.some((size) => size?.width === 0 || size?.height === 0) &&
            errorKeys.MIN_SIZE,
        (json) =>
            json?.sizes &&
            json?.sizes?.length &&
            json?.sizes?.some((size) => size?.width > maxResolution || size?.height > maxResolution) &&
            errorKeys.MAX_RESOLUTION,
        (json) =>
            json?.textElementCount + json?.imageElementCount + json?.videoElementCount > 150 && errorKeys.MAX_ASSETS,
        (json) =>
            json?.sizes &&
            json?.sizes?.length &&
            json?.sizes?.some((size) => size?.width % 2 !== 0 || size?.height % 2 !== 0) &&
            errorKeys.ODD_RESOLUTION,
    ];
};

const getName = (json) => json?.name || json?.fileName;

const fileValidationTemplateRulesFromDesignFile = [(json) => !getName(json) && errorKeys.EMPTY_NAME];

const artboardValidationTemplateRulesFromDesignFile = () => {
    const maxResolution = envVar.imageMaxDimensions;

    return [
        (json) => !getName(json) && { artboardIndex: json?.artboardIndex, error: errorKeys.EMPTY_NAME },
        (json) =>
            (json?.width === 0 || json?.height === 0) && {
                artboardIndex: json?.artboardIndex,
                error: errorKeys.MIN_SIZE,
            },
        (json) =>
            (json?.width > maxResolution || json?.height > maxResolution) && {
                artboardIndex: json?.artboardIndex,
                error: errorKeys.MAX_RESOLUTION,
            },
        (json) =>
            (json?.width < 10 || json?.height < 10) && {
                artboardIndex: json?.artboardIndex,
                error: errorKeys.MIN_RESOLUTION,
            },
    ];
};

export const sendNotify = (title: string, description?: string) =>
    notify({
        thumbnail: (
            <Thumbnail
                variant="clean"
                shape="circle"
                backgroundColor="red500"
                icon={<IconCancel />}
                iconColor="white"
            />
        ),
        title: <Translate id={title} />,
        description: description && <Translate id={description} />,
    });

export function importTemplateFromXML(formData: FormData) {
    return (dispatch, getState) => {
        const requestTime = getUnixTime(new Date(Date.now()));

        dispatch({ type: actionTypes.IMPORT_TEMPLATE_REQUEST, requestTime, importType: ImportType.XML });

        TemplatesServiceFromXML.uploadTemplate(formData)
            .then((response) => {
                const state = getState();

                if (
                    state.importTemplate.cancelImportRequest ||
                    (state.importTemplate.loading &&
                        state.importTemplate.requestTime &&
                        state.importTemplate.requestTime > requestTime)
                ) {
                    return Promise.reject();
                }

                return response;
            })
            .then(({ status, json }) => {
                if (status === 200) {
                    const validationErrors = validationTemplateRulesFromXML()
                        .map((rule) => rule(json))
                        .filter((error) => error);
                    dispatch({
                        type: actionTypes.IMPORT_TEMPLATE_SUCCESS,
                        template: { ...json, fromXML: true },
                        validationErrors,
                    });
                    dispatch(setPage(DesignSubPages.IMPORT_STATUS));
                    sendAppcuesEvent('Template imported from XML', { id: json.id, name: json.name });
                } else {
                    dispatch({ type: actionTypes.IMPORT_TEMPLATE_FAILURE, errors: json });

                    if (json.status === 'WRONG_TYPE') {
                        sendNotify('modal.design.create.design.import.file.type_error');
                    }
                }
            })
            .catch(catchResponseError);
    };
}

const getParticularFileData = (file: DesignFileAnalysis) =>
    'sourceType' in file && file.sourceType === 'INTERNAL'
        ? ({ isLocalFile: true, fileUrl: (file as DesignFileAnalysisLocal).fileUrl } as const)
        : { mediaId: (file as DesignFileAnalysisGlobal).databaseId };

export function importTemplateFromDesignFileForAnalysis(file) {
    return (dispatch, getState) => {
        const requestTime = getUnixTime(new Date(Date.now()));
        const customerId = getState().user.currentCompany;
        const { identityId: creatorId } = getState().user.user;

        dispatch({ type: actionTypes.IMPORT_TEMPLATE_REQUEST, requestTime, importType: ImportType.DESIGN_FILE });

        TemplatesServiceFromDesignFile.createTemplate({
            creatorId,
            customerId,
            type: RequestTypes.ANALYSIS,
            ...getParticularFileData(file),
        } as CreateTemplateData)
            .then((response) => {
                const state = getState();

                if (
                    state.importTemplate.cancelImportRequest ||
                    (state.importTemplate.loading &&
                        state.importTemplate.requestTime &&
                        state.importTemplate.requestTime > requestTime)
                ) {
                    return Promise.reject();
                }

                return response;
            })
            .then(({ status, json }) => {
                if (status === 200) {
                    socketSubscribe(
                        subscribeDesignCreationProgressOptions({
                            customerId,
                            designCreationRequestIds: [json.id],
                            onMessage: (data, unsubscribe) => {
                                const state = getState();

                                if (
                                    state.importTemplate.cancelImportRequest ||
                                    (state.importTemplate.loading &&
                                        state.importTemplate.requestTime &&
                                        state.importTemplate.requestTime > requestTime)
                                ) {
                                    unsubscribe();

                                    return;
                                }

                                if (
                                    data &&
                                    data.items.length &&
                                    data.items[0] &&
                                    (data.items[0].status === errorKeys.NOT_ACCEPTABLE ||
                                        data.items[0].status === errorKeys.FAILED) &&
                                    data.items[0].id === json.id
                                ) {
                                    dispatch({ type: actionTypes.IMPORT_TEMPLATE_FAILURE, errors: json });

                                    if (data.items[0].status === errorKeys.FAILED) {
                                        sendNotify(
                                            'modal.design.create.design.import.file.type_error.design_file.damaged',
                                        );
                                    } else {
                                        sendNotify('modal.design.create.design.import.file.type_error.design_file');
                                    }

                                    unsubscribe();

                                    return;
                                }

                                if (data && data.items.length && data.items[0].id === json.id) {
                                    dispatch({
                                        type: actionTypes.IMPORT_TEMPLATE_DESIGN_FILE_PROGRESS,
                                        progress: data.items[0].progress || 0,
                                    });

                                    if (data.items[0].progress === 100) {
                                        const designFileAnalysis = {
                                            ...file,
                                            designCreationResponses: [
                                                ...data.items[0].designCreationData.designCreationResponses.map(
                                                    (artboard, index) => ({
                                                        artboardIndex: index,
                                                        ...artboard,
                                                    }),
                                                ),
                                            ],
                                        };

                                        // TODO: Validation might be moved to BE
                                        const templateValidationErrors = fileValidationTemplateRulesFromDesignFile
                                            .map((rule) => rule(designFileAnalysis))
                                            .filter((error) => error);

                                        const artboardValidationErrors = designFileAnalysis.designCreationResponses
                                            .map((artboard) =>
                                                artboardValidationTemplateRulesFromDesignFile()
                                                    .map((rule) => rule(artboard))
                                                    .filter((error) => error),
                                            )
                                            .filter((artboard) => artboard.length);

                                        dispatch({
                                            type: actionTypes.IMPORT_TEMPLATE_DESIGN_FILE_SUCCESS,
                                            designFileAnalysis,
                                            validationErrors: [
                                                ...templateValidationErrors,
                                                ...artboardValidationErrors,
                                            ],
                                        });

                                        sendAppcuesEvent('Template imported from design file', {
                                            id: json.id,
                                            name: json.name,
                                        });

                                        // Delaying redirect in order import progress to look more realistic
                                        setTimeout(() => dispatch(setPage(DesignSubPages.IMPORT_STATUS)), 6000);

                                        unsubscribe();
                                    }
                                }
                            },
                        }),
                    );
                } else {
                    if (status === 406) {
                        sendNotify('modal.design.create.design.import.file.type_error.design_file');
                    } else {
                        sendNotify('modal.design.create.design.import.file.undefined');
                    }

                    dispatch({ type: actionTypes.IMPORT_TEMPLATE_FAILURE, errors: json });
                }
            })
            .catch(catchResponseError);
    };
}

export function createDesignsFromDesignFile(collectionId: string) {
    return (dispatch, getState) => {
        const customerId = customerIdSelector(getState());
        const creatorId = getIdentityId(getState());

        const file = getState().importTemplate.designFileAnalysis;
        const { multiDesignMode } = getState().importTemplate;

        const templateImportState = getState().importTemplate;
        const { validationErrors } = templateImportState;

        const invalidArtboardIndexes =
            validationErrors?.length && validationErrors.map((item) => item[0].artboardIndex);

        const filteredDesigns = invalidArtboardIndexes.length
            ? templateImportState.designFileAnalysis.designCreationResponses
                  .map(
                      (design, index) =>
                          !invalidArtboardIndexes.includes(index) && {
                              name: design.name,
                              creativeType: 'IMAGE',
                              aspectRatio: calculateAspectRatio(design),
                          },
                  )
                  .filter((design) => design)
            : templateImportState.designFileAnalysis.designCreationResponses.map((design) => ({
                  name: design.name,
                  creativeType: 'IMAGE',
                  aspectRatio: calculateAspectRatio(design),
              }));

        const validArtboardsCount = multiDesignMode ? filteredDesigns.length : 1;
        const placeholderDesigns = multiDesignMode ? filteredDesigns : filteredDesigns.slice(0, 1);

        dispatch(
            importDesign(
                {
                    type: RequestTypes.IMPORT,
                    collectionId,
                    customerId,
                    creatorId,
                    multiDesignMode,
                    ...getParticularFileData(file),
                },
                validArtboardsCount,
                placeholderDesigns,
            ),
        );
    };
}

export function createDesignFromFigma(collectionId: string) {
    return (dispatch, getState) => {
        const state = getState();
        const customerId = customerIdSelector(state);
        const creatorId = getIdentityId(state);
        const multiDesignMode = getFigmaMultiDesignMode(state);
        const frameIds = getSelectedFigmaFrameIds(state);
        const figmaFrames = getSelectedFigmaFrames(state);
        const figmaFile = getFigmaFile(state);

        if (figmaFile?.status !== 'success' || !customerId || !creatorId || !frameIds?.length) {
            return;
        }

        const placeholderDesigns = multiDesignMode
            ? figmaFrames.map((frame) => ({
                  name: `${figmaFile.value.name} ${frame.name}`,
                  creativeType: 'IMAGE',
                  aspectRatio: calculateAspectRatio(frame),
              }))
            : [
                  {
                      name: figmaFile.value.name,
                      creativeType: 'IMAGE',
                      aspectRatio: calculateAspectRatio(figmaFrames[0]),
                  },
              ];

        dispatch(
            importDesign(
                {
                    type: RequestTypes.IMPORT,
                    customerId,
                    creatorId,
                    collectionId,
                    multiDesignMode,
                    isLocalFile: true,
                    figmaImportPropertiesDto: {
                        fileKey: figmaFile.value.key,
                        frameIds,
                    },
                },
                multiDesignMode ? frameIds.length : 1,
                placeholderDesigns,
            ),
        );
    };
}

function importDesign(
    createTemplateData: CreateTemplateData,
    validArtboardsCount: number,
    placeholderDesigns: {
        name: string;
        creativeType: string;
        aspectRatio: string;
    }[],
) {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.IMPORT_TEMPLATE_CREATE_DESIGNS_REQUEST });
        const { customerId, collectionId, multiDesignMode } = createTemplateData;

        TemplatesServiceFromDesignFile.createTemplate(createTemplateData)
            .then(({ status, json }) => {
                if (status === 200) {
                    const { id } = json;

                    dispatch({ type: actionTypes.IMPORT_TEMPLATE_CREATE_DESIGNS_SUCCESS });

                    notify({
                        id: json.id,
                        isPersistent: true,
                        variant: 'loading',
                        title: (
                            <Translate id="modal.design.create.design.import.in.progress" count={validArtboardsCount} />
                        ),
                        description:
                            validArtboardsCount > 1 ? (
                                <Translate
                                    id="modal.design.create.design.import.progress.counter"
                                    current="1"
                                    outOf={validArtboardsCount}
                                />
                            ) : null,
                    });

                    sendAppcuesEvent('Design created from design file', {
                        id,
                        name: json.name,
                    });

                    dispatch(setPage(DesignSubPages.ALL));
                    dispatch(designsGhostCards(placeholderDesigns));

                    socketSubscribe(
                        subscribeDesignCreationProgressOptions({
                            customerId,
                            designCreationRequestIds: [id],
                            onMessage: (data, unsubscribeDesignCreation) => {
                                if (data && data.items[0].id === id) {
                                    if (data.items[0]?.errorMessage?.length) {
                                        unsubscribeDesignCreation();
                                        dispatch({ type: actionTypes.IMPORT_TEMPLATE_FAILURE, errors: json });

                                        notify({
                                            id,
                                            isPersistent: false,
                                            variant: 'error',
                                            title: (
                                                <Translate
                                                    id="modal.design.create.design.import.error"
                                                    count={validArtboardsCount}
                                                />
                                            ),
                                            description: data.items[0].errorMessage,
                                        });

                                        return;
                                    }

                                    if (data.items[0]?.designCreationRequestItems?.length) {
                                        const validCompleteDesigns = data.items[0]?.designCreationRequestItems.filter(
                                            (design) =>
                                                design.status === 'COMPLETED' &&
                                                design.progress === 100 &&
                                                design.creativeId !== null,
                                        );

                                        if (validCompleteDesigns.length === validArtboardsCount) {
                                            dispatch({
                                                type: actionTypes.IMPORT_TEMPLATE_DESIGN_FILE_SUCCESS,
                                            });

                                            notify({
                                                id,
                                                isPersistent: false,
                                                variant: 'success',
                                                title: (
                                                    <Translate
                                                        id="modal.design.create.design.import.success"
                                                        count={validArtboardsCount}
                                                    />
                                                ),
                                            });

                                            if (collectionId) {
                                                dispatch(fetchCollection(collectionId));
                                            }

                                            dispatch(fetchCreativesByOptions(getState().creatives.options, true));
                                            unsubscribeDesignCreation();
                                        }
                                    } else {
                                        const designInProgress = () =>
                                            Math.round((data.items[0].progress / 100) * validArtboardsCount);

                                        dispatch({
                                            type: actionTypes.IMPORT_TEMPLATE_DESIGN_FILE_PROGRESS,
                                            progress: data.items[0].progress || 0,
                                        });

                                        notify({
                                            id,
                                            isPersistent: true,
                                            variant: 'loading',
                                            title: (
                                                <Translate
                                                    id="modal.design.create.design.import.in.progress"
                                                    count={validArtboardsCount}
                                                />
                                            ),
                                            description:
                                                validArtboardsCount > 1 ? (
                                                    <Translate
                                                        id="modal.design.create.design.import.progress.counter"
                                                        current={designInProgress() === 0 ? 1 : designInProgress()}
                                                        outOf={validArtboardsCount}
                                                    />
                                                ) : null,
                                        });
                                    }
                                }
                            },
                        }),
                    );
                } else {
                    dispatch({ type: actionTypes.IMPORT_TEMPLATE_FAILURE, errors: json });
                    dispatch({ type: actionTypes.IMPORT_TEMPLATE_CREATE_DESIGNS_FAILURE });
                }
            })
            .then(() => {
                dispatch(dropImportedTemplate());
            })
            .catch(catchResponseError);
    };
}

function calculateAspectRatio(design: { width: number; height: number }) {
    const ratio = design.width / design.height;

    const decodeToFraction = (n: number) => {
        let rcp = n % 1 === 0 ? 1 : 1 / (n % 1);
        let denominator = rcp;
        const limit = 10;

        for (
            let i = 0;
            i < limit && Number.isInteger(Math.round(rcp * 10 ** (limit - i)) / 10 ** (limit - i)) !== true;
            i++
        ) {
            rcp = 1 / (rcp % 1);
            denominator *= rcp;
        }

        return [Math.round(n * denominator), Math.round(denominator)];
    };

    return decodeToFraction(ratio).join(':');
}

export function dropImportedTemplate() {
    return {
        type: actionTypes.DROP_IMPORTED_TEMPLATE,
    };
}

export function setImportDesignMode(isMultiDesign) {
    return {
        type: actionTypes.IMPORT_TEMPLATE_SET_MULTIDESIGN_MODE,
        multiDesignMode: isMultiDesign,
    };
}
