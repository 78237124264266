import useAccessRights from 'packages/hooks/useAccessRights';
import useElement from 'packages/pages/design/hooks/useElement';
import type { TextElement as RenderTextElement, TextElement } from '@bynder-studio/render-core';
import features from '~/configs/features';
import { Direction } from '@bynder-studio/structured-text';
import { useCallback } from 'react';

export function useTextElementDirection(elementId: number) {
    const { isPlatformAllowed } = useAccessRights();
    const { element, updateElement } = useElement(elementId);
    const { rtl, detectedDirection } = (element as TextElement).detectedTextDirections;
    const direction = (element as TextElement).textDirection || detectedDirection;
    const showDirectionMode =
        isPlatformAllowed([features.BIDIRECTIONAL_TEXT]) &&
        (!!(element as RenderTextElement).textDirection || detectedDirection !== Direction.LTR || rtl);

    const handleDirectionChange = useCallback(() => {
        updateElement({
            textDirection: direction === Direction.RTL ? Direction.LTR : Direction.RTL,
        });
    }, [updateElement, direction]);

    return {
        direction,
        showDirectionMode,
        handleDirectionChange,
    };
}
