import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown } from '@bynder/design-system';
import { IconMin } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { ActionStatus, Entity, TemplateAction } from 'packages/hooks/useAccessRights/types';
import useAccessRights from 'packages/hooks/useAccessRights';
import { removeFromCategory } from 'packages/store/categories/categories.actions';
import type { IsAvailable, Props } from './types';

export const isRemoveFromCategoryAvailable: IsAvailable = ({ isEntityActionAllowed, hiddenActions, template }) =>
    isEntityActionAllowed(TemplateAction.MOVE, Entity.TEMPLATE, template.grantedPermissions) !==
        ActionStatus.NOT_ALLOWED && !hiddenActions.includes('removeFromCategory');

export const RemoveFromCategory = ({ template, menuActions }: Props) => {
    const { isEntityActionAllowed } = useAccessRights();
    const { translate } = useTranslate();
    const dispatch = useDispatch();

    const onClick = useCallback(() => dispatch(removeFromCategory([template.id])), [dispatch, template.id]);

    const isDisabled =
        isEntityActionAllowed(TemplateAction.MOVE, Entity.TEMPLATE, template.grantedPermissions) ===
        ActionStatus.DISABLED;

    return (
        <Dropdown.Item isDisabled={isDisabled} icon={<IconMin />} onClick={onClick}>
            {translate('pages.categories.category.template.menu.removeFromCategory')}
        </Dropdown.Item>
    );
};
