import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import useEditor from '../../hooks/useEditor';

function EditorCanvas(props, ref) {
    const { loading, initManipulationRenderer, manipulationRenderer, creativeType } = useEditor();
    const canvasContainerRef = useRef();

    const setDimensions = useMemo(() => {
        const callback = (newHeight) => {
            if (!canvasContainerRef.current) {
                return;
            }

            canvasContainerRef.current.style.height = `${newHeight}px`;

            if (!manipulationRenderer) {
                return;
            }

            manipulationRenderer.calculateScaleRatio();
        };

        let idleCallbackId = 0;
        let latestHeight;

        return (h) => {
            latestHeight = h;

            if (!idleCallbackId) {
                idleCallbackId = window.requestIdleCallback(
                    () => {
                        idleCallbackId = 0;
                        callback(latestHeight);
                    },
                    { timeout: 10 },
                );
            }
        };
    }, [manipulationRenderer]);

    useImperativeHandle(ref, () => ({
        setDimensions,
    }));

    useEffect(() => {
        if (!manipulationRenderer) {
            return;
        }

        const scale = manipulationRenderer.calculateScaleRatio();
        manipulationRenderer.setScale(scale);
        manipulationRenderer.redraw();
    }, [manipulationRenderer]);

    useEffect(() => {
        if (loading) {
            return;
        }

        initManipulationRenderer(canvasContainerRef.current, 0);
    }, [loading]);

    useEffect(() => {
        if (!manipulationRenderer || creativeType === 'IMAGE') {
            return;
        }

        return () => manipulationRenderer.pausePlayback();
    }, [manipulationRenderer]);

    return (
        <div
            className="canvas-container d-flex align-items-center justify-content-center"
            id={props.id}
            ref={canvasContainerRef}
        />
    );
}

export default forwardRef(EditorCanvas);
