import { Region } from '../Shared/Region';
import { EffectTypes } from '../../Enums/EffectTypes';
import { ICompElement } from '../CompModels/ICompElement';
import { BaseEffect } from './BaseEffect';

export class DropShadowEffect extends BaseEffect {
    static type = EffectTypes.DROPSHADOW;

    static getRegionOfDefinition(region: Region, compElement: ICompElement) {
        return new Region(
            Number.NEGATIVE_INFINITY,
            Number.NEGATIVE_INFINITY,
            Number.POSITIVE_INFINITY,
            Number.POSITIVE_INFINITY,
        );
    }

    static getRegionOfInterest(region: Region, compElement: ICompElement) {
        if (compElement.dropShadow && compElement.dropShadow.state === 'ENABLE') {
            const ds = compElement.dropShadow;
            const blurRadius = ds.blurRadius * 2;
            region = new Region(
                Math.min(region.left, region.left + ds.offsetX - blurRadius),
                Math.min(region.top, region.top + ds.offsetY - blurRadius),
                Math.max(region.right, region.right + ds.offsetX + blurRadius),
                Math.max(region.bottom, region.bottom + ds.offsetY + blurRadius),
            );
            return region;
        }

        return region;
    }
}
