import { type ThumbnailWSData } from 'packages/socket';
import { type Creative } from './types';
import { type Template } from '../templates/types';
import { type Collection } from '../collections/types';
import { Category } from '../categories/types';

// The meaning of those types are the same, but the structure is slightly different
// it adds complexity to the codebase
export type PreviewItem = (Collection | Category)['previewItems'][number];
export type BaseCreative = Pick<Creative | Template, 'id' | 'thumbnailImage' | 'thumbnailStatus'>;

type EntityWithTemplate = BaseCreative | PreviewItem;

function previewItemGuard(entity: EntityWithTemplate): entity is PreviewItem {
    return 'creativeId' in entity;
}

/*
 * Updates an array of creatives or previewItems only if this is necessary
 * return an object with the updated array and a flag indicating if there was updates
 * If there was not updates, returned array is an original array
 */
export function updateThumbnails<T extends EntityWithTemplate>(creatives: T[], thumbnailUpdates: ThumbnailWSData[]) {
    const newCreatives = new Array<T>(creatives.length);
    let isUpdated = false;

    creatives.forEach((creative, idx) => {
        const isPreviewItem = previewItemGuard(creative);
        const id = isPreviewItem ? creative.creativeId : creative.id;
        const incomingPreviewItem = thumbnailUpdates.find((item) => item.creativeId === id);

        if (!incomingPreviewItem) {
            newCreatives[idx] = creative;

            return;
        }

        const { thumbnailUrl, thumbnailStatus } = incomingPreviewItem;
        const currentThumbnailUrl = isPreviewItem ? creative.previewUrl : creative.thumbnailImage;

        if (creative.thumbnailStatus === thumbnailStatus && (!thumbnailUrl || currentThumbnailUrl === thumbnailUrl)) {
            newCreatives[idx] = creative;

            return;
        }

        if (isPreviewItem) {
            (newCreatives as PreviewItem[])[idx] = {
                ...creative,
                thumbnailStatus,
                previewUrl: thumbnailUrl ?? creative.previewUrl,
            };
        } else {
            (newCreatives as BaseCreative[])[idx] = {
                ...creative,
                thumbnailStatus,
                thumbnailImage: thumbnailUrl ?? creative.thumbnailImage,
            };
        }

        isUpdated = true;
    });

    if (!isUpdated) {
        return {
            creatives,
            isUpdated,
        };
    }

    return {
        creatives: newCreatives,
        isUpdated,
    };
}
