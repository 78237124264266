import CanvasManipulation from '../../Compositor/CanvasManipulationCompositor/CanvasManipulation';
import type { IAssetsLoader, ICompositor, IMultiPageImageModel } from '@bynder-studio/render-core';
import { BaseManipulationRenderer } from '../BaseManipulationRenderer/BaseManipulationRenderer';

export class ImageManipulationRenderer extends BaseManipulationRenderer {
    constructor(
        imageModel: IMultiPageImageModel,
        assetLoader: IAssetsLoader,
        compositor: ICompositor,
        canvasManipulation: CanvasManipulation,
    ) {
        // @ts-ignore
        super(imageModel, assetLoader, compositor, canvasManipulation);
    }

    private handleCompModelUpdate = () => this.drawCompModel();

    async init() {
        await super.init();
        await this.drawCompModel();
        this.getCreativeModel().eventEmitter.on('requireCreativeUpdate', this.handleCompModelUpdate);
    }

    async drawCompModel(): Promise<boolean> {
        // @ts-ignore
        await this.redrawCompModel(this.creativeModel.getCompModel());
        return true;
    }

    async redraw() {
        // @ts-ignore
        const compModel = this.creativeModel.getCompModel();
        await this.redrawCompModel(compModel);
    }
}
