import { type ThumbnailWSData } from 'packages/socket/subscribeOptions/assetsStatus';
import { type UploadType } from './types';
import { type UploadsCollection } from '../collections/types';

// The meaning of those types are the same, but the structure is slightly different
// it adds complexity to the codebase
export type PreviewItem = UploadsCollection['previewItems'][number];
export type BaseUpload = Pick<UploadType, 'id' | 'thumbnails' | 'thumbnailStatus'>;

type UploadEntity = BaseUpload | PreviewItem;

function previewItemGuard(entity: UploadEntity): entity is PreviewItem {
    return 'assetId' in entity;
}

/*
 * Updates an array of uploads or previewItems only if this is necessary
 * return an object with the updated array and a flag indicating if there was updates
 * If there was not updates, returned array is an original array
 */
export function updateThumbnails<T extends UploadEntity>(uploads: T[], thumbnailUpdates: ThumbnailWSData[]) {
    const newUploads = new Array<T>(uploads.length);
    let isUpdated = false;

    uploads.forEach((upload, idx) => {
        const isPreviewItem = previewItemGuard(upload);
        const id = isPreviewItem ? upload.assetId : upload.id;
        const incomingPreviewItem = thumbnailUpdates.find((item) => item.id === id);

        if (!incomingPreviewItem) {
            newUploads[idx] = upload;

            return;
        }

        const { thumbnails, status } = incomingPreviewItem;
        const currentThumbnailUrl = isPreviewItem ? upload.previewUrl : upload.thumbnails['320x320'];

        if (
            !thumbnails?.['320x320'] ||
            (thumbnails?.['320x320'] === currentThumbnailUrl &&
                (status === upload.thumbnailStatus || status === upload.status))
        ) {
            newUploads[idx] = upload;

            return;
        }

        if (isPreviewItem) {
            (newUploads as PreviewItem[])[idx] = {
                ...upload,
                status: status,
                previewUrl: thumbnails?.['320x320'] ?? upload.previewUrl,
            };
        } else {
            (newUploads as BaseUpload[])[idx] = {
                ...upload,
                thumbnailStatus: status,
                thumbnails: thumbnails ?? upload.thumbnails,
            };
        }

        isUpdated = true;
    });

    if (!isUpdated) {
        return {
            uploads,
            isUpdated,
        };
    }

    return {
        uploads: newUploads,
        isUpdated,
    };
}
