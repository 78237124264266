import React from 'react';
import useExport from '../hooks/useExport';
import { LoaderContainer, LoaderContainerWithContent, LoaderWithContent } from './List.styled';

export const Container = ({ children }) => {
    const { loading, hadLoading } = useExport();

    if (hadLoading) {
        return (
            <LoaderContainer>
                <LoaderWithContent size={50} />
            </LoaderContainer>
        );
    }

    if (loading) {
        return (
            <LoaderContainerWithContent>
                {children}
                <LoaderWithContent size={50} />
            </LoaderContainerWithContent>
        );
    }

    return children;
};
