import { CreativeTypes } from '@bynder-studio/render-core';
import { envVar } from '~/helpers/envVar';

export const toInteger = (string, fallback = 0) => {
    try {
        const value = parseInt(string, 10);

        return !isNaN(value) ? value : fallback;
    } catch (err) {
        return fallback;
    }
};
export const toFloat = (string, fallback = 0) => {
    try {
        const value = parseFloat(string);

        return !isNaN(value) ? value : fallback;
    } catch (err) {
        return fallback;
    }
};

const rules = {
    global_dimensions: (dimensions, creativeType: CreativeTypes) => {
        const maxResolution = envVar.designMaxDimensions(creativeType);

        ['width', 'height'].forEach((key) => {
            dimensions[key] = toInteger(dimensions[key]);

            if (dimensions[key] > maxResolution) {
                dimensions[key] = maxResolution;
            }

            if (dimensions[key] < 10) {
                dimensions[key] = 10;
            }
        });

        return { ...dimensions };
    },
    global_duration: (duration, frameRate) => {
        let correctDuration = duration;
        // @ts-ignore
        // eslint-disable-next-line no-undef
        const maxDuration = CREATIVE_MAX_DURATION_SECONDS || 3 * 60;

        if (duration > maxDuration * frameRate) {
            correctDuration = maxDuration * frameRate;
        }

        if (duration < 2) {
            correctDuration = 2;
        }

        return correctDuration;
    },
    global_poster_frame: (posterFrame, duration) => {
        let correctPosterFrame = posterFrame;

        if (posterFrame > duration - 1) {
            correctPosterFrame = duration - 1;
        }

        if (posterFrame < 0) {
            correctPosterFrame = 0;
        }

        return correctPosterFrame;
    },
    global_audio_fade: (value, max: number) => {
        let correctValue = toFloat(value);

        if (correctValue < 0) {
            correctValue = 0;
        }

        if (correctValue > max) {
            correctValue = max;
        }

        return correctValue;
    },
    text_element_text_aria: (text, creativeType: CreativeTypes) => {
        const maxCharacters = envVar.designMaxCharacters(creativeType);

        let correctText = text;

        if (text.length > maxCharacters) {
            correctText = text.substring(0, maxCharacters);
        }

        return correctText;
    },
    element_start_frame: (startFrame: number, frameRate: number) => {
        let correctStartFrame = startFrame;
        const maxDuration = CREATIVE_MAX_DURATION_SECONDS || 3 * 60;

        if (startFrame > maxDuration * frameRate) {
            correctStartFrame = maxDuration * frameRate;
        }

        return correctStartFrame;
    },
    element_end_frame: (endFrame: number, startFrame: number) => {
        let correctEndFrame = endFrame;

        if (startFrame >= endFrame) {
            correctEndFrame = startFrame + 1;
        }

        return correctEndFrame;
    },
    element_border_radius: (radius) => {
        if (radius < 0) {
            return 0;
        }

        if (radius > 999) {
            return 999;
        }

        return radius;
    },
    element_border_weight: (weight) => {
        if (weight < 0) {
            return 0;
        }

        if (weight > 271) {
            return 271;
        }

        return weight;
    },
    element_width: (width, creativeType: CreativeTypes, dimension, isUniScale) => {
        const maxResolution = envVar.designMaxDimensions(creativeType);

        let correctWidth = toInteger(width);

        if (correctWidth >= maxResolution) {
            correctWidth = maxResolution;
        }

        if (correctWidth < 1) {
            correctWidth = 1;
        }

        if (isUniScale) {
            const scale = Number(correctWidth) / dimension.getWidth();
            const newHeight = dimension.getHeight() * scale;

            if (newHeight >= maxResolution) {
                correctWidth /= newHeight / maxResolution;
            }

            if (newHeight < 1) {
                correctWidth *= 1 / newHeight;
            }
        }

        return correctWidth;
    },
    element_height: (height, creativeType: CreativeTypes, dimension, isUniScale) => {
        const maxResolution = envVar.designMaxDimensions(creativeType);

        let correctHeight = toInteger(height);

        if (correctHeight >= maxResolution) {
            correctHeight = maxResolution;
        }

        if (correctHeight < 1) {
            correctHeight = 1;
        }

        if (isUniScale) {
            const scale = Number(correctHeight) / dimension.getHeight();
            const newWidth = dimension.getWidth() * scale;

            if (newWidth >= maxResolution) {
                correctHeight /= newWidth / maxResolution;
            }

            if (newWidth < 1) {
                correctHeight *= 1 / newWidth;
            }
        }

        return correctHeight;
    },
    element_horizontal_position: (x) => {
        let correctX = toInteger(x);

        if (correctX < -9999) {
            correctX = -9999;
        } else if (correctX > 9999) {
            correctX = 9999;
        }

        return correctX;
    },
    element_vertical_position: (y) => {
        let correctY = toInteger(y);

        if (correctY < -9999) {
            correctY = -9999;
        } else if (correctY > 9999) {
            correctY = 9999;
        }

        return correctY;
    },
    element_rotation: (rotation) => {
        let correctRotation = toInteger(rotation);

        if (correctRotation >= 360) {
            correctRotation = 359;
        }

        if (correctRotation <= -360) {
            correctRotation = -359;
        }

        return correctRotation;
    },
    element_opacity: (opacity) => {
        let correctOpacity = toInteger(opacity);

        if (correctOpacity > 100) {
            correctOpacity = 100;
        }

        if (correctOpacity < 0) {
            correctOpacity = 0;
        }

        return correctOpacity;
    },
    new_element_opacity: (opacity) => {
        let correctOpacity = toInteger(opacity, 100);

        if (correctOpacity > 100) {
            correctOpacity = 100;
        }

        if (correctOpacity < 0) {
            correctOpacity = 0;
        }

        return `${correctOpacity}%`;
    },
    font_scale: (scale) => {
        let correctScale = toInteger(scale, 100);

        if (correctScale > 1000) {
            correctScale = 1000;
        }

        if (correctScale < 1) {
            correctScale = 1;
        }

        return correctScale;
    },
    min_font_scale: (scale) => {
        let correctScale = toInteger(scale, 100);

        if (correctScale > 100) {
            correctScale = 100;
        }

        if (correctScale < 1) {
            correctScale = 1;
        }

        return correctScale;
    },
    text_element_font_size: (size) => {
        let correctSize = toInteger(size);

        if (correctSize > 2048) {
            correctSize = 2048;
        }

        if (correctSize < 1) {
            correctSize = 1;
        }

        return correctSize;
    },
    text_element_stroke_width: (width) => {
        let correctWidth = toInteger(width);

        if (correctWidth < 1) {
            correctWidth = 1;
        }

        return toInteger(correctWidth);
    },
    text_line_height: (value) => {
        let correctValue = toInteger(value, 100);

        if (correctValue > 999) {
            correctValue = 999;
        }

        if (correctValue < 0) {
            correctValue = 0;
        }

        return correctValue;
    },
    text_letters_space: (value) => {
        let correctValue = toInteger(value);

        if (correctValue > 200) {
            correctValue = 200;
        }

        if (correctValue < -100) {
            correctValue = -100;
        }

        return correctValue;
    },
    text_paragraph_spacing: (value) => {
        let correctValue = toInteger(value);

        if (correctValue < 0) {
            correctValue = 0;
        }

        if (correctValue > 4096) {
            correctValue = 4096;
        }

        return correctValue;
    },
    text_bg_spacing: (value: string | number) => {
        let correctValue = toInteger(value);

        if (correctValue < -9999) {
            correctValue = -9999;
        }

        if (correctValue > 5000) {
            correctValue = 5000;
        }

        return correctValue;
    },
    color_hex: (hex, previous = '#FFFFFF') => {
        let correctHex = hex.replace('#', '').toUpperCase();

        if (correctHex.length > 6) {
            correctHex = `#${correctHex.slice(0, 6)}`;
        }

        if (correctHex.length === 6) {
            correctHex = `#${correctHex}`;
        }

        if (correctHex.length === 3) {
            correctHex = `#${correctHex
                .split('')
                .map((letter) => `${letter}${letter}`)
                .join('')}`;
        }

        if (correctHex.length === 5 || correctHex.length === 4 || correctHex.length < 3) {
            correctHex = previous;
        }

        return CSS.supports('color', correctHex) ? correctHex : previous;
    },
    shadow_blur_radius: (value) => {
        let correctBlurRadius = toInteger(value);

        if (correctBlurRadius < 0) {
            correctBlurRadius = 0;
        }

        if (correctBlurRadius > 999) {
            correctBlurRadius = 999;
        }

        return correctBlurRadius;
    },
    shadow_blur_offset: (value) => {
        let correctedOffset = toInteger(value);

        if (correctedOffset < -9999) {
            correctedOffset = -9999;
        }

        if (correctedOffset > 9999) {
            correctedOffset = 9999;
        }

        return correctedOffset;
    },
    animation_duration: (
        duration,
        { frameRate, elementDuration, startFrame = 0, animationStartFrame, inverseAnimationValue = 0 },
    ) => {
        const maxDuration = elementDuration || CREATIVE_MAX_DURATION_SECONDS || 3 * 60;
        let correctDuration = duration;

        if (duration > maxDuration) {
            correctDuration = maxDuration;
        }

        const endFrame = startFrame + elementDuration;
        const correctAnimationStart = animationStartFrame === 0 ? startFrame : animationStartFrame;
        const correctAnimationEnd = correctAnimationStart + correctDuration;

        if (correctAnimationEnd > endFrame) {
            correctDuration -= correctAnimationEnd - endFrame;
        }

        if (duration < 0) {
            correctDuration = 0;
        }

        const allowedRemainingDuration = startFrame + maxDuration - inverseAnimationValue;

        if (duration > allowedRemainingDuration) {
            correctDuration = allowedRemainingDuration;
        }

        return correctDuration;
    },
    animation_start_frame: (value, { frameRate, elementDuration, startFrame, animationStartFrame }) => {
        let correctValue = value;

        if (value >= startFrame + elementDuration) {
            correctValue = startFrame + elementDuration - 1;
        }

        if (startFrame && value < startFrame) {
            correctValue = startFrame;
        }

        return correctValue;
    },
    animation_integer: (value) => toInteger(value),
    animation_rotation: (rotation) => {
        let correctRotation = rotation;

        if (correctRotation >= 360) {
            correctRotation = 359;
        }

        if (correctRotation <= -360) {
            correctRotation = -359;
        }

        return correctRotation ? parseInt(correctRotation, 10) : 0;
    },
    animation_positive_integer: (value) => {
        let correctValue = toInteger(value);

        if (correctValue < 0) {
            correctValue = 0;
        }

        return correctValue;
    },
    animation_positive_percentage: (value) => {
        let correctValue = Number(value);

        if (correctValue < 0) {
            correctValue = 0;
        }

        if (correctValue > 2) {
            correctValue = 2;
        }

        return correctValue;
    },
    animation_positive_limited_percentage: (value) => {
        let correctValue = Number(value);

        if (correctValue > 1) {
            correctValue = 1;
        }

        if (correctValue < 0) {
            correctValue = 0;
        }

        return correctValue;
    },
};

const editorAutocorrect = (key: string, ...props) => (rules[key] ? rules[key](...props) : null);

export default editorAutocorrect;
