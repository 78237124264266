import * as React from 'react';
import type { BaseMultiPageModel, TextElement } from '@bynder-studio/render-core';
import { Input, Form } from '@bynder/design-system';
import { IconParagraphSpacing } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import generateTestId from '~/helpers/testIdHelpers';
import useFontSettingInput from '../Fonts/useFontSettingInput';

type Props = {
    creativeModel: BaseMultiPageModel;
    selectedElement: TextElement;
};

const testId = generateTestId('shots_text_paragraph_spacing');

const ParagraphSpacing = ({ creativeModel, selectedElement }: Props) => {
    const { translate } = useTranslate();
    const label = translate('editor.sidebar.shots.paragraph_spacing.label');
    const attrs = useFontSettingInput({
        creativeModel,
        propName: 'paragraphSpacing',
        autoCorrectKey: 'text_paragraph_spacing',
        selectedElement,
        disabled: false,
    });

    return (
        <Form.Group>
            <Input name={label} aria-label={label} suffix="px" icon={<IconParagraphSpacing />} {...testId} {...attrs} />
        </Form.Group>
    );
};

export default ParagraphSpacing;
