import { Badge, Box, textStyles, token } from '@bynder/design-system';
import styled from 'styled-components';

export const StyledHeading = styled.h3`
    ${textStyles.headingS}
`;

export const StyledBadgeText = styled(Badge)`
    background-color: transparent;
    border: none;
    color: ${token.colorText};
`;
