import React, { Suspense, useEffect, useState } from 'react';
import { CreativeTypes, ElementUpdateTypes } from '@bynder-studio/render-core';
import { Divider, Form } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import useAccessRights from 'packages/hooks/useAccessRights';
import useEditor from '~/hooks/useEditor';
import useEditorSelectedElement from '~/hooks/useEditorSelectedElement';
import useForceUpdate from '~/hooks/useForceUpdate';
import features from '~/configs/features';
import AuthorizationHelper from '~/helpers/AuthorizationHelper';
import { InspectorHeader, InspectorContent, Section, FormSectionContent } from '../../index.styled';
import SettingsTabs from '../Tabs';
import LockToggler from '../Size/LockToggle';
import { SizeFormRow } from './index.styled';
import ElementAlignment from '../ElementAlignment';
import VerticalPosition from '../Position/Vertical';
import HorizontalPosition from '../Position/Horizontal';
import Rotation from '../Rotation';
import Opacity from '../Opacity';
import Height from '../Size/Height';
import Width from '../Size/Width';
import Duration from '../Duration';
import Shadow from '../Shadow';
import CropMode from '../CropMode';
import Masking from '../Masking';
import { BlendMode } from '../BlendMode';
import BrowseImage from './BrowseImage';
import Animations from '../../Animations';
import AssetSource from '../AssetSource';
import PersonalUploads from '../PersonalUploads';
import AllowToggleVisibility from '../AllowToggleVisibility';
import ContentProperty from '../ContentProperty';

const Image = () => {
    const { translate } = useTranslate();
    const { isPlatformAllowed } = useAccessRights();
    const [isCropModeOpen, setIsCropModeOpen] = useState(false);
    const { creativeModel, creativeType, manipulationRenderer, frameRate } = useEditor();
    const { selectedElement, selectedElements, selectedElementDeferred } = useEditorSelectedElement();
    const forceUpdate = useForceUpdate();

    const isInternalAssetsAvailable = AuthorizationHelper.isFeatureAvailable(features.INTERNAL_ASSET);

    useEffect(() => {
        if (!creativeModel) {
            return;
        }

        const unsubscribeLock = creativeModel.onElementsPartialUpdate(ElementUpdateTypes.LOCK, forceUpdate);

        return () => {
            unsubscribeLock();
        };
    }, [creativeModel, forceUpdate]);

    useEffect(() => {
        if (selectedElement.locked) setIsCropModeOpen(false);
    }, [selectedElement.locked]);

    const tabs = [
        { value: 'image', label: translate('editor.sidebar.shots.image.tabs.text') },
        { value: 'image_animation', label: translate('editor.sidebar.shots.image.tabs.animation') },
    ];
    const [activeTab, setTab] = useState(tabs[0].value);

    return (
        <>
            {creativeType === 'VIDEO' && (
                <InspectorHeader aria-labelledby="shots-text-tabs-settings">
                    <SettingsTabs tabs={tabs} activeTab={activeTab} onChange={(value) => setTab(value)} />
                </InspectorHeader>
            )}
            <InspectorContent withTabs={creativeType === 'VIDEO'}>
                {activeTab === tabs[0].value && (
                    <>
                        <Section aria-labelledby="shots-alignment-settings">
                            <ElementAlignment creativeModel={creativeModel} selectedElements={selectedElements} />
                        </Section>
                        <Divider />
                        <Section aria-labelledby="shots-size-settings">
                            <FormSectionContent onSubmit={preventSubmit}>
                                <SizeFormRow>
                                    <Width
                                        creativeModel={creativeModel}
                                        selectedElement={selectedElement}
                                        creativeType={creativeType}
                                        isLinked={false}
                                    />
                                    <LockToggler selectedElement={selectedElement} forceUpdate={forceUpdate} />
                                    <Height
                                        creativeModel={creativeModel}
                                        selectedElement={selectedElement}
                                        creativeType={creativeType}
                                        isLinked={false}
                                    />
                                </SizeFormRow>
                                <Form.Row>
                                    <HorizontalPosition
                                        creativeModel={creativeModel}
                                        selectedElement={selectedElement}
                                        isLinked={false}
                                    />
                                    <VerticalPosition
                                        creativeModel={creativeModel}
                                        selectedElement={selectedElement}
                                        isLinked={false}
                                    />
                                </Form.Row>
                                <Form.Row>
                                    <Rotation
                                        creativeModel={creativeModel}
                                        selectedElement={selectedElement}
                                        disabled={false}
                                    />
                                    <Opacity
                                        creativeModel={creativeModel}
                                        selectedElement={selectedElement}
                                        disabled={false}
                                    />
                                </Form.Row>
                            </FormSectionContent>
                        </Section>
                        <Divider />
                        {isPlatformAllowed([features.CONTENT_PROPERTIES]) && (
                            <>
                                <Section aria-labelledby="shots-text-content-property">
                                    <Suspense>
                                        <ContentProperty
                                            creativeModel={creativeModel}
                                            selectedElement={selectedElementDeferred}
                                        />
                                    </Suspense>
                                </Section>
                                <Divider />
                            </>
                        )}
                        <Section aria-labelledby="shots-image-settings">
                            <FormSectionContent onSubmit={preventSubmit}>
                                <BrowseImage
                                    creativeModel={creativeModel}
                                    selectedElement={selectedElement}
                                    disabled={false}
                                    id={selectedElement.id}
                                />
                                <CropMode
                                    opened={isCropModeOpen}
                                    onToggle={() => !selectedElement.locked && setIsCropModeOpen(!isCropModeOpen)}
                                    disabled={false}
                                />
                                {creativeType === CreativeTypes.VIDEO && (
                                    <>
                                        <Divider />
                                        <Duration
                                            disabled={false}
                                            selectedElement={selectedElement}
                                            creativeModel={creativeModel}
                                            frameRate={frameRate}
                                        />
                                    </>
                                )}
                            </FormSectionContent>
                        </Section>
                        <Divider />
                        <Section aria-labelledby="shots-image-asset-source">
                            <Form onSubmit={preventSubmit}>
                                <Form.Group>
                                    <AssetSource />
                                </Form.Group>
                                {isInternalAssetsAvailable && (
                                    <>
                                        <Divider />
                                        <Form.Group>
                                            <PersonalUploads />
                                        </Form.Group>
                                    </>
                                )}
                            </Form>
                        </Section>
                        <Divider />
                        <Section aria-labelledby="shots-image-visibility">
                            <Form onSubmit={preventSubmit}>
                                <Form.Group>
                                    <Form.Label>{translate('editor.element.visibility_control')}</Form.Label>
                                    <AllowToggleVisibility
                                        selectedElement={selectedElement}
                                        creativeModel={creativeModel}
                                    />
                                </Form.Group>
                            </Form>
                        </Section>
                        <Divider />
                        <Section aria-labelledby="shots-image-masking-settings">
                            <FormSectionContent onSubmit={preventSubmit}>
                                <Form.Group>
                                    <Form.Label>{translate('editor.element.mask')}</Form.Label>
                                    <Masking
                                        element={selectedElement}
                                        creativeModel={creativeModel}
                                        manipulationRenderer={manipulationRenderer}
                                        creativeType={creativeType}
                                        disabled={false}
                                    />
                                </Form.Group>
                            </FormSectionContent>
                        </Section>
                        <Divider />
                        <Section aria-labelledby="shots-image-shadow">
                            <FormSectionContent onSubmit={preventSubmit}>
                                <Form.Group>
                                    <Form.Label>{translate('editor.sidebar.shots.image.shadow.label')}</Form.Label>
                                    <Shadow
                                        disabled={false}
                                        selectedElement={selectedElement}
                                        creativeModel={creativeModel}
                                    />
                                </Form.Group>
                                <Divider />
                                <Form.Group>
                                    <BlendMode disabled={false} />
                                </Form.Group>
                            </FormSectionContent>
                        </Section>
                    </>
                )}
                {activeTab === tabs[1].value && <Animations element={selectedElement} disabled={false} />}
            </InspectorContent>
        </>
    );
};

export default Image;

function preventSubmit(e: React.FormEvent) {
    e.preventDefault();
}
