import { notify } from '@bynder/design-system';
import { catchResponseError } from 'packages/helpers/helpers';
import { sendAppcuesEvent } from '~/helpers/RouteWithAppcues';
import * as types from '../types';
import presetsService from 'packages/services/PresetsService';
import { customerIdSelector } from '../user/user.selectors';
import { presetsSelector } from './presets.selectors';
import { removeItem, setPresetData } from './presets.bloc';

export function getPresets(opt, emptyBeforeFetch = true) {
    return (dispatch, getState) => {
        const state = getState();
        const oldOptions = state.presets.options;
        const { creativeType } = state.creatives.creative;

        const options = {
            ...oldOptions,
            page: emptyBeforeFetch ? 0 : oldOptions.page,
            ...opt,
            creativeType,
        };
        dispatch({
            type: types.PRESETS_START_FETCH,
            emptyBeforeFetch,
            options,
        });
        const customerId = customerIdSelector(state);
        presetsService
            .getPresets({ ...options, customerId })
            .then(({ status, json: { items, totalItems } }) => {
                if (status !== 200) {
                    dispatch({
                        type: types.PRESETS_FAIL_FETCH,
                    });
                } else {
                    dispatch({
                        type: types.PRESETS_SUCCESS_FETCH,
                        items,
                        totalItems,
                    });
                }
            })
            .catch(catchResponseError);
    };
}

export function getNextPageData() {
    return (dispatch, getState) => {
        const { page } = getState().presets.options;
        dispatch(getPresets({ page: page + 1 }, false));
    };
}

export function selectPreset(id) {
    return (dispatch, getState) => {
        const state = getState();
        presetsService
            .getPresetById(id)
            .then(({ status, json: preset }) => {
                if (status === 200) {
                    dispatch({
                        type: types.PRESETS_SET_ITEMS,
                        items: setPresetData(presetsSelector(state), id, preset),
                        selectedItemId: id,
                    });
                    sendAppcuesEvent('Preset selected', { id, name: preset.name });
                }
            })
            .catch(catchResponseError);
    };
}

export function removePreset(id) {
    return (dispatch, getState) => {
        const state = getState();
        presetsService
            .deletePreset(id)
            .then(() => {
                dispatch({
                    type: types.PRESETS_SET_ITEMS,
                    items: removeItem(presetsSelector(state), id),
                    selectedItemId: null,
                    totalItems: state.presets.totalItems - 1,
                });
                sendAppcuesEvent('Preset removed', { id });
            })
            .catch(catchResponseError);
    };
}

export function renamePreset(id, newName) {
    return (dispatch) => {
        dispatch({
            type: types.PRESETS_RENAME_REQUEST,
        });
        presetsService
            .updatePreset(id, { name: newName })
            .then(({ json: { name, status } }) => {
                if (status === 'NAME_ALREADY_IN_USE') {
                    dispatch({
                        type: types.PRESETS_RENAME_FAILURE,
                    });

                    notify({
                        title: 'This name already exists in the list. Please choose a different name',
                        variant: 'error',
                    });
                    sendAppcuesEvent('Preset renamed', { id, name });
                } else {
                    dispatch({
                        type: types.PRESETS_RENAME_SUCCESS,
                        id,
                        name,
                    });
                }
            })
            .catch(catchResponseError);
    };
}
