import React, { useEffect, useState } from 'react';
import { useTranslate } from '@bynder/localization';
import { ImageElement, ShapeElement, TextElement, VideoElement } from '@bynder-studio/render-core';
import { Input } from '@bynder/design-system';
import { useInputBlur } from 'packages/hooks/useInputBlur';
import { getDropShadowValueFromElement } from '~/common/editor/helpers';
import editorAutocorrect from 'packages/pages/editor/EditorAutocorrectRules';
import generateTestId from '~/helpers/testIdHelpers';

type ShadowOffsetProp = {
    disabled: boolean;
    onChange: (prop: string, value: any) => void;
    selectedElement: TextElement | ImageElement | VideoElement | ShapeElement;
};

const ShadowOffset = ({ disabled, onChange, selectedElement }: ShadowOffsetProp) => {
    const { onFocus, unsubscribeListener } = useInputBlur();
    const [selectedShadowOffset, setSelectedShadowOffset] = useState(
        getDropShadowValueFromElement(selectedElement).offset,
    );

    useEffect(() => {
        setSelectedShadowOffset(getDropShadowValueFromElement(selectedElement).offset);
    }, [selectedElement]);

    const { translate } = useTranslate();

    const onOffsetChange = (newValue) => {
        setSelectedShadowOffset(newValue);
    };

    const applyChanges = (val: number) => {
        const correctValue = editorAutocorrect('shadow_blur_offset', val);

        setSelectedShadowOffset(correctValue.toString());
        onChange('offset', correctValue);
    };

    const onBlur = () => {
        unsubscribeListener();
        applyChanges(Number(selectedShadowOffset));
    };

    const onKeyDown = (event) => {
        switch (event.key) {
            case 'Enter':
                applyChanges(Number(selectedShadowOffset));
                break;
            case 'ArrowUp':
            case 'ArrowDown': {
                event.preventDefault();
                const numberToAdd = event.key === 'ArrowDown' ? -1 : 1;
                const factor = event.shiftKey ? 10 : 1;
                applyChanges(Number(selectedShadowOffset) + numberToAdd * factor);
                break;
            }
        }
    };

    const testId = generateTestId('shots_styling_shadow__offset');

    return (
        <Input
            name={translate('editor.sidebar.shots.shadow.offset.label')}
            aria-label={translate('editor.sidebar.shots.shadow.offset.label')}
            type="number"
            value={selectedShadowOffset}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={onOffsetChange}
            onKeyDown={onKeyDown}
            isDisabled={disabled}
            suffix="px"
            {...testId}
        />
    );
};

export default ShadowOffset;
