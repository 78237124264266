import React, { useEffect, useMemo } from 'react';
import { IconDelete } from '@bynder/icons';
import generateTestId from '~/helpers/testIdHelpers';
import useEditor from '../../../../../hooks/useEditor';
import useForceUpdate from '../../../../../hooks/useForceUpdate';
import { TimelineActionsIcon } from '../TimelineActions.styled';
import useGeneralHotkeys from 'packages/pages/editor/useGeneralHotKeys';
import useElementGeneralActions from 'packages/pages/editor/useElementGeneralActions';

export default function DeleteAction() {
    const { manipulationRenderer } = useEditor();
    const selectedElements = manipulationRenderer?.getSelectedElements() || [];
    const forceUpdate = useForceUpdate();
    const isActive = !!selectedElements.length;
    const { handleDelete } = useElementGeneralActions();

    useGeneralHotkeys({ handleDelete });

    const testId = useMemo(() => generateTestId('timeline_actions_delete'), []);

    useEffect(() => {
        if (!manipulationRenderer) {
            return;
        }

        manipulationRenderer.eventEmitter.on('elementSelected', forceUpdate);

        return () => manipulationRenderer.eventEmitter.off('elementSelected', forceUpdate);
    }, [forceUpdate, manipulationRenderer]);

    return (
        <div title="Delete">
            <TimelineActionsIcon onClick={handleDelete} active={isActive} {...testId}>
                <IconDelete />
            </TimelineActionsIcon>
        </div>
    );
}
