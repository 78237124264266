import React, { useEffect } from 'react';
import {
    ElementUpdateTypes,
    ImageElement,
    TextElement,
    GroupElement,
    ShapeElement,
    VideoElement,
} from '@bynder-studio/render-core';
import useEditor from '~/hooks/useEditor';
import useForceUpdate from '~/hooks/useForceUpdate';
import MultiSelection from './MultiSelection';
import Text from './Text';
import Shape from './Shape';
import Video from './Video';
import Image from './Image';
import Group from './Group';
import type { Props } from './types';

const skipForceUpdate = [ElementUpdateTypes.POSITION, ElementUpdateTypes.DIMENSION, ElementUpdateTypes.ROTATION];

const Shots = ({ isMultiSelection, selectedElement, selectedElements }: Props) => {
    const { creativeModel } = useEditor();
    const forceUpdate = useForceUpdate();

    useEffect(() => {
        if (!creativeModel || !selectedElement) {
            return;
        }

        return creativeModel.onElementUpdate(selectedElement.id, ({ updateTypes }) => {
            for (const updateType of updateTypes) {
                if (!skipForceUpdate.includes(updateType)) {
                    forceUpdate();
                    return;
                }
            }
        });
    }, [creativeModel, selectedElement, forceUpdate]);

    switch (true) {
        case isMultiSelection:
            return <MultiSelection creativeModel={creativeModel} selectedElements={selectedElements} />;
        case selectedElement instanceof GroupElement:
            return <Group />;
        case selectedElement instanceof TextElement:
            return <Text />;
        case selectedElement instanceof ImageElement:
            return <Image />;
        case selectedElement instanceof VideoElement:
            return <Video />;
        case selectedElement instanceof ShapeElement:
            return <Shape />;
    }

    return null;
};

export default Shots;
