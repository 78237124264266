import { prop, uniqBy } from 'rambda';
import { OwnershipFilterType } from 'packages/pages/components/filters/components/Ownership/types';
import { actionTypes, CategoriesState } from './types';
import { updateThumbnails } from '../creatives/utils';

const initialState: CategoriesState = {
    categories: [],
    category: null,
    checked: [],
    allChecked: false,
    isFetching: false,
    totalItems: 0,
    searching: false,
    fetchOptions: {
        sort: ['date_created', 'desc'],
        size: 1000,
        page: 0,
        search: '',
        includeCollectionId: null,
        type: undefined,
        subType: 'TEMPLATE',
        ownershipSource: OwnershipFilterType.OWNED_BY_ANYONE,
        creativeType: undefined,
        includeEmptyCollection: true,
    },
    editingInProgress: false,
    editingTemplateInProgress: false,
    deleteInProgress: false,
    creatingInProgress: false,
    movingInProgress: false,
    editingDetailsInProgress: false,
    categoriesErrors: undefined,
    categoryErrors: undefined,
};

export default function categories(state = initialState, action) {
    switch (action.type) {
        case actionTypes.CATEGORIES_RESET:
            return {
                ...initialState,
            };
        case actionTypes.CATEGORIES_RESET_OWNERSHIP:
            return {
                ...initialState,
                fetchOptions: {
                    ...initialState.fetchOptions,
                    ownershipSource: action.ownershipSource,
                },
            };
        case actionTypes.CATEGORIES_START_FETCH:
            return {
                ...state,
                isFetching: true,
                searching: action.searching,
                categories: action.emptyBeforeFetch ? [] : state.categories,
                fetchOptions: {
                    ...state.fetchOptions,
                },
            };
        case actionTypes.CATEGORIES_FAIL_FETCH:
            return {
                ...state,
                isFetching: false,
            };
        case actionTypes.CATEGORIES_SUCCESS_FETCH: {
            const newCategories = action.categories.map((item) => ({
                ...item,
                checked: state.checked.includes(item.id),
            }));

            return {
                ...state,
                isFetching: false,
                categories: uniqBy(prop('id'), [...state.categories, ...newCategories]),
                totalItems: action.totalItems,
                fetchOptions: action.fetchOptions,
            };
        }
        case actionTypes.CATEGORIES_CHANGE_SOME: {
            if (!action.categoryThumbnails.length || (!state.categories.length && !state.category)) {
                return state;
            }

            const newCategories = new Array(state.categories.length);
            let isCategoriesUpdated = false;

            state.categories.forEach((category, idx) => {
                if (!category.previewItems) {
                    newCategories[idx] = category;

                    return;
                }

                const { creatives: newPreviewItems, isUpdated: isPreviewItemsUpdated } = updateThumbnails(
                    category.previewItems,
                    action.categoryThumbnails,
                );

                if (!isPreviewItemsUpdated) {
                    newCategories[idx] = category;

                    return;
                }

                isCategoriesUpdated = true;

                newCategories[idx] = {
                    ...category,
                    previewItems: newPreviewItems,
                };
            });

            let newCategory: any = null;
            let isCategoryUpdated = false;

            if (state.category) {
                const { creatives: newPreviewItems, isUpdated: isPreviewItemsUpdated } = updateThumbnails(
                    state.category.previewItems,
                    action.categoryThumbnails,
                );

                if (!isPreviewItemsUpdated) {
                    newCategory = state.category;
                } else {
                    newCategory = {
                        ...state.category,
                        previewItems: newPreviewItems,
                    };

                    isCategoryUpdated = true;
                }
            } else {
                newCategory = state.category;
            }

            if (!(isCategoriesUpdated || isCategoryUpdated)) {
                return state;
            }

            return {
                ...state,
                category: newCategory,
                categories: newCategories,
            };
        }
        case actionTypes.CATEGORIES_POPULATE: {
            const newCategories = action.categories.map((item) => ({
                ...item,
                checked: state.checked.includes(item.id),
            }));

            return {
                ...state,
                categories: newCategories,
            };
        }
        case actionTypes.CATEGORY_START_FETCH:
            return {
                ...state,
                isFetching: true,
            };
        case actionTypes.CATEGORY_FAIL_FETCH:
            return {
                ...state,
                isFetching: false,
            };
        case actionTypes.CATEGORY_SUCCESS_FETCH:
            return {
                ...state,
                isFetching: false,
                category: action.category,
            };
        case actionTypes.CATEGORIES_START_ADD_NEW:
            return {
                ...state,
                creatingInProgress: true,
            };
        case actionTypes.CATEGORIES_ADD_NEW_SUCCESS:
            return {
                ...state,
                creatingInProgress: false,
            };
        case actionTypes.CATEGORIES_ADD_NEW_FAILURE:
            return {
                ...state,
                creatingInProgress: false,
            };
        case actionTypes.CATEGORY_RENAME_IN_PROGRESS:
            return {
                ...state,
                editingInProgress: true,
            };
        case actionTypes.CATEGORY_RENAME_SUCCESS:
            return {
                ...state,
                editingInProgress: false,
            };
        case actionTypes.CATEGORY_RENAME_FAILURE:
            return {
                ...state,
                editingInProgress: false,
            };
        case actionTypes.CATEGORY_ADD_TEMPLATE_IN_PROGRESS:
            return {
                ...state,
                movingInProgress: true,
            };
        case actionTypes.CATEGORY_ADD_TEMPLATE_SUCCESS:
            return {
                ...state,
                movingInProgress: false,
            };
        case actionTypes.CATEGORY_ADD_TEMPLATE_FAILURE:
            return {
                ...state,
                movingInProgress: false,
            };
        case actionTypes.CATEGORY_REMOVE_FROM_TEMPLATE_IN_PROGRESS:
            return {
                ...state,
                movingInProgress: true,
            };
        case actionTypes.CATEGORY_REMOVE_FROM_TEMPLATE_SUCCESS:
            return {
                ...state,
                movingInProgress: false,
            };
        case actionTypes.CATEGORY_REMOVE_FROM_TEMPLATE_FAILURE:
            return {
                ...state,
                movingInProgress: false,
            };
        case actionTypes.CATEGORY_EDIT_IN_PROGRESS:
            return {
                ...state,
                editingDetailsInProgress: true,
            };
        case actionTypes.CATEGORY_EDIT_SUCCESS:
            return {
                ...state,
                editingDetailsInProgress: false,
            };
        case actionTypes.CATEGORY_EDIT_FAILURE:
            return {
                ...state,
                editingDetailsInProgress: false,
            };
        case actionTypes.CATEGORY_TEMPLATE_REMOVE:
            return {
                ...state,
                categories: state.categories.map((category) => {
                    if (category.id !== action.categoryId) {
                        return category;
                    }

                    return {
                        ...category,
                        templates: (category.templates || []).filter(
                            (template) => !action.templateIds.includes(template.id),
                        ),
                    };
                }),
            };
        case actionTypes.CATEGORY_SET_RENAME_TEMPLATE_STATUS:
            return {
                ...state,
                editingTemplateInProgress: action.status,
            };
        case actionTypes.CATEGORY_UPDATE_TEMPLATES:
            return {
                ...state,
                categories: action.categories,
                editingTemplateInProgress: false,
            };
        default:
            return state;
    }
}
