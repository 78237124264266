import styled, { css } from 'styled-components';
import { token } from '@bynder/design-system';

type MarkerProp = { isSlipMode: boolean };
type InputPropsType = { disabled?: boolean };

export const DurationTracker = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    width: 100%;
    height: 40px;
    margin: 16px auto 0 auto;

    border-radius: 5px;
    background-color: ${token.gray100};
    cursor: pointer;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 4px;
        width: 4px;
        height: calc(100% - 8px);
        background-color: ${token.gray200a};
        border-radius: 2px;
        pointer-events: none;
    }

    &:before {
        left: -16px;
    }

    &:after {
        right: -16px;
    }
`;

export const DurationSlider = styled.div`
    position: relative;
    height: 100%;

    border-radius: 10px;
    background-color: ${token.blue100};
    cursor: grab;
    z-index: 3;

    &:active {
        cursor: grabbing;
    }
`;

const durationMarkerCommon = css<MarkerProp>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    position: absolute;
    top: 0;
    z-index: 92;
    height: 100%;

    width: ${(props) => (props.isSlipMode ? '8px' : '12px')};
    cursor: ${(props) => (props.isSlipMode ? 'grab' : 'col-resize')};
    background-color: ${token.blue500};
`;

export const DurationLeftMarker = styled.div<MarkerProp>`
    ${durationMarkerCommon};
    left: 0;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
`;

export const DurationRightMarker = styled.div<MarkerProp>`
    ${durationMarkerCommon};
    right: 0;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
`;

export const DurationMarkerDot = styled.div`
    border-radius: 50%;
    width: 4px;
    height: 4px;
    margin-bottom: 2px;
    background-color: ${token.gray400a};
`;

export const DurationInputWrapper = styled.div<InputPropsType>`
    margin-right: 12px;

    & input {
        width: 82px !important;
    }
`;

export const DurationWrapper = styled.div`
    position: relative;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 8px;
`;

export const BadgeWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 16px;

    transform: translateY(-50%);
`;

export const DurationTimeTooltip = styled.div`
    display: none;
    max-width: 240px;
    padding: 6px 12px;
    top: -40px;
    position: absolute;
    border-radius: 5px;
    background: ${token.gray900a};
    color: ${token.white};
    font-size: 14px;
    line-height: 20px;
    word-break: break-word;
`;
