import React from 'react';
import { Form, Input } from '@bynder/design-system';
import { IconLetterSpacing } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import generateTestId from '~/helpers/testIdHelpers';
import { toPercent, toValue } from '../../utils';
import { Props } from './types';
import useFontSettingInput from '../Fonts/useFontSettingInput';

const LetterSpacing = ({ creativeModel, selectedElement, disabled }: Props) => {
    const { translate } = useTranslate();
    const attrs = useFontSettingInput({
        creativeModel,
        selectedElement,
        disabled,
        propName: 'tracking',
        autoCorrectKey: 'text_letters_space',
        mapDisplayValue: (v) => toPercent(v).toString(),
        mapSubmitValue: toValue,
    });

    return (
        <Form.Group>
            <Input
                {...attrs}
                name={translate('editor.sidebar.shots.letter_spacing.label')}
                aria-label={translate('editor.sidebar.shots.letter_spacing.label')}
                icon={<IconLetterSpacing />}
                suffix="%"
                {...generateTestId('shots_text_letter_spacing')}
            />
        </Form.Group>
    );
};

export default LetterSpacing;
