import React, { forwardRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, Dropdown } from '@bynder/design-system';
import { IconMore } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';

type TriggerProps = Parameters<React.ComponentProps<typeof Dropdown>['trigger']>[0] & {
    isCardAction?: boolean;
    onContextMenuTriggerClick?: () => void;
};

export const Trigger = forwardRef(
    (
        { isOpen, isCardAction, onContextMenuTriggerClick, ...triggerProps }: TriggerProps,
        ref: Ref<HTMLButtonElement>,
    ) => {
        const dispatch = useDispatch();
        const { translate } = useTranslate();

        const onClick = useCallback(() => {
            onContextMenuTriggerClick?.();
            triggerProps.onClick();
        }, [dispatch, triggerProps]);

        return !!isCardAction ? (
            <Card.ContextActionButton
                aria-label={translate('pages.categories.category.template.menu')}
                isPressed={isOpen}
                {...triggerProps}
                onClick={onClick}
                ref={ref}
            >
                <IconMore />
            </Card.ContextActionButton>
        ) : (
            <Button
                title={translate('pages.categories.category.template.menu')}
                variant="clean"
                icon={<IconMore />}
                {...triggerProps}
                onClick={onClick}
                ref={ref}
            />
        );
    },
);
