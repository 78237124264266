export type PositionObject = {
    x: number;
    y: number;
};

export class Position {
    x: number;
    y: number;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    getX(): number {
        return this.x;
    }

    setX(x: number): void {
        this.x = x;
    }

    getY(): number {
        return this.y;
    }

    setY(y: number): void {
        this.y = y;
    }

    add(pos: Position): Position {
        return new Position(this.x + pos.x, this.y + pos.y);
    }

    addX(pos: Position): Position {
        return new Position(this.x + pos.x, this.y);
    }

    addY(pos: Position): Position {
        return new Position(this.x, this.y + pos.y);
    }

    subtract(pos: Position): Position {
        return new Position(this.x - pos.x, this.y - pos.y);
    }

    subtractX(pos: Position): Position {
        return new Position(this.x - pos.x, this.y);
    }

    subtractY(pos: Position): Position {
        return new Position(this.x, this.y - pos.y);
    }

    multiply(scale: number): Position {
        return new Position(this.x * scale, this.y * scale);
    }

    multiplyX(scale: number): Position {
        return new Position(this.x * scale, this.y);
    }

    multiplyY(scale: number): Position {
        return new Position(this.x, this.y * scale);
    }

    divide(scale: number): Position {
        return new Position(this.x / scale, this.y / scale);
    }

    rotatePoint(centerPosition: Position, angle: number) {
        const angleInRadians = (-angle * Math.PI) / 180;
        const newx =
            (this.getX() - centerPosition.getX()) * Math.cos(angleInRadians) -
            (this.getY() - centerPosition.getY()) * Math.sin(angleInRadians) +
            centerPosition.getX();
        const newy =
            (this.getX() - centerPosition.getX()) * Math.sin(angleInRadians) +
            (this.getY() - centerPosition.getY()) * Math.cos(angleInRadians) +
            centerPosition.getY();
        return new Position(newx, newy);
    }

    equals(position: Position): boolean {
        return this.x === position.x && this.y === position.y;
    }

    getCopy(): Position {
        return new Position(this.x, this.y);
    }

    toObject(): PositionObject {
        return {
            x: this.x,
            y: this.y,
        };
    }
}
