import React, { useCallback, useState } from 'react';
import { Buffer, Card } from '@bynder/design-system';
import { parseAspectRatio } from 'packages/pages/components/card/utils';
import { CardAspectRatio } from './CardAspectRatio';
import useExport from '../hooks/useExport';
import { CardBox, ExportModalCardImage } from './Card.styled';
import { useVariationPageThumbnail } from 'packages/pages/design/VariationsContext/useVariationPageThumbnail';

const PageCard = ({
    variationSetId,
    variationId,
    name,
    width,
    height,
    aspectRatio,
    containsTransparency,
    pageIndex,
}) => {
    const { selectedIds, toggleSelect } = useExport();
    const isSelected = selectedIds[variationId];
    const { variationPageThumbnail, loading } = useVariationPageThumbnail(
        variationSetId,
        pageIndex,
        variationSetId,
        300,
        300,
    );

    const handleChange = useCallback(() => {
        toggleSelect(variationId);
    }, [variationId, toggleSelect]);

    const [currentWidth, setCurrentWidth] = useState(0);

    const measuredRef = useCallback((node) => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    return (
        <CardBox
            ref={measuredRef}
            defaultHeight={Math.round(((currentWidth / parseAspectRatio(aspectRatio)) * 100) / 100)}
        >
            <Card variant="clean" selectButtonAriaLabel="Select asset" onSelect={handleChange} isSelected={isSelected}>
                <Card.Frame
                    height="fluid"
                    isCheckeredBackground={containsTransparency}
                    progressBar={loading && <Buffer noCornerRadius />}
                >
                    {variationPageThumbnail?.dataUrl ? (
                        <Card.Images>
                            <ExportModalCardImage src={variationPageThumbnail?.dataUrl} />
                        </Card.Images>
                    ) : (
                        <CardAspectRatio aspectRatio={aspectRatio} />
                    )}
                </Card.Frame>
                <Card.Header title={name} detail={`${width} × ${height} px`} />
            </Card>
        </CardBox>
    );
};

export default PageCard;
