import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Modal, Thumbnail } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconFolder, IconFolderOpen } from '@bynder/icons';
import { customerIdSelector } from 'packages/store/user/user.selectors';
import { CollectionSubType, CollectionType } from 'packages/store/collections/types';
import useAccessRights from 'packages/hooks/useAccessRights';
import { ActionStatus, Entity, TemplateAction } from 'packages/hooks/useAccessRights/types';
import { Template } from 'packages/store/templates/types';
import { searchCategories, initCategories } from 'packages/store/searchCategory/searchCategory.actions';
import { Category } from 'packages/store/categories/types';
import { getSearchedCategoriesState } from 'packages/store/searchCategory/searchCategory.selectors';
import {
    getCategoriesActionProgress,
    getCategory,
    getCategories,
} from 'packages/store/categories/categories.selectors';
import { moveToCategory } from 'packages/store/categories/categories.actions';
import { getTemplates } from 'packages/store/templates/templates.selectors';
import { handleDeselectAll } from '~/store/creatives/creatives.actions';
import { selectedTemplateSelector } from '~/store/templates/templates.selectors';
import debounce from '~/helpers/debounce';
import features from '~/configs/features';
import roles from '~/configs/roles';
import { OwnershipFilterType } from '../filters/components/Ownership/types';
import AccessNotificationModal from './accessNotificationModal';
import { hasPreviewImageURL, isProcessingDone } from '../thumbnail/utils';
import modalContainer from 'packages/common/modalContainer';

const useCurrentCategory = () => {
    const category = useSelector(getCategory);
    const categories = useSelector(getCategories);
    const selectedTemplate = useSelector(selectedTemplateSelector);

    if (!selectedTemplate) {
        return category;
    }

    return categories.find((cat) => cat.id === selectedTemplate.categoryId);
};

const useTemplateMoveModal = ({ isOverview = false }: { isOverview?: boolean }) => {
    const currentCompany = useSelector(customerIdSelector);
    const [searchValue, setSearchValue] = useState('');
    const [moveModalTemplate, setMoveModalTemplate] = useState<Template[] | null>(null);
    const { fetchOptions, categories, isFetching } = useSelector(getSearchedCategoriesState);
    const { checked: selectedTemplates } = useSelector(getTemplates);

    const category = useCurrentCategory();
    const { isEntityActionAllowed, isPlatformAllowed, isAccountRoleAssigned } = useAccessRights();
    const { movingInProgress } = useSelector(getCategoriesActionProgress);

    const [selectedCategory, setSelectedCategory] = useState<Category | null>(category || null);

    const [accessNotificationModal, setAccessNotificationModal] = useState<
        { getAllowedToMoveTemplateIds: number[] | undefined; moveModalTemplate: Template[] | null } | undefined
    >(undefined);

    const isMovingAllowed = (item: Template) => {
        const status = isEntityActionAllowed(TemplateAction.MOVE, Entity.TEMPLATE, item.grantedPermissions);

        return status !== ActionStatus.NOT_ALLOWED && status !== ActionStatus.DISABLED;
    };

    const getAllowedToMoveTemplateIds = moveModalTemplate?.reduce((acc: number[], template: Template) => {
        if (isMovingAllowed(template)) {
            acc.push(template.id);
        }

        return acc;
    }, []);

    const { translate } = useTranslate();
    const dispatch = useDispatch();

    const type: CollectionType = 'CREATIVE';
    const subType: CollectionSubType = 'TEMPLATE';

    const isTrialAccount =
        isPlatformAllowed([features.TRIAL_ENABLED]) && isAccountRoleAssigned(roles.video_brand_studio.video.manage);
    const categorySearchArgs = {
        sort: ['NAME', 'ASC'],
        type,
        subType,
        size: 1000,
        ownershipSource: isTrialAccount ? OwnershipFilterType.OWNED_BY_OTHERS : OwnershipFilterType.OWNED_BY_ANYONE,
        includeEmptyCollection: true,
    };

    const showModal = moveModalTemplate !== null;

    const onSearch = debounce(
        (newSearchValue: string) => {
            const newOptions = { ...fetchOptions, ...categorySearchArgs };
            newOptions.search = newSearchValue;

            dispatch(searchCategories({ ...newOptions }, currentCompany, true));
        },
        1000,
        false,
    );

    const handleSearchValueChange = useCallback((newValue) => {
        setSearchValue(newValue);
        onSearch(newValue);
    }, []);

    const onModalClose = () => {
        setMoveModalTemplate(null);
        setSelectedCategory(null);
        setSearchValue('');
    };

    const onMoveTemplate = React.useCallback(() => {
        if (!getAllowedToMoveTemplateIds?.length) {
            setAccessNotificationModal({
                getAllowedToMoveTemplateIds,
                moveModalTemplate,
            });
        } else {
            if (selectedTemplates.size && getAllowedToMoveTemplateIds?.length !== selectedTemplates.size) {
                setAccessNotificationModal({
                    getAllowedToMoveTemplateIds,
                    moveModalTemplate,
                });
            }

            dispatch(
                moveToCategory({
                    categoryId: selectedCategory?.id,
                    templates: getAllowedToMoveTemplateIds,
                    type: 'CREATIVE',
                    isOverview,
                    isEditor: false,
                }),
            );
        }

        dispatch(handleDeselectAll());
        onModalClose();
    }, [getAllowedToMoveTemplateIds, selectedCategory?.id]);

    const getActiveCategoryName = useCallback(() => {
        if (selectedCategory?.id && !isFetching) {
            return selectedCategory?.name;
        }

        return translate('modal.template.move.none');
    }, [isFetching, selectedCategory?.id]);

    const getCategoryThumbnail = (item: Category) => {
        if (item.coverImageUrl) {
            return (
                <Thumbnail
                    size="xs"
                    variant="content"
                    imageUrl={item.coverImageUrl}
                    backgroundColor={`#${item.coverBgColor}`}
                />
            );
        }

        if (item.coverBgColor) {
            return <Thumbnail size="xs" variant="content" backgroundColor={`#${item.coverBgColor}`} />;
        }

        if (hasPreviewImageURL(item) && isProcessingDone(item)) {
            return (
                <Thumbnail
                    size="xs"
                    variant="content"
                    imageUrl={item?.previewItems[item?.previewItems?.length - 1].previewUrl}
                />
            );
        }

        return (
            <Thumbnail size="xs" variant="clean" icon={<IconFolder />}>
                {!isProcessingDone(item) && <Thumbnail.Overlay isLoading={!isProcessingDone(item)} />}
            </Thumbnail>
        );
    };

    const getSelectedCategoryThumbnail = useCallback(() => {
        if (isFetching) {
            return <Thumbnail size="xs" variant="clean" icon={<IconFolderOpen />} backgroundColor="transparent" />;
        }

        if (selectedCategory) {
            if (selectedCategory.coverImageUrl) {
                return (
                    <Thumbnail
                        size="xs"
                        variant="content"
                        imageUrl={selectedCategory.coverImageUrl}
                        backgroundColor={`#${selectedCategory.coverBgColor}`}
                    />
                );
            }

            if (selectedCategory.coverBgColor) {
                return <Thumbnail size="xs" variant="content" backgroundColor={`#${selectedCategory.coverBgColor}`} />;
            }

            if (hasPreviewImageURL(selectedCategory) && isProcessingDone(selectedCategory)) {
                return (
                    <Thumbnail
                        size="xs"
                        variant="content"
                        imageUrl={selectedCategory.previewItems[selectedCategory.previewItems.length - 1].previewUrl}
                    />
                );
            }
        }

        return (
            <Thumbnail size="xs" variant="clean" icon={<IconFolderOpen />}>
                {!isProcessingDone(selectedCategory) && (
                    <Thumbnail.Overlay isLoading={!isProcessingDone(selectedCategory)} />
                )}
            </Thumbnail>
        );
    }, [isFetching, selectedCategory]);

    useEffect(() => {
        if (category && !isOverview) {
            setSelectedCategory(category);
        } else {
            setSelectedCategory(null);
        }
    }, [category, moveModalTemplate, isOverview]);

    useLayoutEffect(() => {
        if (showModal) {
            dispatch(initCategories(categorySearchArgs));
        }
    }, [showModal]);

    const renderModalTemplateMove = () => (
        <>
            {!accessNotificationModal ? (
                <Modal
                    container={modalContainer}
                    title={translate('modal.template.move.title')}
                    isOpen={showModal}
                    onClose={onModalClose}
                    actionPrimary={
                        <Button
                            variant="primary"
                            onClick={onMoveTemplate}
                            isDisabled={isFetching || !selectedCategory || selectedCategory?.id === category?.id}
                            isLoading={movingInProgress}
                            title={translate('modal.template.move.move')}
                        >
                            {translate('modal.template.move.move')}
                        </Button>
                    }
                    actionSecondary={
                        <Button variant="secondary" onClick={onModalClose} title={translate('modal.cancel.title')}>
                            {translate('modal.cancel.title')}
                        </Button>
                    }
                >
                    <Dropdown
                        position="bottom"
                        trigger={({ isOpen, ...triggerProps }) => (
                            <Button
                                isFullWidth
                                isPressed={isOpen}
                                isDisabled={isFetching}
                                rightIcon={<Dropdown.Arrow />}
                                icon={getSelectedCategoryThumbnail()}
                                {...triggerProps}
                                aria-label={translate('modal.template.move.dropdown.category')}
                            >
                                <Dropdown.SelectButtonText>{getActiveCategoryName()}</Dropdown.SelectButtonText>
                            </Button>
                        )}
                    >
                        <Dropdown.SearchInput
                            value={searchValue}
                            onChange={handleSearchValueChange}
                            placeholder={translate('modal.template.move.search.category')}
                            aria-label={translate('modal.template.move.search.category')}
                        />
                        <Dropdown.SectionTitle> </Dropdown.SectionTitle>
                        {isFetching && (
                            <Dropdown.Item
                                onClick={() => null}
                                isDisabled
                                icon={<Button isLoading variant="clean" aria-label="Loading..." />}
                            >
                                <Dropdown.SelectButtonText>
                                    {translate('modal.template.move.fetch.category')}
                                </Dropdown.SelectButtonText>
                            </Dropdown.Item>
                        )}

                        {categories?.length === 0 && !isFetching && (
                            <Dropdown.Text>{translate('modal.template.move.results.none')}</Dropdown.Text>
                        )}

                        {categories?.length > 0 ? (
                            <>
                                {categories.map((singleCategory) => (
                                    <Dropdown.Item
                                        onClick={() => {
                                            setSelectedCategory((prevSelected) =>
                                                prevSelected === singleCategory ? null : singleCategory,
                                            );
                                        }}
                                        key={singleCategory.id}
                                        isChecked={singleCategory.name === category?.name}
                                        role="menuitemradio"
                                        icon={getCategoryThumbnail(singleCategory)}
                                    >
                                        {singleCategory.name}
                                    </Dropdown.Item>
                                ))}
                            </>
                        ) : null}
                    </Dropdown>
                </Modal>
            ) : (
                <AccessNotificationModal
                    title={translate('modal.template.move.title')}
                    description={
                        !accessNotificationModal.getAllowedToMoveTemplateIds?.length
                            ? translate('modal.template.access.not-allowed')
                            : translate('modal.template.access.not-all', { action: 'move' })
                    }
                    loading={movingInProgress}
                    isOpen={!!accessNotificationModal}
                    toggleOpen={() => setAccessNotificationModal(undefined)}
                />
            )}
        </>
    );

    return {
        setMoveModalTemplate,
        renderModalTemplateMove,
    };
};

export default useTemplateMoveModal;
