import React, { useCallback, useRef, useState, memo, useEffect } from 'react';
import { Dropdown, Button, Tooltip, Flex } from '@bynder/design-system';
import { ElementTypes } from '@bynder-studio/render-core';
import { IconDelete, IconSound, IconSoundOff, IconVolumeDown, IconVolumeOff, IconVolumeUp } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import ContentPickerModal from 'packages/pages/editor/ContentPickerModal';
import MaybeTooltip from 'packages/common/MaybeTooltip';
import useForceUpdate from '~/hooks/useForceUpdate';
import { useAudioShortcuts } from 'packages/hooks/useAudioShortcuts';
import { useReviewStatus } from 'packages/pages/design/sidebar/shots/useReviewStatus';
import useVariations from '../../hooks/useVariations';
import useDesign from '../../hooks/useDesign';
import DeleteAudioModal from '../../sidebar/variations/DeleteAudioModal';
import { VolumeSlider } from './TimelineHeader.styled';

type Props = {
    isMuted: boolean;
    volume: number;
    onChange: (volume: number, isMuted: boolean) => void;
};

enum CONTENT_MODAL_STEPS {
    CHOICE = 'CHOICE',
    TRIM = 'TRIM',
}

const emptyAudioParams = {
    src: null,
    srcType: null,
    srcId: null,
    fileName: '',
    offsetTime: 0,
};

// Design volume control
// TODO: Make one shared component for volume (see SimpleVolumeControl)
const VolumeControl = ({ isMuted, volume, onChange }: Props) => {
    const { translate } = useTranslate();
    const { isDisabledByReview } = useReviewStatus();
    const { creativeModel, duration, setPausePlayback } = useDesign();
    const { updateVariationGlobalProperty } = useVariations();
    const forceUpdate = useForceUpdate();
    const trimModalStep = useRef<string>('');
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isTrimModalOpen, setIsTrimModalOpen] = useState<boolean>(false);
    const [audioTrack, setAudioTrack] = useState(creativeModel?.getGlobalAudio());

    const handleToggleMuteClick = useCallback(() => {
        onChange(volume, !isMuted);
    }, [onChange, volume, isMuted]);

    useAudioShortcuts(handleToggleMuteClick);

    useEffect(() => {
        if (creativeModel) {
            const audioControl = creativeModel.getAudioControl();

            audioControl.setVolume(volume);
            audioControl.setMutedStatus(isMuted);
        }
    }, [creativeModel?.getAudioControl()]);

    useEffect(() => {
        setAudioTrack(creativeModel?.getGlobalAudio());
    }, [creativeModel?.getGlobalAudio()]);

    const handleVolumeChange = useCallback(
        (val) => {
            onChange(val / 100, val === 0);
        },
        [onChange],
    );

    const handleTrimClose = useCallback(() => {
        setIsTrimModalOpen(false);
    }, []);

    const handleDeleteModalToggle = useCallback(() => {
        setIsDeleteModalOpen((current) => !current);
    }, []);

    const deleteAudioFromVariation = useCallback(() => {
        setPausePlayback();
        creativeModel.updateGlobalProperty(ElementTypes.GLOBAL_AUDIO, emptyAudioParams);

        const audio = creativeModel.getGlobalAudio();
        updateVariationGlobalProperty(ElementTypes.GLOBAL_AUDIO, audio.id, emptyAudioParams);
        handleDeleteModalToggle();
        forceUpdate();
    }, [creativeModel, forceUpdate, handleDeleteModalToggle, updateVariationGlobalProperty]);

    const handleDeleteClick = useCallback(() => {
        setIsDeleteModalOpen(true);
    }, []);

    const handleTrimToggle = useCallback(() => {
        trimModalStep.current = CONTENT_MODAL_STEPS.TRIM;
        setIsTrimModalOpen(!isTrimModalOpen);
    }, [isTrimModalOpen]);

    const handleReplaceClick = useCallback(() => {
        trimModalStep.current = CONTENT_MODAL_STEPS.CHOICE;
        setIsTrimModalOpen(true);
    }, []);

    const confirmSelection = useCallback(
        (args) => {
            const { source, offsetTime, url, name } = args;
            const newParams = {
                src: url,
                srcType: 'ASSET_MANAGER',
                srcId: source,
                fileName: name,
                offsetTime,
            };

            setPausePlayback();
            creativeModel.updateGlobalProperty(ElementTypes.GLOBAL_AUDIO, newParams);

            const audio = creativeModel.getGlobalAudio();
            updateVariationGlobalProperty(ElementTypes.GLOBAL_AUDIO, audio.id, newParams);
            handleTrimClose();
        },
        [creativeModel, handleTrimClose, updateVariationGlobalProperty],
    );

    const getVolumeTooltipContent = () => {
        if (isMuted) {
            return translate('editor.timeline.shots.audio.unmute');
        }

        return translate('editor.timeline.shots.audio.mute');
    };

    const getInteractiveVolumeIcon = () => {
        if (isMuted || volume === 0) {
            return <IconVolumeOff />;
        }

        if (volume > 0.5) {
            return <IconVolumeUp />;
        }

        return <IconVolumeDown />;
    };

    const getInteractiveAudioIcon = () => {
        if (hasTrack) {
            return <IconSound />;
        }

        return <IconSoundOff />;
    };

    const hasTrack = !!audioTrack.src;
    const isLocked = audioTrack.locked;
    const step = trimModalStep.current === CONTENT_MODAL_STEPS.TRIM && hasTrack;

    return (
        <>
            <Flex alignItems="center" justifyContent="space-between" className="dtimeline--header-volume">
                <VolumeSlider
                    value={isMuted ? 0 : Math.round(volume * 100)}
                    min={0}
                    max={100}
                    onChange={handleVolumeChange}
                    iconLeft={
                        <Tooltip content={getVolumeTooltipContent()} timing="instant">
                            <Button
                                title={translate('editor.timeline.shots.audio.status')}
                                variant="clean"
                                onClick={handleToggleMuteClick}
                                icon={getInteractiveVolumeIcon()}
                            />
                        </Tooltip>
                    }
                    isInactive
                />
                <Dropdown
                    position="top-right"
                    trigger={({ isOpen, ...triggerProps }) => (
                        <MaybeTooltip
                            hasTooltip={!(isLocked || isDisabledByReview)}
                            content={hasTrack ? audioTrack.fileName : translate('editor.timeline.shots.audio.none')}
                        >
                            <Button
                                title={translate('editor.timeline.shots.audio.track')}
                                isDisabled={isLocked || isDisabledByReview}
                                isPressed={isOpen}
                                variant="clean"
                                icon={getInteractiveAudioIcon()}
                                {...triggerProps}
                            />
                        </MaybeTooltip>
                    )}
                >
                    {hasTrack ? (
                        <>
                            <Dropdown.Item onClick={handleReplaceClick}>
                                {translate('editor.timeline.shots.audio.replace')}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleTrimToggle}>
                                {translate('editor.timeline.shots.audio.trim')}
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item icon={<IconDelete />} onClick={handleDeleteClick}>
                                {translate('editor.timeline.shots.audio.delete')}
                            </Dropdown.Item>
                        </>
                    ) : (
                        <Dropdown.Item icon={<IconSound />} onClick={handleTrimToggle}>
                            {translate('editor.timeline.shots.audio.add')}
                        </Dropdown.Item>
                    )}
                </Dropdown>
            </Flex>
            {isTrimModalOpen && (
                <ContentPickerModal
                    type="audio"
                    step={step}
                    show={isTrimModalOpen}
                    frameRate={25}
                    selectedObject={{ element: { ...audioTrack, duration } }}
                    toggleModal={handleTrimToggle}
                    confirmSelection={confirmSelection}
                />
            )}
            <DeleteAudioModal
                isOpen={isDeleteModalOpen}
                onClose={handleDeleteModalToggle}
                onConfirm={deleteAudioFromVariation}
            />
        </>
    );
};

export default memo<Props>(VolumeControl);
