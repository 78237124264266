import { type PageChangedData, type RenderListenerData } from '@bynder-studio/render-core';

type VariationsThumbnail = Partial<RenderListenerData> & { needsUpdate?: boolean };

let variationsThumbnails: {
    [variationId: string]: {
        [pageIndex: number]: {
            [size: string]: VariationsThumbnail;
        };
    };
} = {};
let listeners: Array<() => void> = [];
let defaultThumbnailSize = { width: 64, height: 64 };

function emitChange() {
    for (const listener of listeners) {
        listener();
    }
}

export const variationsThumbnailsStore = {
    setDefaultThumbnailSize(dimension: { width: number; height: number }) {
        defaultThumbnailSize = dimension;
    },
    getDefaultThumbnailSize() {
        return defaultThumbnailSize;
    },
    variationsThumbnailRenderStart(data: Omit<RenderListenerData, 'dimension' | 'dataUrl'>) {
        const { variationId, pageIndex, width, height } = data;
        const size = `${width}x${height}`;

        if (!(variationId in variationsThumbnails)) {
            variationsThumbnails[variationId] = [];
        }

        if (!variationsThumbnails[variationId][pageIndex]) {
            variationsThumbnails[variationId][pageIndex] = {};
        }

        if (!variationsThumbnails[variationId][pageIndex][size]) {
            variationsThumbnails[variationId][pageIndex][size] = data;
        }

        variationsThumbnails[variationId][pageIndex][size].needsUpdate = false;
        variationsThumbnails = { ...variationsThumbnails };
        emitChange();
    },
    variationsThumbnailRenderEnd(data: RenderListenerData) {
        const { variationId, pageIndex, width, height } = data;
        const size = `${width}x${height}`;
        variationsThumbnails[variationId][pageIndex][size] = data;
        variationsThumbnails = { ...variationsThumbnails };
        emitChange();
    },
    pageChanged(data: PageChangedData) {
        const { variationId, pageIndex } = data;

        if (!variationsThumbnails[variationId] || !variationsThumbnails[variationId][pageIndex]) {
            return;
        }

        Object.values(variationsThumbnails[variationId][pageIndex]).forEach((item) => {
            item.needsUpdate = true;
        });

        variationsThumbnails = { ...variationsThumbnails };
        emitChange();
    },
    deleteVariationThumbnails(variationId: string) {
        delete variationsThumbnails[variationId];
    },
    swapVariationThumbnails(oldVariationId: string, newVariationId: string) {
        variationsThumbnails[newVariationId] = variationsThumbnails[oldVariationId];
        delete variationsThumbnails[oldVariationId];
    },
    subscribe(listener: () => void) {
        listeners = [...listeners, listener];

        return () => {
            listeners = listeners.filter((l) => l !== listener);
        };
    },
    isThumbnailsReady(
        variationId: string,
        pageIndex: number,
        width: number = defaultThumbnailSize.width,
        height: number = defaultThumbnailSize.height,
    ) {
        const size = `${width}x${height}`;
        const thumbnail = variationsThumbnails[variationId]?.[pageIndex]?.[size];

        if (!thumbnail) {
            return false;
        }

        return !thumbnail.needsUpdate;
    },
    getSnapshot() {
        return variationsThumbnails;
    },
};
