import { useCallback, useEffect } from 'react';
import useEditor from '~/hooks/useEditor';
import debounce from '~/helpers/debounce';
import * as persistVolume from '~/helpers/persistVolume';

const useVolumeControl = () => {
    const { creativeModel } = useEditor();

    useEffect(() => {
        const audioControl = creativeModel?.getAudioControl();

        if (!audioControl) {
            return;
        }

        const savedVolume = persistVolume.getVolume();
        const savedMuteStatus = persistVolume.getMutedStatus();

        if (savedVolume) {
            audioControl.setVolume(Number(savedVolume));
        }

        audioControl.setMutedStatus(savedMuteStatus);
    }, [creativeModel]);

    const saveMutedStatusInStorage = useCallback((status) => {
        persistVolume.setMutedStatus(status);
    }, []);

    const saveVolumeInStorage = useCallback(
        debounce(
            (v) => {
                persistVolume.setVolume(v);
            },
            100,
            false,
        ),
        [],
    );

    const changeVolume = useCallback(
        (v) => {
            creativeModel?.getAudioControl().setVolume(v);
            saveVolumeInStorage(v);
        },
        [creativeModel, saveVolumeInStorage],
    );

    const mute = useCallback(() => {
        creativeModel?.getAudioControl().mute();
        saveMutedStatusInStorage(true);
    }, [creativeModel, saveMutedStatusInStorage]);

    const unmute = useCallback(() => {
        creativeModel?.getAudioControl().unmute();
        saveMutedStatusInStorage(false);
    }, [creativeModel, saveMutedStatusInStorage]);

    const toggleMute = useCallback(() => {
        const audioControl = creativeModel?.getAudioControl();

        audioControl.toggleMute();
        saveMutedStatusInStorage(audioControl.getMutedStatus());
    }, [creativeModel, saveMutedStatusInStorage]);

    const setMutedStatus = useCallback(
        (muted) => {
            creativeModel?.getAudioControl().setMutedStatus(muted);
        },
        [creativeModel],
    );

    return {
        mute,
        unmute,
        toggleMute,
        setMutedStatus,
        changeVolume,
        volume: creativeModel?.getAudioControl()?.getVolume(),
        isMuted: creativeModel?.getAudioControl()?.getMutedStatus(),
    };
};

export default useVolumeControl;
