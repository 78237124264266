import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { LogSlider } from './LogSlider';

type ZoomControlHandler = 'left' | 'right' | 'control' | 'full';
type ZoomControlState = {
    isFull: boolean;
    handler: ZoomControlHandler;
    containerRect: DOMRect;
    controlRect: DOMRect;
    isDown: boolean;
    startX: number;
    x: number;
    width: number;
    left: number;
    oldWidth: number;
    oldLeft: number;
};

type ZoomControlChangeParams = {
    left: number;
    width: number;
    zoom: number;
};
export type ZoomControlChangeHandler = (params: ZoomControlChangeParams) => void;

export const useZoomControl = (onChange: ZoomControlChangeHandler, duration: number) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const controlRef = useRef<HTMLDivElement>(null);
    const data = useRef<ZoomControlState>({ isFull: false, isDown: false, width: 100, left: 0 } as ZoomControlState);

    const logZoomIn = useMemo(
        () =>
            new LogSlider({
                minpos: 0,
                maxpos: 100,
                minval: 100,
                maxval: Math.max(duration, 300),
            }),
        [duration],
    );

    const handleMove = useCallback(() => {
        if (!containerRef.current || !controlRef.current) return;

        const { handler, startX, x: mouseX, containerRect, controlRect, isFull } = data.current;
        const { width: containerWidth, x: containerX } = containerRect;
        const { width: controlWidth, x: controlX } = controlRect;
        const clamp = (n: number, min: number, max: number) => Math.min(Math.max(min, n), max);

        const x = Math.max(containerX, Math.min(containerWidth + containerX, mouseX)) - containerX;
        const delta = x - (startX - containerX);
        const minWidth = 40;

        if (handler === 'left') {
            const maxWidth = controlX + controlWidth - containerX;
            const left = clamp(controlX - containerX + delta, 0, maxWidth - minWidth);
            const width = clamp(maxWidth - left, minWidth, maxWidth);
            const widthPercent = (width / containerWidth) * 100;
            const leftPercent = (left / containerWidth) * 100;
            data.current.width = widthPercent;
            data.current.left = leftPercent;
            data.current.isFull = false;
            controlRef.current.style.width = `${widthPercent}%`;
            controlRef.current.style.left = `${leftPercent}%`;
        }
        if (handler === 'right') {
            const maxWidth = containerWidth - (controlX - containerX);
            const width = clamp(controlWidth + delta, minWidth, maxWidth);
            const widthPercent = (width / containerWidth) * 100;
            data.current.width = widthPercent;
            data.current.isFull = false;
            controlRef.current.style.width = `${widthPercent}%`;
        }
        if (handler === 'control') {
            const left = clamp(controlX - containerX + delta, 0, containerWidth - controlWidth);
            const leftPercent = (left / containerWidth) * 100;
            data.current.left = leftPercent;
            data.current.isFull = false;
            controlRef.current.style.left = `${leftPercent}%`;
        }
        if (handler === 'full') {
            const widthPercent = isFull ? data.current.oldWidth : 100;
            const leftPercent = isFull ? data.current.oldLeft : 0;
            data.current.width = widthPercent;
            data.current.left = leftPercent;
            controlRef.current.style.width = `${widthPercent}%`;
            controlRef.current.style.left = `${leftPercent}%`;
        }

        const round = (n: number) => +n.toFixed(3);
        const params: ZoomControlChangeParams = {
            width: round(data.current.width),
            left: round(data.current.left),
            zoom: round(logZoomIn.value(100 - data.current.width)),
        };
        onChange && onChange(params);
    }, [logZoomIn, onChange]);

    useEffect(() => {
        const mousemove = (event: MouseEvent) => {
            data.current.x = event.pageX;
            if (data.current.isDown) {
                handleMove();
            }
        };
        const mouseup = () => {
            data.current.isDown = false;
            document.body.style.userSelect = 'initial';
        };

        document.addEventListener('mousemove', mousemove);
        document.addEventListener('mouseup', mouseup);

        return () => {
            document.removeEventListener('mousemove', mousemove);
            document.removeEventListener('mouseup', mouseup);
        };
    }, [handleMove]);

    const dragStart = useCallback<(handler: ZoomControlHandler, startX: number) => void>((handler, startX) => {
        if (!containerRef.current || !controlRef.current) return;
        document.body.style.userSelect = 'none';
        data.current.handler = handler;
        data.current.isDown = true;
        data.current.startX = startX;
        data.current.containerRect = containerRef.current.getBoundingClientRect();
        data.current.controlRect = controlRef.current.getBoundingClientRect();
    }, []);

    const handleLeftHandleMouseDown = useCallback<(event: React.MouseEvent<HTMLDivElement>) => void>(
        (event) => {
            event.stopPropagation();
            dragStart('left', event.pageX);
        },
        [dragStart],
    );

    const handleRightHandleMouseDown = useCallback<(event: React.MouseEvent<HTMLDivElement>) => void>(
        (event) => {
            event.stopPropagation();
            dragStart('right', event.pageX);
        },
        [dragStart],
    );

    const handleControlMouseDown = useCallback<(event: React.MouseEvent<HTMLDivElement>) => void>(
        (event) => {
            event.stopPropagation();
            dragStart('control', event.pageX);
        },
        [dragStart],
    );

    const handleControlDoubleClick = useCallback<(event: React.MouseEvent<HTMLDivElement>) => void>(
        (event) => {
            event.stopPropagation();
            if (!containerRef.current || !controlRef.current) return;
            data.current.handler = 'full';
            if (!data.current.isFull) {
                data.current.oldWidth = data.current.width;
                data.current.oldLeft = data.current.left;
            }
            handleMove();
            data.current.isFull = !data.current.isFull;
        },
        [handleMove],
    );

    return {
        containerRef,
        controlRef,
        handleControlMouseDown,
        handleControlDoubleClick,
        handleLeftHandleMouseDown,
        handleRightHandleMouseDown,
    };
};
