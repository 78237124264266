import styled from 'styled-components';
import { Button, Checkbox } from '@bynder/design-system';

export const FileFormatButton = styled(Button)`
    justify-content: space-between;
`;

export const CleanCheckbox = styled(Checkbox)`
    & div:before {
        border: none !important;
    }
`;
