import { useState, useRef, useEffect, useCallback } from 'react';

export default function useDebouncedState<T>(
    initialValue: T,
    delay: number,
): { state: T; debouncedState: T; setState: (value: T) => void } {
    const [state, setState] = useState<T>(initialValue);
    const [debouncedState, setDebouncedState] = useState<T>(initialValue);
    const timeoutIdRef = useRef<number>(-1);

    const setStates = useCallback(
        (value: T) => {
            setState(value);
            clearTimeout(timeoutIdRef.current);
            timeoutIdRef.current = window.setTimeout(() => {
                setDebouncedState(value);
            }, delay);
        },
        [delay],
    );

    useEffect(() => {
        return () => {
            clearTimeout(timeoutIdRef.current);
        };
    }, [setStates]);

    return { state, debouncedState, setState: setStates };
}
