import React from 'react';
import { Template } from '@bynder-studio/render-core';
import { Layers } from '../editorComponents/layers/Layers';
import { flattenTree } from '../helpers/elementtree';
import { elementHasAnimations } from '../timeline/tracks/track-renderer';
import { PresetItemsContainer } from './PresetModal.styled';

const PresetItems = ({ preset }: { preset: Template }) => {
    const elements = Object.values(preset.elements)[0]?.children;

    const layers = Object.values(flattenTree(elements, true)).map((el: any) => ({
        id: el.id,
        type: el.type,
        name: el.name,
        icons: elementHasAnimations(el) ? ['animation'] : [],
    }));

    return (
        <PresetItemsContainer>
            <Layers layers={layers} elements={elements} />
        </PresetItemsContainer>
    );
};

export default PresetItems;
