import React, { useContext, useEffect } from 'react';
import { isVariationInvalid } from 'packages/pages/design/sidebar/variations/utils';
import { GridContainer } from './List.styled';
import VariationCard from './VariationCard';
import { VariationsThumbnailsContext } from 'packages/pages/design/VariationsContext/VariationsThumbnailsProvider';
import { variationsThumbnailsStore } from 'packages/pages/design/VariationsContext/variationsThumbnailsStore';

const VariationGrid = ({ items, pageIndex, aspectRatio }) => {
    const { variationThumbnailRenderer } = useContext(VariationsThumbnailsContext);

    useEffect(() => {
        variationThumbnailRenderer.getVariationIds().forEach((variationId) => {
            if (variationsThumbnailsStore.isThumbnailsReady(variationId, pageIndex, 300, 300)) {
                return;
            }
            variationThumbnailRenderer.render(variationId, pageIndex, 300, 300);
        });
    }, [variationThumbnailRenderer, pageIndex]);

    return (
        <GridContainer>
            {items.map((item) => (
                <VariationCard
                    key={item.variationId}
                    isInvalid={isVariationInvalid(item)}
                    {...item}
                    pageIndex={pageIndex}
                    aspectRatio={aspectRatio}
                />
            ))}
        </GridContainer>
    );
};

export default VariationGrid;
