import React, { useCallback } from 'react';
import { Dropdown, Form } from '@bynder/design-system';
import { FORMAT, LOADING } from '~/helpers/textConstants';
import { ExportDestinationContainer, FullWidthButton } from '../Modal/ExportModal.styled';

const SelectFormat = ({ renditionProfiles, isLoading, selectedProfile, onChange, isDisabled }) => {
    const onItemClick = useCallback(
        (profile) => () => {
            onChange?.(profile);
        },
        [onChange],
    );

    return (
        <ExportDestinationContainer>
            <Form>
                <Form.Group>
                    <Form.Label>{FORMAT}</Form.Label>
                    <Dropdown
                        trigger={({ isOpen, ...triggerProps }) => (
                            <FullWidthButton
                                isPressed={isOpen}
                                isDisabled={isLoading || isDisabled}
                                rightIcon={<Dropdown.Arrow />}
                                {...triggerProps}
                                isFullWidth
                            >
                                <Dropdown.SelectButtonText>
                                    {isLoading ? LOADING : selectedProfile?.displayName}
                                </Dropdown.SelectButtonText>
                            </FullWidthButton>
                        )}
                        position="bottom"
                    >
                        {renditionProfiles.map((p) => (
                            <Dropdown.Item key={p.id} onClick={onItemClick(p)} isChecked={p.id === selectedProfile?.id}>
                                {p.displayName}
                            </Dropdown.Item>
                        ))}
                    </Dropdown>
                </Form.Group>
            </Form>
        </ExportDestinationContainer>
    );
};

export default SelectFormat;
