import { Dimension, Position } from '@bynder-studio/render-core';
import BoundingBox from './BoundingBox';

class HoverBox extends BoundingBox {
    constructor(
        position: Position,
        dimension: Dimension,
        rotation: number,
        checkIsElementIsMask: (elementId: number) => boolean,
        elementId = null,
    ) {
        super(position, dimension, rotation, checkIsElementIsMask, elementId);
    }

    isMouseOverBox(mousePosition: Position) {
        return super.isPointInBox(mousePosition);
    }
}

export default HoverBox;
