import styled from 'styled-components';
import { token } from '@bynder/design-system';
import { PageBody } from 'packages/pages/Page.styled';
import Filters from 'packages/pages/components/filters';

export const ModalPageBody = styled(PageBody)`
    padding: ${token.spacing6} ${token.spacing2};
`;

export const ModalFilters = styled(Filters)`
    padding: ${token.spacing4} ${token.spacing2};
`;
