import React, { useState } from 'react';
import type { BaseMultiPageModel, TextElement } from '@bynder-studio/render-core';
import { ElementStylesModal } from './ElementStylesModal';

export const useElementStyleModal = ({
    creativeModel,
    selectedElement,
}: {
    creativeModel: BaseMultiPageModel;
    selectedElement: TextElement;
}) => {
    const [isElementStylesModalOpen, setElementStylesModalOpen] = useState(false);
    const [defaultTab, setDefaultTab] = useState<'textStyles' | 'colors'>('textStyles');

    const onClose = () => {
        setElementStylesModalOpen(false);
    };

    const openElementTextStylesModal = () => {
        setDefaultTab('textStyles');
        setElementStylesModalOpen(true);
    };

    const openElementColorStylesModal = () => {
        setDefaultTab('colors');
        setElementStylesModalOpen(true);
    };

    const renderElementStyleModal = () => (
        <ElementStylesModal
            isOpen={isElementStylesModalOpen}
            defaultTab={defaultTab}
            onClose={onClose}
            creativeModel={creativeModel}
            element={selectedElement}
        />
    );

    return {
        openElementTextStylesModal,
        openElementColorStylesModal,
        renderElementStyleModal,
    };
};
