import { CreativeTypes } from '@bynder-studio/render-core';

export const envVar = {
    get imageMaxDimensions() {
        return IMAGE_MAX_DIMENSIONS || 8192;
    },
    get videoMaxDimensions() {
        return VIDEO_MAX_DIMENSIONS || 4096;
    },
    get imageMaxCharacters() {
        return IMAGE_MAX_CHARACTERS || 2000;
    },
    get videoMaxCharacters() {
        return VIDEO_MAX_CHARACTERS || 450;
    },
    designMaxDimensions(creativeType: CreativeTypes) {
        return creativeType === CreativeTypes.VIDEO ? VIDEO_MAX_DIMENSIONS || 4096 : IMAGE_MAX_DIMENSIONS || 8192;
    },
    designMaxCharacters(creativeType: CreativeTypes) {
        return creativeType === CreativeTypes.VIDEO ? VIDEO_MAX_CHARACTERS || 2000 : IMAGE_MAX_CHARACTERS || 450;
    },
};
