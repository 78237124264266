import React, { PureComponent } from 'react';
import { includes } from 'rambda';
import {
    IconBuildShape,
    IconFolder,
    IconFolderMask,
    IconHideSmall,
    IconImage,
    IconImageMask,
    IconMaskAlt3,
    IconShowSmall,
    IconTextField,
    IconTextMask,
    IconCaretRight,
} from '@bynder/icons';
import { Thumbnail } from '@bynder/design-system';
import { ElementTypes } from '@bynder-studio/render-core';
import Editable from '../../../editable/Editable';
import { elementSortFn } from '../../helpers/elementtree';
import { EditorIcon, EditorIconLarge, LayersListItem } from './Layer.styled';

const generateIcon = (layerElement) => {
    let icon = null;

    const isElementMasked = layerElement?.properties?.mask !== null;

    if (layerElement?.type === ElementTypes.TEXT) {
        icon = isElementMasked ? <IconTextMask /> : <IconTextField />;
    }

    if (layerElement?.type === ElementTypes.IMAGE) {
        if (isElementMasked) {
            icon = <IconImageMask />;
        } else if (layerElement?.src) {
            icon = (
                <Thumbnail imageUrl={layerElement?.src} style={{ width: '24px', height: '24px', objectFit: 'cover' }} />
            );
        } else {
            icon = <IconImage />;
        }
    }

    if (layerElement?.type === ElementTypes.GROUP) {
        icon = isElementMasked ? <IconFolderMask /> : <IconFolder />;
    }

    if (layerElement?.type === ElementTypes.SHAPE) {
        icon = isElementMasked ? <IconMaskAlt3 /> : <IconBuildShape />;
    }

    return icon;
};

class Layer extends PureComponent {
    state = {
        editable: false,
    };

    toggleEditing(editable) {
        this.setState({
            editable,
        });
    }

    changeName(id, name) {
        this.props.changeElementName(id, name);
        this.setState({
            editable: false,
        });
    }

    render() {
        const { layer, enableTools, depth, selectedElementIds = [], expandedGroupIds } = this.props;
        const { toggleElementVisibility, onSelect = () => {}, toggleGroupExpand } = this.props;
        const { name, type, id, disabled, icons = [] } = layer;
        const { hidden, renderOrder } = layer.properties;
        const { editable } = this.state;

        const active = selectedElementIds.includes(layer.id);
        const isGroup = type === 'GROUP';
        const isExpanded = isGroup && includes(layer.id, expandedGroupIds);

        const style = {
            paddingLeft: 5 + depth * 25,
            pointerEvents: layer?.children?.length ? 'all' : 'none',
        };

        const toggleExpand = (evt, layerId) => {
            evt.stopPropagation();
            isGroup && toggleGroupExpand(layerId);
        };

        const activeClass = active ? 'layers__list-item--active' : '';
        const invalidClass = layer.invalid ? 'layers__list-item--invalid' : '';
        const disabledClass = layer.disabled ? 'layers__list-item--disabled font-italic' : '';

        return (
            <div>
                <LayersListItem
                    className={`${activeClass} ${invalidClass} ${disabledClass}`}
                    style={style}
                    onClick={(e) => {
                        if (!layer.disabled) {
                            onSelect(layer.id);
                            toggleExpand(e, layer.id);
                        }
                    }}
                >
                    <div
                        className="layers__expand-container horizontal-center"
                        onClick={(evt) => toggleExpand(evt, layer.id)}
                    >
                        {isGroup && (
                            <div className={`layers__icon-expand ${isExpanded ? 'layers__expanded' : ''}`}>
                                <IconCaretRight />
                            </div>
                        )}
                    </div>
                    <div className="layers__type-icon">
                        <EditorIcon>{generateIcon(layer)}</EditorIcon>
                    </div>
                    <div className="layers__element-title text-truncate">
                        {enableTools && !disabled && active ? (
                            <Editable
                                value={name}
                                editable={editable}
                                onToggle={(editable) => this.toggleEditing(editable)}
                                onConfirm={(value) => this.changeName(id, value)}
                                confirmBeforeUnmount={true}
                                hideIcon={true}
                                useCapture={true}
                                className="text-truncate"
                            />
                        ) : (
                            name
                        )}
                    </div>
                    {toggleElementVisibility && (
                        <div className="layers__visibility-toggle">
                            <button
                                className="btn btn-link text-inherit p-0"
                                onClick={() => toggleElementVisibility(id)}
                            >
                                <EditorIconLarge>{hidden ? <IconHideSmall /> : <IconShowSmall />}</EditorIconLarge>
                            </button>
                        </div>
                    )}
                    {/* I don't see any use case for this */}
                    {/* {icons.map((icon, i) => (
                        <div key={i} className={'editor__icon'}>
                            <Icon icon={icon} />
                        </div>
                    ))} */}
                </LayersListItem>
                {isExpanded &&
                    Object.values(layer.children)
                        .sort(elementSortFn())
                        .map((child) => <Layer {...this.props} key={child.id} depth={depth + 1} layer={child} />)}
            </div>
        );
    }
}
export default Layer;
