import React from 'react';
import { range } from 'rambda';
import { TimelineTrack } from './Track.styled';

export const Track = ({ track }) => {
    return (
        <TimelineTrack>
            <div className="timeline__track-name">
                <span>{track.name}</span>
            </div>

            <div className="timeline__subtracks">
                {range(0, track.subTracks).map((i) => (
                    <div key={i} className="timeline__subtrack">
                        <div className="timeline__subtrack-add_zone" />
                    </div>
                ))}
            </div>
        </TimelineTrack>
    );
};
