import styled from 'styled-components';
import { List, token } from '@bynder/design-system';

export const MaskContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > :first-child {
        margin-right: ${token.spacing4};
        & > button > span > span {
            max-width: 114px;
        }
    }
`;

export const MaskModeContainer = styled.div`
    display: flex;
    margin-left: -1px;

    & > div {
        width: 100%;

        & span {
            display: flex;
            flex-direction: row;
        }
    }
    & button {
        margin-right: 1px;
        margin-left: 1px;
    }
`;

export const StyledListItem = styled(List.Item)`
    & > div:nth-child(2) {
        max-width: 140px;
    }
`;
