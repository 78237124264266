import styled from 'styled-components';
import { Card, Flex, textStyles, token } from '@bynder/design-system';

export const Name = styled.span`
    ${textStyles.uiTextM};
    overflow: hidden;
    color: ${token.gray800} !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`;

export const Created = styled.span`
    ${textStyles.uiTextS};
    color: ${token.gray600} !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`;

export const VariationItemContainer = styled(Flex)`
    & > :first-child > :first-child {
        flex-shrink: 0;
    }
`;

export const VariationThumbnailWrapper = styled.div`
    position: relative;
`;

export const VariationItemCard = styled(Card)`
    --card-frame-overlay-color: ${(props) => (props.isDisabled ? token.gray100a : 'none')};
`;

export const VariationItemData = styled(Flex)`
    overflow: hidden;
`;
