import React from 'react';
import { Flex } from '@bynder/design-system';

import useDesign from 'packages/pages/design/hooks/useDesign';
import { TYPE_AND_SIZE_DOT_SEPARATOR } from '~/helpers/textConstants';
import TabIcon from '../components/TabIcon';

import {
    ExportItemSubtitle,
    ExportItemSubtitleSeparator,
    ExportItemThumbnail,
    ExportItemTitle,
    ExportXIcon,
} from './AllExports.styled';

type Props = {
    name: string;
    count: number;
    statusText: string;
    jobType: string;
    isFailed?: boolean;
};

const ExportItemSubtitleBar = ({ name, count, statusText, jobType, isFailed }: Props) => {
    const { creativeType } = useDesign();

    return (
        <div>
            <Flex alignItems="center">
                <ExportItemThumbnail id={jobType}>
                    <TabIcon tab={jobType} />
                    {isFailed && <ExportXIcon size="16" />}
                </ExportItemThumbnail>
                <Flex direction="column">
                    <ExportItemTitle>{name}</ExportItemTitle>
                    <ExportItemSubtitle>
                        {count} {creativeType.toLowerCase()}
                        {count > 1 ? 's' : ''}
                        <ExportItemSubtitleSeparator>{TYPE_AND_SIZE_DOT_SEPARATOR}</ExportItemSubtitleSeparator>
                        {statusText}
                    </ExportItemSubtitle>
                </Flex>
            </Flex>
        </div>
    );
};

ExportItemSubtitleBar.defaultProps = {
    isFailed: false,
};

export default ExportItemSubtitleBar;
