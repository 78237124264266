import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const GroupTop = styled.div`
    padding-top: ${token.spacing3};
`;

export const SliderWrapper = styled.div`
    width: 100%;
    margin-right: ${token.spacing3};
`;

export const FontIconWrapper = styled.div`
    min-width: 24px;
    margin-right: ${token.spacing3};
`;

export const InputWrapper = styled.div`
    min-width: 80px;
`;

export const LimitWrapper = styled.div`
    position: relative;
`;
