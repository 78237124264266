import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoriesState } from 'packages/store/categories/categories.selectors';
import { getTemplates } from 'packages/store/templates/templates.selectors';
import { customerIdSelector } from 'packages/store/user/user.selectors';
import { CollectionSubType, CollectionType } from 'packages/store/collections/types';
import { fetchCategories, fetchCategoriesBySearchQuery } from 'packages/store/categories/categories.actions';
import { fetchTemplates, fetchTemplatesBySearchQuery } from '~/store/templates/templates.actions';
import debounce from '~/helpers/debounce';
import useQueryParams from 'packages/hooks/useQueryParams';
import { MediaFilterType } from '../filters/components/MediaType/types';
import { OrderFilterType, SortingFilterType } from '../filters/components/Ordering/types';
import { OwnershipFilterType } from '../filters/components/Ownership/types';

const type: CollectionType = 'CREATIVE';
const subType: CollectionSubType = 'TEMPLATE';

const useFilters = () => {
    // if you want to predefine filters value on page load
    // create the filters state here and pass a value as a prop directly to filters
    const dispatch = useDispatch();
    const { setQueryParam } = useQueryParams();

    const { fetchOptions: categoriesFetchOptions } = useSelector(getCategoriesState);
    const { fetchOptions: templatesFetchOptions } = useSelector(getTemplates);
    const currentCompany = useSelector(customerIdSelector);

    const collectionId = templatesFetchOptions.collectionId;

    const onSearch = useMemo(
        () =>
            debounce(
                (newSearchValue: string) => {
                    // todo: add unsubscribe from ws for both actions
                    dispatch(fetchCategoriesBySearchQuery(newSearchValue, type, subType, currentCompany));
                    dispatch(fetchTemplatesBySearchQuery(newSearchValue));

                    setQueryParam('search', newSearchValue);
                },
                1000,
                false,
            ),
        [collectionId, dispatch],
    );

    const onMediaTypeSelect = (mediaType: MediaFilterType) => {
        const templatesNewOptions = { ...templatesFetchOptions };

        if (mediaType === MediaFilterType.ANY) {
            templatesNewOptions.creativeType = undefined;
        } else {
            templatesNewOptions.creativeType = mediaType;
        }

        dispatch(
            fetchTemplates({
                newOptions: templatesNewOptions,
                setSelected: false,
                fetchFromStart: true,
            }),
        );

        setQueryParam('typeTemplate', mediaType.toLowerCase());
    };

    const onSort = (sortBy: SortingFilterType, sortOrder: OrderFilterType) => {
        const categoriesNewOptions = { ...categoriesFetchOptions };
        categoriesNewOptions.sort = [sortBy, sortOrder];
        const templatesNewOptions = { ...templatesFetchOptions };
        templatesNewOptions.sort = [sortBy, sortOrder];

        dispatch(fetchCategories({ ...categoriesNewOptions, type, subType }, currentCompany, true));
        dispatch(
            fetchTemplates({
                newOptions: templatesNewOptions,
                setSelected: false,
                fetchFromStart: true,
            }),
        );

        setQueryParam('sortedBy', sortBy);
        setQueryParam('sortOrder', sortOrder);
    };

    const onOwnershipSelect = (ownershipSource: OwnershipFilterType) => {
        const categoriesNewOptions = { ...categoriesFetchOptions };
        categoriesNewOptions.ownershipSource = ownershipSource;
        const templatesNewOptions = { ...templatesFetchOptions };
        templatesNewOptions.ownershipSource = ownershipSource;

        dispatch(fetchCategories({ ...categoriesNewOptions, type, subType }, currentCompany, true));
        dispatch(
            fetchTemplates({
                newOptions: templatesNewOptions,
                setSelected: false,
                fetchFromStart: true,
            }),
        );

        setQueryParam('ownership', ownershipSource.toLowerCase());
    };

    return { onSearch, onSort, onOwnershipSelect, onMediaTypeSelect };
};

export default useFilters;
