import { ColorParams, DEFAULT_BG_TEXT_COLOR } from './Color';

export class TextBackground {
    public readonly state: 'ENABLED' | 'DISABLED';

    public readonly cornerRadius: number;

    public readonly color: ColorParams;

    public readonly spacingTop: number;

    public readonly spacingBottom: number;

    public readonly spacingLeft: number;

    public readonly spacingRight: number;

    constructor({ state, cornerRadius, color, spacingTop, spacingBottom, spacingLeft, spacingRight }) {
        this.state = state ?? 'ENABLED';
        this.cornerRadius = cornerRadius;
        this.color = color;
        this.spacingTop = spacingTop;
        this.spacingBottom = spacingBottom;
        this.spacingLeft = spacingLeft;
        this.spacingRight = spacingRight;
    }

    isActive() {
        return this.state === 'ENABLED';
    }

    toObject() {
        if (!this.isActive()) {
            return null;
        }

        return {
            cornerRadius: this.cornerRadius,
            color: this.color,
            spacingTop: this.spacingTop,
            spacingBottom: this.spacingBottom,
            spacingLeft: this.spacingLeft,
            spacingRight: this.spacingRight,
        };
    }
}

export const defaultTextBg = new TextBackground({
    state: 'ENABLED',
    color: DEFAULT_BG_TEXT_COLOR,
    cornerRadius: 0,
    spacingLeft: 10,
    spacingTop: 10,
    spacingRight: 10,
    spacingBottom: 10,
});
