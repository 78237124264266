type Options = {
    minpos: number;
    maxpos: number;
    minval: number;
    maxval: number;
};

export class LogSlider {
    private readonly minpos: number;
    private readonly maxpos: number;
    private readonly minval: number;
    private readonly maxval: number;
    private readonly scale: number;

    constructor(options: Partial<Options> = {}) {
        this.minpos = options?.minpos || 0;
        this.maxpos = options?.maxpos || 100;
        this.minval = Math.log(options?.minval || 1);
        this.maxval = Math.log(options?.maxval || 100000);
        this.scale = (this.maxval - this.minval) / (this.maxpos - this.minpos);
    }

    // Calculate value from a slider position
    value(position: number) {
        return Math.exp((position - this.minpos) * this.scale + this.minval);
    }

    // Calculate slider position from a value
    position(value: number) {
        return this.minpos + (Math.log(value) - this.minval) / this.scale;
    }
}
