import { AnimationTypes } from '../../Enums/AnimationTypes';
import KeyFrameHelper, { KeyFrame } from '../../Helpers/KeyFrames';
import { BaseAnimation, BaseAnimationConfig } from './BaseAnimation';
import { BaseVisualElement } from '../Elements/BaseVisualElement';
import { BaseCompElement } from '../CompModels/Elements/BaseCompElement';

class MoveAnimation extends BaseAnimation {
    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        // note that frames can contain fractions, so we cannot just check for 'frameIndex >= this.startFrame + this.duration'
        if (frameIndex > this.startFrame + this.duration - 1) {
            // Apply last animation frame if we are passed the animation
            frameIndex = this.startFrame + this.duration - 1;
        } else if (!this.containsFrame(frameIndex) || this.duration <= 1) {
            return compEl;
        }

        const horizontalPosition = this.config.horizontalPosition;
        const verticalPosition = this.config.verticalPosition;
        const currentPosition = compEl.getAbsBoxPosition();
        const newX = this.timingFn(
            frameIndex - this.startFrame,
            currentPosition.getX(),
            horizontalPosition - currentPosition.getX(),
            this.duration - 1,
        );
        const newY = this.timingFn(
            frameIndex - this.startFrame,
            currentPosition.getY(),
            verticalPosition - currentPosition.getY(),
            this.duration - 1,
        );
        compEl.boundingBox.position.setX(compEl.boundingBox.position.getX() + -currentPosition.getX() + newX);
        compEl.boundingBox.position.setY(compEl.boundingBox.position.getY() + -currentPosition.getY() + newY);
        // set motion blur if enabled
        compEl.motionBlur = compEl.motionBlur || (this.config.motionBlur ?? false);

        return compEl;
    }
}

export class RotateAnimation extends BaseAnimation {
    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        // note that frames can contain fractions, so we cannot just check for 'frameIndex >= this.startFrame + this.duration'
        if (frameIndex > this.startFrame + this.duration - 1) {
            // Apply last animation frame if we are passed the animation
            frameIndex = this.startFrame + this.duration - 1;
        } else if (!this.containsFrame(frameIndex) || this.duration <= 1) {
            return compEl;
        }

        const rotations = this.config.rotations;
        const angle = this.config.angle;
        const totalDegrees = rotations * Math.sign(angle || 1) * 360 + angle;
        const newRotation = this.timingFn(frameIndex - this.startFrame, 0, totalDegrees, this.duration - 1);
        // note that the element can already have a static rotation, so we use it as an offset)
        compEl.rotation = (compEl.rotation + newRotation) % 360.0;
        // set motion blur if enabled
        compEl.motionBlur = compEl.motionBlur || (this.config.motionBlur ?? false);

        return compEl;
    }
}

class ScaleAnimation extends BaseAnimation {
    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        // note that frames can contain fractions, so we cannot just check for 'frameIndex >= this.startFrame + this.duration'
        if (frameIndex > this.startFrame + this.duration - 1) {
            // Apply last animation frame if we are passed the animation
            frameIndex = this.startFrame + this.duration - 1;
        } else if (!this.containsFrame(frameIndex) || this.duration <= 1) {
            return compEl;
        }

        const scaleFrom = compEl.scale;
        const scaleTo = this.config.scale;
        const newScale = this.timingFn(frameIndex - this.startFrame, scaleFrom, scaleTo - scaleFrom, this.duration - 1);

        compEl.scale = newScale;
        compEl.motionBlur = compEl.motionBlur || (this.config.motionBlur ?? false);

        return compEl;
    }
}

class BlinkAnimation extends BaseAnimation {
    // For each blink we fade to 0.0 opacity and back to 1.0 opacity
    // The timing curve is applied to each blink individually
    // All blinks are directly connected to each other (no delay between blinks)
    blinkDuration!: number;

    constructor(startFrame: number, duration: number, config: BaseAnimationConfig, el: BaseVisualElement) {
        super(startFrame, duration, config, el);
        this.blinkDuration = (this.duration - 1) / this.config.blinks;
    }

    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        // Since blinks always end up in the start state, no need keep last frame of the animation
        if (!this.containsFrame(frameIndex) || this.duration <= 1) {
            return compEl;
        }

        const elOpacity = compEl.opacity;
        const keyFrameHelper = new KeyFrameHelper([
            new KeyFrame(0.0, elOpacity),
            new KeyFrame(0.5, 0.0),
            new KeyFrame(1.0, elOpacity),
        ]);
        // get percentage of the animation for the current blink
        const blinkStart = (frameIndex - this.startFrame) % this.blinkDuration;
        const p = this.timingFn(blinkStart, 0, 1, this.blinkDuration); // get distance percentage

        // apply percentage to relative keyframes
        const newOpacity = keyFrameHelper.getValueLinear(p);
        compEl.opacity = newOpacity;

        return compEl;
    }
}

class PopAnimation extends BaseAnimation {
    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        // Since blinks always end up in the start state, no need keep last frame of the animation
        if (!this.containsFrame(frameIndex) || this.duration <= 1) {
            return compEl;
        }

        const configScale = this.config.scale;
        const elScale = compEl.scale;
        const keyFrameHelper = new KeyFrameHelper([
            new KeyFrame(0.0, elScale),
            new KeyFrame(0.5, configScale),
            new KeyFrame(1.0, elScale),
        ]);
        // get percentage of the animation finished
        const p = this.timingFn(frameIndex - this.startFrame, 0, 1, this.duration - 1);
        // apply percentage to relative keyframes
        const newScale = keyFrameHelper.getValueLinear(p);
        compEl.scale = newScale;
        // set motion blur if enabled
        compEl.motionBlur = compEl.motionBlur || (this.config.motionBlur ?? false);

        return compEl;
    }
}

class CornerPinAnimation extends BaseAnimation {
    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        // set motion blur if enabled
        compEl.motionBlur = compEl.motionBlur || (this.config.motionBlur ?? false);

        return compEl;
    }

    setProperties(
        startFrame: number,
        duration: number,
        config: BaseAnimationConfig,
        el: BaseVisualElement | null = null,
    ) {
        this.config = config;

        if (el) {
            this.el = el;
        }

        this.startFrame = this.el.startFrame;
        this.duration = this.el.duration;
    }

    toObject() {
        const { startFrame, duration, ...config } = this.config;

        return config;
    }
}

class DataImportAnimation extends BaseAnimation {
    constructor(startFrame: number, duration: number, config: BaseAnimationConfig, el: BaseVisualElement) {
        // startFrame and duration is not included in the data import animation, so calculate it here
        startFrame = el.startFrame;
        duration = (config.data || []).length;
        super(startFrame, duration, config, el);
    }

    updateCompEl(frameIndex: number, compEl: BaseCompElement) {
        if (!this.containsFrame(frameIndex) || this.duration <= 1) {
            return compEl;
        }

        // set motion blur if enabled
        compEl.motionBlur = compEl.motionBlur || (this.config.motionBlur ?? false);
        // TODO: rounding here will undo motionblur open and close position. Interpolate to allow motion blur.
        const dataFrame = Math.abs(Math.round(frameIndex - this.startFrame));
        compEl.boundingBox.position.x += this.config.data[dataFrame].horizontalPositionOffset;
        compEl.boundingBox.position.y += this.config.data[dataFrame].verticalPositionOffset;

        return compEl;
    }
}

class EmptyAnimation extends BaseAnimation {
    updateCompEl(frameIndex: number, compEl: BaseCompElement): BaseCompElement {
        return compEl;
    }

    setProperties(
        startFrame: number,
        duration: number,
        config: BaseAnimationConfig,
        el: BaseVisualElement | null = null,
    ) {
        this.startFrame = startFrame;
        this.duration = duration;
        this.config = config;

        if (el) {
            this.el = el;
        }
    }
} // ------------------------------------------------------------------------------

export const createAnimation = (config: BaseAnimationConfig, el: BaseVisualElement) => {
    if (config) {
        const startFrame = config.startFrame;
        const duration = config.duration;

        switch (config.type) {
            case AnimationTypes.MOVE:
                return new MoveAnimation(startFrame, duration, config, el);

            case AnimationTypes.ROTATE:
                return new RotateAnimation(startFrame, duration, config, el);

            case AnimationTypes.SCALE:
                return new ScaleAnimation(startFrame, duration, config, el);

            case AnimationTypes.BLINK:
                return new BlinkAnimation(startFrame, duration, config, el);

            case AnimationTypes.POP:
                return new PopAnimation(startFrame, duration, config, el);

            case AnimationTypes.CORNER_PIN:
                return new CornerPinAnimation(startFrame, duration, config, el);

            case AnimationTypes.DATA_IMPORT:
                return new DataImportAnimation(startFrame, duration, config, el);

            default:
                return new EmptyAnimation(startFrame, duration, config, el);
        }
    }

    return null;
};
