import React from 'react';
import { Form, Input } from '@bynder/design-system';
import { IconLineHeight } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import generateTestId from '~/helpers/testIdHelpers';
import { toPercent, toValue } from '../../utils';
import type { Props } from './types';
import useFontSettingInput from '../Fonts/useFontSettingInput';

const LineHeight = ({ creativeModel, selectedElement, disabled }: Props) => {
    const { translate } = useTranslate();
    const attrs = useFontSettingInput({
        creativeModel,
        selectedElement,
        disabled,
        propName: 'leading',
        autoCorrectKey: 'text_line_height',
        mapDisplayValue: (v) => toPercent(v).toString(),
        mapSubmitValue: toValue,
    });

    return (
        <Form.Group>
            <Input
                {...attrs}
                name={translate('editor.sidebar.shots.line_height.label')}
                aria-label={translate('editor.sidebar.shots.line_height.label')}
                icon={<IconLineHeight />}
                suffix="%"
                {...generateTestId('shots_text_line_height')}
            />
        </Form.Group>
    );
};

export default LineHeight;
