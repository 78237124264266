import React, { useMemo } from 'react';
import { Shot, Template, TemplateElement } from '@bynder-studio/render-core';
import { List } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import ShotItem from 'packages/pages/design/sidebar/shots/ShotItem';
import { flattenTree } from '~/common/editor/helpers/elementtree';
import { convertFrameToTime } from '~/common/editor/timeline/helpers/frame-to-time-converter';
import { compareByRenderOrder, getGlobalEditableElements } from 'packages/pages/design/sidebar/shots/utils';
import { getElementsForShots, getElementsToShowForShot } from '~/common/editor/editor.bloc';
import { isTypeBackgroundOrPosterTime } from '~/common/editor/editorHelper';
import { useReviewStatus } from 'packages/pages/design/sidebar/shots/useReviewStatus';
import ShotItemGlobal from 'packages/pages/design/sidebar/shots/ShotItemGlobal';
import ShotReviewBanner from 'packages/pages/design/sidebar/shots/ShotReviewBanner';

type Props = {
    template: Template;
    currentPageIndex: number;
    elements: TemplateElement;
    shots: Shot[];
    selectedShot: Shot;
    selectedShotIndex: number;
    isToolbarDisabled: boolean;
    isLoading: boolean;
};

const ShotVideoDesign = ({
    template,
    currentPageIndex,
    elements,
    shots,
    selectedShot,
    selectedShotIndex,
    isToolbarDisabled,
    isLoading,
}: Props) => {
    const { translate } = useTranslate();
    const { isDisabledByReview } = useReviewStatus();
    const { globalElements, frameRate } = template.pages[currentPageIndex];
    const globals = useMemo(
        () => (globalElements || []).map((g) => Object.values(elements).find((e: any) => e.id === g.id)),
        [elements, globalElements],
    );

    const shotElements = useMemo((): any => {
        const prevShot = shots[selectedShotIndex - 1];
        const currentShot = shots[selectedShotIndex];

        Object.keys(elements).forEach((key) => {
            const shotElement = shots.find((shot) => shot.elements.find((el) => el.id === Number(key)));

            if (shotElement && shotElement.startFrame && shotElement.duration) {
                elements[key].properties.startFrame = shotElement.startFrame;
                elements[key].properties.duration = shotElement.duration;
            }
        });

        const temp = {};

        if (currentShot) {
            const prevSceneElementsToShow =
                prevShot && Object.values(getElementsToShowForShot(elements, prevShot, false));

            getElementsForShots(elements, currentShot, frameRate, globals, false).forEach((el: any) => {
                let disabled = false;
                const elementInShot = currentShot.elements.find((shotElement) => shotElement.id === el.id);

                if (elementInShot && elementInShot.disabled && prevShot) {
                    const elementInPrevShot = prevShot.elements.find((e) => e.id === el.id);
                    disabled = Boolean(
                        elementInPrevShot &&
                            (elementInPrevShot.disabled || prevSceneElementsToShow.find((e: any) => el.id === e.id)),
                    );
                }

                const { type, properties, validations, name, fileName } = el;
                temp[el.id] = { properties, type, validations, disabled, name, fileName, id: el.id };
            });
        }

        return Object.values(temp)
            .filter((e: any) => !isTypeBackgroundOrPosterTime(e) && !e.disabled)
            .sort(compareByRenderOrder);
    }, [selectedShotIndex, elements, shots]);

    const globalEditableElements = useMemo(
        () => getGlobalEditableElements(flattenTree(elements) as TemplateElement),
        [elements],
    );

    return (
        <List>
            <List.Item
                subtext={
                    <>
                        {convertFrameToTime(selectedShot?.startFrame || 0, frameRate)} -{' '}
                        {convertFrameToTime(selectedShot?.startFrame + selectedShot?.duration, frameRate)}
                    </>
                }
            >
                {translate('design.sidebar.scene.globals.duration.label', {
                    name: selectedShot ? selectedShot.name : '',
                })}
            </List.Item>
            <ShotReviewBanner />
            {globalEditableElements.map((element, idx) => (
                <ShotItemGlobal
                    key={element.id}
                    id={element.id}
                    isDisabled={isDisabledByReview || isToolbarDisabled}
                    isLast={idx === globalEditableElements.length - 1}
                />
            ))}
            {shotElements.map((element, idx) => (
                <ShotItem
                    key={element.id}
                    isLast={shotElements.length === idx + 1}
                    shotElement={element}
                    isToolbarDisabled={isToolbarDisabled}
                    isLoading={isLoading}
                    isDisabledByReview={isDisabledByReview}
                />
            ))}
        </List>
    );
};

export default ShotVideoDesign;
