import { useCallback, useMemo } from 'react';
import useEditor from '~/hooks/useEditor';
import useForceUpdate from '~/hooks/useForceUpdate';
import { collectTopLevelElements, isElementLocked } from '~/common/editor/timeline/timeline-actions/components/utils';
import { MAX_ELEMENT_NAME_LENGTH } from '~/common/editor/helpers';

const useElementGeneralActions = (setSelectedGroups, selectedGroups) => {
    const { creativeModel, manipulationRenderer } = useEditor();
    const selectedElements = manipulationRenderer?.getSelectedElements() || [];
    const forceUpdate = useForceUpdate();
    const isActiveElements = !!selectedElements.length;
    const isActiveGroups = !!selectedGroups?.length;
    const isSelected = isActiveElements && collectTopLevelElements(selectedElements).every(isElementLocked);

    const handleSelection = useCallback(() => {
        const editorElements = creativeModel.getAllElementsRecursively();
        const selectedElements = manipulationRenderer?.getSelectedElements() || [];

        const notSelectedElements = editorElements.filter(
            (el) => !selectedElements.some((selected) => selected.id === el.id),
        );

        collectTopLevelElements(notSelectedElements).forEach((el) => {
            manipulationRenderer.selectElement(el.id, true);
        });
    }, [creativeModel, manipulationRenderer]);

    const handleLock = useCallback(() => {
        if (!isActiveElements) {
            return;
        }

        selectedElements.forEach((element) => {
            creativeModel.updateElement(element.id, { locked: !isSelected });
        });
        forceUpdate();
    }, [isActiveElements, isSelected, selectedElements, creativeModel, forceUpdate]);

    const handleDelete = useCallback(() => {
        if (!isActiveElements) {
            return;
        }

        const editorElements = creativeModel.getAllElementsRecursively();
        const elementIdsToRemove = collectTopLevelElements(selectedElements).map((element) => element.id);
        const elementsToDelete = editorElements.filter((el) => elementIdsToRemove.includes(el.id));
        const remainingElements = editorElements.filter((el) => !elementIdsToRemove.includes(el.id));

        const elementsWithMask = editorElements.filter((el) => el.mask !== null && !elementIdsToRemove.includes(el.id));

        const maskedElementsToUpdate = elementsWithMask.filter((maskedElement) =>
            editorElements.find((el) => el.id === maskedElement.id),
        );

        const detacheddMaskIds = elementsToDelete.filter((el) => el.mask !== null).map((el) => el.mask.elementId);

        const masksToUpdate = remainingElements.filter((el) => detacheddMaskIds.includes(el.id));

        creativeModel.removeElements(elementIdsToRemove);

        if (masksToUpdate) {
            masksToUpdate.forEach((mask) => {
                creativeModel.updateElement(mask.id, {
                    mask: null,
                });
            });
        }

        if (maskedElementsToUpdate) {
            maskedElementsToUpdate.forEach((element) => {
                if (elementIdsToRemove.includes(element.mask.elementId)) {
                    creativeModel.updateElement(element.id, {
                        mask: null,
                    });
                }
            });
        }
    }, [isActiveElements, selectedElements, creativeModel]);

    const handleDuplicate = useCallback(() => {
        if (!isActiveElements) {
            return;
        }

        const duplicatedElements = collectTopLevelElements(selectedElements).map((element) => {
            const duplicatedElement = element.getCopy();
            duplicatedElement.parent = element.parent;
            if (duplicatedElement.name.length + ' (copy)'.length <= MAX_ELEMENT_NAME_LENGTH) {
                duplicatedElement.name += ' (copy)';
            }

            return duplicatedElement;
        });

        creativeModel.addElements(duplicatedElements);
    }, [isActiveElements, selectedElements, creativeModel]);

    const handleCreateGroup = useCallback(() => {
        if (!isActiveElements) {
            return;
        }

        const groupElement = creativeModel.wrapElementsIntoGroup(selectedElements.map((el) => el.id));
        manipulationRenderer.selectElement(groupElement.id);
    }, [isActiveElements, selectedElements, creativeModel, manipulationRenderer]);

    const handleUnGroup = useCallback(() => {
        if (!isActiveGroups) {
            return;
        }

        const elementToSelect = selectedGroups[selectedGroups.length - 1].children.reduce(
            (elA, elB) => ((elA?.renderOrder || 0) > (elB?.renderOrder || 0) ? elA : elB),
            null,
        );

        const editorElements = creativeModel.getAllElementsRecursively();
        const maskedElements = editorElements.filter((el) => el.mask && el.mask.elementId);
        selectedGroups.forEach((group) => {
            creativeModel.unwrapGroupElement(group.id);

            if (maskedElements) {
                maskedElements.forEach((element) => {
                    if (group.id === element.mask.elementId) {
                        creativeModel.updateElement(element.id, {
                            mask: null,
                        });
                    }
                });
            }

            if (group.mask && group.mask.elementId) {
                const sameElementMaskedElements = maskedElements
                    .filter((el) => !el.id === group.id)
                    .filter((el) => el.mask.elementId === group.mask.elementId);

                if (!sameElementMaskedElements.length) {
                    creativeModel.updateElement(group.mask.elementId, {
                        mask: null,
                    });
                }
            }
        });

        if (elementToSelect) {
            manipulationRenderer.selectElement(elementToSelect.id);
        } else {
            setSelectedGroups([]);
        }
    }, [isActiveGroups, selectedGroups, creativeModel, manipulationRenderer]);

    const elementActions = useMemo(
        () => ({
            handleLock,
            handleDelete,
            handleSelection,
            handleDuplicate,
            handleCreateGroup,
            handleUnGroup,
        }),
        [creativeModel, forceUpdate, handleLock],
    );

    return elementActions;
};

export default useElementGeneralActions;
