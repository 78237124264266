import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IconAspectRatioOutline, IconTime } from '@bynder/icons';
import { colorPalettes } from 'packages/store/brandColors/brandColors.selectors';
import features from '~/configs/features';
import AuthorizationHelper from '~/helpers/AuthorizationHelper';
import { isCtrlKey } from '~/helpers/hotKeys';
import useEditor from '~/hooks/useEditor';
import CanvasZoomControls from '~/common/editor/CanvasZoomControls/CanvasZoomControls';
import useEditorTimeframe from '~/hooks/useEditorTimeframe';
import MultiPageDropdown from 'packages/pages/editor/MultiPageDropdown';
import useAccessRights from 'packages/hooks/useAccessRights';
import { Account, ActionStatus, ColorPaletteAction } from 'packages/hooks/useAccessRights/types';
import { syncBrandColors } from 'packages/pages/editor/syncBrandColors';
import { SizeLabel } from './Controls.styled';
import usePageHotkeys from 'packages/pages/usePageHotKeys';

const Controls = ({ template, saved, closePublishModal, save, isInAction, setSaveStatus }) => {
    const { creativeModel, creativeType, exportIntegrations, approvalEnabled, reviewers, manipulationRenderer } =
        useEditor();
    const { palettes } = useSelector(colorPalettes);
    const isFeatureAvailable = AuthorizationHelper.isFeatureAvailable(features.MULTI_SIZE_CREATIVE);
    const { isAccountActionAllowed } = useAccessRights();
    const { duration, frameRate } = useEditorTimeframe(creativeModel, creativeType);
    const [currentSize, setCurrentSize] = useState(creativeModel.getCurrentPageIndex());
    const totalPages = creativeModel.getModels().length;
    const currentPage = creativeModel.getCurrentPageIndex();

    const selectedSizeDimension = useMemo(() => creativeModel.getModels()[currentSize].getDimensions(), [currentSize]);

    const calculateToSecond = duration > 0 && frameRate !== null ? duration / frameRate : 0;

    const enableSave = !isInAction && (!saved || !template.lastPublishDate);
    const isVideoCreative = creativeType === 'VIDEO';

    const handleCtrlSave = useCallback(
        (e) => {
            if (isCtrlKey(e) && (e.code === 'KeyS' || e.keyCode === 83)) {
                e.preventDefault();

                if (enableSave) {
                    save(creativeModel, exportIntegrations, approvalEnabled, reviewers);
                }
            }
        },
        [enableSave, creativeModel, save, exportIntegrations, approvalEnabled, reviewers],
    );

    const isElementSelected = useCallback(() => !!manipulationRenderer?.getSelectedElement(), [manipulationRenderer]);

    const handlePageChangePrevious = useCallback(() => {
        if (isElementSelected()) {
            return;
        }

        const prevPage = currentPage - 1 >= 0 ? currentPage - 1 : currentPage;
        creativeModel.setCurrentPageIndex(prevPage);
    }, [currentPage, isElementSelected]);

    const handlePageChangeNext = useCallback(() => {
        if (isElementSelected()) {
            return;
        }

        const nextPage = currentPage + 1 < totalPages ? currentPage + 1 : currentPage;
        creativeModel.setCurrentPageIndex(nextPage);
    }, [currentPage, isElementSelected]);

    usePageHotkeys({ handlePageChangePrevious, handlePageChangeNext });

    useEffect(() => {
        if (!creativeModel) {
            return;
        }

        const handleSizeChange = (pageIndex) => setCurrentSize(pageIndex);
        creativeModel?.getEventEmitter()?.on('currentPageChange', handleSizeChange);

        return () => {
            creativeModel?.getEventEmitter()?.off('currentPageChange', handleSizeChange);
        };
    }, [creativeModel]);

    useEffect(() => {
        document.addEventListener('keydown', handleCtrlSave);

        return () => {
            document.removeEventListener('keydown', handleCtrlSave);
        };
    }, [enableSave]);

    useEffect(() => {
        if (!creativeModel || !saved) {
            return;
        }

        const onCreativeModelAnyChange = () => setSaveStatus(false);
        creativeModel.on('elementUpdated', onCreativeModelAnyChange);
        creativeModel.on('elementRemoved', onCreativeModelAnyChange);
        creativeModel.on('backgroundColorUpdated', onCreativeModelAnyChange);
        creativeModel.on('posterFrameUpdated', onCreativeModelAnyChange);
        creativeModel.on('globalAudioUpdated', onCreativeModelAnyChange);
        creativeModel.on('durationUpdated', onCreativeModelAnyChange);
        creativeModel.on('pageAdded', onCreativeModelAnyChange);
        creativeModel.on('pageRenamed', onCreativeModelAnyChange);
        creativeModel.on('pageRemoved', onCreativeModelAnyChange);

        return () => {
            creativeModel.off('elementUpdated', onCreativeModelAnyChange);
            creativeModel.off('elementRemoved', onCreativeModelAnyChange);
            creativeModel.off('backgroundColorUpdated', onCreativeModelAnyChange);
            creativeModel.off('posterFrameUpdated', onCreativeModelAnyChange);
            creativeModel.off('globalAudioUpdated', onCreativeModelAnyChange);
            creativeModel.off('durationUpdated', onCreativeModelAnyChange);
            creativeModel.off('pageAdded', onCreativeModelAnyChange);
            creativeModel.off('pageRenamed', onCreativeModelAnyChange);
            creativeModel.off('pageRemoved', onCreativeModelAnyChange);
        };
    }, [creativeModel, saved, setSaveStatus]);

    useEffect(() => {
        if (!isInAction) {
            closePublishModal();
        }
    }, [closePublishModal, isInAction]);

    useEffect(() => {
        if (!creativeModel) {
            return;
        }

        if (
            isAccountActionAllowed(ColorPaletteAction.EDIT_COLOR_PALETTE, Account.COLOR_PALETTES) ===
            ActionStatus.ALLOWED
        ) {
            syncBrandColors(creativeModel, palettes, () =>
                save(creativeModel, exportIntegrations, approvalEnabled, reviewers, true),
            );
        }
    }, [creativeModel, palettes]);

    return (
        <div className="col-auto flex-column d-flex position-relative px-0 zoom-control-wrapper">
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    {isFeatureAvailable && <MultiPageDropdown />}
                    <SizeLabel alignItems="center" className="text-muted">
                        <IconAspectRatioOutline />
                        {`${selectedSizeDimension?.width} × ${selectedSizeDimension?.height}`}
                        {isVideoCreative && (
                            <small className="mx-2">
                                <IconTime size="15" /> {`${calculateToSecond}sec`}
                            </small>
                        )}
                    </SizeLabel>
                </div>

                <div className="d-flex align-items-center editor--controls-right">
                    <CanvasZoomControls />
                </div>
            </div>
        </div>
    );
};

export default Controls;
