import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useEditorSelectedElement from '~/hooks/useEditorSelectedElement';
import useEditor from '~/hooks/useEditor';
import { setSaveStatus } from 'packages/store/creativeEditor/creativeEditor.actions';
import { useGetCollectionById } from 'packages/pages/editor/RightSideMenu/Shots/AssetSource/useGetCollectionById';
import { type Ordering } from 'packages/services/graphql/types';
import { useGetCollections } from './useGetCollections';
import { useInfinityLoading } from './useInfinityLoading';
import { type Collection } from 'packages/services/assetSource/fragments';
import useDebouncedState from 'packages/hooks/useDebouncedState';

const orderBy: Ordering = {
    direction: 'DESC',
    field: 'CREATED_AT',
};

export const useAssetSource = () => {
    const dispatch = useDispatch();
    const containerRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [collectionData, setCollectionData] = useState<Collection | null>(null);
    const { selectedElement } = useEditorSelectedElement();
    const { creativeModel } = useEditor();
    const {
        state: searchTerm,
        debouncedState: debouncedSearchTerm,
        setState: setSearchTerm,
    } = useDebouncedState('', 500);

    const [isCollectionInvalid, setIsCollectionInvalid] = useState(false);
    const { collections, isLoading, loadMore, hasNextPage } = useGetCollections(debouncedSearchTerm, orderBy);
    const { fetchCollectionById } = useGetCollectionById();

    useInfinityLoading({ containerRef, isOpen, isLoading, loadMore, hasNextPage });

    const handleRemoveClick = useCallback(() => {
        dispatch(setSaveStatus());
        setCollectionData(null);
        creativeModel.updateElement(selectedElement.id, {
            virtualData: { ...selectedElement.virtualData, bynderCollectionId: null },
        });
    }, [creativeModel, dispatch, selectedElement.id, selectedElement.virtualData]);

    const handleClick = useCallback(
        (item: Collection) => {
            dispatch(setSaveStatus());
            const param = { virtualData: { ...selectedElement.virtualData, bynderCollectionId: item.id } };
            creativeModel.updateElement(selectedElement.id, param);
        },
        [creativeModel, selectedElement?.id, selectedElement?.virtualData],
    );

    const selectedCollectionId: string = selectedElement?.virtualData?.bynderCollectionId || null;

    useEffect(() => {
        (async () => {
            if (!selectedCollectionId) {
                setIsCollectionInvalid(false);
                setCollectionData(null);

                return;
            }

            const selectedCollection = collections.find((item) => item.id === selectedCollectionId);

            const collection = selectedCollection || (await fetchCollectionById(selectedCollectionId));

            if (!collection) {
                setIsCollectionInvalid(true);
                setCollectionData(null);
            } else {
                setIsCollectionInvalid(false);
                setCollectionData(collection);
            }
        })();
    }, [collections, fetchCollectionById, selectedCollectionId]);

    return {
        containerRef,
        locked: !!selectedElement?.locked,
        isLoading,
        collections,
        chosenAsset: collectionData,
        isCollectionInvalid,
        searchTerm,
        setIsOpen,
        handleRemoveClick,
        handleClick,
        setSearchTerm,
    };
};
