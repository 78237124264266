import styled, { css } from 'styled-components';
import { token } from '@bynder/design-system';

export const LayerItemContainer = styled.div<{ level: number }>`
    max-width: 248px;
    width: 100%;
    padding: 8px 15px;
    background-color: ${token.white};
    margin-block-end: 2px;
    cursor: pointer;
    border-radius: 8px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

    ${({ level }) => {
        const marginLeft = level * 12;
        const maxWidth = `calc(248px - ${level * 12}px)`;
        const width = `calc(100% - ${level * 12}px)`;

        return css`
            margin-left: ${marginLeft}px;
            max-width: ${maxWidth};
            width: ${width};
        `;
    }};

    .layers--item-action-btns {
        margin-inline-start: auto;
        overflow: hidden;
        flex-shrink: 0;
    }

    .layers--item-action-btns .layers--item-btn {
        visibility: hidden;
        opacity: 0;
        border: none;
        height: 24px;
        color: ${token.colorTextDisabled};
    }

    .layers--item-action-btns .layers--item-btn-visible {
        width: 24px;
        visibility: visible;
        opacity: 1;
        color: ${token.colorText};
    }

    .layers--item-action-btns .layers--item-btn:hover {
        color: ${token.colorText};
    }

    .layers--item-content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        line-height: 20px;
        color: ${token.colorTextMuted};
        letter-spacing: 0.01em;
        margin-inline-start: 10px;
    }

    &.layers--item-group > svg {
        fill: ${token.brandPrimary500};
    }

    &.layers--item-group .layers--item-group-expand {
        padding: 16px !important;
        margin: -16px;
        margin-left: -21px;
    }

    &.layers--item-group .layers--item-group-expand .triangle--down {
        display: block;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid ${token.gray400};
        transform: rotate(-90deg);
        margin-inline-end: 10px;
    }

    &.layers--item-group .layers--item-group-expand.active .triangle--down {
        transform: rotate(0);
    }

    > svg {
        fill: ${token.colorText};
        color: ${token.colorText};
        flex-shrink: 0;
    }

    &:hover {
        background-color: ${token.gray25};
    }

    &:hover .layers--item-btn {
        width: 24px;
        visibility: visible;
        opacity: 1;
    }

    &:hover .layers--item-action-btns {
        width: auto;
    }

    &.layers--item-hidden {
        color: ${token.colorTextDisabled};
    }

    &.layers--item-hidden > svg {
        fill: ${token.colorTextDisabled};
    }

    &.layers--item-hidden .layers--item-content {
        color: ${token.colorTextDisabled};
    }

    &.layers--item-selected {
        background-color: ${token.brandPrimary500};
    }

    &.layers--item-selected.layers--item-content-property {
        background-color: ${token.teal500};
    }

    &.layers--item-selected > svg {
        fill: ${token.white};
        color: ${token.white};
    }

    &.layers--item-selected .layers--item-content {
        color: ${token.white};
    }

    &.layers--item-selected .layers--item-btn {
        color: ${token.gray400a};
    }

    &.layers--item-selected .layers--item-btn-visible,
    &.layers--item-selected .layers--item-btn:hover {
        color: ${token.white};
    }

    &.layers--item-selected .triangle--down {
        border-top-color: ${token.white} !important;
    }

    &.layers--item-dragging {
        background-color: ${token.gray25};
    }

    &.layers--item-dragging > * {
        opacity: 0;
    }

    &.layers--item-drag-over {
        box-shadow: 0 0 0 2px ${token.brandPrimary500};
    }

    &.layers--item-drag-overlay {
        box-shadow: ${token.elevationStatic}, ${token.elevation8};
        cursor: grab;
    }
`;
