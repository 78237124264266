import React, { useCallback, useEffect, useState } from 'react';
import { MultiPageVideoModel } from '@bynder-studio/render-core';
import { useShotsShortcuts } from 'packages/hooks/useShotsShortcuts';
import { MINIMUM_SHOT_THUMBNAIL_VALUE_IN_PERCENTAGE } from '~/helpers/constants';
import { type ControlShortcutMode } from 'packages/hooks/usePlaybackShortcuts';
import Shot from './Shot';
import { useShots } from '../../../../hooks/useShot';
import useEditor from '../../../../hooks/useEditor';
import useEditorTimeframe from '../../../../hooks/useEditorTimeframe';
import { ShotsStyled } from './Shots.styled';

type Props = {
    shotsContainerRef: any;
    elementWrapperRef: any;
    controlShortcutMode: ControlShortcutMode;
};

// todo: Shots should be stateless hook and share between all editors
export function Shots({ shotsContainerRef, elementWrapperRef, controlShortcutMode }: Props) {
    const { shots } = useShots();
    const { creativeModel, manipulationRenderer } = useEditor();
    const { duration, timelineDuration } = useEditorTimeframe(creativeModel as MultiPageVideoModel);

    const [selectedShotIndex, setSelectedShotIndex] = useState(0);
    const [isDown, setIsDown] = useState(false);
    const [zoomPercent, setZoomPercent] = useState(100);

    const toShot = useCallback(
        (shotIndex: number) => {
            const normalizedShotIndex = Math.max(0, Math.min(shotIndex, shots.length - 1));
            if (manipulationRenderer.getIsPlaying()) {
                manipulationRenderer.pausePlayback();
                manipulationRenderer.selectShot(normalizedShotIndex);
                manipulationRenderer.startPlayback();
            } else {
                manipulationRenderer.selectShot(normalizedShotIndex);
            }
        },
        [manipulationRenderer],
    );

    const toPrevShot = useCallback(() => {
        toShot(selectedShotIndex - 1);
    }, [toShot, selectedShotIndex]);

    const toNextShot = useCallback(() => {
        if (selectedShotIndex >= shots.length - 1) {
            return;
        }

        toShot(selectedShotIndex + 1);
    }, [toShot, selectedShotIndex, shots.length]);

    useShotsShortcuts({ toPrevShot, toNextShot, controlShortcutMode });

    const handleMouseDown = useCallback(() => {
        setIsDown(true);
    }, []);

    const handleShotThumbnailChange = useCallback(
        (shot, newThumbnailFrame) => {
            creativeModel.updateShot(shot.id, { thumbnailFrame: newThumbnailFrame });

            if (manipulationRenderer) {
                manipulationRenderer.setCurrentFrame(shot.getStartFrame() + newThumbnailFrame);
            }
        },
        [creativeModel, manipulationRenderer],
    );

    const handleClick = useCallback(
        (shot) => {
            if (!isDown) {
                return;
            }

            setIsDown(false);

            if (manipulationRenderer) {
                manipulationRenderer.selectShot(shot.getDisplayOrder());
            }
        },
        [isDown, manipulationRenderer],
    );

    useEffect(() => {
        if (!manipulationRenderer) {
            return;
        }

        const onShotSelected = ({ shot }) => {
            setSelectedShotIndex(shot.getDisplayOrder());
            manipulationRenderer.setCurrentFrame(shot.getStartFrame());
        };

        manipulationRenderer.eventEmitter.on('shotSelected', onShotSelected);

        return () => {
            manipulationRenderer.eventEmitter.off('shotSelected', onShotSelected);
        };
    }, [manipulationRenderer, setSelectedShotIndex]);

    useEffect(() => {
        const mutationObserver = new MutationObserver(() => {
            setZoomPercent(Number(shotsContainerRef.current.style.width.replace('%', '')));
        });

        mutationObserver.observe(shotsContainerRef.current, {
            attributes: true,
            attributeFilter: ['style'],
        });

        return () => mutationObserver.disconnect();
    }, []);
    const extremePercent = (100 * MINIMUM_SHOT_THUMBNAIL_VALUE_IN_PERCENTAGE) / zoomPercent;

    return (
        <ShotsStyled ref={shotsContainerRef}>
            {shots.map((shot, index) => {
                const width =
                    index < shots.length - 1
                        ? (100 * shots[index + 1].startFrame) / duration
                        : (100 * Math.max(timelineDuration, duration)) / duration;

                const percentage = Math.abs(width - (100 * shot.startFrame) / duration);
                const shotStyle = {
                    left: `${(100 * shot.startFrame) / duration}%`,
                    width: `calc(${percentage}% - 1px)`,
                };

                const isShotSizeSmallerThanMinimumValue = percentage < extremePercent;

                return (
                    <Shot
                        key={index}
                        index={index}
                        elementWrapperRef={elementWrapperRef}
                        shotsContainerRef={shotsContainerRef}
                        shotStyle={shotStyle}
                        selected={selectedShotIndex === shot.getDisplayOrder()}
                        onClick={handleClick}
                        onMouseDown={handleMouseDown}
                        onShotThumbnailChange={handleShotThumbnailChange}
                        hideThumbnail={isShotSizeSmallerThanMinimumValue}
                    />
                );
            })}
        </ShotsStyled>
    );
}
