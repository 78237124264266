import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Dropdown } from '@bynder/design-system';
import { IconMore } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { Reviewer } from 'packages/hooks/useVirtualProperties';
import { Template } from 'packages/store/templates/types';
import MaybeTooltip from 'packages/common/MaybeTooltip';
import useAccessRights from 'packages/hooks/useAccessRights';
import useDesignRenameModal from 'packages/pages/designs/useDesignRenameModal';
import useTemplateRenameModal from 'packages/pages/components/templates/useTemplateRenameModal';
import { getCreative } from 'packages/store/creatives/creatives.selectors';
import useEditor from '~/hooks/useEditor';
import features from '~/configs/features';
import generateTestId from '~/helpers/testIdHelpers';
import SaveAsTemplateModal from './SaveAsTemplateModal';
import SaveAsVideoModal from './SaveAsVideoModal';
import { SaveAsVideoNotAcceptableErrorModal } from './SaveAsVideoNotAcceptableErrorModal';

export type Props = {
    template: any;
    isInAction: boolean;
    saved: boolean;
    hasCorruptionReason: string | boolean;
    creativeName: string;
    showSaveAsTemplateModal: any;
    showSaveAsVideoModal: any;
    showSaveAsVideoNotAcceptableErrorModal: boolean;
    save: (creativeModel: any, exportIntegrations: any, approvalEnabled: boolean, reviewers: Reviewer[]) => void;
    handleSaveAsTemplateModal: (isOpen: boolean) => void;
    handleSaveAsVideoModal: (isOpen: boolean) => void;
    saveAsVideoModal: () => (name: string) => void;
    closeSaveAsVideoNotAcceptableErrorModal: () => void;
};

const optionsId = generateTestId('editor_header_options');
const optionRenameId = generateTestId('editor_header_options_rename');
const optionSaveId = generateTestId('editor_header_options_save');
const optionSaveAsTemplateId = generateTestId('editor_header_options_save_as_template');
const optionSaveAsVideoId = generateTestId('editor_header_options_save_as_video');
const optionSettingsId = generateTestId('editor_header_options_settings');

const OptionsDropdown = ({
    template,
    isInAction,
    saved,
    hasCorruptionReason,
    creativeName,
    showSaveAsTemplateModal,
    showSaveAsVideoModal,
    showSaveAsVideoNotAcceptableErrorModal,
    save,
    handleSaveAsTemplateModal,
    handleSaveAsVideoModal,
    saveAsVideoModal,
    closeSaveAsVideoNotAcceptableErrorModal,
}: Props) => {
    const { isPlatformAllowed } = useAccessRights();
    const design = useSelector(getCreative);
    const { translate } = useTranslate();
    const navigate = useNavigate();
    const {
        creativeModel,
        type,
        creativeType,
        exportIntegrations,
        approvalEnabled,
        reviewers,
        manipulationRenderer,
        setGlobalsTab,
    } = useEditor();

    const enableSave = !isInAction && (!saved || !template.lastPublishDate);
    const isSaveAsTemplateDisabled = !!hasCorruptionReason || !template.lastPublishDate;
    const isTemplate = type === 'TEMPLATE';
    const useTemplateDetailsUI = isTemplate;

    const { setRenameModalDesign, renderModalDesignRename } = useDesignRenameModal({
        inEditor: true,
        isTemplate,
    });
    const { setRenameModalTemplate, renderModalTemplateRename } = useTemplateRenameModal({ fetchNew: true });

    const getCorruptionDescription = () => {
        if (hasCorruptionReason) {
            return translate('editor.header.options.save-as-template.missing-assets');
        }

        if (!template.lastPublishDate) {
            return translate('editor.header.options.save-as-template.not-published');
        }

        return false;
    };

    return (
        <>
            <Dropdown
                trigger={({ isOpen, ...triggerProps }) => (
                    <Button
                        variant="clean"
                        icon={<IconMore />}
                        aria-label={translate('editor.header.options')}
                        isPressed={isOpen}
                        {...triggerProps}
                        {...optionsId}
                        {...{ id: 'editor_header_options' }}
                    />
                )}
                position="top-right"
            >
                <Dropdown.Item
                    onClick={() => {
                        if (!isTemplate) {
                            setRenameModalDesign(design || null);
                        } else {
                            setRenameModalTemplate((design as Template) || null);
                        }
                    }}
                    {...optionRenameId}
                    {...{ id: 'editor_header_options_rename' }}
                >
                    {useTemplateDetailsUI
                        ? translate('editor.header.options.editDetails')
                        : translate('editor.header.options.rename')}
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => save(creativeModel, exportIntegrations, approvalEnabled, reviewers)}
                    isDisabled={!enableSave}
                    {...optionSaveId}
                    {...{ id: 'editor_header_options_save' }}
                >
                    {!enableSave
                        ? translate('editor.header.options.save.saved')
                        : translate('editor.header.options.save.unsaved')}
                </Dropdown.Item>

                <Dropdown.Divider />

                {type === 'REGULAR' && (
                    <>
                        <MaybeTooltip content={getCorruptionDescription()} hasTooltip={isSaveAsTemplateDisabled}>
                            <Dropdown.Item
                                onClick={() => handleSaveAsTemplateModal(true)}
                                isDisabled={isSaveAsTemplateDisabled}
                                {...optionSaveAsTemplateId}
                                {...{ id: 'editor_header_options_save_as_template' }}
                            >
                                {translate('editor.header.options.save-as-template')}
                            </Dropdown.Item>
                        </MaybeTooltip>
                        {creativeType === 'IMAGE' && isPlatformAllowed([features.VIDEOS_ENABLED]) && (
                            <Dropdown.Item
                                onClick={() => handleSaveAsVideoModal(true)}
                                {...optionSaveAsVideoId}
                                {...{ id: 'editor_header_options_save_as_video' }}
                            >
                                {translate('editor.header.options.save-as-video')}
                            </Dropdown.Item>
                        )}
                        <Dropdown.Divider />
                    </>
                )}

                <Dropdown.Item
                    onClick={() => {
                        setGlobalsTab('Design');
                        manipulationRenderer.selectElement(null);
                    }}
                    {...optionSettingsId}
                    {...{ id: 'editor_header_options_settings' }}
                >
                    {translate('editor.header.options.settings')}
                </Dropdown.Item>
            </Dropdown>

            <SaveAsTemplateModal
                creativeName={creativeName}
                show={showSaveAsTemplateModal}
                toggle={handleSaveAsTemplateModal}
            />
            {creativeType === 'IMAGE' && isPlatformAllowed([features.VIDEOS_ENABLED]) && (
                <>
                    <SaveAsVideoModal
                        creativeName={creativeName}
                        isOpen={showSaveAsVideoModal}
                        onClose={() => handleSaveAsVideoModal(false)}
                        handleSubmit={saveAsVideoModal()}
                    />
                    <SaveAsVideoNotAcceptableErrorModal
                        isOpen={showSaveAsVideoNotAcceptableErrorModal}
                        onClose={closeSaveAsVideoNotAcceptableErrorModal}
                    />
                </>
            )}
            {renderModalDesignRename()}
            {renderModalTemplateRename()}
        </>
    );
};

export default OptionsDropdown;
