export const calculateAspectRatio = (page: { width: number; height: number }) => {
    const ratio = page.width / page.height;

    const decodeToFraction = (n: number) => {
        let rcp = n % 1 === 0 ? 1 : 1 / (n % 1);
        let denominator = rcp;
        const limit = 10;
        for (
            let i = 0;
            i < limit && Number.isInteger(Math.round(rcp * 10 ** (limit - i)) / 10 ** (limit - i)) !== true;
            i++
        ) {
            rcp = 1 / (rcp % 1);
            denominator *= rcp;
        }
        return [Math.round(n * denominator), Math.round(denominator)];
    };

    return decodeToFraction(ratio).join(':');
};
