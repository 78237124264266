import React, { useMemo } from 'react';
import { Button, Dropdown } from '@bynder/design-system';
import { IconSorting } from '@bynder/icons';
import useQueryParams from 'packages/hooks/useQueryParams';
import useExport from '../hooks/useExport';
import { Translate } from '@bynder/localization';

const options = [
    {
        name: <Translate id="modal.export.sort.options.date" />,
        value: 'updated',
    },
    {
        name: <Translate id="modal.export.sort.options.page" />,
        value: 'displayOrder',
    },
    {
        name: <Translate id="modal.export.sort.options.name" />,
        value: 'name',
    },
];

export const OrderBy = () => {
    const { setQueryParam } = useQueryParams();
    const { orderBy, setOrderBy, groupBy } = useExport();

    const groupByOptions = useMemo(
        () => (groupBy === 'variation' ? [options[0], options[2]] : [options[1], options[2]]),
        [groupBy],
    );

    return (
        <Dropdown
            position="bottom-right"
            trigger={({ isOpen, ...triggerProps }) => (
                <Button variant="clean" isPressed={isOpen} icon={<IconSorting />} {...triggerProps} />
            )}
        >
            {groupByOptions.map((option) => (
                <Dropdown.Item
                    key={option.name}
                    isChecked={option.value === orderBy}
                    onClick={() => {
                        setOrderBy(option.value);
                        setQueryParam('orderBy', option.value);
                    }}
                >
                    {option.name}
                </Dropdown.Item>
            ))}
        </Dropdown>
    );
};
