import ProxyServerConnector from './connectors/ProxyServerConnector';
import { readCookie } from '../helpers/cookie';
import { createQuery as createQueryGeneric } from '../helpers/queryString';

export function createQuery(params) {
    const { type, customerId, ownershipSource, subType, includeEmptyCollection } = params;
    const safeCustomerId = customerId || readCookie('currentCompany');

    return `type=${type}\
${subType ? `&subType=${subType}` : ''}\
${typeof includeEmptyCollection === 'boolean' ? `&includeEmptyCollection=${includeEmptyCollection}` : ''}\
&customerId=${safeCustomerId}\
&ownershipSource=${ownershipSource}\
&${createQueryGeneric(params)}`;
}

class CollectionsService extends ProxyServerConnector {
    gateway: ProxyServerConnector;

    constructor(path = '/collections') {
        super(path);
        this.gateway = new ProxyServerConnector(path, GATEWAY_PREFIX);
    }

    getCollections(query, includeCollectionId) {
        return this.gateway.get(`?${query}${includeCollectionId ? '&includeCollectionId=' + includeCollectionId : ''}`);
    }

    getCollection(id) {
        return this.gateway.get(`/${id}`).then(({ status, json }) => ({ status, collection: json }));
    }

    async addToCollection({ collectionId, customerId, ids, name, type, subType }) {
        let id = collectionId;

        if (!id) {
            const requestData = !subType ? { customerId, name, type } : { customerId, name, type, subType };
            const response = await this.gateway.put('', requestData);

            if (response.status !== 200 || !ids || !ids.length) {
                return response;
            }

            id = response.json.id;
        }

        const requestData = !subType ? { ids, type } : { ids, type, subType };

        return this.gateway.put(`/${id}/items`, requestData);
    }

    removeFromCollection(collectionId, ids, type) {
        return this.gateway.delete(`/${collectionId}/items`, { ids, type });
    }

    deleteCollections(ids) {
        return this.gateway.delete('', { ids });
    }

    renameCollection(id, name) {
        return this.gateway.put(`/${id}`, { name });
    }

    moveToCollection(collectionId, ids, type) {
        return this.gateway.put(`/${collectionId}/items`, { ids, type });
    }

    addCover(customerId, collectionId, item) {
        return this.gateway
            .post(`/${collectionId}/store-path`, item)
            .then(({ status, json }) => ({ status, data: json }));
    }

    editCollection(id, collectionUpdates) {
        return this.gateway.put(`/${id}`, collectionUpdates);
    }
}

export default new CollectionsService();
