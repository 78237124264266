import {
    BaseVisualElement,
    changeElementInsideBox,
    getAllElementsRecursively,
    getTopLevelElements,
    GroupElement,
    IAssetsLoader,
    IBaseMultiPageModel,
    ICompModel,
    ICompositor,
    IElement,
    InteractionStates,
    ManipulationEventParams,
    MultipleElementMoveParams,
    Position,
    SnapLines,
    truthy,
} from '@bynder-studio/render-core';
import { TextEditorData, TextProps } from '@bynder-studio/structured-text';
import CanvasManipulation from '../../Compositor/CanvasManipulationCompositor/CanvasManipulation';
import type { IBaseManipulationRenderer } from './IBaseManipulationRenderer';
import { PlaybackRenderer } from '../PlaybackRenderer/PlaybackRenderer';
import { LoopInterval } from '../PlaybackRenderer/IPlaybackRenderer';

const clamp = (x: number, min: number, max: number) => (x < min ? min : x > max ? max : x);

export class BaseManipulationRenderer extends PlaybackRenderer implements IBaseManipulationRenderer {
    canvasManipulation: CanvasManipulation;

    selectedElements: Set<BaseVisualElement> = new Set();

    topLevelSelectedQuantity: number = null;

    hoveredElementId: number;

    draggingElementId: number = null;

    currentSelectedGroupId: number = null;

    currentParentId: number = null;

    constructor(
        creativeModel: IBaseMultiPageModel,
        assetLoader: IAssetsLoader,
        compositor: ICompositor,
        canvasManipulation: CanvasManipulation,
        loopInterval: LoopInterval,
    ) {
        // @ts-ignore
        super(creativeModel, assetLoader, compositor, loopInterval);
        this.canvasManipulation = canvasManipulation;
        this.canvasManipulation.emitter.on('requestElementMove', this.onElementMoveRequest.bind(this));
        this.canvasManipulation.emitter.on('requestElementRotate', this.onElementRotateRequest.bind(this));
        this.canvasManipulation.emitter.on('requestElementResize', this.onElementResizeRequest.bind(this));
        this.canvasManipulation.emitter.on('requestCanvasPan', this.onCanvasPanRequest.bind(this));
        this.canvasManipulation.emitter.on('requestCanvasZoom', this.onCanvasZoomRequest.bind(this));
        this.canvasManipulation.emitter.on('requestElementSelect', this.onElementSelectRequest.bind(this));
        this.canvasManipulation.emitter.on('requestElementDeselect', this.onElementDeselectRequest.bind(this));
        this.canvasManipulation.emitter.on('requestElementHover', this.onElementHoverRequest.bind(this));
        this.canvasManipulation.emitter.on('requestElementInteract', this.onElementInteractRequest.bind(this));
        this.canvasManipulation.emitter.on('requestElementTextUpdate', this.onElementTextUpdate.bind(this));
        this.canvasManipulation.emitter.on('requestActivateTextEditor', this.onActivateTextEditorRequest.bind(this));
        creativeModel.getEventEmitter().on('requestToChangeCurrentPage', this.onModelSizeChangeRequest.bind(this));
        creativeModel.getEventEmitter().on('currentPageChange', this.handleModelPageChange.bind(this));
    }

    onActivateTextEditorRequest({ elementId, ...rest }) {
        this.canvasManipulation.activateTextEditor({ elementId, ...rest });
    }

    onElementTextUpdate(params: {
        id: number;
        formattedText: TextProps;
        textEditorData: TextEditorData;
        isTextEditorActivated: boolean;
    }) {
        const { id, formattedText, textEditorData, isTextEditorActivated } = params;
        const newElement = {
            formattedText,
            textEditorData,
            isTextEditorActivated,
        };

        if (!this.creativeModel.getElementById(id)) {
            return;
        }

        this.creativeModel.updateElement(id, newElement);
    }

    onElementMoveRequest(params: ManipulationEventParams['requestElementMove']) {
        const { element, elementsIds, position, multipleElements } = params;

        if (multipleElements) {
            const { differencePosition } = params as MultipleElementMoveParams;
            this.creativeModel.beginAccumulation();

            elementsIds
                .map((elementId) => this.creativeModel.getElementById(elementId))
                .filter((el) => el && !elementsIds.includes(el.parent?.id))
                .forEach((el) => {
                    const currentPos = el.position;
                    const newElement = { position: currentPos.add(differencePosition) };
                    this.creativeModel.updateElement(el.id, newElement);
                });

            this.creativeModel.endAccumulation();
        } else {
            const newElement = { position };
            this.creativeModel.updateElement(element.id, newElement);
        }
    }

    onElementRotateRequest({
        element,
        elementsIds,
        rotation,
        differenceRotation,
    }: ManipulationEventParams['requestElementRotate']) {
        if (elementsIds.length > 1) {
            elementsIds
                .map((id) => this.creativeModel.getElementById(id))
                .filter((element) => !(element instanceof GroupElement))
                .forEach((element) => {
                    let finalRotation = element.rotation + differenceRotation;
                    finalRotation = Math.round(finalRotation) % 360;

                    if (finalRotation < 0) {
                        finalRotation = 360 + finalRotation;
                    }

                    const newElement = { rotation: finalRotation };
                    this.creativeModel.updateElement(element.id, newElement);
                });
        } else {
            const newElement = { rotation };
            this.creativeModel.updateElement(element.id, newElement);
        }
    }

    onElementResizeRequest({
        startDimension,
        startPosition,
        elementsIds,
        position,
        dimension,
    }: ManipulationEventParams['requestElementResize']) {
        const box = { startPosition, startDimension, position, dimension };

        if (elementsIds.length > 1) {
            this.creativeModel.beginAccumulation();
            elementsIds
                .map((id) => this.creativeModel.getElementById(id))
                .filter((element) => !(element instanceof GroupElement))
                .forEach((element: BaseVisualElement) => {
                    const newElement = changeElementInsideBox(element, box);
                    this.creativeModel.updateElement(element.id, newElement);
                });

            this.creativeModel.endAccumulation();
        } else {
            const element = this.creativeModel.getElementById(elementsIds[0]);
            const newElement = { position, dimension };
            this.creativeModel.updateElement(element.id, newElement);
        }
    }

    onCanvasZoomRequest({ scale, x, y }: ManipulationEventParams['requestCanvasZoom']) {
        const dimension = this.compositor.getCompositorWrapperDimension();
        const oldScale = this.getScale();
        const x0 = dimension.getWidth() / 2;
        const y0 = dimension.getHeight() / 2;
        const pan = this.getPanPosition();
        const pointerX = x - x0;
        const pointerY = y - y0;

        this.setPanPosition(
            new Position(
                pointerX - ((pointerX - pan.getX()) / oldScale) * scale,
                pointerY - ((pointerY - pan.getY()) / oldScale) * scale,
            ),
        );
        this.setScale(scale);
    }

    onCanvasPanRequest({ position }: ManipulationEventParams['requestCanvasPan']) {
        this.setPanPosition(position);
    }

    onElementSelectRequest({ elementId, useMultiple }: ManipulationEventParams['requestElementSelect']) {
        this.selectElement(elementId, useMultiple);
    }

    onElementDeselectRequest({ elementId }: ManipulationEventParams['requestElementDeselect']) {
        this.deselectElement(elementId);
    }

    onElementHoverRequest({ elementId }: ManipulationEventParams['requestElementHover']) {
        this.hoverElement(elementId);
    }

    getPanMode(): boolean {
        return this.canvasManipulation.getPanMode();
    }

    togglePanMode(value) {
        this.canvasManipulation.setPanMode(value);
    }

    onElementInteractRequest({
        elementId,
        interactionState,
    }: {
        elementId: number;
        interactionState: InteractionStates;
    }) {
        this.interactElement({ elementId, interactionState });

        if (interactionState === InteractionStates.DRAGGING) {
            this.draggingElementId = elementId;
            this.compositor.setDraggingId(elementId);
        } else if (this.draggingElementId === elementId && interactionState === InteractionStates.SELECTED) {
            this.compositor.setDraggingId(null);
            this.draggingElementId = null;
        }
    }

    onModelSizeChangeRequest() {
        this.canvasManipulation.prepareCanvasForSizeChange();
    }

    handleModelPageChange(pageIndex: number) {
        const mainModel = (this.creativeModel as IBaseMultiPageModel).getCurrentModel();
        const elements = mainModel.getAllElementsRecursively();
        const idToElement = elements.reduce((acc, element) => ({ ...acc, [element.id]: element }), {});

        this.selectedElements = new Set([...this.selectedElements].map((el) => idToElement[el.id]).filter(truthy));
        this.eventEmitter.emit('elementSelected', { elements: [...this.selectedElements] });
        this.canvasManipulation.setPageInfo(
            pageIndex,
            Array.from(this.selectedElements).map((el) => el.id),
        );
    }

    redrawCompModel(compModel) {
        this.canvasManipulation.setCompModel(compModel);
        this.canvasManipulation.redraw();
    }

    setScale(scale: number): this {
        return super.setScale(clamp(scale, 0.1, 8));
    }

    zoomToFit() {
        const compositorWrapperDimension = this.compositor.getCompositorWrapperDimension();
        const padding = this.compositor.getCompositorWrapperPadding();
        const templateDimension = this.creativeModel.getDimensions();
        const w1 = compositorWrapperDimension.getWidth() - 2 * padding;
        const h1 = compositorWrapperDimension.getHeight() - 2 * padding;
        const w2 = templateDimension.getWidth();
        const h2 = templateDimension.getHeight();
        const scale = Math.min(w1 / w2, h1 / h2);

        this.setScale(scale);
        this.setPanPosition(new Position(0, 0));
    }

    // @ts-ignore
    setPanPosition(panPosition: Position) {
        super.setPanPosition(panPosition);
        this.eventEmitter.emit('canvasPanned', {
            position: this.getPanPosition(),
            lastChange: panPosition,
        });

        return this;
    }

    async drawCompModel(compModel: ICompModel): Promise<boolean> {
        const res = await super.drawCompModel(compModel);

        if (res) {
            this.canvasManipulation.setCompModel(compModel);
        }

        return res;
    }

    deselectElement(elementId: number): void {
        // eslint-disable-next-line unicorn/prefer-query-selector
        const element = this.creativeModel.getElementById(elementId) as BaseVisualElement;
        const elements = getAllElementsRecursively([element]);

        if (this.selectedElements.has(element)) {
            const parentId = element?.parent?.id;
            elements.forEach((el) => {
                this.selectedElements.delete(el);
            });

            if (parentId) {
                const parentEl = [...this.selectedElements].find((elem) => elem.id === parentId);
                this.selectedElements.delete(parentEl);
            }
        }

        this.setChosenGroupAndParent();
        const selectedElementsIds = [...this.selectedElements].map((el) => el.id);
        this.canvasManipulation.selectElement({
            selectedElements: selectedElementsIds,
            topLevelSelectedQuantity: this.topLevelSelectedQuantity,
            currentSelectedGroupId: this.currentSelectedGroupId,
            currentParentId: this.currentParentId,
        });
        this.eventEmitter.emit('elementSelected', {
            elements: [...this.selectedElements],
        });
    }

    selectElement(elementId: number, useMultiple = false, additionalInfo = {}): void {
        const element = this.creativeModel.getElementById(elementId) as BaseVisualElement;
        const elements = getAllElementsRecursively([element]);

        if (useMultiple) {
            if (this.selectedElements.has(element)) {
                elements.forEach((el) => {
                    this.selectedElements.delete(el);
                });
            } else {
                elements.forEach((el) => {
                    this.selectedElements.add(el);
                });
            }
        } else {
            this.selectedElements = new Set(elements);
        }

        this.setChosenGroupAndParent();
        const selectedElementsIds = [...this.selectedElements].map((el) => el.id);
        this.canvasManipulation.selectElement({
            topLevelSelectedQuantity: this.topLevelSelectedQuantity,
            selectedElements: selectedElementsIds,
            currentSelectedGroupId: this.currentSelectedGroupId,
            currentParentId: this.currentParentId,
        });
        this.eventEmitter.emit('elementSelected', {
            elements: [...this.selectedElements],
            ...additionalInfo,
        });
    }

    hoverElement(elementId: number): void {
        this.hoveredElementId = elementId;
        this.canvasManipulation.hoverElement(elementId);
    }

    interactElement({ elementId, interactionState }: { elementId: number; interactionState: InteractionStates }) {
        this.canvasManipulation.interactElement({ elementId, interactionState });
    }

    setElementSnapLines(xAxisSnapLines: SnapLines, yAxisSnapLines: SnapLines) {
        this.canvasManipulation.snapLinesManipulation.setElementSnapLines(xAxisSnapLines, yAxisSnapLines);
    }

    private setChosenGroupAndParent() {
        const topLevelElements = getTopLevelElements([...this.selectedElements]);
        const lastElement = topLevelElements[topLevelElements.length - 1];
        const parentId = lastElement?.parent?.id || null;
        const selectedGroupId = lastElement instanceof GroupElement ? lastElement.id : null;

        this.topLevelSelectedQuantity = topLevelElements.length;
        this.currentSelectedGroupId = selectedGroupId;
        this.currentParentId = parentId;
    }

    getSelectedElement(): IElement | null {
        const elements = [...this.selectedElements];

        if (!elements.length) {
            return null;
        }

        if (elements.length === 1) {
            return elements[0];
        }

        const allSelectedGroups = elements.filter((element) => element instanceof GroupElement);

        if (!allSelectedGroups.length) {
            return null;
        }

        const groupInTopLevel = allSelectedGroups.filter(
            (group) => !group.parent || !allSelectedGroups.includes(group.parent),
        );

        if (groupInTopLevel.length !== 1) {
            return null;
        }

        return groupInTopLevel[0];
    }

    getSelectedElements(): IElement[] {
        return [...this.selectedElements];
    }

    getTopLevelSelectedElements(): IElement[] {
        return getTopLevelElements([...this.selectedElements]);
    }

    isElementSelected(elementId: number): boolean {
        return this.getSelectedElements().some((element) => element.id === elementId);
    }
}
