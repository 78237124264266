import React, { useState } from 'react';
import { Divider, Form } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import useEditor from '~/hooks/useEditor';
import useEditorSelectedElement from '~/hooks/useEditorSelectedElement';
import useForceUpdate from '~/hooks/useForceUpdate';
import { SizeFormRow } from '../index.styled';
import { InspectorHeader, InspectorContent, Section, FormSectionContent } from '../../index.styled';
import SettingsTabs from '../Tabs';
import ElementAlignment from '../ElementAlignment';
import Width from '../Size/Width';
import LockToggler from '../Size/LockToggle';
import Height from '../Size/Height';
import HorizontalPosition from '../Position/Horizontal';
import VerticalPosition from '../Position/Vertical';
import { BlendMode } from '../BlendMode';
import Animations from '../../Animations';
import Masking from '../Masking';

const Group = () => {
    const { translate } = useTranslate();

    const { creativeModel, creativeType, manipulationRenderer } = useEditor();
    const { selectedElement, selectedElements } = useEditorSelectedElement();
    const forceUpdate = useForceUpdate();

    const tabs = [
        { value: 'group', label: translate('editor.sidebar.shots.group.tabs.text') },
        { value: 'group_animation', label: translate('editor.sidebar.shots.animation.tabs.text') },
    ];
    const [activeTab, setTab] = useState(tabs[0].value);

    return (
        <>
            {creativeType === 'VIDEO' && (
                <InspectorHeader aria-labelledby="shots-group-tabs-settings">
                    <SettingsTabs tabs={tabs} activeTab={activeTab} onChange={(value) => setTab(value)} />
                </InspectorHeader>
            )}
            <InspectorContent withTabs={creativeType === 'VIDEO'}>
                {activeTab === tabs[0].value && (
                    <>
                        <Section aria-labelledby="shots-alignment-settings">
                            <ElementAlignment creativeModel={creativeModel} selectedElements={selectedElements} />
                        </Section>
                        <Divider />
                        <Section aria-labelledby="shots-size-settings">
                            <FormSectionContent onSubmit={preventSubmit}>
                                <SizeFormRow>
                                    <Width
                                        creativeModel={creativeModel}
                                        selectedElement={selectedElement}
                                        creativeType={creativeType}
                                        isLinked={false}
                                    />
                                    <LockToggler selectedElement={selectedElement} forceUpdate={forceUpdate} />
                                    <Height
                                        creativeModel={creativeModel}
                                        selectedElement={selectedElement}
                                        creativeType={creativeType}
                                        isLinked={false}
                                    />
                                </SizeFormRow>
                                <Form.Row>
                                    <HorizontalPosition
                                        creativeModel={creativeModel}
                                        selectedElement={selectedElement}
                                        isLinked={false}
                                    />
                                    <VerticalPosition
                                        creativeModel={creativeModel}
                                        selectedElement={selectedElement}
                                        isLinked={false}
                                    />
                                </Form.Row>
                            </FormSectionContent>
                        </Section>
                        <Divider />
                        <Section aria-labelledby="shots-video-masking-settings">
                            <FormSectionContent onSubmit={preventSubmit}>
                                <Form.Group>
                                    <Form.Label>{translate('editor.element.mask')}</Form.Label>
                                    <Masking
                                        element={selectedElement}
                                        creativeModel={creativeModel}
                                        manipulationRenderer={manipulationRenderer}
                                        creativeType={creativeType}
                                        disabled={false}
                                    />
                                </Form.Group>
                            </FormSectionContent>
                        </Section>
                        <Divider />
                        <Section aria-labelledby="shots-video-blend-settings">
                            <FormSectionContent onSubmit={preventSubmit}>
                                <Form.Group>
                                    <BlendMode disabled={false} />
                                </Form.Group>
                            </FormSectionContent>
                        </Section>
                    </>
                )}
                {activeTab === tabs[1].value && <Animations element={selectedElement} disabled={false} />}
            </InspectorContent>
        </>
    );
};

export default Group;

function preventSubmit(e: React.FormEvent) {
    e.preventDefault();
}
