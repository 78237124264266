import { camelToSnakeCase, ColorParams, toRGBAWithBrandId, toRGBAWithBrandIdObj, truthy } from '@bynder-studio/misc';
import {
    AppliedStyles,
    LayoutRun,
    LayoutRuns,
    RunProps,
    SETTINGS_KEYS,
    StyleProps,
    TextProps,
} from '../Core/StructuredText';
import { cleanupRunValues, DenormalizedRun, denormalizeRun, normalizeRun } from '../Core/utils';
import { LayoutElement } from '../Core/Types';
import { cloneObj } from './utils';
import { findFontById, Font, FontFamily, FontStyle, getFontFamilies } from '../Core/FontsFamilies';

export const defaultColor: ColorParams = {
    red: 0,
    green: 0,
    blue: 0,
    opacity: 1,
    brandColorId: null,
};

enum FontStyles {
    italic = 'italic',
    bold = 'bold',
}

type StyleType = {
    [key in FontStyles]: number;
};

type TagType = {
    name: string;
    index: number;
    length: number;
};

type FontId = string | 'default';

type FontStyleForElement = {
    id: FontId;
    url: string | null;
};

type FontStylesForElement = {
    [style in FontStyle]: FontStyleForElement;
};

export type FragmentType = {
    fontId: FontId;
    fontSrc: string | null;
    text: string;
};

export type StructuredText = {
    lines: { fragments: FragmentType[] }[];
};

export const getRandomString = (length: number): string => {
    let ret = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        ret += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return ret;
};

export const flattenNestedTags = (html: string) => {
    const isOpening = (str: string) => /<(b|i)>/.test(str);

    const isClosing = (str: string) => /<\/(b|i)>/.test(str);

    const isBr = (str: string) => /<\/?br>/.test(str);

    const tagRegExp = /<\/?\w+>/g;

    const closeTag = (t: string) => t.replace(/</, '</');

    const tags: TagType[] = [];
    let match = null;

    while ((match = tagRegExp.exec(html)) !== null) {
        tags.push({
            name: match[0],
            index: match.index,
            length: match[0].length,
        });
    }

    const tagStack: TagType[] = [];
    const unmatchedTags: [TagType[], TagType][] = tags
        .map((tag) => {
            if (isBr(tag.name) && tagStack.length) {
                return [[...tagStack.map((t) => ({ ...t }))], tag];
            }

            if (isOpening(tag.name)) {
                tagStack.push(tag);
            } else if (isClosing(tag.name)) {
                tagStack.pop();
            }
        })
        .filter(truthy) as [TagType[], TagType][];
    let ret = html;
    const replacementData = unmatchedTags.map((unmatchedTag) => {
        const [openTags, tag] = unmatchedTag;
        const closing = openTags
            .map((t) => closeTag(t.name))
            .reverse()
            .join('');
        const opening = openTags.map((t) => t.name).join('');
        const placeholder = getRandomString(tag.length);
        ret = ret.slice(0, tag.index) + placeholder + ret.slice(tag.index + tag.length);

        return [placeholder, closing + tag.name + opening];
    });
    replacementData.forEach((replacement) => {
        const [placeholder, replacementTag] = replacement;
        ret = ret.replace(placeholder, replacementTag);
    });

    return ret;
};

export function parseHTML(html: string) {
    if (!html) {
        return '';
    }

    if (!html.includes('</div>')) {
        return html;
    }

    const cleanedHTML = html
        .replace(/<br>(?=<\/.+>)/g, '')
        .split('</div>')
        .filter(truthy)
        .map((v) => v.replace('<div>', ''))
        .map((v) => (v.replace(/<[ib]>/gi, '') === '<br>' ? '' : v))
        .join('<br>');

    return flattenNestedTags(cleanedHTML);
}

export function unescapeHtmlPartly(html: string): string {
    return parseHTML(html)
        .replace(/<br>/g, '\n')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&nbsp;/g, ' ')
        .replace(/&quot;/g, '"')
        .replace(/&private 039;/g, "'")
        .replace(/&amp;/g, '&');
}

export function getLayoutRunsFromPlainText(text: string): LayoutRuns {
    return text
        .split('\n')
        .map((line, i, arr) => ({ length: line.length + Math.sign(arr.length - i), type: LayoutElement.PARAGRAPH }));
}

/**
 * Converts old rich-text value into the new TextProps
 */

export function getStructuredText(value: string | TextProps, defaultProps: StyleProps): TextProps {
    const isJson = typeof value === 'object';
    const fontId: FontId = (defaultProps.fontId || '').toString() || 'default';
    const fontStyles = getFontStylesForElement(getFontFamilies(), fontId);
    const defaultFont = Object.values(fontStyles).find((font) => font.id === fontId) || fontStyles.REGULAR;

    const commonRunProps = {
        ...defaultProps,
        fontId: defaultFont.id.toString(),
    };

    if (isJson) {
        const runs = value.runs.map((data) => {
            const run = cleanupRunValues({
                ...data,
                fontId: data.fontId?.toString(),
            });

            return { ...commonRunProps, ...run };
        });

        if (!runs.length) {
            runs.push({ length: 0, ...commonRunProps });
        }

        return {
            ...value,
            runs,
        };
    }

    const textValue = unescapeHtmlPartly(sanitize(value || ''));
    const fragments = getLineFragments(textValue, fontId, fontStyles);
    const runs = fragments.map<RunProps>((fragment) => ({
        ...commonRunProps,
        length: fragment.text.length,
        ...(fontId !== fragment.fontId
            ? {
                  fontId: fragment.fontId.toString(),
              }
            : {}),
    }));

    if (!runs.length) {
        runs.push({ length: 0, ...commonRunProps });
    }

    const valueProp = fragments.map((fragment) => fragment.text).join('');
    const layoutRuns = getLayoutRunsFromPlainText(valueProp);

    return {
        value: valueProp,
        layoutRuns,
        runs,
    };
}

export function getFontStylesForElement(families: Readonly<FontFamily[]>, fontId: FontId): FontStylesForElement {
    const defaultFamily: FontStylesForElement = {
        BOLD: {
            id: 'default',
            url: null,
        },
        ITALIC: {
            id: 'default',
            url: null,
        },
        BOLD_ITALIC: {
            id: 'default',
            url: null,
        },
        REGULAR: {
            id: 'default',
            url: null,
        },
    };

    if (!fontId) {
        return defaultFamily;
    }

    const family = getFamilyByFontId(families, fontId);

    if (!family) {
        return defaultFamily;
    }

    const result = {
        BOLD: getFontByStyle(family, FontStyle.BOLD, fontId),
        ITALIC: getFontByStyle(family, FontStyle.ITALIC, fontId),
        BOLD_ITALIC: getFontByStyle(family, FontStyle.BOLD_ITALIC, fontId),
        REGULAR: getFontByStyle(family, FontStyle.REGULAR, fontId),
    };

    if (
        !Object.values(result)
            .map((item) => item.id)
            .includes(fontId)
    ) {
        const font = family.fonts.find((item) => item.id.toString() === fontId);

        if (font) {
            result.REGULAR = { ...font, id: font.id.toString() };
        }
    }

    return result;
}

export function getLineFragments(row: string, fontId: FontId, fontStyles: FontStylesForElement): FragmentType[] {
    const chars = row.split('');
    let currentStyle = getStyleById(fontId, fontStyles);
    let isCurrentStyleIsChanged = false;

    const changeCurrentStyle = () => {
        if (isCurrentStyleIsChanged) {
            return;
        }

        isCurrentStyleIsChanged = true;
        currentStyle = {
            bold: 0,
            italic: 0,
        };
    };

    let currentIndex = 0;
    const defaultFont = getStyle(currentStyle, fontStyles);
    const fragments: FragmentType[] = [
        {
            fontId: defaultFont.id,
            fontSrc: defaultFont.url,
            text: '',
        },
    ];
    chars.forEach((char, i) => {
        if (char === '<') {
            const next = chars[i + 1];

            if (next === 'b') {
                changeCurrentStyle();
                currentStyle.bold = currentStyle.bold + 1;
            } else if (next === 'i') {
                changeCurrentStyle();
                currentStyle.italic = currentStyle.italic + 1;
            } else if (next === '/') {
                const nextChar = chars[i + 2];

                if (nextChar === 'b') {
                    changeCurrentStyle();
                    currentStyle.bold = currentStyle.bold - 1;
                } else if (nextChar === 'i') {
                    changeCurrentStyle();
                    currentStyle.italic = currentStyle.italic - 1;
                }
            }
        } else if (char !== '>' && chars[i - 1] !== '<' && chars[i - 2] !== '<') {
            if (currentStyle.bold === 0 && currentStyle.italic === 0) {
                currentStyle = getStyleById(fontId, fontStyles);
            }

            const font = getStyle(currentStyle, fontStyles);

            if (font) {
                if (fragments[fragments.length - 1].fontId !== font.id) {
                    if (!fragments[fragments.length - 1].text) {
                        fragments[fragments.length - 1].fontId = font.id;
                        fragments[fragments.length - 1].fontSrc = font.url;
                    } else {
                        fragments.push({
                            fontId: font.id,
                            fontSrc: font.url,
                            text: '',
                        });
                    }
                }

                fragments[fragments.length - 1].text += char;
            }

            currentIndex++;
        }
    });

    return fragments;
}

function getStyleById(fontId: FontId, fontStyles: FontStylesForElement) {
    const idToStyle: { [id: number | string]: FontStyle } = (Object.keys(fontStyles) as FontStyle[]).reduce(
        (acc, style: FontStyle) => ({ ...acc, [fontStyles[style].id]: style }),
        {},
    );

    switch (idToStyle[fontId]) {
        case FontStyle.BOLD:
            return {
                bold: 1,
                italic: 0,
            };

        case FontStyle.ITALIC:
            return {
                bold: 0,
                italic: 1,
            };

        case FontStyle.BOLD_ITALIC:
            return {
                bold: 1,
                italic: 1,
            };

        default:
            return {
                bold: 0,
                italic: 0,
            };
    }
}

function getStyle(style: StyleType, fontStyles: FontStylesForElement): FontStyleForElement {
    if (style.italic > 0 && style.bold > 0) {
        return fontStyles[FontStyle.BOLD_ITALIC];
    }

    if (style.bold > 0) {
        return fontStyles[FontStyle.BOLD];
    }

    if (style.italic > 0) {
        return fontStyles[FontStyle.ITALIC];
    }

    return fontStyles[FontStyle.REGULAR];
}

function getFamilyByFontId(families: Readonly<FontFamily[]>, fontId: FontId) {
    return families.find((family) => family.fonts.find((font) => font.id.toString() === fontId));
}

function getFontByStyle(family: FontFamily, style: FontStyle, fontId: FontId): FontStyleForElement {
    const font =
        family.fonts.find((item) => item.style === style) ||
        family.fonts.find((item) => item.id.toString() === fontId) ||
        family.fonts.find((item) => item.style === FontStyle.REGULAR);

    return { ...font, id: font.id.toString() };
}

export function getTextPropsFonts(textProps: TextProps, families: Readonly<FontFamily[]>) {
    const data: { [key: string | number]: string } = {};

    textProps.runs
        .map((item) => item.fontId)
        .forEach((fontId) => {
            if (fontId === 'default') {
                return;
            }

            let id: string | number = Number(fontId);

            if (isNaN(id)) {
                id = fontId;
            }

            const url = findFontById(families, id)?.url;

            if (url) {
                data[fontId] = url;
            }
        });

    return data;
}

export function replaceMissingFonts(structuredText: { lines: { fragments: FragmentType[] }[] }, existFonts: FontId[]) {
    structuredText.lines.forEach((line) => {
        line.fragments.forEach((fragment) => {
            if (!existFonts.includes(fragment.fontId)) {
                fragment.fontId = 'default';
            }
        });
    });
}

export const loadFont = (() => {
    const cache: { [key: string]: ArrayBuffer } = {};

    return async (src: string) => {
        if (!src) {
            return null;
        }

        if (cache[src]) {
            return cache[src];
        }

        const fontResponse = await fetch(src);

        if (!fontResponse.ok) {
            return null;
        }

        cache[src] = await fontResponse.arrayBuffer();

        return cache[src];
    };
})();

export const sanitize = (html: string) =>
    html
        .replace(/<span.*?>/gi, '')
        .replace(/<\/span>/gi, '')
        .replace(/<b\s[^>]+>/gi, '<b>')
        .replace(/<div\s[^>]+>/gi, '<div>')
        .replace(/<i\s[^>]+>/gi, '<i>')
        .replace(/<(?!\/?div|\/?b|\/?i)[^>]*>/gi, '');

export const stripHTMLTags = (html: string): string => {
    const text = html.replace(/<br\s*\/>/gi, '\n').replace(/<[^>]*>/gi, '');

    if (document) {
        const txt = document.createElement('textarea');
        txt.innerHTML = text;

        return txt.value;
    }

    return text;
};

const collectRunStyles = (run: RunProps) => {
    return Object.keys(run)
        .filter((key) => key !== 'length')
        .reduce((accum, key, idx) => {
            let value = run[key];

            if (key === 'color') {
                // because color has special logic
                value = toRGBAWithBrandId(run[key]);
            }

            if (key === 'stroke') {
                value = `${value.type}_${value.width}`;
            }

            // why not -2? because we also subtract "length" property
            const lastSymbol = idx === Object.keys(run).length - 2 ? '' : ' ';

            return `${accum}${camelToSnakeCase(key)}="${value}"${lastSymbol}`;
        }, '');
};

export const fromRunsToRichText = (layoutRuns: LayoutRuns, styleRuns: RunProps[], text: string) => {
    let offset = 0;
    let result = '';

    const denormalizesLayoutRuns = layoutRuns.reduce(denormalizeRun, [] as DenormalizedRun<LayoutRun>[]);
    const denormalizesStyleRuns = styleRuns.reduce(denormalizeRun, [] as DenormalizedRun<RunProps>[]);

    denormalizesLayoutRuns.forEach((lRun) => {
        const sRuns = cloneObj(denormalizesStyleRuns.filter((run) => run.end >= lRun.start && run.start <= lRun.end));

        sRuns[0].length -= lRun.start - sRuns[0].start;

        const lastRun = sRuns.at(-1);
        lastRun.length -= lastRun.end - lRun.end;

        const spans = sRuns.reduce((accum, run) => {
            const styles = collectRunStyles(normalizeRun(run));
            accum += `<span ${styles}>${text.slice(offset, offset + run.length)}</span>`;
            offset += run.length;

            return accum;
        }, '');

        result += `<p>${spans}</p>`;
    });

    return result;
};

export const checkStylesForAccessRights = (
    run: RunProps,
    allFonts: Font[],
    existingStyles: AppliedStyles,
): RunProps => {
    const result = run as RunProps;
    const hasAccessToFont = allFonts.find((f) => f.id === Number(run.fontId));

    if (!hasAccessToFont) {
        result.fontId = existingStyles.fontId.toString();
    }

    if (run.color.brandColorId) {
        // TODO: Check for brand-colors existing. Can be implemented later here.
        const hasAccessToBrandColor = true;

        if (!hasAccessToBrandColor) {
            delete result.color.brandColorId;
        }
    }

    return result;
};

const toValue = (name: keyof StyleProps, value: string) => {
    switch (name) {
        case 'color':
            return toRGBAWithBrandIdObj(value);
        case 'fontId':
            return value;
        case 'styleId':
            return value === 'null' ? null : value;
        case 'stroke': {
            const [type, width] = value.split('_');

            return { type, width: !isNaN(Number(width)) ? Number(width) : 10 };
        }
        default:
            return !isNaN(Number(value)) ? Number(value) : value;
    }
};

export const getRunFromElement = (element: HTMLSpanElement, fonts: Font[], existingStyles: AppliedStyles): RunProps => {
    const styleProps = SETTINGS_KEYS.reduce(
        (acc, setting) => {
            const attr = element.getAttribute(camelToSnakeCase(setting));

            (acc[setting] as any) = attr ? toValue(setting, attr) : existingStyles[setting][0];

            return acc;
        },
        { length: element.textContent.length } as RunProps,
    );

    return checkStylesForAccessRights(styleProps, fonts, existingStyles);
};

export const getTextFromClipboardItem = async (data: ClipboardItem, type: 'plain' | 'html'): Promise<string | null> => {
    const mimeType = `text/${type}`;

    if (!data.types.includes(mimeType)) {
        return null;
    }

    const blobText = await data.getType(mimeType);

    return blobText.text();
};
