import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconKeyboard } from '@bynder/icons';
import { toggleShortcutsModal } from '~/store/general/general.actions';
import LayerTree from '~/common/editor/layers/LayerTree';
import CreativeTypes from '~/helpers/CreativeTypes';
import RightSideMenuContainer from 'packages/pages/editor/RightSideMenu/container';
import Header from 'packages/pages/editor/Header';
import { Timeline } from './timeline/Timeline';
import { PREVIEW_CANVAS_ID } from './editorHelper';
import { getCreative } from 'packages/store/creatives/creatives.selectors';
import { usePanningShortcuts } from 'packages/pages/editor/usePanningShortcuts';
import { useElementPositionShortcuts } from 'packages/hooks/useElementPositionShortcuts';
import { useAlignment } from '~/hooks/useAlignment';
import EditorCanvas from './EditorCanvas';
import { EditorImageWrapper, ShortcutsInfoButton } from './_Editor.styled';

const Editor = (props) => {
    const { controlsProps, children } = props;
    const { creativeType } = useSelector(getCreative);
    const dispatch = useDispatch();
    const editorCanvas = useRef(null);
    const onToggleShortcutsModal = () => dispatch(toggleShortcutsModal(true));

    const isImageDesign = creativeType === CreativeTypes.Image;

    usePanningShortcuts();
    useAlignment();
    useElementPositionShortcuts({ isImageDesign });

    const setBeforeUnload = useCallback((r) => {
        onbeforeunload = r
            ? null
            : (e) => {
                  const text = 'Your unsaved changes will be lost, do you still want to leave the page?';
                  e.returnValue = text;

                  return text;
              };
    }, []);

    const setDimension = useCallback((newHeight: number) => {
        if (!editorCanvas.current) {
            return;
        }

        const wrElement = document.querySelector('#PREVIEW_CANVAS_ID');

        if (wrElement && wrElement.getBoundingClientRect().height === newHeight) {
            return;
        }

        editorCanvas.current?.setDimensions(newHeight);
    }, []);

    useEffect(() => () => setBeforeUnload(true), []);

    return (
        <EditorImageWrapper className="d-flex h-100">
            <div className="col scroll h-100">
                <div className="row flex-column h-100 editor-container flex-nowrap pt-3">
                    <Header {...controlsProps} />
                    {children}
                    <div
                        className={`col ${isImageDesign ? 'd-flex px-0 layers--parent' : ''} ${
                            !isImageDesign ? 'editor-canvas-external-video' : ''
                        }`}
                    >
                        {isImageDesign && <LayerTree />}
                        <div
                            className={`canvas-container-wrapper ${
                                isImageDesign ? 'canvas-container-wrapper-image' : ''
                            } d-flex flex-column align-items-center justify-content-center h-100`}
                        >
                            <EditorCanvas ref={editorCanvas} id={PREVIEW_CANVAS_ID} />
                            <ShortcutsInfoButton
                                title=""
                                icon={<IconKeyboard />}
                                type={creativeType}
                                onClick={onToggleShortcutsModal}
                            />
                        </div>
                    </div>

                    {!isImageDesign && <Timeline onResize={setDimension} />}
                </div>
            </div>
            <RightSideMenuContainer />
        </EditorImageWrapper>
    );
};

export default Editor;
