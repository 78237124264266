import React from 'react';
import { Thumbnail } from '@bynder/design-system';
import { Translate } from '@bynder/localization';
import { IconDevice, IconFolderOpen } from '@bynder/icons';
import { hasPreviewImageURL, isProcessingDone } from 'packages/pages/components/thumbnail/utils';
import { GetDefaultSidebarItems, TemplateManagementSidebarItemType } from './types';
import { DesignSubPages } from '../Sidebar/types';

// eslint-disable-next-line import/no-unused-modules
export const getTemplateManagementSidebarItems: GetDefaultSidebarItems = (categories) => {
    const renderThumbnail = (category) => {
        if (category.coverImageUrl) {
            return (
                <Thumbnail
                    variant="content"
                    imageUrl={category.coverImageUrl}
                    backgroundColor={`#${category.coverBgColor}`}
                />
            );
        }

        if (category.coverBgColor) {
            return <Thumbnail variant="content" backgroundColor={`#${category.coverBgColor}`} />;
        }

        if (hasPreviewImageURL(category) && isProcessingDone(category)) {
            return (
                <Thumbnail
                    variant="content"
                    imageUrl={category.previewItems[category.previewItems.length - 1]?.previewUrl}
                />
            );
        }

        return (
            <Thumbnail variant="clean" icon={<IconFolderOpen />}>
                {!isProcessingDone(category) && <Thumbnail.Overlay isLoading={!isProcessingDone(category)} />}
            </Thumbnail>
        );
    };

    const sortedCategories = categories.map((category) => ({
        title: category.name,
        to: [DesignSubPages.CATEGORY_DETAILS, category.id.toString()],
        icon: renderThumbnail(category),
        hide: false,
        path: `?create=true&page=category_details&categoryId=${category.id}`,
    }));

    return {
        top: [
            {
                title: <Translate id="modal.design.create.category.all" />,
                to: [DesignSubPages.CATEGORIES],
                icon: <IconDevice />,
                hide: false,
                path: '?create=true&page=categories&categoryId=ALL',
            },
            ...sortedCategories,
        ].filter((item: TemplateManagementSidebarItemType) => !item.hide),
        bottom: [].filter((item: TemplateManagementSidebarItemType) => !item.hide),
    };
};

export default getTemplateManagementSidebarItems;
