import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, InputField, Modal, TextareaField } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { renameTemplateInCategory } from 'packages/store/categories/categories.actions';
import { getTemplatesActionProgress } from 'packages/store/templates/templates.selectors';
import { renameTemplate } from '~/store/templates/templates.actions';
import { Template } from 'packages/store/templates/types';
import { TextMuted } from './misc.styled';
import modalContainer from 'packages/common/modalContainer';

const MAX_INPUT_SIZE = 120;
const MAX_DESCRIPTION_LENGTH = 500;

// todo: remove isNewDesignModal
const useTemplateRenameModal = (props?: { fetchNew?: boolean; isNewDesignModal?: boolean }) => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const { renamingInProgress } = useSelector(getTemplatesActionProgress);
    const [renameModalTemplate, setRenameModalTemplate] = useState<Template | null>(null);
    const [calledFromCategory, setCalledFromCategory] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const isSavingDisabled = useMemo(() => {
        if (!renameModalTemplate) {
            return true;
        }

        const nameLength = name.trim().length;
        const hasInvalidName = nameLength > MAX_INPUT_SIZE || nameLength <= 0;
        const isNameChanged = name !== renameModalTemplate.name;
        const isDescriptionChanged = description !== renameModalTemplate?.description;

        return renamingInProgress || hasInvalidName || (!isNameChanged && !isDescriptionChanged);
    }, [renamingInProgress, name, description, renameModalTemplate?.name, renameModalTemplate?.description]);

    useEffect(() => {
        setName(renameModalTemplate?.name || '');
    }, [renameModalTemplate?.name]);

    useEffect(() => {
        setDescription(renameModalTemplate?.description || '');
    }, [renameModalTemplate?.description]);

    useEffect(() => {
        return () => {
            if (!renameModalTemplate) {
                setName('');
                setDescription('');
            }
        };
    }, [renameModalTemplate]);

    const setRenameModalTemplateFn = (template: Template, calledFromCategory?: boolean) => {
        setRenameModalTemplate(template);
        setCalledFromCategory(calledFromCategory || false);
    };

    const renderModalTemplateRename = () => (
        <Modal
            container={modalContainer}
            title={translate('modal.template.editDetails.title')}
            isOpen={renameModalTemplate !== null}
            onClose={() => setRenameModalTemplate(null)}
            actionPrimary={
                <Button
                    variant="primary"
                    onClick={() => {
                        if (props?.isNewDesignModal && calledFromCategory) {
                            dispatch(
                                renameTemplateInCategory({
                                    id: renameModalTemplate?.id,
                                    name,
                                    description,
                                    fetchNew: props?.fetchNew,
                                    categoryId: renameModalTemplate?.categoryId,
                                }),
                            );
                        } else {
                            dispatch(
                                renameTemplate({
                                    id: renameModalTemplate?.id,
                                    name,
                                    description,
                                    fetchNew: props?.fetchNew,
                                }),
                            );
                        }

                        setRenameModalTemplate(null);
                    }}
                    isDisabled={isSavingDisabled}
                    isLoading={renamingInProgress}
                    title={translate('modal.design.create.template.rename.save')}
                >
                    {translate('modal.design.create.template.rename.save')}
                </Button>
            }
            actionSecondary={
                <Button
                    variant="secondary"
                    onClick={() => setRenameModalTemplate(null)}
                    title={translate('modal.cancel.title')}
                >
                    {translate('modal.cancel.title')}
                </Button>
            }
        >
            <InputField
                label={translate('modal.template.editDetails.name.label')}
                hasClearButton={name?.length > 0}
                value={name}
                onChange={(newName) => setName(newName)}
                isInvalid={name?.length > MAX_INPUT_SIZE}
                helperText={name?.length > MAX_INPUT_SIZE && translate('modal.project.rename.max.length')}
                placeholder={translate('modal.project.rename.placeholder')}
            />
            <TextareaField
                label={
                    <>
                        {translate('modal.template.editDetails.description.label')}
                        <TextMuted> {translate('modal.template.editDetails.description.optional.label')}</TextMuted>
                    </>
                }
                placeholder={translate('modal.template.editDetails.description.placeholder')}
                helperText={translate('modal.template.editDetails.description.helperText', {
                    quantity: description.length,
                    limit: MAX_DESCRIPTION_LENGTH,
                })}
                value={description}
                onChange={setDescription}
                maxLength={MAX_DESCRIPTION_LENGTH}
            />
        </Modal>
    );

    return {
        setRenameModalTemplate: setRenameModalTemplateFn,
        renderModalTemplateRename,
    };
};

export default useTemplateRenameModal;
