import { colorToCSS } from '~/common/editor/editorHelper';
import { rgbToHex } from 'packages/pages/editor/RightSideMenu/FormComponents/ColorPicker/utils';
import type { ColorParams } from '@bynder-studio/render-core';

export const collectCustomColors = (color?: ColorParams) => {
    if (!color || color.brandColorId) {
        return [];
    }

    const { opacity } = color;
    const hexCode = rgbToHex(colorToCSS(color));

    return [{ hexCode, alpha: opacity }];
};

export const collectBrandColorIds = (color?: ColorParams): Set<number> => {
    if (color?.brandColorId) {
        return new Set([color.brandColorId]);
    }

    return new Set();
};
