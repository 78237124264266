import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { saveAsTemplate, saveAsVideo } from '~/store/creatives/creatives.actions';
import { sendAmplitudeDesignEvent } from '~/store/amplitude/actions';
import { AMPLITUDE_TYPES } from '~/store/amplitude/constants';
import { createNavDestination } from '~/common/breadcrumb/navigation-utils';
import { EditorProvider } from '~/contexts/EditorContext';
import {
    discardCreativeTemplate,
    publishCreativeTemplate,
    saveCreativeTemplate,
    setSaveStatus,
    resetEditor,
} from 'packages/store/creativeEditor/creativeEditor.actions';

import Editor from './__Editor';
import { creativeEditorSelector } from 'packages/store/creativeEditor/creativeEditor.selectors';
import { getCreative } from 'packages/store/creatives/creatives.selectors';
import { customerIdSelector } from 'packages/store/user/user.selectors';

export default function CreativeEditor() {
    const location = useLocation();
    const creative = useSelector(getCreative)!;
    const currentCompany = useSelector(customerIdSelector);
    const { template, fonts, textStyles, contentProperties, elementsFonts, animations, isInAction, saved } =
        useSelector(creativeEditorSelector);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const redirect = useCallback(
        (to: { path: string; search?: string }) =>
            navigate(
                createNavDestination(
                    to.path,
                    {
                        creative,
                        location,
                    },
                    true,
                    to.search,
                ),
            ),
        [creative, history, location],
    );

    const handleSetSaveStatus = useCallback(
        (status) => {
            dispatch(setSaveStatus(status));
        },
        [dispatch],
    );

    const handleSaveCreativeTemplate = useCallback(
        (creativeModel, exportIntegrations, approvalEnabled, reviewers, silent) => {
            dispatch(
                saveCreativeTemplate(
                    creative.id,
                    creativeModel,
                    exportIntegrations,
                    redirect,
                    approvalEnabled,
                    reviewers,
                    creative.type,
                    silent,
                ),
            );
        },
        [creative.id, creative.type, dispatch],
    );

    const handleOnDiscard = useCallback(() => {
        dispatch(discardCreativeTemplate(creative.id));
    }, [creative.id, dispatch]);

    const handleOnPublish = useCallback(
        (creativeModel, exportIntegrations, approvalEnabled, reviewers) => {
            dispatch(
                publishCreativeTemplate(
                    creative.id,
                    creativeModel,
                    exportIntegrations,
                    redirect,
                    approvalEnabled,
                    reviewers,
                    creative.type,
                ),
            );
        },
        [dispatch, creative.id, creative.type],
    );

    const handleSaveAsTemplate = useCallback(
        (name: string) => {
            // todo: check description
            dispatch(saveAsTemplate(name));
        },
        [dispatch],
    );

    const handleSaveAsVideo = useCallback(
        (name, onError) => {
            const navigateToNewDesign = (id) => navigate(`/designs/${id}/edit`);
            dispatch(saveAsVideo(name, navigateToNewDesign, onError));
        },
        [dispatch, navigate],
    );

    const fontsList = useMemo(() => {
        const data = {};

        if (!fonts) {
            return data;
        }

        fonts.forEach((font) => {
            data[font.name] = font;
        });

        return data;
    }, [fonts]);

    useEffect(() => {
        // INVALID: DELETE OR CORRECT LATER
        // dispatch(sendAmplitudeDesignEvent({ eventType: AMPLITUDE_TYPES.OPEN_EDITOR }));

        return () => {
            dispatch(resetEditor());
        };
    }, []);

    if (!template || !fonts) {
        return null;
    }

    return (
        <EditorProvider
            template={template}
            fonts={fonts}
            textStyles={textStyles}
            contentProperties={contentProperties}
            type={creative.type}
            creativeType={creative.creativeType}
            creativeId={creative.id}
        >
            <Editor
                template={template}
                currentCompany={currentCompany}
                fontsList={fontsList}
                fonts={fonts}
                elementsFonts={elementsFonts}
                animations={animations}
                saved={saved}
                isInAction={isInAction}
                setSaveStatus={handleSetSaveStatus}
                handleOnSave={handleSaveCreativeTemplate}
                handleOnDiscard={handleOnDiscard}
                handleOnPublish={handleOnPublish}
                saveAsTemplate={handleSaveAsTemplate}
                saveAsVideo={handleSaveAsVideo}
                videosCount={creative.videosCount}
                creativeName={creative.name}
                hasCorruptionReason={!!creative.corruptionReason}
            />
        </EditorProvider>
    );
}
