import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const TransitionControlStyled = styled.div`
    position: absolute;
    height: 37px;

    &.TransitionControl--disabled {
        color: ${token.colorTextDisabled};
        background-color: ${token.gray25a};
    }

    .TransitionControl-controls {
        position: relative;
        display: flex;
        height: 14px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
    }

    .TransitionControl-line {
        flex: 1;
        height: 3px;
        margin: 0 4px;
        background-color: currentColor;
    }

    &.TransitionControl--withStartControl .TransitionControl-line {
        margin-left: 0;
    }

    &.TransitionControl--withEndControl .TransitionControl-line {
        margin-right: 0;
    }

    .TransitionControl-resizer-circle,
    .TransitionControl-resizer-kube {
        position: absolute;
        background-color: ${token.white};
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }

    .TransitionControl-resizer-circle {
        z-index: 1;
        width: 6px;
        height: 6px;
        margin-left: 1px;
        margin-right: 1px;
        border-radius: 50%;
        background-color: currentColor;
    }

    .TransitionControl-resizer-left.TransitionControl-resizer-circle {
        left: 0;
    }

    .TransitionControl-resizer-right.TransitionControl-resizer-circle {
        right: 0;
    }

    .TransitionControl-resizer-kube {
        z-index: 2;
        width: 12px;
        height: 12px;
        margin-left: 1px;
        margin-right: 1px;
        transform: rotate(45deg);
        border: 2px solid currentColor;
        transition:
            margin-top 0.25s ease,
            margin-bottom 0.25s ease,
            background-color 0.1s ease-out;
        cursor: default;
    }

    &:not(.TransitionControl--disabled) .TransitionControl-resizer-kube:hover {
        border-width: 3px;
        width: 15px;
        height: 15px;
        margin-left: 0;
        margin-right: 0;
    }

    .TransitionControl-resizer-left.TransitionControl-resizer-kube {
        left: -7.5px;
    }

    .TransitionControl-resizer-right.TransitionControl-resizer-kube {
        right: -6.5px;
    }

    &.TransitionControl--overlapping .TransitionControl-resizer-left.TransitionControl-resizer-kube {
        margin-top: -3px;
    }

    &.TransitionControl--overlapping .TransitionControl-resizer-right.TransitionControl-resizer-kube {
        z-index: 3;
        margin-top: 4px;
        background-color: currentColor;
        border-color: ${token.white};
        border-width: 1px;
    }

    .TransitionControl-label {
        font-size: 10px;
        margin: -3px 2px 0 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }
`;
