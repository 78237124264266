import React, { useCallback, useEffect, useRef, useState, KeyboardEvent, useMemo } from 'react';
import { IBaseMultiPageModel, TextStyle } from '@bynder-studio/render-core';
import { useDispatch } from 'react-redux';
import { Button, Modal, Input, ListDragAndDrop, Dropdown, Divider, List, Form } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconEdit, IconMore, IconDelete } from '@bynder/icons';
import generateTestId from '~/helpers/testIdHelpers';
import { textStyleSubtext } from 'packages/pages/editor/RightSideMenu/Shots/Text/TextStyles/utils';
import FontThumbnail from 'packages/pages/editor/RightSideMenu/Shots/Text/TextStyles/shared/FontThumbnail';
import { markAsUnsaved } from 'packages/store/creativeEditor/creativeEditor.actions';
import { getFontById } from '@bynder-studio/structured-text';
import modalContainer from 'packages/common/modalContainer';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    allStyleList: TextStyle[];
    creativeModel: IBaseMultiPageModel;
};

type ExtendedTextStyle = TextStyle & { id: TextStyle['uuid'] };

const extendTextStyles = (textStyles: TextStyle[]): ExtendedTextStyle[] =>
    textStyles.map((style) => ({ id: style.uuid, ...style }));
const cleanUpExtendedStyles = (textStyles: ExtendedTextStyle[]): TextStyle[] =>
    textStyles.map((item) => {
        const { id, ...style } = item;

        return style;
    });

const ManageStyleModal = ({ isOpen, onClose, creativeModel, allStyleList }: Props) => {
    const [renameStyleId, setRenameStyleId] = useState('');
    const [value, setValue] = useState('');
    const { translate } = useTranslate();
    const [items, setItems] = useState(extendTextStyles(allStyleList));
    const inputRef = useRef<HTMLDivElement>(null);
    const textStyles = creativeModel.getTextStyles();
    const dispatch = useDispatch();

    const MAX_INPUT_SIZE = 120;
    const isLongName = value.trim().length > MAX_INPUT_SIZE;

    const handleSave = useCallback(() => {
        textStyles.updateStylesWithOrder(cleanUpExtendedStyles(items));
        dispatch(markAsUnsaved());
        onClose();
    }, [onClose, items, dispatch]);

    const handleRenameClick = useCallback(
        ({ id, name }: ExtendedTextStyle) => {
            setRenameStyleId(id);
            setValue(name);
        },
        [setRenameStyleId],
    );

    const renameStyle = useCallback(() => {
        const index = items.findIndex((item) => item.id === renameStyleId);
        const newName = value.trim();

        if (newName) {
            if (isLongName) {
                return;
            }
            items[index].name = newName;
        }
    }, [items, renameStyleId, value]);

    const deleteStyle = useCallback(
        ({ id }: ExtendedTextStyle) => {
            setItems(items.filter((item) => item.id !== id));
        },
        [items, setItems],
    );

    const handleBlur = useCallback(() => {
        renameStyle();
        setRenameStyleId('');
    }, [setRenameStyleId, renameStyle]);

    const handleKeyDown = useCallback(
        (e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Escape') {
                setRenameStyleId('');
            } else if (e.key === 'Enter') {
                handleBlur();
            }

            e.stopPropagation();
        },
        [setRenameStyleId, handleBlur],
    );

    const handleOrderChange = useCallback(
        (newOrderItems: ExtendedTextStyle[]) => {
            setItems(newOrderItems);
        },
        [textStyles],
    );

    useEffect(() => {
        setItems(extendTextStyles(allStyleList));
        if (!isOpen) {
            setValue('');
            setRenameStyleId('');
        }
    }, [isOpen]);

    useEffect(() => {
        if (renameStyleId) {
            requestAnimationFrame(() => {
                inputRef?.current?.querySelector('input')?.focus();
            });
        }
    }, [renameStyleId]);

    const getDiffProperties = () => {
        const originalStyles = extendTextStyles(allStyleList);
        const propertiesToSave = items.reduce((acc, item, index) => {
            const originalProperty = originalStyles[index];

            if (originalProperty?.id !== item.id || originalProperty?.name !== item.name) {
                return [...acc, item];
            }

            return acc;
        }, [] as ExtendedTextStyle[]);

        return propertiesToSave;
    };

    const isSaveDisabled = useMemo(() => {
        return items?.length === extendTextStyles(allStyleList).length && getDiffProperties().length === 0;
    }, [items, allStyleList, renameStyleId]);

    return (
        <Modal
            container={modalContainer}
            isOpen={isOpen}
            onClose={onClose}
            title={translate('editor.sidebar.shots.text.textStyle.modal.manage.title')}
            actionPrimary={
                <Button
                    variant="primary"
                    onClick={handleSave}
                    isDisabled={isSaveDisabled}
                    {...generateTestId('shots_text_styles_modal_manage_btn')}
                >
                    {translate('editor.sidebar.shots.text.textStyle.modal.manage.save')}
                </Button>
            }
            actionSecondary={
                <Button variant="secondary" onClick={onClose}>
                    {translate('editor.sidebar.shots.text.textStyle.cancel')}
                </Button>
            }
        >
            <ListDragAndDrop
                items={items}
                onOrderChange={handleOrderChange}
                isDividerShown
                renderItem={(item) =>
                    item.id === renameStyleId ? (
                        <List.Item
                            thumbnail={
                                <FontThumbnail id={item.uuid + item.fontId} url={getFontById(item.fontId)?.url ?? ''} />
                            }
                        >
                            <Form.Group>
                                <Input
                                    ref={inputRef}
                                    onBlur={handleBlur}
                                    onChange={setValue}
                                    onKeyDown={handleKeyDown}
                                    value={value}
                                    isInvalid={isLongName}
                                    hasClearButton
                                />
                                {isLongName && (
                                    <Form.HelperText isInvalid={isLongName}>
                                        {translate('input.error.max.length')}
                                    </Form.HelperText>
                                )}
                            </Form.Group>
                        </List.Item>
                    ) : (
                        <ListDragAndDrop.Item
                            subtext={textStyleSubtext(item)}
                            thumbnail={
                                <FontThumbnail id={item.uuid + item.fontId} url={getFontById(item.fontId)?.url ?? ''} />
                            }
                            rightElements={
                                <Dropdown
                                    minWidth="224px"
                                    trigger={({ isOpen: isDropdownOpen, ...triggerProps }) => (
                                        <Button
                                            {...triggerProps}
                                            isPressed={isDropdownOpen}
                                            variant="clean"
                                            icon={<IconMore />}
                                            title=""
                                        />
                                    )}
                                    position="bottom-right"
                                >
                                    <Dropdown.Item icon={<IconEdit />} onClick={() => handleRenameClick(item)}>
                                        {translate('editor.sidebar.shots.text.textStyle.rename')}
                                    </Dropdown.Item>
                                    <Divider />
                                    <Dropdown.Item icon={<IconDelete />} onClick={() => deleteStyle(item)}>
                                        {translate('editor.sidebar.shots.text.textStyle.delete')}
                                    </Dropdown.Item>
                                </Dropdown>
                            }
                        >
                            {item.name}
                        </ListDragAndDrop.Item>
                    )
                }
            />
        </Modal>
    );
};

export default ManageStyleModal;
