import React, { useCallback } from 'react';
import { useTranslate } from '@bynder/localization';
import { Thumbnail } from '@bynder/design-system';
import { IconMaskAlt3, IconMotionBlur } from '@bynder/icons';
import generateTestId from '~/helpers/testIdHelpers';
import { StyledListItem } from './SwitchField.styled';

const NAMES = ['motionBlur', 'mask'] as const;

export const isSwitchField = (name: string): name is SwitchFieldProp => NAMES.includes(name as SwitchFieldProp);

export type SwitchFieldProp = (typeof NAMES)[number];

type SwitchProps = {
    name: SwitchFieldProp;
    value: boolean | string;
    disabled: boolean;
    onChange: (value: boolean) => void;
    testId: string;
};

const LABEL: Record<SwitchFieldProp, string> = {
    motionBlur: 'editor.sidebar.animation.motion-blur.label',
    mask: 'editor.sidebar.animation.mask.label',
};

const ICONS: Record<SwitchFieldProp, React.JSX.Element> = {
    motionBlur: <IconMotionBlur />,
    mask: <IconMaskAlt3 />,
};

const SUBTEXT: Record<SwitchFieldProp, string> = {
    motionBlur: 'editor.sidebar.animation.motion-blur.subtext',
    mask: 'editor.sidebar.animation.mask.subtext',
};

const SwitchField = (props: SwitchProps) => {
    const { translate } = useTranslate();
    const testIdProp = generateTestId(props.testId);
    const value = props.value === 'true' || props.value === true;
    const { onChange } = props;

    const handleChange = useCallback(() => {
        onChange(!value);
    }, [value, onChange]);

    return (
        <StyledListItem
            {...testIdProp}
            checkedVariant="switch"
            onClick={handleChange}
            isChecked={value}
            isDisabled={props.disabled}
            subtext={translate(SUBTEXT[props.name])}
            thumbnail={<Thumbnail variant="clean" icon={ICONS[props.name]} />}
        >
            {translate(LABEL[props.name])}
            <strong>
                {value ? translate('editor.sidebar.animation.on') : translate('editor.sidebar.animation.off')}
            </strong>
        </StyledListItem>
    );
};

export default SwitchField;
