import styled, { css } from 'styled-components';
import { token } from '@bynder/design-system';

export const TextareaWrapper = styled.div<{ focus?: boolean; isDisabled?: boolean; isInvalid?: boolean }>`
    position: relative;
    border: ${(props) => `1px solid ${props.isInvalid ? token.red500 : token.gray100}`};
    border-radius: ${token.radiusBase};
    background: ${token.white};
    font-size: 0;

    ${(props) =>
        props.focus &&
        css`
            border-color: ${props.isInvalid ? token.red500 : token.gray300};
            box-shadow: 0 0 0 3px ${token.gray100a};
        `}

    ${(props) =>
        props.isDisabled &&
        css`
            border-color: ${token.gray100} !important;
            background: ${token.gray25} !important;
            color: ${token.gray600} !important;
            box-shadow: none !important;
        `}
`;

export const TextareaToolBar = styled.div`
    width: 100%;
    display: flex;
    padding-left: ${token.spacing3};
    padding-right: ${token.spacing3};
    padding-top: ${token.spacing2};
    padding-bottom: ${token.spacing2};
    border-bottom: 1px solid ${token.gray100};
    background: ${token.white};
    border-radius: ${token.brandRadiusBase} ${token.brandRadiusBase} 0 0;
`;

export const RightBox = styled.div`
    margin-left: auto;
`;

export const Textarea = styled.textarea`
    min-height: 48px;
    width: 100%;
    resize: none;
    border: none;
    overflow: hidden;
    background: transparent;
    position: relative;
    outline: none;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    font-family: ${token.brandFontStackBase};
    font-size: ${token.fontSize100};
    color: ${token.colorText};
    padding: ${token.spacing3} ${token.spacing5};
    box-sizing: border-box;
`;

export const FontThumbnail = styled.div`
    width: 40px;
    height: 40px;
    font-size: ${token.fontSize200};
    border: 1px solid ${token.gray100};
    border-radius: ${token.brandRadiusBase};
    background-color: ${token.white};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FontThumbnailSmall = styled(FontThumbnail)`
    width: 24px;
    height: 24px;
    font-size: ${token.fontSize100};
`;

export const TextStyleName = styled.div`
    max-width: 116px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const ApplyFont = styled.span<{ name: string; fontUrl: string }>`
    @font-face {
        font-family: 'Custom Font ${(props) => props.name}';
        src: url(${(props) => props.fontUrl}) format('truetype');
    }
    font-family: 'Custom Font ${(props) => props.name}';
`;
