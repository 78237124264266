import * as types from '../types';

export const initialState = {
    assets: [],
    checked: [],
    allChecked: false,
    fetchOptions: {
        thumbnails: ['320x320'],
        filter: 'all',
        search: '',
        sort: ['date_created', 'desc'],
        page: 0,
        size: 50,
    },
    searching: false,
    selectedAsset: null,
    selectedAssetIndex: null,
    totalCount: 0,
    loadingData: false,
    path: '/',
    containsUsed: false,
    checkingAssets: false,
    renamingInProgress: false,
    deleteInProgress: false,
};

export default function assetsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.ASSETS_CHANGE_SOME: {
            const assetsChecked = state.assets.map((asset) => {
                const item = action.assets.find((actionAsset) => asset.id === actionAsset.id);

                return (item && { ...asset, ...item, checked: state.checked.includes(item.id) }) || asset;
            });

            return {
                ...state,
                assets: assetsChecked,
            };
        }
        case types.GET_ASSETS_SUCCESS:
            let actionAssets = action.assets && Array.isArray(action.assets) ? action.assets : [];
            let assets = action.clearData
                ? actionAssets.map((item) => ({ ...item, checked: state.checked.includes(item.id) }))
                : [...state.assets, ...actionAssets];
            return {
                ...state,
                assets,
                selectedAssetIndex: state.selectedAsset
                    ? assets.findIndex((asset) => {
                          return asset.id === state.selectedAsset.id;
                      })
                    : state.selectedAssetIndex,
                totalCount: action.totalCount,
                fetchOptions: action.fetchOptions,
                loadingData: false,
                allChecked: assets.every((c) => state.checked.includes(c.id)),
            };
        case types.GET_ASSETS_REQUEST:
            return {
                ...state,
                loadingData: true,
                searching: action.searching,
                lastRequestDate: action.lastRequestDate,
            };
        case types.SELECT_ASSET_SUCCESS:
            return {
                ...state,
                selectedAsset: action.asset,
                selectedAssetIndex:
                    action.asset &&
                    state.assets.findIndex((asset) => {
                        return asset.id === action.asset.id;
                    }),
            };
        case types.SELECT_COMPANY_REQUEST:
            return {
                ...state,
                currentAsset: null,
            };
        case types.SELECT_COMPANY_SUCCESS:
            return {
                ...state,
                currentAsset: action.asset,
            };
        case types.GET_PATH_REQUEST:
            return {
                ...state,
                path: null,
            };
        case types.GET_PATH_SUCCESS:
            return {
                ...state,
                uploadOptions: action.uploadOptions,
            };
        case types.TOGGLE_ASSETS_ITEM:
            return {
                ...state,
                checked: state.checked.includes(action.id)
                    ? state.checked.filter((e) => e !== action.id)
                    : [...state.checked, action.id],
                allChecked: action.allChecked,
                assets: state.assets.map((item) =>
                    item.id === action.id ? { ...item, checked: !item.checked } : item,
                ),
            };
        case types.TOGGLE_ASSETS_ALL:
            return {
                ...state,
                allChecked: !state.allChecked,
                assets: state.assets.map((item) => ({ ...item, checked: !state.allChecked })),
                checked: state.allChecked
                    ? (() => {
                          const res = Object.assign(state.checked);
                          state.assets.forEach((c) => {
                              res.splice(res.indexOf(c.id), 1);
                          });
                          return res;
                      })()
                    : [...state.checked, ...state.assets.map((c) => c.id).filter((c) => !state.checked.includes(c))],
            };
        case types.ASSETS_DELETE_MODAL_TOGGLE:
            return {
                ...state,
                deleteModal: action.show,
            };
        case types.RESET_ASSETS:
            return {
                ...initialState,
                uploadOptions: state.uploadOptions,
            };
        case types.DELETE_ASSETS_REQUEST:
            return {
                ...state,
                deleteInProgress: true,
            };
        case types.CHECK_ASSETS_REQUEST:
            return {
                ...state,
                checkingAssets: true,
            };
        case types.CHECK_ASSETS_SUCCESS:
            return {
                ...state,
                checkingAssets: false,
                containsUsed: action.containsUsed,
            };
        case types.DELETE_ASSETS_SUCCESS:
            return {
                ...state,
                selectedAsset:
                    state.selectedAsset && state.checked.includes(state.selectedAsset.id) ? null : state.selectedAsset,
                checked: [],
                deleteInProgress: false,
            };
        case types.DELETE_ASSETS_FAIL:
            return {
                ...state,
                deleteInProgress: false,
            };
        case types.ASSETS_DESELECT_ALL:
            return {
                ...state,
                checked: [],
                assets: state.assets.map((item) => ({ ...item, checked: false })),
            };
        case types.RENAME_ASSET_IN_PROGRESS:
            return {
                ...state,
                renamingInProgress: true,
            };
        case types.RENAME_ASSET_SUCCESS:
            return {
                ...state,
                renamingInProgress: false,
            };
        case types.RENAME_ASSET_FAILURE:
            return {
                ...state,
                renamingInProgress: false,
            };
        default:
            return state;
    }
}
