import authorizationHelper from '~/helpers/AuthorizationHelper';
import permissions from '~/configs/permissions';

const disableEditor = window.DISABLE_CREATIVE_EDITOR === 'true';

export const getDesignEditLink = (designId: number) => {
    return `/designs/${designId}`;
};

export const getDesignEditorLink = (designId: number) => {
    if (
        disableEditor ||
        !authorizationHelper.hasPermissions([
            permissions.CREATIVES.WRITE,
            permissions.CREATIVES_SPECIFICATIONS.READ,
            permissions.CREATIVES_SPECIFICATIONS.WRITE,
        ])
    ) {
        return '#';
    }

    return `/designs/${designId}/edit`;
};

export const PAGE_SIZE = 40;
