import styled from 'styled-components';
import { Button, token } from '@bynder/design-system';

export const ExportModalTabWrapper = styled.div`
    margin-block-end: 20px;
`;

export const ExportModalTabLabel = styled.label`
    padding-left: 12px;

    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #334e5c;
`;

export const ExportLockedIcon = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;

    svg {
        path {
            fill: ${token.gray400} !important;
        }
    }
`;

export const ExportDestinationContainer = styled.div`
    max-width: 362px;
    margin-right: auto;
    margin-left: auto;
    padding-top: ${token.spacing6};
`;

export const FullWidthButton = styled(Button)`
    justify-content: space-between;
`;

export const ModalBaseHeaderWrapper = styled.div`
    h3 {
        overflow: unset !important;
    }

    .secondary-info-wrapper {
        position: relative;
    }

    .download-all-btn {
        position: absolute;
        right: 0;
        top: -22px;
    }
`;
