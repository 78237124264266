export enum StatusFilterType {
    ANY_STATUS = 'ANY_STATUS',
    DRAFT = 'DRAFT',
    IN_REVIEW = 'IN_REVIEW',
    EDITS_REQUESTED = 'EDITS_REQUESTED',
    APPROVED = 'APPROVED',
    NO_STATUS = 'NO_STATUS',
}

export type StatusFilterProps = {
    value?: StatusFilterType;
    isDisabled?: boolean;
    isDesigns?: boolean;
    onSelect: (value: StatusFilterType) => void;
};
