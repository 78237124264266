export const getRandomHex = () => {
    const color = '#' + Math.floor(Math.random() * 16777215).toString(16);

    return color.padEnd(7, Math.floor(Math.random() * 15).toString(16));
};

export const rgbToHex = (r: number, g: number, b: number) => {
    if (r > 255 || g > 255 || b > 255) {
        throw new Error('Invalid color component');
    }

    return ((r << 16) | (g << 8) | b).toString(16);
};
