import styled from 'styled-components';

// coppied from DS, would be great import it from there
const CHEQUERBOARD_PATTERN =
    "url(\"data:image/svg+xml,%3Csvg width='8' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Crect height='8' width='8' y='0' x='0' fill='%23ffffff'/%3E%3Crect height='4' width='4' y='0' x='0' fill='%23e9ecee'/%3E%3Crect height='4' width='4' y='4' x='4' fill='%23e9ecee'/%3E%3C/svg%3E%0A\")";

export const StyledCanvas = styled.canvas<{ hasAlpha: boolean }>`
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-image: ${(props) => props.hasAlpha && CHEQUERBOARD_PATTERN};
`;
