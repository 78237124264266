import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Form, List, Select, Thumbnail } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconAdjust, IconArrowDown, IconArrowUp } from '@bynder/icons';
import useForceUpdate from '~/hooks/useForceUpdate';
import useVariations from 'packages/pages/design/hooks/useVariations';
import BrowseButton from 'packages/pages/editor/RightSideMenu/FormComponents/BrowseButton';
import Scale from 'packages/pages/design/components/Scale';
import HorizontalOffset from 'packages/pages/design/components/HorizontalOffset';
import VerticalOffset from 'packages/pages/design/components/VerticalOffset';
import HorizontalAlignment from 'packages/pages/design/components/HorizontalAlignment';
import VerticalAlignment from 'packages/pages/design/components/VerticalAlignment';
import useElement from 'packages/pages/design/hooks/useElement';
import useDesign from 'packages/pages/design/hooks/useDesign';
import { flattenTree } from '~/common/editor/helpers/elementtree';
import { elementIsVideo, getElementPropertiesFromSource, getFreeCropModeParams } from '~/common/editor/helpers';
import { ContentType } from 'packages/pages/editor/RightSideMenu/FormComponents/BrowseButton/types';

const cropModeOptions = [
    { value: 'CONTAIN', label: 'Contain' },
    { value: 'COVER', label: 'Cover' },
    { value: 'COVER_WIDTH', label: 'Width' },
    { value: 'COVER_HEIGHT', label: 'Height' },
    { value: 'FREE', label: 'Free' },
];

type Props = {
    id: number;
    disabled: boolean;
    type: string;
    isLoading: boolean;
};

const ImageVideoElement = ({ id, disabled, type, isLoading }: Props) => {
    const { translate } = useTranslate();
    const { frameRate, creativeType, recalculateShots, creativeModel, template } = useDesign();
    const { key, element, updateElement, syncContentProperty } = useElement(id);
    const { updateVariationElement, removeDeletedAsset } = useVariations();
    const currentPageIndex = useMemo(() => creativeModel.getCurrentPageIndex(), [creativeModel.index]);
    const forceUpdate = useForceUpdate();
    const { fileName, contentTransform, useAudio = false } = element;
    const [cropMode, setCropMode] = useState((contentTransform || {}).type || 'CONTAIN');
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        setCropMode((contentTransform || {}).type || 'CONTAIN');
    }, [key, contentTransform]);

    const handleChangeUseAudio = useCallback(() => {
        if (element.contentPropertyId) {
            syncContentProperty({ useAudio: !useAudio });
        } else {
            updateElement({ useAudio: !useAudio });
        }
        forceUpdate();
    }, [syncContentProperty, forceUpdate, useAudio]);

    const handleChangeCropMode = useCallback(
        (newValue) => {
            setCropMode(newValue);
            const param = {
                contentTransform: {
                    ...contentTransform,
                    type: newValue,
                    ...(newValue === 'FREE' ? getFreeCropModeParams(element) : {}),
                },
            };
            updateElement(param);
        },
        [contentTransform, element, updateElement],
    );

    const isDynamicLengthAvailable = useMemo(() => element?.useDynamicLength, [element]);

    const handleSelectSource = useCallback(
        (source) => {
            const fieldsToChange = getElementPropertiesFromSource(element, creativeType, source);
            const { duration } = fieldsToChange;
            delete fieldsToChange.duration;

            if (element.contentPropertyId) {
                syncContentProperty(fieldsToChange);
            } else {
                updateElement(fieldsToChange);
            }

            if (duration && isDynamicLengthAvailable) {
                recalculateShots([{ elementId: element.id, duration }]).then((elements) => {
                    if (!elements.length) {
                        return;
                    }

                    const models = creativeModel.getModels();
                    const modelsMetaData = creativeModel.getModelsMetaData();

                    models.forEach((model, i) => {
                        model.updateElements(elements);
                        const sizeId = modelsMetaData[i]?.id;
                        elements.forEach((item) => {
                            updateVariationElement(sizeId, item.id, item);
                        });
                    });
                });
            }

            removeDeletedAsset(id);
        },
        [
            element,
            updateElement,
            syncContentProperty,
            creativeType,
            creativeModel,
            updateVariationElement,
            removeDeletedAsset,
        ],
    );

    const collectionId = useMemo(() => {
        const desiredElement: any = Object.values(flattenTree(template.pages[currentPageIndex].elements)).find(
            (el: any) => el.id === id,
        );

        if (!desiredElement) {
            return null;
        }

        return desiredElement.properties.value?.bynderCollectionId;
    }, [template.pages, currentPageIndex, id]);

    return (
        <>
            {!elementIsVideo(type) ? (
                <Form.Group>
                    <BrowseButton
                        id={id.toString()}
                        htmlFor={id.toString()}
                        label={translate('design.sidebar.scene.image.asset.label')}
                        placeholder={translate('design.sidebar.scene.image.input.placeholder')}
                        type={type as ContentType}
                        frameRate={frameRate}
                        value={fileName}
                        selectedObject={{ element }}
                        disabled={disabled || isLoading}
                        locked={false}
                        loading={isLoading}
                        resizableRange
                        previewURL={element.src || ''}
                        isAlpha={element.isAlpha}
                        collectionId={collectionId}
                        isEditorPage={false}
                        testId="design_scene_image_asset"
                        handleSelectSource={handleSelectSource}
                    />
                </Form.Group>
            ) : (
                <Form.Group>
                    <BrowseButton
                        id={id.toString()}
                        htmlFor={id.toString()}
                        label={translate('design.sidebar.scene.video.asset.label')}
                        placeholder={translate('design.sidebar.scene.video.input.placeholder')}
                        type={type as ContentType}
                        frameRate={frameRate}
                        value={fileName}
                        selectedObject={{ element }}
                        disabled={disabled || isLoading}
                        locked={false}
                        loading={isLoading}
                        resizableRange={isDynamicLengthAvailable}
                        previewURL={element.src || ''}
                        isAlpha={element.isAlpha}
                        collectionId={collectionId}
                        isEditorPage={false}
                        testId="design_scene_video_asset"
                        handleSelectSource={handleSelectSource}
                    />
                </Form.Group>
            )}
            <List.Item
                thumbnail={<Thumbnail icon={<IconAdjust />} />}
                rightElements={isExpanded ? <IconArrowUp /> : <IconArrowDown />}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                {translate('design.sidebar.scene.options.expand.label', { type: type.toLowerCase() })}
            </List.Item>
            {isExpanded && (
                <Box paddingBlock="3">
                    <Form.Group>
                        <Form.Label id="scene_crop_mode">
                            {translate('design.sidebar.scene.options.crop-mode.label')}
                        </Form.Label>
                        <Select
                            value={cropMode}
                            onChange={handleChangeCropMode}
                            label={cropModeOptions.find((i) => i.value === cropMode)?.label}
                            aria-labelledby="scene_crop_mode"
                            isDisabled={disabled}
                        >
                            {cropModeOptions.map((item) => (
                                <Select.Item value={item.value} key={item.value}>
                                    {item.label}
                                </Select.Item>
                            ))}
                        </Select>
                    </Form.Group>
                    {cropMode === 'FREE' ? (
                        <>
                            <Scale id={id} disabled={disabled} />
                            <HorizontalOffset id={id} disabled={disabled} />
                            <VerticalOffset id={id} disabled={disabled} />
                        </>
                    ) : (
                        <>
                            <HorizontalAlignment id={id} disabled={disabled} />
                            <VerticalAlignment id={id} disabled={disabled} />
                        </>
                    )}
                    {elementIsVideo(type) && (
                        <Form.Group>
                            <List.Item
                                checkedVariant="switch"
                                onClick={handleChangeUseAudio}
                                isChecked={!!useAudio}
                                isDisabled={disabled}
                            >
                                {translate('design.sidebar.scene.options.crop-mode.use-audio.label')}
                            </List.Item>
                        </Form.Group>
                    )}
                </Box>
            )}
        </>
    );
};

export default memo(ImageVideoElement);
