import * as React from 'react';
import { type BaseMultiPageModel, type TextElement, StrokeType } from '@bynder-studio/render-core';
import { useTranslate } from '@bynder/localization';
import { Tabs, Form } from '@bynder/design-system';
import { IconMin, IconTextOutline } from '@bynder/icons';
import { Stroke } from '@bynder-studio/misc';
import generateTestId from '~/helpers/testIdHelpers';
import MaybeTooltip from 'packages/common/MaybeTooltip';
import useTextSelection from 'packages/hooks/useTextSelection';
import { OutlineButton } from './index.styled';

type Props = {
    creativeModel: BaseMultiPageModel;
    selectedElement: TextElement;
};

const testId = generateTestId('shots_text_outline');

const Outline = ({ creativeModel, selectedElement }: Props) => {
    const { translate } = useTranslate();
    const { id, locked } = selectedElement;
    const { strokeData } = useTextSelection();
    const [stroke, isMixed] = strokeData;

    const handleChange = (strokeProp: Partial<Stroke>) => {
        const param = {
            updateTextSettingForSelection: {
                settings: {
                    stroke: {
                        ...stroke,
                        ...strokeProp,
                    },
                },
            },
        };

        creativeModel.updateElement(id, param);
    };

    return (
        <Form.Group>
            <Form.Label>{translate('editor.sidebar.shots.text.outline.title')}</Form.Label>
            <Tabs variant="clean" {...testId}>
                <MaybeTooltip
                    content={translate(`editor.sidebar.shots.text.outline.${StrokeType.NONE.toLowerCase()}.label`)}
                    hasTooltip={!locked}
                >
                    <OutlineButton
                        variant="clean"
                        icon={<IconMin />}
                        title={translate(`editor.sidebar.shots.text.outline.${StrokeType.NONE.toLowerCase()}.label`)}
                        isPressed={stroke.type === StrokeType.NONE && !isMixed}
                        isDisabled={locked}
                        onClick={() => {
                            handleChange({ type: StrokeType.NONE });
                        }}
                        {...generateTestId(`shots_text_outline_none`)}
                    />
                </MaybeTooltip>
                <MaybeTooltip
                    content={translate(`editor.sidebar.shots.text.outline.outline.label`)}
                    position="top-right"
                    hasTooltip={!locked}
                >
                    <OutlineButton
                        variant="clean"
                        icon={<IconTextOutline />}
                        title={translate(`editor.sidebar.shots.text.outline.outline.label`)}
                        isPressed={stroke.type !== StrokeType.NONE && !isMixed}
                        isDisabled={locked}
                        onClick={() => {
                            if (stroke.type === StrokeType.NONE) {
                                handleChange({ type: StrokeType.OUTSIDE, width: 1 });
                            }
                        }}
                        {...generateTestId(`shots_text_outline_${stroke.type ? `${stroke.type}_selected` : ''}`)}
                    />
                </MaybeTooltip>
            </Tabs>
        </Form.Group>
    );
};

export default Outline;
