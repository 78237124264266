import React, { useState } from 'react';
import { Dropdown, Thumbnail } from '@bynder/design-system';
import { IconSharedFolder, IconArrowDown, IconArrowUp } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { EntityGrantedPermissions, IdentityType } from 'packages/store/sharing/types';
import { renderGroupPermission, renderUserPermission, renderProfilePermission } from './items';
import { DropdownButton, DropdownWrapper, PermissionGroup, PermissionWithSeparator } from './index.styled';

const ReviewersDropdown = ({ entityPermissions }: { entityPermissions: EntityGrantedPermissions[] }) => {
    const [expanded, setExpanded] = useState(false);
    const { translate } = useTranslate();

    const getSubText = () => {
        const permissionsCounter = entityPermissions.reduce(
            (acc, perm) => {
                if (perm.securityIdentity.securityIdentityType === IdentityType.GROUP) {
                    return { ...acc, groups: acc.groups + 1 };
                }
                if (perm.securityIdentity.securityIdentityType === IdentityType.USER) {
                    return { ...acc, users: acc.users + 1 };
                }

                return acc;
            },
            {
                groups: 0,
                users: 0,
            },
        );

        let subText = <></>;

        if (permissionsCounter.groups > 0) {
            const groupsTitle = translate('modal.sharing.ownership.project.inherit.group', {
                counter: permissionsCounter.groups,
            });

            if (permissionsCounter.users > 0) {
                subText = (
                    <>
                        {subText}
                        <PermissionWithSeparator>{groupsTitle}</PermissionWithSeparator>
                    </>
                );
            } else {
                subText = (
                    <>
                        {subText}
                        <PermissionGroup>{groupsTitle}</PermissionGroup>
                    </>
                );
            }
        }
        if (permissionsCounter.users > 0) {
            const usersTitle = translate('modal.sharing.ownership.project.inherit.users', {
                counter: permissionsCounter.users,
            });

            subText = (
                <>
                    {subText}
                    <PermissionGroup>{usersTitle}</PermissionGroup>
                </>
            );
        }

        return subText;
    };

    const renderReviewersPermissions = (items: EntityGrantedPermissions[]) =>
        items.map((item) => {
            if (item.securityIdentity.securityIdentityType === IdentityType.USER)
                return renderUserPermission(item, items, translate);
            if (item.securityIdentity.securityIdentityType === IdentityType.GROUP)
                return renderGroupPermission(item, items, translate);
            if (item.securityIdentity.securityIdentityType === IdentityType.PROFILE)
                return renderProfilePermission(item, items, translate);
            return null;
        });

    return (
        <>
            <DropdownButton
                id="reviewer_permissions_button"
                subtext={getSubText()}
                thumbnail={<Thumbnail icon={<IconSharedFolder />} shape="square" />}
                onClick={() => setExpanded(!expanded)}
                rightElements={expanded ? <IconArrowUp /> : <IconArrowDown />}
            >
                {translate('modal.sharing.reviewers')}
            </DropdownButton>
            {expanded && <DropdownWrapper>{renderReviewersPermissions(entityPermissions)}</DropdownWrapper>}
            <Dropdown.Divider />
        </>
    );
};

export default ReviewersDropdown;
