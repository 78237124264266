import React from 'react';
import { useSelector } from 'react-redux';
import { getImportTemplateType } from 'packages/store/importTemplate/importTemplate.selectors';
import { ImportType } from 'packages/store/importTemplate/types';
import ImportStatusXML from './ImportStatusXML';
import ImportStatusDesignFile from './ImportStatusDesignFile';

const ImportStatus = () => {
    const type = useSelector(getImportTemplateType);

    const renderStatus = {
        [ImportType.XML]: <ImportStatusXML />,
        [ImportType.DESIGN_FILE]: <ImportStatusDesignFile />,
    };

    return renderStatus[type];
};

export default ImportStatus;
