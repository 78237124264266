import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const DroppablePlaceholder = styled.div`
    display: flex;
    align-items: center;
    margin-top: -2px;
    padding-top: 2px;

    &.layers--item-droppable-fill-height {
        flex: 1;
        min-height: 40px;
        align-items: flex-start;
    }

    .layers--item-droppable-placeholder-view {
        flex: 1;
        padding-top: 2px;
        margin-top: -2px;
        border-radius: 1px;
        background: ${token.brandPrimary500};
        opacity: 0;
        transition: opacity 150ms ease-in-out;
    }
`;
