import { IdentityRole, IdentityType } from 'packages/store/sharing/types';

export const sortPermissions = (items, sortingDataKey = 'grantedPermissions') => {
    let sortedItems = items;
    const ownerIndex = sortedItems.findIndex((item) =>
        item[sortingDataKey].some((perm) => perm.permission === IdentityRole.OWNER && perm.granted),
    );
    if (ownerIndex >= 0) {
        sortedItems = [
            sortedItems[ownerIndex],
            ...sortedItems.slice(0, ownerIndex),
            ...sortedItems.slice(ownerIndex + 1, sortedItems.length),
        ];
    }

    const allUsersIndex = sortedItems.findIndex(
        (item) => item.securityIdentity.securityIdentityType === IdentityType.ALL_USERS,
    );
    if (allUsersIndex >= 0) {
        sortedItems = [
            sortedItems[allUsersIndex],
            ...sortedItems.slice(0, allUsersIndex),
            ...sortedItems.slice(allUsersIndex + 1, sortedItems.length),
        ];
    }

    return sortedItems;
};

export const getTemplatePreviewLink = (templateId: number) => {
    return `/designs?create=true&template=${templateId}&page=template_preview`;
};
