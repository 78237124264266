import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { token } from '@bynder/design-system';
import features from '~/configs/features';
import AuthorizationHelper from '~/helpers/AuthorizationHelper';
import useVariations from 'packages/pages/design/hooks/useVariations';
import useDesign from 'packages/pages/design/hooks/useDesign';
import PagePreviewItem from 'packages/pages/design/components/PagePreview/PagePreviewItem';
import { usePagePreview } from 'packages/pages/design/hooks/usePagePreview';
import { useChanges } from 'packages/pages/design/components/PagePreview/useChanges';
import { calculateAspectRatio } from 'packages/pages/design/components/PagePreview/utils';
import { PagesWrapper, PageBlur, PagesList, PagesContainer } from './Component.styled';

const PagePreview = () => {
    const { creativeModel, setCurrentPageIndex } = useDesign();
    const { saveVariation, variations, currentVariation } = useVariations();
    const [alignment, setAlignment] = useState('center');
    const wrapperRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLDivElement>(null);
    const { isLoadingVariations } = useVariations();
    const { pagePreviewImages } = usePagePreview();

    const pages = useMemo(() => {
        const models = creativeModel.getModels();
        return creativeModel
            .getModelsMetaData()
            .map((page, index) => {
                const model = models[index];
                const dimension = model.getDimensions();
                const width = dimension.getWidth();
                const height = dimension.getHeight();
                return {
                    ...page,
                    index,
                    width,
                    height,
                };
            })
            .sort((a, b) => a.displayOrder - b.displayOrder);
    }, [creativeModel]);
    const currentPageIndex = creativeModel.getCurrentPageIndex();
    const [pageIndex, setPageIndex] = useState(() => pages.findIndex((s) => s.index === currentPageIndex));

    useChanges(() => {
        setCurrentPageIndex(pages[pageIndex].index);
    }, [pages, pageIndex, setCurrentPageIndex]);

    const isMultiPageSwitcherAvailable = AuthorizationHelper.isFeatureAvailable(features.MULTI_SIZE_CREATIVE);

    const currentVariationObj = useMemo(
        () => variations.find((v) => v.id === currentVariation),
        [variations, currentVariation],
    );

    const handlePageClick = useCallback(
        (_index) => {
            if (!currentVariationObj) {
                return;
            }

            const { hasUnsavedChanges, id } = currentVariationObj;

            if (hasUnsavedChanges) {
                saveVariation(id);
            }

            setPageIndex(_index);
        },
        [variations, currentVariation, saveVariation, currentVariationObj],
    );

    const setInitialAlignment = useCallback(() => {
        if (!wrapperRef.current || !listRef.current) return;

        const listHeight = listRef.current.scrollHeight;
        const wrapperHeight = wrapperRef.current.getBoundingClientRect().height;
        if (listHeight > wrapperHeight) {
            setAlignment('flex-start');
        }
    }, []);

    useEffect(() => {
        setInitialAlignment();
    }, []);

    if (!isMultiPageSwitcherAvailable) return null;

    return (
        <PagesContainer>
            <PageBlur top={token.spacing5} bottom="auto" />
            <PagesWrapper ref={wrapperRef}>
                <PagesList ref={listRef} alignment={alignment}>
                    {pages.map((page, idx) => (
                        <PagePreviewItem
                            key={page.id}
                            isSelected={currentPageIndex === idx}
                            hasDeletedAsset={currentVariationObj?.sizes?.[page.id]?.hasDeletedAsset}
                            isLoading={!pagePreviewImages[idx] || pagePreviewImages[idx].loading || isLoadingVariations}
                            aspectRatio={calculateAspectRatio(page)}
                            onClick={handlePageClick}
                            name={page.name}
                            idx={idx}
                            url={pagePreviewImages[idx]?.src}
                        />
                    ))}
                </PagesList>
            </PagesWrapper>
            <PageBlur top="auto" bottom={token.spacing5} />
        </PagesContainer>
    );
};

export default PagePreview;
