export enum UndoRedoChangeTypes {
    create = 'create',
    update = 'update',
    remove = 'remove',
}

export enum UndoRedoReasonTypes {
    undo = 'undo',
    redo = 'redo',
}
