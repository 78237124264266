import { leftToStartFrame, TRACK_HEIGHT } from '../helper';
import { findElementById } from '../../../helpers/elementtree';
import { findElementByIdForSnap } from './snapping';

const getSameDepthItems = (selfId, elements, renderAbleItems) => {
    return elements
        .map((element) => renderAbleItems.find((item) => item.element.id === element.id))
        .filter((item) => item && item.element.id !== selfId);
};

const getGroupRenderAbleItems = (selfId, groupId, elements, renderAbleItems) => {
    const group = findElementById(elements, groupId);

    return getSameDepthItems(selfId, [...group.children, group], renderAbleItems);
};

const findClosestElement = (elementsToCompare, top) => {
    return elementsToCompare.reduce((prev, curr) => {
        const prevTop = parseFloat(prev.style.top);
        const currTop = parseFloat(curr.style.top);
        const diffWithPrev = Math.abs(top - prevTop);
        const diffWithCurr = Math.abs(top - currTop);

        return diffWithPrev < diffWithCurr ? prev : curr;
    });
};

export const computeDropResults = (
    elements,
    renderAbleItems,
    duration,
    elementId,
    top,
    leftPercentage,
    groupId,
    isBetweenTracks,
    { elementId: snapElementId, fromRight, toLeft },
) => {
    const itemsToCompare =
        groupId && elementId.toString() !== groupId.toString()
            ? getGroupRenderAbleItems(elementId, groupId, elements, renderAbleItems)
            : getSameDepthItems(elementId, elements, renderAbleItems);

    const trackIdx = (top / TRACK_HEIGHT) << 0;
    const trackTop = trackIdx * TRACK_HEIGHT + 3;
    const firstElementOnTrack =
        !itemsToCompare.length || itemsToCompare.find((item) => parseFloat(item.style.top) === trackTop);
    let targetRenderOrder = 0;
    if (itemsToCompare.length) {
        if (!firstElementOnTrack || isBetweenTracks) {
            const closestRenderAbleItem = findClosestElement(itemsToCompare, top);
            const isAbove = parseFloat(closestRenderAbleItem.style.top) < top;
            const { renderOrder } = closestRenderAbleItem.element;

            targetRenderOrder = renderOrder + (isAbove ? -0.5 : 0.5);
        } else {
            targetRenderOrder = firstElementOnTrack.element.renderOrder;
        }
    }

    let startFrame = '';
    if (snapElementId) {
        const snapElement = findElementByIdForSnap(elements, snapElementId, duration);
        startFrame = snapElement.startFrame;
        if (!toLeft) startFrame += snapElement.duration;
        if (fromRight) startFrame -= findElementById(elements, elementId).duration;
    } else {
        startFrame = leftToStartFrame(duration, leftPercentage);
    }

    return [targetRenderOrder, startFrame];
};
