import { ColorPalettesState, BrandColorsState } from './types';

export const colorPalettes = (state: any): ColorPalettesState => state.palettes;
export const colorPalettesFetching = (state: any) => colorPalettes(state).isFetching;

export const brandColors = (state: any): BrandColorsState => state.brandColors;

export const getPaletteActionProgress = (
    state: any,
): {
    createInProgress: ColorPalettesState['createInProgress'];
    editInProgress: ColorPalettesState['editInProgress'];
    deleteInProgress: ColorPalettesState['deleteInProgress'];
} => ({
    createInProgress: state.palettes.createInProgress,
    editInProgress: state.palettes.editInProgress,
    deleteInProgress: state.palettes.deleteInProgress,
});

export const getBrandColorsActionProgress = (
    state: any,
): {
    creatingInProgress: BrandColorsState['creatingInProgress'];
    editingInProgress: BrandColorsState['editingInProgress'];
    deleteInProgress: BrandColorsState['deleteInProgress'];
} => ({
    creatingInProgress: state.brandColors.creatingInProgress,
    editingInProgress: state.brandColors.editingInProgress,
    deleteInProgress: state.brandColors.deleteInProgress,
});
