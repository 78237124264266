import * as React from 'react';
import { IBaseMultiPageModel, TextElement, TextScript } from '@bynder-studio/render-core';
import { useTranslate } from '@bynder/localization';
import { Tabs, Form, Tooltip } from '@bynder/design-system';
import { IconNormalScript, IconSubscript, IconSuperscript } from '@bynder/icons';
import generateTestId from '~/helpers/testIdHelpers';
import { ScriptButton } from './index.styled';
import useTextSelection from 'packages/hooks/useTextSelection';

type Props = {
    creativeModel: IBaseMultiPageModel;
    selectedElement: TextElement;
};

const SCRIPT_OPTIONS = [
    { name: TextScript.BASE, icon: <IconNormalScript /> },
    { name: TextScript.SUB, icon: <IconSubscript /> },
    { name: TextScript.SUPER, icon: <IconSuperscript /> },
] as const;

const testId = generateTestId('shots_text_script');

const Script = ({ creativeModel, selectedElement }: Props) => {
    const { translate } = useTranslate();

    const { id, locked } = selectedElement;
    const { textScriptData } = useTextSelection();
    const [textTransform, isMixed] = textScriptData;

    const handleChange = React.useCallback(
        (textScript: TextScript) => {
            const param = {
                updateTextSettingForSelection: {
                    settings: {
                        textScript,
                    },
                },
            };

            creativeModel.updateElement(id, param);
        },
        [creativeModel, id],
    );

    return (
        <Form.Group>
            <Form.Label>{translate('editor.sidebar.shots.text.script.title')}</Form.Label>
            <Tabs variant="clean" {...testId}>
                {SCRIPT_OPTIONS.map((option, i) => (
                    <Tooltip
                        key={option.name}
                        position={i === SCRIPT_OPTIONS.length - 1 ? 'top-right' : 'top-center'}
                        content={translate(`editor.sidebar.shots.text.script.${option.name.toLowerCase()}.label`)}
                    >
                        <ScriptButton
                            title=""
                            variant="clean"
                            icon={option.icon}
                            isPressed={!isMixed && textTransform === option.name}
                            isDisabled={locked}
                            onClick={() => {
                                handleChange(option.name);
                            }}
                            {...generateTestId(
                                `shots_text_script_${option.name.toLowerCase()}${
                                    !isMixed && textTransform === option.name ? '_selected' : ''
                                }`,
                            )}
                        />
                    </Tooltip>
                ))}
            </Tabs>
        </Form.Group>
    );
};

export default Script;
