import React, { useMemo } from 'react';
import { elementSortFn, filterTree } from '../../helpers/elementtree';
import { useToggleExpand } from '../../helpers/groups';
import Layer from './Layer';

export const Layers = (props) => {
    const { layers, elements } = props;
    const [expandedGroupIds, toggleGroupExpand] = useToggleExpand([]);

    const layerTree = useMemo(() => {
        return layers.length
            ? filterTree(
                  elements,
                  layers.map((layer) => layer.id),
                  (element) => {
                      const layer = layers.find((layer) => layer.id === element.id);
                      if (layer) {
                          element.disabled = layer.disabled;
                          element.icons = layer.icons;
                      }
                  },
              )
            : {};
    }, [elements, layers]);

    return (
        <div>
            {Object.values(layerTree)
                .sort(elementSortFn())
                .map((layer, i) => (
                    <Layer
                        {...props}
                        key={layer.id}
                        layer={layer}
                        expandedGroupIds={expandedGroupIds}
                        toggleGroupExpand={toggleGroupExpand}
                        depth={0}
                    />
                ))}
        </div>
    );
};
