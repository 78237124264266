import AppVersionService from '../services/appVersionService';
import appVersion from '!!raw-loader!../../../../app-version';

export const checkAppVersion = async () => {
    const serviceAppVersion = await AppVersionService.getVersion();

    if (serviceAppVersion) {
        const { version } = serviceAppVersion;

        return version !== appVersion;
    }

    return false;
};
