export enum InteractionStates {
    DRAGGING = 'DRAGGING',
    PRESSED = 'PRESSED',
    SELECTED = 'SELECTED',
}

export enum HandlerInteractionStates {
    PRESSED = 'PRESSED',
    HOVER = 'HOVER',
}
