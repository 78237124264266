import React, { FC, useEffect, useRef } from 'react';
import { Button } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { InfinityLoaderContainer } from './InfinityLoader.styled';

export type InfinityLoaderProps = {
    isLoading: boolean;
    count: number;
    totalItems: number;
    loadMore: () => void;
};

export const InfinityLoader: FC<InfinityLoaderProps> = ({ isLoading, totalItems: total, count, loadMore: onLoad }) => {
    const ref = useRef<HTMLDivElement>(null);
    const { translate } = useTranslate();

    useEffect(() => {
        if (!ref.current || isLoading || total === count) {
            return;
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    onLoad();
                }
            });
        });

        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [isLoading, total, count, onLoad]);

    return (
        <InfinityLoaderContainer ref={ref}>
            {isLoading && <Button isLoading variant="clean" aria-label={translate('pages.loading')} />}
        </InfinityLoaderContainer>
    );
};
