import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { omit } from 'rambda';
import {
    IconBuildShape,
    IconFolder,
    IconFolderMask,
    IconImage,
    IconImageMask,
    IconMask,
    IconMaskAlt3,
    IconTextField,
    IconTextMask,
    IconVideo,
    IconVideoMask,
    IconCaretRight,
} from '@bynder/icons';
import { Thumbnail, token } from '@bynder/design-system';
import { isCtrlKey } from '~/helpers/hotKeys';
import generateTestId from '~/helpers/testIdHelpers';
import { getElementType, isUsedAsMask } from '~/common/editor/helpers/elementtree';
import ElementName from './elementName/ElementName';
import { useResizing } from '../dragging/useResizing';
import { useDragging } from '../dragging/useDragging';
import { isElementLocked } from '../../timeline-actions/components/utils.js';
import { elementHasAnimations } from '../track-renderer';
import useEditor from '../../../../../hooks/useEditor';
import useElementValidation from '../../../../../hooks/useElementValidation';
import useForceUpdate from '../../../../../hooks/useForceUpdate';
import ElementTimelineTransitions from './timelineTransitions/ElementTimelineTransitions';
import { TimelineElementStyled } from './Element.styled.ts';

const checkIsHiddenByParent = (element) =>
    element.parent && !element.hidden ? checkIsHiddenByParent(element.parent) : element.hidden;

const useBind = (callBack, ...boundedArgs) =>
    useCallback(
        (...args) => {
            if (typeof callBack === 'function') {
                callBack(...boundedArgs, ...args);
            }
        },
        [callBack, boundedArgs],
    );

export const Element = ({
    item,
    onClick,
    onExpandClick,
    onResize,
    onResizeEnd,
    onDrag,
    onDrop,
    onCancelDragging,
    onAnimationExpand,
    containerRef,
    tracksRef,
    trackDuration,
    scrollContainerRef,
}) => {
    const elementNameNodeRef = useRef(null);
    const elementRef = useRef();
    const { element, style, expanded, animationsExpanded } = item;
    const { name: elementName, id, parent, contentPropertyId } = element;
    const { creativeModel, manipulationRenderer } = useEditor();
    const { isValid, isChildrenValid } = useElementValidation(id);
    const forceUpdate = useForceUpdate();
    const [selected, setSelected] = useState(false);
    const dragEnabled = FEATURE_DRAG_ENABLED;
    const isGroup = !!item.children;
    const isParentHidden = checkIsHiddenByParent(element);

    const getElementTypeForTestId = (el) => {
        const isMask = isUsedAsMask(creativeModel, el);
        const isElementMasked = el?.mask !== null;
        const type = getElementType(el);
        const postfix = isMask ? '_mask' : isElementMasked ? '_masked' : '';

        return `${type}${postfix}`;
    };

    const mapElementIcon = {
        mask: <IconMask />,
        image: element.src ? (
            <Thumbnail imageUrl={element.src} style={{ width: '24px', height: '24px', objectFit: 'cover' }} />
        ) : (
            <IconImage />
        ),
        'image-masked': <IconImageMask />,
        video: <IconVideo />,
        'video-masked': <IconVideoMask />,
        text: <IconTextField />,
        'text-masked': <IconTextMask />,
        shape: <IconBuildShape />,
        'shape-masked': <IconMaskAlt3 />,
        group: <IconFolder />,
        'group-masked': <IconFolderMask />,
    };

    const getElementIcon = (el) => {
        if (!isValid) {
            return 'error';
        }

        const isMask = isUsedAsMask(creativeModel, el);

        if (isMask) {
            return mapElementIcon['mask'];
        }

        const isElementMasked = el?.mask !== null;

        const type = getElementType(el).toLowerCase();

        if (isElementMasked) {
            return mapElementIcon[`${type}-masked`];
        }

        return mapElementIcon[type];
    };

    const type = getElementTypeForTestId(element).toLowerCase();
    const testId = useMemo(() => generateTestId(`timeline_element_${type}_${id}`), [type, id]);
    const color = contentPropertyId ? token.teal500 : token.blue500;
    const backgroundColor = contentPropertyId ? token.teal500a10 : token.blue500a10;

    useEffect(() => {
        if (!manipulationRenderer) {
            return;
        }

        setSelected(manipulationRenderer.isElementSelected(id));
        const elementSelectedListener = () => setSelected(manipulationRenderer.isElementSelected(id));

        manipulationRenderer.eventEmitter.on('elementSelected', elementSelectedListener);
        creativeModel.on('elementUpdated', forceUpdate);

        return () => {
            manipulationRenderer.eventEmitter.off('elementSelected', elementSelectedListener);
            creativeModel.off('elementUpdated', forceUpdate);
        };
    }, [id, manipulationRenderer]);

    const handleDrag = useBind(onDrag, item);
    const handleDrop = useBind(onDrop, item);
    const handleAnimationExpand = useBind(onAnimationExpand, item);
    const handleResize = useBind(onResize, item);
    const handleResizeEnd = useBind(onResizeEnd, item, elementRef);

    const handleNameChange = useCallback(
        (name) => {
            creativeModel.updateElement(item.element.id, { name });
        },
        [item, creativeModel],
    );

    const [onResizeMouseDown, resizeModeRef] = useResizing(
        elementRef,
        containerRef,
        handleResize,
        handleResizeEnd,
        element,
        { blockRightEdge: false },
    );

    const handleMouseUp = (evt) => {
        onClick(item, evt);
    };

    const [dragging, onDragStart] = useDragging({
        elementRef,
        containerRef,
        scrollContainerRef,
        tracksRef,
        onDragCb: handleDrag,
        onDrop: handleDrop,
        handleMouseUp,
        onCancel: onCancelDragging,
    });

    const icon = getElementIcon(element);

    const onEdgeClick = useCallback(
        (evt) => {
            if (isGroup) {
                evt.stopPropagation();
                onExpandClick(item);
            }
        },
        [isGroup, item, onExpandClick],
    );
    const onEdgeMouseDown = useCallback(
        (e) => {
            if (isGroup) {
                e.stopPropagation();
            }
        },
        [isGroup],
    );

    const onDragEnableMouseDown = (evt) => {
        const isMultiSelectMode = isCtrlKey(evt);
        !isMultiSelectMode && dragEnabled && evt.button === 0 && onDragStart(evt);
    };

    const elementWidth = useMemo(() => {
        return (
            containerRef.current && (containerRef.current.getBoundingClientRect().width * parseFloat(style.width)) / 100
        );
    }, [containerRef.current && containerRef.current.getBoundingClientRect().width, style.width]);

    const isLocked = isElementLocked(element);

    const onElementClick = useCallback(
        (evt) => {
            !dragging.current && !resizeModeRef.current && onClick(item, evt);
        },
        [item, onClick],
    );

    const classNames = useMemo(() => {
        const temp = ['timeline__element'];

        selected && temp.push('selected');
        isGroup && temp.push('is-group');
        !isChildrenValid && temp.push('hasMinorError');
        !isValid && temp.push('hasMajorError');
        isParentHidden && temp.push('hidden');
        contentPropertyId && temp.push('content-property');

        return temp;
    }, [selected, isGroup, isChildrenValid, isValid, isParentHidden, contentPropertyId]);

    const lockedAttr = useMemo(() => (DEFINE_TESTID_ATTRIBUTES ? { 'data-locked': isLocked } : {}), [isLocked]);

    useEffect(() => {
        Object.entries(style).forEach(([key, value]) => {
            elementRef.current.style[key] = value;
        });
    }, [style]);

    return (
        <>
            <TimelineElementStyled
                className={classNames.join(' ')}
                onClick={onElementClick}
                ref={elementRef}
                data-id={id}
                data-group-id={isGroup ? id : parent?.id}
                data-is-group={isGroup}
                data-is-expanded={expanded}
                {...testId}
                style={style}
                onMouseDown={onDragEnableMouseDown}
                {...lockedAttr}
            >
                <div className="timeline__element__left-edge" onClick={onEdgeClick} onMouseDown={onEdgeMouseDown}>
                    {isGroup ? (
                        <div className={`timeline__element__expand-icon ${expanded ? 'expanded' : ''}`}>
                            <IconCaretRight />
                        </div>
                    ) : (
                        <div
                            className="timeline__element__resize-handle resize-handle-left"
                            onMouseDown={(evt) => onResizeMouseDown(evt, 'left')}
                        />
                    )}
                </div>
                <ElementName
                    id={id}
                    name={elementName}
                    duration={element.duration}
                    onNameChange={handleNameChange}
                    icon={icon}
                    isGroup={isGroup}
                    childrenLength={element.children?.length}
                    elementWidth={elementWidth}
                    onAnimationExpand={handleAnimationExpand}
                    hasAnimations={elementHasAnimations(element)}
                    isHidden={element.hidden}
                    isParentHidden={isParentHidden}
                    elementNameNodeRef={elementNameNodeRef}
                    isLocked={isLocked}
                    scrollContainerRef={scrollContainerRef}
                />
                {!isGroup && (
                    <div
                        className="timeline__element__resize-handle resize-handle-right"
                        onMouseDown={(evt) => onResizeMouseDown(evt, 'right')}
                    />
                )}
            </TimelineElementStyled>

            {animationsExpanded && (
                <ElementTimelineTransitions
                    trackContainerRef={containerRef}
                    trackDuration={trackDuration}
                    element={element}
                    color={color}
                    backgroundColor={backgroundColor}
                    elementStyle={omit('backgroundColor', style)}
                />
            )}
        </>
    );
};
