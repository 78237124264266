import { useCallback, useEffect } from 'react';
import { BaseVisualElement, getAllElementsRecursively, TextElement } from '@bynder-studio/render-core';
import useEditor from '~/hooks/useEditor';
import { isCtrlKey } from '~/helpers/hotKeys';
import { HORIZNTAL_ALIGNMENTS } from 'packages/pages/editor/RightSideMenu/Shots/Text/TextAlignment/utils';

export const useAlignment = () => {
    const { creativeModel, manipulationRenderer } = useEditor();

    const handleKeyDown = useCallback(
        (event) => {
            if (!manipulationRenderer) {
                return;
            }

            const selectedElements = getAllElementsRecursively(
                (manipulationRenderer.getSelectedElements() || []) as BaseVisualElement[],
            ).filter((el) => el instanceof TextElement);

            if (selectedElements.length) {
                selectedElements.forEach(({ id, contentTransform }) => {
                    let params = null;

                    if (event.altKey && isCtrlKey(event)) {
                        switch (event.code) {
                            case 'KeyL': {
                                params = {
                                    contentTransform: {
                                        ...contentTransform,
                                        horizontalAlignment: HORIZNTAL_ALIGNMENTS[0].value,
                                    },
                                };
                                break;
                            }
                            case 'KeyE': {
                                params = {
                                    contentTransform: {
                                        ...contentTransform,
                                        horizontalAlignment: HORIZNTAL_ALIGNMENTS[1].value,
                                    },
                                };
                                break;
                            }
                            case 'KeyR': {
                                params = {
                                    contentTransform: {
                                        ...contentTransform,
                                        horizontalAlignment: HORIZNTAL_ALIGNMENTS[2].value,
                                    },
                                };
                                break;
                            }
                            case 'KeyJ': {
                                params = {
                                    contentTransform: {
                                        ...contentTransform,
                                        horizontalAlignment: HORIZNTAL_ALIGNMENTS[3].value,
                                    },
                                };
                                break;
                            }

                            default:
                                break;
                        }

                        if (params) {
                            event.preventDefault();
                            creativeModel.updateElement(id, params);
                        }
                    }
                });
            }
        },
        [manipulationRenderer, creativeModel],
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);
};
