import { token } from '@bynder/design-system';
import styled from 'styled-components';

export const ElementAlignmentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${token.spacing3} ${token.spacing6} ${token.spacing2} ${token.spacing6};
`;

export const ElementAlignmentButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
`;
