import { getUnixTime } from 'date-fns';
import { OwnershipFilterType } from 'packages/pages/components/filters/components/Ownership/types';
import { updateThumbnails } from 'packages/store/creatives/utils';
import * as types from '../types';

export const initialState = {
    creatives: [],
    checked: [],
    allChecked: false,
    dynamicFields: null,
    creativeFetched: false,
    creativesFetched: false,
    creative: null,
    generateSuccess: false,
    options: {
        filter: 'all',
        search: '',
        searchTime: null,
        sort: ['date_created', 'desc'],
        ownershipSource: OwnershipFilterType.OWNED_BY_ME,
        page: 0,
        size: 40,
        creativeType: undefined,
    },
    searching: false,
    totalCount: 0,
    loadingData: false,
    firstLoad: true,
    duplicationInProgress: false,
    deleteInProgress: false,
    renamingInProgress: false,
    addingInProgress: false,
    pendingDesignSavingRequest: false,
};

export default function creativesReducer(state = initialState, action) {
    switch (action.type) {
        case types.EDIT_CREATIVE_NAME:
            return {
                ...state,
                creative: {
                    ...state.creative,
                    name: action.name,
                },
            };
        case types.CREATE_CREATIVE_FAIL:
            return {
                ...state,
                addingInProgress: false,
            };
        case types.NEW_CREATIVE_REQUEST:
            return {
                ...state,
                addingInProgress: true,
            };
        case types.SELECT_CREATIVE:
            return {
                ...state,
                selectedCreative: action.creative,
            };
        case types.CREATE_CREATIVE_SUCCESS:
            return {
                ...state,
                addingInProgress: false,
            };
        case types.GENERATE_UNMOUNT:
            return {
                ...state,
                generateSuccess: false,
            };
        case types.PENDING_REQUESTS:
            return {
                ...state,
                pendingDesignSavingRequest: action.pendingDesignSavingRequest,
            };
        case types.GENERATE_VIDEOS_SUCCESS:
            return {
                ...state,
                generateSuccess: true,
            };
        case types.GET_CREATIVES_REQUEST:
            return {
                ...state,
                loadingData: true,
                searching: action.searching,
                options: {
                    ...state.options,
                    searchTime: action.searchTime,
                },
            };
        case types.GET_CREATIVE_REQUEST:
            return {
                ...state,
                creative: null,
                dynamicFields: null,
                creativeFetched: false,
            };
        case types.GET_CREATIVE_SUCCESS:
            return {
                ...state,
                dynamicFields: action.dynamicFields,
                creativeFetched: true,
                creative: action.creative,
            };

        case types.SET_CREATIVE:
            return {
                ...state,
                creative: action.creative,
                dynamicFields: action.dynamicFields,
            };
        case types.GET_CREATIVES_SUCCESS: {
            const newCreatives = action.creatives.map((item) => ({
                ...item,
                checked: state.checked.includes(item.id),
            }));
            const creatives = action.clearData
                ? newCreatives
                : [...state.creatives.filter((creative) => !creative.duplicationImitation), ...newCreatives];

            return {
                ...state,
                creatives: creatives.map((item) => ({ ...item, timestamp: getUnixTime(new Date(Date.now())) })),
                totalCount: action.totalCount,
                options: action.options,
                loadingData: false,
                firstLoad: false,
                generateSuccess: false,
                allChecked: creatives.every((c) => state.checked.includes(c.id)),
                creativesFetched: true,
            };
        }
        case types.CREATIVES_CHANGE_SOME: {
            const { creatives } = updateThumbnails(state.creatives, action.creativeThumbnails);

            return {
                ...state,
                creatives,
            };
        }
        case types.TOGGLE_CREATIVES_ITEM: {
            const checkedCreative = state.checked.find((creative) => creative?.id === action.id);

            return {
                ...state,
                checked: checkedCreative
                    ? state.checked.filter((creative) => creative.id !== action.id)
                    : [...state.checked, state.creatives.find((creative) => creative?.id === action.id)],
                creatives: state.creatives.map((item) =>
                    item.id === action.id ? { ...item, checked: !item.checked } : item,
                ),
            };
        }
        case types.TOGGLE_CREATIVES_ALL:
            return {
                ...state,
                allChecked: !state.allChecked,
                creatives: state.creatives.map((item) => ({ ...item, checked: !state.allChecked })),
                checked: state.allChecked
                    ? (() => {
                          const res = Object.assign(state.checked);
                          state.creatives.forEach((c) => {
                              res.splice(res.indexOf(c.id), 1);
                          });

                          return res;
                      })()
                    : [...state.checked, ...state.creatives.map((c) => c.id).filter((c) => !state.checked.includes(c))],
            };
        case types.DESELECT_CREATIVES_ALL:
            return {
                ...state,
                allChecked: false,
                creatives: state.creatives.map((item) => ({ ...item, checked: false })),
                checked: [],
            };
        case types.SET_CREATIVES_DEFAULTS:
            return {
                ...state,
                ...initialState,
            };
        case types.CREATIVE_DELETE_MODAL_TOGGLE:
            return {
                ...state,
                deleteModal: action.show,
            };
        case types.DELETE_CREATIVES_IN_PROGRESS:
            return {
                ...state,
                deleteInProgress: true,
            };
        case types.DELETE_CREATIVES_SUCCESS:
            return {
                ...state,
                deleteModal: false,
                deleteInProgress: false,
                checked: state.checked.filter((c) => !action.ids.includes(c)),
            };
        case types.DELETE_CREATIVES_FAIL:
            return {
                ...state,
                deleteInProgress: false,
                deleteModal: false,
            };
        case types.CREATIVE_DUPLICATE_MODAL_TOGGLE:
            return {
                ...state,
                duplicateModal: action.show,
            };
        case types.DUPLICATE_CREATIVES_IN_PROGRESS:
            return {
                ...state,
                duplicateModal: false,
                duplicationInProgress: true,
                creatives: [
                    ...action.ids.map((_, index) => ({
                        duplicationImitation: true,
                        ...action.creativesCopyInfo[index],
                    })),
                    ...state.creatives.map((c) => ({
                        ...c,
                        checked: action.ids.includes(c.id) ? false : c.checked,
                    })),
                ],
            };
        case types.IMPORT_CREATIVES_GHOST_CARDS:
            return {
                ...state,
                creatives: [...action.placeholderDesigns, ...state.creatives],
            };
        case types.DUPLICATE_CREATIVES_SUCCESS:
            return {
                ...state,
                duplicateModal: false,
                duplicationInProgress: false,
                checked: state.checked.filter((c) => !action.ids.includes(c)),
            };
        case types.DUPLICATE_CREATIVES_FAIL:
            return {
                ...state,
                duplicationInProgress: false,
                duplicateModal: false,
                creatives: state.creatives.filter((creatives) => !creatives.duplicationImitation),
            };
        case types.UPDATE_CREATIVE_VARIATIONS_COUNT: {
            if (!state.creative) {
                return state;
            }

            return {
                ...state,
                creative: {
                    ...state.creative,
                    videosCount: action.count,
                },
            };
        }
        case types.RENAME_CREATIVE_IN_PROGRESS:
            return {
                ...state,
                renamingInProgress: true,
            };
        case types.RENAME_CREATIVE_SUCCESS:
            return {
                ...state,
                renamingInProgress: false,
            };
        case types.RENAME_CREATIVE_FAILURE:
            return {
                ...state,
                renamingInProgress: false,
            };
        default:
            return state;
    }
}
