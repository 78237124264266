import { useMemo, useState } from 'react';
import { excludeChildren, excludeParents } from './utils';

const useSearch = (creativeModel, selectedElement) => {
    const [searchValue, setSearch] = useState('');

    const modelElements = useMemo(() => {
        const allElements = creativeModel.getAllElementsRecursively();
        const allElementsNoParents = excludeParents(selectedElement, allElements);
        const allElementsNoParentsNoChildren = excludeChildren(selectedElement, allElementsNoParents);

        return allElementsNoParentsNoChildren.filter(
            (element) =>
                selectedElement.id !== element.id &&
                selectedElement.id !== element?.mask?.elementId &&
                selectedElement.id !== element?.parent?.mask?.elementId,
        );
    }, [creativeModel, selectedElement]);

    const searchResults = useMemo(
        () => modelElements.filter((element) => element.name.toLowerCase().includes(searchValue.toLowerCase())),
        [searchValue, selectedElement],
    );

    return {
        searchValue,
        searchResults,
        setSearch,
    };
};

export default useSearch;
