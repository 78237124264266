import React, { useEffect, useState } from 'react';
import { useTranslate } from '@bynder/localization';
import { List, Thumbnail } from '@bynder/design-system';
import { IconVolumeOff, IconVolumeUp } from '@bynder/icons';
import generateTestId from '~/helpers/testIdHelpers';
import features from '~/configs/features';
import useAccessRights from 'packages/hooks/useAccessRights';
import { AudioFeature } from '../../../FormComponents/AudioFeature';

const Audio = ({ creativeModel, selectedElement, disabled, frameRate }) => {
    const { translate } = useTranslate();
    const { isPlatformAllowed } = useAccessRights();
    const { id, locked, useAudio, startFrame, duration, volume, fadeIn, fadeOut } = selectedElement;
    const [useAudioValue, setUseAudioValue] = useState(useAudio);
    const playbackDuration = creativeModel?.getPlaybackDuration()?.getDuration();
    const durationSeconds = (duration - Math.max(0, startFrame + duration - playbackDuration)) / frameRate;

    const handleOnChange = (newValue: boolean) => {
        setUseAudioValue(newValue);
        const param = {
            useAudio: newValue,
        };
        creativeModel.updateElement(id, param);
    };

    const onToggle = () => {
        handleOnChange(!useAudioValue);
    };

    const handleVolumeChange = (volume: number) => {
        creativeModel.updateElement(id, { volume });
    };

    const handleFadeInChange = (fadeIn: number) => {
        creativeModel.updateElement(id, { fadeIn });
    };

    const handleFadeOutChange = (fadeOut: number) => {
        creativeModel.updateElement(id, { fadeOut });
    };

    useEffect(() => {
        setUseAudioValue(useAudio);
    }, [useAudio]);

    const listItem = generateTestId('shots_video_use_audio');

    return (
        <>
            <List.Item
                thumbnail={<Thumbnail size="m" icon={useAudioValue ? <IconVolumeUp /> : <IconVolumeOff />} />}
                checkedVariant="switch"
                onClick={onToggle}
                isChecked={!!useAudioValue}
                isDisabled={locked || disabled}
                {...listItem}
            >
                {translate('editor.sidebar.shots.video.use-audio.label')}
            </List.Item>
            {isPlatformAllowed([features.MULTI_TRACK_AUDIO]) && useAudioValue && (
                <AudioFeature
                    isDisabled={locked || disabled}
                    duration={durationSeconds}
                    volume={volume}
                    fadeIn={fadeIn}
                    fadeOut={fadeOut}
                    onVolumeChange={handleVolumeChange}
                    onFadeInChange={handleFadeInChange}
                    onFadeOutChange={handleFadeOutChange}
                />
            )}
        </>
    );
};

export default Audio;
