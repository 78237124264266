import React from 'react';
import { Button } from '@bynder/design-system';

import useExport from '../hooks/useExport';

export const DeselectAll = () => {
    const { selectedCount, deselectAll } = useExport();

    if (!selectedCount) return null;

    return (
        <Button variant="clean" onClick={deselectAll}>
            Deselect all
        </Button>
    );
};
