import React from 'react';
import { Button, Tooltip } from '@bynder/design-system';
import { IconGridView, IconListView } from '@bynder/icons';
import useQueryParams from 'packages/hooks/useQueryParams';
import useExport from '../hooks/useExport';

const options = [
    {
        tooltip: 'Change to list view',
        value: 'grid',
        icon: <IconGridView />,
        nextValue: 'list',
    },
    {
        tooltip: 'Change to grid view',
        value: 'list',
        icon: <IconListView />,
        nextValue: 'grid',
    },
];

export const View = () => {
    const { setQueryParam } = useQueryParams();
    const { view, setView } = useExport();
    const selected = options.find((option) => option.value === view);

    return (
        <Tooltip content={selected.tooltip}>
            <Button
                variant="clean"
                icon={selected.icon}
                onClick={() => {
                    setView(selected.nextValue);
                    setQueryParam('view', selected.nextValue);
                }}
            />
        </Tooltip>
    );
};
