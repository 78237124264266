import { useEffect } from 'react';
import { isInteractiveElement } from '@bynder-studio/misc';
import { isArrowKey } from 'packages/pages/utils';
import useEditor from '~/hooks/useEditor';

// Shortcuts positioning for <b>image</b> design.
// Important: Element positioning in <b>video</b> design is implemented in
// usePlaybackShorcuts because it is bound to the video playback
export const useElementPositionShortcuts = ({ isImageDesign }: { isImageDesign: boolean }) => {
    const { manipulationRenderer } = useEditor();

    useEffect(() => {
        if (!isImageDesign) {
            return;
        }

        const handleKeyDown = (event: KeyboardEvent) => {
            if (isInteractiveElement(event) || !isArrowKey(event) || !manipulationRenderer) {
                return;
            }

            manipulationRenderer.canvasManipulation.moveElementWithKeys(event);
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isImageDesign, manipulationRenderer]);
};
