import styled from 'styled-components';
import { Button, token } from '@bynder/design-system';

export const PageNameButton = styled(Button)`
    max-width: 335px;
`;

export const WarningIconWrapper = styled.div`
    margin-right: ${token.spacing3};
`;
