export type FigmaFileResult =
    | {
          status: 'not started';
      }
    | {
          status: 'loading';
      }
    | {
          status: 'success';
          value: FigmaFile;
      }
    | {
          status: 'not found';
      }
    | {
          status: 'error';
          cause?: 'not authorized';
      }
    | {
          status: 'error';
          cause?: 'bad request';
      };

export type FigmaFrame = {
    id: string;
    name: string;
    imageUrl: string;
    width: number;
    height: number;
};

export type FigmaPage = {
    id: string;
    name: string;
    frames: FigmaFrame[];
};

export type FigmaFile = {
    key: string;
    id: string;
    name: string;
    pages: FigmaPage[];
};

export type FigmaSelectionState = {
    fileResult: FigmaFileResult;
    selectedPage?: string;
    selectedFrameIds: string[];
    isLimitReached: boolean;
    multiDesignMode: boolean;
};

export const FIGMA_FRAMES_TOGGLE = 'FIGMA_FRAMES_TOGGLE';
export const FIGMA_PAGE_SELECTION = 'FIGMA_PAGE_SELECTION';
export const FIGMA_CLOSE_LIMIT_REACHED_MODAL = 'FIGMA_CLOSE_LIMIT_REACHED_MODAL';
export const FIGMA_SET_FILE = 'FIGMA_SET_FILE';
export const FIGMA_SET_MULTI_DESIGN_MODE = 'FIGMA_SET_MULTI_DESIGN_MODE';
