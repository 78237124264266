import React from 'react';
import { TimelineResizingStats } from './ResizeStats.styled';

export const ResizeStats = ({ resizeStatsRef }) => {
    return (
        <TimelineResizingStats ref={resizeStatsRef}>
            <span className="timeline__resizing-start-frame-diff" />
            <span className="timeline__resizing-duration-name">Duration:</span>
            <span className="timeline__resizing-duration" />
        </TimelineResizingStats>
    );
};
