import { v4 as uuidv4 } from 'uuid';
import { truthy } from '@bynder-studio/misc';
import { Reason, TextStyle } from '../../types';
import { DynamicEventEmitter } from '../../Helpers/DynamicEventEmitter';
import { cleanupNullValues, deepClone, equals } from '../../Helpers/utils';
import { TextProps } from '@bynder-studio/structured-text';

const composeStyleName = (name: string) => (name || '').trim();
const allowedKeys = [
    'fontId',
    'fontSize',
    'leading',
    'tracking',
    'textTransform',
    'textDecoration',
    'textScript',
    'stroke',
    'paragraphSpacing',
];
const cleanUpStyle = <T extends Partial<TextStyle>>(style: T): Omit<Omit<T, 'uuid'>, 'name'> => {
    const cleanStyle = {} as Omit<Omit<T, 'uuid'>, 'name'>;
    allowedKeys.forEach((key) => {
        if (key in style) {
            cleanStyle[key] = style[key];
        }
    });
    return cleanStyle;
};

export class TextStyles {
    private styles: TextStyle[] = [];
    private eventEmitter: DynamicEventEmitter;

    static removeStyleId(textProps: TextProps, styleId: string): TextProps {
        const newTextProps = deepClone(textProps);
        newTextProps.runs.forEach((run) => {
            if (run.styleId === styleId) {
                run.styleId = null;
            }
        });
        return newTextProps;
    }

    static updateStyle(
        textProps: TextProps,
        styleId: string,
        newValue: Partial<Omit<TextStyle, 'uuid' | 'name'>>,
        oldValue: Partial<TextStyle>,
    ): TextProps {
        const newTextProps = deepClone(textProps);
        const keys = Object.keys(newValue);
        if (keys.includes('fontId')) {
            (newValue as any).fontId = newValue.fontId.toString();
            (oldValue as any).fontId = oldValue.fontId.toString();
        }
        newTextProps.runs.forEach((run) => {
            if (run.styleId === styleId) {
                keys.forEach((key) => {
                    if (run[key] === oldValue[key]) {
                        run[key] = newValue[key];
                    }
                });
            }
        });
        return newTextProps;
    }

    private populateNameAndUuid(styles: TextStyle[]): TextStyle[] {
        return styles
            .map(cleanupNullValues)
            .map(({ name, uuid, ...style }) => {
                if (Object.keys(style).length === 0) {
                    return null;
                }
                return {
                    uuid: uuid || uuidv4(),
                    name: composeStyleName(name),
                    ...cleanUpStyle(style),
                };
            })
            .filter(truthy);
    }

    get list() {
        return this.styles;
    }

    setEventEmitter(eventEmitter: DynamicEventEmitter) {
        this.eventEmitter = eventEmitter;
    }

    loadStyles(styles: TextStyle[]) {
        this.styles = this.populateNameAndUuid(styles);
    }

    extendStyles(styles: TextStyle[]) {
        const updatedIds = new Map<string, string>();

        if (styles.length === 0) {
            return updatedIds;
        }

        const newStyles = styles.filter(
            ({ uuid, ...data }) =>
                !this.list.some(({ uuid: existingUuid, ...existingData }) => {
                    existingData.fontId = Number(existingData.fontId);
                    const isNewStyle = !equals(data, existingData);

                    if (!isNewStyle) {
                        updatedIds.set(uuid, existingUuid);
                    }

                    return !isNewStyle;
                }),
        );

        newStyles.forEach((style) => {
            const uuid = uuidv4();
            updatedIds.set(style.uuid, uuid);
            style.uuid = uuid;
        });

        this.styles = [...this.styles, ...this.populateNameAndUuid(newStyles)];

        return updatedIds;
    }

    createStyle(style: Omit<TextStyle, 'uuid'> & { uuid?: string }, reason: Reason = 'user'): string {
        const item = { ...cleanUpStyle(style), name: composeStyleName(style.name), uuid: style.uuid || uuidv4() };
        this.styles.push(item);
        this.eventEmitter.emit('textStylesChanged', {
            action: 'create',
            oldValue: null,
            newValue: item,
            reason,
        });
        return item.uuid;
    }

    updateStyle(style: Partial<TextStyle>, reason: Reason = 'user') {
        const index = this.styles.findIndex((s) => s.uuid === style.uuid);
        if (index !== -1) {
            const oldValue: Partial<TextStyle> = {};
            Object.entries(style).forEach(([key, value]) => {
                oldValue[key] = this.styles[index][key];
                this.styles[index][key] = value;
            });
            this.eventEmitter.emit('textStylesChanged', { action: 'update', oldValue, newValue: style, reason });
        }
    }

    updateStyles(styles: Partial<TextStyle>[]) {
        styles.forEach((style) => {
            this.updateStyle(style);
        });
    }

    updateStylesWithOrder(styles: TextStyle[]) {
        const deletedStylesIds = this.styles.map((style) => style.uuid);
        const reorderedStyles = styles.map((newStyle) => {
            const oldStyleIdx = this.styles.findIndex((style) => style.uuid === newStyle.uuid);
            const oldStyle = this.styles[oldStyleIdx];
            if (oldStyle.name !== newStyle.name) {
                this.updateStyle({ uuid: newStyle.uuid, name: newStyle.name });
            }

            deletedStylesIds[oldStyleIdx] = null;
            return oldStyle;
        });
        deletedStylesIds.forEach((styleId) => (styleId ? this.deleteStyle(styleId) : null));
        this.styles = reorderedStyles;
    }

    deleteStyle(uuid: string, reason: Reason = 'user') {
        const index = this.styles.findIndex((s) => s.uuid === uuid);
        if (index !== -1) {
            const [style] = this.styles.splice(index, 1);
            this.eventEmitter.emit('textStylesChanged', {
                action: 'remove',
                oldValue: style,
                newValue: null,
                reason,
            });
        }
    }

    getStyle(uuid: string) {
        const style = this.styles.find((s) => s.uuid === uuid);
        return style && deepClone(style);
    }

    applyStyle(obj: { styleId?: string }) {
        if (!('styleId' in obj)) {
            return obj;
        }
        if (!obj.styleId) {
            obj.styleId = null;
            return obj;
        }
        const style = this.getStyle(obj.styleId);
        if (!style) return obj;
        const { uuid, name, ...styleProps } = style;
        Object.entries(styleProps).forEach(([key, value]) => {
            if (value === undefined) return;
            obj[key] = value;
            if (key === 'fontId') {
                obj[key] = value.toString();
            }
        });
        return obj;
    }

    toObject() {
        return this.styles.map((item) => {
            return { ...item };
        });
    }
}
