import React from 'react';
import { BaseMultiPageModel } from '@bynder-studio/render-core';
import { Button, ListItem, Thumbnail, token } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconSyncSettings } from '@bynder/icons';
import generateTestId from '~/helpers/testIdHelpers';
import { useContentPropertyListModal } from '../../Shots/ContentProperty/useContentPropertyListModal';
import { useContentProperty } from '../../Shots/ContentProperty/useContentProperty';

const ContentPropertySettings = ({ creativeModel }: { creativeModel: BaseMultiPageModel }) => {
    const { translate } = useTranslate();

    const { allTypesProperties, onSave } = useContentProperty(creativeModel, {});

    const { renderContentPropertyListModal, setIsContentPropertyListModalOpen } = useContentPropertyListModal({
        creativeModel,
        properties: allTypesProperties,
        onSave,
    });

    return (
        <>
            <ListItem
                thumbnail={<Thumbnail backgroundColor={token.gray50a} icon={<IconSyncSettings />} />}
                rightElements={
                    <Button
                        variant="clean"
                        title={translate('editor.sidebar.globals.tab.design.properties.edit')}
                        isDisabled={!allTypesProperties.length}
                        onClick={() => setIsContentPropertyListModalOpen(true)}
                        {...generateTestId('design settings: content properties edit button')}
                    >
                        {translate('editor.sidebar.globals.tab.design.properties.edit')}
                    </Button>
                }
                subtext={
                    allTypesProperties.length > 0
                        ? translate('editor.sidebar.globals.tab.design.properties.subtext', {
                              count: allTypesProperties.length,
                          })
                        : translate('editor.sidebar.globals.tab.design.properties.subtext.no-properties')
                }
                {...generateTestId('design settings: content properties list item')}
            >
                {translate('editor.sidebar.globals.tab.design.properties.title')}
            </ListItem>
            {renderContentPropertyListModal()}
        </>
    );
};

export default ContentPropertySettings;
