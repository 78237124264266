import React, { useCallback, useEffect, useState } from 'react';
import { CreativeTypes } from '@bynder-studio/render-core';
import { useDispatch } from 'react-redux';
import { Dropdown, Divider, Toolbar } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconAdd } from '@bynder/icons';
import MultiSizeModal from 'packages/common/MultiSizeModal';
import useEditor from '~/hooks/useEditor';
import PageItem from './components/PageItem';
import PageItemName from './components/PageItemName';
import OptionsDropdown from './components/OptionsDropdown';
import DeleteModal from './components/PageDeleteModal';
import generateTestId from '~/helpers/testIdHelpers';
import PageRenameModal from './components/PageRenameModal';
import PageDuplicateModal from './components/PageDuplicateModal';
import { markAsUnsaved } from 'packages/store/creativeEditor/creativeEditor.actions';
import PageManageModal from './components/PageManageModal';
import { PagePreviewProvider } from '~/contexts/PagePreviewContext';
import { SortedMeta } from './types';

const MultiPageDropdown = () => {
    const { creativeModel, creativeType } = useEditor();
    const [sortedPagesMetaData, setSortedPagesMetaData] = useState<SortedMeta[]>([]);
    const [selectedPageIndex, setSelectedPageIndex] = useState(creativeModel.getCurrentPageIndex());
    const [isDuplicateModalOpened, setIsDuplicateModalOpened] = useState(false);
    const [isPageModalOpened, setIsPageModalOpened] = useState(false);
    const [isRenameModalOpened, setIsRenameModalOpened] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isPageManageOpen, setIsPageManageOpen] = useState(false);
    const isVideoModel = creativeType === 'VIDEO';
    const dispatch = useDispatch();
    const { translate } = useTranslate();

    const sortPagesMetaData = useCallback(() => {
        const models = creativeModel.getModels();
        const sortedPages = creativeModel
            .getModelsMetaData()
            .map((page, index) => ({
                ...page,
                index,
                dimensions: models[index].getDimensions(),
            }))
            .sort((a, b) => a.displayOrder - b.displayOrder);

        if (sortedPages) {
            setSortedPagesMetaData(sortedPages);
        }
    }, [creativeModel]);

    const handlePageModal = useCallback(() => {
        setIsPageModalOpened(!isPageModalOpened);
    }, [isPageModalOpened]);

    const handlePageSelect = useCallback(
        (index) => {
            creativeModel.setCurrentPageIndex(index);
            setSelectedPageIndex(index);
        },
        [creativeModel],
    );

    const handleOnPageRename = useCallback(
        (name) => {
            creativeModel.renamePage(selectedPageIndex, name);
            sortPagesMetaData();
            setIsRenameModalOpened(false);
            dispatch(markAsUnsaved());
        },
        [creativeModel, dispatch, sortPagesMetaData, selectedPageIndex],
    );

    const handleOnPageManage = (updatedPagesMeta: SortedMeta[]) => {
        const currentPageOrder = sortedPagesMetaData.map((page) => page.id);
        const updatedPageOrder = updatedPagesMeta.map((page) => page.id);
        const updatedIndexes = currentPageOrder.reduce((acc, id) => {
            const index = updatedPageOrder.indexOf(id);

            if (index !== -1) {
                acc.push(index);
            }

            return acc;
        }, [] as number[]);

        sortedPagesMetaData.forEach((pageMeta) => {
            if (!updatedPageOrder.includes(pageMeta.id)) {
                creativeModel.removePage(
                    creativeModel.getModelsMetaData().findIndex((meta) => meta.id === pageMeta.id),
                );
            }
        });

        // Rearrange models
        creativeModel.updatePageOrder(updatedIndexes, updatedPagesMeta);

        // Update sorted meta data
        setSortedPagesMetaData(updatedPagesMeta);
        dispatch(markAsUnsaved());
        setIsPageManageOpen(false);
    };

    const handleSizeModalConfirm = (name, { width, height, format }) => {
        creativeModel.addPage(name, width, height, format);
        dispatch(markAsUnsaved());
        setIsPageModalOpened(false);
    };

    const handleDuplicateModalConfirm = (name, { width, height, format }, contentPropertiesSettings) => {
        const duplicationData = {
            name,
            dimensions: { width, height, format },
        };

        creativeModel.duplicatePage(selectedPageIndex, duplicationData, contentPropertiesSettings);
        setIsDuplicateModalOpened(false);
        dispatch(markAsUnsaved());
    };

    const handleOptionSelect = (option) => {
        switch (option) {
            case 'rename':
                setIsRenameModalOpened(true);
                break;
            case 'duplicate':
                setIsDuplicateModalOpened(true);
                break;
            case 'delete':
                setIsDeleteModalOpen(true);
                break;
            default:
        }
    };

    const handleDeleteModalConfirm = useCallback(() => {
        creativeModel.removePage(selectedPageIndex);
        setIsDeleteModalOpen(false);
        dispatch(markAsUnsaved());
    }, [creativeModel, dispatch, selectedPageIndex]);

    const handleDeleteModalClose = useCallback(() => {
        setIsDeleteModalOpen(false);
    }, []);

    useEffect(() => {
        const handlePageAdded = ({ metaData, pageIndex }) => {
            const pageMetaData = {
                ...metaData,
                index: pageIndex,
            } as SortedMeta;

            const filteredMeta = sortedPagesMetaData.filter((page) => page.id !== pageMetaData.id);
            const pages = [...filteredMeta, pageMetaData];

            setSortedPagesMetaData(pages);
            dispatch(markAsUnsaved());
        };

        const handleCurrentSizeChange = (index) => {
            setSelectedPageIndex(index);
        };

        const handleSizeRenamed = () => sortPagesMetaData();
        const handleSizeRemoved = () => sortPagesMetaData();
        const handleOrderChanged = () => sortPagesMetaData();

        creativeModel.getEventEmitter().on('currentPageChange', handleCurrentSizeChange);
        creativeModel.getEventEmitter().on('pageAdded', handlePageAdded);
        creativeModel.getEventEmitter().on('pageRenamed', handleSizeRenamed);
        creativeModel.getEventEmitter().on('pageRemoved', handleSizeRemoved);
        creativeModel.getEventEmitter().on('pageOrderUpdated', handleOrderChanged);

        return () => {
            creativeModel.getEventEmitter().off('currentPageChange', handleCurrentSizeChange);
            creativeModel.getEventEmitter().off('pageAdded', handlePageAdded);
            creativeModel.getEventEmitter().off('pageRenamed', handleSizeRenamed);
            creativeModel.getEventEmitter().off('pageRemoved', handleSizeRemoved);
            creativeModel.getEventEmitter().off('pageOrderUpdated', handleOrderChanged);
        };
    }, [creativeModel, dispatch, sortPagesMetaData, sortedPagesMetaData]);

    useEffect(() => {
        sortPagesMetaData();
    }, []);

    return (
        <>
            <Toolbar>
                <Dropdown
                    trigger={({ isOpen, ...triggerProps }) => (
                        <PageItemName
                            triggerProps={triggerProps}
                            sizeMetaData={sortedPagesMetaData[selectedPageIndex]}
                            isVideoModel={isVideoModel}
                        />
                    )}
                    {...generateTestId('editor-multipage-dropdown')}
                >
                    {sortedPagesMetaData?.map((pageMeta) => {
                        const { name, index, id } = pageMeta;
                        const isSelected = index === selectedPageIndex;

                        return (
                            <React.Fragment key={id}>
                                <PageItem
                                    sizeIndex={index}
                                    name={name}
                                    isSelected={isSelected}
                                    onSelect={handlePageSelect}
                                    sizeMetaData={pageMeta}
                                    isVideoModel={isVideoModel}
                                />
                            </React.Fragment>
                        );
                    })}
                    <Dropdown.Item icon={<IconAdd />} onClick={handlePageModal}>
                        {translate('editor.page.dropdown.new')}
                    </Dropdown.Item>
                    <Divider />
                    <Dropdown.Item onClick={() => setIsPageManageOpen(true)}>
                        {translate('editor.page.dropdown.manage')}
                    </Dropdown.Item>
                </Dropdown>
                <Toolbar.Divider />
                <OptionsDropdown onSelect={handleOptionSelect} />
            </Toolbar>
            {isDuplicateModalOpened && (
                <PageDuplicateModal
                    pageMetaData={sortedPagesMetaData[selectedPageIndex]}
                    creativeModel={creativeModel}
                    onToggle={setIsDuplicateModalOpened}
                    handleConfirm={handleDuplicateModalConfirm}
                    creativeType={CreativeTypes[creativeType]}
                />
            )}
            <DeleteModal
                show={isDeleteModalOpen}
                onConfirm={handleDeleteModalConfirm}
                onClose={handleDeleteModalClose}
                pageName={sortedPagesMetaData[selectedPageIndex]?.name || ''}
            />
            {isPageModalOpened && (
                <MultiSizeModal
                    creativeModel={creativeModel}
                    onToggle={handlePageModal}
                    handleConfirm={handleSizeModalConfirm}
                    creativeType={CreativeTypes[creativeType]}
                />
            )}
            {isRenameModalOpened && (
                <PageRenameModal
                    show={isRenameModalOpened}
                    pageName={sortedPagesMetaData[selectedPageIndex]?.name}
                    onClose={() => setIsRenameModalOpened(false)}
                    onConfirm={handleOnPageRename}
                />
            )}
            {isPageManageOpen && (
                <PagePreviewProvider isEditor>
                    <PageManageModal
                        pages={sortedPagesMetaData}
                        show={isPageManageOpen}
                        onClose={() => setIsPageManageOpen(false)}
                        onConfirm={handleOnPageManage}
                    />
                </PagePreviewProvider>
            )}
        </>
    );
};

export default MultiPageDropdown;
