import { produce } from 'immer';
import { isImportedAnimation } from './editorHelper';

export const computeMetadata = (prevMetadata, property, value, selfUpdate = true) => {
    return produce(prevMetadata, (properties) => {
        if (property === 'duration') {
            const inDuration = (properties.animationIn && properties.animationIn.duration) || 0;
            const outDuration = (properties.animationOut && properties.animationOut.duration) || 0;

            if (inDuration + outDuration > value && value < properties.duration) {
                const newOutDuration = value - inDuration;

                if (newOutDuration > 0) {
                    properties.animationOut.duration = newOutDuration;
                } else {
                    properties.animationOut = null;
                    properties.animationIn && (properties.animationIn.duration = value);
                }
            }

            if (
                properties.animations &&
                properties.animations.length &&
                !isImportedAnimation(properties.animations[0].type) &&
                properties.animations[0].startFrame - properties.startFrame + properties.animations[0].duration >
                    value &&
                value < properties.duration
            ) {
                const animation = properties.animations[0];

                const elementStartFrame = properties.startFrame;
                const relativeStartFrame = animation.startFrame - elementStartFrame;

                const newDuration = value - relativeStartFrame;

                if (newDuration > 0) {
                    properties.animations[0].duration = newDuration;
                } else {
                    properties.animations = null;
                }
            }

            if (selfUpdate) {
                properties[property] = value;
            }
        } else if (property === 'startFrame') {
            if (
                properties.animations &&
                properties.animations.length &&
                !isImportedAnimation(properties.animations[0].type)
            ) {
                const animation = properties.animations[0];

                properties.animations[0].startFrame = animation.startFrame + value - properties.startFrame;
            }

            if (selfUpdate) {
                properties[property] = value;
            }
        } else if (property === 'animationIn' || property === 'animationOut') {
            if (!value) {
                properties[property] = value;
                return;
            }

            const inDuration = (properties.animationIn && properties.animationIn.duration) || 0;
            const outDuration = (properties.animationOut && properties.animationOut.duration) || 0;

            const newValue = { ...value };

            if (newValue) {
                if (!prevMetadata[property]) {
                    let freeTransitionDuration = properties.duration - (inDuration + outDuration);
                    freeTransitionDuration = freeTransitionDuration > 0 ? freeTransitionDuration : 0;

                    newValue.duration = Math.min(newValue.duration, freeTransitionDuration);
                }
            }

            properties[property] = newValue;
        } else if (property === 'animations') {
            properties[property] = value;
        }
    });
};

export const isValidProperty = (element, property) => {
    if (element && element.invalidProperties && element.invalidProperties.size) {
        return !element.invalidProperties.has(property);
    }

    return true;
};
