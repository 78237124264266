import React, { useEffect, useState } from 'react';
import { Button, Dropdown, token } from '@bynder/design-system';
import { IconStateIndicatorXL, IconStateIndicatorXLOutline } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import useQueryParams from 'packages/hooks/useQueryParams';
import { StatusFilterType, StatusFilterProps } from './types';

const Status = ({ value, onSelect, ...props }: StatusFilterProps) => {
    const [status, setStatus] = useState(value);
    const { translate } = useTranslate();
    const { getQueryParam, resetQueryParams } = useQueryParams();

    const onSortBySelect = (newSortBy: StatusFilterType) => {
        setStatus(newSortBy);
        onSelect(newSortBy);
    };

    const onAnySelected = () => {
        resetQueryParams(['approvalStatus']);
        setStatus(StatusFilterType.ANY_STATUS);
        onSelect(StatusFilterType.ANY_STATUS);
    };

    const statusTitles = {
        [StatusFilterType.ANY_STATUS]: translate('filters.status.any_status'),
        [StatusFilterType.DRAFT]: translate('filters.status.draft'),
        [StatusFilterType.IN_REVIEW]: translate('filters.status.in_review'),
        [StatusFilterType.EDITS_REQUESTED]: translate('filters.status.edits_requested'),
        [StatusFilterType.APPROVED]: translate('filters.status.approved'),
        [StatusFilterType.NO_STATUS]: translate('filters.status.no_status'),
    };

    const iconsMap = {
        DRAFT: <IconStateIndicatorXL style={{ color: token.gray400 }} />,
        IN_REVIEW: <IconStateIndicatorXL style={{ color: token.yellow500 }} />,
        EDITS_REQUESTED: <IconStateIndicatorXL style={{ color: token.red500 }} />,
        APPROVED: <IconStateIndicatorXL style={{ color: token.green500 }} />,
        NO_STATUS: <IconStateIndicatorXLOutline style={{ color: token.gray400 }} />,
    };

    const getSelectionStatusIcon = () =>
        status && status !== StatusFilterType.ANY_STATUS ? { icon: iconsMap[status] } : null;

    useEffect(() => {
        const queriedStatus = getQueryParam('approvalStatus')?.toUpperCase();

        if (queriedStatus && Object.keys(StatusFilterType).includes(queriedStatus)) {
            setStatus(StatusFilterType[queriedStatus]);
        } else {
            setStatus(StatusFilterType.ANY_STATUS);
        }
    }, []);

    useEffect(() => {
        if (value) {
            setStatus(value);
        } else {
            setStatus(StatusFilterType.ANY_STATUS);
        }
    }, [value]);

    return (
        <>
            <Dropdown
                position="top-left"
                trigger={({ isOpen, ...triggerProps }) => (
                    <Button
                        data-testid="overview review status filter"
                        variant="clean"
                        isPressed={isOpen}
                        {...triggerProps}
                        aria-label={translate('filters.status.label')}
                        rightIcon={<Dropdown.Arrow />}
                        {...getSelectionStatusIcon()}
                    >
                        {!status ? statusTitles[StatusFilterType.ANY_STATUS] : statusTitles[status]}
                    </Button>
                )}
                {...props}
            >
                {status !== StatusFilterType.ANY_STATUS && (
                    <>
                        <Dropdown.Item onClick={onAnySelected}>{translate('filters.status.any_status')}</Dropdown.Item>
                        <Dropdown.Divider />
                    </>
                )}
                <Dropdown.Item
                    onClick={() => onSortBySelect(StatusFilterType.DRAFT)}
                    isChecked={status === StatusFilterType.DRAFT}
                    icon={iconsMap[StatusFilterType.DRAFT]}
                >
                    {translate('filters.status.draft')}
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => onSortBySelect(StatusFilterType.IN_REVIEW)}
                    isChecked={status === StatusFilterType.IN_REVIEW}
                    icon={iconsMap[StatusFilterType.IN_REVIEW]}
                >
                    {translate('filters.status.in_review')}
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => onSortBySelect(StatusFilterType.EDITS_REQUESTED)}
                    isChecked={status === StatusFilterType.EDITS_REQUESTED}
                    icon={iconsMap[StatusFilterType.EDITS_REQUESTED]}
                >
                    {translate('filters.status.edits_requested')}
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => onSortBySelect(StatusFilterType.APPROVED)}
                    isChecked={status === StatusFilterType.APPROVED}
                    icon={iconsMap[StatusFilterType.APPROVED]}
                >
                    {translate('filters.status.approved')}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                    onClick={() => onSortBySelect(StatusFilterType.NO_STATUS)}
                    isChecked={status === StatusFilterType.NO_STATUS}
                    icon={iconsMap[StatusFilterType.NO_STATUS]}
                >
                    {translate('filters.status.no_status')}
                </Dropdown.Item>
            </Dropdown>
        </>
    );
};

export default Status;
