import React from 'react';
import { Card, EmptyState, List, Thumbnail } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconFrame } from '@bynder/icons';
import { Grid } from 'packages/ds/Grid/Grid';
import { type FigmaFrame as FigmaFrameType } from 'packages/store/figma/types';

type FrameProps = {
    frame: FigmaFrameType;
    isSelected: boolean;
    toggleFrame: (frameId: string) => void;
    isListView?: boolean;
    isLast?: boolean;
};

export type FigmaFramesProps = {
    frames: FigmaFrameType[];
    selectedFramesIds: string[];
} & Pick<FrameProps, 'toggleFrame' | 'isListView'>;

export default function FigmaFrames({ frames, selectedFramesIds, toggleFrame, isListView }: FigmaFramesProps) {
    const { translate } = useTranslate();

    if (frames.length === 0) {
        return (
            <EmptyState
                title={translate('modal.design.create.design.figma.frames.no-frames.title')}
                text={translate('modal.design.create.design.figma.frames.not-frames.text')}
                icon={<IconFrame />}
            />
        );
    }

    if (isListView) {
        return (
            <List>
                {frames.map((frame, idx) => (
                    <Frame
                        key={frame.id}
                        frame={frame}
                        isSelected={selectedFramesIds.includes(frame.id)}
                        toggleFrame={toggleFrame}
                        isListView
                        isLast={idx === frames.length - 1}
                    />
                ))}
            </List>
        );
    }

    return (
        <Grid>
            {frames.map((frame) => (
                <Frame
                    key={frame.id}
                    frame={frame}
                    isSelected={selectedFramesIds.includes(frame.id)}
                    toggleFrame={toggleFrame}
                />
            ))}
        </Grid>
    );
}

function Frame({ frame, isSelected, toggleFrame, isListView, isLast }: FrameProps) {
    const onSelect = () => toggleFrame(frame.id);
    const size = `${frame.width} × ${frame.height} px`;

    if (isListView) {
        return (
            <>
                <List.Item
                    onClick={onSelect}
                    checkedVariant="checkbox"
                    isChecked={isSelected}
                    thumbnail={<Thumbnail variant="preview" imageUrl={frame.imageUrl} />}
                    subtext={size}
                >
                    {frame.name}
                </List.Item>
                {!isLast && <List.Divider />}
            </>
        );
    }

    return (
        <Card onSelect={onSelect} onClick={onSelect} isSelected={isSelected} variant="clean" selectButtonAriaLabel="  ">
            <Card.Frame>
                <Card.Images>
                    <Card.Img src={frame.imageUrl} />
                </Card.Images>
            </Card.Frame>
            <Card.Header title={frame.name} detail={size} />
        </Card>
    );
}
