import { useEffect } from 'react';
import { ElementUpdateTypes } from '@bynder-studio/render-core';
import useForceUpdate from '~/hooks/useForceUpdate';
import useEditor from '~/hooks/useEditor';
import useEditorSelectedElement from '~/hooks/useEditorSelectedElement';

const useMaskApply = () => {
    const forceUpdate = useForceUpdate();
    const { creativeModel } = useEditor();
    const { selectedElement } = useEditorSelectedElement();

    useEffect(() => {
        return () => {
            creativeModel.onElementPartialUpdate(selectedElement.id, ElementUpdateTypes.MASK, forceUpdate);
            creativeModel.onElementPartialUpdate(selectedElement.id, ElementUpdateTypes.SHAPE, forceUpdate);
        };
    }, [selectedElement.id, creativeModel]);
};

export default useMaskApply;
