import React from 'react';
import { GroupElement } from '@bynder-studio/render-web';
import { Form, InteractiveIcon } from '@bynder/design-system';
import { IconLockOpenSmall, IconLockSmall } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import generateTestId from '~/helpers/testIdHelpers';

const LockToggler = ({ selectedElement, forceUpdate }) => {
    const { translate } = useTranslate();

    const { locked, lockUniScaling } = selectedElement;
    const isGroup = selectedElement instanceof GroupElement;
    const value = isGroup ? true : lockUniScaling;

    const lockRatioTestId = generateTestId('shots_properties_lock_ratio');

    const handleChangeLockUniScaling = () => {
        // update prop on element, originally we don't store this prop on element
        selectedElement.lockUniScaling = !selectedElement.lockUniScaling;
        forceUpdate();
    };

    return (
        <Form.Group>
            <InteractiveIcon
                label={translate('editor.sidebar.shots.width.lock.label')}
                onClick={() => !isGroup && handleChangeLockUniScaling()}
                icon={value ? <IconLockSmall /> : <IconLockOpenSmall />}
                isDisabled={locked || isGroup}
                {...lockRatioTestId}
            />
        </Form.Group>
    );
};

export default LockToggler;
