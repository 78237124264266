import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useQueryParams from 'packages/hooks/useQueryParams';
import Design from 'packages/pages/design';
import { SELECTED_CORRUPTED_DESIGN } from 'packages/pages/designs';
import { getShotsFromPageAccordingDuration } from '~/helpers/template';
import { resetEditor, IS_PUBLISHED, getCreativeTemplate } from 'packages/store/creativeEditor/creativeEditor.actions';
import { creativeEditorSelector } from 'packages/store/creativeEditor/creativeEditor.selectors';
import { PageWithAppcues } from '~/helpers/RouteWithAppcues';
import { getCreative } from 'packages/store/creatives/creatives.selectors';
import { getColorPalettes } from 'packages/store/brandColors/colorPalettes.actions';
import { colorPalettesFetching } from 'packages/store/brandColors/brandColors.selectors';
import { fetchCreative } from '~/store/creatives/common.actions';
import { initReview } from 'packages/store/review/review.actions';
import { hideLoading, showLoading } from 'react-redux-loading-bar';

const Generate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams<{ creativeId: string }>();
    const { getQueryParam, setQueryParam } = useQueryParams();

    const isFetchingPalettes = useSelector(colorPalettesFetching);
    const { template, fonts, textStyles, contentProperties } = useSelector(creativeEditorSelector);
    const creative = useSelector(getCreative);
    const creativeType = creative?.creativeType;

    const templateWithShots = useMemo(() => {
        if (!template) {
            return undefined;
        }

        if (creativeType !== 'VIDEO') {
            return template;
        }

        return {
            ...template,
            pages: template.pages.map((page) => ({
                ...page,
                shots: getShotsFromPageAccordingDuration(page),
            })),
        };
    }, [template, creativeType]);

    useEffect(() => {
        const id = params.creativeId;

        dispatch(fetchCreative(id));
        dispatch(getCreativeTemplate(id, true, 'create'));
        dispatch(getColorPalettes());

        return () => {
            dispatch(resetEditor());
            dispatch(initReview());
        };
    }, [dispatch, params.creativeId]);

    useEffect(() => {
        if (!creative) {
            return;
        }

        const isPublished = getQueryParam(IS_PUBLISHED);

        if (isPublished) {
            setQueryParam(IS_PUBLISHED, null);
            return;
        }

        if (creative.corruptionReason) {
            navigate(`/designs?${SELECTED_CORRUPTED_DESIGN}=${creative.id}`, { replace: true });
        }
    }, [creative, history]);

    const isLoaded = Boolean(templateWithShots && creative && !isFetchingPalettes && fonts);

    useEffect(() => {
        dispatch(isLoaded ? hideLoading() : showLoading());
    }, [isLoaded, dispatch]);

    if (!isLoaded) {
        return null;
    }

    return (
        <PageWithAppcues>
            <Design
                creativeType={creative.creativeType}
                creativeId={creative.id}
                creativeVersionId={creative.versionId}
                creativeName={creative.name}
                isMultiVersionCreative={creative.applyVersioning}
                template={templateWithShots}
                fonts={fonts}
                textStyles={textStyles}
                contentProperties={contentProperties}
            />
        </PageWithAppcues>
    );
};

export default Generate;
