import { useSyncExternalStore } from 'react';
import { variationsThumbnailsStore } from 'packages/pages/design/VariationsContext/variationsThumbnailsStore';

export const useVariationPageThumbnail = (
    variationId: string,
    pageIndex: number,
    oldVariationId?: string,
    width?: number,
    height?: number,
) => {
    const variationsThumbnails = useSyncExternalStore(
        variationsThumbnailsStore.subscribe,
        variationsThumbnailsStore.getSnapshot,
    );

    const w = width || variationsThumbnailsStore.getDefaultThumbnailSize().width;
    const h = height || variationsThumbnailsStore.getDefaultThumbnailSize().height;

    const size = `${w}x${h}`;

    const thumbnailsReady = variationsThumbnailsStore.isThumbnailsReady(variationId, pageIndex, w, h);

    const variationPageThumbnail = ((variationsThumbnails[oldVariationId || variationId] ||
        variationsThumbnails[variationId] ||
        [])[pageIndex] || {})[size];

    const loading = !thumbnailsReady || !variationPageThumbnail?.dataUrl;

    return { variationPageThumbnail, loading };
};
