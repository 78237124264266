import React, { useCallback, useEffect, useRef } from 'react';
import useEditor from '~/hooks/useEditor';
import { useHorizontalDragging } from '../dragging/useHorizontalDragging';
import { addZeroIfNeeded, convertFrameToSecMinFrame } from '../../helpers/frame-to-time-converter';
import { PlayheadStyled } from './Playhead.styled';

/**
 * Update tooltip visibility and value
 * @param {number} frame
 * @param {number} frameRate
 * @param {boolean} show
 */
const updateTooltip = (frame, frameRate, show) => {
    // HTML element
    const tooltip = document.querySelector('#tooltiptext');
    // Convert frame to minutes:seconds:frames
    const timeIndicatorObj = convertFrameToSecMinFrame(frame, frameRate);
    // Update the tooltip
    tooltip.innerHTML =
        addZeroIfNeeded(timeIndicatorObj.minutes) +
        ':' +
        addZeroIfNeeded(timeIndicatorObj.seconds) +
        ':' +
        addZeroIfNeeded(timeIndicatorObj.frame);
    tooltip.classList.remove(show ? 'hidden' : 'visible');
    tooltip.classList.add(show ? 'visible' : 'hidden');
};

const Playhead = ({ containerRef, elementContainerRef, duration, timelineDuration, scrollContainerRef }) => {
    const { manipulationRenderer, creativeModel } = useEditor();

    const controlRef = useRef(null);
    const draggerRef = useRef(null);
    const lineRef = useRef(null);
    const tooltipRef = useRef(null);

    const frameRef = useRef(manipulationRenderer?.getCurrentFrame() ?? 0);

    const handleContainerAndControlChanges = useCallback(() => {
        const { x: elementsWrapperX, width: elementsWrapperWidth } = containerRef.current.getBoundingClientRect();
        const { x: controlX } = controlRef.current.getBoundingClientRect();
        const { offsetWidth: tooltipWidth } = tooltipRef.current;
        const { offsetWidth: draggerWidth } = draggerRef.current;

        const tooltipMargin = -(tooltipWidth / 2 - draggerWidth / 2);

        if (controlX - tooltipWidth / 2 < elementsWrapperX) {
            // left
            const marginLeft = tooltipMargin - (controlX - tooltipWidth / 2 - elementsWrapperX);
            tooltipRef.current.style.marginLeft = `${marginLeft}px`;
        } else if (controlX + tooltipWidth / 2 > elementsWrapperWidth + elementsWrapperX) {
            // right
            const marginLeft = tooltipMargin - (controlX + tooltipWidth / 2 - elementsWrapperWidth - elementsWrapperX);
            tooltipRef.current.style.marginLeft = `${marginLeft}px`;
        } else {
            tooltipRef.current.style.marginLeft = `${tooltipMargin}px`;
        }

        if (controlX < elementsWrapperX || controlX > elementsWrapperWidth + elementsWrapperX) {
            controlRef.current.style.visibility = 'hidden';
        } else {
            controlRef.current.style.visibility = 'initial';
        }
    }, [containerRef.current, controlRef.current]);

    useEffect(() => {
        const observer = new MutationObserver(handleContainerAndControlChanges);
        observer.observe(elementContainerRef.current, { attributes: true, childList: false, subtree: false });
        observer.observe(controlRef.current, { attributes: true, childList: false, subtree: false });

        return () => observer.disconnect();
    }, [elementContainerRef.current]);

    const handleContainerScroll = useCallback((event) => {
        draggerRef.current.style.top = `${event.target.scrollTop}px`;
        lineRef.current.style.marginTop = `${event.target.scrollTop}px`;
        lineRef.current.style.minHeight = `calc(100% - ${event.target.scrollTop}px)`;
    }, []);

    useEffect(() => {
        handleContainerAndControlChanges();
        scrollContainerRef.current.addEventListener('scroll', handleContainerScroll);

        return () =>
            scrollContainerRef.current &&
            scrollContainerRef.current.removeEventListener('scroll', handleContainerScroll);
    }, [handleContainerScroll, frameRef.current]);

    useEffect(() => {
        const frameRate = creativeModel.getPlaybackDuration().getFrameRate();
        updateTooltip(frameRef.current, frameRate, false);
    }, [creativeModel, duration, timelineDuration]);

    const onDrag = (leftPercentage) => {
        let newFrame = (duration / 100) * leftPercentage;
        newFrame = Math.trunc(newFrame);

        if (frameRef.current !== newFrame) {
            frameRef.current = newFrame;
            manipulationRenderer?.setCurrentFrame(newFrame);
        }

        const frameRate = creativeModel.getPlaybackDuration().getFrameRate();
        updateTooltip(newFrame, frameRate, true);
    };

    const calcControlLeft = useCallback((frame) => `${(frame / duration) * 100}%`, [duration]);

    const onDrop = useCallback(() => {
        const frameRate = creativeModel.getPlaybackDuration().getFrameRate();
        const leftPercentage = parseFloat(controlRef.current.style.left, 10);
        const frame = Math.round((duration / 100) * leftPercentage);

        controlRef.current.style.left = calcControlLeft(frame);
        frameRef.current = frame;
        updateTooltip(frameRef.current, frameRate, false);
        manipulationRenderer?.setCurrentFrame(frameRef.current);
    }, [creativeModel, updateTooltip, duration, manipulationRenderer]);

    const handleDrag = useHorizontalDragging({
        controlRef,
        containerRef: elementContainerRef,
        maxContainerRef: containerRef,
        onDrag,
        onDrop,
    });

    useEffect(() => {
        if (!manipulationRenderer) {
            return;
        }

        const frameUpdateHandler = (frame) => {
            if (frameRef.current !== frame) {
                frameRef.current = frame;
                controlRef.current.style.left = calcControlLeft(frame);
                const frameRate = creativeModel.getPlaybackDuration().getFrameRate();
                updateTooltip(frameRef.current + 1, frameRate, false);
            }
        };

        if (frameRef.current > duration) {
            manipulationRenderer.setCurrentFrame(duration);
            frameUpdateHandler(duration);
        }

        frameRef.current = manipulationRenderer.getCurrentFrame();

        manipulationRenderer.eventEmitter.on('frameUpdate', frameUpdateHandler);

        return () => manipulationRenderer.eventEmitter.off('frameUpdate', frameUpdateHandler);
    }, [manipulationRenderer, duration]);

    const left = `${(frameRef.current / duration) * 100}%`;

    return (
        <PlayheadStyled className="tooltipied" ref={controlRef} onMouseDown={handleDrag} style={{ left }}>
            <div id="draggerpunch" className="Playhead-dragger" ref={draggerRef}>
                <span className="tooltiptext" id="tooltiptext" ref={tooltipRef}>
                    00:00:00
                </span>
                <div className="Playhead-dragger-punch" id="Playhead-dragger-punch" />
            </div>
            <div className="Playhead-line" ref={lineRef} />
        </PlayheadStyled>
    );
};

export default Playhead;
