import React from 'react';
import { useSelector } from 'react-redux';
import { Divider, Tabs } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import useEditor from '~/hooks/useEditor';
import { ExportSettings } from 'packages/pages/editor/RightSideMenu/Globals/exportSettings/ExportSettings';
import useAccessRights from 'packages/hooks/useAccessRights';
import features from '~/configs/features';
import { InspectorContent, InspectorHeader, Section } from '../index.styled';
import PosterFrameInput from './PosterFrameInput';
import DurationInput from './DurationInput';
import BackgroundColorInput from './BackgroundColorInput';
import SizeSelector from './SizeSelector';
import GlobalAudio from './GlobalAudio';
import RequestApproval from './RequestApproval';
import ContentPropertySettings from './ContentPropertySettings';
import TextStyleSettings from './TextStyleSettings';
import { currentEditorType } from 'packages/store/creativeEditor/creativeEditor.selectors';

const Globals = () => {
    const { creativeModel, creativeType, frameRate, globalsTab, setGlobalsTab } = useEditor();
    const { translate } = useTranslate();
    const { isPlatformAllowed } = useAccessRights();
    const isVideoCreative = creativeType === 'VIDEO';

    const editorType = useSelector(currentEditorType);

    if (!creativeModel) {
        return null;
    }

    const isInCreate = editorType === 'create';

    const getPageTab = () => (
        <>
            {!isInCreate && (
                <>
                    <Section aria-labelledby="global-size-selector">
                        <SizeSelector
                            creativeModel={creativeModel}
                            creativeType={creativeType}
                            id="global-size-selector"
                        />
                    </Section>
                    <Divider />
                </>
            )}
            <Section aria-labelledby="global-background-color">
                <BackgroundColorInput creativeModel={creativeModel} disabled={false} />
            </Section>
            {isVideoCreative && (
                <>
                    {!isInCreate && (
                        <>
                            <Divider />
                            <Section aria-labelledby="global-video-duration">
                                <DurationInput
                                    creativeModel={creativeModel}
                                    frameRate={frameRate}
                                    id="global-video-duration"
                                    disabled={false}
                                />
                            </Section>
                            <Divider />
                        </>
                    )}
                    <Section aria-labelledby="global-video-frame-input">
                        <PosterFrameInput
                            creativeModel={creativeModel}
                            frameRate={frameRate}
                            id="global-video-frame-input"
                            disabled={false}
                        />
                    </Section>
                    <Divider />
                    <Section aria-labelledby="global-video-browse">
                        <GlobalAudio
                            creativeModel={creativeModel}
                            frameRate={frameRate}
                            id="global-video-browse"
                            disabled={false}
                        />
                    </Section>
                </>
            )}
        </>
    );

    const getDesignTab = () => (
        <>
            {isPlatformAllowed([features.CONTENT_PROPERTIES]) && (
                <>
                    <Section aria-labelledby="design-content-properties">
                        <ContentPropertySettings creativeModel={creativeModel} />
                    </Section>
                    <Divider />
                </>
            )}
            <Section aria-labelledby="design-text-styles">
                <TextStyleSettings creativeModel={creativeModel} />
            </Section>
            <Divider />
            {isPlatformAllowed([features.APPROVALS_ENABLED]) && (
                <>
                    <Section aria-labelledby="global-approvals">
                        <RequestApproval disabled={false} />
                    </Section>
                    <Divider />
                </>
            )}
            <Section aria-labelledby="global-video-export">
                <ExportSettings />
            </Section>
        </>
    );

    return (
        <InspectorContent>
            <InspectorHeader>
                <Tabs>
                    <Tabs.Tab
                        key={translate('editor.sidebar.globals.tab.page')}
                        label={translate('editor.sidebar.globals.tab.page')}
                        onClick={() => setGlobalsTab('Page')}
                        isActive={globalsTab === 'Page'}
                    />
                    <Tabs.Tab
                        key={translate('editor.sidebar.globals.tab.design')}
                        label={translate('editor.sidebar.globals.tab.design')}
                        onClick={() => setGlobalsTab('Design')}
                        isActive={globalsTab === 'Design'}
                    />
                </Tabs>
            </InspectorHeader>
            {globalsTab === 'Page' && getPageTab()}
            {globalsTab === 'Design' && getDesignTab()}
        </InspectorContent>
    );
};

export default Globals;
