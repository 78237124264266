import React, { ReactElement, useCallback } from 'react';
import { ColorParams } from '@bynder-studio/render-core';
import { Tooltip, Button, Thumbnail, Flex } from '@bynder/design-system';
import { IconArrowDown, IconColorStyle } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { BrandColorPalette } from 'packages/store/brandColors/types';
import { colorToCSS } from '~/common/editor/editorHelper';
import generateTestId from '~/helpers/testIdHelpers';
import { hexToRgb } from '../../../ColorPicker/utils';
import { BrandColorOnElement } from '../..';
import { FullWidthButton, StyledDropdown } from './ColorsDropdown.styled';

type Props = {
    colors: ColorParams[];
    palettes: BrandColorPalette[];
    disabled: boolean;
    children: ReactElement;
    selectedBrandColors: BrandColorOnElement[] | [];
    testId: string;
};

const ColorDropdown = ({ colors, palettes, selectedBrandColors, disabled, testId, children }: Props) => {
    const { translate } = useTranslate();

    const m = translate('editor.sidebar.shots.text.font.styles');
    const [brandColor] = selectedBrandColors;

    const isMixed = colors.length > 1 && selectedBrandColors.length;
    const name = isMixed ? m : brandColor?.name;
    const subText = isMixed ? `${colors.length} colors` : brandColor?.paletteName;

    const getThumbnailColor = useCallback(
        () => (brandColor ? hexToRgb(brandColor.hexCode, brandColor.alpha) : colorToCSS(colors[0])),
        [brandColor],
    );

    const testIdEdit = generateTestId(`${testId}_edit_palette_color_dropdown`);
    const testIdChoose = generateTestId(`${testId}_choose_from_palette_dropdown`);

    return (
        <StyledDropdown
            minWidth="288px"
            trigger={({ isOpen, onClick, onKeyDown, ...triggerProps }, { open, close }) =>
                selectedBrandColors.length ? (
                    <Flex alignItems="center" justifyContent="space-between">
                        <FullWidthButton
                            isSelected={isOpen}
                            thumbnail={
                                <Thumbnail
                                    shape="circle"
                                    variant="content"
                                    size="s"
                                    backgroundColor={!isMixed ? getThumbnailColor() : undefined}
                                />
                            }
                            subtext={subText}
                            rightElements={<IconArrowDown />}
                            onClick={() => (isOpen ? close() : open())}
                            isDisabled={disabled}
                            {...testIdEdit}
                            {...triggerProps}
                        >
                            {name}
                        </FullWidthButton>
                    </Flex>
                ) : (
                    <Tooltip
                        position="top-right"
                        content={translate('editor.sidebar.shots.color.search.button.tooltip')}
                    >
                        <Button
                            isPressed={isOpen}
                            icon={<IconColorStyle />}
                            aria-label={translate('editor.sidebar.shots.color.search.access')}
                            onClick={() => (isOpen ? close() : open())}
                            variant="clean"
                            isDisabled={!palettes || disabled}
                            {...testIdChoose}
                            {...triggerProps}
                        />
                    </Tooltip>
                )
            }
            position={selectedBrandColors?.length ? 'bottom-left' : 'bottom-right'}
            isKeepingFocusOnTrigger
        >
            {children}
        </StyledDropdown>
    );
};

export default ColorDropdown;
