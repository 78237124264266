import React from 'react';
import { Table } from '@bynder/design-system';
import { useFriendlyTime } from '~/hooks/useFriendlyTime';

const DateCreatedCell = ({ date }: { date: string }) => {
    const { getFriendlyTime } = useFriendlyTime();
    const friendlyTime = getFriendlyTime(date).toString();
    const capitalizedFriendlyTime = friendlyTime[0].toUpperCase() + friendlyTime.slice(1);

    return <Table.Cell textFlow="no-wrap">{capitalizedFriendlyTime}</Table.Cell>;
};

export default DateCreatedCell;
