import { truthy, MAX_CP_NAME_LENGTH } from '@bynder-studio/misc';
import { v4 as uuidv4 } from 'uuid';
import { FontFamily } from '@bynder-studio/structured-text';
import type { ContentProperty, ElementForContentProperty, Reason } from '../types';
import { ImageElement } from '../Models/Elements/ImageElement';
import { VideoElement } from '../Models/Elements/VideoElement';
import { TextElement } from '../Models/Elements/TextElement';
import { ShapeElement } from '../Models/Elements/ShapeElement';
import { ElementTypes } from '../Enums/ElementTypes';
import { deepClone, equals } from './utils';
import { DynamicEventEmitter } from './DynamicEventEmitter';
import { ElementUpdatedEventData } from '../event-types';
import { getElementType } from './elementUtils';
import { ElementUpdateTypes } from '../Enums/ElementUpdateTypes';
import type { IBaseMultiPageModel } from '../Models/BaseMultiPageModel/IBaseMultiPageModel';
import { ShapeTypes } from '../Enums/ShapeTypes';
import type { BaseVisualElement } from '../Models/Elements/BaseVisualElement';
import { ContentPropertyData } from '../SpecificationParser/types';
import { IBaseModel } from '../Models/BaseModel/IBaseModel';
import { exportContentProperty } from '../SpecificationParser/SpecificationExporter';
import { WebSpecificationParser } from '../SpecificationParser/WebSpecificationParser';

export class ContentPropertiesManager {
    private static createPropertiesFromElement(element: ElementForContentProperty): ContentProperty['properties'] {
        if (element instanceof ImageElement) {
            return {
                src: element.src,
                srcId: element.srcId,
                srcType: element.srcType,
                fileName: element.fileName,
                naturalDimension: {
                    width: element.naturalDimension.width,
                    height: element.naturalDimension.height,
                },
                virtualData: { ...element.virtualData },
            };
        }

        if (element instanceof VideoElement) {
            return {
                src: element.src,
                srcId: element.srcId,
                srcType: element.srcType,
                fileName: element.fileName,
                offsetTime: element.offsetTime,
                isAlpha: element.isAlpha,
                useAudio: element.useAudio,
                useDynamicLength: element.useDynamicLength,
                naturalDimension: {
                    width: element.naturalDimension.width,
                    height: element.naturalDimension.height,
                },
                virtualData: { ...element.virtualData },
            };
        }

        if (element instanceof TextElement) {
            const runs = deepClone(element.formattedText.runs);
            const hasNonEmpty = runs.some((run) => run.length > 0);

            return {
                formattedText: {
                    value: element.formattedText.value,
                    layoutRuns: element.formattedText.layoutRuns,
                    runs: hasNonEmpty ? runs.filter((run) => run.length > 0) : [runs[0]],
                },
                limitTextToBounds: element.limitTextToBounds,
                textStyles: [...element.textStyles],
                brandColors: [...element.brandColors],
            };
        }

        if (element instanceof ShapeElement) {
            return {
                shapeType: element.shapeType,
                path: element.shapeType === ShapeTypes.CUSTOM ? element.path : '',
                fillColor: element.fillColor,
                borderColor: element.borderColor,
                borderWidth: element.borderWidth,
                borderAlignment: element.borderAlignment,
                borderLineCap: element.borderLineCap,
                borderRadius: element.borderRadius,
                fillBrandColors: element.fillBrandColors,
                borderBrandColors: element.borderBrandColors,
            };
        }

        return null;
    }

    private contentProperties: ContentProperty[] = [];

    private eventEmitter: DynamicEventEmitter;

    private multiPageModel: IBaseMultiPageModel;

    handleElementUpdated = (params: ElementUpdatedEventData & { pageIndex: number; reason: Reason }) => {
        const { pageIndex, element, updateTypes, reason } = params;

        if (!element.contentPropertyId) {
            return;
        }

        const contentProperty = this.getContentProperty(element.contentPropertyId);

        if (!contentProperty) {
            return;
        }

        const newContentProperty: ContentProperty = { ...contentProperty };
        newContentProperty.properties = {
            ...ContentPropertiesManager.createPropertiesFromElement(element as ElementForContentProperty),
        };

        if (equals(newContentProperty, contentProperty)) {
            return;
        }

        if (updateTypes.has(ElementUpdateTypes.CONTENT_PROPERTY)) {
            // prettier-ignore
            this.multiPageModel
                .getModels()[pageIndex]
                .updateElement(element.id, contentProperty.properties, { reason });

            return;
        }

        this.updateContentProperty(newContentProperty, reason);

        this.multiPageModel.getModels().forEach((model, index) => {
            model.getAllElementsRecursively().forEach((el) => {
                if ((el as BaseVisualElement).contentPropertyId === newContentProperty.uuid && el.id !== element.id) {
                    model.updateElement(el.id, deepClone(newContentProperty.properties), { reason });
                }
            });
        });
    };

    getContentPropertiesByType(type: ContentProperty['type']) {
        return this.contentProperties.filter((value) => value.type === type);
    }

    get allContentProperties() {
        return this.contentProperties;
    }

    getContentProperty(uuid: string) {
        const contentProperty = this.contentProperties.find((s) => s.uuid === uuid);

        if (!contentProperty) {
            return null;
        }

        return deepClone(contentProperty);
    }

    setMultiPageModel<T extends IBaseModel>(multiPageModel: IBaseMultiPageModel<T>) {
        this.multiPageModel = multiPageModel;
    }

    setEventEmitter(eventEmitter: DynamicEventEmitter) {
        this.unsubscribe();
        this.eventEmitter = eventEmitter;
        this.eventEmitter.on('elementUpdated', this.handleElementUpdated);
    }

    unsubscribe() {
        if (!this.eventEmitter) {
            return;
        }

        this.eventEmitter.off('elementUpdated', this.handleElementUpdated);
    }

    loadContentProperties(contentProperties: ContentProperty[]) {
        this.contentProperties = contentProperties;
    }

    extendContentProperties(
        contentProperties: ContentPropertyData[],
        fontFamilies: FontFamily[],
        updatedStyleIds: Map<string, string>,
    ) {
        const updatedIds = new Map<ContentProperty['uuid'], ContentProperty['uuid']>();

        if (contentProperties.length === 0) {
            return updatedIds;
        }

        const newContentProperties = WebSpecificationParser.parseContentProperties(
            contentProperties as any,
            fontFamilies,
        ).filter(
            ({ uuid, ...prop }) =>
                !this.contentProperties.some(({ uuid: oldUuid, ...existingProp }) => {
                    if (prop.type === ElementTypes.TEXT) {
                        prop.properties.formattedText.runs.forEach((run) => {
                            if (run.styleId && updatedStyleIds.has(run.styleId)) {
                                run.styleId = updatedStyleIds.get(run.styleId);
                            }
                        });
                    }

                    const result = equals(existingProp, prop);

                    if (result) {
                        updatedIds.set(uuid, oldUuid);
                    }

                    return result;
                }),
        );

        newContentProperties.forEach((contentProperty) => {
            const uuid = uuidv4();
            updatedIds.set(contentProperty.uuid, uuid);
            contentProperty.uuid = uuid;
        });

        this.contentProperties = [...this.contentProperties, ...newContentProperties];

        return updatedIds;
    }

    createContentPropertyFromElement(element: ElementForContentProperty, name: string) {
        const validatedName = name.slice(0, MAX_CP_NAME_LENGTH);
        const type = getElementType(element) as
            | ElementTypes.IMAGE
            | ElementTypes.VIDEO
            | ElementTypes.TEXT
            | ElementTypes.SHAPE;

        const commonProperties = {
            uuid: uuidv4(),
            name: validatedName,
            type,
            properties: ContentPropertiesManager.createPropertiesFromElement(element),
        };

        if (!commonProperties.properties) {
            return null;
        }

        return this.createContentProperty(commonProperties);
    }

    updateContentProperty(contentProperty: Partial<ContentProperty>, reason: Reason = 'user') {
        const index = this.contentProperties.findIndex((s) => s.uuid === contentProperty.uuid);

        if (index === -1) {
            return;
        }

        const oldValue: Partial<ContentProperty> = {};
        Object.entries(contentProperty).forEach(([key, value]) => {
            oldValue[key] = this.contentProperties[index][key];
            this.contentProperties[index][key] = value;
        });

        this.eventEmitter.emit('contentPropertyChanged', {
            action: 'update',
            oldValue,
            newValue: contentProperty,
            reason,
        });

        if (!this.multiPageModel) {
            return;
        }

        this.multiPageModel.getModels().forEach((model, index) => {
            model.getAllElementsRecursively().forEach((el) => {
                if ((el as BaseVisualElement).contentPropertyId === contentProperty.uuid) {
                    model.updateElement(el.id, deepClone(contentProperty.properties), { reason });
                }
            });
        });
    }

    updateContentProperties(contentProperties: ContentProperty[]) {
        const deletedPropsIds = new Set(this.contentProperties.map((contentProperty) => contentProperty.uuid));
        const updatedProps = contentProperties.map((newContentProperty) => {
            const oldContentProperty = this.contentProperties.find(
                (contentProperty) => contentProperty.uuid === newContentProperty.uuid,
            );

            if (!oldContentProperty) {
                return;
            }

            if (oldContentProperty.name !== newContentProperty.name) {
                this.updateContentProperty({ uuid: newContentProperty.uuid, name: newContentProperty.name });
            }

            deletedPropsIds.delete(oldContentProperty.uuid);

            return oldContentProperty;
        });
        deletedPropsIds.forEach((uuid) => this.deleteContentProperty(uuid));
        this.contentProperties = updatedProps;
    }

    deleteContentProperty(uuid: string, reason: Reason = 'user') {
        const index = this.contentProperties.findIndex((s) => s.uuid === uuid);

        if (index === -1) {
            return;
        }

        const [contentProperty] = this.contentProperties.splice(index, 1);
        this.eventEmitter.emit('contentPropertyChanged', {
            action: 'remove',
            oldValue: contentProperty,
            newValue: null,
            reason,
        });

        if (!this.multiPageModel) {
            return;
        }

        this.multiPageModel.getModels().forEach((model, index) => {
            model.getAllElementsRecursively().forEach((el) => {
                if ((el as BaseVisualElement).contentPropertyId === contentProperty.uuid) {
                    model.updateElement(el.id, { contentPropertyId: '' }, { reason });
                }
            });
        });
    }

    createContentProperty(contentProperty: Omit<ContentProperty, 'uuid'> & { uuid?: string }, reason: Reason = 'user') {
        contentProperty.uuid = contentProperty.uuid || uuidv4();
        this.contentProperties.push(contentProperty as ContentProperty);
        this.eventEmitter.emit('contentPropertyChanged', {
            action: 'create',
            oldValue: null,
            newValue: contentProperty,
            reason,
        });

        return contentProperty.uuid;
    }

    getCopy() {
        const copy = new ContentPropertiesManager();
        copy.contentProperties = deepClone(this.contentProperties);

        return copy;
    }

    toObject() {
        return this.contentProperties.map(exportContentProperty).filter(truthy);
    }
}
