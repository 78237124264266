import React from 'react';
import { Flex, Badge, Tooltip } from '@bynder/design-system';
import { IconLockSmall, IconLockOpenSmall } from '@bynder/icons';
import { ZoomControl } from 'packages/common/ZoomControl';
import { useDuration, Props } from 'packages/common/Duration/useDuration';
import { framesToTime } from '~/helpers/helpers';
import { frameIndexToTime } from '~/common/editor/editorHelper';
import FramePicker from 'packages/pages/editor/RightSideMenu/FormComponents/FramePicker';
import {
    DurationSlider,
    DurationTracker,
    DurationLeftMarker,
    DurationRightMarker,
    DurationWrapper,
    BadgeWrapper,
    DurationInputWrapper,
    DurationMarkerDot,
    DurationTimeTooltip,
} from 'packages/common/Duration/Duration.styled';
import { RedLineStyled } from 'packages/pages/design/timeline/TimelineShotContainer/TimelineShotContainer.styled';

const Duration = (props: Props) => {
    const {
        isSlipMode,
        duration,
        frameRate,
        start,
        end,
        containerRef,
        redLineRef,
        timeTooltipRef,
        controlRef,
        handleDrag,
        isBadgeVisible,
        handleZoomControlChange,
        fullDurationInFrames,
        actionsDisabled,
        onInputChange,
        onInputCorrection,
        handleDragByClick,
        handleMouseMoveX,
        handleMouseLeave,
        onHandleMouseDown,
    } = useDuration(props);

    return (
        <>
            <Flex justifyContent="center">
                <DurationInputWrapper disabled={isSlipMode || actionsDisabled}>
                    <FramePicker
                        name="Duration"
                        prefix="Duration"
                        value={duration}
                        frameRate={frameRate}
                        disabled={isSlipMode}
                        iconRight={isSlipMode ? <IconLockSmall /> : <IconLockOpenSmall />}
                        autocorrect={onInputCorrection('duration')}
                        handleOnChange={onInputChange('duration')}
                    />
                </DurationInputWrapper>
                <DurationInputWrapper disabled={actionsDisabled}>
                    <FramePicker
                        name="Start"
                        prefix="Start"
                        value={start}
                        frameRate={frameRate}
                        disabled={actionsDisabled}
                        autocorrect={onInputCorrection('start')}
                        handleOnChange={onInputChange('start')}
                    />
                </DurationInputWrapper>
                <DurationInputWrapper disabled={actionsDisabled}>
                    <FramePicker
                        name="End"
                        prefix="End"
                        value={end}
                        frameRate={frameRate}
                        disabled={actionsDisabled}
                        autocorrect={onInputCorrection('end')}
                        handleOnChange={onInputChange('end')}
                    />
                </DurationInputWrapper>
            </Flex>

            <DurationWrapper>
                <DurationTracker
                    ref={containerRef}
                    onClick={handleDragByClick}
                    onMouseMove={handleMouseMoveX}
                    onMouseLeave={handleMouseLeave}
                >
                    <RedLineStyled ref={redLineRef} />
                    <DurationTimeTooltip ref={timeTooltipRef} />
                    <DurationSlider ref={controlRef} onMouseDown={handleDrag}>
                        <Tooltip content={framesToTime(start)} className="trim-modal--tooltip" position="top-left">
                            <DurationLeftMarker
                                isSlipMode={isSlipMode}
                                onMouseDown={isSlipMode ? undefined : (evt) => onHandleMouseDown(evt, 'left')}
                            >
                                {!isSlipMode && (
                                    <>
                                        <DurationMarkerDot />
                                        <DurationMarkerDot />
                                        <DurationMarkerDot />
                                    </>
                                )}
                            </DurationLeftMarker>
                        </Tooltip>
                        {isBadgeVisible && (
                            <BadgeWrapper>
                                <Badge color="black-alpha">{Math.floor(frameIndexToTime(duration, 25))}s</Badge>
                            </BadgeWrapper>
                        )}
                        <Tooltip content={framesToTime(end)} className="trim-modal--tooltip" position="top-right">
                            <DurationRightMarker
                                isSlipMode={isSlipMode}
                                onMouseDown={isSlipMode ? undefined : (evt) => onHandleMouseDown(evt, 'right')}
                            >
                                {!isSlipMode && (
                                    <>
                                        <DurationMarkerDot />
                                        <DurationMarkerDot />
                                        <DurationMarkerDot />
                                    </>
                                )}
                            </DurationRightMarker>
                        </Tooltip>
                    </DurationSlider>
                </DurationTracker>
            </DurationWrapper>
            <ZoomControl onChange={handleZoomControlChange} duration={fullDurationInFrames} />
        </>
    );
};

export default Duration;
