import React from 'react';
import { Dropdown, Button, Divider } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconCopy, IconDelete, IconMore, IconRename } from '@bynder/icons';
import useEditor from '~/hooks/useEditor';

type OptionsDropdownType = {
    onSelect: (name: string) => void;
};

const OptionsDropdown = ({ onSelect }: OptionsDropdownType) => {
    const { translate } = useTranslate();
    const { creativeModel } = useEditor();
    const isDeleteDisabled = creativeModel.getModels().length < 2;

    return (
        <Dropdown
            trigger={({ isOpen, ...triggerProps }) => (
                <Button
                    title={translate('editor.page.options.title')}
                    variant="clean"
                    icon={<IconMore />}
                    {...triggerProps}
                />
            )}
        >
            <Dropdown.Item icon={<IconRename />} onClick={() => onSelect('rename')}>
                {translate('editor.page.options.rename')}
            </Dropdown.Item>
            <Dropdown.Item icon={<IconCopy />} onClick={() => onSelect('duplicate')}>
                {translate('editor.page.options.duplicate')}
            </Dropdown.Item>
            <Divider />
            <Dropdown.Item icon={<IconDelete />} isDisabled={isDeleteDisabled} onClick={() => onSelect('delete')}>
                {translate('editor.page.options.delete')}
            </Dropdown.Item>
        </Dropdown>
    );
};

export default OptionsDropdown;
