import { createSelector } from 'reselect';

export const gallerySelector = (state) => state.gallery.templates;
export const selectedGalleryIdSelector = (state) => state.gallery.selectedTemplateId;

export const selectedGallerySelector = createSelector(
    gallerySelector,
    selectedGalleryIdSelector,
    (templates, selectedTemplateId) => {
        return templates.find((template) => template.id === selectedTemplateId);
    },
);
