import React from 'react';
import { TruncatedText } from '@bynder/design-system';
import useSizes from 'packages/pages/designCreateModal/components/Blank/useSizes';
import generateTestId from '~/helpers/testIdHelpers';
import { PageNameButton } from './Component.styled';

type PageItemNameProps<T, S> = {
    triggerProps: T;
    sizeMetaData: S;
    isVideoModel: boolean;
};

const PageItemName = ({
    triggerProps,
    sizeMetaData,
    isVideoModel,
}: PageItemNameProps<typeof triggerProps, typeof sizeMetaData>) => {
    const { videoCategories, imageCategories, findByFormatKey } = useSizes('', '', true);
    const displayItem = findByFormatKey(isVideoModel ? videoCategories : imageCategories, sizeMetaData?.format);

    const getPageName = () => {
        const pageName = sizeMetaData?.name;

        if (!pageName?.length) {
            return displayItem.label;
        }

        return pageName;
    };

    return (
        <PageNameButton
            icon={displayItem.icon}
            variant="clean"
            {...triggerProps}
            {...generateTestId('editor-multipage-dropdown-selected-page')}
        >
            <TruncatedText>{getPageName()}</TruncatedText>
        </PageNameButton>
    );
};

export default PageItemName;
