import React, { useEffect } from 'react';
import { Button, Table } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import debounce from '~/helpers/debounce';
import { LoadingLayer } from '../grid/Grid.styled';
import { StickyHack } from './List.styled';
import { Props } from './types';

const DEFAULT_THRESHOLD = 250;
const DEFAULT_SCROLLING_RESET_TIME_INTERVAL = 150;

const ListView = ({
    columnWidths,
    renderHeader,
    renderItem,
    isLoading,
    container,
    onScroll,
    scrollThreshold = DEFAULT_THRESHOLD,
}: Props) => {
    const { translate } = useTranslate();

    const handleOnScroll = debounce(
        () => {
            if (container?.current) {
                const { clientHeight, scrollTop, scrollHeight } = container.current;

                if (clientHeight + scrollTop >= scrollHeight - scrollThreshold) {
                    onScroll();
                }
            }
        },
        DEFAULT_SCROLLING_RESET_TIME_INTERVAL,
        false,
    );

    useEffect(() => {
        const containerCurrent = container?.current;

        if (containerCurrent) {
            containerCurrent.addEventListener('scroll', handleOnScroll, false);
        }

        return () => {
            if (containerCurrent) {
                containerCurrent.removeEventListener('scroll', handleOnScroll, false);
            }
        };
    });

    return (
        <>
            <StickyHack />
            <Table columnWidths={columnWidths}>
                <Table.Head stickyOffset="0px">{renderHeader}</Table.Head>
                <Table.Body>{renderItem}</Table.Body>
            </Table>
            {isLoading && (
                <LoadingLayer data-testid="list view loader">
                    <Button isLoading variant="clean" aria-label={translate('pages.loading')} />
                </LoadingLayer>
            )}
        </>
    );
};

export default ListView;
