import { defaultTextBg, TextBackground } from '@bynder-studio/misc';
import { IStructuredGlyph, ShapedLine, ShapedText, ShapedTextPosition, TextProps } from './StructuredText';
import { type TextController } from './TextController';
import { CursorOrientation, Direction, HorizontalAlignment, Placement, Region, VerticalAlignment } from './Types';
import {
    createWordsIndexMap,
    equals,
    getParagraphs,
    isRtlGlyph,
    reCalculateLeadingPx,
    reorderByLevels,
    toFloat,
    toPercent,
} from './utils';

export interface BoundedTextProps {
    fontId?: string;
    textStruct?: TextProps;
    boxWidth?: number;
    boxHeight?: number;
    placement?: Placement;
    horizontalAlignment?: HorizontalAlignment;
    verticalAlignment?: VerticalAlignment;
    minFontScale?: number;
    fontScale?: number;
    textBackground?: TextBackground;
    textDirection?: Direction;
}

export type BreakData = {
    glyphIdx: number;
    xBreakOffsetPx: number;
};

const MAX_FONT_SCALE_PERCENT = 100;
const MIN_FONT_SCALE_PERCENT = 1;

// BoundedText formats a StructuredText according to provided layout settings
// Anything related to pixels is dealt with by BoundedText
export class BoundedText extends EventTarget {
    private shapedText!: ShapedText;

    // box level configurations affecting display of text
    private boxWidth = 0;

    private boxHeight = 0;

    private placement: Placement = Placement.FIT;

    private horizontalAlignment: HorizontalAlignment = HorizontalAlignment.CENTER;

    private verticalAlignment: VerticalAlignment = VerticalAlignment.MIDDLE;

    private minFontScale: number = null;

    private fontScale = 1;

    private textBackground: TextBackground = defaultTextBg;

    private textDirection: Direction;

    constructor(
        private textController: TextController,
        textStruct: TextProps,
        config: BoundedTextProps = {},
    ) {
        super();
        this.updateConfig({ ...config, textStruct });
    }

    getShapedText() {
        return this.shapedText;
    }

    getDimension(): [number, number] {
        return [this.boxWidth, this.boxHeight];
    }

    getAutoResizeScale() {
        return this.textController.getAutoResizeScale();
    }

    /**
     * // Update config and re-shape or re-layout when necessary
     * // Note that the ordering of the config parameters is important
     * @param config
     * @param forceReShape
     */
    updateConfig(config: BoundedTextProps = {}, forceReShape = false) {
        let needUpdate = false;
        let reShape = false; // require re-shaping input text
        // let reLayout = false;   // require re-layout of existing structured text

        // update box level properties
        if (
            config.textStruct !== undefined &&
            (!this.shapedText || !equals(config.textStruct, this.shapedText.getTextProps()))
        ) {
            this.shapedText = new ShapedText(config.textStruct);
            reShape = true;
        }

        if (config.placement !== undefined && config.placement !== this.placement) {
            this.placement = config.placement;
            reShape = true;
        }

        if (config.boxWidth !== undefined && config.boxWidth !== this.boxWidth) {
            this.boxWidth = config.boxWidth;
            reShape = true;
        }

        if (config.boxHeight !== undefined && config.boxHeight !== this.boxHeight) {
            this.boxHeight = config.boxHeight;
            reShape = true;
        }

        if (config.minFontScale !== undefined && config.minFontScale !== this.minFontScale) {
            this.minFontScale = config.minFontScale;
            reShape = true;
        }

        if (config.fontScale !== undefined && config.fontScale !== this.fontScale) {
            this.fontScale = config.fontScale;
            reShape = true;
        }

        if (config.horizontalAlignment !== undefined && config.horizontalAlignment !== this.horizontalAlignment) {
            this.horizontalAlignment = config.horizontalAlignment;
            needUpdate = true;
        }

        if (config.verticalAlignment !== undefined && config.verticalAlignment !== this.verticalAlignment) {
            this.verticalAlignment = config.verticalAlignment;
            needUpdate = true;
        }

        if (config.textBackground !== undefined && !equals(config.textBackground, this.textBackground)) {
            this.textBackground = config.textBackground;
            reShape = true;
        }

        if (config.textDirection !== undefined && config.textDirection !== this.textDirection) {
            this.textDirection = config.textDirection;
            reShape = true;
        }

        this.textController.setFontScale(this.fontScale);

        if (reShape || forceReShape) {
            this.textController.shapeText(this.shapedText, {
                textBackground: this.textBackground,
                textDirection: this.textDirection,
            });
            needUpdate = true;
        }

        if (needUpdate) {
            this.updateLayout();
            this.dispatchEvent(new Event('updated'));
        }
    }

    private updateLayout() {
        // update text to respect configured layout
        /** @deprecated Use only if already chosen in element */
        if (this.placement === Placement.FIT) {
            this.setLayoutFit();
        } else {
            this.shapedText.scale = 1;
            this.updateTextWithNewLines();
            this.updateTextWithAutoResizeScale(MAX_FONT_SCALE_PERCENT);

            if (this.placement === Placement.AUTO_RESIZE) {
                this.setLayoutAutoResize();
            }
        }
    }

    setLayoutAutoResize() {
        if (this.shapedText.getContentHeight() > this.boxHeight || this.shapedText.getContentWidth() > this.boxWidth) {
            this.searchAutoResizeScale(MAX_FONT_SCALE_PERCENT, toPercent(this.minFontScale) || MIN_FONT_SCALE_PERCENT);
        }
    }

    updateTextWithAutoResizeScale = (newScaleRaw: number) => {
        if (toFloat(newScaleRaw) !== this.textController.getAutoResizeScale()) {
            this.textController.setAutoResizeScale(toFloat(newScaleRaw));
            this.textController.shapeText(this.shapedText, {
                textBackground: this.textBackground,
                textDirection: this.textDirection,
            });
            this.updateTextWithNewLines();
        }
    };

    searchAutoResizeScale = (start: number, end: number) => {
        const scale = Math.floor((start + end) / 2);
        const minFontScalePercent = this.minFontScale ? toPercent(this.minFontScale) : MIN_FONT_SCALE_PERCENT;

        if (scale === end || scale === start) {
            this.updateTextWithAutoResizeScale(scale);

            return;
        }

        if (scale <= minFontScalePercent) {
            this.updateTextWithAutoResizeScale(minFontScalePercent);

            return;
        }

        this.updateTextWithAutoResizeScale(scale);
        const heightDiff = this.boxHeight - this.shapedText.getContentHeight();
        const widthDiff = this.boxWidth - this.shapedText.getContentWidth();
        // This is allowed difference between boxDimension and contentDimension
        const allowedDiff = 10;

        if (heightDiff <= allowedDiff && heightDiff >= 0 && widthDiff <= allowedDiff && widthDiff >= 0) {
            return;
        }

        return heightDiff > 0 && widthDiff > 0
            ? this.searchAutoResizeScale(start, scale)
            : this.searchAutoResizeScale(scale, end);
    };

    private setLayoutFit() {
        // Fit the text as is, no wrapping or hyphenation is necessary
        // Set em to pixel scale. Fit the text inside the box or use configured font size in case it is smaller.
        const scaleX = this.boxWidth / this.shapedText.getContentWidth();
        const scaleY = this.boxHeight / this.shapedText.getContentHeight();

        this.shapedText.scale = Math.min(scaleX, scaleY, 1);
        this.updateTextScaledPositions();
    }

    private updateTextWithNewLines() {
        this.rebuildLines();
        this.updateTextScaledPositions();
    }

    private getParagraphOffset(glyphs: ShapedLine['glyphs']) {
        if (!glyphs.length) {
            return 0;
        }

        const start = glyphs[0].data.textIdxs[0];
        const end = glyphs[glyphs.length - 1].data.textIdxs.slice(-1)[0];

        const styles = this.shapedText.getStylesForSelection(start, end);

        return styles.paragraphSpacing.reduce((acc, val) => Math.max(acc, val), 0);
    }

    private rebuildLines() {
        // Check sText against configuration and wrap where necessary
        const curLines = this.shapedText.getShapedLines() ?? [];
        const newLines: ShapedLine[] = [];

        const paragraphs = getParagraphs(this.shapedText.getTextProps());
        let paragraphCount = 0;

        const isNewParagraph = () => {
            const index = paragraphs.indexOf(paragraphCount);

            if (index === -1) {
                return false;
            }

            paragraphs.splice(index, 1);

            return true;
        };

        const getLineGlyphs = (sLine: ShapedLine, i: number) => {
            return sLine.glyphs.length ? sLine.glyphs : curLines?.[i - 1]?.glyphs?.slice(-1) ?? [];
        };

        curLines.forEach((sLine, i) => {
            sLine.metrics.leadingPx = reCalculateLeadingPx(
                getLineGlyphs(sLine, i),
                this.shapedText.getDefaultLineMetrics(),
            );

            if (sLine.metrics.widthPx <= this.boxWidth) {
                if (isNewParagraph()) {
                    sLine.metrics.leadingPx += this.getParagraphOffset(getLineGlyphs(sLine, i));
                }

                newLines.push(sLine);
            } else {
                // Break up fragments into multiple lines
                const lines = this.breakShapedLine(sLine, this.boxWidth);

                if (isNewParagraph()) {
                    lines[0].metrics.leadingPx += this.getParagraphOffset(getLineGlyphs(lines[0], i));
                }

                newLines.push(...lines);
            }

            if (sLine.glyphs.some((g) => g.isNewLine())) {
                paragraphCount++;
            }
        });

        if (curLines.length !== newLines.length) {
            // make sure to update lineIdx in the glyphData for each line
            newLines.forEach((sLine, lineIdx) => {
                sLine.glyphs.forEach((g) => {
                    g.data.lineIdx = lineIdx;
                });
            });

            this.shapedText.setShapedLines(newLines);
        }
    }

    private breakShapedLine(sLine: ShapedLine, maxWidthPx: number): ShapedLine[] {
        const sLines = [] as ShapedLine[];

        let { glyphs } = sLine;
        let glyphsWidthPx = glyphs.reduce((acc, g) => {
            return acc + g.emToPx(g.getXAdvanceAndSpacingEm());
        }, 0);
        let glyphBreaks = this.getGlyphBreaks(glyphs);

        while (glyphsWidthPx > maxWidthPx && glyphBreaks.length) {
            const [fittingGlyphs, remainingGlyphs] = this.breakGlyphs(glyphs, glyphBreaks, maxWidthPx);

            sLines.push(new ShapedLine(fittingGlyphs));

            // re-evaluate remaining glyphs
            glyphs = remainingGlyphs;
            glyphsWidthPx = glyphs.reduce<number>((acc, g) => {
                return acc + g.emToPx(g.getXAdvanceAndSpacingEm());
            }, 0);
            glyphBreaks = this.getGlyphBreaks(glyphs);
        }

        // convert remaining glyphs into line
        if (glyphs.length) {
            sLines.push(new ShapedLine(glyphs));
        }

        return sLines;
    }

    private getGlyphBreaks(glyphs: IStructuredGlyph[]): any[] {
        // Collect glyph information fragments can be broken on
        const breakData = [] as BreakData[];
        let xOffsetPx = 0;
        let prevGlyphSpacingPx = 0; // additional spacing of last Glyph

        glyphs.forEach((g, gIdx) => {
            // Mark glyph as breakable when path is empty (nothing to draw).
            // Do not break on newline glyphs, since this was already done.
            if (!g.isNewLine() && g.data.path === '') {
                breakData.push({
                    glyphIdx: gIdx,
                    xBreakOffsetPx: xOffsetPx - prevGlyphSpacingPx, // width to assume when breaking on this glyph
                });
            }

            xOffsetPx += g.emToPx(g.getXAdvanceAndSpacingEm());
            prevGlyphSpacingPx = g.emToPx(g.getXSpacingEm());

            gIdx++;
        });

        return breakData;
    }

    private breakGlyphs(glyphs: IStructuredGlyph[], breakData: any, maxWidthPx: number) {
        // Break glyphs into a new line and remaining glyphs

        // Determine glyphIdx to break on
        let currentBreak: any = null;

        breakData.forEach((glyphBreak: any) => {
            if (glyphBreak.xBreakOffsetPx <= maxWidthPx) {
                // set break within line width
                currentBreak = glyphBreak;
            } else if (glyphBreak.xBreakOffsetPx > maxWidthPx && currentBreak === null) {
                // if no break within line width is found, set the first one outside line width
                currentBreak = glyphBreak;
            }
        });

        // TODO: Check if reshaping is necessary after line break.

        // Create new line with glyphs
        return [
            // include break character in current line
            // TODO: Determine if this is necessary and correct (might be too wide to be allowed in the line)
            glyphs.slice(0, currentBreak.glyphIdx + 1),
            glyphs.slice(currentBreak.glyphIdx + 1),
        ];
    }

    getFirstShapedTextPosition() {
        return this.shapedText.getFirstShapedTextPosition();
    }

    getLastShapedTextPosition() {
        return this.shapedText.getLastShapedTextPosition();
    }

    getShapedLines() {
        return this.shapedText.getShapedLines();
    }

    getTextForSelection(startPos: ShapedTextPosition, endPos: ShapedTextPosition) {
        return this.shapedText.getTextForSelection(
            this.shapedText.getTextIdx(startPos),
            this.shapedText.getTextIdx(endPos),
        );
    }

    getGlyphArea(glyph: IStructuredGlyph, subTextIdx: number): [number, number, number, number] {
        const pos = {
            lineIdx: glyph.data.lineIdx,
            glyphIdx: glyph.data.glyphIdx,
            textIdx: glyph.data.textIdxs[subTextIdx],
            subTextIdx,
            cursorOrientation: CursorOrientation.Left,
        };

        return this.getGlyphAreaByPosition(pos);
    }

    getGlyphAreaByPosition(pos: ShapedTextPosition): [number, number, number, number] {
        return this.shapedText.getGlyphAreaByPosition(pos);
    }

    /**
     * Convert two mouse coordinates to a text position range
     * @param x1
     * @param y1
     * @param x2
     * @param y2,
     * @param correctingInvalid = for handling wrong position. Im some cases we don't need to handle it
     * and want to see that position is wrong
     */
    getPositionsFromArea(
        x1: number,
        y1: number,
        x2: number,
        y2: number,
        correctingInvalid = true,
    ): [ShapedTextPosition, ShapedTextPosition] | [null, null] {
        // determine start and end line
        const [yLineIdxStart, yLineIdxEnd] = this.getLineRangeByCoordinates(y1, y2);

        // order positions (smallest first)
        let xStart = x1;
        let xEnd = x2;
        let [lineIdxStart, lineIdxEnd] = [yLineIdxStart, yLineIdxEnd];

        if (yLineIdxEnd < yLineIdxStart || (yLineIdxStart === yLineIdxEnd && x2 < x1)) {
            xStart = x2;
            xEnd = x1;
            [lineIdxStart, lineIdxEnd] = [yLineIdxEnd, yLineIdxStart];
        }

        if (!correctingInvalid) {
            if (
                lineIdxStart === Number.NEGATIVE_INFINITY ||
                lineIdxStart === Number.POSITIVE_INFINITY ||
                lineIdxEnd === Number.NEGATIVE_INFINITY ||
                lineIdxEnd === Number.POSITIVE_INFINITY
            ) {
                return [null, null];
            }
        }

        let posStart: ShapedTextPosition | null = null;
        let posEnd: ShapedTextPosition | null = null;

        if (!correctingInvalid) {
            if (
                lineIdxStart === Number.NEGATIVE_INFINITY ||
                lineIdxStart === Number.POSITIVE_INFINITY ||
                lineIdxEnd === Number.NEGATIVE_INFINITY ||
                lineIdxEnd === Number.POSITIVE_INFINITY
            ) {
                return [null, null];
            }
        }

        //  Determine position for start
        if (lineIdxStart === Number.NEGATIVE_INFINITY) {
            posStart = this.getShapedTextPositionByLineIdxAndX(0, xStart);
        } else if (lineIdxStart === Number.POSITIVE_INFINITY) {
            const lastLineIdx = this.shapedText.getShapedLines().length - 1;
            posStart = this.getShapedTextPositionByLineIdxAndX(lastLineIdx, xStart);
        } else {
            posStart = this.getShapedTextPositionByLineIdxAndX(lineIdxStart, xStart);
        }

        // Determine position for end
        if (lineIdxEnd === Number.NEGATIVE_INFINITY) {
            posEnd = this.getShapedTextPositionByLineIdxAndX(0, xEnd);
        } else if (lineIdxEnd === Number.POSITIVE_INFINITY) {
            const lastLineIdx = this.shapedText.getShapedLines().length - 1;
            posEnd = this.getShapedTextPositionByLineIdxAndX(lastLineIdx, xEnd);
        } else {
            posEnd = this.getShapedTextPositionByLineIdxAndX(lineIdxEnd, xEnd);
        }

        return [posStart, posEnd] as [ShapedTextPosition, ShapedTextPosition];
    }

    /**
     * Determine the lines that are part of a selection
     * Returned in the order that the input coordinates were provided
     * So if y2 is xmaller than y1, the enline might be smaller than the start line
     * @param y1
     * @param y2
     */
    private getLineRangeByCoordinates(y1: number, y2: number): [number, number] {
        const yStart = Math.min(y1, y2);
        const yEnd = Math.max(y1, y2);

        let lineIdxStart = Number.NEGATIVE_INFINITY;
        let lineIdxEnd = Number.POSITIVE_INFINITY;

        const sLines = this.shapedText.getShapedLines();

        // check overlap with lines
        if (sLines.length) {
            const lastLine = sLines[sLines.length - 1];

            if (yStart < sLines[0].absLeadingBbox.top) {
                lineIdxStart = Number.NEGATIVE_INFINITY;
            } else if (yStart > lastLine.absLeadingBbox.getBottom()) {
                lineIdxStart = Number.POSITIVE_INFINITY;
            }

            if (yEnd < sLines[0].absLeadingBbox.top) {
                lineIdxEnd = Number.NEGATIVE_INFINITY;
            } else if (yEnd > lastLine.absLeadingBbox.getBottom()) {
                lineIdxEnd = Number.POSITIVE_INFINITY;
            }

            // find lines that overlaps with y
            for (const [i, sLine] of sLines.entries()) {
                if (yStart >= sLine.absLeadingBbox.top && yStart <= sLine.absLeadingBbox.getBottom()) {
                    lineIdxStart = i;
                }

                if (yEnd >= sLine.absLeadingBbox.top && yEnd <= sLine.absLeadingBbox.getBottom()) {
                    lineIdxEnd = i;
                    break;
                }
            }
        }

        // return in the right order
        if (y2 < y1) {
            return [lineIdxEnd, lineIdxStart];
        }

        return [lineIdxStart, lineIdxEnd];
    }

    getSelectionRanges(startPos: ShapedTextPosition, endPos: ShapedTextPosition) {
        const startTextIdx = this.shapedText.getTextIdx(startPos);
        const endTextIdx = this.shapedText.getTextIdx(endPos);

        if (startTextIdx === endTextIdx) {
            return [];
        }

        const glyphs = this.shapedText.getGlyphs();
        const lines = this.shapedText.getShapedLines();
        const textLength = this.shapedText.getText().length;
        const textIdxToGlyph = new Map<number, [IStructuredGlyph, number]>();
        glyphs.forEach((glyph) => {
            glyph.data.textIdxs.forEach((textIdx: number, subTextIdx: number) => {
                textIdxToGlyph.set(textIdx, [glyph, subTextIdx]);
            });
        });

        const list: { x: number; y: number; width: number; height: number; startX: number; endX: number }[] = [];

        const getGlyphX = (glyph: IStructuredGlyph, subTextIdx: number, include: boolean) => {
            const isSubStart = subTextIdx === 0;
            const isSubEnd = glyph.data.textIdxs.length - 1 === subTextIdx;

            if (!include && isSubStart) {
                return glyph.data.absBbox.left;
            }

            if (include && isSubEnd) {
                return glyph.data.absBbox.getRight() + (isRtlGlyph(glyph) ? 0 : glyph.emToPx(glyph.data.trackingEm));
            }

            return (
                glyph.data.absBbox.left +
                (glyph.data.absBbox.width / glyph.data.textIdxs.length) * (subTextIdx + (include ? 1 : 0))
            );
        };

        for (let i = startTextIdx; i < endTextIdx; i++) {
            const item = textIdxToGlyph.get(i);

            if (!item) {
                continue;
            }

            const [glyph, subTextIdx] = item;
            let startX = getGlyphX(glyph, subTextIdx, false);
            let endX = getGlyphX(glyph, subTextIdx, true);
            let line = lines[glyph.data.lineIdx];

            const y = line.absLeadingBbox.top;
            const x = Math.min(startX, endX);
            const width = Math.max(Math.abs(endX - startX), 2);
            const height = line.absLeadingBbox.height;

            list.push({ x, y, width, height, startX, endX });

            // if a last glyph is a new line
            if (textLength - 1 === i && glyph.isNewLine()) {
                line = lines[lines.length - 1];
                startX = line.absLeadingBbox.left;
                endX = line.absLeadingBbox.left;

                const y = line.absLeadingBbox.top;
                const x = Math.min(startX, endX);
                const width = Math.max(Math.abs(endX - startX), 2);
                const height = line.absLeadingBbox.height;

                list.push({ x, y, width, height, startX, endX });
            }
        }

        const merge = () => {
            const mergedList: { x: number; y: number; width: number; height: number }[] = [];
            let current = list[0];

            if (!current) {
                return mergedList;
            }

            for (let i = 1; i < list.length; i++) {
                const next = list[i];

                if (current.y !== next.y) {
                    mergedList.push(current);
                    current = next;
                    continue;
                }

                if (current.x + current.width >= next.x) {
                    current.x = Math.min(current.x, next.x);
                    current.startX = Math.min(current.startX, next.startX);
                    current.endX = Math.max(current.endX, next.endX);
                    current.width = Math.max(Math.abs(current.endX - current.startX), 2);
                } else {
                    mergedList.push(current);
                    current = next;
                }
            }

            mergedList.push(current);

            return mergedList;
        };

        return merge();
    }

    /**
     *
     * @param startPos
     * @param endPos
     * @param lineIdx indicates how to treat the current line in relation to the provided positions
     * @returns
     */
    getLineXRange(startPos: ShapedTextPosition, endPos: ShapedTextPosition, lineIdx: number): [number, number] {
        let startSubTextIdx = startPos.subTextIdx;
        let endSubTextIdx = endPos.subTextIdx;
        let startGlyph: IStructuredGlyph;
        let endGlyph: IStructuredGlyph;
        let startX = 0;
        let endX = 0;

        const glyphs = this.shapedText.getGlyphs();

        const getXCoord = (pos: ShapedTextPosition, g: IStructuredGlyph, subTextIdx: number) => {
            if (pos.glyphIdx >= glyphs.length) {
                // return last position including last full glyph
                g = glyphs[glyphs.length - 1];

                return g.data.absBbox.getRight();
            }

            return g.data.absBbox.left + (g.data.absBbox.width / g.data.textIdxs.length) * subTextIdx;
        };

        if (lineIdx === startPos.lineIdx && lineIdx === endPos.lineIdx) {
            // single line selection (can be partial)

            startGlyph = glyphs[startPos.glyphIdx];
            endGlyph = glyphs[endPos.glyphIdx];

            startX = getXCoord(startPos, startGlyph, startSubTextIdx);
            endX = getXCoord(endPos, endGlyph, endSubTextIdx);
        } else if (lineIdx === startPos.lineIdx) {
            // first line (possible partial selection)

            const lineGlyphs = this.shapedText.getShapedLines()[lineIdx].glyphs;
            startGlyph = glyphs[startPos.glyphIdx];
            endGlyph = lineGlyphs[lineGlyphs.length - 1];
            endSubTextIdx = endGlyph.data.textIdxs.length - 1;

            startX = getXCoord(startPos, startGlyph, startSubTextIdx);
            endX = endGlyph.data.absBbox.getRight();
        } else if (lineIdx === endPos.lineIdx) {
            // last line (possible partial selection)

            const lineGlyphs = this.shapedText.getShapedLines()[lineIdx].glyphs;

            if (lineGlyphs.length === 0) {
                // when last character is a newline the last line doesn't contain glyphs
                const leftPx = this.shapedText.getShapedLines()[lineIdx].absLeftPx;
                startX = leftPx;
                endX = leftPx;
            } else {
                startGlyph = lineGlyphs[0];
                endGlyph = glyphs[endPos.glyphIdx];
                startSubTextIdx = 0;
                startX = startGlyph.data.absBbox.left;
                endX = getXCoord(endPos, endGlyph, endSubTextIdx);
            }
        } else {
            // full line selection

            const lineGlyphs = this.shapedText.getShapedLines()[lineIdx].glyphs;
            startGlyph = lineGlyphs[0];
            endGlyph = lineGlyphs[lineGlyphs.length - 1];
            startSubTextIdx = 0;
            endSubTextIdx = endGlyph.data.textIdxs.length - 1;

            startX = startGlyph.data.absBbox.left;
            endX = endGlyph.data.absBbox.getRight();
        }

        return [startX, endX];
    }

    /**
     * Moving is only possible over one dimension at once
     * @param pos current position
     * @param xDelta
     * @param yDelta
     */
    getShapedTextPositionDelta(pos: ShapedTextPosition, xDelta: number, yDelta: number): ShapedTextPosition {
        if (xDelta !== 0) {
            // Traverse via translation to text position
            const curTextIdx = this.shapedText.getTextIdxFromShapedTextPosition(pos);

            return this.shapedText.getShapedTextPositionFromTextIdx(curTextIdx + xDelta);
        }

        if (yDelta !== 0) {
            const sLines = this.shapedText.getShapedLines();

            const curLineIdx = this.shapedText.getLineIdxFromShapedTextPosition(pos);
            let newLineIdx;

            if (yDelta < 0) {
                // upward
                newLineIdx = Math.max(curLineIdx + yDelta, 0);
            } else {
                // downward
                newLineIdx = Math.min(curLineIdx + yDelta, sLines.length - 1);
            }

            if (curLineIdx === newLineIdx) {
                return pos;
            }

            // determine glyph position closest to old line
            let [x, y, w, h] = this.getGlyphAreaByPosition(pos);

            if (pos.cursorOrientation === CursorOrientation.Right) {
                x += w;
            }

            return this.getShapedTextPositionByLineIdxAndX(newLineIdx, x) as ShapedTextPosition;
        }

        return pos;
    }

    getShapedTextPositionByGlyphIdx(glyphIdx: number): ShapedTextPosition {
        const glyph = this.shapedText.getGlyphs().find((g) => g.data.glyphIdx === glyphIdx);

        return this.shapedText.textIdxToPosition(glyph?.data?.textIdxs[0] || glyphIdx);
    }

    getShapedTextPositionByLineIdxAndX(lineIdx: number, xPos: number): ShapedTextPosition {
        const shapedLines = this.shapedText.getShapedLines();
        const sLine = shapedLines[lineIdx];
        const glyphs = sLine.glyphs;

        if (glyphs.length === 0 && lineIdx >= shapedLines.length - 1) {
            // Last line without glyphs, just return last position
            return this.getLastShapedTextPosition();
        }

        const firstGlyph = glyphs[0].data;
        const lastGlyph = glyphs[glyphs.length - 1].data;

        // traverse glyphs and subTexts and check which is closest to the xPos
        if (xPos <= firstGlyph.absBbox.left + firstGlyph.absBbox.width / (1 + firstGlyph.textIdxs.length)) {
            // Location is before the first glyph and subtext
            return {
                lineIdx: firstGlyph.lineIdx,
                glyphIdx: firstGlyph.glyphIdx,
                textIdx: firstGlyph.textIdxs[0],
                subTextIdx: 0,
                cursorOrientation: CursorOrientation.Left,
            };
        }

        const lastGlyphLeft = lastGlyph.absBbox.left;
        const lastGlyphWidth = lastGlyph.absBbox.width;
        const lastGlyphTextIdxsLength = lastGlyph.textIdxs.length;

        if (xPos > lastGlyphLeft + (lastGlyphWidth / (1 + lastGlyphTextIdxsLength)) * lastGlyphTextIdxsLength) {
            const shapedGlyphs = this.shapedText.getGlyphs();
            const lastShapedGlyphs = shapedGlyphs[shapedGlyphs.length - 1];

            if (lastGlyph.glyphIdx >= shapedGlyphs.length - (lastShapedGlyphs?.isNewLine() ? 0 : 1)) {
                return this.getLastShapedTextPosition();
            }

            const glyph = shapedGlyphs[lastGlyph.glyphIdx];
            const subTextIdx = lastGlyphTextIdxsLength - 1;

            return {
                lineIdx: glyph.data.lineIdx,
                glyphIdx: glyph.data.glyphIdx,
                textIdx: glyph.data.textIdxs[subTextIdx],
                subTextIdx,
                cursorOrientation: isRtlGlyph(glyph) ? CursorOrientation.Right : CursorOrientation.Left,
            };
        }

        // location is overlapping with some glyph and subtext
        for (let i = 0; i < glyphs.length; i++) {
            const currentGlyph = glyphs[i];
            const currentGlyphData = currentGlyph.data;
            const currentGlyphLeft = currentGlyphData.absBbox.left;
            const currentGlyphWidth = currentGlyphData.absBbox.width;
            const currentGlyphTextIdxsLength = currentGlyphData.textIdxs.length;

            const nextGlyphData = glyphs[i + 1]?.data;
            const nextGlyphLeft = nextGlyphData?.absBbox?.left;
            const nextGlyphWidth = nextGlyphData?.absBbox?.width;
            const nextGlyphTextIdxsLength = nextGlyphData?.textIdxs?.length;

            // if this is the last item, or if the position is between the current and next item, set the next position
            if (
                i === glyphs.length - 1 ||
                (xPos >= currentGlyphLeft + currentGlyphWidth / (1 + currentGlyphTextIdxsLength) &&
                    xPos <= nextGlyphLeft + nextGlyphWidth / (1 + nextGlyphTextIdxsLength))
            ) {
                let subTextIdx = 0;

                if (currentGlyphTextIdxsLength > 1) {
                    const xPosFromLeft = xPos - currentGlyphLeft;
                    const textCharWidth = currentGlyphWidth / (1 + currentGlyphTextIdxsLength);
                    subTextIdx = Math.min(Math.floor(xPosFromLeft / textCharWidth) - 1, currentGlyphTextIdxsLength - 1);
                }

                const textIdx = currentGlyphData.textIdxs[subTextIdx];

                return this.shapedText.textIdxToPosition(textIdx + (isRtlGlyph(currentGlyph) ? 0 : 1));
            }
        }
    }

    private updateTextScaledPositions() {
        const s = (n: number) => this.shapedText.scale * n;

        const sText = this.shapedText;
        const sLines = sText.getShapedLines();
        const sTextHeight = sText.getContentHeight();

        sText.absBbox = new Region();

        if (sLines.length && sTextHeight) {
            let scaledBoxTopPx = 0;

            // Determine top offset for text block
            if (this.verticalAlignment === VerticalAlignment.TOP) {
                scaledBoxTopPx = 0;
            } else if (this.verticalAlignment === VerticalAlignment.MIDDLE) {
                scaledBoxTopPx = this.boxHeight / 2 - s(sTextHeight) / 2;
            } else if (this.verticalAlignment === VerticalAlignment.BOTTOM) {
                scaledBoxTopPx = this.boxHeight - s(sTextHeight);
            }

            // Keep track of the first baseline that is used to draw the glyphs (top - ascender = baseline)
            const scaledFirstBaselinePx = s(sLines[0].metrics.ascenderPx);
            let scaledBaselineTopPx = scaledBoxTopPx + scaledFirstBaselinePx;

            let prevScaledBottomPx = scaledBoxTopPx;

            // For each line set the top and left offset in px
            sLines.forEach((sLine, sLineIdx) => {
                if (sLineIdx > 0) {
                    // for each line after the first, we increase the height with the leading
                    scaledBaselineTopPx += s(sLine.metrics.leadingPx);
                }

                let scaledLeftPx = 0;
                let wordsIndexMap = null;

                if (this.horizontalAlignment === HorizontalAlignment.JUSTIFY) {
                    const isLastLine = sLineIdx === sLines.length - 1;
                    const lineHasBreak = sLine.glyphs.some((g) => g.isNewLine());

                    if (!isLastLine && !lineHasBreak) {
                        const { wordsCount, mapping } = createWordsIndexMap(sLines[sLineIdx]);
                        wordsIndexMap = mapping;
                        scaledLeftPx =
                            wordsCount > 1 ? (this.boxWidth - s(sLine.metrics.widthPx)) / (wordsCount - 1) : 0;
                    }
                } else if (this.horizontalAlignment === HorizontalAlignment.LEFT) {
                    scaledLeftPx = -s(sLine.metrics.leftTrimmedPx);
                } else if (this.horizontalAlignment === HorizontalAlignment.CENTER) {
                    scaledLeftPx = this.boxWidth / 2 - s(sLine.metrics.widthPx) / 2 - s(sLine.metrics.leftTrimmedPx);
                } else if (this.horizontalAlignment === HorizontalAlignment.RIGHT) {
                    scaledLeftPx = this.boxWidth - s(sLine.metrics.widthPx) - s(sLine.metrics.leftTrimmedPx);
                }

                sLine.absLeftPx = scaledLeftPx;
                sLine.absTopPx = scaledBaselineTopPx - s(sLine.metrics.ascenderPx);
                sLine.absBbox = new Region(
                    sLine.absLeftPx,
                    sLine.absTopPx,
                    s(sLine.metrics.widthPx),
                    s(sLine.metrics.lineHeightPx),
                );

                // determine height of the box including leading
                let t = prevScaledBottomPx;
                let h = sLine.absBbox.getBottom() - t;

                if (h < s(sLine.metrics.lineHeightPx)) {
                    t -= s(sLine.metrics.lineHeightPx) - h;
                    h = s(sLine.metrics.lineHeightPx);
                }

                sLine.absLeadingBbox = new Region(sLine.absLeftPx, t, s(sLine.metrics.widthPx), h);

                prevScaledBottomPx = sLine.absBbox.getBottom();

                this.updateGlyphScaledPositions(sLine, scaledLeftPx, scaledBaselineTopPx, wordsIndexMap);

                // update bounding box in text
                // @ts-ignore
                sText.absBbox.union(sLine.absBbox);
            });
        }
    }

    private updateGlyphScaledPositions(
        shapedLine: ShapedLine,
        scaledXOffset: number,
        scaledYOffset: number,
        wordsIndexMap?: { [glyphIdx: number]: number },
    ) {
        const s = (n: number) => this.shapedText.scale * n;
        const getJustifyScale = (g: IStructuredGlyph) => {
            if (!wordsIndexMap) {
                return 1;
            }

            const glyphIdx = g.data.glyphIdx;

            return wordsIndexMap[glyphIdx];
        };

        // Set start positions for each line
        let scaledXPos = 0;
        let scaledYPos = 0;
        const glyphs = reorderByLevels(shapedLine.glyphs);

        if (wordsIndexMap) {
            scaledXPos = -s(shapedLine.metrics.leftTrimmedPx);
        }

        glyphs.forEach((g) => {
            const updatedScaledXOffset = scaledXOffset * getJustifyScale(g);

            // set scaled absolute position to baseline (for drawing)
            g.data.absLeftPx = scaledXPos + updatedScaledXOffset + s(g.unitToPx(g.data.xOffset));
            g.data.absTopPx = scaledYPos + scaledYOffset + s(g.unitToPx(g.data.yOffset));
            // set scaled absolute bounding box (for hit testing)
            g.data.absBbox = new Region(
                g.data.absLeftPx,
                g.data.absTopPx - s(g.unitToPx(g.data.ascender)),
                s(g.unitToPx(g.data.xAdvance)),
                s(g.unitToPx(g.data.lineHeight)),
            );

            // to calculate the absolute bounding box of the content:
            g.data.absContentBbox = new Region(
                g.data.absLeftPx + s(g.unitToPx(g.data.xMin)),
                g.data.absTopPx - s(g.unitToPx(g.data.yMax)), // yMax is on top in our coordinate system
                s(g.unitToPx(g.data.width)),
                s(g.unitToPx(g.data.height)),
            );

            // scale path as well
            g.data.pathScale = g.unitToPx(1) * s(1);

            // Advance position for next glyph
            scaledXPos += s(g.emToPx(g.getXAdvanceAndSpacingEm()));
            scaledYPos += s(g.emToPx(g.getYAdvanceAndSpacingEm()));
        });
    }
}
