import { useCallback, useEffect } from 'react';
import { isInteractiveElement } from '@bynder-studio/misc';
import { isCtrlKey } from '~/helpers/hotKeys';
import { type ControlShortcutMode } from 'packages/hooks/usePlaybackShortcuts';

type Props = {
    toPrevShot: () => void;
    toNextShot: () => void;
    controlShortcutMode?: ControlShortcutMode;
};

// todo: Shots could be in a playback control hook, but only after
// implementing one stateless hook for control shots in all types of editors
export const useShotsShortcuts = ({ toPrevShot, toNextShot, controlShortcutMode = 'playhead' }: Props) => {
    const handleKeyCodes = useCallback(
        (e) => {
            if (isInteractiveElement(e) || !isCtrlKey(e) || e.shiftKey || controlShortcutMode !== 'playhead') {
                return;
            }

            switch (e.code) {
                case 'ArrowRight':
                    e.preventDefault();
                    toNextShot();
                    break;
                case 'ArrowLeft':
                    e.preventDefault();
                    toPrevShot();
                    break;
                default:
                    break;
            }
        },
        [controlShortcutMode, toNextShot, toPrevShot],
    );

    useEffect(() => {
        window.addEventListener('keydown', handleKeyCodes);

        return () => {
            window.removeEventListener('keydown', handleKeyCodes);
        };
    }, [handleKeyCodes]);
};
