export const convertFrameToTime = (frame: number, frameRate: number) => {
    const convertedTime = convertFrameToSecMinFrame(frame, frameRate);

    const minutes = String(convertedTime.minutes).padStart(1, '0');
    const seconds = String(convertedTime.seconds).padStart(2, '0');

    return `${minutes}:${seconds}`;
};

/**
 * Returns an object containing frame, seconds, minutes
 */
export const convertFrameToSecMinFrame = (frame: number, frameRet: number) => {
    let frm;
    let sec;
    let min = 0;
    let timeindex = null;

    if (frame >= frameRet) {
        timeindex = Math.floor(frame / frameRet);
        frm = frame % frameRet;

        if (timeindex < 60) {
            sec = timeindex;
        } else {
            min = Math.floor(timeindex / 60);
            sec = timeindex - min * 60;
        }
    } else {
        sec = 0;
        min = 0;
        frm = frame;
    }

    return {
        frame: frm,
        seconds: sec,
        minutes: min,
    };
};

export const addZeroIfNeeded = (timePiece: number) =>
    timePiece ? (timePiece < 10 ? '0' + timePiece : timePiece) : '00';
