import { SocketSubscribeConfig } from '../socketSubscribe';
import { type UUID } from 'packages/types/uuid';
import { type AssetStatus, type AssetType } from '../../../enums/assets';

export type ThumbnailWSData = {
    id: UUID;
    type: AssetType;
    status: AssetStatus;
    progress?: number;
    thumbnails?: { [key: string]: string };
};

type Options = SocketSubscribeConfig<
    {
        totalItems: number;
        items: ThumbnailWSData[];
    },
    {
        assetIds: string[];
    }
>;

type Params = {
    customerId: number;
    assetIds: Options['publishBody']['assetIds'];
    onMessage: Options['onMessage'];
};

export function subscribeAssetsStatusOptions({ customerId, assetIds, onMessage }: Params): Options {
    return {
        destination: `assets.${customerId}.status`,
        publishBody: {
            assetIds,
        },
        onMessage,
    };
}
