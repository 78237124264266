import React from 'react';
import { Thumbnail } from '@bynder/design-system';
import { IconFolderOpen } from '@bynder/icons';
import generateTestId from '~/helpers/testIdHelpers';
import { Category } from 'packages/store/categories/types';
import { hasPreviewImageURL, isProcessingDone } from 'packages/pages/components/thumbnail/utils';

type Props = {
    category: Category | null;
};

const categoryCoverThumbnailId = generateTestId('category_cover_thumbnail');
const categoryColorThumbnailId = generateTestId('category_color_thumbnail');
const categoryPreviewThumbnailId = generateTestId('category_preview_thumbnail');

const CategoryThumbnail = ({ category }: Props) => {
    if (category?.coverImageUrl) {
        return (
            <Thumbnail
                variant="content"
                imageUrl={category?.coverImageUrl}
                backgroundColor={`#${category?.coverBgColor}`}
                {...categoryCoverThumbnailId}
            />
        );
    }

    if (category?.coverBgColor) {
        return (
            <Thumbnail variant="content" backgroundColor={`#${category?.coverBgColor}`} {...categoryColorThumbnailId} />
        );
    }

    if (hasPreviewImageURL(category) && isProcessingDone(category)) {
        return (
            <Thumbnail
                variant="content"
                imageUrl={category?.previewItems[category?.previewItems?.length - 1]?.previewUrl}
                {...categoryPreviewThumbnailId}
            />
        );
    }

    return (
        <Thumbnail variant="clean" icon={<IconFolderOpen />}>
            {!isProcessingDone(category) && <Thumbnail.Overlay isLoading={!isProcessingDone(category)} />}
        </Thumbnail>
    );
};

export default CategoryThumbnail;
