import React, { useEffect, useState } from 'react';
import { Button, Dropdown } from '@bynder/design-system';
import { IconImage, IconSound, IconVideo } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import useQueryParams from 'packages/hooks/useQueryParams';
import { MediaTypeContainer } from './MediaType.styled';
import { MediaFilterType, MediaTypeFilterProps } from './types';

const MediaType = ({ value, onSelect, isDisabled, showAudio = false, ...props }: MediaTypeFilterProps) => {
    const [selectedType, setSelectedType] = useState(value || MediaFilterType.ANY);
    const { translate } = useTranslate();
    const { getQueryParam } = useQueryParams();

    useEffect(() => {
        if (Array.isArray(value) && value.includes(MediaFilterType.IMAGE)) {
            setSelectedType(MediaFilterType.IMAGE);
        } else {
            setSelectedType(value || MediaFilterType.ANY);
        }
    }, [value]);

    const handleOnSelect = (mediaType: MediaFilterType) => {
        setSelectedType(mediaType);
        onSelect(mediaType);
    };

    const getDropdownSelection = () => {
        switch (selectedType) {
            case MediaFilterType.IMAGE:
                return (
                    <MediaTypeContainer>
                        <IconImage />
                        {translate('filters.type.image')}
                    </MediaTypeContainer>
                );
            case MediaFilterType.VIDEO:
                return (
                    <MediaTypeContainer>
                        <IconVideo />
                        {translate('filters.type.video')}
                    </MediaTypeContainer>
                );
            case MediaFilterType.AUDIO:
                return (
                    showAudio && (
                        <MediaTypeContainer>
                            <IconSound />
                            {translate('filters.type.audio')}
                        </MediaTypeContainer>
                    )
                );
            default:
                return translate('filters.type.any');
        }
    };

    useEffect(() => {
        const queriedDesignType = getQueryParam('typeDesign')?.toUpperCase();
        const queriedTemplateType = getQueryParam('typeTemplate')?.toUpperCase();

        if (!queriedTemplateType && queriedDesignType && Object.keys(MediaFilterType).includes(queriedDesignType)) {
            setSelectedType(MediaFilterType[queriedDesignType]);
        }

        if (!queriedDesignType && queriedTemplateType && Object.keys(MediaFilterType).includes(queriedTemplateType)) {
            setSelectedType(MediaFilterType[queriedTemplateType]);
        }
    }, []);

    return (
        <Dropdown
            trigger={({ isOpen, ...triggerProps }) => (
                <Button
                    data-testid="overview media filter"
                    variant="clean"
                    isPressed={isOpen}
                    rightIcon={<Dropdown.Arrow />}
                    {...triggerProps}
                    aria-label={translate('filters.type.label')}
                    isDisabled={isDisabled}
                >
                    {getDropdownSelection()}
                </Button>
            )}
            {...props}
        >
            {selectedType !== MediaFilterType.ANY && (
                <>
                    <Dropdown.Item onClick={() => handleOnSelect(MediaFilterType.ANY)}>
                        {translate('filters.type.any')}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                </>
            )}
            <Dropdown.Item
                onClick={() => handleOnSelect(MediaFilterType.IMAGE)}
                isChecked={selectedType === MediaFilterType.IMAGE}
                icon={<IconImage />}
            >
                {translate('filters.type.image')}
            </Dropdown.Item>
            <Dropdown.Item
                onClick={() => handleOnSelect(MediaFilterType.VIDEO)}
                isChecked={selectedType === MediaFilterType.VIDEO}
                icon={<IconVideo />}
            >
                {translate('filters.type.video')}
            </Dropdown.Item>
            {showAudio && (
                <Dropdown.Item
                    onClick={() => handleOnSelect(MediaFilterType.AUDIO)}
                    isChecked={selectedType === MediaFilterType.AUDIO}
                    icon={<IconSound />}
                >
                    {translate('filters.type.audio')}
                </Dropdown.Item>
            )}
        </Dropdown>
    );
};

export default MediaType;
