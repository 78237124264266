import React, { FC, useCallback } from 'react';
import { getFontById } from '@bynder-studio/structured-text';
import type { TextStyle } from '@bynder-studio/render-core';
import { Dropdown, token } from '@bynder/design-system';
import { IconMin } from '@bynder/icons';
import generateTestId from '~/helpers/testIdHelpers';
import FontThumbnail from './shared/FontThumbnail';
import { textStyleSubtext } from './utils';

type TextStyleItemProps = {
    item: TextStyle;
    selected?: boolean;
    useMultiselectIcon?: boolean;
    onClick: (item: TextStyle) => void;
};

export const TextStyleItem: FC<TextStyleItemProps> = ({ item, selected, useMultiselectIcon, onClick }) => {
    const handleClick = useCallback(() => {
        onClick(item);
    }, [item]);

    return (
        <Dropdown.ListItem
            isChecked={!useMultiselectIcon && selected}
            onClick={handleClick}
            subtext={textStyleSubtext(item)}
            thumbnail={<FontThumbnail id={item.uuid + item.fontId} url={getFontById(item.fontId)?.url ?? ''} />}
            rightElements={selected && useMultiselectIcon && <IconMin style={{ color: token.gray800 }} />}
            {...generateTestId('shots_text_styles_item')}
        >
            {item.name}
        </Dropdown.ListItem>
    );
};
