import React, { useState } from 'react';
import ResetVariationModal from 'packages/pages/design/sidebar/variations/ResetVariationModal';

const useVariationResetModal = () => {
    const [variationId, setVariationId] = useState<string>('');

    const onClose = () => {
        setVariationId('');
    };

    const renderVariationResetModal = () => <ResetVariationModal variationId={variationId} onClose={onClose} />;

    const setVariationResetModal = (id: string) => {
        setVariationId(id);
    };

    return {
        setVariationResetModal,
        renderVariationResetModal,
    };
};

export default useVariationResetModal;
