import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from '@bynder/localization';
import { Template } from 'packages/store/templates/types';
import { EntityType } from 'packages/store/sharing/types';
import ShareModal from '.';
import { fetchSharing } from 'packages/store/sharing/sharing.actions';

type Params = { collectionName?: string };

const useTemplateShareModal = ({ collectionName }: Params) => {
    const [shareModalTemplate, setShareModalTemplate] = useState<Template | null>(null);
    const { translate } = useTranslate();

    const dispatch = useDispatch();

    const showModal = shareModalTemplate !== null;

    const onClose = () => {
        if (shareModalTemplate) {
            dispatch(fetchSharing(shareModalTemplate.id, EntityType.TEMPLATE));
        }
        setShareModalTemplate(null);
    };

    const renderModalTemplateShare = () => (
        <ShareModal
            title={translate('modal.template.share.title')}
            entityId={shareModalTemplate && shareModalTemplate.id}
            entityType={EntityType.TEMPLATE}
            isModalOpen={showModal}
            collectionName={collectionName || showModal ? shareModalTemplate?.categoryName : null}
            onClose={onClose}
        />
    );

    return {
        setShareModalTemplate,
        renderModalTemplateShare,
    };
};

export default useTemplateShareModal;
