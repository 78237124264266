import styled from 'styled-components';
import { Flex, textStyles, token } from '@bynder/design-system';

export const Name = styled.span`
    ${textStyles.uiTextM};
    color: ${token.gray800} !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`;

export const Page = styled.span`
    ${textStyles.uiTextS};
    color: ${token.gray600} !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`;

export const PageItemStyled = styled(Flex)`
    & > :first-child > :first-child {
        flex-shrink: 0;
    }
`;

export const PageData = styled(Flex)`
    overflow: hidden;
`;
