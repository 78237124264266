import React from 'react';
import { notify, Thumbnail } from '@bynder/design-system';
import { IconCheck, IconCancel, IconRename } from '@bynder/icons';
import { Translate } from '@bynder/localization';
import { OwnershipFilterType } from 'packages/pages/components/filters/components/Ownership/types';
import { isTrialAccountStorageFn, catchResponseError, makeAbortable } from 'packages/helpers/helpers';
import CollectionsService, { createQuery } from '~/services/CollectionsService';
// TODO: LOOP DEPENDENCY
import { fetchTemplates } from '~/store/templates/templates.actions';
import { fetchGallery } from '~/store/gallery/gallery.actions';
import { sendAppcuesEvent } from '~/helpers/RouteWithAppcues';
import TemplatesService from '~/services/TemplatesService';
import { actionTypes, Category } from './types';
import { type Template } from '../templates/types';
import CreativesService from '~/services/CreativesService';
import { updateInArray } from '~/helpers/helpers';
import { fetchCreative } from '~/store/creatives/common.actions';
import { getCategoriesState } from './categories.selectors';
import { CollectionSubType, CollectionType } from '../collections/types';
import { ThumbnailWSData } from 'packages/socket';

let collectionsGetRequest;
let abortGetTemplates;

export function updateCategoryThumbnails(categoryThumbnails: ThumbnailWSData[]) {
    return { type: actionTypes.CATEGORIES_CHANGE_SOME, categoryThumbnails };
}

// todo: return unsubscribe ws callback
export function fetchCategories(options, customerId, emptyBeforeFetch = false, includeCollectionId?, callback?) {
    return (dispatch, getState) => {
        abortGetTemplates?.();
        CollectionsService.abortRequest(collectionsGetRequest);

        const fetchOptions = options || getState().categories.fetchOptions;

        if (emptyBeforeFetch) {
            fetchOptions.page = 0;
        }

        const query = createQuery({ ...fetchOptions, customerId });

        if (options && getState().categories.fetchOptions.search !== options.search) {
            dispatch({
                type: actionTypes.CATEGORIES_START_FETCH,
                searching: true,
                emptyBeforeFetch,
            });
        } else {
            dispatch({ type: actionTypes.CATEGORIES_START_FETCH, searching: false, emptyBeforeFetch });
        }

        collectionsGetRequest = CollectionsService.getCollections(query, includeCollectionId);

        collectionsGetRequest
            .then(({ status, json: { items: categories, totalItems, page } }) => {
                if (status !== 200) {
                    dispatch({ type: actionTypes.CATEGORIES_FAIL_FETCH });
                    notify({
                        thumbnail: (
                            <Thumbnail
                                variant="clean"
                                shape="circle"
                                backgroundColor="red500"
                                icon={<IconCancel />}
                                iconColor="white"
                            />
                        ),
                        title: <Translate id="pages.categories.message.failure" />,
                    });
                } else {
                    if (page) {
                        fetchOptions.page = page;
                    }

                    callback?.(categories);

                    dispatch({
                        type: actionTypes.CATEGORIES_SUCCESS_FETCH,
                        categories,
                        totalItems,
                        emptyBeforeFetch,
                        fetchOptions,
                    });
                }
            })
            .catch(catchResponseError);
    };
}

// getting fetch options inside the action creator helps to
// memoize and debounce search callbacks
export function fetchCategoriesBySearchQuery(
    query: string,
    type: CollectionType,
    subType: CollectionSubType,
    currentCompany: number,
) {
    return (dispatch, getState) => {
        const { fetchOptions } = getCategoriesState(getState());

        const newOptions = {
            ...fetchOptions,
            search: query,
            type,
            subType,
        };

        dispatch(fetchCategories(newOptions, currentCompany, true));
    };
}

export function createNewCategory(name, type, subType) {
    return (dispatch, getState) => {
        const { fetchOptions } = getState().categories;
        const customerId = getState().user.currentCompany;

        dispatch({ type: actionTypes.CATEGORIES_START_ADD_NEW });

        CollectionsService.addToCollection({ collectionId: null, customerId, name, type, subType })
            .then(({ status }) => {
                if (status === 200) {
                    dispatch({ type: actionTypes.CATEGORIES_ADD_NEW_SUCCESS });
                    dispatch(fetchCategories({ ...fetchOptions, type, subType }, customerId, true));

                    notify({
                        thumbnail: (
                            <Thumbnail
                                variant="clean"
                                shape="circle"
                                backgroundColor="green500"
                                icon={<IconCheck />}
                                iconColor="white"
                            />
                        ),
                        title: <Translate id="modal.categories.create.success" />,
                    });
                } else if (status === 409) {
                    dispatch({ type: actionTypes.CATEGORIES_ADD_NEW_FAILURE });

                    notify({
                        thumbnail: (
                            <Thumbnail
                                variant="clean"
                                shape="circle"
                                backgroundColor="red500"
                                icon={<IconCancel />}
                                iconColor="white"
                            />
                        ),
                        title: <Translate id="modal.categories.create.failure.duplicate" />,
                    });
                } else {
                    dispatch({ type: actionTypes.CATEGORIES_ADD_NEW_FAILURE });

                    notify({
                        thumbnail: (
                            <Thumbnail
                                variant="clean"
                                shape="circle"
                                backgroundColor="red500"
                                icon={<IconCancel />}
                                iconColor="white"
                            />
                        ),
                        title: <Translate id="modal.categories.create.failure" />,
                    });
                }
            })
            .catch(catchResponseError);
    };
}

export function deleteCategories(categoryIds, redirectAfter) {
    return (dispatch, getState) => {
        const customerId = getState().user.currentCompany;
        const { options, fetchOptions: templatesFetchOptions } = getState().categories;

        dispatch({ type: actionTypes.CATEGORY_DELETE_IN_PROGRESS });

        CollectionsService.deleteCollections(categoryIds)
            .then(({ status }) => {
                if (status === 200) {
                    dispatch({ type: actionTypes.CATEGORY_DELETE_SUCCESS });

                    if (redirectAfter) {
                        redirectAfter();
                    } else {
                        dispatch(fetchCategories(options, customerId, true));
                        dispatch(
                            fetchTemplates({
                                newOptions: { ...templatesFetchOptions },
                                setSelected: false,
                                fetchFromStart: true,
                            }),
                        );
                    }

                    notify({
                        thumbnail: (
                            <Thumbnail
                                variant="clean"
                                shape="circle"
                                backgroundColor="green500"
                                icon={<IconCheck />}
                                iconColor="white"
                            />
                        ),
                        title: <Translate id="modal.category.delete.success" />,
                    });
                    sendAppcuesEvent('Category deleted', { ids: categoryIds });
                } else {
                    dispatch({ type: actionTypes.CATEGORY_DELETE_FAILURE });

                    notify({
                        thumbnail: (
                            <Thumbnail
                                variant="clean"
                                shape="circle"
                                backgroundColor="red500"
                                icon={<IconCancel />}
                                iconColor="white"
                            />
                        ),
                        title: <Translate id="modal.category.delete.failure" />,
                    });
                }
            })
            .catch(catchResponseError);
    };
}

export function fetchCategory(id) {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.CATEGORY_START_FETCH });

        CollectionsService.getCollection(id)
            .then(({ status, collection }) => {
                if (status === 200) {
                    dispatch({
                        type: actionTypes.CATEGORY_SUCCESS_FETCH,
                        category: collection,
                    });
                } else {
                    dispatch({ type: actionTypes.CATEGORY_FAIL_FETCH });
                }
            })
            .catch(catchResponseError);
    };
}

export function renameCategory(id, name, isOverview) {
    return (dispatch, getState) => {
        const customerId = getState().user.currentCompany;
        const options = getState().categories.fetchOptions;
        dispatch({ type: actionTypes.CATEGORY_RENAME_IN_PROGRESS });

        CollectionsService.renameCollection(id, name)
            .then(({ status }) => {
                if (status === 200) {
                    dispatch({ type: actionTypes.CATEGORY_RENAME_SUCCESS });
                    dispatch(!isOverview ? fetchCategory(id) : fetchCategories(options, customerId, true));

                    notify({
                        thumbnail: (
                            <Thumbnail variant="clean" shape="circle" icon={<IconRename />} iconColor="gray600" />
                        ),
                        title: <Translate id="modal.category.rename.success" />,
                    });
                    sendAppcuesEvent('Category renamed', { id, name });
                } else {
                    dispatch({ type: actionTypes.CATEGORY_RENAME_FAILURE });
                    notify({
                        thumbnail: (
                            <Thumbnail
                                variant="clean"
                                shape="circle"
                                backgroundColor="red500"
                                icon={<IconCancel />}
                                iconColor="white"
                            />
                        ),
                        title: <Translate id="modal.category.rename.failure" />,
                    });
                }
            })
            .catch(catchResponseError);
    };
}

export function renameTemplateInCategory({ id, name, description, fetchNew, categoryId }) {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.CATEGORY_SET_RENAME_TEMPLATE_STATUS,
            status: true,
        });

        CreativesService.renameCreativeOrTemplate(id, { name, description })
            .then(({ status, json: template }) => {
                if (status === 200) {
                    const { categories } = getState().categories;

                    const currentCategory = categories.find((category) => category.id === categoryId);

                    if (!currentCategory) {
                        return;
                    }

                    const currentTemplates = currentCategory.templates;

                    if (!currentTemplates) {
                        return;
                    }

                    const modifiedTemplates = updateInArray(
                        currentTemplates,
                        (templateItem) => templateItem.id === id,
                        (prev) => ({ ...prev, ...template }),
                    );

                    currentCategory.templates = modifiedTemplates;

                    const modifiedCategories = categories.map((category) => {
                        if (category.id === currentCategory.id) {
                            return currentCategory;
                        }

                        return category;
                    });

                    dispatch({
                        type: actionTypes.CATEGORY_UPDATE_TEMPLATES,
                        categories: modifiedCategories,
                    });

                    if (fetchNew) {
                        dispatch(fetchCreative(id, fetchNew));
                    }

                    notify({
                        title: <Translate id="modal.design.create.template.rename.success" />,
                        variant: 'success',
                    });

                    sendAppcuesEvent('Template renamed', { id: template.id, name: template.name });
                } else {
                    dispatch({
                        type: actionTypes.CATEGORY_SET_RENAME_TEMPLATE_STATUS,
                        status: false,
                    });

                    notify({
                        title: <Translate id="modal.design.create.template.rename.failure" />,
                        variant: 'error',
                    });
                }
            })
            .catch((err) => {
                catchResponseError(err);

                dispatch({
                    type: actionTypes.CATEGORY_SET_RENAME_TEMPLATE_STATUS,
                    status: false,
                });
            });
    };
}

export function editDetailsCategory(id, categoryUpdates, isOverview) {
    return (dispatch, getState) => {
        const customerId = getState().user.currentCompany;
        const options = getState().categories.fetchOptions;
        dispatch({ type: actionTypes.CATEGORY_EDIT_IN_PROGRESS });

        CollectionsService.editCollection(id, categoryUpdates)
            .then(({ status }) => {
                if (status === 200) {
                    dispatch({ type: actionTypes.CATEGORY_EDIT_SUCCESS });
                    dispatch(!isOverview ? fetchCategory(id) : fetchCategories(options, customerId, true));

                    notify({
                        thumbnail: (
                            <Thumbnail
                                variant="clean"
                                shape="circle"
                                icon={<IconCheck />}
                                backgroundColor="green500"
                                iconColor="white"
                            />
                        ),
                        title: <Translate id="modal.category.editDetails.success" />,
                    });
                    sendAppcuesEvent('Category renamed', { id });
                } else {
                    dispatch({ type: actionTypes.CATEGORY_EDIT_FAILURE });
                    notify({
                        thumbnail: (
                            <Thumbnail
                                variant="clean"
                                shape="circle"
                                backgroundColor="red500"
                                icon={<IconCancel />}
                                iconColor="white"
                            />
                        ),
                        title: <Translate id="modal.category.editDetails.failure" />,
                    });
                }
            })
            .catch(catchResponseError);
    };
}

export function moveToCategory({
    categoryId,
    templates,
    type,
    isOverview,
    isEditor,
    silent = false,
}: {
    categoryId: Category['id'] | undefined; // todo: check if this can be undefined at all
    templates: Template['id'][];
    type: string; // todo: use literals union or enum here
    isOverview: boolean;
    isEditor: boolean;
    silent?: boolean;
}) {
    return async (dispatch, getState) => {
        const state = getState();
        const customerId = getState().user.currentCompany;

        dispatch({ type: actionTypes.CATEGORY_ADD_TEMPLATE_IN_PROGRESS });

        const res = await CollectionsService.moveToCollection(categoryId, templates, type).catch(catchResponseError);

        if (!res) {
            return;
        }

        if (res.status !== 200) {
            dispatch({ type: actionTypes.CATEGORY_ADD_TEMPLATE_FAILURE });

            notify({
                thumbnail: (
                    <Thumbnail
                        variant="clean"
                        shape="circle"
                        backgroundColor="red500"
                        icon={<IconCancel />}
                        iconColor="white"
                    />
                ),
                title: <Translate id="modal.template.move.failure" />,
            });

            return;
        }

        dispatch({ type: actionTypes.CATEGORY_ADD_TEMPLATE_SUCCESS });

        if (!isEditor) {
            const movedTemplates = templates.reduce<Record<number, number | undefined>>((acc, templateId) => {
                acc[templateId] = categoryId;

                return acc;
            }, {});

            if (isOverview) {
                const { fetchOptions } = state.categories;

                dispatch(fetchCategories({ ...fetchOptions, type }, customerId, true));
                dispatch(fetchTemplates({ newOptions: { collectionId: null }, fetchFromStart: true, movedTemplates }));
            } else {
                const { fetchOptions: templatesFetchOptions, id: currentCategoryId } = state.categories.category;

                dispatch(
                    fetchTemplates({
                        newOptions: {
                            collectionId: currentCategoryId,
                            ...templatesFetchOptions,
                        },
                        movedTemplates,
                    }),
                );

                dispatch(fetchCategory(currentCategoryId));
            }
        }

        if (!silent) {
            notify({
                thumbnail: (
                    <Thumbnail
                        variant="clean"
                        shape="circle"
                        backgroundColor="green500"
                        icon={<IconCheck />}
                        iconColor="white"
                    />
                ),
                title: <Translate id="modal.template.move.success" count={templates.length} />,
            });
        }

        sendAppcuesEvent('Moved to category', { id: categoryId });
    };
}

export function removeFromCategory(templates) {
    return (dispatch, getState) => {
        const { fetchOptions: templatesFetchOptions, id: currentCategoryId } = getState().categories.category;

        dispatch({ type: actionTypes.CATEGORY_REMOVE_FROM_TEMPLATE_IN_PROGRESS });

        CollectionsService.removeFromCollection(currentCategoryId, templates, 'CREATIVE')
            .then(({ status }) => {
                if (status === 200) {
                    const movedTemplates = templates.reduce((acc, templateId) => {
                        acc[templateId] = undefined;

                        return acc;
                    }, {});

                    dispatch({ type: actionTypes.CATEGORY_REMOVE_FROM_TEMPLATE_SUCCESS });
                    dispatch(
                        fetchTemplates({
                            newOptions: {
                                collectionId: currentCategoryId,
                                ...templatesFetchOptions,
                            },
                            fetchFromStart: true,
                            movedTemplates,
                        }),
                    );

                    dispatch(fetchCategory(currentCategoryId));

                    notify({
                        thumbnail: (
                            <Thumbnail
                                variant="clean"
                                shape="circle"
                                backgroundColor="green500"
                                icon={<IconCheck />}
                                iconColor="white"
                            />
                        ),
                        title: <Translate id="pages.categories.category.template.removeFromCategory.success" />,
                    });
                    sendAppcuesEvent('Removed from category', { id: currentCategoryId });
                } else {
                    dispatch({ type: actionTypes.CATEGORY_REMOVE_FROM_TEMPLATE_FAILURE });

                    notify({
                        thumbnail: (
                            <Thumbnail
                                variant="clean"
                                shape="circle"
                                backgroundColor="red500"
                                icon={<IconCancel />}
                                iconColor="white"
                            />
                        ),
                        title: <Translate id="pages.categories.category.template.removeFromCategory.failure" />,
                    });
                }
            })
            .catch(catchResponseError);
    };
}

export function resetOwnership() {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.CATEGORIES_RESET_OWNERSHIP,
            ownershipSource: OwnershipFilterType.OWNED_BY_OTHERS,
        });
    };
}

export function initCategories(params = {}, callback?) {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.CATEGORIES_RESET });

        if (isTrialAccountStorageFn(getState())) {
            dispatch(resetOwnership());
        }

        const options = { ...getState().categories.fetchOptions, ...params };
        dispatch(fetchCategories(options, getState().user.currentCompany, true, false, callback));
    };
}

export function initNewDesignModal(params = {} as any) {
    return (dispatch, getState) => {
        const callback = (categories) => {
            const abortController = new AbortController();
            abortGetTemplates = () => abortController.abort();

            const getTemplates = async (newOptions) => {
                const oldState = getState();
                const { fetchOptions } = oldState.templates;
                const options = {
                    ...fetchOptions,
                    ...newOptions,
                    customerId: oldState.user.currentCompany,
                    sort: ['date_updated', 'desc'],
                    page: 0,
                    size: 4,
                };

                return TemplatesService.fetchTemplates(options)
                    .then(({ status, json }) => {
                        if (status === 200) {
                            const { totalItems, items } = json;

                            return items;
                        }

                        return [];
                    })
                    .catch(catchResponseError);
            };

            makeAbortable(
                Promise.allSettled(
                    categories.map((category) =>
                        getTemplates({ collectionId: category.id, assignedToCollection: true }),
                    ),
                ),
                abortController,
            )
                .then((res: any) => {
                    const fullCategories = categories.map((category, index) => ({
                        ...category,
                        templates: res[index].value,
                    }));

                    dispatch({
                        type: actionTypes.CATEGORIES_POPULATE,
                        categories: fullCategories,
                    });
                })
                .then(() => {
                    dispatch(
                        fetchTemplates({
                            newOptions: {
                                gallery: false,
                                creativeType: undefined,
                                collectionId: undefined,
                                assignedToCollection: false,
                                search: '',
                                page: 0,
                                size: 4,
                            },
                        }),
                    );
                })
                .then(() => {
                    if (params.gallery) {
                        dispatch(
                            fetchGallery({
                                size: 4,
                            }),
                        );
                    }
                })
                .catch(catchResponseError);
        };
        dispatch(initCategories(params, callback));
    };
}

export function removeTemplatesFromCategory(categoryId: number | string, templateIds: (number | string)[]) {
    return {
        type: actionTypes.CATEGORY_TEMPLATE_REMOVE,
        categoryId,
        templateIds,
    };
}
