import React from 'react';
import { useSelector } from 'react-redux';
import { Thumbnail } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconFolderOpen } from '@bynder/icons';
import { getCategory } from 'packages/store/categories/categories.selectors';
import { Template } from 'packages/store/templates/types';
import { selectedTemplateSelector } from '~/store/templates/templates.selectors';
import { selectedGallerySelector } from '~/store/gallery/gallery.selectors';
import generateTestId from '~/helpers/testIdHelpers';
import { DesignSubPages } from './components/Sidebar/types';
import { hasPreviewImageURL, isProcessingDone } from '../components/thumbnail/utils';

const useTitleMap = () => {
    const { translate } = useTranslate();
    const selectedTemplate: Template = useSelector(selectedTemplateSelector);
    const selectedGallery: Template = useSelector(selectedGallerySelector);
    const category = useSelector(getCategory);

    const modalTitleMap = {
        [DesignSubPages.ALL]: () => ({
            title: translate('modal.design.create.title'),
        }),
        [DesignSubPages.BLANK]: () => ({
            title: translate('modal.design.create.title'),
        }),
        [DesignSubPages.CATEGORIES]: () => ({
            title: translate('modal.design.create.title'),
        }),
        [DesignSubPages.CATEGORY_DETAILS]: () => {
            if (category) {
                const categoryCoverThumbnailId = generateTestId('category_cover_thumbnail');
                const categoryColorThumbnailId = generateTestId('category_color_thumbnail');
                const categoryPreviewThumbnailId = generateTestId('category_preview_thumbnail');

                const renderThumbnail = () => {
                    if (category?.coverImageUrl) {
                        return (
                            <Thumbnail
                                variant="content"
                                imageUrl={category.coverImageUrl}
                                backgroundColor={`#${category.coverBgColor}`}
                                {...categoryCoverThumbnailId}
                            />
                        );
                    }

                    if (category?.coverBgColor) {
                        return (
                            <Thumbnail
                                variant="content"
                                backgroundColor={`#${category.coverBgColor}`}
                                {...categoryColorThumbnailId}
                            />
                        );
                    }

                    if (hasPreviewImageURL(category) && isProcessingDone(category)) {
                        return (
                            <Thumbnail
                                variant="content"
                                imageUrl={category.previewItems[category.previewItems.length - 1]?.previewUrl}
                                {...categoryPreviewThumbnailId}
                            />
                        );
                    }

                    return (
                        <Thumbnail variant="clean" icon={<IconFolderOpen />}>
                            {!isProcessingDone(category) && (
                                <Thumbnail.Overlay isLoading={!isProcessingDone(category)} />
                            )}
                        </Thumbnail>
                    );
                };

                return {
                    title: category.name,
                    secondaryInfo:
                        category && category.imageCreativesCount + category.videoCreativesCount
                            ? translate('pages.categories.category.numberOftemplates', {
                                  count: category.imageCreativesCount + category.videoCreativesCount,
                              })
                            : translate('pages.categories.category.numberOftemplates.empty'),
                    thumbnail: renderThumbnail(),
                };
            }

            return {
                title: ' ',
                secondaryInfo: ' ',
            };
        },
        [DesignSubPages.GALLERY]: () => ({
            title: translate('modal.design.create.title'),
        }),
        [DesignSubPages.IMPORT]: () => ({
            title: translate('modal.design.create.import.title'),
        }),
        [DesignSubPages.FIGMA_IMPORT]: () => ({
            title: translate('modal.design.create.figma_import.title'),
        }),
        [DesignSubPages.IMPORT_STATUS]: () => ({
            title: translate('modal.design.create.import.complete.title'),
        }),
        [DesignSubPages.TEMPLATE_PREVIEW]: () => {
            if (selectedTemplate) {
                const getSecondaryTitle = () =>
                    selectedTemplate?.pages?.length > 1
                        ? translate('modal.design.create.design.numberOfPages', {
                              count: selectedTemplate?.pages?.length,
                          })
                        : `${selectedTemplate.pages[0].width} × ${selectedTemplate.pages[0].height} px`;

                return {
                    title: selectedTemplate?.name,
                    secondaryInfo: getSecondaryTitle(),
                };
            }

            return {
                title: ' ',
                secondaryInfo: ' ',
            };
        },
        [DesignSubPages.GALLERY_PREVIEW]: () => {
            if (selectedGallery) {
                const getSecondaryTitle = () =>
                    selectedGallery?.pages?.length > 1
                        ? translate('modal.design.create.design.numberOfPages', {
                              count: selectedGallery?.pages?.length,
                          })
                        : `${selectedGallery.pages[0].width} × ${selectedGallery.pages[0].height} px`;

                return {
                    title: selectedGallery?.name,
                    secondaryInfo: getSecondaryTitle(),
                };
            }

            return {
                title: ' ',
                secondaryInfo: ' ',
            };
        },
    };

    return modalTitleMap;
};

export default useTitleMap;
