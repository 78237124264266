import React, { FC } from 'react';
import { Flex } from '@bynder/design-system';
import { upperFirst } from '../utils';
import {
    ExportItemRow,
    ExportItemRowWrapper,
    ExportItemSubtitle,
    ExportItemThumbnailProgress,
    ExportItemTitle,
} from './AllExports.styled';
import type { ExportJobItemProgressType } from 'packages/variation-export/AllExports/types';

type InProgressJobRowProps = {
    job: ExportJobItemProgressType;
};

const displayOrderComparator = (a: { displayOrder: number }, b: { displayOrder: number }) =>
    a.displayOrder - b.displayOrder;

export const InProgressJobRow: FC<InProgressJobRowProps> = ({ job }) => {
    const subTitle =
        (job.progresses || [])
            .sort(displayOrderComparator)
            .map((item) => `${upperFirst(item.status)} ${item.progress}%`)
            .join(' • ') || 'Preparing...';

    return (
        <ExportItemRowWrapper className="w-100">
            <ExportItemRow justifyContent="space-between" hoverdisabled="true">
                <Flex alignItems="center">
                    <ExportItemThumbnailProgress />
                    <Flex direction="column">
                        <ExportItemTitle>{job.name}</ExportItemTitle>
                        <Flex alignItems="center">
                            <ExportItemSubtitle>{subTitle}</ExportItemSubtitle>
                        </Flex>
                    </Flex>
                </Flex>
            </ExportItemRow>
        </ExportItemRowWrapper>
    );
};
