import { mat2d } from 'gl-matrix';

import { Dimension, DimensionObject } from './Dimension';
import { Position, PositionObject } from './Position';

export type TransformDataObject = {
    position: PositionObject;
    dimension: DimensionObject;
    rotation: number;
    scale: number;
    transforms: mat2d[];
};

export class TransformData {
    position: Position;
    dimension: Dimension;
    rotation: number = 0;
    scale: number = 1.0;
    transforms: mat2d[];

    constructor(position: Position, dimension: Dimension, rotation = 0, scale = 1.0, transforms: mat2d[] = []) {
        this.position = position;
        this.dimension = dimension;
        this.rotation = rotation;
        this.scale = scale;
        this.transforms = transforms;
    }

    toObject(): TransformDataObject {
        return {
            position: this.position.toObject(),
            dimension: this.dimension.toObject(),
            rotation: this.rotation,
            scale: this.scale,
            transforms: this.transforms,
        };
    }
}
