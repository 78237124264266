/**
 This hook is used to get the dispatch function that can handle thunk actions.
 It is used in the same way as the useDispatch hook from react-redux,
 but it properly types the dispatch function to be able to use promises returned from dispatched actions.
 */

import { type ThunkDispatch } from 'redux-thunk';
import { type AnyAction } from 'redux';
import { useDispatch } from 'react-redux';

type AppDispatch = ThunkDispatch<any, unknown, AnyAction>;

export default function useThunkDispatch() {
    return useDispatch<AppDispatch>();
}
