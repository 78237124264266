import React, { useEffect } from 'react';
import ZoomControls from '../../ZoomControls/ZoomControls';
import useEditor from '../../../hooks/useEditor';
import useForceUpdate from '../../../hooks/useForceUpdate';
import { isCtrlKey } from '~/helpers/hotKeys';
import type { ArrayElement } from '~/common/types';

export const ZOOM_VALUES = [10, 25, 50, 100, 200, 400, 800] as const;

type ZoomValueType = ArrayElement<typeof ZOOM_VALUES>;

const CanvasZoomControls = () => {
    const { manipulationRenderer } = useEditor();
    const forceUpdate = useForceUpdate();

    const getZoomPercent = () => Math.ceil((manipulationRenderer?.getScale() || 1) * 100);

    const getPrevZoomValue = () => {
        const zoomPercent = getZoomPercent();
        const defaultArrayIdx = ZOOM_VALUES.indexOf(zoomPercent);

        if (defaultArrayIdx === -1) {
            const closestBiggerValueIdx = ZOOM_VALUES.findIndex((currentValue) => currentValue > zoomPercent);

            return closestBiggerValueIdx === -1
                ? ZOOM_VALUES[ZOOM_VALUES.length - 1]
                : ZOOM_VALUES[closestBiggerValueIdx - 1];
        }

        if (defaultArrayIdx === 0) {
            return null;
        }

        return ZOOM_VALUES[defaultArrayIdx - 1];
    };

    const getNextZoomValue = () => {
        const zoomPercent = getZoomPercent();
        const defaultArrayIdx = ZOOM_VALUES.indexOf(zoomPercent);

        if (defaultArrayIdx === -1) {
            const closestBiggerValueIdx = ZOOM_VALUES.findIndex((currentValue) => currentValue > zoomPercent);

            return closestBiggerValueIdx === -1
                ? ZOOM_VALUES[ZOOM_VALUES.length - 1]
                : ZOOM_VALUES[closestBiggerValueIdx];
        }

        if (defaultArrayIdx === ZOOM_VALUES.length - 1) {
            return null;
        }

        return ZOOM_VALUES[defaultArrayIdx + 1];
    };

    const setZoomLevel = (zoomValue: ZoomValueType) => {
        if (!manipulationRenderer) {
            return;
        }

        manipulationRenderer.setScale(zoomValue / 100);
    };

    const onZoomClick = (value: ZoomValueType) => {
        setZoomLevel(value);
    };

    const zoomOut = () => {
        const val = getPrevZoomValue();

        if (val) {
            setZoomLevel(val);
        }
    };

    const zoomIn = () => {
        const val = getNextZoomValue();

        if (val) {
            setZoomLevel(val);
        }
    };

    const zoomToFit = () => {
        if (!manipulationRenderer) {
            return;
        }

        manipulationRenderer.zoomToFit();
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (!isCtrlKey(event)) {
            return;
        }

        switch (event.code) {
            case 'Equal': {
                event.preventDefault();
                zoomIn();
                break;
            }
            case 'Minus': {
                event.preventDefault();
                zoomOut();
                break;
            }
            case 'Digit0': {
                event.preventDefault();
                setZoomLevel(100);
                break;
            }
            case 'Digit1': {
                event.preventDefault();
                zoomToFit();
                break;
            }

            default: {
                break;
            }
        }
    };

    useEffect(() => {
        if (!manipulationRenderer) {
            return;
        }

        document.addEventListener('keydown', handleKeyDown);
        manipulationRenderer.eventEmitter.on('scaleUpdated', forceUpdate);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            manipulationRenderer.eventEmitter.off('scaleUpdated', forceUpdate);
        };
    }, [manipulationRenderer, forceUpdate]);

    return (
        <ZoomControls
            onZoomOut={zoomOut}
            onZoomIn={zoomIn}
            onZoomClick={onZoomClick}
            onZoomToFit={zoomToFit}
            zoomPercentage={getZoomPercent()}
            maxZoom={ZOOM_VALUES[ZOOM_VALUES.length - 1]}
            minZoom={ZOOM_VALUES[0]}
        />
    );
};

export default CanvasZoomControls;
