import React, { useEffect, useMemo, useState } from 'react';
import { IconUngroup } from '@bynder/icons';
import generateTestId from '~/helpers/testIdHelpers';
import useEditor from '../../../../../hooks/useEditor';
import { collectTopLevelGroups } from './utils';
import useElementGeneralActions from 'packages/pages/editor/useElementGeneralActions';
import useGeneralHotkeys from 'packages/pages/editor/useGeneralHotKeys';
import { TimelineActionsIcon } from '../TimelineActions.styled';

export default function UngroupAction() {
    const { manipulationRenderer } = useEditor();
    const [selectedGroups, setSelectedGroups] = useState(() =>
        collectTopLevelGroups(manipulationRenderer?.getSelectedElements() || []),
    );
    const isActive = !!selectedGroups.length;

    const { handleUnGroup } = useElementGeneralActions(setSelectedGroups, selectedGroups);

    useGeneralHotkeys({ handleUnGroup });

    const testId = useMemo(() => generateTestId('timeline_actions_ungroup'), []);

    useEffect(() => {
        if (!manipulationRenderer) {
            return;
        }

        const listener = () => {
            setSelectedGroups(collectTopLevelGroups(manipulationRenderer.getSelectedElements()));
        };

        manipulationRenderer.eventEmitter.on('elementSelected', listener);

        return () => manipulationRenderer.eventEmitter.off('elementSelected', listener);
    }, [manipulationRenderer]);

    return (
        <div title="Ungroup">
            <TimelineActionsIcon onClick={handleUnGroup} active={isActive} {...testId}>
                <IconUngroup />
            </TimelineActionsIcon>
        </div>
    );
}
