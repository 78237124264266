import { useCallback, useEffect, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Ordering } from 'packages/services/graphql/types';
import { getPortalDomain } from 'packages/store/platform/platform.selectors';
import useDirectAccessToken from 'packages/hooks/useDirectAccessToken';
import { ABORT_ERROR_NAME, makeAbortable } from 'packages/helpers/helpers';
import AssetSourceService, { type Collections } from 'packages/services/assetSource/AssetSourceService';
import { isErr } from 'packages/services/graphql/utils';

type Data =
    | { tag: 'Loading'; value: null | Collections }
    | { tag: 'Loaded'; value: Collections }
    | { tag: 'Failure'; value: string };

const emptyCollections = [];

export function useGetCollections(searchTerm: string, orderBy: Ordering) {
    const portalDomain = useSelector(getPortalDomain);
    const { getDirectAccessToken } = useDirectAccessToken();
    const cancelReqRef = useRef<() => void | undefined>();

    const [data, dispatch] = useReducer(reducer, { tag: 'Loading', value: null });

    const isLoading = data.tag === 'Loading';
    const collections = data.tag === 'Failure' ? emptyCollections : data.value?.collections.nodes ?? emptyCollections;
    const hasNextPage = data.tag === 'Loaded' && data.value.collections.pageInfo.hasNextPage;
    const after = hasNextPage ? data.value.collections.pageInfo.endCursor : null;

    const loadMore = useCallback(async () => {
        if (!hasNextPage) {
            return;
        }

        dispatch(loadingStart());

        const abortController = new AbortController();

        cancelReqRef.current = () => {
            abortController.abort();
        };

        try {
            const res = await makeAbortable(
                AssetSourceService.fetchCollections(portalDomain, getDirectAccessToken, {
                    orderBy,
                    searchTerm,
                    after,
                }),
                abortController,
            );

            dispatch(loadedNewPage(res));
        } catch (e) {
            dispatch(failure(e as Error));
        }
    }, [hasNextPage, after, portalDomain, getDirectAccessToken, orderBy, searchTerm]);

    useEffect(() => {
        dispatch(loadingStart());

        const abortController = new AbortController();

        cancelReqRef.current = () => {
            abortController.abort();
        };

        makeAbortable(
            AssetSourceService.fetchCollections(portalDomain, getDirectAccessToken, {
                orderBy,
                searchTerm,
                after: null,
            }),
            abortController,
        )
            .then((res) => {
                dispatch(loaded(res));
            })
            .catch((e) => dispatch(failure(e as Error)));

        return () => cancelReqRef.current?.();
    }, [getDirectAccessToken, orderBy, portalDomain, searchTerm]);

    return {
        collections,
        isLoading,
        hasNextPage,
        loadMore,
    };
}

type FetchCollectionsRes = Awaited<ReturnType<typeof AssetSourceService.fetchCollections>>;

function loadingStart() {
    return {
        type: 'LOADING_START',
    } as const;
}

function loaded(payload: FetchCollectionsRes) {
    return {
        type: 'LOADED',
        payload,
    } as const;
}

function loadedNewPage(payload: FetchCollectionsRes) {
    return {
        type: 'LOADED_NEW_PAGE',
        payload,
    } as const;
}

function failure(payload: Error) {
    return {
        type: 'FAILURE',
        payload,
    } as const;
}

type Action =
    | ReturnType<typeof loadingStart>
    | ReturnType<typeof loaded>
    | ReturnType<typeof loadedNewPage>
    | ReturnType<typeof failure>;

function reducer(state: Data, action: Action): Data {
    switch (action.type) {
        case 'LOADING_START':
            return {
                tag: 'Loading',
                value: state.tag === 'Failure' ? null : state.value,
            };
        case 'LOADED':
            if (isErr(action.payload)) {
                return {
                    tag: 'Failure',
                    value: action.payload.error,
                };
            }

            return {
                tag: 'Loaded',
                value: action.payload.value,
            };
        case 'LOADED_NEW_PAGE':
            if (state.tag === 'Failure' || !state.value) {
                console.error('Trying to load more asset sources without initial page');

                return state;
            }

            if (isErr(action.payload)) {
                return state;
            }

            return {
                tag: 'Loaded',
                value: {
                    collections: {
                        ...action.payload.value.collections,
                        nodes: [...state.value.collections.nodes, ...action.payload.value.collections.nodes],
                    },
                },
            };
        case 'FAILURE':
            if (action.payload.name === ABORT_ERROR_NAME) {
                return {
                    tag: 'Loading',
                    value: null,
                };
            }

            return {
                tag: 'Failure',
                value: 'Something went wrong',
            };
    }
}
