import styled from 'styled-components';
import { Button, SkeletonText, SkeletonThumbnail, token } from '@bynder/design-system';
import { CardBoxProps } from './types';

export const CardBox = styled.div<CardBoxProps>`
    padding: ${token.spacing2}; // reserve space for outline
    img {
        height: ${({ defaultHeight }) => `${defaultHeight < 132 ? 132 : defaultHeight > 320 ? 320 : defaultHeight}px`};
    }
    a > div > div > div > svg {
        height: ${({ defaultHeight }) => `${defaultHeight < 132 ? 132 : defaultHeight > 320 ? 320 : defaultHeight}px`};
    }
    a:hover {
        text-decoration: none;
        cursor: default;
    }
    a[href*='designs'] {
        cursor: pointer;
    }
`;

export const WishWeCouldTurnBackTimeToTheGoodOldDays = styled.div`
    display: flex;
    align-items: center;
    margin: ${token.spacing6};
    padding: ${token.spacing4};
    padding-left: ${token.spacing6};
    border-radius: ${token.radiusMedium};
    background: ${token.gray50};

    font-size: ${token.fontSize100};
    line-height: 20px;
    margin-bottom: ${token.spacing2};
    color: ${token.gray800a};

    div:first-child {
        flex: 1;
        display: flex;
        align-items: center;
    }

    a {
        /* color: ${token.blue600}; */
        text-decoration: none;

        :hover {
            /* color: ${token.blue700}; */
            text-decoration: none;
        }
    }
`;

export const StyledSkeletonButton = styled(Button)`
    min-width: 140px;
`;

export const StyledSkeletonTextWhite = styled(SkeletonText)`
    &:before {
        width: 100%;
        background: rgba(255, 255, 255, 0.2);
    }
`;

export const StyledSkeletonText = styled(SkeletonText)`
    &:before {
        width: 100%;
    }
`;

export const StyledSkeletonThumbnail = styled(SkeletonThumbnail)`
    & > div {
        background-color: rgba(255, 255, 255, 0.2) !important;
    }
`;
