import React from 'react';
import { useTranslate } from '@bynder/localization';
import {
    Icon11Square,
    IconAdwords,
    IconFacebook,
    IconInstagram,
    IconLandscape,
    IconLinkedin,
    IconPortrait,
    IconTiktok,
    IconX,
    IconYoutube,
} from '@bynder/icons';
import { Categories, imageSizes, videoSizes } from './types';

function useSizes(videoSearch: string, imageSearch: string, withCustomSize: boolean = false) {
    const { translate } = useTranslate();

    const customSizeCategory: Categories = [
        {
            label: translate('modal.design.create.blank.category.custom'),
            items: [
                {
                    label: translate('modal.design.create.blank.items.custom'),
                    icon: <IconLandscape />,
                    size: { width: 0, height: 0 },
                },
            ],
        },
    ];

    let videoCategories: Categories = [
        {
            label: translate('modal.design.create.blank.category.common'),
            items: [
                {
                    label: translate('modal.design.create.blank.items.landscape'),
                    icon: <IconLandscape />,
                    size: videoSizes.LANDSCAPE,
                },
                {
                    label: translate('modal.design.create.blank.items.square'),
                    icon: <Icon11Square />,
                    size: videoSizes.SQUARE,
                },
                {
                    label: translate('modal.design.create.blank.items.portrait'),
                    icon: <IconPortrait />,
                    size: videoSizes.PORTRAIT,
                },
            ],
        },
        {
            label: translate('modal.design.create.blank.category.instagram'),
            items: [
                {
                    label: translate('modal.design.create.blank.items.ig_post'),
                    icon: <IconInstagram />,
                    size: videoSizes.IG_POST,
                },
                {
                    label: translate('modal.design.create.blank.items.ig_reels'),
                    icon: <IconInstagram />,
                    size: videoSizes.IG_REELS,
                },
                {
                    label: translate('modal.design.create.blank.items.ig_story'),
                    icon: <IconInstagram />,
                    size: videoSizes.IG_STORY,
                },
            ],
        },
        {
            label: translate('modal.design.create.blank.category.facebook'),
            items: [
                {
                    label: translate('modal.design.create.blank.items.fb_post'),
                    icon: <IconFacebook />,
                    size: videoSizes.FB_POST,
                },
                {
                    label: translate('modal.design.create.blank.items.fb_story'),
                    icon: <IconFacebook />,
                    size: videoSizes.FB_STORY,
                },
            ],
        },
        {
            label: translate('modal.design.create.blank.category.linkedin'),
            items: [
                {
                    label: translate('modal.design.create.blank.items.li_post'),
                    icon: <IconLinkedin />,
                    size: videoSizes.LI_POST,
                },
                {
                    label: translate('modal.design.create.blank.items.li_story'),
                    icon: <IconLinkedin />,
                    size: videoSizes.LI_STORY,
                },
                {
                    label: translate('modal.design.create.blank.items.li_add'),
                    icon: <IconLinkedin />,
                    size: videoSizes.LI_ADD,
                },
            ],
        },
        {
            label: translate('modal.design.create.blank.category.google_adwords'),
            items: [
                {
                    label: translate('modal.design.create.blank.items.ga_square'),
                    icon: <IconAdwords />,
                    size: videoSizes.GA_SQUARE,
                },
                {
                    label: translate('modal.design.create.blank.items.ga_small_square'),
                    icon: <IconAdwords />,
                    size: videoSizes.GA_SMALL_SQUARE,
                },
                {
                    label: translate('modal.design.create.blank.items.ga_inline'),
                    icon: <IconAdwords />,
                    size: videoSizes.GA_INLINE_AD,
                },
                {
                    label: translate('modal.design.create.blank.items.ga_large'),
                    icon: <IconAdwords />,
                    size: videoSizes.GA_LARGE_AD,
                },
            ],
        },
        {
            label: translate('modal.design.create.blank.category.x'),
            items: [
                {
                    label: translate('modal.design.create.blank.items.x_ad'),
                    icon: <IconX />,
                    size: videoSizes.TW_AD,
                },
                {
                    label: translate('modal.design.create.blank.items.x_post'),
                    icon: <IconX />,
                    size: videoSizes.TW_POST,
                },
                {
                    label: translate('modal.design.create.blank.items.x_video'),
                    icon: <IconX />,
                    size: videoSizes.TW_VIDEO,
                },
            ],
        },
        {
            label: translate('modal.design.create.blank.category.tiktok'),
            items: [
                {
                    label: translate('modal.design.create.blank.items.tt_video'),
                    icon: <IconTiktok />,
                    size: videoSizes.TT_VIDEO,
                },
                {
                    label: translate('modal.design.create.blank.items.tt_bg'),
                    icon: <IconTiktok />,
                    size: videoSizes.TT_BG,
                },
            ],
        },
        {
            label: translate('modal.design.create.blank.category.youtube'),
            items: [
                {
                    label: translate('modal.design.create.blank.items.yt_ad'),
                    icon: <IconYoutube />,
                    size: videoSizes.YT_VIDEO_AD,
                },
                {
                    label: translate('modal.design.create.blank.items.yt_shorts'),
                    icon: <IconYoutube />,
                    size: videoSizes.YT_SHORTS,
                },
                {
                    label: translate('modal.design.create.blank.items.yt_4k'),
                    icon: <IconYoutube />,
                    size: videoSizes.YT_4K,
                },
            ],
        },
    ];

    let imageCategories: Categories = [
        {
            label: translate('modal.design.create.blank.category.common'),
            items: [
                {
                    label: translate('modal.design.create.blank.items.landscape'),
                    icon: <IconLandscape />,
                    size: imageSizes.LANDSCAPE,
                },
                {
                    label: translate('modal.design.create.blank.items.square'),
                    icon: <Icon11Square />,
                    size: imageSizes.SQUARE,
                },
                {
                    label: translate('modal.design.create.blank.items.portrait'),
                    icon: <IconPortrait />,
                    size: imageSizes.PORTRAIT,
                },
            ],
        },
        {
            label: translate('modal.design.create.blank.category.instagram'),
            items: [
                {
                    label: translate('modal.design.create.blank.items.ig_post'),
                    icon: <IconInstagram />,
                    size: imageSizes.IG_POST,
                },
                {
                    label: translate('modal.design.create.blank.items.ig_story'),
                    icon: <IconInstagram />,
                    size: imageSizes.IG_STORY,
                },
            ],
        },
        {
            label: translate('modal.design.create.blank.category.facebook'),
            items: [
                {
                    label: translate('modal.design.create.blank.items.fb_ad'),
                    icon: <IconFacebook />,
                    size: imageSizes.FB_AD,
                },
                {
                    label: translate('modal.design.create.blank.items.fb_app_ad'),
                    icon: <IconFacebook />,
                    size: imageSizes.FB_APP_AD,
                },
                {
                    label: translate('modal.design.create.blank.items.fb_cover'),
                    icon: <IconFacebook />,
                    size: imageSizes.FB_COVER,
                },
                {
                    label: translate('modal.design.create.blank.items.fb_post'),
                    icon: <IconFacebook />,
                    size: imageSizes.FB_POST,
                },
                {
                    label: translate('modal.design.create.blank.items.fb_story'),
                    icon: <IconFacebook />,
                    size: imageSizes.FB_STORY,
                },
                {
                    label: translate('modal.design.create.blank.items.fb_event_cover'),
                    icon: <IconFacebook />,
                    size: imageSizes.FB_EVENT_COVER,
                },
            ],
        },
        {
            label: translate('modal.design.create.blank.category.linkedin'),
            items: [
                {
                    label: translate('modal.design.create.blank.items.li_post'),
                    icon: <IconLinkedin />,
                    size: imageSizes.LI_POST,
                },
                {
                    label: translate('modal.design.create.blank.items.li_story'),
                    icon: <IconLinkedin />,
                    size: imageSizes.LI_STORY,
                },
            ],
        },
        {
            label: translate('modal.design.create.blank.category.google_adwords'),
            items: [
                {
                    label: translate('modal.design.create.blank.items.ga_square'),
                    icon: <IconAdwords />,
                    size: imageSizes.GA_SQUARE,
                },
                {
                    label: translate('modal.design.create.blank.items.ga_small_square'),
                    icon: <IconAdwords />,
                    size: imageSizes.GA_SMALL_SQUARE,
                },
                {
                    label: translate('modal.design.create.blank.items.ga_inline'),
                    icon: <IconAdwords />,
                    size: imageSizes.GA_INLINE_AD,
                },
                {
                    label: translate('modal.design.create.blank.items.ga_large'),
                    icon: <IconAdwords />,
                    size: imageSizes.GA_LARGE_AD,
                },
            ],
        },
        {
            label: translate('modal.design.create.blank.category.x'),
            items: [
                {
                    label: translate('modal.design.create.blank.items.x_ad'),
                    icon: <IconX />,
                    size: imageSizes.TW_AD,
                },
                {
                    label: translate('modal.design.create.blank.items.x_header'),
                    icon: <IconX />,
                    size: imageSizes.TW_HEADER,
                },
                {
                    label: translate('modal.design.create.blank.items.x_post'),
                    icon: <IconX />,
                    size: imageSizes.TW_POST,
                },
            ],
        },
        {
            label: translate('modal.design.create.blank.category.tiktok'),
            items: [
                {
                    label: translate('modal.design.create.blank.items.tt_bg'),
                    icon: <IconTiktok />,
                    size: imageSizes.TT_BG,
                },
            ],
        },
        {
            label: translate('modal.design.create.blank.category.youtube'),
            items: [
                {
                    label: translate('modal.design.create.blank.items.yt_art'),
                    icon: <IconYoutube />,
                    size: imageSizes.YT_ART,
                },
                {
                    label: translate('modal.design.create.blank.items.yt_logo'),
                    icon: <IconYoutube />,
                    size: imageSizes.YT_LOGO,
                },
                {
                    label: translate('modal.design.create.blank.items.yt_thumbnail'),
                    icon: <IconYoutube />,
                    size: imageSizes.YT_THUMBNAIL,
                },
            ],
        },
    ];

    if (withCustomSize) {
        videoCategories = [...customSizeCategory, ...videoCategories];
        imageCategories = [...customSizeCategory, ...imageCategories];
    }

    const filterSizes = (categories: Categories, searchValue: string) =>
        categories.reduce((accumulator: any, category) => {
            const items = category.items.filter((item) => item.label.toLowerCase().includes(searchValue.toLowerCase()));

            if (!items.length) {
                return accumulator;
            }

            accumulator.push({ ...category, items });

            return accumulator;
        }, []);

    const findByFormatKey = (categories, formatKey) => {
        let matchItem;
        categories.some((category) => {
            const foundItem = category.items.find((item) => item.size.format === formatKey);

            if (foundItem) {
                matchItem = foundItem;

                return true;
            }

            return false;
        });

        if (!matchItem) {
            return customSizeCategory[0].items[0];
        }

        return matchItem;
    };

    return {
        videoCategories: filterSizes(videoCategories, videoSearch),
        imageCategories: filterSizes(imageCategories, imageSearch),
        customSizeCategory: customSizeCategory[0].items[0],
        findByFormatKey,
    };
}

export default useSizes;
