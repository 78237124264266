import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getPortalDomain } from 'packages/store/platform/platform.selectors';
import useDirectAccessToken from 'packages/hooks/useDirectAccessToken';
import AssetSourceService from 'packages/services/assetSource/AssetSourceService';
import { isErr } from 'packages/services/graphql/utils';

export const useGetCollectionById = () => {
    const portalDomain = useSelector(getPortalDomain);
    const { getDirectAccessToken } = useDirectAccessToken();

    const fetchCollectionById = useCallback(
        async (collectionId: string) => {
            const res = await AssetSourceService.fetchCollection(portalDomain, getDirectAccessToken, collectionId);

            if (isErr(res)) {
                return null;
            }

            return res.value.node;
        },
        [getDirectAccessToken, portalDomain],
    );

    return { fetchCollectionById };
};
