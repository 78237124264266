import { BaseMultiPageModel } from '../BaseMultiPageModel/BaseMultiPageModel';
import { IImageModel } from './IImageModel';
import type { IMultiPageImageModel } from './IMultiPageImageModel';
import { ImageModel } from './ImageModel';

export class MultiPageImageModel extends BaseMultiPageModel<IImageModel> implements IMultiPageImageModel {
    createModel(): ImageModel {
        return new ImageModel();
    }

    getCopy(): MultiPageImageModel {
        const copy = new MultiPageImageModel();
        copy.copyFrom(this);

        return copy;
    }
}
