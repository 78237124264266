import React from 'react';
import { SkeletonThumbnail, SkeletonText } from '@bynder/design-system';
import { StyledSkeletonNavContainer, StyledSkeletonNavItem } from './Sidebar.styled';

const SidebarSkeleton = () => (
    <StyledSkeletonNavContainer>
        <StyledSkeletonNavItem>
            <SkeletonThumbnail size="xs" />
            <SkeletonText />
        </StyledSkeletonNavItem>
        <StyledSkeletonNavItem>
            <SkeletonThumbnail size="xs" />
            <SkeletonText />
        </StyledSkeletonNavItem>
        <StyledSkeletonNavItem>
            <SkeletonThumbnail size="xs" />
            <SkeletonText variant="secondary" />
        </StyledSkeletonNavItem>
    </StyledSkeletonNavContainer>
);

export default SidebarSkeleton;
