import { useCallback, useEffect, useRef } from 'react';
import store from 'store2';
import { useDragEvents } from '../tracks/dragging/useDragEvents';

const STORAGE_KEY = 'timeline-height';
const TIMELINE_MIN_HEIGHT = 350;
const CANVAS_MIN_HEIGHT = 150;
const EDITOR_HEADER_HEIGHT = 130;
const RESIZE_LINE_HEIGHT = 12;

const getHeightBySelector = (selector: string) => document.querySelector(selector)?.getBoundingClientRect()?.height;

export const useTimelineResizing = (creativeId: number, onResize: (height: number) => void) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const containerHeightRef = useRef(0);
    const editorHeight = useRef(0);

    const handleDragStart = useCallback(() => {
        containerHeightRef.current = containerRef.current?.getBoundingClientRect()?.height;
    }, []);

    const onSizeChange = useCallback(
        (height: number) => {
            const savedState = store.get(STORAGE_KEY) || {};
            store.set(STORAGE_KEY, { ...savedState, [creativeId]: height });

            if (containerRef.current) {
                containerRef.current.style.flexBasis = height + 'px';
            }

            // Don't know what is 4, I guess it's some margin or padding
            const canvasHeight =
                editorHeight.current -
                height -
                4 * RESIZE_LINE_HEIGHT -
                getHeightBySelector('.app--header') -
                getHeightBySelector('.zoom-control-wrapper');
            onResize(canvasHeight);
        },
        [creativeId, onResize],
    );

    const calculateHeight = useCallback(
        (deltaY: number) => {
            if (!deltaY) {
                const storedHeight = store.get(STORAGE_KEY)?.[creativeId] || null;

                if (storedHeight) {
                    return storedHeight;
                }
            }

            const maxTimelineHeight = editorHeight.current - CANVAS_MIN_HEIGHT - EDITOR_HEADER_HEIGHT;
            const newTimelineHeight =
                containerHeightRef.current - deltaY > maxTimelineHeight
                    ? maxTimelineHeight
                    : containerHeightRef.current - deltaY;

            return Math.min(Math.max(newTimelineHeight, 350), editorHeight.current - CANVAS_MIN_HEIGHT);
        },
        [creativeId],
    );

    const onDrag = useCallback(
        (deltaX: number, deltaY: number) => {
            const element = document.querySelector('.canvas-container');

            if (!element) {
                return;
            }

            const height = calculateHeight(deltaY);
            const canvasComputedStyles = getComputedStyle(element);

            if (
                (parseInt(canvasComputedStyles.height, 10) < CANVAS_MIN_HEIGHT && deltaY < 0) ||
                height < TIMELINE_MIN_HEIGHT
            ) {
                return;
            }

            requestAnimationFrame(() => {
                onSizeChange(height);
            });
        },
        [calculateHeight, onSizeChange],
    );

    const handleWindowResize = useCallback(() => {
        const editorContainer = document.querySelector('.editor-container');

        if (!editorContainer) {
            return;
        }

        editorHeight.current = parseInt(getComputedStyle(editorContainer).height, 10);
        onDrag(0, 0);
    }, [onDrag]);

    useEffect(() => {
        if (document.querySelector('.editor-container')) {
            handleDragStart();
            handleWindowResize();
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleDragStart, handleWindowResize]);

    const [onMouseDown] = useDragEvents({ onDragStart: handleDragStart, onDrag });

    return [containerRef, onMouseDown];
};
