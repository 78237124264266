export function getAlpha(color): string {
    return color.substring(color.lastIndexOf(',') + 1, color.lastIndexOf(')'));
}

export function hexToRgb(hex: string, a: string | number) {
    let newHex = hex.replace('#', '').replace(/_/g, '');

    if (newHex.length === 3) {
        newHex = `${newHex[0].repeat(2) + newHex[1].repeat(2) + newHex[2].repeat(2)}`;
    }

    if (newHex.length < 3) {
        newHex = 'FFFFFF';
    }

    const r = parseInt(newHex.substring(0, 2), 16) || 0;
    const g = parseInt(newHex.substring(2, 4), 16) || 0;
    const b = parseInt(newHex.substring(4, 6), 16) || 0;

    return `rgba(${r},${g},${b},${a})`;
}

export function rgbToHex(rgb) {
    rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
    return rgb && rgb.length === 4
        ? `#${`0${parseInt(rgb[1], 10).toString(16).toUpperCase()}`.slice(-2)}${`0${parseInt(rgb[2], 10)
              .toString(16)
              .toUpperCase()}`.slice(-2)}${`0${parseInt(rgb[3], 10).toString(16).toUpperCase()}`.slice(-2)}`
        : '';
}

export function mouseDownListener(e, state, main, pickerOnChange) {
    if (!state.getColorMode) return;

    const { layerX, layerY } = e;
    const x = (e.target.width / e.target.clientWidth) * layerX;
    const y = (e.target.height / e.target.clientHeight) * layerY;

    const ctx = main.getContext('2d');
    const px = ctx.getImageData(x, y, 1, 1).data;

    const color = {
        rgb: {
            r: px[0],
            g: px[1],
            b: px[2],
            a: state.alpha / 100,
        },
        hex: rgbToHex(`rgba(${px[0]},${px[1]},${px[2]},${Math.round((state.alpha / 100) * 255)})`),
    };
    pickerOnChange(color);
}

export function mouseMoveListener(e: any, zoom: HTMLCanvasElement, main: HTMLDivElement) {
    const { pageX, pageY, layerX, layerY } = e;
    const x = (e.target.width / e.target.clientWidth) * layerX;
    const y = (e.target.height / e.target.clientHeight) * layerY;

    const zoomCtx: any = zoom.getContext('2d');

    zoomCtx.arc(zoom.width / 2, zoom.height / 2, 110, 0, 2 * Math.PI);
    zoomCtx.drawImage(main, x - 5, y - 5, 11, 11, 0, 0, zoom.width, zoom.height);
    for (let x = 0; x < zoom.width; x += zoom.width / 11) {
        zoomCtx.moveTo(x, 0);
        zoomCtx.lineTo(x, zoom.height);
    }

    for (let y = 0; y < zoom.height; y += zoom.height / 11) {
        zoomCtx.moveTo(0, y);
        zoomCtx.lineTo(zoom.width, y);
    }

    zoomCtx.strokeStyle = '#777';
    zoomCtx.stroke();

    zoomCtx.strokeStyle = '#fff';
    zoomCtx.strokeRect((zoom.height / 11) * 5, (zoom.height / 11) * 5, zoom.height / 11, zoom.height / 11);

    zoom.style.top = `${pageY}px`;
    zoom.style.left = `${pageX}px`;
    zoom.style.display = 'block';
}
