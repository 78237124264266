import React, { useState, memo, useMemo, useEffect } from 'react';
import { IconTranslate } from '@bynder/icons';
import { Translate, useTranslate } from '@bynder/localization';
import { Button, Divider, Form, Modal, MultiSelectChips, Tooltip, notify } from '@bynder/design-system';
import VariationSetsService from '~/services/VariationSetsService';
import { VariationTransformedSetItemType } from 'packages/variation-export/types';
import { socketSubscribe } from 'packages/socket';
import { subscribeVariationTranslationsOptions } from 'packages/socket/subscribeOptions/variationsTranslation';
import { StyledMultiSelectChips } from './Variations.styled';
import LANGS from './langs.json';
import modalContainer from 'packages/common/modalContainer';

type Props = {
    onClose: () => void;
    variation: VariationTransformedSetItemType | null;
    getVariationsAndSave: () => void;
    variationsTotal: number;
};

type Language = {
    code: string;
    name: string;
    nativeName: string;
};

const preventSubmit = (event) => event.preventDefault();

const MAX_SELECTED_LANGS = 10;
const MAX_VARIATION_COUNT = 50;
const FAVORITE_LANGS = ['en', 'de', 'fr', 'es', 'it', 'nl'];

const TranslationStatuses = {
    TRANSLATED: 'TRANSLATED',
    PENDING: 'PENDING',
    FAILED: 'FAILED',
};

const languagesList: Language[] = Object.keys(LANGS).map((langCode) => ({
    code: langCode,
    name: LANGS[langCode].name,
    nativeName: LANGS[langCode].nativeName,
}));

const VariationTranslateModal = ({ onClose, variation, getVariationsAndSave, variationsTotal }: Props) => {
    const { translate } = useTranslate();
    const [selectedLngs, setSelectedLngs] = useState<string[]>([]);
    const [value, setValue] = useState('');
    const [isTranslating, setIsTranslating] = useState(false);

    const removeLanguage = (idx: number) => {
        setSelectedLngs((prev) => {
            const temp = [...prev];
            temp.splice(idx, 1);

            return temp;
        });
    };

    const isVariationCountExceeded = selectedLngs.length + variationsTotal > MAX_VARIATION_COUNT;
    const isLangCountExceeded = selectedLngs.length > MAX_SELECTED_LANGS;

    const selectLanguage = (lng: string) => {
        if (isLangCountExceeded) {
            return;
        }

        if (selectedLngs.includes(lng)) {
            return;
        }

        setSelectedLngs((prev) => [...prev, lng]);
        setValue('');
    };

    function subscribeToUpdates(jobId: number, variationSetId: string) {
        return async () => {
            await socketSubscribe(
                subscribeVariationTranslationsOptions({
                    jobId,
                    variationSetId,
                    onMessage: (translations, unsubscribe) => {
                        if (translations.status === TranslationStatuses.FAILED) {
                            unsubscribe();

                            notify({
                                title: <Translate id="design.variations.translate.failure" />,
                                variant: 'error',
                            });

                            notify.remove('variation_translate');
                        }

                        if (translations.status === TranslationStatuses.TRANSLATED) {
                            unsubscribe();

                            notify({
                                id: 'variation_translate',
                                title: <Translate id="design.variations.translate.success" />,
                                variant: 'success',
                            });

                            getVariationsAndSave();
                        }
                    },
                }),
            );
        };
    }

    const handleTranslate = () => {
        if (!variation) {
            return;
        }

        setIsTranslating(true);

        return VariationSetsService.translateVariation(variation.id, { languages: selectedLngs })
            .then((resp: any) => {
                if (resp.status !== 200) {
                    if (resp.json && resp.json.appErrorCode && resp.json.appErrorCode === 15004) {
                        notify({
                            variant: 'error',
                            isPersistent: false,
                            title: <Translate id="design.save.approval.error" />,
                        });
                    } else if (resp.json && resp.json.appErrorCode && resp.json.appErrorCode === 10500) {
                        notify({
                            title: <Translate id="design.variations.translate.limit.error" />,
                            variant: 'error',
                        });
                    } else if (resp.json && resp.json.appErrorCode && resp.json.appErrorCode === 11001) {
                        notify({
                            title: <Translate id="design.variations.translate.locked.error" />,
                            variant: 'error',
                        });
                    } else {
                        notify({
                            title: <Translate id="design.variations.translate.failure" />,
                            variant: 'error',
                        });
                    }

                    return;
                }

                const {
                    json: { jobId },
                } = resp;

                subscribeToUpdates(jobId, variation.variationSetId)();

                notify({
                    id: 'variation_translate',
                    title: <Translate id="design.variations.translate.in-progress" />,
                    variant: 'loading',
                    isPersistent: true,
                });
            })
            .finally(() => {
                setIsTranslating(false);
                onClose();
            });
    };

    const filteredLanguagesTo = useMemo(() => {
        if (value.length) {
            return languagesList.filter(({ name }) => name.toLowerCase().includes(value.toLowerCase()));
        }

        return languagesList;
    }, [languagesList, value]);

    const favoriteLanguages = useMemo(() => {
        return FAVORITE_LANGS.reduce((acc, favLangCode) => {
            const lang = filteredLanguagesTo.find(({ code: langCode }) => langCode === favLangCode);

            return lang ? [...acc, lang] : acc;
        }, [] as Language[]);
    }, [filteredLanguagesTo]);

    const isLangsSame = (a: Language[], b: Language[]) => {
        if (a.length !== b.length) {
            return false;
        }

        return a.every(({ code: aLangCode }) => b.find(({ code: bLangCode }) => bLangCode === aLangCode));
    };

    useEffect(() => {
        // Chips' input is autofocused and there is no property to deprecate it. So we do it manually
        if (variation) {
            setTimeout(() => {
                document.activeElement?.blur();
                document.body.click();
            }, 0);
        }

        return () => {
            if (!variation) {
                setValue('');
                setSelectedLngs([]);
            }
        };
    }, [variation]);

    const translateButton = () => (
        <Button
            variant="primary"
            onClick={handleTranslate}
            icon={<IconTranslate />}
            isDisabled={!selectedLngs.length || isLangCountExceeded || isVariationCountExceeded || isTranslating}
        >
            {translate('design.sidebar.variations.modal.translate.button.translate')}
        </Button>
    );

    return (
        <Modal
            container={modalContainer}
            isOpen={variation !== null}
            onClose={onClose}
            title={translate('design.sidebar.variations.modal.translate.title', { name: variation?.name || '' })}
            actionPrimary={
                isVariationCountExceeded ? (
                    <Tooltip content={translate('design.variations.translate.limit.info')}>{translateButton()}</Tooltip>
                ) : (
                    translateButton()
                )
            }
            actionSecondary={
                <Button variant="secondary" onClick={onClose}>
                    {translate('design.sidebar.variations.modal.translate.button.cancel')}
                </Button>
            }
        >
            <Form onSubmit={preventSubmit}>
                <Form.Group>
                    <Form.Label htmlFor="language_to_chips">
                        {translate('design.sidebar.variations.modal.translate.to.label')}
                    </Form.Label>
                    <StyledMultiSelectChips
                        id="language_to_chips"
                        openOn={selectedLngs.length === 0 ? 'focus' : 'type'}
                        placeholder={translate('design.sidebar.variations.modal.translate.to.placeholder')}
                        value={value}
                        disabled={isLangCountExceeded}
                        aria-describedby="language_to_chips_helper"
                        onChange={setValue}
                        onDeleteAll={() => setSelectedLngs([])}
                        chips={selectedLngs.map((lng, idx) => (
                            <MultiSelectChips.Chip key={lng} onDelete={() => removeLanguage(idx)}>
                                {lng}
                            </MultiSelectChips.Chip>
                        ))}
                    >
                        {filteredLanguagesTo.length ? (
                            <>
                                {favoriteLanguages.length && !isLangsSame(filteredLanguagesTo, favoriteLanguages) ? (
                                    <>
                                        {favoriteLanguages.map((lng) => (
                                            <MultiSelectChips.Item
                                                onClick={() => selectLanguage(lng.name)}
                                                key={lng.code}
                                                isChecked={selectedLngs.includes(lng.name.toLowerCase())}
                                            >
                                                {lng.name}
                                            </MultiSelectChips.Item>
                                        ))}
                                        <Divider />
                                    </>
                                ) : null}
                                {filteredLanguagesTo.map((lng) => (
                                    <MultiSelectChips.Item
                                        onClick={() => selectLanguage(lng.name)}
                                        key={lng.code}
                                        isChecked={selectedLngs.includes(lng.name.toLowerCase())}
                                    >
                                        {lng.name}
                                    </MultiSelectChips.Item>
                                ))}
                            </>
                        ) : (
                            <MultiSelectChips.Text>
                                {translate('design.sidebar.variations.modal.translate.no_result')}
                            </MultiSelectChips.Text>
                        )}
                    </StyledMultiSelectChips>
                    <Form.HelperText id="language_to_chips_helper" isInvalid={isLangCountExceeded}>
                        {translate('design.sidebar.variations.modal.translate.to.helper', {
                            count: selectedLngs.length,
                        })}
                    </Form.HelperText>
                </Form.Group>
            </Form>
        </Modal>
    );
};

export default memo(VariationTranslateModal);
