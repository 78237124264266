import React, { FC } from 'react';
import { Button, Modal } from '@bynder/design-system';
import { envVar } from '~/helpers/envVar';
import generateTestId from '~/helpers/testIdHelpers';
import modalContainer from 'packages/common/modalContainer';

type SaveAsVideoNotAcceptableErrorModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const SaveAsVideoNotAcceptableErrorModal: FC<SaveAsVideoNotAcceptableErrorModalProps> = ({
    isOpen,
    onClose,
}) => {
    const maxVideoSize = envVar.videoMaxDimensions;
    const maxCharacters = envVar.videoMaxCharacters;

    const actionId = generateTestId('editor_header_save_as_video_not_acceptable_ok');

    return (
        <Modal
            container={modalContainer}
            title="Unable to save as video design"
            actionPrimary={
                <Button
                    variant="primary"
                    onClick={onClose}
                    {...actionId}
                    {...{ id: 'editor_header_save_as_video_not_acceptable_ok' }}
                >
                    Okay, got it
                </Button>
            }
            isOpen={isOpen}
            onClose={onClose}
        >
            <p>
                Please check the following specifications and try again:
                <br />
                <br />
                <ul>
                    <li>
                        Image design resolution does not exceed {maxVideoSize} × {maxVideoSize} px
                    </li>
                    <li>
                        Image elements resolution does not exceed {maxVideoSize} × {maxVideoSize} px
                    </li>
                    <li>Text elements do not exceed {maxCharacters} characters</li>
                </ul>
            </p>
        </Modal>
    );
};
