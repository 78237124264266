import { ContentTransformTypes } from '../Enums/ContentTransformTypes';
import { ContentTransform } from '../Models/Shared/ContentTransform';
import { Dimension } from '../Models/Shared/Dimension';
import { Position } from '../Models/Shared/Position';

export function calculateBoxCrop(
    contentTransform: ContentTransform,
    boxDimension: Dimension,
    mediaDimension: Dimension,
) {
    const boxWidth = boxDimension.getWidth();
    const boxHeight = boxDimension.getHeight();
    const mediaWidth = mediaDimension.getWidth();
    const mediaHeight = mediaDimension.getHeight();
    // calculate aspect ratios
    const arBox: number = boxWidth / boxHeight;
    const arMedia: number = mediaWidth / mediaHeight;
    let scale = 1.0;
    let contentOffsetX = 0;
    let contentOffsetY = 0;
    let scaledMediaWidth = 0;
    let scaledMediaHeight = 0;

    if (contentTransform.getType() === ContentTransformTypes.FREE) {
        scale = contentTransform.getScale();
        contentOffsetX = contentTransform.getHorizontalOffset();
        contentOffsetY = contentTransform.getVerticalOffset();
        scaledMediaWidth = mediaWidth * scale;
        scaledMediaHeight = mediaHeight * scale;
    } else {
        // determine scale factor to properly scale the media to fit the box
        if (
            contentTransform.getType() === ContentTransformTypes.COVER_WIDTH ||
            (contentTransform.getType() === ContentTransformTypes.COVER && arBox > arMedia) ||
            (contentTransform.getType() === ContentTransformTypes.CONTAIN && arMedia > arBox)
        ) {
            scale = boxWidth / mediaWidth;
        } else {
            scale = boxHeight / mediaHeight;
        }

        scaledMediaWidth = mediaWidth * scale;
        scaledMediaHeight = mediaHeight * scale;
        const horAlignment = contentTransform.getHorizontalAlignment();
        const verAlignment = contentTransform.getVerticalAlignment();

        // now position the media in the box and save the offsets
        if (horAlignment >= -1.0 && horAlignment <= 1.0) {
            // content is positioned inside the bounding box
            contentOffsetX = ((boxWidth - scaledMediaWidth) / 2) * (horAlignment + 1.0);
        } else if (horAlignment < -1.0) {
            // content is positioned left outside of the box
            contentOffsetX = scaledMediaWidth * (Math.abs(horAlignment) - 1.0) * -1;
        } else {
            // content is positioned right outside of the box
            contentOffsetX = scaledMediaWidth * (Math.abs(horAlignment) - 1.0) + (boxWidth - scaledMediaWidth);
        }

        if (verAlignment >= -1.0 && verAlignment <= 1.0) {
            // content is positioned inside the bounding box
            contentOffsetY = ((boxHeight - scaledMediaHeight) / 2) * (verAlignment + 1.0);
        } else if (verAlignment < -1.0) {
            // content is positioned top outside of the box
            contentOffsetY = scaledMediaHeight * (Math.abs(verAlignment) - 1.0) * -1;
        } else {
            // content is positioned bottom outside of the box
            contentOffsetY = scaledMediaHeight * (Math.abs(verAlignment) - 1.0) + (boxHeight - scaledMediaHeight);
        }
    }

    // calculate intersection of box and scaled media
    // let mediaCoords = [contentOffsetX, contentOffsetY, scaledMediaWidth - contentOffsetX, scaledMediaHeight - contentOffsetY];
    // let boxCoords = [0, 0, boxWidth, boxHeight];
    const topLeftX = Math.max(0, contentOffsetX) || 0;
    const topLeftY = Math.max(0, contentOffsetY) || 0;
    const bottomRightX = Math.min(boxWidth, scaledMediaWidth + contentOffsetX) || 0;
    const bottomRightY = Math.min(boxHeight, scaledMediaHeight + contentOffsetY) || 0;
    // size and position of content in bounding box
    const contentPosition = new Position(topLeftX, topLeftY);
    const contentDimension = new Dimension(bottomRightX - topLeftX, bottomRightY - topLeftY);
    // size and position crop of the original media
    const cropPosition = new Position(
        contentOffsetX < 0 ? Math.abs(contentOffsetX) / scale : 0,
        contentOffsetY < 0 ? Math.abs(contentOffsetY) / scale : 0,
    );
    const cropDimension = new Dimension(contentDimension.width / scale, contentDimension.height / scale);
    // Include original crop details as a percentage
    const cropPositionPct = new Position(cropPosition.getX() / mediaWidth, cropPosition.getY() / mediaHeight);
    const cropDimensionPct = new Dimension(
        cropDimension.getWidth() / mediaWidth,
        cropDimension.getHeight() / mediaHeight,
    );
    return {
        cropPositionPct: cropPositionPct,
        cropDimensionPct: cropDimensionPct,
        position: contentPosition,
        dimension: contentDimension,
    };
}
