import { useSelector } from 'react-redux';
import { useCallback } from 'react';

import { getUserTimeZone } from 'packages/store/user/user.selectors';
import { getFriendlyTime } from '~/helpers/freindlyTime';

export const useFriendlyTime = () => {
    // @ts-ignore because reducer is not "typed"
    const timeZone = useSelector(getUserTimeZone);

    const getFriendlyTimeWithTz = useCallback(getFriendlyTime(timeZone), [timeZone]);

    return {
        getFriendlyTime: getFriendlyTimeWithTz,
    };
};
