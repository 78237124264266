import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from '@bynder/localization';
import { Dropdown, Form, Input } from '@bynder/design-system';
import useSizes from 'packages/pages/designCreateModal/components/Blank/useSizes';
import editorAutocorrect from 'packages/pages/editor/EditorAutocorrectRules';
import { setSaveStatus } from 'packages/store/creativeEditor/creativeEditor.actions';
import generateTestId from '~/helpers/testIdHelpers';
import CreativeTypes from '~/helpers/CreativeTypes';
import { DropdownButton } from './index.styled';
import { FormSectionContent } from '../../index.styled';

const SizeSelector = ({ creativeModel, creativeType, id }) => {
    const { translate } = useTranslate();
    const dispatch = useDispatch();

    const isVideoCreative = creativeType === CreativeTypes.Video;

    const dimension = creativeModel?.getDimensions();
    const modelMetaData = creativeModel.getModelsMetaData();
    const selectedPageIndex = creativeModel.getCurrentPageIndex();

    const [width, setWidth] = useState(dimension.width);
    const [height, setHeight] = useState(dimension.height);

    const [search, setSearch] = useState('');
    const { videoCategories, imageCategories, findByFormatKey } = useSizes(search, search);

    const sizeCategories = isVideoCreative ? videoCategories : imageCategories;

    const matchFormatChangeWithSelector = (format) => findByFormatKey(sizeCategories, format);

    const [selected, setSelected] = useState(matchFormatChangeWithSelector(modelMetaData[selectedPageIndex].format));

    const updateDimensions = (sizeItem) => {
        const dimensions = {
            width: sizeItem.width,
            height: sizeItem.height,
        };

        creativeModel.updateDimension(dimensions);

        const newMeta = modelMetaData.map((pageMeta, index) => {
            if (index === selectedPageIndex) {
                return { ...pageMeta, format: sizeItem.format };
            }
            return pageMeta;
        });

        creativeModel.setModelsMetaData(newMeta);

        dispatch(setSaveStatus());
    };

    const onBlur = () => {
        const newDimensions = { width, height };
        const correctDimensions = editorAutocorrect('global_dimensions', newDimensions, creativeType);

        if (
            correctDimensions.width === dimension.width &&
            correctDimensions.height === dimension.height &&
            selected.size.width === dimension.width &&
            selected.size.height === dimension.height
        ) {
            return;
        }

        updateDimensions(correctDimensions);
    };

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            onBlur();
        }
    };

    const onChange = () => {
        const newDimension = creativeModel.getDimensions();
        setWidth(newDimension.getWidth());
        setHeight(newDimension.getHeight());

        const newModelMetaData = creativeModel.getModelsMetaData();
        const newSelectedPageIndex = creativeModel.getCurrentPageIndex();
        setSelected(matchFormatChangeWithSelector(newModelMetaData[newSelectedPageIndex]?.format));
    };

    useEffect(() => {
        creativeModel.on('currentPageChange', onChange);

        return () => {
            creativeModel.off('currentPageChange', onChange);
        };
    }, []);

    useEffect(() => {
        onChange();
    }, [
        creativeModel?.getDimensions()?.width,
        creativeModel?.getDimensions()?.height,
        creativeModel.getCurrentPageIndex(),
        creativeModel.getModelsMetaData()[creativeModel.getCurrentPageIndex()]?.format,
    ]);

    const dropdownTestId = generateTestId('global_format');
    const widthTestId = generateTestId('global_width');
    const heightTestId = generateTestId('global_height');

    return (
        <FormSectionContent onSubmit={(event) => event.preventDefault()}>
            <Form.Group>
                <Dropdown
                    trigger={({ isOpen, ...triggerProps }) => (
                        <DropdownButton
                            isPressed={isOpen}
                            isFullWidth
                            variant="secondary"
                            icon={selected.icon}
                            rightIcon={<Dropdown.Arrow />}
                            aria-label={selected.label}
                            {...dropdownTestId}
                            {...triggerProps}
                        >
                            {selected.label}
                        </DropdownButton>
                    )}
                    position="bottom"
                >
                    <Dropdown.SearchInput
                        value={search}
                        onChange={(value) => setSearch(value)}
                        placeholder={translate('modal.design.create.blank.mediaType.video.search.placeholder')}
                        aria-label={translate('modal.design.create.blank.mediaType.video.search.label')}
                        data-testid={`${id}_search_input`}
                    />
                    {sizeCategories.map((category, index) => (
                        <Fragment key={category.label}>
                            <Dropdown.SectionTitle>{category.label}</Dropdown.SectionTitle>
                            {category.items.map((item) => (
                                <Dropdown.Item
                                    key={item.label}
                                    icon={item.icon}
                                    isChecked={item.label === selected.label}
                                    onClick={() => {
                                        if (selected.size.format === item.size.format) return;
                                        updateDimensions(item.size);
                                    }}
                                    isDisabled={!item.size.width || !item.size.height}
                                >
                                    {item.label}
                                </Dropdown.Item>
                            ))}
                            {sizeCategories.length - 1 !== index ? <Dropdown.Divider /> : null}
                        </Fragment>
                    ))}
                    {!sizeCategories.length ? (
                        <Dropdown.Text>{translate('modal.design.create.blank.items.no_results')}</Dropdown.Text>
                    ) : null}
                </Dropdown>
            </Form.Group>
            <Form.Row>
                <Form.Group>
                    <Input
                        type="number"
                        id={`${id}-width`}
                        aria-label={`${id}-width`}
                        aria-describedby={`${id}_width_message`}
                        value={width}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        onChange={(newWidth: any) => setWidth(newWidth)}
                        suffix="px"
                        prefix="W"
                        {...widthTestId}
                    />
                </Form.Group>
                <Form.Group>
                    <Input
                        type="number"
                        id={`${id}-height`}
                        aria-label={`${id}-width`}
                        aria-describedby={`${id}_width_message`}
                        value={height}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        onChange={(newHeight: any) => setHeight(newHeight)}
                        data-testid={`${id}_height`}
                        suffix="px"
                        prefix="H"
                        {...heightTestId}
                    />
                </Form.Group>
            </Form.Row>
        </FormSectionContent>
    );
};

export default SizeSelector;
