import React, { useState } from 'react';
import { BaseMultiPageModel } from '@bynder-studio/render-core';
import type { ContentProperty } from '@bynder-studio/render-core';
import ContentPropertyListModal from './components/modals/ListModal/ContentPropertyListModal';

type Props = {
    properties: ContentProperty[];
    creativeModel: BaseMultiPageModel;
    onSave: (updatedProperties: ContentProperty[]) => void;
};

export function useContentPropertyListModal({ creativeModel, properties, onSave }: Props) {
    const [isOpen, setIsModalOpen] = useState(false);

    const renderContentPropertyListModal = () => (
        <ContentPropertyListModal
            isOpen={isOpen}
            creativeModel={creativeModel}
            properties={properties}
            onClose={() => setIsModalOpen(false)}
            onSave={onSave}
        />
    );

    return {
        renderContentPropertyListModal,
        setIsContentPropertyListModalOpen: setIsModalOpen,
    };
}
